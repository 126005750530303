import { Button } from 'antd'
import styled from 'styled-components'

import { css } from '@styled-system/css'

export const ButtonConfirm = styled(Button)({
  marginLeft: 'auto',
})

export const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`

export const SelectWrapper = styled.div`
  max-width: 100%;
`

export const Description = styled.div(
  css({
    variant: 'typography.textSmallRegular',
    color: 'TxtPrimary',
    marginBottom: [null, null, '16px'],
  })
)
