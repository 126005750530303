import styled from 'styled-components'

import { breakpoints } from '~/tokens'

export const HeaderWrapperDesktop = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 24px;
  justify-content: space-between;
  margin-bottom: 32px;

  @media (max-width: ${breakpoints.MOBILE_XL}px) {
    display: none;
  }
`

export const HeaderWrapperEditDesktop = styled(HeaderWrapperDesktop)`
  flex-direction: column;
  align-items: flex-start;
`

export const HeaderWrapperMobile = styled.div`
  display: none;

  @media (max-width: ${breakpoints.MOBILE_XL}px) {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-bottom: 16px;
  }
`

export const HeaderWrapperMobileEdit = styled.div`
  display: none;

  @media (max-width: ${breakpoints.MOBILE_XL}px) {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 16px;
    margin-bottom: 16px;
  }
`

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`

export const ViewTitle = styled.p`
  font-weight: 700;
  font-size: 22px;
  line-height: 28px;
  margin: 0;
  color: var(--gray-8-color);
`
export const EditTitle = styled.p`
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  margin: 0;
  color: var(--gray-8-color);

  @media (max-width: ${breakpoints.MOBILE_XL}px) {
    font-size: 16px;
    line-height: 24px;
  }
`
