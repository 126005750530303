import React from 'react'

import { Skeleton } from 'antd'

import { MyUserEntity } from '~pages/EmployeesPage/components/EmployeesList/EmployeesList'

import { UserAddedList } from './components/UserAdded/UserAddedList'

import * as S from './CompanyEmployee.styled'

interface CompanyEmployeeProps {
  users: MyUserEntity[]
  loading: boolean
  canDeleteUserFromCompany: boolean
  onUserEdit: (userId: number) => void
  onUserDelete: (userId: number) => void
  onUserActivate: (userId: number) => void
  onUserDeactivate: (userId: number) => void
}

export const CompanyEmployeeAdded: React.FC<CompanyEmployeeProps> = (props) => {
  return (
    <S.Wrapper>
      <S.Header>
        <S.Title variant="h3">Добавленные</S.Title>
      </S.Header>

      <Skeleton loading={props.loading} />

      {!props.loading && (
        <UserAddedList
          users={props.users}
          onUserEdit={props.onUserEdit}
          onUserDelete={props.onUserDelete}
          canDeleteUserFromCompany={props.canDeleteUserFromCompany}
          onUserActivate={props.onUserActivate}
          onUserDeactivate={props.onUserDeactivate}
        />
      )}
    </S.Wrapper>
  )
}
