import React from 'react'

import { Badge, Button } from 'antd'
import { FiBell } from 'react-icons/fi'
import { useNavigate } from 'react-router-dom'

import { appRoutes } from '~/router'
import { useGetDevice } from '~hooks/common'
import { useGetUnreadNotificationsByInterval } from '~hooks/notifications'

export const NotificationsCounter: React.FC = () => {
  const navigate = useNavigate()
  const device = useGetDevice()
  const isMobile = device === 'mobile'
  const notificationsCounterFn = useGetUnreadNotificationsByInterval()

  const onNotificationClickHandler = () => {
    navigate(appRoutes.notifications.url)
  }

  return (
    <Badge count={notificationsCounterFn.data?.totalUnreadNotifications} overflowCount={100}>
      <Button
        size={isMobile ? 'small' : 'middle'}
        type="text"
        icon={<FiBell size={isMobile ? 22 : 24} color="grey" />}
        onClick={onNotificationClickHandler}
      />
    </Badge>
  )
}
