import React, { useState } from 'react'

import { Modal } from 'antd'

import { EmptyButton } from '~/ui-components'
import { useCreateCompanyEdo, useGetCompanyEdo, useUpdateCompanyEdo } from '~hooks/company'

import { appToast, modalSharedSettings } from '../../../../../../../utils'
import { EdoForm } from '../EdoForm'
import { EdoView } from '../EdoView'

import { IEdoForm } from '../EdoForm/EdoForm.types'

interface IEdoSectionProps {
  companyId: number
}

export const EdoSection: React.FC<IEdoSectionProps> = ({ companyId }) => {
  const [isModalVisible, setIsModalVisible] = useState(false)
  const getCompanyEdo = useGetCompanyEdo(companyId)
  const updateCompanyEdo = useUpdateCompanyEdo(companyId ?? NaN)
  const createCompanyEdo = useCreateCompanyEdo(companyId ?? NaN)

  const handleModalClose = () => setIsModalVisible(false)
  const handleModalOpen = () => {
    if (!companyId) {
      appToast.info({ description: 'Сначала заполните данные о компании' })
    } else {
      setIsModalVisible(true)
    }
  }
  const handleSubmitChanges = async (formValues: IEdoForm) => {
    try {
      if (!getCompanyEdo.data) {
        await createCompanyEdo.mutateAsync({
          edmId: formValues.operatorEdoId,
          name: formValues.operatorEdoName,
        })
      } else {
        await updateCompanyEdo.mutateAsync({
          name: formValues.operatorEdoName,
          edmId: formValues.operatorEdoId,
        })
      }

      await getCompanyEdo.refetch()
      appToast.success({ description: 'Данные о компании обновлены' })
    } catch (e) {
      const description = 'Ошибка при обновлении электронного документооборота'
      console.error(description, e)
      appToast.error({ description })
    }
    handleModalClose()
  }

  if (getCompanyEdo.isLoading) {
    return <EmptyButton>Загрузка...</EmptyButton>
  }

  if (getCompanyEdo.isError) {
    return <EmptyButton>Ошибка</EmptyButton>
  }

  return (
    <>
      {!getCompanyEdo.data ? (
        <EmptyButton onClick={handleModalOpen}>Добавить электронный документооборот</EmptyButton>
      ) : (
        <EdoView onChange={handleModalOpen} data={getCompanyEdo.data} />
      )}

      <Modal
        {...modalSharedSettings}
        open={isModalVisible}
        onCancel={handleModalClose}
        title="Электронный Документооборот"
      >
        <EdoForm
          onSubmit={handleSubmitChanges}
          isLoading={updateCompanyEdo.isLoading}
          defaultValues={getCompanyEdo.data}
        />
      </Modal>
    </>
  )
}
