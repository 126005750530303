import React from 'react'

import { Empty, Skeleton } from 'antd'

import { ModeratorOfferListMobileItem } from './ModeratorOfferListMobileItem'

import { IModeratorOfferListMobileProps, ModeratorOfferListMobileItemType } from './ModeratorOfferListMobile.types'

import * as S from './ModeratorOfferListMobileItem.styled'

export const ModeratorOfferListMobile: React.FC<IModeratorOfferListMobileProps> = ({
  data,
  loading,
  onItemClick,
  takeOfferToWork,
}) => {
  const onItemClickHandler = (record: ModeratorOfferListMobileItemType) => {
    onItemClick(record)
  }

  const takeOfferToWorkHandler = (offerId: number) => {
    takeOfferToWork(offerId)
  }

  if (loading) {
    return <Skeleton />
  }

  if (!data.length) {
    return <Empty />
  }

  return (
    <S.MobileItemsWrapper>
      {data.map((item) => (
        <ModeratorOfferListMobileItem
          key={item.id}
          {...item}
          takeOfferToWork={takeOfferToWorkHandler}
          onItemClick={onItemClickHandler}
        />
      ))}
    </S.MobileItemsWrapper>
  )
}
