import React from 'react'

import { Button, Dropdown } from 'antd'
import { ItemType } from 'antd/es/menu/interface'
import { FiMoreVertical, FiTrash2 } from 'react-icons/fi'

import { appToast } from '~/utils'
import { UpdateAuctionOfferStatusBodyStatusEnum } from '~api/generated'
import { useUpdateAuctionOfferStatus } from '~hooks/auctions'

interface IOfferActionMenuProps {
  auctionOfferId: number
  onCancelOffer?: () => void
}

export const ActionOfferMenu: React.FC<IOfferActionMenuProps> = ({ auctionOfferId, onCancelOffer }) => {
  const updateAuctionOfferStatus = useUpdateAuctionOfferStatus()
  const handleUnpublish = async () => {
    try {
      await updateAuctionOfferStatus.mutateAsync({
        auctionOfferId,
        updateAuctionOfferStatusBody: {
          status: UpdateAuctionOfferStatusBodyStatusEnum.Rejected,
        },
      })
      await onCancelOffer?.()
      appToast.success({ description: 'Ставка отменена' })
    } catch (e) {
      const description = 'Ошибка отмены ставки'
      console.error(description, e)
      appToast.error({ description })
    }
  }

  const menuItems: ItemType[] = [
    {
      label: 'Отменить ставку',
      key: 'unpublish',
      icon: <FiTrash2 />,
      onClick: handleUnpublish,
    },
  ]

  return (
    <>
      <Dropdown menu={{ items: menuItems }} trigger={['click']}>
        <Button size="small" type="text" icon={<FiMoreVertical size={24} />} onClick={(evt) => evt.stopPropagation()} />
      </Dropdown>
    </>
  )
}
