import React from 'react'

import { RouteObject } from 'react-router-dom'

import { Warehouses } from '~/pages'
import { appRoutes } from '~/router'

import { RequireAuth } from '../../providers/auth'

export const warehousesRouter: RouteObject = {
  path: appRoutes.warehouses.url,
  element: (
    <RequireAuth>
      <Warehouses />
    </RequireAuth>
  ),
}
