import * as yup from 'yup'

import { useCheckCreateUserForCompany } from '~hooks/user'
import { VALIDATORS_MSG } from '~shared/validations'
import { fullPhoneNumberValidator } from '~shared/validations/schemas'

const requiredTrimmedString = yup.string().trim().required(VALIDATORS_MSG.required)
const notRequiredTrimmedString = yup.string().trim().notRequired()
export const createAdvertOfferFormByAdminSchema = () => {
  const checkCreateUserForCompany = useCheckCreateUserForCompany()

  return yup.object({
    advertId: yup.number().typeError(VALIDATORS_MSG.required).required(VALIDATORS_MSG.required),
    volume: yup
      .number()
      .typeError(VALIDATORS_MSG.required)
      .min(200, VALIDATORS_MSG.minValue(200))
      .required(VALIDATORS_MSG.required),
    pricePerTon: yup
      .number()
      .typeError(VALIDATORS_MSG.required)
      .min(1000, VALIDATORS_MSG.minValue(1000))
      .required(VALIDATORS_MSG.required),
    usingNds: yup.boolean().notRequired(),
    companyInfo: yup.object({
      name: requiredTrimmedString,
      inn: requiredTrimmedString,
      kpp: notRequiredTrimmedString,
      codeOkved: requiredTrimmedString,
      director: notRequiredTrimmedString,
      actualAddress: notRequiredTrimmedString,
      legalAddress: notRequiredTrimmedString,
    }),
    contactUser: requiredTrimmedString,
    contactPhone: yup
      .string()
      .trim()
      .test('mobilePhone', VALIDATORS_MSG.notFullPhoneNumber, fullPhoneNumberValidator)
      .required(VALIDATORS_MSG.required)
      .test({
        name: 'contactPhone',
        exclusive: true,
        params: {},
        message: 'Пользователь привязан к другой компании',
        test: function (phoneNumber) {
          const inn = this.parent.companyInfo?.inn
          if (!phoneNumber || !inn) return true
          return new Promise((resolve) => {
            checkCreateUserForCompany
              .mutateAsync({
                phoneNumber,
                inn,
              })
              .then((data) => resolve(data))
          })
        },
      }),
  })
}
