import React, { useEffect, useRef, useState } from 'react'

import { Button, Select } from 'antd'
import { SizeType } from 'antd/es/config-provider/SizeContext'
import { BaseSelectRef } from 'rc-select'

import { useGetProductTypes } from '~/hooks'
import { ProductTypeData } from '~api/generated'
import { CustomControlInterface } from '~shared/controls/CustomControl.interface'

import * as S from './CultureSelectControl.styled'
export type CultureSelectControlValue = ProductTypeData['type'][]

interface CultureSelectControlProps extends CustomControlInterface {
  onChange: (value: CultureSelectControlValue) => void
  value: CultureSelectControlValue
  size?: SizeType
  hasError?: boolean
  id?: string
}

export const CultureSelectControl: React.FC<CultureSelectControlProps> = React.forwardRef<
  BaseSelectRef,
  CultureSelectControlProps
>(({ id, value, onChange, hasError, size }, ref) => {
  const cultureList = useGetProductTypes()
  const [isOpen, setOpen] = useState(false)
  const selectRef = useRef<BaseSelectRef | null>(null)

  const onSelectAddressHandler = (v: CultureSelectControlValue) => {
    onChange(v)
  }

  useEffect(() => {
    if (!isOpen) {
      setTimeout(() => {
        selectRef.current?.blur()
      }, 100)
    }
  }, [isOpen])

  return (
    <S.StyledSelect
      ref={(el) => (selectRef.current = el)}
      id={id}
      size={size}
      showSearch
      mode="multiple"
      maxTagCount={1}
      style={{ width: '100%' }}
      placeholder="Выберите культуру"
      value={value}
      status={hasError ? 'error' : undefined}
      optionFilterProp="children"
      loading={cultureList.isLoading}
      // @ts-ignore
      onChange={onSelectAddressHandler}
      showArrow
      maxTagTextLength={16}
      maxTagPlaceholder={(omittedValues) => `+${omittedValues.length}`}
      onDropdownVisibleChange={(visible) => setOpen(visible)}
      suffixIcon={
        isOpen ? (
          <Button type="primary" size="small">
            Ok
          </Button>
        ) : undefined
      }
    >
      {cultureList.data?.map((culture) => (
        <Select.Option key={culture.type} value={culture.type}>
          {culture.name}
        </Select.Option>
      ))}
    </S.StyledSelect>
  )
})

CultureSelectControl.displayName = 'CultureSelectControl'
