import { codeUnreachable } from '~/utils/code-unreachable'
import { FreightOrderChangeRequestBlockType } from '~api/gql-generated/graphql'

export function getFreightOrderChangeRequestBlockTypeName(
  freightOrderChangeRequestBlockType: FreightOrderChangeRequestBlockType
): string {
  switch (freightOrderChangeRequestBlockType) {
    case FreightOrderChangeRequestBlockType.Freight:
      return 'Груз'
    case FreightOrderChangeRequestBlockType.Price:
      return 'Оплата'
    case FreightOrderChangeRequestBlockType.TransportRegistry:
      return 'Реестр авто'
    case FreightOrderChangeRequestBlockType.Route:
      return 'Маршрут'
    default:
      return codeUnreachable(freightOrderChangeRequestBlockType)
  }
}
