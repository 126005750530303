import { graphql } from '~api/gql-generated'

export const bankAccountCreateMutation = graphql(/* GraphQL */ `
  mutation BankAccountCreate($input: BankAccountCreateMutationsInput!) {
    bankAccountCreate(input: $input) {
      companyId
      bankName
      bic
      correspondentAccount
      paymentAccount
    }
  }
`)

export const bankAccountDeleteMutation = graphql(/* GraphQL */ `
  mutation BankAccount($bankAccountId: Int!) {
    bankAccount(bankAccountId: $bankAccountId) {
      delete
    }
  }
`)
