import styled from 'styled-components'

import { css } from '@styled-system/css'

export const ButtonWrapper = styled.div(
  css({
    display: 'flex',
    flexWrap: 'wrap',
    columnGap: ['16px', null, '24px'],
    rowGap: '12px',
  })
)
export const StatusWrapper = styled.div(
  css({
    display: 'flex',
    gap: '24px',
  })
)

export const Content = styled.div(
  css({
    display: 'flex',
    flexDirection: 'column',
    gap: ['16px', null, '24px'],
  })
)
