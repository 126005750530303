import React from 'react'

import { Flex, Spin } from 'antd'
import { observer } from 'mobx-react-lite'
import { FiArrowRight } from 'react-icons/fi'

import { appRoutes } from '~/router'
import { userUiSettingsStore } from '~/store/userUiSettingsStore'
import { getNoun } from '~/utils/getNoun'
import { ReactComponent as DamadeLogoLargeVip } from '~assets/icons/damade-logo-large-vip.svg'
import { ReactComponent as DamadeLogoVip } from '~assets/icons/damade-logo-vip.svg'
import { ReactComponent as GapResursLogoLargeVip } from '~assets/icons/gap-resurs-logo-large-vip.svg'
import { ReactComponent as GapResursLogoVip } from '~assets/icons/gap-resurs-logo-vip.svg'
import { useGetVipElevators } from '~hooks/elevators'

import * as S from '~layout/WelcomePageV2/WelcomePageV2.styled'

export function findVipClientById(id: VipClientId) {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return Object.values(VIP_CLIENTS).find((client) => client.id === id)!
}

export enum VipClientId {
  GAP = 'gap-resurs',
  DAMATE = 'damate',
}

export const VIP_CLIENTS = {
  gapResurs: {
    isShow: true,
    id: VipClientId.GAP,
    legalName: 'ГАП РЕСУРС',
    logo: <GapResursLogoVip />,
    logoLarge: <GapResursLogoLargeVip />,
  },
  damate: {
    isShow: true,
    id: VipClientId.DAMATE,
    legalName: 'ГК «ДАМАТЕ»',
    logo: <DamadeLogoVip />,
    logoLarge: <DamadeLogoLargeVip />,
  },
} as const

export const VipCards: React.FC = observer(() => {
  const getElevatorsFn = useGetVipElevators()

  const gapCount = getElevatorsFn.data?.gapResourceBuyOffersCount ?? 0
  const damateCount = getElevatorsFn.data?.damateBuyOffersCount ?? 0

  return (
    <Spin spinning={getElevatorsFn.isLoading}>
      <S.VipWrapper>
        {VIP_CLIENTS.gapResurs.isShow && gapCount > 0 ? (
          <S.VipCard
            to={appRoutes.basisVip.url.replace(':id', VIP_CLIENTS.gapResurs.id)}
            onClick={() => userUiSettingsStore.setVipClientId(VipClientId.GAP)}
          >
            <Flex vertical gap={16}>
              {VIP_CLIENTS.gapResurs.logo}
              <S.GapResource>{VIP_CLIENTS.gapResurs.legalName}</S.GapResource>
            </Flex>
            <Flex vertical gap={16} align="flex-end">
              <S.Counter>
                {gapCount} {getNoun(gapCount, 'объявление', 'объявления', 'объявлений')}
              </S.Counter>
              <FiArrowRight size={24} />
            </Flex>
          </S.VipCard>
        ) : null}

        {VIP_CLIENTS.damate.isShow && damateCount > 0 ? (
          <S.VipCard
            to={appRoutes.basisVip.url.replace(':id', VIP_CLIENTS.damate.id)}
            onClick={() => userUiSettingsStore.setVipClientId(VipClientId.DAMATE)}
          >
            <Flex vertical gap={16}>
              {VIP_CLIENTS.damate.logo}
              <S.GapResource>{VIP_CLIENTS.damate.legalName}</S.GapResource>
            </Flex>
            <Flex vertical gap={16} align="flex-end">
              <S.Counter>
                {damateCount} {getNoun(damateCount, 'объявление', 'объявления', 'объявлений')}
              </S.Counter>
              <FiArrowRight size={24} />
            </Flex>
          </S.VipCard>
        ) : null}
      </S.VipWrapper>
    </Spin>
  )
})
