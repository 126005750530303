import React, { useEffect, useState } from 'react'

import { InputNumber, Slider } from 'antd'

import { CustomControlInterface } from '~shared/controls/CustomControl.interface'

import * as S from './RangeSliderControl.styled'

const INPUT_WIDTH = { width: '100%' }
const MIN_VALUE = 0
const MAX_VALUE = 10000

export type RangeSliderValue = {
  from: number
  to: number
}

interface IRangeSliderControlProps extends CustomControlInterface {
  onChange: (value: RangeSliderValue) => void
  value: RangeSliderValue
  step?: number
  minValue?: number
  maxValue?: number
  id?: string
  hasError?: boolean
}

export const RangeSliderControl: React.FC<IRangeSliderControlProps> = React.forwardRef<
  HTMLDivElement,
  IRangeSliderControlProps
>((props, ref) => {
  const minValue = props.minValue ?? MIN_VALUE
  const maxValue = props.maxValue ?? MAX_VALUE
  const [fromValue, setFromValue] = useState<number>(props.value.from)
  const [toValue, setToValue] = useState<number>(props.value.to)
  const [fromDefaultValue, setFromDefaultValue] = useState<number>(props.value.from)
  const [toDefaultValue, setToDefaultValue] = useState<number>(props.value.to)

  useEffect(() => {
    setFromValue(props.value.from)
    setToValue(props.value.to)
  }, [props.value.to, props.value.from])

  useEffect(() => {
    props.onChange({
      from: fromDefaultValue,
      to: toDefaultValue,
    })
  }, [fromDefaultValue, toDefaultValue])

  const onChangeInputHandler = (inputType: 'from' | 'to') => (value: number | null) => {
    if (!value) {
      return
    }
    if (inputType === 'from') {
      return setFromDefaultValue(value)
    }
    if (inputType) {
      return setToDefaultValue(value)
    }
  }

  const onChangeSliderHandler = (value: number[]) => {
    setFromDefaultValue(value[0])
    setToDefaultValue(value[1])
  }

  return (
    <>
      <S.InputWrapper>
        <InputNumber
          prefix="от"
          style={INPUT_WIDTH}
          min={minValue}
          max={toDefaultValue}
          defaultValue={fromDefaultValue}
          value={fromValue}
          onChange={onChangeInputHandler('from')}
        />
        <span>-</span>
        <InputNumber
          prefix="до"
          style={INPUT_WIDTH}
          min={minValue}
          max={maxValue}
          defaultValue={toDefaultValue}
          value={toValue}
          onChange={onChangeInputHandler('to')}
        />
      </S.InputWrapper>
      <Slider
        range
        ref={ref}
        step={props.step}
        min={minValue}
        max={maxValue}
        defaultValue={[fromDefaultValue, toDefaultValue]}
        value={[fromValue, toValue]}
        onChange={onChangeSliderHandler}
      />
    </>
  )
})

RangeSliderControl.displayName = 'RangeSliderControl'
