import { useMutation } from '@tanstack/react-query'

import {
  ElevatorAdvertApiAdminElevatorAdvertControllerUpdateElevatorAdvertModeratorRequest,
  ElevatorAdvertOfferApiAdminElevatorAdvertOfferControllerAdminUpdateAdvertOfferModeratorRequest,
} from '~/api/generated'
import { useApi } from '~providers/api'

export const useUpdateElevatorAdvertOfferModerator = () => {
  const api = useApi()

  return useMutation(
    (request: ElevatorAdvertOfferApiAdminElevatorAdvertOfferControllerAdminUpdateAdvertOfferModeratorRequest) => {
      return api.elevatorAdvertOffer.adminElevatorAdvertOfferControllerAdminUpdateAdvertOfferModerator(request)
    }
  )
}
