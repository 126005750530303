import { useMutation } from '@tanstack/react-query'

import { DealDocumentApiDealDocumentControllerDeleteDocumentRequest } from '~api/generated'
import { useApi } from '~providers/api'

export const useRemoveDealDocument = () => {
  const api = useApi()

  const removeDealDocumentFn = async (
    request: DealDocumentApiDealDocumentControllerDeleteDocumentRequest
  ): Promise<void> => {
    const res = await api.dealDocument.dealDocumentControllerDeleteDocument(request)

    return res.data
  }

  return useMutation(removeDealDocumentFn)
}
