import { codeUnreachable } from '~/utils/code-unreachable'
import { PreferredRouteType } from '~api/gql-generated/graphql'

export function getPreferredRouteTypeName(preferredRouteType: PreferredRouteType): string {
  switch (preferredRouteType) {
    case PreferredRouteType.Short:
      return 'Короткого рейса'
    case PreferredRouteType.Long:
      return 'Дальнего рейса'
    default:
      return codeUnreachable(preferredRouteType)
  }
}
