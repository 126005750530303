import React, { useEffect } from 'react'

import { Input } from 'antd'
import { Controller, useForm } from 'react-hook-form'

import { yupResolver } from '@hookform/resolvers/yup'

import { FormField, TimePicker } from '~/ui-components'
import {
  PhoneNumberControl,
  ScalesWeightLimitControl,
  WarehouseLoadTypeControl,
  YesOrNotControl,
} from '~shared/controls'

import { loadingInfoCardFormSchema } from './LoadingInfoCardFormSchema'

import { DealLoadingInfoCardForm, DealLoadingInfoCardFormValues } from './LoadingInfoCardForm.types'

import * as S from './LoadingInfoCardForm.styled'

export const LoadingInfoCardForm: React.FC<DealLoadingInfoCardForm> = ({ defaultValues, onSubmit, formId }) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useForm<DealLoadingInfoCardFormValues>({
    defaultValues: {
      contactUser: defaultValues?.contactUser ?? '',
      contactPhone: defaultValues?.contactPhone ?? '',
      loadingType: defaultValues?.loadingType,
      hasScales: defaultValues?.hasScales,
      scalesWeightLimit: defaultValues?.scalesWeightLimit,
      openingTime: defaultValues?.openingTime,
      closingTime: defaultValues?.closingTime,
    },
    resolver: yupResolver(loadingInfoCardFormSchema),
  })
  const hasScales = watch('hasScales')

  useEffect(() => {
    if (!hasScales) {
      setValue('scalesWeightLimit', null)
    }
  }, [hasScales])

  return (
    <form id={formId} onSubmit={handleSubmit(onSubmit)}>
      <FormField
        label="Время работы пункта погрузки"
        validateMessage={errors.openingTime?.message ?? errors.closingTime?.message}
      >
        <S.OpeningHoursWrapper>
          <Controller
            control={control}
            name="openingTime"
            render={({ field }) => <TimePicker value={field.value} onChange={field.onChange} allowClear />}
          />
          -
          <Controller
            control={control}
            name="closingTime"
            render={({ field }) => <TimePicker value={field.value} onChange={field.onChange} />}
          />
        </S.OpeningHoursWrapper>
      </FormField>

      <FormField label="Способ погрузки" htmlFor="loadingType" validateMessage={errors.loadingType?.message}>
        <Controller
          name="loadingType"
          control={control}
          render={({ field }) => <WarehouseLoadTypeControl {...field} hasError={!!errors.loadingType} />}
        />
      </FormField>

      <FormField label="Весы" validateMessage={errors.hasScales?.message}>
        <Controller name="hasScales" control={control} render={({ field }) => <YesOrNotControl {...field} />} />
      </FormField>

      <FormField label="Тоннаж весов" htmlFor="scalesWeightLimit" validateMessage={errors.scalesWeightLimit?.message}>
        <Controller
          name="scalesWeightLimit"
          control={control}
          render={({ field }) => (
            <ScalesWeightLimitControl id="scalesWeightLimit" {...field} hasError={!!errors.scalesWeightLimit} />
          )}
        />
      </FormField>

      <FormField
        label="Ответственный за перевозку"
        htmlFor="contactUser"
        validateMessage={errors.contactUser?.message}
        isRequired
      >
        <Controller
          name="contactUser"
          control={control}
          render={({ field }) => (
            <Input
              id="contactUser"
              placeholder="Введите фамилию и имя"
              {...field}
              status={errors.contactUser && 'error'}
            />
          )}
        />
      </FormField>

      <FormField
        validateMessage={errors.contactPhone?.message}
        label="Телефон ответственного за перевозку "
        htmlFor="contactPhone"
        isRequired
      >
        <Controller
          name="contactPhone"
          control={control}
          render={({ field }) => <PhoneNumberControl {...field} id="contactPhone" hasError={!!errors.contactPhone} />}
        />
      </FormField>
    </form>
  )
}
