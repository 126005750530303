import React, { FC, useEffect } from 'react'

import { Button, Modal } from 'antd'
import { Controller, useForm } from 'react-hook-form'

import { yupResolver } from '@hookform/resolvers/yup'

import { FormField } from '~/ui-components'
import { modalSharedSettings } from '~/utils'
import { PriceControl, VolumeControl } from '~shared/controls'

import { changeOfferModalSchema } from './ChangeOfferModalSchema'

import { ChangeOfferModalProps, FormValues } from './ChangeOfferModal.types'

import * as S from './ChangeOfferModal.styled'

const FORM_ID = 'change-offer-modal'

export const ChangeOfferModal: FC<ChangeOfferModalProps> = ({
  visible,
  onClose,
  onSubmit,
  isFetching,
  defaultValues,
}) => {
  const {
    control,
    handleSubmit,
    formState: { errors, isDirty },
    reset,
  } = useForm<FormValues>({
    defaultValues,
    resolver: yupResolver(changeOfferModalSchema),
  })

  useEffect(() => reset(), [visible])

  const handleFormSubmit = (values: FormValues) => {
    onSubmit(values)
  }

  return (
    <Modal
      {...modalSharedSettings}
      open={visible}
      onCancel={onClose}
      title="Опубликовать повторно"
      footer={[
        <Button key="cancel" htmlType="button" onClick={onClose}>
          Отмена
        </Button>,
        <Button type="primary" key="submit" htmlType="submit" loading={isFetching} disabled={!isDirty} form={FORM_ID}>
          Опубликовать
        </Button>,
        <Button type="primary" key="submit" htmlType="submit" loading={isFetching} form={FORM_ID}>
          Опубликовать без изменений
        </Button>,
      ]}
    >
      <S.Form onSubmit={handleSubmit(handleFormSubmit)} id={FORM_ID}>
        <FormField label="Объем, тонн" validateMessage={errors.volume?.message} isRequired>
          <Controller
            name="volume"
            control={control}
            render={({ field }) => <VolumeControl id="volume" {...field} hasError={!!errors.volume} />}
          />
        </FormField>

        <FormField label="Цена за 1 тонну" validateMessage={errors.price?.message} isRequired>
          <Controller
            name="price"
            control={control}
            render={({ field }) => <PriceControl id="price" {...field} hasError={!!errors.price} />}
          />
        </FormField>
      </S.Form>
    </Modal>
  )
}
