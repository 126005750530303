import { Card } from 'antd'
import styled from 'styled-components'

import { css } from '@styled-system/css'

export const MobileItemsWrapper = styled.div(
  css({
    display: 'grid',
    gridTemplateColumns: '1fr',
    gap: '8px',
  })
)

export const ContentWrapper = styled.div(
  css({
    display: 'grid',
    gridTemplateColumns: '1fr',
    gap: '8px',
    cursor: 'pointer',
  })
)

export const Row = styled.div(
  css({
    display: 'flex',
    flexDirection: 'column',
  })
)

export const ActionWrapper = styled.div(
  css({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  })
)

export const StyledCard = styled(Card)<{ selected?: boolean | null }>`
  background: ${(p) => p.selected && 'linear-gradient(0deg, #fefbeb, #fefbeb), #ffffff;'};
`
