import React from 'react'

import { Badge } from '~/ui-components'
import { getDealStatusColor } from '~/utils/deals/get-deal-status-color'
import { getDealStatusName } from '~/utils/deals/get-deal-status-name'

import { BadgeVariant } from '~ui-components/components/Badge/Badge.types'

interface IDealStatusBadgeProps {
  status?: string
}

export const DealStatusBadge: React.FC<IDealStatusBadgeProps> = ({ status }) => {
  if (!status?.trim()) {
    return <span>-</span>
  }

  return <Badge color={getDealStatusColor(status) as BadgeVariant}>{getDealStatusName(status)}</Badge>
}
