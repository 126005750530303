import React, { useState } from 'react'

import { Button, Collapse, Modal, Space } from 'antd'
import { observer } from 'mobx-react-lite'

import { Badge, Card, Text } from '~/ui-components'
import { appToast, httpErrorHandler } from '~/utils'
import { getElevatorAdvertOfferStatus } from '~/utils/elevator-advert-offers/get-elevator-advert-offer-status'
import { getDateFormat } from '~/utils/getDateFormat'
import { ElevatorAdvertOfferData, ElevatorOfferResponseStatus, GetElevatorAdvertResponse } from '~api/generated'
import { useAcceptAdvertOffer, useRejectAdvertOffer } from '~hooks/elevator-advert-offers'
import { BasisAdvertOfferChangeStatusButton } from '~pages/BasisPage/components/BasisAdvertOfferChangeStatusButton'
import { BasisAdvertOfferRejectReasonModal } from '~pages/BasisPage/components/BasisAdvertOfferRejectReasonModal'
import { MyAdvertOfferAcceptManualStep } from '~pages/BasisPage/modules/MyAdvertOffers/components/MyAdvertOffersList/components/MyAdvertOfferAcceptManualStep'
import { useAuth } from '~providers/auth'
import { PhoneNumber } from '~shared/components'

import { RejectAdvertOfferForm } from '~pages/BasisPage/components/BasisAdvertOfferRejectReasonModal/BasisAdvertOfferRejectReasonModal.types'

import * as S from './MyAdvertOffersListItemMobile.styled'

interface MyAdvertOffersListItemProps {
  advert: GetElevatorAdvertResponse
  offer: ElevatorAdvertOfferData
  onNeedToRefetch: () => void
  showOnlyActions?: boolean
}

export const MyAdvertOffersListItemMobile: React.FC<MyAdvertOffersListItemProps> = observer(
  ({ offer, advert, onNeedToRefetch, showOnlyActions = false }) => {
    const { companyId } = useAuth()

    const [isOpenRejectOfferModal, setOpenRejectOfferModal] = useState(false)
    const acceptAdvertOfferFn = useAcceptAdvertOffer()
    const rejectAdvertOfferFn = useRejectAdvertOffer()

    const handleAcceptOffer = async () => {
      Modal.confirm({
        title: 'Принятие отклика',
        content: (
          <div style={{ marginLeft: '-32px' }}>
            <MyAdvertOfferAcceptManualStep />
          </div>
        ),
        okText: 'Принять отклик',
        cancelText: 'Отмена',
        width: 600,
        async onOk() {
          try {
            await acceptAdvertOfferFn.mutateAsync({
              id: offer.id,
              updateElevatorOfferResponseAcceptBody: { userCompanyId: companyId },
            })
            await onNeedToRefetch()
            appToast.success({ description: 'Отклик принят' })
          } catch (e) {
            httpErrorHandler(e, 'Ошибка при принятии отклика')
          }
        },
      })
    }

    const handleDeclineOffer = async (declineReason: RejectAdvertOfferForm) => {
      try {
        await rejectAdvertOfferFn.mutateAsync({
          id: offer.id,
          updateElevatorAdvertOfferRejectBody: {
            userCompanyId: companyId,
            rejectionReason: declineReason.reason,
            rejectionComment: declineReason.message,
          },
        })
        await onNeedToRefetch()
        appToast.success({ description: 'Отклик отклонен' })
      } catch (e) {
        httpErrorHandler(e, 'Ошибка при отклонении отклика')
      }
    }

    function renderActionButtons() {
      if (offer.status === ElevatorOfferResponseStatus.UnderConsideration) {
        return (
          <S.ActionWrapper>
            <Button block htmlType="button" onClick={() => setOpenRejectOfferModal(true)}>
              Отклонить
            </Button>

            <Button block htmlType="button" type="primary" onClick={handleAcceptOffer}>
              Принять
            </Button>
          </S.ActionWrapper>
        )
      }
    }

    if (showOnlyActions) {
      return (
        <>
          {renderActionButtons()}{' '}
          <BasisAdvertOfferChangeStatusButton
            offerId={offer.id}
            offerStatus={offer.status}
            type="SELL"
            myPurchaseStatus={offer.myPurchaseStatus}
            counterpartyPurchaseStatus={offer.counterpartyPurchaseStatus}
            onAfterStatusChange={() => onNeedToRefetch()}
          />
        </>
      )
    }

    return (
      <Card key={offer.id}>
        <S.CardHeader>
          <S.CardHeaderTop>
            <S.CardHeaderTopLeft>
              <S.TitleOfferNumber>Отклик №{offer.id}</S.TitleOfferNumber>
              <S.PublishedAt>{offer.publishedAt ? getDateFormat(offer.publishedAt) : null}</S.PublishedAt>
            </S.CardHeaderTopLeft>
            {/*<FiArrowRight size={24} />*/}
          </S.CardHeaderTop>
          <S.ProductWrapper>
            <S.ProductRowWrapper>
              <S.PriceWrapper>
                <Text variant="h4">{offer.price} ₽/т</Text>
                <Text variant="normal">{offer.includingNds ? 'с НДС' : 'без НДС'}</Text>
              </S.PriceWrapper>
              <S.Volume>{offer.volume} т</S.Volume>
            </S.ProductRowWrapper>
            <div>
              <Badge color={getElevatorAdvertOfferStatus(offer.status).color} size="s">
                {getElevatorAdvertOfferStatus(offer.status).name}
              </Badge>
            </div>
            <Text variant="normal" style={{ marginTop: 4 }}>
              {offer.company?.name}
            </Text>
          </S.ProductWrapper>

          {renderActionButtons()}

          <BasisAdvertOfferChangeStatusButton
            offerId={offer.id}
            offerStatus={offer.status}
            type="SELL"
            myPurchaseStatus={offer.myPurchaseStatus}
            counterpartyPurchaseStatus={offer.counterpartyPurchaseStatus}
            onAfterStatusChange={() => onNeedToRefetch()}
          />

          {offer.status === 'ACCEPTED' && (
            <Collapse ghost>
              <Collapse.Panel
                header={<b style={{ color: '#45A67D' }}>Данные {advert.type === 'BUY' ? 'продавца' : 'покупателя'}</b>}
                key="1"
              >
                <S.CompanyInfo>
                  <Space direction="vertical">
                    <Card.Label>Компания</Card.Label>
                    <Card.Value>
                      <b>{offer?.contactCompany?.name ?? '-'}</b>
                    </Card.Value>
                  </Space>
                  <Space direction="vertical">
                    <Card.Label>ИНН</Card.Label>
                    <Card.Value>
                      <b>{offer?.contactCompany?.inn ?? '-'}</b>
                    </Card.Value>
                  </Space>
                  <Space direction="vertical">
                    <Card.Label>Контактное лицо</Card.Label>
                    <Card.Value>
                      <b>{offer?.contactPerson?.name}</b>
                    </Card.Value>
                  </Space>
                  <Space direction="vertical">
                    <Card.Label>Телефон</Card.Label>
                    <Card.Value>
                      <PhoneNumber phoneNumber={offer?.contactPerson?.phoneNumber} prefix="" />
                    </Card.Value>
                  </Space>
                </S.CompanyInfo>
              </Collapse.Panel>
            </Collapse>
          )}
        </S.CardHeader>

        <BasisAdvertOfferRejectReasonModal
          isOpen={isOpenRejectOfferModal}
          onReject={handleDeclineOffer}
          onClose={() => setOpenRejectOfferModal(false)}
        />
      </Card>
    )
  }
)
