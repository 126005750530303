/* tslint:disable */
/* eslint-disable */
/**
 * API Зерно-Агрегатора
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { CreationContactPersonData } from './creation-contact-person-data';
import { CreationProductData } from './creation-product-data';

/**
 * 
 * @export
 * @interface CreateOfferBody
 */
export interface CreateOfferBody {
    /**
     * 
     * @type {string}
     * @memberof CreateOfferBody
     */
    'type': CreateOfferBodyTypeEnum;
    /**
     * Объём товара
     * @type {number}
     * @memberof CreateOfferBody
     */
    'volume': number;
    /**
     * 
     * @type {number}
     * @memberof CreateOfferBody
     */
    'price': number;
    /**
     * 
     * @type {boolean}
     * @memberof CreateOfferBody
     */
    'includingNds': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateOfferBody
     */
    'shippingRequired': boolean;
    /**
     * Адрес склада
     * @type {string}
     * @memberof CreateOfferBody
     */
    'address': string;
    /**
     * Товар
     * @type {CreationProductData}
     * @memberof CreateOfferBody
     */
    'product': CreationProductData;
    /**
     * Примечание к предложению
     * @type {string}
     * @memberof CreateOfferBody
     */
    'note': string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateOfferBody
     */
    'publishedAt': string | null;
    /**
     * Контактное лицо
     * @type {CreationContactPersonData}
     * @memberof CreateOfferBody
     */
    'contactPerson'?: CreationContactPersonData;
}

export const CreateOfferBodyTypeEnum = {
    Buy: 'BUY',
    Sell: 'SELL'
} as const;

export type CreateOfferBodyTypeEnum = typeof CreateOfferBodyTypeEnum[keyof typeof CreateOfferBodyTypeEnum];


