import React from 'react'

import { Spin } from 'antd'
import { useParams } from 'react-router'

import { dealDocumentsGroupsTypes } from '~/utils/constants'
import { useGetDealDocumentsGroups } from '~/utils/deals'
import { useGetDealDocuments } from '~hooks/deal-documents'
import { useGetDealById } from '~hooks/deals'
import { useAuth } from '~providers/auth'

import { DealDocumentsGroup } from '../../components'

import * as S from './DealDocuments.styled'

export const DealDocuments = () => {
  const { companyId } = useAuth()
  const { id: dealIdParam } = useParams<{ id: string | undefined }>()

  const dealId = Number(dealIdParam)

  const {
    data: documents,
    refetch: refetchDocuments,
    isLoading: isLoadingDocuments,
  } = useGetDealDocuments({
    dealId,
  })

  const documentsGroups = useGetDealDocumentsGroups(documents)
  const { data: deal } = useGetDealById(dealId)

  const dealSide = deal?.buyer.id === companyId ? 'buyer' : 'seller'

  return (
    <S.Wrapper>
      {/*<div>*/}
      {/*  <Button icon={<FiDownload />} type="link">*/}
      {/*    Скачать все документы архивом*/}
      {/*  </Button>*/}
      {/*</div>*/}

      <Spin spinning={isLoadingDocuments}>
        <S.Content>
          <DealDocumentsGroup
            dealId={dealId}
            title="Документы по сделке"
            documents={documentsGroups.main}
            onRefetch={refetchDocuments}
          />

          {dealSide === 'buyer' && (
            <DealDocumentsGroup
              dealId={dealId}
              title="Документы от агента"
              documents={documentsGroups.agent}
              onRefetch={refetchDocuments}
            />
          )}

          {(dealSide === 'seller' || !!documentsGroups.product.length) && (
            <DealDocumentsGroup
              dealId={dealId}
              title="Документы на товар"
              isAddDocuments={dealSide === 'seller'}
              documents={documentsGroups.product}
              documentsTypes={dealDocumentsGroupsTypes.product}
              onRefetch={refetchDocuments}
            />
          )}

          {(dealSide === 'seller' || !!documentsGroups.transportation.length) && (
            <DealDocumentsGroup
              dealId={dealId}
              title="Документы по перевозке"
              documents={documentsGroups.transportation}
              documentsTypes={dealDocumentsGroupsTypes.transportation}
              isAddDocuments={dealSide === 'seller'}
              onRefetch={refetchDocuments}
            />
          )}
        </S.Content>
      </Spin>
    </S.Wrapper>
  )
}
