import { useQuery, UseQueryResult } from '@tanstack/react-query'

import {
  ElevatorAdvertApiElevatorAdvertControllerGetSimilarElevatorsRequest,
  GetSimilarElevatorsResponse,
} from '~api/generated'
import { useApi } from '~providers/api'

export const useGetSimilarElevators = (
  request: ElevatorAdvertApiElevatorAdvertControllerGetSimilarElevatorsRequest
): UseQueryResult<GetSimilarElevatorsResponse> => {
  const api = useApi()

  const fetchFn = async (): Promise<GetSimilarElevatorsResponse> => {
    const res = await api.elevatorAdvert.elevatorAdvertControllerGetSimilarElevators(request)

    return res.data
  }

  return useQuery(['similarElevators', request], fetchFn, { enabled: !!request.elevatorAdvertId })
}
