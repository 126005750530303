import { Radio } from 'antd'
import styled from 'styled-components'

import { breakpoints } from '~/tokens'

export const StyledRadio = styled(Radio)`
  padding: 16px;
  border-radius: 16px;
  border: 1px solid var(--gray-3-color);

  :hover {
    border: 1px solid var(--primary-main-color);
  }
  .ant-wave-target {
    align-self: baseline;
  }

  .ant-radio-inner {
    inset-block-start: 10px;
    width: 20px;
    height: 20px;
  }
`
