import { CreateDealSpecification } from '~hooks/deals/useCreateDealSpecification'
import { IDealSpecificationForm } from '~pages/ModeratorPage/components/ModeratorDeals/components/DealSpecificationForm'

export function mapFormValueToDealSpecificationBody(
  formValues: IDealSpecificationForm,
  dealId: number
): CreateDealSpecification {
  return {
    dealId,
    createDealSpecificationBody: {
      parameters: formValues.culture?.parameters ?? [],
      volume: formValues.volume,
      price: formValues.price,
      includingNdsForBuyer: formValues.usingNdsForBuyer,
      includingNdsForSeller: formValues.usingNdsForSeller,
      transportationType: formValues.transportationType,
      shipper: formValues.shipper,
      loadingAddress: formValues.loadingAddress?.value ?? '',
      consignee: formValues.consignee,
      unloadingAddress: formValues.unloadingAddress?.value ?? '',
      deliveryDate: (formValues.deliveryDate ?? new Date()).toISOString(),
      paymentMethod: formValues.paymentMethod,
      fileKeys: [],
      commission: 100,
    },
    labAnalysis: formValues.labAnalysis,
  }
}
