import React from 'react'

import { Empty, Skeleton } from 'antd'

import { ModeratorCompaniesListMobileItem } from './ModeratorCompaniesListMobileItem'

import {
  IModeratorCompanyListMobileProps,
  ModeratorCompanyListMobileItemType,
} from './ModeratorCompaniesListMobile.types'

import * as S from './ModeratorCompaniesListMobileItem.styled'

export const ModeratorCompaniesListMobile: React.FC<IModeratorCompanyListMobileProps> = ({
  data,
  loading,
  onItemClick,
}) => {
  const onItemClickHandler = (record: ModeratorCompanyListMobileItemType) => {
    onItemClick(record)
  }

  if (loading) {
    return <Skeleton />
  }

  if (!data.length) {
    return <Empty />
  }

  return (
    <S.MobileItemsWrapper>
      {data.map((item) => (
        <ModeratorCompaniesListMobileItem key={item.id} {...item} onItemClick={onItemClickHandler} />
      ))}
    </S.MobileItemsWrapper>
  )
}
