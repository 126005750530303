import { useMutation } from '@tanstack/react-query'

import { SendSmsCodeBody } from '~/api/generated'
import { useApi } from '~providers/api'

export const useSendSecretCodeMutation = () => {
  const api = useApi()
  return useMutation(['sendSecretCode'], async ({ phoneNumber }: SendSmsCodeBody) => {
    return (
      await api.auth.authControllerSendSmsCode({
        sendSmsCodeBody: {
          phoneNumber,
        },
      })
    ).data
  })
}
