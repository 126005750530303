import React, { useEffect, useState } from 'react'

import { Button, Modal, Spin } from 'antd'

import { appToast, httpErrorHandler } from '~/utils'
import { parseTimeToDate } from '~/utils/getDateFormat'
import { useGetDealLogisticsInfo, useUpdateDealLogisticsLoading } from '~hooks/deal-logistics'
import { LoadingInfoCardForm } from '~pages/Deals/modules/DealLogistics/components/LoadingInfoCard/components/LoadingInfoCardForm'
import { LoadingInfoCardView } from '~pages/Deals/modules/DealLogistics/components/LoadingInfoCard/components/LoadingInfoCardView'
import { LogisticCard } from '~pages/Deals/modules/DealLogistics/components/LogisticCard'

import { DealLoadingInfoCardFormValues } from './components/LoadingInfoCardForm/LoadingInfoCardForm.types'
import { LoadingInfoCardProps } from '~pages/Deals/modules/DealLogistics/components/LoadingInfoCard/LoadingInfoCard.types'

const FORM_ID = 'loading-info-card-form-id'

export const LoadingInfoCard: React.FC<LoadingInfoCardProps> = ({ isEditable, dealId }) => {
  const useGetDealLogisticsInfoFn = useGetDealLogisticsInfo({ dealId })
  const useUpdateDealLogisticsLoadingFn = useUpdateDealLogisticsLoading()
  const [visible, setVisible] = useState(false)
  const [defaultCardValues, setDefaultCardValues] = useState<DealLoadingInfoCardFormValues | null>(null)
  const dealLoadingData = useGetDealLogisticsInfoFn.data?.loading

  useEffect(() => {
    if (dealLoadingData) {
      const { warehouse } = dealLoadingData
      setDefaultCardValues({
        contactUser: dealLoadingData.contactPerson?.name ?? '',
        contactPhone: dealLoadingData.contactPerson?.phoneNumber ?? '',
        closingTime: warehouse.closingTime ? parseTimeToDate(warehouse.closingTime) : null,
        openingTime: warehouse.openingTime ? parseTimeToDate(warehouse.openingTime) : null,
        loadingType: warehouse.roadTransportParameters?.loadingTypes?.length
          ? warehouse.roadTransportParameters?.loadingTypes[0]
          : null,
        hasScales: warehouse.roadTransportParameters?.hasScales ?? undefined,
        scalesWeightLimit: warehouse.roadTransportParameters?.scalesWeightLimitT ?? null,
      })
    } else {
      setDefaultCardValues(null)
    }
  }, [dealLoadingData])

  const handleModalClose = () => setVisible(false)

  const handleModalShow = () => setVisible(true)

  const handleSubmit = async (data: DealLoadingInfoCardFormValues) => {
    try {
      await useUpdateDealLogisticsLoadingFn.mutateAsync({
        dealId,
        updateDealLogisticsInfoBody: {
          warehouse: {
            roadTransportParameters: {
              hasScales: data.hasScales,
              scalesWeightLimitT: data.scalesWeightLimit ?? null,
              loadingTypes: data.loadingType ? [data.loadingType] : [],
            },
            closingTime: data.closingTime?.toISOString() ?? null,
            openingTime: data.openingTime?.toISOString() ?? null,
          },
          contactPerson: {
            name: data.contactUser,
            phoneNumber: data.contactPhone,
          },
        },
      })

      appToast.success({ description: 'Информации о погрузке изменена' })
      await useGetDealLogisticsInfoFn.refetch()
      handleModalClose()
    } catch (e) {
      httpErrorHandler(e, 'Ошибка при изменении информации о погрузке')

      return Promise.reject(e)
    }
  }

  return (
    <>
      <Spin spinning={useGetDealLogisticsInfoFn.isLoading}>
        <LogisticCard
          title="Информация о погрузке"
          description="Данная карточка заполняется продавцом"
          onClickChangeButton={handleModalShow}
          isEditable={isEditable}
        >
          <LoadingInfoCardView
            formValues={defaultCardValues}
            companyName={dealLoadingData?.companyInfo.companyName}
            warehousePhone={dealLoadingData?.companyInfo?.phoneNumber ?? undefined}
            warehouse={dealLoadingData?.warehouse}
          />
        </LogisticCard>
      </Spin>

      <Modal
        closable={true}
        destroyOnClose={true}
        title="Информация о погрузке"
        open={visible}
        centered
        width={500}
        onCancel={handleModalClose}
        footer={[
          <Button key="cancel" onClick={handleModalClose} htmlType="button">
            Отмена
          </Button>,
          <Button
            key="submit"
            form={FORM_ID}
            type="primary"
            htmlType="submit"
            loading={useUpdateDealLogisticsLoadingFn.isLoading}
          >
            Завершить
          </Button>,
        ]}
      >
        <Spin spinning={useUpdateDealLogisticsLoadingFn.isLoading}>
          <LoadingInfoCardForm formId={FORM_ID} defaultValues={defaultCardValues} onSubmit={handleSubmit} />
        </Spin>
      </Modal>
    </>
  )
}
