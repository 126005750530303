import React, { useEffect, useState } from 'react'

import { Button, InputNumber, Modal, Select, Space } from 'antd'
import { BaseSelectRef } from 'rc-select'
import { Controller, useForm } from 'react-hook-form'
import { FiInfo, FiPlus } from 'react-icons/fi'

import { yupResolver } from '@hookform/resolvers/yup/dist/yup'

import { offersFilterStore } from '~/store/filters'
import { userUiSettingsStore } from '~/store/userUiSettingsStore'
import { Text } from '~/ui-components'
import { appToast, httpErrorHandler } from '~/utils'
import { useCreateWarehouse, useGetWarehouses } from '~hooks/warehouses'
import {
  CreateWarehouseFormValue,
  CreateWarehouseModal,
} from '~pages/Warehouses/components/CreateWarehouse/CreateWarehouseModal/CreateWarehouseModal'
import {
  mapWarehouseCultureFormValuesToRequest,
  mapWarehouseInfoFormValuesToCreateRequest,
} from '~pages/Warehouses/utils/mapWarehouseInfoFormValuesToRequest'
import { useAuth } from '~providers/auth'
import { CustomControlInterface } from '~shared/controls/CustomControl.interface'

import { distanceFromWarehouseControlValidation } from '~shared/controls/DistanceFromWarehouseControl/DistanceFromWarehouseControl.validation'

import * as S from './DistanceFromWarehouseControl.styled'

export type DistanceFromWarehouseControlValue = {
  warehouseId?: number
  distance?: number
}

interface DistanceFromWarehouseControlProps extends CustomControlInterface {
  onChange: (value: DistanceFromWarehouseControlValue) => void
  value: DistanceFromWarehouseControlValue
  isColumnView?: boolean
  hasError?: boolean
  id?: string
  placeholder?: string
}

const defaultControlValue: DistanceFromWarehouseControlValue = {
  warehouseId: undefined,
  distance: undefined,
}
export const DistanceFromWarehouseControl: React.FC<DistanceFromWarehouseControlProps> = React.forwardRef<
  BaseSelectRef,
  DistanceFromWarehouseControlProps
>(({ id, isColumnView = false, value, onChange, hasError, placeholder = 'Выберите склад' }, ref) => {
  const { companyId, isDemoUser } = useAuth()
  const [isCreateWarehouseModalOpen, setIsCreateWarehouseModalOpen] = useState(false)
  const warehousesFn = useGetWarehouses(companyId)
  const createWarehouseFn = useCreateWarehouse()

  const {
    control,
    watch,
    formState: { errors },
    trigger,
    setValue,
  } = useForm<DistanceFromWarehouseControlValue>({
    defaultValues: {
      warehouseId: value.warehouseId ?? defaultControlValue.warehouseId,
      distance: value.distance ?? defaultControlValue.distance,
    },
    resolver: yupResolver(distanceFromWarehouseControlValidation),
  })
  const selectedWarehouseId = watch('warehouseId')
  const selectedDistance = watch('distance')

  useEffect(() => {
    console.log(selectedWarehouseId)
    if (selectedWarehouseId === -1) {
      if (!companyId) {
        appToast.info({ description: 'Сначала нужно заполнить данные о своей компании' })
        setValue('warehouseId', undefined)
        return
      }

      if (isDemoUser) {
        userUiSettingsStore.showDemoUserAuthModal()
        return
      }
      return setIsCreateWarehouseModalOpen(true)
    }
    if (!selectedWarehouseId) {
      setValue('warehouseId', undefined)
      setValue('distance', undefined)
      if (offersFilterStore.orderBy === 'distance') {
        offersFilterStore.setOrderBy(undefined)
        offersFilterStore.setOrder(undefined)
      }
      onChange(defaultControlValue)
      return
    }
    setValue('distance', undefined)
    onChange({ warehouseId: selectedWarehouseId, distance: selectedDistance })
  }, [selectedWarehouseId])

  // set default control value
  useEffect(() => {
    setValue('warehouseId', value.warehouseId)
    setValue('distance', value.distance)
  }, [value.warehouseId, value.distance])

  // emit event
  useEffect(() => {
    if (selectedWarehouseId) {
      onChange({ warehouseId: selectedWarehouseId, distance: selectedDistance ?? undefined })
    }
    void trigger()
  }, [selectedDistance])

  const handleShowSuggestion = async () => {
    Modal.info({
      title: 'Расчет расстояния',
      content: (
        <Space direction="vertical" size={16}>
          <Text variant="normal-bold">
            Если у вас уже есть склад, выберите его из списка и укажите радиус (+км) – это дополнительное расстояние, в
            пределах которого вы хотите искать предложения.
          </Text>
          <Text variant="normal">
            Если же у вас еще нет склада, нажмите на кнопку «Создать склад» и укажите адрес. Мы добавим его в раздел
            «Склады».
          </Text>
        </Space>
      ),
      okText: 'Понятно',
    })
  }

  const createWarehouseHandler = async (formValue: CreateWarehouseFormValue) => {
    if (companyId === null) return
    try {
      const createdWarehouseId = await createWarehouseFn.mutateAsync({
        companyId,
        info: mapWarehouseInfoFormValuesToCreateRequest(formValue),
        culture: mapWarehouseCultureFormValuesToRequest(formValue.step4Culture),
      })
      await warehousesFn.refetch()
      appToast.success({ description: 'Склад добавлен' })
      setIsCreateWarehouseModalOpen(false)
      if (createdWarehouseId) {
        setValue('warehouseId', createdWarehouseId)
        setValue('distance', undefined)
      }
      console.log({ createdWarehouseId })
    } catch (e) {
      httpErrorHandler(e, 'Ошибка при добавлении склада')
    }
  }

  return (
    <S.Wrapper>
      <Space hidden={isColumnView}>
        <Button
          size="small"
          type="text"
          icon={<FiInfo color="var(--primary-main-color)" size="24" />}
          onClick={handleShowSuggestion}
        />
        <S.Title>Расчет расстояния:</S.Title>
      </Space>

      <Controller
        name="warehouseId"
        control={control}
        render={({ field }) => (
          <Select
            {...field}
            ref={ref}
            id={id}
            allowClear
            labelRender={(x) => {
              const foundWarehouse = warehousesFn.data?.find((w) => w.id === x.value)
              return foundWarehouse?.address ?? x.label
            }}
            // onClear={onClearHandler}
            className="warehouseId-select"
            placeholder={placeholder}
            loading={warehousesFn.isFetching}
            status={hasError ? 'error' : undefined}
            notFoundContent="Созданные склады не найдены..."
          >
            {warehousesFn.data?.map((warehouse) => (
              <Select.Option key={warehouse.id} value={warehouse.id}>
                {warehouse.name ? (
                  <Space direction="vertical" size={2}>
                    <b>{warehouse.name}</b> <S.WarehouseAddress>{warehouse.address}</S.WarehouseAddress>
                  </Space>
                ) : (
                  warehouse.address
                )}
              </Select.Option>
            ))}
            <Select.Option value={-1}>
              <Space style={{ color: 'var(--primary-main-color)' }}>
                <FiPlus />
                Добавить новый склад
              </Space>
            </Select.Option>
          </Select>
        )}
      />

      <Controller
        name="distance"
        control={control}
        render={({ field }) => (
          <InputNumber
            {...field}
            addonAfter="км"
            disabled={!selectedWarehouseId}
            min={0}
            max={9999}
            style={{ minWidth: 120, maxWidth: 120 }}
            placeholder="+км"
            status={errors.distance ? 'error' : undefined}
          />
        )}
      />

      <CreateWarehouseModal
        open={isCreateWarehouseModalOpen}
        onCancel={() => setIsCreateWarehouseModalOpen(false)}
        onSubmitOnCreate={createWarehouseHandler}
        lastStepSubmitLoading={createWarehouseFn.isLoading}
      />
    </S.Wrapper>
  )
})

DistanceFromWarehouseControl.displayName = 'DistanceFromWarehouseControl'
