import { useEffect, useState } from 'react'

import throttle from 'lodash/throttle'

export const useGetTableHeight = (height = 296) => {
  // TODO: Подумать как считать высоту
  const [tableHeight, setTableHeight] = useState(0)

  useEffect(() => {
    const handleWindowResize = throttle(() => {
      setTableHeight(Math.max(window.innerHeight - height, 180))
    }, 300)
    handleWindowResize()
    window.addEventListener('resize', handleWindowResize)

    return () => window.removeEventListener('resize', handleWindowResize)
  }, [])

  return tableHeight
}
