/* tslint:disable */
/* eslint-disable */
/**
 * API Зерно-Агрегатора
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface UpdateDealStatusBody
 */
export interface UpdateDealStatusBody {
    /**
     * Новый статус сделки
     * @type {string}
     * @memberof UpdateDealStatusBody
     */
    'status': UpdateDealStatusBodyStatusEnum;
}

export const UpdateDealStatusBodyStatusEnum = {
    OnModeration: 'ON_MODERATION',
    RejectedAfterModeration: 'REJECTED_AFTER_MODERATION',
    Agreement: 'AGREEMENT',
    Signing: 'SIGNING',
    Payment: 'PAYMENT',
    Shipment: 'SHIPMENT',
    Rejected: 'REJECTED',
    Realised: 'REALISED'
} as const;

export type UpdateDealStatusBodyStatusEnum = typeof UpdateDealStatusBodyStatusEnum[keyof typeof UpdateDealStatusBodyStatusEnum];


