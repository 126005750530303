import React, { useEffect, useState } from 'react'

import { Select } from 'antd'

import { FileData } from '~api/generated'
import {
  getDocInstanceFileByIndex,
  getDocumentInstancesOptions,
  getDocumentInstanceTotalFilesCount,
  getFirstDocInstanceId,
} from '~shared/components/DocumentViewer/utils'

import { IDocumentViewerProps } from './DocumentViewer.types'

import * as S from './DocumentViewer.styled'

const NO_DOC_MESSAGE = 'Файл отсутствует'

export const DocumentViewer: React.FC<IDocumentViewerProps> = (props) => {
  const docInstanceOptions = getDocumentInstancesOptions(props.docInstances)
  const [file, setFile] = useState<FileData | null>(null)
  const [docInstanceId, setDocInstanceId] = useState<number | null>(
    getFirstDocInstanceId(props.docInstances, props.startCheckFromDocInstanceId)
  )
  const [docInstanceFileIndex, setDocInstanceFileIndex] = useState<number>(0)

  useEffect(() => setDocInstanceFileIndex(0), [docInstanceId])

  useEffect(() => {
    const foundFile = getDocInstanceFileByIndex(props.docInstances, docInstanceId, docInstanceFileIndex)
    setFile(foundFile)
    props.onFileView?.(foundFile)
  }, [docInstanceFileIndex, docInstanceId])
  const totalFilesCount = getDocumentInstanceTotalFilesCount(props.docInstances, docInstanceId)

  if (!props.docInstances) {
    return <h2>{NO_DOC_MESSAGE}</h2>
  }

  const handleDocumentInstanceChange = (docInstanceId: number | null) => {
    setDocInstanceId(docInstanceId)
  }

  if (!file) {
    return <h2>{NO_DOC_MESSAGE}</h2>
  }

  const isPdfFile = file.mimetype === 'application/pdf'

  return (
    <S.Content>
      <S.DocumentInfo>
        {docInstanceOptions.length > 1 && (
          <div>
            Документ:&thinsp;
            <Select
              style={{ width: 200 }}
              value={docInstanceId}
              options={docInstanceOptions}
              onSelect={handleDocumentInstanceChange}
            />
          </div>
        )}
      </S.DocumentInfo>

      {isPdfFile ? (
        <S.IFrame src={file.source} title={file.name} />
      ) : (
        <S.StyledImage src={file.source} alt={file.name} />
      )}

      {totalFilesCount > 1 && (
        <S.StyledPagination
          current={docInstanceFileIndex + 1}
          total={totalFilesCount}
          pageSize={1}
          showSizeChanger={false}
          onChange={(page) => setDocInstanceFileIndex(page - 1)}
          simple
        />
      )}
    </S.Content>
  )
}
