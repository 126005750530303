import { Link } from 'react-router-dom'
import styled from 'styled-components'

import css from '@styled-system/css'

import { breakpoints } from '~/tokens'
import { Box } from '~/ui-components'

export const MenuWrapper = styled.section`
  display: flex;
  flex-direction: column;
  width: 250px;
  height: 100vh;
  box-sizing: border-box;
  background-color: white;
  border-right: 1px solid var(--gray-2-color);

  @media (max-width: ${breakpoints.DESKTOP_L}px) {
    width: 70px;
  }

  @media (max-width: ${breakpoints.MOBILE_XL}px) {
    display: none;
  }
`

export const NavWrapper = styled('nav')(
  css({
    overflowY: 'auto',
    overflowX: 'hidden',
  })
)

export const LogoWrapper = styled(Box)`
  display: block;
  padding: 16px 0 16px 24px;

  @media (max-width: ${breakpoints.DESKTOP_L}px) {
    display: none;
  }
`

export const LogoWrapperIpad = styled(Box)`
  display: none;

  @media (max-width: ${breakpoints.DESKTOP_L}px) {
    display: block;
    padding: 10px 18px;
  }
`

export const MenuBottomAction = styled.div`
  margin-top: auto;
  padding: 32px 24px;
  display: flex;
  flex-direction: column;

  @media (max-width: ${breakpoints.DESKTOP_L}px) {
    padding: 32px 15px;
  }

  @media (max-width: ${breakpoints.MOBILE_XL}px) {
    display: none;
  }
`

export const LogoLink = styled(Link)(
  css({
    display: 'block',
  })
)

export const LogoImg = styled.img`
  display: block;
  height: 32px;
`
