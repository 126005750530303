import React from 'react'

import { RouteObject } from 'react-router-dom'

import { CarParkPage } from '~/pages'
import { appRoutes } from '~/router'
import { Transport, TransportationData } from '~pages/CarParkPage/modules'
import { CreateDriver } from '~pages/CarParkPage/modules/CreateDriver/CreateDriver'
import { CreateTransport } from '~pages/CarParkPage/modules/CreateTransport'
import { CreateTransportationData } from '~pages/CarParkPage/modules/CreateTransportationData'
import { DriverInfo } from '~pages/CarParkPage/modules/DriverInfo/DriverInfo'
import { Drivers } from '~pages/CarParkPage/modules/Drivers/Drivers'
import { EditTransport } from '~pages/CarParkPage/modules/EditTransport'
import { EditTransportationData } from '~pages/CarParkPage/modules/EditTransportationData'
import { TransportCard } from '~pages/CarParkPage/modules/TransportCard/TransportCard'

import { RequireAuth } from '../../providers/auth'

export const carParkRouter: RouteObject[] = [
  {
    path: appRoutes.carPark.url,
    element: (
      <RequireAuth>
        <CarParkPage />
      </RequireAuth>
    ),
    children: [
      {
        path: appRoutes.carParkTransportationData.url,
        element: <TransportationData />,
      },
      {
        path: appRoutes.carParkTransport.url,
        element: <Transport />,
      },
      {
        path: appRoutes.carParkDrivers.url,
        element: <Drivers />,
      },
    ],
  },
  {
    path: appRoutes.carParkCreateDriver.url,
    element: (
      <RequireAuth>
        <CreateDriver />
      </RequireAuth>
    ),
  },
  {
    path: appRoutes.carParkEditDriver.url,
    element: (
      <RequireAuth>
        <CreateDriver />
      </RequireAuth>
    ),
  },
  {
    path: appRoutes.carParkCreateTransportationData.url,
    element: (
      <RequireAuth>
        <CreateTransportationData />
      </RequireAuth>
    ),
  },
  {
    path: appRoutes.carParkEditTransportationData.url,
    element: (
      <RequireAuth>
        <EditTransportationData />
      </RequireAuth>
    ),
  },
  {
    path: appRoutes.carParkCreateTransport.url,
    element: (
      <RequireAuth>
        <CreateTransport />
      </RequireAuth>
    ),
  },
  {
    path: appRoutes.carParkEditTransport.url,
    element: (
      <RequireAuth>
        <EditTransport />
      </RequireAuth>
    ),
  },
  {
    path: appRoutes.carParkTransportCard.url,
    element: (
      <RequireAuth>
        <TransportCard />
      </RequireAuth>
    ),
  },
  {
    path: appRoutes.carParkDriver.url,
    element: (
      <RequireAuth>
        <DriverInfo />
      </RequireAuth>
    ),
  },
]
