/* tslint:disable */
/* eslint-disable */
/**
 * API Зерно-Агрегатора
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CreateAuctionBody } from '../models';
// @ts-ignore
import { CreateAuctionOfferBody } from '../models';
// @ts-ignore
import { CreateAuctionOfferResponse } from '../models';
// @ts-ignore
import { CreateAuctionResponse } from '../models';
// @ts-ignore
import { GetAuctionOffersResponse } from '../models';
// @ts-ignore
import { GetAuctionsResponse } from '../models';
// @ts-ignore
import { GetCompanyAuctionOffersResponse } from '../models';
// @ts-ignore
import { GetCompanyAuctionsResponse } from '../models';
// @ts-ignore
import { GetModeratedAuctionsResponse } from '../models';
// @ts-ignore
import { OrderDirection } from '../models';
// @ts-ignore
import { UpdateAuctionModeratorBody } from '../models';
// @ts-ignore
import { UpdateAuctionOfferStatusBody } from '../models';
// @ts-ignore
import { UpdateAuctionStatusBody } from '../models';
/**
 * AuctionApi - axios parameter creator
 * @export
 */
export const AuctionApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} companyId 
         * @param {CreateAuctionBody} createAuctionBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        auctionControllerCreateAuction: async (companyId: number, createAuctionBody: CreateAuctionBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('auctionControllerCreateAuction', 'companyId', companyId)
            // verify required parameter 'createAuctionBody' is not null or undefined
            assertParamExists('auctionControllerCreateAuction', 'createAuctionBody', createAuctionBody)
            const localVarPath = `/company/{companyId}/auction`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createAuctionBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} auctionId 
         * @param {CreateAuctionOfferBody} createAuctionOfferBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        auctionControllerCreateAuctionOffer: async (auctionId: number, createAuctionOfferBody: CreateAuctionOfferBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'auctionId' is not null or undefined
            assertParamExists('auctionControllerCreateAuctionOffer', 'auctionId', auctionId)
            // verify required parameter 'createAuctionOfferBody' is not null or undefined
            assertParamExists('auctionControllerCreateAuctionOffer', 'createAuctionOfferBody', createAuctionOfferBody)
            const localVarPath = `/auction/{auctionId}/offer`
                .replace(`{${"auctionId"}}`, encodeURIComponent(String(auctionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createAuctionOfferBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} auctionId 
         * @param {number} [currentPage] Номер текущей страницы
         * @param {number} [pageSize] Количество элементов на странице
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        auctionControllerGetAuctionOffers: async (auctionId: number, currentPage?: number, pageSize?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'auctionId' is not null or undefined
            assertParamExists('auctionControllerGetAuctionOffers', 'auctionId', auctionId)
            const localVarPath = `/auction/{auctionId}/offers`
                .replace(`{${"auctionId"}}`, encodeURIComponent(String(auctionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (currentPage !== undefined) {
                localVarQueryParameter['current-page'] = currentPage;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page-size'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [currentPage] Номер текущей страницы
         * @param {number} [pageSize] Количество элементов на странице
         * @param {Array<number>} [auctionIds] Массив с id торгов
         * @param {Array<string>} [regionIds] Список id регионов
         * @param {Array<string>} [productTypes] Список типов товаров
         * @param {'BUY' | 'SELL'} [type] Тип предложения
         * @param {number} [minVolume] Минимальный объем
         * @param {number} [maxVolume] Максимальный объем
         * @param {number} [minPrice] Минимальная цена
         * @param {number} [maxPrice] Максимальная цена
         * @param {'id' | 'offerId' | 'selectedVolume' | 'status' | 'moderatedBy' | 'startedAt' | 'endedAt' | 'createdAt' | 'updatedAt'} [orderBy] Поле для сортировки
         * @param {OrderDirection} [order] Направление сортировки
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        auctionControllerGetAuctions: async (currentPage?: number, pageSize?: number, auctionIds?: Array<number>, regionIds?: Array<string>, productTypes?: Array<string>, type?: 'BUY' | 'SELL', minVolume?: number, maxVolume?: number, minPrice?: number, maxPrice?: number, orderBy?: 'id' | 'offerId' | 'selectedVolume' | 'status' | 'moderatedBy' | 'startedAt' | 'endedAt' | 'createdAt' | 'updatedAt', order?: OrderDirection, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/auctions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (currentPage !== undefined) {
                localVarQueryParameter['current-page'] = currentPage;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page-size'] = pageSize;
            }

            if (auctionIds) {
                localVarQueryParameter['auction-ids'] = auctionIds;
            }

            if (regionIds) {
                localVarQueryParameter['region-ids'] = regionIds;
            }

            if (productTypes) {
                localVarQueryParameter['product-types'] = productTypes;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            if (minVolume !== undefined) {
                localVarQueryParameter['min-volume'] = minVolume;
            }

            if (maxVolume !== undefined) {
                localVarQueryParameter['max-volume'] = maxVolume;
            }

            if (minPrice !== undefined) {
                localVarQueryParameter['min-price'] = minPrice;
            }

            if (maxPrice !== undefined) {
                localVarQueryParameter['max-price'] = maxPrice;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['order-by'] = orderBy;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} companyId 
         * @param {number} [currentPage] Номер текущей страницы
         * @param {number} [pageSize] Количество элементов на странице
         * @param {Array<number>} [offerIds] Массив с id предложений к торгам
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        auctionControllerGetCompanyAuctionOffers: async (companyId: number, currentPage?: number, pageSize?: number, offerIds?: Array<number>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('auctionControllerGetCompanyAuctionOffers', 'companyId', companyId)
            const localVarPath = `/company/{companyId}/auction-offers`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (currentPage !== undefined) {
                localVarQueryParameter['current-page'] = currentPage;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page-size'] = pageSize;
            }

            if (offerIds) {
                localVarQueryParameter['offer-ids'] = offerIds;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} companyId 
         * @param {number} [currentPage] Номер текущей страницы
         * @param {number} [pageSize] Количество элементов на странице
         * @param {Array<number>} [auctionIds] Массив с id торгов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        auctionControllerGetCompanyAuctions: async (companyId: number, currentPage?: number, pageSize?: number, auctionIds?: Array<number>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('auctionControllerGetCompanyAuctions', 'companyId', companyId)
            const localVarPath = `/company/{companyId}/auctions`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (currentPage !== undefined) {
                localVarQueryParameter['current-page'] = currentPage;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page-size'] = pageSize;
            }

            if (auctionIds) {
                localVarQueryParameter['auction-ids'] = auctionIds;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [currentPage] Номер текущей страницы
         * @param {number} [pageSize] Количество элементов на странице
         * @param {Array<number>} [auctionIds] Массив с id торгов
         * @param {Array<string>} [regionIds] Список id регионов
         * @param {Array<string>} [productTypes] Список типов товаров
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        auctionControllerGetModeratedAuctions: async (currentPage?: number, pageSize?: number, auctionIds?: Array<number>, regionIds?: Array<string>, productTypes?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/auctions/moderated`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (currentPage !== undefined) {
                localVarQueryParameter['current-page'] = currentPage;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page-size'] = pageSize;
            }

            if (auctionIds) {
                localVarQueryParameter['auction-ids'] = auctionIds;
            }

            if (regionIds) {
                localVarQueryParameter['region-ids'] = regionIds;
            }

            if (productTypes) {
                localVarQueryParameter['product-types'] = productTypes;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} auctionId 
         * @param {UpdateAuctionModeratorBody} updateAuctionModeratorBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        auctionControllerUpdateAuctionModerator: async (auctionId: number, updateAuctionModeratorBody: UpdateAuctionModeratorBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'auctionId' is not null or undefined
            assertParamExists('auctionControllerUpdateAuctionModerator', 'auctionId', auctionId)
            // verify required parameter 'updateAuctionModeratorBody' is not null or undefined
            assertParamExists('auctionControllerUpdateAuctionModerator', 'updateAuctionModeratorBody', updateAuctionModeratorBody)
            const localVarPath = `/auction/{auctionId}/moderator`
                .replace(`{${"auctionId"}}`, encodeURIComponent(String(auctionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateAuctionModeratorBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} auctionOfferId 
         * @param {UpdateAuctionOfferStatusBody} updateAuctionOfferStatusBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        auctionControllerUpdateAuctionOfferStatus: async (auctionOfferId: number, updateAuctionOfferStatusBody: UpdateAuctionOfferStatusBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'auctionOfferId' is not null or undefined
            assertParamExists('auctionControllerUpdateAuctionOfferStatus', 'auctionOfferId', auctionOfferId)
            // verify required parameter 'updateAuctionOfferStatusBody' is not null or undefined
            assertParamExists('auctionControllerUpdateAuctionOfferStatus', 'updateAuctionOfferStatusBody', updateAuctionOfferStatusBody)
            const localVarPath = `/auction-offer/{auctionOfferId}/status`
                .replace(`{${"auctionOfferId"}}`, encodeURIComponent(String(auctionOfferId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateAuctionOfferStatusBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} auctionId 
         * @param {UpdateAuctionStatusBody} updateAuctionStatusBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        auctionControllerUpdateAuctionStatus: async (auctionId: number, updateAuctionStatusBody: UpdateAuctionStatusBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'auctionId' is not null or undefined
            assertParamExists('auctionControllerUpdateAuctionStatus', 'auctionId', auctionId)
            // verify required parameter 'updateAuctionStatusBody' is not null or undefined
            assertParamExists('auctionControllerUpdateAuctionStatus', 'updateAuctionStatusBody', updateAuctionStatusBody)
            const localVarPath = `/auction/{auctionId}/status`
                .replace(`{${"auctionId"}}`, encodeURIComponent(String(auctionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateAuctionStatusBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuctionApi - functional programming interface
 * @export
 */
export const AuctionApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AuctionApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} companyId 
         * @param {CreateAuctionBody} createAuctionBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async auctionControllerCreateAuction(companyId: number, createAuctionBody: CreateAuctionBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateAuctionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.auctionControllerCreateAuction(companyId, createAuctionBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} auctionId 
         * @param {CreateAuctionOfferBody} createAuctionOfferBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async auctionControllerCreateAuctionOffer(auctionId: number, createAuctionOfferBody: CreateAuctionOfferBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateAuctionOfferResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.auctionControllerCreateAuctionOffer(auctionId, createAuctionOfferBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} auctionId 
         * @param {number} [currentPage] Номер текущей страницы
         * @param {number} [pageSize] Количество элементов на странице
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async auctionControllerGetAuctionOffers(auctionId: number, currentPage?: number, pageSize?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAuctionOffersResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.auctionControllerGetAuctionOffers(auctionId, currentPage, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [currentPage] Номер текущей страницы
         * @param {number} [pageSize] Количество элементов на странице
         * @param {Array<number>} [auctionIds] Массив с id торгов
         * @param {Array<string>} [regionIds] Список id регионов
         * @param {Array<string>} [productTypes] Список типов товаров
         * @param {'BUY' | 'SELL'} [type] Тип предложения
         * @param {number} [minVolume] Минимальный объем
         * @param {number} [maxVolume] Максимальный объем
         * @param {number} [minPrice] Минимальная цена
         * @param {number} [maxPrice] Максимальная цена
         * @param {'id' | 'offerId' | 'selectedVolume' | 'status' | 'moderatedBy' | 'startedAt' | 'endedAt' | 'createdAt' | 'updatedAt'} [orderBy] Поле для сортировки
         * @param {OrderDirection} [order] Направление сортировки
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async auctionControllerGetAuctions(currentPage?: number, pageSize?: number, auctionIds?: Array<number>, regionIds?: Array<string>, productTypes?: Array<string>, type?: 'BUY' | 'SELL', minVolume?: number, maxVolume?: number, minPrice?: number, maxPrice?: number, orderBy?: 'id' | 'offerId' | 'selectedVolume' | 'status' | 'moderatedBy' | 'startedAt' | 'endedAt' | 'createdAt' | 'updatedAt', order?: OrderDirection, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAuctionsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.auctionControllerGetAuctions(currentPage, pageSize, auctionIds, regionIds, productTypes, type, minVolume, maxVolume, minPrice, maxPrice, orderBy, order, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} companyId 
         * @param {number} [currentPage] Номер текущей страницы
         * @param {number} [pageSize] Количество элементов на странице
         * @param {Array<number>} [offerIds] Массив с id предложений к торгам
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async auctionControllerGetCompanyAuctionOffers(companyId: number, currentPage?: number, pageSize?: number, offerIds?: Array<number>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCompanyAuctionOffersResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.auctionControllerGetCompanyAuctionOffers(companyId, currentPage, pageSize, offerIds, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} companyId 
         * @param {number} [currentPage] Номер текущей страницы
         * @param {number} [pageSize] Количество элементов на странице
         * @param {Array<number>} [auctionIds] Массив с id торгов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async auctionControllerGetCompanyAuctions(companyId: number, currentPage?: number, pageSize?: number, auctionIds?: Array<number>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCompanyAuctionsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.auctionControllerGetCompanyAuctions(companyId, currentPage, pageSize, auctionIds, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [currentPage] Номер текущей страницы
         * @param {number} [pageSize] Количество элементов на странице
         * @param {Array<number>} [auctionIds] Массив с id торгов
         * @param {Array<string>} [regionIds] Список id регионов
         * @param {Array<string>} [productTypes] Список типов товаров
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async auctionControllerGetModeratedAuctions(currentPage?: number, pageSize?: number, auctionIds?: Array<number>, regionIds?: Array<string>, productTypes?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetModeratedAuctionsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.auctionControllerGetModeratedAuctions(currentPage, pageSize, auctionIds, regionIds, productTypes, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} auctionId 
         * @param {UpdateAuctionModeratorBody} updateAuctionModeratorBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async auctionControllerUpdateAuctionModerator(auctionId: number, updateAuctionModeratorBody: UpdateAuctionModeratorBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.auctionControllerUpdateAuctionModerator(auctionId, updateAuctionModeratorBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} auctionOfferId 
         * @param {UpdateAuctionOfferStatusBody} updateAuctionOfferStatusBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async auctionControllerUpdateAuctionOfferStatus(auctionOfferId: number, updateAuctionOfferStatusBody: UpdateAuctionOfferStatusBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.auctionControllerUpdateAuctionOfferStatus(auctionOfferId, updateAuctionOfferStatusBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} auctionId 
         * @param {UpdateAuctionStatusBody} updateAuctionStatusBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async auctionControllerUpdateAuctionStatus(auctionId: number, updateAuctionStatusBody: UpdateAuctionStatusBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.auctionControllerUpdateAuctionStatus(auctionId, updateAuctionStatusBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AuctionApi - factory interface
 * @export
 */
export const AuctionApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AuctionApiFp(configuration)
    return {
        /**
         * 
         * @param {number} companyId 
         * @param {CreateAuctionBody} createAuctionBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        auctionControllerCreateAuction(companyId: number, createAuctionBody: CreateAuctionBody, options?: any): AxiosPromise<CreateAuctionResponse> {
            return localVarFp.auctionControllerCreateAuction(companyId, createAuctionBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} auctionId 
         * @param {CreateAuctionOfferBody} createAuctionOfferBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        auctionControllerCreateAuctionOffer(auctionId: number, createAuctionOfferBody: CreateAuctionOfferBody, options?: any): AxiosPromise<CreateAuctionOfferResponse> {
            return localVarFp.auctionControllerCreateAuctionOffer(auctionId, createAuctionOfferBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} auctionId 
         * @param {number} [currentPage] Номер текущей страницы
         * @param {number} [pageSize] Количество элементов на странице
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        auctionControllerGetAuctionOffers(auctionId: number, currentPage?: number, pageSize?: number, options?: any): AxiosPromise<GetAuctionOffersResponse> {
            return localVarFp.auctionControllerGetAuctionOffers(auctionId, currentPage, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [currentPage] Номер текущей страницы
         * @param {number} [pageSize] Количество элементов на странице
         * @param {Array<number>} [auctionIds] Массив с id торгов
         * @param {Array<string>} [regionIds] Список id регионов
         * @param {Array<string>} [productTypes] Список типов товаров
         * @param {'BUY' | 'SELL'} [type] Тип предложения
         * @param {number} [minVolume] Минимальный объем
         * @param {number} [maxVolume] Максимальный объем
         * @param {number} [minPrice] Минимальная цена
         * @param {number} [maxPrice] Максимальная цена
         * @param {'id' | 'offerId' | 'selectedVolume' | 'status' | 'moderatedBy' | 'startedAt' | 'endedAt' | 'createdAt' | 'updatedAt'} [orderBy] Поле для сортировки
         * @param {OrderDirection} [order] Направление сортировки
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        auctionControllerGetAuctions(currentPage?: number, pageSize?: number, auctionIds?: Array<number>, regionIds?: Array<string>, productTypes?: Array<string>, type?: 'BUY' | 'SELL', minVolume?: number, maxVolume?: number, minPrice?: number, maxPrice?: number, orderBy?: 'id' | 'offerId' | 'selectedVolume' | 'status' | 'moderatedBy' | 'startedAt' | 'endedAt' | 'createdAt' | 'updatedAt', order?: OrderDirection, options?: any): AxiosPromise<GetAuctionsResponse> {
            return localVarFp.auctionControllerGetAuctions(currentPage, pageSize, auctionIds, regionIds, productTypes, type, minVolume, maxVolume, minPrice, maxPrice, orderBy, order, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} companyId 
         * @param {number} [currentPage] Номер текущей страницы
         * @param {number} [pageSize] Количество элементов на странице
         * @param {Array<number>} [offerIds] Массив с id предложений к торгам
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        auctionControllerGetCompanyAuctionOffers(companyId: number, currentPage?: number, pageSize?: number, offerIds?: Array<number>, options?: any): AxiosPromise<GetCompanyAuctionOffersResponse> {
            return localVarFp.auctionControllerGetCompanyAuctionOffers(companyId, currentPage, pageSize, offerIds, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} companyId 
         * @param {number} [currentPage] Номер текущей страницы
         * @param {number} [pageSize] Количество элементов на странице
         * @param {Array<number>} [auctionIds] Массив с id торгов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        auctionControllerGetCompanyAuctions(companyId: number, currentPage?: number, pageSize?: number, auctionIds?: Array<number>, options?: any): AxiosPromise<GetCompanyAuctionsResponse> {
            return localVarFp.auctionControllerGetCompanyAuctions(companyId, currentPage, pageSize, auctionIds, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [currentPage] Номер текущей страницы
         * @param {number} [pageSize] Количество элементов на странице
         * @param {Array<number>} [auctionIds] Массив с id торгов
         * @param {Array<string>} [regionIds] Список id регионов
         * @param {Array<string>} [productTypes] Список типов товаров
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        auctionControllerGetModeratedAuctions(currentPage?: number, pageSize?: number, auctionIds?: Array<number>, regionIds?: Array<string>, productTypes?: Array<string>, options?: any): AxiosPromise<GetModeratedAuctionsResponse> {
            return localVarFp.auctionControllerGetModeratedAuctions(currentPage, pageSize, auctionIds, regionIds, productTypes, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} auctionId 
         * @param {UpdateAuctionModeratorBody} updateAuctionModeratorBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        auctionControllerUpdateAuctionModerator(auctionId: number, updateAuctionModeratorBody: UpdateAuctionModeratorBody, options?: any): AxiosPromise<void> {
            return localVarFp.auctionControllerUpdateAuctionModerator(auctionId, updateAuctionModeratorBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} auctionOfferId 
         * @param {UpdateAuctionOfferStatusBody} updateAuctionOfferStatusBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        auctionControllerUpdateAuctionOfferStatus(auctionOfferId: number, updateAuctionOfferStatusBody: UpdateAuctionOfferStatusBody, options?: any): AxiosPromise<void> {
            return localVarFp.auctionControllerUpdateAuctionOfferStatus(auctionOfferId, updateAuctionOfferStatusBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} auctionId 
         * @param {UpdateAuctionStatusBody} updateAuctionStatusBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        auctionControllerUpdateAuctionStatus(auctionId: number, updateAuctionStatusBody: UpdateAuctionStatusBody, options?: any): AxiosPromise<void> {
            return localVarFp.auctionControllerUpdateAuctionStatus(auctionId, updateAuctionStatusBody, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for auctionControllerCreateAuction operation in AuctionApi.
 * @export
 * @interface AuctionApiAuctionControllerCreateAuctionRequest
 */
export interface AuctionApiAuctionControllerCreateAuctionRequest {
    /**
     * 
     * @type {number}
     * @memberof AuctionApiAuctionControllerCreateAuction
     */
    readonly companyId: number

    /**
     * 
     * @type {CreateAuctionBody}
     * @memberof AuctionApiAuctionControllerCreateAuction
     */
    readonly createAuctionBody: CreateAuctionBody
}

/**
 * Request parameters for auctionControllerCreateAuctionOffer operation in AuctionApi.
 * @export
 * @interface AuctionApiAuctionControllerCreateAuctionOfferRequest
 */
export interface AuctionApiAuctionControllerCreateAuctionOfferRequest {
    /**
     * 
     * @type {number}
     * @memberof AuctionApiAuctionControllerCreateAuctionOffer
     */
    readonly auctionId: number

    /**
     * 
     * @type {CreateAuctionOfferBody}
     * @memberof AuctionApiAuctionControllerCreateAuctionOffer
     */
    readonly createAuctionOfferBody: CreateAuctionOfferBody
}

/**
 * Request parameters for auctionControllerGetAuctionOffers operation in AuctionApi.
 * @export
 * @interface AuctionApiAuctionControllerGetAuctionOffersRequest
 */
export interface AuctionApiAuctionControllerGetAuctionOffersRequest {
    /**
     * 
     * @type {number}
     * @memberof AuctionApiAuctionControllerGetAuctionOffers
     */
    readonly auctionId: number

    /**
     * Номер текущей страницы
     * @type {number}
     * @memberof AuctionApiAuctionControllerGetAuctionOffers
     */
    readonly currentPage?: number

    /**
     * Количество элементов на странице
     * @type {number}
     * @memberof AuctionApiAuctionControllerGetAuctionOffers
     */
    readonly pageSize?: number
}

/**
 * Request parameters for auctionControllerGetAuctions operation in AuctionApi.
 * @export
 * @interface AuctionApiAuctionControllerGetAuctionsRequest
 */
export interface AuctionApiAuctionControllerGetAuctionsRequest {
    /**
     * Номер текущей страницы
     * @type {number}
     * @memberof AuctionApiAuctionControllerGetAuctions
     */
    readonly currentPage?: number

    /**
     * Количество элементов на странице
     * @type {number}
     * @memberof AuctionApiAuctionControllerGetAuctions
     */
    readonly pageSize?: number

    /**
     * Массив с id торгов
     * @type {Array<number>}
     * @memberof AuctionApiAuctionControllerGetAuctions
     */
    readonly auctionIds?: Array<number>

    /**
     * Список id регионов
     * @type {Array<string>}
     * @memberof AuctionApiAuctionControllerGetAuctions
     */
    readonly regionIds?: Array<string>

    /**
     * Список типов товаров
     * @type {Array<string>}
     * @memberof AuctionApiAuctionControllerGetAuctions
     */
    readonly productTypes?: Array<string>

    /**
     * Тип предложения
     * @type {'BUY' | 'SELL'}
     * @memberof AuctionApiAuctionControllerGetAuctions
     */
    readonly type?: 'BUY' | 'SELL'

    /**
     * Минимальный объем
     * @type {number}
     * @memberof AuctionApiAuctionControllerGetAuctions
     */
    readonly minVolume?: number

    /**
     * Максимальный объем
     * @type {number}
     * @memberof AuctionApiAuctionControllerGetAuctions
     */
    readonly maxVolume?: number

    /**
     * Минимальная цена
     * @type {number}
     * @memberof AuctionApiAuctionControllerGetAuctions
     */
    readonly minPrice?: number

    /**
     * Максимальная цена
     * @type {number}
     * @memberof AuctionApiAuctionControllerGetAuctions
     */
    readonly maxPrice?: number

    /**
     * Поле для сортировки
     * @type {'id' | 'offerId' | 'selectedVolume' | 'status' | 'moderatedBy' | 'startedAt' | 'endedAt' | 'createdAt' | 'updatedAt'}
     * @memberof AuctionApiAuctionControllerGetAuctions
     */
    readonly orderBy?: 'id' | 'offerId' | 'selectedVolume' | 'status' | 'moderatedBy' | 'startedAt' | 'endedAt' | 'createdAt' | 'updatedAt'

    /**
     * Направление сортировки
     * @type {OrderDirection}
     * @memberof AuctionApiAuctionControllerGetAuctions
     */
    readonly order?: OrderDirection
}

/**
 * Request parameters for auctionControllerGetCompanyAuctionOffers operation in AuctionApi.
 * @export
 * @interface AuctionApiAuctionControllerGetCompanyAuctionOffersRequest
 */
export interface AuctionApiAuctionControllerGetCompanyAuctionOffersRequest {
    /**
     * 
     * @type {number}
     * @memberof AuctionApiAuctionControllerGetCompanyAuctionOffers
     */
    readonly companyId: number

    /**
     * Номер текущей страницы
     * @type {number}
     * @memberof AuctionApiAuctionControllerGetCompanyAuctionOffers
     */
    readonly currentPage?: number

    /**
     * Количество элементов на странице
     * @type {number}
     * @memberof AuctionApiAuctionControllerGetCompanyAuctionOffers
     */
    readonly pageSize?: number

    /**
     * Массив с id предложений к торгам
     * @type {Array<number>}
     * @memberof AuctionApiAuctionControllerGetCompanyAuctionOffers
     */
    readonly offerIds?: Array<number>
}

/**
 * Request parameters for auctionControllerGetCompanyAuctions operation in AuctionApi.
 * @export
 * @interface AuctionApiAuctionControllerGetCompanyAuctionsRequest
 */
export interface AuctionApiAuctionControllerGetCompanyAuctionsRequest {
    /**
     * 
     * @type {number}
     * @memberof AuctionApiAuctionControllerGetCompanyAuctions
     */
    readonly companyId: number

    /**
     * Номер текущей страницы
     * @type {number}
     * @memberof AuctionApiAuctionControllerGetCompanyAuctions
     */
    readonly currentPage?: number

    /**
     * Количество элементов на странице
     * @type {number}
     * @memberof AuctionApiAuctionControllerGetCompanyAuctions
     */
    readonly pageSize?: number

    /**
     * Массив с id торгов
     * @type {Array<number>}
     * @memberof AuctionApiAuctionControllerGetCompanyAuctions
     */
    readonly auctionIds?: Array<number>
}

/**
 * Request parameters for auctionControllerGetModeratedAuctions operation in AuctionApi.
 * @export
 * @interface AuctionApiAuctionControllerGetModeratedAuctionsRequest
 */
export interface AuctionApiAuctionControllerGetModeratedAuctionsRequest {
    /**
     * Номер текущей страницы
     * @type {number}
     * @memberof AuctionApiAuctionControllerGetModeratedAuctions
     */
    readonly currentPage?: number

    /**
     * Количество элементов на странице
     * @type {number}
     * @memberof AuctionApiAuctionControllerGetModeratedAuctions
     */
    readonly pageSize?: number

    /**
     * Массив с id торгов
     * @type {Array<number>}
     * @memberof AuctionApiAuctionControllerGetModeratedAuctions
     */
    readonly auctionIds?: Array<number>

    /**
     * Список id регионов
     * @type {Array<string>}
     * @memberof AuctionApiAuctionControllerGetModeratedAuctions
     */
    readonly regionIds?: Array<string>

    /**
     * Список типов товаров
     * @type {Array<string>}
     * @memberof AuctionApiAuctionControllerGetModeratedAuctions
     */
    readonly productTypes?: Array<string>
}

/**
 * Request parameters for auctionControllerUpdateAuctionModerator operation in AuctionApi.
 * @export
 * @interface AuctionApiAuctionControllerUpdateAuctionModeratorRequest
 */
export interface AuctionApiAuctionControllerUpdateAuctionModeratorRequest {
    /**
     * 
     * @type {number}
     * @memberof AuctionApiAuctionControllerUpdateAuctionModerator
     */
    readonly auctionId: number

    /**
     * 
     * @type {UpdateAuctionModeratorBody}
     * @memberof AuctionApiAuctionControllerUpdateAuctionModerator
     */
    readonly updateAuctionModeratorBody: UpdateAuctionModeratorBody
}

/**
 * Request parameters for auctionControllerUpdateAuctionOfferStatus operation in AuctionApi.
 * @export
 * @interface AuctionApiAuctionControllerUpdateAuctionOfferStatusRequest
 */
export interface AuctionApiAuctionControllerUpdateAuctionOfferStatusRequest {
    /**
     * 
     * @type {number}
     * @memberof AuctionApiAuctionControllerUpdateAuctionOfferStatus
     */
    readonly auctionOfferId: number

    /**
     * 
     * @type {UpdateAuctionOfferStatusBody}
     * @memberof AuctionApiAuctionControllerUpdateAuctionOfferStatus
     */
    readonly updateAuctionOfferStatusBody: UpdateAuctionOfferStatusBody
}

/**
 * Request parameters for auctionControllerUpdateAuctionStatus operation in AuctionApi.
 * @export
 * @interface AuctionApiAuctionControllerUpdateAuctionStatusRequest
 */
export interface AuctionApiAuctionControllerUpdateAuctionStatusRequest {
    /**
     * 
     * @type {number}
     * @memberof AuctionApiAuctionControllerUpdateAuctionStatus
     */
    readonly auctionId: number

    /**
     * 
     * @type {UpdateAuctionStatusBody}
     * @memberof AuctionApiAuctionControllerUpdateAuctionStatus
     */
    readonly updateAuctionStatusBody: UpdateAuctionStatusBody
}

/**
 * AuctionApi - object-oriented interface
 * @export
 * @class AuctionApi
 * @extends {BaseAPI}
 */
export class AuctionApi extends BaseAPI {
    /**
     * 
     * @param {AuctionApiAuctionControllerCreateAuctionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuctionApi
     */
    public auctionControllerCreateAuction(requestParameters: AuctionApiAuctionControllerCreateAuctionRequest, options?: AxiosRequestConfig) {
        return AuctionApiFp(this.configuration).auctionControllerCreateAuction(requestParameters.companyId, requestParameters.createAuctionBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AuctionApiAuctionControllerCreateAuctionOfferRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuctionApi
     */
    public auctionControllerCreateAuctionOffer(requestParameters: AuctionApiAuctionControllerCreateAuctionOfferRequest, options?: AxiosRequestConfig) {
        return AuctionApiFp(this.configuration).auctionControllerCreateAuctionOffer(requestParameters.auctionId, requestParameters.createAuctionOfferBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AuctionApiAuctionControllerGetAuctionOffersRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuctionApi
     */
    public auctionControllerGetAuctionOffers(requestParameters: AuctionApiAuctionControllerGetAuctionOffersRequest, options?: AxiosRequestConfig) {
        return AuctionApiFp(this.configuration).auctionControllerGetAuctionOffers(requestParameters.auctionId, requestParameters.currentPage, requestParameters.pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AuctionApiAuctionControllerGetAuctionsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuctionApi
     */
    public auctionControllerGetAuctions(requestParameters: AuctionApiAuctionControllerGetAuctionsRequest = {}, options?: AxiosRequestConfig) {
        return AuctionApiFp(this.configuration).auctionControllerGetAuctions(requestParameters.currentPage, requestParameters.pageSize, requestParameters.auctionIds, requestParameters.regionIds, requestParameters.productTypes, requestParameters.type, requestParameters.minVolume, requestParameters.maxVolume, requestParameters.minPrice, requestParameters.maxPrice, requestParameters.orderBy, requestParameters.order, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AuctionApiAuctionControllerGetCompanyAuctionOffersRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuctionApi
     */
    public auctionControllerGetCompanyAuctionOffers(requestParameters: AuctionApiAuctionControllerGetCompanyAuctionOffersRequest, options?: AxiosRequestConfig) {
        return AuctionApiFp(this.configuration).auctionControllerGetCompanyAuctionOffers(requestParameters.companyId, requestParameters.currentPage, requestParameters.pageSize, requestParameters.offerIds, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AuctionApiAuctionControllerGetCompanyAuctionsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuctionApi
     */
    public auctionControllerGetCompanyAuctions(requestParameters: AuctionApiAuctionControllerGetCompanyAuctionsRequest, options?: AxiosRequestConfig) {
        return AuctionApiFp(this.configuration).auctionControllerGetCompanyAuctions(requestParameters.companyId, requestParameters.currentPage, requestParameters.pageSize, requestParameters.auctionIds, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AuctionApiAuctionControllerGetModeratedAuctionsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuctionApi
     */
    public auctionControllerGetModeratedAuctions(requestParameters: AuctionApiAuctionControllerGetModeratedAuctionsRequest = {}, options?: AxiosRequestConfig) {
        return AuctionApiFp(this.configuration).auctionControllerGetModeratedAuctions(requestParameters.currentPage, requestParameters.pageSize, requestParameters.auctionIds, requestParameters.regionIds, requestParameters.productTypes, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AuctionApiAuctionControllerUpdateAuctionModeratorRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuctionApi
     */
    public auctionControllerUpdateAuctionModerator(requestParameters: AuctionApiAuctionControllerUpdateAuctionModeratorRequest, options?: AxiosRequestConfig) {
        return AuctionApiFp(this.configuration).auctionControllerUpdateAuctionModerator(requestParameters.auctionId, requestParameters.updateAuctionModeratorBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AuctionApiAuctionControllerUpdateAuctionOfferStatusRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuctionApi
     */
    public auctionControllerUpdateAuctionOfferStatus(requestParameters: AuctionApiAuctionControllerUpdateAuctionOfferStatusRequest, options?: AxiosRequestConfig) {
        return AuctionApiFp(this.configuration).auctionControllerUpdateAuctionOfferStatus(requestParameters.auctionOfferId, requestParameters.updateAuctionOfferStatusBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AuctionApiAuctionControllerUpdateAuctionStatusRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuctionApi
     */
    public auctionControllerUpdateAuctionStatus(requestParameters: AuctionApiAuctionControllerUpdateAuctionStatusRequest, options?: AxiosRequestConfig) {
        return AuctionApiFp(this.configuration).auctionControllerUpdateAuctionStatus(requestParameters.auctionId, requestParameters.updateAuctionStatusBody, options).then((request) => request(this.axios, this.basePath));
    }
}
