import React from 'react'

import { Button, Upload } from 'antd'
import { FiUpload } from 'react-icons/fi'

import { CustomControlInterface } from '~shared/controls/CustomControl.interface'

type Value = File[]

interface IUploadDocumentsControlProps extends CustomControlInterface {
  onChange: (value: Value) => void
  value: Value
  hasError?: boolean
  id?: string
}

export const UploadDocumentsControl: React.FC<IUploadDocumentsControlProps> = React.forwardRef<
  HTMLButtonElement,
  IUploadDocumentsControlProps
>(({ id, value, onChange, hasError }, ref) => {
  const onChangeHandler = (value: Value) => {
    onChange(value)
  }

  return (
    <Upload
      id={id}
      showUploadList
      multiple
      accept=".png, .jpg, .jpeg, .pdf"
      onChange={({ fileList }) => onChangeHandler(fileList.map((x) => x.originFileObj as File))}
      customRequest={(x) => x.onSuccess?.('ok')}
    >
      <Button ref={ref} icon={<FiUpload />}>
        Загрузить
      </Button>
    </Upload>
  )
})

UploadDocumentsControl.displayName = 'UploadDocumentsControl'
