import styled from 'styled-components'
import { Flex } from 'antd'

export const Wrapper = styled(Flex)`
  padding: 24px;
`

export const TextId = styled.div`
  font-size: 24px;
  font-weight: 400;
  color: #8c8c8c;
`

export const TextLoad = styled.div`
  font-size: 28px;
  font-weight: 700;
  color: black;
`

export const AvailableVolumeWrapper = styled(Flex)<{ isAvailable?: boolean }>`
  border-left: 2px solid ${(props) => (props.isAvailable ? 'var(--primary-main-color)' : 'var(--danger-color)')};
  padding: 0 16px;
`

export const CounterWrapper = styled(Flex)<{ active?: boolean }>`
  background-color: ${(props) => (props.active ? '#ecf6f2' : '#F5F5F5')};
  padding: 8px 16px;
  border: 1px solid ${(props) => (props.active ? '#45a67d' : '#9E9E9E')};
  border-radius: 6px;
  cursor: pointer;
`
