import * as yup from 'yup'

import { OfferPriceType } from '~api/generated'
import { useGetCompanyZernoAgregator } from '~hooks/company/useGetCompanyZernoAgregator'
import { useCheckCreateUserForCompany } from '~hooks/user'
import { addressSchema, VALIDATORS_MSG } from '~shared/validations'
import { fullPhoneNumberValidator } from '~shared/validations/schemas'

const requiredTrimmedString = yup.string().trim().required(VALIDATORS_MSG.required)
const notRequiredTrimmedString = yup.string().trim().notRequired()

export const createAdvertFormToSellByAdminValidation = () => {
  const checkCreateUserForCompany = useCheckCreateUserForCompany()
  const companyZernoAgregator = useGetCompanyZernoAgregator()

  return yup.object({
    elevatorId: yup.number().required(VALIDATORS_MSG.required),
    culture: yup
      .object({
        // hasError: yup.bool().oneOf([false], 'Параметры культуры заполнены с ошибками'),
      })
      .required(VALIDATORS_MSG.required),
    volume: yup
      .number()
      .typeError(VALIDATORS_MSG.required)
      .min(200, VALIDATORS_MSG.minValue(200))
      .required(VALIDATORS_MSG.required),
    pricePerTon: yup
      .number()
      .typeError(VALIDATORS_MSG.required)
      .min(1000, VALIDATORS_MSG.minValue(1000))
      .required(VALIDATORS_MSG.required),
    priceType: yup.mixed().required(VALIDATORS_MSG.required),
    requiredLaboratoryAnalysis: yup.boolean(),
    labAnalysis: yup.mixed().when('requiredLaboratoryAnalysis', {
      is: (requiredLaboratoryAnalysis: boolean) => requiredLaboratoryAnalysis,
      then: yup.mixed().notRequired(),
      otherwise: yup.mixed().required(VALIDATORS_MSG.required),
    }),
    address: yup.mixed().when('priceType', {
      is: (val: OfferPriceType) => val === OfferPriceType.AtSellersGate,
      then: addressSchema,
      otherwise: yup.mixed().notRequired(),
    }),
    companyInfo: yup
      .object({
        name: notRequiredTrimmedString,
        inn: notRequiredTrimmedString,
        kpp: notRequiredTrimmedString,
        codeOkved: notRequiredTrimmedString,
        director: notRequiredTrimmedString,
        actualAddress: notRequiredTrimmedString,
        legalAddress: notRequiredTrimmedString,
      })
      .when('company', (company, schema) => {
        return company === 'other'
          ? yup.object({
              name: requiredTrimmedString,
              inn: requiredTrimmedString,
              kpp: notRequiredTrimmedString,
              codeOkved: requiredTrimmedString,
              director: notRequiredTrimmedString,
              actualAddress: notRequiredTrimmedString,
              legalAddress: notRequiredTrimmedString,
            })
          : schema
      }),
    contactUser: requiredTrimmedString,
    contactPhone: yup
      .string()
      .trim()
      .test('mobilePhone', VALIDATORS_MSG.notFullPhoneNumber, fullPhoneNumberValidator)
      .required(VALIDATORS_MSG.required)
      .test({
        name: 'contactPhone',
        exclusive: true,
        params: {},
        message: 'Пользователь привязан к другой компании',
        test: function (phoneNumber) {
          const inn = this.parent.companyInfo?.inn || companyZernoAgregator.data?.inn
          if (!phoneNumber || !inn) return true
          return new Promise((resolve) => {
            checkCreateUserForCompany
              .mutateAsync({
                phoneNumber,
                inn,
              })
              .then((data) => resolve(data))
          })
        },
      }),
  })
}
