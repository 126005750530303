import { Breakpoints, GetBreakpoint } from './types'

export const breakpoints: Record<Breakpoints, number> = {
  MOBILE_S: 320,
  MOBILE_M: 374,
  MOBILE_L: 539,
  MOBILE_XL: 767,
  DESKTOP_S: 1023,
  DESKTOP_M: 1279,
  DESKTOP_L: 1439,
  DESKTOP_XL: 1919,
}

export const getBreakpointToken: GetBreakpoint = (name) => breakpoints[name]
