import React, { FC } from 'react'

import { Select } from 'antd'
import { Controller, useForm } from 'react-hook-form'

import { yupResolver } from '@hookform/resolvers/yup'

import { FormField, UploadButton } from '~/ui-components'

import { loadAddDocumentFormSchema } from './LoadAddDocumentForm.schema'

import { LoadAddDocumentFormProps, LoadAddDocumentFormValues } from './LoadAddDocumentForm.types'
import { getLogisticOrderDocumentName } from '~/utils/logistics/get-logistic-order-document-name'

export const LoadAddDocumentForm: FC<LoadAddDocumentFormProps> = ({
  documentsTypes,
  onSubmit,
  defaultDocumentsType,
}) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<LoadAddDocumentFormValues>({
    defaultValues: {
      documentType: defaultDocumentsType,
      documentFiles: undefined,
    },
    resolver: yupResolver(loadAddDocumentFormSchema),
  })

  return (
    <form id="load-add-document-form" onSubmit={handleSubmit(onSubmit)}>
      <FormField label="Тип документа" isRequired htmlFor="documentType" validateMessage={errors.documentType?.message}>
        <Controller
          name="documentType"
          control={control}
          render={({ field }) => (
            <Select
              style={{ width: '100%' }}
              defaultValue={
                defaultDocumentsType
                  ? { value: defaultDocumentsType, label: getLogisticOrderDocumentName(defaultDocumentsType) }
                  : undefined
              }
              disabled={!!defaultDocumentsType}
              placeholder="Тип документа"
              onChange={(e) => field.onChange(e)}
            >
              {documentsTypes?.map((type) => (
                <Select.Option key={type} value={type}>
                  {getLogisticOrderDocumentName(type)}
                </Select.Option>
              ))}
            </Select>
          )}
        />
      </FormField>
      <FormField label="Документ" isRequired validateMessage={errors.documentFiles?.message}>
        <Controller
          control={control}
          name="documentFiles"
          render={({ field }) => (
            <UploadButton
              onChange={(evt) => {
                if (evt.target.files?.length) {
                  field.onChange([evt.target.files[0]])
                }
              }}
              onDelete={() => field.onChange(undefined)}
            >
              Загрузить
            </UploadButton>
          )}
        />
      </FormField>
    </form>
  )
}
