import { useMutation } from '@tanstack/react-query'

import { AddWarehousePositionActionDecreaseBody } from '~api/generated'
import { useApi } from '~providers/api'

export const useWarehouseCultureIncreaseOrDecrease = (
  type: 'increase' | 'decrease',
  warehousePositionId: number | null
) => {
  const api = useApi()

  const mutationFn = async (request: AddWarehousePositionActionDecreaseBody): Promise<void> => {
    if (!warehousePositionId) {
      return Promise.reject('[useWarehouseCultureExternalSell]: warehousePositionId was not passed')
    }

    switch (type) {
      case 'increase':
        await api.warehouses.warehousePositionControllerAddWarehousePositionActionIncrease({
          warehousePositionId,
          addWarehousePositionActionIncreaseBody: request,
        })
        return
      case 'decrease':
        await api.warehouses.warehousePositionControllerAddWarehousePositionActionDecrease({
          warehousePositionId,
          addWarehousePositionActionDecreaseBody: request,
        })
        return
      default:
        return
    }
  }
  return useMutation(mutationFn)
}
