import React, { FC, useEffect, useState } from 'react'

import { millisecondsToHours, millisecondsToMinutes } from 'date-fns'

import { AuctionTimerProps } from './AuctionTimer.types'

import * as S from './AuctionTimer.styled'

export const AuctionTimer: FC<AuctionTimerProps> = ({ endedAt }) => {
  const [time, setTime] = useState('')
  const [isTimeEnd, setTimeEnd] = useState(false)

  useEffect(() => {
    if (!endedAt) return

    const setTimer = () => {
      const startDate = new Date()
      const endDate = new Date(endedAt)

      const hours = millisecondsToHours(endDate.getTime() - startDate.getTime())
      const minutes = millisecondsToMinutes(endDate.getTime() - startDate.getTime())

      if (intervalId && hours <= 0 && minutes <= 0) {
        clearInterval(intervalId)
        setTimeEnd(true)
        return
      }

      setTime(`${hours}ч ${minutes % 60}м`)
    }

    const intervalId = setInterval(setTimer, 1000 * 60)

    setTimer()

    return () => intervalId && clearInterval(intervalId)
  }, [endedAt])

  if (isTimeEnd) {
    return (
      <S.Wrapper>
        <S.InfoText variant="normal">Торги закончились</S.InfoText>
      </S.Wrapper>
    )
  }

  return (
    <S.Wrapper>
      <S.InfoText variant="normal">Торги закончатся через</S.InfoText>
      <S.Time variant="normal-bold">{time}</S.Time>
    </S.Wrapper>
  )
}
