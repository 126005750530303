import { useQuery } from '@tanstack/react-query'

import { GetOfferResponse } from '~/api/generated'
import { useApi } from '~/providers/api'

export const useGetModeratorOffer = (offerId: number) => {
  const api = useApi()

  async function fetchFn(): Promise<GetOfferResponse> {
    const res = await api.offers.adminGetOffer({
      offerId,
    })
    return res.data
  }

  return useQuery(['fetchModeratorOffer', offerId], fetchFn)
}
