import React, { useState } from 'react'

import { Modal } from 'antd'

import { useQuery } from '@apollo/client'

import { appRoutes } from '~/router'
import { AvatarUploader } from '~/shared/components'
import { userUiSettingsStore } from '~/store/userUiSettingsStore'
import { EmptyButton } from '~/ui-components'
import { appToast, httpErrorHandler, modalSharedSettings } from '~/utils'
import { getMeCompaniesQuery } from '~api/gql-query/me.query.graphql'
import { useLoginByAdminAsUser } from '~hooks/auth'
import { useGetUserAvatarUrl, useGetUserById, useUpdateCurrentUser } from '~hooks/user'
import { useAuth } from '~providers/auth'

import { IPersonalProfileForm, PersonalProfileForm } from '../PersonalProfileForm'
import { PersonalProfileView } from '../PersonalProfileView'

interface IPersonalProfileSectionProps {
  userId?: number
  hideAddUser?: boolean
}

export const PersonalProfileSection: React.FC<IPersonalProfileSectionProps> = (props) => {
  const getCompanies = useQuery(getMeCompaniesQuery)
  const [isModalVisible, setIsModalVisible] = useState(false)

  const { userId: currentUserId, phoneNumber, setAuthenticated, refetchUser } = useAuth()
  const userId: number | null = props.userId ? props.userId : currentUserId

  const userQuery = useGetUserById(userId, { enabled: !!userId })
  const { data: userAvatarUrl, refetch: userAvatarRefetch } = useGetUserAvatarUrl(userId)

  const isUserNotCreated = !userQuery.data?.name

  const mutationUpdateCurrentUser = useUpdateCurrentUser()
  const useLoginByAdminAsUserFn = useLoginByAdminAsUser()

  const handleModalClose = () => setIsModalVisible(false)
  const handleModalOpen = () => setIsModalVisible(true)

  const handleSubmitChanges = async (formValues: IPersonalProfileForm) => {
    const data = {
      name: formValues.name,
      position: formValues.position,
      email: formValues.email,
    }

    try {
      await mutationUpdateCurrentUser.mutateAsync({
        userId: userId ?? NaN,
        updateUserBody: data,
      })
      appToast.success({ description: 'Данные о пользователе обновлены' })
    } catch (e) {
      httpErrorHandler(e, 'Ошибка при обновлении данных пользователя')
    }

    await userQuery.refetch()
    handleModalClose()
  }

  const handleUploadPhoto = async (file: File) => {
    if (!userId) {
      return
    }

    try {
      // await mutationCurrentUserPhoto.mutateAsync({ userId, profilePicture: file })
    } catch (e) {
      httpErrorHandler(e, 'Ошибка при загрузке фото')
    }
    await userAvatarRefetch()
  }

  const handleDeletePhoto = async () => {
    if (!userId) {
      return
    }
    try {
      // await deleteCurrentUserPhoto.mutateAsync({ userId })
    } catch (e) {
      httpErrorHandler(e, 'Ошибка при удалении фото')
    }
    await userAvatarRefetch()
  }

  const onAdminLoginAsUserHandler = async (userId: number) => {
    if (!userId) {
      return
    }

    try {
      const user = await useLoginByAdminAsUserFn.mutateAsync({
        userId,
        userAgent: navigator.userAgent,
      })
      await new Promise((r) => setTimeout(r, 2000))
      await window.location.replace(appRoutes.accountProfile.url)
      await getCompanies.refetch()
    } catch (e) {
      httpErrorHandler(e, 'Ошибка при входе в приложение от лица другого пользователя')
    }
  }

  if (userQuery.isLoading) {
    return <EmptyButton>Загрузка...</EmptyButton>
  }

  if (userQuery.isError) {
    return <EmptyButton>Ошибка</EmptyButton>
  }

  const renderProfileView = () => {
    if (!userQuery.data) {
      return
    }

    if (props.hideAddUser) {
      return (
        <PersonalProfileView
          user={userQuery.data}
          onChange={handleModalOpen}
          onAdminLoginAsUser={onAdminLoginAsUserHandler}
        />
      )
    }
    if (isUserNotCreated) {
      return <EmptyButton onClick={handleModalOpen}>Введите личные данные</EmptyButton>
    }
    return (
      <PersonalProfileView
        user={userQuery.data}
        onChange={handleModalOpen}
        onAdminLoginAsUser={onAdminLoginAsUserHandler}
      />
    )
  }
  return (
    <>
      {renderProfileView()}

      <Modal {...modalSharedSettings} open={isModalVisible} onCancel={handleModalClose} title="Личные данные">
        <>
          <AvatarUploader
            avatarUrl={userAvatarUrl ?? undefined}
            onUpload={handleUploadPhoto}
            onDeleteAvatar={handleDeletePhoto}
          />
          <PersonalProfileForm
            defaultValues={userQuery.data ?? { phoneNumber: phoneNumber ?? undefined }}
            isLoading={mutationUpdateCurrentUser.isLoading || userQuery.isFetching}
            onSubmit={handleSubmitChanges}
            onCancel={handleModalClose}
          />
        </>
      </Modal>
    </>
  )
}
