import { codeUnreachable } from '~/utils/code-unreachable'

export function getIsAuctionName(isAuction: boolean) {
  switch (isAuction) {
    case true:
      return 'Возможен торг'
    case false:
      return 'Без торга'
    default:
      return codeUnreachable(isAuction)
  }
}
