import styled from 'styled-components'

import { css } from '@styled-system/css'

export const Wrapper = styled.div(
  css({
    display: 'flex',
    gap: ['4px', null, '8px'],
  })
)

export const Input = styled.input(
  css({
    display: 'block',
    width: ['40px', null, '46px'],
    height: ['48px', null, '70px'],
    padding: 0,
    borderRadius: 3,
    border: '1px solid',
    borderColor: '#E9E9E9',
    textAlign: 'center',
    fontSize: ['20px', '20px', '28px'],
    lineHeight: 1,
    fontWeight: 600,
    color: `var(--gray-8-color)`,
    outline: 'none',

    '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
      WebkitAppearance: 'none',
      margin: 0,
      MozAppearance: 'textfield',
    },
  })
)
