import { graphql } from '~api/gql-generated/gql'

export const getUsersQuery = graphql(/* GraphQL */ `
  query Users($pagination: UserPaginationInput!, $filter: UsersFilterInput) {
    users(pagination: $pagination, filter: $filter) {
      users {
        id
        phoneNumber
        status
        email
        name
        role
        createdAt
        deactivatedAt
        authenticatedAt
        avatarUrl
        companies {
          id
          legalName
          type
        }
        actions {
          updateAvatar
          update
          deactivate
          activate
        }
      }
      totalCount
    }
  }
`)
