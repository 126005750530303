import { AxiosResponse } from 'axios'

import { QueryObserverSuccessResult, useQuery, UseQueryOptions } from '@tanstack/react-query'

import { GetProductTypesResponse, ProductTypeData } from '~/api/generated'
import { useApi } from '~providers/api'

type Options = Omit<UseQueryOptions<ProductTypeData[], unknown>, 'queryKey' | 'queryFn'>

export const useGetProductTypes = (options?: Options) => {
  const api = useApi()

  const fetchFn = async (): Promise<ProductTypeData[]> => {
    try {
      const res: AxiosResponse<GetProductTypesResponse> = await api.products.productControllerGetProductTypes()

      return res.data.productTypes
    } catch (error) {
      console.error({ description: 'Ошибка при загрузке списка культур' })
      return []
    }
  }
  return useQuery<ProductTypeData[]>(['fetchProducts'], fetchFn, {
    staleTime: Infinity,
    refetchInterval: 1000 * 60 * 30,
    ...options,
    // https://github.com/TanStack/query/discussions/1331
  }) as QueryObserverSuccessResult<ProductTypeData[], never>
}
