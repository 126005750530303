import React from 'react'

import { Button, Flex, Input, InputNumber } from 'antd'
import { Controller, useForm } from 'react-hook-form'

import { yupResolver } from '@hookform/resolvers/yup'

import { FormField } from '~/ui-components'
import { formatNumber } from '~/utils/formatNumber'
import { getDateFormat } from '~/utils/getDateFormat'
import { inputSharedSettings } from '~/utils/inputSharedSettings'
import { getTransportType } from '~/utils/logistics/get-transport-type'
import { ReadValue } from '~shared/components'

import { ResponseToLoadFormSchema } from './ResponseToLoadForm.validation'

import { ResponseToLoadFormProps, ResponseToLoadFormValues } from './ResponseToLoadForm.types'

export const ResponseToLoadForm: React.FC<ResponseToLoadFormProps> = ({ onBack, onSubmit, freightOrder }) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<ResponseToLoadFormValues>({
    resolver: yupResolver(ResponseToLoadFormSchema),
  })

  const onSubmitHandler = (data: ResponseToLoadFormValues) => {
    onSubmit(data)
  }

  return (
    <Flex vertical gap={24}>
      <Flex gap={32}>
        <Flex
          vertical
          gap={16}
          style={{ backgroundColor: 'var(--gray-1-color)', padding: '16px', borderRadius: '8px' }}
          flex="1 1 50%"
        >
          <ReadValue
            name="Дата погрузки"
            value={freightOrder.startedAt ? getDateFormat(freightOrder.startedAt) : undefined}
          />
          <ReadValue
            name="Маршрут"
            value={`${freightOrder.route.points[0].address.short} - ${freightOrder.route.points[1].address.short}, ${freightOrder.route.distance} км`}
          />
          <ReadValue name="Тип груза" value={freightOrder.route.points[0].freight?.productType.name} />
          <ReadValue name="Доступный объем" value={(freightOrder.availableWeightKg / 1000).toString() + ' т'} />
          <ReadValue name="Транспорт" value={getTransportType(freightOrder.transportFormatTypes)} />
          <ReadValue
            name="Ставка"
            value={
              <>
                <p>{formatNumber(freightOrder.pricePerTonWithNds)} ₽/т с НДС</p>
                <p>{formatNumber(freightOrder.pricePerTonWithoutNds)} ₽/т без НДС</p>
              </>
            }
          />
        </Flex>

        <Flex vertical gap={16} flex="1 1 50%">
          <FormField label="Вес" htmlFor="weightKg" validateMessage={errors.weightKg?.message} isRequired grow={false}>
            <Controller
              name="weightKg"
              control={control}
              render={({ field }) => (
                <InputNumber
                  {...field}
                  {...inputSharedSettings}
                  addonAfter="т"
                  style={{ width: '100%' }}
                  id="weightKg"
                  placeholder="Введите значение"
                  status={errors.weightKg && 'error'}
                  step={1}
                />
              )}
            />
          </FormField>

          <FormField
            label="Комментарий"
            htmlFor="additionalDetails"
            validateMessage={errors.additionalDetails?.message}
            grow={false}
          >
            <Controller
              name="additionalDetails"
              control={control}
              render={({ field }) => (
                <Input
                  {...field}
                  id="additionalDetails"
                  status={errors.additionalDetails && 'error'}
                  placeholder="Введите комментарий"
                />
              )}
            />
          </FormField>
        </Flex>
      </Flex>

      <Flex gap={16} justify="flex-end">
        <Button htmlType="button" onClick={onBack}>
          Отменить
        </Button>

        <Button htmlType="button" type="primary" onClick={handleSubmit(onSubmitHandler)}>
          Направить отклик
        </Button>
      </Flex>
    </Flex>
  )
}
