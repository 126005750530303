import styled from 'styled-components'

import css from '@styled-system/css'

export const Form = styled('form')(
  css({
    display: 'flex',
    flexDirection: 'column',
  })
)

export const Address = styled.div(
  css({
    display: 'flex',
    flexDirection: 'column',
    gap: 4,
  })
)

export const Price = styled.div(
  css({
    display: 'flex',
    gap: [0, null, 4],
    flexDirection: ['column'],
  })
)

export const NdsWrapper = styled('div')(
  css({
    mt: '8px',
  })
)
