import { codeUnreachable } from '~/utils/code-unreachable'
import { TransportType } from '~api/gql-generated/graphql'

export function getTransportTypeName(transportType: TransportType): string {
  switch (transportType) {
    case TransportType.Truck:
      return 'Грузовик'
    case TransportType.Tractor:
      return 'Тягач'
    case TransportType.SemiTrailer:
      return 'Полуприцеп'
    case TransportType.Trailer:
      return 'Прицеп'
    default:
      return codeUnreachable(transportType)
  }
}
