import styled from 'styled-components'

import { breakpoints } from '~/tokens'
import { Text } from '~/ui-components'

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 24px;
`
export const ProductName = styled(Text).attrs({ variant: 'normal-bold' })``
export const Volume = styled(Text).attrs({ variant: 'h4' })`
  color: var(--primary-main-color);
`

export const CardFooter = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`
export const CardHeader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 16px;
  //margin-bottom: 24px;
`
export const CardHeaderTop = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
`
export const CardHeaderTopLeft = styled.div`
  display: flex;
  gap: 8px;
`
export const ProductWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`
export const ProductRowWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 8px;
`
export const PriceWrapper = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`

export const TitleOfferNumber = styled(Text).attrs({ variant: 'normal' })`
  color: #777777;
`
export const PublishedAt = styled(Text).attrs({ variant: 'normal' })`
  color: var(--gray-4-color);
`
export const Parameters = styled.div`
  margin-top: 8px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 8px;
  max-width: 500px;
  color: var(--badge-gray-bg-color);
  @media (max-width: ${breakpoints.MOBILE_XL}px) {
    grid-template-columns: 1fr;
    grid-row-gap: 4px;
  }
`
