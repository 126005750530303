import React from 'react'
import * as S from './DocumentCardNotAvailable.styled'
import { DocumentCardNotAvailableProps } from './DocumentCardNotAvailable.types'

export const DocumentCardNotAvailable: React.FC<DocumentCardNotAvailableProps> = ({ text }) => {
  return (
    <S.DocumentCardNotAvailable>
      <S.TransportDocumentNotAvailableText>{text}</S.TransportDocumentNotAvailableText>
    </S.DocumentCardNotAvailable>
  )
}
