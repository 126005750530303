import React from 'react'

import { Col, Flex, Input, Radio, RadioChangeEvent, Row } from 'antd'
import { Controller, useFormContext, useWatch } from 'react-hook-form'

import { FormField, Text } from '~/ui-components'
import { getAddressType } from '~/utils/logistics/get-address-type'
import { ElevatorData, WarehouseData } from '~api/generated'
import { LoadingTransportType, RoutePointType, UnloadingTransportType } from '~api/gql-generated/graphql'
import { AddressControlDadata, ElevatorSelectControlV2, LoadTypeControl, UnloadTypeControl } from '~shared/controls'
import { WarehouseSelectControl } from '~shared/controls/WarehouseSelectControl'
import { Timeline } from '~ui-components/components/Timeline'

import { Load } from './components/Load'
import { TechnicalCharacteristics } from './components/TechnicalCharacteristics'

import {
  AddressTypes,
  CreateLoadFormFieldProps,
} from '~pages/LogisticsPage/modules/MyCargos/components/CreateLoad/CreateLoad.types'

export const RouteAndLoad: React.FC<CreateLoadFormFieldProps> = ({ control, errors }) => {
  const routePoints = useWatch({ control, name: 'routePoints' })
  const fromAddressType = useWatch({ control, name: 'fromAddressType' })
  const toAddressType = useWatch({ control, name: 'toAddressType' })
  const { setValue } = useFormContext()

  const onChangeAddressType = (e: RadioChangeEvent, index: number) => {
    switch (e.target.value) {
      case AddressTypes.address:
        setValue(`routePoints.${index}.warehouseId`, undefined)
        setValue(`routePoints.${index}.elevatorId`, undefined)
        setWarehouseFormValues(undefined, index)
        setElevatorFormValues(undefined, index)
        break
      case AddressTypes.warehouse:
        setValue(`routePoints.${index}.address`, undefined)
        setValue(`routePoints.${index}.elevatorId`, undefined)
        setElevatorFormValues(undefined, index)
        break
      case AddressTypes.elevator:
        setValue(`routePoints.${index}.address`, undefined)
        setValue(`routePoints.${index}.warehouseId`, undefined)
        setWarehouseFormValues(undefined, index)
        break
      default:
        break
    }
  }

  const setWarehouseFormValues = (data: WarehouseData | undefined, index: number) => {
    setValue(`routePoints.${index}.address`, data?.address)
    index === 0 &&
      setValue(`routePoints.${index}.roadTransportParameters.loadingTypes`, data?.roadTransportParameters?.loadingTypes)
    index === 1 &&
      setValue(
        `routePoints.${index}.roadTransportParameters.unloadingTypes`,
        data?.roadTransportParameters?.unloadingTypes
      )
    index === 0 &&
      setValue(
        `routePoints.${index}.roadTransportParameters.loadingThroughput`,
        data?.roadTransportParameters?.loadingThroughput ?? undefined
      )
    index === 1 &&
      setValue(
        `routePoints.${index}.roadTransportParameters.unloadingThroughput`,
        data?.roadTransportParameters?.unloadingThroughput ?? undefined
      )
    setValue(
      `routePoints.${index}.roadTransportParameters.vehicleLengthMaxM`,
      data?.roadTransportParameters?.vehicleLengthMaxM ?? undefined
    )
    setValue(
      `routePoints.${index}.roadTransportParameters.vehicleHeightMaxM`,
      data?.roadTransportParameters?.vehicleHeightMaxM ?? undefined
    )
    setValue(
      `routePoints.${index}.roadTransportParameters.vehicleClearanceMinCm`,
      data?.roadTransportParameters?.vehicleClearanceMinCm ?? undefined
    )
    setValue(
      `routePoints.${index}.roadTransportParameters.hasScales`,
      data?.roadTransportParameters?.hasScales ?? undefined
    )
    setValue(
      `routePoints.${index}.roadTransportParameters.scalesWeightLimitT`,
      data?.roadTransportParameters?.scalesWeightLimitT ?? undefined
    )
    setValue(
      `routePoints.${index}.roadTransportParameters.hasWeighingAutomation`,
      data?.roadTransportParameters?.hasWeighingAutomation ?? undefined
    )
    setValue(
      `routePoints.${index}.roadTransportParameters.roadSurfaceType`,
      data?.roadTransportParameters?.roadSurfaceType ?? undefined
    )
    setValue(
      `routePoints.${index}.roadTransportParameters.roadSurfaceQuality`,
      data?.roadTransportParameters?.roadSurfaceQuality ?? undefined
    )
    setValue(
      `routePoints.${index}.roadTransportParameters.distanceToScalesM`,
      data?.roadTransportParameters?.distanceToScalesM ?? undefined
    )
    setValue(
      `routePoints.${index}.roadTransportParameters.hasParking`,
      data?.roadTransportParameters?.hasParking ?? undefined
    )
    setValue(
      `routePoints.${index}.roadTransportParameters.additionalDetails`,
      data?.roadTransportParameters?.additionalDetails ?? undefined
    )
  }

  const setElevatorFormValues = (data: ElevatorData | undefined, index: number) => {
    setValue(`routePoints.${index}.address`, data?.address)
    index === 0 && setValue(`routePoints.${index}.roadTransportParameters.loadingTypes`, data?.loadingTransport)
    index === 1 && setValue(`routePoints.${index}.roadTransportParameters.unloadingTypes`, data?.unloadingTransport)
    index === 0 &&
      setValue(`routePoints.${index}.roadTransportParameters.loadingThroughput`, data?.autoReceptionSpeed ?? undefined)
    index === 1 &&
      setValue(`routePoints.${index}.roadTransportParameters.unloadingThroughput`, data?.autoUnloadSpeed ?? undefined)
    setValue(
      `routePoints.${index}.roadTransportParameters.vehicleLengthMaxM`,
      data?.scalesLength ? Math.ceil(data?.scalesLength) : undefined
    )
    setValue(
      `routePoints.${index}.roadTransportParameters.vehicleHeightMaxM`,
      data?.autoHeight ? Math.ceil(data?.autoHeight / 100) : undefined
    )
    setValue(
      `routePoints.${index}.roadTransportParameters.vehicleClearanceMinCm`,
      data?.autoClearance ? Math.ceil(data?.autoClearance) : undefined
    )
    setValue(`routePoints.${index}.roadTransportParameters.hasScales`, data?.hasScales ?? undefined)
    setValue(`routePoints.${index}.roadTransportParameters.scalesWeightLimitT`, data?.scalesWeightLimit ?? undefined)
    setValue(`routePoints.${index}.roadTransportParameters.hasWeighingAutomation`, data?.hasTecEco ?? undefined)
    setValue(`routePoints.${index}.roadTransportParameters.hasParking`, data?.hasAutoStorage ?? undefined)
    setValue(`routePoints.${index}.roadTransportParameters.additionalDetails`, data?.autoAdditionalInfo ?? undefined)
  }

  const timelineItems = [
    {
      label: 'Погрузка',
      children: (
        <Flex vertical gap={24} style={{ padding: '4px 12px' }}>
          <Row gutter={32}>
            <Col span={12}>
              <Flex vertical gap={16}>
                <FormField
                  label="Откуда"
                  htmlFor="fromAddressType"
                  validateMessage={errors.fromAddressType?.message}
                  isRequired
                >
                  <Controller
                    name="fromAddressType"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <Radio.Group
                        value={value}
                        onChange={(e) => {
                          onChange(e.target.value)
                          onChangeAddressType(e, 0)
                        }}
                        optionType="button"
                        buttonStyle="solid"
                        size="middle"
                      >
                        {[AddressTypes.address, AddressTypes.warehouse, AddressTypes.elevator].map((value) => (
                          <Radio.Button key={value} value={value}>
                            {getAddressType(value)}
                          </Radio.Button>
                        ))}
                      </Radio.Group>
                    )}
                  />
                </FormField>
              </Flex>
            </Col>
            <Col span={12} />

            <Col sm={{ span: 12 }} lg={{ span: 10 }}>
              {fromAddressType === AddressTypes.address && (
                <FormField
                  label="Адрес"
                  htmlFor="routePoints.0.address"
                  validateMessage={errors.routePoints?.[0]?.address?.message}
                  isRequired
                >
                  <Controller
                    name="routePoints.0.address"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <AddressControlDadata
                        value={value}
                        onChange={(val) => onChange(val?.value)}
                        id="routePoints.0.address"
                        placeholder="Введите адрес"
                        hasError={!!errors.routePoints?.[0]?.address}
                      />
                    )}
                  />
                </FormField>
              )}

              {fromAddressType === AddressTypes.warehouse && (
                <FormField
                  label="Мой склад"
                  htmlFor="routePoints.0.warehouseId"
                  validateMessage={errors.routePoints?.[0]?.warehouseId?.message}
                  isRequired
                >
                  <Controller
                    name="routePoints.0.warehouseId"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <WarehouseSelectControl
                        id="routePoints.0.warehouseId"
                        value={value}
                        onChange={(data) => {
                          onChange(data?.id)
                          setWarehouseFormValues(data, 0)
                        }}
                        hasError={!!errors.routePoints?.[0]?.warehouseId}
                      />
                    )}
                  />
                </FormField>
              )}

              {fromAddressType === AddressTypes.elevator && (
                <FormField
                  label="Базис"
                  htmlFor="routePoints.0.elevatorId"
                  validateMessage={errors.routePoints?.[0]?.elevatorId?.message}
                  isRequired
                >
                  <Controller
                    name="routePoints.0.elevatorId"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <ElevatorSelectControlV2
                        id="routePoints.0.elevatorId"
                        value={value}
                        onChange={(data) => {
                          onChange(data?.id)
                          setElevatorFormValues(data, 0)
                        }}
                        hasError={!!errors.routePoints?.[0]?.elevatorId}
                      />
                    )}
                  />
                </FormField>
              )}
            </Col>
            <Col sm={{ span: 12 }} lg={{ span: 14 }} />

            <Col sm={{ span: 12 }} lg={{ span: 10 }}>
              <FormField
                label="Способ погрузки"
                htmlFor="routePoints.0.roadTransportParameters.loadingTypes"
                validateMessage={errors.routePoints?.[0]?.roadTransportParameters?.loadingTypes?.message}
                isRequired
              >
                <Controller
                  name="routePoints.0.roadTransportParameters.loadingTypes"
                  control={control}
                  render={({ field }) => (
                    <LoadTypeControl
                      {...field}
                      hasError={!!errors.routePoints?.[0]?.roadTransportParameters?.loadingTypes}
                    />
                  )}
                />
              </FormField>
            </Col>
            <Col sm={{ span: 12 }} lg={{ span: 10 }}>
              {routePoints.length > 0 &&
                routePoints[0].roadTransportParameters.loadingTypes?.includes(LoadingTransportType.Custom) && (
                  <FormField
                    label="Введите способ погрузки"
                    htmlFor="routePoints.0.roadTransportParameters.loadingTypeCustom"
                    validateMessage={errors.routePoints?.[0]?.roadTransportParameters?.loadingTypeCustom?.message}
                    isRequired
                  >
                    <Controller
                      name="routePoints.0.roadTransportParameters.loadingTypeCustom"
                      control={control}
                      render={({ field }) => (
                        <Input
                          id="loadingTypeCustom"
                          style={{ maxWidth: '342px' }}
                          placeholder="Способ погрузки"
                          {...field}
                          status={errors.routePoints?.[0]?.roadTransportParameters?.loadingTypeCustom && 'error'}
                        />
                      )}
                    />
                  </FormField>
                )}
            </Col>
            <Col lg={{ span: 4 }} />

            <TechnicalCharacteristics control={control} errors={errors} type={RoutePointType.Start} index={0} />
          </Row>
        </Flex>
      ),
    },
    {
      label: 'Груз',
      children: <Load control={control} errors={errors} index={0} />,
    },
    {
      label: 'Приемка',
      children: (
        <Flex vertical gap={24} style={{ padding: '4px 12px' }}>
          <Row gutter={32}>
            <Col span={12}>
              <Flex vertical gap={16}>
                <FormField
                  label="Куда"
                  htmlFor="toAddressType"
                  validateMessage={errors.toAddressType?.message}
                  isRequired
                >
                  <Controller
                    name="toAddressType"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <Radio.Group
                        value={value}
                        onChange={(e) => {
                          onChange(e.target.value)
                          onChangeAddressType(e, 1)
                        }}
                        optionType="button"
                        buttonStyle="solid"
                        size="middle"
                      >
                        {[AddressTypes.address, AddressTypes.warehouse, AddressTypes.elevator].map((value) => (
                          <Radio.Button key={value} value={value}>
                            {getAddressType(value)}
                          </Radio.Button>
                        ))}
                      </Radio.Group>
                    )}
                  />
                </FormField>
              </Flex>
            </Col>
            <Col span={12} />

            <Col sm={{ span: 12 }} lg={{ span: 10 }}>
              {toAddressType === AddressTypes.address && (
                <FormField
                  label="Адрес"
                  htmlFor="routePoints.1.address"
                  validateMessage={errors.routePoints?.[1]?.address?.message}
                  isRequired
                >
                  <Controller
                    name="routePoints.1.address"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <AddressControlDadata
                        value={value}
                        onChange={(val) => onChange(val?.value)}
                        id="routePoints.1.address"
                        placeholder="Введите адрес"
                        hasError={!!errors.routePoints?.[1]?.address}
                      />
                    )}
                  />
                </FormField>
              )}

              {toAddressType === AddressTypes.warehouse && (
                <FormField
                  label="Мой склад"
                  htmlFor="routePoints.1.warehouseId"
                  validateMessage={errors.routePoints?.[1]?.warehouseId?.message}
                  isRequired
                >
                  <Controller
                    name="routePoints.1.warehouseId"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <WarehouseSelectControl
                        id="routePoints.1.warehouseId"
                        value={value}
                        onChange={(data) => {
                          onChange(data?.id)
                          setWarehouseFormValues(data, 1)
                        }}
                        hasError={!!errors.routePoints?.[1]?.warehouseId}
                      />
                    )}
                  />
                </FormField>
              )}

              {toAddressType === AddressTypes.elevator && (
                <FormField
                  label="Базис"
                  htmlFor="routePoints.1.elevatorId"
                  validateMessage={errors.routePoints?.[1]?.elevatorId?.message}
                  isRequired
                >
                  <Controller
                    name="routePoints.1.elevatorId"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <ElevatorSelectControlV2
                        id="routePoints.1.elevatorId"
                        value={value}
                        onChange={(data) => {
                          onChange(data?.id)
                          setElevatorFormValues(data, 1)
                        }}
                        hasError={!!errors.routePoints?.[1]?.elevatorId}
                      />
                    )}
                  />
                </FormField>
              )}
            </Col>
            <Col sm={{ span: 12 }} lg={{ span: 14 }} />

            <Col sm={{ span: 12 }} lg={{ span: 10 }}>
              <FormField
                label="Способ приемки"
                htmlFor="routePoints.1.roadTransportParameters.unloadingTypes"
                validateMessage={errors.routePoints?.[1]?.roadTransportParameters?.unloadingTypes?.message}
                isRequired
              >
                <Controller
                  name="routePoints.1.roadTransportParameters.unloadingTypes"
                  control={control}
                  render={({ field }) => (
                    <UnloadTypeControl
                      {...field}
                      hasError={!!errors.routePoints?.[1]?.roadTransportParameters?.unloadingTypes}
                    />
                  )}
                />
              </FormField>
            </Col>
            <Col sm={{ span: 12 }} lg={{ span: 10 }}>
              {routePoints.length > 1 &&
                routePoints[1].roadTransportParameters.unloadingTypes?.includes(UnloadingTransportType.Custom) && (
                  <FormField
                    label="Введите способ приемки"
                    htmlFor="routePoints.1.roadTransportParameters.unloadingTypeCustom"
                    validateMessage={errors.routePoints?.[1]?.roadTransportParameters?.unloadingTypeCustom?.message}
                    isRequired
                  >
                    <Controller
                      name="routePoints.1.roadTransportParameters.unloadingTypeCustom"
                      control={control}
                      render={({ field }) => (
                        <Input
                          style={{ maxWidth: '342px' }}
                          id="unloadingTypeCustom"
                          placeholder="Способ приемки"
                          {...field}
                          status={errors.routePoints?.[1]?.roadTransportParameters?.unloadingTypeCustom && 'error'}
                        />
                      )}
                    />
                  </FormField>
                )}
            </Col>
            <Col lg={{ span: 4 }} />

            <TechnicalCharacteristics control={control} errors={errors} type={RoutePointType.End} index={1} />
          </Row>
        </Flex>
      ),
    },
  ]

  return (
    <>
      <Text variant="h1">Маршрут и груз</Text>

      <Timeline mode="left" items={timelineItems} />
    </>
  )
}
