import * as yup from 'yup'

import { VALIDATORS_MSG } from '~shared/validations'

export const createAdvertFormByBuyerSchema = yup.object({
  elevatorId: yup.number().required(VALIDATORS_MSG.required),
  culture: yup
    .object({
      // hasError: yup.bool().oneOf([false], 'Параметры культуры заполнены с ошибками'),
    })
    .required(VALIDATORS_MSG.required),
  pricePerTon: yup
    .number()
    .typeError(VALIDATORS_MSG.required)
    .min(100, VALIDATORS_MSG.minValue(100))
    .required(VALIDATORS_MSG.required),
  publicationDateCount: yup.number().when('isUnlimitedDate', {
    is: false,
    then: yup
      .number()
      .typeError(VALIDATORS_MSG.required)
      .min(1, VALIDATORS_MSG.minValue(1))
      .max(100, VALIDATORS_MSG.minValue(100))
      .required('Укажите срок публикации или опубликуйте бессрочно'), // делаем поле обязательным
    otherwise: yup
      .number()
      .transform((value) => (isNaN(value) ? undefined : value))
      .typeError('Укажите срок публикации или опубликуйте бессрочно')
      .min(1, VALIDATORS_MSG.minValue(1))
      .max(100, VALIDATORS_MSG.minValue(100)),
  }),
  isUnlimitedDate: yup.boolean(),
})
