import React from 'react'

import { Button, Space, Spin, Tooltip } from 'antd'
import { BsFillQuestionSquareFill } from 'react-icons/bs'

import { Text } from '~/ui-components'
import { formatPhoneNumber } from '~/utils/formatPhoneNumber'
import { getUserRole } from '~/utils/user/getUserRoleOld'
import { ResponsePageHeader } from '~shared/components'
import { RightPanelEditButton } from '~shared/components/ResponsePageHeader/right-elements'

import { PersonalAccountInfoProps } from './PersonalAccountInfo.types'

import * as S from './PersonalAccountInfo.styled'

export const PersonalAccountInfo: React.FC<PersonalAccountInfoProps> = ({
  onEdit,
  onChangePhoneNumber,
  loading,
  name,
  role,
  email,
  position,
  phoneNumber,
  canEdit,
}) => {
  return (
    <Spin spinning={loading}>
      <S.Wrapper>
        <ResponsePageHeader
          variant="view"
          title="Личные данные"
          subTitle="ПРОФИЛЬ"
          rightElement={<RightPanelEditButton onClick={onEdit} canEdit={canEdit} />}
        />

        <S.ContentWrapper>
          <S.FormReadItem>
            <Text variant="form-read-label">ФИО</Text>
            <Text variant="form-read-value">{name}</Text>
          </S.FormReadItem>

          <S.FormReadItem>
            <Text variant="form-read-label">Роль на платформе</Text>
            <Space>
              <Text variant="form-read-value">{role ? getUserRole(role) : '-'}</Text>
              <Tooltip
                title={
                  <>
                    Ваша роль в компании.
                    <br />
                    Чтобы сменить ее, свяжитесь с нами по номеру +7 (962) 966-44-22
                  </>
                }
              >
                <BsFillQuestionSquareFill size={16} color="#CECECE" />
              </Tooltip>
            </Space>
          </S.FormReadItem>

          <S.FormReadItem>
            <Text variant="form-read-label">Должность в компании</Text>
            <Text variant="form-read-value">{position ? position : 'Не задано'}</Text>
          </S.FormReadItem>

          <S.FormReadItem>
            <Text variant="form-read-label">Номер телефона</Text>
            <Text variant="form-read-value">{formatPhoneNumber(phoneNumber)}</Text>
            <Button type="link" onClick={onChangePhoneNumber}>
              Изменить
            </Button>
          </S.FormReadItem>

          <S.FormReadItem>
            <Text variant="form-read-label">Email</Text>
            <Text variant="form-read-value">{email}</Text>
          </S.FormReadItem>
        </S.ContentWrapper>
      </S.Wrapper>
    </Spin>
  )
}
