import { TabsProps } from 'antd'

interface UrlItem {
  url: string
  title: string
}
export function getTabsFromUrl(items: UrlItem[]): TabsProps['items'] {
  return items.map((tab) => ({
    label: tab.title,
    key: tab.url,
    tabKey: tab.url,
  }))
}
