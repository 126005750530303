import { ProductParameterData, ProductTypeData } from '~api/generated'

import { ProductParametersWithValue } from '~shared/controls/ParametersCultureControl/ParametersCultureControl.types'

export function findProductByType(
  allParametersCultureList: ProductTypeData[],
  productType?: string
): ProductTypeData | null {
  const found = allParametersCultureList.find((x) => x.type === productType)
  if (!found) {
    return null
  }
  return found
}

/**
 * Инициализирует контролы с параметрами культуры. Если параметр передан, то сетит его по дефолту, если не передан то сетит undefined
 */
export function initCultureParameters(
  productType: string | undefined,
  predefinedParametersCulture: ProductParametersWithValue[] | null,
  allProducts: ProductTypeData[]
): ProductParametersWithValue[] {
  const foundProduct = findProductByType(allProducts, productType)

  if (!productType || !foundProduct) {
    return []
  }

  if (predefinedParametersCulture) {
    return foundProduct.parameters.reduce((acc, item) => {
      return acc.concat({
        ...item,
        value: predefinedParametersCulture.find((x) => x.type === item.type)?.value ?? '',
      })
    }, [] as ProductParametersWithValue[])
  }
  return foundProduct.parameters.map((x) => ({
    ...x,
    value: '',
  }))
}
