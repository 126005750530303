import React from 'react'

import { Flex, Table, Typography } from 'antd'
import { ColumnsType } from 'antd/lib/table'

import { getDateFormat } from '~/utils/getDateFormat'
import { FreightDealEntity, RoutePointType } from '~api/gql-generated/graphql'
import { actionArrowColumn } from '~shared/columns/shared'
import { formatNumber } from '~/utils/formatNumber'

export function getFreightDealColumns(onActionArrowClick?: (record: FreightDealEntity) => void) {
  const columns: ColumnsType<FreightDealEntity> = [
    {
      title: 'ID заказа',
      dataIndex: 'id',
      width: 60,
      className: 'bold-cell',
    },
    {
      title: 'ID груза',
      dataIndex: ['order', 'id'],
      width: 60,
      className: 'bold-cell',
    },
    {
      title: 'Дата погрузки',
      className: 'bold-cell',
      dataIndex: ['order', 'startedAt'],
      key: 'startedAt',
      width: 150,
      render: (startedAt) => startedAt && getDateFormat(startedAt),
    },
    {
      title: 'Маршрут',
      dataIndex: ['order', 'route', 'points'],
      width: 200,
      className: 'bold-cell',
      render: (points: FreightDealEntity['order']['route']['points']) => {
        const start = points.find((x) => x.type === RoutePointType.Start)
        const end = points.find((x) => x.type === RoutePointType.End)

        if (start && end) {
          return (
            <Flex align="center" gap={4}>
              <Typography.Text style={{ maxWidth: 100 }} ellipsis={{ tooltip: start.address.short }}>
                {start.address.short}
              </Typography.Text>
              &ndash;
              <Typography.Text style={{ maxWidth: 100 }} ellipsis={{ tooltip: start.address.short }}>
                {end.address.short}
              </Typography.Text>
            </Flex>
          )
        }
        if (start || end) {
          return (
            <Typography.Text
              style={{ maxWidth: 100 }}
              ellipsis={{ tooltip: start?.address.short ?? end?.address.short }}
            >
              {start?.address.short ?? end?.address.short}
            </Typography.Text>
          )
        }
      },
    },
    {
      title: 'Груз',
      dataIndex: ['order', 'route', 'points'],
      render: (points: FreightDealEntity['order']['route']['points']) => {
        const freights = points.filter((x) => !!x.freight).map((x) => x.freight)
        if (!freights.length) {
          return null
        }
        return freights[0]?.productType?.name ?? ''
      },
    },
    {
      title: 'Вес, т',
      dataIndex: ['order', 'route', 'points'],
      render: (points: FreightDealEntity['order']['route']['points']) => {
        const freights = points.filter((x) => !!x.freight).map((x) => x.freight)
        if (!freights.length) {
          return null
        }
        return freights[0]?.weightKg ? formatNumber(freights[0].weightKg / 1000) : ''
      },
    },
    {
      title: 'Ставка, ₽/т',
      render: (v: FreightDealEntity) => {
        return (
          <Flex vertical>
            <Typography.Text>{formatNumber(v.order.pricePerTonWithNds)} (с НДС)</Typography.Text>
            <Typography.Text>{formatNumber(v.order.pricePerTonWithoutNds)} (без НДС)</Typography.Text>
          </Flex>
        )
      },
    },
    Table.EXPAND_COLUMN,
    { ...actionArrowColumn, fixed: 'right', onCell: (record) => ({ onClick: () => onActionArrowClick?.(record) }) },
  ]

  return columns
}
