import React, { useState } from 'react'

import { Modal } from 'antd'
import { useParams } from 'react-router'

import { appToast, modalSharedSettings } from '~/utils'
import { DealSideType } from '~/utils/deals/get-deal-side'
import { DealDocumentData, DealDocumentDataTypeEnum } from '~api/generated'
import { useGetCurrentRole } from '~hooks/auth'
import { useUploadDealDocument } from '~hooks/deal-documents'
import { DealPaymentForm } from '~pages/Deals/modules/DealPayment/components/DealPaymentForm'
import { Document } from '~shared/components'
import { DocumentViewerModal } from '~shared/components/DocumentViewer'

import { IDealPaymentForm } from '~pages/Deals/modules/DealPayment/components/DealPaymentForm/DealPaymentForm.types'

import * as S from './DealPaymentSection.styled'

interface IDealPaymentSectionProps {
  dealId: number
  dealSide: DealSideType
  uploadDocumentButtonText?: string
  document?: DealDocumentData
  canChange: boolean
  documentType?: DealDocumentDataTypeEnum
  afterUploadDocument?: () => void
  afterRemoveDocument?: () => void
  checkDocumentByAdmin?: (instanceId: number) => void
}

export const DealPaymentSection: React.FC<IDealPaymentSectionProps> = (props) => {
  const {
    user: { isAdmin },
  } = useGetCurrentRole()
  const params = useParams<{ id: string | undefined }>()

  const isDocUpload = props.document && props.documentType && props.document.instances?.[0].files?.length

  const uploadDealDocumentFn = useUploadDealDocument()
  const [isDealFormModalVisible, setIsDealFormModalVisible] = useState(false)
  const [viewerDocumentId, setViewerDocumentId] = useState<number | null>(null)

  const handleDealFormModalOpen = () => setIsDealFormModalVisible(true)
  const handleDealFormModalClose = () => setIsDealFormModalVisible(false)
  const handleCheckDocumentByAdmin = (instanceId: number) => props.checkDocumentByAdmin?.(instanceId)

  const handleSubmitChanges = async (formValues: IDealPaymentForm) => {
    if (!formValues.documentFiles?.length) {
      appToast.error({ description: 'Документ не загружен' })
      return
    }

    try {
      await uploadDealDocumentFn.mutateAsync({
        dealId: props.dealId,
        documentType: formValues.documentType,
        createDealDocumentBody: {
          documentFiles: formValues.documentFiles,
          documentInfo: {
            documentNumber: formValues.documentNumber,
            formationDate: formValues.documentDate,
          },
        },
      })
    } catch (e) {
      appToast.error({ description: 'Ошибка при сохранении платежного документа' })
      return
    }

    props.afterUploadDocument?.()
    appToast.success({ description: 'Документ загружен' })
    handleDealFormModalClose()
  }

  const handleShowDocuments = (id: number) => {
    setViewerDocumentId(id)
  }

  return (
    <>
      <DocumentViewerModal
        isOpen={!!viewerDocumentId}
        onCancel={() => setViewerDocumentId(null)}
        key={props.documentType}
        docInstances={props.document?.instances || []}
        // startCheckFromDocInstanceId={props.document?.id}
      />

      {!isDocUpload ? (
        <S.AddDocumentButton onClick={handleDealFormModalOpen}>
          {props.uploadDocumentButtonText || 'Добавить документ'}
        </S.AddDocumentButton>
      ) : (
        props.document &&
        params.id && (
          <Document
            document={props.document}
            dealId={Number(params.id)}
            onShow={(instanceId) =>
              isAdmin && props.checkDocumentByAdmin
                ? handleCheckDocumentByAdmin(instanceId)
                : handleShowDocuments(instanceId)
            }
            onRemoveAfter={props.afterRemoveDocument}
            onUploadAfter={props.afterUploadDocument}
          />
        )
        // <DealPaymentView
        //   key={props.document?.id}
        //   data={{
        //     documentType: props.documentType!,
        //     documentNumber: props.document?.documentInfo?.documentNumber ?? '-',
        //     documentDate: props.document?.documentInfo?.formationDate
        //       ? props.document.documentInfo.formationDate
        //       : new Date().toISOString(),
        //     status: props.document?.status ?? null,
        //     files: props.document?.files,
        //   }}
        //   canChange={props.canChange}
        //   onShowDocuments={handleShowDocuments}
        //   onRemoveDocument={handleRemoveDocument}
        //   onCheckDocumentByAdmin={handleCheckDocumentByAdmin}
        // />
      )}

      <Modal
        {...modalSharedSettings}
        open={isDealFormModalVisible}
        onCancel={handleDealFormModalClose}
        title="Выбор документа"
      >
        <S.Content>
          {isDealFormModalVisible && (
            <DealPaymentForm
              dealSide={props.dealSide}
              onSubmit={handleSubmitChanges}
              isLoading={uploadDealDocumentFn.isLoading}
            />
          )}
        </S.Content>
      </Modal>
    </>
  )
}
