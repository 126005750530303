import React, { useEffect, useState } from 'react'

import { Button } from 'antd'
import { observer } from 'mobx-react-lite'
import { FiFilter } from 'react-icons/fi'
import { useLocation } from 'react-router-dom'

import { appRoutes } from '~/router'
import { getStore } from '~pages/OffersPage/components/OffersFilter/_utils'
import { useApi } from '~providers/api'

import { IOffersFilterForm, OffersFilterForm } from './components/OffersFilterForm'

import { IOffersFilterProps } from '~pages/OffersPage/components/OffersFilter/OffersFilter.types'

import * as S from './OffersFilter.styled'

function getOfferTypeFromUrl(url: string): 'ALL' | 'SELL' | 'BUY' {
  switch (url) {
    case appRoutes.offersListSell.url:
      return 'SELL'
    case appRoutes.offersListBuy.url:
      return 'BUY'
    case appRoutes.offersList.url:
      return 'ALL'
    default:
      return 'ALL'
  }
}
export const OffersFilter: React.FC<IOffersFilterProps> = observer((props) => {
  const api = useApi()
  const store = getStore(props.store)
  const location = useLocation()
  const filterSearchParam = getOfferTypeFromUrl(location.pathname)
  const [isVisible, setVisible] = useState(false)
  const [foundResults, setFoundResults] = useState<number | null>(null)

  // предустановка фильтров для страницы предложений в соответсвии с query params в URL. e.g:/offers/list?filter=SELL
  useEffect(() => {
    switch (filterSearchParam) {
      case 'BUY': {
        store.setFilters({ ...store.filters, offerType: 'SELL' })
        break
      }
      case 'SELL': {
        store.setFilters({ ...store.filters, offerType: 'BUY' })
        break
      }
      case 'ALL': {
        store.setFilters({ ...store.filters, offerType: 'ALL' })
        break
      }
    }
  }, [location])

  const onOpenHandler = () => setVisible(true)
  const onCloseHandler = () => setVisible(false)
  const onResetToDefaultValuesHandler = () => setFoundResults(null)

  const onApplyFilters = (values: IOffersFilterForm) => {
    onCloseHandler()
    store.setFilters(values)
  }

  // filters just changed, not applied (for counting result)
  const onChangeFilters = async (values: IOffersFilterForm): Promise<void> => {
    switch (props.store) {
      case 'admin':
        const r1 = await api.offers.adminGetOffers({
          pageSize: 1,
          minVolume: values.volume.from,
          maxVolume: values.volume.to,
          minPrice: values.price.from,
          maxPrice: values.price.to,
          type: values.offerType === 'ALL' ? undefined : values.offerType,
          regionIds: values.regionIds,
          productTypes: values.productTypes,
          includingNds: values.usingNds,
        })
        setFoundResults(r1.data.pagination.total)
        break
      case 'offers':
        const r2 = await api.offers.getOffers({
          pageSize: 1,
          minVolume: values.volume.from,
          maxVolume: values.volume.to,
          minPrice: values.price.from,
          maxPrice: values.price.to,
          type: values.offerType === 'ALL' ? undefined : values.offerType,
          regionIds: values.regionIds,
          productTypes: values.productTypes,
          warehouseIdForDistance: values.distanceFromWarehouse?.warehouseId,
          maxDistance: values.distanceFromWarehouse?.distance,
          includingNds: values.usingNds,
          statuses: ['PUBLISHED'],
        })
        setFoundResults(r2.data.pagination.total)
        break
      case 'myOffers':
        if (!store.companyId) {
          console.error('companyId is required for my offers filtering')
          break
        }
        const r3 = await api.offers.offerControllerGetCompanyOffers({
          companyId: store.companyId,
          pageSize: 1,
          minVolume: values.volume.from,
          maxVolume: values.volume.to,
          minPrice: values.price.from,
          maxPrice: values.price.to,
          type: values.offerType === 'ALL' ? undefined : values.offerType,
          includingNds: values.usingNds,
          regionIds: values.regionIds,
        })
        setFoundResults(r3.data.pagination.total)
        break
    }
  }

  return (
    <>
      <Button icon={<FiFilter />} onClick={onOpenHandler}>
        Фильтры
        {store.hasActiveFilters ? (
          <S.ActiveFilterCount variant="normal-bold">({store.activeFiltersCount})</S.ActiveFilterCount>
        ) : null}
      </Button>

      <S.StyledDrawer
        destroyOnClose
        className="offers-filter-menu"
        title="Фильтры"
        placement="right"
        onClose={onCloseHandler}
        open={isVisible}
      >
        <OffersFilterForm
          isDisabledOfferType={props.store !== 'admin' && (filterSearchParam === 'SELL' || filterSearchParam === 'BUY')}
          isShowDistanceFromWarehouseControl={props.store === 'offers'}
          defaultValues={store.defaultFiltersConfig}
          currentValues={store.filters}
          onSubmit={onApplyFilters}
          onChange={onChangeFilters}
          onResetToDefaultValues={onResetToDefaultValuesHandler}
          foundResults={foundResults}
        />
      </S.StyledDrawer>
    </>
  )
})
