import React from 'react'

import { Button, Modal } from 'antd'
import { Controller, useForm } from 'react-hook-form'

import { yupResolver } from '@hookform/resolvers/yup/dist/yup'

import { FormField } from '~/ui-components'
import { modalSharedSettings } from '~/utils'
import { PriceControl } from '~shared/controls'

import { MyAdvertEditFormSchema } from './MyAdvertEdit.validation'

import { MyAdvertEditFormProps, MyAdvertEditFormValues } from './MyAdvertEdit.types'

const FORM_ID = 'my-advert-edit-form'

export const MyAdvertEdit: React.FC<MyAdvertEditFormProps> = ({ defaultValues, isOpen, onClose, onSubmit }) => {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<MyAdvertEditFormValues>({
    defaultValues: {
      pricePerTon: defaultValues?.pricePerTon,
    },
    resolver: yupResolver(MyAdvertEditFormSchema),
  })

  const onCancelEditAdvertModalHandler = () => {
    onClose()
  }

  const onSubmitHandler = (formValues: MyAdvertEditFormValues) => {
    onSubmit(formValues)
  }

  return (
    <Modal
      {...modalSharedSettings}
      width={500}
      open={isOpen}
      onCancel={onCancelEditAdvertModalHandler}
      title="Редактировать цену"
      footer={[
        <Button key="cancel" onClick={onCancelEditAdvertModalHandler} htmlType="button">
          Отмена
        </Button>,
        <Button
          key="submit"
          form={FORM_ID}
          type="primary"
          htmlType="submit" /*loading={updateElevatorAdvertFn.isLoading}*/
        >
          Сохранить
        </Button>,
      ]}
    >
      <form id={FORM_ID} onSubmit={handleSubmit(onSubmitHandler)}>
        <FormField htmlFor="pricePerTon" label="Цена" validateMessage={errors.pricePerTon?.message} isRequired>
          <Controller
            name="pricePerTon"
            control={control}
            render={({ field }) => (
              <PriceControl id="pricePerTon" {...field} hasError={!!errors.pricePerTon} addAfter="₽/т" />
            )}
          />
        </FormField>
      </form>
    </Modal>
  )
}
