import React from 'react'

import { Empty, Skeleton } from 'antd'

import { ModeratorDealListMobileItem } from './ModeratorDealListMobileItem'

import { IDealListMobileProps, ModeratorDealListMobileItemType } from './ModeratorDealListMobile.types'

import * as S from '~pages/ModeratorPage/components/ModeratorUserList/components/ModeratorUserListMobile/ModeratorUserListMobileItem.styled'

export const ModeratorDealListMobile: React.FC<IDealListMobileProps> = ({ data, loading, onItemClick }) => {
  const onItemClickHandler = (record: ModeratorDealListMobileItemType) => {
    onItemClick(record)
  }

  if (loading) {
    return <Skeleton />
  }

  if (!data.length) {
    return <Empty />
  }

  return (
    <S.MobileItemsWrapper>
      {data.map((item) => (
        <ModeratorDealListMobileItem key={item.id} {...item} onItemClick={onItemClickHandler} />
      ))}
    </S.MobileItemsWrapper>
  )
}
