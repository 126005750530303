import React, { FC, PropsWithChildren } from 'react'

import { Navigate, useLocation } from 'react-router-dom'

import { appRoutes } from '~/router'

import { useAuth } from './index'

const RequireAuth: FC<PropsWithChildren> = (props) => {
  const { authenticated } = useAuth()
  const location = useLocation()

  if (!authenticated) {
    return <Navigate to={appRoutes.auth.url} state={{ from: location }} replace />
  }

  return <React.Fragment>{props.children}</React.Fragment>
}

export default RequireAuth
