import { useQuery, UseQueryResult } from '@tanstack/react-query'

import {
  ElevatorAdvertOfferApiElevatorAdvertOfferControllerGetMyElevatorAdvertOffersRequest,
  GetMyElevatorAdvertOffersResponse,
} from '~api/generated'
import { useApi } from '~providers/api'

export const useGetElevatorAdvertOffersMy = (
  request: ElevatorAdvertOfferApiElevatorAdvertOfferControllerGetMyElevatorAdvertOffersRequest
): UseQueryResult<GetMyElevatorAdvertOffersResponse> => {
  const api = useApi()

  const fetchFn = async (): Promise<GetMyElevatorAdvertOffersResponse> => {
    const res = await api.elevatorAdvertOffer.elevatorAdvertOfferControllerGetMyElevatorAdvertOffers(request)

    return res.data
  }

  return useQuery(['elevatorsAdvertOffersMy', request], fetchFn, { keepPreviousData: true })
}
