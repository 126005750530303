import { CreateCompanyBody } from '~api/generated'
import { ICompanyInfoForm } from '~pages/ProfilePage/components/tabs/CompanyProfileTab/components/CompanyInfoForm'

function getStringOrNull(value: string | null): string | null {
  return !value?.trim() ? null : value
}
export function mapCompanyFormValueToCreateRequest(
  formValues: ICompanyInfoForm
): Omit<CreateCompanyBody, 'bindingUser'> {
  return {
    inn: formValues.inn,
    // type: undefined,
    // name: formValues.name,
    // usingNds: formValues.usingNds,
    // ogrn: formValues.ogrn,
    // aicCharterMember: formValues.aicCharterMember,
    // director: getStringOrNull(formValues.director),
    // actualAddress: getStringOrNull(formValues.actualAddress),
    // legalAddress: getStringOrNull(formValues.legalAddress),
    // kpp: getStringOrNull(formValues.kpp),
    // codeOkved: getStringOrNull(formValues.codeOkved),
    // signatory: getStringOrNull(formValues.signatory),
    // signatoryPosition: getStringOrNull(formValues.signatoryPosition),
    // signatoryLegalBasis: formValues.signatoryLegalBasis as CreateCompanyBodySignatoryLegalBasisEnum,
  }
}
