import { graphql } from '~api/gql-generated/gql'

export const getDriversListQuery = graphql(/* GraphQL */ `
  query Drivers($filter: DriverFilterInput, $pagination: PaginationInput) {
    drivers(filter: $filter, pagination: $pagination) {
      pagination {
        totalPages
        total
        pageSize
        currentPage
      }
      drivers {
        id
        phoneNumber
        name
        driverData {
          birthDate
          licenseNumber
          licenseIssuedAt
          licenseExpiredAt
          additionalDetails
        }
        transports {
          id
          companyId
          transportStationId
          type
          brand
          bodyTypeId
          axlesCount
          manufacturingDate
          vin
          registrationCertificate
          transportPassport
          licensePlate
          ownershipType
          ownerName
          lengthCm
          widthCm
          heightCm
          loadCapacityKg
          loadVolumeL
          loadingMethodId
          adrCertificate
          shortRouteKm
          longRouteKm
          preferredRouteType
          canWorkInField
          additionalDetails
          createdAt
          updatedAt
          deletedAt
          bodyType {
            id
            name
          }
          loadingMethod {
            id
            name
          }
        }
      }
    }
  }
`)

export const getDriverQuery = graphql(/* GraphQL */ `
  query Driver($driverId: Int!) {
    driver(driverId: $driverId) {
      phoneNumber
      name
      id
      driverData {
        birthDate
        licenseNumber
        licenseIssuedAt
        licenseExpiredAt
        additionalDetails
      }
      transports {
        id
        companyId
        transportStationId
        type
        brand
        bodyTypeId
        axlesCount
        manufacturingDate
        vin
        registrationCertificate
        transportPassport
        licensePlate
        ownershipType
        ownerName
        lengthCm
        widthCm
        heightCm
        loadCapacityKg
        loadVolumeL
        loadingMethodId
        adrCertificate
        shortRouteKm
        longRouteKm
        preferredRouteType
        canWorkInField
        additionalDetails
        createdAt
        updatedAt
        deletedAt
        bodyType {
          id
          name
        }
        loadingMethod {
          id
          name
        }
      }
    }
  }
`)
