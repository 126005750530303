import React, { useEffect } from 'react'

import { notification } from 'antd'

import { useQueryClient } from '@tanstack/react-query'

import { appToast } from '~/utils'
import { usePrevious } from '~hooks/common'
import { useGetUnreadNotificationsByInterval, useMarkNotificationRead } from '~hooks/notifications'
import { parseNotification } from '~pages/Notifications/NotificationsPage/utils/parse-notification'
import { useAuth } from '~providers/auth'

export const Notifications: React.FC = () => {
  const { authenticated, user } = useAuth()

  const unreadNotificationFn = useGetUnreadNotificationsByInterval()
  const markNotificationReadFn = useMarkNotificationRead()
  const queryClient = useQueryClient()
  const lastUnreadNotification = unreadNotificationFn.data?.lastUnreadNotification ?? null
  const totalUnreadNotifications = unreadNotificationFn.data?.totalUnreadNotifications ?? null
  const previousUnreadNotification = usePrevious(unreadNotificationFn.data)

  useEffect(() => {
    if (!authenticated || !lastUnreadNotification || !totalUnreadNotifications || !previousUnreadNotification) {
      return
    }

    const isListLengthIncrease = totalUnreadNotifications > previousUnreadNotification.totalUnreadNotifications
    const hasNewUnread = !!lastUnreadNotification && isListLengthIncrease

    const markNotificationAsRead = async () => {
      await markNotificationReadFn.mutateAsync(lastUnreadNotification.id)
      await queryClient.invalidateQueries(['unreadNotifications'])
    }

    if (hasNewUnread) {
      const notificationKey = lastUnreadNotification.id.toString()
      appToast.notification({
        description: parseNotification({
          text: lastUnreadNotification.message,
          useRouting: false,
          userRole: user?.role,
        }),
        key: notificationKey,
        onClick: async () => {
          await markNotificationAsRead()
          notification.destroy(notificationKey)
        },
      })
    }
  }, [totalUnreadNotifications])

  return null
}
