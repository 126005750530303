/* tslint:disable */
/* eslint-disable */
/**
 * API Зерно-Агрегатора
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CreateAccountBody } from '../models';
// @ts-ignore
import { GetAuthUserResponse } from '../models';
// @ts-ignore
import { LoginDto } from '../models';
// @ts-ignore
import { SendSmsCodeBody } from '../models';
/**
 * AuthApi - axios parameter creator
 * @export
 */
export const AuthApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Создать новый аккаунт
         * @param {CreateAccountBody} createAccountBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerCreateAccount: async (createAccountBody: CreateAccountBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createAccountBody' is not null or undefined
            assertParamExists('authControllerCreateAccount', 'createAccountBody', createAccountBody)
            const localVarPath = `/auth/account`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createAccountBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Выполнить вход
         * @param {LoginDto} loginDto 
         * @param {string} [userAgent] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerLogin: async (loginDto: LoginDto, userAgent?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'loginDto' is not null or undefined
            assertParamExists('authControllerLogin', 'loginDto', loginDto)
            const localVarPath = `/auth/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (userAgent !== undefined && userAgent !== null) {
                localVarHeaderParameter['user-agent'] = String(userAgent);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(loginDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Выполнить выход из аккаунта
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerLogout: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/auth/logout`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Выполнить вход
         * @param {number} userId 
         * @param {string} [userAgent] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerModeratorAsUserLogin: async (userId: number, userAgent?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('authControllerModeratorAsUserLogin', 'userId', userId)
            const localVarPath = `/auth/{userId}/login`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (userAgent !== undefined && userAgent !== null) {
                localVarHeaderParameter['user-agent'] = String(userAgent);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Запросить SMS с кодом для регистрации пользователя
         * @param {SendSmsCodeBody} sendSmsCodeBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerSendSmsCode: async (sendSmsCodeBody: SendSmsCodeBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sendSmsCodeBody' is not null or undefined
            assertParamExists('authControllerSendSmsCode', 'sendSmsCodeBody', sendSmsCodeBody)
            const localVarPath = `/auth/send-sms-code`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(sendSmsCodeBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthApi - functional programming interface
 * @export
 */
export const AuthApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AuthApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Создать новый аккаунт
         * @param {CreateAccountBody} createAccountBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authControllerCreateAccount(createAccountBody: CreateAccountBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authControllerCreateAccount(createAccountBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Выполнить вход
         * @param {LoginDto} loginDto 
         * @param {string} [userAgent] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authControllerLogin(loginDto: LoginDto, userAgent?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAuthUserResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authControllerLogin(loginDto, userAgent, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Выполнить выход из аккаунта
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authControllerLogout(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authControllerLogout(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Выполнить вход
         * @param {number} userId 
         * @param {string} [userAgent] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authControllerModeratorAsUserLogin(userId: number, userAgent?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAuthUserResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authControllerModeratorAsUserLogin(userId, userAgent, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Запросить SMS с кодом для регистрации пользователя
         * @param {SendSmsCodeBody} sendSmsCodeBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authControllerSendSmsCode(sendSmsCodeBody: SendSmsCodeBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authControllerSendSmsCode(sendSmsCodeBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AuthApi - factory interface
 * @export
 */
export const AuthApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AuthApiFp(configuration)
    return {
        /**
         * 
         * @summary Создать новый аккаунт
         * @param {CreateAccountBody} createAccountBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerCreateAccount(createAccountBody: CreateAccountBody, options?: any): AxiosPromise<void> {
            return localVarFp.authControllerCreateAccount(createAccountBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Выполнить вход
         * @param {LoginDto} loginDto 
         * @param {string} [userAgent] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerLogin(loginDto: LoginDto, userAgent?: string, options?: any): AxiosPromise<GetAuthUserResponse> {
            return localVarFp.authControllerLogin(loginDto, userAgent, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Выполнить выход из аккаунта
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerLogout(options?: any): AxiosPromise<string> {
            return localVarFp.authControllerLogout(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Выполнить вход
         * @param {number} userId 
         * @param {string} [userAgent] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerModeratorAsUserLogin(userId: number, userAgent?: string, options?: any): AxiosPromise<GetAuthUserResponse> {
            return localVarFp.authControllerModeratorAsUserLogin(userId, userAgent, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Запросить SMS с кодом для регистрации пользователя
         * @param {SendSmsCodeBody} sendSmsCodeBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerSendSmsCode(sendSmsCodeBody: SendSmsCodeBody, options?: any): AxiosPromise<string> {
            return localVarFp.authControllerSendSmsCode(sendSmsCodeBody, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for authControllerCreateAccount operation in AuthApi.
 * @export
 * @interface AuthApiAuthControllerCreateAccountRequest
 */
export interface AuthApiAuthControllerCreateAccountRequest {
    /**
     * 
     * @type {CreateAccountBody}
     * @memberof AuthApiAuthControllerCreateAccount
     */
    readonly createAccountBody: CreateAccountBody
}

/**
 * Request parameters for authControllerLogin operation in AuthApi.
 * @export
 * @interface AuthApiAuthControllerLoginRequest
 */
export interface AuthApiAuthControllerLoginRequest {
    /**
     * 
     * @type {LoginDto}
     * @memberof AuthApiAuthControllerLogin
     */
    readonly loginDto: LoginDto

    /**
     * 
     * @type {string}
     * @memberof AuthApiAuthControllerLogin
     */
    readonly userAgent?: string
}

/**
 * Request parameters for authControllerModeratorAsUserLogin operation in AuthApi.
 * @export
 * @interface AuthApiAuthControllerModeratorAsUserLoginRequest
 */
export interface AuthApiAuthControllerModeratorAsUserLoginRequest {
    /**
     * 
     * @type {number}
     * @memberof AuthApiAuthControllerModeratorAsUserLogin
     */
    readonly userId: number

    /**
     * 
     * @type {string}
     * @memberof AuthApiAuthControllerModeratorAsUserLogin
     */
    readonly userAgent?: string
}

/**
 * Request parameters for authControllerSendSmsCode operation in AuthApi.
 * @export
 * @interface AuthApiAuthControllerSendSmsCodeRequest
 */
export interface AuthApiAuthControllerSendSmsCodeRequest {
    /**
     * 
     * @type {SendSmsCodeBody}
     * @memberof AuthApiAuthControllerSendSmsCode
     */
    readonly sendSmsCodeBody: SendSmsCodeBody
}

/**
 * AuthApi - object-oriented interface
 * @export
 * @class AuthApi
 * @extends {BaseAPI}
 */
export class AuthApi extends BaseAPI {
    /**
     * 
     * @summary Создать новый аккаунт
     * @param {AuthApiAuthControllerCreateAccountRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authControllerCreateAccount(requestParameters: AuthApiAuthControllerCreateAccountRequest, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).authControllerCreateAccount(requestParameters.createAccountBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Выполнить вход
     * @param {AuthApiAuthControllerLoginRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authControllerLogin(requestParameters: AuthApiAuthControllerLoginRequest, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).authControllerLogin(requestParameters.loginDto, requestParameters.userAgent, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Выполнить выход из аккаунта
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authControllerLogout(options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).authControllerLogout(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Выполнить вход
     * @param {AuthApiAuthControllerModeratorAsUserLoginRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authControllerModeratorAsUserLogin(requestParameters: AuthApiAuthControllerModeratorAsUserLoginRequest, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).authControllerModeratorAsUserLogin(requestParameters.userId, requestParameters.userAgent, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Запросить SMS с кодом для регистрации пользователя
     * @param {AuthApiAuthControllerSendSmsCodeRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authControllerSendSmsCode(requestParameters: AuthApiAuthControllerSendSmsCodeRequest, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).authControllerSendSmsCode(requestParameters.sendSmsCodeBody, options).then((request) => request(this.axios, this.basePath));
    }
}
