import React from 'react'

import { Table } from 'antd'
import { ColumnsType } from 'antd/lib/table'

import { useGetTableHeight } from '~/hooks'
import { DealStatusBadge } from '~/utils/deals/DealStatusBadge'
import { formatNumber } from '~/utils/formatNumber'
import { getDateFormat } from '~/utils/getDateFormat'
import { CompanyDealDataStatusEnum } from '~api/generated'
import { actionArrowColumn, locationColumn, ndsColumn } from '~shared/columns/shared'

import { DealListTableDataType, IDealListTableProps } from './DealListTable.types'

export const DealListTable: React.FC<IDealListTableProps> = ({ tableData, loading, onRowClick }) => {
  const tableHeight = useGetTableHeight(288)
  const onRowClickHandler = (record: DealListTableDataType) => {
    onRowClick(record)
  }

  const columns: ColumnsType<DealListTableDataType> = [
    {
      title: 'Статус',
      dataIndex: 'status',
      key: 'status',
      width: '10%',
      render: (value: CompanyDealDataStatusEnum) => <DealStatusBadge status={value} />,
    },
    {
      title: 'Наименование культуры',
      dataIndex: 'culture',
      key: 'culture',
      width: 200,
    },
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      width: 100,
    },
    {
      title: 'Объем, т',
      dataIndex: 'quantity',
      key: 'quantity',
      width: 100,
    },
    {
      title: 'На склад',
      dataIndex: 'warehouse',
      key: 'warehouse',
    },
    locationColumn,
    {
      title: 'Цена, ₽/т',
      dataIndex: 'price',
      key: 'price',
      width: 120,
      render: (value) => formatNumber(value),
    },
    ndsColumn,
    {
      title: 'Дата создания',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 150,
    },
    {
      title: 'Дата начала',
      dataIndex: 'startedAt',
      key: 'startedAt',
      width: 150,
      render: (startedAt) => startedAt && getDateFormat(startedAt),
    },
    actionArrowColumn,
  ]

  return (
    <Table
      rowKey="id"
      className="table-interactive"
      loading={loading}
      columns={columns}
      dataSource={tableData}
      scroll={{ x: 'max-content', y: tableHeight }}
      pagination={false}
      onRow={(record, index) => ({
        onClick: onRowClickHandler.bind(null, record, index),
      })}
    />
  )
}
