import React from 'react'

import { Radio, RadioChangeEvent } from 'antd'

import { CustomControlInterface } from '~shared/controls/CustomControl.interface'

interface IProps extends CustomControlInterface {
  onChange: (value: Value) => void
  value: Value | undefined
}

type Value = 'other' | 'zernoAgregator'

export const ZernoAgrOrAnotherCompanyRadioControl: React.FC<IProps> = React.forwardRef<HTMLDivElement, IProps>(
  ({ value, onChange }, ref) => {
    const onChangeHandler = (e: RadioChangeEvent) => {
      onChange(e.target.value)
    }

    return (
      <Radio.Group onChange={onChangeHandler} value={value} optionType="button" buttonStyle="solid">
        <Radio value="other">Другой компании</Radio>
        <Radio value="zernoAgregator">Зерно-агрегатор</Radio>
      </Radio.Group>
    )
  }
)

ZernoAgrOrAnotherCompanyRadioControl.displayName = 'ZernoAgrOrAnotherCompanyRadioControl'
