import { NavLink } from 'react-router-dom'
import styled from 'styled-components'

import { breakpoints } from '~/tokens'

export const StyledNav = styled.nav`
  display: none;

  @media (max-width: ${breakpoints.MOBILE_XL}px) {
    margin-top: auto;
    display: flex;
    background: #fafafb;
    padding: 8px;
    justify-content: space-between;
    gap: 4px;
    border-top: 0.5px solid var(--gray-2-color);
  }
`
export const StyledNavLink = styled(NavLink)`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
  color: var(--gray-8-color) !important;
  min-width: 50px;

  &.active {
    color: var(--primary-main-color) !important;
  }

  &:hover {
    color: var(--primary-main-color) !important;
  }
`

export const LinkText = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  text-align: center;
  letter-spacing: -0.24px;
`
