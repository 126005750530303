import styled from 'styled-components'
import { Flex } from 'antd'

export const Wrapper = styled(Flex)`
  padding: 24px;
`

export const TextDeal = styled.div`
  font-size: 28px;
  font-weight: 700;
  color: black;
`

export const TextId = styled.div`
  font-size: 24px;
  font-weight: 400;
  color: #8c8c8c;
`

export const CompleteOrderWrapper = styled(Flex)`
  border-left: 2px solid var(--primary-main-color);
  padding: 4px 16px;
  background-color: #ecf6f2;
`

export const AcceptAllWrapper = styled(Flex)`
  border-left: 2px solid #ff9900;
  padding: 4px 16px;
  background-color: #ff99001a;
`
