import * as yup from 'yup'

import { transformNanToUndefined } from '~shared/validations/schemas'

export const createElevatorFormStep3RailSchema = yup.object({
  stationName: yup.string(),
  hasRailWay: yup.boolean(),
  hasRailScales: yup.boolean(),
  hasRailStock: yup.boolean(),
  hasTecEco: yup.boolean(),
  railLoadSpeed: yup.number().transform(transformNanToUndefined),
  waitingArea: yup.number().transform(transformNanToUndefined),
  railAdditionalInfo: yup.string(),
})
