import React from 'react'

import { Button, Modal, Select, Tooltip, Upload } from 'antd'
import { UploadRequestOption } from 'rc-upload/lib/interface'
import { FiEye, FiTrash2, FiUpload } from 'react-icons/fi'

import { AppConfig } from '~/appConfig'
import { appToast } from '~/utils'
import { DocumentPartStatus } from '~api/generated'
import { useGetCurrentRole } from '~hooks/auth'
import { getDealDocumentStatusDropdownOptions } from '~shared/components/DocumentChecker/utils'

interface IDocumentActions {
  status: DocumentPartStatus | undefined
  onChangeDocumentStatus?: (newStatus: DocumentPartStatus) => void
  isUploaded: boolean
  onShow: () => void
  onRemove: () => void
  onUploadFile: (uploadedFile: File) => void
}

export const DocumentActions: React.FC<IDocumentActions> = ({
  isUploaded = false,
  onUploadFile,
  onRemove,
  onShow,
  status,
  onChangeDocumentStatus,
}) => {
  const {
    user: { isAdmin },
  } = useGetCurrentRole()
  const uploadHandler = (r: UploadRequestOption<File>) => {
    const file = r.file as File
    const isOverMaxLim = file.size / 1024 / 1024 > AppConfig.MAX_DOCUMENT_FILE_SIZE_MB

    if (isOverMaxLim) {
      appToast.error({ description: `Размер файла превышает ${AppConfig.MAX_DOCUMENT_FILE_SIZE_MB}мб` })
      return
    }
    onUploadFile(file)
  }

  const showChangeStatusConfirm = (newStatus: DocumentPartStatus) => {
    Modal.confirm({
      title: 'Вы действительно хотите изменить статус документа?',
      content: (
        <>
          Статус будет изменен на{' '}
          <b>{getDealDocumentStatusDropdownOptions().find((x) => x.value === newStatus)?.label}</b>
        </>
      ),
      okText: 'Изменить',
      cancelText: 'Отмена',
      onOk() {
        onChangeDocumentStatus?.(newStatus)
      },
    })
  }

  return (
    <>
      {isUploaded && (
        <>
          {isAdmin && (
            <Select
              defaultValue={status}
              style={{ width: '100%' }}
              options={getDealDocumentStatusDropdownOptions()}
              onSelect={showChangeStatusConfirm}
            />
          )}

          <Tooltip title="посмотреть документ">
            <Button size="small" type="text" icon={<FiEye size={24} />} onClick={onShow} />
          </Tooltip>

          <Tooltip title="удалить документ">
            <Button size="small" type="text" icon={<FiTrash2 color="red" size={24} />} onClick={onRemove} />
          </Tooltip>
        </>
      )}

      <Upload accept="application/pdf" showUploadList={false} customRequest={uploadHandler}>
        <Tooltip title="загрузить документ">
          <Button size="small" type="text" icon={<FiUpload size={24} />} />
        </Tooltip>
      </Upload>
    </>
  )
}
