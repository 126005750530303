import { useMutation } from '@tanstack/react-query'

import { DealApiDealControllerUpdateDealStatusRequest } from '~api/generated'
import { useApi } from '~providers/api'

export const useUpdateDealStatus = () => {
  const api = useApi()

  return useMutation((request: DealApiDealControllerUpdateDealStatusRequest) => {
    return api.deal.dealControllerUpdateDealStatus(request)
  })
}
