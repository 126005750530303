import { useMutation } from '@tanstack/react-query'

import { CompanyApiCompanyDocumentControllerUpdateDocumentStatusRequest } from '~api/generated'
import { useApi } from '~providers/api'

export const useUpdateDocumentStatus = () => {
  const api = useApi()

  const updateDocumentStatusFn = async (
    request: CompanyApiCompanyDocumentControllerUpdateDocumentStatusRequest
  ): Promise<void> => {
    const res = await api.companies.companyDocumentControllerUpdateDocumentStatus(request)
    return res.data
  }

  return useMutation(updateDocumentStatusFn)
}
