import React from 'react'

import { Button } from 'antd'
import { FiTrash2 } from 'react-icons/fi'

import { Text } from '~/ui-components'

import { CompanyAccountPayRequisitesListItemProps } from './CompanyAccountPayRequisitesListItem.types'

import * as S from './CompanyAccountPayRequisitesListItem.styled'

export const CompanyAccountPayRequisitesListItem: React.FC<CompanyAccountPayRequisitesListItemProps> = ({
  id,
  bic,
  correspondentAccount,
  bankName,
  paymentAccount,
  canRemove,
  onRemove,
}) => {
  return (
    <S.Wrapper>
      <S.Title>Реквизиты {bankName}</S.Title>
      <S.Row>
        <Text variant="form-read-label">Расчетный счет</Text>
        <Text variant="normal">{paymentAccount}</Text>
      </S.Row>
      <S.Row>
        <Text variant="form-read-label">Корр. счет</Text>
        <Text variant="normal">{correspondentAccount}</Text>
      </S.Row>
      <S.Row>
        <Text variant="form-read-label">БИК</Text>
        <Text variant="normal">{bic}</Text>
      </S.Row>
      <S.Row>
        <span />
        <Button danger type="text" icon={<FiTrash2 size={24} />} onClick={() => onRemove(id)} disabled={!canRemove} />
      </S.Row>
    </S.Wrapper>
  )
}
