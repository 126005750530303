import styled from 'styled-components'

import { EmptyButton, Text } from '~/ui-components'

export const Content = styled.div`
  display: grid;
  gap: 24px;
`

export const Subtitle = styled(Text).attrs({ variant: 'h4' })`
  margin-bottom: 16px;
`

export const DocumentWrapper = styled.div`
  display: grid;
  gap: 8px;
`
export const EmptyDocument = styled(EmptyButton)`
  min-height: 112px;
`
