import styled from 'styled-components'

export const StyledTable = styled.div`
  .deleted {
    background: linear-gradient(0deg, #f5cdcd, #f5cdcd), #ffffff;
  }
  .isCreationRequest {
    background: rgb(252, 242, 189);
  }
`
