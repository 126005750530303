import { isEqual } from 'lodash'
import { makeAutoObservable } from 'mobx'

import { getActiveFilters } from '~/store/filters/_utils'
import { ElevatorApiElevatorControllerGetElevatorsMyRequest, ElevatorOfferStatus } from '~api/generated'
import { RegionSelectControlValue } from '~shared/controls/RegionSelectControl'

export interface IBasisFilterForm {
  elevatorId: number | undefined
  productTypes: RegionSelectControlValue
  advertStatuses: ElevatorOfferStatus[]
  advertType: 'BUY' | 'SELL' | undefined
}

// Filter panel shows this values after first open
const defaultFiltersConfig: IBasisFilterForm = {
  elevatorId: undefined,
  productTypes: [],
  advertStatuses: [],
  advertType: undefined,
}

class MyBasisAdvertsFilterStore {
  private filters: IBasisFilterForm

  constructor() {
    this.filters = defaultFiltersConfig
    makeAutoObservable(this)
  }

  setFilters(newFilters: IBasisFilterForm): void {
    this.filters = newFilters
  }

  get basisFilters(): IBasisFilterForm {
    return this.filters
  }

  get defaultFiltersConfig(): IBasisFilterForm {
    return defaultFiltersConfig
  }

  get isCurrentFilterDefault(): boolean {
    return isEqual(this.filters, defaultFiltersConfig)
  }

  get mappedCurrentFiltersToResponse(): Partial<ElevatorApiElevatorControllerGetElevatorsMyRequest> {
    return this.isCurrentFilterDefault
      ? {}
      : {
          elevatorId: this.filters.elevatorId,
          productTypes: this.filters.productTypes.length ? this.filters.productTypes : undefined,
        }
  }

  get activeFilters(): string[] {
    return getActiveFilters(this.filters, defaultFiltersConfig)
  }

  get hasActiveFilters(): boolean {
    return this.activeFilters.length > 0
  }

  get activeFiltersCount(): number {
    return this.activeFilters.length
  }
}

export const myBasisAdvertsFilterStore = new MyBasisAdvertsFilterStore()
export const myBasisAdvertOffersFilterStore = new MyBasisAdvertsFilterStore()
