import React from 'react'

import { Button, Dropdown, Tooltip } from 'antd'
import { ItemType } from 'antd/es/menu/interface'
import { FiPlus, FiPlusCircle } from 'react-icons/fi'

import { DownOutlined } from '@ant-design/icons'

import { useGetDevice } from '~hooks/common'

interface CompanyEmployeeAddRightMenuProps {
  onCreate: () => void
  onAddFromAnotherCompany: () => void
  canUserCreateNewUser: boolean
  canUserTransferUser: boolean
}
export const CompanyEmployeeAddRightMenu: React.FC<CompanyEmployeeAddRightMenuProps> = (props) => {
  const device = useGetDevice()
  const isMobile = device === 'mobile'

  const menuItems: ItemType[] = [
    {
      label: (
        <Tooltip title={!props.canUserCreateNewUser ? 'Не хватает прав' : undefined}>
          {isMobile ? 'Добавить нового' : 'Добавить нового сотрудника'}
        </Tooltip>
      ),
      key: 'addNew',
      icon: isMobile ? <FiPlus /> : null,
      onClick: props.onCreate,
      disabled: !props.canUserCreateNewUser,
    },
    {
      label: (
        <Tooltip title={!props.canUserCreateNewUser ? 'Не хватает прав' : undefined}>
          {isMobile ? 'Добавить из другой компании' : 'Добавить сотрудника из другой компании'}
        </Tooltip>
      ),
      key: 'addExisting',
      icon: isMobile ? <FiPlus /> : null,
      onClick: props.onAddFromAnotherCompany,
      disabled: !props.canUserTransferUser,
    },
  ]

  return (
    <Dropdown menu={{ items: menuItems }} trigger={['click']}>
      {isMobile ? (
        <Button type="text" icon={<FiPlusCircle size={18} />} />
      ) : (
        <Button type="primary">
          Добавить сотрудника <DownOutlined />
        </Button>
      )}
    </Dropdown>
  )
}
