import React from 'react'

import { ColumnsType } from 'antd/lib/table'

import { useGetProductTypeData } from '~/hooks'
import { Badge } from '~/ui-components'
import { formatNumber } from '~/utils/formatNumber'
import { getDateFormat } from '~/utils/getDateFormat'
import { getNdsString } from '~/utils/getNdsString'
import { getOfferStatus } from '~/utils/offers/get-offer-status'
import { CompanyOfferData } from '~api/generated'
import { useGetCurrentRole } from '~hooks/auth'
import { actionArrowColumn } from '~shared/columns/shared'

export const useGetColumnsMyOffers = () => {
  const {
    user: { isAdmin },
  } = useGetCurrentRole()
  const { getProductType, getProductTypeParametersForUI_V2 } = useGetProductTypeData()

  const columns: ColumnsType<CompanyOfferData> = [
    ...(isAdmin
      ? [
          {
            title: '№',
            dataIndex: 'id',
          },
        ]
      : []),
    {
      title: 'Дата публикации',
      dataIndex: 'publishedAt',
      width: 150,
      render: (publishedAt) => publishedAt && getDateFormat(publishedAt),
    },
    {
      title: 'Статус предложения',
      dataIndex: 'status',
      width: 100,
      render: (value) => {
        const offerStatus = getOfferStatus(value)
        return (
          <Badge color={offerStatus.color} size="s">
            {offerStatus.name}
          </Badge>
        )
      },
    },
    {
      title: 'Наименование культуры',
      dataIndex: ['product', 'name'],
      width: 200,
      className: 'bold-cell',
      // render: (value: string) => {
      //   return getProductType(value)?.name
      // },
    },
    {
      title: 'Параметры',
      dataIndex: 'product',
      width: 180,
      render: (value: CompanyOfferData['product'], row) => {
        return getProductTypeParametersForUI_V2(value)
          .slice(0, 2)
          .map((param) => <div key={param}>{param}</div>)
      },
    },
    {
      title: 'Объем, т',
      dataIndex: 'volume',
      align: 'right',
      width: '100px',
      className: 'bold-cell',
      render: (volume) => {
        return formatNumber(volume)
      },
    },
    {
      title: 'Цена, ₽/т',
      dataIndex: 'price',
      align: 'right',
      width: '120px',
      className: 'bold-cell',
      render: (price) => {
        return formatNumber(price)
      },
    },
    {
      title: 'НДС',
      dataIndex: 'includingNds',
      width: '100px',
      render: (includingNds, row) => {
        return getNdsString(row.includingNds)
      },
    },
    {
      title: 'Локация',
      dataIndex: 'address',
    },
    {
      title: 'Дата публикации',
      dataIndex: 'publishedAt',
      width: '160px',
      render: (publishedAt) => {
        return publishedAt ? getDateFormat(new Date(publishedAt)) : ''
      },
    },
    actionArrowColumn,
  ]

  return columns
}
