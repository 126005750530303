import React, { useState } from 'react'

import { Button, Flex, Spin } from 'antd'
import { FormProvider, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

import { useMutation } from '@apollo/client'
import { ApolloError } from '@apollo/client/errors'
import { yupResolver } from '@hookform/resolvers/yup/dist/yup'

import { AppHeader } from '~/layout'
import { appRoutes } from '~/router'
import { appToast } from '~/utils'
import { graphqlErrorHandler } from '~/utils/graphqlErrorHandler'
import { FreightPackingType, RoutePointType } from '~api/gql-generated/graphql'
import { freightOrderDocumentCreateMutation } from '~api/gql-mutations/freight-order-document.mutation.graphql'
import { createLoadMutation } from '~api/gql-mutations/logistics.mutation.graphql'
import { useUploadFileAndGetId } from '~hooks/_utils'
import { Additional } from '~pages/LogisticsPage/modules/MyCargos/components/CreateLoad/components/Additional'
import { Conditions } from '~pages/LogisticsPage/modules/MyCargos/components/CreateLoad/components/Conditions'
import { RouteAndLoad } from '~pages/LogisticsPage/modules/MyCargos/components/CreateLoad/components/RouteAndLoad'
import { Transport } from '~pages/LogisticsPage/modules/MyCargos/components/CreateLoad/components/Transport'
import { useAuth } from '~providers/auth'

import { Documents } from './components/Documents/Documents'
import { LoadingDate } from './components/LoadingDate/LoadingDate'

import { Row } from './components/Documents/Documents.types'
import {
  CreateLoadSchema,
  CreateLoadValues,
} from '~pages/LogisticsPage/modules/MyCargos/components/CreateLoad/CreateLoad.types'

import * as S from './CreateLoad.styled'

export const CreateLoad: React.FC = () => {
  const navigate = useNavigate()
  const { companyId } = useAuth()
  const [documents, setDocuments] = useState<Row[]>([])

  const methods = useForm<CreateLoadValues>({
    defaultValues: {
      routePoints: [
        {
          address: undefined,
          elevatorId: undefined,
          warehouseId: undefined,
          type: RoutePointType.Start,
          freight: {
            packingType: undefined,
            packingTypeCustom: undefined,
            bigBagsCount: undefined,
            productTypeId: undefined,
            weightKg: undefined,
          },
          roadTransportParameters: {
            loadingTypes: [],
            unloadingTypes: [],
            loadingTypeCustom: undefined,
            unloadingTypeCustom: undefined,
            loadingThroughput: undefined,
            unloadingThroughput: undefined,
            vehicleLengthMaxM: undefined,
            vehicleHeightMaxM: undefined,
            vehicleClearanceMinCm: undefined,
            hasParking: undefined,
            hasScales: undefined,
            hasWeighingAutomation: undefined,
            distanceToScalesM: undefined,
            scalesWeightLimitT: undefined,
            roadSurfaceType: undefined,
            roadSurfaceQuality: undefined,
            additionalDetails: undefined,
          },
        },
        {
          address: undefined,
          elevatorId: undefined,
          warehouseId: undefined,
          type: RoutePointType.End,
          freight: {
            packingType: FreightPackingType.Bulk,
            packingTypeCustom: undefined,
            bigBagsCount: undefined,
            productTypeId: 0,
            weightKg: 0,
          },
          roadTransportParameters: {
            loadingTypes: [],
            unloadingTypes: [],
            loadingTypeCustom: undefined,
            unloadingTypeCustom: undefined,
            loadingThroughput: undefined,
            unloadingThroughput: undefined,
            vehicleLengthMaxM: undefined,
            vehicleHeightMaxM: undefined,
            vehicleClearanceMinCm: undefined,
            hasParking: undefined,
            hasScales: undefined,
            hasWeighingAutomation: undefined,
            distanceToScalesM: undefined,
            scalesWeightLimitT: undefined,
            roadSurfaceType: undefined,
            roadSurfaceQuality: undefined,
            additionalDetails: undefined,
          },
        },
      ],
      readinessType: undefined,
      startedAt: undefined,
      endedAt: undefined,
      startDaysCount: undefined,
      transportFormatTypes: [],
      transportBodyTypeIds: [],
      pricePerTonWithoutNds: undefined,
      isAuction: undefined,
      paymentTerm: undefined,
      advancePercent: undefined,
      deferredDaysCount: undefined,
      contactPerson: undefined,
      contactPhoneNumber: undefined,
      additionalDetails: undefined,
      fromAddressType: undefined,
      toAddressType: undefined,
    },
    resolver: yupResolver(CreateLoadSchema),
  })

  const {
    control,
    formState: { errors },
    handleSubmit,
  } = methods

  const uploadFileAndGetId = useUploadFileAndGetId()
  const [createDocument] = useMutation(freightOrderDocumentCreateMutation)
  const [createLoad] = useMutation(createLoadMutation)

  const onCreateLoad = async (formValues: CreateLoadValues) => {
    try {
      const { data } = await createLoad({
        variables: {
          input: {
            companyId,
            routePoints: [
              {
                ...formValues.routePoints[0],
                address: formValues.routePoints[0].address ?? null,
                elevatorId: null,
                warehouseId: null,
                freight: {
                  ...formValues.routePoints[0].freight,
                  productTypeId: formValues.routePoints[0].freight.productTypeId,
                  weightKg: formValues.routePoints[0].freight.weightKg * 1000,
                },
              },
              {
                ...formValues.routePoints[1],
                address: formValues.routePoints[1].address ?? null,
                elevatorId: null,
                warehouseId: null,
                freight: null,
              },
            ],
            readinessType: formValues.readinessType,
            startedAt: formValues.startedAt,
            endedAt: formValues.endedAt,
            startDaysCount: formValues.startDaysCount,
            transportFormatTypes: formValues.transportFormatTypes,
            transportBodyTypeIds: formValues.transportBodyTypeIds,
            pricePerTonWithoutNds: formValues.pricePerTonWithoutNds,
            isAuction: formValues.isAuction,
            paymentTerm: formValues.paymentTerm,
            contactPerson: formValues.contactPerson,
            contactPhoneNumber: formValues.contactPhoneNumber,
            advancePercent: formValues.advancePercent,
            deferredDaysCount: formValues.deferredDaysCount,
            additionalDetails: formValues.additionalDetails,
          },
        },
      })
      if (data?.freightOrderCreate.id) {
        await attachDocuments(data.freightOrderCreate.id)
      }
      appToast.success({ message: 'Груз добавлен' })
      navigate(appRoutes.logisticMyCargos.url)
    } catch (e) {
      if (e instanceof ApolloError) {
        graphqlErrorHandler(e, 'Ошибка при добавлении груза')
      }
    }
  }

  const attachDocuments = async (freightOrderId: number) => {
    documents.map(async (document) => {
      const fileKeys = await Promise.all(
        document.files.map((documentFile) => uploadFileAndGetId.mutateAsync(documentFile))
      )

      try {
        await createDocument({
          variables: {
            input: {
              freightOrderId,
              type: document.type,
              fileKeys,
            },
          },
        })
      } catch (e) {
        graphqlErrorHandler(e, 'Ошибка при добавлении документа')
      }
    })
  }

  return (
    <Spin spinning={false}>
      <AppHeader title={appRoutes.logisticsCreateLoad.title} isBack />

      <S.Wrapper>
        <FormProvider {...methods}>
          <form>
            <Flex vertical gap={32}>
              <S.FlexItem vertical gap={24}>
                <LoadingDate control={control} errors={errors} />
              </S.FlexItem>
              <S.FlexItem vertical gap={32}>
                <RouteAndLoad control={control} errors={errors} />
              </S.FlexItem>
              <S.FlexItem vertical gap={32}>
                <Transport control={control} errors={errors} />
              </S.FlexItem>
              <S.FlexItem vertical gap={32}>
                <Conditions control={control} errors={errors} />
              </S.FlexItem>
              <S.FlexItem vertical gap={32}>
                <Documents documents={documents} setDocuments={setDocuments} />
              </S.FlexItem>
              <S.FlexItem vertical gap={8}>
                <Additional control={control} errors={errors} />
              </S.FlexItem>
              <Flex justify="flex-end" gap={8}>
                <S.StyledButton type="default" htmlType="button" onClick={() => navigate(-1)}>
                  Отмена
                </S.StyledButton>
                <Button type="primary" htmlType="button" onClick={handleSubmit(onCreateLoad)}>
                  Добавить груз
                </Button>
              </Flex>
            </Flex>
          </form>
        </FormProvider>
      </S.Wrapper>
    </Spin>
  )
}
