import React, { FC } from 'react'

import { Spin } from 'antd'

import { Card } from '~/ui-components'
import { getDocumentInfo } from '~/utils/profile'

import { DocumentActions, DocumentUploadStatusBadge, DownloadTemplateDocButton } from '../../components'
import { IDocumentsView } from '../../models/documentsView.model'

import * as S from './DocumentsTableView.styled'

export const DocumentsTableView: FC<IDocumentsView> = ({
  documents,
  onUploadFile,
  onShow,
  isUploading,
  onRemove,
  onChangeStatus,
}) => {
  return (
    <S.Wrapper>
      {documents.map((document, idx) => {
        const isUploaded = !!document.file?.source

        return (
          <Spin key={document.type} spinning={isUploading}>
            <Card>
              <Card.Content>
                <S.DocumentContent>
                  <S.CardValue>{idx + 1}</S.CardValue>
                  <S.CardValue>
                    {getDocumentInfo(document.type).name} <br />
                    <S.DocDescription>{getDocumentInfo(document.type).explanation}</S.DocDescription>
                    {document.type === 'last-year-income-statement' && <DownloadTemplateDocButton />}
                  </S.CardValue>

                  <S.StatusWrapper>
                    <Card.Value>
                      <DocumentUploadStatusBadge isUploaded={isUploaded} />
                    </Card.Value>
                  </S.StatusWrapper>

                  <S.ActionsWrapper>
                    <DocumentActions
                      status={document.file?.status}
                      onChangeDocumentStatus={(newStatus) => onChangeStatus(document.type, newStatus)}
                      isUploaded={isUploaded}
                      onShow={() => onShow(document.file?.source ?? '')}
                      onRemove={() => onRemove(document.type)}
                      onUploadFile={(file) => onUploadFile({ file, documentType: document.type })}
                    />
                  </S.ActionsWrapper>
                </S.DocumentContent>
              </Card.Content>
            </Card>
          </Spin>
        )
      })}
    </S.Wrapper>
  )
}
