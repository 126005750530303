import { DocumentPartStatus } from '~api/gql-generated/graphql'

import { BadgeVariant } from '~ui-components/components/Badge/Badge.types'
import { codeUnreachable } from '~/utils/code-unreachable'

interface Status {
  name: string
  color: BadgeVariant
}

export function getDocumentStatus(status: DocumentPartStatus): Status {
  switch (status) {
    case DocumentPartStatus.Confirmed:
      return {
        name: 'Подтвержден',
        color: 'base',
      }
    case DocumentPartStatus.Unchecked:
      return {
        name: 'На проверке',
        color: 'yellow',
      }
    case DocumentPartStatus.Rejected:
      return {
        name: 'Отклонено',
        color: 'red',
      }
    default:
      return codeUnreachable(status)
  }
}
