import styled from 'styled-components'
import { Radio } from 'antd'

export const RadioItem = styled(Radio)`
  border: 1px solid #e9ecee;
  border-radius: 6px;
  padding: 16px 24px;
  background: #fafafb;

  &.ant-radio-wrapper-checked {
    border-color: var(--primary-main-color);
    background: var(--primary-bg-color);
  }
`
