import React from 'react'

import styled from 'styled-components'

import { TransportEntity } from '~api/gql-generated/graphql'

interface AssignedTransportCounterProps {
  transports: TransportEntity[]
}

export function AssignedTransportCounter({ transports }: AssignedTransportCounterProps) {
  if (transports.length < 2) {
    return null
  }

  return <Wrapper>+ {transports.length - 1}</Wrapper>
}

export const Wrapper = styled.div`
  color: #45a67d;
  border: 1px solid #45a67d;
  padding: 2px 4px;
  border-radius: 8px;
`
