import React from 'react'

import { Button, InputNumber, Space } from 'antd'
import { Controller, useForm } from 'react-hook-form'

import { yupResolver } from '@hookform/resolvers/yup/dist/yup'

import { FormField, FormFooter, Text, TimePicker } from '~/ui-components'
import { inputSharedSettings } from '~/utils/inputSharedSettings'
import { createElevatorFormStep2Schema } from '~pages/SettingsPage/modules/ElevatorsAdmin/components/CreateElevatorForm/step2/CreateElevatorFormStep2Schema'
import { CultureSelectControl, DaysOfWeekControl, ElevatorTransferTypeControl, YesOrNotControl } from '~shared/controls'

import {
  CreateElevatorFormStep2Props,
  CreateElevatorFormStep2Values,
} from '~pages/SettingsPage/modules/ElevatorsAdmin/components/CreateElevatorForm/step2/CreateElevatorFormStep2.types'

import * as S from './CreateElevatorFormStep2.styled'

export const CreateElevatorFormStep2: React.FC<CreateElevatorFormStep2Props> = ({
  step1Data,
  onNext,
  onCancel,
  onPrev,
  defaultValues,
}) => {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<CreateElevatorFormStep2Values>({
    defaultValues: {
      storageVolume: defaultValues?.storageVolume,
      productTypes: defaultValues?.productTypes,
      transport: defaultValues?.transport,
      workingDaysOfWeek: defaultValues?.workingDaysOfWeek,
      openingTime: defaultValues?.openingTime,
      closingTime: defaultValues?.closingTime,
      hasLab: defaultValues?.hasLab,
      hasDigitalQueue: defaultValues?.hasDigitalQueue,
    },
    resolver: yupResolver(createElevatorFormStep2Schema),
  })
  const onSubmitHandler = (formValues: CreateElevatorFormStep2Values) => {
    onNext(formValues)
  }

  return (
    <form onSubmit={handleSubmit(onSubmitHandler)}>
      <Space direction="vertical" size={6} style={{ marginBottom: 16 }}>
        <Text variant="normal-bold">{step1Data?.name}</Text>
        <Text variant="normal">{step1Data?.address}</Text>
      </Space>

      <FormField
        htmlFor="storageVolume"
        label="Мощность единовременного хранения"
        validateMessage={errors.storageVolume?.message}
        isRequired
      >
        <Controller
          name="storageVolume"
          control={control}
          render={({ field }) => (
            <InputNumber
              {...field}
              {...inputSharedSettings}
              precision={0}
              addonAfter="т"
              style={{ width: '100%' }}
              id="storageVolume"
              placeholder="10"
              status={errors.storageVolume && 'error'}
              step={0.1}
            />
          )}
        />
      </FormField>

      <FormField label="Хранимые культуры" validateMessage={errors.productTypes?.message} isRequired>
        <Controller
          name="productTypes"
          control={control}
          render={({ field }) => <CultureSelectControl {...field} hasError={!!errors.productTypes} />}
        />
      </FormField>

      <FormField label="Виды принимаемого транспорта" validateMessage={errors.transport?.message} isRequired>
        <Controller
          name="transport"
          control={control}
          render={({ field }) => <ElevatorTransferTypeControl {...field} hasError={!!errors.transport} />}
        />
      </FormField>

      <FormField label="Часы работы" validateMessage={errors.openingTime?.message ?? errors.closingTime?.message}>
        <S.HalfRow>
          <Controller
            control={control}
            name="openingTime"
            render={({ field }) => <TimePicker value={field.value} onChange={field.onChange} />}
          />
          -
          <Controller
            control={control}
            name="closingTime"
            render={({ field }) => <TimePicker value={field.value} onChange={field.onChange} />}
          />
        </S.HalfRow>
      </FormField>

      <FormField label="Рабочие дни недели" validateMessage={errors.workingDaysOfWeek?.message} isRequired>
        <Controller
          name="workingDaysOfWeek"
          control={control}
          render={({ field }) => <DaysOfWeekControl {...field} hasError={!!errors.workingDaysOfWeek} />}
        />
      </FormField>

      <FormField label="Наличие лаборатории" validateMessage={errors.hasLab?.message} isRequired>
        <Controller name="hasLab" control={control} render={({ field }) => <YesOrNotControl {...field} />} />
      </FormField>

      <FormField label="Наличие электронной очереди" validateMessage={errors.hasDigitalQueue?.message}>
        <Controller name="hasDigitalQueue" control={control} render={({ field }) => <YesOrNotControl {...field} />} />
      </FormField>

      <FormFooter>
        <Button onClick={onCancel} htmlType="button">
          Отмена
        </Button>
        <Button onClick={onPrev} htmlType="button">
          Назад
        </Button>
        <Button type="primary" htmlType="submit">
          Далее
        </Button>
      </FormFooter>
    </form>
  )
}
