import { useQuery } from '@tanstack/react-query'

import { DealApiDealControllerGetDealLogisticsInfoRequest, GetDealLogisticsInfoResponse } from '~/api/generated'
import { httpErrorHandler } from '~/utils'
import { useApi } from '~providers/api'

export const useGetDealLogisticsInfo = (request: DealApiDealControllerGetDealLogisticsInfoRequest) => {
  const api = useApi()
  const { dealId } = request

  const fetchFn = async (): Promise<GetDealLogisticsInfoResponse> => {
    try {
      const response = await api.deal.dealControllerGetDealLogisticsInfo(request)
      return response.data
    } catch (e) {
      httpErrorHandler(e, 'Ошибка при загрузке инфрормации о логистике')
      return Promise.reject(e)
    }
  }

  return useQuery(['dealLogisticsInfo', request], fetchFn, {
    staleTime: 5000,
    enabled: !!dealId,
    retry: 2,
  })
}
