import React, { FC } from 'react'

import { FiChevronRight } from 'react-icons/fi'

import { Card } from '~/ui-components'
import { DealStatusBadge } from '~/utils/deals/DealStatusBadge'

import { DealCardProps } from '~pages/Deals/components/DealCard/DealCard.types'

import * as S from './DealCard.styled'

export const DealCard: FC<DealCardProps> = ({ onItemClick, ...item }) => {
  const onClickHandler = () => {
    onItemClick(item)
  }

  return (
    <Card onClick={onClickHandler}>
      <S.Content>
        <div>
          <Card.Label>Наименование культуры</Card.Label>
          <Card.Value>{item.culture}</Card.Value>
        </div>
        <div>
          <Card.Label>ID</Card.Label>
          <Card.Value>{item.id}</Card.Value>
        </div>
        <div>
          <Card.Label>Дата</Card.Label>
          <Card.Value>{item.createdAt}</Card.Value>
        </div>
        <div>
          <Card.Label>Объем,т</Card.Label>
          <Card.Value>{item.quantity}</Card.Value>
        </div>
        <div>
          <Card.Label>Склад</Card.Label>
          <Card.Value>{item.warehouse}</Card.Value>
        </div>
        <div>
          <Card.Label>Локация</Card.Label>
          <Card.Value>{item.address}</Card.Value>
        </div>
        <S.Status>
          <Card.Label>Статус:</Card.Label>
          <Card.Value>
            <DealStatusBadge status={item.status} />
          </Card.Value>
        </S.Status>
        <div>
          <Card.Label>Цена, ₽/т</Card.Label>
          <Card.Value>
            {item.price} {item.includingNds ? 'с НДС' : 'без НДС'}
          </Card.Value>
        </div>
        {/*<S.IconArrow variant="ChevronRight" size={24} color="GreenPrimary" />*/}
        <FiChevronRight size={24} display={'block'} />
      </S.Content>
    </Card>
  )
}
