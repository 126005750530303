import React from 'react'

import { Avatar, Space } from 'antd'

import { Text } from '~/ui-components'
import { getAbbrCompany } from '~/utils/company/get-abbr-company'
import { UserRole } from '~api/gql-generated/graphql'
import { MyUserEntity } from '~pages/EmployeesPage/components/EmployeesList/EmployeesList'
import { EmployeeActionMenu, PhoneNumber, UserRoleTag } from '~shared/components'

import * as S from './UserAddedListItem.styled'

interface UserListItemProps {
  user: MyUserEntity
  onEdit: () => void
  onDelete: () => void
  canDeleteUserFromCompany: boolean
  onActivate: () => void
  onDeactivate: () => void
}

export const UserAddedListItem: React.FC<UserListItemProps> = (props) => {
  const isActivated = !props.user?.deactivatedAt

  const canUpdateUserData = props.user?.actions?.update ?? false
  const canActivateUser = props.user?.actions?.activate ?? false
  const canDeactivateUser = props.user?.actions?.deactivate ?? false

  const isOwner = props.user.role === UserRole.Owner

  return (
    <S.Wrapper>
      <S.EmployeeInfoWrapper>
        <S.EmployeeInfo isDeactivated={!isActivated}>
          <div>
            <Avatar size={50} shape="circle" src={props.user.avatarUrl}>
              {getAbbrCompany(props.user.name)}
            </Avatar>
          </div>
          <Space direction="vertical">
            <Text variant="normal-bold">{props.user.name}</Text>
            <Text variant="normal">
              <PhoneNumber phoneNumber={props.user.phoneNumber} prefix="" />
            </Text>
            <a href={`mailto:${props.user.email}`}>{props.user.email}</a>
          </Space>
        </S.EmployeeInfo>

        <S.EmployeePosition>
          {!isActivated && <S.DeactivatedUserMessage>Деактивирован</S.DeactivatedUserMessage>}

          <UserRoleTag role={props.user.role} />
        </S.EmployeePosition>
      </S.EmployeeInfoWrapper>

      <S.ActionsWrapper>
        <EmployeeActionMenu
          isOwner={isOwner}
          isActivated={isActivated}
          onEdit={props.onEdit}
          onDelete={props.onDelete}
          onActivate={props.onActivate}
          onDeactivate={props.onDeactivate}
          canUpdateUserData={canUpdateUserData}
          canDeleteUserFromCompany={props.canDeleteUserFromCompany}
          canActivateUser={canActivateUser}
          canDeactivateUser={canDeactivateUser}
        />
      </S.ActionsWrapper>
    </S.Wrapper>
  )
}
