import React, { FC, useRef, useState } from 'react'

import { Button, Modal } from 'antd'

import { DocumentInstanceData, DocumentPartStatus } from '~/api/generated'
import { DocumentCardFolder } from '~/ui-components'
import { appToast, modalSharedSettings } from '~/utils'
import { useGetDealDocuments, useRemoveDealDocument, useUploadDealDocument } from '~hooks/deal-documents'
import { DealAddDocumentForm } from '~pages/Deals/components'
import { DocumentCardController, ModalDocumentInstances } from '~shared/components'

import { DocumentProps } from './Document.types'

const getFolderStatus = (statuses: DocumentInstanceData['status'][]) => {
  if (statuses.includes(DocumentPartStatus.Rejected)) return 'error'
  if (statuses.includes(DocumentPartStatus.Unchecked)) return 'warning'
  return 'success'
}

export const Document: FC<DocumentProps> = ({
  document,
  onShow,
  dealId,
  onRemoveAfter,
  onUploadAfter,
  isAddFiles,
  isShowRemoveForAdmin,
}) => {
  const [isOpenInstancesModal, setOpenInstancesModal] = useState(false)
  const [isOpenAddDocumentModal, setOpenAddDocumentModal] = useState(false)

  const replaceFileId = useRef<null | number>(null)

  const uploadDealDocument = useUploadDealDocument()
  const removeDealDocument = useRemoveDealDocument()

  const { data: documents } = useGetDealDocuments({
    dealId: Number(dealId),
  })

  const instance = document?.instances[0]

  const handleInstancesModalOpen = () => {
    setOpenInstancesModal(true)
  }

  const handleInstancesModalClose = () => {
    setOpenInstancesModal(false)
  }

  const handleAddDocumentModalOpen = () => {
    setOpenAddDocumentModal(true)
  }

  const handleAddDocumentModalClose = () => {
    replaceFileId.current = null
    setOpenAddDocumentModal(false)
  }

  const handleReplaceDocumentModalOpen = (id: number) => {
    replaceFileId.current = id
    setOpenAddDocumentModal(true)
  }

  const handleRemoveDocumentInstance = async (id: number, cb?: () => void) => {
    try {
      await removeDealDocument.mutateAsync({
        documentId: id,
      })
      cb?.()
      appToast.success({
        description: 'Документ успешно удален',
      })
    } catch (e) {
      console.error(e)
      appToast.error({
        description: 'Ошибка удаления документа',
      })
    }
  }

  const handleUploadDocumentInstance = async (files: File[]) => {
    if (!dealId) return

    if (replaceFileId.current) {
      await handleRemoveDocumentInstance(replaceFileId.current)
    }

    try {
      await uploadDealDocument.mutateAsync({
        dealId,
        documentType: document.type,
        createDealDocumentBody: {
          documentFiles: files,
        },
      })
      onUploadAfter?.()
      appToast.success({
        description: 'Документ успешно загружен',
      })
    } catch (e) {
      console.error(e)
      appToast.error({
        description: 'Ошибка загрузки документа',
      })
    } finally {
      handleAddDocumentModalClose()
    }
  }

  const documentsTypes = documents?.map((doc) => doc.type)

  return (
    <>
      {document.instances.length > 1 ? (
        <DocumentCardFolder
          type={document.type}
          count={document.instances.length}
          status={getFolderStatus(document.instances.map((it) => it.status))}
          onOpen={handleInstancesModalOpen}
        />
      ) : (
        instance && (
          <DocumentCardController
            dealId={dealId}
            documentInstance={instance}
            canChange={document.canChange}
            type={document.type}
            isShowRemoveForAdmin={isShowRemoveForAdmin}
            documentsTypes={documentsTypes}
            onAddFileAfter={onUploadAfter}
            onShow={() => onShow(instance.id)}
            onRemove={() => handleRemoveDocumentInstance(instance.id, onRemoveAfter)}
            onReplaceFile={() => handleReplaceDocumentModalOpen(instance.id)}
          />
        )
      )}

      <ModalDocumentInstances
        dealId={dealId}
        document={document}
        open={isOpenInstancesModal}
        isAddFile={isAddFiles}
        canChange={document.canChange}
        documentsTypes={documentsTypes}
        isShowRemoveForAdmin={isShowRemoveForAdmin}
        onShow={onShow}
        onAddFileAfter={onUploadAfter}
        onRemove={(id) => handleRemoveDocumentInstance(id, onRemoveAfter)}
        onCancel={handleInstancesModalClose}
        onAddFile={handleAddDocumentModalOpen}
        onReplaceFile={handleReplaceDocumentModalOpen}
      />

      <Modal
        title="Добавить файл"
        {...modalSharedSettings}
        open={isOpenAddDocumentModal}
        onCancel={handleAddDocumentModalClose}
        width={400}
        footer={[
          <Button key="cancel" onClick={handleAddDocumentModalClose} htmlType="button">
            Отмена
          </Button>,
          <Button
            key="submit"
            form="deal-add-document-form"
            type="primary"
            htmlType="submit"
            loading={uploadDealDocument.isLoading}
          >
            Готово
          </Button>,
        ]}
      >
        <DealAddDocumentForm
          documentsTypes={documents?.map((doc) => doc.type)}
          defaultDocumentsType={document.type}
          onSubmit={(value) => handleUploadDocumentInstance(value.documentFiles)}
        />
      </Modal>
    </>
  )
}
