import styled from 'styled-components'

import { breakpoints } from '~/tokens'
import { Text } from '~/ui-components'

export const ContentWrapper = styled.div`
  display: grid;
  gap: 16px;
  padding: 24px;

  @media (max-width: ${breakpoints.MOBILE_XL}px) {
    padding: 16px;
  }
`

export const Header = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
`

export const ActionButtons = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
  justify-content: flex-end;
`

export const TextModerator = styled(Text)`
  color: var(--gray-6-color);
`

export const TextModeratorName = styled(Text)`
  color: var(--gray-8-color);
`

export const SpinWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100vh;
  align-items: center;
  justify-content: center;
`
