import React from 'react'

import { Select } from 'antd'
import { BaseSelectRef } from 'rc-select'

import { CustomControlInterface } from '~shared/controls/CustomControl.interface'

type Value = number | undefined

interface ScalesWeightLimitControlProps extends CustomControlInterface {
  onChange: (value: Value) => void
  value: Value | null
  hasError?: boolean
  id?: string
  disabled?: boolean
}
export const ScalesWeightLimitControl: React.FC<ScalesWeightLimitControlProps> = React.forwardRef<
  BaseSelectRef,
  ScalesWeightLimitControlProps
>(({ id, value, onChange, hasError, disabled }, ref) => {
  const onSelectHandler = (v: Value) => {
    onChange(v)
  }

  return (
    <Select
      ref={ref}
      id={id}
      style={{ width: '100%' }}
      value={value}
      onChange={onSelectHandler}
      placeholder="30 т"
      status={hasError ? 'error' : undefined}
      disabled={disabled}
    >
      <Select.Option value={30}>30 т</Select.Option>
      <Select.Option value={40}>40 т</Select.Option>
      <Select.Option value={60}>60 т</Select.Option>
      <Select.Option value={80}>80 т</Select.Option>
      <Select.Option value={100}>100 т</Select.Option>
      <Select.Option value={150}>150 т</Select.Option>
    </Select>
  )
})

ScalesWeightLimitControl.displayName = 'ScalesWeightLimitControl'
