import React from 'react'

import { RouteObject } from 'react-router-dom'

import { ModeratorPage } from '~/pages'
import { ModeratorDealList } from '~/pages/ModeratorPage/components/ModeratorDealList'
import { ModeratorDealResponseList } from '~/pages/ModeratorPage/components/ModeratorDealResponseList'
import { ModeratorDealPayments } from '~/pages/ModeratorPage/components/ModeratorDeals/ModeratorDealPayments'
import { appRoutes } from '~/router'
import { ModeratorBasisAdvertDetails } from '~pages/ModeratorPage/components/ModeratorBasisAdvert/ModeratorBasisAdvertDetails'
import { ModeratorBasisAdvertList } from '~pages/ModeratorPage/components/ModeratorBasisAdvert/ModeratorBasisAdvertList'
import { ModeratorBasisAdvertOfferDetails } from '~pages/ModeratorPage/components/ModeratorBasisAdvertOffers/ModeratorBasisAdvertOfferDetails'
import { ModeratorBasisAdvertOffersList } from '~pages/ModeratorPage/components/ModeratorBasisAdvertOffers/ModeratorBasisAdvertOffersList'
import { ModeratorCompanyDetails } from '~pages/ModeratorPage/components/ModeratorComapanyDetails'
import { ModeratorCompanyDocuments } from '~pages/ModeratorPage/components/ModeratorCompanyDocuments'
import { ModeratorCompanyInfo } from '~pages/ModeratorPage/components/ModeratorCompanyInfo'
import { ModeratorDealResponseDetails } from '~pages/ModeratorPage/components/ModeratorDealResponseDetails'
import {
  ModeratorDealDetails,
  ModeratorDealDocuments,
  ModeratorDealInformation,
  ModeratorDealLogistics,
} from '~pages/ModeratorPage/components/ModeratorDeals'
import { ModeratorEmployeeInfo } from '~pages/ModeratorPage/components/ModeratorEmployeeInfo'
import { ModeratorOfferDetails } from '~pages/ModeratorPage/components/ModeratorOfferDetails'
import { ModeratorOfferList } from '~pages/ModeratorPage/components/ModeratorOfferList'
import {
  ModeratorAuctionDetails,
  ModeratorAuctions,
  ModeratorBasisListTab,
  ModeratorCompaniesListTab,
  ModeratorDealListTab,
  ModeratorUserPage,
  ModeratorUsersListTab,
} from '~pages/ModeratorPage/modules'

import { RequireAuth } from '../../providers/auth'

export const moderatorRouter: RouteObject[] = [
  {
    path: appRoutes.moderator.url,
    element: (
      <RequireAuth>
        <ModeratorPage />
      </RequireAuth>
    ),
    children: [
      {
        path: appRoutes.moderatorCompanies.url,
        element: <ModeratorCompaniesListTab />,
      },
      {
        path: appRoutes.moderatorUsers.url,
        element: <ModeratorUsersListTab />,
      },
      {
        path: appRoutes.moderatorOffers.url,
        element: <ModeratorOfferList />,
      },
      {
        path: appRoutes.moderatorDeals.url,
        element: <ModeratorDealListTab />,
        children: [
          {
            path: appRoutes.moderatorDealsList.url,
            element: <ModeratorDealList />,
          },
          {
            path: appRoutes.moderatorDealsResponsesList.url,
            element: <ModeratorDealResponseList />,
          },
        ],
      },
      {
        path: appRoutes.moderatorBasis.url,
        element: <ModeratorBasisListTab />,
        children: [
          {
            path: appRoutes.moderatorBasisAdverts.url,
            element: <ModeratorBasisAdvertList />,
          },
          {
            path: appRoutes.moderatorBasisBids.url,
            element: <ModeratorBasisAdvertOffersList />,
          },
        ],
      },
      {
        path: appRoutes.moderatorAuctions.url,
        element: <ModeratorAuctions />,
      },
    ],
  },
  {
    path: appRoutes.moderatorBasisAdvertDetails.url,
    element: <ModeratorBasisAdvertDetails />,
  },
  {
    path: appRoutes.moderatorBasisAdvertOfferDetails.url,
    element: <ModeratorBasisAdvertOfferDetails />,
  },
  {
    path: appRoutes.moderatorAuctionDetails.url,
    element: <ModeratorAuctionDetails />,
  },
  {
    path: appRoutes.moderatorOfferInformation.url,
    element: <ModeratorOfferDetails />,
  },
  {
    path: appRoutes.moderatorDealResponseDetails.url,
    element: <ModeratorDealResponseDetails />,
  },
  {
    path: appRoutes.moderatorDealDetails.url,
    element: <ModeratorDealDetails />,
    children: [
      {
        path: appRoutes.dealDetailsInformation.url,
        element: <ModeratorDealInformation />,
      },
      {
        path: appRoutes.dealDetailsPayment.url,
        element: <ModeratorDealPayments />,
      },
      {
        path: appRoutes.dealDetailsDocuments.url,
        element: <ModeratorDealDocuments />,
      },
      {
        path: appRoutes.dealDetailsLogistics.url,
        element: <ModeratorDealLogistics />,
      },
    ],
  },
  {
    path: appRoutes.moderatorUserDetails.url,
    element: <ModeratorUserPage />,
  },
  {
    path: appRoutes.moderatorCompanyDetails.url,
    element: <ModeratorCompanyDetails />,
    children: [
      {
        path: appRoutes.moderatorCompanyDocumentsInformation.url,
        element: <ModeratorCompanyDocuments />,
      },
      {
        path: appRoutes.moderatorCompanyInformation.url,
        element: <ModeratorCompanyInfo />,
      },
      {
        path: appRoutes.moderatorCompanyEmployees.url,
        element: <ModeratorEmployeeInfo />,
      },
    ],
  },
]
