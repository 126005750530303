import React from 'react'

import { Button, Pagination, Table } from 'antd'
import type { ColumnsType } from 'antd/lib/table'
import { useNavigate } from 'react-router-dom'

import { useGetTableHeight, usePagination } from '~/hooks'
import { appRoutes } from '~/router'
import { appToast, httpErrorHandler } from '~/utils'
import { formatNumber } from '~/utils/formatNumber'
import { BaseUserData, UserData } from '~api/generated'
import { useGetModeratorDealsList, useUpdateDealModerator } from '~hooks/moderator'
import { ModeratorOfferListMobile } from '~pages/ModeratorPage/components/ModeratorOfferList/components'
import { useAuth } from '~providers/auth'
import { actionArrowColumn } from '~shared/columns/shared'

import * as S from '~pages/ModeratorPage/components/ModeratorOfferList/ModeratorOfferList.styled'

interface DealListTableDataType {
  id: number
  culture: string
  volume: number
  price: number
  status: string
  companyName: string
  createdAt: string
  expiredAt: Date | null
  moderator: BaseUserData | null
  createdBy: BaseUserData | null
}

export const ModeratorDealResponseList: React.FC = () => {
  const { userId } = useAuth()
  const navigate = useNavigate()
  const { currentPage, pageSize, setCurrentPage, setPageSize, pageSizeOptions } = usePagination()
  const dealListFn = useGetModeratorDealsList({
    currentPage,
    pageSize,
    statuses: ['ON_MODERATION'],
  })
  const updateDealModerator = useUpdateDealModerator()
  const tableHeight = useGetTableHeight(310)

  const dealList = dealListFn.data?.complexDeals ?? []
  const pagination = dealListFn.data?.pagination

  const takeDealToWorkHandler = async (e: React.MouseEvent, dealId: number) => {
    e.stopPropagation()
    if (!userId) {
      return
    }

    try {
      await updateDealModerator.mutateAsync({
        complexDealId: dealId,
        updateComplexDealModeratorBody: {
          moderatorId: userId,
        },
      })

      appToast.success({ description: `Вы взяли в работу предложение ${dealId}` })
    } catch (e) {
      httpErrorHandler(e, 'Ошибка при обновлении модератора оффера')
    }
    await dealListFn.refetch()
  }

  const columns: ColumnsType<DealListTableDataType> = [
    {
      title: 'Id',
      dataIndex: 'id',
      key: 'id',
      width: '10%',
    },
    {
      title: 'Культура',
      dataIndex: 'culture',
      key: 'culture',
    },
    {
      title: 'Объем, тонн',
      dataIndex: 'volume',
      key: 'volume',
      width: '10%',
    },
    {
      title: 'Цена, ₽',
      dataIndex: 'price',
      key: 'price',
      width: '10%',
      render: (value) => formatNumber(value),
    },
    {
      title: 'Наименование компании',
      dataIndex: 'companyName',
      key: 'companyName',
      ellipsis: true,
    },
    // {
    //   title: 'Дата создания',
    //   dataIndex: 'startedAt',
    //   key: 'startedAt',
    //   width: 150,
    //   render: (startedAt) => startedAt && getDateFormat(startedAt),
    // },
    {
      title: 'Модератор',
      dataIndex: 'moderator',
      key: 'moderator',
      width: '15%',
      render: (moderator: UserData, record: DealListTableDataType) => {
        if (moderator) {
          return moderator.name
        }
        return (
          <Button type="primary" onClick={(event) => takeDealToWorkHandler(event, record.id)}>
            Взять в работу
          </Button>
        )
      },
      ellipsis: true,
    },
    actionArrowColumn,
  ]

  const dealsListTableData: DealListTableDataType[] =
    dealList.map((deal) => ({
      id: deal.id,
      culture: deal.offer?.product.name ?? '-',
      volume: deal.offer.volume,
      price: deal.offer.price,
      status: deal.offer.status,
      companyName: deal.offer?.type === 'SELL' ? deal.seller?.name : deal.buyer.name ?? '-',
      createdAt: deal.createdAt,
      expiredAt: null,
      moderator: deal.moderator,
      createdBy: null,
    })) ?? []

  const onRowClickHandler = (record: DealListTableDataType) => {
    navigate(appRoutes.moderatorDeals.url + '/' + appRoutes.moderatorDealsResponsesList.url + '/' + record.id)
  }

  return (
    <>
      <S.Desktop>
        <Table
          rowKey="id"
          className="table-interactive"
          loading={dealListFn.isLoading || dealListFn.isFetching}
          columns={columns}
          dataSource={dealsListTableData}
          scroll={{ y: tableHeight, x: 'max-content' }}
          pagination={false}
          onRow={(record, index) => ({
            onClick: onRowClickHandler.bind(null, record, index),
          })}
        />
      </S.Desktop>
      <S.Mobile>
        {
          // using the same component as Offers
        }
        <ModeratorOfferListMobile
          data={dealsListTableData}
          loading={dealListFn.isLoading || dealListFn.isFetching}
          onItemClick={onRowClickHandler}
          takeOfferToWork={() => console.log('takeOfferToWork')}
        />
      </S.Mobile>

      {!!pagination && (
        <Pagination
          current={pagination?.currentPage}
          total={pagination?.total}
          pageSize={pagination?.pageSize}
          pageSizeOptions={pageSizeOptions}
          onShowSizeChange={(x, size) => setPageSize(size)}
          showSizeChanger
          onChange={setCurrentPage}
        />
      )}
    </>
  )
}
