import React from 'react'

import { IAppRouteName } from '~/router/routes'
import { Text } from '~/ui-components'
import { mobileMenu } from '~layout/MobileMenuFooter/MobileMenuFooter.data'

import * as S from './MobileMenuFooter.styled'

export const MobileMenuFooter: React.FC = () => {
  const renderItems = () => {
    return Object.keys(mobileMenu).map((menuItemName) => {
      const item = mobileMenu[menuItemName as IAppRouteName]

      if (!item) {
        return null
      }

      const { url, title, icon, roles } = item

      return (
        <S.StyledNavLink key={url} to={url}>
          {icon}
          <S.LinkText>{title}</S.LinkText>
        </S.StyledNavLink>
      )
    })
  }

  return <S.StyledNav>{renderItems()}</S.StyledNav>
}
