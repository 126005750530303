import React, { useState } from 'react'

import { Button, Modal, Skeleton, Space, Spin } from 'antd'
import { AiFillCheckCircle } from 'react-icons/ai'
import { FiAlertCircle } from 'react-icons/fi'
import { Link, useParams } from 'react-router-dom'

import { useGetProductTypeData } from '~/hooks'
import { AppHeader } from '~/layout'
import { appRoutes } from '~/router'
import { Badge, Card, Text, UploadButton } from '~/ui-components'
import { appToast, httpErrorHandler, modalSharedSettings } from '~/utils'
import { getElevatorAdvertOfferRejectReason } from '~/utils/elevator-advert-offers/get-elevator-advert-offer-reject-reason'
import { getElevatorAdvertOfferStatus } from '~/utils/elevator-advert-offers/get-elevator-advert-offer-status'
import { getElevatorPriceTypeName } from '~/utils/elevators/get-elevator-price-type-name'
import { formatNumber } from '~/utils/formatNumber'
import { getUserRoleOld } from '~/utils/user/getUserRoleOld'
import { ElevatorOfferResponseStatus } from '~api/generated'
import { EntityModel } from '~api/gql-generated/graphql'
import { useGetElevatorAdvertAdmin } from '~hooks/elevator-advert-admin'
import { useEditElevatorAdvertOffer } from '~hooks/elevator-advert-offers'
import {
  useGetElevatorAdvertOfferByAdmin,
  useRejectAdvertOfferByAdmin,
  useUpdateElevatorAdvertOfferModerator,
  useUpdateElevatorAdvertOfferStatusByAdmin,
} from '~hooks/elevator-advert-offers-admin'
import { BasisAdvertOfferRejectReasonModal } from '~pages/BasisPage/components/BasisAdvertOfferRejectReasonModal'
import { ModeratorBasisAdvertOfferRemoveModal } from '~pages/ModeratorPage/components/ModeratorBasisAdvert/ModeratorBasisAdvertRemoveModal'
import { EditAdvertOfferFormByAdmin } from '~pages/ModeratorPage/components/ModeratorBasisAdvertOffers/EditAdvertOfferFormByAdmin'
import { Notes } from '~pages/ModeratorPage/components/Notes/Notes'
import { useAuth } from '~providers/auth'
import { PhoneNumber } from '~shared/components'
import { ParametersCultureControlValue } from '~shared/controls/ParametersCultureControl'

import { RejectAdvertOfferForm } from '~pages/BasisPage/components/BasisAdvertOfferRejectReasonModal/BasisAdvertOfferRejectReasonModal.types'
import { EditAdvertOfferFormByAdminValues } from '~pages/ModeratorPage/components/ModeratorBasisAdvertOffers/EditAdvertOfferFormByAdmin/EditAdvertOfferFormByAdmin.types'

import * as S from './ModeratorBasisAdvertOfferDetails.styled'

const EDIT_OFFER_FORM_ID = 'edit-advert-offer-form-by-admin-id'

export const ModeratorBasisAdvertOfferDetails: React.FC = () => {
  const { userId } = useAuth()

  const { id: offerId } = useParams<{ id: string | undefined }>()
  const [isEditOfferModalOpen, setIsEditOfferModalOpen] = useState(false)
  const { getProductTypeParametersForUI_V2 } = useGetProductTypeData()
  const [isRejectAdvertByBuyerModalVisible, setIsRejectAdvertByBuyerModalVisible] = useState(false)
  const [isRejectAdvertByAdminModalVisible, setIsRejectAdvertByAdminModalVisible] = useState(false)
  const [isShowAdvert, setShowAdvert] = useState(false)

  const getElevatorAdvertOfferAdminFn = useGetElevatorAdvertOfferByAdmin({ id: Number(offerId) })
  const updateOfferStatusFn = useUpdateElevatorAdvertOfferStatusByAdmin()
  const rejectAdvertOfferByAdminFn = useRejectAdvertOfferByAdmin()
  const editElevatorAdvertOfferFn = useEditElevatorAdvertOffer()
  const updateOfferModeratorFn = useUpdateElevatorAdvertOfferModerator()

  const offer = getElevatorAdvertOfferAdminFn.data

  const status = offer ? getElevatorAdvertOfferStatus(offer.status) : null
  const cultureName = offer?.product.name
  const getElevatorAdvertAdminFn = useGetElevatorAdvertAdmin({
    elevatorAdvertId: isShowAdvert && offer?.elevatorAdvert.id ? offer.elevatorAdvert.id : NaN,
  })
  const elevatorAdvert = getElevatorAdvertAdminFn.data
  const offerCultureParameters: string[] = getProductTypeParametersForUI_V2(offer?.product)
  const advertProductParameters: string[] = getProductTypeParametersForUI_V2(elevatorAdvert?.product)

  const changeOfferStatus = async (
    status: ElevatorOfferResponseStatus,
    description: string,
    rejectionReason?: string
  ) => {
    if (!offerId) return

    try {
      await updateOfferStatusFn.mutateAsync({
        id: Number(offerId),
        adminUpdateElevatorAdvertOfferStatusBody: {
          status,
          rejectionReason,
        },
      })
      appToast.success({ description })
    } catch (e) {
      httpErrorHandler(e, 'Ошибка при обновлении статуса ставки')
    }
    await getElevatorAdvertOfferAdminFn.refetch()
  }

  const handleOfferToPublishClick = () => {
    Modal.confirm({
      title: 'Вы уверены что хотите опубликовать ставку?',
      okText: 'Опубликовать',
      cancelText: 'Отмена',
      onOk() {
        void changeOfferStatus(ElevatorOfferResponseStatus.UnderConsideration, 'Ставка опубликована')
      },
    })
  }

  const handleAuctionToRejectByAdminClick = (reason: string) => {
    void changeOfferStatus(ElevatorOfferResponseStatus.RejectedOnModeration, 'Ставка отклонена', reason)
  }

  const handleOfferToRejectByBuyerClick = async (rejectAdvertOfferForm: RejectAdvertOfferForm) => {
    if (!offerId) {
      return
    }
    try {
      await rejectAdvertOfferByAdminFn.mutateAsync({
        id: +offerId,
        adminUpdateElevatorAdvertOfferRejectBody: {
          rejectionReason: rejectAdvertOfferForm.reason,
          rejectionComment: rejectAdvertOfferForm.message,
        },
      })
      await getElevatorAdvertOfferAdminFn.refetch()
      appToast.success({ description: 'Ставка отклонена' })
    } catch (e) {
      httpErrorHandler(e, 'Ошибка при отклонении ставки')
    }
  }

  const handleOfferToWorkButtonClick = async () => {
    if (!offerId || !userId) return

    try {
      await updateOfferModeratorFn.mutateAsync({
        id: +offerId,
      })

      appToast.success({ description: `Вы взяли в работу отклик №${offerId}` })
    } catch (e) {
      httpErrorHandler(e, 'Ошибка при обновлении модератора отклика')
    }
    await getElevatorAdvertOfferAdminFn.refetch()
  }

  const onCancelCreateOfferModalHandler = () => {
    setIsEditOfferModalOpen(false)
  }

  const onSubmitCreateEditModalHandler = async (formValues: EditAdvertOfferFormByAdminValues) => {
    try {
      await editElevatorAdvertOfferFn.mutateAsync({
        elevatorOfferResponseId: Number(offerId),
        adminUpdateElevatorOfferResponseBody: {
          parameters: formValues.culture?.parameters ?? null,
          price: formValues.pricePerTon,
          priceType: formValues.priceType,
          includingNds: formValues.usingNds,
        },
      })
      await getElevatorAdvertOfferAdminFn.refetch()
      appToast.success({ description: 'Ставка обновлена' })
      setIsEditOfferModalOpen(false)
    } catch (e) {
      httpErrorHandler(e, 'Ошибка при обновлении ставки')
    }
  }

  const isShowActionButtons = offer?.moderator?.id === userId

  const cultureDefaultControl: ParametersCultureControlValue = {
    cultureTypeId: offer?.product?.type,
    parameters: offer?.product?.parameters,
    hasError: false,
  }

  return (
    <>
      <AppHeader title={`${appRoutes.moderatorBasisAdvertOfferDetails.title} ${offerId}`} isBack />
      <S.ContentWrapper>
        <S.Header>
          {offer?.moderator?.id && (
            <>
              <S.TextModerator variant="normal">В работе у модератора</S.TextModerator>
              <S.TextModeratorName variant="normal">{offer.moderator?.name}</S.TextModeratorName>
            </>
          )}

          {offer?.moderator?.id !== userId && (
            <Button htmlType="button" type="primary" onClick={handleOfferToWorkButtonClick}>
              Взять в работу
            </Button>
          )}

          <div style={{ marginLeft: 'auto' }}>
            <Notes entityId={Number(offerId)} relatedModel={EntityModel.ElevatorOffer} />
          </div>
        </S.Header>

        {offer?.status === 'CONTROVERSIAL' && (
          <Space direction="vertical">
            <Space size={4}>
              <FiAlertCircle size="24" color="red" /> <Text variant="normal-bold">У отклика статус “Спорный”</Text>
            </Space>
            <Text variant="normal-bold">
              {offer.buyerPurchaseStatus === false
                ? 'Покупатель '
                : offer.sellerPurchaseStatus === false
                ? 'Продавец '
                : ''}
              не согласен со статусом “Сделка не совершена”
            </Text>
            <Text variant="normal-bold">Свяжитесь с каждой из сторон и выясните причину разногласий.</Text>
          </Space>
        )}

        {offer?.status === 'ON_MODERATION_WITH_ANALYSIS' && (
          <S.AnalysisWrapper>
            <S.HasAnalysisText>
              <AiFillCheckCircle size={22} />
              Выбрана услуга <b>«Заказать отбор качества»</b>
            </S.HasAnalysisText>
            <Text variant="normal">Свяжитесь с продавцом для уточнения информации!</Text>
          </S.AnalysisWrapper>
        )}

        <Spin spinning={getElevatorAdvertOfferAdminFn.isFetching}>
          <Card>
            <S.CardContent>
              <Space>
                <Card.Label>Базис:</Card.Label>
                <Card.Value>
                  <Link to={appRoutes.basisInfo.url.replace(':id', offer?.elevator?.id?.toString() ?? '')}>
                    {offer?.elevator?.name}
                  </Link>
                </Card.Value>
              </Space>
              <Card.Title>{cultureName}</Card.Title>

              {status && (
                <S.StatusWrapper>
                  <Badge color={status.color} size="s">
                    {status.name}
                  </Badge>

                  {offer?.userRejectionReason && (
                    <Text variant="normal-bold">
                      Причина отклонения:
                      <Text as="span" variant="normal-bold" color="red">
                        &nbsp;
                        {offer.userRejectionReason === 'OTHER'
                          ? offer?.userRejectionComment
                          : getElevatorAdvertOfferRejectReason(offer.userRejectionReason)}
                      </Text>
                    </Text>
                  )}
                </S.StatusWrapper>
              )}

              {!!offerCultureParameters.length && (
                <S.Parameters>
                  {offerCultureParameters.map((param) => (
                    <Badge key={param} color="gray" isRound>
                      {param}
                    </Badge>
                  ))}
                </S.Parameters>
              )}

              {offer?.laboratoryAnalysis && (
                <UploadButton
                  fileName="Лаб. анализ"
                  isReadOnly
                  onFileClick={() => window.open(offer.laboratoryAnalysis?.source, '_blank')}
                />
              )}

              <S.Row>
                <Card.Label>Цена</Card.Label>
                <div>
                  <S.CardValueGreen>
                    {formatNumber(offer?.price)} ₽ {offer?.includingNds ? ' (с НДС)' : ' (без НДС)'}
                  </S.CardValueGreen>
                  {offer?.priceType && <S.PriceType>{getElevatorPriceTypeName(offer.priceType)}</S.PriceType>}
                </div>
              </S.Row>

              {offer?.address && (
                <S.Row>
                  <Card.Label>Адрес</Card.Label>
                  <Text variant="normal">{offer.address}</Text>
                </S.Row>
              )}

              <S.Row>
                <Card.Label>Объем</Card.Label>
                <S.CardValueGreen>{formatNumber(offer?.volume)} т</S.CardValueGreen>
              </S.Row>
              <div />

              <S.Row>
                <Card.Label>Продавец</Card.Label>
                <Card.Value>
                  <Link to={appRoutes.moderatorCompanyDetails.url.replace(':id', offer?.company?.id?.toString() ?? '')}>
                    {offer?.company?.name ?? '-'}
                  </Link>
                </Card.Value>
              </S.Row>

              <S.Row>
                <Card.Label>ИНН</Card.Label>
                <Card.Value>{offer?.company?.inn ?? '-'}</Card.Value>
              </S.Row>

              <S.Row>
                <Card.Label>Контактное лицо</Card.Label>
                <Card.Value>{offer?.contactPerson?.name ?? '-'}</Card.Value>
              </S.Row>
              <S.Row>
                <Card.Label>Телефон</Card.Label>
                <Card.Value>
                  <PhoneNumber phoneNumber={offer?.contactPerson?.phoneNumber} prefix={''} />
                </Card.Value>
              </S.Row>

              <S.Row>
                <Card.Label>Отклик создал</Card.Label>
                <Card.Value>
                  <>
                    {offer?.createdByUser?.role ? getUserRoleOld(offer.createdByUser.role) : null}:
                    <br />
                    <span>{offer?.createdByUser?.name ?? '-'}</span>
                    <br />
                    ID:
                    <Button type="link">
                      <Link
                        to={appRoutes.moderatorUserDetails.url.replace(
                          ':id',
                          offer?.createdByUser?.id.toString() ?? ''
                        )}
                      >
                        {offer?.createdByUser?.id}
                      </Link>
                    </Button>
                  </>
                </Card.Value>
              </S.Row>

              <S.DividerStyled />

              {isShowAdvert && (
                <S.CardContent>
                  <Skeleton loading={getElevatorAdvertAdminFn.isFetching} />

                  <Card.Title>Объявление №{elevatorAdvert?.id}</Card.Title>

                  {elevatorAdvert != null && (
                    <S.ParametersInfo isAllow={elevatorAdvert.allowOffersWithOtherParameters ?? false}>
                      <FiAlertCircle size="24" />
                      <Text variant="normal">
                        {elevatorAdvert.allowOffersWithOtherParameters
                          ? 'Покупатель также рассматривает предложения с другим показателями.'
                          : 'Показатели вашей культуры должны совпадать с указанными в объявлении'}
                      </Text>
                    </S.ParametersInfo>
                  )}

                  {!!advertProductParameters.length && (
                    <S.Parameters>
                      {advertProductParameters.map((param) => (
                        <Badge key={param} color="gray" isRound>
                          {param}
                        </Badge>
                      ))}
                    </S.Parameters>
                  )}

                  <S.Row>
                    <Card.Label>Цена</Card.Label>
                    <S.CardValueGreen>{formatNumber(elevatorAdvert?.pricePerTon)} ₽/тонна</S.CardValueGreen>
                  </S.Row>

                  {elevatorAdvert?.volume && (
                    <S.Row>
                      <Card.Label>Объем</Card.Label>
                      <S.CardValueGreen>{formatNumber(elevatorAdvert.volume)} т</S.CardValueGreen>
                    </S.Row>
                  )}

                  <S.Row>
                    <Card.Label>Базис</Card.Label>
                    <Card.Value>
                      <Link to={appRoutes.basisInfo.url.replace(':id', elevatorAdvert?.elevator?.id?.toString() ?? '')}>
                        {elevatorAdvert?.elevator?.name ?? '-'}
                      </Link>
                    </Card.Value>
                  </S.Row>

                  <S.Row>
                    <Card.Label>Адрес</Card.Label>
                    <Card.Value>{elevatorAdvert?.elevator?.address ?? '-'}</Card.Value>
                  </S.Row>
                  <div />

                  <S.Row>
                    <Card.Label>Покупатель</Card.Label>
                    <Card.Value>
                      <Link
                        to={appRoutes.moderatorCompanyDetails.url.replace(
                          ':id',
                          elevatorAdvert?.company?.id?.toString() ?? ''
                        )}
                      >
                        {elevatorAdvert?.company?.name ?? '-'}
                      </Link>
                    </Card.Value>
                  </S.Row>

                  <S.Row>
                    <Card.Label>ИНН</Card.Label>
                    <Card.Value>{elevatorAdvert?.company?.inn ?? '-'}</Card.Value>
                  </S.Row>

                  <S.Row>
                    <Card.Label>Контактное лицо</Card.Label>
                    <Card.Value>{elevatorAdvert?.contactUser?.name ?? '-'}</Card.Value>
                  </S.Row>
                  <S.Row>
                    <Card.Label>Телефон</Card.Label>
                    <Card.Value>
                      <PhoneNumber phoneNumber={elevatorAdvert?.contactUser?.phoneNumber} prefix={''} />
                    </Card.Value>
                  </S.Row>
                </S.CardContent>
              )}
              <div>
                <Button type="link" onClick={() => setShowAdvert((x) => !x)}>
                  {isShowAdvert ? 'Скрыть объявление' : 'Показать объявление'}
                </Button>
              </div>
            </S.CardContent>
          </Card>
        </Spin>

        {isShowActionButtons && (
          <S.ActionButtons>
            <Button
              type="text"
              onClick={() => setIsRejectAdvertByAdminModalVisible(true)}
              disabled={!offer?.nextStatuses.includes('REJECTED_ON_MODERATION')}
            >
              Отменить
            </Button>
            <Button
              type="text"
              onClick={() => setIsRejectAdvertByBuyerModalVisible(true)}
              disabled={!offer?.nextStatuses.includes('REJECTED_BY_BUYER')}
            >
              Отклонить от лица пользователя
            </Button>
            <Button type="default" onClick={() => setIsEditOfferModalOpen(true)}>
              Редактировать
            </Button>
            <Button
              type="primary"
              onClick={handleOfferToPublishClick}
              disabled={offer?.status === 'UNDER_CONSIDERATION'}
            >
              Опубликовать
            </Button>
          </S.ActionButtons>
        )}
      </S.ContentWrapper>

      <BasisAdvertOfferRejectReasonModal
        isOpen={isRejectAdvertByBuyerModalVisible}
        onClose={() => setIsRejectAdvertByBuyerModalVisible(false)}
        onReject={handleOfferToRejectByBuyerClick}
      />

      <ModeratorBasisAdvertOfferRemoveModal
        isOpen={isRejectAdvertByAdminModalVisible}
        onClose={() => setIsRejectAdvertByAdminModalVisible(false)}
        onReject={({ message }) => handleAuctionToRejectByAdminClick(message)}
      />

      <Modal
        {...modalSharedSettings}
        width={500}
        open={isEditOfferModalOpen}
        onCancel={onCancelCreateOfferModalHandler}
        title="Редактировать отклик"
        footer={[
          <Button key="cancel" onClick={onCancelCreateOfferModalHandler} htmlType="button">
            Отмена
          </Button>,
          <Button
            key="submit"
            form={EDIT_OFFER_FORM_ID}
            type="primary"
            htmlType="submit"
            loading={editElevatorAdvertOfferFn.isLoading}
          >
            Сохранить
          </Button>,
        ]}
      >
        <EditAdvertOfferFormByAdmin
          formId={EDIT_OFFER_FORM_ID}
          onSubmit={onSubmitCreateEditModalHandler}
          allowOffersWithOtherParameters={offer?.elevatorAdvert?.allowOffersWithOtherParameters ?? false}
          productData={offer?.product}
          defaultValues={
            offer
              ? {
                  priceType: offer.priceType,
                  usingNds: offer.includingNds,
                  pricePerTon: offer.price,
                  labAnalysis: offer.laboratoryAnalysis?.source,
                  culture: cultureDefaultControl,
                }
              : undefined
          }
        />
      </Modal>
    </>
  )
}
