import styled from 'styled-components'

import { breakpoints } from '~/tokens'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  border: 1px solid var(--gray-3-color);
  border-radius: 16px;
  padding: 24px;
  width: 380px;

  @media (max-width: ${breakpoints.MOBILE_XL}px) {
    width: 100%;
    padding: 16px;
  }
`

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
`

export const Title = styled.p`
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  color: #27272e;
`
