import * as yup from 'yup'

import { VALIDATORS_MSG } from '~shared/validations'

export const createElevatorFormStep5ServicePriceSchema = yup.object({
  usingNds: yup.boolean(),
  receptionPrice: yup.number(),
  dryingPrice: yup.number(),
  storingPrice: yup.number(),
  mixingPrice: yup.number(),
  autoShipmentPrice: yup.number(),
  trainShipmentPrice: yup.number(),
  paperworkPrice: yup.number(),
  cleaningPrice: yup.number(),
  censusPrice: yup.number(),
  otherServices: yup.string(),
})
