import React from 'react'

import { Modal } from 'antd'

import { DealsPromoContent } from './DealsPromoContent'

interface IDealsPromoModalProps {
  visible: boolean
  onClose: () => void
}

export const DealsPromoModal: React.FC<IDealsPromoModalProps> = ({ visible, onClose }) => {
  return (
    <Modal
      open={visible}
      closable={true}
      width={'100%'}
      style={{ top: 10, maxWidth: '1000px' }}
      cancelButtonProps={{ style: { display: 'none' } }}
      onOk={onClose}
      okText="Понятно"
      onCancel={onClose}
    >
      <DealsPromoContent />
    </Modal>
  )
}
