import React, { useMemo } from 'react'

import { ColumnsType } from 'antd/lib/table'

import { useGetCurrentRole } from '~/hooks/auth'
import { actionArrowColumn, idColumn } from '~/shared/columns/shared'
import { Badge } from '~/ui-components'
import { showColumn } from '~/utils'
import { getAuctionStatus } from '~/utils/auctions/get-auction-status'
import { formatNumber } from '~/utils/formatNumber'
import { getDateTimeDayFormat } from '~/utils/getDateFormat'
import { getNdsString } from '~/utils/getNdsString'
import { getOfferType } from '~/utils/offers/get-offer-type'
import { CompanyAuctionData, OfferDataTypeEnum, ProductTypeData } from '~api/generated'

export const useGetColumnsMyLots = (productsMap: Map<ProductTypeData['type'], ProductTypeData>) => {
  const {
    company: { isTrader },
  } = useGetCurrentRole()

  return useMemo(() => {
    const columns: ColumnsType<CompanyAuctionData> = [
      idColumn,
      {
        title: 'Статус лота',
        dataIndex: 'status',
        width: 120,
        render: (value) => {
          const offerType = getAuctionStatus(value)
          return (
            <Badge color={offerType.color} size="s">
              {offerType.name}
            </Badge>
          )
        },
      },
      ...showColumn<CompanyAuctionData>(isTrader, {
        title: 'Тип лота',
        dataIndex: ['offer', 'type'],
        width: 140,
        render: (type: OfferDataTypeEnum) => {
          const offerType = getOfferType(type)
          return (
            <Badge color={offerType.color} size="s">
              {offerType.name}
            </Badge>
          )
        },
      }),
      {
        title: 'Наименование культуры',
        dataIndex: ['offer', 'product', 'name'],
        width: 200,
        className: 'bold-cell',
      },
      {
        title: 'Объем, т',
        dataIndex: ['offer', 'volume'],
        align: 'right',
        width: 100,
        className: 'bold-cell',
        render: (volume) => {
          return formatNumber(volume)
        },
      },
      {
        title: 'Ставка, ₽/т',
        dataIndex: ['offer', 'price'],
        align: 'right',
        width: 140,
        className: 'bold-cell',
        render: (price) => {
          return formatNumber(price)
        },
      },
      {
        title: 'НДС',
        dataIndex: 'includingNds',
        width: '100px',
        render: (includingNds, row) => {
          return getNdsString(row.offer.includingNds)
        },
      },
      {
        title: 'Локация',
        dataIndex: ['offer', 'address'],
      },
      {
        title: 'Торги закончатся ',
        dataIndex: 'endedAt',
        width: 150,
        render: (endedAt) => {
          return endedAt ? getDateTimeDayFormat(endedAt) : ''
        },
      },
      actionArrowColumn,
    ]

    return columns
  }, [productsMap])
}
