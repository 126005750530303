import React from 'react'

import { matchRoutes, Navigate, Outlet, useLocation } from 'react-router-dom'

import { appRoutes } from '~/router'

const LogisticsPage: React.FC = () => {
  const { pathname } = useLocation()
  const matchLogistic = matchRoutes([{ path: appRoutes.logistics.url }], pathname)

  if (!matchLogistic) {
    return <Outlet />
  } else {
    return <Navigate to={appRoutes.logisticMyCargos.url} replace />
  }
}

export default LogisticsPage
