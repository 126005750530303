import React from 'react'

import { RouteObject } from 'react-router-dom'

import { AuthPage } from '~/pages'
import { appRoutes } from '~/router'

export const authRouter: RouteObject = {
  path: appRoutes.auth.url,
  element: <AuthPage />,
}
