import * as yup from 'yup'

import { VALIDATORS_MSG } from '~shared/validations'

const requiredTrimmedString = yup.string().trim().required(VALIDATORS_MSG.required)

const notRequiredTrimmedString = yup.string().trim().notRequired()

export const companyInfoFormSchema = yup
  .object({
    name: requiredTrimmedString,
    inn: requiredTrimmedString,
    kpp: notRequiredTrimmedString,
    ogrn: requiredTrimmedString,
    codeOkved: requiredTrimmedString,
    director: requiredTrimmedString,
    signatory: requiredTrimmedString,
    signatoryPosition: requiredTrimmedString,
    // signatoryLegalBasis: yup
    //   .mixed()
    //   .oneOf([
    //     CreateCompanyBodySignatoryLegalBasisEnum.CompanyRegulation,
    //     CreateCompanyBodySignatoryLegalBasisEnum.PowerOfAttorney,
    //   ])
    //   .required(VALIDATORS_MSG.required),
    actualAddress: notRequiredTrimmedString,
    legalAddress: notRequiredTrimmedString,
    usingNds: yup.boolean().required(VALIDATORS_MSG.required),
    aicCharterMember: yup.boolean().required(VALIDATORS_MSG.required),
  })
  .required()
