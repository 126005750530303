import React from 'react'

import { Flex, Skeleton } from 'antd'
import { RiAuctionLine } from 'react-icons/ri'
import { Navigate } from 'react-router-dom'

import { useQuery } from '@apollo/client'

import { AppHeader } from '~/layout'
import { appRoutes } from '~/router'
import { Text } from '~/ui-components'
import { getNoun } from '~/utils/getNoun'
import { getCompanyStateQuery } from '~api/gql-query/company.query.graphql'
import { ReactComponent as BasisIcon } from '~assets/icons/basis.svg'
import { ReactComponent as CoinIcon } from '~assets/icons/coin.svg'
import { ReactComponent as WheatIcon } from '~assets/icons/wheat.svg'
import { useGetAuctions } from '~hooks/auctions'
import { useGetCurrentRole } from '~hooks/auth'
import { useGetElevators } from '~hooks/elevators'
import { useGetOffers } from '~hooks/offer'
import { VIP_CLIENTS, VipCards } from '~layout/WelcomePageV2/VipCards'
import { useAuth } from '~providers/auth'

import * as S from './WelcomePageV2.styled'

export const WelcomePageV2: React.FC = () => {
  const { companyId } = useAuth()
  const {
    company: { isCarrier },
  } = useGetCurrentRole()

  const companyStateFn = useQuery(getCompanyStateQuery, {
    variables: { companyId },
    skip: !companyId,
  })

  const offersBuy = useGetOffers({ type: 'BUY', statuses: ['PUBLISHED'], pageSize: 1 })
  const offersSell = useGetOffers({ type: 'SELL', statuses: ['PUBLISHED'], pageSize: 1 })
  const auctions = useGetAuctions({ pageSize: 1 })
  const elevators = useGetElevators({ currentPage: 1, pageSize: 1 })

  const totalBuy = offersBuy?.data?.pagination?.total
  const totalSell = offersSell?.data?.pagination?.total
  const totalAuctions = auctions?.data?.pagination?.total
  const totalBasisCount = elevators?.data?.pagination?.total

  const hasPriorityPass = VIP_CLIENTS.gapResurs.isShow || VIP_CLIENTS.damate.isShow

  if (isCarrier) {
    return <Navigate to={appRoutes.logisticFindCargo.url} replace={true} />
  }

  if (offersBuy.isLoading || offersSell.isLoading || auctions.isLoading || elevators.isLoading) {
    return <Skeleton loading />
  }

  return (
    <>
      <AppHeader title="Главная" />

      <S.Wrapper>
        <S.Title>Добро пожаловать на Зерно-Агрегатор</S.Title>

        <S.CardWrapper>
          <S.Card to={appRoutes.offersListBuy.url}>
            <Flex gap={10}>
              <WheatIcon />
              <Text variant="h4">Купить</Text>
            </Flex>

            <Flex vertical>
              <S.Counter>{totalSell}</S.Counter>
              <S.Description>
                <S.Grey>{getNoun(totalSell ?? 0, 'Предложение', 'Предложения', 'Предложений')}</S.Grey> на Продажу
              </S.Description>
            </Flex>
          </S.Card>

          <S.Card to={appRoutes.offersListSell.url}>
            <Flex gap={10}>
              <CoinIcon />
              <Text variant="h4">Продать</Text>
            </Flex>

            <Flex vertical>
              <S.Counter>{totalBuy}</S.Counter>
              <S.Description>
                <S.Grey>{getNoun(totalBuy ?? 0, 'Предложение', 'Предложения', 'Предложений')}</S.Grey> на Покупку
              </S.Description>
            </Flex>
          </S.Card>

          <S.Card to={appRoutes.basisList.url}>
            <Flex gap={10}>
              <BasisIcon />
              <Text variant="h4">Базисы</Text>
            </Flex>

            <Flex vertical>
              <S.Counter>{totalBasisCount}</S.Counter>
              <S.Description>
                <S.Grey>{getNoun(totalBasisCount ?? 0, 'Базис', 'Базиса', 'Базисов')}</S.Grey> на платформе
              </S.Description>
            </Flex>
          </S.Card>

          <S.Card to={appRoutes.auctionLotsList.url}>
            <Flex gap={10}>
              <RiAuctionLine size={20} />
              <Text variant="h4">Торги</Text>
            </Flex>

            <Flex vertical>
              <S.Counter>{totalAuctions}</S.Counter>
              <S.Description>
                <S.Grey>{getNoun(totalBuy ?? 0, 'Лот', 'Лота', 'Лотов')}</S.Grey> на Торгах
              </S.Description>
            </Flex>
          </S.Card>
        </S.CardWrapper>

        {hasPriorityPass && (
          <Flex>
            <Flex vertical gap={24}>
              <Text variant="h1">Приоритетное размещение</Text>
              <VipCards />
            </Flex>
          </Flex>
        )}
      </S.Wrapper>
    </>
  )
}
