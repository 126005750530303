import React, { FC, useEffect } from 'react'

import { Button, Input, Modal } from 'antd'
import { Controller, useForm } from 'react-hook-form'

import { yupResolver } from '@hookform/resolvers/yup'

import { CompanyDataTypeEnum, CreateOfferBody, CreationProductParameterData, OfferDataTypeEnum } from '~/api/generated'
import { DatePicker, FormField, UploadButton } from '~/ui-components'
import { modalSharedSettings } from '~/utils'
import { useGetCurrentRole } from '~hooks/auth'
import { useGetCompanyById } from '~hooks/company'
import { getDisabledDate, getDisabledTime } from '~pages/Auctions/utils'
import { useAuth } from '~providers/auth'
import {
  AddressControlDadata,
  CheckboxControl,
  ParametersCultureControl,
  PhoneNumberControl,
  PriceControl,
  SellOrBuyRadioControl,
  UsingNdsControl,
  VolumeControl,
} from '~shared/controls'

import { CreateLotModalSchema } from './CreateLotModalSchema'

import { CreateLotModalProps, FormValues } from './CreateLotModal.types'

import * as S from './CreateLotModal.styled'

const FORM_ID = 'create-lot-modal'

export const CreateLotModal: FC<CreateLotModalProps> = ({ visible, onClose, onSubmit, isFetching }) => {
  const { companyId, phoneNumber, user } = useAuth()
  const {
    company: { companyRole },
  } = useGetCurrentRole()
  const { data: companyInfo } = useGetCompanyById(companyId)

  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
    reset,
  } = useForm<FormValues>({
    defaultValues: {
      offerType: companyRole === CompanyDataTypeEnum.Buyer ? 'BUY' : 'SELL',
      address: undefined,
      isAddAddress: false,
      culture: undefined,
      volume: undefined,
      price: undefined,
      includingNds: companyInfo?.usingNds ?? undefined,
      labAnalysis: undefined,
      shippingRequired: false,
      startedAt: undefined,
      note: '',
      contactUser: user?.name ?? '',
      contactPhone: '',
    },
    resolver: yupResolver(CreateLotModalSchema),
  })

  useEffect(() => reset(), [visible])

  console.log(watch())

  const handleFormSubmit = (values: FormValues) => {
    const createOfferBody: CreateOfferBody = {
      product: {
        parameters: values.culture?.parameters as CreationProductParameterData[],
        type: values.culture?.cultureTypeId ?? '',
      },
      type: values.offerType,
      volume: Number(values.volume),
      price: Number(values.price),
      includingNds: values.includingNds,
      address: values.address?.value ?? '',
      shippingRequired: values.shippingRequired,
      publishedAt: null,
      contactPerson: values.contactPhone
        ? {
            phoneNumber: values.contactPhone,
            name: values.contactUser,
          }
        : undefined,
      note: values.note,
    }

    onSubmit(createOfferBody, values.isAddAddress, values.labAnalysis, values.startedAt)
  }

  const offerType = watch('offerType')
  const title = `Создание лота ${offerType === 'BUY' ? 'на покупку' : ''}${offerType === 'SELL' ? 'на продажу' : ''}`

  return (
    <Modal
      {...modalSharedSettings}
      open={visible}
      onCancel={onClose}
      title={title}
      footer={[
        <Button key="cancel" htmlType="button" onClick={onClose}>
          Отмена
        </Button>,
        <Button key="submit" type="primary" form={FORM_ID} htmlType="submit" loading={isFetching}>
          Разместить
        </Button>,
      ]}
    >
      <S.Form onSubmit={handleSubmit(handleFormSubmit)} id={FORM_ID}>
        {companyRole === CompanyDataTypeEnum.Trader && (
          <FormField>
            <Controller
              name="offerType"
              control={control}
              render={({ field }) => <SellOrBuyRadioControl {...field} />}
            />
          </FormField>
        )}

        <FormField label="Адрес" htmlFor="address" isRequired validateMessage={errors.address?.message}>
          <S.Address>
            <Controller
              name="address"
              control={control}
              render={({ field }) => (
                <AddressControlDadata
                  id="address"
                  value={undefined}
                  onChange={(value) => field.onChange(value)}
                  hasError={!!errors.address}
                />
              )}
            />

            <Controller
              control={control}
              name="isAddAddress"
              render={({ field }) => <CheckboxControl {...field}>Добавить местоположение в мои склады</CheckboxControl>}
            />
          </S.Address>
        </FormField>

        <FormField validateMessage={errors.culture ? 'Параметры культуры заполнены с ошибками' : ''}>
          <Controller
            name="culture"
            control={control}
            render={({ field }) => <ParametersCultureControl isRequired {...field} />}
          />
        </FormField>

        {offerType === OfferDataTypeEnum.Sell && (
          <FormField label="Результаты лаб. исследований" validateMessage={errors.labAnalysis?.message} isRequired>
            <Controller
              control={control}
              name="labAnalysis"
              render={({ field }) => (
                <UploadButton
                  onChange={(evt) => {
                    if (evt.target.files?.length) {
                      field.onChange(evt.target.files[0])
                    }
                  }}
                >
                  Загрузить
                </UploadButton>
              )}
            />
          </FormField>
        )}

        <FormField label="Объем, тонн" validateMessage={errors.volume?.message} isRequired>
          <S.InputWrapper>
            <Controller
              name="volume"
              control={control}
              render={({ field }) => <VolumeControl id="volume" {...field} hasError={!!errors.volume} />}
            />
          </S.InputWrapper>
        </FormField>

        <S.Price>
          <S.InputWrapper>
            <FormField
              label={(offerType === OfferDataTypeEnum.Sell ? 'Минимальная' : 'Максимальная') + ' ставка, ₽/тонн'}
              validateMessage={errors.price?.message}
              isRequired
            >
              <Controller
                name="price"
                control={control}
                render={({ field }) => <PriceControl id="price" {...field} hasError={!!errors.price} />}
              />
            </FormField>
          </S.InputWrapper>

          <S.NdsWrapper>
            <Controller control={control} name="includingNds" render={({ field }) => <UsingNdsControl {...field} />} />
          </S.NdsWrapper>
        </S.Price>

        {offerType === OfferDataTypeEnum.Buy && (
          <FormField>
            <Controller
              control={control}
              name="shippingRequired"
              render={({ field }) => (
                <CheckboxControl {...field}>Требуется перевозчик для доставки до склада</CheckboxControl>
              )}
            />
          </FormField>
        )}

        <FormField label="Контактное лицо" htmlFor="contactUser" validateMessage={errors.contactUser?.message}>
          <Controller
            name="contactUser"
            control={control}
            render={({ field }) => (
              <Input id="contactUser" placeholder="Укажите ФИО" {...field} status={errors.contactUser && 'error'} />
            )}
          />
        </FormField>

        <FormField validateMessage={errors.contactPhone?.message} label="Телефон" htmlFor="contactPhone">
          <Controller
            name="contactPhone"
            control={control}
            render={({ field }) => <PhoneNumberControl {...field} id="contactPhone" hasError={!!errors.contactPhone} />}
            rules={{
              validate: {
                contactPhone: () => false,
              },
            }}
          />
        </FormField>

        <FormField label="Примечание" validateMessage={errors.note?.message}>
          <Controller
            name="note"
            control={control}
            render={({ field }) => (
              <Input
                placeholder="Примечание"
                {...field}
                status={errors.note && 'error'}
                style={{ width: '100%' }}
                value={field.value}
              />
            )}
          />
        </FormField>

        <FormField label="Начать торги">
          <S.DateWrapper>
            <Controller
              name="startedAt"
              control={control}
              render={({ field }) => (
                <DatePicker
                  showTime
                  format="DD.MM.YYYY HH:mm"
                  {...field}
                  disabledDate={getDisabledDate}
                  disabledTime={getDisabledTime}
                />
              )}
            />
          </S.DateWrapper>
        </FormField>
      </S.Form>
    </Modal>
  )
}
