import React, { useRef } from 'react'

import { Tour, TourProps } from 'antd'

import { useMutation, useQuery } from '@apollo/client'

import { appRoutes } from '~/router'
import { UserStateFieldName } from '~api/gql-generated/graphql'
import { updateMeStateMutation } from '~api/gql-mutations/me.mutation.graphql'
import { getMeState } from '~api/gql-query/me.query.graphql'

import * as S from './AccountMenuDesktop.styled'

export const AccountMenuDesktop: React.FC = () => {
  const getMeStateFn = useQuery(getMeState)
  const [updateMeState] = useMutation(updateMeStateMutation, {
    refetchQueries: [getMeState],
  })
  const isOpenTour = getMeStateFn?.data?.me.state.multiCompanyProfileGuideShown ?? true

  const refAccountProfile = useRef(null)
  const refAccountCompanyProfile = useRef(null)
  const refAccountCompanyRequisites = useRef(null)
  const refAccountCompanyPayRequisites = useRef(null)
  const refAccountCompanyDocs = useRef(null)
  const refAccountCompanyEmployees = useRef(null)

  const steps: TourProps['steps'] = [
    {
      title: 'Мы обновили личный кабинет!',
      description: 'В этих подсказках мы расскажем про новые разделы в личном кабинете.',
      target: null,
    },
    {
      title: 'Личные данные',
      description:
        'Здесь хранятся ваши личные данные. Они нужны, чтобы наши администраторы знали, как к вам обращаться. Например, при заключении сделки.',
      placement: 'right',
      target: () => refAccountProfile.current,
    },
    {
      title: 'Общая информация',
      description:
        'Здесь вы можете загрузить логотип компании, указать номер телефона и адрес сайта, а также добавить дополнительную информацию о компании. Это улучшит ее визуальное представление и обеспечит более полную информацию для потенциальных покупателей.',
      placement: 'right',
      target: () => refAccountCompanyProfile.current,
    },
    {
      title: 'Реквизиты компании',
      description:
        'Чтобы совершать сделки, укажите КПП, ФИО генерального директора, должность подписанта, основание, юридический и физический адрес, а также применение НДС.',
      placement: 'right',
      target: () => refAccountCompanyRequisites.current,
    },
    {
      title: 'Платежные реквизиты',
      description:
        'Здесь нужно указать реквизиты вашего банковского счета, на который будут переводиться деньги по заключенным сделкам.',
      placement: 'right',
      target: () => refAccountCompanyPayRequisites.current,
    },
    {
      title: 'Документы',
      description:
        'Ваша компания занимается определенным видом деятельности, требующим наличия соответствующих договоров, лицензий и сертификатов, здесь вы должны загрузить их.',
      placement: 'right',
      target: () => refAccountCompanyDocs.current,
    },
    {
      title: 'Сотрудники',
      description:
        'Этот раздел предоставляет возможность добавить сотрудников вашей компании и назначить им роль на нашей платформе. Здесь вы можете указать их ФИО, контактные данные и выбрать одну из доступных ролей: директор, менеджер или бухгалтер.',
      placement: 'right',
      target: () => refAccountCompanyEmployees.current,
    },
  ]

  const onCloseTour = async () => {
    await updateMeState({
      variables: {
        input: {
          fieldName: UserStateFieldName.MultiCompanyProfileGuideShown,
          value: String(true),
        },
      },
    })
  }

  return (
    <S.StyledNav>
      <Tour
        open={!isOpenTour}
        onClose={onCloseTour}
        steps={steps}
        indicatorsRender={(current, total) => (
          <span>
            {current + 1} / {total}
          </span>
        )}
      />

      <S.StyledDivider />

      <S.SectionName>ПРОФИЛЬ</S.SectionName>
      <S.StyledNavLink to={appRoutes.accountProfile.url} ref={refAccountProfile}>
        {appRoutes.accountProfile.title}
      </S.StyledNavLink>

      <S.StyledDivider />

      <S.SectionName>МОЯ КОМПАНИЯ</S.SectionName>
      <S.StyledNavLink to={appRoutes.accountCompanyProfile.url} ref={refAccountCompanyProfile}>
        {appRoutes.accountCompanyProfile.title}
      </S.StyledNavLink>
      <S.StyledNavLink to={appRoutes.accountCompanyRequisites.url} ref={refAccountCompanyRequisites}>
        {appRoutes.accountCompanyRequisites.title}
      </S.StyledNavLink>
      <S.StyledNavLink to={appRoutes.accountCompanyPayRequisites.url} ref={refAccountCompanyPayRequisites}>
        {appRoutes.accountCompanyPayRequisites.title}
      </S.StyledNavLink>
      <S.StyledNavLink to={appRoutes.accountCompanyDocs.url} ref={refAccountCompanyDocs}>
        {appRoutes.accountCompanyDocs.title}
      </S.StyledNavLink>
      <S.StyledNavLink to={appRoutes.accountCompanyEmployees.url} ref={refAccountCompanyEmployees}>
        {appRoutes.accountCompanyEmployees.title}
      </S.StyledNavLink>
    </S.StyledNav>
  )
}
