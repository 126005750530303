import { useMutation } from '@tanstack/react-query'

import { ElevatorAdvertApiAdminElevatorAdvertControllerUpdateElevatorAdvertModeratorRequest } from '~/api/generated'
import { useApi } from '~providers/api'

export const useUpdateElevatorAdvertModerator = () => {
  const api = useApi()

  return useMutation((request: ElevatorAdvertApiAdminElevatorAdvertControllerUpdateElevatorAdvertModeratorRequest) => {
    return api.elevatorAdvert.adminElevatorAdvertControllerUpdateElevatorAdvertModerator(request)
  })
}
