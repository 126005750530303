/* tslint:disable */
/* eslint-disable */
/**
 * API Зерно-Агрегатора
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { BaseUserData } from '../models';
// @ts-ignore
import { CreateUserBody } from '../models';
// @ts-ignore
import { CreateUserResponse } from '../models';
// @ts-ignore
import { GetMeResponse } from '../models';
// @ts-ignore
import { GetProfilePictureUrlResponse } from '../models';
// @ts-ignore
import { GetUserByPhoneResponse } from '../models';
// @ts-ignore
import { GetUserResponse } from '../models';
// @ts-ignore
import { GetUsersResponse } from '../models';
// @ts-ignore
import { OrderDirection } from '../models';
// @ts-ignore
import { UpdateUserBody } from '../models';
// @ts-ignore
import { UpdateUserStatusBody } from '../models';
/**
 * UserApi - axios parameter creator
 * @export
 */
export const UserApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateUserBody} createUserBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerCreateUser: async (createUserBody: CreateUserBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createUserBody' is not null or undefined
            assertParamExists('userControllerCreateUser', 'createUserBody', createUserBody)
            const localVarPath = `/user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createUserBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerGetProfilePictureUrl: async (userId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('userControllerGetProfilePictureUrl', 'userId', userId)
            const localVarPath = `/user/{userId}/profile-picture`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerGetUser: async (userId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('userControllerGetUser', 'userId', userId)
            const localVarPath = `/user/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} phoneNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerGetUserByPhone: async (phoneNumber: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'phoneNumber' is not null or undefined
            assertParamExists('userControllerGetUserByPhone', 'phoneNumber', phoneNumber)
            const localVarPath = `/user-by-phone/{phoneNumber}`
                .replace(`{${"phoneNumber"}}`, encodeURIComponent(String(phoneNumber)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [currentPage] Номер текущей страницы
         * @param {number} [pageSize] Количество элементов на странице
         * @param {Array<number>} [companyIds] Массив с id компаний пользователей
         * @param {Array<number>} [userIds] Массив с id пользователей
         * @param {string} [phoneNumber] Номер телефона
         * @param {'OWNER' | 'DIRECTOR' | 'MANAGER' | 'ACCOUNTANT' | 'DRIVER' | 'ADMIN' | 'AUTHENTICATED' | 'DEMO_USER'} [role] Роль пользователя
         * @param {'ACTIVATED' | 'BLOCKED'} [status] Статус пользователя
         * @param {'id' | 'name' | 'companyId' | 'createdAt' | 'updatedAt' | 'position' | 'role' | 'status'} [orderBy] Поле для сортировки
         * @param {OrderDirection} [order] Направление сортировки
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerGetUsers: async (currentPage?: number, pageSize?: number, companyIds?: Array<number>, userIds?: Array<number>, phoneNumber?: string, role?: 'OWNER' | 'DIRECTOR' | 'MANAGER' | 'ACCOUNTANT' | 'DRIVER' | 'ADMIN' | 'AUTHENTICATED' | 'DEMO_USER', status?: 'ACTIVATED' | 'BLOCKED', orderBy?: 'id' | 'name' | 'companyId' | 'createdAt' | 'updatedAt' | 'position' | 'role' | 'status', order?: OrderDirection, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (currentPage !== undefined) {
                localVarQueryParameter['current-page'] = currentPage;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page-size'] = pageSize;
            }

            if (companyIds) {
                localVarQueryParameter['company-ids'] = companyIds;
            }

            if (userIds) {
                localVarQueryParameter['user-ids'] = userIds;
            }

            if (phoneNumber !== undefined) {
                localVarQueryParameter['phoneNumber'] = phoneNumber;
            }

            if (role !== undefined) {
                localVarQueryParameter['role'] = role;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['order-by'] = orderBy;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [currentPage] Номер текущей страницы
         * @param {number} [pageSize] Количество элементов на странице
         * @param {Array<number>} [companyIds] Массив с id компаний пользователей
         * @param {Array<number>} [userIds] Массив с id пользователей
         * @param {string} [phoneNumber] Номер телефона
         * @param {'OWNER' | 'DIRECTOR' | 'MANAGER' | 'ACCOUNTANT' | 'DRIVER' | 'ADMIN' | 'AUTHENTICATED' | 'DEMO_USER'} [role] Роль пользователя
         * @param {'ACTIVATED' | 'BLOCKED'} [status] Статус пользователя
         * @param {'id' | 'name' | 'companyId' | 'createdAt' | 'updatedAt' | 'position' | 'role' | 'status'} [orderBy] Поле для сортировки
         * @param {OrderDirection} [order] Направление сортировки
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerGetUsersReport: async (currentPage?: number, pageSize?: number, companyIds?: Array<number>, userIds?: Array<number>, phoneNumber?: string, role?: 'OWNER' | 'DIRECTOR' | 'MANAGER' | 'ACCOUNTANT' | 'DRIVER' | 'ADMIN' | 'AUTHENTICATED' | 'DEMO_USER', status?: 'ACTIVATED' | 'BLOCKED', orderBy?: 'id' | 'name' | 'companyId' | 'createdAt' | 'updatedAt' | 'position' | 'role' | 'status', order?: OrderDirection, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/users/report`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (currentPage !== undefined) {
                localVarQueryParameter['current-page'] = currentPage;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page-size'] = pageSize;
            }

            if (companyIds) {
                localVarQueryParameter['company-ids'] = companyIds;
            }

            if (userIds) {
                localVarQueryParameter['user-ids'] = userIds;
            }

            if (phoneNumber !== undefined) {
                localVarQueryParameter['phoneNumber'] = phoneNumber;
            }

            if (role !== undefined) {
                localVarQueryParameter['role'] = role;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['order-by'] = orderBy;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerMe: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} userId 
         * @param {UpdateUserBody} updateUserBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerUpdateUser: async (userId: number, updateUserBody: UpdateUserBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('userControllerUpdateUser', 'userId', userId)
            // verify required parameter 'updateUserBody' is not null or undefined
            assertParamExists('userControllerUpdateUser', 'updateUserBody', updateUserBody)
            const localVarPath = `/user/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateUserBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} userId 
         * @param {UpdateUserStatusBody} updateUserStatusBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerUpdateUserStatus: async (userId: number, updateUserStatusBody: UpdateUserStatusBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('userControllerUpdateUserStatus', 'userId', userId)
            // verify required parameter 'updateUserStatusBody' is not null or undefined
            assertParamExists('userControllerUpdateUserStatus', 'updateUserStatusBody', updateUserStatusBody)
            const localVarPath = `/user/{userId}/status`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateUserStatusBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserApi - functional programming interface
 * @export
 */
export const UserApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateUserBody} createUserBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userControllerCreateUser(createUserBody: CreateUserBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateUserResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userControllerCreateUser(createUserBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userControllerGetProfilePictureUrl(userId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetProfilePictureUrlResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userControllerGetProfilePictureUrl(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userControllerGetUser(userId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetUserResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userControllerGetUser(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} phoneNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userControllerGetUserByPhone(phoneNumber: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetUserByPhoneResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userControllerGetUserByPhone(phoneNumber, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [currentPage] Номер текущей страницы
         * @param {number} [pageSize] Количество элементов на странице
         * @param {Array<number>} [companyIds] Массив с id компаний пользователей
         * @param {Array<number>} [userIds] Массив с id пользователей
         * @param {string} [phoneNumber] Номер телефона
         * @param {'OWNER' | 'DIRECTOR' | 'MANAGER' | 'ACCOUNTANT' | 'DRIVER' | 'ADMIN' | 'AUTHENTICATED' | 'DEMO_USER'} [role] Роль пользователя
         * @param {'ACTIVATED' | 'BLOCKED'} [status] Статус пользователя
         * @param {'id' | 'name' | 'companyId' | 'createdAt' | 'updatedAt' | 'position' | 'role' | 'status'} [orderBy] Поле для сортировки
         * @param {OrderDirection} [order] Направление сортировки
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userControllerGetUsers(currentPage?: number, pageSize?: number, companyIds?: Array<number>, userIds?: Array<number>, phoneNumber?: string, role?: 'OWNER' | 'DIRECTOR' | 'MANAGER' | 'ACCOUNTANT' | 'DRIVER' | 'ADMIN' | 'AUTHENTICATED' | 'DEMO_USER', status?: 'ACTIVATED' | 'BLOCKED', orderBy?: 'id' | 'name' | 'companyId' | 'createdAt' | 'updatedAt' | 'position' | 'role' | 'status', order?: OrderDirection, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetUsersResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userControllerGetUsers(currentPage, pageSize, companyIds, userIds, phoneNumber, role, status, orderBy, order, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [currentPage] Номер текущей страницы
         * @param {number} [pageSize] Количество элементов на странице
         * @param {Array<number>} [companyIds] Массив с id компаний пользователей
         * @param {Array<number>} [userIds] Массив с id пользователей
         * @param {string} [phoneNumber] Номер телефона
         * @param {'OWNER' | 'DIRECTOR' | 'MANAGER' | 'ACCOUNTANT' | 'DRIVER' | 'ADMIN' | 'AUTHENTICATED' | 'DEMO_USER'} [role] Роль пользователя
         * @param {'ACTIVATED' | 'BLOCKED'} [status] Статус пользователя
         * @param {'id' | 'name' | 'companyId' | 'createdAt' | 'updatedAt' | 'position' | 'role' | 'status'} [orderBy] Поле для сортировки
         * @param {OrderDirection} [order] Направление сортировки
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userControllerGetUsersReport(currentPage?: number, pageSize?: number, companyIds?: Array<number>, userIds?: Array<number>, phoneNumber?: string, role?: 'OWNER' | 'DIRECTOR' | 'MANAGER' | 'ACCOUNTANT' | 'DRIVER' | 'ADMIN' | 'AUTHENTICATED' | 'DEMO_USER', status?: 'ACTIVATED' | 'BLOCKED', orderBy?: 'id' | 'name' | 'companyId' | 'createdAt' | 'updatedAt' | 'position' | 'role' | 'status', order?: OrderDirection, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userControllerGetUsersReport(currentPage, pageSize, companyIds, userIds, phoneNumber, role, status, orderBy, order, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userControllerMe(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetMeResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userControllerMe(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} userId 
         * @param {UpdateUserBody} updateUserBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userControllerUpdateUser(userId: number, updateUserBody: UpdateUserBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BaseUserData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userControllerUpdateUser(userId, updateUserBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} userId 
         * @param {UpdateUserStatusBody} updateUserStatusBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userControllerUpdateUserStatus(userId: number, updateUserStatusBody: UpdateUserStatusBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userControllerUpdateUserStatus(userId, updateUserStatusBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserApi - factory interface
 * @export
 */
export const UserApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateUserBody} createUserBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerCreateUser(createUserBody: CreateUserBody, options?: any): AxiosPromise<CreateUserResponse> {
            return localVarFp.userControllerCreateUser(createUserBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerGetProfilePictureUrl(userId: number, options?: any): AxiosPromise<GetProfilePictureUrlResponse> {
            return localVarFp.userControllerGetProfilePictureUrl(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerGetUser(userId: number, options?: any): AxiosPromise<GetUserResponse> {
            return localVarFp.userControllerGetUser(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} phoneNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerGetUserByPhone(phoneNumber: string, options?: any): AxiosPromise<GetUserByPhoneResponse> {
            return localVarFp.userControllerGetUserByPhone(phoneNumber, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [currentPage] Номер текущей страницы
         * @param {number} [pageSize] Количество элементов на странице
         * @param {Array<number>} [companyIds] Массив с id компаний пользователей
         * @param {Array<number>} [userIds] Массив с id пользователей
         * @param {string} [phoneNumber] Номер телефона
         * @param {'OWNER' | 'DIRECTOR' | 'MANAGER' | 'ACCOUNTANT' | 'DRIVER' | 'ADMIN' | 'AUTHENTICATED' | 'DEMO_USER'} [role] Роль пользователя
         * @param {'ACTIVATED' | 'BLOCKED'} [status] Статус пользователя
         * @param {'id' | 'name' | 'companyId' | 'createdAt' | 'updatedAt' | 'position' | 'role' | 'status'} [orderBy] Поле для сортировки
         * @param {OrderDirection} [order] Направление сортировки
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerGetUsers(currentPage?: number, pageSize?: number, companyIds?: Array<number>, userIds?: Array<number>, phoneNumber?: string, role?: 'OWNER' | 'DIRECTOR' | 'MANAGER' | 'ACCOUNTANT' | 'DRIVER' | 'ADMIN' | 'AUTHENTICATED' | 'DEMO_USER', status?: 'ACTIVATED' | 'BLOCKED', orderBy?: 'id' | 'name' | 'companyId' | 'createdAt' | 'updatedAt' | 'position' | 'role' | 'status', order?: OrderDirection, options?: any): AxiosPromise<GetUsersResponse> {
            return localVarFp.userControllerGetUsers(currentPage, pageSize, companyIds, userIds, phoneNumber, role, status, orderBy, order, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [currentPage] Номер текущей страницы
         * @param {number} [pageSize] Количество элементов на странице
         * @param {Array<number>} [companyIds] Массив с id компаний пользователей
         * @param {Array<number>} [userIds] Массив с id пользователей
         * @param {string} [phoneNumber] Номер телефона
         * @param {'OWNER' | 'DIRECTOR' | 'MANAGER' | 'ACCOUNTANT' | 'DRIVER' | 'ADMIN' | 'AUTHENTICATED' | 'DEMO_USER'} [role] Роль пользователя
         * @param {'ACTIVATED' | 'BLOCKED'} [status] Статус пользователя
         * @param {'id' | 'name' | 'companyId' | 'createdAt' | 'updatedAt' | 'position' | 'role' | 'status'} [orderBy] Поле для сортировки
         * @param {OrderDirection} [order] Направление сортировки
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerGetUsersReport(currentPage?: number, pageSize?: number, companyIds?: Array<number>, userIds?: Array<number>, phoneNumber?: string, role?: 'OWNER' | 'DIRECTOR' | 'MANAGER' | 'ACCOUNTANT' | 'DRIVER' | 'ADMIN' | 'AUTHENTICATED' | 'DEMO_USER', status?: 'ACTIVATED' | 'BLOCKED', orderBy?: 'id' | 'name' | 'companyId' | 'createdAt' | 'updatedAt' | 'position' | 'role' | 'status', order?: OrderDirection, options?: any): AxiosPromise<void> {
            return localVarFp.userControllerGetUsersReport(currentPage, pageSize, companyIds, userIds, phoneNumber, role, status, orderBy, order, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerMe(options?: any): AxiosPromise<GetMeResponse> {
            return localVarFp.userControllerMe(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} userId 
         * @param {UpdateUserBody} updateUserBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerUpdateUser(userId: number, updateUserBody: UpdateUserBody, options?: any): AxiosPromise<BaseUserData> {
            return localVarFp.userControllerUpdateUser(userId, updateUserBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} userId 
         * @param {UpdateUserStatusBody} updateUserStatusBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerUpdateUserStatus(userId: number, updateUserStatusBody: UpdateUserStatusBody, options?: any): AxiosPromise<void> {
            return localVarFp.userControllerUpdateUserStatus(userId, updateUserStatusBody, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for userControllerCreateUser operation in UserApi.
 * @export
 * @interface UserApiUserControllerCreateUserRequest
 */
export interface UserApiUserControllerCreateUserRequest {
    /**
     * 
     * @type {CreateUserBody}
     * @memberof UserApiUserControllerCreateUser
     */
    readonly createUserBody: CreateUserBody
}

/**
 * Request parameters for userControllerGetProfilePictureUrl operation in UserApi.
 * @export
 * @interface UserApiUserControllerGetProfilePictureUrlRequest
 */
export interface UserApiUserControllerGetProfilePictureUrlRequest {
    /**
     * 
     * @type {number}
     * @memberof UserApiUserControllerGetProfilePictureUrl
     */
    readonly userId: number
}

/**
 * Request parameters for userControllerGetUser operation in UserApi.
 * @export
 * @interface UserApiUserControllerGetUserRequest
 */
export interface UserApiUserControllerGetUserRequest {
    /**
     * 
     * @type {number}
     * @memberof UserApiUserControllerGetUser
     */
    readonly userId: number
}

/**
 * Request parameters for userControllerGetUserByPhone operation in UserApi.
 * @export
 * @interface UserApiUserControllerGetUserByPhoneRequest
 */
export interface UserApiUserControllerGetUserByPhoneRequest {
    /**
     * 
     * @type {string}
     * @memberof UserApiUserControllerGetUserByPhone
     */
    readonly phoneNumber: string
}

/**
 * Request parameters for userControllerGetUsers operation in UserApi.
 * @export
 * @interface UserApiUserControllerGetUsersRequest
 */
export interface UserApiUserControllerGetUsersRequest {
    /**
     * Номер текущей страницы
     * @type {number}
     * @memberof UserApiUserControllerGetUsers
     */
    readonly currentPage?: number

    /**
     * Количество элементов на странице
     * @type {number}
     * @memberof UserApiUserControllerGetUsers
     */
    readonly pageSize?: number

    /**
     * Массив с id компаний пользователей
     * @type {Array<number>}
     * @memberof UserApiUserControllerGetUsers
     */
    readonly companyIds?: Array<number>

    /**
     * Массив с id пользователей
     * @type {Array<number>}
     * @memberof UserApiUserControllerGetUsers
     */
    readonly userIds?: Array<number>

    /**
     * Номер телефона
     * @type {string}
     * @memberof UserApiUserControllerGetUsers
     */
    readonly phoneNumber?: string

    /**
     * Роль пользователя
     * @type {'OWNER' | 'DIRECTOR' | 'MANAGER' | 'ACCOUNTANT' | 'DRIVER' | 'ADMIN' | 'AUTHENTICATED' | 'DEMO_USER'}
     * @memberof UserApiUserControllerGetUsers
     */
    readonly role?: 'OWNER' | 'DIRECTOR' | 'MANAGER' | 'ACCOUNTANT' | 'DRIVER' | 'ADMIN' | 'AUTHENTICATED' | 'DEMO_USER'

    /**
     * Статус пользователя
     * @type {'ACTIVATED' | 'BLOCKED'}
     * @memberof UserApiUserControllerGetUsers
     */
    readonly status?: 'ACTIVATED' | 'BLOCKED'

    /**
     * Поле для сортировки
     * @type {'id' | 'name' | 'companyId' | 'createdAt' | 'updatedAt' | 'position' | 'role' | 'status'}
     * @memberof UserApiUserControllerGetUsers
     */
    readonly orderBy?: 'id' | 'name' | 'companyId' | 'createdAt' | 'updatedAt' | 'position' | 'role' | 'status'

    /**
     * Направление сортировки
     * @type {OrderDirection}
     * @memberof UserApiUserControllerGetUsers
     */
    readonly order?: OrderDirection
}

/**
 * Request parameters for userControllerGetUsersReport operation in UserApi.
 * @export
 * @interface UserApiUserControllerGetUsersReportRequest
 */
export interface UserApiUserControllerGetUsersReportRequest {
    /**
     * Номер текущей страницы
     * @type {number}
     * @memberof UserApiUserControllerGetUsersReport
     */
    readonly currentPage?: number

    /**
     * Количество элементов на странице
     * @type {number}
     * @memberof UserApiUserControllerGetUsersReport
     */
    readonly pageSize?: number

    /**
     * Массив с id компаний пользователей
     * @type {Array<number>}
     * @memberof UserApiUserControllerGetUsersReport
     */
    readonly companyIds?: Array<number>

    /**
     * Массив с id пользователей
     * @type {Array<number>}
     * @memberof UserApiUserControllerGetUsersReport
     */
    readonly userIds?: Array<number>

    /**
     * Номер телефона
     * @type {string}
     * @memberof UserApiUserControllerGetUsersReport
     */
    readonly phoneNumber?: string

    /**
     * Роль пользователя
     * @type {'OWNER' | 'DIRECTOR' | 'MANAGER' | 'ACCOUNTANT' | 'DRIVER' | 'ADMIN' | 'AUTHENTICATED' | 'DEMO_USER'}
     * @memberof UserApiUserControllerGetUsersReport
     */
    readonly role?: 'OWNER' | 'DIRECTOR' | 'MANAGER' | 'ACCOUNTANT' | 'DRIVER' | 'ADMIN' | 'AUTHENTICATED' | 'DEMO_USER'

    /**
     * Статус пользователя
     * @type {'ACTIVATED' | 'BLOCKED'}
     * @memberof UserApiUserControllerGetUsersReport
     */
    readonly status?: 'ACTIVATED' | 'BLOCKED'

    /**
     * Поле для сортировки
     * @type {'id' | 'name' | 'companyId' | 'createdAt' | 'updatedAt' | 'position' | 'role' | 'status'}
     * @memberof UserApiUserControllerGetUsersReport
     */
    readonly orderBy?: 'id' | 'name' | 'companyId' | 'createdAt' | 'updatedAt' | 'position' | 'role' | 'status'

    /**
     * Направление сортировки
     * @type {OrderDirection}
     * @memberof UserApiUserControllerGetUsersReport
     */
    readonly order?: OrderDirection
}

/**
 * Request parameters for userControllerUpdateUser operation in UserApi.
 * @export
 * @interface UserApiUserControllerUpdateUserRequest
 */
export interface UserApiUserControllerUpdateUserRequest {
    /**
     * 
     * @type {number}
     * @memberof UserApiUserControllerUpdateUser
     */
    readonly userId: number

    /**
     * 
     * @type {UpdateUserBody}
     * @memberof UserApiUserControllerUpdateUser
     */
    readonly updateUserBody: UpdateUserBody
}

/**
 * Request parameters for userControllerUpdateUserStatus operation in UserApi.
 * @export
 * @interface UserApiUserControllerUpdateUserStatusRequest
 */
export interface UserApiUserControllerUpdateUserStatusRequest {
    /**
     * 
     * @type {number}
     * @memberof UserApiUserControllerUpdateUserStatus
     */
    readonly userId: number

    /**
     * 
     * @type {UpdateUserStatusBody}
     * @memberof UserApiUserControllerUpdateUserStatus
     */
    readonly updateUserStatusBody: UpdateUserStatusBody
}

/**
 * UserApi - object-oriented interface
 * @export
 * @class UserApi
 * @extends {BaseAPI}
 */
export class UserApi extends BaseAPI {
    /**
     * 
     * @param {UserApiUserControllerCreateUserRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userControllerCreateUser(requestParameters: UserApiUserControllerCreateUserRequest, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).userControllerCreateUser(requestParameters.createUserBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UserApiUserControllerGetProfilePictureUrlRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userControllerGetProfilePictureUrl(requestParameters: UserApiUserControllerGetProfilePictureUrlRequest, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).userControllerGetProfilePictureUrl(requestParameters.userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UserApiUserControllerGetUserRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userControllerGetUser(requestParameters: UserApiUserControllerGetUserRequest, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).userControllerGetUser(requestParameters.userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UserApiUserControllerGetUserByPhoneRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userControllerGetUserByPhone(requestParameters: UserApiUserControllerGetUserByPhoneRequest, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).userControllerGetUserByPhone(requestParameters.phoneNumber, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UserApiUserControllerGetUsersRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userControllerGetUsers(requestParameters: UserApiUserControllerGetUsersRequest = {}, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).userControllerGetUsers(requestParameters.currentPage, requestParameters.pageSize, requestParameters.companyIds, requestParameters.userIds, requestParameters.phoneNumber, requestParameters.role, requestParameters.status, requestParameters.orderBy, requestParameters.order, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UserApiUserControllerGetUsersReportRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userControllerGetUsersReport(requestParameters: UserApiUserControllerGetUsersReportRequest = {}, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).userControllerGetUsersReport(requestParameters.currentPage, requestParameters.pageSize, requestParameters.companyIds, requestParameters.userIds, requestParameters.phoneNumber, requestParameters.role, requestParameters.status, requestParameters.orderBy, requestParameters.order, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userControllerMe(options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).userControllerMe(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UserApiUserControllerUpdateUserRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userControllerUpdateUser(requestParameters: UserApiUserControllerUpdateUserRequest, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).userControllerUpdateUser(requestParameters.userId, requestParameters.updateUserBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UserApiUserControllerUpdateUserStatusRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userControllerUpdateUserStatus(requestParameters: UserApiUserControllerUpdateUserStatusRequest, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).userControllerUpdateUserStatus(requestParameters.userId, requestParameters.updateUserStatusBody, options).then((request) => request(this.axios, this.basePath));
    }
}
