import React, { useEffect } from 'react'

import { Button, InputNumber, Modal } from 'antd'
import { addDays } from 'date-fns'
import { Controller, useForm } from 'react-hook-form'
import * as yup from 'yup'

import { yupResolver } from '@hookform/resolvers/yup/dist/yup'

import { FormField } from '~/ui-components'
import { appToast, httpErrorHandler, modalSharedSettings } from '~/utils'
import { ElevatorOfferStatus } from '~api/generated'
import { useUpdateElevatorAdvertStatus } from '~hooks/elevator-advert'
import { CheckboxControl } from '~shared/controls'
import { VALIDATORS_MSG } from '~shared/validations'

const FORM_ID = 'republish-advert-offer-modal'

const republishMyAdvertModalSchemaValidation = yup.object({
  publicationDateCount: yup.number().when('isUnlimitedDate', {
    is: false,
    then: yup
      .number()
      .typeError(VALIDATORS_MSG.required)
      .min(1, VALIDATORS_MSG.minValue(1))
      .max(100, VALIDATORS_MSG.minValue(100))
      .required('Укажите срок публикации или опубликуйте бессрочно'), // делаем поле обязательным
    otherwise: yup
      .number()
      .transform((value) => (isNaN(value) ? undefined : value))
      .typeError('Укажите срок публикации или опубликуйте бессрочно')
      .min(1, VALIDATORS_MSG.minValue(1))
      .max(100, VALIDATORS_MSG.minValue(100)),
  }),
  isUnlimitedDate: yup.boolean(),
})

interface RepublishMyAdvertForm {
  publicationDateCount: number | undefined
  isUnlimitedDate: boolean
}

interface RepublishMyAdvertModalProps {
  advertId: number
  companyId: number
  afterAction: () => void
  onClose: () => void
  isOpen: boolean
}
export const RepublishMyAdvertModal: React.FC<RepublishMyAdvertModalProps> = (props) => {
  const {
    watch,
    setValue,
    control,
    handleSubmit,
    formState: { errors, isDirty },
  } = useForm<RepublishMyAdvertForm>({
    defaultValues: {
      publicationDateCount: undefined,
      isUnlimitedDate: true,
    },
    resolver: yupResolver(republishMyAdvertModalSchemaValidation),
  })

  const updateElevatorAdvertStatusFn = useUpdateElevatorAdvertStatus()

  const isUnlimitedDateWatch = watch('isUnlimitedDate')

  useEffect(() => {
    setValue('publicationDateCount', undefined)
  }, [isUnlimitedDateWatch])

  const onCancelModalHandler = () => {
    props.onClose()
  }

  const handleFormSubmit = async (values: RepublishMyAdvertForm) => {
    try {
      await updateElevatorAdvertStatusFn.mutateAsync({
        id: props.advertId,
        updateElevatorAdvertStatusBody: {
          userCompanyId: props.companyId,
          status: ElevatorOfferStatus.Published,
          expiredAt: values.publicationDateCount
            ? addDays(new Date(), values.publicationDateCount).toISOString()
            : null,
        },
      })
      props.onClose()
      props.afterAction()
      appToast.success({
        description: 'Объявление опубликовано',
      })
    } catch (e) {
      httpErrorHandler(e, 'Ошибка при публикации объявления')
    }
  }

  return (
    <Modal
      {...modalSharedSettings}
      destroyOnClose={true}
      width={300}
      open={props.isOpen}
      onCancel={onCancelModalHandler}
      title="Разместить снова"
      footer={[
        <Button key="cancel" htmlType="button" onClick={onCancelModalHandler}>
          Отмена
        </Button>,
        <Button
          type="primary"
          key="submit"
          htmlType="submit"
          form={FORM_ID}
          disabled={!isDirty}
          loading={updateElevatorAdvertStatusFn.isLoading}
        >
          Разместить
        </Button>,
      ]}
    >
      <form id={FORM_ID} onSubmit={handleSubmit(handleFormSubmit)}>
        <FormField
          htmlFor="publicationDateCount"
          label="Срок публикации"
          validateMessage={errors.publicationDateCount?.message}
          isRequired
        >
          <Controller
            name="publicationDateCount"
            control={control}
            render={({ field }) => (
              <InputNumber
                onChange={field.onChange}
                addonAfter="дней"
                value={field.value}
                // placeholder="введите"
                disabled={isUnlimitedDateWatch}
                status={errors.publicationDateCount ? 'error' : undefined}
                precision={0}
              />
            )}
          />
        </FormField>

        <FormField validateMessage={errors.publicationDateCount?.message}>
          <Controller
            control={control}
            name="isUnlimitedDate"
            render={({ field }) => <CheckboxControl {...field}>Опубликовать бессрочно</CheckboxControl>}
          />
        </FormField>
      </form>
    </Modal>
  )
}
