import { Drawer } from 'antd'
import styled from 'styled-components'

import { css } from '@styled-system/css'

import { Text } from '~/ui-components'

export const StyledDrawer = styled(Drawer)(
  css({
    '& .ant-drawer-content-wrapper': {
      width: '320px !important',
    },
  })
)

export const ActiveFilterCount = styled(Text)`
  color: var(--primary-pressed-color);
  margin-left: 4px;
  display: inline;
`
