import { Segmented } from 'antd'
import styled from 'styled-components'

import { breakpoints } from '~/tokens'

export const StyledForm = styled.form`
  display: flex;
  gap: 24px;
  flex-wrap: wrap;
  @media (max-width: ${breakpoints.MOBILE_XL}px) {
    flex-direction: column;
    gap: 8px;
  }
`
export const StyledSegmented = styled(Segmented)`
  .ant-segmented-group {
    background: white;
  }
`
export const Item = styled.div``

export const ItemSelect = styled.div`
  min-width: 200px;
  max-width: 500px;

  @media (max-width: ${breakpoints.MOBILE_XL}px) {
    width: 100%;
    max-width: 100%;
  }
`
