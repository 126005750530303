import styled from 'styled-components'

import { breakpoints } from '~/tokens'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  margin-bottom: 28px;
  padding: 24px;
  padding-bottom: 0;

  @media (max-width: ${breakpoints.MOBILE_XL}px) {
    gap: 16px;
    padding: 16px;
  }
`

export const ContentWrapper = styled.div`
  width: 100%;
  display: flex;
  gap: 32px;

  @media (max-width: ${breakpoints.MOBILE_XL}px) {
    flex-direction: column-reverse;
    gap: 24px;
  }
`
export const Suggession = styled.div`
  height: max-content;
  width: 360px;
  background: var(--gray-1-color);
  padding: 24px;
  border-radius: 6px;

  @media (max-width: ${breakpoints.MOBILE_XL}px) {
    width: unset;
    background: unset;
    padding: unset;
    border-radius: unset;
  }
`

export const StyledForm = styled.form`
  width: 400px;
  @media (max-width: ${breakpoints.MOBILE_XL}px) {
    width: unset;
  }
`

export const ActionWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 16px;
  @media (max-width: ${breakpoints.MOBILE_XL}px) {
    width: unset;
    display: none;
  }
`

export const FormRow = styled.div`
  display: flex;
  gap: 10px;
  @media (max-width: ${breakpoints.MOBILE_XL}px) {
    gap: unset;
    flex-direction: column;
  }
`
