import React from 'react'

import { Empty, Flex, Skeleton, Space } from 'antd'
import { FiArrowRight } from 'react-icons/fi'

import { Badge, Card, Text } from '~/ui-components'
import { getElevatorAdvertStatus } from '~/utils/elevator-adverts/get-elevator-advert-status'
import { getDateFormat } from '~/utils/getDateFormat'

import { MyAdvertsListProps } from '~pages/BasisPage/modules/MyAdvert/components/MyAdvertsList/MyAdvertsList.types'

import * as S from './MyAdvertsListMobile.styled'

export const MyAdvertsListMobile: React.FC<MyAdvertsListProps> = ({
  adverts,
  isLoading,
  onAdvertClick,
  currentCompanyRole,
}) => {
  const onClickHandler = (advertId: number) => onAdvertClick(advertId)

  return (
    <S.LIstWrapper>
      <Skeleton loading={isLoading} active />

      {!adverts?.length && !isLoading && <Empty description="Объявления отсутсвуют" />}

      {adverts?.map((advert) => (
        <Card key={advert.id} onClick={() => onClickHandler(advert.id)}>
          <S.CardHeader>
            <S.CardHeaderTop>
              <Space>
                <S.TitleAdvertNumber>Объявление №{advert.id}</S.TitleAdvertNumber>
                {advert.priceUpdatedAt && (
                  <S.PublishedAt>Обновлено {getDateFormat(new Date(advert.priceUpdatedAt))}</S.PublishedAt>
                )}
              </Space>

              <FiArrowRight size={24} />
            </S.CardHeaderTop>

            <S.CultureWrapper>
              <Text variant="h4">{advert.product.name}</Text>
              {advert.volume && <S.ValueGreen variant="h4">{advert.volume} т</S.ValueGreen>}
            </S.CultureWrapper>

            <div>
              <Badge color={getElevatorAdvertStatus(advert.status).color} size="s">
                {getElevatorAdvertStatus(advert.status).name}
              </Badge>
            </div>

            <S.PriceWrapper>
              <S.PriceRowWrapper>
                <Card.Title>{advert.pricePerTon} ₽/т</Card.Title>
                {advert.type === 'SELL' && <Text variant="normal">{advert.usingNds ? 'с НДС' : 'без НДС'}</Text>}
              </S.PriceRowWrapper>
              {advert.type === 'BUY' && <Text variant="normal">Цена без НДС (с НДС +10%)</Text>}
            </S.PriceWrapper>
          </S.CardHeader>

          {(advert?.publishedAt || advert.expiredAt) && (
            <Flex vertical gap={2} style={{ marginBottom: 14 }}>
              {advert?.publishedAt && (
                <Text variant="form-read-label">Дата публикации: {getDateFormat(advert.publishedAt)}</Text>
              )}
              {advert?.expiredAt && (
                <Text variant="form-read-label">Дата завершения: {getDateFormat(advert.expiredAt)}</Text>
              )}
            </Flex>
          )}
          <Card.Content>
            {/*<div>*/}
            {/*  {!!advert.product.parameters.length && (*/}
            {/*    <S.Parameters>*/}
            {/*      {getProductTypeParametersForUI_V2(advert.product).map((param) => (*/}
            {/*        <Text variant="description" key={param} color="#696E73">*/}
            {/*          {param}*/}
            {/*        </Text>*/}
            {/*      ))}*/}
            {/*    </S.Parameters>*/}
            {/*  )}*/}
            {/*</div>*/}
            <S.CardFooter>
              <Text variant="normal">Базис: {advert.elevator.name}</Text>
              <S.OffersCounterWrapper isActive={advert.offerCount.allOffers > 0}>
                <S.OffersCounter isActive={advert.offerCount.allOffers > 0}>
                  Ставки: {advert.offerCount.allOffers ?? '0'}
                </S.OffersCounter>
                {advert.offerCount.newOffers > 0 && (
                  <S.OffersCounterNew>{advert.offerCount.newOffers}</S.OffersCounterNew>
                )}
              </S.OffersCounterWrapper>
            </S.CardFooter>
          </Card.Content>
        </Card>
      ))}
    </S.LIstWrapper>
  )
}
