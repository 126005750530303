import styled from 'styled-components'

import { breakpoints } from '~/tokens'
import { Card, Text } from '~/ui-components'

export const CardHeaderTop = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
`

export const PublishedAt = styled(Text).attrs({ variant: 'description' })`
  color: var(--gray-4-color);
  display: inline;
  margin-left: 8px;
  line-height: 20px;
  vertical-align: middle;
`

export const StyledCard = styled(Card)`
  display: flex;
  flex-direction: column;
  min-width: 320px;
  gap: 8px;
  cursor: pointer;
`

export const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  flex: 1;
`

export const Parameters = styled.div`
  margin-top: 8px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 8px;
  max-width: 500px;
  color: var(--badge-gray-bg-color);
  @media (max-width: ${breakpoints.MOBILE_XL}px) {
    grid-template-columns: 1fr;
    grid-row-gap: 4px;
  }
`

export const Green = styled.span`
  color: var(--primary-main-color);
  font-size: 16px;
  font-weight: 700;
`
