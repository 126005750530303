import React, { useState } from 'react'

import { Badge, Button, Flex, Tooltip } from 'antd'
import { AiOutlineDelete } from 'react-icons/ai'
import { FiAlertCircle, FiDownload, FiEye, FiPlus } from 'react-icons/fi'

import { Text } from '~/ui-components'
import { getDateFormat } from '~/utils/getDateFormat'
import { getLogisticDealDocumentName } from '~/utils/logistics/get-logistic-deal-document-name'
import { DocumentInstanceEntity, FreightDealDocumentType } from '~api/gql-generated/graphql'
import { DocumentViewerModal } from '~shared/components/DocumentViewer'

import * as S from './LogisticDealDocument.styled'

interface LogisticDocInfo {
  documentType: FreightDealDocumentType
  documentInstance: DocumentInstanceEntity
}

interface LogisticOrderDocumentProps {
  documentType: FreightDealDocumentType
  documentInstance: DocumentInstanceEntity
  date?: string
  onDelete: (doc: LogisticDocInfo) => void
  onAddFile: (doc: LogisticDocInfo) => void
  onDownloadFile: (doc: LogisticDocInfo) => void
  canDelete: boolean
}

export const LogisticDealDocument: React.FC<LogisticOrderDocumentProps> = ({
  documentType,
  documentInstance,
  ...props
}) => {
  const [isOpenViewer, setIsOpen] = useState(false)
  function renderTitle() {
    const tooltipText = getTooltipByType(documentType)
    const title = <Text variant="label-bold">{getLogisticDealDocumentName(documentType)}</Text>
    if (!tooltipText) {
      return title
    }
    return (
      <Flex gap={4}>
        <Tooltip title={tooltipText}>
          <FiAlertCircle />
        </Tooltip>
        {title}
      </Flex>
    )
  }

  const openFile = () => {
    setIsOpen(true)
  }

  return (
    <S.Wrapper>
      <Flex vertical gap={16}>
        {renderTitle()}
        <Text variant="normal">{props.date ? getDateFormat(props.date) : '-'}</Text>
      </Flex>

      <Flex vertical gap={16} align="flex-end">
        <Flex gap={8}>
          <Badge count={documentInstance.files.length === 1 ? 0 : documentInstance.files.length} showZero={false}>
            <Button type="text" icon={<FiEye size={24} />} onClick={openFile} />
          </Badge>
          <Button
            type="text"
            icon={<FiDownload size={24} />}
            onClick={() => props.onDownloadFile({ documentType, documentInstance })}
          />
          {props.canDelete && (
            <Button
              type="text"
              icon={<AiOutlineDelete size={24} />}
              onClick={() => props.onDelete({ documentType, documentInstance })}
            />
          )}
        </Flex>

        {props.canDelete && (
          <Button
            type="text"
            icon={<FiPlus size={16} />}
            onClick={() => props.onAddFile({ documentType, documentInstance })}
          >
            Добавить файл
          </Button>
        )}
      </Flex>

      <DocumentViewerModal
        isOpen={isOpenViewer}
        onCancel={() => setIsOpen(false)}
        key={documentType}
        docInstances={[documentInstance as any]}
      />
    </S.Wrapper>
  )
}

function getTooltipByType(documentType: FreightDealDocumentType): string | null {
  switch (documentType) {
    case FreightDealDocumentType.CarriageContractAnnex:
    case FreightDealDocumentType.CarriageContractAnnexSigned:
      return 'Документ, содержащий информацию об объеме предоставляемых услуг. Может быть спецификацией, приложением, дополнительным соглашением'
    case FreightDealDocumentType.ConsignmentNoteForUnloading:
    case FreightDealDocumentType.ConsignmentNoteForLoading: {
      return 'Документ, содержащий перечень фактически доставленных и переданных грузов. Может быть реестром фактической приемки, выгрузки, погрузки.'
    }
    default:
      return null
  }
}
