import {
  FreightOrderChangeRequestBlockType,
  FreightOrderFieldChangeEntity,
  FreightOrderFieldName,
  FreightPackingType,
  FreightPaymentTerm,
} from '~api/gql-generated/graphql'
import { Flex } from 'antd'
import { Text } from '~/ui-components'
import { getFreightPackingType } from '~/utils/logistics/get-freight-packing-type'
import { getFreightPaymentTerm } from '~/utils/logistics/get-freight-payment-term'
import { getNoun } from '~/utils/getNoun'
import React from 'react'
import { FreightDealInitialValuesProps } from './FreightDealInitialValues.types'
import { useQuery } from '@apollo/client'
import { freightOrderFieldInitialValuesQuery } from '~api/gql-query/freight-order-field-initial-values.query.graphql'

export const FreightDealInitialValues: React.FC<FreightDealInitialValuesProps> = ({ type, freightDealId }) => {
  const getFreightOrderFieldInitialValues = useQuery(freightOrderFieldInitialValuesQuery, {
    variables: { freightDealId },
    fetchPolicy: 'cache-and-network',
  })
  const freightOrderFieldInitialValues: FreightOrderFieldChangeEntity[] =
    getFreightOrderFieldInitialValues.data?.freightOrderFieldInitialValues ?? []
  const freightOrderFieldInitialValuesMap = new Map<FreightOrderFieldName, string>()
  freightOrderFieldInitialValues.map((freightOrderFieldInitialValue) =>
    freightOrderFieldInitialValuesMap.set(freightOrderFieldInitialValue.fieldName, freightOrderFieldInitialValue.value)
  )

  switch (type) {
    case FreightOrderChangeRequestBlockType.Freight:
      const weight = freightOrderFieldInitialValuesMap.get(FreightOrderFieldName.Weight)
      const packingType = freightOrderFieldInitialValuesMap.get(FreightOrderFieldName.PackingType) as
        | FreightPackingType
        | undefined
      const bigBags = freightOrderFieldInitialValuesMap.get(FreightOrderFieldName.BigBagsCount)
      const packingTypeCustom = freightOrderFieldInitialValuesMap.get(FreightOrderFieldName.PackingTypeCustom)
      return (
        <Flex gap={4}>
          {weight && <Text variant="form-read-value">{+weight / 1000} т;</Text>}
          {packingType && (
            <>
              <Text variant="form-read-value">{getFreightPackingType(packingType)},</Text>
              {packingType === FreightPackingType.BigBag && bigBags && (
                <Text variant="form-read-value">{+bigBags} шт</Text>
              )}
              {packingType === FreightPackingType.Custom && packingTypeCustom && (
                <Text variant="form-read-value">{packingTypeCustom}</Text>
              )}
            </>
          )}
        </Flex>
      )
    case FreightOrderChangeRequestBlockType.Price:
      const pricePerTonWithoutNds = freightOrderFieldInitialValuesMap.get(FreightOrderFieldName.PricePerTonWithoutNds)
      const paymentTerm = freightOrderFieldInitialValuesMap.get(FreightOrderFieldName.PaymentTerm) as
        | FreightPaymentTerm
        | undefined
      const deferredDaysCount = freightOrderFieldInitialValuesMap.get(FreightOrderFieldName.DeferredDaysCount)
      const advancePercent = freightOrderFieldInitialValuesMap.get(FreightOrderFieldName.AdvancePercent)
      return (
        <Flex gap={4}>
          {pricePerTonWithoutNds && <Text variant="form-read-value">{pricePerTonWithoutNds} ₽/т;</Text>}
          {paymentTerm && (
            <>
              <Text variant="form-read-value">{getFreightPaymentTerm(paymentTerm)},</Text>
              {paymentTerm === FreightPaymentTerm.Advance && advancePercent && (
                <Text variant="form-read-value">{advancePercent} %</Text>
              )}
              {paymentTerm === FreightPaymentTerm.Deferred && deferredDaysCount && (
                <Text variant="form-read-value">
                  {deferredDaysCount} {getNoun(+deferredDaysCount, 'день', 'дня', 'дней')}
                </Text>
              )}
            </>
          )}
        </Flex>
      )
    case FreightOrderChangeRequestBlockType.TransportRegistry:
      const canUpdateTransportRegistryInPast = freightOrderFieldInitialValuesMap.get(
        FreightOrderFieldName.CanUpdateTransportRegistryInPast
      )
      return (
        <Flex gap={4}>
          {canUpdateTransportRegistryInPast && (
            <Text variant="form-read-value">
              {canUpdateTransportRegistryInPast === 'true'
                ? 'С возможностью редактирования'
                : 'Без возможности редактирования'}
            </Text>
          )}
        </Flex>
      )
    case FreightOrderChangeRequestBlockType.Route:
      const distance = freightOrderFieldInitialValuesMap.get(FreightOrderFieldName.Distance)
      return <Flex gap={4}>{distance && <Text variant="form-read-value">{distance} км</Text>}</Flex>
    default:
      return null
  }
}
