import { BaseDealAdditionalServiceDataTypeTypeEnum } from '~api/generated'

export function getTitleByServiceName(value: BaseDealAdditionalServiceDataTypeTypeEnum): string {
  switch (value) {
    case 'SERVICE':
      return 'Организация перевозки агентом'
    case 'SHIPPING':
      return 'Проба для проведения анализа'
    case 'EMPTY_SERVICE':
      return 'Без дополнительных услуг'
  }
}
