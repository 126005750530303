import styled from 'styled-components'

import { css } from '@styled-system/css'

import { Box } from '~/ui-components'

export const ContentWrapper = styled(Box)(
  css({
    padding: ['16px', '16x', '24px'],
    display: 'flex',
    flexDirection: 'column',
  })
)

export const ActionWrapperFooter = styled(Box)(
  css({
    marginTop: '18px',
    display: 'flex',
    flexWrap: 'wrap',
    gap: '12px',
    justifyContent: 'flex-end',
  })
)

export const ActionWrapperHeader = styled(Box)(
  css({
    marginBottom: '24px',
    display: 'flex',
    flexWrap: 'wrap',
    gap: '12px',
    justifyContent: 'flex-start',
    alignItems: 'center',
  })
)

export const AdminInfoLabel = styled.div(
  css({
    color: 'TxtPrimary',
    minWidth: '150px',
    variant: 'typography.tableBodyText',
  })
)

export const AdminInfoValue = styled.div(
  css({
    color: 'TxtDefault',
    variant: 'typography.tableBodyText',
  })
)

export const Title = styled(Box)(
  css({
    color: 'TxtDefault',
    variant: ['typography.headingsH5'],
    fontWeight: '700',
  })
)
