import { codeUnreachable } from '~/utils/code-unreachable'
import { FreightDealStatus, FreightDealType } from '~api/gql-generated/graphql'

export function getFreightLastResponseText(
  value: FreightDealStatus,
  type: FreightDealType,
  canCreateResponse = false
): string {
  switch (value) {
    case FreightDealStatus.Agreement:
    case FreightDealStatus.Realised:
    case FreightDealStatus.Rejected:
      return type === FreightDealType.Bet
        ? 'Заказчик принял вашу ставку! В карточке заказа вы можете детальнее обсудить условия.'
        : 'Заказчик принял ваш отклик! В карточке заказа вы можете детальнее обсудить условия.'
    case FreightDealStatus.OnModeration:
      return type === FreightDealType.Bet
        ? 'Отслеживайте изменения статуса в разделе «Отклики»'
        : 'Отслеживайте изменения статуса в разделе «Мои отклики»'
    case FreightDealStatus.RejectedAfterModeration:
      let res =
        type === FreightDealType.Bet
          ? 'К сожалению, заказчик отклонил вашу ставку.'
          : 'К сожалению, заказчик отклонил ваш отклик.'
      res += canCreateResponse ? ' Вы можете сделать ставку или откликнуться повторно.' : ''
      return res
    case FreightDealStatus.Shipment:
      return 'Доставка'
    default:
      return codeUnreachable(value)
  }
}
