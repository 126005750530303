/* tslint:disable */
/* eslint-disable */
/**
 * API Зерно-Агрегатора
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AdminCreateElevatorBody } from '../models';
// @ts-ignore
import { AdminGetElevatorResponse } from '../models';
// @ts-ignore
import { AdminGetElevatorsResponse } from '../models';
// @ts-ignore
import { AdminUpdateElevatorBody } from '../models';
// @ts-ignore
import { GetElevatorCompaniesResponse } from '../models';
// @ts-ignore
import { GetElevatorProductTypesResponse } from '../models';
// @ts-ignore
import { GetElevatorResponse } from '../models';
// @ts-ignore
import { GetElevatorsMyResponse } from '../models';
// @ts-ignore
import { GetElevatorsResponse } from '../models';
// @ts-ignore
import { GetVipElevatorsResponse } from '../models';
/**
 * ElevatorApi - axios parameter creator
 * @export
 */
export const ElevatorApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} [currentPage] Номер текущей страницы
         * @param {number} [pageSize] Количество элементов на странице
         * @param {string} [elevatorNameOrAddress] Название элеватора или его адрес
         * @param {Array<string>} [regionIds] Список id регионов
         * @param {Array<string>} [productTypes] Список типов товаров
         * @param {boolean} [isDeletedOnly] Показывать скрытые элеваторы
         * @param {boolean} [isCreationRequestOnly] Показывать запросы на создание элеватора
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        elevatorAdminControllerAdminGetElevators: async (currentPage?: number, pageSize?: number, elevatorNameOrAddress?: string, regionIds?: Array<string>, productTypes?: Array<string>, isDeletedOnly?: boolean, isCreationRequestOnly?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/elevators`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (currentPage !== undefined) {
                localVarQueryParameter['current-page'] = currentPage;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page-size'] = pageSize;
            }

            if (elevatorNameOrAddress !== undefined) {
                localVarQueryParameter['elevator-name-or-address'] = elevatorNameOrAddress;
            }

            if (regionIds) {
                localVarQueryParameter['region-ids'] = regionIds;
            }

            if (productTypes) {
                localVarQueryParameter['product-types'] = productTypes;
            }

            if (isDeletedOnly !== undefined) {
                localVarQueryParameter['isDeletedOnly'] = isDeletedOnly;
            }

            if (isCreationRequestOnly !== undefined) {
                localVarQueryParameter['isCreationRequestOnly'] = isCreationRequestOnly;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AdminCreateElevatorBody} adminCreateElevatorBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        elevatorAdminControllerCreateElevator: async (adminCreateElevatorBody: AdminCreateElevatorBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'adminCreateElevatorBody' is not null or undefined
            assertParamExists('elevatorAdminControllerCreateElevator', 'adminCreateElevatorBody', adminCreateElevatorBody)
            const localVarPath = `/admin/elevator`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminCreateElevatorBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} elevatorId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        elevatorAdminControllerDeleteElevator: async (elevatorId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'elevatorId' is not null or undefined
            assertParamExists('elevatorAdminControllerDeleteElevator', 'elevatorId', elevatorId)
            const localVarPath = `/admin/elevator/{elevatorId}`
                .replace(`{${"elevatorId"}}`, encodeURIComponent(String(elevatorId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        elevatorAdminControllerGetElevator: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('elevatorAdminControllerGetElevator', 'id', id)
            const localVarPath = `/admin/elevator/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} elevatorId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        elevatorAdminControllerRecoverElevator: async (elevatorId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'elevatorId' is not null or undefined
            assertParamExists('elevatorAdminControllerRecoverElevator', 'elevatorId', elevatorId)
            const localVarPath = `/admin/elevator/{elevatorId}/recover`
                .replace(`{${"elevatorId"}}`, encodeURIComponent(String(elevatorId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} elevatorId 
         * @param {AdminUpdateElevatorBody} adminUpdateElevatorBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        elevatorAdminControllerUpdateElevator: async (elevatorId: number, adminUpdateElevatorBody: AdminUpdateElevatorBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'elevatorId' is not null or undefined
            assertParamExists('elevatorAdminControllerUpdateElevator', 'elevatorId', elevatorId)
            // verify required parameter 'adminUpdateElevatorBody' is not null or undefined
            assertParamExists('elevatorAdminControllerUpdateElevator', 'adminUpdateElevatorBody', adminUpdateElevatorBody)
            const localVarPath = `/admin/elevator/{elevatorId}`
                .replace(`{${"elevatorId"}}`, encodeURIComponent(String(elevatorId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminUpdateElevatorBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        elevatorControllerGetElevator: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('elevatorControllerGetElevator', 'id', id)
            const localVarPath = `/elevator/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} elevatorId 
         * @param {number} [currentPage] Номер текущей страницы
         * @param {number} [pageSize] Количество элементов на странице
         * @param {string} [companyName] Название компании
         * @param {Array<string>} [productTypes] Список типов товаров
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        elevatorControllerGetElevatorCompanies: async (elevatorId: number, currentPage?: number, pageSize?: number, companyName?: string, productTypes?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'elevatorId' is not null or undefined
            assertParamExists('elevatorControllerGetElevatorCompanies', 'elevatorId', elevatorId)
            const localVarPath = `/elevator/{elevatorId}/companies`
                .replace(`{${"elevatorId"}}`, encodeURIComponent(String(elevatorId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (currentPage !== undefined) {
                localVarQueryParameter['current-page'] = currentPage;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page-size'] = pageSize;
            }

            if (companyName !== undefined) {
                localVarQueryParameter['company-name'] = companyName;
            }

            if (productTypes) {
                localVarQueryParameter['product-types'] = productTypes;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {number} [companyId] Идентификатор компании
         * @param {'BUY' | 'SELL'} [offerType] Тип объявления
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        elevatorControllerGetElevatorProductTypes: async (id: number, companyId?: number, offerType?: 'BUY' | 'SELL', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('elevatorControllerGetElevatorProductTypes', 'id', id)
            const localVarPath = `/elevator/{id}/product-types`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }

            if (offerType !== undefined) {
                localVarQueryParameter['offerType'] = offerType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [currentPage] Номер текущей страницы
         * @param {number} [pageSize] Количество элементов на странице
         * @param {string} [elevatorNameOrAddress] Название элеватора или его адрес
         * @param {Array<string>} [regionIds] Список id регионов
         * @param {Array<string>} [productTypes] Список типов товаров
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        elevatorControllerGetElevators: async (currentPage?: number, pageSize?: number, elevatorNameOrAddress?: string, regionIds?: Array<string>, productTypes?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/elevators`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (currentPage !== undefined) {
                localVarQueryParameter['current-page'] = currentPage;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page-size'] = pageSize;
            }

            if (elevatorNameOrAddress !== undefined) {
                localVarQueryParameter['elevator-name-or-address'] = elevatorNameOrAddress;
            }

            if (regionIds) {
                localVarQueryParameter['region-ids'] = regionIds;
            }

            if (productTypes) {
                localVarQueryParameter['product-types'] = productTypes;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} userCompanyId Id компании, пользователь которой делает запрос
         * @param {number} [currentPage] Номер текущей страницы
         * @param {number} [pageSize] Количество элементов на странице
         * @param {number} [elevatorId] Id элеватора
         * @param {Array<string>} [productTypes] Массив с типами товаров
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        elevatorControllerGetElevatorsMy: async (userCompanyId: number, currentPage?: number, pageSize?: number, elevatorId?: number, productTypes?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userCompanyId' is not null or undefined
            assertParamExists('elevatorControllerGetElevatorsMy', 'userCompanyId', userCompanyId)
            const localVarPath = `/elevators/my`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (currentPage !== undefined) {
                localVarQueryParameter['current-page'] = currentPage;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page-size'] = pageSize;
            }

            if (userCompanyId !== undefined) {
                localVarQueryParameter['userCompanyId'] = userCompanyId;
            }

            if (elevatorId !== undefined) {
                localVarQueryParameter['elevatorId'] = elevatorId;
            }

            if (productTypes) {
                localVarQueryParameter['product-types'] = productTypes;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        elevatorControllerGetVipElevators: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/elevators/vip`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ElevatorApi - functional programming interface
 * @export
 */
export const ElevatorApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ElevatorApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} [currentPage] Номер текущей страницы
         * @param {number} [pageSize] Количество элементов на странице
         * @param {string} [elevatorNameOrAddress] Название элеватора или его адрес
         * @param {Array<string>} [regionIds] Список id регионов
         * @param {Array<string>} [productTypes] Список типов товаров
         * @param {boolean} [isDeletedOnly] Показывать скрытые элеваторы
         * @param {boolean} [isCreationRequestOnly] Показывать запросы на создание элеватора
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async elevatorAdminControllerAdminGetElevators(currentPage?: number, pageSize?: number, elevatorNameOrAddress?: string, regionIds?: Array<string>, productTypes?: Array<string>, isDeletedOnly?: boolean, isCreationRequestOnly?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminGetElevatorsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.elevatorAdminControllerAdminGetElevators(currentPage, pageSize, elevatorNameOrAddress, regionIds, productTypes, isDeletedOnly, isCreationRequestOnly, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {AdminCreateElevatorBody} adminCreateElevatorBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async elevatorAdminControllerCreateElevator(adminCreateElevatorBody: AdminCreateElevatorBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.elevatorAdminControllerCreateElevator(adminCreateElevatorBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} elevatorId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async elevatorAdminControllerDeleteElevator(elevatorId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.elevatorAdminControllerDeleteElevator(elevatorId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async elevatorAdminControllerGetElevator(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminGetElevatorResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.elevatorAdminControllerGetElevator(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} elevatorId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async elevatorAdminControllerRecoverElevator(elevatorId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.elevatorAdminControllerRecoverElevator(elevatorId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} elevatorId 
         * @param {AdminUpdateElevatorBody} adminUpdateElevatorBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async elevatorAdminControllerUpdateElevator(elevatorId: number, adminUpdateElevatorBody: AdminUpdateElevatorBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.elevatorAdminControllerUpdateElevator(elevatorId, adminUpdateElevatorBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async elevatorControllerGetElevator(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetElevatorResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.elevatorControllerGetElevator(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} elevatorId 
         * @param {number} [currentPage] Номер текущей страницы
         * @param {number} [pageSize] Количество элементов на странице
         * @param {string} [companyName] Название компании
         * @param {Array<string>} [productTypes] Список типов товаров
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async elevatorControllerGetElevatorCompanies(elevatorId: number, currentPage?: number, pageSize?: number, companyName?: string, productTypes?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetElevatorCompaniesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.elevatorControllerGetElevatorCompanies(elevatorId, currentPage, pageSize, companyName, productTypes, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {number} [companyId] Идентификатор компании
         * @param {'BUY' | 'SELL'} [offerType] Тип объявления
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async elevatorControllerGetElevatorProductTypes(id: number, companyId?: number, offerType?: 'BUY' | 'SELL', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetElevatorProductTypesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.elevatorControllerGetElevatorProductTypes(id, companyId, offerType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [currentPage] Номер текущей страницы
         * @param {number} [pageSize] Количество элементов на странице
         * @param {string} [elevatorNameOrAddress] Название элеватора или его адрес
         * @param {Array<string>} [regionIds] Список id регионов
         * @param {Array<string>} [productTypes] Список типов товаров
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async elevatorControllerGetElevators(currentPage?: number, pageSize?: number, elevatorNameOrAddress?: string, regionIds?: Array<string>, productTypes?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetElevatorsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.elevatorControllerGetElevators(currentPage, pageSize, elevatorNameOrAddress, regionIds, productTypes, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} userCompanyId Id компании, пользователь которой делает запрос
         * @param {number} [currentPage] Номер текущей страницы
         * @param {number} [pageSize] Количество элементов на странице
         * @param {number} [elevatorId] Id элеватора
         * @param {Array<string>} [productTypes] Массив с типами товаров
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async elevatorControllerGetElevatorsMy(userCompanyId: number, currentPage?: number, pageSize?: number, elevatorId?: number, productTypes?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetElevatorsMyResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.elevatorControllerGetElevatorsMy(userCompanyId, currentPage, pageSize, elevatorId, productTypes, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async elevatorControllerGetVipElevators(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetVipElevatorsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.elevatorControllerGetVipElevators(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ElevatorApi - factory interface
 * @export
 */
export const ElevatorApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ElevatorApiFp(configuration)
    return {
        /**
         * 
         * @param {number} [currentPage] Номер текущей страницы
         * @param {number} [pageSize] Количество элементов на странице
         * @param {string} [elevatorNameOrAddress] Название элеватора или его адрес
         * @param {Array<string>} [regionIds] Список id регионов
         * @param {Array<string>} [productTypes] Список типов товаров
         * @param {boolean} [isDeletedOnly] Показывать скрытые элеваторы
         * @param {boolean} [isCreationRequestOnly] Показывать запросы на создание элеватора
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        elevatorAdminControllerAdminGetElevators(currentPage?: number, pageSize?: number, elevatorNameOrAddress?: string, regionIds?: Array<string>, productTypes?: Array<string>, isDeletedOnly?: boolean, isCreationRequestOnly?: boolean, options?: any): AxiosPromise<AdminGetElevatorsResponse> {
            return localVarFp.elevatorAdminControllerAdminGetElevators(currentPage, pageSize, elevatorNameOrAddress, regionIds, productTypes, isDeletedOnly, isCreationRequestOnly, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AdminCreateElevatorBody} adminCreateElevatorBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        elevatorAdminControllerCreateElevator(adminCreateElevatorBody: AdminCreateElevatorBody, options?: any): AxiosPromise<void> {
            return localVarFp.elevatorAdminControllerCreateElevator(adminCreateElevatorBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} elevatorId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        elevatorAdminControllerDeleteElevator(elevatorId: number, options?: any): AxiosPromise<void> {
            return localVarFp.elevatorAdminControllerDeleteElevator(elevatorId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        elevatorAdminControllerGetElevator(id: number, options?: any): AxiosPromise<AdminGetElevatorResponse> {
            return localVarFp.elevatorAdminControllerGetElevator(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} elevatorId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        elevatorAdminControllerRecoverElevator(elevatorId: number, options?: any): AxiosPromise<void> {
            return localVarFp.elevatorAdminControllerRecoverElevator(elevatorId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} elevatorId 
         * @param {AdminUpdateElevatorBody} adminUpdateElevatorBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        elevatorAdminControllerUpdateElevator(elevatorId: number, adminUpdateElevatorBody: AdminUpdateElevatorBody, options?: any): AxiosPromise<void> {
            return localVarFp.elevatorAdminControllerUpdateElevator(elevatorId, adminUpdateElevatorBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        elevatorControllerGetElevator(id: number, options?: any): AxiosPromise<GetElevatorResponse> {
            return localVarFp.elevatorControllerGetElevator(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} elevatorId 
         * @param {number} [currentPage] Номер текущей страницы
         * @param {number} [pageSize] Количество элементов на странице
         * @param {string} [companyName] Название компании
         * @param {Array<string>} [productTypes] Список типов товаров
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        elevatorControllerGetElevatorCompanies(elevatorId: number, currentPage?: number, pageSize?: number, companyName?: string, productTypes?: Array<string>, options?: any): AxiosPromise<GetElevatorCompaniesResponse> {
            return localVarFp.elevatorControllerGetElevatorCompanies(elevatorId, currentPage, pageSize, companyName, productTypes, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {number} [companyId] Идентификатор компании
         * @param {'BUY' | 'SELL'} [offerType] Тип объявления
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        elevatorControllerGetElevatorProductTypes(id: number, companyId?: number, offerType?: 'BUY' | 'SELL', options?: any): AxiosPromise<GetElevatorProductTypesResponse> {
            return localVarFp.elevatorControllerGetElevatorProductTypes(id, companyId, offerType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [currentPage] Номер текущей страницы
         * @param {number} [pageSize] Количество элементов на странице
         * @param {string} [elevatorNameOrAddress] Название элеватора или его адрес
         * @param {Array<string>} [regionIds] Список id регионов
         * @param {Array<string>} [productTypes] Список типов товаров
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        elevatorControllerGetElevators(currentPage?: number, pageSize?: number, elevatorNameOrAddress?: string, regionIds?: Array<string>, productTypes?: Array<string>, options?: any): AxiosPromise<GetElevatorsResponse> {
            return localVarFp.elevatorControllerGetElevators(currentPage, pageSize, elevatorNameOrAddress, regionIds, productTypes, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} userCompanyId Id компании, пользователь которой делает запрос
         * @param {number} [currentPage] Номер текущей страницы
         * @param {number} [pageSize] Количество элементов на странице
         * @param {number} [elevatorId] Id элеватора
         * @param {Array<string>} [productTypes] Массив с типами товаров
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        elevatorControllerGetElevatorsMy(userCompanyId: number, currentPage?: number, pageSize?: number, elevatorId?: number, productTypes?: Array<string>, options?: any): AxiosPromise<GetElevatorsMyResponse> {
            return localVarFp.elevatorControllerGetElevatorsMy(userCompanyId, currentPage, pageSize, elevatorId, productTypes, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        elevatorControllerGetVipElevators(options?: any): AxiosPromise<GetVipElevatorsResponse> {
            return localVarFp.elevatorControllerGetVipElevators(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for elevatorAdminControllerAdminGetElevators operation in ElevatorApi.
 * @export
 * @interface ElevatorApiElevatorAdminControllerAdminGetElevatorsRequest
 */
export interface ElevatorApiElevatorAdminControllerAdminGetElevatorsRequest {
    /**
     * Номер текущей страницы
     * @type {number}
     * @memberof ElevatorApiElevatorAdminControllerAdminGetElevators
     */
    readonly currentPage?: number

    /**
     * Количество элементов на странице
     * @type {number}
     * @memberof ElevatorApiElevatorAdminControllerAdminGetElevators
     */
    readonly pageSize?: number

    /**
     * Название элеватора или его адрес
     * @type {string}
     * @memberof ElevatorApiElevatorAdminControllerAdminGetElevators
     */
    readonly elevatorNameOrAddress?: string

    /**
     * Список id регионов
     * @type {Array<string>}
     * @memberof ElevatorApiElevatorAdminControllerAdminGetElevators
     */
    readonly regionIds?: Array<string>

    /**
     * Список типов товаров
     * @type {Array<string>}
     * @memberof ElevatorApiElevatorAdminControllerAdminGetElevators
     */
    readonly productTypes?: Array<string>

    /**
     * Показывать скрытые элеваторы
     * @type {boolean}
     * @memberof ElevatorApiElevatorAdminControllerAdminGetElevators
     */
    readonly isDeletedOnly?: boolean

    /**
     * Показывать запросы на создание элеватора
     * @type {boolean}
     * @memberof ElevatorApiElevatorAdminControllerAdminGetElevators
     */
    readonly isCreationRequestOnly?: boolean
}

/**
 * Request parameters for elevatorAdminControllerCreateElevator operation in ElevatorApi.
 * @export
 * @interface ElevatorApiElevatorAdminControllerCreateElevatorRequest
 */
export interface ElevatorApiElevatorAdminControllerCreateElevatorRequest {
    /**
     * 
     * @type {AdminCreateElevatorBody}
     * @memberof ElevatorApiElevatorAdminControllerCreateElevator
     */
    readonly adminCreateElevatorBody: AdminCreateElevatorBody
}

/**
 * Request parameters for elevatorAdminControllerDeleteElevator operation in ElevatorApi.
 * @export
 * @interface ElevatorApiElevatorAdminControllerDeleteElevatorRequest
 */
export interface ElevatorApiElevatorAdminControllerDeleteElevatorRequest {
    /**
     * 
     * @type {number}
     * @memberof ElevatorApiElevatorAdminControllerDeleteElevator
     */
    readonly elevatorId: number
}

/**
 * Request parameters for elevatorAdminControllerGetElevator operation in ElevatorApi.
 * @export
 * @interface ElevatorApiElevatorAdminControllerGetElevatorRequest
 */
export interface ElevatorApiElevatorAdminControllerGetElevatorRequest {
    /**
     * 
     * @type {number}
     * @memberof ElevatorApiElevatorAdminControllerGetElevator
     */
    readonly id: number
}

/**
 * Request parameters for elevatorAdminControllerRecoverElevator operation in ElevatorApi.
 * @export
 * @interface ElevatorApiElevatorAdminControllerRecoverElevatorRequest
 */
export interface ElevatorApiElevatorAdminControllerRecoverElevatorRequest {
    /**
     * 
     * @type {number}
     * @memberof ElevatorApiElevatorAdminControllerRecoverElevator
     */
    readonly elevatorId: number
}

/**
 * Request parameters for elevatorAdminControllerUpdateElevator operation in ElevatorApi.
 * @export
 * @interface ElevatorApiElevatorAdminControllerUpdateElevatorRequest
 */
export interface ElevatorApiElevatorAdminControllerUpdateElevatorRequest {
    /**
     * 
     * @type {number}
     * @memberof ElevatorApiElevatorAdminControllerUpdateElevator
     */
    readonly elevatorId: number

    /**
     * 
     * @type {AdminUpdateElevatorBody}
     * @memberof ElevatorApiElevatorAdminControllerUpdateElevator
     */
    readonly adminUpdateElevatorBody: AdminUpdateElevatorBody
}

/**
 * Request parameters for elevatorControllerGetElevator operation in ElevatorApi.
 * @export
 * @interface ElevatorApiElevatorControllerGetElevatorRequest
 */
export interface ElevatorApiElevatorControllerGetElevatorRequest {
    /**
     * 
     * @type {number}
     * @memberof ElevatorApiElevatorControllerGetElevator
     */
    readonly id: number
}

/**
 * Request parameters for elevatorControllerGetElevatorCompanies operation in ElevatorApi.
 * @export
 * @interface ElevatorApiElevatorControllerGetElevatorCompaniesRequest
 */
export interface ElevatorApiElevatorControllerGetElevatorCompaniesRequest {
    /**
     * 
     * @type {number}
     * @memberof ElevatorApiElevatorControllerGetElevatorCompanies
     */
    readonly elevatorId: number

    /**
     * Номер текущей страницы
     * @type {number}
     * @memberof ElevatorApiElevatorControllerGetElevatorCompanies
     */
    readonly currentPage?: number

    /**
     * Количество элементов на странице
     * @type {number}
     * @memberof ElevatorApiElevatorControllerGetElevatorCompanies
     */
    readonly pageSize?: number

    /**
     * Название компании
     * @type {string}
     * @memberof ElevatorApiElevatorControllerGetElevatorCompanies
     */
    readonly companyName?: string

    /**
     * Список типов товаров
     * @type {Array<string>}
     * @memberof ElevatorApiElevatorControllerGetElevatorCompanies
     */
    readonly productTypes?: Array<string>
}

/**
 * Request parameters for elevatorControllerGetElevatorProductTypes operation in ElevatorApi.
 * @export
 * @interface ElevatorApiElevatorControllerGetElevatorProductTypesRequest
 */
export interface ElevatorApiElevatorControllerGetElevatorProductTypesRequest {
    /**
     * 
     * @type {number}
     * @memberof ElevatorApiElevatorControllerGetElevatorProductTypes
     */
    readonly id: number

    /**
     * Идентификатор компании
     * @type {number}
     * @memberof ElevatorApiElevatorControllerGetElevatorProductTypes
     */
    readonly companyId?: number

    /**
     * Тип объявления
     * @type {'BUY' | 'SELL'}
     * @memberof ElevatorApiElevatorControllerGetElevatorProductTypes
     */
    readonly offerType?: 'BUY' | 'SELL'
}

/**
 * Request parameters for elevatorControllerGetElevators operation in ElevatorApi.
 * @export
 * @interface ElevatorApiElevatorControllerGetElevatorsRequest
 */
export interface ElevatorApiElevatorControllerGetElevatorsRequest {
    /**
     * Номер текущей страницы
     * @type {number}
     * @memberof ElevatorApiElevatorControllerGetElevators
     */
    readonly currentPage?: number

    /**
     * Количество элементов на странице
     * @type {number}
     * @memberof ElevatorApiElevatorControllerGetElevators
     */
    readonly pageSize?: number

    /**
     * Название элеватора или его адрес
     * @type {string}
     * @memberof ElevatorApiElevatorControllerGetElevators
     */
    readonly elevatorNameOrAddress?: string

    /**
     * Список id регионов
     * @type {Array<string>}
     * @memberof ElevatorApiElevatorControllerGetElevators
     */
    readonly regionIds?: Array<string>

    /**
     * Список типов товаров
     * @type {Array<string>}
     * @memberof ElevatorApiElevatorControllerGetElevators
     */
    readonly productTypes?: Array<string>
}

/**
 * Request parameters for elevatorControllerGetElevatorsMy operation in ElevatorApi.
 * @export
 * @interface ElevatorApiElevatorControllerGetElevatorsMyRequest
 */
export interface ElevatorApiElevatorControllerGetElevatorsMyRequest {
    /**
     * Id компании, пользователь которой делает запрос
     * @type {number}
     * @memberof ElevatorApiElevatorControllerGetElevatorsMy
     */
    readonly userCompanyId: number

    /**
     * Номер текущей страницы
     * @type {number}
     * @memberof ElevatorApiElevatorControllerGetElevatorsMy
     */
    readonly currentPage?: number

    /**
     * Количество элементов на странице
     * @type {number}
     * @memberof ElevatorApiElevatorControllerGetElevatorsMy
     */
    readonly pageSize?: number

    /**
     * Id элеватора
     * @type {number}
     * @memberof ElevatorApiElevatorControllerGetElevatorsMy
     */
    readonly elevatorId?: number

    /**
     * Массив с типами товаров
     * @type {Array<string>}
     * @memberof ElevatorApiElevatorControllerGetElevatorsMy
     */
    readonly productTypes?: Array<string>
}

/**
 * ElevatorApi - object-oriented interface
 * @export
 * @class ElevatorApi
 * @extends {BaseAPI}
 */
export class ElevatorApi extends BaseAPI {
    /**
     * 
     * @param {ElevatorApiElevatorAdminControllerAdminGetElevatorsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ElevatorApi
     */
    public elevatorAdminControllerAdminGetElevators(requestParameters: ElevatorApiElevatorAdminControllerAdminGetElevatorsRequest = {}, options?: AxiosRequestConfig) {
        return ElevatorApiFp(this.configuration).elevatorAdminControllerAdminGetElevators(requestParameters.currentPage, requestParameters.pageSize, requestParameters.elevatorNameOrAddress, requestParameters.regionIds, requestParameters.productTypes, requestParameters.isDeletedOnly, requestParameters.isCreationRequestOnly, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ElevatorApiElevatorAdminControllerCreateElevatorRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ElevatorApi
     */
    public elevatorAdminControllerCreateElevator(requestParameters: ElevatorApiElevatorAdminControllerCreateElevatorRequest, options?: AxiosRequestConfig) {
        return ElevatorApiFp(this.configuration).elevatorAdminControllerCreateElevator(requestParameters.adminCreateElevatorBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ElevatorApiElevatorAdminControllerDeleteElevatorRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ElevatorApi
     */
    public elevatorAdminControllerDeleteElevator(requestParameters: ElevatorApiElevatorAdminControllerDeleteElevatorRequest, options?: AxiosRequestConfig) {
        return ElevatorApiFp(this.configuration).elevatorAdminControllerDeleteElevator(requestParameters.elevatorId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ElevatorApiElevatorAdminControllerGetElevatorRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ElevatorApi
     */
    public elevatorAdminControllerGetElevator(requestParameters: ElevatorApiElevatorAdminControllerGetElevatorRequest, options?: AxiosRequestConfig) {
        return ElevatorApiFp(this.configuration).elevatorAdminControllerGetElevator(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ElevatorApiElevatorAdminControllerRecoverElevatorRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ElevatorApi
     */
    public elevatorAdminControllerRecoverElevator(requestParameters: ElevatorApiElevatorAdminControllerRecoverElevatorRequest, options?: AxiosRequestConfig) {
        return ElevatorApiFp(this.configuration).elevatorAdminControllerRecoverElevator(requestParameters.elevatorId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ElevatorApiElevatorAdminControllerUpdateElevatorRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ElevatorApi
     */
    public elevatorAdminControllerUpdateElevator(requestParameters: ElevatorApiElevatorAdminControllerUpdateElevatorRequest, options?: AxiosRequestConfig) {
        return ElevatorApiFp(this.configuration).elevatorAdminControllerUpdateElevator(requestParameters.elevatorId, requestParameters.adminUpdateElevatorBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ElevatorApiElevatorControllerGetElevatorRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ElevatorApi
     */
    public elevatorControllerGetElevator(requestParameters: ElevatorApiElevatorControllerGetElevatorRequest, options?: AxiosRequestConfig) {
        return ElevatorApiFp(this.configuration).elevatorControllerGetElevator(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ElevatorApiElevatorControllerGetElevatorCompaniesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ElevatorApi
     */
    public elevatorControllerGetElevatorCompanies(requestParameters: ElevatorApiElevatorControllerGetElevatorCompaniesRequest, options?: AxiosRequestConfig) {
        return ElevatorApiFp(this.configuration).elevatorControllerGetElevatorCompanies(requestParameters.elevatorId, requestParameters.currentPage, requestParameters.pageSize, requestParameters.companyName, requestParameters.productTypes, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ElevatorApiElevatorControllerGetElevatorProductTypesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ElevatorApi
     */
    public elevatorControllerGetElevatorProductTypes(requestParameters: ElevatorApiElevatorControllerGetElevatorProductTypesRequest, options?: AxiosRequestConfig) {
        return ElevatorApiFp(this.configuration).elevatorControllerGetElevatorProductTypes(requestParameters.id, requestParameters.companyId, requestParameters.offerType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ElevatorApiElevatorControllerGetElevatorsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ElevatorApi
     */
    public elevatorControllerGetElevators(requestParameters: ElevatorApiElevatorControllerGetElevatorsRequest = {}, options?: AxiosRequestConfig) {
        return ElevatorApiFp(this.configuration).elevatorControllerGetElevators(requestParameters.currentPage, requestParameters.pageSize, requestParameters.elevatorNameOrAddress, requestParameters.regionIds, requestParameters.productTypes, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ElevatorApiElevatorControllerGetElevatorsMyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ElevatorApi
     */
    public elevatorControllerGetElevatorsMy(requestParameters: ElevatorApiElevatorControllerGetElevatorsMyRequest, options?: AxiosRequestConfig) {
        return ElevatorApiFp(this.configuration).elevatorControllerGetElevatorsMy(requestParameters.userCompanyId, requestParameters.currentPage, requestParameters.pageSize, requestParameters.elevatorId, requestParameters.productTypes, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ElevatorApi
     */
    public elevatorControllerGetVipElevators(options?: AxiosRequestConfig) {
        return ElevatorApiFp(this.configuration).elevatorControllerGetVipElevators(options).then((request) => request(this.axios, this.basePath));
    }
}
