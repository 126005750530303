import { codeUnreachable } from '~/utils/code-unreachable'
import { FreightDealStatus, FreightDealType } from '~api/gql-generated/graphql'

export function getFreightLastResponseName(value: FreightDealStatus, type: FreightDealType): string {
  switch (value) {
    case FreightDealStatus.Agreement:
    case FreightDealStatus.Realised:
    case FreightDealStatus.Rejected:
      return type === FreightDealType.Response ? 'принят' : 'принята'
    case FreightDealStatus.OnModeration:
      return 'на рассмотрении'
    case FreightDealStatus.RejectedAfterModeration:
      return type === FreightDealType.Response ? 'отклонен' : 'отклонена'
    case FreightDealStatus.Shipment:
      return 'доставка'
    default:
      return codeUnreachable(value)
  }
}
