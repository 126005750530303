import { parseTimeToDate, transformDateToTimeString } from '~/utils/getDateFormat'
import {
  CreateWarehouseBody,
  CreateWarehousePositionBody,
  CreationProductParameterData,
  TransportMode,
  UpdateWarehouseBody,
  WarehouseData,
} from '~api/generated'
import {
  CreateWarehouseFormValue,
  EditWarehouseFormValue,
} from '~pages/Warehouses/components/CreateWarehouse/CreateWarehouseModal/CreateWarehouseModal'

import { WarehouseCultureFormValues } from '~pages/Warehouses/components/WarehouseCultureForm/WarehouseCultureForm.types'

export function mapWarehouseInfoFormValuesToCreateRequest(formValues: CreateWarehouseFormValue): CreateWarehouseBody {
  return {
    address: formValues.step1OverallInfo.address,
    name: formValues.step1OverallInfo.name,
    openingTime: formValues.step1OverallInfo?.openingTime
      ? transformDateToTimeString(formValues.step1OverallInfo.openingTime)
      : null,
    closingTime: formValues.step1OverallInfo?.closingTime
      ? transformDateToTimeString(formValues.step1OverallInfo.closingTime)
      : null,
    hasLaboratory: formValues.step1OverallInfo.hasLab,
    hasElectronicQueue: formValues.step1OverallInfo.hasDigitalQueue,
    workingDaysOfWeek: formValues.step1OverallInfo.workingDaysOfWeek,
    railTransportParameters: formValues.step1OverallInfo.transport.includes(TransportMode.Rail)
      ? {
          // FIXME: нейминг надо как в базисах
          stationName: formValues.step2Rail?.stationName ?? '',
          hasScales: formValues.step2Rail?.hasRailScales,
          hasOwnLocomotives: formValues.step2Rail?.hasRailStock,
          hasOwnRailroad: formValues.step2Rail?.hasRailWay,
          servicedWagonsCount: formValues.step2Rail?.waitingArea,
          loadingThroughput: formValues.step2Rail?.railLoadSpeed,
          additionalDetails: formValues.step2Rail?.railAdditionalInfo,
        }
      : null,
    roadTransportParameters: formValues.step1OverallInfo.transport.includes(TransportMode.Road)
      ? {
          loadingTypes: formValues.step3Car?.loadingTransport ?? [],
          loadingThroughput: formValues.step3Car?.autoReceptionSpeed,
          unloadingTypes: formValues.step3Car?.unloadingTransport ?? [],
          unloadingThroughput: formValues.step3Car?.autoUnloadSpeed,
          vehicleClearanceMinCm: formValues.step3Car?.autoClearance,
          vehicleHeightMaxM: formValues.step3Car?.autoHeight ? formValues.step3Car.autoHeight * 100 : undefined,
          vehicleLengthMaxM: formValues.step3Car?.autoLength,
          hasScales: formValues.step3Car?.hasScales,
          scalesWeightLimitT: formValues.step3Car?.scalesWeightLimit,
          distanceToScalesM: formValues.step3Car?.distanceToScalesM,
          hasWeighingAutomation: formValues.step3Car?.hasWeighingAutomation,
          roadSurfaceType: formValues.step3Car?.roadSurfaceType,
          roadSurfaceQuality: formValues.step3Car?.roadSurfaceQuality,
          additionalDetails: formValues.step3Car?.autoAdditionalInfo,
          hasParking: formValues.step3Car?.hasAutoStorage,
        }
      : null,
  }
}

export function mapWarehouseInfoFormValuesToUpdateRequest(formValues: EditWarehouseFormValue): UpdateWarehouseBody {
  return {
    name: formValues.step1OverallInfo?.name,
    openingTime: formValues.step1OverallInfo?.openingTime
      ? transformDateToTimeString(formValues.step1OverallInfo.openingTime)
      : null,
    closingTime: formValues.step1OverallInfo?.closingTime
      ? transformDateToTimeString(formValues.step1OverallInfo.closingTime)
      : null,
    hasLaboratory: formValues.step1OverallInfo?.hasLab,
    hasElectronicQueue: formValues.step1OverallInfo?.hasDigitalQueue,
    workingDaysOfWeek: formValues.step1OverallInfo?.workingDaysOfWeek,
    railTransportParameters: formValues.step1OverallInfo?.transport?.includes(TransportMode.Rail)
      ? {
          stationName: formValues.step2Rail?.stationName ?? '',
          hasScales: formValues.step2Rail?.hasRailScales,
          hasOwnLocomotives: formValues.step2Rail?.hasRailStock,
          hasOwnRailroad: formValues.step2Rail?.hasRailWay,
          servicedWagonsCount: formValues.step2Rail?.waitingArea,
          loadingThroughput: formValues.step2Rail?.railLoadSpeed,
          additionalDetails: formValues.step2Rail?.railAdditionalInfo,
          // unloadingThroughput, // FIXME: такого поля нет на макетах
        }
      : null,
    roadTransportParameters: formValues.step1OverallInfo?.transport?.includes(TransportMode.Road)
      ? {
          loadingTypes: formValues.step3Car?.loadingTransport ?? [],
          loadingThroughput: formValues.step3Car?.autoReceptionSpeed,
          unloadingTypes: formValues.step3Car?.unloadingTransport ?? [],
          unloadingThroughput: formValues.step3Car?.autoUnloadSpeed,
          vehicleClearanceMinCm: formValues.step3Car?.autoClearance,
          vehicleHeightMaxM: formValues.step3Car?.autoHeight ? formValues.step3Car.autoHeight * 100 : undefined,
          vehicleLengthMaxM: formValues.step3Car?.autoLength,
          hasScales: formValues.step3Car?.hasScales,
          scalesWeightLimitT: formValues.step3Car?.scalesWeightLimit,
          distanceToScalesM: formValues.step3Car?.distanceToScalesM,
          hasWeighingAutomation: formValues.step3Car?.hasWeighingAutomation,
          roadSurfaceType: formValues.step3Car?.roadSurfaceType,
          roadSurfaceQuality: formValues.step3Car?.roadSurfaceQuality,
          additionalDetails: formValues.step3Car?.autoAdditionalInfo,
          hasParking: formValues.step3Car?.hasAutoStorage,
        }
      : null,
  }
}

export function mapWarehouseCultureFormValuesToRequest(
  _culture: WarehouseCultureFormValues | null
): CreateWarehousePositionBody & { laboratoryAnalysis?: File } {
  return {
    product: {
      parameters: _culture?.culture?.parameters as CreationProductParameterData[],
      type: _culture?.culture?.cultureTypeId ?? '',
    },
    volume: _culture?.volumeTons ?? 0,
    laboratoryAnalysis: _culture?.labAnalysis,
  }
}

export function getDefaultWarehouseValuesForEditing(warehouse?: WarehouseData): EditWarehouseFormValue | undefined {
  if (!warehouse) {
    return
  }

  return {
    step1OverallInfo: {
      address: warehouse.address,
      name: warehouse.name ?? undefined,
      openingTime: warehouse.openingTime ? parseTimeToDate(warehouse.openingTime) : null,
      closingTime: warehouse.closingTime ? parseTimeToDate(warehouse.closingTime) : null,
      transport: warehouse.transportModes,
      hasLab: warehouse.hasLaboratory ?? undefined,
      hasDigitalQueue: warehouse.hasElectronicQueue ?? undefined,
      workingDaysOfWeek: warehouse.workingDaysOfWeek,
    },
    step2Rail: {
      stationName: warehouse.railTransportParameters?.stationName ?? '',
      hasRailScales: warehouse.railTransportParameters?.hasScales ?? undefined,
      hasRailStock: warehouse.railTransportParameters?.hasOwnLocomotives ?? undefined,
      hasRailWay: warehouse.railTransportParameters?.hasOwnRailroad ?? undefined,
      waitingArea: warehouse.railTransportParameters?.servicedWagonsCount ?? undefined,
      railLoadSpeed: warehouse.railTransportParameters?.loadingThroughput ?? undefined,
      // railLoadSpeed: warehouse.railTransportParameters?.unloadingThroughput ?? undefined, FIXME: такого поля нет на макетах
      railAdditionalInfo: warehouse.railTransportParameters?.additionalDetails ?? undefined,
    },
    step3Car: {
      loadingTransport: warehouse.roadTransportParameters?.loadingTypes ?? [],
      unloadingTransport: warehouse.roadTransportParameters?.unloadingTypes ?? [],
      autoReceptionSpeed: warehouse.roadTransportParameters?.loadingThroughput ?? undefined,
      autoUnloadSpeed: warehouse.roadTransportParameters?.unloadingThroughput ?? undefined,
      autoClearance: warehouse.roadTransportParameters?.vehicleClearanceMinCm ?? undefined,
      autoHeight: warehouse.roadTransportParameters?.vehicleHeightMaxM
        ? warehouse.roadTransportParameters.vehicleHeightMaxM / 100
        : undefined,
      autoLength: warehouse.roadTransportParameters?.vehicleLengthMaxM ?? undefined,
      hasScales: warehouse.roadTransportParameters?.hasScales ?? undefined,
      scalesWeightLimit: warehouse.roadTransportParameters?.scalesWeightLimitT ?? undefined,
      distanceToScalesM: warehouse.roadTransportParameters?.distanceToScalesM ?? undefined,
      hasWeighingAutomation: warehouse.roadTransportParameters?.hasWeighingAutomation ?? undefined,
      roadSurfaceType: warehouse.roadTransportParameters?.roadSurfaceType,
      roadSurfaceQuality: warehouse.roadTransportParameters?.roadSurfaceQuality,
      hasAutoStorage: warehouse.roadTransportParameters?.hasParking ?? undefined,
      autoAdditionalInfo: warehouse.roadTransportParameters?.additionalDetails ?? undefined,
    },
  }
}
