import * as yup from 'yup'

import { VALIDATORS_MSG } from '~shared/validations'
import { fullPhoneNumberValidator } from '~shared/validations/schemas'

const requiredTrimmedString = yup.string().trim().required(VALIDATORS_MSG.required)
const transformEmptyStringFn = (value: string) => (value === '' ? null : value)

export const addEmployeeToCompanyFormFormSchema = yup
  .object({
    name: requiredTrimmedString,
    phoneNumber: yup
      .string()
      .trim()
      .test('mobilePhone', VALIDATORS_MSG.notFullPhoneNumber, fullPhoneNumberValidator)
      .required(VALIDATORS_MSG.required),
    position: requiredTrimmedString,
    email: yup.string().nullable().trim().email(VALIDATORS_MSG.email).transform(transformEmptyStringFn),
  })
  .required()
