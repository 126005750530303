import React, { useState } from 'react'

import { Button, Carousel, Empty, Pagination, Spin } from 'antd'
import { FiArrowRight, FiChevronRight } from 'react-icons/fi'
import { useNavigate } from 'react-router-dom'

import { usePagination } from '~/hooks'
import { appRoutes } from '~/router'
import { Card } from '~/ui-components'
import { getNoun } from '~/utils/getNoun'
import { ElevatorCompanyAdvertsSummaryData, ElevatorCompanyData } from '~api/generated'
import { useGetElevatorCompanies } from '~hooks/elevators'
import { AdvertMiniOverview } from '~pages/BasisPage/modules/BasisDetails/components/AdvertMiniOverview'
import { SearchInput } from '~shared/components/SearchInput'
import { CultureSelectControl } from '~shared/controls'

import * as S from './ToBuyAdvertList.styled'

interface ToBuyAdvertListProps {
  basisId: number
}

export const ToBuyAdvertList: React.FC<ToBuyAdvertListProps> = ({ basisId }) => {
  const navigate = useNavigate()
  const { currentPage, setCurrentPage, pageSize, setPageSize, pageSizeOptions } = usePagination()
  const [selectedProductTypes, setSelectedProductTypes] = useState<string[]>([])
  const [companyNameQuery, setCompanyNameQuery] = useState<string>('')

  const getElevatorCompaniesFn = useGetElevatorCompanies({
    elevatorId: basisId,
    productTypes: selectedProductTypes,
    companyName: !companyNameQuery.trim() ? undefined : companyNameQuery,
    pageSize,
    currentPage,
  })

  const companies = getElevatorCompaniesFn.data?.elevatorCompanies
  const pagination = getElevatorCompaniesFn.data?.pagination

  const handleNavigateToAllBasisAdverts = () => {
    navigate(appRoutes.basisAdverts.url.replace(':id', basisId.toString()))
  }
  const handleNavigateToCompanyAdverts = (companyId: number) => {
    navigate(
      appRoutes.basisCompanyAdverts.url
        .replace(':basisId', basisId.toString())
        .replace(':companyId', companyId.toString())
    )
  }

  const getTotalCountOfAdverts = (c: ElevatorCompanyData): number =>
    c.advertsSummaries.reduce((acc, item) => acc + item.count, 0)

  function renderAdverts(adverts?: ElevatorCompanyAdvertsSummaryData[]) {
    const subArrayLenght = 2
    const list =
      !adverts || adverts.length === 0
        ? []
        : Array.from({ length: Math.ceil(adverts.length / subArrayLenght) }, (_, i) =>
            adverts.slice(i * subArrayLenght, i * subArrayLenght + subArrayLenght)
          )

    function SampleNextArrow(props: any) {
      const { className, style, onClick } = props
      return (
        <FiChevronRight
          className={className}
          size={24}
          color={'red'}
          style={{ display: 'block', top: '30%', width: 24, height: 24 }}
          onClick={onClick}
        />
      )
    }

    return (
      <section
        style={{ maxWidth: 500, cursor: 'auto' }}
        onClick={(e) => {
          e.preventDefault()
          e.stopPropagation()
        }}
      >
        <Carousel autoplay arrows={true} nextArrow={<SampleNextArrow />}>
          {list.map((subList, idx) => {
            return (
              <S.AdvertOverviewLIstWrapper key={idx}>
                {subList.map((advert) => (
                  <AdvertMiniOverview key={advert.productType.id.toString()} advert={advert} />
                ))}
              </S.AdvertOverviewLIstWrapper>
            )
          })}
        </Carousel>
      </section>
    )
  }

  return (
    <Spin spinning={getElevatorCompaniesFn.isFetching}>
      <S.ContentWrapper>
        <S.FiltersWrapper>
          <SearchInput
            defaultValue={companyNameQuery}
            onSearch={(v) => setCompanyNameQuery(v)}
            autoFocus={false}
            placeholder="Поиск компании"
          />
          <CultureSelectControl value={selectedProductTypes} onChange={(x) => setSelectedProductTypes(x)} />
        </S.FiltersWrapper>
        <div>
          <Button type="link" onClick={handleNavigateToAllBasisAdverts}>
            <b>Посмотреть объявления всех компаний</b>
          </Button>
        </div>

        <Spin spinning={getElevatorCompaniesFn.isFetching}>
          <S.CompanyLIstWrapper>
            {!companies?.length && <Empty description="Компании на базисе отсутстуют" />}

            {companies?.map((company) => (
              <Card
                style={{ cursor: 'pointer' }}
                key={company.id.toString()}
                onClick={() => handleNavigateToCompanyAdverts(company.id)}
              >
                <Card.Header>
                  <S.CardHeaderTop>
                    <Card.Title>{company.name}</Card.Title>
                    <FiArrowRight size={24} />
                  </S.CardHeaderTop>
                </Card.Header>

                <Card.Content>
                  <S.CardContentWrapper>
                    <S.AdvertsCounter>
                      {getTotalCountOfAdverts(company)}{' '}
                      {getNoun(getTotalCountOfAdverts(company), 'объявление', 'объявления', 'объявлений')}
                    </S.AdvertsCounter>

                    {renderAdverts(company.advertsSummaries)}
                  </S.CardContentWrapper>
                </Card.Content>
              </Card>
            ))}
          </S.CompanyLIstWrapper>
        </Spin>

        {pagination && pagination.total > 0 && (
          <Pagination
            current={pagination?.currentPage}
            total={pagination?.total}
            pageSize={pagination?.pageSize}
            pageSizeOptions={pageSizeOptions}
            onShowSizeChange={(x, size) => setPageSize(size)}
            showSizeChanger
            onChange={setCurrentPage}
          />
        )}
      </S.ContentWrapper>
    </Spin>
  )
}
