import styled from 'styled-components'

import { breakpoints } from '~/tokens'
import { Text } from '~/ui-components'

export const ContentWrapper = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  //align-items: center;
  gap: 16px;
  background: #f2f3f5;
  padding: 16px 24px;
  border-radius: 8px;
  box-shadow: 0px 1px 4px 0px #54545426;
  flex: 1;
  @media (max-width: ${breakpoints.MOBILE_XL}px) {
    padding: 8px 16px;
  }
`
const StyledText = styled(Text)`
  color: #00704d;
`
export const Title = styled(Text).attrs({ variant: 'label-bold' })`
  @media (max-width: ${breakpoints.MOBILE_XL}px) {
    width: 100%;
    text-align: start;
  }
`
export const Description = styled(Text).attrs({ variant: 'normal' })`
  font-size: 14px;
  font-weight: 700;

  @media (max-width: ${breakpoints.MOBILE_XL}px) {
    width: 100%;
    font-size: 12px;
    font-weight: unset;
  }
`

export const Label = styled(StyledText).attrs({ variant: 'label' })`
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
`
export const CounterWrapper = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`

export const ContentWrapper3 = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  gap: 4px;
  @media (max-width: ${breakpoints.MOBILE_XL}px) {
    //flex-direction: row;
  }
`
