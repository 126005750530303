import React, { useEffect, useMemo } from 'react'

import { Tabs } from 'antd'
import { useParams } from 'react-router'
import { Outlet, useLocation, useNavigate, useSearchParams } from 'react-router-dom'

import { AppHeader } from '~/layout'
import { appRoutes } from '~/router'
import { getTabsFromUrl } from '~/utils/getTabsFromUrl'

const OffersPage: React.FC = () => {
  const navigate = useNavigate()
  const { pathname, search } = useLocation()
  const location = useLocation()
  const { id } = useParams<{ id: string | undefined }>()
  const [searchParams] = useSearchParams()

  const filter = searchParams.get('filter')

  useEffect(() => {
    if (pathname === appRoutes.offers.url) {
      navigate(appRoutes.offersList.url.concat(search), { replace: true })
    }
  }, [pathname])

  // const handleBackClick = () => {
  //   navigate({
  //     pathname: pathname.split('/').slice(0, -1).join('/'),
  //     search: location.search,
  //   })
  // }

  const handleTabsChange = (url: string) => {
    navigate({
      pathname: url,
      search: location.search,
    })
  }

  const tabs = [appRoutes.offersList, appRoutes.offersMy, appRoutes.offersResponses]

  const activeTab = useMemo(() => {
    return tabs.find((tab) => tab.url === pathname)
  }, [pathname])

  const getTitle = () => {
    if (filter === 'BUY') return appRoutes.offersListSell.title
    if (filter === 'SELL') return appRoutes.offersListBuy.title
    return appRoutes.offers.title
  }

  return (
    <>
      <AppHeader isBack={!!id} title={id ? `Предложение № ${id}` : getTitle()}>
        {!id && (
          <Tabs activeKey={activeTab?.url} size="small" onChange={handleTabsChange} items={getTabsFromUrl(tabs)} />
        )}
      </AppHeader>
      <Outlet />
    </>
  )
}

export default OffersPage
