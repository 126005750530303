import { useQuery, UseQueryResult } from '@tanstack/react-query'

import { isApiResponseEmpty } from '~/utils/isApiResponseEmpty'
import { CompanyDataTypeEnum } from '~api/generated'
import { sharedCompanyQueryOptions } from '~hooks/company/_sharedCompanyQueryOptions'
import { useApi } from '~providers/api'

interface useGetCompanyAccountTypeResponse {
  companyRoleType: CompanyDataTypeEnum
}

export const useGetCompanyAccountType = (
  companyId: number | undefined | null,
  enabled?: boolean
): UseQueryResult<useGetCompanyAccountTypeResponse | null> => {
  const api = useApi()

  const fetchFn = async (): Promise<useGetCompanyAccountTypeResponse | null> => {
    if (!companyId) {
      return null
    }
    try {
      const res = await api.companies.companyControllerGetCompanies({ ids: [companyId] })

      const companyRoleType = res.data?.companies?.[0].type

      return { companyRoleType }
    } catch (error) {
      if (isApiResponseEmpty(error)) {
        return null
      }

      return Promise.reject(error)
    }
  }

  return useQuery(['fetchCompanyAccountType', companyId], fetchFn, { ...sharedCompanyQueryOptions, enabled })
}
