import React, { useEffect, useState } from 'react'

import { Modal, Space } from 'antd'

import { useCheckNewAppVersion } from '~/hooks'
import { Text } from '~/ui-components'

export const NewAppVersionModal: React.FC = () => {
  const hasNewAppVersionFn = useCheckNewAppVersion()
  const hasNewAppVersion = hasNewAppVersionFn.data
  const [open, setOpen] = useState(false)

  useEffect(() => {
    if (hasNewAppVersion?.hasNewVersion == null) {
      return setOpen(false)
    }
    setOpen(hasNewAppVersion.hasNewVersion)
  }, [hasNewAppVersion?.hasNewVersion, hasNewAppVersion?.lastCheckAt])

  const onCancelHandler = () => {
    setOpen(false)
  }

  const onRefreshHandler = () => {
    window.location.reload()
  }

  return (
    <Modal
      closable={false}
      destroyOnClose={true}
      width={380}
      open={open}
      onOk={onRefreshHandler}
      onCancel={onCancelHandler}
      okText="Обновить"
      cancelText="Позже"
    >
      <Space direction="vertical" size={32}>
        <Text variant="h2">Доступна новая версия</Text>

        <Text variant="normal">Мы улучшили работу сайта и платформы, пожалуйста, нажмите Обновить</Text>
      </Space>
    </Modal>
  )
}
