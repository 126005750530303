import styled from 'styled-components'

import { css } from '@styled-system/css'

import { Box, Text } from '~/ui-components'

export const Wrapper = styled.div(
  css({
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    maxWidth: '900px',
    background: '#fff',
    borderRadius: '16px',
    border: '1px solid #45A67D',
    padding: ['16px', null, '32px'],
    boxShadow: '0 1px 4px rgba(84, 84, 84, 0.15)',
  })
)

export const Title = styled(Text).attrs({ variant: 'h3' })`
  text-align: center;
`

export const Message = styled(Text).attrs({ variant: 'normal' })`
  text-align: center;
  word-break: break-word;
  color: var(--gray-6-color);
`
