import React from 'react'

import { Empty, Spin } from 'antd'

import { OffersListItemMobile } from '~pages/BasisPage/modules/MyAdvertOffers/components/OffersListItemMobile'

import { MyAdvertOffersListProps } from '~pages/BasisPage/modules/MyAdvertOffers/MyAdvertOffers.types'

import * as S from './MyAdvertOffersListMobile.styled'

export const MyAdvertOffersListMobile: React.FC<MyAdvertOffersListProps> = ({ onOfferClick, offers, isLoading }) => {
  return (
    <Spin spinning={isLoading}>
      <S.ContentWrapper>
        {!offers?.length && !isLoading && <Empty />}
        {offers?.map((offer) => (
          <OffersListItemMobile hasProductName key={offer.id} offer={offer} onClick={onOfferClick} />
        ))}
      </S.ContentWrapper>
    </Spin>
  )
}
