import { useMutation } from '@tanstack/react-query'

import { appToast } from '~/utils'
import { OfferApiOfferControllerUpdateOfferRequest } from '~api/generated'
import { useApi } from '~providers/api'

export const useUpdateOfferPriceAndVolume = () => {
  const api = useApi()

  const mutationFn = async (request: OfferApiOfferControllerUpdateOfferRequest) => {
    try {
      await api.offers.offerControllerUpdateOffer(request)
      appToast.success({ message: 'Предложение изменено' })
    } catch (err) {
      appToast.error({ message: 'Ошибка при изменении предложения' })
    }
  }

  return useMutation(mutationFn)
}
