import React, { PropsWithChildren } from 'react'

import { DatePicker, DatePickerProps } from '~/ui-components'
import { CustomControlInterface } from '~shared/controls/CustomControl.interface'

type DateControlKind = 'isoString' | 'date'

interface IBaseDatePickerControlProps1 extends CustomControlInterface {
  worksWith: 'date'
  hasError?: boolean
  id?: string
  datePickerProps?: DatePickerProps
  onChange: (value: Date | null | undefined) => void
  value: Date | null | undefined
}
interface IBaseDatePickerControlProps2 extends CustomControlInterface {
  worksWith: 'isoString'
  hasError?: boolean
  id?: string
  datePickerProps?: DatePickerProps
  onChange: (value: string | null | undefined) => void
  value: string | null | undefined
}

type ControlProps = IBaseDatePickerControlProps1 | IBaseDatePickerControlProps2

export const DatePickerControl: React.FC<PropsWithChildren<ControlProps>> = React.forwardRef<
  HTMLInputElement,
  PropsWithChildren<ControlProps>
>((props, ref) => {
  const onChangeHandler = (date: Date | Date[], dateString: string | string[]) => {
    if (Array.isArray(date)) {
      return
    }

    if (props.worksWith === 'isoString') {
      props.onChange(date?.toISOString() ?? null)
    } else {
      props.onChange(date)
    }
  }

  return (
    <DatePicker
      {...props.datePickerProps}
      id={props.id}
      value={props.worksWith === 'isoString' ? (props?.value ? new Date(props.value) : null) : props.value}
      onChange={onChangeHandler}
      status={props.hasError ? 'error' : undefined}
    />
  )
})

DatePickerControl.displayName = 'DatePickerControl'
