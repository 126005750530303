import React, { PropsWithChildren } from 'react'

import { Select } from 'antd'
import { BaseSelectRef } from 'rc-select'

import { getElevatorTransportTypeName } from '~/utils/elevators/get-elevator-transport-type-name'
import { TransportMode } from '~api/generated'
import { CustomControlInterface } from '~shared/controls/CustomControl.interface'

type Value = TransportMode[]

interface ElevatorTransferTypeControlProps extends CustomControlInterface {
  onChange: (value: Value) => void
  value: Value
  hasError?: boolean
  id?: string
  placeholder?: string
}

interface IOption {
  label: string
  value: TransportMode
}

const transferTypeOptions: IOption[] = [
  {
    label: getElevatorTransportTypeName(TransportMode.Road),
    value: TransportMode.Road,
  },
  {
    label: getElevatorTransportTypeName(TransportMode.Rail),
    value: TransportMode.Rail,
  },
  // {
  //   label: getElevatorTransportTypeName(TransportMode.River),
  //   value: TransportMode.River,
  // },
]

export const ElevatorTransferTypeControl: React.FC<PropsWithChildren<ElevatorTransferTypeControlProps>> =
  React.forwardRef<BaseSelectRef, PropsWithChildren<ElevatorTransferTypeControlProps>>(
    ({ id, value, onChange, hasError, placeholder = 'Выберите из списка' }, ref) => {
      const onChangeHandler = (checkedValue: Value) => {
        onChange(checkedValue)
      }

      return (
        <Select
          allowClear
          mode="multiple"
          ref={ref}
          id={id}
          style={{ width: '100%' }}
          placeholder={placeholder}
          value={value}
          onChange={onChangeHandler}
          status={hasError ? 'error' : undefined}
          optionFilterProp="children"
        >
          {transferTypeOptions.map((it) => (
            <Select.Option key={it.value} value={it.value}>
              {it.label}
            </Select.Option>
          ))}
        </Select>
      )
    }
  )

ElevatorTransferTypeControl.displayName = 'ElevatorTransferTypeControl'
