import { uniq } from 'lodash'

import { useQuery, UseQueryResult } from '@tanstack/react-query'

import { CompanyData, UserApiUserControllerGetUsersRequest, UserData } from '~/api/generated'
import { useApi } from '~/providers/api'

interface ModeratedUser extends UserData {
  company: CompanyData | null
}

type GetUsersParams = UserApiUserControllerGetUsersRequest

export const useGetModeratorUserList = (params: GetUsersParams) => {
  const api = useApi()

  async function fetchUsers(req: GetUsersParams) {
    const response = await api.users.userControllerGetUsers(req)
    return response.data
  }

  async function fetchCompanies(ids: number[]): Promise<CompanyData[]> {
    const response = await api.companies.companyControllerGetCompanies({
      ids,
      pageSize: 100,
    })
    return response.data.companies
  }

  const fetchFn = async () => {
    try {
      const { users, pagination } = await fetchUsers(params)
      const companiesIds: number[] = uniq(users?.map((x) => x.companyId)).filter(Boolean) as number[]
      const companies = await fetchCompanies(companiesIds)

      const res: ModeratedUser[] = users.map((user) => {
        const foundCompany: CompanyData | null = user.companyId ? companies.find((c) => c.id === user.companyId)! : null

        return { ...user, company: foundCompany }
      })
      return {
        users: res,
        pagination,
      }
    } catch (error) {
      return {}
    }
  }

  return useQuery(['fetchModeratorUserList', params], fetchFn, { staleTime: 1000 * 5, keepPreviousData: true })
}
