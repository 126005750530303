import { codeUnreachable } from '~/utils/code-unreachable'
import { RoadSurfaceType } from '~api/generated'

export function getRoadSurfaceTypeName(loadType?: RoadSurfaceType | null): string {
  if (!loadType) {
    return '-'
  }
  switch (loadType) {
    case RoadSurfaceType.Paved: {
      return 'Грунтовое'
    }
    case RoadSurfaceType.Unpaved: {
      return 'Твердое'
    }
    default: {
      return codeUnreachable(loadType)
    }
  }
}
