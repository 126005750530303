import { useMutation } from '@tanstack/react-query'

import { CreateCompanyBody, CreateOfferBody, OfferApiAdminCreateOfferRequest } from '~/api/generated'
import { appToast } from '~/utils'
import { useUploadFileAndGetId } from '~hooks/_utils'
import { useApi } from '~providers/api'

interface CreateOfferAndCheckCompanyProps {
  createCompanyBody: CreateCompanyBody
  createOfferBody: CreateOfferBody
  isCompanyZernoAgregator: boolean
  labAnalysis?: File
  comment?: string
}

export const useCreateOfferAdmin = () => {
  const api = useApi()
  const uploadLabAnalysisAndGetIdFn = useUploadFileAndGetId()

  const createOfferFn = async (params: CreateOfferAndCheckCompanyProps) => {
    const { createOfferBody, createCompanyBody, comment, isCompanyZernoAgregator } = params

    const laboratoryAnalysisFileKey = params?.labAnalysis
      ? await uploadLabAnalysisAndGetIdFn.mutateAsync(params.labAnalysis)
      : null

    let companyInn = ''

    if (params.isCompanyZernoAgregator) {
      const zernoAgregatorCompany = await api.companies.companyControllerGetCompanyZernoAgregator()
      if (!zernoAgregatorCompany.data?.id) {
        appToast.error({ description: 'Ошибка при получении ID компании Zerno-Agregator' })
        return null
      }
      companyInn = zernoAgregatorCompany.data.inn
    } else {
      companyInn = createCompanyBody.inn
    }

    const request: OfferApiAdminCreateOfferRequest = {
      adminCreateOfferBody: {
        company: {
          inn: companyInn,
        },
        user: createOfferBody.contactPerson?.phoneNumber
          ? {
              name: createOfferBody.contactPerson.name,
              phoneNumber: createOfferBody.contactPerson.phoneNumber,
            }
          : undefined,
        offer: {
          contactPerson: createOfferBody.contactPerson,
          type: createOfferBody.type,
          note: createOfferBody.note,
          price: createOfferBody.price,
          volume: createOfferBody.volume,
          includingNds: createOfferBody.includingNds,
          publishedAt: createOfferBody.publishedAt,
          shippingRequired: createOfferBody.shippingRequired,
          message: comment,
        },
        product: {
          productData: createOfferBody.product,
          warehousePositionId: null,
          laboratoryAnalysisFileKey,
        },
        warehouse: {
          warehouseId: undefined,
          warehouseData: {
            address: createOfferBody.address,
          },
        },
      },
    }

    const res = await api.offers.adminCreateOffer(request)

    return res.data
  }

  return useMutation(createOfferFn)
}
