import React, { useState } from 'react'

import { Button, Drawer, Flex, Modal, Popover, Skeleton, Spin } from 'antd'
import { AiOutlineHistory, AiOutlineNodeIndex, AiOutlineUser, AiOutlineWallet } from 'react-icons/ai'
import { FiArrowLeft, FiArrowRight, FiBox, FiChevronDown, FiChevronUp, FiEdit2, FiList, FiTruck } from 'react-icons/fi'
import { IoCloseOutline } from 'react-icons/io5'

import { useMutation } from '@apollo/client'
import { ApolloError } from '@apollo/client/errors'

import { Badge, Text } from '~/ui-components'
import { appToast, modalSharedSettings } from '~/utils'
import { formatNumber } from '~/utils/formatNumber'
import { formatPhoneNumber } from '~/utils/formatPhoneNumber'
import { getDateFormat, getDateTimeFormat } from '~/utils/getDateFormat'
import { getNoun } from '~/utils/getNoun'
import { graphqlErrorHandler } from '~/utils/graphqlErrorHandler'
import { getFreightDealNotificationText } from '~/utils/logistics/get-freight-deal-notification-text'
import { getFreightPackingType } from '~/utils/logistics/get-freight-packing-type'
import { getFreightPaymentTerm } from '~/utils/logistics/get-freight-payment-term'
import { getIsAuctionName } from '~/utils/logistics/get-is-auction-name'
import { getLoadType } from '~/utils/logistics/get-load-type'
import { getTransportType } from '~/utils/logistics/get-transport-type'
import { getUnloadType } from '~/utils/logistics/get-unload-type'
import {
  FreightDealStatus,
  FreightOrderChangeRequestBlockType,
  FreightOrderChangeRequestEntity,
  FreightOrderChangeRequestStatus,
  FreightOrderChangeRequestType,
  FreightOrderFieldName,
  FreightPackingType,
  FreightPaymentTerm,
} from '~api/gql-generated/graphql'
import { freightOrderChangeRequestSetViewedMutation } from '~api/gql-mutations/freight-order-change-request-set-viewed.mutation.graphql'
import { freightOrderChangeRequestUpdateStatusMutation } from '~api/gql-mutations/freight-order-change-request-update-status.mutation.graphql'
import { freightOrderChangeRequestsCreateMutation } from '~api/gql-mutations/freight-order-change-requests-create.mutation.graphql'
import { useGetCurrentRole } from '~hooks/auth'
import { LoadDocuments } from '~pages/LogisticsPage/modules/LoadCard/components/LoadDocuments'
import { LogisticDealHistory } from '~pages/LogisticsPage/modules/LogisticDealCard/components/LogisticDealHistory'
import { OfferOfConditionsForm } from '~pages/LogisticsPage/modules/LogisticDealCard/components/OfferOfConditionsForm'
import { OfferYourForm } from '~pages/LogisticsPage/modules/LogisticDealCard/components/OfferYourForm'
import { ProposedConditionForm } from '~pages/LogisticsPage/modules/LogisticDealCard/components/ProposedConditionForm'
import { useAuth } from '~providers/auth'
import { ReadValue } from '~shared/components'
import { LinkValue } from '~shared/components/LinkValue'
import { StatusIcon } from '~shared/components/StatusIcon'
import { getRoadSurfaceQualityName } from '~shared/controls/RoadSurfaceQualitySelectControl/get-road-surface-quality-name'
import { getRoadSurfaceTypeName } from '~shared/controls/RoadSurfaceTypeSelectControl/get-road-surface-type-name'
import { Timeline } from '~ui-components/components/Timeline'

import { LogisticDealDetailsProps } from './LogisticDealDetails.types'
import { OfferOfConditionsFormValues } from '~pages/LogisticsPage/modules/LogisticDealCard/components/OfferOfConditionsForm/OfferOfConditionsForm.types'
import { OfferYourFormValues } from '~pages/LogisticsPage/modules/LogisticDealCard/components/OfferYourForm/OfferYourForm.types'

import * as S from './LogisticDealDetails.styled'

const OfferNotification = ({
  isShowNotification,
  onSetViewedHandler,
  status,
  isAll,
  date,
  isCreator,
  isFinalizing,
}: {
  isShowNotification: boolean
  onSetViewedHandler: () => void
  status: FreightOrderChangeRequestStatus
  isAll: boolean
  date: string
  isCreator: boolean
  isFinalizing: boolean
}) => {
  const [isShow, setIsShow] = useState(isShowNotification)
  const notificationText = getFreightDealNotificationText(status, isAll, isCreator, isFinalizing)

  return isShow ? (
    <S.FlexNotificationWrapper justify="space-between">
      <Flex gap={8} align="center">
        <StatusIcon status={status} isCreator={isCreator} isFinalizing={isFinalizing} />
        <ReadValue name={notificationText.name} value={notificationText.value} />
      </Flex>

      <Flex gap={16}>
        <Text variant="form-read-label">{getDateTimeFormat(date)}</Text>
        {(status === FreightOrderChangeRequestStatus.Accepted ||
          status === FreightOrderChangeRequestStatus.Rejected) && (
          <IoCloseOutline
            size={20}
            onClick={() => {
              setIsShow(false)
              onSetViewedHandler()
            }}
            color={'var(--gray-4-color)'}
            style={{ cursor: 'pointer' }}
          />
        )}
      </Flex>
    </S.FlexNotificationWrapper>
  ) : null
}

export const LogisticDealDetails: React.FC<LogisticDealDetailsProps> = ({
  freightDeal,
  loading,
  refetchFreightDeal,
}) => {
  const [isOpenTechnicalCharacteristics, setIsOpenTechnicalCharacteristics] = useState(false)
  const [isOfferOfConditionsModalOpen, setIsOfferOfConditionsModalOpen] = useState(false)
  const [currentOfferOfConditionsType, setCurrentOfferOfConditionsType] = useState<FreightOrderChangeRequestBlockType>(
    FreightOrderChangeRequestBlockType.Freight
  )
  const [isHistoryModalOpen, setIsHistoryModalOpen] = useState(false)
  const [currentHistoryType, setCurrentHistoryType] = useState<FreightOrderChangeRequestBlockType>(
    FreightOrderChangeRequestBlockType.Freight
  )
  const [isProposedConditionModalOpen, setIsProposedConditionModalOpen] = useState(false)
  const [isOfferYourModalOpen, setIsOfferYourModalOpen] = useState(false)
  const [lastChangeRequest, setLastChangeRequest] = useState<FreightOrderChangeRequestEntity | null>(null)

  const {
    company: { isCarrier },
  } = useGetCurrentRole()
  const { companyId } = useAuth()

  const [freightOrderChangeRequestsCreate] = useMutation(freightOrderChangeRequestsCreateMutation)
  const [freightOrderChangeRequestUpdateStatus] = useMutation(freightOrderChangeRequestUpdateStatusMutation)
  const [freightOrderChangeRequestSetViewed] = useMutation(freightOrderChangeRequestSetViewedMutation)

  const onOfferOfConditionsHandler = async (
    formValues: OfferOfConditionsFormValues,
    dirtyFields: Partial<Record<keyof OfferOfConditionsFormValues, boolean>>
  ) => {
    const changeRequests = []
    if (dirtyFields.weightKg) {
      changeRequests.push({
        type: FreightOrderChangeRequestType.Weight,
        changedFields: [
          {
            fieldName: FreightOrderFieldName.Weight,
            value: (formValues.weightKg * 1000).toString(),
          },
        ],
      })
    }

    if (dirtyFields.packingType || dirtyFields.bigBagsCount || dirtyFields.packingTypeCustom) {
      changeRequests.push({
        type: FreightOrderChangeRequestType.PackingType,
        changedFields: [
          {
            fieldName: FreightOrderFieldName.PackingType,
            value: formValues.packingType,
          },
          ...(dirtyFields.bigBagsCount
            ? [
                {
                  fieldName: FreightOrderFieldName.BigBagsCount,
                  value: formValues.bigBagsCount.toString(),
                },
              ]
            : []),
          ...(dirtyFields.packingTypeCustom
            ? [
                {
                  fieldName: FreightOrderFieldName.PackingTypeCustom,
                  value: formValues.packingTypeCustom,
                },
              ]
            : []),
        ],
      })
    }

    if (dirtyFields.pricePerTonWithoutNds) {
      changeRequests.push({
        type: FreightOrderChangeRequestType.PricePerTon,
        changedFields: [
          {
            fieldName: FreightOrderFieldName.PricePerTonWithoutNds,
            value: formValues.pricePerTonWithoutNds.toString(),
          },
        ],
      })
    }

    if (dirtyFields.paymentTerm || dirtyFields.deferredDaysCount || dirtyFields.advancePercent) {
      changeRequests.push({
        type: FreightOrderChangeRequestType.PaymentTerm,
        changedFields: [
          {
            fieldName: FreightOrderFieldName.PaymentTerm,
            value: formValues.paymentTerm,
          },
          ...(dirtyFields.deferredDaysCount
            ? [
                {
                  fieldName: FreightOrderFieldName.DeferredDaysCount,
                  value: formValues.deferredDaysCount.toString(),
                },
              ]
            : []),
          ...(dirtyFields.advancePercent
            ? [
                {
                  fieldName: FreightOrderFieldName.AdvancePercent,
                  value: formValues.advancePercent.toString(),
                },
              ]
            : []),
        ],
      })
    }

    if (dirtyFields.canUpdateTransportRegistryInPast) {
      changeRequests.push({
        type: FreightOrderChangeRequestType.TransportRegistry,
        changedFields: [
          {
            fieldName: FreightOrderFieldName.CanUpdateTransportRegistryInPast,
            value: formValues.canUpdateTransportRegistryInPast.toString(),
          },
        ],
      })
    }

    if (dirtyFields.distance) {
      changeRequests.push({
        type: FreightOrderChangeRequestType.Distance,
        changedFields: [
          {
            fieldName: FreightOrderFieldName.Distance,
            value: formValues.distance.toString(),
          },
        ],
        additionalDetails: formValues.additionalDetails,
      })
    }

    try {
      await freightOrderChangeRequestsCreate({
        variables: {
          input: {
            orderId: freightDeal?.dealOrderId ?? 0,
            changeRequests,
          },
        },
      })
      setIsOfferOfConditionsModalOpen(false)
      appToast.success({ message: 'Условия предложены' })
      refetchFreightDeal()
    } catch (e) {
      e instanceof ApolloError && graphqlErrorHandler(e, 'Ошибка при предложении условий')
    }
  }

  const onProposedConditionHandler = async (id: number, status: FreightOrderChangeRequestStatus) => {
    let successMessage = ''
    let errorMessage = ''
    switch (status) {
      case FreightOrderChangeRequestStatus.Accepted:
        successMessage = 'Условие принято'
        errorMessage = 'Ошибка при принятии условия'
        break
      case FreightOrderChangeRequestStatus.Rejected:
        successMessage = 'Условие отклонено'
        errorMessage = 'Ошибка при отклонении условия'
        break
      case FreightOrderChangeRequestStatus.CounterOffer:
        successMessage = 'Условие предложено'
        errorMessage = 'Ошибка при предложении условия'
        break
    }

    setLastChangeRequest(null)
    setIsOfferYourModalOpen(false)

    try {
      await freightOrderChangeRequestUpdateStatus({
        variables: {
          changeRequestId: id,
          input: {
            status,
          },
        },
      })
      appToast.success({ message: successMessage })
      refetchFreightDeal()
    } catch (e) {
      e instanceof ApolloError && graphqlErrorHandler(e, errorMessage)
    }
  }

  const onOfferYourHandler = async (formValues: OfferYourFormValues) => {
    const changeRequests = []
    if (lastChangeRequest?.type === FreightOrderChangeRequestType.Weight) {
      changeRequests.push({
        type: FreightOrderChangeRequestType.Weight,
        changedFields: [
          {
            fieldName: FreightOrderFieldName.Weight,
            value: (formValues.weightKg * 1000).toString(),
          },
        ],
      })
    }

    if (lastChangeRequest?.type === FreightOrderChangeRequestType.PackingType) {
      changeRequests.push({
        type: FreightOrderChangeRequestType.PackingType,
        changedFields: [
          {
            fieldName: FreightOrderFieldName.PackingType,
            value: formValues.packingType,
          },
          ...(formValues.bigBagsCount !== undefined
            ? [
                {
                  fieldName: FreightOrderFieldName.BigBagsCount,
                  value: formValues.bigBagsCount.toString(),
                },
              ]
            : []),
          ...(formValues.packingTypeCustom !== undefined
            ? [
                {
                  fieldName: FreightOrderFieldName.PackingTypeCustom,
                  value: formValues.packingTypeCustom,
                },
              ]
            : []),
        ],
      })
    }

    if (lastChangeRequest?.type === FreightOrderChangeRequestType.PricePerTon) {
      changeRequests.push({
        type: FreightOrderChangeRequestType.PricePerTon,
        changedFields: [
          {
            fieldName: FreightOrderFieldName.PricePerTonWithoutNds,
            value: formValues.pricePerTonWithoutNds.toString(),
          },
        ],
      })
    }

    if (lastChangeRequest?.type === FreightOrderChangeRequestType.PaymentTerm) {
      changeRequests.push({
        type: FreightOrderChangeRequestType.PaymentTerm,
        changedFields: [
          {
            fieldName: FreightOrderFieldName.PaymentTerm,
            value: formValues.paymentTerm,
          },
          ...(formValues.deferredDaysCount !== undefined
            ? [
                {
                  fieldName: FreightOrderFieldName.DeferredDaysCount,
                  value: formValues.deferredDaysCount.toString(),
                },
              ]
            : []),
          ...(formValues.advancePercent !== undefined
            ? [
                {
                  fieldName: FreightOrderFieldName.AdvancePercent,
                  value: formValues.advancePercent.toString(),
                },
              ]
            : []),
        ],
      })
    }

    if (lastChangeRequest?.type === FreightOrderChangeRequestType.TransportRegistry) {
      changeRequests.push({
        type: FreightOrderChangeRequestType.TransportRegistry,
        changedFields: [
          {
            fieldName: FreightOrderFieldName.CanUpdateTransportRegistryInPast,
            value: formValues.canUpdateTransportRegistryInPast.toString(),
          },
        ],
      })
    }

    if (lastChangeRequest?.type === FreightOrderChangeRequestType.Distance) {
      changeRequests.push({
        type: FreightOrderChangeRequestType.Distance,
        changedFields: [
          {
            fieldName: FreightOrderFieldName.Distance,
            value: formValues.distance.toString(),
          },
        ],
        additionalDetails: formValues.additionalDetails,
      })
    }

    setLastChangeRequest(null)
    setIsOfferYourModalOpen(false)

    try {
      await freightOrderChangeRequestsCreate({
        variables: {
          input: {
            orderId: freightDeal?.dealOrderId ?? 0,
            changeRequests,
          },
        },
      })
      appToast.success({ message: 'Условие предложено' })
      refetchFreightDeal()
    } catch (e) {
      e instanceof ApolloError && graphqlErrorHandler(e, 'Ошибка при предложении условия')
    }
  }

  const onSetViewedHandler = async (id: number) => {
    try {
      await freightOrderChangeRequestSetViewed({
        variables: {
          changeRequestId: id,
        },
      })
    } catch (e) {
      e instanceof ApolloError && graphqlErrorHandler(e, 'Произошла ошибка')
    }
  }

  const timelineItems = [
    {
      label: 'Погрузка',
      children: (
        <Flex vertical gap={16}>
          <Flex gap={16}>
            <Flex gap={8}>
              <FiTruck size={20} />
              <FiArrowLeft size={20} />
            </Flex>
            <Text variant="form-read-value">{freightDeal?.dealOrder.route.points[0].address.short}</Text>
          </Flex>
          <Text variant="normal">{freightDeal?.dealOrder.route.points[0].address.full}</Text>
        </Flex>
      ),
    },
    {
      label: 'Приемка',
      children: (
        <Flex vertical gap={16}>
          <Flex gap={16}>
            <Flex gap={8}>
              <FiTruck size={20} />
              <FiArrowRight size={20} />
            </Flex>
            <Text variant="form-read-value">{freightDeal?.dealOrder.route.points[1].address.short}</Text>
          </Flex>
          <Text variant="normal">{freightDeal?.dealOrder.route.points[1].address.full}</Text>
        </Flex>
      ),
    },
  ]

  const packingTypeValue = () => {
    if (!freightDeal?.dealOrder.route.points[0].freight?.packingType) return undefined

    const freight = freightDeal.dealOrder.route.points[0].freight
    let result = getFreightPackingType(freight.packingType)

    switch (freight.packingType) {
      case FreightPackingType.BigBag:
        if (freight.bigBagsCount != null) {
          result += `, ${freight.bigBagsCount} шт`
        }
        break
      case FreightPackingType.Custom:
        if (freight.packingTypeCustom) {
          result += `, ${freight.packingTypeCustom}`
        }
        break
    }

    return result
  }

  const paymentTermValue = () => {
    if (!freightDeal?.dealOrder.paymentTerm) return undefined

    let result = getFreightPaymentTerm(freightDeal.dealOrder.paymentTerm)

    switch (freightDeal.dealOrder.paymentTerm) {
      case FreightPaymentTerm.Deferred:
        if (freightDeal.dealOrder.deferredDaysCount != null) {
          result += `, ${freightDeal.dealOrder.deferredDaysCount} ${getNoun(
            freightDeal.dealOrder.deferredDaysCount,
            'день',
            'дня',
            'дней'
          )}`
        }
        break
      case FreightPaymentTerm.Advance:
        if (freightDeal.dealOrder.advancePercent != null) {
          result += `, ${freightDeal.dealOrder.advancePercent} %`
        }
        break
    }

    return result
  }

  const lastChangeRequests = freightDeal?.lastChangeRequests ?? []

  const lastChangeRequestsWeight = lastChangeRequests.find((item) => item.type === FreightOrderChangeRequestType.Weight)

  const lastChangeRequestsPackingType = lastChangeRequests.find(
    (item) => item.type === FreightOrderChangeRequestType.PackingType
  )

  const lastChangeRequestsPricePerTon = lastChangeRequests.find(
    (item) => item.type === FreightOrderChangeRequestType.PricePerTon
  )

  const lastChangeRequestsPaymentTerm = lastChangeRequests.find(
    (item) => item.type === FreightOrderChangeRequestType.PaymentTerm
  )

  const lastChangeRequestsTransportRegistry = lastChangeRequests.find(
    (item) => item.type === FreightOrderChangeRequestType.TransportRegistry
  )

  const lastChangeRequestsDistance = lastChangeRequests.find(
    (item) => item.type === FreightOrderChangeRequestType.Distance
  )

  const PopoverWrapper = ({
    children,
    curLastChangeRequest,
  }: {
    children: React.ReactElement
    curLastChangeRequest: FreightOrderChangeRequestEntity
  }) => {
    return (
      <Popover
        placement="rightTop"
        content={
          isProposedConditionModalOpen ? (
            <ProposedConditionForm
              onOfferYour={() => {
                setIsProposedConditionModalOpen(false)
                setIsOfferYourModalOpen(true)
              }}
              onSubmit={onProposedConditionHandler}
              lastChangeRequest={curLastChangeRequest}
            />
          ) : isOfferYourModalOpen ? (
            <OfferYourForm
              onBack={() => setIsOfferYourModalOpen(false)}
              onSubmit={onOfferYourHandler}
              lastChangeRequest={curLastChangeRequest}
            />
          ) : null
        }
        title={
          <Flex justify="space-between" style={{ marginBottom: '16px' }}>
            <Text variant="normal-bold">
              {isProposedConditionModalOpen ? 'Предлагаемое условие' : isOfferYourModalOpen ? 'Предложить свое' : ''}
            </Text>
            <IoCloseOutline
              size={20}
              onClick={() => {
                setIsProposedConditionModalOpen(false)
                setIsOfferYourModalOpen(false)
              }}
              color={'var(--gray-4-color)'}
              style={{ cursor: 'pointer' }}
            />
          </Flex>
        }
        trigger="click"
        open={
          (isProposedConditionModalOpen || isOfferYourModalOpen) && lastChangeRequest?.id === curLastChangeRequest.id
        }
        onOpenChange={(open) => {
          if (!open) {
            setIsProposedConditionModalOpen(false)
            setIsOfferYourModalOpen(false)
            setLastChangeRequest(null)
          }
        }}
      >
        {children}
      </Popover>
    )
  }

  return (
    <Spin spinning={loading}>
      <Skeleton loading={loading} />

      {freightDeal && (
        <S.Wrapper>
          <Flex vertical gap={32}>
            <S.FlexCompanyWrapper vertical gap={8} align="flex-start">
              {!isCarrier && (
                <>
                  <Text variant="normal-bold">{freightDeal.carrier.legalName}</Text>
                  <Badge color="darkgray" size="s">
                    Перевозчик
                  </Badge>
                </>
              )}

              {isCarrier && (
                <>
                  <Text variant="normal-bold">{freightDeal.customer.legalName}</Text>
                  <Badge color="darkgray" size="s">
                    Заказчик
                  </Badge>
                </>
              )}
            </S.FlexCompanyWrapper>

            <S.FlexItem vertical gap={24}>
              <Flex align="center" gap={16}>
                <AiOutlineUser size={20} />
                <Text variant="h2">Контакты</Text>
              </Flex>

              {isCarrier && (
                <Flex gap={60}>
                  <ReadValue name="Контактное лицо" value={freightDeal.dealOrder.contactPerson} />
                  <ReadValue name="Телефон" value={formatPhoneNumber(freightDeal.dealOrder.contactPhoneNumber)} />
                </Flex>
              )}

              {!isCarrier && (
                <Flex gap={60}>
                  <ReadValue name="Контактное лицо" value={freightDeal.creator.name} />
                  <ReadValue name="Телефон" value={formatPhoneNumber(freightDeal.creator.phoneNumber)} />
                </Flex>
              )}
            </S.FlexItem>

            <S.FlexItem vertical gap={24}>
              <Flex align="center" gap={16}>
                <FiBox size={20} />
                <Text variant="h2">Груз</Text>
              </Flex>

              {lastChangeRequestsWeight &&
              lastChangeRequestsPackingType &&
              lastChangeRequestsWeight.status === lastChangeRequestsPackingType.status &&
              lastChangeRequestsWeight.createdByCompanyId === lastChangeRequestsPackingType.createdByCompanyId &&
              companyId !== lastChangeRequestsWeight.endedByCompanyId ? (
                <OfferNotification
                  isShowNotification={!lastChangeRequestsWeight.isViewedByCounterparty}
                  onSetViewedHandler={() => onSetViewedHandler(lastChangeRequestsWeight.id)}
                  status={lastChangeRequestsWeight.status}
                  isAll
                  date={
                    lastChangeRequestsWeight.updatedAt > lastChangeRequestsPackingType.updatedAt
                      ? lastChangeRequestsWeight.updatedAt
                      : lastChangeRequestsPackingType.updatedAt
                  }
                  isCreator={lastChangeRequestsWeight.createdByCompanyId === companyId}
                  isFinalizing={lastChangeRequestsWeight.endedByCompanyId === companyId}
                />
              ) : (
                <>
                  {lastChangeRequestsWeight && companyId !== lastChangeRequestsWeight.endedByCompanyId && (
                    <OfferNotification
                      isShowNotification={!lastChangeRequestsWeight.isViewedByCounterparty}
                      onSetViewedHandler={() => onSetViewedHandler(lastChangeRequestsWeight.id)}
                      status={lastChangeRequestsWeight.status}
                      isAll={!lastChangeRequestsPackingType}
                      date={lastChangeRequestsWeight.updatedAt}
                      isCreator={lastChangeRequestsWeight.createdByCompanyId === companyId}
                      isFinalizing={lastChangeRequestsWeight.endedByCompanyId === companyId}
                    />
                  )}
                  {lastChangeRequestsPackingType && companyId !== lastChangeRequestsPackingType.endedByCompanyId && (
                    <OfferNotification
                      isShowNotification={!lastChangeRequestsPackingType.isViewedByCounterparty}
                      onSetViewedHandler={() => onSetViewedHandler(lastChangeRequestsPackingType.id)}
                      status={lastChangeRequestsPackingType.status}
                      isAll={!lastChangeRequestsWeight}
                      date={lastChangeRequestsPackingType.updatedAt}
                      isCreator={lastChangeRequestsPackingType.createdByCompanyId === companyId}
                      isFinalizing={lastChangeRequestsPackingType.endedByCompanyId === companyId}
                    />
                  )}
                </>
              )}

              <Flex gap={60}>
                <ReadValue name="ID груза" value={freightDeal.orderId.toString()} />
                <ReadValue name="Тип груза" value={freightDeal.dealOrder.route.points[0].freight?.productType.name} />
                {lastChangeRequestsWeight && freightDeal?.status === FreightDealStatus.Agreement ? (
                  <PopoverWrapper
                    curLastChangeRequest={lastChangeRequestsWeight}
                    children={
                      <LinkValue
                        name="Вес"
                        value={
                          freightDeal.dealOrder.weightKg
                            ? formatNumber(freightDeal.dealOrder.weightKg / 1000) + ' т'
                            : undefined
                        }
                        status={lastChangeRequestsWeight.status}
                        onClick={() => {
                          setLastChangeRequest(lastChangeRequestsWeight)
                          setIsProposedConditionModalOpen(true)
                        }}
                        isCreator={lastChangeRequestsWeight.createdByCompanyId === companyId}
                        isFinalizing={lastChangeRequestsWeight.endedByCompanyId === companyId}
                      />
                    }
                  />
                ) : (
                  <ReadValue
                    name="Вес"
                    value={
                      freightDeal.dealOrder.weightKg
                        ? formatNumber(freightDeal.dealOrder.weightKg / 1000) + ' т'
                        : undefined
                    }
                  />
                )}
                {lastChangeRequestsPackingType && freightDeal?.status === FreightDealStatus.Agreement ? (
                  <PopoverWrapper
                    curLastChangeRequest={lastChangeRequestsPackingType}
                    children={
                      <LinkValue
                        name="Вид упаковки"
                        value={packingTypeValue()}
                        status={lastChangeRequestsPackingType.status}
                        onClick={() => {
                          setLastChangeRequest(lastChangeRequestsPackingType)
                          setIsProposedConditionModalOpen(true)
                        }}
                        isCreator={lastChangeRequestsPackingType.createdByCompanyId === companyId}
                        isFinalizing={lastChangeRequestsPackingType.endedByCompanyId === companyId}
                      />
                    }
                  />
                ) : (
                  <ReadValue name="Вид упаковки" value={packingTypeValue()} />
                )}
              </Flex>

              <Flex gap={24}>
                {freightDeal?.status === FreightDealStatus.Agreement && (
                  <Button
                    type="link"
                    onClick={() => {
                      setCurrentOfferOfConditionsType(FreightOrderChangeRequestBlockType.Freight)
                      setIsOfferOfConditionsModalOpen(true)
                    }}
                  >
                    <Flex gap={8} align="center">
                      <FiEdit2 size={16} color={'var(--primary-main-color)'} />
                      Предложить свои условия
                    </Flex>
                  </Button>
                )}

                <Button
                  type="link"
                  onClick={() => {
                    setCurrentHistoryType(FreightOrderChangeRequestBlockType.Freight)
                    setIsHistoryModalOpen(true)
                  }}
                >
                  <Flex gap={8} align="center">
                    <AiOutlineHistory size={16} color={'var(--primary-main-color)'} />
                    Посмотреть историю изменений
                  </Flex>
                </Button>
              </Flex>
            </S.FlexItem>

            <S.FlexItem vertical gap={24}>
              <Flex align="center" gap={16}>
                <AiOutlineWallet size={20} />
                <Text variant="h2">Оплата</Text>
              </Flex>

              {lastChangeRequestsPricePerTon &&
              lastChangeRequestsPaymentTerm &&
              lastChangeRequestsPricePerTon.status === lastChangeRequestsPaymentTerm.status &&
              lastChangeRequestsPricePerTon.createdByCompanyId === lastChangeRequestsPaymentTerm.createdByCompanyId &&
              companyId !== lastChangeRequestsPricePerTon.endedByCompanyId ? (
                <OfferNotification
                  isShowNotification={!lastChangeRequestsPricePerTon.isViewedByCounterparty}
                  onSetViewedHandler={() => onSetViewedHandler(lastChangeRequestsPricePerTon.id)}
                  status={lastChangeRequestsPricePerTon.status}
                  isAll
                  date={
                    lastChangeRequestsPricePerTon.updatedAt > lastChangeRequestsPaymentTerm.updatedAt
                      ? lastChangeRequestsPricePerTon.updatedAt
                      : lastChangeRequestsPaymentTerm.updatedAt
                  }
                  isCreator={lastChangeRequestsPricePerTon.createdByCompanyId === companyId}
                  isFinalizing={lastChangeRequestsPricePerTon.endedByCompanyId === companyId}
                />
              ) : (
                <>
                  {lastChangeRequestsPricePerTon && companyId !== lastChangeRequestsPricePerTon.endedByCompanyId && (
                    <OfferNotification
                      isShowNotification={!lastChangeRequestsPricePerTon.isViewedByCounterparty}
                      onSetViewedHandler={() => onSetViewedHandler(lastChangeRequestsPricePerTon.id)}
                      status={lastChangeRequestsPricePerTon.status}
                      isAll={!lastChangeRequestsPackingType}
                      date={lastChangeRequestsPricePerTon.updatedAt}
                      isCreator={lastChangeRequestsPricePerTon.createdByCompanyId === companyId}
                      isFinalizing={lastChangeRequestsPricePerTon.createdByCompanyId === companyId}
                    />
                  )}
                  {lastChangeRequestsPaymentTerm && companyId !== lastChangeRequestsPaymentTerm.endedByCompanyId && (
                    <OfferNotification
                      isShowNotification={!lastChangeRequestsPaymentTerm.isViewedByCounterparty}
                      onSetViewedHandler={() => onSetViewedHandler(lastChangeRequestsPaymentTerm.id)}
                      status={lastChangeRequestsPaymentTerm.status}
                      isAll={!lastChangeRequestsPricePerTon}
                      date={lastChangeRequestsPaymentTerm.updatedAt}
                      isCreator={lastChangeRequestsPaymentTerm.createdByCompanyId === companyId}
                      isFinalizing={lastChangeRequestsPaymentTerm.endedByCompanyId === companyId}
                    />
                  )}
                </>
              )}

              <Flex gap={60}>
                {lastChangeRequestsPricePerTon &&
                freightDeal?.status === FreightDealStatus.Agreement &&
                freightDeal.dealOrder.isAuction ? (
                  <PopoverWrapper
                    curLastChangeRequest={lastChangeRequestsPricePerTon}
                    children={
                      <LinkValue
                        name="Ставка"
                        value={
                          <>
                            <p>{formatNumber(freightDeal.dealOrder.pricePerTonWithNds)} ₽/т с НДС</p>
                            <p>{formatNumber(freightDeal.dealOrder.pricePerTonWithoutNds)} ₽/т без НДС</p>
                          </>
                        }
                        status={lastChangeRequestsPricePerTon.status}
                        onClick={() => {
                          setLastChangeRequest(lastChangeRequestsPricePerTon)
                          setIsProposedConditionModalOpen(true)
                        }}
                        isCreator={lastChangeRequestsPricePerTon.createdByCompanyId === companyId}
                        isFinalizing={lastChangeRequestsPricePerTon.endedByCompanyId === companyId}
                      />
                    }
                  />
                ) : (
                  <ReadValue
                    name="Ставка"
                    value={
                      <>
                        <p>{formatNumber(freightDeal.dealOrder.pricePerTonWithNds)} ₽/т с НДС</p>
                        <p>{formatNumber(freightDeal.dealOrder.pricePerTonWithoutNds)} ₽/т без НДС</p>
                      </>
                    }
                  />
                )}
                <ReadValue
                  name="Общая стоимость"
                  value={
                    freightDeal.dealOrder.route.points[0].freight?.weightKg ? (
                      <>
                        <p>{formatNumber(freightDeal.dealOrder.priceWithNds)} ₽ с НДС</p>
                        <p>{formatNumber(freightDeal.dealOrder.priceWithoutNds)} ₽ без НДС</p>
                      </>
                    ) : undefined
                  }
                />
                <ReadValue name="Возможность торга" value={getIsAuctionName(freightDeal.dealOrder.isAuction)} />
                {lastChangeRequestsPaymentTerm &&
                freightDeal?.status === FreightDealStatus.Agreement &&
                freightDeal.dealOrder.isAuction ? (
                  <PopoverWrapper
                    curLastChangeRequest={lastChangeRequestsPaymentTerm}
                    children={
                      <LinkValue
                        name="Условия оплаты"
                        value={paymentTermValue()}
                        status={lastChangeRequestsPaymentTerm.status}
                        onClick={() => {
                          setLastChangeRequest(lastChangeRequestsPaymentTerm)
                          setIsProposedConditionModalOpen(true)
                        }}
                        isCreator={lastChangeRequestsPaymentTerm.createdByCompanyId === companyId}
                        isFinalizing={lastChangeRequestsPaymentTerm.endedByCompanyId === companyId}
                      />
                    }
                  />
                ) : (
                  <ReadValue name="Условия оплаты" value={paymentTermValue()} />
                )}
              </Flex>

              <Flex gap={24}>
                {freightDeal?.status === FreightDealStatus.Agreement && freightDeal.dealOrder.isAuction && (
                  <>
                    <Button
                      type="link"
                      onClick={() => {
                        setCurrentOfferOfConditionsType(FreightOrderChangeRequestBlockType.Price)
                        setIsOfferOfConditionsModalOpen(true)
                      }}
                    >
                      <Flex gap={8} align="center">
                        <FiEdit2 size={16} color={'var(--primary-main-color)'} />
                        Предложить свои условия
                      </Flex>
                    </Button>

                    <Button
                      type="link"
                      onClick={() => {
                        setCurrentHistoryType(FreightOrderChangeRequestBlockType.Price)
                        setIsHistoryModalOpen(true)
                      }}
                    >
                      <Flex gap={8} align="center">
                        <AiOutlineHistory size={16} color={'var(--primary-main-color)'} />
                        Посмотреть историю изменений
                      </Flex>
                    </Button>
                  </>
                )}
              </Flex>
            </S.FlexItem>

            <S.FlexItem vertical gap={24}>
              <Flex align="center" gap={16}>
                <FiTruck size={20} />
                <Text variant="h2">Транспорт</Text>
              </Flex>

              <Flex gap={60}>
                <ReadValue name="Вид грузоперевозки" value={'Автомобильные'} />
                <ReadValue name="Тип транспорта" value={getTransportType(freightDeal.dealOrder.transportFormatTypes)} />
                <ReadValue
                  name="Тип кузова"
                  value={freightDeal.dealOrder.transportBodyTypes
                    .map((transportBodyType) => transportBodyType.name)
                    .join(', ')}
                />
                <ReadValue
                  name="Способ погрузки"
                  value={freightDeal.dealOrder.route.points[0].roadTransportParameters.loadingTypes
                    .map((loadingType) => getLoadType(loadingType))
                    .join(', ')}
                />
                <ReadValue
                  name="Способ приемки"
                  value={freightDeal.dealOrder.route.points[1].roadTransportParameters.unloadingTypes
                    .map((unloadingType) => getUnloadType(unloadingType))
                    .join(', ')}
                />
              </Flex>
            </S.FlexItem>

            <S.FlexItem vertical gap={24}>
              <Flex align="center" gap={16}>
                <Text variant="h2">Реестр авто</Text>
              </Flex>

              {lastChangeRequestsTransportRegistry &&
                companyId !== lastChangeRequestsTransportRegistry.endedByCompanyId && (
                  <OfferNotification
                    isShowNotification={!lastChangeRequestsTransportRegistry.isViewedByCounterparty}
                    onSetViewedHandler={() => onSetViewedHandler(lastChangeRequestsTransportRegistry.id)}
                    status={lastChangeRequestsTransportRegistry.status}
                    isAll
                    date={lastChangeRequestsTransportRegistry.updatedAt}
                    isCreator={lastChangeRequestsTransportRegistry.createdByCompanyId === companyId}
                    isFinalizing={lastChangeRequestsTransportRegistry.endedByCompanyId === companyId}
                  />
                )}

              <Flex gap={60}>
                {lastChangeRequestsTransportRegistry && freightDeal?.status === FreightDealStatus.Agreement ? (
                  <PopoverWrapper
                    curLastChangeRequest={lastChangeRequestsTransportRegistry}
                    children={
                      <LinkValue
                        name="Редактирование"
                        value="Без возможности редактирования реестра по требованию"
                        status={lastChangeRequestsTransportRegistry.status}
                        onClick={() => {
                          setLastChangeRequest(lastChangeRequestsTransportRegistry)
                          setIsProposedConditionModalOpen(true)
                        }}
                        isCreator={lastChangeRequestsTransportRegistry.createdByCompanyId === companyId}
                        isFinalizing={lastChangeRequestsTransportRegistry.endedByCompanyId === companyId}
                      />
                    }
                  />
                ) : (
                  <ReadValue name="Редактирование" value="Без возможности редактирования реестра по требованию" />
                )}
              </Flex>

              <Flex gap={24}>
                {freightDeal?.status === FreightDealStatus.Agreement && (
                  <Button
                    type="link"
                    onClick={() => {
                      setCurrentOfferOfConditionsType(FreightOrderChangeRequestBlockType.TransportRegistry)
                      setIsOfferOfConditionsModalOpen(true)
                    }}
                  >
                    <Flex gap={8} align="center">
                      <FiEdit2 size={16} color={'var(--primary-main-color)'} />
                      Предложить свои условия
                    </Flex>
                  </Button>
                )}

                <Button
                  type="link"
                  onClick={() => {
                    setCurrentHistoryType(FreightOrderChangeRequestBlockType.TransportRegistry)
                    setIsHistoryModalOpen(true)
                  }}
                >
                  <Flex gap={8} align="center">
                    <AiOutlineHistory size={16} color={'var(--primary-main-color)'} />
                    Посмотреть историю изменений
                  </Flex>
                </Button>
              </Flex>
            </S.FlexItem>

            <Flex vertical gap={32}>
              <Flex align="center" gap={16}>
                <AiOutlineNodeIndex size={20} />
                <Text variant="h2">Маршрут</Text>
              </Flex>

              {freightDeal.dealOrder.startedAt && (
                <Flex gap={8}>
                  <Text variant="label">Дата начала погрузки</Text>
                  <Text variant="label-bold">{getDateFormat(freightDeal.dealOrder.startedAt)}</Text>
                  {freightDeal.dealOrder.startDaysCount && (
                    <Text variant="label-bold">{`+${freightDeal.dealOrder.startDaysCount} ${getNoun(
                      freightDeal.dealOrder.startDaysCount,
                      'день',
                      'дня',
                      'дней'
                    )}`}</Text>
                  )}
                </Flex>
              )}

              <Flex>
                <Timeline mode="left" items={timelineItems} style={{ width: '100%' }} />
              </Flex>

              {lastChangeRequestsDistance && companyId !== lastChangeRequestsDistance.endedByCompanyId && (
                <OfferNotification
                  isShowNotification={!lastChangeRequestsDistance.isViewedByCounterparty}
                  onSetViewedHandler={() => onSetViewedHandler(lastChangeRequestsDistance.id)}
                  status={lastChangeRequestsDistance.status}
                  isAll
                  date={lastChangeRequestsDistance.updatedAt}
                  isCreator={lastChangeRequestsDistance.createdByCompanyId === companyId}
                  isFinalizing={lastChangeRequestsDistance.endedByCompanyId === companyId}
                />
              )}

              <Flex>
                {lastChangeRequestsDistance && freightDeal?.status === FreightDealStatus.Agreement ? (
                  <PopoverWrapper
                    curLastChangeRequest={lastChangeRequestsDistance}
                    children={
                      <LinkValue
                        name="Длина маршрута"
                        value={`${freightDeal.dealOrder.route.distance} км`}
                        status={lastChangeRequestsDistance.status}
                        onClick={() => {
                          setLastChangeRequest(lastChangeRequestsDistance)
                          setIsProposedConditionModalOpen(true)
                        }}
                        isCreator={lastChangeRequestsDistance.createdByCompanyId === companyId}
                        isFinalizing={lastChangeRequestsDistance.endedByCompanyId === companyId}
                      />
                    }
                  />
                ) : (
                  <Text variant="h4">Длина маршрута: {freightDeal.dealOrder.route.distance} км</Text>
                )}
              </Flex>

              <Flex gap={24}>
                {freightDeal?.status === FreightDealStatus.Agreement && (
                  <Button
                    type="link"
                    onClick={() => {
                      setCurrentOfferOfConditionsType(FreightOrderChangeRequestBlockType.Route)
                      setIsOfferOfConditionsModalOpen(true)
                    }}
                  >
                    <Flex gap={8} align="center">
                      <FiEdit2 size={16} color={'var(--primary-main-color)'} />
                      Предложить свои условия
                    </Flex>
                  </Button>
                )}

                <Button
                  type="link"
                  onClick={() => {
                    setCurrentHistoryType(FreightOrderChangeRequestBlockType.Route)
                    setIsHistoryModalOpen(true)
                  }}
                >
                  <Flex gap={8} align="center">
                    <AiOutlineHistory size={16} color={'var(--primary-main-color)'} />
                    Посмотреть историю изменений
                  </Flex>
                </Button>
              </Flex>
            </Flex>

            <Flex vertical gap={32}>
              <LoadDocuments
                freightOrderId={freightDeal.orderId}
                title="Образцы документов"
                isAddDocuments={companyId === freightDeal.customerId}
                isDeleteDocuments={companyId === freightDeal.customerId}
              />
            </Flex>

            <Flex vertical gap={32}>
              <Flex
                justify="space-between"
                style={{
                  padding: '16px',
                  borderTop: '1px solid #e9ecee',
                  borderBottom: '1px solid #e9ecee',
                  cursor: 'pointer',
                }}
                onClick={() => setIsOpenTechnicalCharacteristics((prev) => !prev)}
              >
                <Flex align="center" gap={8}>
                  <FiList size={24} />
                  <Text variant="h2">Тех. характеристики</Text>
                </Flex>

                <Flex align="center" gap={10} style={{ color: 'var(--primary-main-color)' }}>
                  Подробнее {!isOpenTechnicalCharacteristics ? <FiChevronDown size={20} /> : <FiChevronUp size={20} />}
                </Flex>
              </Flex>
              {isOpenTechnicalCharacteristics && (
                <>
                  <Flex>
                    <Text variant="h4">Основное</Text>
                  </Flex>

                  <Flex gap={32}>
                    <Flex vertical gap={32}>
                      <ReadValue
                        name="Способ погрузки"
                        value={freightDeal.dealOrder.route.points[0].roadTransportParameters.loadingTypes
                          .map((loadingType) => getLoadType(loadingType))
                          .join(', ')}
                      />
                      <ReadValue
                        name="Скорость погрузки"
                        value={
                          freightDeal.dealOrder.route.points[0].roadTransportParameters.loadingThroughput
                            ? freightDeal.dealOrder.route.points[0].roadTransportParameters.loadingThroughput?.toString() +
                              ' авто/сутки'
                            : undefined
                        }
                      />
                      <ReadValue
                        name="Длина весов"
                        value={
                          freightDeal.dealOrder.route.points[0].roadTransportParameters.vehicleLengthMaxM
                            ? freightDeal.dealOrder.route.points[0].roadTransportParameters.vehicleLengthMaxM?.toString() +
                              ' м'
                            : undefined
                        }
                      />
                      <ReadValue
                        name="Ограничения для ТС по высоте"
                        value={
                          freightDeal.dealOrder.route.points[0].roadTransportParameters.vehicleHeightMaxM
                            ? freightDeal.dealOrder.route.points[0].roadTransportParameters.vehicleHeightMaxM?.toString() +
                              ' м'
                            : undefined
                        }
                      />
                      {/*<ReadValue*/}
                      {/*  name="Ограничения для ТС по высоте клиренса"*/}
                      {/*  value={*/}
                      {/*    freightDeal.dealOrder.route.points[0].roadTransportParameters.vehicleClearanceMinCm*/}
                      {/*      ? freightDeal.dealOrder.route.points[0].roadTransportParameters.vehicleClearanceMinCm?.toString() +*/}
                      {/*      ' см'*/}
                      {/*      : undefined*/}
                      {/*  }*/}
                      {/*/>*/}
                      <ReadValue
                        name="Наличие весов"
                        value={
                          freightDeal.dealOrder.route.points[0].roadTransportParameters.hasScales !== null
                            ? freightDeal.dealOrder.route.points[0].roadTransportParameters.hasScales
                              ? 'Есть'
                              : 'Нет'
                            : undefined
                        }
                      />
                      <ReadValue
                        name="Тоннаж весов"
                        value={
                          freightDeal.dealOrder.route.points[0].roadTransportParameters.scalesWeightLimitT
                            ? freightDeal.dealOrder.route.points[0].roadTransportParameters.scalesWeightLimitT?.toString() +
                              ' т'
                            : undefined
                        }
                      />
                      {/*<ReadValue*/}
                      {/*  name="Автоматизация взвешивания (ПО)"*/}
                      {/*  value={*/}
                      {/*    freightDeal.dealOrder.route.points[0].roadTransportParameters.hasWeighingAutomation !== null ? freightDeal.dealOrder.route.points[0].roadTransportParameters.hasWeighingAutomation ? 'Есть' : 'Нет' : undefined*/}
                      {/*  }*/}
                      {/*/>*/}
                    </Flex>

                    <Flex vertical gap={32}>
                      <ReadValue
                        name="Способ приемки"
                        value={freightDeal.dealOrder.route.points[1].roadTransportParameters.unloadingTypes
                          .map((unloadingType) => getUnloadType(unloadingType))
                          .join(', ')}
                      />
                      <ReadValue
                        name="Скорость приемки"
                        value={
                          freightDeal.dealOrder.route.points[1].roadTransportParameters.unloadingThroughput
                            ? freightDeal.dealOrder.route.points[1].roadTransportParameters.unloadingThroughput?.toString() +
                              ' авто/сутки'
                            : undefined
                        }
                      />
                      <ReadValue
                        name="Длина весов"
                        value={
                          freightDeal.dealOrder.route.points[1].roadTransportParameters.vehicleLengthMaxM
                            ? freightDeal.dealOrder.route.points[1].roadTransportParameters.vehicleLengthMaxM?.toString() +
                              ' м'
                            : undefined
                        }
                      />
                      <ReadValue
                        name="Ограничения для ТС по высоте"
                        value={
                          freightDeal.dealOrder.route.points[1].roadTransportParameters.vehicleHeightMaxM
                            ? freightDeal.dealOrder.route.points[1].roadTransportParameters.vehicleHeightMaxM?.toString() +
                              ' м'
                            : undefined
                        }
                      />
                      {/*<ReadValue*/}
                      {/*  name="Ограничения для ТС по высоте клиренса"*/}
                      {/*  value={*/}
                      {/*    freightDeal.dealOrder.route.points[1].roadTransportParameters.vehicleClearanceMinCm*/}
                      {/*      ? freightDeal.dealOrder.route.points[1].roadTransportParameters.vehicleClearanceMinCm?.toString() +*/}
                      {/*      ' см'*/}
                      {/*      : undefined*/}
                      {/*  }*/}
                      {/*/>*/}
                      <ReadValue
                        name="Наличие весов"
                        value={
                          freightDeal.dealOrder.route.points[1].roadTransportParameters.hasScales !== null
                            ? freightDeal.dealOrder.route.points[1].roadTransportParameters.hasScales
                              ? 'Есть'
                              : 'Нет'
                            : undefined
                        }
                      />
                      <ReadValue
                        name="Тоннаж весов"
                        value={
                          freightDeal.dealOrder.route.points[1].roadTransportParameters.scalesWeightLimitT
                            ? freightDeal.dealOrder.route.points[1].roadTransportParameters.scalesWeightLimitT?.toString() +
                              ' т'
                            : undefined
                        }
                      />
                      {/*<ReadValue*/}
                      {/*  name="Автоматизация взвешивания (ПО)"*/}
                      {/*  value={*/}
                      {/*    freightDeal.dealOrder.route.points[1].roadTransportParameters.hasWeighingAutomation !== null ? freightDeal.dealOrder.route.points[1].roadTransportParameters.hasWeighingAutomation ? 'Есть' : 'Нет' : undefined*/}
                      {/*  }*/}
                      {/*/>*/}
                    </Flex>
                  </Flex>

                  <Flex>
                    <Text variant="h4">Дорога к подъезду и складу</Text>
                  </Flex>

                  <Flex gap={32}>
                    <Flex vertical gap={32}>
                      <ReadValue
                        name="Тип покрытия"
                        value={
                          freightDeal.dealOrder.route.points[0].roadTransportParameters.roadSurfaceType !== null
                            ? getRoadSurfaceTypeName(
                                freightDeal.dealOrder.route.points[0].roadTransportParameters.roadSurfaceType
                              )
                            : undefined
                        }
                      />
                      <ReadValue
                        name="Качество покрытия"
                        value={
                          freightDeal.dealOrder.route.points[0].roadTransportParameters.roadSurfaceQuality !== null
                            ? getRoadSurfaceQualityName(
                                freightDeal.dealOrder.route.points[0].roadTransportParameters.roadSurfaceQuality
                              )
                            : undefined
                        }
                      />
                      <ReadValue
                        name="Расстояние от ворот до весов"
                        value={
                          freightDeal.dealOrder.route.points[0].roadTransportParameters.distanceToScalesM
                            ? freightDeal.dealOrder.route.points[0].roadTransportParameters.distanceToScalesM?.toString() +
                              ' м'
                            : undefined
                        }
                      />
                      <ReadValue
                        name="Площадка-накопитель для ТС"
                        value={
                          freightDeal.dealOrder.route.points[0].roadTransportParameters.hasParking !== null
                            ? freightDeal.dealOrder.route.points[0].roadTransportParameters.hasParking
                              ? 'Есть'
                              : 'Нет'
                            : undefined
                        }
                      />
                      <ReadValue
                        name="Дополнительные сведения"
                        value={freightDeal.dealOrder.route.points[0].roadTransportParameters.additionalDetails}
                      />
                    </Flex>

                    <Flex vertical gap={32}>
                      <ReadValue
                        name="Тип покрытия"
                        value={
                          freightDeal.dealOrder.route.points[1].roadTransportParameters.roadSurfaceType !== null
                            ? getRoadSurfaceTypeName(
                                freightDeal.dealOrder.route.points[1].roadTransportParameters.roadSurfaceType
                              )
                            : undefined
                        }
                      />
                      <ReadValue
                        name="Качество покрытия"
                        value={
                          freightDeal.dealOrder.route.points[1].roadTransportParameters.roadSurfaceQuality !== null
                            ? getRoadSurfaceQualityName(
                                freightDeal.dealOrder.route.points[1].roadTransportParameters.roadSurfaceQuality
                              )
                            : undefined
                        }
                      />
                      <ReadValue
                        name="Расстояние от ворот до весов"
                        value={
                          freightDeal.dealOrder.route.points[1].roadTransportParameters.distanceToScalesM
                            ? freightDeal.dealOrder.route.points[1].roadTransportParameters.distanceToScalesM?.toString() +
                              ' м'
                            : undefined
                        }
                      />
                      <ReadValue
                        name="Площадка-накопитель для ТС"
                        value={
                          freightDeal.dealOrder.route.points[1].roadTransportParameters.hasParking !== null
                            ? freightDeal.dealOrder.route.points[1].roadTransportParameters.hasParking
                              ? 'Есть'
                              : 'Нет'
                            : undefined
                        }
                      />
                      <ReadValue
                        name="Дополнительные сведения"
                        value={freightDeal.dealOrder.route.points[1].roadTransportParameters.additionalDetails}
                      />
                    </Flex>
                  </Flex>
                </>
              )}
            </Flex>

            <Flex vertical gap={16}>
              <Flex>
                <Text variant="h2">Дополнительно</Text>
              </Flex>

              <Flex>
                <Text variant="normal">
                  {freightDeal.dealOrder.additionalDetails ? freightDeal.dealOrder.additionalDetails : 'Не заполнено'}
                </Text>
              </Flex>
            </Flex>
          </Flex>

          <Modal
            {...modalSharedSettings}
            width={500}
            open={isOfferOfConditionsModalOpen}
            onCancel={() => setIsOfferOfConditionsModalOpen(false)}
            title="Предложить свои условия"
          >
            <OfferOfConditionsForm
              onBack={() => setIsOfferOfConditionsModalOpen(false)}
              onSubmit={onOfferOfConditionsHandler}
              type={currentOfferOfConditionsType}
              freightDeal={freightDeal}
            />
          </Modal>

          <Drawer title="История изменений" open={isHistoryModalOpen} onClose={() => setIsHistoryModalOpen(false)}>
            <LogisticDealHistory type={currentHistoryType} freightDealId={freightDeal.id} />
          </Drawer>
        </S.Wrapper>
      )}
    </Spin>
  )
}
