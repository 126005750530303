/* tslint:disable */
/* eslint-disable */
/**
 * API Зерно-Агрегатора
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CreateOrderBody } from '../models';
// @ts-ignore
import { CreateOrderOfferBody } from '../models';
// @ts-ignore
import { CreateOrderResponse } from '../models';
// @ts-ignore
import { GetOrderOffersResponse } from '../models';
// @ts-ignore
import { GetOrderReportResponse } from '../models';
// @ts-ignore
import { GetOrdersResponse } from '../models';
// @ts-ignore
import { OrderDirection } from '../models';
// @ts-ignore
import { UpdateOrderModeratorBody } from '../models';
// @ts-ignore
import { UpdateOrderOfferDistanceBody } from '../models';
// @ts-ignore
import { UpdateOrderOfferDomainBody } from '../models';
// @ts-ignore
import { UpdateOrderOfferStatusBody } from '../models';
// @ts-ignore
import { UpdateOrderStatusBody } from '../models';
/**
 * OrderApi - axios parameter creator
 * @export
 */
export const OrderApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} companyId 
         * @param {CreateOrderBody} createOrderBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderControllerCreateOrder: async (companyId: number, createOrderBody: CreateOrderBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('orderControllerCreateOrder', 'companyId', companyId)
            // verify required parameter 'createOrderBody' is not null or undefined
            assertParamExists('orderControllerCreateOrder', 'createOrderBody', createOrderBody)
            const localVarPath = `/company/{companyId}/order`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createOrderBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} orderId 
         * @param {CreateOrderOfferBody} createOrderOfferBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderControllerCreateOrderOffer: async (orderId: number, createOrderOfferBody: CreateOrderOfferBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderId' is not null or undefined
            assertParamExists('orderControllerCreateOrderOffer', 'orderId', orderId)
            // verify required parameter 'createOrderOfferBody' is not null or undefined
            assertParamExists('orderControllerCreateOrderOffer', 'createOrderOfferBody', createOrderOfferBody)
            const localVarPath = `/order/{orderId}/offer`
                .replace(`{${"orderId"}}`, encodeURIComponent(String(orderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createOrderOfferBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} orderId 
         * @param {Array<number>} [ids] Массив id предложений
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderControllerGetOrderOffers: async (orderId: number, ids?: Array<number>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderId' is not null or undefined
            assertParamExists('orderControllerGetOrderOffers', 'orderId', orderId)
            const localVarPath = `/order/{orderId}/offers`
                .replace(`{${"orderId"}}`, encodeURIComponent(String(orderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (ids) {
                localVarQueryParameter['ids'] = ids;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderControllerGetOrderReport: async (orderId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderId' is not null or undefined
            assertParamExists('orderControllerGetOrderReport', 'orderId', orderId)
            const localVarPath = `/order/{orderId}/report`
                .replace(`{${"orderId"}}`, encodeURIComponent(String(orderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [currentPage] Номер текущей страницы
         * @param {number} [pageSize] Количество элементов на странице
         * @param {Array<number>} [ids] Список id заявок
         * @param {Array<number>} [companyIds] Список id компаний
         * @param {Array<string>} [regionIds] Список id регионов
         * @param {Array<string>} [productTypes] Список типов товаров
         * @param {'BUY' | 'SELL'} [type] Тип заявки
         * @param {'ON_MODERATION' | 'IN_SEARCHING' | 'REJECTED' | 'REALISED'} [status] Статус заявки
         * @param {number} [minVolume] Минимальный объем
         * @param {number} [maxVolume] Максимальный объем
         * @param {number} [minPrice] Минимальная цена
         * @param {number} [maxPrice] Максимальная цена
         * @param {'id' | 'companyId' | 'offerId' | 'selectedVolume' | 'status' | 'startedAt' | 'endedAt' | 'moderatedBy' | 'createdAt' | 'updatedAt'} [orderBy] Поле для сортировки
         * @param {OrderDirection} [order] Направление сортировки
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderControllerGetOrders: async (currentPage?: number, pageSize?: number, ids?: Array<number>, companyIds?: Array<number>, regionIds?: Array<string>, productTypes?: Array<string>, type?: 'BUY' | 'SELL', status?: 'ON_MODERATION' | 'IN_SEARCHING' | 'REJECTED' | 'REALISED', minVolume?: number, maxVolume?: number, minPrice?: number, maxPrice?: number, orderBy?: 'id' | 'companyId' | 'offerId' | 'selectedVolume' | 'status' | 'startedAt' | 'endedAt' | 'moderatedBy' | 'createdAt' | 'updatedAt', order?: OrderDirection, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/orders`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (currentPage !== undefined) {
                localVarQueryParameter['current-page'] = currentPage;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page-size'] = pageSize;
            }

            if (ids) {
                localVarQueryParameter['ids'] = ids;
            }

            if (companyIds) {
                localVarQueryParameter['company-ids'] = companyIds;
            }

            if (regionIds) {
                localVarQueryParameter['region-ids'] = regionIds;
            }

            if (productTypes) {
                localVarQueryParameter['product-types'] = productTypes;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (minVolume !== undefined) {
                localVarQueryParameter['min-volume'] = minVolume;
            }

            if (maxVolume !== undefined) {
                localVarQueryParameter['max-volume'] = maxVolume;
            }

            if (minPrice !== undefined) {
                localVarQueryParameter['min-price'] = minPrice;
            }

            if (maxPrice !== undefined) {
                localVarQueryParameter['max-price'] = maxPrice;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['order-by'] = orderBy;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} orderId 
         * @param {UpdateOrderModeratorBody} updateOrderModeratorBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderControllerUpdateOrderModerator: async (orderId: number, updateOrderModeratorBody: UpdateOrderModeratorBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderId' is not null or undefined
            assertParamExists('orderControllerUpdateOrderModerator', 'orderId', orderId)
            // verify required parameter 'updateOrderModeratorBody' is not null or undefined
            assertParamExists('orderControllerUpdateOrderModerator', 'updateOrderModeratorBody', updateOrderModeratorBody)
            const localVarPath = `/order/{orderId}/moderator`
                .replace(`{${"orderId"}}`, encodeURIComponent(String(orderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateOrderModeratorBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} orderOfferId 
         * @param {UpdateOrderOfferDistanceBody} updateOrderOfferDistanceBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderControllerUpdateOrderOfferDistance: async (orderOfferId: number, updateOrderOfferDistanceBody: UpdateOrderOfferDistanceBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderOfferId' is not null or undefined
            assertParamExists('orderControllerUpdateOrderOfferDistance', 'orderOfferId', orderOfferId)
            // verify required parameter 'updateOrderOfferDistanceBody' is not null or undefined
            assertParamExists('orderControllerUpdateOrderOfferDistance', 'updateOrderOfferDistanceBody', updateOrderOfferDistanceBody)
            const localVarPath = `/order-offer/{orderOfferId}/distance`
                .replace(`{${"orderOfferId"}}`, encodeURIComponent(String(orderOfferId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateOrderOfferDistanceBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} orderOfferId 
         * @param {UpdateOrderOfferDomainBody} updateOrderOfferDomainBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderControllerUpdateOrderOfferDomain: async (orderOfferId: number, updateOrderOfferDomainBody: UpdateOrderOfferDomainBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderOfferId' is not null or undefined
            assertParamExists('orderControllerUpdateOrderOfferDomain', 'orderOfferId', orderOfferId)
            // verify required parameter 'updateOrderOfferDomainBody' is not null or undefined
            assertParamExists('orderControllerUpdateOrderOfferDomain', 'updateOrderOfferDomainBody', updateOrderOfferDomainBody)
            const localVarPath = `/order-offer/{orderOfferId}/domain`
                .replace(`{${"orderOfferId"}}`, encodeURIComponent(String(orderOfferId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateOrderOfferDomainBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} orderOfferId 
         * @param {UpdateOrderOfferStatusBody} updateOrderOfferStatusBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderControllerUpdateOrderOfferStatus: async (orderOfferId: number, updateOrderOfferStatusBody: UpdateOrderOfferStatusBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderOfferId' is not null or undefined
            assertParamExists('orderControllerUpdateOrderOfferStatus', 'orderOfferId', orderOfferId)
            // verify required parameter 'updateOrderOfferStatusBody' is not null or undefined
            assertParamExists('orderControllerUpdateOrderOfferStatus', 'updateOrderOfferStatusBody', updateOrderOfferStatusBody)
            const localVarPath = `/order-offer/{orderOfferId}/status`
                .replace(`{${"orderOfferId"}}`, encodeURIComponent(String(orderOfferId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateOrderOfferStatusBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} orderId 
         * @param {UpdateOrderStatusBody} updateOrderStatusBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderControllerUpdateOrderStatus: async (orderId: number, updateOrderStatusBody: UpdateOrderStatusBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderId' is not null or undefined
            assertParamExists('orderControllerUpdateOrderStatus', 'orderId', orderId)
            // verify required parameter 'updateOrderStatusBody' is not null or undefined
            assertParamExists('orderControllerUpdateOrderStatus', 'updateOrderStatusBody', updateOrderStatusBody)
            const localVarPath = `/order/{orderId}/status`
                .replace(`{${"orderId"}}`, encodeURIComponent(String(orderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateOrderStatusBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OrderApi - functional programming interface
 * @export
 */
export const OrderApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OrderApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} companyId 
         * @param {CreateOrderBody} createOrderBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orderControllerCreateOrder(companyId: number, createOrderBody: CreateOrderBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateOrderResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orderControllerCreateOrder(companyId, createOrderBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} orderId 
         * @param {CreateOrderOfferBody} createOrderOfferBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orderControllerCreateOrderOffer(orderId: number, createOrderOfferBody: CreateOrderOfferBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orderControllerCreateOrderOffer(orderId, createOrderOfferBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} orderId 
         * @param {Array<number>} [ids] Массив id предложений
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orderControllerGetOrderOffers(orderId: number, ids?: Array<number>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetOrderOffersResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orderControllerGetOrderOffers(orderId, ids, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orderControllerGetOrderReport(orderId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetOrderReportResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orderControllerGetOrderReport(orderId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [currentPage] Номер текущей страницы
         * @param {number} [pageSize] Количество элементов на странице
         * @param {Array<number>} [ids] Список id заявок
         * @param {Array<number>} [companyIds] Список id компаний
         * @param {Array<string>} [regionIds] Список id регионов
         * @param {Array<string>} [productTypes] Список типов товаров
         * @param {'BUY' | 'SELL'} [type] Тип заявки
         * @param {'ON_MODERATION' | 'IN_SEARCHING' | 'REJECTED' | 'REALISED'} [status] Статус заявки
         * @param {number} [minVolume] Минимальный объем
         * @param {number} [maxVolume] Максимальный объем
         * @param {number} [minPrice] Минимальная цена
         * @param {number} [maxPrice] Максимальная цена
         * @param {'id' | 'companyId' | 'offerId' | 'selectedVolume' | 'status' | 'startedAt' | 'endedAt' | 'moderatedBy' | 'createdAt' | 'updatedAt'} [orderBy] Поле для сортировки
         * @param {OrderDirection} [order] Направление сортировки
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orderControllerGetOrders(currentPage?: number, pageSize?: number, ids?: Array<number>, companyIds?: Array<number>, regionIds?: Array<string>, productTypes?: Array<string>, type?: 'BUY' | 'SELL', status?: 'ON_MODERATION' | 'IN_SEARCHING' | 'REJECTED' | 'REALISED', minVolume?: number, maxVolume?: number, minPrice?: number, maxPrice?: number, orderBy?: 'id' | 'companyId' | 'offerId' | 'selectedVolume' | 'status' | 'startedAt' | 'endedAt' | 'moderatedBy' | 'createdAt' | 'updatedAt', order?: OrderDirection, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetOrdersResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orderControllerGetOrders(currentPage, pageSize, ids, companyIds, regionIds, productTypes, type, status, minVolume, maxVolume, minPrice, maxPrice, orderBy, order, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} orderId 
         * @param {UpdateOrderModeratorBody} updateOrderModeratorBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orderControllerUpdateOrderModerator(orderId: number, updateOrderModeratorBody: UpdateOrderModeratorBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orderControllerUpdateOrderModerator(orderId, updateOrderModeratorBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} orderOfferId 
         * @param {UpdateOrderOfferDistanceBody} updateOrderOfferDistanceBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orderControllerUpdateOrderOfferDistance(orderOfferId: number, updateOrderOfferDistanceBody: UpdateOrderOfferDistanceBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orderControllerUpdateOrderOfferDistance(orderOfferId, updateOrderOfferDistanceBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} orderOfferId 
         * @param {UpdateOrderOfferDomainBody} updateOrderOfferDomainBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orderControllerUpdateOrderOfferDomain(orderOfferId: number, updateOrderOfferDomainBody: UpdateOrderOfferDomainBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orderControllerUpdateOrderOfferDomain(orderOfferId, updateOrderOfferDomainBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} orderOfferId 
         * @param {UpdateOrderOfferStatusBody} updateOrderOfferStatusBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orderControllerUpdateOrderOfferStatus(orderOfferId: number, updateOrderOfferStatusBody: UpdateOrderOfferStatusBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orderControllerUpdateOrderOfferStatus(orderOfferId, updateOrderOfferStatusBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} orderId 
         * @param {UpdateOrderStatusBody} updateOrderStatusBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orderControllerUpdateOrderStatus(orderId: number, updateOrderStatusBody: UpdateOrderStatusBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orderControllerUpdateOrderStatus(orderId, updateOrderStatusBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OrderApi - factory interface
 * @export
 */
export const OrderApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OrderApiFp(configuration)
    return {
        /**
         * 
         * @param {number} companyId 
         * @param {CreateOrderBody} createOrderBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderControllerCreateOrder(companyId: number, createOrderBody: CreateOrderBody, options?: any): AxiosPromise<CreateOrderResponse> {
            return localVarFp.orderControllerCreateOrder(companyId, createOrderBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} orderId 
         * @param {CreateOrderOfferBody} createOrderOfferBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderControllerCreateOrderOffer(orderId: number, createOrderOfferBody: CreateOrderOfferBody, options?: any): AxiosPromise<void> {
            return localVarFp.orderControllerCreateOrderOffer(orderId, createOrderOfferBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} orderId 
         * @param {Array<number>} [ids] Массив id предложений
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderControllerGetOrderOffers(orderId: number, ids?: Array<number>, options?: any): AxiosPromise<GetOrderOffersResponse> {
            return localVarFp.orderControllerGetOrderOffers(orderId, ids, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderControllerGetOrderReport(orderId: number, options?: any): AxiosPromise<GetOrderReportResponse> {
            return localVarFp.orderControllerGetOrderReport(orderId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [currentPage] Номер текущей страницы
         * @param {number} [pageSize] Количество элементов на странице
         * @param {Array<number>} [ids] Список id заявок
         * @param {Array<number>} [companyIds] Список id компаний
         * @param {Array<string>} [regionIds] Список id регионов
         * @param {Array<string>} [productTypes] Список типов товаров
         * @param {'BUY' | 'SELL'} [type] Тип заявки
         * @param {'ON_MODERATION' | 'IN_SEARCHING' | 'REJECTED' | 'REALISED'} [status] Статус заявки
         * @param {number} [minVolume] Минимальный объем
         * @param {number} [maxVolume] Максимальный объем
         * @param {number} [minPrice] Минимальная цена
         * @param {number} [maxPrice] Максимальная цена
         * @param {'id' | 'companyId' | 'offerId' | 'selectedVolume' | 'status' | 'startedAt' | 'endedAt' | 'moderatedBy' | 'createdAt' | 'updatedAt'} [orderBy] Поле для сортировки
         * @param {OrderDirection} [order] Направление сортировки
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderControllerGetOrders(currentPage?: number, pageSize?: number, ids?: Array<number>, companyIds?: Array<number>, regionIds?: Array<string>, productTypes?: Array<string>, type?: 'BUY' | 'SELL', status?: 'ON_MODERATION' | 'IN_SEARCHING' | 'REJECTED' | 'REALISED', minVolume?: number, maxVolume?: number, minPrice?: number, maxPrice?: number, orderBy?: 'id' | 'companyId' | 'offerId' | 'selectedVolume' | 'status' | 'startedAt' | 'endedAt' | 'moderatedBy' | 'createdAt' | 'updatedAt', order?: OrderDirection, options?: any): AxiosPromise<GetOrdersResponse> {
            return localVarFp.orderControllerGetOrders(currentPage, pageSize, ids, companyIds, regionIds, productTypes, type, status, minVolume, maxVolume, minPrice, maxPrice, orderBy, order, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} orderId 
         * @param {UpdateOrderModeratorBody} updateOrderModeratorBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderControllerUpdateOrderModerator(orderId: number, updateOrderModeratorBody: UpdateOrderModeratorBody, options?: any): AxiosPromise<void> {
            return localVarFp.orderControllerUpdateOrderModerator(orderId, updateOrderModeratorBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} orderOfferId 
         * @param {UpdateOrderOfferDistanceBody} updateOrderOfferDistanceBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderControllerUpdateOrderOfferDistance(orderOfferId: number, updateOrderOfferDistanceBody: UpdateOrderOfferDistanceBody, options?: any): AxiosPromise<void> {
            return localVarFp.orderControllerUpdateOrderOfferDistance(orderOfferId, updateOrderOfferDistanceBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} orderOfferId 
         * @param {UpdateOrderOfferDomainBody} updateOrderOfferDomainBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderControllerUpdateOrderOfferDomain(orderOfferId: number, updateOrderOfferDomainBody: UpdateOrderOfferDomainBody, options?: any): AxiosPromise<void> {
            return localVarFp.orderControllerUpdateOrderOfferDomain(orderOfferId, updateOrderOfferDomainBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} orderOfferId 
         * @param {UpdateOrderOfferStatusBody} updateOrderOfferStatusBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderControllerUpdateOrderOfferStatus(orderOfferId: number, updateOrderOfferStatusBody: UpdateOrderOfferStatusBody, options?: any): AxiosPromise<void> {
            return localVarFp.orderControllerUpdateOrderOfferStatus(orderOfferId, updateOrderOfferStatusBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} orderId 
         * @param {UpdateOrderStatusBody} updateOrderStatusBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderControllerUpdateOrderStatus(orderId: number, updateOrderStatusBody: UpdateOrderStatusBody, options?: any): AxiosPromise<void> {
            return localVarFp.orderControllerUpdateOrderStatus(orderId, updateOrderStatusBody, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for orderControllerCreateOrder operation in OrderApi.
 * @export
 * @interface OrderApiOrderControllerCreateOrderRequest
 */
export interface OrderApiOrderControllerCreateOrderRequest {
    /**
     * 
     * @type {number}
     * @memberof OrderApiOrderControllerCreateOrder
     */
    readonly companyId: number

    /**
     * 
     * @type {CreateOrderBody}
     * @memberof OrderApiOrderControllerCreateOrder
     */
    readonly createOrderBody: CreateOrderBody
}

/**
 * Request parameters for orderControllerCreateOrderOffer operation in OrderApi.
 * @export
 * @interface OrderApiOrderControllerCreateOrderOfferRequest
 */
export interface OrderApiOrderControllerCreateOrderOfferRequest {
    /**
     * 
     * @type {number}
     * @memberof OrderApiOrderControllerCreateOrderOffer
     */
    readonly orderId: number

    /**
     * 
     * @type {CreateOrderOfferBody}
     * @memberof OrderApiOrderControllerCreateOrderOffer
     */
    readonly createOrderOfferBody: CreateOrderOfferBody
}

/**
 * Request parameters for orderControllerGetOrderOffers operation in OrderApi.
 * @export
 * @interface OrderApiOrderControllerGetOrderOffersRequest
 */
export interface OrderApiOrderControllerGetOrderOffersRequest {
    /**
     * 
     * @type {number}
     * @memberof OrderApiOrderControllerGetOrderOffers
     */
    readonly orderId: number

    /**
     * Массив id предложений
     * @type {Array<number>}
     * @memberof OrderApiOrderControllerGetOrderOffers
     */
    readonly ids?: Array<number>
}

/**
 * Request parameters for orderControllerGetOrderReport operation in OrderApi.
 * @export
 * @interface OrderApiOrderControllerGetOrderReportRequest
 */
export interface OrderApiOrderControllerGetOrderReportRequest {
    /**
     * 
     * @type {number}
     * @memberof OrderApiOrderControllerGetOrderReport
     */
    readonly orderId: number
}

/**
 * Request parameters for orderControllerGetOrders operation in OrderApi.
 * @export
 * @interface OrderApiOrderControllerGetOrdersRequest
 */
export interface OrderApiOrderControllerGetOrdersRequest {
    /**
     * Номер текущей страницы
     * @type {number}
     * @memberof OrderApiOrderControllerGetOrders
     */
    readonly currentPage?: number

    /**
     * Количество элементов на странице
     * @type {number}
     * @memberof OrderApiOrderControllerGetOrders
     */
    readonly pageSize?: number

    /**
     * Список id заявок
     * @type {Array<number>}
     * @memberof OrderApiOrderControllerGetOrders
     */
    readonly ids?: Array<number>

    /**
     * Список id компаний
     * @type {Array<number>}
     * @memberof OrderApiOrderControllerGetOrders
     */
    readonly companyIds?: Array<number>

    /**
     * Список id регионов
     * @type {Array<string>}
     * @memberof OrderApiOrderControllerGetOrders
     */
    readonly regionIds?: Array<string>

    /**
     * Список типов товаров
     * @type {Array<string>}
     * @memberof OrderApiOrderControllerGetOrders
     */
    readonly productTypes?: Array<string>

    /**
     * Тип заявки
     * @type {'BUY' | 'SELL'}
     * @memberof OrderApiOrderControllerGetOrders
     */
    readonly type?: 'BUY' | 'SELL'

    /**
     * Статус заявки
     * @type {'ON_MODERATION' | 'IN_SEARCHING' | 'REJECTED' | 'REALISED'}
     * @memberof OrderApiOrderControllerGetOrders
     */
    readonly status?: 'ON_MODERATION' | 'IN_SEARCHING' | 'REJECTED' | 'REALISED'

    /**
     * Минимальный объем
     * @type {number}
     * @memberof OrderApiOrderControllerGetOrders
     */
    readonly minVolume?: number

    /**
     * Максимальный объем
     * @type {number}
     * @memberof OrderApiOrderControllerGetOrders
     */
    readonly maxVolume?: number

    /**
     * Минимальная цена
     * @type {number}
     * @memberof OrderApiOrderControllerGetOrders
     */
    readonly minPrice?: number

    /**
     * Максимальная цена
     * @type {number}
     * @memberof OrderApiOrderControllerGetOrders
     */
    readonly maxPrice?: number

    /**
     * Поле для сортировки
     * @type {'id' | 'companyId' | 'offerId' | 'selectedVolume' | 'status' | 'startedAt' | 'endedAt' | 'moderatedBy' | 'createdAt' | 'updatedAt'}
     * @memberof OrderApiOrderControllerGetOrders
     */
    readonly orderBy?: 'id' | 'companyId' | 'offerId' | 'selectedVolume' | 'status' | 'startedAt' | 'endedAt' | 'moderatedBy' | 'createdAt' | 'updatedAt'

    /**
     * Направление сортировки
     * @type {OrderDirection}
     * @memberof OrderApiOrderControllerGetOrders
     */
    readonly order?: OrderDirection
}

/**
 * Request parameters for orderControllerUpdateOrderModerator operation in OrderApi.
 * @export
 * @interface OrderApiOrderControllerUpdateOrderModeratorRequest
 */
export interface OrderApiOrderControllerUpdateOrderModeratorRequest {
    /**
     * 
     * @type {number}
     * @memberof OrderApiOrderControllerUpdateOrderModerator
     */
    readonly orderId: number

    /**
     * 
     * @type {UpdateOrderModeratorBody}
     * @memberof OrderApiOrderControllerUpdateOrderModerator
     */
    readonly updateOrderModeratorBody: UpdateOrderModeratorBody
}

/**
 * Request parameters for orderControllerUpdateOrderOfferDistance operation in OrderApi.
 * @export
 * @interface OrderApiOrderControllerUpdateOrderOfferDistanceRequest
 */
export interface OrderApiOrderControllerUpdateOrderOfferDistanceRequest {
    /**
     * 
     * @type {number}
     * @memberof OrderApiOrderControllerUpdateOrderOfferDistance
     */
    readonly orderOfferId: number

    /**
     * 
     * @type {UpdateOrderOfferDistanceBody}
     * @memberof OrderApiOrderControllerUpdateOrderOfferDistance
     */
    readonly updateOrderOfferDistanceBody: UpdateOrderOfferDistanceBody
}

/**
 * Request parameters for orderControllerUpdateOrderOfferDomain operation in OrderApi.
 * @export
 * @interface OrderApiOrderControllerUpdateOrderOfferDomainRequest
 */
export interface OrderApiOrderControllerUpdateOrderOfferDomainRequest {
    /**
     * 
     * @type {number}
     * @memberof OrderApiOrderControllerUpdateOrderOfferDomain
     */
    readonly orderOfferId: number

    /**
     * 
     * @type {UpdateOrderOfferDomainBody}
     * @memberof OrderApiOrderControllerUpdateOrderOfferDomain
     */
    readonly updateOrderOfferDomainBody: UpdateOrderOfferDomainBody
}

/**
 * Request parameters for orderControllerUpdateOrderOfferStatus operation in OrderApi.
 * @export
 * @interface OrderApiOrderControllerUpdateOrderOfferStatusRequest
 */
export interface OrderApiOrderControllerUpdateOrderOfferStatusRequest {
    /**
     * 
     * @type {number}
     * @memberof OrderApiOrderControllerUpdateOrderOfferStatus
     */
    readonly orderOfferId: number

    /**
     * 
     * @type {UpdateOrderOfferStatusBody}
     * @memberof OrderApiOrderControllerUpdateOrderOfferStatus
     */
    readonly updateOrderOfferStatusBody: UpdateOrderOfferStatusBody
}

/**
 * Request parameters for orderControllerUpdateOrderStatus operation in OrderApi.
 * @export
 * @interface OrderApiOrderControllerUpdateOrderStatusRequest
 */
export interface OrderApiOrderControllerUpdateOrderStatusRequest {
    /**
     * 
     * @type {number}
     * @memberof OrderApiOrderControllerUpdateOrderStatus
     */
    readonly orderId: number

    /**
     * 
     * @type {UpdateOrderStatusBody}
     * @memberof OrderApiOrderControllerUpdateOrderStatus
     */
    readonly updateOrderStatusBody: UpdateOrderStatusBody
}

/**
 * OrderApi - object-oriented interface
 * @export
 * @class OrderApi
 * @extends {BaseAPI}
 */
export class OrderApi extends BaseAPI {
    /**
     * 
     * @param {OrderApiOrderControllerCreateOrderRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public orderControllerCreateOrder(requestParameters: OrderApiOrderControllerCreateOrderRequest, options?: AxiosRequestConfig) {
        return OrderApiFp(this.configuration).orderControllerCreateOrder(requestParameters.companyId, requestParameters.createOrderBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {OrderApiOrderControllerCreateOrderOfferRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public orderControllerCreateOrderOffer(requestParameters: OrderApiOrderControllerCreateOrderOfferRequest, options?: AxiosRequestConfig) {
        return OrderApiFp(this.configuration).orderControllerCreateOrderOffer(requestParameters.orderId, requestParameters.createOrderOfferBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {OrderApiOrderControllerGetOrderOffersRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public orderControllerGetOrderOffers(requestParameters: OrderApiOrderControllerGetOrderOffersRequest, options?: AxiosRequestConfig) {
        return OrderApiFp(this.configuration).orderControllerGetOrderOffers(requestParameters.orderId, requestParameters.ids, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {OrderApiOrderControllerGetOrderReportRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public orderControllerGetOrderReport(requestParameters: OrderApiOrderControllerGetOrderReportRequest, options?: AxiosRequestConfig) {
        return OrderApiFp(this.configuration).orderControllerGetOrderReport(requestParameters.orderId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {OrderApiOrderControllerGetOrdersRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public orderControllerGetOrders(requestParameters: OrderApiOrderControllerGetOrdersRequest = {}, options?: AxiosRequestConfig) {
        return OrderApiFp(this.configuration).orderControllerGetOrders(requestParameters.currentPage, requestParameters.pageSize, requestParameters.ids, requestParameters.companyIds, requestParameters.regionIds, requestParameters.productTypes, requestParameters.type, requestParameters.status, requestParameters.minVolume, requestParameters.maxVolume, requestParameters.minPrice, requestParameters.maxPrice, requestParameters.orderBy, requestParameters.order, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {OrderApiOrderControllerUpdateOrderModeratorRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public orderControllerUpdateOrderModerator(requestParameters: OrderApiOrderControllerUpdateOrderModeratorRequest, options?: AxiosRequestConfig) {
        return OrderApiFp(this.configuration).orderControllerUpdateOrderModerator(requestParameters.orderId, requestParameters.updateOrderModeratorBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {OrderApiOrderControllerUpdateOrderOfferDistanceRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public orderControllerUpdateOrderOfferDistance(requestParameters: OrderApiOrderControllerUpdateOrderOfferDistanceRequest, options?: AxiosRequestConfig) {
        return OrderApiFp(this.configuration).orderControllerUpdateOrderOfferDistance(requestParameters.orderOfferId, requestParameters.updateOrderOfferDistanceBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {OrderApiOrderControllerUpdateOrderOfferDomainRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public orderControllerUpdateOrderOfferDomain(requestParameters: OrderApiOrderControllerUpdateOrderOfferDomainRequest, options?: AxiosRequestConfig) {
        return OrderApiFp(this.configuration).orderControllerUpdateOrderOfferDomain(requestParameters.orderOfferId, requestParameters.updateOrderOfferDomainBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {OrderApiOrderControllerUpdateOrderOfferStatusRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public orderControllerUpdateOrderOfferStatus(requestParameters: OrderApiOrderControllerUpdateOrderOfferStatusRequest, options?: AxiosRequestConfig) {
        return OrderApiFp(this.configuration).orderControllerUpdateOrderOfferStatus(requestParameters.orderOfferId, requestParameters.updateOrderOfferStatusBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {OrderApiOrderControllerUpdateOrderStatusRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public orderControllerUpdateOrderStatus(requestParameters: OrderApiOrderControllerUpdateOrderStatusRequest, options?: AxiosRequestConfig) {
        return OrderApiFp(this.configuration).orderControllerUpdateOrderStatus(requestParameters.orderId, requestParameters.updateOrderStatusBody, options).then((request) => request(this.axios, this.basePath));
    }
}
