import { useQuery, UseQueryResult } from '@tanstack/react-query'

import { ElevatorApiElevatorControllerGetElevatorCompaniesRequest, GetElevatorCompaniesResponse } from '~api/generated'
import { useApi } from '~providers/api'

export const useGetElevatorCompanies = (
  request: ElevatorApiElevatorControllerGetElevatorCompaniesRequest
): UseQueryResult<GetElevatorCompaniesResponse> => {
  const api = useApi()

  const fetchFn = async (): Promise<GetElevatorCompaniesResponse> => {
    const res = await api.elevators.elevatorControllerGetElevatorCompanies(request)

    return res.data
  }

  return useQuery(['elevatorCompanies', request], fetchFn, { keepPreviousData: true })
}
