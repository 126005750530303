import React, { useEffect } from 'react'

import { Pagination, Table } from 'antd'
import { observer } from 'mobx-react-lite'
import { useLocation, useNavigate } from 'react-router-dom'

import { useGetProductTypeData, useGetTableHeight, usePagination } from '~/hooks'
import { myOffersFilterStore } from '~/store/filters/offersFilterStore'
import { getOfferType } from '~/utils/offers/get-offer-type'
import { CompanyOfferData } from '~api/generated'
import { useGetDevice } from '~hooks/common'
import { useGetOffersCompany } from '~hooks/offer'
import { EmptyMyOfferList } from '~pages/OffersPage/components/EmptyMyOfferList'
import { OfferCardPreview } from '~pages/OffersPage/components/OfferCardPreview'
import { View } from '~pages/OffersPage/components/ViewToggle'
import { useGetView } from '~pages/OffersPage/hooks'
import { useGetColumnsMyOffers } from '~pages/OffersPage/hooks/useGetColumnsMyOffers'
import { useAuth } from '~providers/auth'
import { ActiveFiltersInline } from '~shared/components/ActiveFiltersInline'

import { OffersCardPreviewWrapper } from '../../components/OffersCardPreviewWrapper'
import { OffersFilter } from '../../components/OffersFilter'
import { OffersHeader } from '../../components/OffersHeader'

import * as S from '../../OffersPage.styled'

export const MyOffers: React.FC = observer(() => {
  const { companyId } = useAuth()
  const navigate = useNavigate()
  const location = useLocation()
  const { view, setView } = useGetView()
  const device = useGetDevice()
  const { currentPage, setCurrentPage, pageSize, setPageSize, pageSizeOptions } = usePagination()
  const tableHeight = useGetTableHeight(310)

  const { getProductType, getProductTypeParametersForUI_V2 } = useGetProductTypeData()

  myOffersFilterStore.setCompanyId(companyId)
  const filters = myOffersFilterStore.filters

  const {
    data: offersData,
    refetch: refetchOfferList,
    isLoading: isLoadingOfferList,
  } = useGetOffersCompany({
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    companyId: companyId!,
    currentPage,
    pageSize: pageSize,
    ...myOffersFilterStore.mappedCurrentFiltersToResponse,
  })

  useEffect(() => {
    void refetchOfferList()
  }, [
    filters.volume.from,
    filters.volume.to,
    filters.price.from,
    filters.price.to,
    filters.offerType,
    filters.regionIds,
  ])

  const offers = offersData?.companyOffers
  const pagination = !offersData?.pagination || !offersData?.pagination?.total ? null : offersData.pagination

  const onRowClickHandler = (record: CompanyOfferData) => {
    navigate({
      pathname: `${record.id}`,
      search: location.search,
    })
  }

  const columns = useGetColumnsMyOffers()

  const isMobile = device === 'mobile'

  const isOfferListEmpty = !offers?.length && !isLoadingOfferList

  if (isOfferListEmpty) {
    return (
      <S.ContentWrapper>
        <S.Content>
          <OffersHeader view={view} onChangeView={(value) => setView(value)} onAfterCreateOffer={refetchOfferList} />
          <ActiveFiltersInline store="myOffers" />
          <EmptyMyOfferList />
        </S.Content>
      </S.ContentWrapper>
    )
  }

  return (
    <S.ContentWrapper>
      <S.Content>
        <OffersHeader
          filters={<OffersFilter store="myOffers" />}
          view={view}
          onChangeView={(value) => setView(value)}
          onAfterCreateOffer={refetchOfferList}
        />

        <ActiveFiltersInline store="myOffers" />
        {/*<ActiveFiltersTags store={myOffersFilterStore} />*/}

        {view === View.CARDS || isMobile ? (
          <OffersCardPreviewWrapper isLoading={isLoadingOfferList}>
            {offers?.map((offer, idx) => {
              return (
                <OfferCardPreview
                  key={idx}
                  onClick={() => onRowClickHandler(offer)}
                  name={offer.product.name}
                  img={getProductType(offer.product.type)?.picture?.source}
                  parameters={getProductTypeParametersForUI_V2(offer.product)}
                  volume={offer.volume}
                  price={offer.price}
                  isNds={offer.includingNds}
                  address={offer.address}
                  distance={offer.distance}
                  statuses={[getOfferType(offer.type)]}
                  publishedAt={offer.publishedAt}
                />
              )
            })}
          </OffersCardPreviewWrapper>
        ) : (
          <Table<CompanyOfferData>
            rowKey="id"
            className="table-interactive"
            loading={isLoadingOfferList}
            columns={columns}
            dataSource={offers}
            pagination={false}
            scroll={{ x: 'max-content', y: tableHeight }}
            onRow={(record) => ({
              onClick: onRowClickHandler.bind(null, record),
            })}
          />
        )}

        {pagination && (
          <Pagination
            current={pagination?.currentPage}
            total={pagination?.total}
            pageSize={pagination?.pageSize}
            pageSizeOptions={pageSizeOptions}
            onShowSizeChange={(x, size) => setPageSize(size)}
            showSizeChanger
            onChange={setCurrentPage}
          />
        )}
      </S.Content>
    </S.ContentWrapper>
  )
})
