import styled from 'styled-components'

import { breakpoints } from '~/tokens'

export const Wrapper = styled.div`
  // display: flex;
  // flex-direction: column;
  // align-items: center;
  //
  // gap: 8px;
  //
  // @media (max-width: ${breakpoints.MOBILE_XL}px) {
  // }
`
