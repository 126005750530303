import { Radio } from 'antd'
import styled from 'styled-components'

export const StyledRadio = styled(Radio)`
  & > span:nth-child(2) {
    width: 100%;
  }
`

export const OptionsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
`
export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`

export const OptionText = styled.p`
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  margin: 0;
  color: #494949;
`
