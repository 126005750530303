/* eslint-disable */
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'
export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] }
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> }
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> }
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never }
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never }
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string }
  String: { input: string; output: string }
  Boolean: { input: boolean; output: boolean }
  Int: { input: number; output: number }
  Float: { input: number; output: number }
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: { input: any; output: any }
}

export type AcceptAccessToCompanyInput = {
  companyId: Scalars['Int']['input']
  role: UserRole
}

export type AsUserLoginInput = {
  token: Scalars['String']['input']
  userId: Scalars['Int']['input']
}

export type AuthMutations = {
  __typename?: 'AuthMutations'
  confirmAuthPhoneNumber: ConfirmationCodeEntity
  confirmPhoneNumber: ConfirmationCodeEntity
  logInAsDemoUser: ConfirmationCodeEntity
  logInBySmsCode: Scalars['String']['output']
  logOut: Scalars['String']['output']
  logOutUserReturnModerator: Scalars['String']['output']
  loginAsUser: Scalars['String']['output']
  requestPhoneNumberConfirmationCode: ConfirmationCodeEntity
  sendLogInSms: ConfirmationCodeEntity
  sendSignUpSms: ConfirmationCodeEntity
  signUp: Scalars['String']['output']
  signUpForeign: Scalars['String']['output']
}

export type AuthMutationsConfirmAuthPhoneNumberArgs = {
  input: ConfirmPhoneNumberInput
}

export type AuthMutationsConfirmPhoneNumberArgs = {
  input: ConfirmPhoneNumberInput
}

export type AuthMutationsLogInAsDemoUserArgs = {
  input: LogInAsDemoUserInput
}

export type AuthMutationsLogInBySmsCodeArgs = {
  input: LogInBySmsCodeInput
}

export type AuthMutationsLoginAsUserArgs = {
  input: AsUserLoginInput
}

export type AuthMutationsRequestPhoneNumberConfirmationCodeArgs = {
  input: RequestPhoneNumberConfirmationCodeInput
}

export type AuthMutationsSendLogInSmsArgs = {
  input: SendLogInSmsInput
}

export type AuthMutationsSendSignUpSmsArgs = {
  input: SendSignUpSmsInput
}

export type AuthMutationsSignUpArgs = {
  input: AuthSignUpInput
}

export type AuthMutationsSignUpForeignArgs = {
  input: SignUpForeignInput
}

export type AuthSignUpInput = {
  companyType: CompanyType
  confirmationCodeId: Scalars['String']['input']
  inn: Scalars['String']['input']
  name: Scalars['String']['input']
}

export type BankAccountActionsEntity = {
  __typename?: 'BankAccountActionsEntity'
  delete: Scalars['Boolean']['output']
}

export type BankAccountCreateMutationsInput = {
  bankName: Scalars['String']['input']
  bic: Scalars['String']['input']
  companyId: Scalars['Int']['input']
  correspondentAccount: Scalars['String']['input']
  paymentAccount: Scalars['String']['input']
}

export type BankAccountEntity = {
  __typename?: 'BankAccountEntity'
  actions: BankAccountActionsEntity
  bankName: Scalars['String']['output']
  bic: Scalars['String']['output']
  companyId?: Maybe<Scalars['Int']['output']>
  correspondentAccount: Scalars['String']['output']
  createdAt: Scalars['DateTime']['output']
  id: Scalars['Int']['output']
  paymentAccount: Scalars['String']['output']
}

export type BankAccountMutations = {
  __typename?: 'BankAccountMutations'
  delete: Scalars['Int']['output']
}

export type CompanyActionsEntity = {
  __typename?: 'CompanyActionsEntity'
  addUser: Scalars['Boolean']['output']
  delete: Scalars['Boolean']['output']
  deleteUser: Scalars['Boolean']['output']
  sendOnModeration: Scalars['Boolean']['output']
  updateGeneralInfo: Scalars['Boolean']['output']
  updateLegalInfo: Scalars['Boolean']['output']
}

export type CompanyCreateMutationsInput = {
  inn: Scalars['String']['input']
  type: CompanyType
}

export type CompanyEntity = {
  __typename?: 'CompanyEntity'
  actions: CompanyActionsEntity
  actualAddress?: Maybe<Scalars['String']['output']>
  additionalInfo?: Maybe<Scalars['String']['output']>
  aicCharterMember?: Maybe<Scalars['Boolean']['output']>
  bankAccounts: Array<BankAccountEntity>
  codeOkved?: Maybe<Scalars['String']['output']>
  countryName: CountryName
  directorName?: Maybe<Scalars['String']['output']>
  id: Scalars['Int']['output']
  inn: Scalars['String']['output']
  kpp?: Maybe<Scalars['String']['output']>
  legalAddress?: Maybe<Scalars['String']['output']>
  legalForm: Scalars['String']['output']
  legalName: Scalars['String']['output']
  logoUrl?: Maybe<Scalars['String']['output']>
  ogrn?: Maybe<Scalars['String']['output']>
  okved?: Maybe<Scalars['String']['output']>
  phoneNumber?: Maybe<Scalars['String']['output']>
  rejectionReason?: Maybe<Scalars['String']['output']>
  signatory?: Maybe<Scalars['String']['output']>
  signatoryLegalBasis?: Maybe<SignatoryLegalBasis>
  signatoryPosition?: Maybe<Scalars['String']['output']>
  state: CompanyStateEntity
  type: CompanyType
  useNds?: Maybe<Scalars['Boolean']['output']>
  users: Array<UserEntity>
  verificationRequirements: CompanyVerificationRequirementsEntity
  verificationStatus: CompanyVerificationStatus
  verifiedAt?: Maybe<Scalars['DateTime']['output']>
  website?: Maybe<Scalars['String']['output']>
}

export type CompanyMutations = {
  __typename?: 'CompanyMutations'
  addUsers: Array<UserEntity>
  delete: Scalars['Int']['output']
  deleteUser: UserEntity
  sendOnModeration: Scalars['Boolean']['output']
  updateGeneralInfo: CompanyEntity
  updateLegalInfo: CompanyEntity
  updateLogo: CompanyEntity
}

export type CompanyMutationsAddUsersArgs = {
  userIds: Array<Scalars['Int']['input']>
}

export type CompanyMutationsDeleteUserArgs = {
  userId: Scalars['Int']['input']
}

export type CompanyMutationsUpdateGeneralInfoArgs = {
  input: CompanyUpdateGeneralInfoInput
}

export type CompanyMutationsUpdateLegalInfoArgs = {
  input: CompanyUpdateLegalInfoInput
}

export type CompanyMutationsUpdateLogoArgs = {
  input: CompanyUpdateLogoInput
}

export type CompanyStateEntity = {
  __typename?: 'CompanyStateEntity'
  canUsePriorityCompanies: Scalars['Boolean']['output']
  companyVerifiedMessageClosed: Scalars['Boolean']['output']
  firstFreightOrderCreated: Scalars['Boolean']['output']
}

export enum CompanyType {
  Buyer = 'BUYER',
  Carrier = 'CARRIER',
  DemoCompany = 'DEMO_COMPANY',
  Seller = 'SELLER',
  Trader = 'TRADER',
}

export type CompanyUpdateGeneralInfoInput = {
  additionalInfo?: InputMaybe<Scalars['String']['input']>
  phoneNumber?: InputMaybe<Scalars['String']['input']>
  website?: InputMaybe<Scalars['String']['input']>
}

export type CompanyUpdateLegalInfoInput = {
  actualAddress: Scalars['String']['input']
  aicCharterMember?: InputMaybe<Scalars['Boolean']['input']>
  directorName: Scalars['String']['input']
  kpp: Scalars['String']['input']
  legalAddress: Scalars['String']['input']
  signatory?: InputMaybe<Scalars['String']['input']>
  signatoryLegalBasis?: InputMaybe<SignatoryLegalBasis>
  signatoryPosition?: InputMaybe<Scalars['String']['input']>
  useNds: Scalars['Boolean']['input']
}

export type CompanyUpdateLogoInput = {
  logoFileKey?: InputMaybe<Scalars['String']['input']>
}

export type CompanyVerificationRequirementsEntity = {
  __typename?: 'CompanyVerificationRequirementsEntity'
  bankAccount?: Maybe<Scalars['Boolean']['output']>
  documents?: Maybe<Scalars['Boolean']['output']>
  requisites?: Maybe<Scalars['Boolean']['output']>
}

export enum CompanyVerificationStatus {
  NotVerified = 'NOT_VERIFIED',
  OnModeration = 'ON_MODERATION',
  Rejected = 'REJECTED',
  Verified = 'VERIFIED',
}

export type ConfirmNewPhoneNumberInput = {
  confirmationCode: Scalars['String']['input']
  confirmationCodeId: Scalars['String']['input']
}

export type ConfirmPhoneNumberInput = {
  confirmationCode: Scalars['String']['input']
  confirmationCodeId: Scalars['String']['input']
}

export type ConfirmationCodeEntity = {
  __typename?: 'ConfirmationCodeEntity'
  confirmedAt?: Maybe<Scalars['DateTime']['output']>
  expiredAt: Scalars['DateTime']['output']
  id: Scalars['String']['output']
  isConfirmed: Scalars['Boolean']['output']
  numberOfAttempts: Scalars['Int']['output']
  phoneNumber: Scalars['String']['output']
  purpose: ConfirmationCodePurpose
}

export enum ConfirmationCodePurpose {
  ChangePhoneNumber = 'CHANGE_PHONE_NUMBER',
  Login = 'LOGIN',
  SignUp = 'SIGN_UP',
}

export enum CountryName {
  Armenia = 'ARMENIA',
  Belarus = 'BELARUS',
  Brazil = 'BRAZIL',
  China = 'CHINA',
  Cuba = 'CUBA',
  India = 'INDIA',
  Kazakhstan = 'KAZAKHSTAN',
  Kyrgyzstan = 'KYRGYZSTAN',
  Moldova = 'MOLDOVA',
  Russia = 'RUSSIA',
  SouthAfrica = 'SOUTH_AFRICA',
  Tajikistan = 'TAJIKISTAN',
  Uzbekistan = 'UZBEKISTAN',
}

export type CreateForeignCompanyInput = {
  countryName: CountryName
  inn: Scalars['String']['input']
  legalAddress: Scalars['String']['input']
  legalForm: Scalars['String']['input']
  legalName: Scalars['String']['input']
  type: CompanyType
}

export type CreateFreightInput = {
  bigBagsCount?: InputMaybe<Scalars['Int']['input']>
  packingType: FreightPackingType
  packingTypeCustom?: InputMaybe<Scalars['String']['input']>
  productTypeId: Scalars['Int']['input']
  weightKg: Scalars['Int']['input']
}

export type CreateRoadTransportParametersInput = {
  additionalDetails?: InputMaybe<Scalars['String']['input']>
  distanceToScalesM?: InputMaybe<Scalars['Int']['input']>
  hasParking?: InputMaybe<Scalars['Boolean']['input']>
  hasScales?: InputMaybe<Scalars['Boolean']['input']>
  hasWeighingAutomation?: InputMaybe<Scalars['Boolean']['input']>
  loadingThroughput?: InputMaybe<Scalars['Int']['input']>
  loadingTypeCustom?: InputMaybe<Scalars['String']['input']>
  loadingTypes: Array<LoadingTransportType>
  roadSurfaceQuality?: InputMaybe<RoadSurfaceQuality>
  roadSurfaceType?: InputMaybe<RoadSurfaceType>
  scalesWeightLimitT?: InputMaybe<Scalars['Int']['input']>
  unloadingThroughput?: InputMaybe<Scalars['Int']['input']>
  unloadingTypeCustom?: InputMaybe<Scalars['String']['input']>
  unloadingTypes: Array<UnloadingTransportType>
  vehicleClearanceMinCm?: InputMaybe<Scalars['Int']['input']>
  vehicleHeightMaxM?: InputMaybe<Scalars['Int']['input']>
  vehicleLengthMaxM?: InputMaybe<Scalars['Int']['input']>
}

export type CreateRoutePointInput = {
  address?: InputMaybe<Scalars['String']['input']>
  elevatorId?: InputMaybe<Scalars['Int']['input']>
  freight?: InputMaybe<CreateFreightInput>
  roadTransportParameters?: InputMaybe<CreateRoadTransportParametersInput>
  type: RoutePointType
  warehouseId?: InputMaybe<Scalars['Int']['input']>
}

export enum DayOfWeek {
  Friday = 'FRIDAY',
  Monday = 'MONDAY',
  Saturday = 'SATURDAY',
  Sunday = 'SUNDAY',
  Thursday = 'THURSDAY',
  Tuesday = 'TUESDAY',
  Wednesday = 'WEDNESDAY',
}

export type DocumentFileEntity = {
  __typename?: 'DocumentFileEntity'
  id: Scalars['Int']['output']
  mimetype: Scalars['String']['output']
  name: Scalars['String']['output']
  size: Scalars['Int']['output']
  source: Scalars['String']['output']
  status: DocumentPartStatus
}

export type DocumentFileUpdateInput = {
  rejectionNote?: InputMaybe<Scalars['String']['input']>
  status: DocumentPartStatus
}

export type DocumentFilesCreateInput = {
  fileKeys: Array<Scalars['String']['input']>
}

export type DocumentInstanceEntity = {
  __typename?: 'DocumentInstanceEntity'
  createdAt: Scalars['DateTime']['output']
  files: Array<DocumentFileEntity>
  formedAt?: Maybe<Scalars['DateTime']['output']>
  id: Scalars['Int']['output']
  number?: Maybe<Scalars['String']['output']>
  rejectionNote?: Maybe<Scalars['String']['output']>
  status: DocumentPartStatus
}

export enum DocumentPartStatus {
  Confirmed = 'CONFIRMED',
  Rejected = 'REJECTED',
  Unchecked = 'UNCHECKED',
}

export type DocumentsArchiveFilterInput = {
  documentIds?: InputMaybe<Array<Scalars['Int']['input']>>
}

export type DriverCreateInput = {
  additionalDetails?: InputMaybe<Scalars['String']['input']>
  birthDate?: InputMaybe<Scalars['DateTime']['input']>
  licenseExpiredAt?: InputMaybe<Scalars['DateTime']['input']>
  licenseIssuedAt?: InputMaybe<Scalars['DateTime']['input']>
  licenseNumber?: InputMaybe<Scalars['String']['input']>
  userId: Scalars['Int']['input']
}

export type DriverDataEntity = {
  __typename?: 'DriverDataEntity'
  additionalDetails?: Maybe<Scalars['String']['output']>
  birthDate?: Maybe<Scalars['DateTime']['output']>
  licenseExpiredAt?: Maybe<Scalars['DateTime']['output']>
  licenseIssuedAt?: Maybe<Scalars['DateTime']['output']>
  licenseNumber?: Maybe<Scalars['String']['output']>
}

export type DriverEntity = {
  __typename?: 'DriverEntity'
  driverData: DriverDataEntity
  id: Scalars['Int']['output']
  name: Scalars['String']['output']
  phoneNumber: Scalars['String']['output']
  transports: Array<TransportEntity>
}

export type DriverFilterInput = {
  companyIds?: InputMaybe<Array<Scalars['Int']['input']>>
  search?: InputMaybe<Scalars['String']['input']>
  transportIds?: InputMaybe<Array<Scalars['Int']['input']>>
  userIds?: InputMaybe<Array<Scalars['Int']['input']>>
}

export type DriverMutations = {
  __typename?: 'DriverMutations'
  deleteTransport: DriverEntity
  setTransport: DriverEntity
  updateDriver: DriverEntity
}

export type DriverMutationsDeleteTransportArgs = {
  transportId: Scalars['Int']['input']
}

export type DriverMutationsSetTransportArgs = {
  transportId: Scalars['Int']['input']
}

export type DriverMutationsUpdateDriverArgs = {
  input: DriverUpdateInput
}

export type DriverUpdateInput = {
  additionalDetails?: InputMaybe<Scalars['String']['input']>
  birthDate?: InputMaybe<Scalars['DateTime']['input']>
  licenseExpiredAt?: InputMaybe<Scalars['DateTime']['input']>
  licenseIssuedAt?: InputMaybe<Scalars['DateTime']['input']>
  licenseNumber?: InputMaybe<Scalars['String']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  phoneNumber?: InputMaybe<Scalars['String']['input']>
}

export type DriverUserCreateInput = {
  additionalDetails?: InputMaybe<Scalars['String']['input']>
  birthDate?: InputMaybe<Scalars['DateTime']['input']>
  licenseExpiredAt?: InputMaybe<Scalars['DateTime']['input']>
  licenseIssuedAt?: InputMaybe<Scalars['DateTime']['input']>
  licenseNumber?: InputMaybe<Scalars['String']['input']>
  name: Scalars['String']['input']
  phoneNumber: Scalars['String']['input']
}

export type DriversEntity = {
  __typename?: 'DriversEntity'
  drivers: Array<DriverEntity>
  pagination: PaginationEntity
}

export type ElevatorCreateInput = {
  additionalServiceWithNds?: InputMaybe<Scalars['Boolean']['input']>
  address: Scalars['String']['input']
  anotherServices?: InputMaybe<Scalars['String']['input']>
  autoAdditionalInfo?: InputMaybe<Scalars['String']['input']>
  autoClearance?: InputMaybe<Scalars['Int']['input']>
  autoHeight?: InputMaybe<Scalars['Int']['input']>
  autoLength?: InputMaybe<Scalars['Int']['input']>
  autoReceptionSpeed?: InputMaybe<Scalars['Int']['input']>
  autoShipmentPrice?: InputMaybe<Scalars['Float']['input']>
  autoUnloadSpeed?: InputMaybe<Scalars['Int']['input']>
  censusPrice?: InputMaybe<Scalars['Float']['input']>
  cleaningPrice?: InputMaybe<Scalars['Float']['input']>
  closingTime?: InputMaybe<Scalars['DateTime']['input']>
  companyId?: InputMaybe<Scalars['Int']['input']>
  dryingPrice?: InputMaybe<Scalars['Float']['input']>
  gateHeight?: InputMaybe<Scalars['Int']['input']>
  hasAutoStorage?: InputMaybe<Scalars['Boolean']['input']>
  hasDigitalQueue?: InputMaybe<Scalars['Boolean']['input']>
  hasLab?: InputMaybe<Scalars['Boolean']['input']>
  hasRailScales?: InputMaybe<Scalars['Boolean']['input']>
  hasRailStock?: InputMaybe<Scalars['Boolean']['input']>
  hasRailWay?: InputMaybe<Scalars['Boolean']['input']>
  hasScales?: InputMaybe<Scalars['Boolean']['input']>
  hasTecEco?: InputMaybe<Scalars['Boolean']['input']>
  loadingTransport?: InputMaybe<Array<LoadingTransportType>>
  mixingPrice?: InputMaybe<Scalars['Float']['input']>
  name: Scalars['String']['input']
  openingTime?: InputMaybe<Scalars['DateTime']['input']>
  paperworkPrice?: InputMaybe<Scalars['Float']['input']>
  productOnElevator?: InputMaybe<Array<Scalars['String']['input']>>
  railAdditionalInfo?: InputMaybe<Scalars['String']['input']>
  railLoadSpeed?: InputMaybe<Scalars['Int']['input']>
  receptionPrice?: InputMaybe<Scalars['Float']['input']>
  scalesLength?: InputMaybe<Scalars['Int']['input']>
  scalesWeightLimit?: InputMaybe<Scalars['Int']['input']>
  stationName?: InputMaybe<Scalars['String']['input']>
  storageVolume?: InputMaybe<Scalars['Int']['input']>
  storingPrice?: InputMaybe<Scalars['Float']['input']>
  trainShipmentPrice?: InputMaybe<Scalars['Float']['input']>
  transport?: InputMaybe<Array<TransportMode>>
  type: Array<ElevatorType>
  unloadingTransport?: InputMaybe<Array<UnloadingTransportType>>
  waitingArea?: InputMaybe<Scalars['Int']['input']>
  workingDaysOfWeek?: InputMaybe<Array<DayOfWeek>>
}

export type ElevatorEntity = {
  __typename?: 'ElevatorEntity'
  additionalServiceWithNds?: Maybe<Scalars['Boolean']['output']>
  address: Scalars['String']['output']
  anotherServices?: Maybe<Scalars['String']['output']>
  autoAdditionalInfo?: Maybe<Scalars['String']['output']>
  autoClearance?: Maybe<Scalars['Int']['output']>
  autoHeight?: Maybe<Scalars['Int']['output']>
  autoLength?: Maybe<Scalars['Int']['output']>
  autoReceptionSpeed?: Maybe<Scalars['Int']['output']>
  autoShipmentPrice?: Maybe<Scalars['Float']['output']>
  autoUnloadSpeed?: Maybe<Scalars['Int']['output']>
  buyOfferCount: Scalars['Int']['output']
  censusPrice?: Maybe<Scalars['Float']['output']>
  cleaningPrice?: Maybe<Scalars['Float']['output']>
  closingTime?: Maybe<Scalars['DateTime']['output']>
  createdAt: Scalars['DateTime']['output']
  dryingPrice?: Maybe<Scalars['Float']['output']>
  gateHeight?: Maybe<Scalars['Int']['output']>
  hasAutoStorage?: Maybe<Scalars['Boolean']['output']>
  hasDigitalQueue: Scalars['Boolean']['output']
  hasLab: Scalars['Boolean']['output']
  hasRailScales?: Maybe<Scalars['Boolean']['output']>
  hasRailStock?: Maybe<Scalars['Boolean']['output']>
  hasRailWay?: Maybe<Scalars['Boolean']['output']>
  hasScales?: Maybe<Scalars['Boolean']['output']>
  hasTecEco?: Maybe<Scalars['Boolean']['output']>
  id: Scalars['Int']['output']
  isCreationRequest: Scalars['Boolean']['output']
  isDeleted: Scalars['Boolean']['output']
  loadingTransport?: Maybe<Array<LoadingTransportType>>
  mixingPrice?: Maybe<Scalars['Float']['output']>
  name: Scalars['String']['output']
  openingTime?: Maybe<Scalars['DateTime']['output']>
  paperworkPrice?: Maybe<Scalars['Float']['output']>
  productOnElevator?: Maybe<Array<Scalars['String']['output']>>
  railAdditionalInfo?: Maybe<Scalars['String']['output']>
  railLoadSpeed?: Maybe<Scalars['Int']['output']>
  receptionPrice?: Maybe<Scalars['Float']['output']>
  scalesLength?: Maybe<Scalars['Int']['output']>
  scalesWeightLimit?: Maybe<Scalars['Int']['output']>
  sellOfferCount: Scalars['Int']['output']
  stationName?: Maybe<Scalars['String']['output']>
  storageVolume?: Maybe<Scalars['Int']['output']>
  storingPrice?: Maybe<Scalars['Float']['output']>
  trainShipmentPrice?: Maybe<Scalars['Float']['output']>
  transport?: Maybe<Array<TransportMode>>
  type: Array<ElevatorType>
  unloadingTransport?: Maybe<Array<UnloadingTransportType>>
  waitingArea?: Maybe<Scalars['Int']['output']>
  workingDaysOfWeek: Array<DayOfWeek>
}

export type ElevatorMutations = {
  __typename?: 'ElevatorMutations'
  approveCreationRequest: ElevatorEntity
}

export enum ElevatorType {
  Port = 'PORT',
  Recast = 'RECAST',
  Transshipment = 'TRANSSHIPMENT',
}

export enum EntityModel {
  Auction = 'AUCTION',
  BankAccount = 'BANK_ACCOUNT',
  Company = 'COMPANY',
  Deal = 'DEAL',
  Elevator = 'ELEVATOR',
  ElevatorOffer = 'ELEVATOR_OFFER',
  Offer = 'OFFER',
  Order = 'ORDER',
  User = 'USER',
}

export type FileEntity = {
  __typename?: 'FileEntity'
  mimetype: Scalars['String']['output']
  name: Scalars['String']['output']
  size: Scalars['Int']['output']
  source: Scalars['String']['output']
}

export type FreightDealDocumentCreateInput = {
  fileKeys: Array<Scalars['String']['input']>
  formedAt?: InputMaybe<Scalars['DateTime']['input']>
  freightDealId: Scalars['Int']['input']
  number?: InputMaybe<Scalars['String']['input']>
  type: FreightDealDocumentType
}

export type FreightDealDocumentEntity = {
  __typename?: 'FreightDealDocumentEntity'
  instances: Array<DocumentInstanceEntity>
  type: FreightDealDocumentType
}

export type FreightDealDocumentFileMutations = {
  __typename?: 'FreightDealDocumentFileMutations'
  delete: Scalars['Int']['output']
  update: Array<FreightDealDocumentEntity>
}

export type FreightDealDocumentFileMutationsUpdateArgs = {
  input: DocumentFileUpdateInput
}

export type FreightDealDocumentFilterInput = {
  types?: InputMaybe<Array<FreightDealDocumentType>>
}

export type FreightDealDocumentMutations = {
  __typename?: 'FreightDealDocumentMutations'
  addFiles: Array<FreightDealDocumentEntity>
  delete: Scalars['Int']['output']
}

export type FreightDealDocumentMutationsAddFilesArgs = {
  input: DocumentFilesCreateInput
}

export enum FreightDealDocumentType {
  ActOfCompletedWork = 'ACT_OF_COMPLETED_WORK',
  ActOfCompletedWorkSigned = 'ACT_OF_COMPLETED_WORK_SIGNED',
  BillOfLadingForLoading = 'BILL_OF_LADING_FOR_LOADING',
  BillOfLadingForUnloading = 'BILL_OF_LADING_FOR_UNLOADING',
  CarriageContract = 'CARRIAGE_CONTRACT',
  CarriageContractAnnex = 'CARRIAGE_CONTRACT_ANNEX',
  CarriageContractAnnexSigned = 'CARRIAGE_CONTRACT_ANNEX_SIGNED',
  CarriageContractSigned = 'CARRIAGE_CONTRACT_SIGNED',
  ConsignmentNoteForLoading = 'CONSIGNMENT_NOTE_FOR_LOADING',
  ConsignmentNoteForUnloading = 'CONSIGNMENT_NOTE_FOR_UNLOADING',
  RegisterOfActualLoading = 'REGISTER_OF_ACTUAL_LOADING',
  RegisterOfActualUnloading = 'REGISTER_OF_ACTUAL_UNLOADING',
  TransportRegisterForLoading = 'TRANSPORT_REGISTER_FOR_LOADING',
  TransportRegisterForUnloading = 'TRANSPORT_REGISTER_FOR_UNLOADING',
}

export type FreightDealEntity = {
  __typename?: 'FreightDealEntity'
  additionalDetails?: Maybe<Scalars['String']['output']>
  carrier: CompanyEntity
  carrierId: Scalars['Int']['output']
  createdAt: Scalars['DateTime']['output']
  createdById: Scalars['Int']['output']
  creator: UserEntity
  customer: CompanyEntity
  customerId: Scalars['Int']['output']
  dealOrder: FreightOrderEntity
  dealOrderId: Scalars['Int']['output']
  id: Scalars['Int']['output']
  isAgreedByCarrier?: Maybe<Scalars['Boolean']['output']>
  isAgreedByCustomer?: Maybe<Scalars['Boolean']['output']>
  isRealisedByCarrier?: Maybe<Scalars['Boolean']['output']>
  isRealisedByCustomer?: Maybe<Scalars['Boolean']['output']>
  lastChangeRequests: Array<FreightOrderChangeRequestEntity>
  nextStatuses: Array<FreightDealStatus>
  order: FreightOrderEntity
  orderId: Scalars['Int']['output']
  rejectedAt?: Maybe<Scalars['DateTime']['output']>
  rejectedBy?: Maybe<UserEntity>
  rejectedById?: Maybe<Scalars['Int']['output']>
  rejectionReason?: Maybe<Scalars['String']['output']>
  releasedAt?: Maybe<Scalars['DateTime']['output']>
  startedAt?: Maybe<Scalars['DateTime']['output']>
  status: FreightDealStatus
  type: FreightDealType
  updatedAt: Scalars['DateTime']['output']
}

export type FreightDealFilterInput = {
  carrierIds?: InputMaybe<Array<Scalars['Int']['input']>>
  customerIds?: InputMaybe<Array<Scalars['Int']['input']>>
  freightDealIds?: InputMaybe<Array<Scalars['Int']['input']>>
  fromRegionIds?: InputMaybe<Array<Scalars['Int']['input']>>
  paymentTerm?: InputMaybe<FreightPaymentTerm>
  pricePerTonFrom?: InputMaybe<Scalars['Int']['input']>
  pricePerTonTo?: InputMaybe<Scalars['Int']['input']>
  priceWithNds?: InputMaybe<Scalars['Boolean']['input']>
  productTypeIds?: InputMaybe<Array<Scalars['Int']['input']>>
  search?: InputMaybe<Scalars['String']['input']>
  startedAtFrom?: InputMaybe<Scalars['DateTime']['input']>
  startedAtTo?: InputMaybe<Scalars['DateTime']['input']>
  statuses?: InputMaybe<Array<FreightDealStatus>>
  toRegionIds?: InputMaybe<Array<Scalars['Int']['input']>>
  types?: InputMaybe<Array<FreightDealType>>
  weightKgFrom?: InputMaybe<Scalars['Int']['input']>
  weightKgTo?: InputMaybe<Scalars['Int']['input']>
}

export type FreightDealMutations = {
  __typename?: 'FreightDealMutations'
  updateStatus: FreightDealEntity
}

export type FreightDealMutationsUpdateStatusArgs = {
  input: FreightDealStatusUpdateInput
}

export enum FreightDealStatus {
  Agreement = 'AGREEMENT',
  OnModeration = 'ON_MODERATION',
  Realised = 'REALISED',
  Rejected = 'REJECTED',
  RejectedAfterModeration = 'REJECTED_AFTER_MODERATION',
  Shipment = 'SHIPMENT',
}

export type FreightDealStatusUpdateInput = {
  rejectionReason?: InputMaybe<Scalars['String']['input']>
  status: FreightDealStatus
}

export enum FreightDealType {
  Bet = 'BET',
  Response = 'RESPONSE',
}

export type FreightDealsEntity = {
  __typename?: 'FreightDealsEntity'
  freightDeals: Array<FreightDealEntity>
  pagination: PaginationEntity
}

export type FreightEntity = {
  __typename?: 'FreightEntity'
  bigBagsCount?: Maybe<Scalars['Int']['output']>
  createdAt: Scalars['DateTime']['output']
  id: Scalars['Int']['output']
  packingType: FreightPackingType
  packingTypeCustom?: Maybe<Scalars['String']['output']>
  productType: ProductTypeEntity
  productTypeId: Scalars['Int']['output']
  updatedAt: Scalars['DateTime']['output']
  weightKg: Scalars['Int']['output']
}

export type FreightOrderBetCreateInput = {
  additionalDetails?: InputMaybe<Scalars['String']['input']>
  carrierId: Scalars['Int']['input']
  freightOrderId: Scalars['Int']['input']
  paymentTerm?: InputMaybe<FreightPaymentTerm>
  pricePerTon: Scalars['Int']['input']
  priceWithNds: Scalars['Boolean']['input']
  startedAt?: InputMaybe<Scalars['DateTime']['input']>
  weightKg: Scalars['Int']['input']
}

export type FreightOrderChangeRequestBlockEntity = {
  __typename?: 'FreightOrderChangeRequestBlockEntity'
  changeRequests: Array<FreightOrderChangeRequestEntity>
  createdAt: Scalars['DateTime']['output']
  id: Scalars['Int']['output']
  type: FreightOrderChangeRequestBlockType
  updatedAt: Scalars['DateTime']['output']
}

export type FreightOrderChangeRequestBlockFilterInput = {
  types?: InputMaybe<Array<FreightOrderChangeRequestBlockType>>
}

export enum FreightOrderChangeRequestBlockType {
  Freight = 'FREIGHT',
  Price = 'PRICE',
  Route = 'ROUTE',
  TransportRegistry = 'TRANSPORT_REGISTRY',
}

export type FreightOrderChangeRequestCreateInput = {
  additionalDetails?: InputMaybe<Scalars['String']['input']>
  changedFields: Array<FreightOrderFieldChangeCreateInput>
  type: FreightOrderChangeRequestType
}

export type FreightOrderChangeRequestEntity = {
  __typename?: 'FreightOrderChangeRequestEntity'
  additionalDetails?: Maybe<Scalars['String']['output']>
  changedFields: Array<FreightOrderFieldChangeEntity>
  createdAt: Scalars['DateTime']['output']
  createdByCompany: CompanyEntity
  createdByCompanyId: Scalars['Int']['output']
  createdByUser: UserEntity
  createdByUserId: Scalars['Int']['output']
  endedAt?: Maybe<Scalars['DateTime']['output']>
  endedByCompany?: Maybe<CompanyEntity>
  endedByCompanyId?: Maybe<Scalars['Int']['output']>
  endedByUser?: Maybe<UserEntity>
  endedByUserId?: Maybe<Scalars['Int']['output']>
  id: Scalars['Int']['output']
  isViewedByCounterparty: Scalars['Boolean']['output']
  nextStatuses: Array<FreightOrderChangeRequestStatus>
  status: FreightOrderChangeRequestStatus
  type: FreightOrderChangeRequestType
  updatedAt: Scalars['DateTime']['output']
}

export type FreightOrderChangeRequestMutations = {
  __typename?: 'FreightOrderChangeRequestMutations'
  setViewed: FreightOrderChangeRequestEntity
  updateStatus: FreightOrderChangeRequestEntity
}

export type FreightOrderChangeRequestMutationsUpdateStatusArgs = {
  input: FreightOrderChangeRequestStatusUpdateInput
}

export enum FreightOrderChangeRequestStatus {
  Accepted = 'ACCEPTED',
  CounterOffer = 'COUNTER_OFFER',
  OnModeration = 'ON_MODERATION',
  Rejected = 'REJECTED',
}

export type FreightOrderChangeRequestStatusUpdateInput = {
  status: FreightOrderChangeRequestStatus
}

export enum FreightOrderChangeRequestType {
  Distance = 'DISTANCE',
  PackingType = 'PACKING_TYPE',
  PaymentTerm = 'PAYMENT_TERM',
  PricePerTon = 'PRICE_PER_TON',
  TransportRegistry = 'TRANSPORT_REGISTRY',
  Weight = 'WEIGHT',
}

export type FreightOrderChangeRequestsCreateInput = {
  changeRequests: Array<FreightOrderChangeRequestCreateInput>
  orderId: Scalars['Int']['input']
}

export type FreightOrderCreateInput = {
  additionalDetails?: InputMaybe<Scalars['String']['input']>
  advancePercent?: InputMaybe<Scalars['Int']['input']>
  companyId: Scalars['Int']['input']
  contactPerson: Scalars['String']['input']
  contactPhoneNumber: Scalars['String']['input']
  deferredDaysCount?: InputMaybe<Scalars['Int']['input']>
  endedAt?: InputMaybe<Scalars['DateTime']['input']>
  isAuction: Scalars['Boolean']['input']
  paymentTerm: FreightPaymentTerm
  pricePerTonWithoutNds: Scalars['Int']['input']
  readinessType: FreightReadinessType
  routePoints: Array<CreateRoutePointInput>
  startDaysCount?: InputMaybe<Scalars['Int']['input']>
  startedAt?: InputMaybe<Scalars['DateTime']['input']>
  transportBodyTypeIds?: InputMaybe<Array<Scalars['Int']['input']>>
  transportFormatTypes?: InputMaybe<Array<TransportFormatType>>
}

export type FreightOrderDocumentCreateInput = {
  fileKeys: Array<Scalars['String']['input']>
  formedAt?: InputMaybe<Scalars['DateTime']['input']>
  freightOrderId: Scalars['Int']['input']
  number?: InputMaybe<Scalars['String']['input']>
  type: FreightOrderDocumentType
}

export type FreightOrderDocumentEntity = {
  __typename?: 'FreightOrderDocumentEntity'
  instances: Array<DocumentInstanceEntity>
  type: FreightOrderDocumentType
}

export type FreightOrderDocumentFileMutations = {
  __typename?: 'FreightOrderDocumentFileMutations'
  delete: Scalars['Int']['output']
  update: Array<FreightOrderDocumentEntity>
}

export type FreightOrderDocumentFileMutationsUpdateArgs = {
  input: DocumentFileUpdateInput
}

export type FreightOrderDocumentFilterInput = {
  types?: InputMaybe<Array<FreightOrderDocumentType>>
}

export type FreightOrderDocumentMutations = {
  __typename?: 'FreightOrderDocumentMutations'
  addFiles: Array<FreightOrderDocumentEntity>
  delete: Scalars['Int']['output']
}

export type FreightOrderDocumentMutationsAddFilesArgs = {
  input: DocumentFilesCreateInput
}

export enum FreightOrderDocumentType {
  CarriageContractTemplate = 'CARRIAGE_CONTRACT_TEMPLATE',
}

export type FreightOrderEntity = {
  __typename?: 'FreightOrderEntity'
  additionalDetails?: Maybe<Scalars['String']['output']>
  advancePercent?: Maybe<Scalars['Int']['output']>
  availableWeightKg: Scalars['Int']['output']
  canCreateResponse: Scalars['Boolean']['output']
  companyId: Scalars['Int']['output']
  contactPerson: Scalars['String']['output']
  contactPhoneNumber: Scalars['String']['output']
  createdAt: Scalars['DateTime']['output']
  deferredDaysCount?: Maybe<Scalars['Int']['output']>
  deletedAt?: Maybe<Scalars['DateTime']['output']>
  endedAt?: Maybe<Scalars['DateTime']['output']>
  id: Scalars['Int']['output']
  isAuction: Scalars['Boolean']['output']
  paymentTerm: FreightPaymentTerm
  pricePerTonWithNds: Scalars['Int']['output']
  pricePerTonWithoutNds: Scalars['Int']['output']
  priceWithNds: Scalars['Int']['output']
  priceWithoutNds: Scalars['Int']['output']
  readinessType: FreightReadinessType
  responsesStatistics?: Maybe<FreightOrderResponsesStatisticsEntity>
  route: RouteEntity
  routeId: Scalars['Int']['output']
  selectedWeightKg: Scalars['Int']['output']
  startDaysCount?: Maybe<Scalars['Int']['output']>
  startedAt?: Maybe<Scalars['DateTime']['output']>
  transportBodyTypes: Array<TransportBodyTypeEntity>
  transportFormatTypes: Array<TransportFormatType>
  updatedAt: Scalars['DateTime']['output']
  weightKg: Scalars['Int']['output']
}

export type FreightOrderFieldChangeCreateInput = {
  fieldName: FreightOrderFieldName
  value: Scalars['String']['input']
}

export type FreightOrderFieldChangeEntity = {
  __typename?: 'FreightOrderFieldChangeEntity'
  fieldName: FreightOrderFieldName
  value: Scalars['String']['output']
}

export enum FreightOrderFieldName {
  AdvancePercent = 'ADVANCE_PERCENT',
  BigBagsCount = 'BIG_BAGS_COUNT',
  CanUpdateTransportRegistryInPast = 'CAN_UPDATE_TRANSPORT_REGISTRY_IN_PAST',
  DeferredDaysCount = 'DEFERRED_DAYS_COUNT',
  Distance = 'DISTANCE',
  PackingType = 'PACKING_TYPE',
  PackingTypeCustom = 'PACKING_TYPE_CUSTOM',
  PaymentTerm = 'PAYMENT_TERM',
  PricePerTonWithoutNds = 'PRICE_PER_TON_WITHOUT_NDS',
  Weight = 'WEIGHT',
}

export type FreightOrderFilterInput = {
  companyIds?: InputMaybe<Array<Scalars['Int']['input']>>
  freightOrderIds?: InputMaybe<Array<Scalars['Int']['input']>>
  fromRegionIds?: InputMaybe<Array<Scalars['Int']['input']>>
  paymentTerm?: InputMaybe<FreightPaymentTerm>
  pricePerTonFrom?: InputMaybe<Scalars['Int']['input']>
  pricePerTonTo?: InputMaybe<Scalars['Int']['input']>
  priceWithNds?: InputMaybe<Scalars['Boolean']['input']>
  productTypeIds?: InputMaybe<Array<Scalars['Int']['input']>>
  search?: InputMaybe<Scalars['String']['input']>
  startedAtFrom?: InputMaybe<Scalars['DateTime']['input']>
  startedAtTo?: InputMaybe<Scalars['DateTime']['input']>
  toRegionIds?: InputMaybe<Array<Scalars['Int']['input']>>
  weightKgFrom?: InputMaybe<Scalars['Int']['input']>
  weightKgTo?: InputMaybe<Scalars['Int']['input']>
}

export type FreightOrderResponseCreateInput = {
  additionalDetails?: InputMaybe<Scalars['String']['input']>
  carrierId: Scalars['Int']['input']
  freightOrderId: Scalars['Int']['input']
  weightKg: Scalars['Int']['input']
}

export type FreightOrderResponseEntity = {
  __typename?: 'FreightOrderResponseEntity'
  additionalDetails?: Maybe<Scalars['String']['output']>
  createdAt: Scalars['DateTime']['output']
  dealOrder: FreightOrderResponseOrderEntity
  id: Scalars['Int']['output']
  nextStatuses: Array<FreightDealStatus>
  order: FreightOrderEntity
  orderId: Scalars['Int']['output']
  status: FreightDealStatus
  type: FreightDealType
}

export type FreightOrderResponseFilterInput = {
  carrierIds?: InputMaybe<Array<Scalars['Int']['input']>>
  customerIds?: InputMaybe<Array<Scalars['Int']['input']>>
  freightDealIds?: InputMaybe<Array<Scalars['Int']['input']>>
  freightOrderIds?: InputMaybe<Array<Scalars['Int']['input']>>
  productTypeIds?: InputMaybe<Array<Scalars['Int']['input']>>
  regionIds?: InputMaybe<Array<Scalars['Int']['input']>>
  search?: InputMaybe<Scalars['String']['input']>
  statuses?: InputMaybe<Array<FreightDealStatus>>
  types?: InputMaybe<Array<FreightDealType>>
}

export type FreightOrderResponseMutations = {
  __typename?: 'FreightOrderResponseMutations'
  updateStatus: FreightOrderResponseEntity
}

export type FreightOrderResponseMutationsUpdateStatusArgs = {
  input: FreightDealStatusUpdateInput
}

export type FreightOrderResponseOrderEntity = {
  __typename?: 'FreightOrderResponseOrderEntity'
  paymentTerm: FreightPaymentTerm
  pricePerTonWithNds: Scalars['Int']['output']
  pricePerTonWithoutNds: Scalars['Int']['output']
  priceWithNds: Scalars['Int']['output']
  priceWithoutNds: Scalars['Int']['output']
  startedAt?: Maybe<Scalars['DateTime']['output']>
  weightKg: Scalars['Int']['output']
}

export type FreightOrderResponsesCountEntity = {
  __typename?: 'FreightOrderResponsesCountEntity'
  betsCount: Scalars['Int']['output']
  responsesCount: Scalars['Int']['output']
}

export type FreightOrderResponsesEntity = {
  __typename?: 'FreightOrderResponsesEntity'
  freightOrderResponses: Array<FreightOrderResponseEntity>
  pagination: PaginationEntity
}

export type FreightOrderResponsesStatisticsEntity = {
  __typename?: 'FreightOrderResponsesStatisticsEntity'
  previousDays: FreightOrderResponsesCountEntity
  today: FreightOrderResponsesCountEntity
}

export type FreightOrdersEntity = {
  __typename?: 'FreightOrdersEntity'
  freightOrders: Array<FreightOrderEntity>
  pagination: PaginationEntity
}

export enum FreightPackingType {
  BigBag = 'BIG_BAG',
  Bulk = 'BULK',
  Custom = 'CUSTOM',
}

export enum FreightPaymentTerm {
  Advance = 'ADVANCE',
  Deferred = 'DEFERRED',
}

export enum FreightReadinessType {
  Always = 'ALWAYS',
  FromDate = 'FROM_DATE',
}

export enum LoadingTransportType {
  Custom = 'CUSTOM',
  Elevator = 'ELEVATOR',
  FrontLoader = 'FRONT_LOADER',
  GrainThrower = 'GRAIN_THROWER',
  Telehandler = 'TELEHANDLER',
  VerticalPipe = 'VERTICAL_PIPE',
}

export type LogInAsDemoUserInput = {
  confirmationCodeId: Scalars['String']['input']
}

export type LogInBySmsCodeInput = {
  confirmationCode: Scalars['String']['input']
  confirmationCodeId: Scalars['String']['input']
}

export type MeActionsEntity = {
  __typename?: 'MeActionsEntity'
  createCompany: Scalars['Boolean']['output']
  createUser: Scalars['Boolean']['output']
}

export type MeEntity = {
  __typename?: 'MeEntity'
  actions: MeActionsEntity
  activeCompanyId: Scalars['Int']['output']
  avatarUrl?: Maybe<Scalars['String']['output']>
  companies: Array<CompanyEntity>
  createdAt: Scalars['DateTime']['output']
  email?: Maybe<Scalars['String']['output']>
  id: Scalars['Int']['output']
  moderator?: Maybe<UserEntity>
  name: Scalars['String']['output']
  phoneNumber: Scalars['String']['output']
  position?: Maybe<Scalars['String']['output']>
  role: UserRole
  state: MeStateEntity
}

export type MeMutations = {
  __typename?: 'MeMutations'
  confirmNewPhoneNumber: ConfirmationCodeEntity
  requestChangePhoneNumber: ConfirmationCodeEntity
  setNewPhoneNumber: ConfirmationCodeEntity
  updateAvatar: MeEntity
  updateProfile: MeEntity
  updateState: MeEntity
}

export type MeMutationsConfirmNewPhoneNumberArgs = {
  input: ConfirmNewPhoneNumberInput
}

export type MeMutationsSetNewPhoneNumberArgs = {
  input: SetNewPhoneNumberInput
}

export type MeMutationsUpdateAvatarArgs = {
  input: UpdateAvatarInput
}

export type MeMutationsUpdateProfileArgs = {
  input: MeUpdateProfileInput
}

export type MeMutationsUpdateStateArgs = {
  input: UpdateStateInput
}

export type MeStateEntity = {
  __typename?: 'MeStateEntity'
  isShowEmployeesGuide: Scalars['Boolean']['output']
  multiCompanyProfileGuideShown: Scalars['Boolean']['output']
}

export type MeUpdateProfileInput = {
  email?: InputMaybe<Scalars['String']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  position?: InputMaybe<Scalars['String']['input']>
}

export type Mutation = {
  __typename?: 'Mutation'
  auth: AuthMutations
  bankAccount: BankAccountMutations
  bankAccountCreate: BankAccountEntity
  company: CompanyMutations
  companyCreate: CompanyEntity
  companyCreateForeign: CompanyEntity
  driver: DriverMutations
  driverCreate: DriverEntity
  driverUserCreate: DriverEntity
  elevator: ElevatorMutations
  elevatorCreate: ElevatorEntity
  freightDeal: FreightDealMutations
  freightDealDocument: FreightDealDocumentMutations
  freightDealDocumentCreate: Array<FreightDealDocumentEntity>
  freightDealDocumentFile: FreightDealDocumentFileMutations
  freightOrderBetCreate: FreightOrderResponseEntity
  freightOrderChangeRequest: FreightOrderChangeRequestMutations
  freightOrderChangeRequestAcceptAll: Array<FreightOrderChangeRequestEntity>
  freightOrderChangeRequestsCreate: Array<FreightOrderChangeRequestEntity>
  freightOrderCreate: FreightOrderEntity
  freightOrderDocument: FreightOrderDocumentMutations
  freightOrderDocumentCreate: Array<FreightOrderDocumentEntity>
  freightOrderDocumentFile: FreightOrderDocumentFileMutations
  freightOrderResponse: FreightOrderResponseMutations
  freightOrderResponseCreate: FreightOrderResponseEntity
  me: MeMutations
  note: NoteMutations
  noteCreate: NoteEntity
  setActiveCompany: Scalars['String']['output']
  transport: TransportMutations
  transportCreate: TransportEntity
  transportData: TransportDataMutations
  transportDataCreate: TransportDataEntity
  transportDocument: TransportDocumentMutations
  transportDocumentCreate: Array<TransportDocumentEntity>
  transportDocumentFile: TransportDocumentFileMutations
  user: UserMutations
  userCreate: UserEntity
}

export type MutationBankAccountArgs = {
  bankAccountId: Scalars['Int']['input']
}

export type MutationBankAccountCreateArgs = {
  input: BankAccountCreateMutationsInput
}

export type MutationCompanyArgs = {
  companyId: Scalars['Int']['input']
}

export type MutationCompanyCreateArgs = {
  input: CompanyCreateMutationsInput
}

export type MutationCompanyCreateForeignArgs = {
  input: CreateForeignCompanyInput
}

export type MutationDriverArgs = {
  driverId: Scalars['Int']['input']
}

export type MutationDriverCreateArgs = {
  input: DriverCreateInput
}

export type MutationDriverUserCreateArgs = {
  input: DriverUserCreateInput
}

export type MutationElevatorArgs = {
  elevatorId: Scalars['Int']['input']
}

export type MutationElevatorCreateArgs = {
  input: ElevatorCreateInput
}

export type MutationFreightDealArgs = {
  freightDealId: Scalars['Int']['input']
}

export type MutationFreightDealDocumentArgs = {
  documentId: Scalars['Int']['input']
}

export type MutationFreightDealDocumentCreateArgs = {
  input: FreightDealDocumentCreateInput
}

export type MutationFreightDealDocumentFileArgs = {
  documentFileId: Scalars['Int']['input']
}

export type MutationFreightOrderBetCreateArgs = {
  input: FreightOrderBetCreateInput
}

export type MutationFreightOrderChangeRequestArgs = {
  changeRequestId: Scalars['Int']['input']
}

export type MutationFreightOrderChangeRequestAcceptAllArgs = {
  freightDealId: Scalars['Int']['input']
}

export type MutationFreightOrderChangeRequestsCreateArgs = {
  input: FreightOrderChangeRequestsCreateInput
}

export type MutationFreightOrderCreateArgs = {
  input: FreightOrderCreateInput
}

export type MutationFreightOrderDocumentArgs = {
  documentId: Scalars['Int']['input']
}

export type MutationFreightOrderDocumentCreateArgs = {
  input: FreightOrderDocumentCreateInput
}

export type MutationFreightOrderDocumentFileArgs = {
  documentFileId: Scalars['Int']['input']
}

export type MutationFreightOrderResponseArgs = {
  freightDealId: Scalars['Int']['input']
}

export type MutationFreightOrderResponseCreateArgs = {
  input: FreightOrderResponseCreateInput
}

export type MutationNoteArgs = {
  noteId: Scalars['Int']['input']
}

export type MutationNoteCreateArgs = {
  input: NoteCreateInput
}

export type MutationSetActiveCompanyArgs = {
  input: SetActiveCompanyInput
}

export type MutationTransportArgs = {
  transportId: Scalars['Int']['input']
}

export type MutationTransportCreateArgs = {
  input: TransportCreateInput
}

export type MutationTransportDataArgs = {
  companyId: Scalars['Int']['input']
}

export type MutationTransportDataCreateArgs = {
  input: TransportDataCreateInput
}

export type MutationTransportDocumentArgs = {
  documentId: Scalars['Int']['input']
}

export type MutationTransportDocumentCreateArgs = {
  input: TransportDocumentCreateInput
}

export type MutationTransportDocumentFileArgs = {
  documentFileId: Scalars['Int']['input']
}

export type MutationUserArgs = {
  userId: Scalars['Int']['input']
}

export type MutationUserCreateArgs = {
  input: UserCreateInput
}

export type NoteCreateInput = {
  entityId: Scalars['Int']['input']
  model: EntityModel
  text: Scalars['String']['input']
}

export type NoteEntity = {
  __typename?: 'NoteEntity'
  author: UserEntity
  authorId: Scalars['Int']['output']
  createdAt: Scalars['DateTime']['output']
  entityId: Scalars['Int']['output']
  id: Scalars['Int']['output']
  model: EntityModel
  text: Scalars['String']['output']
  updatedAt?: Maybe<Scalars['DateTime']['output']>
}

export type NoteMutations = {
  __typename?: 'NoteMutations'
  update: NoteEntity
}

export type NoteMutationsUpdateArgs = {
  input: NoteUpdateInput
}

export enum NoteSortField {
  CreatedAt = 'CREATED_AT',
}

export type NoteSortingInput = {
  direction?: InputMaybe<OrderDirection>
  field?: InputMaybe<NoteSortField>
}

export type NoteUpdateInput = {
  text: Scalars['String']['input']
}

export type NotesFilterInput = {
  entityId?: InputMaybe<Scalars['Int']['input']>
  model?: InputMaybe<EntityModel>
}

export type NotesResponse = {
  __typename?: 'NotesResponse'
  notes: Array<NoteEntity>
  totalCount: Scalars['Float']['output']
}

export enum OrderDirection {
  Asc = 'ASC',
  Desc = 'DESC',
}

export enum OwnershipType {
  Leasing = 'LEASING',
  Own = 'OWN',
  Rent = 'RENT',
}

export type PaginationEntity = {
  __typename?: 'PaginationEntity'
  currentPage: Scalars['Int']['output']
  pageSize: Scalars['Int']['output']
  total: Scalars['Int']['output']
  totalPages: Scalars['Int']['output']
}

export type PaginationInput = {
  currentPage?: InputMaybe<Scalars['Int']['input']>
  pageSize?: InputMaybe<Scalars['Int']['input']>
}

export enum PreferredRouteType {
  Long = 'LONG',
  Short = 'SHORT',
}

export type ProductTypeEntity = {
  __typename?: 'ProductTypeEntity'
  gost: Scalars['String']['output']
  id: Scalars['Int']['output']
  name: Scalars['String']['output']
  type: Scalars['String']['output']
}

export type Query = {
  __typename?: 'Query'
  bankAccount: BankAccountEntity
  bankAccounts: Array<BankAccountEntity>
  company: CompanyEntity
  driver: DriverEntity
  drivers: DriversEntity
  freightDeal: FreightDealEntity
  freightDealDocuments: Array<FreightDealDocumentEntity>
  freightDealDocumentsArchive: FileEntity
  freightDeals: FreightDealsEntity
  freightOrder: FreightOrderEntity
  freightOrderChangeRequestBlocks: Array<FreightOrderChangeRequestBlockEntity>
  freightOrderDocuments: Array<FreightOrderDocumentEntity>
  freightOrderDocumentsArchive: FileEntity
  freightOrderFieldInitialValues: Array<FreightOrderFieldChangeEntity>
  freightOrderResponses: FreightOrderResponsesEntity
  freightOrders: FreightOrdersEntity
  me: MeEntity
  notes: NotesResponse
  regions: Array<RegionEntity>
  transport: TransportEntity
  transportBodyTypes: Array<TransportBodyTypeEntity>
  transportData?: Maybe<TransportDataEntity>
  transportDocuments: Array<TransportDocumentEntity>
  transportLoadingMethods: Array<TransportLoadingMethodEntity>
  transportStations: Array<TransportStationEntity>
  transports: TransportsEntity
  user: UserEntity
  users: UsersResponse
}

export type QueryBankAccountArgs = {
  bankAccountId: Scalars['Int']['input']
}

export type QueryBankAccountsArgs = {
  companyId: Scalars['Int']['input']
}

export type QueryCompanyArgs = {
  companyId: Scalars['Int']['input']
}

export type QueryDriverArgs = {
  driverId: Scalars['Int']['input']
}

export type QueryDriversArgs = {
  filter?: InputMaybe<DriverFilterInput>
  pagination?: InputMaybe<PaginationInput>
}

export type QueryFreightDealArgs = {
  freightDealId: Scalars['Int']['input']
}

export type QueryFreightDealDocumentsArgs = {
  filter?: InputMaybe<FreightDealDocumentFilterInput>
  freightDealId: Scalars['Int']['input']
}

export type QueryFreightDealDocumentsArchiveArgs = {
  filter?: InputMaybe<DocumentsArchiveFilterInput>
  freightDealId: Scalars['Int']['input']
}

export type QueryFreightDealsArgs = {
  filter?: InputMaybe<FreightDealFilterInput>
  pagination?: InputMaybe<PaginationInput>
}

export type QueryFreightOrderArgs = {
  freightOrderId: Scalars['Int']['input']
}

export type QueryFreightOrderChangeRequestBlocksArgs = {
  filter?: InputMaybe<FreightOrderChangeRequestBlockFilterInput>
  freightDealId: Scalars['Int']['input']
}

export type QueryFreightOrderDocumentsArgs = {
  filter?: InputMaybe<FreightOrderDocumentFilterInput>
  freightOrderId: Scalars['Int']['input']
}

export type QueryFreightOrderDocumentsArchiveArgs = {
  filter?: InputMaybe<DocumentsArchiveFilterInput>
  freightOrderId: Scalars['Int']['input']
}

export type QueryFreightOrderFieldInitialValuesArgs = {
  freightDealId: Scalars['Int']['input']
}

export type QueryFreightOrderResponsesArgs = {
  filter?: InputMaybe<FreightOrderResponseFilterInput>
  pagination?: InputMaybe<PaginationInput>
}

export type QueryFreightOrdersArgs = {
  filter?: InputMaybe<FreightOrderFilterInput>
  pagination?: InputMaybe<PaginationInput>
}

export type QueryNotesArgs = {
  filter?: InputMaybe<NotesFilterInput>
  pagination?: InputMaybe<PaginationInput>
  sorting?: InputMaybe<NoteSortingInput>
}

export type QueryTransportArgs = {
  transportId: Scalars['Int']['input']
}

export type QueryTransportDataArgs = {
  companyId: Scalars['Int']['input']
}

export type QueryTransportDocumentsArgs = {
  filter?: InputMaybe<TransportDocumentFilterInput>
  transportId: Scalars['Int']['input']
}

export type QueryTransportStationsArgs = {
  filter?: InputMaybe<TransportStationFilter>
}

export type QueryTransportsArgs = {
  filter?: InputMaybe<TransportFilterInput>
  pagination?: InputMaybe<PaginationInput>
}

export type QueryUserArgs = {
  userId: Scalars['Int']['input']
}

export type QueryUsersArgs = {
  filter?: InputMaybe<UsersFilterInput>
  pagination: UserPaginationInput
}

export type RegionEntity = {
  __typename?: 'RegionEntity'
  id: Scalars['Int']['output']
  name: Scalars['String']['output']
}

export type RejectAccessToCompanyInput = {
  companyId: Scalars['Int']['input']
}

export type RequestPhoneNumberConfirmationCodeInput = {
  phoneNumber: Scalars['String']['input']
}

export enum RoadSurfaceQuality {
  AllSeasonal = 'ALL_SEASONAL',
  Seasonal = 'SEASONAL',
}

export enum RoadSurfaceType {
  Paved = 'PAVED',
  Unpaved = 'UNPAVED',
}

export type RoadTransportParametersEntity = {
  __typename?: 'RoadTransportParametersEntity'
  additionalDetails?: Maybe<Scalars['String']['output']>
  createdAt: Scalars['DateTime']['output']
  distanceToScalesM?: Maybe<Scalars['Int']['output']>
  hasParking?: Maybe<Scalars['Boolean']['output']>
  hasScales?: Maybe<Scalars['Boolean']['output']>
  hasWeighingAutomation?: Maybe<Scalars['Boolean']['output']>
  id: Scalars['Int']['output']
  loadingThroughput?: Maybe<Scalars['Int']['output']>
  loadingTypeCustom?: Maybe<Scalars['String']['output']>
  loadingTypes: Array<LoadingTransportType>
  roadSurfaceQuality?: Maybe<RoadSurfaceQuality>
  roadSurfaceType?: Maybe<RoadSurfaceType>
  scalesWeightLimitT?: Maybe<Scalars['Int']['output']>
  unloadingThroughput?: Maybe<Scalars['Int']['output']>
  unloadingTypeCustom?: Maybe<Scalars['String']['output']>
  unloadingTypes: Array<UnloadingTransportType>
  updatedAt?: Maybe<Scalars['DateTime']['output']>
  vehicleClearanceMinCm?: Maybe<Scalars['Int']['output']>
  vehicleHeightMaxM?: Maybe<Scalars['Int']['output']>
  vehicleLengthMaxM?: Maybe<Scalars['Int']['output']>
}

export type RouteEntity = {
  __typename?: 'RouteEntity'
  createdAt: Scalars['DateTime']['output']
  distance: Scalars['Int']['output']
  distanceToStartKm?: Maybe<Scalars['Int']['output']>
  id: Scalars['Int']['output']
  points: Array<RoutePointEntity>
  updatedAt: Scalars['DateTime']['output']
}

export type RoutePointAddressEntity = {
  __typename?: 'RoutePointAddressEntity'
  full: Scalars['String']['output']
  short: Scalars['String']['output']
}

export type RoutePointEntity = {
  __typename?: 'RoutePointEntity'
  address: RoutePointAddressEntity
  addressId: Scalars['Int']['output']
  createdAt: Scalars['DateTime']['output']
  freight?: Maybe<FreightEntity>
  freightId?: Maybe<Scalars['Int']['output']>
  id: Scalars['Int']['output']
  roadTransportParameters: RoadTransportParametersEntity
  roadTransportParametersId: Scalars['Int']['output']
  type: RoutePointType
  updatedAt: Scalars['DateTime']['output']
}

export enum RoutePointType {
  End = 'END',
  Start = 'START',
}

export type SendLogInSmsInput = {
  phoneNumber: Scalars['String']['input']
}

export type SendSignUpSmsInput = {
  phoneNumber: Scalars['String']['input']
}

export type SetActiveCompanyInput = {
  companyId?: InputMaybe<Scalars['Int']['input']>
}

export type SetNewPhoneNumberInput = {
  confirmationCodeId: Scalars['String']['input']
  phoneNumber: Scalars['String']['input']
}

export type SignUpForeignInput = {
  confirmationCodeId: Scalars['String']['input']
  countryName: CountryName
  inn: Scalars['String']['input']
  legalAddress: Scalars['String']['input']
  legalForm: Scalars['String']['input']
  legalName: Scalars['String']['input']
  name: Scalars['String']['input']
  type: CompanyType
}

export enum SignatoryLegalBasis {
  CompanyRegulation = 'COMPANY_REGULATION',
  PowerOfAttorney = 'POWER_OF_ATTORNEY',
}

export type TransportBodyTypeEntity = {
  __typename?: 'TransportBodyTypeEntity'
  id: Scalars['Int']['output']
  name: Scalars['String']['output']
}

export type TransportCreateInput = {
  additionalDetails?: InputMaybe<Scalars['String']['input']>
  adrCertificate?: InputMaybe<Scalars['String']['input']>
  axlesCount: Scalars['Int']['input']
  bodyTypeId?: InputMaybe<Scalars['Int']['input']>
  brand: Scalars['String']['input']
  canWorkInField?: InputMaybe<Scalars['Boolean']['input']>
  companyId: Scalars['Int']['input']
  heightCm?: InputMaybe<Scalars['Int']['input']>
  lengthCm?: InputMaybe<Scalars['Int']['input']>
  licensePlate: Scalars['String']['input']
  loadCapacityKg?: InputMaybe<Scalars['Int']['input']>
  loadVolumeL?: InputMaybe<Scalars['Int']['input']>
  loadingMethodId?: InputMaybe<Scalars['Int']['input']>
  longRouteKm?: InputMaybe<Scalars['Int']['input']>
  manufacturingDate?: InputMaybe<Scalars['Int']['input']>
  ownerName?: InputMaybe<Scalars['String']['input']>
  ownershipType: OwnershipType
  preferredRouteType?: InputMaybe<PreferredRouteType>
  registrationCertificate?: InputMaybe<Scalars['String']['input']>
  shortRouteKm?: InputMaybe<Scalars['Int']['input']>
  transportPassport?: InputMaybe<Scalars['String']['input']>
  transportStationId?: InputMaybe<Scalars['Int']['input']>
  type: TransportType
  vin?: InputMaybe<Scalars['String']['input']>
  widthCm?: InputMaybe<Scalars['Int']['input']>
}

export type TransportDataCreateInput = {
  address: Scalars['String']['input']
  companyId: Scalars['Int']['input']
  regionIds: Array<Scalars['Int']['input']>
  transportModes?: InputMaybe<Array<TransportMode>>
  transportationArea?: InputMaybe<TransportationArea>
}

export type TransportDataEntity = {
  __typename?: 'TransportDataEntity'
  address?: Maybe<Scalars['String']['output']>
  companyId: Scalars['Int']['output']
  createdAt: Scalars['DateTime']['output']
  id: Scalars['Int']['output']
  regions: Array<RegionEntity>
  transportModes: Array<TransportMode>
  transportationArea?: Maybe<TransportationArea>
  updatedAt: Scalars['DateTime']['output']
}

export type TransportDataMutations = {
  __typename?: 'TransportDataMutations'
  update: TransportDataEntity
}

export type TransportDataMutationsUpdateArgs = {
  input: TransportDataUpdateInput
}

export type TransportDataUpdateInput = {
  address?: InputMaybe<Scalars['String']['input']>
  regionIds?: InputMaybe<Array<Scalars['Int']['input']>>
  transportModes?: InputMaybe<Array<TransportMode>>
  transportationArea?: InputMaybe<TransportationArea>
}

export type TransportDocumentCreateInput = {
  fileKeys: Array<Scalars['String']['input']>
  formedAt?: InputMaybe<Scalars['DateTime']['input']>
  number?: InputMaybe<Scalars['String']['input']>
  transportId: Scalars['Int']['input']
  type: TransportDocumentType
}

export type TransportDocumentEntity = {
  __typename?: 'TransportDocumentEntity'
  instances: Array<DocumentInstanceEntity>
  type: TransportDocumentType
}

export type TransportDocumentFileMutations = {
  __typename?: 'TransportDocumentFileMutations'
  delete: Scalars['Int']['output']
  update: Array<TransportDocumentEntity>
}

export type TransportDocumentFileMutationsUpdateArgs = {
  input: DocumentFileUpdateInput
}

export type TransportDocumentFilterInput = {
  types?: InputMaybe<Array<TransportDocumentType>>
}

export type TransportDocumentMutations = {
  __typename?: 'TransportDocumentMutations'
  addFiles: Array<TransportDocumentEntity>
  delete: Scalars['Int']['output']
}

export type TransportDocumentMutationsAddFilesArgs = {
  input: DocumentFilesCreateInput
}

export enum TransportDocumentType {
  LeasingContract = 'LEASING_CONTRACT',
  Photo = 'PHOTO',
  RegistrationCertificate = 'REGISTRATION_CERTIFICATE',
  TransportPassport = 'TRANSPORT_PASSPORT',
}

export type TransportEntity = {
  __typename?: 'TransportEntity'
  additionalDetails?: Maybe<Scalars['String']['output']>
  adrCertificate?: Maybe<Scalars['String']['output']>
  axlesCount: Scalars['Int']['output']
  bodyType?: Maybe<TransportBodyTypeEntity>
  bodyTypeId?: Maybe<Scalars['Int']['output']>
  brand: Scalars['String']['output']
  canWorkInField?: Maybe<Scalars['Boolean']['output']>
  companyId: Scalars['Int']['output']
  createdAt: Scalars['DateTime']['output']
  deletedAt?: Maybe<Scalars['DateTime']['output']>
  heightCm?: Maybe<Scalars['Int']['output']>
  id: Scalars['Int']['output']
  lengthCm?: Maybe<Scalars['Int']['output']>
  licensePlate: Scalars['String']['output']
  loadCapacityKg?: Maybe<Scalars['Int']['output']>
  loadVolumeL?: Maybe<Scalars['Int']['output']>
  loadingMethod?: Maybe<TransportLoadingMethodEntity>
  loadingMethodId?: Maybe<Scalars['Int']['output']>
  longRouteKm?: Maybe<Scalars['Int']['output']>
  manufacturingDate?: Maybe<Scalars['Int']['output']>
  ownerName?: Maybe<Scalars['String']['output']>
  ownershipType: OwnershipType
  preferredRouteType?: Maybe<PreferredRouteType>
  registrationCertificate?: Maybe<Scalars['String']['output']>
  shortRouteKm?: Maybe<Scalars['Int']['output']>
  transportPassport?: Maybe<Scalars['String']['output']>
  transportStationId?: Maybe<Scalars['Int']['output']>
  type: TransportType
  updatedAt: Scalars['DateTime']['output']
  vin?: Maybe<Scalars['String']['output']>
  widthCm?: Maybe<Scalars['Int']['output']>
}

export type TransportFilterInput = {
  companyIds?: InputMaybe<Array<Scalars['Int']['input']>>
  driverIds?: InputMaybe<Array<Scalars['Int']['input']>>
  search?: InputMaybe<Scalars['String']['input']>
  transportStationIds?: InputMaybe<Array<Scalars['Int']['input']>>
  types?: InputMaybe<Array<TransportType>>
}

export enum TransportFormatType {
  RoadTrain = 'ROAD_TRAIN',
  Single = 'SINGLE',
}

export type TransportLoadingMethodEntity = {
  __typename?: 'TransportLoadingMethodEntity'
  id: Scalars['Int']['output']
  name: Scalars['String']['output']
}

export enum TransportMode {
  Rail = 'RAIL',
  River = 'RIVER',
  Road = 'ROAD',
}

export type TransportMutations = {
  __typename?: 'TransportMutations'
  update: TransportEntity
}

export type TransportMutationsUpdateArgs = {
  input: TransportUpdateInput
}

export type TransportStationEntity = {
  __typename?: 'TransportStationEntity'
  address: Scalars['String']['output']
  addressId: Scalars['Int']['output']
  companyId: Scalars['Int']['output']
  createdAt: Scalars['DateTime']['output']
  id: Scalars['Int']['output']
  updatedAt: Scalars['DateTime']['output']
}

export type TransportStationFilter = {
  companyIds?: InputMaybe<Array<Scalars['Int']['input']>>
}

export enum TransportType {
  SemiTrailer = 'SEMI_TRAILER',
  Tractor = 'TRACTOR',
  Trailer = 'TRAILER',
  Truck = 'TRUCK',
}

export type TransportUpdateInput = {
  additionalDetails?: InputMaybe<Scalars['String']['input']>
  adrCertificate?: InputMaybe<Scalars['String']['input']>
  axlesCount?: InputMaybe<Scalars['Int']['input']>
  bodyTypeId?: InputMaybe<Scalars['Int']['input']>
  brand?: InputMaybe<Scalars['String']['input']>
  canWorkInField?: InputMaybe<Scalars['Boolean']['input']>
  heightCm?: InputMaybe<Scalars['Int']['input']>
  lengthCm?: InputMaybe<Scalars['Int']['input']>
  licensePlate?: InputMaybe<Scalars['String']['input']>
  loadCapacityKg?: InputMaybe<Scalars['Int']['input']>
  loadVolumeL?: InputMaybe<Scalars['Int']['input']>
  loadingMethodId?: InputMaybe<Scalars['Int']['input']>
  longRouteKm?: InputMaybe<Scalars['Int']['input']>
  manufacturingDate?: InputMaybe<Scalars['Int']['input']>
  ownerName?: InputMaybe<Scalars['String']['input']>
  ownershipType?: InputMaybe<OwnershipType>
  preferredRouteType?: InputMaybe<PreferredRouteType>
  registrationCertificate?: InputMaybe<Scalars['String']['input']>
  shortRouteKm?: InputMaybe<Scalars['Int']['input']>
  transportPassport?: InputMaybe<Scalars['String']['input']>
  transportStationId?: InputMaybe<Scalars['Int']['input']>
  vin?: InputMaybe<Scalars['String']['input']>
  widthCm?: InputMaybe<Scalars['Int']['input']>
}

export enum TransportationArea {
  Cis = 'CIS',
  Region = 'REGION',
  Russia = 'RUSSIA',
  Worldwide = 'WORLDWIDE',
}

export type TransportsEntity = {
  __typename?: 'TransportsEntity'
  pagination: PaginationEntity
  transports: Array<TransportEntity>
}

export enum UnloadingTransportType {
  ByContractorChoice = 'BY_CONTRACTOR_CHOICE',
  Custom = 'CUSTOM',
  DumpTruck = 'DUMP_TRUCK',
  Manipulator = 'MANIPULATOR',
  MechanicalShovel = 'MECHANICAL_SHOVEL',
  PneumaticUnloader = 'PNEUMATIC_UNLOADER',
  SideBoardAutoUnloader = 'SIDE_BOARD_AUTO_UNLOADER',
  TailgateAutoUnloader = 'TAILGATE_AUTO_UNLOADER',
}

export type UpdateAvatarInput = {
  avatarFileKey?: InputMaybe<Scalars['String']['input']>
}

export type UpdateStateInput = {
  companyId?: InputMaybe<Scalars['Int']['input']>
  fieldName: UserStateFieldName
  value: Scalars['String']['input']
}

export type UserActionsEntity = {
  __typename?: 'UserActionsEntity'
  activate: Scalars['Boolean']['output']
  deactivate: Scalars['Boolean']['output']
  update: Scalars['Boolean']['output']
  updateAvatar: Scalars['Boolean']['output']
}

export type UserCreateInput = {
  avatarFileKey?: InputMaybe<Scalars['String']['input']>
  companyIds: Array<Scalars['Float']['input']>
  email?: InputMaybe<Scalars['String']['input']>
  name: Scalars['String']['input']
  phoneNumber: Scalars['String']['input']
  position?: InputMaybe<Scalars['String']['input']>
  role: UserRole
}

export type UserEntity = {
  __typename?: 'UserEntity'
  actions: UserActionsEntity
  authenticatedAt: Scalars['DateTime']['output']
  avatarUrl?: Maybe<Scalars['String']['output']>
  companies: Array<CompanyEntity>
  createdAt: Scalars['DateTime']['output']
  createdBy?: Maybe<UserEntity>
  deactivatedAt?: Maybe<Scalars['DateTime']['output']>
  email?: Maybe<Scalars['String']['output']>
  id: Scalars['Int']['output']
  name: Scalars['String']['output']
  phoneNumber: Scalars['String']['output']
  position?: Maybe<Scalars['String']['output']>
  role: UserRole
  status: UserStatus
}

export type UserMutations = {
  __typename?: 'UserMutations'
  acceptAccessToCompany: UserEntity
  activate: UserEntity
  deactivate: UserEntity
  rejectAccessToCompany: UserEntity
  update: UserEntity
  updateAvatar: UserEntity
  updateState: UserEntity
}

export type UserMutationsAcceptAccessToCompanyArgs = {
  input: AcceptAccessToCompanyInput
}

export type UserMutationsRejectAccessToCompanyArgs = {
  input: RejectAccessToCompanyInput
}

export type UserMutationsUpdateArgs = {
  input: UserUpdateInput
}

export type UserMutationsUpdateAvatarArgs = {
  input: UpdateAvatarInput
}

export type UserMutationsUpdateStateArgs = {
  input: UpdateStateInput
}

export type UserPaginationInput = {
  skip: Scalars['Int']['input']
  take: Scalars['Int']['input']
}

export enum UserRole {
  Accountant = 'ACCOUNTANT',
  Admin = 'ADMIN',
  Authenticated = 'AUTHENTICATED',
  DemoUser = 'DEMO_USER',
  Director = 'DIRECTOR',
  Driver = 'DRIVER',
  Manager = 'MANAGER',
  Owner = 'OWNER',
}

export enum UserStateFieldName {
  CanUsePriorityCompanies = 'CAN_USE_PRIORITY_COMPANIES',
  CompanyVerifiedMessageClosed = 'COMPANY_VERIFIED_MESSAGE_CLOSED',
  FirstFreightOrderCreated = 'FIRST_FREIGHT_ORDER_CREATED',
  IsShowEmployeesGuide = 'IS_SHOW_EMPLOYEES_GUIDE',
  MultiCompanyProfileGuideShown = 'MULTI_COMPANY_PROFILE_GUIDE_SHOWN',
}

export enum UserStatus {
  Activated = 'ACTIVATED',
  Blocked = 'BLOCKED',
}

export type UserUpdateInput = {
  email?: InputMaybe<Scalars['String']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  position?: InputMaybe<Scalars['String']['input']>
  role?: InputMaybe<UserRole>
}

export type UsersFilterInput = {
  excludeFromCompanyIds?: InputMaybe<Array<Scalars['Int']['input']>>
  fromCompanyIds?: InputMaybe<Array<Scalars['Int']['input']>>
  name?: InputMaybe<Scalars['String']['input']>
  onlyRequestedAccess?: InputMaybe<Scalars['Boolean']['input']>
}

export type UsersResponse = {
  __typename?: 'UsersResponse'
  totalCount: Scalars['Float']['output']
  users: Array<UserEntity>
}

export type CompanyDataFragment = {
  __typename?: 'CompanyEntity'
  id: number
  inn: string
  kpp?: string | null
  legalName: string
  ogrn?: string | null
  okved?: string | null
  type: CompanyType
  verificationStatus: CompanyVerificationStatus
  phoneNumber?: string | null
  rejectionReason?: string | null
  useNds?: boolean | null
  website?: string | null
  additionalInfo?: string | null
  directorName?: string | null
  verifiedAt?: any | null
  codeOkved?: string | null
  signatory?: string | null
  signatoryPosition?: string | null
  signatoryLegalBasis?: SignatoryLegalBasis | null
  legalAddress?: string | null
  actualAddress?: string | null
  aicCharterMember?: boolean | null
  logoUrl?: string | null
}

export type ConfirmPhoneNumberMutationVariables = Exact<{
  input: ConfirmPhoneNumberInput
}>

export type ConfirmPhoneNumberMutation = {
  __typename?: 'Mutation'
  auth: {
    __typename?: 'AuthMutations'
    confirmPhoneNumber: {
      __typename?: 'ConfirmationCodeEntity'
      numberOfAttempts: number
      isConfirmed: boolean
      phoneNumber: string
      id: string
    }
  }
}

export type LogInAsDemoUserMutationVariables = Exact<{
  input: LogInAsDemoUserInput
}>

export type LogInAsDemoUserMutation = {
  __typename?: 'Mutation'
  auth: {
    __typename?: 'AuthMutations'
    logInAsDemoUser: {
      __typename?: 'ConfirmationCodeEntity'
      phoneNumber: string
      purpose: ConfirmationCodePurpose
      numberOfAttempts: number
      isConfirmed: boolean
      id: string
    }
  }
}

export type SendLogInSmsMutationVariables = Exact<{
  input: SendLogInSmsInput
}>

export type SendLogInSmsMutation = {
  __typename?: 'Mutation'
  auth: {
    __typename?: 'AuthMutations'
    sendLogInSms: {
      __typename?: 'ConfirmationCodeEntity'
      id: string
      phoneNumber: string
      numberOfAttempts: number
      isConfirmed: boolean
    }
  }
}

export type RequestPhoneNumberConfirmationCodeMutationVariables = Exact<{
  input: RequestPhoneNumberConfirmationCodeInput
}>

export type RequestPhoneNumberConfirmationCodeMutation = {
  __typename?: 'Mutation'
  auth: {
    __typename?: 'AuthMutations'
    requestPhoneNumberConfirmationCode: {
      __typename?: 'ConfirmationCodeEntity'
      purpose: ConfirmationCodePurpose
      phoneNumber: string
      numberOfAttempts: number
      isConfirmed: boolean
      id: string
      expiredAt: any
      confirmedAt?: any | null
    }
  }
}

export type ConfirmAuthPhoneNumberMutationVariables = Exact<{
  input: ConfirmPhoneNumberInput
}>

export type ConfirmAuthPhoneNumberMutation = {
  __typename?: 'Mutation'
  auth: {
    __typename?: 'AuthMutations'
    confirmAuthPhoneNumber: {
      __typename?: 'ConfirmationCodeEntity'
      purpose: ConfirmationCodePurpose
      phoneNumber: string
      numberOfAttempts: number
      isConfirmed: boolean
      id: string
      expiredAt: any
      confirmedAt?: any | null
    }
  }
}

export type SendSignUpSmsMutationVariables = Exact<{
  input: SendSignUpSmsInput
}>

export type SendSignUpSmsMutation = {
  __typename?: 'Mutation'
  auth: {
    __typename?: 'AuthMutations'
    sendSignUpSms: {
      __typename?: 'ConfirmationCodeEntity'
      id: string
      phoneNumber: string
      numberOfAttempts: number
      isConfirmed: boolean
    }
  }
}

export type SignUpMutationVariables = Exact<{
  input: AuthSignUpInput
}>

export type SignUpMutation = { __typename?: 'Mutation'; auth: { __typename?: 'AuthMutations'; signUp: string } }

export type SignUpForeignMutationVariables = Exact<{
  input: SignUpForeignInput
}>

export type SignUpForeignMutation = {
  __typename?: 'Mutation'
  auth: { __typename?: 'AuthMutations'; signUpForeign: string }
}

export type LogInBySmsCodeMutationVariables = Exact<{
  input: LogInBySmsCodeInput
}>

export type LogInBySmsCodeMutation = {
  __typename?: 'Mutation'
  auth: { __typename?: 'AuthMutations'; logInBySmsCode: string }
}

export type LoginAsUserMutationVariables = Exact<{
  input: AsUserLoginInput
}>

export type LoginAsUserMutation = {
  __typename?: 'Mutation'
  auth: { __typename?: 'AuthMutations'; loginAsUser: string }
}

export type LogOutUserReturnModeratorMutationVariables = Exact<{ [key: string]: never }>

export type LogOutUserReturnModeratorMutation = {
  __typename?: 'Mutation'
  auth: { __typename?: 'AuthMutations'; logOutUserReturnModerator: string }
}

export type SetActiveCompanyMutationVariables = Exact<{
  input: SetActiveCompanyInput
}>

export type SetActiveCompanyMutation = { __typename?: 'Mutation'; setActiveCompany: string }

export type BankAccountCreateMutationVariables = Exact<{
  input: BankAccountCreateMutationsInput
}>

export type BankAccountCreateMutation = {
  __typename?: 'Mutation'
  bankAccountCreate: {
    __typename?: 'BankAccountEntity'
    companyId?: number | null
    bankName: string
    bic: string
    correspondentAccount: string
    paymentAccount: string
  }
}

export type BankAccountMutationVariables = Exact<{
  bankAccountId: Scalars['Int']['input']
}>

export type BankAccountMutation = {
  __typename?: 'Mutation'
  bankAccount: { __typename?: 'BankAccountMutations'; delete: number }
}

export type TransportationDataCreateMutationVariables = Exact<{
  input: TransportDataCreateInput
}>

export type TransportationDataCreateMutation = {
  __typename?: 'Mutation'
  transportDataCreate: { __typename?: 'TransportDataEntity'; id: number }
}

export type MutationMutationVariables = Exact<{
  companyId: Scalars['Int']['input']
  input: TransportDataUpdateInput
}>

export type MutationMutation = {
  __typename?: 'Mutation'
  transportData: { __typename?: 'TransportDataMutations'; update: { __typename?: 'TransportDataEntity'; id: number } }
}

export type TransportCreateMutationVariables = Exact<{
  input: TransportCreateInput
}>

export type TransportCreateMutation = {
  __typename?: 'Mutation'
  transportCreate: { __typename?: 'TransportEntity'; id: number }
}

export type TransportUpdateMutationVariables = Exact<{
  input: TransportUpdateInput
  transportId: Scalars['Int']['input']
}>

export type TransportUpdateMutation = {
  __typename?: 'Mutation'
  transport: { __typename?: 'TransportMutations'; update: { __typename?: 'TransportEntity'; id: number } }
}

export type TransportDocumentCreateMutationVariables = Exact<{
  input: TransportDocumentCreateInput
}>

export type TransportDocumentCreateMutation = {
  __typename?: 'Mutation'
  transportDocumentCreate: Array<{ __typename?: 'TransportDocumentEntity'; type: TransportDocumentType }>
}

export type DeleteTransportDocumentMutationVariables = Exact<{
  documentId: Scalars['Int']['input']
}>

export type DeleteTransportDocumentMutation = {
  __typename?: 'Mutation'
  transportDocument: { __typename?: 'TransportDocumentMutations'; delete: number }
}

export type AddTransportDocumentFilesMutationVariables = Exact<{
  documentId: Scalars['Int']['input']
  input: DocumentFilesCreateInput
}>

export type AddTransportDocumentFilesMutation = {
  __typename?: 'Mutation'
  transportDocument: {
    __typename?: 'TransportDocumentMutations'
    addFiles: Array<{ __typename?: 'TransportDocumentEntity'; type: TransportDocumentType }>
  }
}

export type DeleteTransportDocumentFileMutationVariables = Exact<{
  documentFileId: Scalars['Int']['input']
}>

export type DeleteTransportDocumentFileMutation = {
  __typename?: 'Mutation'
  transportDocumentFile: { __typename?: 'TransportDocumentFileMutations'; delete: number }
}

export type CompanySendOnModerationMutationVariables = Exact<{
  companyId: Scalars['Int']['input']
}>

export type CompanySendOnModerationMutation = {
  __typename?: 'Mutation'
  company: { __typename?: 'CompanyMutations'; sendOnModeration: boolean }
}

export type CompanyCreateMutationVariables = Exact<{
  input: CompanyCreateMutationsInput
}>

export type CompanyCreateMutation = {
  __typename?: 'Mutation'
  companyCreate: { __typename?: 'CompanyEntity'; inn: string; type: CompanyType }
}

export type DeleteCompanyMutationVariables = Exact<{
  companyId: Scalars['Int']['input']
}>

export type DeleteCompanyMutation = {
  __typename?: 'Mutation'
  company: { __typename?: 'CompanyMutations'; delete: number }
}

export type UpdateCompanyGeneralInfoMutationVariables = Exact<{
  input: CompanyUpdateGeneralInfoInput
  companyId: Scalars['Int']['input']
}>

export type UpdateCompanyGeneralInfoMutation = {
  __typename?: 'Mutation'
  company: {
    __typename?: 'CompanyMutations'
    updateGeneralInfo: {
      __typename?: 'CompanyEntity'
      website?: string | null
      additionalInfo?: string | null
      phoneNumber?: string | null
      logoUrl?: string | null
    }
  }
}

export type UpdateLogoMutationVariables = Exact<{
  companyId: Scalars['Int']['input']
  input: CompanyUpdateLogoInput
}>

export type UpdateLogoMutation = {
  __typename?: 'Mutation'
  company: { __typename?: 'CompanyMutations'; updateLogo: { __typename?: 'CompanyEntity'; logoUrl?: string | null } }
}

export type UpdateLegalInfoMutationVariables = Exact<{
  companyId: Scalars['Int']['input']
  input: CompanyUpdateLegalInfoInput
}>

export type UpdateLegalInfoMutation = {
  __typename?: 'Mutation'
  company: {
    __typename?: 'CompanyMutations'
    updateLegalInfo: {
      __typename?: 'CompanyEntity'
      useNds?: boolean | null
      kpp?: string | null
      inn: string
      directorName?: string | null
    }
  }
}

export type DeleteUserMutationVariables = Exact<{
  userId: Scalars['Int']['input']
  companyId: Scalars['Int']['input']
}>

export type DeleteUserMutation = {
  __typename?: 'Mutation'
  company: { __typename?: 'CompanyMutations'; deleteUser: { __typename?: 'UserEntity'; id: number } }
}

export type DriverCreateMutationVariables = Exact<{
  input: DriverCreateInput
}>

export type DriverCreateMutation = {
  __typename?: 'Mutation'
  driverCreate: { __typename?: 'DriverEntity'; id: number }
}

export type UpdateDriverMutationVariables = Exact<{
  input: DriverUpdateInput
  driverId: Scalars['Int']['input']
}>

export type UpdateDriverMutation = {
  __typename?: 'Mutation'
  driver: { __typename?: 'DriverMutations'; updateDriver: { __typename?: 'DriverEntity'; id: number } }
}

export type SetTransportMutationVariables = Exact<{
  transportId: Scalars['Int']['input']
  driverId: Scalars['Int']['input']
}>

export type SetTransportMutation = {
  __typename?: 'Mutation'
  driver: { __typename?: 'DriverMutations'; setTransport: { __typename?: 'DriverEntity'; id: number } }
}

export type DriverUserCreateMutationVariables = Exact<{
  input: DriverUserCreateInput
}>

export type DriverUserCreateMutation = {
  __typename?: 'Mutation'
  driverUserCreate: { __typename?: 'DriverEntity'; id: number }
}

export type ElevatorCreateMutationVariables = Exact<{
  input: ElevatorCreateInput
}>

export type ElevatorCreateMutation = {
  __typename?: 'Mutation'
  elevatorCreate: {
    __typename?: 'ElevatorEntity'
    id: number
    name: string
    address: string
    type: Array<ElevatorType>
    workingDaysOfWeek: Array<DayOfWeek>
    openingTime?: any | null
    closingTime?: any | null
    hasScales?: boolean | null
    hasLab: boolean
    hasDigitalQueue: boolean
    gateHeight?: number | null
    scalesWeightLimit?: number | null
    scalesLength?: number | null
    transport?: Array<TransportMode> | null
    unloadingTransport?: Array<UnloadingTransportType> | null
    loadingTransport?: Array<LoadingTransportType> | null
    isDeleted: boolean
    createdAt: any
    buyOfferCount: number
    sellOfferCount: number
    storageVolume?: number | null
    autoClearance?: number | null
    autoHeight?: number | null
    autoLength?: number | null
    autoUnloadSpeed?: number | null
    hasAutoStorage?: boolean | null
    hasRailScales?: boolean | null
    hasRailStock?: boolean | null
    hasRailWay?: boolean | null
    hasTecEco?: boolean | null
    railLoadSpeed?: number | null
    autoReceptionSpeed?: number | null
    autoAdditionalInfo?: string | null
    railAdditionalInfo?: string | null
    stationName?: string | null
    waitingArea?: number | null
    productOnElevator?: Array<string> | null
    additionalServiceWithNds?: boolean | null
    receptionPrice?: number | null
    dryingPrice?: number | null
    storingPrice?: number | null
    mixingPrice?: number | null
    censusPrice?: number | null
    autoShipmentPrice?: number | null
    trainShipmentPrice?: number | null
    paperworkPrice?: number | null
    cleaningPrice?: number | null
    anotherServices?: string | null
  }
}

export type ElevatorApproveCreationRequestMutationVariables = Exact<{
  elevatorId: Scalars['Int']['input']
}>

export type ElevatorApproveCreationRequestMutation = {
  __typename?: 'Mutation'
  elevator: {
    __typename?: 'ElevatorMutations'
    approveCreationRequest: { __typename?: 'ElevatorEntity'; id: number; name: string }
  }
}

export type FreightDealDocumentCreateMutationVariables = Exact<{
  input: FreightDealDocumentCreateInput
}>

export type FreightDealDocumentCreateMutation = {
  __typename?: 'Mutation'
  freightDealDocumentCreate: Array<{
    __typename?: 'FreightDealDocumentEntity'
    type: FreightDealDocumentType
    instances: Array<{
      __typename?: 'DocumentInstanceEntity'
      id: number
      number?: string | null
      rejectionNote?: string | null
      status: DocumentPartStatus
      formedAt?: any | null
      createdAt: any
      files: Array<{
        __typename?: 'DocumentFileEntity'
        id: number
        mimetype: string
        name: string
        size: number
        source: string
        status: DocumentPartStatus
      }>
    }>
  }>
}

export type FreightDealDocumentMutationVariables = Exact<{
  documentId: Scalars['Int']['input']
}>

export type FreightDealDocumentMutation = {
  __typename?: 'Mutation'
  freightDealDocument: { __typename?: 'FreightDealDocumentMutations'; delete: number }
}

export type AddFilesMutationVariables = Exact<{
  input: DocumentFilesCreateInput
  documentId: Scalars['Int']['input']
}>

export type AddFilesMutation = {
  __typename?: 'Mutation'
  freightDealDocument: {
    __typename?: 'FreightDealDocumentMutations'
    addFiles: Array<{
      __typename?: 'FreightDealDocumentEntity'
      type: FreightDealDocumentType
      instances: Array<{
        __typename?: 'DocumentInstanceEntity'
        id: number
        status: DocumentPartStatus
        createdAt: any
        number?: string | null
        formedAt?: any | null
        rejectionNote?: string | null
        files: Array<{
          __typename?: 'DocumentFileEntity'
          id: number
          status: DocumentPartStatus
          name: string
          size: number
          mimetype: string
          source: string
        }>
      }>
    }>
  }
}

export type FreightDealUpdateStatusMutationVariables = Exact<{
  freightDealId: Scalars['Int']['input']
  input: FreightDealStatusUpdateInput
}>

export type FreightDealUpdateStatusMutation = {
  __typename?: 'Mutation'
  freightDeal: { __typename?: 'FreightDealMutations'; updateStatus: { __typename?: 'FreightDealEntity'; id: number } }
}

export type FreightOrderChangeRequestAcceptAllMutationVariables = Exact<{
  freightDealId: Scalars['Int']['input']
}>

export type FreightOrderChangeRequestAcceptAllMutation = {
  __typename?: 'Mutation'
  freightOrderChangeRequestAcceptAll: Array<{ __typename?: 'FreightOrderChangeRequestEntity'; id: number }>
}

export type FreightOrderChangeRequestMutationVariables = Exact<{
  changeRequestId: Scalars['Int']['input']
}>

export type FreightOrderChangeRequestMutation = {
  __typename?: 'Mutation'
  freightOrderChangeRequest: {
    __typename?: 'FreightOrderChangeRequestMutations'
    setViewed: { __typename?: 'FreightOrderChangeRequestEntity'; id: number }
  }
}

export type FreightOrderChangeRequestUpdateStatusMutationVariables = Exact<{
  input: FreightOrderChangeRequestStatusUpdateInput
  changeRequestId: Scalars['Int']['input']
}>

export type FreightOrderChangeRequestUpdateStatusMutation = {
  __typename?: 'Mutation'
  freightOrderChangeRequest: {
    __typename?: 'FreightOrderChangeRequestMutations'
    updateStatus: { __typename?: 'FreightOrderChangeRequestEntity'; id: number }
  }
}

export type FreightOrderChangeRequestsCreateMutationVariables = Exact<{
  input: FreightOrderChangeRequestsCreateInput
}>

export type FreightOrderChangeRequestsCreateMutation = {
  __typename?: 'Mutation'
  freightOrderChangeRequestsCreate: Array<{ __typename?: 'FreightOrderChangeRequestEntity'; id: number }>
}

export type FreightOrderDocumentCreateMutationVariables = Exact<{
  input: FreightOrderDocumentCreateInput
}>

export type FreightOrderDocumentCreateMutation = {
  __typename?: 'Mutation'
  freightOrderDocumentCreate: Array<{
    __typename?: 'FreightOrderDocumentEntity'
    type: FreightOrderDocumentType
    instances: Array<{
      __typename?: 'DocumentInstanceEntity'
      id: number
      status: DocumentPartStatus
      createdAt: any
      number?: string | null
      formedAt?: any | null
      rejectionNote?: string | null
      files: Array<{
        __typename?: 'DocumentFileEntity'
        name: string
        size: number
        mimetype: string
        source: string
        id: number
        status: DocumentPartStatus
      }>
    }>
  }>
}

export type FreightOrderDocumentAddFilesMutationVariables = Exact<{
  input: DocumentFilesCreateInput
  documentId: Scalars['Int']['input']
}>

export type FreightOrderDocumentAddFilesMutation = {
  __typename?: 'Mutation'
  freightOrderDocument: {
    __typename?: 'FreightOrderDocumentMutations'
    addFiles: Array<{
      __typename?: 'FreightOrderDocumentEntity'
      type: FreightOrderDocumentType
      instances: Array<{
        __typename?: 'DocumentInstanceEntity'
        id: number
        status: DocumentPartStatus
        createdAt: any
        number?: string | null
        formedAt?: any | null
        rejectionNote?: string | null
        files: Array<{
          __typename?: 'DocumentFileEntity'
          name: string
          size: number
          mimetype: string
          source: string
          id: number
          status: DocumentPartStatus
        }>
      }>
    }>
  }
}

export type FreightOrderDocumentMutationVariables = Exact<{
  documentId: Scalars['Int']['input']
}>

export type FreightOrderDocumentMutation = {
  __typename?: 'Mutation'
  freightOrderDocument: { __typename?: 'FreightOrderDocumentMutations'; delete: number }
}

export type FreightResponseUpdateStatusMutationVariables = Exact<{
  freightDealId: Scalars['Int']['input']
  input: FreightDealStatusUpdateInput
}>

export type FreightResponseUpdateStatusMutation = {
  __typename?: 'Mutation'
  freightOrderResponse: {
    __typename?: 'FreightOrderResponseMutations'
    updateStatus: { __typename?: 'FreightOrderResponseEntity'; id: number }
  }
}

export type FreightOrderCreateMutationVariables = Exact<{
  input: FreightOrderCreateInput
}>

export type FreightOrderCreateMutation = {
  __typename?: 'Mutation'
  freightOrderCreate: { __typename?: 'FreightOrderEntity'; id: number }
}

export type FreightOrderResponseCreateMutationVariables = Exact<{
  input: FreightOrderResponseCreateInput
}>

export type FreightOrderResponseCreateMutation = {
  __typename?: 'Mutation'
  freightOrderResponseCreate: { __typename?: 'FreightOrderResponseEntity'; id: number }
}

export type FreightOrderBetCreateMutationVariables = Exact<{
  input: FreightOrderBetCreateInput
}>

export type FreightOrderBetCreateMutation = {
  __typename?: 'Mutation'
  freightOrderBetCreate: { __typename?: 'FreightOrderResponseEntity'; id: number }
}

export type UpdateMeMutationVariables = Exact<{
  input: MeUpdateProfileInput
}>

export type UpdateMeMutation = {
  __typename?: 'Mutation'
  me: {
    __typename?: 'MeMutations'
    updateProfile: { __typename?: 'MeEntity'; email?: string | null; name: string; position?: string | null }
  }
}

export type UpdateAvatarMutationVariables = Exact<{
  input: UpdateAvatarInput
}>

export type UpdateAvatarMutation = {
  __typename?: 'Mutation'
  me: { __typename?: 'MeMutations'; updateAvatar: { __typename?: 'MeEntity'; avatarUrl?: string | null } }
}

export type UpdateStateMutationVariables = Exact<{
  input: UpdateStateInput
}>

export type UpdateStateMutation = {
  __typename?: 'Mutation'
  me: {
    __typename?: 'MeMutations'
    updateState: {
      __typename?: 'MeEntity'
      state: { __typename?: 'MeStateEntity'; multiCompanyProfileGuideShown: boolean }
    }
  }
}

export type RequestChangePhoneNumberMutationVariables = Exact<{ [key: string]: never }>

export type RequestChangePhoneNumberMutation = {
  __typename?: 'Mutation'
  me: {
    __typename?: 'MeMutations'
    requestChangePhoneNumber: {
      __typename?: 'ConfirmationCodeEntity'
      id: string
      phoneNumber: string
      isConfirmed: boolean
      numberOfAttempts: number
    }
  }
}

export type SetNewPhoneNumberMutationVariables = Exact<{
  input: SetNewPhoneNumberInput
}>

export type SetNewPhoneNumberMutation = {
  __typename?: 'Mutation'
  me: {
    __typename?: 'MeMutations'
    setNewPhoneNumber: {
      __typename?: 'ConfirmationCodeEntity'
      id: string
      phoneNumber: string
      isConfirmed: boolean
      numberOfAttempts: number
    }
  }
}

export type ConfirmNewPhoneNumberMutationVariables = Exact<{
  input: ConfirmNewPhoneNumberInput
}>

export type ConfirmNewPhoneNumberMutation = {
  __typename?: 'Mutation'
  me: {
    __typename?: 'MeMutations'
    confirmNewPhoneNumber: {
      __typename?: 'ConfirmationCodeEntity'
      id: string
      phoneNumber: string
      isConfirmed: boolean
      numberOfAttempts: number
    }
  }
}

export type UpdateNoteMutationVariables = Exact<{
  noteId: Scalars['Int']['input']
  input: NoteUpdateInput
}>

export type UpdateNoteMutation = {
  __typename?: 'Mutation'
  note: {
    __typename?: 'NoteMutations'
    update: {
      __typename?: 'NoteEntity'
      id: number
      entityId: number
      createdAt: any
      authorId: number
      model: EntityModel
      text: string
      updatedAt?: any | null
    }
  }
}

export type AddNoteMutationVariables = Exact<{
  input: NoteCreateInput
}>

export type AddNoteMutation = {
  __typename?: 'Mutation'
  noteCreate: {
    __typename?: 'NoteEntity'
    id: number
    text: string
    authorId: number
    model: EntityModel
    entityId: number
    createdAt: any
    updatedAt?: any | null
  }
}

export type UserCreateMutationVariables = Exact<{
  input: UserCreateInput
}>

export type UserCreateMutation = {
  __typename?: 'Mutation'
  userCreate: {
    __typename?: 'UserEntity'
    role: UserRole
    phoneNumber: string
    name: string
    companies: Array<{ __typename?: 'CompanyEntity'; id: number }>
  }
}

export type AddUsersMutationVariables = Exact<{
  userIds: Array<Scalars['Int']['input']> | Scalars['Int']['input']
  companyId: Scalars['Int']['input']
}>

export type AddUsersMutation = {
  __typename?: 'Mutation'
  company: {
    __typename?: 'CompanyMutations'
    addUsers: Array<{
      __typename?: 'UserEntity'
      id: number
      role: UserRole
      status: UserStatus
      phoneNumber: string
      name: string
      email?: string | null
      avatarUrl?: string | null
    }>
  }
}

export type UpdateUserMutationVariables = Exact<{
  input: UserUpdateInput
  userId: Scalars['Int']['input']
}>

export type UpdateUserMutation = {
  __typename?: 'Mutation'
  user: {
    __typename?: 'UserMutations'
    update: {
      __typename?: 'UserEntity'
      role: UserRole
      name: string
      email?: string | null
      id: number
      avatarUrl?: string | null
      phoneNumber: string
    }
  }
}

export type UserActivateMutationVariables = Exact<{
  userId: Scalars['Int']['input']
}>

export type UserActivateMutation = {
  __typename?: 'Mutation'
  user: { __typename?: 'UserMutations'; activate: { __typename?: 'UserEntity'; id: number } }
}

export type UserDeactivateMutationVariables = Exact<{
  userId: Scalars['Int']['input']
}>

export type UserDeactivateMutation = {
  __typename?: 'Mutation'
  user: { __typename?: 'UserMutations'; deactivate: { __typename?: 'UserEntity'; id: number } }
}

export type UserAcceptAccessMutationVariables = Exact<{
  input: AcceptAccessToCompanyInput
  userId: Scalars['Int']['input']
}>

export type UserAcceptAccessMutation = {
  __typename?: 'Mutation'
  user: { __typename?: 'UserMutations'; acceptAccessToCompany: { __typename?: 'UserEntity'; id: number } }
}

export type UserRejectAccessMutationVariables = Exact<{
  input: RejectAccessToCompanyInput
  userId: Scalars['Int']['input']
}>

export type UserRejectAccessMutation = {
  __typename?: 'Mutation'
  user: { __typename?: 'UserMutations'; rejectAccessToCompany: { __typename?: 'UserEntity'; id: number } }
}

export type UserUpdateStateMutationVariables = Exact<{
  input: UpdateStateInput
  userId: Scalars['Int']['input']
}>

export type UserUpdateStateMutation = {
  __typename?: 'Mutation'
  user: { __typename?: 'UserMutations'; updateState: { __typename?: 'UserEntity'; id: number } }
}

export type TransportationDataQueryVariables = Exact<{
  companyId: Scalars['Int']['input']
}>

export type TransportationDataQuery = {
  __typename?: 'Query'
  transportData?: {
    __typename?: 'TransportDataEntity'
    transportModes: Array<TransportMode>
    transportationArea?: TransportationArea | null
    address?: string | null
    regions: Array<{ __typename?: 'RegionEntity'; id: number; name: string }>
  } | null
}

export type RegionsQueryVariables = Exact<{ [key: string]: never }>

export type RegionsQuery = {
  __typename?: 'Query'
  regions: Array<{ __typename?: 'RegionEntity'; id: number; name: string }>
}

export type TransportsQueryVariables = Exact<{
  filter?: InputMaybe<TransportFilterInput>
  pagination?: InputMaybe<PaginationInput>
}>

export type TransportsQuery = {
  __typename?: 'Query'
  transports: {
    __typename?: 'TransportsEntity'
    transports: Array<{
      __typename?: 'TransportEntity'
      id: number
      companyId: number
      transportStationId?: number | null
      type: TransportType
      brand: string
      bodyTypeId?: number | null
      axlesCount: number
      manufacturingDate?: number | null
      vin?: string | null
      registrationCertificate?: string | null
      transportPassport?: string | null
      licensePlate: string
      ownershipType: OwnershipType
      ownerName?: string | null
      lengthCm?: number | null
      widthCm?: number | null
      heightCm?: number | null
      loadCapacityKg?: number | null
      loadVolumeL?: number | null
      loadingMethodId?: number | null
      adrCertificate?: string | null
      shortRouteKm?: number | null
      longRouteKm?: number | null
      preferredRouteType?: PreferredRouteType | null
      canWorkInField?: boolean | null
      additionalDetails?: string | null
      createdAt: any
      updatedAt: any
      deletedAt?: any | null
      bodyType?: { __typename?: 'TransportBodyTypeEntity'; id: number; name: string } | null
      loadingMethod?: { __typename?: 'TransportLoadingMethodEntity'; id: number; name: string } | null
    }>
    pagination: {
      __typename?: 'PaginationEntity'
      currentPage: number
      pageSize: number
      total: number
      totalPages: number
    }
  }
}

export type TransportQueryVariables = Exact<{
  transportId: Scalars['Int']['input']
}>

export type TransportQuery = {
  __typename?: 'Query'
  transport: {
    __typename?: 'TransportEntity'
    id: number
    companyId: number
    transportStationId?: number | null
    type: TransportType
    brand: string
    bodyTypeId?: number | null
    axlesCount: number
    manufacturingDate?: number | null
    vin?: string | null
    registrationCertificate?: string | null
    transportPassport?: string | null
    licensePlate: string
    ownershipType: OwnershipType
    ownerName?: string | null
    lengthCm?: number | null
    widthCm?: number | null
    heightCm?: number | null
    loadCapacityKg?: number | null
    loadVolumeL?: number | null
    loadingMethodId?: number | null
    adrCertificate?: string | null
    shortRouteKm?: number | null
    longRouteKm?: number | null
    preferredRouteType?: PreferredRouteType | null
    canWorkInField?: boolean | null
    additionalDetails?: string | null
    createdAt: any
    updatedAt: any
    deletedAt?: any | null
    bodyType?: { __typename?: 'TransportBodyTypeEntity'; id: number; name: string } | null
    loadingMethod?: { __typename?: 'TransportLoadingMethodEntity'; id: number; name: string } | null
  }
}

export type TransportBodyTypesQueryVariables = Exact<{ [key: string]: never }>

export type TransportBodyTypesQuery = {
  __typename?: 'Query'
  transportBodyTypes: Array<{ __typename?: 'TransportBodyTypeEntity'; id: number; name: string }>
}

export type TransportLoadingMethodsQueryVariables = Exact<{ [key: string]: never }>

export type TransportLoadingMethodsQuery = {
  __typename?: 'Query'
  transportLoadingMethods: Array<{ __typename?: 'TransportLoadingMethodEntity'; id: number; name: string }>
}

export type TransportStationsQueryVariables = Exact<{
  filter?: InputMaybe<TransportStationFilter>
}>

export type TransportStationsQuery = {
  __typename?: 'Query'
  transportStations: Array<{ __typename?: 'TransportStationEntity'; id: number; address: string }>
}

export type TransportDocumentsQueryVariables = Exact<{
  transportId: Scalars['Int']['input']
  filter?: InputMaybe<TransportDocumentFilterInput>
}>

export type TransportDocumentsQuery = {
  __typename?: 'Query'
  transportDocuments: Array<{
    __typename?: 'TransportDocumentEntity'
    type: TransportDocumentType
    instances: Array<{
      __typename?: 'DocumentInstanceEntity'
      id: number
      status: DocumentPartStatus
      createdAt: any
      number?: string | null
      formedAt?: any | null
      rejectionNote?: string | null
      files: Array<{
        __typename?: 'DocumentFileEntity'
        id: number
        status: DocumentPartStatus
        name: string
        size: number
        mimetype: string
        source: string
      }>
    }>
  }>
}

export type CompanyActionsFragment = {
  __typename?: 'CompanyActionsEntity'
  updateLegalInfo: boolean
  updateGeneralInfo: boolean
  sendOnModeration: boolean
  deleteUser: boolean
  delete: boolean
  addUser: boolean
}

export type CompanyStateFragment = {
  __typename?: 'CompanyEntity'
  state: {
    __typename?: 'CompanyStateEntity'
    firstFreightOrderCreated: boolean
    companyVerifiedMessageClosed: boolean
    canUsePriorityCompanies: boolean
  }
}

export type CompanyStateQueryVariables = Exact<{
  companyId: Scalars['Int']['input']
}>

export type CompanyStateQuery = {
  __typename?: 'Query'
  company: {
    __typename?: 'CompanyEntity'
    state: {
      __typename?: 'CompanyStateEntity'
      firstFreightOrderCreated: boolean
      companyVerifiedMessageClosed: boolean
      canUsePriorityCompanies: boolean
    }
  }
}

export type CompanyQueryVariables = Exact<{
  companyId: Scalars['Int']['input']
}>

export type CompanyQuery = {
  __typename?: 'Query'
  company: {
    __typename?: 'CompanyEntity'
    id: number
    inn: string
    kpp?: string | null
    legalName: string
    ogrn?: string | null
    okved?: string | null
    type: CompanyType
    verificationStatus: CompanyVerificationStatus
    phoneNumber?: string | null
    rejectionReason?: string | null
    useNds?: boolean | null
    website?: string | null
    additionalInfo?: string | null
    directorName?: string | null
    verifiedAt?: any | null
    codeOkved?: string | null
    signatory?: string | null
    signatoryPosition?: string | null
    signatoryLegalBasis?: SignatoryLegalBasis | null
    legalAddress?: string | null
    actualAddress?: string | null
    aicCharterMember?: boolean | null
    logoUrl?: string | null
    actions: {
      __typename?: 'CompanyActionsEntity'
      updateLegalInfo: boolean
      updateGeneralInfo: boolean
      sendOnModeration: boolean
      deleteUser: boolean
      delete: boolean
      addUser: boolean
    }
    state: {
      __typename?: 'CompanyStateEntity'
      firstFreightOrderCreated: boolean
      companyVerifiedMessageClosed: boolean
      canUsePriorityCompanies: boolean
    }
  }
}

export type DriversQueryVariables = Exact<{
  filter?: InputMaybe<DriverFilterInput>
  pagination?: InputMaybe<PaginationInput>
}>

export type DriversQuery = {
  __typename?: 'Query'
  drivers: {
    __typename?: 'DriversEntity'
    pagination: {
      __typename?: 'PaginationEntity'
      totalPages: number
      total: number
      pageSize: number
      currentPage: number
    }
    drivers: Array<{
      __typename?: 'DriverEntity'
      id: number
      phoneNumber: string
      name: string
      driverData: {
        __typename?: 'DriverDataEntity'
        birthDate?: any | null
        licenseNumber?: string | null
        licenseIssuedAt?: any | null
        licenseExpiredAt?: any | null
        additionalDetails?: string | null
      }
      transports: Array<{
        __typename?: 'TransportEntity'
        id: number
        companyId: number
        transportStationId?: number | null
        type: TransportType
        brand: string
        bodyTypeId?: number | null
        axlesCount: number
        manufacturingDate?: number | null
        vin?: string | null
        registrationCertificate?: string | null
        transportPassport?: string | null
        licensePlate: string
        ownershipType: OwnershipType
        ownerName?: string | null
        lengthCm?: number | null
        widthCm?: number | null
        heightCm?: number | null
        loadCapacityKg?: number | null
        loadVolumeL?: number | null
        loadingMethodId?: number | null
        adrCertificate?: string | null
        shortRouteKm?: number | null
        longRouteKm?: number | null
        preferredRouteType?: PreferredRouteType | null
        canWorkInField?: boolean | null
        additionalDetails?: string | null
        createdAt: any
        updatedAt: any
        deletedAt?: any | null
        bodyType?: { __typename?: 'TransportBodyTypeEntity'; id: number; name: string } | null
        loadingMethod?: { __typename?: 'TransportLoadingMethodEntity'; id: number; name: string } | null
      }>
    }>
  }
}

export type DriverQueryVariables = Exact<{
  driverId: Scalars['Int']['input']
}>

export type DriverQuery = {
  __typename?: 'Query'
  driver: {
    __typename?: 'DriverEntity'
    phoneNumber: string
    name: string
    id: number
    driverData: {
      __typename?: 'DriverDataEntity'
      birthDate?: any | null
      licenseNumber?: string | null
      licenseIssuedAt?: any | null
      licenseExpiredAt?: any | null
      additionalDetails?: string | null
    }
    transports: Array<{
      __typename?: 'TransportEntity'
      id: number
      companyId: number
      transportStationId?: number | null
      type: TransportType
      brand: string
      bodyTypeId?: number | null
      axlesCount: number
      manufacturingDate?: number | null
      vin?: string | null
      registrationCertificate?: string | null
      transportPassport?: string | null
      licensePlate: string
      ownershipType: OwnershipType
      ownerName?: string | null
      lengthCm?: number | null
      widthCm?: number | null
      heightCm?: number | null
      loadCapacityKg?: number | null
      loadVolumeL?: number | null
      loadingMethodId?: number | null
      adrCertificate?: string | null
      shortRouteKm?: number | null
      longRouteKm?: number | null
      preferredRouteType?: PreferredRouteType | null
      canWorkInField?: boolean | null
      additionalDetails?: string | null
      createdAt: any
      updatedAt: any
      deletedAt?: any | null
      bodyType?: { __typename?: 'TransportBodyTypeEntity'; id: number; name: string } | null
      loadingMethod?: { __typename?: 'TransportLoadingMethodEntity'; id: number; name: string } | null
    }>
  }
}

export type FreightDealDocumentsQueryVariables = Exact<{
  freightDealId: Scalars['Int']['input']
  filter?: InputMaybe<FreightDealDocumentFilterInput>
}>

export type FreightDealDocumentsQuery = {
  __typename?: 'Query'
  freightDealDocuments: Array<{
    __typename?: 'FreightDealDocumentEntity'
    type: FreightDealDocumentType
    instances: Array<{
      __typename?: 'DocumentInstanceEntity'
      id: number
      status: DocumentPartStatus
      createdAt: any
      number?: string | null
      formedAt?: any | null
      rejectionNote?: string | null
      files: Array<{
        __typename?: 'DocumentFileEntity'
        id: number
        status: DocumentPartStatus
        name: string
        size: number
        mimetype: string
        source: string
      }>
    }>
  }>
}

export type FreightDealDocumentsArchiveQueryVariables = Exact<{
  freightDealId: Scalars['Int']['input']
  filter: DocumentsArchiveFilterInput
}>

export type FreightDealDocumentsArchiveQuery = {
  __typename?: 'Query'
  freightDealDocumentsArchive: {
    __typename?: 'FileEntity'
    name: string
    size: number
    mimetype: string
    source: string
  }
}

export type FreightDealsQueryVariables = Exact<{
  filter?: InputMaybe<FreightDealFilterInput>
  pagination?: InputMaybe<PaginationInput>
}>

export type FreightDealsQuery = {
  __typename?: 'Query'
  freightDeals: {
    __typename?: 'FreightDealsEntity'
    freightDeals: Array<{
      __typename?: 'FreightDealEntity'
      id: number
      orderId: number
      dealOrderId: number
      customerId: number
      carrierId: number
      status: FreightDealStatus
      type: FreightDealType
      createdById: number
      startedAt?: any | null
      rejectedAt?: any | null
      rejectedById?: number | null
      rejectionReason?: string | null
      isRealisedByCustomer?: boolean | null
      isRealisedByCarrier?: boolean | null
      isAgreedByCustomer?: boolean | null
      isAgreedByCarrier?: boolean | null
      releasedAt?: any | null
      additionalDetails?: string | null
      createdAt: any
      updatedAt: any
      nextStatuses: Array<FreightDealStatus>
      lastChangeRequests: Array<{
        __typename?: 'FreightOrderChangeRequestEntity'
        id: number
        type: FreightOrderChangeRequestType
        status: FreightOrderChangeRequestStatus
        updatedAt: any
        createdByCompanyId: number
        endedByCompanyId?: number | null
        additionalDetails?: string | null
        nextStatuses: Array<FreightOrderChangeRequestStatus>
        isViewedByCounterparty: boolean
        changedFields: Array<{
          __typename?: 'FreightOrderFieldChangeEntity'
          value: string
          fieldName: FreightOrderFieldName
        }>
      }>
      order: {
        __typename?: 'FreightOrderEntity'
        id: number
        companyId: number
        routeId: number
        readinessType: FreightReadinessType
        startedAt?: any | null
        endedAt?: any | null
        startDaysCount?: number | null
        transportFormatTypes: Array<TransportFormatType>
        weightKg: number
        selectedWeightKg: number
        priceWithoutNds: number
        priceWithNds: number
        availableWeightKg: number
        pricePerTonWithoutNds: number
        pricePerTonWithNds: number
        isAuction: boolean
        paymentTerm: FreightPaymentTerm
        advancePercent?: number | null
        deferredDaysCount?: number | null
        contactPerson: string
        contactPhoneNumber: string
        additionalDetails?: string | null
        createdAt: any
        updatedAt: any
        deletedAt?: any | null
        canCreateResponse: boolean
        transportBodyTypes: Array<{ __typename?: 'TransportBodyTypeEntity'; id: number; name: string }>
        route: {
          __typename?: 'RouteEntity'
          id: number
          distance: number
          createdAt: any
          updatedAt: any
          points: Array<{
            __typename?: 'RoutePointEntity'
            id: number
            addressId: number
            type: RoutePointType
            freightId?: number | null
            roadTransportParametersId: number
            createdAt: any
            updatedAt: any
            address: { __typename?: 'RoutePointAddressEntity'; full: string; short: string }
            freight?: {
              __typename?: 'FreightEntity'
              id: number
              packingType: FreightPackingType
              packingTypeCustom?: string | null
              bigBagsCount?: number | null
              productTypeId: number
              weightKg: number
              createdAt: any
              updatedAt: any
              productType: { __typename?: 'ProductTypeEntity'; id: number; name: string; type: string; gost: string }
            } | null
            roadTransportParameters: {
              __typename?: 'RoadTransportParametersEntity'
              id: number
              loadingTypes: Array<LoadingTransportType>
              unloadingTypes: Array<UnloadingTransportType>
              loadingTypeCustom?: string | null
              unloadingTypeCustom?: string | null
              loadingThroughput?: number | null
              unloadingThroughput?: number | null
              vehicleLengthMaxM?: number | null
              vehicleHeightMaxM?: number | null
              vehicleClearanceMinCm?: number | null
              hasParking?: boolean | null
              hasScales?: boolean | null
              hasWeighingAutomation?: boolean | null
              distanceToScalesM?: number | null
              scalesWeightLimitT?: number | null
              roadSurfaceType?: RoadSurfaceType | null
              roadSurfaceQuality?: RoadSurfaceQuality | null
              additionalDetails?: string | null
              createdAt: any
              updatedAt?: any | null
            }
          }>
        }
        responsesStatistics?: {
          __typename?: 'FreightOrderResponsesStatisticsEntity'
          previousDays: { __typename?: 'FreightOrderResponsesCountEntity'; betsCount: number; responsesCount: number }
          today: { __typename?: 'FreightOrderResponsesCountEntity'; betsCount: number; responsesCount: number }
        } | null
      }
      customer: {
        __typename?: 'CompanyEntity'
        id: number
        countryName: CountryName
        legalForm: string
        inn: string
        kpp?: string | null
        legalName: string
        ogrn?: string | null
        okved?: string | null
        type: CompanyType
        verificationStatus: CompanyVerificationStatus
        phoneNumber?: string | null
        rejectionReason?: string | null
        useNds?: boolean | null
        website?: string | null
        additionalInfo?: string | null
        directorName?: string | null
        verifiedAt?: any | null
        codeOkved?: string | null
        signatory?: string | null
        signatoryPosition?: string | null
        signatoryLegalBasis?: SignatoryLegalBasis | null
        legalAddress?: string | null
        actualAddress?: string | null
        aicCharterMember?: boolean | null
        logoUrl?: string | null
      }
      dealOrder: {
        __typename?: 'FreightOrderEntity'
        id: number
        companyId: number
        routeId: number
        readinessType: FreightReadinessType
        startedAt?: any | null
        endedAt?: any | null
        startDaysCount?: number | null
        transportFormatTypes: Array<TransportFormatType>
        weightKg: number
        selectedWeightKg: number
        priceWithoutNds: number
        priceWithNds: number
        availableWeightKg: number
        pricePerTonWithoutNds: number
        pricePerTonWithNds: number
        isAuction: boolean
        paymentTerm: FreightPaymentTerm
        advancePercent?: number | null
        deferredDaysCount?: number | null
        contactPerson: string
        contactPhoneNumber: string
        additionalDetails?: string | null
        createdAt: any
        updatedAt: any
        deletedAt?: any | null
        canCreateResponse: boolean
        transportBodyTypes: Array<{ __typename?: 'TransportBodyTypeEntity'; id: number; name: string }>
      }
    }>
    pagination: {
      __typename?: 'PaginationEntity'
      currentPage: number
      pageSize: number
      total: number
      totalPages: number
    }
  }
}

export type FreightDealQueryVariables = Exact<{
  freightDealId: Scalars['Int']['input']
}>

export type FreightDealQuery = {
  __typename?: 'Query'
  freightDeal: {
    __typename?: 'FreightDealEntity'
    id: number
    orderId: number
    dealOrderId: number
    customerId: number
    carrierId: number
    status: FreightDealStatus
    type: FreightDealType
    createdById: number
    startedAt?: any | null
    rejectedAt?: any | null
    rejectedById?: number | null
    rejectionReason?: string | null
    isRealisedByCustomer?: boolean | null
    isRealisedByCarrier?: boolean | null
    isAgreedByCustomer?: boolean | null
    isAgreedByCarrier?: boolean | null
    releasedAt?: any | null
    additionalDetails?: string | null
    createdAt: any
    updatedAt: any
    nextStatuses: Array<FreightDealStatus>
    order: {
      __typename?: 'FreightOrderEntity'
      id: number
      companyId: number
      routeId: number
      readinessType: FreightReadinessType
      startedAt?: any | null
      endedAt?: any | null
      startDaysCount?: number | null
      transportFormatTypes: Array<TransportFormatType>
      weightKg: number
      selectedWeightKg: number
      priceWithoutNds: number
      priceWithNds: number
      availableWeightKg: number
      pricePerTonWithoutNds: number
      pricePerTonWithNds: number
      isAuction: boolean
      paymentTerm: FreightPaymentTerm
      advancePercent?: number | null
      deferredDaysCount?: number | null
      contactPerson: string
      contactPhoneNumber: string
      additionalDetails?: string | null
      createdAt: any
      updatedAt: any
      deletedAt?: any | null
      transportBodyTypes: Array<{ __typename?: 'TransportBodyTypeEntity'; id: number; name: string }>
      route: {
        __typename?: 'RouteEntity'
        id: number
        distance: number
        createdAt: any
        updatedAt: any
        points: Array<{
          __typename?: 'RoutePointEntity'
          id: number
          addressId: number
          type: RoutePointType
          freightId?: number | null
          roadTransportParametersId: number
          createdAt: any
          updatedAt: any
          address: { __typename?: 'RoutePointAddressEntity'; full: string; short: string }
          freight?: {
            __typename?: 'FreightEntity'
            id: number
            packingType: FreightPackingType
            packingTypeCustom?: string | null
            bigBagsCount?: number | null
            productTypeId: number
            weightKg: number
            createdAt: any
            updatedAt: any
            productType: { __typename?: 'ProductTypeEntity'; id: number; name: string; type: string; gost: string }
          } | null
          roadTransportParameters: {
            __typename?: 'RoadTransportParametersEntity'
            id: number
            loadingTypes: Array<LoadingTransportType>
            unloadingTypes: Array<UnloadingTransportType>
            loadingTypeCustom?: string | null
            unloadingTypeCustom?: string | null
            loadingThroughput?: number | null
            unloadingThroughput?: number | null
            vehicleLengthMaxM?: number | null
            vehicleHeightMaxM?: number | null
            vehicleClearanceMinCm?: number | null
            hasParking?: boolean | null
            hasScales?: boolean | null
            hasWeighingAutomation?: boolean | null
            distanceToScalesM?: number | null
            scalesWeightLimitT?: number | null
            roadSurfaceType?: RoadSurfaceType | null
            roadSurfaceQuality?: RoadSurfaceQuality | null
            additionalDetails?: string | null
            createdAt: any
            updatedAt?: any | null
          }
        }>
      }
    }
    dealOrder: {
      __typename?: 'FreightOrderEntity'
      id: number
      companyId: number
      routeId: number
      readinessType: FreightReadinessType
      startedAt?: any | null
      endedAt?: any | null
      startDaysCount?: number | null
      transportFormatTypes: Array<TransportFormatType>
      weightKg: number
      selectedWeightKg: number
      priceWithoutNds: number
      priceWithNds: number
      availableWeightKg: number
      pricePerTonWithoutNds: number
      pricePerTonWithNds: number
      isAuction: boolean
      paymentTerm: FreightPaymentTerm
      advancePercent?: number | null
      deferredDaysCount?: number | null
      contactPerson: string
      contactPhoneNumber: string
      additionalDetails?: string | null
      createdAt: any
      updatedAt: any
      deletedAt?: any | null
      transportBodyTypes: Array<{ __typename?: 'TransportBodyTypeEntity'; id: number; name: string }>
      route: {
        __typename?: 'RouteEntity'
        id: number
        distance: number
        createdAt: any
        updatedAt: any
        points: Array<{
          __typename?: 'RoutePointEntity'
          id: number
          addressId: number
          type: RoutePointType
          freightId?: number | null
          roadTransportParametersId: number
          createdAt: any
          updatedAt: any
          address: { __typename?: 'RoutePointAddressEntity'; full: string; short: string }
          freight?: {
            __typename?: 'FreightEntity'
            id: number
            packingType: FreightPackingType
            packingTypeCustom?: string | null
            bigBagsCount?: number | null
            productTypeId: number
            weightKg: number
            createdAt: any
            updatedAt: any
            productType: { __typename?: 'ProductTypeEntity'; id: number; name: string; type: string; gost: string }
          } | null
          roadTransportParameters: {
            __typename?: 'RoadTransportParametersEntity'
            id: number
            loadingTypes: Array<LoadingTransportType>
            unloadingTypes: Array<UnloadingTransportType>
            loadingTypeCustom?: string | null
            unloadingTypeCustom?: string | null
            loadingThroughput?: number | null
            unloadingThroughput?: number | null
            vehicleLengthMaxM?: number | null
            vehicleHeightMaxM?: number | null
            vehicleClearanceMinCm?: number | null
            hasParking?: boolean | null
            hasScales?: boolean | null
            hasWeighingAutomation?: boolean | null
            distanceToScalesM?: number | null
            scalesWeightLimitT?: number | null
            roadSurfaceType?: RoadSurfaceType | null
            roadSurfaceQuality?: RoadSurfaceQuality | null
            additionalDetails?: string | null
            createdAt: any
            updatedAt?: any | null
          }
        }>
      }
    }
    customer: {
      __typename?: 'CompanyEntity'
      id: number
      countryName: CountryName
      legalForm: string
      inn: string
      kpp?: string | null
      legalName: string
      ogrn?: string | null
      okved?: string | null
      type: CompanyType
      verificationStatus: CompanyVerificationStatus
      phoneNumber?: string | null
      rejectionReason?: string | null
      useNds?: boolean | null
      website?: string | null
      additionalInfo?: string | null
      directorName?: string | null
      verifiedAt?: any | null
      codeOkved?: string | null
      signatory?: string | null
      signatoryPosition?: string | null
      signatoryLegalBasis?: SignatoryLegalBasis | null
      legalAddress?: string | null
      actualAddress?: string | null
      aicCharterMember?: boolean | null
      logoUrl?: string | null
      bankAccounts: Array<{
        __typename?: 'BankAccountEntity'
        id: number
        companyId?: number | null
        bankName: string
        bic: string
        correspondentAccount: string
        paymentAccount: string
        createdAt: any
        actions: { __typename?: 'BankAccountActionsEntity'; delete: boolean }
      }>
      users: Array<{
        __typename?: 'UserEntity'
        id: number
        phoneNumber: string
        status: UserStatus
        email?: string | null
        name: string
        position?: string | null
        role: UserRole
        createdAt: any
        deactivatedAt?: any | null
        authenticatedAt: any
        avatarUrl?: string | null
      }>
      actions: {
        __typename?: 'CompanyActionsEntity'
        addUser: boolean
        delete: boolean
        deleteUser: boolean
        sendOnModeration: boolean
        updateGeneralInfo: boolean
        updateLegalInfo: boolean
      }
      state: {
        __typename?: 'CompanyStateEntity'
        companyVerifiedMessageClosed: boolean
        canUsePriorityCompanies: boolean
      }
      verificationRequirements: {
        __typename?: 'CompanyVerificationRequirementsEntity'
        requisites?: boolean | null
        bankAccount?: boolean | null
        documents?: boolean | null
      }
    }
    carrier: {
      __typename?: 'CompanyEntity'
      id: number
      countryName: CountryName
      legalForm: string
      inn: string
      kpp?: string | null
      legalName: string
      ogrn?: string | null
      okved?: string | null
      type: CompanyType
      verificationStatus: CompanyVerificationStatus
      phoneNumber?: string | null
      rejectionReason?: string | null
      useNds?: boolean | null
      website?: string | null
      additionalInfo?: string | null
      directorName?: string | null
      verifiedAt?: any | null
      codeOkved?: string | null
      signatory?: string | null
      signatoryPosition?: string | null
      signatoryLegalBasis?: SignatoryLegalBasis | null
      legalAddress?: string | null
      actualAddress?: string | null
      aicCharterMember?: boolean | null
      logoUrl?: string | null
      bankAccounts: Array<{
        __typename?: 'BankAccountEntity'
        id: number
        companyId?: number | null
        bankName: string
        bic: string
        correspondentAccount: string
        paymentAccount: string
        createdAt: any
        actions: { __typename?: 'BankAccountActionsEntity'; delete: boolean }
      }>
      users: Array<{
        __typename?: 'UserEntity'
        id: number
        phoneNumber: string
        status: UserStatus
        email?: string | null
        name: string
        position?: string | null
        role: UserRole
        createdAt: any
        deactivatedAt?: any | null
        authenticatedAt: any
        avatarUrl?: string | null
        createdBy?: {
          __typename?: 'UserEntity'
          id: number
          phoneNumber: string
          status: UserStatus
          email?: string | null
          name: string
          position?: string | null
          role: UserRole
          createdAt: any
          deactivatedAt?: any | null
          authenticatedAt: any
          avatarUrl?: string | null
        } | null
        companies: Array<{
          __typename?: 'CompanyEntity'
          id: number
          countryName: CountryName
          legalForm: string
          inn: string
          kpp?: string | null
          legalName: string
          ogrn?: string | null
          okved?: string | null
          type: CompanyType
          verificationStatus: CompanyVerificationStatus
          phoneNumber?: string | null
          rejectionReason?: string | null
          useNds?: boolean | null
          website?: string | null
          additionalInfo?: string | null
          directorName?: string | null
          verifiedAt?: any | null
          codeOkved?: string | null
          signatory?: string | null
          signatoryPosition?: string | null
          signatoryLegalBasis?: SignatoryLegalBasis | null
          legalAddress?: string | null
          actualAddress?: string | null
          aicCharterMember?: boolean | null
          logoUrl?: string | null
          bankAccounts: Array<{
            __typename?: 'BankAccountEntity'
            id: number
            companyId?: number | null
            bankName: string
            bic: string
            correspondentAccount: string
            paymentAccount: string
            createdAt: any
            actions: { __typename?: 'BankAccountActionsEntity'; delete: boolean }
          }>
          users: Array<{
            __typename?: 'UserEntity'
            id: number
            phoneNumber: string
            status: UserStatus
            email?: string | null
            name: string
            position?: string | null
            role: UserRole
            createdAt: any
            deactivatedAt?: any | null
            authenticatedAt: any
            avatarUrl?: string | null
            createdBy?: {
              __typename?: 'UserEntity'
              id: number
              phoneNumber: string
              status: UserStatus
              email?: string | null
              name: string
              position?: string | null
              role: UserRole
              createdAt: any
              deactivatedAt?: any | null
              authenticatedAt: any
              avatarUrl?: string | null
            } | null
            companies: Array<{
              __typename?: 'CompanyEntity'
              id: number
              countryName: CountryName
              legalForm: string
              inn: string
              kpp?: string | null
              legalName: string
              ogrn?: string | null
              okved?: string | null
              type: CompanyType
              verificationStatus: CompanyVerificationStatus
              phoneNumber?: string | null
              rejectionReason?: string | null
              useNds?: boolean | null
              website?: string | null
              additionalInfo?: string | null
              directorName?: string | null
              verifiedAt?: any | null
              codeOkved?: string | null
              signatory?: string | null
              signatoryPosition?: string | null
              signatoryLegalBasis?: SignatoryLegalBasis | null
              legalAddress?: string | null
              actualAddress?: string | null
              aicCharterMember?: boolean | null
              logoUrl?: string | null
            }>
            actions: {
              __typename?: 'UserActionsEntity'
              activate: boolean
              deactivate: boolean
              update: boolean
              updateAvatar: boolean
            }
          }>
          actions: {
            __typename?: 'CompanyActionsEntity'
            addUser: boolean
            delete: boolean
            deleteUser: boolean
            sendOnModeration: boolean
            updateGeneralInfo: boolean
            updateLegalInfo: boolean
          }
          state: {
            __typename?: 'CompanyStateEntity'
            companyVerifiedMessageClosed: boolean
            canUsePriorityCompanies: boolean
          }
          verificationRequirements: {
            __typename?: 'CompanyVerificationRequirementsEntity'
            requisites?: boolean | null
            bankAccount?: boolean | null
            documents?: boolean | null
          }
        }>
        actions: {
          __typename?: 'UserActionsEntity'
          activate: boolean
          deactivate: boolean
          update: boolean
          updateAvatar: boolean
        }
      }>
      actions: {
        __typename?: 'CompanyActionsEntity'
        addUser: boolean
        delete: boolean
        deleteUser: boolean
        sendOnModeration: boolean
        updateGeneralInfo: boolean
        updateLegalInfo: boolean
      }
      state: {
        __typename?: 'CompanyStateEntity'
        companyVerifiedMessageClosed: boolean
        canUsePriorityCompanies: boolean
      }
      verificationRequirements: {
        __typename?: 'CompanyVerificationRequirementsEntity'
        requisites?: boolean | null
        bankAccount?: boolean | null
        documents?: boolean | null
      }
    }
    creator: {
      __typename?: 'UserEntity'
      id: number
      phoneNumber: string
      status: UserStatus
      email?: string | null
      name: string
      position?: string | null
      role: UserRole
      createdAt: any
      deactivatedAt?: any | null
      authenticatedAt: any
      avatarUrl?: string | null
      createdBy?: {
        __typename?: 'UserEntity'
        id: number
        phoneNumber: string
        status: UserStatus
        email?: string | null
        name: string
        position?: string | null
        role: UserRole
        createdAt: any
        deactivatedAt?: any | null
        authenticatedAt: any
        avatarUrl?: string | null
      } | null
      companies: Array<{
        __typename?: 'CompanyEntity'
        id: number
        countryName: CountryName
        legalForm: string
        inn: string
        kpp?: string | null
        legalName: string
        ogrn?: string | null
        okved?: string | null
        type: CompanyType
        verificationStatus: CompanyVerificationStatus
        phoneNumber?: string | null
        rejectionReason?: string | null
        useNds?: boolean | null
        website?: string | null
        additionalInfo?: string | null
        directorName?: string | null
        verifiedAt?: any | null
        codeOkved?: string | null
        signatory?: string | null
        signatoryPosition?: string | null
        signatoryLegalBasis?: SignatoryLegalBasis | null
        legalAddress?: string | null
        actualAddress?: string | null
        aicCharterMember?: boolean | null
        logoUrl?: string | null
      }>
      actions: {
        __typename?: 'UserActionsEntity'
        activate: boolean
        deactivate: boolean
        update: boolean
        updateAvatar: boolean
      }
    }
    rejectedBy?: {
      __typename?: 'UserEntity'
      id: number
      phoneNumber: string
      status: UserStatus
      email?: string | null
      name: string
      position?: string | null
      role: UserRole
      createdAt: any
      deactivatedAt?: any | null
      authenticatedAt: any
      avatarUrl?: string | null
    } | null
    lastChangeRequests: Array<{
      __typename?: 'FreightOrderChangeRequestEntity'
      id: number
      type: FreightOrderChangeRequestType
      status: FreightOrderChangeRequestStatus
      updatedAt: any
      createdByCompanyId: number
      endedByCompanyId?: number | null
      additionalDetails?: string | null
      nextStatuses: Array<FreightOrderChangeRequestStatus>
      isViewedByCounterparty: boolean
      changedFields: Array<{
        __typename?: 'FreightOrderFieldChangeEntity'
        value: string
        fieldName: FreightOrderFieldName
      }>
    }>
  }
}

export type FreightOrderChangeRequestBlocksQueryVariables = Exact<{
  freightDealId: Scalars['Int']['input']
  filter?: InputMaybe<FreightOrderChangeRequestBlockFilterInput>
}>

export type FreightOrderChangeRequestBlocksQuery = {
  __typename?: 'Query'
  freightOrderChangeRequestBlocks: Array<{
    __typename?: 'FreightOrderChangeRequestBlockEntity'
    id: number
    type: FreightOrderChangeRequestBlockType
    updatedAt: any
    changeRequests: Array<{
      __typename?: 'FreightOrderChangeRequestEntity'
      type: FreightOrderChangeRequestType
      status: FreightOrderChangeRequestStatus
      nextStatuses: Array<FreightOrderChangeRequestStatus>
      endedByCompanyId?: number | null
      createdByCompanyId: number
      additionalDetails?: string | null
      id: number
      updatedAt: any
      changedFields: Array<{
        __typename?: 'FreightOrderFieldChangeEntity'
        fieldName: FreightOrderFieldName
        value: string
      }>
      createdByCompany: { __typename?: 'CompanyEntity'; legalName: string }
    }>
  }>
}

export type FreightOrderDocumentsQueryVariables = Exact<{
  freightOrderId: Scalars['Int']['input']
  filter?: InputMaybe<FreightOrderDocumentFilterInput>
}>

export type FreightOrderDocumentsQuery = {
  __typename?: 'Query'
  freightOrderDocuments: Array<{
    __typename?: 'FreightOrderDocumentEntity'
    type: FreightOrderDocumentType
    instances: Array<{
      __typename?: 'DocumentInstanceEntity'
      status: DocumentPartStatus
      rejectionNote?: string | null
      number?: string | null
      id: number
      formedAt?: any | null
      createdAt: any
      files: Array<{
        __typename?: 'DocumentFileEntity'
        name: string
        size: number
        mimetype: string
        source: string
        id: number
        status: DocumentPartStatus
      }>
    }>
  }>
}

export type FreightOrderDocumentsArchiveQueryVariables = Exact<{
  freightOrderId: Scalars['Int']['input']
  filter?: InputMaybe<DocumentsArchiveFilterInput>
}>

export type FreightOrderDocumentsArchiveQuery = {
  __typename?: 'Query'
  freightOrderDocumentsArchive: {
    __typename?: 'FileEntity'
    name: string
    size: number
    mimetype: string
    source: string
  }
}

export type FreightOrderFieldInitialValuesQueryVariables = Exact<{
  freightDealId: Scalars['Int']['input']
}>

export type FreightOrderFieldInitialValuesQuery = {
  __typename?: 'Query'
  freightOrderFieldInitialValues: Array<{
    __typename?: 'FreightOrderFieldChangeEntity'
    value: string
    fieldName: FreightOrderFieldName
  }>
}

export type FreightOrderResponsesQueryVariables = Exact<{
  filter?: InputMaybe<FreightOrderResponseFilterInput>
  pagination?: InputMaybe<PaginationInput>
}>

export type FreightOrderResponsesQuery = {
  __typename?: 'Query'
  freightOrderResponses: {
    __typename?: 'FreightOrderResponsesEntity'
    freightOrderResponses: Array<{
      __typename?: 'FreightOrderResponseEntity'
      id: number
      orderId: number
      status: FreightDealStatus
      type: FreightDealType
      additionalDetails?: string | null
      createdAt: any
      nextStatuses: Array<FreightDealStatus>
      order: {
        __typename?: 'FreightOrderEntity'
        id: number
        companyId: number
        routeId: number
        readinessType: FreightReadinessType
        startedAt?: any | null
        endedAt?: any | null
        startDaysCount?: number | null
        transportFormatTypes: Array<TransportFormatType>
        weightKg: number
        selectedWeightKg: number
        priceWithoutNds: number
        priceWithNds: number
        availableWeightKg: number
        pricePerTonWithoutNds: number
        pricePerTonWithNds: number
        isAuction: boolean
        paymentTerm: FreightPaymentTerm
        advancePercent?: number | null
        deferredDaysCount?: number | null
        contactPerson: string
        contactPhoneNumber: string
        additionalDetails?: string | null
        createdAt: any
        updatedAt: any
        deletedAt?: any | null
        canCreateResponse: boolean
        transportBodyTypes: Array<{ __typename?: 'TransportBodyTypeEntity'; id: number; name: string }>
        route: {
          __typename?: 'RouteEntity'
          id: number
          distance: number
          distanceToStartKm?: number | null
          createdAt: any
          updatedAt: any
          points: Array<{
            __typename?: 'RoutePointEntity'
            id: number
            addressId: number
            type: RoutePointType
            freightId?: number | null
            roadTransportParametersId: number
            createdAt: any
            updatedAt: any
            address: { __typename?: 'RoutePointAddressEntity'; full: string; short: string }
            freight?: {
              __typename?: 'FreightEntity'
              id: number
              packingType: FreightPackingType
              packingTypeCustom?: string | null
              bigBagsCount?: number | null
              productTypeId: number
              weightKg: number
              createdAt: any
              updatedAt: any
              productType: { __typename?: 'ProductTypeEntity'; id: number; name: string; type: string; gost: string }
            } | null
            roadTransportParameters: {
              __typename?: 'RoadTransportParametersEntity'
              id: number
              loadingTypes: Array<LoadingTransportType>
              unloadingTypes: Array<UnloadingTransportType>
              loadingTypeCustom?: string | null
              unloadingTypeCustom?: string | null
              loadingThroughput?: number | null
              unloadingThroughput?: number | null
              vehicleLengthMaxM?: number | null
              vehicleHeightMaxM?: number | null
              vehicleClearanceMinCm?: number | null
              hasParking?: boolean | null
              hasScales?: boolean | null
              hasWeighingAutomation?: boolean | null
              distanceToScalesM?: number | null
              scalesWeightLimitT?: number | null
              roadSurfaceType?: RoadSurfaceType | null
              roadSurfaceQuality?: RoadSurfaceQuality | null
              additionalDetails?: string | null
              createdAt: any
              updatedAt?: any | null
            }
          }>
        }
        responsesStatistics?: {
          __typename?: 'FreightOrderResponsesStatisticsEntity'
          previousDays: { __typename?: 'FreightOrderResponsesCountEntity'; betsCount: number; responsesCount: number }
          today: { __typename?: 'FreightOrderResponsesCountEntity'; betsCount: number; responsesCount: number }
        } | null
      }
      dealOrder: {
        __typename?: 'FreightOrderResponseOrderEntity'
        startedAt?: any | null
        weightKg: number
        priceWithoutNds: number
        priceWithNds: number
        pricePerTonWithoutNds: number
        pricePerTonWithNds: number
        paymentTerm: FreightPaymentTerm
      }
    }>
    pagination: {
      __typename?: 'PaginationEntity'
      currentPage: number
      pageSize: number
      total: number
      totalPages: number
    }
  }
}

export type FreightOrdersQueryVariables = Exact<{
  filter?: InputMaybe<FreightOrderFilterInput>
  pagination?: InputMaybe<PaginationInput>
}>

export type FreightOrdersQuery = {
  __typename?: 'Query'
  freightOrders: {
    __typename?: 'FreightOrdersEntity'
    freightOrders: Array<{
      __typename?: 'FreightOrderEntity'
      id: number
      companyId: number
      routeId: number
      readinessType: FreightReadinessType
      startedAt?: any | null
      endedAt?: any | null
      startDaysCount?: number | null
      transportFormatTypes: Array<TransportFormatType>
      weightKg: number
      selectedWeightKg: number
      priceWithoutNds: number
      priceWithNds: number
      availableWeightKg: number
      pricePerTonWithoutNds: number
      pricePerTonWithNds: number
      isAuction: boolean
      paymentTerm: FreightPaymentTerm
      advancePercent?: number | null
      deferredDaysCount?: number | null
      contactPerson: string
      contactPhoneNumber: string
      additionalDetails?: string | null
      createdAt: any
      updatedAt: any
      deletedAt?: any | null
      canCreateResponse: boolean
      transportBodyTypes: Array<{ __typename?: 'TransportBodyTypeEntity'; id: number; name: string }>
      route: {
        __typename?: 'RouteEntity'
        id: number
        distance: number
        distanceToStartKm?: number | null
        createdAt: any
        updatedAt: any
        points: Array<{
          __typename?: 'RoutePointEntity'
          id: number
          addressId: number
          type: RoutePointType
          freightId?: number | null
          roadTransportParametersId: number
          createdAt: any
          updatedAt: any
          address: { __typename?: 'RoutePointAddressEntity'; full: string; short: string }
          freight?: {
            __typename?: 'FreightEntity'
            id: number
            packingType: FreightPackingType
            packingTypeCustom?: string | null
            bigBagsCount?: number | null
            productTypeId: number
            weightKg: number
            createdAt: any
            updatedAt: any
            productType: { __typename?: 'ProductTypeEntity'; id: number; name: string; type: string; gost: string }
          } | null
          roadTransportParameters: {
            __typename?: 'RoadTransportParametersEntity'
            id: number
            loadingTypes: Array<LoadingTransportType>
            unloadingTypes: Array<UnloadingTransportType>
            loadingTypeCustom?: string | null
            unloadingTypeCustom?: string | null
            loadingThroughput?: number | null
            unloadingThroughput?: number | null
            vehicleLengthMaxM?: number | null
            vehicleHeightMaxM?: number | null
            vehicleClearanceMinCm?: number | null
            hasParking?: boolean | null
            hasScales?: boolean | null
            hasWeighingAutomation?: boolean | null
            distanceToScalesM?: number | null
            scalesWeightLimitT?: number | null
            roadSurfaceType?: RoadSurfaceType | null
            roadSurfaceQuality?: RoadSurfaceQuality | null
            additionalDetails?: string | null
            createdAt: any
            updatedAt?: any | null
          }
        }>
      }
      responsesStatistics?: {
        __typename?: 'FreightOrderResponsesStatisticsEntity'
        previousDays: { __typename?: 'FreightOrderResponsesCountEntity'; betsCount: number; responsesCount: number }
        today: { __typename?: 'FreightOrderResponsesCountEntity'; betsCount: number; responsesCount: number }
      } | null
    }>
    pagination: {
      __typename?: 'PaginationEntity'
      currentPage: number
      pageSize: number
      total: number
      totalPages: number
    }
  }
}

export type FreightOrderQueryVariables = Exact<{
  freightOrderId: Scalars['Int']['input']
}>

export type FreightOrderQuery = {
  __typename?: 'Query'
  freightOrder: {
    __typename?: 'FreightOrderEntity'
    id: number
    companyId: number
    routeId: number
    readinessType: FreightReadinessType
    startedAt?: any | null
    endedAt?: any | null
    startDaysCount?: number | null
    transportFormatTypes: Array<TransportFormatType>
    priceWithoutNds: number
    priceWithNds: number
    availableWeightKg: number
    pricePerTonWithoutNds: number
    pricePerTonWithNds: number
    isAuction: boolean
    paymentTerm: FreightPaymentTerm
    advancePercent?: number | null
    deferredDaysCount?: number | null
    contactPerson: string
    contactPhoneNumber: string
    additionalDetails?: string | null
    createdAt: any
    updatedAt: any
    deletedAt?: any | null
    selectedWeightKg: number
    weightKg: number
    canCreateResponse: boolean
    transportBodyTypes: Array<{ __typename?: 'TransportBodyTypeEntity'; id: number; name: string }>
    route: {
      __typename?: 'RouteEntity'
      id: number
      distance: number
      distanceToStartKm?: number | null
      createdAt: any
      updatedAt: any
      points: Array<{
        __typename?: 'RoutePointEntity'
        id: number
        addressId: number
        type: RoutePointType
        freightId?: number | null
        roadTransportParametersId: number
        createdAt: any
        updatedAt: any
        address: { __typename?: 'RoutePointAddressEntity'; full: string; short: string }
        freight?: {
          __typename?: 'FreightEntity'
          id: number
          packingType: FreightPackingType
          packingTypeCustom?: string | null
          bigBagsCount?: number | null
          productTypeId: number
          weightKg: number
          createdAt: any
          updatedAt: any
          productType: { __typename?: 'ProductTypeEntity'; id: number; name: string; type: string; gost: string }
        } | null
        roadTransportParameters: {
          __typename?: 'RoadTransportParametersEntity'
          id: number
          loadingTypes: Array<LoadingTransportType>
          unloadingTypes: Array<UnloadingTransportType>
          loadingTypeCustom?: string | null
          unloadingTypeCustom?: string | null
          loadingThroughput?: number | null
          unloadingThroughput?: number | null
          vehicleLengthMaxM?: number | null
          vehicleHeightMaxM?: number | null
          vehicleClearanceMinCm?: number | null
          hasParking?: boolean | null
          hasScales?: boolean | null
          hasWeighingAutomation?: boolean | null
          distanceToScalesM?: number | null
          scalesWeightLimitT?: number | null
          roadSurfaceType?: RoadSurfaceType | null
          roadSurfaceQuality?: RoadSurfaceQuality | null
          additionalDetails?: string | null
          createdAt: any
          updatedAt?: any | null
        }
      }>
    }
    responsesStatistics?: {
      __typename?: 'FreightOrderResponsesStatisticsEntity'
      previousDays: { __typename?: 'FreightOrderResponsesCountEntity'; betsCount: number; responsesCount: number }
      today: { __typename?: 'FreightOrderResponsesCountEntity'; betsCount: number; responsesCount: number }
    } | null
  }
}

export type GetMeQueryQueryVariables = Exact<{ [key: string]: never }>

export type GetMeQueryQuery = {
  __typename?: 'Query'
  me: {
    __typename?: 'MeEntity'
    id: number
    name: string
    email?: string | null
    position?: string | null
    phoneNumber: string
    role: UserRole
    avatarUrl?: string | null
    activeCompanyId: number
    companies: Array<{ __typename?: 'CompanyEntity'; id: number }>
    actions: { __typename?: 'MeActionsEntity'; createCompany: boolean; createUser: boolean }
    state: { __typename?: 'MeStateEntity'; multiCompanyProfileGuideShown: boolean }
    moderator?: { __typename?: 'UserEntity'; id: number; name: string } | null
  }
}

export type GetMeStateQueryQueryVariables = Exact<{ [key: string]: never }>

export type GetMeStateQueryQuery = {
  __typename?: 'Query'
  me: {
    __typename?: 'MeEntity'
    state: { __typename?: 'MeStateEntity'; multiCompanyProfileGuideShown: boolean; isShowEmployeesGuide: boolean }
  }
}

export type GetMeCompaniesQueryQueryVariables = Exact<{ [key: string]: never }>

export type GetMeCompaniesQueryQuery = {
  __typename?: 'Query'
  me: {
    __typename?: 'MeEntity'
    companies: Array<{
      __typename?: 'CompanyEntity'
      id: number
      inn: string
      kpp?: string | null
      legalName: string
      ogrn?: string | null
      okved?: string | null
      type: CompanyType
      signatory?: string | null
      signatoryLegalBasis?: SignatoryLegalBasis | null
      signatoryPosition?: string | null
      aicCharterMember?: boolean | null
      verificationStatus: CompanyVerificationStatus
      phoneNumber?: string | null
      rejectionReason?: string | null
      useNds?: boolean | null
      website?: string | null
      logoUrl?: string | null
      additionalInfo?: string | null
      directorName?: string | null
      verifiedAt?: any | null
      codeOkved?: string | null
      actualAddress?: string | null
      legalAddress?: string | null
      actions: {
        __typename?: 'CompanyActionsEntity'
        addUser: boolean
        delete: boolean
        deleteUser: boolean
        sendOnModeration: boolean
        updateGeneralInfo: boolean
        updateLegalInfo: boolean
      }
    }>
  }
}

export type BankAccountsQueryVariables = Exact<{
  companyId: Scalars['Int']['input']
}>

export type BankAccountsQuery = {
  __typename?: 'Query'
  bankAccounts: Array<{
    __typename?: 'BankAccountEntity'
    bankName: string
    id: number
    paymentAccount: string
    correspondentAccount: string
    companyId?: number | null
    bic: string
    actions: { __typename?: 'BankAccountActionsEntity'; delete: boolean }
  }>
}

export type VerificationRequirementsQueryVariables = Exact<{ [key: string]: never }>

export type VerificationRequirementsQuery = {
  __typename?: 'Query'
  me: {
    __typename?: 'MeEntity'
    companies: Array<{
      __typename?: 'CompanyEntity'
      id: number
      rejectionReason?: string | null
      verificationStatus: CompanyVerificationStatus
      verificationRequirements: {
        __typename?: 'CompanyVerificationRequirementsEntity'
        requisites?: boolean | null
        bankAccount?: boolean | null
        documents?: boolean | null
      }
    }>
  }
}

export type QueryQueryVariables = Exact<{
  filter?: InputMaybe<NotesFilterInput>
  sorting?: InputMaybe<NoteSortingInput>
  pagination?: InputMaybe<PaginationInput>
}>

export type QueryQuery = {
  __typename?: 'Query'
  notes: {
    __typename?: 'NotesResponse'
    totalCount: number
    notes: Array<{
      __typename?: 'NoteEntity'
      id: number
      text: string
      authorId: number
      model: EntityModel
      entityId: number
      createdAt: any
      updatedAt?: any | null
      author: { __typename?: 'UserEntity'; name: string }
    }>
  }
}

export type UsersQueryVariables = Exact<{
  pagination: UserPaginationInput
  filter?: InputMaybe<UsersFilterInput>
}>

export type UsersQuery = {
  __typename?: 'Query'
  users: {
    __typename?: 'UsersResponse'
    totalCount: number
    users: Array<{
      __typename?: 'UserEntity'
      id: number
      phoneNumber: string
      status: UserStatus
      email?: string | null
      name: string
      role: UserRole
      createdAt: any
      deactivatedAt?: any | null
      authenticatedAt: any
      avatarUrl?: string | null
      companies: Array<{ __typename?: 'CompanyEntity'; id: number; legalName: string; type: CompanyType }>
      actions: {
        __typename?: 'UserActionsEntity'
        updateAvatar: boolean
        update: boolean
        deactivate: boolean
        activate: boolean
      }
    }>
  }
}

export const CompanyDataFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CompanyData' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CompanyEntity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'inn' } },
          { kind: 'Field', name: { kind: 'Name', value: 'kpp' } },
          { kind: 'Field', name: { kind: 'Name', value: 'legalName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ogrn' } },
          { kind: 'Field', name: { kind: 'Name', value: 'okved' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'verificationStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rejectionReason' } },
          { kind: 'Field', name: { kind: 'Name', value: 'useNds' } },
          { kind: 'Field', name: { kind: 'Name', value: 'website' } },
          { kind: 'Field', name: { kind: 'Name', value: 'additionalInfo' } },
          { kind: 'Field', name: { kind: 'Name', value: 'directorName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'verifiedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'codeOkved' } },
          { kind: 'Field', name: { kind: 'Name', value: 'signatory' } },
          { kind: 'Field', name: { kind: 'Name', value: 'signatoryPosition' } },
          { kind: 'Field', name: { kind: 'Name', value: 'signatoryLegalBasis' } },
          { kind: 'Field', name: { kind: 'Name', value: 'legalAddress' } },
          { kind: 'Field', name: { kind: 'Name', value: 'actualAddress' } },
          { kind: 'Field', name: { kind: 'Name', value: 'aicCharterMember' } },
          { kind: 'Field', name: { kind: 'Name', value: 'logoUrl' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CompanyDataFragment, unknown>
export const CompanyActionsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CompanyActions' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CompanyActionsEntity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'updateLegalInfo' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updateGeneralInfo' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sendOnModeration' } },
          { kind: 'Field', name: { kind: 'Name', value: 'deleteUser' } },
          { kind: 'Field', name: { kind: 'Name', value: 'delete' } },
          { kind: 'Field', name: { kind: 'Name', value: 'addUser' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CompanyActionsFragment, unknown>
export const CompanyStateFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CompanyState' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CompanyEntity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'state' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'firstFreightOrderCreated' } },
                { kind: 'Field', name: { kind: 'Name', value: 'companyVerifiedMessageClosed' } },
                { kind: 'Field', name: { kind: 'Name', value: 'canUsePriorityCompanies' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CompanyStateFragment, unknown>
export const ConfirmPhoneNumberDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ConfirmPhoneNumber' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ConfirmPhoneNumberInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'auth' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'confirmPhoneNumber' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'input' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'numberOfAttempts' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isConfirmed' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ConfirmPhoneNumberMutation, ConfirmPhoneNumberMutationVariables>
export const LogInAsDemoUserDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'LogInAsDemoUser' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'LogInAsDemoUserInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'auth' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'logInAsDemoUser' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'input' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'purpose' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'numberOfAttempts' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isConfirmed' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<LogInAsDemoUserMutation, LogInAsDemoUserMutationVariables>
export const SendLogInSmsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SendLogInSms' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'SendLogInSmsInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'auth' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sendLogInSms' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'input' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'numberOfAttempts' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isConfirmed' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SendLogInSmsMutation, SendLogInSmsMutationVariables>
export const RequestPhoneNumberConfirmationCodeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'RequestPhoneNumberConfirmationCode' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'RequestPhoneNumberConfirmationCodeInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'auth' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'requestPhoneNumberConfirmationCode' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'input' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'purpose' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'numberOfAttempts' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isConfirmed' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'expiredAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'confirmedAt' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  RequestPhoneNumberConfirmationCodeMutation,
  RequestPhoneNumberConfirmationCodeMutationVariables
>
export const ConfirmAuthPhoneNumberDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ConfirmAuthPhoneNumber' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ConfirmPhoneNumberInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'auth' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'confirmAuthPhoneNumber' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'input' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'purpose' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'numberOfAttempts' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isConfirmed' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'expiredAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'confirmedAt' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ConfirmAuthPhoneNumberMutation, ConfirmAuthPhoneNumberMutationVariables>
export const SendSignUpSmsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SendSignUpSms' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'SendSignUpSmsInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'auth' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sendSignUpSms' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'input' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'numberOfAttempts' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isConfirmed' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SendSignUpSmsMutation, SendSignUpSmsMutationVariables>
export const SignUpDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SignUp' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'AuthSignUpInput' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'auth' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'signUp' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'input' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
                    },
                  ],
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SignUpMutation, SignUpMutationVariables>
export const SignUpForeignDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SignUpForeign' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'SignUpForeignInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'auth' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'signUpForeign' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'input' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
                    },
                  ],
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SignUpForeignMutation, SignUpForeignMutationVariables>
export const LogInBySmsCodeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'LogInBySmsCode' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'LogInBySmsCodeInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'auth' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'logInBySmsCode' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'input' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
                    },
                  ],
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<LogInBySmsCodeMutation, LogInBySmsCodeMutationVariables>
export const LoginAsUserDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'LoginAsUser' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'AsUserLoginInput' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'auth' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'loginAsUser' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'input' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
                    },
                  ],
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<LoginAsUserMutation, LoginAsUserMutationVariables>
export const LogOutUserReturnModeratorDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'LogOutUserReturnModerator' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'auth' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'logOutUserReturnModerator' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<LogOutUserReturnModeratorMutation, LogOutUserReturnModeratorMutationVariables>
export const SetActiveCompanyDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SetActiveCompany' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'SetActiveCompanyInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'setActiveCompany' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SetActiveCompanyMutation, SetActiveCompanyMutationVariables>
export const BankAccountCreateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'BankAccountCreate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'BankAccountCreateMutationsInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bankAccountCreate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'companyId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'bankName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'bic' } },
                { kind: 'Field', name: { kind: 'Name', value: 'correspondentAccount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'paymentAccount' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<BankAccountCreateMutation, BankAccountCreateMutationVariables>
export const BankAccountDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'BankAccount' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'bankAccountId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bankAccount' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'bankAccountId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'bankAccountId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'delete' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<BankAccountMutation, BankAccountMutationVariables>
export const TransportationDataCreateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'TransportationDataCreate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'TransportDataCreateInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transportDataCreate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<TransportationDataCreateMutation, TransportationDataCreateMutationVariables>
export const MutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Mutation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'companyId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'TransportDataUpdateInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transportData' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'companyId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'companyId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'update' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'input' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<MutationMutation, MutationMutationVariables>
export const TransportCreateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'TransportCreate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'TransportCreateInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transportCreate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<TransportCreateMutation, TransportCreateMutationVariables>
export const TransportUpdateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'TransportUpdate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'TransportUpdateInput' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'transportId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transport' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'transportId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'transportId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'update' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'input' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<TransportUpdateMutation, TransportUpdateMutationVariables>
export const TransportDocumentCreateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'TransportDocumentCreate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'TransportDocumentCreateInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transportDocumentCreate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'type' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<TransportDocumentCreateMutation, TransportDocumentCreateMutationVariables>
export const DeleteTransportDocumentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteTransportDocument' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'documentId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transportDocument' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'documentId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'documentId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'delete' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteTransportDocumentMutation, DeleteTransportDocumentMutationVariables>
export const AddTransportDocumentFilesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AddTransportDocumentFiles' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'documentId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'DocumentFilesCreateInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transportDocument' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'documentId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'documentId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'addFiles' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'input' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'type' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AddTransportDocumentFilesMutation, AddTransportDocumentFilesMutationVariables>
export const DeleteTransportDocumentFileDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteTransportDocumentFile' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'documentFileId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transportDocumentFile' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'documentFileId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'documentFileId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'delete' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteTransportDocumentFileMutation, DeleteTransportDocumentFileMutationVariables>
export const CompanySendOnModerationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CompanySendOnModeration' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'companyId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'company' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'companyId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'companyId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'sendOnModeration' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CompanySendOnModerationMutation, CompanySendOnModerationMutationVariables>
export const CompanyCreateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CompanyCreate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'CompanyCreateMutationsInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'companyCreate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'inn' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CompanyCreateMutation, CompanyCreateMutationVariables>
export const DeleteCompanyDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteCompany' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'companyId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'company' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'companyId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'companyId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'delete' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteCompanyMutation, DeleteCompanyMutationVariables>
export const UpdateCompanyGeneralInfoDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateCompanyGeneralInfo' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'CompanyUpdateGeneralInfoInput' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'companyId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'company' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'companyId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'companyId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'updateGeneralInfo' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'input' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'website' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'additionalInfo' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'logoUrl' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateCompanyGeneralInfoMutation, UpdateCompanyGeneralInfoMutationVariables>
export const UpdateLogoDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateLogo' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'companyId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'CompanyUpdateLogoInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'company' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'companyId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'companyId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'updateLogo' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'input' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'logoUrl' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateLogoMutation, UpdateLogoMutationVariables>
export const UpdateLegalInfoDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateLegalInfo' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'companyId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'CompanyUpdateLegalInfoInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'company' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'companyId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'companyId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'updateLegalInfo' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'input' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'useNds' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'kpp' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'inn' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'directorName' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateLegalInfoMutation, UpdateLegalInfoMutationVariables>
export const DeleteUserDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteUser' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'userId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'companyId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'company' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'companyId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'companyId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'deleteUser' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'userId' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'userId' } },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteUserMutation, DeleteUserMutationVariables>
export const DriverCreateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DriverCreate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'DriverCreateInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'driverCreate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DriverCreateMutation, DriverCreateMutationVariables>
export const UpdateDriverDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateDriver' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'DriverUpdateInput' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'driverId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'driver' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'driverId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'driverId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'updateDriver' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'input' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateDriverMutation, UpdateDriverMutationVariables>
export const SetTransportDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SetTransport' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'transportId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'driverId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'driver' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'driverId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'driverId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'setTransport' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'transportId' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'transportId' } },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SetTransportMutation, SetTransportMutationVariables>
export const DriverUserCreateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DriverUserCreate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'DriverUserCreateInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'driverUserCreate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DriverUserCreateMutation, DriverUserCreateMutationVariables>
export const ElevatorCreateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ElevatorCreate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ElevatorCreateInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'elevatorCreate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'address' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'workingDaysOfWeek' } },
                { kind: 'Field', name: { kind: 'Name', value: 'openingTime' } },
                { kind: 'Field', name: { kind: 'Name', value: 'closingTime' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hasScales' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hasLab' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hasDigitalQueue' } },
                { kind: 'Field', name: { kind: 'Name', value: 'gateHeight' } },
                { kind: 'Field', name: { kind: 'Name', value: 'scalesWeightLimit' } },
                { kind: 'Field', name: { kind: 'Name', value: 'scalesLength' } },
                { kind: 'Field', name: { kind: 'Name', value: 'transport' } },
                { kind: 'Field', name: { kind: 'Name', value: 'unloadingTransport' } },
                { kind: 'Field', name: { kind: 'Name', value: 'loadingTransport' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isDeleted' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'buyOfferCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sellOfferCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'storageVolume' } },
                { kind: 'Field', name: { kind: 'Name', value: 'autoClearance' } },
                { kind: 'Field', name: { kind: 'Name', value: 'autoHeight' } },
                { kind: 'Field', name: { kind: 'Name', value: 'autoLength' } },
                { kind: 'Field', name: { kind: 'Name', value: 'autoUnloadSpeed' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hasAutoStorage' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hasRailScales' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hasRailStock' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hasRailWay' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hasTecEco' } },
                { kind: 'Field', name: { kind: 'Name', value: 'railLoadSpeed' } },
                { kind: 'Field', name: { kind: 'Name', value: 'autoReceptionSpeed' } },
                { kind: 'Field', name: { kind: 'Name', value: 'autoAdditionalInfo' } },
                { kind: 'Field', name: { kind: 'Name', value: 'railAdditionalInfo' } },
                { kind: 'Field', name: { kind: 'Name', value: 'stationName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'waitingArea' } },
                { kind: 'Field', name: { kind: 'Name', value: 'productOnElevator' } },
                { kind: 'Field', name: { kind: 'Name', value: 'additionalServiceWithNds' } },
                { kind: 'Field', name: { kind: 'Name', value: 'receptionPrice' } },
                { kind: 'Field', name: { kind: 'Name', value: 'dryingPrice' } },
                { kind: 'Field', name: { kind: 'Name', value: 'storingPrice' } },
                { kind: 'Field', name: { kind: 'Name', value: 'mixingPrice' } },
                { kind: 'Field', name: { kind: 'Name', value: 'censusPrice' } },
                { kind: 'Field', name: { kind: 'Name', value: 'autoShipmentPrice' } },
                { kind: 'Field', name: { kind: 'Name', value: 'trainShipmentPrice' } },
                { kind: 'Field', name: { kind: 'Name', value: 'paperworkPrice' } },
                { kind: 'Field', name: { kind: 'Name', value: 'cleaningPrice' } },
                { kind: 'Field', name: { kind: 'Name', value: 'anotherServices' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ElevatorCreateMutation, ElevatorCreateMutationVariables>
export const ElevatorApproveCreationRequestDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ElevatorApproveCreationRequest' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'elevatorId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'elevator' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'elevatorId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'elevatorId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'approveCreationRequest' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ElevatorApproveCreationRequestMutation, ElevatorApproveCreationRequestMutationVariables>
export const FreightDealDocumentCreateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'FreightDealDocumentCreate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'FreightDealDocumentCreateInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'freightDealDocumentCreate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'instances' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'rejectionNote' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'formedAt' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'files' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'mimetype' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'size' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'source' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FreightDealDocumentCreateMutation, FreightDealDocumentCreateMutationVariables>
export const FreightDealDocumentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'FreightDealDocument' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'documentId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'freightDealDocument' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'documentId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'documentId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'delete' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FreightDealDocumentMutation, FreightDealDocumentMutationVariables>
export const AddFilesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AddFiles' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'DocumentFilesCreateInput' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'documentId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'freightDealDocument' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'documentId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'documentId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'addFiles' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'input' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'instances' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'formedAt' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'rejectionNote' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'files' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'size' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'mimetype' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'source' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AddFilesMutation, AddFilesMutationVariables>
export const FreightDealUpdateStatusDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'FreightDealUpdateStatus' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'freightDealId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'FreightDealStatusUpdateInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'freightDeal' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'freightDealId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'freightDealId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'updateStatus' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'input' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FreightDealUpdateStatusMutation, FreightDealUpdateStatusMutationVariables>
export const FreightOrderChangeRequestAcceptAllDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'FreightOrderChangeRequestAcceptAll' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'freightDealId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'freightOrderChangeRequestAcceptAll' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'freightDealId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'freightDealId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  FreightOrderChangeRequestAcceptAllMutation,
  FreightOrderChangeRequestAcceptAllMutationVariables
>
export const FreightOrderChangeRequestDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'FreightOrderChangeRequest' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'changeRequestId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'freightOrderChangeRequest' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'changeRequestId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'changeRequestId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'setViewed' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FreightOrderChangeRequestMutation, FreightOrderChangeRequestMutationVariables>
export const FreightOrderChangeRequestUpdateStatusDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'FreightOrderChangeRequestUpdateStatus' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'FreightOrderChangeRequestStatusUpdateInput' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'changeRequestId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'freightOrderChangeRequest' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'changeRequestId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'changeRequestId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'updateStatus' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'input' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  FreightOrderChangeRequestUpdateStatusMutation,
  FreightOrderChangeRequestUpdateStatusMutationVariables
>
export const FreightOrderChangeRequestsCreateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'FreightOrderChangeRequestsCreate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'FreightOrderChangeRequestsCreateInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'freightOrderChangeRequestsCreate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  FreightOrderChangeRequestsCreateMutation,
  FreightOrderChangeRequestsCreateMutationVariables
>
export const FreightOrderDocumentCreateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'FreightOrderDocumentCreate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'FreightOrderDocumentCreateInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'freightOrderDocumentCreate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'instances' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'formedAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'rejectionNote' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'files' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'size' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'mimetype' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'source' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FreightOrderDocumentCreateMutation, FreightOrderDocumentCreateMutationVariables>
export const FreightOrderDocumentAddFilesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'FreightOrderDocumentAddFiles' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'DocumentFilesCreateInput' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'documentId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'freightOrderDocument' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'documentId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'documentId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'addFiles' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'input' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'instances' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'formedAt' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'rejectionNote' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'files' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'size' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'mimetype' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'source' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FreightOrderDocumentAddFilesMutation, FreightOrderDocumentAddFilesMutationVariables>
export const FreightOrderDocumentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'FreightOrderDocument' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'documentId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'freightOrderDocument' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'documentId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'documentId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'delete' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FreightOrderDocumentMutation, FreightOrderDocumentMutationVariables>
export const FreightResponseUpdateStatusDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'FreightResponseUpdateStatus' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'freightDealId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'FreightDealStatusUpdateInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'freightOrderResponse' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'freightDealId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'freightDealId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'updateStatus' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'input' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FreightResponseUpdateStatusMutation, FreightResponseUpdateStatusMutationVariables>
export const FreightOrderCreateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'FreightOrderCreate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'FreightOrderCreateInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'freightOrderCreate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FreightOrderCreateMutation, FreightOrderCreateMutationVariables>
export const FreightOrderResponseCreateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'FreightOrderResponseCreate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'FreightOrderResponseCreateInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'freightOrderResponseCreate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FreightOrderResponseCreateMutation, FreightOrderResponseCreateMutationVariables>
export const FreightOrderBetCreateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'FreightOrderBetCreate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'FreightOrderBetCreateInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'freightOrderBetCreate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FreightOrderBetCreateMutation, FreightOrderBetCreateMutationVariables>
export const UpdateMeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateMe' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'MeUpdateProfileInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'me' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'updateProfile' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'input' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'position' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateMeMutation, UpdateMeMutationVariables>
export const UpdateAvatarDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateAvatar' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpdateAvatarInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'me' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'updateAvatar' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'input' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'avatarUrl' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateAvatarMutation, UpdateAvatarMutationVariables>
export const UpdateStateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateState' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpdateStateInput' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'me' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'updateState' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'input' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'state' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'multiCompanyProfileGuideShown' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateStateMutation, UpdateStateMutationVariables>
export const RequestChangePhoneNumberDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'RequestChangePhoneNumber' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'me' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'requestChangePhoneNumber' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isConfirmed' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'numberOfAttempts' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RequestChangePhoneNumberMutation, RequestChangePhoneNumberMutationVariables>
export const SetNewPhoneNumberDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SetNewPhoneNumber' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'SetNewPhoneNumberInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'me' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'setNewPhoneNumber' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'input' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isConfirmed' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'numberOfAttempts' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SetNewPhoneNumberMutation, SetNewPhoneNumberMutationVariables>
export const ConfirmNewPhoneNumberDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ConfirmNewPhoneNumber' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ConfirmNewPhoneNumberInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'me' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'confirmNewPhoneNumber' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'input' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isConfirmed' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'numberOfAttempts' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ConfirmNewPhoneNumberMutation, ConfirmNewPhoneNumberMutationVariables>
export const UpdateNoteDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateNote' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'noteId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'NoteUpdateInput' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'note' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'noteId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'noteId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'update' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'input' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'entityId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'authorId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'model' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateNoteMutation, UpdateNoteMutationVariables>
export const AddNoteDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AddNote' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'NoteCreateInput' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'noteCreate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                { kind: 'Field', name: { kind: 'Name', value: 'authorId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'model' } },
                { kind: 'Field', name: { kind: 'Name', value: 'entityId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AddNoteMutation, AddNoteMutationVariables>
export const UserCreateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UserCreate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'UserCreateInput' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userCreate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'role' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'companies' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UserCreateMutation, UserCreateMutationVariables>
export const AddUsersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AddUsers' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'userIds' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'companyId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'company' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'companyId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'companyId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'addUsers' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'userIds' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'userIds' } },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'role' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'avatarUrl' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AddUsersMutation, AddUsersMutationVariables>
export const UpdateUserDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateUser' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'UserUpdateInput' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'userId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'userId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'userId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'update' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'input' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'role' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'avatarUrl' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateUserMutation, UpdateUserMutationVariables>
export const UserActivateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UserActivate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'userId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'userId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'userId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'activate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UserActivateMutation, UserActivateMutationVariables>
export const UserDeactivateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UserDeactivate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'userId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'userId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'userId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'deactivate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UserDeactivateMutation, UserDeactivateMutationVariables>
export const UserAcceptAccessDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UserAcceptAccess' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'AcceptAccessToCompanyInput' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'userId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'userId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'userId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'acceptAccessToCompany' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'input' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UserAcceptAccessMutation, UserAcceptAccessMutationVariables>
export const UserRejectAccessDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UserRejectAccess' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'RejectAccessToCompanyInput' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'userId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'userId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'userId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'rejectAccessToCompany' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'input' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UserRejectAccessMutation, UserRejectAccessMutationVariables>
export const UserUpdateStateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UserUpdateState' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpdateStateInput' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'userId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'userId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'userId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'updateState' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'input' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UserUpdateStateMutation, UserUpdateStateMutationVariables>
export const TransportationDataDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'TransportationData' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'companyId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transportData' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'companyId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'companyId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'transportModes' } },
                { kind: 'Field', name: { kind: 'Name', value: 'transportationArea' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'regions' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'address' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<TransportationDataQuery, TransportationDataQueryVariables>
export const RegionsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Regions' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'regions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RegionsQuery, RegionsQueryVariables>
export const TransportsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Transports' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'TransportFilterInput' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'pagination' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'PaginationInput' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transports' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pagination' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'pagination' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'transports' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'companyId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'transportStationId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'brand' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'bodyTypeId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'axlesCount' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'manufacturingDate' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'vin' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'registrationCertificate' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'transportPassport' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'licensePlate' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'ownershipType' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'ownerName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lengthCm' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'widthCm' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'heightCm' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'loadCapacityKg' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'loadVolumeL' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'loadingMethodId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'adrCertificate' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'shortRouteKm' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'longRouteKm' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'preferredRouteType' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'canWorkInField' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'additionalDetails' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deletedAt' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'bodyType' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'loadingMethod' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pagination' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'currentPage' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'pageSize' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'totalPages' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<TransportsQuery, TransportsQueryVariables>
export const TransportDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Transport' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'transportId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transport' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'transportId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'transportId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'companyId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'transportStationId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'brand' } },
                { kind: 'Field', name: { kind: 'Name', value: 'bodyTypeId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'axlesCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'manufacturingDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'vin' } },
                { kind: 'Field', name: { kind: 'Name', value: 'registrationCertificate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'transportPassport' } },
                { kind: 'Field', name: { kind: 'Name', value: 'licensePlate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'ownershipType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'ownerName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lengthCm' } },
                { kind: 'Field', name: { kind: 'Name', value: 'widthCm' } },
                { kind: 'Field', name: { kind: 'Name', value: 'heightCm' } },
                { kind: 'Field', name: { kind: 'Name', value: 'loadCapacityKg' } },
                { kind: 'Field', name: { kind: 'Name', value: 'loadVolumeL' } },
                { kind: 'Field', name: { kind: 'Name', value: 'loadingMethodId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'adrCertificate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'shortRouteKm' } },
                { kind: 'Field', name: { kind: 'Name', value: 'longRouteKm' } },
                { kind: 'Field', name: { kind: 'Name', value: 'preferredRouteType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'canWorkInField' } },
                { kind: 'Field', name: { kind: 'Name', value: 'additionalDetails' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deletedAt' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'bodyType' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'loadingMethod' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<TransportQuery, TransportQueryVariables>
export const TransportBodyTypesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'TransportBodyTypes' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transportBodyTypes' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<TransportBodyTypesQuery, TransportBodyTypesQueryVariables>
export const TransportLoadingMethodsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'TransportLoadingMethods' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transportLoadingMethods' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<TransportLoadingMethodsQuery, TransportLoadingMethodsQueryVariables>
export const TransportStationsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'TransportStations' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'TransportStationFilter' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transportStations' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'address' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<TransportStationsQuery, TransportStationsQueryVariables>
export const TransportDocumentsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'TransportDocuments' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'transportId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'TransportDocumentFilterInput' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transportDocuments' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'transportId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'transportId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'instances' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'formedAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'rejectionNote' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'files' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'size' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'mimetype' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'source' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<TransportDocumentsQuery, TransportDocumentsQueryVariables>
export const CompanyStateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'CompanyState' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'companyId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'company' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'companyId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'companyId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'CompanyState' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CompanyState' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CompanyEntity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'state' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'firstFreightOrderCreated' } },
                { kind: 'Field', name: { kind: 'Name', value: 'companyVerifiedMessageClosed' } },
                { kind: 'Field', name: { kind: 'Name', value: 'canUsePriorityCompanies' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CompanyStateQuery, CompanyStateQueryVariables>
export const CompanyDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Company' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'companyId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'company' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'companyId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'companyId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'CompanyData' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'CompanyState' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'actions' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'CompanyActions' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CompanyData' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CompanyEntity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'inn' } },
          { kind: 'Field', name: { kind: 'Name', value: 'kpp' } },
          { kind: 'Field', name: { kind: 'Name', value: 'legalName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ogrn' } },
          { kind: 'Field', name: { kind: 'Name', value: 'okved' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'verificationStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rejectionReason' } },
          { kind: 'Field', name: { kind: 'Name', value: 'useNds' } },
          { kind: 'Field', name: { kind: 'Name', value: 'website' } },
          { kind: 'Field', name: { kind: 'Name', value: 'additionalInfo' } },
          { kind: 'Field', name: { kind: 'Name', value: 'directorName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'verifiedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'codeOkved' } },
          { kind: 'Field', name: { kind: 'Name', value: 'signatory' } },
          { kind: 'Field', name: { kind: 'Name', value: 'signatoryPosition' } },
          { kind: 'Field', name: { kind: 'Name', value: 'signatoryLegalBasis' } },
          { kind: 'Field', name: { kind: 'Name', value: 'legalAddress' } },
          { kind: 'Field', name: { kind: 'Name', value: 'actualAddress' } },
          { kind: 'Field', name: { kind: 'Name', value: 'aicCharterMember' } },
          { kind: 'Field', name: { kind: 'Name', value: 'logoUrl' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CompanyState' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CompanyEntity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'state' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'firstFreightOrderCreated' } },
                { kind: 'Field', name: { kind: 'Name', value: 'companyVerifiedMessageClosed' } },
                { kind: 'Field', name: { kind: 'Name', value: 'canUsePriorityCompanies' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CompanyActions' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CompanyActionsEntity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'updateLegalInfo' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updateGeneralInfo' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sendOnModeration' } },
          { kind: 'Field', name: { kind: 'Name', value: 'deleteUser' } },
          { kind: 'Field', name: { kind: 'Name', value: 'delete' } },
          { kind: 'Field', name: { kind: 'Name', value: 'addUser' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CompanyQuery, CompanyQueryVariables>
export const DriversDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Drivers' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'DriverFilterInput' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'pagination' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'PaginationInput' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'drivers' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pagination' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'pagination' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pagination' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'totalPages' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'pageSize' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'currentPage' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'drivers' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'driverData' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'birthDate' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'licenseNumber' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'licenseIssuedAt' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'licenseExpiredAt' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'additionalDetails' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'transports' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'companyId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'transportStationId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'brand' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'bodyTypeId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'axlesCount' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'manufacturingDate' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'vin' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'registrationCertificate' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'transportPassport' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'licensePlate' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'ownershipType' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'ownerName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'lengthCm' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'widthCm' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'heightCm' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'loadCapacityKg' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'loadVolumeL' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'loadingMethodId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'adrCertificate' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'shortRouteKm' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'longRouteKm' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'preferredRouteType' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'canWorkInField' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'additionalDetails' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'deletedAt' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'bodyType' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'loadingMethod' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DriversQuery, DriversQueryVariables>
export const DriverDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Driver' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'driverId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'driver' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'driverId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'driverId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'driverData' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'birthDate' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'licenseNumber' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'licenseIssuedAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'licenseExpiredAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'additionalDetails' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'transports' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'companyId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'transportStationId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'brand' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'bodyTypeId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'axlesCount' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'manufacturingDate' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'vin' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'registrationCertificate' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'transportPassport' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'licensePlate' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'ownershipType' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'ownerName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lengthCm' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'widthCm' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'heightCm' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'loadCapacityKg' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'loadVolumeL' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'loadingMethodId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'adrCertificate' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'shortRouteKm' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'longRouteKm' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'preferredRouteType' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'canWorkInField' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'additionalDetails' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deletedAt' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'bodyType' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'loadingMethod' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DriverQuery, DriverQueryVariables>
export const FreightDealDocumentsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'FreightDealDocuments' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'freightDealId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'FreightDealDocumentFilterInput' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'freightDealDocuments' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'freightDealId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'freightDealId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'instances' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'formedAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'rejectionNote' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'files' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'size' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'mimetype' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'source' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FreightDealDocumentsQuery, FreightDealDocumentsQueryVariables>
export const FreightDealDocumentsArchiveDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'FreightDealDocumentsArchive' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'freightDealId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'DocumentsArchiveFilterInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'freightDealDocumentsArchive' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'freightDealId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'freightDealId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'size' } },
                { kind: 'Field', name: { kind: 'Name', value: 'mimetype' } },
                { kind: 'Field', name: { kind: 'Name', value: 'source' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FreightDealDocumentsArchiveQuery, FreightDealDocumentsArchiveQueryVariables>
export const FreightDealsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'FreightDeals' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'FreightDealFilterInput' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'pagination' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'PaginationInput' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'freightDeals' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pagination' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'pagination' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'freightDeals' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'orderId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'dealOrderId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'customerId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'carrierId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'createdById' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'startedAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'rejectedAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'rejectedById' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'rejectionReason' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isRealisedByCustomer' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isRealisedByCarrier' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isAgreedByCustomer' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isAgreedByCarrier' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'releasedAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'additionalDetails' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'nextStatuses' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'lastChangeRequests' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'changedFields' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'fieldName' } },
                                ],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'createdByCompanyId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'endedByCompanyId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'additionalDetails' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'nextStatuses' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'isViewedByCounterparty' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'order' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'companyId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'routeId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'readinessType' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'startedAt' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'endedAt' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'startDaysCount' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'transportFormatTypes' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'transportBodyTypes' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                ],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'weightKg' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'selectedWeightKg' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'priceWithoutNds' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'priceWithNds' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'availableWeightKg' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'pricePerTonWithoutNds' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'pricePerTonWithNds' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'isAuction' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'paymentTerm' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'advancePercent' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'deferredDaysCount' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'contactPerson' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'contactPhoneNumber' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'additionalDetails' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'deletedAt' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'canCreateResponse' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'route' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'distance' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'points' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'addressId' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'freightId' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'roadTransportParametersId' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'address' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              { kind: 'Field', name: { kind: 'Name', value: 'full' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'short' } },
                                            ],
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'freight' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'packingType' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'packingTypeCustom' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'bigBagsCount' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'productTypeId' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'weightKg' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'productType' },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                                    { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                                    { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                                                    { kind: 'Field', name: { kind: 'Name', value: 'gost' } },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'roadTransportParameters' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'loadingTypes' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'unloadingTypes' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'loadingTypeCustom' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'unloadingTypeCustom' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'loadingThroughput' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'unloadingThroughput' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'vehicleLengthMaxM' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'vehicleHeightMaxM' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'vehicleClearanceMinCm' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'hasParking' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'hasScales' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'hasWeighingAutomation' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'distanceToScalesM' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'scalesWeightLimitT' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'roadSurfaceType' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'roadSurfaceQuality' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'additionalDetails' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'responsesStatistics' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'previousDays' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'betsCount' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'responsesCount' } },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'today' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'betsCount' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'responsesCount' } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'customer' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'countryName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'legalForm' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'inn' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'kpp' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'legalName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'ogrn' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'okved' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'verificationStatus' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'rejectionReason' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'useNds' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'website' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'additionalInfo' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'directorName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'verifiedAt' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'codeOkved' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'signatory' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'signatoryPosition' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'signatoryLegalBasis' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'legalAddress' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'actualAddress' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'aicCharterMember' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'logoUrl' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'dealOrder' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'companyId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'routeId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'readinessType' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'startedAt' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'endedAt' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'startDaysCount' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'transportFormatTypes' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'transportBodyTypes' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                ],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'weightKg' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'selectedWeightKg' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'priceWithoutNds' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'priceWithNds' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'availableWeightKg' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'pricePerTonWithoutNds' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'pricePerTonWithNds' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'isAuction' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'paymentTerm' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'advancePercent' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'deferredDaysCount' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'contactPerson' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'contactPhoneNumber' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'additionalDetails' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'deletedAt' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'canCreateResponse' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pagination' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'currentPage' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'pageSize' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'totalPages' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FreightDealsQuery, FreightDealsQueryVariables>
export const FreightDealDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'FreightDeal' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'freightDealId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'freightDeal' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'freightDealId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'freightDealId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'orderId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'dealOrderId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'customerId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'carrierId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdById' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rejectedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rejectedById' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rejectionReason' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isRealisedByCustomer' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isRealisedByCarrier' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isAgreedByCustomer' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isAgreedByCarrier' } },
                { kind: 'Field', name: { kind: 'Name', value: 'releasedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'additionalDetails' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'order' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'companyId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'routeId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'readinessType' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'startedAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'endedAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'startDaysCount' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'transportFormatTypes' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'transportBodyTypes' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'weightKg' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'selectedWeightKg' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'priceWithoutNds' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'priceWithNds' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'availableWeightKg' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'pricePerTonWithoutNds' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'pricePerTonWithNds' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isAuction' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'paymentTerm' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'advancePercent' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deferredDaysCount' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'contactPerson' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'contactPhoneNumber' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'additionalDetails' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deletedAt' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'route' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'distance' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'points' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'addressId' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'freightId' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'roadTransportParametersId' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'address' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'full' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'short' } },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'freight' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'packingType' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'packingTypeCustom' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'bigBagsCount' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'productTypeId' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'weightKg' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'productType' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'gost' } },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'roadTransportParameters' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'loadingTypes' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'unloadingTypes' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'loadingTypeCustom' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'unloadingTypeCustom' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'loadingThroughput' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'unloadingThroughput' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'vehicleLengthMaxM' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'vehicleHeightMaxM' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'vehicleClearanceMinCm' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'hasParking' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'hasScales' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'hasWeighingAutomation' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'distanceToScalesM' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'scalesWeightLimitT' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'roadSurfaceType' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'roadSurfaceQuality' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'additionalDetails' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'dealOrder' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'companyId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'routeId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'readinessType' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'startedAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'endedAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'startDaysCount' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'transportFormatTypes' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'transportBodyTypes' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'weightKg' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'selectedWeightKg' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'priceWithoutNds' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'priceWithNds' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'availableWeightKg' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'pricePerTonWithoutNds' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'pricePerTonWithNds' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isAuction' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'paymentTerm' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'advancePercent' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deferredDaysCount' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'contactPerson' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'contactPhoneNumber' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'additionalDetails' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deletedAt' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'route' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'distance' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'points' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'addressId' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'freightId' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'roadTransportParametersId' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'address' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'full' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'short' } },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'freight' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'packingType' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'packingTypeCustom' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'bigBagsCount' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'productTypeId' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'weightKg' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'productType' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'gost' } },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'roadTransportParameters' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'loadingTypes' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'unloadingTypes' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'loadingTypeCustom' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'unloadingTypeCustom' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'loadingThroughput' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'unloadingThroughput' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'vehicleLengthMaxM' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'vehicleHeightMaxM' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'vehicleClearanceMinCm' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'hasParking' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'hasScales' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'hasWeighingAutomation' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'distanceToScalesM' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'scalesWeightLimitT' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'roadSurfaceType' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'roadSurfaceQuality' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'additionalDetails' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'customer' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'countryName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'legalForm' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'inn' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'kpp' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'legalName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'ogrn' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'okved' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'verificationStatus' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'rejectionReason' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'useNds' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'website' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'additionalInfo' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'directorName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'verifiedAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'codeOkved' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'signatory' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'signatoryPosition' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'signatoryLegalBasis' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'legalAddress' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'actualAddress' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'aicCharterMember' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'logoUrl' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'bankAccounts' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'companyId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'bankName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'bic' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'correspondentAccount' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'paymentAccount' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'actions' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [{ kind: 'Field', name: { kind: 'Name', value: 'delete' } }],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'users' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'position' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'role' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'deactivatedAt' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'authenticatedAt' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'avatarUrl' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'actions' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'addUser' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'delete' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'deleteUser' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'sendOnModeration' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'updateGeneralInfo' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'updateLegalInfo' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'state' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'companyVerifiedMessageClosed' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'canUsePriorityCompanies' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'verificationRequirements' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'requisites' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'bankAccount' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'documents' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'carrier' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'countryName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'legalForm' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'inn' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'kpp' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'legalName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'ogrn' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'okved' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'verificationStatus' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'rejectionReason' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'useNds' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'website' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'additionalInfo' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'directorName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'verifiedAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'codeOkved' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'signatory' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'signatoryPosition' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'signatoryLegalBasis' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'legalAddress' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'actualAddress' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'aicCharterMember' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'logoUrl' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'bankAccounts' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'companyId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'bankName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'bic' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'correspondentAccount' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'paymentAccount' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'actions' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [{ kind: 'Field', name: { kind: 'Name', value: 'delete' } }],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'users' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'position' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'role' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'deactivatedAt' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'authenticatedAt' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'createdBy' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'position' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'role' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'deactivatedAt' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'authenticatedAt' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'avatarUrl' } },
                                ],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'avatarUrl' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'companies' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'countryName' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'legalForm' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'inn' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'kpp' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'legalName' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'ogrn' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'okved' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'verificationStatus' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'rejectionReason' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'useNds' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'website' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'additionalInfo' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'directorName' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'verifiedAt' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'codeOkved' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'signatory' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'signatoryPosition' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'signatoryLegalBasis' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'legalAddress' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'actualAddress' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'aicCharterMember' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'logoUrl' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'bankAccounts' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'companyId' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'bankName' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'bic' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'correspondentAccount' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'paymentAccount' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'actions' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [{ kind: 'Field', name: { kind: 'Name', value: 'delete' } }],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'users' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'position' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'role' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'deactivatedAt' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'authenticatedAt' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'createdBy' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'position' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'role' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'deactivatedAt' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'authenticatedAt' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'avatarUrl' } },
                                            ],
                                          },
                                        },
                                        { kind: 'Field', name: { kind: 'Name', value: 'avatarUrl' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'companies' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'countryName' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'legalForm' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'inn' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'kpp' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'legalName' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'ogrn' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'okved' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'verificationStatus' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'rejectionReason' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'useNds' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'website' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'additionalInfo' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'directorName' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'verifiedAt' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'codeOkved' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'signatory' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'signatoryPosition' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'signatoryLegalBasis' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'legalAddress' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'actualAddress' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'aicCharterMember' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'logoUrl' } },
                                            ],
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'actions' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              { kind: 'Field', name: { kind: 'Name', value: 'activate' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'deactivate' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'update' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'updateAvatar' } },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'actions' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'addUser' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'delete' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'deleteUser' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'sendOnModeration' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'updateGeneralInfo' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'updateLegalInfo' } },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'state' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'companyVerifiedMessageClosed' },
                                        },
                                        { kind: 'Field', name: { kind: 'Name', value: 'canUsePriorityCompanies' } },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'verificationRequirements' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'requisites' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'bankAccount' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'documents' } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'actions' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'activate' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'deactivate' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'update' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'updateAvatar' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'actions' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'addUser' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'delete' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'deleteUser' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'sendOnModeration' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'updateGeneralInfo' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'updateLegalInfo' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'state' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'companyVerifiedMessageClosed' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'canUsePriorityCompanies' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'verificationRequirements' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'requisites' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'bankAccount' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'documents' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'creator' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'position' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'role' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deactivatedAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'authenticatedAt' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'createdBy' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'position' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'role' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'deactivatedAt' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'authenticatedAt' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'avatarUrl' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'avatarUrl' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'companies' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'countryName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'legalForm' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'inn' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'kpp' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'legalName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'ogrn' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'okved' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'verificationStatus' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'rejectionReason' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'useNds' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'website' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'additionalInfo' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'directorName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'verifiedAt' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'codeOkved' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'signatory' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'signatoryPosition' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'signatoryLegalBasis' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'legalAddress' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'actualAddress' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'aicCharterMember' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'logoUrl' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'actions' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'activate' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'deactivate' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'update' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'updateAvatar' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'rejectedBy' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'position' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'role' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deactivatedAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'authenticatedAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'avatarUrl' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'nextStatuses' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'lastChangeRequests' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'changedFields' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'fieldName' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'createdByCompanyId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'endedByCompanyId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'additionalDetails' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'nextStatuses' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isViewedByCounterparty' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FreightDealQuery, FreightDealQueryVariables>
export const FreightOrderChangeRequestBlocksDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'FreightOrderChangeRequestBlocks' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'freightDealId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'FreightOrderChangeRequestBlockFilterInput' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'freightOrderChangeRequestBlocks' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'freightDealId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'freightDealId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'changeRequests' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'nextStatuses' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'endedByCompanyId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'createdByCompanyId' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'changedFields' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'fieldName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'additionalDetails' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'createdByCompany' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'legalName' } }],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FreightOrderChangeRequestBlocksQuery, FreightOrderChangeRequestBlocksQueryVariables>
export const FreightOrderDocumentsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'FreightOrderDocuments' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'freightOrderId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'FreightOrderDocumentFilterInput' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'freightOrderDocuments' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'freightOrderId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'freightOrderId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'instances' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'rejectionNote' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'formedAt' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'files' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'size' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'mimetype' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'source' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FreightOrderDocumentsQuery, FreightOrderDocumentsQueryVariables>
export const FreightOrderDocumentsArchiveDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'FreightOrderDocumentsArchive' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'freightOrderId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'DocumentsArchiveFilterInput' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'freightOrderDocumentsArchive' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'freightOrderId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'freightOrderId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'size' } },
                { kind: 'Field', name: { kind: 'Name', value: 'mimetype' } },
                { kind: 'Field', name: { kind: 'Name', value: 'source' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FreightOrderDocumentsArchiveQuery, FreightOrderDocumentsArchiveQueryVariables>
export const FreightOrderFieldInitialValuesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'FreightOrderFieldInitialValues' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'freightDealId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'freightOrderFieldInitialValues' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'freightDealId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'freightDealId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fieldName' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FreightOrderFieldInitialValuesQuery, FreightOrderFieldInitialValuesQueryVariables>
export const FreightOrderResponsesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'FreightOrderResponses' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'FreightOrderResponseFilterInput' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'pagination' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'PaginationInput' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'freightOrderResponses' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pagination' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'pagination' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'freightOrderResponses' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'orderId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'additionalDetails' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'order' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'companyId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'routeId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'readinessType' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'startedAt' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'endedAt' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'startDaysCount' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'transportFormatTypes' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'transportBodyTypes' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                ],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'weightKg' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'selectedWeightKg' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'priceWithoutNds' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'priceWithNds' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'availableWeightKg' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'pricePerTonWithoutNds' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'pricePerTonWithNds' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'isAuction' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'paymentTerm' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'advancePercent' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'deferredDaysCount' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'contactPerson' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'contactPhoneNumber' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'additionalDetails' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'deletedAt' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'route' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'distance' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'distanceToStartKm' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'points' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'addressId' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'freightId' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'roadTransportParametersId' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'address' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              { kind: 'Field', name: { kind: 'Name', value: 'full' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'short' } },
                                            ],
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'freight' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'packingType' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'packingTypeCustom' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'bigBagsCount' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'productTypeId' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'weightKg' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'productType' },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                                    { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                                    { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                                                    { kind: 'Field', name: { kind: 'Name', value: 'gost' } },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'roadTransportParameters' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'loadingTypes' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'unloadingTypes' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'loadingTypeCustom' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'unloadingTypeCustom' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'loadingThroughput' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'unloadingThroughput' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'vehicleLengthMaxM' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'vehicleHeightMaxM' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'vehicleClearanceMinCm' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'hasParking' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'hasScales' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'hasWeighingAutomation' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'distanceToScalesM' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'scalesWeightLimitT' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'roadSurfaceType' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'roadSurfaceQuality' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'additionalDetails' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'canCreateResponse' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'responsesStatistics' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'previousDays' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'betsCount' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'responsesCount' } },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'today' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'betsCount' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'responsesCount' } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'dealOrder' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'startedAt' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'weightKg' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'priceWithoutNds' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'priceWithNds' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'pricePerTonWithoutNds' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'pricePerTonWithNds' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'paymentTerm' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'nextStatuses' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pagination' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'currentPage' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'pageSize' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'totalPages' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FreightOrderResponsesQuery, FreightOrderResponsesQueryVariables>
export const FreightOrdersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'FreightOrders' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'FreightOrderFilterInput' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'pagination' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'PaginationInput' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'freightOrders' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pagination' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'pagination' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'freightOrders' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'companyId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'routeId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'readinessType' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'startedAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'endedAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'startDaysCount' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'transportFormatTypes' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'transportBodyTypes' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'weightKg' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'selectedWeightKg' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'priceWithoutNds' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'priceWithNds' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'availableWeightKg' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'pricePerTonWithoutNds' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'pricePerTonWithNds' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isAuction' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'paymentTerm' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'advancePercent' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deferredDaysCount' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'contactPerson' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'contactPhoneNumber' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'additionalDetails' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deletedAt' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'route' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'distance' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'distanceToStartKm' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'points' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'addressId' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'freightId' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'roadTransportParametersId' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'address' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'full' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'short' } },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'freight' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'packingType' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'packingTypeCustom' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'bigBagsCount' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'productTypeId' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'weightKg' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'productType' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'gost' } },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'roadTransportParameters' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'loadingTypes' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'unloadingTypes' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'loadingTypeCustom' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'unloadingTypeCustom' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'loadingThroughput' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'unloadingThroughput' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'vehicleLengthMaxM' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'vehicleHeightMaxM' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'vehicleClearanceMinCm' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'hasParking' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'hasScales' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'hasWeighingAutomation' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'distanceToScalesM' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'scalesWeightLimitT' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'roadSurfaceType' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'roadSurfaceQuality' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'additionalDetails' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'canCreateResponse' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'responsesStatistics' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'previousDays' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'betsCount' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'responsesCount' } },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'today' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'betsCount' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'responsesCount' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pagination' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'currentPage' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'pageSize' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'totalPages' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FreightOrdersQuery, FreightOrdersQueryVariables>
export const FreightOrderDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'FreightOrder' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'freightOrderId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'freightOrder' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'freightOrderId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'freightOrderId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'companyId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'routeId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'readinessType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'endedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startDaysCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'transportFormatTypes' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'transportBodyTypes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'priceWithoutNds' } },
                { kind: 'Field', name: { kind: 'Name', value: 'priceWithNds' } },
                { kind: 'Field', name: { kind: 'Name', value: 'availableWeightKg' } },
                { kind: 'Field', name: { kind: 'Name', value: 'pricePerTonWithoutNds' } },
                { kind: 'Field', name: { kind: 'Name', value: 'pricePerTonWithNds' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isAuction' } },
                { kind: 'Field', name: { kind: 'Name', value: 'paymentTerm' } },
                { kind: 'Field', name: { kind: 'Name', value: 'advancePercent' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deferredDaysCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'contactPerson' } },
                { kind: 'Field', name: { kind: 'Name', value: 'contactPhoneNumber' } },
                { kind: 'Field', name: { kind: 'Name', value: 'additionalDetails' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deletedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'selectedWeightKg' } },
                { kind: 'Field', name: { kind: 'Name', value: 'weightKg' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'route' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'distance' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'distanceToStartKm' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'points' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'addressId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'freightId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'roadTransportParametersId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'address' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'full' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'short' } },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'freight' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'packingType' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'packingTypeCustom' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'bigBagsCount' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'productTypeId' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'weightKg' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'productType' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'gost' } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'roadTransportParameters' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'loadingTypes' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'unloadingTypes' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'loadingTypeCustom' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'unloadingTypeCustom' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'loadingThroughput' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'unloadingThroughput' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'vehicleLengthMaxM' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'vehicleHeightMaxM' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'vehicleClearanceMinCm' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'hasParking' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'hasScales' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'hasWeighingAutomation' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'distanceToScalesM' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'scalesWeightLimitT' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'roadSurfaceType' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'roadSurfaceQuality' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'additionalDetails' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'responsesStatistics' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'previousDays' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'betsCount' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'responsesCount' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'today' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'betsCount' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'responsesCount' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'canCreateResponse' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FreightOrderQuery, FreightOrderQueryVariables>
export const GetMeQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetMeQuery' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'me' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'position' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
                { kind: 'Field', name: { kind: 'Name', value: 'role' } },
                { kind: 'Field', name: { kind: 'Name', value: 'avatarUrl' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'companies' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'actions' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'createCompany' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'createUser' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'state' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'multiCompanyProfileGuideShown' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'moderator' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'activeCompanyId' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetMeQueryQuery, GetMeQueryQueryVariables>
export const GetMeStateQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetMeStateQuery' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'me' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'state' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'multiCompanyProfileGuideShown' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isShowEmployeesGuide' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetMeStateQueryQuery, GetMeStateQueryQueryVariables>
export const GetMeCompaniesQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetMeCompaniesQuery' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'me' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'companies' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'inn' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'kpp' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'legalName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'ogrn' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'okved' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'signatory' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'signatoryLegalBasis' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'signatoryPosition' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'aicCharterMember' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'verificationStatus' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'rejectionReason' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'useNds' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'website' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'logoUrl' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'additionalInfo' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'directorName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'verifiedAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'codeOkved' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'actualAddress' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'legalAddress' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'actions' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'addUser' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'delete' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'deleteUser' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'sendOnModeration' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'updateGeneralInfo' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'updateLegalInfo' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetMeCompaniesQueryQuery, GetMeCompaniesQueryQueryVariables>
export const BankAccountsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'BankAccounts' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'companyId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bankAccounts' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'companyId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'companyId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'bankName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'paymentAccount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'correspondentAccount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'companyId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'bic' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'actions' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'delete' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<BankAccountsQuery, BankAccountsQueryVariables>
export const VerificationRequirementsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'VerificationRequirements' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'me' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'companies' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'rejectionReason' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'verificationStatus' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'verificationRequirements' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'requisites' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'bankAccount' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'documents' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<VerificationRequirementsQuery, VerificationRequirementsQueryVariables>
export const QueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Query' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'NotesFilterInput' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sorting' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'NoteSortingInput' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'pagination' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'PaginationInput' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'notes' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sorting' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'sorting' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pagination' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'pagination' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'notes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'authorId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'model' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'entityId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'author' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<QueryQuery, QueryQueryVariables>
export const UsersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Users' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'pagination' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UserPaginationInput' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'UsersFilterInput' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'users' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pagination' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'pagination' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'users' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'role' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deactivatedAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'authenticatedAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'avatarUrl' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'companies' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'legalName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'actions' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'updateAvatar' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'update' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'deactivate' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'activate' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UsersQuery, UsersQueryVariables>
