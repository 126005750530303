import * as yup from 'yup'

import { VALIDATORS_MSG } from '~shared/validations'

export const warehouseCultureFormSchema = yup.object({
  volumeTons: yup
    .number()
    .typeError(VALIDATORS_MSG.required)
    .required(VALIDATORS_MSG.required)
    .test('max', 'должно быть больше или равно 0', (val: number | undefined): boolean => Number(val) >= 0),
  culture: yup
    .object({
      // hasError: yup.bool().oneOf([false], 'Параметры культуры заполнены с ошибками'),
    })
    .required(VALIDATORS_MSG.required),
  labAnalysis: yup.mixed(),
})
