import { Button } from 'antd'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { breakpoints } from '~/tokens'

export const Wrapper = styled.section`
  display: grid;
  grid-template-columns: 300px auto;
  gap: 90px;
  margin-top: 24px;
  @media (max-width: ${breakpoints.MOBILE_XL}px) {
    grid-template-columns: 1fr;
    gap: 24px;
    margin-top: unset;
  }
`

export const BasisInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;

  @media (max-width: ${breakpoints.MOBILE_XL}px) {
    gap: unset;

    & > :first-child {
      display: none;
    }
  }
`

export const BasisAdvertsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  @media (max-width: ${breakpoints.MOBILE_XL}px) {
    gap: 16px;
  }
`

export const BasisAdvertCounter = styled.div`
  display: flex;
  align-items: center;
  padding: 0px 6px;
  border-radius: 6px;
  background: #e3e3e3;
`

export const BasisAdvertsList = styled.article`
  display: flex;
  //justify-content: space-between;
  gap: 40px;

  @media (max-width: ${breakpoints.MOBILE_XL}px) {
    flex-direction: column;
    gap: 8px;
  }
`

export const BasisAdvertsListItem = styled.div`
  min-width: 250px;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  gap: 4px;
  grid-template-areas:
    'product'
    'price'
    'company';

  @media (max-width: ${breakpoints.MOBILE_XL}px) {
    gap: unset;
    grid-template-columns: max-content auto;
    grid-template-areas:
      'product price'
      'company price';

    border-radius: 8px;
    background: #fff;
    box-shadow: 0px 1px 4px 0px rgba(84, 84, 84, 0.15);
    padding: 8px;
  }
`

export const ProductInfo = styled(Link)`
  grid-area: product;
  font-size: 14px;
  line-height: 22px;
  font-weight: 700;
  display: flex;
  gap: 8px;
  text-decoration-line: underline !important;
`

export const Price = styled.div`
  grid-area: price;
  font-size: 14px;
  font-weight: 700;
  line-height: 22px;

  @media (max-width: ${breakpoints.MOBILE_XL}px) {
    max-width: 106px;
    justify-self: right;
    text-align: end;
  }
`

export const Company = styled.div`
  grid-area: company;
  display: flex;
  margin-top: 6px;

  @media (max-width: ${breakpoints.MOBILE_XL}px) {
    margin-top: unset;
  }
`

export const BasisInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;

  @media (max-width: ${breakpoints.MOBILE_XL}px) {
    gap: unset;
  }
`

export const Green = styled.span`
  color: var(--primary-main-color);
`
export const ShowAllButtonDesktop = styled(Button)`
  display: unset;

  @media (max-width: ${breakpoints.MOBILE_XL}px) {
    display: none;
  }
`

export const ShowAllButtonMobile = styled(Button)`
  display: none;
  @media (max-width: ${breakpoints.MOBILE_XL}px) {
    display: unset;
  }
`

export const BasisNameLink = styled(Link)`
  color: var(--primary-main-color);
  margin: 0;
  font-size: 18px;
  line-height: 24px;
  font-weight: 600;
`
