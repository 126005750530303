import React from 'react'

import { Space, Table } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import { ColumnType } from 'antd/lib/table/interface'

import { useGetTableHeight } from '~/hooks'
import { Badge, Text } from '~/ui-components'
import { getElevatorAdvertStatus } from '~/utils/elevator-adverts/get-elevator-advert-status'
import { getElevatorAdvertType } from '~/utils/elevator-adverts/get-elevator-advert-type'
import { formatNumber } from '~/utils/formatNumber'
import { getDateFormat } from '~/utils/getDateFormat'
import { MyElevatorAdvertListData, MyElevatorAdvertOfferCount } from '~api/generated'
import { CompanyType } from '~api/gql-generated/graphql'
import { actionArrowColumn } from '~shared/columns/shared'

import { MyAdvertsListProps } from '~pages/BasisPage/modules/MyAdvert/components/MyAdvertsList/MyAdvertsList.types'

import * as S from './MyAdvertsListTable.styled'

export const MyAdvertsListTable: React.FC<MyAdvertsListProps> = ({
  onAdvertClick,
  adverts,
  isLoading,
  currentCompanyRole,
}) => {
  const tableHeight = useGetTableHeight(294)

  const onRowClickHandler = (record: MyElevatorAdvertListData) => {
    onAdvertClick(record.id)
  }

  const columns: ColumnsType<MyElevatorAdvertListData> = [
    {
      title: '№',
      dataIndex: 'id',
      width: 60,
    },
    {
      title: 'Тип объявления',
      dataIndex: 'type',
      key: currentCompanyRole === CompanyType.Trader ? 'type' : 'hidden',
      render: (v: MyElevatorAdvertListData['type']) => {
        const type = getElevatorAdvertType(v)
        return (
          <Badge color={type.color} size="s">
            {type.name}
          </Badge>
        )
      },
    },
    {
      title: 'Статус',
      dataIndex: 'status',
      width: 200,
      render: (status: MyElevatorAdvertListData['status']) =>
        status ? (
          <Badge color={getElevatorAdvertStatus(status)?.color} size="s">
            {getElevatorAdvertStatus(status)?.name}
          </Badge>
        ) : null,
    },
    {
      title: 'Культура',
      dataIndex: ['product', 'name'],
      className: 'bold-cell',
    },
    {
      title: 'Объем, т',
      key: currentCompanyRole === CompanyType.Seller ? 'volume' : 'hidden',
      dataIndex: 'volume',
      width: 100,
      className: 'bold-cell',
      render: (volume: number) => {
        return <S.ValueGreen>{formatNumber(volume)}</S.ValueGreen>
      },
    },
    {
      title:
        currentCompanyRole === CompanyType.Buyer ? (
          <Space direction="vertical" size={2}>
            <span>Цена без НДС</span> <S.PublishedAt>(с НДС +10%)</S.PublishedAt>
          </Space>
        ) : (
          'Цена, ₽/т'
        ),
      dataIndex: 'pricePerTon',
      width: 160,
      render: (price: number, advert: MyElevatorAdvertListData) => {
        const formattedPrice = formatNumber(price)
        if (advert.type === 'BUY') {
          return (
            <Space direction="vertical">
              <b>{formattedPrice}</b>
              {advert.priceUpdatedAt && (
                <S.PublishedAt>Обн {getDateFormat(new Date(advert.priceUpdatedAt))}</S.PublishedAt>
              )}
            </Space>
          )
        }

        return (
          <Space direction="vertical" size={2}>
            <Space>
              <b>{formattedPrice}</b>
              <Text variant="normal">{advert.usingNds ? 'с НДС' : 'без НДС'}</Text>
            </Space>
            {advert.priceUpdatedAt && (
              <S.PublishedAt>Обн {getDateFormat(new Date(advert.priceUpdatedAt))}</S.PublishedAt>
            )}
          </Space>
        )
      },
    },
    {
      title: 'Базис',
      dataIndex: ['elevator', 'name'],
    },
    {
      title: 'Дата публикации',
      dataIndex: 'publishedAt',
      render: (publishedAt: MyElevatorAdvertListData['publishedAt']) => (publishedAt ? getDateFormat(publishedAt) : ''),
    },
    {
      title: 'Дата завершения',
      dataIndex: 'expiredAt',
      render: (expiredAt: MyElevatorAdvertListData['expiredAt']) => (expiredAt ? getDateFormat(expiredAt) : ''),
    },
    {
      title: 'Ставки',
      key: 'offerCount',
      width: 180,
      render: (_: MyElevatorAdvertOfferCount, advert: MyElevatorAdvertListData) => {
        return (
          <S.OffersCounterWrapper isActive={advert.offerCount.allOffers > 0}>
            <S.OffersCounter isActive={advert.offerCount.allOffers > 0}>
              Ставки: {advert.offerCount.allOffers ?? '0'}
            </S.OffersCounter>
            {advert.offerCount.newOffers > 0 && <S.OffersCounterNew>{advert.offerCount.newOffers}</S.OffersCounterNew>}
          </S.OffersCounterWrapper>
        )
      },
    },
    actionArrowColumn,
  ].filter((x: ColumnType<MyElevatorAdvertListData>) => x.key !== 'hidden')

  return (
    <Table
      className="table-interactive"
      rowKey="id"
      loading={isLoading}
      columns={columns}
      dataSource={adverts}
      scroll={{ y: tableHeight, x: 'min-content' }}
      pagination={false}
      onRow={(record, index) => ({
        onClick: onRowClickHandler.bind(null, record, index),
      })}
    />
  )
}
