import styled from 'styled-components'

import { css } from '@styled-system/css'

import { Badge, Card, Divider } from '~/ui-components'

export const Wrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
  maxWidth: '900px',
  margin: '0 auto',
})

export const CardContent = styled.div({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '16px',
})

export const Row = styled('div')(
  css({
    display: 'grid',
    gridTemplateColumns: ['1fr', null, '200px 1fr'],
  })
)

export const CardValueGreen = styled(Card.Value)(
  css({
    color: 'GreenPrimary',
  })
)

export const Properties = styled.div({
  display: 'flex',
  flexWrap: 'wrap',
  gap: '8px',
})

export const DividerStyled = styled(Divider)({
  width: '100%',
})

export const BadgeStyled = styled(Badge)({
  whiteSpace: 'unset',
})
