import React, { useEffect } from 'react'

import { Input } from 'antd'
import { Controller, useForm } from 'react-hook-form'

import { yupResolver } from '@hookform/resolvers/yup'

import { FormField, TimePicker } from '~/ui-components'
import { PhoneNumberControl, ScalesWeightLimitControl, YesOrNotControl } from '~shared/controls'

import { unloadingInfoCardFormSchema } from './UnloadingInfoCardFormSchema'

import { DealUnloadingInfoCardForm, DealUnloadingInfoCardFormValues } from './UnloadingInfoCardForm.types'

import * as S from './UnloadingInfoCardForm.styled'

export const UnloadingInfoCardForm: React.FC<DealUnloadingInfoCardForm> = ({ defaultValues, onSubmit, formId }) => {
  const {
    control,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm<DealUnloadingInfoCardFormValues>({
    defaultValues: {
      contactUser: defaultValues?.contactUser ?? '',
      contactPhone: defaultValues?.contactPhone ?? '',
      hasScales: defaultValues?.hasScales,
      scalesWeightLimit: defaultValues?.scalesWeightLimit,
      openingTime: defaultValues?.openingTime,
      closingTime: defaultValues?.closingTime,
    },
    resolver: yupResolver(unloadingInfoCardFormSchema),
  })
  const hasScales = watch('hasScales')

  useEffect(() => {
    if (!hasScales) {
      setValue('scalesWeightLimit', null)
    }
  }, [hasScales])

  return (
    <form id={formId} onSubmit={handleSubmit(onSubmit)}>
      <FormField
        label="Время работы пункта погрузки"
        validateMessage={errors.openingTime?.message ?? errors.closingTime?.message}
      >
        <S.OpeningHoursWrapper>
          <Controller
            control={control}
            name="openingTime"
            render={({ field }) => <TimePicker value={field.value} onChange={field.onChange} allowClear />}
          />
          -
          <Controller
            control={control}
            name="closingTime"
            render={({ field }) => <TimePicker value={field.value} onChange={field.onChange} />}
          />
        </S.OpeningHoursWrapper>
      </FormField>

      <FormField label="Весы" validateMessage={errors.hasScales?.message}>
        <Controller name="hasScales" control={control} render={({ field }) => <YesOrNotControl {...field} />} />
      </FormField>

      <FormField label="Тоннаж весов" htmlFor="scalesWeightLimit" validateMessage={errors.scalesWeightLimit?.message}>
        <Controller
          name="scalesWeightLimit"
          control={control}
          render={({ field }) => (
            <ScalesWeightLimitControl id="scalesWeightLimit" {...field} hasError={!!errors.scalesWeightLimit} />
          )}
        />
      </FormField>

      <FormField
        label="Ответственный за перевозку"
        htmlFor="contactUser"
        validateMessage={errors.contactUser?.message}
        isRequired
      >
        <Controller
          name="contactUser"
          control={control}
          render={({ field }) => (
            <Input
              id="contactUser"
              placeholder="Введите фамилию и имя"
              {...field}
              status={errors.contactUser && 'error'}
            />
          )}
        />
      </FormField>

      <FormField
        validateMessage={errors.contactPhone?.message}
        label="Телефон ответственного за перевозку "
        htmlFor="contactPhone"
        isRequired
      >
        <Controller
          name="contactPhone"
          control={control}
          render={({ field }) => <PhoneNumberControl {...field} id="contactPhone" hasError={!!errors.contactPhone} />}
        />
      </FormField>
    </form>
  )
}
