import React, { useState } from 'react'

import { Modal } from 'antd'

import { useMutation, useQuery } from '@apollo/client'
import { ApolloError } from '@apollo/client/errors'

import { userUiSettingsStore } from '~/store/userUiSettingsStore'
import { appToast } from '~/utils'
import { graphqlErrorHandler } from '~/utils/graphqlErrorHandler'
import { UserRole } from '~api/gql-generated/graphql'
import { updateMeAvatarMutation, updateMeMutation } from '~api/gql-mutations/me.mutation.graphql'
import { getMeQuery } from '~api/gql-query/me.query.graphql'
import { useUploadFileAndGetId } from '~hooks/_utils'
import { useAuth } from '~providers/auth'

import { ChangePhoneNumberModal } from './components/ChangePhoneNumberModal'
import { PersonalAccountInfo } from './components/PersonalAccountInfo'
import { PersonalAccountInfoEdit, PersonalAccountInfoEditValues } from './components/PersonalAccountInfoEdit'

export const PersonalAccountInfoPage: React.FC = () => {
  const { isDemoUser } = useAuth()
  const getMe = useQuery(getMeQuery)
  const [updateMe, updateMeState] = useMutation(updateMeMutation)
  const [updateMeAvatar, updateMeAvatarState] = useMutation(updateMeAvatarMutation)
  const uploadAvatarAndGetIdFn = useUploadFileAndGetId()

  const [isEdit, setEdit] = useState(false)
  const [isChangePhoneModalOpen, setChangePhoneModalOpen] = useState(false)

  const data = getMe.data?.me
  const canEdit = true || isDemoUser // FIXME: заменить на флаг который будет приходить с бэка

  const onEditHandler = () => {
    if (isDemoUser) {
      userUiSettingsStore.showDemoUserAuthModal()
      return
    }
    setEdit(true)
  }
  const onCloseEditHandler = () => setEdit(false)

  const onChangePhoneNumberHandler = () => {
    if (isDemoUser) {
      userUiSettingsStore.showDemoUserAuthModal()
      return
    }
    Modal.confirm({
      title: 'Изменить номер',
      content: 'Чтобы изменить номер телефона, необходимо подтвердить текущий.',
      okText: 'Отправить код',
      cancelText: 'Отмена',
      onOk() {
        setChangePhoneModalOpen(true)
      },
    })
  }

  const onAccountInfoEditFormSubmit = async (formValues: PersonalAccountInfoEditValues) => {
    if (formValues.avatarFile || formValues.avatarFile === null) {
      const avatarFileKey =
        formValues.avatarFile === null ? undefined : await uploadAvatarAndGetIdFn.mutateAsync(formValues.avatarFile)
      await updateMeAvatar({ variables: { input: { avatarFileKey } } })
    }

    try {
      await updateMe({
        variables: { input: { name: formValues.name, email: formValues.email, position: formValues.position } },
      })
      appToast.success({ message: 'Личные данные изменены' })
      setEdit(false)
    } catch (e) {
      e instanceof ApolloError && graphqlErrorHandler(e, 'Ошибка при изменении данных пользователя')
    }
    await getMe.refetch()
  }

  const onPhoneNumberChangeHandler = async () => {
    setChangePhoneModalOpen(false)
    appToast.success({ message: 'Номер телефона изменен' })
    await getMe.refetch()
  }

  return isEdit ? (
    <PersonalAccountInfoEdit
      onSubmit={onAccountInfoEditFormSubmit}
      onBack={onCloseEditHandler}
      loading={updateMeState.loading || updateMeAvatarState.loading}
      defaultValues={{
        name: data?.name ?? '',
        email: data?.email ?? '',
        avatarUrl: data?.avatarUrl ?? undefined,
        position: data?.position ?? undefined,
      }}
    />
  ) : (
    <>
      <PersonalAccountInfo
        loading={getMe.loading}
        name={data?.name ?? ''}
        email={data?.email ?? ''}
        phoneNumber={data?.phoneNumber ?? ''}
        position={data?.position ?? undefined}
        role={data?.role}
        canEdit={canEdit}
        onEdit={onEditHandler}
        onChangePhoneNumber={onChangePhoneNumberHandler}
      />

      <ChangePhoneNumberModal
        isOpen={isChangePhoneModalOpen}
        onCancel={() => setChangePhoneModalOpen(false)}
        afterChangeNumber={onPhoneNumberChangeHandler}
        currentPhoneNumber={data?.phoneNumber ?? ''}
      />
    </>
  )
}
