import React, { useState } from 'react'

import { Skeleton } from 'antd'
import { observer } from 'mobx-react-lite'

import { useMutation, useQuery } from '@apollo/client'
import { ApolloError } from '@apollo/client/errors'

import { userUiSettingsStore } from '~/store/userUiSettingsStore'
import { appToast } from '~/utils'
import { graphqlErrorHandler } from '~/utils/graphqlErrorHandler'
import { updateCompanyLegalInfoMutation } from '~api/gql-mutations/company.mutation.graphql'
import { getCompanyDataQuery } from '~api/gql-query/company.query.graphql'
import { getCompanyVerificationRequirements } from '~api/gql-query/me.query.graphql'
import { useAuth } from '~providers/auth'

import { CompanyAccountRequisites } from './components/CompanyAccountRequisites'
import {
  CompanyAccountRequisitesEdit,
  CompanyAccountRequisitesEditFormValues,
} from './components/CompanyAccountRequisitesEdit'

export const CompanyAccountRequisitesPage: React.FC = observer(() => {
  const { isDemoUser, companyId } = useAuth()
  const currentCompany = useQuery(getCompanyDataQuery, {
    variables: { companyId },
  })
  const [updateCompanyLegalInfo, updateCompanyLegalInfoState] = useMutation(updateCompanyLegalInfoMutation, {
    refetchQueries: [getCompanyVerificationRequirements],
  })
  const [isEdit, setEdit] = useState(false)

  const companyData = currentCompany?.data?.company ?? null

  const canEdit = (companyData?.actions?.updateLegalInfo || isDemoUser) ?? false

  const onEditHandler = () => {
    if (isDemoUser) {
      userUiSettingsStore.showDemoUserAuthModal()
      return
    }
    setEdit(true)
  }
  const onCloseEditHandler = () => setEdit(false)
  const onSubmit = async (formValues: CompanyAccountRequisitesEditFormValues) => {
    const companyId = companyData?.id
    if (!companyId) {
      return
    }
    try {
      await updateCompanyLegalInfo({
        variables: {
          companyId,
          input: {
            kpp: formValues.kpp,
            useNds: formValues.useNds,
            directorName: formValues.directorName,
            actualAddress: formValues.actualAddress,
            legalAddress: formValues.legalAddress,
            aicCharterMember: formValues?.aicCharterMember == null ? false : formValues.aicCharterMember,
            signatory: formValues.signatory,
            signatoryLegalBasis: formValues.signatoryLegalBasis,
            signatoryPosition: formValues.signatoryPosition,
          },
        },
      })
      appToast.success({ message: 'Данные о компании изменены' })
      setEdit(false)
    } catch (e) {
      e instanceof ApolloError && graphqlErrorHandler(e, 'Ошибка при изменении данных о компании')
    }
    await currentCompany.refetch()
  }

  if (!companyData) {
    return <Skeleton />
  }

  return isEdit ? (
    <CompanyAccountRequisitesEdit
      onSubmit={onSubmit}
      onBack={onCloseEditHandler}
      loading={updateCompanyLegalInfoState.loading}
      defaultValues={{
        legalName: companyData.legalName,
        inn: companyData.inn,
        kpp: companyData.kpp ?? undefined,
        ogrn: companyData.ogrn ?? undefined,
        okved: companyData.okved ?? undefined,
        directorName: companyData.directorName ?? undefined,
        useNds: companyData.useNds ?? undefined,
        signatory: companyData.signatory ?? undefined,
        signatoryLegalBasis: companyData.signatoryLegalBasis ?? undefined,
        signatoryPosition: companyData.signatoryPosition ?? undefined,
        actualAddress: companyData.actualAddress ?? undefined,
        legalAddress: companyData.legalAddress ?? undefined,
        aicCharterMember: companyData.aicCharterMember ?? undefined,
      }}
    />
  ) : (
    <CompanyAccountRequisites
      loading={currentCompany.loading}
      canEdit={canEdit}
      onEdit={onEditHandler}
      companyData={companyData}
    />
  )
})
