import React, { FC, useEffect } from 'react'

import { Button, Input, Modal } from 'antd'
import { Controller, useForm } from 'react-hook-form'

import { yupResolver } from '@hookform/resolvers/yup'

import { CreateAuctionOfferBody, OfferDataTypeEnum } from '~/api/generated'
import { FormField, UploadButton } from '~/ui-components'
import { modalSharedSettings } from '~/utils'
import { useUploadFileAndGetId } from '~hooks/_utils'
import { useGetCompanyById } from '~hooks/company'
import { useAuth } from '~providers/auth'
import {
  AddressControlDadata,
  ParametersCultureControl,
  PhoneNumberControl,
  PriceControl,
  UsingNdsControl,
  VolumeControl,
} from '~shared/controls'

import { getCreateOfferForLotModalSchema } from './CreateOfferForLotModalSchema'

import { CreateOfferForLotModalProps, FormValues } from './CreateOfferForLotModal.types'

import * as S from './CreateOfferForLotModal.styled'

const FORM_ID = 'create-offer-for-lot'

export const CreateOfferForLotModal: FC<CreateOfferForLotModalProps> = ({
  productTypeId,
  offerType,
  validatePrice,
  visible,
  onClose,
  onSubmit,
  isFetching,
}) => {
  const { companyId, phoneNumber, user } = useAuth()
  const { data: companyInfo } = useGetCompanyById(companyId)
  const uploadFileAndGetId = useUploadFileAndGetId()

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<FormValues>({
    defaultValues: {
      address: undefined,
      culture: {
        cultureTypeId: `${productTypeId}`,
      },
      volume: undefined,
      price: undefined,
      includingNds: companyInfo?.usingNds ?? undefined,
      contactUser: user?.name ?? '',
      contactPhone: '',
    },
    resolver: yupResolver(getCreateOfferForLotModalSchema(offerType, validatePrice)),
  })

  useEffect(() => reset(), [visible])

  const handleFormSubmit = async (values: FormValues) => {
    console.log(values)
    const laboratoryAnalysisFileKey = values?.labAnalysis
      ? await uploadFileAndGetId.mutateAsync(values.labAnalysis)
      : null

    const createAuctionOfferBody: CreateAuctionOfferBody = {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      companyId: companyId!,
      volume: Number(values.volume),
      price: Number(values.price),
      address: values.address?.value ?? '',
      includingNds: values.includingNds,
      laboratoryAnalysisFileKey,
      contactPerson: values.contactPhone
        ? {
            phoneNumber: values.contactPhone,
            name: values.contactUser,
          }
        : undefined,
    }

    if (Object.keys(values.culture?.parameters ?? {}).length) {
      createAuctionOfferBody.parameters = values.culture?.parameters
    }

    onSubmit(createAuctionOfferBody)
  }

  const isOfferTypeSell = offerType === OfferDataTypeEnum.Sell

  const title = isOfferTypeSell ? 'Предложить цену и объем' : 'Предложить товар'
  const ButtonSuccessText = isOfferTypeSell ? 'Предложить цену' : 'Предложить товар'

  return (
    <Modal
      {...modalSharedSettings}
      open={visible}
      onCancel={onClose}
      title={title}
      footer={[
        <Button key="cancel" htmlType="button" onClick={onClose}>
          Отмена
        </Button>,
        <Button type="primary" key="submit" htmlType="submit" loading={isFetching} form={FORM_ID}>
          {ButtonSuccessText}
        </Button>,
      ]}
    >
      <S.Form onSubmit={handleSubmit(handleFormSubmit)} id={FORM_ID}>
        {offerType === OfferDataTypeEnum.Buy && (
          <FormField validateMessage={errors.culture ? 'Параметры культуры заполнены с ошибками' : ''}>
            <Controller
              name="culture"
              control={control}
              render={({ field }) => <ParametersCultureControl isSelectDisabled {...field} value={field.value} />}
            />
          </FormField>
        )}

        {offerType === OfferDataTypeEnum.Buy && (
          <FormField label="Результаты лаб. исследований" validateMessage={errors.labAnalysis?.message} isRequired>
            <Controller
              control={control}
              name="labAnalysis"
              render={({ field }) => (
                <UploadButton
                  onChange={(evt) => {
                    if (evt.target.files?.length) {
                      field.onChange(evt.target.files[0])
                    }
                  }}
                >
                  Загрузить
                </UploadButton>
              )}
            />
          </FormField>
        )}

        <S.Price>
          <FormField label="Цена, ₽/тонн" validateMessage={errors.price?.message} isRequired>
            <>
              <Controller
                name="price"
                control={control}
                render={({ field }) => <PriceControl id="price" {...field} hasError={!!errors.price} />}
              />
              <S.NdsWrapper>
                <Controller
                  control={control}
                  name="includingNds"
                  render={({ field }) => <UsingNdsControl {...field} />}
                />
              </S.NdsWrapper>
            </>
          </FormField>
        </S.Price>

        <FormField label="Объем, тонн" validateMessage={errors.volume?.message} isRequired>
          <Controller
            name="volume"
            control={control}
            render={({ field }) => <VolumeControl id="volume" {...field} hasError={!!errors.volume} />}
          />
        </FormField>

        <FormField label="Адрес" htmlFor="address" isRequired validateMessage={errors.address?.message}>
          <S.Address>
            <Controller
              name="address"
              control={control}
              render={({ field }) => (
                <AddressControlDadata
                  id="address"
                  value={undefined}
                  onChange={(value) => field.onChange(value)}
                  hasError={!!errors.address}
                />
              )}
            />
          </S.Address>
        </FormField>

        <FormField label="Контактное лицо" htmlFor="contactUser" validateMessage={errors.contactUser?.message}>
          <Controller
            name="contactUser"
            control={control}
            render={({ field }) => (
              <Input id="contactUser" placeholder="Укажите ФИО" {...field} status={errors.contactUser && 'error'} />
            )}
          />
        </FormField>

        <FormField validateMessage={errors.contactPhone?.message} label="Телефон" htmlFor="contactPhone">
          <Controller
            name="contactPhone"
            control={control}
            render={({ field }) => <PhoneNumberControl {...field} id="contactPhone" hasError={!!errors.contactPhone} />}
            rules={{
              validate: {
                contactPhone: () => false,
              },
            }}
          />
        </FormField>
      </S.Form>
    </Modal>
  )
}
