import React, { useEffect, useRef } from 'react'

import { ParametersCultureControl } from '~shared/controls'

export const LandingCreateOffer = () => {
  const containerRef = useRef<HTMLDivElement | null>(null)
  const height = useRef<number | null>(null)

  useEffect(() => {
    if (!containerRef.current) return
    const sendPostMessage = () => {
      const offsetHeight = containerRef.current?.offsetHeight ?? 0
      if (height.current !== offsetHeight) {
        height.current = offsetHeight
        window.parent.postMessage(
          {
            frameHeight: height.current,
          },
          '*'
        )
      }
    }

    // sendPostMessage отправляет сообщение родителю о изменении высоты
    sendPostMessage()

    window.addEventListener('resize', sendPostMessage)

    // setInterval(sendPostMessage, 500)
  }, [])

  return (
    <div ref={containerRef}>
      <ParametersCultureControl onChange={console.log} value={undefined} />
    </div>
  )
}
