import { Button } from 'antd'
import styled from 'styled-components'

import { breakpoints } from '~/tokens'

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 16px;
`

export const CreateLotButton = styled(Button)`
  @media (max-width: ${breakpoints.MOBILE_L}px) {
    width: 100%;
  }
`
