import React, { PropsWithChildren } from 'react'

import { Select } from 'antd'
import { BaseSelectRef } from 'rc-select'

import { getElevatorPriceTypeName } from '~/utils/elevators/get-elevator-price-type-name'
// import * as S from './PriceTypeControl.styled'
import { OfferPriceType } from '~api/generated'
import { CustomControlInterface } from '~shared/controls/CustomControl.interface'

type Value = OfferPriceType | undefined

interface IPriceControlProps extends CustomControlInterface {
  id?: string
  onChange: (value: Value) => void
  value: Value
  hasError?: boolean
}

const transferTypeOptions = [
  {
    label: getElevatorPriceTypeName(OfferPriceType.Census),
    value: OfferPriceType.Census,
  },
  {
    label: getElevatorPriceTypeName(OfferPriceType.AtBuyersGate),
    value: OfferPriceType.AtBuyersGate,
  },
  {
    label: getElevatorPriceTypeName(OfferPriceType.AtSellersGate),
    value: OfferPriceType.AtSellersGate,
  },
]

export const PriceTypeControl: React.FC<PropsWithChildren<IPriceControlProps>> = React.forwardRef<
  BaseSelectRef,
  PropsWithChildren<IPriceControlProps>
>(({ value, onChange, hasError, id }, ref) => {
  const onChangeHandler = (checkedValue: string) => {
    onChange(checkedValue as Value)
  }
  return (
    <Select
      ref={ref}
      id={id}
      style={{ width: '100%' }}
      placeholder="Выберите тип"
      value={value}
      onChange={onChangeHandler}
      status={hasError ? 'error' : undefined}
      optionFilterProp="children"
      allowClear
    >
      {transferTypeOptions.map((it) => (
        <Select.Option key={it.value} value={it.value}>
          {it.label}
        </Select.Option>
      ))}
    </Select>
  )
})

PriceTypeControl.displayName = 'PriceTypeControl'
