import styled from 'styled-components'

import { css } from '@styled-system/css'

import { Text } from '~/ui-components'

export const Header = styled.div(
  css({
    variant: 'typography.headingsH5',
    color: 'TxtDefault',
    fontWeight: '700',
  })
)

export const NameTitle = styled(Text).attrs({ variant: 'h3' })(
  css({
    marginBottom: ['24px', null, '32px'],
  })
)

export const Row = styled('div')(
  css({
    display: 'grid',
    gridTemplateColumns: ['1fr', null, '170px 1fr'],
  })
)
