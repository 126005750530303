import { useQuery, UseQueryOptions } from '@tanstack/react-query'

import { DealDocumentApiDealDocumentControllerGetDocumentsRequest, DealDocumentData } from '~/api/generated'
import { AppConfig } from '~/appConfig'
import { useApi } from '~providers/api'

export const useGetDealDocuments = (request: DealDocumentApiDealDocumentControllerGetDocumentsRequest) => {
  const api = useApi()
  const { dealId } = request

  const fetchFn = async (): Promise<Array<DealDocumentData>> => {
    try {
      const response = await api.dealDocument.dealDocumentControllerGetDocuments(request)
      return response.data.documents
    } catch (error) {
      return Promise.reject(error)
    }
  }

  return useQuery(['dealDocuments', request], fetchFn, {
    enabled: !!dealId,
    refetchInterval: AppConfig.REFETCH_DOCUMENTS_FOR_DEALS_INTERVAL,
  })
}
