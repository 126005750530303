import styled from 'styled-components'

import { breakpoints } from '~/tokens'

export const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  padding: 24px;
  gap: 32px;
  //max-width: 1300px;
  @media (max-width: ${breakpoints.MOBILE_XL}px) {
    padding: 24px 16px;
  }
`

export const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 32px;
  margin-top: 40px;
  @media (max-width: ${breakpoints.MOBILE_XL}px) {
    flex-direction: column-reverse;
    align-items: unset;
    margin-top: unset;
  }
`
