import React from 'react'

import { Spin } from 'antd'
import { useParams } from 'react-router-dom'

import { AppHeader } from '~/layout'
import { appRoutes } from '~/router'
import { useGetElevator } from '~hooks/elevators'
import { ElevatorDetailsInfo } from '~shared/components/ElevatorDetailsInfo'

export const BasisInfo: React.FC = () => {
  const { id } = useParams<{ id: string | undefined }>()
  const basisId = Number(id)
  const getElevatorFn = useGetElevator({ id: basisId })

  const elevator = getElevatorFn.data

  return (
    <div>
      <AppHeader title={appRoutes.basisInfo.title} isBack />
      <Spin spinning={getElevatorFn.isFetching}>
        <ElevatorDetailsInfo elevator={elevator} />
      </Spin>
    </div>
  )
}
