import styled from 'styled-components'

import { css } from '@styled-system/css'

export const InputWrapper = styled.div(
  css({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: '16px',
    marginBottom: '12px',

    '& .ant-input-number-prefix': {
      color: '#A9AAA8',
    },
    '& .ant-input-number-input': {
      textAlign: 'end',
      paddingRight: '26px !important',
    },
  })
)
