import { useMutation } from '@tanstack/react-query'

import { DealApiDealControllerUpdateDealCarrierRequest } from '~api/generated'
import { useApi } from '~providers/api'

export const useUpdateDealLogisticsCarrier = () => {
  const api = useApi()

  const updateDealLogisticsCarrierFn = async (
    request: DealApiDealControllerUpdateDealCarrierRequest
  ): Promise<void> => {
    const res = await api.deal.dealControllerUpdateDealCarrier(request)
    return res.data
  }

  return useMutation(updateDealLogisticsCarrierFn)
}
