import React, { useEffect } from 'react'

import { Input } from 'antd'
import { Controller, useForm } from 'react-hook-form'

import { yupResolver } from '@hookform/resolvers/yup/dist/yup'

import { DaDataPartySuggestion, FormField, InputDadata } from '~/ui-components'
import {
  CheckboxControl,
  ElevatorSelectControl,
  ParametersCultureControl,
  PhoneNumberControl,
  PriceControl,
  ZernoAgrOrAnotherCompanyRadioControl,
} from '~shared/controls'
import { fullPhoneNumberValidator } from '~shared/validations/schemas'

import { createAdvertFormToBuyByAdminValidation } from './CreateAdvertFormToBuyByAdmin.validation'

import {
  CreateAdvertFormToBuyByAdminFormValues,
  CreateAdvertFormToBuyByAdminProps,
} from './CreateAdvertFormToBuyByAdmin.types'

import * as S from './CreateAdvertFormToBuyByAdmin.styled'

export const CreateAdvertFormToBuyByAdmin: React.FC<CreateAdvertFormToBuyByAdminProps> = ({ onSubmit, formId }) => {
  const schema = createAdvertFormToBuyByAdminValidation()

  const {
    watch,
    handleSubmit,
    control,
    setValue,
    formState: { errors },
    trigger,
  } = useForm<CreateAdvertFormToBuyByAdminFormValues>({
    defaultValues: {
      allowOffersWithOtherParameters: false,
      company: 'other',
      companyInfo: {
        name: '',
        inn: '',
        kpp: '',
        ogrn: '',
        codeOkved: '',
        director: '',
        actualAddress: '',
        legalAddress: '',
      },
      contactUser: '',
      contactPhone: '',
    },
    resolver: yupResolver(schema),
  })

  const companyWatch = watch('company')
  const contactPhoneWatch = watch('contactPhone')
  const companyInfoWatch = watch('companyInfo')

  useEffect(() => {
    if (fullPhoneNumberValidator(contactPhoneWatch) && companyInfoWatch.inn) {
      void trigger('contactPhone')
    }
  }, [companyInfoWatch.inn, contactPhoneWatch])

  useEffect(() => {
    if (companyWatch === 'zernoAgregator') {
      resetCompanyInfo()
    }
  }, [companyWatch])

  const onSubmitHandler = (formValues: CreateAdvertFormToBuyByAdminFormValues) => {
    onSubmit(formValues)
  }

  const handleDadataSelect = (value?: DaDataPartySuggestion | undefined): void => {
    const data = value?.data

    if (!data) {
      console.log('There is no data for set')
      return
    }

    setValue(
      'companyInfo',
      {
        name: data.name?.short_with_opf ?? value?.value,
        inn: data?.inn,
        kpp: data?.kpp ?? '',
        ogrn: data?.ogrn ?? '',
        codeOkved: data?.okved,
        director: data.type === 'INDIVIDUAL' ? data.name?.full : data.management?.name ?? '',
        legalAddress: data.address?.value,
        actualAddress: data.address?.value,
      },
      { shouldValidate: true }
    )
  }

  const resetCompanyInfo = (name?: string) => {
    setValue('companyInfo', {
      name: name ?? '',
      inn: '',
      kpp: '',
      ogrn: '',
      codeOkved: '',
      director: '',
      legalAddress: '',
      actualAddress: '',
    })
  }

  return (
    <form id={formId} onSubmit={handleSubmit(onSubmitHandler)}>
      <FormField label="Базис" htmlFor="elevatorId" validateMessage={errors.elevatorId?.message} isRequired>
        <Controller
          name="elevatorId"
          control={control}
          render={({ field }) => <ElevatorSelectControl id="elevatorId" {...field} hasError={!!errors.elevatorId} />}
        />
      </FormField>

      <FormField validateMessage={errors.culture ? 'Параметры культуры заполнены с ошибками' : ''}>
        <Controller
          name="culture"
          control={control}
          render={({ field }) => <ParametersCultureControl isRequired {...field} />}
        />
      </FormField>

      <FormField htmlFor="pricePerTon" label="Цена без НДС" validateMessage={errors.pricePerTon?.message} isRequired>
        <Controller
          name="pricePerTon"
          control={control}
          render={({ field }) => (
            <PriceControl id="pricePerTon" {...field} hasError={!!errors.pricePerTon} addAfter="₽/т" />
          )}
        />
      </FormField>

      <FormField>
        <Controller
          control={control}
          name="allowOffersWithOtherParameters"
          render={({ field }) => (
            <div>
              <CheckboxControl {...field}>Рассматриваю предложения с похожими показателями</CheckboxControl>
              <S.InfoText>Продавцы смогут предлагать вам культуру с собственными показателями</S.InfoText>
            </div>
          )}
        />
      </FormField>

      <S.DividerStyled />

      <FormField label="Разместить от лица">
        <Controller
          name="company"
          control={control}
          render={({ field }) => <ZernoAgrOrAnotherCompanyRadioControl {...field} />}
        />
      </FormField>

      {companyWatch === 'other' && (
        <FormField
          label="Наименование компании или ИНН"
          htmlFor="companyInfo"
          validateMessage={errors.companyInfo?.inn?.message}
          isRequired
        >
          <Controller
            name="companyInfo"
            control={control}
            render={({ field }) => (
              <InputDadata
                id="name"
                placeholder="Введите наименование компании"
                inputValue={field.value.name}
                onSelect={handleDadataSelect}
                variant="organizations"
                inputStatus={errors.companyInfo && 'error'}
                onChangeInput={resetCompanyInfo}
              />
            )}
          />
        </FormField>
      )}

      <FormField label="Контактное лицо" htmlFor="contactUser" validateMessage={errors.contactUser?.message} isRequired>
        <Controller
          name="contactUser"
          control={control}
          render={({ field }) => (
            <Input id="contactUser" placeholder="Укажите ФИО" {...field} status={errors.contactUser && 'error'} />
          )}
        />
      </FormField>

      <FormField validateMessage={errors.contactPhone?.message} label="Телефон" htmlFor="contactPhone" isRequired>
        <Controller
          name="contactPhone"
          control={control}
          render={({ field }) => <PhoneNumberControl {...field} id="contactPhone" hasError={!!errors.contactPhone} />}
          rules={{
            validate: {
              contactPhone: () => false,
            },
          }}
        />
      </FormField>
    </form>
  )
}
