import { ThemeConfig } from 'antd/es/config-provider/context'

const _primary_main_color = '#45A67D'
const _hoverColor = '#52BD90'
const _primary_bg_color = '#F7FAF9'
const _primary_active_color = '#64D4A5'
const _placeholder_color = '#9E9E9E'
const _timeline_color = '#EDEDED'
export const ZernoTheme: ThemeConfig = {
  token: {
    colorPrimary: _primary_main_color,
    colorPrimaryBg: _primary_bg_color,
    colorPrimaryActive: _primary_active_color,
    colorPrimaryHover: _hoverColor,
    colorLink: _primary_main_color,
    colorTextPlaceholder: _placeholder_color,
    fontFamily: '"Inter", Helvetica, Arial, sans-serif',
    fontSize: 14,
    colorLinkHover: _hoverColor,
    colorLinkActive: _hoverColor,
  },
  components: {
    Button: {
      colorPrimary: _primary_main_color,
    },
    Input: {
      colorText: '#494949',
      borderRadius: 4,
    },
    Segmented: {
      colorBgElevated: _primary_main_color,
    },
    Timeline: {
      tailColor: _timeline_color,
      dotBorderWidth: 2,
    },
  },
}
