import { useMutation } from '@tanstack/react-query'

import { useApi } from '~providers/api'

export const useMarkNotificationReadForAll = () => {
  const api = useApi()

  return useMutation(() => {
    return api.notifications.notificationControllerMarkAllNotificationsViewed()
  })
}
