import React from 'react'

import { RouteObject } from 'react-router-dom'

import { EmployeesPage } from '~/pages'
import { appRoutes } from '~/router'

import { RequireAuth } from '../../providers/auth'

export const employeesRouter: RouteObject = {
  path: appRoutes.employees.url,
  element: (
    <RequireAuth>
      <EmployeesPage />
    </RequireAuth>
  ),
}
