import styled from 'styled-components'
import { Flex } from 'antd'

export const Wrapper = styled(Flex)`
  padding: 24px;
`

export const TextId = styled.div`
  font-size: 24px;
  font-weight: 400;
  color: #8c8c8c;
`

export const TextTransportType = styled.div`
  font-size: 28px;
  font-weight: 700;
  color: black;
`

export const TextLicensePlate = styled.div`
  font-size: 24px;
  font-weight: 600;
  color: black;
  border: 2px solid black;
  border-radius: 8px;
  padding: 8px 16px;
`
