import { UnloadingTransportType } from '~api/generated'
import { UnloadingTransportType as UnloadingTransportTypeGQL } from '~api/gql-generated/graphql'

export function getElevatorUnloadType(loadType?: UnloadingTransportType | UnloadingTransportTypeGQL | null): string {
  if (!loadType?.length) {
    return '-'
  }
  switch (loadType) {
    case UnloadingTransportType.TailgateAutoUnloader: {
      return 'Авторазгрузчик (задний борт)'
    }
    case UnloadingTransportType.SideBoardAutoUnloader: {
      return 'Авторазгрузчик (боковой борт)'
    }
    case UnloadingTransportType.Manipulator: {
      return 'Манипулятор'
    }
    case UnloadingTransportType.DumpTruck: {
      return 'Самосвал'
    }
    case UnloadingTransportType.MechanicalShovel: {
      return 'Ручной (мехлопаты)'
    }
    case UnloadingTransportType.PneumaticUnloader: {
      return 'Пневморазгрузчик'
    }
    default: {
      return ''
    }
  }
}
