import { NavLink } from 'react-router-dom'
import styled from 'styled-components'

import { Text } from '~/ui-components'

export const StyledNavLink = styled(NavLink)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 48px;
  padding: 14px 0;

  font-weight: 700;
  font-size: 14px;
  line-height: 16px;

  color: var(--gray-8-color) !important;

  &.active {
    color: var(--primary-main-color) !important;
    background: rgba(69, 166, 125, 0.1) !important;
    border-left: 2px solid var(--primary-main-color);
  }

  &:hover {
    color: var(--primary-main-color) !important;
    background: rgba(69, 166, 125, 0.15) !important;
  }
`
