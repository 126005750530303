export type NumberTuple = number[]

/* Добавляет алиасы в массив */
export const addAliases = (arr: string[], aliases: string[]) =>
  aliases.forEach((key, idx) =>
    Object.defineProperty(arr, key, {
      get() {
        return this[idx]
      },
    })
  )

/* Преобразует 'px' в 'em' */
export const pxToEm = (val: number) => val / 16

export const getNumberTuple = ({ limit, step }: { limit: number; step: number }): NumberTuple =>
  limit > 0 ? [...getNumberTuple({ limit: limit - step, step }), limit] : [limit]
