import React, { useEffect, useState } from 'react'

import { Skeleton, Spin } from 'antd'
import { useParams } from 'react-router'

import { Text } from '~/ui-components'
import { appToast } from '~/utils'
import {
  isDocumentTypeUsesForDealSide,
  paymentListBuyer,
  paymentListSeller,
} from '~/utils/deals/get-deal-document-payment-list'
import { DealSideType } from '~/utils/deals/get-deal-side'
import { DealDocumentData, FileData, DocumentPartStatus } from '~api/generated'
import { useGetDealDocuments, useUpdateDealDocumentStatus } from '~hooks/deal-documents'
import { useGetModeratorDeal } from '~hooks/moderator'
import { DealPaymentSection } from '~pages/Deals/modules/DealPayment/components/DealPaymentSection'
import { SellerOrBuyerRadioControl } from '~pages/ModeratorPage/components/ModeratorDeals/components'
import { DocumentChecker } from '~shared/components'

import * as S from './ModeratorDealPayments.styled'
import * as DealPaymentStyle from '~/pages/Deals/modules/DealPayment/DealPayment.styled'

export const ModeratorDealPayments: React.FC = () => {
  const params = useParams<{ id: string | undefined }>()
  const complexDealId = Number(params.id)
  const dealFn = useGetModeratorDeal(complexDealId)
  const [documentForCheck, setDocumentForCheck] = useState<{ document: DealDocumentData; instanceId: number } | null>(
    null
  )
  const [dealSide, setDealSide] = useState<'seller' | 'buyer'>('seller')
  const [dealId, setDealId] = useState<number | null>(null)
  const dealDocumentsFn = useGetDealDocuments({ dealId: dealId ?? NaN })
  const updateDocumentStatusFn = useUpdateDealDocumentStatus()

  const dealDocuments = dealDocumentsFn.data?.filter((doc) => !!doc.instances?.length)
  const uploadedDocuments = dealDocuments?.filter((x) => x.instances.filter((x) => !!x.id).length) ?? []

  useEffect(() => {
    if (dealFn.data?.dealWithSeller || dealFn.data?.dealWithBuyer) {
      if (dealSide === 'seller') {
        setDealId(dealFn.data.dealWithSeller?.id ?? null)
        return
      }
      if (dealSide === 'buyer') {
        setDealId(dealFn.data.dealWithBuyer?.id ?? null)
        return
      }
      setDealId(null)
    }
  }, [dealFn.data?.id, dealSide])

  if (
    !dealFn.data ||
    !dealDocumentsFn.data ||
    dealFn.isLoading ||
    dealDocumentsFn.isLoading ||
    updateDocumentStatusFn.isLoading ||
    !dealId
  ) {
    return <Skeleton />
  }

  const afterUploadDocumentHandler = () => dealDocumentsFn.refetch()
  const afterRemoveDocumentHandler = () => dealDocumentsFn.refetch()

  const checkDocumentByAdminHandler = (document: DealDocumentData, instanceId: number) => {
    setDocumentForCheck({ document, instanceId })
  }
  const changeDocumentFileStatus = async (file: FileData, status: DocumentPartStatus, reason?: string) => {
    try {
      await updateDocumentStatusFn.mutateAsync({
        fileId: file.id,
        updateDealDocumentFileBody: {
          status,
          rejectionNote: reason || null,
        },
      })
    } catch (e) {
      appToast.error({ description: 'Ошибка при изменении статуса документа' })
    }

    appToast.success({ description: 'Статус документа изменен' })

    await dealDocumentsFn.refetch()
  }

  const renderUploadedDocuments = (column: DealSideType) => {
    return uploadedDocuments
      .filter((x) => isDocumentTypeUsesForDealSide(x.type, column, true))
      .map((document) => (
        <DealPaymentStyle.Section key={document.type}>
          <DealPaymentSection
            dealSide={dealSide}
            dealId={dealId}
            document={document}
            documentType={document.type}
            canChange={document.canChange}
            afterRemoveDocument={afterRemoveDocumentHandler}
            afterUploadDocument={afterUploadDocumentHandler}
            checkDocumentByAdmin={(instanceId) => checkDocumentByAdminHandler(document, instanceId)}
          />
        </DealPaymentStyle.Section>
      ))
  }

  return (
    <Spin spinning={dealFn.isLoading || dealFn.isLoading}>
      <DocumentChecker
        isCheckerVisible={!!documentForCheck}
        docTemplateList={[...paymentListBuyer, ...paymentListSeller]}
        documentsForCheck={dealDocuments ?? []}
        startCheckFromDocument={documentForCheck?.document.type ?? null}
        startCheckFromDocumentInstanceId={documentForCheck?.instanceId}
        onDocumentFileStatusChange={changeDocumentFileStatus}
        onFinishCheck={() => setDocumentForCheck(null)}
      />
      <S.Wrapper>
        <S.TopActionsWrapper>
          <SellerOrBuyerRadioControl value={dealSide} onChange={(value) => setDealSide(value)} />
        </S.TopActionsWrapper>

        <DealPaymentStyle.ContentWrapper>
          <DealPaymentStyle.Column>
            <Text variant="h3">От администратора</Text>
            {renderUploadedDocuments(dealSide === 'seller' ? 'buyer' : 'seller')}
            <DealPaymentStyle.Section>
              <DealPaymentSection
                dealSide={dealSide === 'seller' ? 'buyer' : 'seller'}
                dealId={dealId}
                canChange={false}
                afterUploadDocument={afterUploadDocumentHandler}
                afterRemoveDocument={afterRemoveDocumentHandler}
              />
            </DealPaymentStyle.Section>
          </DealPaymentStyle.Column>

          <DealPaymentStyle.Column>
            <Text variant="h3">От {dealSide === 'seller' ? 'Продавца' : 'Покупателя'}</Text>
            {renderUploadedDocuments(dealSide)}
          </DealPaymentStyle.Column>
        </DealPaymentStyle.ContentWrapper>
      </S.Wrapper>
    </Spin>
  )
}
