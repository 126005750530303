import { graphql } from '~api/gql-generated'

export const confirmPhoneNumberMutation = graphql(/* GraphQL */ `
  mutation ConfirmPhoneNumber($input: ConfirmPhoneNumberInput!) {
    auth {
      confirmPhoneNumber(input: $input) {
        numberOfAttempts
        isConfirmed
        phoneNumber
        id
      }
    }
  }
`)

export const logInAsDemoUserMutation = graphql(/* GraphQL */ `
  mutation LogInAsDemoUser($input: LogInAsDemoUserInput!) {
    auth {
      logInAsDemoUser(input: $input) {
        phoneNumber
        purpose
        numberOfAttempts
        isConfirmed
        id
      }
    }
  }
`)

export const sendLogInSmsMutation = graphql(/* GraphQL */ `
  mutation SendLogInSms($input: SendLogInSmsInput!) {
    auth {
      sendLogInSms(input: $input) {
        id
        phoneNumber
        numberOfAttempts
        isConfirmed
      }
    }
  }
`)
export const requestPhoneNumberConfirmationCodeMutation = graphql(/* GraphQL */ `
  mutation RequestPhoneNumberConfirmationCode($input: RequestPhoneNumberConfirmationCodeInput!) {
    auth {
      requestPhoneNumberConfirmationCode(input: $input) {
        purpose
        phoneNumber
        numberOfAttempts
        isConfirmed
        id
        expiredAt
        confirmedAt
      }
    }
  }
`)
export const confirmAuthPhoneNumberMutation = graphql(/* GraphQL */ `
  mutation ConfirmAuthPhoneNumber($input: ConfirmPhoneNumberInput!) {
    auth {
      confirmAuthPhoneNumber(input: $input) {
        purpose
        phoneNumber
        numberOfAttempts
        isConfirmed
        id
        expiredAt
        confirmedAt
      }
    }
  }
`)

export const sendSignUpSmsMutation = graphql(/* GraphQL */ `
  mutation SendSignUpSms($input: SendSignUpSmsInput!) {
    auth {
      sendSignUpSms(input: $input) {
        id
        phoneNumber
        numberOfAttempts
        isConfirmed
      }
    }
  }
`)
export const signUpMutation = graphql(/* GraphQL */ `
  mutation SignUp($input: AuthSignUpInput!) {
    auth {
      signUp(input: $input)
    }
  }
`)
export const SignUpForeignMutation = graphql(/* GraphQL */ `
  mutation SignUpForeign($input: SignUpForeignInput!) {
    auth {
      signUpForeign(input: $input)
    }
  }
`)

export const logInBySmsCodeMutation = graphql(/* GraphQL */ `
  mutation LogInBySmsCode($input: LogInBySmsCodeInput!) {
    auth {
      logInBySmsCode(input: $input)
    }
  }
`)

// залогиниться от лица друго пользователя (админа)
export const loginByAdminAsUserMutation = graphql(/* GraphQL */ `
  mutation LoginAsUser($input: AsUserLoginInput!) {
    auth {
      loginAsUser(input: $input)
    }
  }
`)

// вернутся обратно как админ, в текущей сессии пользователя
export const logOutUserReturnModeratorMutation = graphql(/* GraphQL */ `
  mutation LogOutUserReturnModerator {
    auth {
      logOutUserReturnModerator
    }
  }
`)
export const setActiveCompanyMutation = graphql(/* GraphQL */ `
  mutation SetActiveCompany($input: SetActiveCompanyInput!) {
    setActiveCompany(input: $input)
  }
`)
