import * as yup from 'yup'
import { TestContext } from 'yup'

import { VALIDATORS_MSG } from '~shared/validations'

const maxAvailableAdvertVolumeValidator = (val: number | undefined, context: TestContext): boolean => {
  const maxAvailableAdvertVolume = (context.options.context as { maxAvailableAdvertVolume: number })
    ?.maxAvailableAdvertVolume
  if (!val || !maxAvailableAdvertVolume) {
    return true
  }
  return val <= maxAvailableAdvertVolume
}
export const createBuyAdvertFormSchema = yup.object({
  volume: yup
    .number()
    .test(
      'maxAvailableAdvertVolume',
      'Объем не должен превышать доступный объем в предложении',
      maxAvailableAdvertVolumeValidator
    )
    .typeError(VALIDATORS_MSG.required)
    .min(100, VALIDATORS_MSG.minValue(100))

    .required(VALIDATORS_MSG.required),
  pricePerTon: yup
    .number()
    .typeError(VALIDATORS_MSG.required)
    .min(1000, VALIDATORS_MSG.minValue(1000))
    .required(VALIDATORS_MSG.required),
  usingNds: yup.boolean().notRequired(),
})
