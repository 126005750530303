import React, { useEffect, useState } from 'react'

import { Button, Empty, Flex, Modal, Pagination, Table } from 'antd'
import { observer } from 'mobx-react-lite'
import { FiChevronDown, FiChevronUp } from 'react-icons/fi'
import { useNavigate } from 'react-router-dom'

import { useMutation, useQuery } from '@apollo/client'
import { ApolloError } from '@apollo/client/errors'

import { usePagination } from '~/hooks'
import { AppHeader } from '~/layout'
import { appRoutes } from '~/router'
import { Text } from '~/ui-components'
import { modalSharedSettings } from '~/utils'
import { graphqlErrorHandler } from '~/utils/graphqlErrorHandler'
import { FreightDealStatus, FreightDealType, FreightOrderResponseFilterInput } from '~api/gql-generated/graphql'
import { freightResponseUpdateStatusMutation } from '~api/gql-mutations/freight-response-update-status.mutation.graphql'
import { freightOrderResponsesQuery } from '~api/gql-query/freight-order-responses.query.graphql'
import { useGetCurrentRole } from '~hooks/auth'
import { FreightOrderResponsesTableRowDetails } from '~pages/LogisticsPage/modules/LogisticDeals/components/FreightOrderResponsesTableRowDetails/FreightOrderResponsesTableRowDetails'
import { getFreightOrderResponseColumns } from '~pages/LogisticsPage/shared/getFreightOrderResponsesColumns'
import { useAuth } from '~providers/auth'

import { ResponseForm } from './components/ResponseForm'

import { ResponseFormValues } from './components/ResponseForm/ResponseForm.types'
import { ResponsesFilter } from './components/ResponsesFilter'
import { logisticResponsesFilterStore } from '~/store/filters'
import { isIntegerString } from '~/utils/isIntegerString'

export const LogisticResponses: React.FC = observer(() => {
  const store = logisticResponsesFilterStore
  const navigate = useNavigate()
  const { companyId } = useAuth()
  const {
    company: { isCarrier },
  } = useGetCurrentRole()
  const { currentPage, setCurrentPage, pageSize, setPageSize, pageSizeOptions } = usePagination()

  const [currentResponseId, setCurrentResponseId] = useState<number | null>(null)
  const [currentResponseType, setCurrentResponseType] = useState<FreightDealType | null>(null)
  const [currentResponseModalType, setCurrentResponseModalType] = useState<'Reject' | 'Cancel' | 'Accept' | null>(null)
  const [isResponseModalOpen, setIsResponseModalOpen] = useState(false)
  const [isSuccessResponseModalOpen, setIsSuccessResponseModalOpen] = useState(false)

  const searchFilters: Partial<FreightOrderResponseFilterInput> = {
    search: isIntegerString(store.mappedCurrentFiltersToResponse.search)
      ? undefined
      : store.mappedCurrentFiltersToResponse.search,
    freightDealIds: isIntegerString(store.mappedCurrentFiltersToResponse.search)
      ? [Number(store.mappedCurrentFiltersToResponse.search)]
      : undefined,
  }

  const statuses = store.mappedCurrentFiltersToResponse.statuses
  const freightOrderResponsesFn = useQuery(freightOrderResponsesQuery, {
    variables: {
      filter: {
        carrierIds: isCarrier ? [companyId] : undefined,
        customerIds: !isCarrier ? [companyId] : undefined,
        ...store.mappedCurrentFiltersToResponse,
        statuses: statuses
          ? statuses.includes(FreightDealStatus.Agreement)
            ? [...statuses, FreightDealStatus.Realised, FreightDealStatus.Rejected]
            : statuses
          : undefined,
        ...searchFilters,
      },
      pagination: { currentPage, pageSize },
    },
    onError: graphqlErrorHandler,
    fetchPolicy: 'cache-and-network',
  })

  const [freightResponseUpdateStatus] = useMutation(freightResponseUpdateStatusMutation, {
    refetchQueries: [freightOrderResponsesQuery],
  })

  useEffect(() => {
    void freightOrderResponsesFn.refetch()
    setCurrentPage(1)
  }, [
    store.responsesFilters.freightOrderIds,
    store.responsesFilters.regionIds,
    store.responsesFilters.productTypeIds,
    store.responsesFilters.statuses,
    store.responsesFilters.search,
  ])

  const onFreightResponseUpdateStatus = async (formValues: ResponseFormValues) => {
    try {
      const status =
        currentResponseModalType === 'Accept' ? FreightDealStatus.Agreement : FreightDealStatus.RejectedAfterModeration
      await freightResponseUpdateStatus({
        variables: {
          freightDealId: currentResponseId as number,
          input: {
            status,
            rejectionReason: formValues?.rejectionReason,
          },
        },
      })
      setIsResponseModalOpen(false)
      setIsSuccessResponseModalOpen(true)
    } catch (e) {
      e instanceof ApolloError && graphqlErrorHandler(e, 'Ошибка при изменении статуса отклика')
    }
  }

  const freightOrderResponses = freightOrderResponsesFn.data?.freightOrderResponses.freightOrderResponses ?? []
  const pagination = freightOrderResponsesFn.data?.freightOrderResponses.pagination

  const columns = getFreightOrderResponseColumns()

  const locale = {
    emptyText: (
      <Flex vertical gap={14}>
        <Empty description="" />
        <Text variant="h4">Здесь будут ваши отклики</Text>
        <Text variant="normal">У вас пока нет ни одного отклика</Text>
      </Flex>
    ),
  }

  const openResponseModal = (id: number, type: FreightDealType, modalType: 'Reject' | 'Cancel' | 'Accept' | null) => {
    setIsResponseModalOpen(true)
    setCurrentResponseId(id)
    setCurrentResponseType(type)
    setCurrentResponseModalType(modalType)
  }

  const getResponseModalTitle = () => {
    let action = ''
    switch (currentResponseModalType) {
      case 'Reject':
        action = 'Отклонить'
        break
      case 'Cancel':
        action = 'Отменить'
        break
      case 'Accept':
        action = 'Принять'
        break
      default:
        break
    }
    return currentResponseType === FreightDealType.Response ? `${action} отклик` : `${action} ставку`
  }

  const getSuccessResponseModalTitle = () => {
    switch (currentResponseModalType) {
      case 'Reject':
        return 'Отказ направлен'
      case 'Cancel':
        return currentResponseType === FreightDealType.Response ? 'Отклик отменен' : 'Ставка отменена'
      case 'Accept':
        return currentResponseType === FreightDealType.Response ? 'Отклик принят' : 'Ставка принята'
      default:
        return ''
    }
  }

  return (
    <Flex vertical>
      <AppHeader title={appRoutes.logisticResponses.title} />
      <Flex vertical gap={24} style={{ padding: 24 }}>
        <ResponsesFilter />

        {!!pagination?.total && <Text variant="normal">Всего откликов: {pagination?.total}</Text>}

        <Table
          locale={locale}
          rowKey="id"
          className="table-interactive"
          loading={freightOrderResponsesFn.loading}
          columns={columns}
          dataSource={freightOrderResponses}
          scroll={{ x: 'max-content' }}
          expandable={{
            expandedRowRender: (record) => (
              <FreightOrderResponsesTableRowDetails
                freightOrderResponse={record}
                onGoToOrder={(freightOrderResponse) =>
                  navigate(appRoutes.logisticDealCard.url.replace(':id', freightOrderResponse.id.toString()))
                }
                onAcceptResponse={(freightOrderResponse) =>
                  openResponseModal(freightOrderResponse.id, freightOrderResponse.type, 'Accept')
                }
                onDeclineResponse={(freightOrderResponse) =>
                  openResponseModal(freightOrderResponse.id, freightOrderResponse.type, 'Reject')
                }
                onCarrierDeclineBet={(freightOrderResponse) =>
                  openResponseModal(freightOrderResponse.id, FreightDealType.Bet, 'Cancel')
                }
                onCarrierDeclineResponse={(freightOrderResponse) =>
                  openResponseModal(freightOrderResponse.id, FreightDealType.Response, 'Cancel')
                }
              />
            ),
            showExpandColumn: true,
            expandIcon: ({ expanded, onExpand, record }) =>
              expanded ? (
                <Flex
                  align="center"
                  gap={10}
                  style={{ color: 'var(--primary-main-color)' }}
                  onClick={(e) => onExpand(record, e)}
                >
                  <FiChevronUp size={20} /> Свернуть
                </Flex>
              ) : (
                <Flex
                  align="center"
                  gap={10}
                  style={{ color: 'var(--primary-main-color)' }}
                  onClick={(e) => onExpand(record, e)}
                >
                  <FiChevronDown size={20} /> Развернуть
                </Flex>
              ),
          }}
          pagination={false}
        />

        {pagination && (
          <Pagination
            style={{ marginTop: 'auto' }}
            current={pagination?.currentPage}
            total={pagination?.total}
            pageSize={pagination?.pageSize}
            pageSizeOptions={pageSizeOptions}
            onShowSizeChange={(x, size) => setPageSize(size)}
            showSizeChanger
            onChange={setCurrentPage}
          />
        )}
      </Flex>

      <Modal
        {...modalSharedSettings}
        width={400}
        open={isResponseModalOpen}
        onCancel={() => setIsResponseModalOpen(false)}
        title={getResponseModalTitle()}
      >
        <ResponseForm
          onBack={() => setIsResponseModalOpen(false)}
          onSubmit={onFreightResponseUpdateStatus}
          isAccept={currentResponseModalType === 'Accept'}
        />
      </Modal>

      <Modal
        {...modalSharedSettings}
        width={400}
        open={isSuccessResponseModalOpen}
        onCancel={() => setIsSuccessResponseModalOpen(false)}
        title={getSuccessResponseModalTitle()}
      >
        <Flex vertical gap={24}>
          {currentResponseModalType !== 'Accept' && (
            <Flex>
              <Text variant="normal">
                {`Причина будет указана в карточке ${
                  currentResponseType === FreightDealType.Response ? 'отклика' : 'ставки'
                }`}
              </Text>
            </Flex>
          )}

          <Flex gap={16} justify="flex-end">
            <Button
              htmlType="button"
              type="primary"
              onClick={() => {
                setCurrentResponseId(null)
                setCurrentResponseType(null)
                setCurrentResponseModalType(null)
                setIsSuccessResponseModalOpen(false)
              }}
            >
              Понятно!
            </Button>
          </Flex>
        </Flex>
      </Modal>
    </Flex>
  )
})
