import { useQuery, UseQueryResult } from '@tanstack/react-query'

import { ElevatorApiElevatorControllerGetElevatorsRequest, GetElevatorsResponse } from '~api/generated'
import { useApi } from '~providers/api'

export const useGetElevators = (
  request: ElevatorApiElevatorControllerGetElevatorsRequest
): UseQueryResult<GetElevatorsResponse> => {
  const api = useApi()

  const fetchFn = async (): Promise<GetElevatorsResponse> => {
    const res = await api.elevators.elevatorControllerGetElevators(request)

    return res.data
  }

  return useQuery(['elevators', request], fetchFn, { keepPreviousData: true })
}
