import React from 'react'

import { Button, Input } from 'antd'
import { Controller, useForm } from 'react-hook-form'

import { yupResolver } from '@hookform/resolvers/yup/dist/yup'

import { FormField } from '~/ui-components'
import { AvatarUploader, ResponsePageHeader } from '~shared/components'

import { personalAccountInfoEditSchema } from '~pages/AccountPage/modules/PersonalAccountInfoPage/components/PersonalAccountInfoEdit/PersonalAccountInfoEdit.validation'

import { PersonalAccountInfoEditProps, PersonalAccountInfoEditValues } from './PersonalAccountInfoEdit.types'

import * as S from './PersonalAccountInfoEdit.styled'

export const PersonalAccountInfoEdit: React.FC<PersonalAccountInfoEditProps> = ({
  onSubmit,
  onBack,
  loading,
  defaultValues,
}) => {
  const {
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = useForm<PersonalAccountInfoEditValues>({
    defaultValues: {
      avatarFile: defaultValues.avatarFile,
      avatarUrl: defaultValues.avatarUrl,
      name: defaultValues.name,
      email: defaultValues.email,
      position: defaultValues.position,
    },
    resolver: yupResolver(personalAccountInfoEditSchema),
  })

  return (
    <S.Wrapper>
      <S.StyledForm>
        <ResponsePageHeader
          variant="edit"
          title="Редактировать личные данные"
          titleMobile="Редактировать"
          subTitle="Компания"
          loading={loading}
          onSubmit={handleSubmit(onSubmit)}
          onBack={onBack}
        />
        <AvatarUploader
          avatarUrl={defaultValues.avatarUrl}
          onUpload={(file) => setValue('avatarFile', file)}
          onDeleteAvatar={() => setValue('avatarFile', null)}
          uploadText="Изменить фото"
        />

        <FormField label="ФИО" isRequired htmlFor="name" validateMessage={errors.name?.message}>
          <Controller
            name="name"
            control={control}
            render={({ field }) => (
              <Input {...field} id="name" status={errors.name && 'error'} placeholder="Введите ФИО" />
            )}
          />
        </FormField>

        <FormField htmlFor="email" label="Электронная почта" validateMessage={errors.email?.message} isRequired>
          <Controller
            name="email"
            control={control}
            render={({ field }) => (
              <Input
                id="email"
                placeholder="zerno@mail.ru"
                status={errors.email && 'error'}
                {...field}
                value={field.value ?? ''}
              />
            )}
          />
        </FormField>

        <FormField htmlFor="position" label="Должность" validateMessage={errors.position?.message}>
          <Controller
            name="position"
            control={control}
            render={({ field }) => (
              <Input
                id="position"
                placeholder="Менеджер"
                status={errors.position && 'error'}
                {...field}
                value={field.value ?? ''}
              />
            )}
          />
        </FormField>

        <S.ActionWrapper>
          <Button type="default" htmlType="button" onClick={onBack}>
            Отмена
          </Button>
          <Button type="primary" htmlType="button" loading={loading} onClick={handleSubmit(onSubmit)}>
            Готово
          </Button>
        </S.ActionWrapper>
      </S.StyledForm>
    </S.Wrapper>
  )
}
