import React, { useEffect } from 'react'

import { Radio, RadioChangeEvent } from 'antd'
import { matchRoutes, Outlet, useLocation, useNavigate } from 'react-router-dom'

import { appRoutes } from '~/router'

import * as S from './ModeratorBasisListTab.styled'

export const ModeratorBasisListTab: React.FC = () => {
  const navigate = useNavigate()
  const { pathname } = useLocation()

  useEffect(() => {
    const routes = matchRoutes([{ path: appRoutes.moderatorBasis.url }], pathname)

    if (routes?.length && routes[0].route.path === appRoutes.moderatorBasis.url) {
      navigate(appRoutes.moderatorBasisAdverts.url)
    }
  }, [pathname])

  const onChange = (e: RadioChangeEvent) => {
    navigate(e.target.value)
  }

  const tabs = [appRoutes.moderatorBasisAdverts, appRoutes.moderatorBasisBids]

  const currentTab = pathname.split('/').pop()

  return (
    <S.ContentWrapper>
      <Radio.Group buttonStyle="solid" value={currentTab} onChange={onChange}>
        {tabs.map((tab) => (
          <Radio.Button key={tab.url} value={tab.url}>
            {tab.title}
          </Radio.Button>
        ))}
      </Radio.Group>
      <Outlet />
    </S.ContentWrapper>
  )
}
