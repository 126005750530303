import React from 'react'

import { Button, Empty, Skeleton, Space, Spin } from 'antd'
import { FiEdit2, FiPlusCircle } from 'react-icons/fi'

import { Text } from '~/ui-components'
import { CompanyAccountPayRequisitesListItem } from '~pages/AccountPage/modules/CompanyAccountPayRequisitesPage/components/CompanyAccountPayRequisitesListItem'
import { ResponsePageHeader } from '~shared/components'
import { RightPanelAddButton } from '~shared/components/ResponsePageHeader/right-elements'

import { CompanyAccountPayRequisitesListProps } from './CompanyAccountPayRequisitesList.types'

import * as S from './CompanyAccountPayRequisitesList.styled'

export const CompanyAccountPayRequisitesList: React.FC<CompanyAccountPayRequisitesListProps> = ({
  data,
  loading,
  onRemove,
  onAdd,
}) => {
  const isEmpty = !data.length && !loading

  return (
    <S.Wrapper>
      <ResponsePageHeader
        variant="view"
        title="Платежные реквизиты"
        subTitle="МОЯ КОМПАНИЯ"
        rightElement={<RightPanelAddButton onClick={onAdd} text="Добавить реквизиты" />}
      />
      {isEmpty && (
        <div>
          <Empty
            description={
              <>
                <Space direction="vertical">
                  <Text variant="normal-bold">Ой! Здесь ничего нет</Text>
                  <Text variant="normal">
                    Добавьте платежные реквизиты, это обязательный для <br /> заполнения раздел информации о компании.
                  </Text>
                </Space>
              </>
            }
          >
            <Button type="primary" onClick={onAdd}>
              Добавить реквизиты
            </Button>
          </Empty>
        </div>
      )}

      <Skeleton loading={loading} />

      <S.ContentWrapper>
        {data.map((bankAccountData) => (
          <CompanyAccountPayRequisitesListItem
            key={bankAccountData.id}
            correspondentAccount={bankAccountData.correspondentAccount ?? ''}
            bankName={bankAccountData.bankName ?? ''}
            paymentAccount={bankAccountData.paymentAccount ?? ''}
            bic={bankAccountData.bic ?? ''}
            id={bankAccountData.id ?? -1}
            canRemove={bankAccountData.actions?.delete ?? false}
            onRemove={onRemove}
          />
        ))}
      </S.ContentWrapper>
    </S.Wrapper>
  )
}
