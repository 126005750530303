import React from 'react'

import { Box } from '~/ui-components'

import { PersonalProfileSection } from './components'

import * as S from './EmployeeProfileTab.styled'

interface IEmployeeProfileTabProps {
  userIds?: number[]
}

export const EmployeeProfileTab: React.FC<IEmployeeProfileTabProps> = ({ userIds }) => {
  return (
    <S.ContentWrapper>
      {userIds?.map((userId) => (
        <Box minHeight="268px" key={userId}>
          <PersonalProfileSection userId={userId} hideAddUser />
        </Box>
      )) ?? (
        <Box minHeight="268px">
          <PersonalProfileSection />
        </Box>
      )}
    </S.ContentWrapper>
  )
}
