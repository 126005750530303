import React from 'react'

import * as S from './NotificationMessageCard.styled'

interface INotificationMessageCardProps {
  title: string
  message: string | JSX.Element
}

export const NotificationMessageCard: React.FC<INotificationMessageCardProps> = ({ title, message }) => {
  return (
    <S.Wrapper>
      <S.Title>{title}</S.Title>
      <S.Message>{message}</S.Message>
    </S.Wrapper>
  )
}
