import React from 'react'

import { Button, Input } from 'antd'
import { Controller, useForm } from 'react-hook-form'

import { yupResolver } from '@hookform/resolvers/yup'

import { FormField } from '~/ui-components'
import { PhoneNumberControl, UserRoleControl } from '~shared/controls'

import { addEmployeeToCompanyFormFormSchema } from './AddEmployeeToCompanyForm.validation'

import { AddEmployeeToCompanyFormFormValues, AddEmployeeToCompanyFormProps } from './AddEmployeeToCompanyForm.types'

import * as S from './AddEmployeeToCompanyForm.styled'

export const AddEmployeeToCompanyForm: React.FC<AddEmployeeToCompanyFormProps> = ({
  onBack,
  loading,
  onSubmit,
  defaultValues,
}) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<AddEmployeeToCompanyFormFormValues>({
    defaultValues: {
      name: defaultValues?.name,
      phoneNumber: defaultValues?.phoneNumber,
      position: defaultValues?.position,
      email: defaultValues?.email,
    },
    resolver: yupResolver(addEmployeeToCompanyFormFormSchema),
  })

  const onSubmitHandler = (data: AddEmployeeToCompanyFormFormValues) => {
    onSubmit(data)
  }

  return (
    <S.Wrapper>
      <S.FormRow>
        <FormField label="ФИО" isRequired htmlFor="name" validateMessage={errors.name?.message}>
          <Controller
            name="name"
            control={control}
            render={({ field }) => (
              <Input {...field} id="name" status={errors.name && 'error'} placeholder="Введите ФИО" />
            )}
          />
        </FormField>
      </S.FormRow>

      <S.FormRow>
        <FormField
          htmlFor="phoneNumber"
          validateMessage={errors.phoneNumber?.message}
          label="Номер телефона"
          isRequired
        >
          <Controller
            name="phoneNumber"
            control={control}
            render={({ field }) => (
              <PhoneNumberControl
                {...field}
                autoFocus
                id="phoneNumber"
                hasError={!!errors.phoneNumber}
                disabled={!!defaultValues?.phoneNumber}
              />
            )}
          />
        </FormField>
      </S.FormRow>

      <S.FormRow>
        <FormField htmlFor="email" label="Email" validateMessage={errors.email?.message}>
          <Controller
            name="email"
            control={control}
            render={({ field }) => (
              <Input
                id="email"
                placeholder="zerno@mail.ru"
                status={errors.email && 'error'}
                {...field}
                value={field.value ?? ''}
              />
            )}
          />
        </FormField>
      </S.FormRow>

      <S.FormRow>
        <FormField htmlFor="position" label="Должность" validateMessage={errors.position?.message} isRequired>
          <Controller name="position" control={control} render={({ field }) => <UserRoleControl {...field} />} />
        </FormField>
      </S.FormRow>

      <S.ActionWrapper>
        <Button htmlType="button" onClick={onBack}>
          Отмена
        </Button>

        <Button htmlType="button" type="primary" onClick={handleSubmit(onSubmitHandler)} loading={loading}>
          Готово
        </Button>
      </S.ActionWrapper>
    </S.Wrapper>
  )
}
