import React, { useEffect, useState } from 'react'

import { Modal } from 'antd'

import { EmptyButton } from '~/ui-components'
import { useGetCompanyAccountType, useGetCompanyById, useUpdateCompanyAccountType } from '~hooks/company'
import { useAuth } from '~providers/auth'

import { appToast, modalSharedSettings } from '../../../../../../../utils'
import { AccountTypeForm } from '../AccountTypeForm'
import { AccountTypeView } from '../AccountTypeView'

import { IAccountTypeForm } from '../AccountTypeForm/AccountTypeForm.types'

import * as S from './AccountTypeSection.styled'

interface IAccountTypeSectionProps {
  companyId: number
  isAdmin: boolean
}

export const AccountTypeSection: React.FC<IAccountTypeSectionProps> = ({ companyId, isAdmin }) => {
  const auth = useAuth()
  const [isModalVisible, setIsModalVisible] = useState(false)
  const getCompanyAccountType = useGetCompanyAccountType(companyId)
  const companyInfo = useGetCompanyById(companyId)
  const updateCompanyAccountType = useUpdateCompanyAccountType()

  const hasCompanyAccount = getCompanyAccountType.data?.companyRoleType

  // show modal instantly after filling company info
  useEffect(() => {
    if (!getCompanyAccountType.isLoading && companyId && !hasCompanyAccount) {
      setIsModalVisible(true)
    }
  }, [hasCompanyAccount, companyId])

  const handleModalClose = () => setIsModalVisible(false)
  const handleModalOpen = () => {
    if (!companyId) {
      appToast.info({ description: 'Сначала заполните данные о компании' })
    } else {
      setIsModalVisible(true)
    }
  }
  const handleSubmitChanges = async (formValues: IAccountTypeForm) => {
    try {
      await updateCompanyAccountType.mutateAsync({
        companyId,
        updateCompanyTypeBody: {
          type: formValues.companyRoleType,
        },
      })
      // await auth.refetchTokens()
      await getCompanyAccountType.refetch()
      appToast.success({ description: 'Данные о компании обновлены' })
    } catch (e) {
      const description = 'Ошибка при обновлении типа аккаунта компании'
      console.error(description, e)
      appToast.error({ description })
    }
    handleModalClose()
  }

  if (getCompanyAccountType.isLoading || companyInfo.isLoading) {
    return <EmptyButton>Загрузка...</EmptyButton>
  }

  if (getCompanyAccountType.isError || companyInfo.error) {
    return <EmptyButton>Ошибка</EmptyButton>
  }

  return (
    <>
      {!getCompanyAccountType.data?.companyRoleType ? (
        <EmptyButton onClick={handleModalOpen}>Выбрать тип аккаунта</EmptyButton>
      ) : (
        <AccountTypeView
          onChange={handleModalOpen}
          accountType={getCompanyAccountType.data as any}
          companyStatus={companyInfo?.data?.status}
        />
      )}

      <Modal {...modalSharedSettings} open={isModalVisible} onCancel={handleModalClose} title="Тип Аккаунта">
        <S.Description>Выбирается один раз, будьте внимательны при заполнении</S.Description>
        <AccountTypeForm
          onSubmit={handleSubmitChanges}
          isLoading={updateCompanyAccountType.isLoading}
          defaultValues={getCompanyAccountType.data as any}
          isAdmin={isAdmin}
        />
      </Modal>
    </>
  )
}
