import React from 'react'
import { Button, Flex, Space } from 'antd'
import { Text } from '~/ui-components'
import * as S from './TransportationDataFill.styled'
import { appRoutes } from '~/router'
import { useNavigate } from 'react-router-dom'

export const TransportationDataFill: React.FC = () => {
  const navigate = useNavigate()

  return (
    <S.Wrapper>
      <Flex vertical justify="flex-start" gap={8}>
        <S.EmptyWrapper
          description={
            <Space>
              <Text variant="h4">Заполните транспортные данные</Text>
            </Space>
          }
          imageStyle={{ width: '48px', height: '40px' }}
        />
        <Text variant="normal">Чтобы видеть заявки в вашем регионе и удаленность от вашего автопарка</Text>
      </Flex>
      <Flex gap={12}>
        <Button type="primary" onClick={() => navigate(appRoutes.carParkCreateTransportationData.url)}>
          Заполнить транспортные данные
        </Button>
      </Flex>
    </S.Wrapper>
  )
}
