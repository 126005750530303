import React, { FC } from 'react'

import { AuctionPromoContent } from '~pages/Auctions/components/AuctionPromo/AuctionPromoContent'

import * as S from './AuctionPromo.styled'

interface IAuctionPromoProps {
  navigateToAuctions: () => void
}

export const AuctionPromo: FC<IAuctionPromoProps> = ({ navigateToAuctions }) => {
  return (
    <S.Wrapper>
      <S.Content>
        <AuctionPromoContent />

        <S.RedirectButton type="primary" size="large" onClick={() => navigateToAuctions()}>
          Все понятно, перейти к торгам!
        </S.RedirectButton>
      </S.Content>
    </S.Wrapper>
  )
}
