import * as yup from 'yup'

import { addressSchema, VALIDATORS_MSG } from '~shared/validations'

export const createOfferModalSchema = yup.object({
  address: addressSchema,
  volume: yup
    .number()
    .typeError(VALIDATORS_MSG.required)
    .min(20, VALIDATORS_MSG.minValue(20))
    .required(VALIDATORS_MSG.required),
  price: yup
    .number()
    .typeError(VALIDATORS_MSG.required)
    .min(1000, VALIDATORS_MSG.minValue(1000))
    .required(VALIDATORS_MSG.required),
  includingNds: yup.boolean().required(VALIDATORS_MSG.required),
  culture: yup
    .object({
      // hasError: yup.bool().oneOf([false], 'Параметры культуры заполнены с ошибками'),
    })
    .required(VALIDATORS_MSG.required),
})
