import { useMutation } from '@tanstack/react-query'

import { CreateDealBody, CreateDealResponse } from '~api/generated'
import { useApi } from '~providers/api'

export const useCreateDeal = (companyId: number | null) => {
  const api = useApi()

  const useCreateDealFn = async (createDealBody: CreateDealBody): Promise<CreateDealResponse | null> => {
    if (!companyId || !createDealBody) {
      return null
    }

    const res = await api.deal.dealControllerCreateDeal({
      companyId,
      createDealBody,
    })

    return res.data
  }

  return useMutation(useCreateDealFn)
}
