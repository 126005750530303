import { Flex } from 'antd'
import styled from 'styled-components'

import { Text } from '~/ui-components'

export const FlexItem = styled(Flex)`
  border: 1px solid var(--gray-2-color);
  border-radius: 6px;
  padding: 10px 16px;
  cursor: pointer;
  box-shadow: 0 4px 20px 0 #aeb1b233;
`

export const IconWrapper = styled(Flex)`
  background: var(--gray-2-color);
  border-radius: 6px;
  padding: 13px 0;
`

export const TextDark = styled(Text)`
  background: var(--gray-2-color);
  border-radius: 6px;
  padding: 8px;
`

export const TextLight = styled(Text)`
  border: 1px solid var(--gray-2-color);
  border-radius: 6px;
  padding: 8px;
`
