import { Button } from 'antd'
import styled from 'styled-components'

import { css } from '@styled-system/css'

import { Text } from '~/ui-components'

export const Description = styled(Text).attrs({ variant: 'normal' })(
  css({
    color: 'Placeholder',
    textAlign: 'center',
  })
)

export const SendButton = styled(Button)(
  css({
    padding: '0 !important',
    height: 'auto',
  })
)
