import React from 'react'

import { FiTruck, FiUser } from 'react-icons/fi'
import { RiAuctionLine } from 'react-icons/ri'

import { appRoutes } from '~/router'
import { ReactComponent as BasisIcon } from '~assets/icons/basis.svg'
import { ReactComponent as WheatCoinIcon } from '~assets/icons/wheat-coin.svg'

import { AppMenuInfo } from '~layout/AppMenu/AppMenu.types'

const SIZE = 24

export const mobileMenu: AppMenuInfo = {
  offers: {
    url: appRoutes.offersList.url,
    title: appRoutes.offersList.title,
    icon: <WheatCoinIcon style={{ width: SIZE, height: SIZE }} />,
  },
  logistics: {
    url: appRoutes.logisticFindCargo.url,
    title: appRoutes.logistics.title,
    icon: <FiTruck size={SIZE} />,
  },
  auctions: {
    url: appRoutes.auctions.url,
    title: appRoutes.auctions.title,
    icon: <RiAuctionLine size={SIZE} />,
  },
  basis: {
    url: appRoutes.basis.url,
    title: appRoutes.basis.title,
    icon: <BasisIcon />,
  },
  profile: {
    url: appRoutes.account.url,
    title: 'Профиль',
    icon: <FiUser size={SIZE} />,
  },
}
