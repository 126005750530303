import React from 'react'

import { Select } from 'antd'
import { BaseSelectRef } from 'rc-select'

import { CustomControlInterface } from '~shared/controls/CustomControl.interface'

import { FreightDealStatus } from '~api/gql-generated/graphql'
import { getFreightResponseStatusName } from '~/utils/logistics/get-freight-response-status-name'

interface FreightDealResponsesStatusesSelectControlProps extends CustomControlInterface {
  id?: string
  value: FreightDealStatus[] | undefined
  onChange: (value: FreightDealStatus[]) => void
  hasError?: boolean
}

const options = [FreightDealStatus.OnModeration, FreightDealStatus.Agreement, FreightDealStatus.RejectedAfterModeration]

export const FreightDealResponsesStatusesSelectControl: React.FC<FreightDealResponsesStatusesSelectControlProps> =
  React.forwardRef<BaseSelectRef, FreightDealResponsesStatusesSelectControlProps>(
    ({ id, value, onChange, hasError }, ref) => {
      return (
        <Select
          ref={ref}
          allowClear
          style={{ width: '100%' }}
          placeholder="Все статусы"
          value={value}
          onChange={onChange}
          status={!!hasError ? 'error' : undefined}
          mode="multiple"
          maxTagCount={1}
        >
          {options.map((freightDealStatus) => (
            <Select.Option key={freightDealStatus} value={freightDealStatus}>
              {getFreightResponseStatusName(freightDealStatus)}
            </Select.Option>
          ))}
        </Select>
      )
    }
  )

FreightDealResponsesStatusesSelectControl.displayName = 'FreightDealResponsesStatusesSelectControl'
