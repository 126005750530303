import * as yup from 'yup'

import { TransportMode } from '~api/generated'
import { VALIDATORS_MSG } from '~shared/validations'

export const createWarehouseFormStep1Schema = yup.object({
  name: yup.string().trim(),
  address: yup.string().required(VALIDATORS_MSG.required),
  transport: yup
    .array()
    .of(yup.mixed().oneOf(Object.values(TransportMode)).required())
    .min(1, VALIDATORS_MSG.required)
    .required(VALIDATORS_MSG.required),
  openingTime: yup.date().nullable(),
  closingTime: yup.date().nullable(),
  workingDaysOfWeek: yup.array().of(yup.string()).min(1, VALIDATORS_MSG.required),
  hasLab: yup.boolean(),
  hasDigitalQueue: yup.boolean(),
})
