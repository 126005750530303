import { codeUnreachable } from '~/utils/code-unreachable'
import { FreightOrderChangeRequestStatus } from '~api/gql-generated/graphql'

export function getFreightDealNotificationText(
  status: FreightOrderChangeRequestStatus,
  isAll: boolean,
  isCreator: boolean,
  isFinalizing: boolean
) {
  switch (status) {
    case FreightOrderChangeRequestStatus.OnModeration:
      return {
        name: isCreator ? 'Ожидание решения контрагента.' : 'Контрагент предложил новые условия.',
        value: 'Чтобы посмотреть изменение, нажмите на выделенное.',
      }
    case FreightOrderChangeRequestStatus.CounterOffer:
      return {
        name: 'Контрагент предложил новые условия.',
        value: 'Чтобы посмотреть изменение, нажмите на выделенное.',
      }
    case FreightOrderChangeRequestStatus.Accepted:
      return {
        name: isAll
          ? 'Контрагент принял предложенные вами условия.'
          : 'Контрагент принял некоторые из предложенных вами условий.',
        value: 'Принятые условия отображаются в заказе, чтобы посмотреть исходное условие, нажмите на выделенное.',
      }
    case FreightOrderChangeRequestStatus.Rejected:
      return {
        name:
          isCreator === isFinalizing
            ? isAll
              ? 'Контрагент отклонил свои условия.'
              : 'Контрагент отклонил некоторые из своих условий.'
            : isAll
            ? 'Контрагент отклонил предложенные вами условия.'
            : 'Контрагент отклонил некоторые из предложенных вами условий.',
        value: 'Чтобы посмотреть изменение, нажмите на выделенное.',
      }
    default:
      return codeUnreachable(status)
  }
}
