import styled from 'styled-components'

import { Text } from '~/ui-components'

export const Address = styled(Text).attrs({ variant: 'normal' })`
  flex-shrink: 0;
  color: var(--gray-6-color);
  margin-top: 8px;
`

export const AdvertCounter = styled(Text).attrs({ variant: 'normal' })``
export const Content = styled.div`
  display: grid;
  grid-template-columns: 1fr max-content;
  grid-gap: 12px;
  align-items: end;
`
