import styled from 'styled-components'
import { Flex } from 'antd'

export const InitialHistoryWrapper = styled(Flex)`
  border-left: 2px solid var(--primary-main-color);
  padding: 4px 16px;
  //background-color: #ecf6f2;
`

export const FlexWrapper = styled(Flex)`
  padding-bottom: 24px;
  border-bottom: 1px solid var(--gray-2-color);
`
