import React from 'react'

import { Button, Input, InputNumber } from 'antd'
import { Controller, useForm } from 'react-hook-form'

import { yupResolver } from '@hookform/resolvers/yup/dist/yup'

import { FormField, FormFooter } from '~/ui-components'
import { inputSharedSettings } from '~/utils/inputSharedSettings'
import { CompanyType } from '~api/gql-generated/graphql'
import {
  ElevatorLoadTypeControl,
  ElevatorUnloadTypeControl,
  RoadSurfaceQualitySelectControl,
  RoadSurfaceTypeSelectControl,
  YesOrNotControl,
} from '~shared/controls'

import { createWarehouseFormStep3CarSchema } from './CreateWarehouseFormStep3CarSchema'

import {
  CreateWarehouseFormStep3CarProps,
  CreateWarehouseFormStep3CarValues,
} from './CreateWarehouseFormStep3Car.types'

export const CreateWarehouseFormStep3Car: React.FC<CreateWarehouseFormStep3CarProps> = ({
  companyRole,
  onNext,
  onCancel,
  onPrev,
  defaultValues,
  nextButtonText = 'Далее',
}) => {
  const isSeller = companyRole === CompanyType.Seller
  const isBuyer = companyRole === CompanyType.Buyer
  const isTrader = companyRole === CompanyType.Trader

  const {
    handleSubmit,
    control,
    formState: { errors },
    watch,
  } = useForm<CreateWarehouseFormStep3CarValues>({
    context: { companyRole },
    defaultValues: {
      autoReceptionSpeed: defaultValues?.autoReceptionSpeed,
      autoUnloadSpeed: defaultValues?.autoUnloadSpeed,
      scalesWeightLimit: defaultValues?.scalesWeightLimit,
      scalesLength: defaultValues?.scalesLength,
      autoLength: defaultValues?.autoLength,
      autoHeight: defaultValues?.autoHeight,
      autoClearance: defaultValues?.autoClearance,
      loadingTransport: defaultValues?.loadingTransport,
      unloadingTransport: defaultValues?.unloadingTransport,
      hasAutoStorage: defaultValues?.hasAutoStorage,
      autoAdditionalInfo: defaultValues?.autoAdditionalInfo,
      hasWeighingAutomation: defaultValues?.hasWeighingAutomation,
      distanceToScalesM: defaultValues?.distanceToScalesM,
      hasScales: defaultValues?.hasScales,
      roadSurfaceType: defaultValues?.roadSurfaceType,
      roadSurfaceQuality: defaultValues?.roadSurfaceQuality,
    },
    resolver: yupResolver(createWarehouseFormStep3CarSchema),
  })

  const hasScales = watch('hasScales')

  const onSubmitHandler = (formValues: CreateWarehouseFormStep3CarValues) => {
    onNext(formValues)
  }

  return (
    <form onSubmit={handleSubmit(onSubmitHandler)}>
      {(isBuyer || isTrader) && (
        <>
          <FormField
            htmlFor="autoUnloadSpeed"
            label="Скорость погрузки"
            validateMessage={errors.autoUnloadSpeed?.message}
          >
            <Controller
              name="autoUnloadSpeed"
              control={control}
              render={({ field }) => (
                <InputNumber
                  {...field}
                  {...inputSharedSettings}
                  addonAfter="авто/день"
                  style={{ width: '100%' }}
                  id="autoUnloadSpeed"
                  placeholder="Введите значение"
                  status={errors.autoUnloadSpeed && 'error'}
                  step={1}
                />
              )}
            />
          </FormField>
          <FormField
            label="Способы погрузки"
            htmlFor="unloadingTransport"
            validateMessage={errors.unloadingTransport?.message}
          >
            <Controller
              name="unloadingTransport"
              control={control}
              render={({ field }) => <ElevatorUnloadTypeControl {...field} hasError={!!errors.unloadingTransport} />}
            />
          </FormField>
        </>
      )}

      {(isSeller || isTrader) && (
        <>
          <FormField
            htmlFor="autoReceptionSpeed"
            label="Скорость приемки"
            validateMessage={errors.autoReceptionSpeed?.message}
          >
            <Controller
              name="autoReceptionSpeed"
              control={control}
              render={({ field }) => (
                <InputNumber
                  {...field}
                  {...inputSharedSettings}
                  addonAfter="Авто/сутки"
                  style={{ width: '100%' }}
                  id="autoReceptionSpeed"
                  placeholder="Введите значение"
                  status={errors.autoReceptionSpeed && 'error'}
                  step={1}
                />
              )}
            />
          </FormField>

          <FormField
            label="Способы приемки"
            htmlFor="loadingTransport"
            validateMessage={errors.loadingTransport?.message}
          >
            <Controller
              name="loadingTransport"
              control={control}
              render={({ field }) => <ElevatorLoadTypeControl {...field} hasError={!!errors.loadingTransport} />}
            />
          </FormField>
        </>
      )}

      <FormField htmlFor="autoLength" label="Ограничения для Т/С по длине" validateMessage={errors.autoLength?.message}>
        <Controller
          name="autoLength"
          control={control}
          render={({ field }) => (
            <InputNumber
              {...field}
              {...inputSharedSettings}
              addonAfter="м"
              style={{ width: '100%' }}
              id="autoLength"
              placeholder="Введите значение"
              status={errors.autoLength && 'error'}
              step={1}
            />
          )}
        />
      </FormField>

      <FormField
        htmlFor="autoHeight"
        label="Ограничения для Т/С по высоте"
        validateMessage={errors.autoHeight?.message}
      >
        <Controller
          name="autoHeight"
          control={control}
          render={({ field }) => (
            <InputNumber
              {...field}
              {...inputSharedSettings}
              addonAfter="м"
              style={{ width: '100%' }}
              id="autoHeight"
              placeholder="Введите значение"
              status={errors.autoHeight && 'error'}
              step={0.1}
            />
          )}
        />
      </FormField>

      <FormField
        htmlFor="autoClearance"
        label="Ограничения для Т/С по высоте клиренса"
        validateMessage={errors.autoClearance?.message}
      >
        <Controller
          name="autoClearance"
          control={control}
          render={({ field }) => (
            <InputNumber
              {...field}
              {...inputSharedSettings}
              addonAfter="см"
              style={{ width: '100%' }}
              id="autoClearance"
              placeholder="Введите значение"
              status={errors.autoClearance && 'error'}
              step={1}
            />
          )}
        />
      </FormField>

      {(isSeller || isTrader) && (
        <>
          <FormField label="Весы" validateMessage={errors.hasScales?.message}>
            <Controller name="hasScales" control={control} render={({ field }) => <YesOrNotControl {...field} />} />
          </FormField>

          <FormField
            htmlFor="scalesWeightLimit"
            label="Тоннаж весов"
            validateMessage={errors.scalesWeightLimit?.message}
            isVisible={hasScales}
          >
            <Controller
              name="scalesWeightLimit"
              control={control}
              render={({ field }) => (
                <InputNumber
                  {...field}
                  {...inputSharedSettings}
                  addonAfter="т"
                  style={{ width: '100%' }}
                  id="scalesWeightLimit"
                  placeholder="Введите значение"
                  status={errors.scalesWeightLimit && 'error'}
                  step={1}
                />
              )}
            />
          </FormField>

          <FormField
            htmlFor="scalesLength"
            label="Длина весов"
            validateMessage={errors.scalesLength?.message}
            isVisible={hasScales}
          >
            <Controller
              name="scalesLength"
              control={control}
              render={({ field }) => (
                <InputNumber
                  {...field}
                  style={{ width: '100%' }}
                  addonAfter="м"
                  id="scalesLength"
                  placeholder="10,55"
                  status={errors.scalesLength && 'error'}
                  {...inputSharedSettings}
                />
              )}
            />
          </FormField>

          <FormField
            label="Автоматизация взвешивания (ПО)"
            validateMessage={errors.hasWeighingAutomation?.message}
            isVisible={hasScales}
          >
            <Controller
              name="hasWeighingAutomation"
              control={control}
              render={({ field }) => <YesOrNotControl {...field} />}
            />
          </FormField>
        </>
      )}

      <FormField
        htmlFor="distanceToScalesM"
        label="Расстояние от ворот до весов"
        validateMessage={errors.distanceToScalesM?.message}
        isVisible={hasScales}
      >
        <Controller
          name="distanceToScalesM"
          control={control}
          render={({ field }) => (
            <InputNumber
              {...field}
              style={{ width: '100%' }}
              addonAfter="м"
              id="distanceToScalesM"
              placeholder="10"
              status={errors.distanceToScalesM && 'error'}
              {...inputSharedSettings}
            />
          )}
        />
      </FormField>

      <FormField label="Тип покрытия" htmlFor="roadSurfaceType" validateMessage={errors.roadSurfaceType?.message}>
        <Controller
          name="roadSurfaceType"
          control={control}
          render={({ field }) => <RoadSurfaceTypeSelectControl {...field} hasError={!!errors.roadSurfaceType} />}
        />
      </FormField>

      <FormField
        label="Качество покрытия"
        htmlFor="roadSurfaceQuality"
        validateMessage={errors.roadSurfaceQuality?.message}
      >
        <Controller
          name="roadSurfaceQuality"
          control={control}
          render={({ field }) => <RoadSurfaceQualitySelectControl {...field} hasError={!!errors.roadSurfaceQuality} />}
        />
      </FormField>

      <FormField label="Площадка накопитель для Т/С" validateMessage={errors.hasAutoStorage?.message}>
        <Controller name="hasAutoStorage" control={control} render={({ field }) => <YesOrNotControl {...field} />} />
      </FormField>

      <FormField
        label="Дополнительные сведения по автотранспорту"
        htmlFor="autoAdditionalInfo"
        validateMessage={errors.autoAdditionalInfo?.message}
      >
        <Controller
          name="autoAdditionalInfo"
          control={control}
          render={({ field }) => (
            <Input.TextArea
              {...field}
              id="autoAdditionalInfo"
              placeholder="Дополнительные сведения"
              status={errors.autoAdditionalInfo && 'error'}
            />
          )}
        />
      </FormField>

      <FormFooter>
        <Button onClick={onCancel} htmlType="button">
          Отмена
        </Button>
        <Button onClick={onPrev} htmlType="button">
          Назад
        </Button>
        <Button type="primary" htmlType="submit">
          {nextButtonText}
        </Button>
      </FormFooter>
    </form>
  )
}
