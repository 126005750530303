/* tslint:disable */
/* eslint-disable */
/**
 * API Зерно-Агрегатора
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { BaseCompanyData } from './base-company-data';
import { BaseOfferData } from './base-offer-data';
import { BaseUserData } from './base-user-data';
import { DealWithCompanyData } from './deal-with-company-data';

/**
 * 
 * @export
 * @interface GetComplexDealResponse
 */
export interface GetComplexDealResponse {
    /**
     * Id комплексной сделки. Может меняться и всегда равно id сделки с владельцем предложения
     * @type {number}
     * @memberof GetComplexDealResponse
     */
    'id': number;
    /**
     * Время создания сделки
     * @type {string}
     * @memberof GetComplexDealResponse
     */
    'createdAt': string;
    /**
     * Предложение в рамках которого происходит сделка
     * @type {BaseOfferData}
     * @memberof GetComplexDealResponse
     */
    'offer': BaseOfferData;
    /**
     * Информация о сделке с продавцом
     * @type {DealWithCompanyData}
     * @memberof GetComplexDealResponse
     */
    'dealWithSeller': DealWithCompanyData | null;
    /**
     * Информация о сделке с покупателем
     * @type {DealWithCompanyData}
     * @memberof GetComplexDealResponse
     */
    'dealWithBuyer': DealWithCompanyData | null;
    /**
     * Информация о компании продавца
     * @type {BaseCompanyData}
     * @memberof GetComplexDealResponse
     */
    'seller': BaseCompanyData;
    /**
     * Информация о компании покупателя
     * @type {BaseCompanyData}
     * @memberof GetComplexDealResponse
     */
    'buyer': BaseCompanyData;
    /**
     * Следующие возможные статусы
     * @type {Array<string>}
     * @memberof GetComplexDealResponse
     */
    'nextStatuses': Array<GetComplexDealResponseNextStatusesEnum>;
    /**
     * Информация о модераторе
     * @type {BaseUserData}
     * @memberof GetComplexDealResponse
     */
    'moderator': BaseUserData | null;
    /**
     * Контактные данные продавца
     * @type {BaseUserData}
     * @memberof GetComplexDealResponse
     */
    'sellerUser': BaseUserData | null;
    /**
     * Контактные данные покупателя
     * @type {BaseUserData}
     * @memberof GetComplexDealResponse
     */
    'buyerUser': BaseUserData | null;
    /**
     * Адрес погрузки
     * @type {string}
     * @memberof GetComplexDealResponse
     */
    'loadingAddress': string | null;
    /**
     * Адрес разгрузки
     * @type {string}
     * @memberof GetComplexDealResponse
     */
    'unloadingAddress': string | null;
}

export const GetComplexDealResponseNextStatusesEnum = {
    OnModeration: 'ON_MODERATION',
    RejectedAfterModeration: 'REJECTED_AFTER_MODERATION',
    Agreement: 'AGREEMENT',
    Signing: 'SIGNING',
    Payment: 'PAYMENT',
    Shipment: 'SHIPMENT',
    Rejected: 'REJECTED',
    Realised: 'REALISED'
} as const;

export type GetComplexDealResponseNextStatusesEnum = typeof GetComplexDealResponseNextStatusesEnum[keyof typeof GetComplexDealResponseNextStatusesEnum];


