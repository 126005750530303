import { appRoutes } from '~/router'

const COUNTRIES_CODE_LIST: {
  title: string
  value: string
  format: string
  placeholder: string
  numberLength: number
}[] = [
  { title: '+7', value: '+7', format: '(###)###-##-##', placeholder: '(900)123-23-23', numberLength: 10 },
  { title: '+374', value: '+374', format: '-##-###-###', placeholder: '-12-345-678', numberLength: 8 },
  { title: '+375', value: '+375', format: '(##)###-##-##', placeholder: '(17)123-45-67', numberLength: 9 },
  { title: '+55', value: '+55', format: '(##)#####-####', placeholder: '(11)98765-4321', numberLength: 11 },
  { title: '+86', value: '+86', format: '(###)####-####', placeholder: '(123)4567-8901', numberLength: 11 },
  // { title: '+53', value: '+53', format: '-#-###-####', placeholder: '-1-234-5678', numberLength: 8 },
  { title: '+91', value: '+91', format: '(####)###-###', placeholder: '(1234)567-890', numberLength: 10 },
  // { title: '+996', value: '+996', format: '(###)###-###', placeholder: '(123)456-789', numberLength: 9 },
  // { title: '+373', value: '+373', format: '-####-####', placeholder: '-1234-5678', numberLength: 8 },
  { title: '+27', value: '+27', format: '-##-###-####', placeholder: '-12-345-6789', numberLength: 9 },
  // { title: '+992', value: '+992', format: '-##-###-####', placeholder: '-12-345-6789', numberLength: 9 },
  { title: '+998', value: '+998', format: '-##-###-####', placeholder: '-12-345-6789', numberLength: 9 },
]

export const AppConfig = {
  /**
   * backend api url
   */
  API_URL: process.env.REACT_APP_API_URL,
  BASE_PATH: '/',
  FIRST_PAGE_AFTER_LOGIN: appRoutes.welcomePage.url,
  MAX_DOCUMENT_FILE_SIZE_MB: 20,
  MAX_DOCUMENT_ARCHIVE_FILE_SIZE_MB: 200,
  ADMIN_CONTACT_EMAIL: 'info@zerno-agregator.ru',
  ADMIN_CONTACT_PHONE: '+74956466115',
  WHATSAPP_CONTACT_PHONE: 'https://wa.me/79629664422',
  TELEGRAM_CONTACT_PHONE: 'https://t.me/+79629664422',
  OFFER_EXPIRATION_ALERT_HOURS: 10,
  /**
   * get request for getting unread notification will be sent every N ms
   */
  REFETCH_UNREAD_NOTIFICATION_INTERVAL: 10000,
  REFETCH_DOCUMENTS_FOR_DEALS_INTERVAL: 30000,
  DEFAULT_DEBOUNCE_TIME: 500,
  FILTERS: {
    volume: {
      from: 10,
      to: 99000,
    },
    price: {
      from: 100,
      to: 999000,
    },
  },
  COUNTRIES_CODE_LIST,
}
