import React from 'react'

import { Empty } from 'antd'

import { MyUserEntity } from '~pages/EmployeesPage/components/EmployeesList/EmployeesList'

import { UserAddedListItem } from '../UserAddedListItem'

import * as S from './UserAddedList.styled'

interface UserListProps {
  users: MyUserEntity[]
  onUserEdit: (userId: number) => void
  onUserDelete: (userId: number) => void
  canDeleteUserFromCompany: boolean
  onUserActivate: (userId: number) => void
  onUserDeactivate: (userId: number) => void
}

export const UserAddedList: React.FC<UserListProps> = (props) => {
  if (!props.users.length) {
    return (
      <S.Wrapper>
        <Empty description="Сотрудники ещё не добавлены" />
      </S.Wrapper>
    )
  }

  const onUserEditHandler = (user: MyUserEntity) => user.id && props.onUserEdit(user.id)
  const onUserDeleteHandler = (user: MyUserEntity) => user.id && props.onUserDelete(user.id)
  const onUserActivateHandler = (user: MyUserEntity) => user.id && props.onUserActivate(user.id)
  const onUserDeactivateHandler = (user: MyUserEntity) => user.id && props.onUserDeactivate(user.id)

  return (
    <S.Wrapper>
      {props.users.map((user) => (
        <UserAddedListItem
          key={user.id}
          user={user}
          onEdit={() => onUserEditHandler(user)}
          onDelete={() => onUserDeleteHandler(user)}
          onActivate={() => onUserActivateHandler(user)}
          onDeactivate={() => onUserDeactivateHandler(user)}
          canDeleteUserFromCompany={props.canDeleteUserFromCompany}
        />
      ))}
    </S.Wrapper>
  )
}
