import React from 'react'

import { Button, Input, Spin } from 'antd'
import { Controller, useForm } from 'react-hook-form'

import { yupResolver } from '@hookform/resolvers/yup/dist/yup'

import { AvatarUploader, ResponsePageHeader } from '~/shared/components'
import { FormField } from '~/ui-components'
import { PhoneNumberControl } from '~shared/controls'

import { companyAccountInfoEditSchema } from './CompanyAccountInfoEdit.validation'

import { CompanyAccountInfoEditProps, CompanyAccountInfoEditValues } from './CompanyAccountInfoEdit.types'

import * as S from './CompanyAccountInfoEdit.styled'

export const CompanyAccountInfoEdit: React.FC<CompanyAccountInfoEditProps> = ({
  loading,
  onBack,
  onSubmit,
  defaultValues,
}) => {
  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm<CompanyAccountInfoEditValues>({
    defaultValues: {
      avatarFile: defaultValues.avatarFile,
      logoUrl: defaultValues.logoUrl,
      mobileNumber: defaultValues.mobileNumber ?? '',
      website: defaultValues.website ?? '',
      additionalInfo: defaultValues.additionalInfo ?? '',
    },
    resolver: yupResolver(companyAccountInfoEditSchema),
  })

  return (
    <S.Wrapper>
      <ResponsePageHeader
        variant="edit"
        title="Редактировать общую информацию"
        titleMobile="Редактировать"
        subTitle="МОЯ КОМПАНИЯ"
        loading={loading}
        onSubmit={handleSubmit(onSubmit)}
        onBack={onBack}
      />
      <S.StyledForm>
        <Spin spinning={loading}>
          <AvatarUploader
            avatarUrl={defaultValues.logoUrl}
            onUpload={(file) => setValue('avatarFile', file)}
            onDeleteAvatar={() => setValue('avatarFile', null)}
            uploadText="Загрузить лого"
          />

          <FormField label="Телефон компании" htmlFor="mobileNumber" validateMessage={errors.mobileNumber?.message}>
            <Controller
              name="mobileNumber"
              control={control}
              render={({ field }) => (
                <PhoneNumberControl {...field} autoFocus id="mobileNumber" hasError={!!errors.mobileNumber} />
              )}
            />
          </FormField>

          <FormField htmlFor="website" label="Сайт компании" validateMessage={errors.website?.message}>
            <Controller
              name="website"
              control={control}
              render={({ field }) => (
                <Input
                  id="website"
                  placeholder="https://"
                  status={errors.website && 'error'}
                  {...field}
                  value={field.value ?? ''}
                />
              )}
            />
          </FormField>

          <FormField
            htmlFor="additionalInfo"
            label="Дополнительная информация"
            validateMessage={errors.additionalInfo?.message}
          >
            <Controller
              name="additionalInfo"
              control={control}
              render={({ field }) => (
                <Input.TextArea
                  id="additionalInfo"
                  rows={3}
                  placeholder="Например, опыт и преимущества компании"
                  status={errors.additionalInfo && 'error'}
                  {...field}
                  value={field.value ?? ''}
                />
              )}
            />
          </FormField>

          <S.ActionWrapper>
            <Button type="default" htmlType="button" onClick={onBack}>
              Отмена
            </Button>
            <Button type="primary" htmlType="button" loading={loading} onClick={handleSubmit(onSubmit)}>
              Готово
            </Button>
          </S.ActionWrapper>
        </Spin>
      </S.StyledForm>
    </S.Wrapper>
  )
}
