import React, { useState } from 'react'

import { Flex, Select, Table } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import { useNavigate } from 'react-router-dom'

import { appRoutes } from '~/router'
import { Badge, Text } from '~/ui-components'
import { getElevatorAdvertOfferStatus } from '~/utils/elevator-advert-offers/get-elevator-advert-offer-status'
import { enumToArray } from '~/utils/enum-to-array'
import { getDateFormat } from '~/utils/getDateFormat'
import { ElevatorAdvertOfferData, ElevatorOfferResponseStatus, ProductParameterData } from '~api/generated'
import { useGetElevatorAdvertOffers } from '~hooks/elevator-advert-offers'
import { actionArrowColumn, idColumn, priceColumn, volumeColumn } from '~shared/columns/shared'
import { SearchInput } from '~shared/components/SearchInput'

const statusOptions = enumToArray(ElevatorOfferResponseStatus).map((option) => ({
  label: getElevatorAdvertOfferStatus(option as ElevatorOfferResponseStatus).name,
  value: option,
}))

interface ResponsesDesktopBuyerProps {
  advertId: number
  companyId: number
}
export function ResponsesDesktopBuyer({ advertId, companyId }: ResponsesDesktopBuyerProps) {
  const navigate = useNavigate()
  const [filterByStatus, setFilterByStatus] = useState<ElevatorOfferResponseStatus[]>([])
  const [filterById, setFilterById] = useState<string>('')

  const getElevatorAdvertOffers = useGetElevatorAdvertOffers({
    id: advertId,
    userCompanyId: companyId,
    pageSize: 100,
    statuses: filterByStatus,
    advertOfferId: +filterById ? Number(filterById) : undefined,
    orderBy: 'publishedAt',
  })
  const offers = getElevatorAdvertOffers.data?.elevatorAdvertOffers

  const uniqParams =
    offers
      ?.flatMap((x) => x.product.parameters)
      .filter((el, idx, list) => list.findIndex((e) => e.type === el.type) === idx) ?? []

  const col = uniqParams?.map((k) => ({
    title: k.name,
    dataIndex: ['product', 'parameters'],
    width: '160px !important',
    render: (z: ProductParameterData[]) => {
      const parameter = z.find((x) => x.name === k.name)
      if (!parameter) {
        return
      }

      const isYear = parameter.type === 'crop_year'
      const value = Number(parameter.value).toFixed(isYear ? 0 : 1)
      const measurementUnit =
        parameter.measurementUnit === '%'
          ? `${parameter.measurementUnit}`
          : parameter.measurementUnit === null
          ? ''
          : ` ${parameter.measurementUnit}`

      return `${value}${measurementUnit}`
    },
  }))

  const handleRowClick = (record: ElevatorAdvertOfferData) => {
    navigate(
      appRoutes.basisMyAdvertResponse.url.replace(':advertId', advertId.toString()).replace(':id', record.id.toString())
    )
  }

  const columns: ColumnsType<ElevatorAdvertOfferData> = [
    {
      ...idColumn,
    },
    {
      title: 'Статус',
      dataIndex: 'status',
      fixed: 'left',
      width: 200,
      render: (status: ElevatorAdvertOfferData['status']) => {
        return (
          <Badge color={getElevatorAdvertOfferStatus(status).color} size="s">
            {getElevatorAdvertOfferStatus(status).name}
          </Badge>
        )
      },
    },
    { ...priceColumn, fixed: 'left' },
    { ...volumeColumn, fixed: 'left' },
    {
      title: 'Дата отклика',
      dataIndex: 'publishedAt',
      width: 150,
      render: (publishedAt) => publishedAt && getDateFormat(publishedAt),
      fixed: 'left',
    },
    ...(col as any),
    { ...actionArrowColumn, fixed: 'right' },
  ]

  return (
    <Flex vertical gap={24}>
      <Text variant="h3">Отклики: {offers?.length}</Text>

      <Flex gap={8}>
        <Select
          id="status"
          style={{ width: 210 }}
          allowClear
          placeholder="Статус"
          mode="multiple"
          onChange={(v) => setFilterByStatus(v)}
          options={statusOptions}
        />

        <SearchInput
          style={{ width: 210 }}
          defaultValue=""
          onSearch={(value) => setFilterById(value)}
          placeholder="Поиск по №"
          type="number"
          pattern="\d*"
        />
      </Flex>

      <Table<ElevatorAdvertOfferData>
        rowKey="type"
        className="table-interactive"
        loading={getElevatorAdvertOffers.isLoading}
        columns={columns}
        dataSource={offers}
        scroll={{ x: 'max-content' }}
        pagination={false}
        onRow={(record) => ({
          onClick: handleRowClick.bind(null, record),
        })}
      />
    </Flex>
  )
}
