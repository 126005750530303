import React from 'react'

import { Select } from 'antd'
import { BaseSelectRef } from 'rc-select'

import { CustomControlInterface } from '~shared/controls/CustomControl.interface'

export type TransportationTypeControlValue = 'franco-auto' | 'franco-rail' | 'franco-warehouse'
type Value = TransportationTypeControlValue | undefined

interface TransportationTypeSelectControlProps extends CustomControlInterface {
  onChange: (value: Value) => void
  value: TransportationTypeControlValue | undefined
  hasError?: boolean
  id?: string
}

interface IOption {
  value: TransportationTypeControlValue
  label: string
}
const selectOptions: IOption[] = [
  {
    value: 'franco-auto',
    label: getTransportationTypeName('franco-auto'),
  },
  {
    value: 'franco-rail',
    label: getTransportationTypeName('franco-rail'),
  },
  {
    value: 'franco-warehouse',
    label: getTransportationTypeName('franco-warehouse'),
  },
]

export function getTransportationTypeName(type: TransportationTypeControlValue): string {
  switch (type) {
    case 'franco-auto':
      return 'Франко-автомобиль'
    case 'franco-rail':
      return 'Франко-вагон'
    case 'franco-warehouse':
      return 'Франко-склад'
  }
}
export const TransportationTypeSelectControl: React.FC<TransportationTypeSelectControlProps> = React.forwardRef<
  BaseSelectRef,
  TransportationTypeSelectControlProps
>(({ id, value, onChange, hasError }, ref) => {
  const onSelectAddressHandler = (v: TransportationTypeControlValue) => {
    onChange(v)
  }

  return (
    <Select
      ref={ref}
      id={id}
      style={{ width: '100%' }}
      placeholder="франко-вагон"
      value={value}
      onChange={onSelectAddressHandler}
      status={hasError ? 'error' : undefined}
      optionFilterProp="children"
    >
      {selectOptions.map((it) => (
        <Select.Option key={it.value} value={it.value}>
          {it.label}
        </Select.Option>
      ))}
    </Select>
  )
})

TransportationTypeSelectControl.displayName = 'TransportationTypeSelectControl'
