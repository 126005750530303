import styled from 'styled-components'

import { css } from '@styled-system/css'

export const Wrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
  maxWidth: '900px',
  margin: '0 auto',
})

export const TopActionsWrapper = styled.div(
  css({
    display: 'grid',
    gridTemplateColumns: ['1fr', '1fr', '1fr 400px'],
    alignItems: 'center',
    gap: '16px',
  })
)
