import React from 'react'

import { Button, Tooltip } from 'antd'
import { UploadRequestOption } from 'rc-upload/lib/interface'
import { FiDownload, FiTrash2, FiUpload } from 'react-icons/fi'

import { AppConfig } from '~/appConfig'
import { Badge } from '~/ui-components'
import { appToast } from '~/utils'

import * as S from './UploadAllDocumentsButton.styled'

interface IUploadAllDocumentsButton {
  currentArchiveLink?: string | null
  isLoading: boolean
  onUpload: (uploadedFile: File) => void
  onDownload: (link: string) => void
  onRemove: () => void
}

export const UploadAllDocumentsButton: React.FC<IUploadAllDocumentsButton> = ({
  currentArchiveLink,
  isLoading,
  onUpload,
  onDownload,
  onRemove,
}) => {
  const uploadHandler = (r: UploadRequestOption<File>) => {
    const file = r.file as File
    const isOverMaxLim = file.size / 1024 / 1024 > AppConfig.MAX_DOCUMENT_ARCHIVE_FILE_SIZE_MB
    if (isOverMaxLim) {
      appToast.error({ description: `Размер файла превышает ${AppConfig.MAX_DOCUMENT_ARCHIVE_FILE_SIZE_MB}мб` })
      return
    }
    onUpload(file)
  }

  const onDownloadHandler = () => (currentArchiveLink ? onDownload(currentArchiveLink) : undefined)

  if (currentArchiveLink) {
    return (
      <S.ActionWrapper>
        <Badge color="base">Архив загружен</Badge>

        <Tooltip title="скачать архив">
          <Button size="small" type="text" icon={<FiDownload size={22} />} onClick={onDownloadHandler} />
        </Tooltip>

        <Tooltip title="удалить архив" placement="leftTop">
          <Button size="small" type="text" icon={<FiTrash2 color="red" />} onClick={onRemove} />
        </Tooltip>
      </S.ActionWrapper>
    )
  }

  return (
    <S.UploadStyled accept="application/zip, .rar" showUploadList={false} customRequest={uploadHandler}>
      <Button block type="primary" icon={<FiUpload />} loading={isLoading}>
        Загрузить одним архивом
      </Button>
    </S.UploadStyled>
  )
}
