import { graphql } from '~api/gql-generated/gql'

export const getNotesQuery = graphql(/* GraphQL */ `
  query Query($filter: NotesFilterInput, $sorting: NoteSortingInput, $pagination: PaginationInput) {
    notes(filter: $filter, sorting: $sorting, pagination: $pagination) {
      totalCount
      notes {
        id
        text
        authorId
        model
        entityId
        createdAt
        updatedAt
        author {
          name
        }
      }
    }
  }
`)
