import React, { FC } from 'react'

import { useNavigate } from 'react-router-dom'

import { DealsPromoContent } from './DealsPromoContent'

import * as S from './DealsPromo.styled'

export const DealsPromo: FC = () => {
  const navigate = useNavigate()

  return (
    <S.Wrapper>
      <S.Content>
        <DealsPromoContent />

        <S.RedirectButton type="primary" size="large" onClick={() => navigate('/offers')}>
          Перейти к списку предложений
        </S.RedirectButton>
      </S.Content>
    </S.Wrapper>
  )
}
