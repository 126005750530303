import React, { PropsWithChildren } from 'react'

import { Select } from 'antd'
import { BaseSelectRef } from 'rc-select'

import { getElevatorLoadType } from '~/utils/elevators/get-elevator-load-type'
import { LoadingTransportType } from '~api/generated'
import { CustomControlInterface } from '~shared/controls/CustomControl.interface'

type Value = LoadingTransportType[] | undefined

interface IElevatorLoadTypeControlProps extends CustomControlInterface {
  onChange: (value: Value) => void
  value: Value
  hasError?: boolean
  disabled?: boolean
  id?: string
}

interface IOption {
  value: LoadingTransportType
  label: string
}

const loadTypesOptions: IOption[] = [
  {
    label: getElevatorLoadType(LoadingTransportType.GrainThrower),
    value: LoadingTransportType.GrainThrower,
  },
  {
    label: getElevatorLoadType(LoadingTransportType.Elevator),
    value: LoadingTransportType.Elevator,
  },
  {
    label: getElevatorLoadType(LoadingTransportType.Telehandler),
    value: LoadingTransportType.Telehandler,
  },
  {
    label: getElevatorLoadType(LoadingTransportType.FrontLoader),
    value: LoadingTransportType.FrontLoader,
  },
  {
    label: getElevatorLoadType(LoadingTransportType.VerticalPipe),
    value: LoadingTransportType.VerticalPipe,
  },
]

export const ElevatorLoadTypeControl: React.FC<PropsWithChildren<IElevatorLoadTypeControlProps>> = React.forwardRef<
  BaseSelectRef,
  PropsWithChildren<IElevatorLoadTypeControlProps>
>(({ id, value, onChange, hasError, disabled }, ref) => {
  const onSelectAddressHandler = (v: Value): void => {
    // if (!v?.length) {
    //   onChange(undefined)
    //   return
    // }
    onChange(v)
  }

  return (
    <Select
      ref={ref}
      id={id}
      mode="multiple"
      style={{ width: '100%' }}
      placeholder="Выберите способ погрузки"
      value={value}
      disabled={disabled}
      onChange={onSelectAddressHandler}
      status={hasError ? 'error' : undefined}
      optionFilterProp="children"
      allowClear
    >
      {loadTypesOptions.map((it) => (
        <Select.Option key={it.value} value={it.value}>
          {it.label}
        </Select.Option>
      ))}
    </Select>
  )
})

ElevatorLoadTypeControl.displayName = 'ElevatorLoadTypeControl'
