import styled from 'styled-components'

import css from '@styled-system/css'

export const ContentWrapper = styled.div(
  css({
    padding: ['8px', '8px', '32px'],
    display: 'grid',
    gap: '12px',
    gridTemplateColumns: [
      'repeat(1, minmax(300px, 100%))',
      'repeat(1, minmax(300px, 100%))',
      'repeat(1, minmax(380px, 100%))',
      'repeat(2, minmax(350px, 430px))',
    ],
    gridAutoRows: 'max-content',
    justifyContent: 'center',
  })
)

export const Section = styled.div(
  css({
    ':first-child': {
      gridColumn: '1 / -1',
    },

    '> *': {
      height: '100%',
    },
  })
)
