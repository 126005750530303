import React from 'react'

import { Skeleton } from 'antd'
import { useParams } from 'react-router'

import { EmptyButton, Text } from '~/ui-components'
import { isDocumentTypeUsesForDealSide } from '~/utils/deals/get-deal-document-payment-list'
import { DealSideType, getDealSide } from '~/utils/deals/get-deal-side'
import { DealDocumentDataTypeEnum } from '~api/generated'
import { useGetDealDocuments } from '~hooks/deal-documents'
import { useGetDealById } from '~hooks/deals'
import { DealPaymentSection } from '~pages/Deals/modules/DealPayment/components/DealPaymentSection'
import { useAuth } from '~providers/auth'

import * as S from './DealPayment.styled'

export const DealPayment = () => {
  const { companyId } = useAuth()

  const { id } = useParams<{ id: string | undefined }>()
  const dealId = Number(id)

  const dealFn = useGetDealById(dealId)
  const deal = dealFn.data
  const dealDocumentsFn = useGetDealDocuments({ dealId })

  if (dealDocumentsFn.isLoading || dealFn.isLoading || !companyId || !deal) {
    return <Skeleton />
  }

  const dealSide = getDealSide({ userCompanyId: companyId, buyerId: deal.buyer.id, sellerId: deal.seller.id })
  const dealDocuments = dealDocumentsFn.data
  const uploadedDocuments = dealDocuments?.filter((x) => x.instances.filter((x) => !!x.id).length) ?? []

  const hasInvoice = !!uploadedDocuments.find((doc) => doc.type === DealDocumentDataTypeEnum.InvoiceForSupplyPayment)
  const hasPaymentOrder = !!uploadedDocuments.find(
    (doc) => doc.type === DealDocumentDataTypeEnum.PaymentOrderUnderTheSupplyContract
  )

  const renderUploadedDocuments = (column: DealSideType) => {
    return uploadedDocuments
      .filter((x) => isDocumentTypeUsesForDealSide(x.type, column, false))
      .map((document) => (
        <S.Section key={document.type}>
          <DealPaymentSection
            dealSide={dealSide}
            dealId={dealId}
            document={document}
            documentType={document.type}
            canChange={document.canChange}
            afterUploadDocument={dealDocumentsFn.refetch}
            afterRemoveDocument={dealDocumentsFn.refetch}
          />
        </S.Section>
      ))
  }

  const renderUploadNewDocumentButton = (column: 'fromSeller' | 'fromBuyer') => {
    // кейс для покупателя
    if (dealSide === 'buyer') {
      // нет счета на оплату. Колонка "от Продавца"
      if (!hasInvoice && column === 'fromSeller') {
        return (
          <S.Section>
            <EmptyButton isReadOnly>Здесь будет счет на оплату</EmptyButton>
          </S.Section>
        )
      }

      // нет счета на оплату. Колонка "от Покупателя"
      if (!hasInvoice && column === 'fromBuyer') {
        return (
          <S.Section>
            <EmptyButton isReadOnly>
              Загрузить платежное поручение можно только после загрузки продавцом счета на оплату
            </EmptyButton>
          </S.Section>
        )
      }
    }

    // кейс для продавца
    if (dealSide === 'seller') {
      // нет счета на оплату. Колонка "от Продавца"
      if (!hasInvoice && column === 'fromSeller') {
        return (
          <S.Section>
            <DealPaymentSection
              dealSide={dealSide}
              dealId={dealId}
              afterUploadDocument={afterUploadDocumentHandler}
              uploadDocumentButtonText="Загрузите счет на оплату"
              canChange={false}
            />
          </S.Section>
        )
      }

      // нет платежного поручения. Колонка "от Покупателя"
      if (!hasPaymentOrder && column === 'fromBuyer') {
        return (
          <S.Section>
            <EmptyButton isReadOnly>Здесь будет лежать платежное поручение с отметкой банка от покупателя</EmptyButton>
          </S.Section>
        )
      }
    }

    if ((column === 'fromSeller' && dealSide !== 'seller') || (column === 'fromBuyer' && dealSide !== 'buyer')) {
      return null
    }
    return (
      <S.Section>
        <DealPaymentSection
          dealSide={dealSide}
          dealId={dealId}
          canChange={false}
          afterUploadDocument={afterUploadDocumentHandler}
        />
      </S.Section>
    )
  }

  const afterUploadDocumentHandler = () => dealDocumentsFn.refetch()

  return (
    <S.ContentWrapper>
      <S.Column>
        <Text variant="h3">От продавца</Text>
        {renderUploadedDocuments('seller')}
        {renderUploadNewDocumentButton('fromSeller')}
      </S.Column>

      <S.Column>
        <Text variant="h3">От покупателя</Text>
        {renderUploadedDocuments('buyer')}
        {renderUploadNewDocumentButton('fromBuyer')}
      </S.Column>
    </S.ContentWrapper>
  )
}
