import * as yup from 'yup'

import { VALIDATORS_MSG } from '~shared/validations'

export const createWarehouseFormStep2RailSchema = yup.object({
  stationName: yup.string().required(VALIDATORS_MSG.required),
  hasRailWay: yup.boolean(),
  hasRailScales: yup.boolean(),
  hasRailStock: yup.boolean(),
  // hasTecEco: yup.boolean(),
  railLoadSpeed: yup.number().typeError(VALIDATORS_MSG.required),
  waitingArea: yup.number().typeError(VALIDATORS_MSG.required),
  railAdditionalInfo: yup.string(),
})
