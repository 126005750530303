import styled from 'styled-components'
import { border, color, compose, flexbox, layout, opacity, position, shadow, space, textAlign } from 'styled-system'

import css from '@styled-system/css'
import shouldForwardProp from '@styled-system/should-forward-prop'

import { BoxProps } from './Box.types'

const styleProps = compose(border, color, flexbox, layout, position, space, shadow, opacity, textAlign)

const scroll = css({
  scrollbarWidth: 'thin',
  '::-webkit-scrollbar': {
    width: 6,
    height: 6,
  },
  '::-webkit-scrollbar-thumb': {
    bg: 'dune',
    borderRadius: 2,
    ':hover': {
      bg: 'shadyLady',
    },
  },
  '::-webkit-scrollbar-corner': {
    bg: 'transparent',
  },
})

export const Box = styled.div.withConfig({
  shouldForwardProp: (prop) => shouldForwardProp(prop),
})<BoxProps>({ boxSizing: 'border-box' }, styleProps, scroll)
