import { CompanyAuctionDataStatusEnum } from '~api/generated'

import { BadgeVariant } from '~ui-components/components/Badge/Badge.types'

export function getAuctionStatus(status: string): {
  name: string
  color: BadgeVariant
} {
  switch (status) {
    case CompanyAuctionDataStatusEnum.OnModeration:
      return {
        name: 'На проверке',
        color: 'yellow',
      }
    case CompanyAuctionDataStatusEnum.Realised:
      return {
        name: 'Лот продан',
        color: 'gray',
      }
    case CompanyAuctionDataStatusEnum.InProgress:
      return {
        name: 'На торгах',
        color: 'base',
      }
    case CompanyAuctionDataStatusEnum.Rejected:
      return {
        name: 'Лот отменен',
        color: 'red',
      }
    case CompanyAuctionDataStatusEnum.Expired:
      return {
        name: 'Торги закончились',
        color: 'gray',
      }
    default:
      return {
        name: 'Ошибка',
        color: 'red',
      }
  }
}
