import React from 'react'

import { Pagination, Table } from 'antd'
import { useLocation, useNavigate } from 'react-router-dom'

import { useGetProductTypeData, useGetTableHeight, usePagination } from '~/hooks'
import { getOfferType } from '~/utils/offers/get-offer-type'
import { AcceptedOfferData } from '~api/generated'
import { useGetDevice } from '~hooks/common'
import { useGetAcceptedOffers } from '~hooks/offer/useGetAcceptedOffers'
import { EmptyAcceptedOfferList } from '~pages/OffersPage/components/EmptyAcceptedOfferList'
import { OfferCardPreview } from '~pages/OffersPage/components/OfferCardPreview'
import { View } from '~pages/OffersPage/components/ViewToggle'
import { useGetView } from '~pages/OffersPage/hooks'
import { useGetColumnsAcceptedOffers } from '~pages/OffersPage/hooks/useGetColumnsAcceptedOffers'
import { useAuth } from '~providers/auth'

import { OffersCardPreviewWrapper } from '../../components/OffersCardPreviewWrapper'
import { OffersHeader } from '../../components/OffersHeader'

import * as S from '../../OffersPage.styled'

export const AcceptedOffers: React.FC = () => {
  const navigate = useNavigate()
  const { companyId } = useAuth()
  const location = useLocation()
  const { view, setView } = useGetView()
  const device = useGetDevice()
  const { currentPage, setCurrentPage, pageSize, setPageSize, pageSizeOptions } = usePagination()
  const tableHeight = useGetTableHeight()
  const { getProductType, getProductTypeParametersForUI_V2 } = useGetProductTypeData()

  const {
    data: offersData,
    refetch: refetchOfferList,
    isLoading: isLoadingOfferList,
  } = useGetAcceptedOffers({
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    companyId: companyId!,
    currentPage,
    pageSize: pageSize,
  })

  const offers = offersData?.acceptedOffers
  const pagination = !offersData?.pagination || !offersData?.pagination?.total ? null : offersData.pagination

  const onRowClickHandler = (record: AcceptedOfferData) => {
    navigate({
      pathname: `${record.id}`,
      search: location.search,
    })
  }

  const columns = useGetColumnsAcceptedOffers()

  const isMobile = device === 'mobile'

  const isOfferListEmpty = !offers?.length && !isLoadingOfferList

  if (isOfferListEmpty) {
    return (
      <S.ContentWrapper>
        <S.Content>
          <OffersHeader view={view} onChangeView={(value) => setView(value)} onAfterCreateOffer={refetchOfferList} />
          <EmptyAcceptedOfferList />
        </S.Content>
      </S.ContentWrapper>
    )
  }

  return (
    <S.ContentWrapper>
      <S.Content>
        <OffersHeader view={view} onChangeView={(value) => setView(value)} onAfterCreateOffer={refetchOfferList} />

        {view === View.CARDS || isMobile ? (
          <OffersCardPreviewWrapper isLoading={isLoadingOfferList}>
            {offers?.map((offer, idx) => {
              return (
                <OfferCardPreview
                  key={idx}
                  onClick={() => onRowClickHandler(offer)}
                  name={offer.product.name}
                  img={getProductType(offer.product.type)?.picture?.source}
                  parameters={getProductTypeParametersForUI_V2(offer.product)}
                  volume={offer.volume}
                  price={offer.price}
                  isNds={offer.includingNds}
                  address={offer.address}
                  distance={offer.distance}
                  statuses={[getOfferType(offer.type)]}
                  acceptedAt={offer.acceptedAt}
                />
              )
            })}
          </OffersCardPreviewWrapper>
        ) : (
          <Table<AcceptedOfferData>
            rowKey="id"
            className="table-interactive"
            loading={isLoadingOfferList}
            columns={columns}
            dataSource={offers}
            pagination={false}
            scroll={{ x: 'max-content', y: tableHeight }}
            onRow={(record) => ({
              onClick: onRowClickHandler.bind(null, record),
            })}
          />
        )}
        {pagination && (
          <Pagination
            current={pagination?.currentPage}
            total={pagination?.total}
            pageSize={pagination?.pageSize}
            pageSizeOptions={pageSizeOptions}
            onShowSizeChange={(x, size) => setPageSize(size)}
            showSizeChanger
            onChange={setCurrentPage}
          />
        )}
      </S.Content>
    </S.ContentWrapper>
  )
}
