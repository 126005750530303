import { useMutation } from '@tanstack/react-query'

import { CreateEdmOperatorBody } from '~/api/generated'
import { useApi } from '~providers/api'

export const useCreateCompanyEdo = (companyId: number) => {
  const api = useApi()

  return useMutation((createEdmOperatorBody: CreateEdmOperatorBody) => {
    return api.companies.edmOperatorControllerCreateEdmOperator({
      companyId,
      createEdmOperatorBody,
    })
  })
}
