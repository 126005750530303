import { useMutation } from '@tanstack/react-query'

import { AuctionApiAuctionControllerUpdateAuctionModeratorRequest } from '~/api/generated'
import { useApi } from '~providers/api'

export const useUpdateAuctionModerator = () => {
  const api = useApi()

  return useMutation((request: AuctionApiAuctionControllerUpdateAuctionModeratorRequest) => {
    return api.auction.auctionControllerUpdateAuctionModerator(request)
  })
}
