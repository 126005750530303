import React from 'react'

import { Button, Space } from 'antd'
import { Link } from 'react-router-dom'

import { appRoutes } from '~/router'
import { useGetCurrentRole } from '~hooks/auth'

interface AdminLinkToCompanyByIdProps {
  prefix?: string
  companyId: number
}

export const AdminLinkToCompanyById: React.FC<AdminLinkToCompanyByIdProps> = ({ prefix = 'ID:', companyId }) => {
  const {
    user: { isAdmin },
  } = useGetCurrentRole()

  if (!isAdmin) {
    return null
  }

  return (
    <Button type="link" style={{ height: 20 }}>
      <Link to={appRoutes.moderatorCompanyDetails.url.replace(':id', companyId.toString())}>
        <Space size={4}>
          {prefix}
          {companyId}
        </Space>
      </Link>
    </Button>
  )
}
