import React, { useState } from 'react'

import { Button, Divider, Modal, Space, Spin } from 'antd'
import { observer } from 'mobx-react-lite'
import { Link, useNavigate, useParams } from 'react-router-dom'

import { AppHeader } from '~/layout'
import { appRoutes } from '~/router'
import { PhoneNumber } from '~/shared/components'
import { Badge, Card, Text, UploadButton } from '~/ui-components'
import { appToast, httpErrorHandler } from '~/utils'
import { getElevatorAdvertOfferStatus } from '~/utils/elevator-advert-offers/get-elevator-advert-offer-status'
import { getElevatorAdvertOfferStatusDescription } from '~/utils/elevator-advert-offers/get-elevator-advert-offer-status-description'
import { getElevatorPriceTypeName } from '~/utils/elevators/get-elevator-price-type-name'
import { getDateFormat } from '~/utils/getDateFormat'
import { ElevatorOfferResponseStatus } from '~api/generated'
import { useAcceptAdvertOffer, useGetElevatorAdvertOffer, useRejectAdvertOffer } from '~hooks/elevator-advert-offers'
import { useCancelElevatorAdvertOffer } from '~hooks/elevator-advert-offers/useCancelElevatorAdvertOffer'
import { BasisAdvertOfferChangeStatusButton } from '~pages/BasisPage/components/BasisAdvertOfferChangeStatusButton'
import { BasisCultureParams } from '~pages/BasisPage/components/BasisCultureParams'
import { useAuth } from '~providers/auth'

import { BasisAdvertOfferRejectReasonModal } from '../../components/BasisAdvertOfferRejectReasonModal'

import { RejectAdvertOfferForm } from '~pages/BasisPage/components/BasisAdvertOfferRejectReasonModal/BasisAdvertOfferRejectReasonModal.types'

import * as S from './BasisAdvertOffer.styled'

export const BasisAdvertOffer: React.FC = observer(() => {
  const { id } = useParams<{ id: string | undefined }>()
  const { companyRole, companyId } = useAuth()
  const offerId = Number(id)
  const navigate = useNavigate()
  const [isOpenRejectOfferModal, setOpenRejectOfferModal] = useState(false)
  const cancelElevatorAdvertOffer = useCancelElevatorAdvertOffer()
  const getElevatorAdvertOfferFn = useGetElevatorAdvertOffer({ id: offerId, userCompanyId: companyId })
  const acceptAdvertOfferFn = useAcceptAdvertOffer()
  const rejectAdvertOfferFn = useRejectAdvertOffer()

  const offer = getElevatorAdvertOfferFn.data
  const isMyOffer = offer?.isOwnedByMyCompany
  const isOfferForMyAdvert = offer?.elevatorAdvert?.company?.id === companyId

  const handleNavigateToAdvertInfo = () => {
    if (!offer?.elevatorAdvert?.id) return
    navigate(appRoutes.basisAdvert.url.replace(':id', offer.elevatorAdvert.id.toString()))
  }

  const handleCancelOfferConfirm = () => {
    Modal.confirm({
      title: 'Отменить отклик',
      content: '',
      okText: 'Снять с публикации',
      cancelText: 'Отмена',
      async onOk() {
        try {
          await cancelElevatorAdvertOffer.mutateAsync({
            id: offerId,
            updateElevatorOfferResponseCancelBody: {
              userCompanyId: companyId,
            },
          })
          await getElevatorAdvertOfferFn.refetch()
          appToast.success({
            description: 'Отклик снят с публикации',
          })
        } catch (e) {
          httpErrorHandler(e, 'Ошибка при снятии с публикации')
        }
      },
    })
  }

  const handleAcceptOffer = async () => {
    Modal.confirm({
      title: 'Принять ставку',
      content:
        'После принятия ставки мы свяжемся с продавцом, а вам будут доступны его контактные данные. Отменить действие будет невозможно',
      okText: 'Принять',
      cancelText: 'Отмена',
      async onOk() {
        try {
          await acceptAdvertOfferFn.mutateAsync({
            id: offerId,
            updateElevatorOfferResponseAcceptBody: { userCompanyId: companyId },
          })
          await getElevatorAdvertOfferFn.refetch()
          appToast.success({ description: 'Ставка принята' })
        } catch (e) {
          httpErrorHandler(e, 'Ошибка при принятии ставки')
        }
      },
    })
  }

  const handleDeclineOffer = async (declineReason: RejectAdvertOfferForm) => {
    try {
      await rejectAdvertOfferFn.mutateAsync({
        id: offerId,
        updateElevatorAdvertOfferRejectBody: {
          userCompanyId: companyId,
          rejectionReason: declineReason.reason,
          rejectionComment: declineReason.message,
        },
      })
      await getElevatorAdvertOfferFn.refetch()
      appToast.success({ description: 'Ставка отклонена' })
    } catch (e) {
      httpErrorHandler(e, 'Ошибка при отклонении ставки')
    }
  }

  return (
    <>
      <AppHeader title={appRoutes.basisAdvertOffer.title + offerId} isBack />

      <Spin spinning={getElevatorAdvertOfferFn.isFetching}>
        <S.ContentWrapper>
          {offer && (
            <Card key={offer.id}>
              {offer.contactPerson ? (
                <div>
                  <Text variant="h2" style={{ textAlign: 'center', marginTop: 0 }}>
                    Данные {isMyOffer ? 'продавца' : 'покупателя'}
                  </Text>

                  <S.CompanyInfo>
                    <Card.Label>Компания</Card.Label>
                    <Card.Value>
                      <b>{offer?.contactCompany?.name ?? '-'}</b>
                    </Card.Value>
                    <Card.Label>ИНН</Card.Label>
                    <Card.Value>
                      <b>{offer?.contactCompany?.inn ?? '-'}</b>
                    </Card.Value>

                    <Card.Label>Контактное лицо</Card.Label>
                    <Card.Value>
                      <b>{offer.contactPerson.name}</b>
                    </Card.Value>
                    <Card.Label>Телефон</Card.Label>
                    <Card.Value>
                      <PhoneNumber phoneNumber={offer.contactPerson.phoneNumber} prefix="" />
                    </Card.Value>
                  </S.CompanyInfo>
                </div>
              ) : null}
              <S.StatusMessage>
                {getElevatorAdvertOfferStatusDescription(offer, companyRole, isOfferForMyAdvert)}
              </S.StatusMessage>
              <Divider />

              <S.OfferWrapper>
                <S.TopInfoWrapper>
                  <S.BasisInfo>
                    <Card.Label>Базис:</Card.Label>
                    <Card.Value>
                      <Link to={appRoutes.basisInfo.url.replace(':id', offer.elevator.id.toString())}>
                        {offer?.elevator?.name}
                      </Link>
                    </Card.Value>
                  </S.BasisInfo>
                  <S.PublishedAt>
                    {getDateFormat(isOfferForMyAdvert ? offer?.publishedAt ?? offer.createdAt : offer.createdAt)}
                  </S.PublishedAt>
                </S.TopInfoWrapper>

                <Card.Title>{offer.product.name}</Card.Title>

                <Space direction="vertical">
                  <S.Label>Статус</S.Label>
                  {(isOfferForMyAdvert || isMyOffer) && (
                    <div>
                      <Badge color={getElevatorAdvertOfferStatus(offer.status).color} size="s">
                        {getElevatorAdvertOfferStatus(offer.status).name}
                      </Badge>
                    </div>
                  )}
                </Space>
                <Space direction="vertical">
                  <S.Label>Объем</S.Label>
                  <S.Volume>{offer.volume} т</S.Volume>
                </Space>

                <Space direction="vertical">
                  <S.Label>Цена</S.Label>

                  <Space>
                    <S.Price>{offer.price} ₽/т</S.Price>
                    <Text variant="normal">{offer.includingNds ? 'с НДС' : 'без НДС'}</Text>
                  </Space>

                  {offer?.priceType && <S.PriceType>{getElevatorPriceTypeName(offer.priceType)}</S.PriceType>}
                </Space>

                <Space direction="vertical">
                  <S.Label>Показатели</S.Label>
                  {!!offer.product.parameters.length && <BasisCultureParams product={offer.product} />}
                </Space>

                {offer?.laboratoryAnalysis?.source && (
                  <UploadButton
                    fileName="Лаб. анализ"
                    isReadOnly
                    onFileClick={() => window.open(offer.laboratoryAnalysis?.source, '_blank')}
                  />
                )}

                {isOfferForMyAdvert ? (
                  <Space direction="vertical">
                    <S.ActionWrapper>
                      {offer?.nextStatuses.includes(ElevatorOfferResponseStatus.Accepted) && (
                        <S.OfferMessage>
                          Контакты данные компании будут доступны вам после принятия ставки.
                        </S.OfferMessage>
                      )}

                      {offer?.nextStatuses.includes(ElevatorOfferResponseStatus.RejectedByBuyer) && (
                        <Button htmlType="button" onClick={() => setOpenRejectOfferModal(true)}>
                          Отклонить
                        </Button>
                      )}

                      {offer?.nextStatuses.includes(ElevatorOfferResponseStatus.Accepted) && (
                        <Button htmlType="button" type="primary" onClick={handleAcceptOffer}>
                          Принять
                        </Button>
                      )}
                    </S.ActionWrapper>

                    {offer?.nextStatuses.includes(ElevatorOfferResponseStatus.Accepted) && (
                      <S.OfferMessage>
                        Вы можете принять решение по этой ставке в течение 24 часов. По истечению срока мы направим эту
                        ставку другому покупателю. Подробнее
                      </S.OfferMessage>
                    )}
                  </Space>
                ) : (
                  <S.ActionWrapper2>
                    {(offer.status === 'ON_MODERATION' || offer.status === 'UNDER_CONSIDERATION') && (
                      <Button htmlType="button" type="primary" onClick={handleCancelOfferConfirm}>
                        Отменить отклик
                      </Button>
                    )}

                    <BasisAdvertOfferChangeStatusButton
                      offerId={offerId}
                      offerStatus={offer.status}
                      type={companyRole === 'SELLER' ? 'SELL' : 'BUY'}
                      myPurchaseStatus={offer.myPurchaseStatus}
                      counterpartyPurchaseStatus={offer.counterpartyPurchaseStatus}
                      onAfterStatusChange={() => getElevatorAdvertOfferFn.refetch()}
                    />

                    <Divider />

                    <S.OfferWrapper>
                      <Text variant="h2" style={{ textAlign: 'center', marginTop: 0 }}>
                        Данные из объявления
                      </Text>
                      <Text variant="normal">{offer?.elevatorAdvert?.company?.name}</Text>

                      <Text variant="h4">{offer.product.name}</Text>
                      <div>{!!offer.product.parameters.length && <BasisCultureParams product={offer.product} />}</div>

                      <S.AdvertPriceMessage>
                        Мы не выводим тут цену, потому что она могла измениться. Чтобы посмотреть актуальные данные,
                        перейдите к объявлению.
                      </S.AdvertPriceMessage>
                    </S.OfferWrapper>

                    <Button htmlType="button" type="primary" onClick={handleNavigateToAdvertInfo}>
                      Перейти к объявлению
                    </Button>
                  </S.ActionWrapper2>
                )}
              </S.OfferWrapper>
            </Card>
          )}
        </S.ContentWrapper>
      </Spin>

      <BasisAdvertOfferRejectReasonModal
        isOpen={isOpenRejectOfferModal}
        onReject={handleDeclineOffer}
        onClose={() => setOpenRejectOfferModal(false)}
      />
    </>
  )
})
