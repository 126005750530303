import React from 'react'

import { Flex, Space } from 'antd'

import { Text } from '~/ui-components'
import { getTransportTypeName } from '~/utils/car-park/get-transport-type'
import { getTransportTypeIcon } from '~/utils/car-park/get-transport-type-icon'
import { TransportEntity, TransportType } from '~api/gql-generated/graphql'

import * as S from './TransportListItem.styled'

interface TransportListItemProps {
  transport: TransportEntity
  onClickCard: (transportId: number) => void
  children?: React.ReactNode
}

export function TransportListItem({ transport, onClickCard, children }: TransportListItemProps) {
  return (
    <S.FlexItem align="center" gap={24} onClick={() => onClickCard(transport.id)}>
      <S.IconWrapper>{getTransportTypeIcon(transport.type)}</S.IconWrapper>
      <Flex vertical gap={8}>
        <Flex>
          <Space size={8}>
            <Text variant="normal-bold">{transport.brand}</Text>
            <Text variant="normal">{transport.licensePlate}</Text>
          </Space>
        </Flex>
        <Flex>
          <Space size={8}>
            <Text variant="normal">ID: {transport.id}</Text>
            <S.TextDark variant="label">{getTransportTypeName(transport.type)}</S.TextDark>
            {transport.type !== TransportType.Tractor && transport.bodyType && (
              <S.TextLight variant="label">{transport.bodyType.name}</S.TextLight>
            )}
            {transport.loadCapacityKg && <S.TextLight variant="label">{transport.loadCapacityKg / 1000} т</S.TextLight>}
            {transport.loadVolumeL && <S.TextLight variant="label">{transport.loadVolumeL / 1000} м³</S.TextLight>}
          </Space>
        </Flex>
      </Flex>

      {children}
    </S.FlexItem>
  )
}
