import React from 'react'

import { Button, Dropdown, Tooltip } from 'antd'
import type { ItemType } from 'antd/es/menu/interface'
import { FiMoreVertical } from 'react-icons/fi'

import { DownOutlined } from '@ant-design/icons'

import { useGetDevice } from '~hooks/common'

import * as S from './EmployeeActionMenu.styled'

interface EmployeeActionMenuProps {
  isOwner: boolean
  isActivated: boolean
  onEdit: () => void
  onDelete?: () => void
  onActivate: () => void
  onDeactivate: () => void
  canUpdateUserData: boolean
  canDeleteUserFromCompany?: boolean
  canActivateUser: boolean
  canDeactivateUser: boolean
}

export const EmployeeActionMenu: React.FC<EmployeeActionMenuProps> = (props) => {
  const device = useGetDevice()
  const isMobile = device === 'mobile'

  const menuItems: ItemType[] = [
    {
      label: <Tooltip title={!props.canUpdateUserData ? 'Не хватает прав' : undefined}>Редактировать</Tooltip>,
      key: 'edit',
      onClick: props.onEdit,
      disabled: !props.canUpdateUserData,
    },
    {
      label: <Tooltip title={!props.canActivateUser ? 'Не хватает прав' : undefined}>Активировать</Tooltip>,
      key: 'activate',
      onClick: props.onActivate,
      disabled: !props.canActivateUser,
      style: { display: props.isActivated ? 'none' : 'inherit' },
    },
    {
      label: <Tooltip title={!props.canDeactivateUser ? 'Не хватает прав' : undefined}>Деактивировать</Tooltip>,
      key: 'deactivate',
      onClick: props.onDeactivate,
      disabled: !props.canDeactivateUser,
      style: { display: !props.isActivated ? 'none' : 'inherit' },
    },
    {
      label: (
        <Tooltip title={!props.canDeleteUserFromCompany ? 'Не хватает прав' : undefined}>Удалить из компании</Tooltip>
      ),
      key: 'remove',
      danger: true,
      onClick: props.onDelete,
      disabled: !props.canDeleteUserFromCompany,
      style: { display: !props.onDelete ? 'none' : undefined },
    },
  ]

  return (
    <Dropdown menu={{ items: menuItems }} trigger={['click']} disabled={props.isOwner}>
      <Tooltip title={props.isOwner ? 'Нельзя редактировать сотрудника с ролью "Владелец"' : undefined}>
        {isMobile ? (
          <Button type="text" icon={<FiMoreVertical size={18} />} />
        ) : (
          <S.StyledButton>
            Действия <DownOutlined />
          </S.StyledButton>
        )}
      </Tooltip>
    </Dropdown>
  )
}
