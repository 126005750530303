import React, { useEffect, useState } from 'react'

import { Button, Modal, Spin } from 'antd'

import { useMutation } from '@apollo/client'
import { ApolloError } from '@apollo/client/errors'

import { modalSharedSettings } from '~/utils'
import { graphqlErrorHandler } from '~/utils/graphqlErrorHandler'
import { confirmPhoneNumberMutation } from '~api/gql-mutations/auth.mutation.graphql'
import {
  confirmNewPhoneNumberMutation,
  requestChangePhoneNumberMutation,
  setNewPhoneNumberMutation,
} from '~api/gql-mutations/me.mutation.graphql'
import { ChangePhoneNumberConfirmationCode } from '~pages/AccountPage/modules/PersonalAccountInfoPage/components/ChangePhoneNumberConfirmationCode'
import { NewPhoneNumberForm } from '~pages/AccountPage/modules/PersonalAccountInfoPage/components/NewPhoneNumberForm'

import * as S from './ChangePhoneNumberModal.styled'

interface ChangePhoneNumberModalProps {
  currentPhoneNumber: string
  isOpen: boolean
  onCancel: () => void
  afterChangeNumber: () => void
}

export const ChangePhoneNumberModal: React.FC<ChangePhoneNumberModalProps> = ({
  isOpen,
  onCancel,
  currentPhoneNumber,
  afterChangeNumber,
}) => {
  const [smsCodeErrorMessage, setSmsCodeErrorMessage] = useState<string>('')
  const [newPhoneErrorMessage, setNewPhoneErrorMessage] = useState<string>('')
  const [confirmationOldPhoneCodeId, setConfirmationOldPhoneCodeId] = useState<string | null>(null)
  const [confirmationNewPhoneCodeId, setConfirmationNewPhoneCodeId] = useState<string | null>(null)
  const [isConfirmedOldNumber, setConfirmedOldNumber] = useState(false)
  const [isConfirmedNewNumber, setConfirmedNewNumber] = useState(false)
  const [newPhoneNumber, setNewPhoneNumber] = useState<string | null>(null)
  const [requestChangePhoneNumber, requestChangePhoneNumberState] = useMutation(requestChangePhoneNumberMutation)
  const [confirmOldPhoneNumber, confirmOldPhoneNumberState] = useMutation(confirmPhoneNumberMutation)
  const [setNewPhoneNumberFn, setNewPhoneNumberFnState] = useMutation(setNewPhoneNumberMutation)
  const [confirmNewPhoneNumberFn, confirmNewPhoneNumberFnState] = useMutation(confirmNewPhoneNumberMutation)

  useEffect(() => {
    if (isOpen) {
      void sendCodeToOldMobilePhoneHandler(currentPhoneNumber)
    }
  }, [isOpen])

  const sendCodeToOldMobilePhoneHandler = async (phoneNumber: string) => {
    setConfirmationOldPhoneCodeId(null)
    setSmsCodeErrorMessage('')
    setConfirmedOldNumber(false)
    setNewPhoneNumber(null)
    setConfirmedNewNumber(false)
    setConfirmationNewPhoneCodeId(null)
    try {
      const res = await requestChangePhoneNumber()
      setConfirmationOldPhoneCodeId(res.data?.me?.requestChangePhoneNumber?.id ?? null)
    } catch (e) {
      if (e instanceof ApolloError) {
        if (e.message === 'SMS was sent recently') {
          setSmsCodeErrorMessage('Слишком часто запрашиваете код')
          return
        }
        graphqlErrorHandler(e, 'Ошибка при отправке запроса на смену номера телефона')
      }
    }
  }

  const verifyCodeFromOldMobilePhone = async (smsCode: string) => {
    setSmsCodeErrorMessage('')

    if (!confirmationOldPhoneCodeId) {
      setSmsCodeErrorMessage('Проверочный код не был отправлен на телефон')
      console.log('confirmationCodeId is not present')
      return
    }
    try {
      const res = await confirmOldPhoneNumber({
        variables: { input: { confirmationCode: smsCode, confirmationCodeId: confirmationOldPhoneCodeId } },
      })
      setConfirmedOldNumber(res.data?.auth.confirmPhoneNumber.isConfirmed ?? false)
    } catch (e) {
      setConfirmedOldNumber(false)

      if (e instanceof ApolloError) {
        if (e.message === 'SMS code is invalid') {
          setSmsCodeErrorMessage('Неверный код')
          return
        }
        graphqlErrorHandler(e, 'Ошибка при отправке запроса на смену номера телефона')
      }
    }
  }

  const changePhoneToNewNumberAndSendCodeHandler = async (phoneNumber: string) => {
    setNewPhoneErrorMessage('')

    if (!confirmationOldPhoneCodeId) {
      setNewPhoneErrorMessage('Проверочный код не был отправлен на телефон')
      console.log('confirmationCodeId is not present')
      return
    }
    try {
      const res = await setNewPhoneNumberFn({
        variables: { input: { phoneNumber, confirmationCodeId: confirmationOldPhoneCodeId } },
      })
      setNewPhoneNumber(res.data?.me?.setNewPhoneNumber?.phoneNumber ?? null)
      setConfirmationNewPhoneCodeId(res.data?.me?.setNewPhoneNumber?.id ?? null)
    } catch (e) {
      if (e instanceof ApolloError) {
        const regex = /User with phone number \+7\d{10} already exists/
        if (regex.test(e.message)) {
          setNewPhoneErrorMessage('Такой номер телефона уже используется другим пользователем')
          return
        }
        graphqlErrorHandler(e, 'Ошибка при отправке запроса на смену номера телефона')
      }
    }
  }

  const verifyCodeFromNewMobilePhone = async (smsCode: string) => {
    if (!confirmationNewPhoneCodeId) {
      setSmsCodeErrorMessage('Проверочный код не был отправлен на телефон')
      console.log('confirmationNewPhoneCodeId is not present')
      return
    }
    try {
      const res = await confirmNewPhoneNumberFn({
        variables: { input: { confirmationCode: smsCode, confirmationCodeId: confirmationNewPhoneCodeId } },
      })
      setConfirmedNewNumber(res.data?.me.confirmNewPhoneNumber.isConfirmed ?? false)
      afterChangeNumberHandler()
    } catch (e) {
      setConfirmedNewNumber(false)
      setSmsCodeErrorMessage('Неверный код')
      e instanceof ApolloError &&
        graphqlErrorHandler(e, 'Ошибка при отправке запроса для проверки кода для смены номера телефона')
    }
  }

  const afterChangeNumberHandler = () => {
    afterChangeNumber()
  }

  const renderContent = () => {
    if (newPhoneNumber) {
      if (isConfirmedNewNumber) {
        return <Button onClick={onCancel}>Номер изменен</Button>
      }
      return (
        <Spin spinning={confirmNewPhoneNumberFnState.loading}>
          <ChangePhoneNumberConfirmationCode
            mobileNumber={newPhoneNumber}
            onRetry={() => changePhoneToNewNumberAndSendCodeHandler(newPhoneNumber)}
            onSubmit={verifyCodeFromNewMobilePhone}
            errorMessage={smsCodeErrorMessage}
          />
        </Spin>
      )
    } else {
      if (isConfirmedOldNumber) {
        return (
          <NewPhoneNumberForm
            onSubmit={changePhoneToNewNumberAndSendCodeHandler}
            isSubmitting={setNewPhoneNumberFnState.loading}
            errorMessage={newPhoneErrorMessage}
            onCancel={onCancel}
          />
        )
      }
      return (
        <Spin spinning={confirmOldPhoneNumberState.loading}>
          <ChangePhoneNumberConfirmationCode
            mobileNumber={currentPhoneNumber}
            onRetry={() => sendCodeToOldMobilePhoneHandler(currentPhoneNumber)}
            onSubmit={verifyCodeFromOldMobilePhone}
            errorMessage={smsCodeErrorMessage}
          />
        </Spin>
      )
    }
  }

  return (
    <Modal {...modalSharedSettings} open={isOpen} onCancel={onCancel} title="Изменить номер" footer={null} width={380}>
      <S.Wrapper>{renderContent()}</S.Wrapper>
    </Modal>
  )
}
