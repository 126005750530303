import { codeUnreachable } from '~/utils/code-unreachable'
import { TransportMode } from '~api/generated'

export function getTransportModeName(transportMode: TransportMode): string {
  switch (transportMode) {
    case TransportMode.Road:
      return 'Автомобильный'
    case TransportMode.Rail:
      return 'Железнодорожный'
    case TransportMode.River:
      return 'Морской/внутренний водный (речной)'
    default:
      return codeUnreachable(transportMode)
  }
}
