import { useMutation } from '@tanstack/react-query'

import { CompanyApiUpdateDocumentRequest } from '~api/generated'
import { useUploadFileAndGetId } from '~hooks/_utils'
import { useApi } from '~providers/api'

interface useUploadCompanyDocumentRequest {
  companyId: number
  documentType: CompanyApiUpdateDocumentRequest['documentType']
  companyDocument: File
}
export const useUploadCompanyDocument = () => {
  const api = useApi()
  const uploadFileAndGetId = useUploadFileAndGetId()

  return useMutation(async ({ companyId, documentType, companyDocument }: useUploadCompanyDocumentRequest) => {
    const companyDocumentFileKey = await uploadFileAndGetId.mutateAsync(companyDocument)

    return api.companies.updateDocument({
      companyId,
      documentType,
      updateDocumentBody: {
        companyDocumentFileKey,
      },
    })
  })
}
