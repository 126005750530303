import React from 'react'

import { DaDataParty, DaDataSuggestion } from 'react-dadata/dist/types'

import { InputDadata } from '~/ui-components'
import { CustomControlInterface } from '~shared/controls/CustomControl.interface'

interface OrganizationControlAutocompleteProps extends CustomControlInterface {
  onChange: (value: string | undefined) => void
  value: string | undefined
  onSelect: (value: DaDataSuggestion<DaDataParty> | undefined) => void
  hasError?: boolean
  id?: string
}

export const OrganizationControlAutocomplete: React.FC<OrganizationControlAutocompleteProps> = React.forwardRef<
  HTMLInputElement,
  OrganizationControlAutocompleteProps
>(({ id, value, onChange, onSelect, hasError }, ref) => {
  const onChangeInputHandler = (v: string | undefined) => {
    onChange(v)
  }
  const onSelectHandler = (v: DaDataSuggestion<DaDataParty> | undefined) => {
    onSelect(v)
  }

  return (
    <InputDadata
      id={id}
      placeholder="Введите название"
      inputValue={value}
      onChangeInput={onChangeInputHandler}
      onSelect={onSelectHandler}
      variant="organizations"
      inputStatus={hasError ? 'error' : undefined}
    />
  )
})

OrganizationControlAutocomplete.displayName = 'OrganizationControlAutocomplete'
