import { useMutation } from '@tanstack/react-query'

import { useApi } from '~providers/api'

interface IRemoveBankAccount {
  bankAccountId: number
}

export const useRemoveBankAccount = () => {
  const api = useApi()

  return useMutation(({ bankAccountId }: IRemoveBankAccount) => {
    return api.companies.bankAccountControllerDeleteBankAccount({ bankAccountId })
  })
}
