import React, { useEffect, useState } from 'react'

import { Modal } from 'antd'

import { FormField } from '~/ui-components'
import { formatNumber } from '~/utils/formatNumber'
import { getNdsString } from '~/utils/getNdsString'
import { WarehouseSelect } from '~shared/components'
import { ISelectedWarehouse } from '~shared/components/WarehouseSelect/WarehouseSelect'

import * as S from './SelectWarehouseAndCreateDealModal.styled'

interface IProps {
  visible: boolean
  companyId: number
  productName: string
  price: number
  volume: number
  includingNds: boolean
  onCancel: () => void
  onSubmit: (value: ISelectedWarehouse) => void
}

export const SelectWarehouseAndCreateDealModal: React.FC<IProps> = (props) => {
  const [selectedWarehouse, setSelectedWarehouse] = useState<ISelectedWarehouse | null>(null)

  useEffect(() => {
    if (!props.visible) {
      setSelectedWarehouse(null)
    }
  }, [props.visible])
  const cancelHandler = () => {
    props.onCancel()
  }

  const selectWarehouseHandler = (value: ISelectedWarehouse | null) => {
    setSelectedWarehouse(value)
  }

  const onSubmit = () => {
    if (selectedWarehouse) {
      props.onSubmit(selectedWarehouse)
      props.onCancel()
    }
  }

  return (
    <Modal
      title="Отклик на предложение"
      open={props.visible}
      closable
      destroyOnClose
      maskClosable
      width={400}
      onCancel={cancelHandler}
      onOk={onSubmit}
      okButtonProps={{ disabled: !selectedWarehouse }}
    >
      <S.Content>
        <S.Culture>{props.productName}</S.Culture>

        <S.Value>{formatNumber(props.volume)} тонн</S.Value>

        <S.Price>
          {formatNumber(props.price)} ₽ {props.includingNds != null && `(${getNdsString(props.includingNds)})`}
        </S.Price>
      </S.Content>
      <FormField isRequired label="Выбрать склад выгрузки">
        <WarehouseSelect companyId={props.companyId} onSelect={selectWarehouseHandler} />
      </FormField>
    </Modal>
  )
}
