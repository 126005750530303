import styled from 'styled-components'
import { variant } from 'styled-system'

import { css } from '@styled-system/css'

import { BADGE_SIZES } from './Badge.constants'

import type { BadgeProps, BadgeVariant, VariantStyle } from './Badge.types'

const variants: Record<BadgeVariant, VariantStyle> = {
  base: {
    color: 'BadgeGreenDark',
    backgroundColor: 'BadgeGreenLight',
  },
  yellow: {
    color: 'BadgeYellowDark',
    backgroundColor: 'BadgeYellowLight',
  },
  gray: {
    color: 'BadgeGrayDark',
    backgroundColor: 'BadgeGrayLight',
  },
  red: {
    color: 'BadgeRedDark',
    backgroundColor: 'BadgeRedLight',
  },
  blue: {
    color: 'BadgeBlueDark',
    backgroundColor: 'BadgeBlueLight',
  },
  purple: {
    color: 'BadgePurpleDark',
    backgroundColor: 'BadgePurpleLight',
  },
  darkgray: {
    color: 'Gray800',
    backgroundColor: 'Gray300',
  },
}

export const Badge = styled('div')<BadgeProps>(
  ({ isRound, isFilled = true }) =>
    css({
      display: 'inline-flex',
      padding: isFilled ? BADGE_SIZES.m : '0 !important',
      borderRadius: isRound ? '100px' : '6px',
      backgroundColor: isFilled ? '' : 'transparent !important',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      variant: isFilled ? 'typography.textCaption' : 'typography.buttonBase',
    }),
  variant({
    prop: 'color',
    variants,
  }),
  variant({
    prop: 'size',
    variants: {
      s: {
        padding: BADGE_SIZES.s,
      },
      m: {
        padding: BADGE_SIZES.m,
      },
    },
  })
)
