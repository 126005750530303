/* tslint:disable */
/* eslint-disable */
/**
 * API Зерно-Агрегатора
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface UpdateOfferStatusBody
 */
export interface UpdateOfferStatusBody {
    /**
     * Id компании, пользователь которой делает запрос
     * @type {number}
     * @memberof UpdateOfferStatusBody
     */
    'userCompanyId': number;
    /**
     * 
     * @type {string}
     * @memberof UpdateOfferStatusBody
     */
    'status': UpdateOfferStatusBodyStatusEnum;
}

export const UpdateOfferStatusBodyStatusEnum = {
    OnModeration: 'ON_MODERATION',
    PrePublished: 'PRE_PUBLISHED',
    Published: 'PUBLISHED',
    PreDeal: 'PRE_DEAL',
    InDeal: 'IN_DEAL',
    Expired: 'EXPIRED',
    Rejected: 'REJECTED',
    Realised: 'REALISED'
} as const;

export type UpdateOfferStatusBodyStatusEnum = typeof UpdateOfferStatusBodyStatusEnum[keyof typeof UpdateOfferStatusBodyStatusEnum];


