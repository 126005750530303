import * as yup from 'yup'

import { addressSchema, VALIDATORS_MSG } from '~shared/validations'

const requiredTrimmedString = yup.string().trim().required(VALIDATORS_MSG.required)

export const dealSpecificationFormSchema = yup
  .object({
    culture: yup.object({
      // hasError: yup.bool().oneOf([false], 'Параметры культуры заполнены с ошибками'),
    }),
    labAnalysis: yup.mixed().required(VALIDATORS_MSG.requiredUpload),
    volume: yup.number().typeError(VALIDATORS_MSG.required).required(VALIDATORS_MSG.required),
    price: yup.number().typeError(VALIDATORS_MSG.required).required(VALIDATORS_MSG.required),
    commission: yup.number().typeError(VALIDATORS_MSG.required).required(VALIDATORS_MSG.required),
    usingNdsForBuyer: yup.boolean().required(VALIDATORS_MSG.required),
    usingNdsForSeller: yup.boolean().required(VALIDATORS_MSG.required),
    transportationType: requiredTrimmedString,
    consignee: requiredTrimmedString,
    shipper: requiredTrimmedString,
    loadingAddress: addressSchema,
    unloadingAddress: addressSchema,
    deliveryDate: yup.date().typeError(VALIDATORS_MSG.required).required(VALIDATORS_MSG.required),
    paymentMethod: requiredTrimmedString,
  })
  .required()
