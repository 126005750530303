import React from 'react'

import { Button, Flex, Input, InputNumber, Radio, Space } from 'antd'
import { Controller, useForm } from 'react-hook-form'

import { yupResolver } from '@hookform/resolvers/yup'

import { FormField, Text } from '~/ui-components'
import { formatNumber } from '~/utils/formatNumber'
import { getDateFormat } from '~/utils/getDateFormat'
import { inputSharedSettings } from '~/utils/inputSharedSettings'
import { getTransportType } from '~/utils/logistics/get-transport-type'
import { FreightPaymentTerm } from '~api/gql-generated/graphql'
import { ReadValue } from '~shared/components'
import { DatePickerControl } from '~shared/controls'

import { BetToLoadFormSchema } from './BetToLoadForm.validation'

import { BetToLoadFormProps, BetToLoadFormValues } from './BetToLoadForm.types'

export const BetToLoadForm: React.FC<BetToLoadFormProps> = ({ onBack, onSubmit, freightOrder }) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<BetToLoadFormValues>({
    resolver: yupResolver(BetToLoadFormSchema),
  })

  const onSubmitHandler = (data: BetToLoadFormValues) => {
    onSubmit(data)
  }

  return (
    <Flex vertical gap={24}>
      <Flex gap={32}>
        <Flex
          vertical
          gap={16}
          style={{ backgroundColor: 'var(--gray-1-color)', padding: '16px', borderRadius: '8px' }}
          flex="1 1 40%"
        >
          <ReadValue
            name="Дата погрузки"
            value={freightOrder.startedAt ? getDateFormat(freightOrder.startedAt) : undefined}
          />
          <ReadValue
            name="Маршрут"
            value={`${freightOrder.route.points[0].address.short} - ${freightOrder.route.points[1].address.short}, ${freightOrder.route.distance} км`}
          />
          <ReadValue name="Тип груза" value={freightOrder.route.points[0].freight?.productType.name} />
          <ReadValue name="Доступный объем" value={(freightOrder.availableWeightKg / 1000).toString() + ' т'} />
          <ReadValue name="Транспорт" value={getTransportType(freightOrder.transportFormatTypes)} />
          <ReadValue
            name="Ставка"
            value={
              <>
                <p>{formatNumber(freightOrder.pricePerTonWithNds)} ₽/т с НДС</p>
                <p>{formatNumber(freightOrder.pricePerTonWithoutNds)} ₽/т без НДС</p>
              </>
            }
          />
        </Flex>

        <Flex vertical gap={24} flex="1 1 60%">
          <Flex gap={16}>
            <Flex vertical>
              <FormField
                label="Ставка"
                htmlFor="pricePerTon"
                validateMessage={errors.pricePerTon?.message}
                isRequired
                grow={false}
              >
                <Controller
                  name="pricePerTon"
                  control={control}
                  render={({ field }) => (
                    <InputNumber
                      {...field}
                      {...inputSharedSettings}
                      addonAfter="₽/т"
                      style={{ width: '100%' }}
                      id="pricePerTon"
                      placeholder="Укажите ставку"
                      status={errors.pricePerTon && 'error'}
                      step={1}
                    />
                  )}
                />
              </FormField>

              <FormField htmlFor="priceWithNds" validateMessage={errors.priceWithNds?.message}>
                <Controller
                  name="priceWithNds"
                  control={control}
                  render={({ field }) => (
                    <Radio.Group onChange={(e) => field.onChange(e.target.value)} value={field.value}>
                      <Space>
                        <Radio value={true}>с НДС</Radio>
                        <Radio value={false}>без НДС</Radio>
                      </Space>
                    </Radio.Group>
                  )}
                />
              </FormField>
            </Flex>

            <FormField
              label="Вес"
              htmlFor="weightKg"
              validateMessage={errors.weightKg?.message}
              isRequired
              grow={false}
            >
              <Controller
                name="weightKg"
                control={control}
                render={({ field }) => (
                  <InputNumber
                    {...field}
                    {...inputSharedSettings}
                    addonAfter="т"
                    style={{ width: '100%' }}
                    id="weightKg"
                    placeholder="Укажите вес"
                    status={errors.weightKg && 'error'}
                    step={1}
                  />
                )}
              />
            </FormField>
          </Flex>

          <FormField
            label="Условия оплаты"
            htmlFor="paymentTerm"
            validateMessage={errors.paymentTerm?.message}
            isRequired
          >
            <Controller
              name="paymentTerm"
              control={control}
              render={({ field }) => (
                <Radio.Group {...field} optionType="button" buttonStyle="solid" size="middle">
                  <Radio.Button value={FreightPaymentTerm.Advance}>Предоплата</Radio.Button>
                  <Radio.Button value={FreightPaymentTerm.Deferred}>Отсрочка</Radio.Button>
                </Radio.Group>
              )}
            />
          </FormField>

          <Flex vertical>
            <FormField label="Дата погрузки" htmlFor="startedAt" validateMessage={errors.startedAt?.message}>
              <Controller
                name="startedAt"
                control={control}
                render={({ field }) => (
                  <DatePickerControl worksWith="isoString" id="startedAt" {...field} hasError={!!errors.startedAt} />
                )}
              />
            </FormField>
            <Text variant="form-read-label">Вы можете предложить свою дату погрузки</Text>
          </Flex>

          <FormField
            label="Комментарий"
            htmlFor="additionalDetails"
            validateMessage={errors.additionalDetails?.message}
            grow={false}
          >
            <Controller
              name="additionalDetails"
              control={control}
              render={({ field }) => (
                <Input
                  {...field}
                  id="additionalDetails"
                  status={errors.additionalDetails && 'error'}
                  placeholder="Введите комментарий"
                />
              )}
            />
          </FormField>
        </Flex>
      </Flex>

      <Flex gap={16} justify="flex-end">
        <Button htmlType="button" onClick={onBack}>
          Отменить
        </Button>

        <Button htmlType="button" type="primary" onClick={handleSubmit(onSubmitHandler)}>
          Направить ставку
        </Button>
      </Flex>
    </Flex>
  )
}
