import React, { PropsWithChildren } from 'react'

import { Select } from 'antd'
import { BaseSelectRef } from 'rc-select'

import { RoadSurfaceQuality } from '~api/generated'
import { CustomControlInterface } from '~shared/controls/CustomControl.interface'
import { getRoadSurfaceQualityName } from '~shared/controls/RoadSurfaceQualitySelectControl/get-road-surface-quality-name'

type Value = RoadSurfaceQuality | undefined

interface RoadSurfaceQualitySelectControlProps extends CustomControlInterface {
  onChange: (value: Value) => void
  value: Value
  hasError?: boolean
  disabled?: boolean
  id?: string
}

interface IOption {
  value: RoadSurfaceQuality
  label: string
}

const loadTypesOptions: IOption[] = [
  {
    label: getRoadSurfaceQualityName(RoadSurfaceQuality.AllSeasonal),
    value: RoadSurfaceQuality.AllSeasonal,
  },
  {
    label: getRoadSurfaceQualityName(RoadSurfaceQuality.Seasonal),
    value: RoadSurfaceQuality.Seasonal,
  },
]

export const RoadSurfaceQualitySelectControl: React.FC<PropsWithChildren<RoadSurfaceQualitySelectControlProps>> =
  React.forwardRef<BaseSelectRef, PropsWithChildren<RoadSurfaceQualitySelectControlProps>>(
    ({ id, value, onChange, hasError, disabled }, ref) => {
      const onSelectAddressHandler = (v: Value): void => {
        onChange(v)
      }

      return (
        <Select
          ref={ref}
          id={id}
          style={{ width: '100%' }}
          placeholder="Выберите из списка"
          value={value}
          disabled={disabled}
          onChange={onSelectAddressHandler}
          status={hasError ? 'error' : undefined}
          optionFilterProp="children"
          allowClear
        >
          {loadTypesOptions.map((it) => (
            <Select.Option key={it.value} value={it.value}>
              {it.label}
            </Select.Option>
          ))}
        </Select>
      )
    }
  )

RoadSurfaceQualitySelectControl.displayName = 'RoadSurfaceQualitySelectControl'
