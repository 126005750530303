import * as yup from 'yup'

import { TransportationArea, TransportMode } from '~api/gql-generated/graphql'
import { VALIDATORS_MSG } from '~shared/validations'

export interface EditTransportationDataProps {
  onSubmit: (formValues: EditTransportationDataValues) => void
  onBack: () => void
  defaultValues: EditTransportationDataValues
}

export const EditTransportationDataSchema = yup.object({
  transportModes: yup.array().of(yup.mixed<TransportMode>().defined(VALIDATORS_MSG.defined)),
  transportationArea: yup.mixed<TransportationArea>().oneOf(Object.values(TransportationArea)).nullable(),
  regions: yup.array().of(yup.mixed<number>().defined(VALIDATORS_MSG.defined)),
  address: yup.string().trim().nullable().defined(VALIDATORS_MSG.defined),
})

export type EditTransportationDataValues = yup.InferType<typeof EditTransportationDataSchema>
