import { useMutation } from '@tanstack/react-query'

import { ElevatorAdvertApiAdminElevatorAdvertControllerUpdateElevatorAdvertRequest } from '~/api/generated'
import { useApi } from '~providers/api'

export const useUpdateElevatorAdvertByAdmin = () => {
  const api = useApi()

  const useUpdateElevatorAdvert = async (
    request: ElevatorAdvertApiAdminElevatorAdvertControllerUpdateElevatorAdvertRequest
  ): Promise<void> => {
    const res = await api.elevatorAdvert.adminElevatorAdvertControllerUpdateElevatorAdvert(request)

    return res.data
  }

  return useMutation(useUpdateElevatorAdvert)
}
