import React from 'react'

import { getElevatorAdvertOfferRejectReason } from '~/utils/elevator-advert-offers/get-elevator-advert-offer-reject-reason'
import { CompanyDataTypeEnum, ElevatorOfferResponseStatus, GetElevatorAdvertOfferResponse } from '~api/generated'

export function getElevatorAdvertOfferStatusDescription(
  offer: GetElevatorAdvertOfferResponse,
  role: CompanyDataTypeEnum | null,
  isOfferForMyAdvert: boolean
): string | JSX.Element {
  const isSeller = role === 'SELLER'
  // const isBuyer = role === 'BUYER'
  switch (offer.status) {
    case ElevatorOfferResponseStatus.OnModerationWithAnalysis:
    case ElevatorOfferResponseStatus.OnModeration:
      return 'Ваш отклик проверяется администратором. Мы сообщим, когда статус поменяется.'
    case ElevatorOfferResponseStatus.UnderConsideration:
      if (isOfferForMyAdvert) {
        return 'Если вам подходит отклик, то нажмите кнопку “Принять”, после чего мы покажем данные продавца.'
      }
      if (isSeller) {
        return 'Покупатель еще не рассмотрел ваш отклик. Мы сообщим вам, когда статус отклика поменяется.'
      }
      return 'Продавец еще не рассмотрел ваш отклик. Мы сообщим вам, когда статус отклика поменяется.'
    case 'REJECTED_BY_BUYER':
      const reasonType = getElevatorAdvertOfferRejectReason(offer.userRejectionReason)

      if (isSeller) {
        return (
          <p style={{ textAlign: 'center' }}>
            Ваш отклик не подошел покупателю.
            <br />
            Причина: ”{offer.userRejectionReason === 'OTHER' ? offer.userRejectionComment : reasonType}”
            <br />
            Вы можете сделать новый.
          </p>
        )
      }
      return (
        <p style={{ textAlign: 'center' }}>
          Ваш отклик не подошел продавцу.
          <br />
          Причина: ”{offer.userRejectionReason === 'OTHER' ? offer.userRejectionComment : reasonType}”
          <br />
          Вы можете сделать новый.
        </p>
      )
    case 'REJECTED_ON_MODERATION':
      return (
        <p style={{ textAlign: 'center' }}>
          Ваш отклик не прошел модерацию.
          <br />
          Причина отмены: “{offer.rejectionReason}”
        </p>
      )
    case 'ACCEPTED':
      if (isSeller) {
        return 'Свяжитесь с покупателем и обсудите детали сделки'
      }
      return 'Свяжитесь с продавцом и обсудите детали сделки'
    case 'CONTROVERSIAL':
      return 'Отклик переведен в статус “Спорный”. Администратор свяжется с вами, чтобы уточнить причину.'
    default:
      return ''
  }
}
