import React from 'react'

import { Flex } from 'antd'

import { Text } from '~/ui-components'
import { formatPhoneNumber } from '~/utils/formatPhoneNumber'
import { getDateFormat } from '~/utils/getDateFormat'
import { DriverEntity } from '~api/gql-generated/graphql'

interface DriverInfoCardProps {
  driver: DriverEntity
}
export function DriverInfoCard({ driver }: DriverInfoCardProps) {
  return (
    <Flex vertical gap={24} style={{ paddingTop: 16 }}>
      <Flex vertical gap={4}>
        <Text variant="form-read-label">Номер телефона</Text>
        <Text variant="form-read-value">{formatPhoneNumber(driver?.phoneNumber)}</Text>
      </Flex>

      <Flex vertical gap={4}>
        <Text variant="form-read-label">Дата рождения</Text>
        <Text variant="form-read-value">{getDateFormat(driver?.driverData.birthDate)}</Text>
      </Flex>

      <Flex vertical gap={4}>
        <Text variant="form-read-label">Серия и номер вод-го удостоверения</Text>
        <Text variant="form-read-value">{driver?.driverData.licenseNumber}</Text>
      </Flex>

      <Flex vertical gap={4}>
        <Text variant="form-read-label">Дата выдачи</Text>
        <Text variant="form-read-value">{getDateFormat(driver?.driverData.licenseIssuedAt)}</Text>
      </Flex>

      <Flex vertical gap={4}>
        <Text variant="form-read-label">Годен до</Text>
        <Text variant="form-read-value">{getDateFormat(driver?.driverData.licenseExpiredAt)}</Text>
      </Flex>

      <Flex vertical gap={4}>
        <Text variant="form-read-label">Комментарий</Text>
        <Text variant="form-read-value">{driver?.driverData?.additionalDetails ?? '-'}</Text>
      </Flex>
    </Flex>
  )
}
