import React from 'react'
import { TransportMode } from '~api/gql-generated/graphql'
import { Select } from 'antd'
import { getTransportModeName } from '~/utils/car-park/get-transport-mode'
import { CustomControlInterface } from '~shared/controls/CustomControl.interface'
import { BaseSelectRef } from 'rc-select'

interface TransportModesSelectControlProps extends CustomControlInterface {
  id?: string
  value: TransportMode[] | undefined
  onChange: (value: TransportMode[]) => void
  hasError?: boolean
}

export const TransportModesSelectControl: React.FC<TransportModesSelectControlProps> = React.forwardRef<
  BaseSelectRef,
  TransportModesSelectControlProps
>(({ value, onChange, hasError }, ref) => {
  return (
    <Select
      ref={ref}
      allowClear
      mode="multiple"
      maxTagCount={1}
      style={{ width: '100%' }}
      placeholder="Выберите"
      defaultValue={value}
      onChange={onChange}
      status={hasError ? 'error' : undefined}
    >
      {Object.values(TransportMode).map((TransportMode) => (
        <Select.Option key={TransportMode} value={TransportMode}>
          {getTransportModeName(TransportMode)}
        </Select.Option>
      ))}
    </Select>
  )
})
