import React, { useEffect, useState } from 'react'

import { Button, Checkbox, Input, Select, Tooltip } from 'antd'
import { Controller, useForm } from 'react-hook-form'

import { yupResolver } from '@hookform/resolvers/yup'

import { Box, FormField, Text } from '~/ui-components'
import { SignatoryLegalBasis } from '~api/gql-generated/graphql'
import { ResponsePageHeader } from '~shared/components'
import { AddressControlAutocomplete, UsingNdsControl } from '~shared/controls'

import { companyAccountRequisitesEditFormSchema } from './CompanyAccountRequisitesEdit.validation'

import {
  CompanyAccountRequisitesEditFormValues,
  CompanyAccountRequisitesEditProps,
} from './CompanyAccountRequisitesEdit.types'

import * as S from './CompanyAccountRequisitesEdit.styled'

const tooltipTitle = 'Если вы указали неправильную компанию, создайте новую компанию'

export const CompanyAccountRequisitesEdit: React.FC<CompanyAccountRequisitesEditProps> = ({
  defaultValues,
  onSubmit,
  onBack,
  loading,
}) => {
  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    watch,
    formState: { errors, isDirty },
  } = useForm<CompanyAccountRequisitesEditFormValues>({
    defaultValues: {
      legalName: defaultValues?.legalName ?? '',
      inn: defaultValues?.inn ?? '',
      kpp: defaultValues?.kpp ?? '',
      ogrn: defaultValues?.ogrn ?? '',
      okved: defaultValues?.okved ?? '',
      directorName: defaultValues?.directorName ?? '',
      useNds: defaultValues?.useNds,
      signatory: defaultValues?.signatory ?? undefined,
      signatoryLegalBasis: defaultValues?.signatoryLegalBasis ?? undefined,
      signatoryPosition: defaultValues?.signatoryPosition ?? '',
      actualAddress: defaultValues?.actualAddress ?? '',
      legalAddress: defaultValues?.legalAddress ?? '',
      aicCharterMember: defaultValues?.aicCharterMember ?? undefined,
    },
    resolver: yupResolver(companyAccountRequisitesEditFormSchema),
  })

  const legalAddressFormControl = watch('legalAddress')
  const actualAddressFormControl = watch('actualAddress')

  const [isSameAddress, setIsSameAddress] = useState(legalAddressFormControl === actualAddressFormControl)

  useEffect(() => {
    if (!legalAddressFormControl?.trim() || !actualAddressFormControl?.trim()) {
      setIsSameAddress(false)
      return
    }
    setIsSameAddress(legalAddressFormControl === actualAddressFormControl)
  }, [legalAddressFormControl, actualAddressFormControl])

  useEffect(() => {
    if (isSameAddress) {
      setValue('actualAddress', getValues('legalAddress'), { shouldTouch: true })
    } else {
      // setValue('actualAddress', defaultValues?.actualAddress ?? '', { shouldTouch: true })
    }
  }, [isSameAddress])

  const onSubmitHandler = (data: CompanyAccountRequisitesEditFormValues) => {
    onSubmit(data)
  }

  return (
    <S.Wrapper>
      <ResponsePageHeader
        variant="edit"
        title="Редактировать реквизиты компании"
        titleMobile="Редактировать"
        subTitle="МОЯ КОМПАНИЯ"
        loading={loading}
        onSubmit={handleSubmit(onSubmitHandler)}
        onBack={onBack}
      />

      <S.ContentWrapper>
        <S.StyledForm>
          <S.FormRow>
            <Tooltip title={tooltipTitle}>
              <FormField label="Название компании" htmlFor="legalName" validateMessage={errors.legalName?.message}>
                <Controller
                  name="legalName"
                  control={control}
                  render={({ field }) => (
                    <Input
                      {...field}
                      disabled
                      id="legalName"
                      status={errors.legalName && 'error'}
                      placeholder="ООО «Название компании»"
                    />
                  )}
                />
              </FormField>
            </Tooltip>
          </S.FormRow>

          <S.FormRow>
            <Tooltip title={tooltipTitle}>
              <FormField label="ИНН" htmlFor="inn" validateMessage={errors.inn?.message}>
                <Controller
                  name="inn"
                  control={control}
                  render={({ field }) => (
                    <Input {...field} disabled id="inn" status={errors.inn && 'error'} placeholder="777888000" />
                  )}
                />
              </FormField>
            </Tooltip>

            <FormField label="КПП" isRequired htmlFor="kpp" validateMessage={errors.kpp?.message}>
              <Controller
                name="kpp"
                control={control}
                render={({ field }) => (
                  <Input {...field} id="kpp" status={errors.kpp && 'error'} placeholder="000256172" />
                )}
              />
            </FormField>
          </S.FormRow>

          <S.FormRow>
            <Tooltip title={tooltipTitle}>
              <FormField label="Код ОКВЭД" htmlFor="okved" validateMessage={errors.okved?.message}>
                <Controller
                  name="okved"
                  control={control}
                  render={({ field }) => (
                    <Input {...field} disabled id="okved" status={errors.okved && 'error'} placeholder="6172" />
                  )}
                />
              </FormField>
            </Tooltip>

            <Tooltip title={tooltipTitle}>
              <FormField label="ОГРН" isRequired htmlFor="ogrn" validateMessage={errors.ogrn?.message}>
                <Controller
                  name="ogrn"
                  control={control}
                  render={({ field }) => (
                    <Input {...field} disabled id="ogrn" status={errors.ogrn && 'error'} placeholder="1234567890125" />
                  )}
                />
              </FormField>
            </Tooltip>
          </S.FormRow>

          <S.FormRow>
            <FormField
              label="Генеральный директор"
              htmlFor="directorName"
              validateMessage={errors.directorName?.message}
              isRequired
            >
              <Controller
                name="directorName"
                control={control}
                render={({ field }) => (
                  <Input
                    {...field}
                    id="directorName"
                    status={errors.directorName && 'error'}
                    placeholder="Введите ФИО"
                  />
                )}
              />
            </FormField>
          </S.FormRow>

          <S.FormRow>
            <FormField label="Подписант" htmlFor="signatory" validateMessage={errors.signatory?.message} isRequired>
              <Controller
                name="signatory"
                control={control}
                render={({ field }) => (
                  <Input {...field} id="signatory" status={errors.signatory && 'error'} placeholder="Введите ФИО" />
                )}
              />
            </FormField>
          </S.FormRow>

          <S.FormRow>
            <FormField
              label="Должность подписанта"
              htmlFor="signatoryPosition"
              validateMessage={errors.signatoryPosition?.message}
              isRequired
            >
              <Controller
                name="signatoryPosition"
                control={control}
                render={({ field }) => (
                  <Input
                    {...field}
                    id="signatoryPosition"
                    status={errors.signatoryPosition && 'error'}
                    placeholder="Введите должность"
                  />
                )}
              />
            </FormField>
          </S.FormRow>

          <S.FormRow>
            <FormField
              label="Основание"
              isRequired
              htmlFor="signatoryLegalBasis"
              validateMessage={errors.signatoryLegalBasis?.message}
            >
              <Controller
                name="signatoryLegalBasis"
                control={control}
                render={({ field }) => (
                  <Select
                    status={errors.signatoryLegalBasis && 'error'}
                    style={{ width: '100%' }}
                    value={field.value}
                    placeholder="Выберите основание права подписи"
                    onChange={(e) => field.onChange(e)}
                  >
                    <Select.Option value={SignatoryLegalBasis.CompanyRegulation}>Устав</Select.Option>
                    <Select.Option value={SignatoryLegalBasis.PowerOfAttorney}>Доверенность</Select.Option>
                  </Select>
                )}
              />
            </FormField>
          </S.FormRow>

          <S.FormRow>
            <FormField label="Юридический адрес" htmlFor="legalAddress" validateMessage={errors.legalAddress?.message}>
              <Controller
                name="legalAddress"
                control={control}
                render={({ field }) => (
                  <AddressControlAutocomplete
                    id="legalAddress"
                    value={field.value}
                    onChange={field.onChange}
                    hasError={!!errors.legalAddress}
                    onAddressSelect={(suggestion) =>
                      field.onChange(suggestion?.value ?? suggestion?.unrestricted_value)
                    }
                  />
                )}
              />
            </FormField>
          </S.FormRow>

          <S.FormRow>
            <FormField
              label="Физический адрес"
              htmlFor="actualAddress"
              validateMessage={errors.actualAddress?.message}
              isRequired
            >
              <>
                <Controller
                  name="actualAddress"
                  control={control}
                  render={({ field }) => (
                    <AddressControlAutocomplete
                      id="actualAddress"
                      value={field.value}
                      onChange={field.onChange}
                      hasError={!!errors.actualAddress}
                      onAddressSelect={(suggestion) =>
                        field.onChange(suggestion?.value ?? suggestion?.unrestricted_value)
                      }
                    />
                  )}
                />
                <Box mt={4}>
                  <Checkbox
                    id="sameAddress"
                    checked={isSameAddress}
                    onChange={(e) => setIsSameAddress(e.target.checked)}
                  >
                    Адрес совпадает с юридическим
                  </Checkbox>
                </Box>
              </>
            </FormField>
          </S.FormRow>

          <S.FormRow>
            <FormField label="Применение НДС" isRequired htmlFor="useNds" validateMessage={errors.useNds?.message}>
              <Controller name="useNds" control={control} render={({ field }) => <UsingNdsControl {...field} />} />
            </FormField>

            <FormField validateMessage={errors.aicCharterMember?.message} label="&nbsp;">
              <Controller
                name="aicCharterMember"
                control={control}
                render={({ field }) => (
                  <Checkbox
                    id="aicCharterMember"
                    checked={field.value}
                    onChange={(e) => field.onChange(e.target.checked)}
                  >
                    Участник хартии АПК
                  </Checkbox>
                )}
              />
            </FormField>
          </S.FormRow>

          <S.ActionWrapper>
            <Button type="default" htmlType="button" onClick={onBack}>
              Отмена
            </Button>
            <Button type="primary" htmlType="button" loading={loading} onClick={handleSubmit(onSubmitHandler)}>
              Готово
            </Button>
          </S.ActionWrapper>
        </S.StyledForm>

        <S.Suggession>
          <Text variant="normal">
            Нередактируемые данные заносятся автоматически при регистрации компании. Если вы обнаружили ошибку, сообщите
            нам.
          </Text>
        </S.Suggession>
      </S.ContentWrapper>
    </S.Wrapper>
  )
}
