import React from 'react'

import { Button, Flex } from 'antd'

import { Text } from '~/ui-components'
import { getNoun } from '~/utils/getNoun'
import { getFreightPackingType } from '~/utils/logistics/get-freight-packing-type'
import { getFreightPaymentTerm } from '~/utils/logistics/get-freight-payment-term'
import {
  FreightOrderChangeRequestEntity,
  FreightOrderChangeRequestStatus,
  FreightOrderChangeRequestType,
  FreightOrderFieldName,
  FreightPackingType,
  FreightPaymentTerm,
} from '~api/gql-generated/graphql'

import { ProposedConditionFormProps } from './ProposedConditionForm.types'

const CurrentProposedConditionValues = ({
  lastChangeRequest,
}: {
  lastChangeRequest: FreightOrderChangeRequestEntity
}) => {
  let result = ''
  switch (lastChangeRequest.type) {
    case FreightOrderChangeRequestType.Weight:
      const weight = lastChangeRequest.changedFields.find((item) => item.fieldName === FreightOrderFieldName.Weight)
        ?.value

      return weight ? (
        <Flex vertical gap={16}>
          <Flex gap={8}>
            <Text variant="form-read-label">Вес</Text>
            <Text variant="form-read-value">{+weight / 1000} т</Text>
          </Flex>
        </Flex>
      ) : null
    case FreightOrderChangeRequestType.PackingType:
      const packingType = lastChangeRequest.changedFields.find(
        (item) => item.fieldName === FreightOrderFieldName.PackingType
      )?.value
      const bigBagsCount = lastChangeRequest.changedFields.find(
        (item) => item.fieldName === FreightOrderFieldName.BigBagsCount
      )?.value
      const packingTypeCustom = lastChangeRequest.changedFields.find(
        (item) => item.fieldName === FreightOrderFieldName.PackingTypeCustom
      )?.value

      result += packingType ? getFreightPackingType(packingType as FreightPackingType) : ''
      result += bigBagsCount ? `, ${bigBagsCount} шт` : ''
      result += packingTypeCustom ? `, ${packingTypeCustom}` : ''

      return result ? (
        <Flex vertical gap={16}>
          <Flex gap={8}>
            <Text variant="form-read-label">Вид упаковки</Text>
            <Text variant="form-read-value">{result}</Text>
          </Flex>
        </Flex>
      ) : null
    case FreightOrderChangeRequestType.PricePerTon:
      const pricePerTon = lastChangeRequest.changedFields.find(
        (item) => item.fieldName === FreightOrderFieldName.PricePerTonWithoutNds
      )?.value

      return pricePerTon ? (
        <Flex vertical gap={16}>
          <Flex gap={8}>
            <Text variant="form-read-label">Ставка без НДС</Text>
            <Text variant="form-read-value">{pricePerTon} ₽/т</Text>
          </Flex>
        </Flex>
      ) : null
    case FreightOrderChangeRequestType.PaymentTerm:
      const paymentTerm = lastChangeRequest.changedFields.find(
        (item) => item.fieldName === FreightOrderFieldName.PaymentTerm
      )?.value
      const deferredDaysCount = lastChangeRequest.changedFields.find(
        (item) => item.fieldName === FreightOrderFieldName.DeferredDaysCount
      )?.value
      const advancePercent = lastChangeRequest.changedFields.find(
        (item) => item.fieldName === FreightOrderFieldName.AdvancePercent
      )?.value

      result += paymentTerm ? getFreightPaymentTerm(paymentTerm as FreightPaymentTerm) : ''
      result += deferredDaysCount ? `, ${deferredDaysCount} ${getNoun(+deferredDaysCount, 'день', 'дня', 'дней')}` : ''
      result += advancePercent ? `, ${advancePercent} %` : ''

      return result ? (
        <Flex vertical gap={16}>
          <Flex gap={8}>
            <Text variant="form-read-label">Условия оплаты</Text>
            <Text variant="form-read-value">{result}</Text>
          </Flex>
        </Flex>
      ) : null
    case FreightOrderChangeRequestType.TransportRegistry:
      const canUpdateTransportRegistryInPast = lastChangeRequest.changedFields.find(
        (item) => item.fieldName === FreightOrderFieldName.CanUpdateTransportRegistryInPast
      )?.value

      return canUpdateTransportRegistryInPast ? (
        <Flex vertical gap={16}>
          <Flex gap={8}>
            <Text variant="form-read-label">Редактирование</Text>
            <Text variant="form-read-value">{canUpdateTransportRegistryInPast}</Text>
          </Flex>
        </Flex>
      ) : null
    case FreightOrderChangeRequestType.Distance:
      const distance = lastChangeRequest.changedFields.find((item) => item.fieldName === FreightOrderFieldName.Distance)
        ?.value
      const comment = lastChangeRequest.additionalDetails

      return distance && comment ? (
        <Flex vertical gap={16}>
          <Flex gap={8}>
            <Text variant="form-read-label">Длина маршрута</Text>
            <Text variant="form-read-value">{distance} км</Text>
          </Flex>

          <Flex gap={8}>
            <Text variant="form-read-label">Комментарий:</Text>
            <Text variant="normal">{comment}</Text>
          </Flex>
        </Flex>
      ) : null
    default:
      return null
  }
}

export const ProposedConditionForm: React.FC<ProposedConditionFormProps> = ({
  onOfferYour,
  onSubmit,
  lastChangeRequest,
}) => {
  return (
    <Flex vertical gap={24}>
      <Flex style={{ paddingBottom: '16px', borderBottom: '1px solid var(--gray-2-color)' }}>
        <CurrentProposedConditionValues lastChangeRequest={lastChangeRequest} />
      </Flex>

      <Flex gap={16} justify="flex-end">
        <Button htmlType="button" onClick={onOfferYour}>
          Предложить свое
        </Button>

        {lastChangeRequest.nextStatuses.includes(FreightOrderChangeRequestStatus.Rejected) && (
          <Button
            htmlType="button"
            onClick={() => onSubmit(lastChangeRequest.id, FreightOrderChangeRequestStatus.Rejected)}
          >
            Отклонить
          </Button>
        )}

        {lastChangeRequest.nextStatuses.includes(FreightOrderChangeRequestStatus.Accepted) && (
          <Button
            htmlType="button"
            type="primary"
            onClick={() => onSubmit(lastChangeRequest.id, FreightOrderChangeRequestStatus.Accepted)}
          >
            Принять
          </Button>
        )}
      </Flex>
    </Flex>
  )
}
