import styled from 'styled-components'

import { breakpoints } from '~/tokens'
import { Card } from '~/ui-components'

export const Content = styled(Card.Content)`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 24px;
  @media (max-width: ${breakpoints.MOBILE_XL}px) {
    margin-top: 16px;
  }
`

export const FormView1Column = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 24px;
  grid-row-gap: 16px;

  @media (max-width: ${breakpoints.MOBILE_XL}px) {
    grid-template-columns: 1fr;
    grid-row-gap: 4px;
    margin-top: 16px;
  }
`
