import { FreightOrderChangeRequestStatus } from '~api/gql-generated/graphql'

export function getFreightDealStatusIconText(
  status: FreightOrderChangeRequestStatus,
  isCreator: boolean,
  isFinalizing: boolean
) {
  switch (status) {
    case FreightOrderChangeRequestStatus.OnModeration:
      return isCreator ? 'Ожидание действия контрагента' : 'Контрагент ожидает вашего решения'
    case FreightOrderChangeRequestStatus.CounterOffer:
      return 'Контрагент предложил новые условия'
    case FreightOrderChangeRequestStatus.Accepted:
      return isCreator ? 'Контрагент принял предложенные вами условия' : 'Вы приняли условие контрагента'
    case FreightOrderChangeRequestStatus.Rejected:
      return isCreator === isFinalizing
        ? isCreator
          ? 'Неактуально, вы отклонили свое условие'
          : 'Неактуально, Контрагент отклонил свое условие'
        : isCreator
        ? 'Контрагент отклонил предложенные вами условия'
        : 'Вы отклонили условие контрагента'
    default:
      return ''
  }
}
