import * as yup from 'yup'

import { DealDocumentDataTypeEnum } from '~api/generated'
import { VALIDATORS_MSG } from '~shared/validations'

export const dealPaymentFormSchema = yup
  .object({
    documentType: yup.mixed().oneOf(Object.values(DealDocumentDataTypeEnum)).required(VALIDATORS_MSG.required),
    documentNumber: yup.string().trim().required(VALIDATORS_MSG.required),
    documentDate: yup.string().trim().required(VALIDATORS_MSG.required),
    documentFiles: yup.mixed().required(VALIDATORS_MSG.requiredUpload),
  })
  .required()
