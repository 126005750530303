import React, { useState } from 'react'

import { Button, Space } from 'antd'
import { FiArrowRight } from 'react-icons/fi'

import { Badge, Card, Text } from '~/ui-components'
import { getElevatorAdvertOfferStatus } from '~/utils/elevator-advert-offers/get-elevator-advert-offer-status'
import { getDateFormat } from '~/utils/getDateFormat'
import { ElevatorAdvertOfferData } from '~api/generated'
import { BasisCultureParams } from '~pages/BasisPage/components/BasisCultureParams'

import * as S from './BasisAdvertOfferPreviewCard.styled'

interface BasisAdvertOfferPreviewCardProps {
  offer: ElevatorAdvertOfferData
  onClick: (offerId: number) => void
}

export const BasisAdvertOfferPreviewCard: React.FC<BasisAdvertOfferPreviewCardProps> = ({ offer, onClick }) => {
  const [isShowParams, setShowParams] = useState(false)
  const onClickHandler = (offerId: number) => onClick(offerId)

  return (
    <Card key={offer.id} onClick={() => onClickHandler(offer.id)}>
      <S.CardHeader>
        <S.CardHeaderTop>
          <S.CardHeaderTopLeft>
            <S.TitleOfferNumber>Отклик №{offer.id}</S.TitleOfferNumber>
            <S.PublishedAt>{offer.publishedAt ? getDateFormat(offer.publishedAt) : null}</S.PublishedAt>
          </S.CardHeaderTopLeft>
          <FiArrowRight size={24} />
        </S.CardHeaderTop>
        <S.ProductWrapper>
          <S.PriceWrapper>
            <Space>
              <Text variant="h4">{offer.price} ₽/т</Text>
              <Text variant="normal">{offer.includingNds ? 'с НДС' : 'без НДС'}</Text>
            </Space>
            <S.Volume>{offer.volume} т</S.Volume>
          </S.PriceWrapper>

          <div>
            <Badge color={getElevatorAdvertOfferStatus(offer.status).color} size="s">
              {getElevatorAdvertOfferStatus(offer.status).name}
            </Badge>
          </div>

          {isShowParams && !!offer.product?.parameters.length && <BasisCultureParams product={offer.product} />}

          <S.ActionWrapper>
            <Button
              type="link"
              onClick={(e) => {
                e.stopPropagation()
                setShowParams((x) => !x)
              }}
            >
              {isShowParams ? 'Свернуть' : 'Развернуть'}
            </Button>
            <Text variant="normal">{offer.company?.name}</Text>
          </S.ActionWrapper>
        </S.ProductWrapper>
      </S.CardHeader>
    </Card>
  )
}
