import React, { useState } from 'react'

import { Button, Space, Switch } from 'antd'

import { useQueryClient } from '@tanstack/react-query'

import { Text } from '~/ui-components'
import { appToast, httpErrorHandler } from '~/utils'
import { useCreateElevatorAdmin } from '~hooks/elevators-admin'
import { CreateElevatorModal } from '~pages/SettingsPage/modules/ElevatorsAdmin/components/CreateElevatorModal'
import { CreateBasisFormValue } from '~pages/SettingsPage/modules/ElevatorsAdmin/components/CreateElevatorModal/CreateElevatorModal'
import { ElevatorsList } from '~pages/SettingsPage/modules/ElevatorsAdmin/components/ElevatorsList'

import * as S from './ElevatorAdmin.styled'

export const ElevatorsAdmin: React.FC = () => {
  const queryClient = useQueryClient()
  const [isCreateElevatorModalOpen, setCreateElevatorModalOpen] = useState(false)
  const [isShowDeletedOnly, setShowDeletedOnly] = useState(false)
  const [isShowCreationRequestOnly, setShowCreationRequestOnly] = useState(false)
  const createElevatorFn = useCreateElevatorAdmin()

  const onCloseCreateElevatorModalHandler = () => setCreateElevatorModalOpen(false)
  const onShowDeletedOnlyHandler = (checked: boolean) => setShowDeletedOnly(checked)
  const onShowCreationRequestOnlyHandler = (checked: boolean) => setShowCreationRequestOnly(checked)

  const onCreateElevatorHandler = async (formValues: CreateBasisFormValue) => {
    try {
      await createElevatorFn.mutateAsync({
        adminCreateElevatorBody: {
          // step 1
          name: formValues.step1.name,
          address: formValues.step1.address,
          type: formValues.step1.type,
          // step 2
          storageVolume: formValues.step2.storageVolume,
          productOnElevator: formValues.step2.productTypes,
          transport: formValues.step2.transport,
          openingTime: formValues.step2.openingTime?.toISOString(),
          closingTime: formValues.step2.closingTime?.toISOString(),
          workingDaysOfWeek: formValues.step2.workingDaysOfWeek,
          hasLab: formValues.step2.hasLab,
          hasDigitalQueue: formValues.step2.hasDigitalQueue,
          // step 3 Rail
          stationName: formValues.step3Rail?.stationName,
          hasRailWay: formValues.step3Rail?.hasRailWay,
          hasRailScales: formValues.step3Rail?.hasRailScales,
          hasRailStock: formValues.step3Rail?.hasRailStock,
          hasTecEco: formValues.step3Rail?.hasTecEco,
          railLoadSpeed: formValues.step3Rail?.railLoadSpeed,
          waitingArea: formValues.step3Rail?.waitingArea,
          railAdditionalInfo: formValues.step3Rail?.railAdditionalInfo,
          // step 4 Car
          hasScales: true, // радов сказал что у базисов всегда есть весы
          scalesWeightLimit: formValues.step4Car?.scalesWeightLimit,
          scalesLength: formValues.step4Car?.scalesLength,
          autoReceptionSpeed: formValues.step4Car?.autoReceptionSpeed,
          autoUnloadSpeed: formValues.step4Car?.autoUnloadSpeed,
          autoLength: formValues.step4Car?.autoLength,
          autoHeight: formValues.step4Car?.autoHeight ? formValues.step4Car.autoHeight * 100 : undefined,
          autoClearance: formValues.step4Car?.autoClearance,
          loadingTransport: !formValues.step4Car?.loadingTransport?.length
            ? undefined
            : formValues.step4Car?.loadingTransport,
          unloadingTransport: !formValues.step4Car?.unloadingTransport?.length
            ? undefined
            : formValues.step4Car?.unloadingTransport,
          hasAutoStorage: formValues.step4Car?.hasAutoStorage,
          autoAdditionalInfo: formValues.step4Car?.autoAdditionalInfo,
          // step 5 additional service
          additionalServiceWithNds: formValues.step5ServicePrice?.usingNds,
          receptionPrice: formValues.step5ServicePrice?.receptionPrice,
          dryingPrice: formValues.step5ServicePrice?.dryingPrice,
          storingPrice: formValues.step5ServicePrice?.storingPrice,
          mixingPrice: formValues.step5ServicePrice?.mixingPrice,
          autoShipmentPrice: formValues.step5ServicePrice?.autoShipmentPrice,
          trainShipmentPrice: formValues.step5ServicePrice?.trainShipmentPrice,
          paperworkPrice: formValues.step5ServicePrice?.paperworkPrice,
          cleaningPrice: formValues.step5ServicePrice?.cleaningPrice,
          censusPrice: formValues.step5ServicePrice?.censusPrice,
          anotherServices: formValues.step5ServicePrice?.otherServices,
        },
      })
      appToast.success({ description: 'Базис создан' })
      await queryClient.invalidateQueries(['elevatorsAdmin'])
      onCloseCreateElevatorModalHandler()
    } catch (e) {
      httpErrorHandler(e, 'Ошибка при создании базиса')
    }
  }

  return (
    <S.Content>
      <S.Header>
        <Button type="primary" onClick={() => setCreateElevatorModalOpen(true)}>
          Добавить базис
        </Button>

        <S.ShowDeletedToggler>
          <Space>
            <Text variant="label-bold">Только удаленные</Text>
            <Switch checked={isShowDeletedOnly} onChange={onShowDeletedOnlyHandler} />
          </Space>
          <Space>
            <Text variant="label-bold">Только заявки</Text>
            <Switch checked={isShowCreationRequestOnly} onChange={onShowCreationRequestOnlyHandler} />
          </Space>
        </S.ShowDeletedToggler>
      </S.Header>

      <S.Desktop>
        <ElevatorsList isShowDeletedOnly={isShowDeletedOnly} isShowCreationRequestOnly={isShowCreationRequestOnly} />
      </S.Desktop>

      <CreateElevatorModal
        open={isCreateElevatorModalOpen}
        onCancel={onCloseCreateElevatorModalHandler}
        onSubmit={onCreateElevatorHandler}
        lastStepSubmitLoading={createElevatorFn.isLoading}
      />
    </S.Content>
  )
}
