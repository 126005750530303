import { useQuery, UseQueryResult } from '@tanstack/react-query'

import { AdminGetElevatorsResponse, ElevatorApiElevatorAdminControllerAdminGetElevatorsRequest } from '~api/generated'
import { useApi } from '~providers/api'

export const useGetElevatorsAdmin = (
  request: ElevatorApiElevatorAdminControllerAdminGetElevatorsRequest
): UseQueryResult<AdminGetElevatorsResponse> => {
  const api = useApi()

  const fetchFn = async (): Promise<AdminGetElevatorsResponse> => {
    const res = await api.elevators.elevatorAdminControllerAdminGetElevators(request)

    return res.data
  }

  return useQuery(['elevatorsAdmin', request.currentPage, request.pageSize], fetchFn, { keepPreviousData: true })
}
