import { codeUnreachable } from '~/utils/code-unreachable'
import { TransportationArea } from '~api/gql-generated/graphql'

export function getTransportationAreaName(transportationArea: TransportationArea): string {
  switch (transportationArea) {
    case TransportationArea.Region:
      return 'На территории региона'
    case TransportationArea.Russia:
      return 'На территории РФ'
    case TransportationArea.Cis:
      return 'На территории СНГ'
    case TransportationArea.Worldwide:
      return 'По всему миру'
    default:
      return codeUnreachable(transportationArea)
  }
}
