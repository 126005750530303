import React, { FC, useEffect } from 'react'

import { Button, Input, Modal } from 'antd'
import { Controller, useForm } from 'react-hook-form'

import { yupResolver } from '@hookform/resolvers/yup'

import { CompanyDataTypeEnum, CreateOfferBody, CreationProductParameterData } from '~/api/generated'
import { FormField, UploadButton } from '~/ui-components'
import { modalSharedSettings } from '~/utils'
import { useGetCurrentRole } from '~hooks/auth'
import { createOfferModalSchema } from '~pages/OffersPage/components/CreateOfferModal/CreateOfferModalSchema'
import { useAuth } from '~providers/auth'
import {
  AddressControlDadata,
  CheckboxControl,
  ParametersCultureControl,
  PriceControl,
  SellOrBuyRadioControl,
  UsingNdsControl,
  VolumeControl,
} from '~shared/controls'

import { CreateOfferModalProps, FormValues } from './CreateOfferModal.types'

import * as S from './CreateOfferModal.styled'

export const CreateOfferModal: FC<CreateOfferModalProps> = ({ visible, onClose, onSubmit, isFetching }) => {
  const { companyId, user } = useAuth()
  const {
    company: { companyRole },
  } = useGetCurrentRole()

  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
    reset,
  } = useForm<FormValues>({
    defaultValues: {
      offerType: companyRole === CompanyDataTypeEnum.Buyer ? 'BUY' : 'SELL',
      address: undefined,
      isAddAddress: false,
      culture: undefined,
      volume: undefined,
      price: undefined,
      includingNds: undefined,
      labAnalysis: undefined,
      shippingRequired: false,
      note: '',
    },
    resolver: yupResolver(createOfferModalSchema),
  })

  useEffect(() => reset(), [visible])

  const handleFormSubmit = (values: FormValues) => {
    const createOfferBody: CreateOfferBody = {
      product: {
        parameters: values.culture?.parameters as CreationProductParameterData[],
        type: values.culture?.cultureTypeId ?? '',
      },
      type: values.offerType,
      volume: Number(values.volume),
      price: Number(values.price),
      includingNds: values.includingNds,
      address: values.address?.value ?? '',
      shippingRequired: values.shippingRequired,
      publishedAt: null,
      contactPerson: {
        phoneNumber: user?.phoneNumber || '',
        name: user?.name ?? '',
      },
      note: values.note,
    }

    onSubmit(createOfferBody, values.isAddAddress, values.labAnalysis)
  }

  const offerType = watch('offerType')

  return (
    <Modal
      title="Создание предложения"
      open={visible}
      onCancel={onClose}
      {...modalSharedSettings}
      footer={[
        <Button key="cancel" htmlType="button" onClick={onClose}>
          Отмена
        </Button>,
        <Button key="submit" type="primary" htmlType="submit" loading={isFetching} form="create-offer-modal">
          Разместить
        </Button>,
      ]}
    >
      <S.Form onSubmit={handleSubmit(handleFormSubmit)} id="create-offer-modal">
        {companyRole === CompanyDataTypeEnum.Trader && (
          <FormField>
            <Controller
              name="offerType"
              control={control}
              render={({ field }) => <SellOrBuyRadioControl {...field} />}
            />
          </FormField>
        )}

        <FormField label="Адрес" htmlFor="address" isRequired validateMessage={errors.address?.message}>
          <S.Address>
            <Controller
              name="address"
              control={control}
              render={({ field }) => (
                <AddressControlDadata
                  id="address"
                  value={undefined}
                  onChange={(value) => field.onChange(value)}
                  hasError={!!errors.address}
                />
              )}
            />

            <Controller
              control={control}
              name="isAddAddress"
              render={({ field }) => <CheckboxControl {...field}>Добавить местоположение в мои склады</CheckboxControl>}
            />
          </S.Address>
        </FormField>

        <FormField validateMessage={errors.culture ? 'Параметры культуры заполнены с ошибками' : ''}>
          <Controller
            name="culture"
            control={control}
            render={({ field }) => <ParametersCultureControl isRequired {...field} />}
          />
        </FormField>

        {offerType === 'SELL' && (
          <FormField label="Результаты лаб. исследований">
            <Controller
              control={control}
              name="labAnalysis"
              render={({ field }) => (
                <UploadButton
                  onChange={(evt) => {
                    if (evt.target.files?.length) {
                      field.onChange(evt.target.files[0])
                    }
                  }}
                >
                  Загрузить
                </UploadButton>
              )}
            />
          </FormField>
        )}

        <FormField label="Объем, тонн" validateMessage={errors.volume?.message} isRequired>
          <S.InputWrapper>
            <Controller
              name="volume"
              control={control}
              render={({ field }) => <VolumeControl id="volume" {...field} hasError={!!errors.volume} />}
            />
          </S.InputWrapper>
        </FormField>

        <S.Price>
          <S.InputWrapper>
            <FormField label="Цена за 1 тонну" validateMessage={errors.price?.message} isRequired>
              <Controller
                name="price"
                control={control}
                render={({ field }) => <PriceControl id="price" {...field} hasError={!!errors.price} addAfter="₽/т" />}
              />
            </FormField>
          </S.InputWrapper>

          <S.NdsWrapper>
            <FormField label="" validateMessage={errors.includingNds?.message}>
              <Controller
                control={control}
                name="includingNds"
                render={({ field }) => <UsingNdsControl {...field} />}
              />
            </FormField>
          </S.NdsWrapper>
        </S.Price>

        {offerType === 'BUY' && (
          <FormField>
            <Controller
              control={control}
              name="shippingRequired"
              render={({ field }) => (
                <CheckboxControl {...field}>Требуется перевозчик для доставки до склада</CheckboxControl>
              )}
            />
          </FormField>
        )}

        <FormField label="Примечание" validateMessage={errors.note?.message}>
          <Controller
            name="note"
            control={control}
            render={({ field }) => (
              <Input.TextArea
                placeholder="Примечание"
                {...field}
                status={errors.note && 'error'}
                style={{ width: '100%' }}
                value={field.value}
              />
            )}
          />
        </FormField>
      </S.Form>
    </Modal>
  )
}
