import React, { useMemo } from 'react'

import { Button, Empty, Pagination, Spin } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import { useParams } from 'react-router'
import { useNavigate } from 'react-router-dom'

import { useGetProductTypeData, useIsTimeEnd, usePagination } from '~/hooks'
import { AppHeader } from '~/layout'
import { appRoutes } from '~/router'
import { Badge, Text } from '~/ui-components'
import { showColumn } from '~/utils'
import { getOfferStatusForAuction } from '~/utils/auctions/get-offer-status-for-auction'
import { formatNumber } from '~/utils/formatNumber'
import { formatPhoneNumber } from '~/utils/formatPhoneNumber'
import { getDateTimeDayFormat } from '~/utils/getDateFormat'
import { getNdsString } from '~/utils/getNdsString'
import { getOfferType } from '~/utils/offers/get-offer-type'
import { AuctionOfferData, BaseOfferDataTypeEnum, CompanyAuctionDataStatusEnum } from '~api/generated'
import { useGetAuctionById, useGetOffersForAuction } from '~hooks/auctions'
import { useGetCurrentRole } from '~hooks/auth'
import { useGetDevice } from '~hooks/common'
import { useGetCompanyById } from '~hooks/company'
import { useGetCompanies } from '~hooks/company/useGetCompanies'
import { ActionContextMenu, AuctionOfferMobileCard, AuctionsHeaderDetails } from '~pages/Auctions/components'
import { AuctionOffersTable } from '~pages/Auctions/components/AuctionOffersTable'
import { AuctionTimer } from '~pages/Auctions/components/AuctionTimer'
import { API_OPTIONS } from '~pages/Auctions/utils'
import { OfferCardDetails } from '~pages/OffersPage/components/OfferCardDetails'

import * as S from '../../AuctionsPage.styled'

export const AuctionMyResponseLotDetails = () => {
  const { id } = useParams<{ id: string | undefined }>()
  const navigate = useNavigate()
  const {
    user: { isAdmin },
  } = useGetCurrentRole()
  const device = useGetDevice()
  const isMobile = device === 'mobile'
  const { pageSize, pageSizeOptions, currentPage, setCurrentPage, setPageSize } = usePagination({
    pageSize: 5,
  })
  const { getProductTypeParametersForUI_V2, getProductType } = useGetProductTypeData()

  const auctionId = id ? +id : undefined
  const {
    data: auctionData,
    isLoading: isLoadingAuction,
    refetch: refetchAuctionData,
  } = useGetAuctionById(auctionId, API_OPTIONS)
  const {
    data: auctionOffersData,
    refetch: refetchAuctionOffersData,
    isLoading: isLoadingAuctionOfferData,
  } = useGetOffersForAuction(
    {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      auctionId: auctionId!,
      pageSize,
      currentPage,
    },
    API_OPTIONS
  )

  const auctionOffers = auctionOffersData?.auctionOffers
  const auctionOffersPagination = auctionOffersData?.pagination
  const isAuctionEnd = useIsTimeEnd(auctionData?.endedAt ? new Date(auctionData.endedAt) : undefined)
  const { data: company } = useGetCompanyById(isAdmin ? auctionData?.offer?.companyId : undefined)

  const companiesIds = useMemo(
    () => Array.from(new Set(auctionOffers?.map((offer) => offer.companyId).filter(Boolean))),
    [auctionOffers]
  )
  const { data: companiesData } = useGetCompanies(
    {
      ids: companiesIds as number[],
    },
    isAdmin
  )
  const companiesMap = useMemo(() => new Map(companiesData?.map((company) => [company.id, company])), [companiesData])

  const handleBackButtonClick = () => {
    navigate(appRoutes.auctionsMyResponses.url)
  }

  const handleDealButtonClick = (evt: React.MouseEvent<HTMLElement, MouseEvent>, id: number | null) => {
    evt.stopPropagation()
    navigate(`/deals/${id}/information`)
  }

  const handleDataUpdate = () => {
    refetchAuctionOffersData()
    refetchAuctionData()
  }

  if (!auctionData || isLoadingAuction) {
    return (
      <S.SpinWrapper>
        <Spin spinning size="large" />
      </S.SpinWrapper>
    )
  }

  const offer = auctionData?.offer
  const isRowExpandable = offer.type === BaseOfferDataTypeEnum.Buy

  const Actions = () => {
    const isShowContextMenu = auctionData.status === CompanyAuctionDataStatusEnum.OnModeration

    if (auctionData.status === CompanyAuctionDataStatusEnum.Rejected) return null

    return (
      <S.AuctionCardActions>
        <AuctionTimer endedAt={auctionData?.endedAt} />
        {auctionId && isShowContextMenu && (
          <ActionContextMenu auctionId={auctionId} onCancelAuction={handleDataUpdate} />
        )}
      </S.AuctionCardActions>
    )
  }

  const columns: ColumnsType<AuctionOfferData> = [
    {
      title: 'Участник',
      dataIndex: 'isOwn',
      width: 150,
      render: (isOwn, row) => {
        return isOwn ? <S.Own>{'Вы'}</S.Own> : `Участник ${row.offerNumber}`
      },
    },
    {
      title: 'Статус ставки',
      dataIndex: 'status',
      width: 160,
      render: (value) => {
        const offerType = getOfferStatusForAuction(value)
        return (
          <Badge color={offerType.color} size="s">
            {offerType.name}
          </Badge>
        )
      },
    },
    {
      title: 'Объем, т',
      dataIndex: 'volume',
      align: 'right',
      width: 100,
      className: 'bold-cell',
      render: (volume) => {
        return formatNumber(volume)
      },
    },
    {
      title: 'Ставка ₽/т',
      dataIndex: 'price',
      align: 'right',
      width: '120px',
      className: 'bold-cell',
      render: (price) => {
        return formatNumber(price)
      },
    },
    {
      title: 'НДС',
      dataIndex: 'includingNds',
      width: '100px',
      render: (includingNds) => {
        return getNdsString(includingNds)
      },
    },
    ...showColumn<AuctionOfferData>(isAdmin, [
      {
        title: 'Компания',
        dataIndex: 'companyId',
        width: 100,
        render: (companyId: number) => {
          return <div style={{ minWidth: 100, whiteSpace: 'nowrap' }}>{companiesMap.get(companyId)?.name}</div>
        },
      },
      {
        title: 'Контактное лицо',
        dataIndex: 'contactPerson',
        width: 100,
        render: (contactPerson: AuctionOfferData['contactPerson']) => {
          return (
            <div style={{ minWidth: 100, whiteSpace: 'nowrap' }}>
              {contactPerson?.name}
              {contactPerson?.phoneNumber && <br />}
              {formatPhoneNumber(contactPerson?.phoneNumber ?? '')}
            </div>
          )
        },
      },
    ]),

    {
      title: 'Время ставки',
      dataIndex: 'addedAt',
      render: (addedAt) => {
        return addedAt ? getDateTimeDayFormat(addedAt) : ''
      },
    },
    {
      title: '',
      dataIndex: 'status',
      width: 160,
      render: (value, row) => {
        if (row.dealId && row.isOwn) {
          return (
            <S.StatusWrapper>
              <Button onClick={(evt) => handleDealButtonClick(evt, row.dealId)}>Перейти к сделке</Button>
            </S.StatusWrapper>
          )
        }
      },
    },
  ]

  return (
    <>
      <AppHeader
        title={`${appRoutes.auctionsMyResponsesDetails.title} ${auctionId}`}
        isBack
        onBack={handleBackButtonClick}
      />
      <S.Content>
        <AuctionsHeaderDetails onUpdateData={handleDataUpdate} />
        <OfferCardDetails
          statuses={[getOfferType(offer.type)]}
          title={getProductType(offer?.product.type)?.name ?? ''}
          parameters={getProductTypeParametersForUI_V2(offer.product)}
          shippingRequired={offer.shippingRequired}
          type={offer.type}
          address={offer.address}
          laboratoryAnalysisSource={offer.laboratoryAnalysis?.source}
          minPrice={offer.type === BaseOfferDataTypeEnum.Sell ? offer.price : undefined}
          maxPrice={offer.type === BaseOfferDataTypeEnum.Buy ? offer.price : undefined}
          initialVolume={offer.volume}
          availableVolume={auctionData?.notSelectedVolume}
          includingNds={offer.includingNds}
          actions={<Actions />}
          distance={offer.distance}
          note={offer.note}
          company={company || undefined}
          contactPersonName={isAdmin ? offer.contactPerson?.name : undefined}
          contactPersonPhone={isAdmin ? offer.contactPerson?.phoneNumber : undefined}
          endAuction={!isAuctionEnd ? auctionData.endedAt : null}
          isParametersCorrespondsToGost={offer.product.parametersIsCorrespondsToGost}
          gost={offer.product.gost}
        />

        <S.OfferTableHeader>
          {!!auctionOffers?.length && <Text variant="h3">Предложения участников</Text>}
        </S.OfferTableHeader>

        {!!auctionOffers?.length && (
          <>
            {isMobile ? (
              <Spin spinning={isLoadingAuctionOfferData}>
                {!auctionOffers?.length && <Empty />}
                <S.AuctionMobileCardList>
                  {auctionOffers.map((offer) => (
                    <AuctionOfferMobileCard
                      offerId={offer.id}
                      key={offer.id}
                      isOwn={offer.isOwn}
                      offerStatus={offer.status}
                      offerNumber={offer.offerNumber}
                      offerType={offer.type}
                      offerParameters={offer.product.parameters}
                      volume={offer.volume}
                      price={offer.price}
                      includingNds={offer.includingNds}
                      datetime={getDateTimeDayFormat(offer.addedAt)}
                      companyName={isAdmin && offer.companyId ? companiesMap.get(offer.companyId)?.name : undefined}
                      contactUserName={offer.contactPerson?.name}
                      contactUserPhone={offer.contactPerson?.phoneNumber}
                      productTypeId={offer.product.type}
                      onUpdate={handleDataUpdate}
                      onClickDealButton={
                        offer.dealId && offer.isOwn ? (evt) => handleDealButtonClick(evt, offer.dealId) : undefined
                      }
                    />
                  ))}
                </S.AuctionMobileCardList>
              </Spin>
            ) : (
              <S.AuctionTableWrapper>
                <AuctionOffersTable
                  columns={columns}
                  data={auctionOffers}
                  isLoading={isLoadingAuctionOfferData}
                  isRowExpandable={isRowExpandable}
                  onchangeStatusOnRejected={handleDataUpdate}
                />
              </S.AuctionTableWrapper>
            )}

            {auctionOffersPagination && (
              <Pagination
                current={auctionOffersPagination?.currentPage}
                total={auctionOffersPagination?.total}
                pageSize={auctionOffersPagination?.pageSize}
                pageSizeOptions={pageSizeOptions}
                onShowSizeChange={(x, size) => setPageSize(size)}
                showSizeChanger
                onChange={setCurrentPage}
              />
            )}
          </>
        )}
      </S.Content>
    </>
  )
}
