import styled from 'styled-components'

import { Text } from '~/ui-components'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  margin-bottom: 28px;
  padding: 6px;
`

export const Info = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
`

export const CompanyTypeBadge = styled.div`
  background: var(--gray-7-color);
  color: var(--white-color);
  font-size: 12px;
  line-height: 16px;
  border-radius: 6px;
  padding: 4px 12px;
`

export const StyledName = styled(Text).attrs({ variant: 'normal-bold' })`
  color: var(--gray-8-color);
  overflow-wrap: break-word;
  text-align: center;
`
