import { AdminElevatorDataTypeEnum } from '~api/generated'

export function getElevatorTypeName(v: AdminElevatorDataTypeEnum): string {
  switch (v) {
    case AdminElevatorDataTypeEnum.Transshipment:
      return 'Перевалка'
    case AdminElevatorDataTypeEnum.Port:
      return 'Порт'
    case AdminElevatorDataTypeEnum.Recast:
      return 'Переработка'
  }
}
