import React from 'react'

import { Button, InputNumber } from 'antd'
import { Controller, useForm } from 'react-hook-form'

import { yupResolver } from '@hookform/resolvers/yup/dist/yup'

import { Card, FormField } from '~/ui-components'
import { PriceControl } from '~shared/controls'

import { DealAdditionalServicesAdminFormSchema } from './DealAdditionalServicesAdminFormSchema'

import {
  DealAdditionalServicesAdminFormProps,
  DealAdditionalServicesAdminFormValues,
} from './DealAdditionalServicesAdminForm.types'

import * as S from './DealAdditionalServicesAdminForm.styled'

export const DealAdditionalServicesAdminForm: React.FC<DealAdditionalServicesAdminFormProps> = (props) => {
  const {
    watch,
    control,
    handleSubmit,
    formState: { errors, isDirty },
  } = useForm<DealAdditionalServicesAdminFormValues>({
    defaultValues: {
      distance: props.value.find((x) => x.type === 'SHIPPING')?.distance ?? undefined,
      shippingPrice: props.value.find((x) => x.type === 'SHIPPING')?.price ?? undefined,
      servicePrice: props.value.find((x) => x.type === 'SERVICE')?.price ?? undefined,
    },
    resolver: yupResolver(DealAdditionalServicesAdminFormSchema),
  })
  const handleFormSubmit = (values: DealAdditionalServicesAdminFormValues) => {
    props.onSubmit(values)
  }

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)}>
      <Card>
        <Card.Header>
          <Card.Title>Доп. услуги по сделке</Card.Title>
        </Card.Header>
        <S.Content>
          <S.Subtitle>Организация перевозки агентом</S.Subtitle>
          <S.AgentFormWrapper>
            <S.AgentFormTitle>Откуда</S.AgentFormTitle>
            <S.AgentFormValue>{props.fromAddress}</S.AgentFormValue>

            <S.AgentFormTitle>Куда</S.AgentFormTitle>
            <S.AgentFormValue>{props.toAddress}</S.AgentFormValue>

            <S.AgentFormTitle>Объем</S.AgentFormTitle>
            <S.AgentFormValue>{props.volume} тонн</S.AgentFormValue>
          </S.AgentFormWrapper>

          <S.AgentFormTitle>Рассчитайте стоимость перевозки одной тонны товара и укажите в поле ниже:</S.AgentFormTitle>

          <FormField label="Расстояние" validateMessage={errors.distance?.message} isRequired>
            <Controller
              name="distance"
              control={control}
              render={({ field }) => (
                <InputNumber
                  onChange={field.onChange}
                  value={field.value}
                  placeholder="100"
                  style={{ width: '100%' }}
                  status={errors.distance ? 'error' : undefined}
                  addonAfter="км"
                />
              )}
            />
          </FormField>

          <FormField
            htmlFor="shippingPrice"
            label="Стоимость доставки"
            isRequired
            validateMessage={errors.shippingPrice?.message}
          >
            <Controller
              name="shippingPrice"
              control={control}
              render={({ field }) => <PriceControl id="shippingPrice" placeholder="100" addAfter="₽/т" {...field} />}
            />
          </FormField>

          <S.SubtitleFormItem>Независимый анализ</S.SubtitleFormItem>

          <FormField
            htmlFor="servicePrice"
            label="Стоимость услуги"
            isRequired
            validateMessage={errors.servicePrice?.message}
          >
            <Controller
              name="servicePrice"
              control={control}
              render={({ field }) => <PriceControl id="servicePrice" placeholder="100" addAfter="₽" {...field} />}
            />
          </FormField>
        </S.Content>
        <S.Footer>
          <Button htmlType="button" onClick={() => props.onCancel()}>
            Отмена
          </Button>

          <Button type="primary" htmlType="submit">
            Сохранить
          </Button>
        </S.Footer>
      </Card>
    </form>
  )
}
