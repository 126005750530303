import React, { useState } from 'react'

import { Avatar, Button, Dropdown, Space, Tooltip } from 'antd'
import type { ItemType } from 'antd/es/menu/interface'
import { FiChevronRight, FiCreditCard, FiList, FiLogOut, FiUser } from 'react-icons/fi'
import { Link } from 'react-router-dom'

import { appRoutes } from '~/router'
import { Text } from '~/ui-components'
import { getAbbrCompany } from '~/utils/company/get-abbr-company'
import { getUserRole } from '~/utils/user/getUserRoleOld'
import { UserRole } from '~api/gql-generated/graphql'
import { useGetDevice } from '~hooks/common'
import { useAuth } from '~providers/auth'
import { HelpInfoModal } from '~shared/components'

import * as S from './UserMenu.styled'

interface IUserMenuProps {
  userAvatarUrl?: string | null
  userName?: string | null
  userRole?: UserRole
}

const SIZE = 16

export const UserMenu: React.FC<IUserMenuProps> = ({ userAvatarUrl, userRole, userName }) => {
  const auth = useAuth()
  const device = useGetDevice()
  const isMobile = device === 'mobile'

  const [isShowHelp, setShowHelp] = useState(false)
  // const hasUserName = !!userNameInitials?.trim()

  const menuItems: ItemType[] = [
    {
      label: (
        <Link to={appRoutes.accountProfile.url}>
          <S.UserInfoItemMenuWrapper>
            <Avatar size={32} shape="circle" style={{ backgroundColor: '#ACA7E4' }} src={userAvatarUrl}>
              {getAbbrCompany(userName)}
            </Avatar>
            <Space direction="vertical" size={4}>
              <Text variant="normal-bold">{userName}</Text>

              <S.UserRoleWrapper>{userRole && getUserRole(userRole)}</S.UserRoleWrapper>
            </Space>
            <FiChevronRight size={24} display={'block'} />
          </S.UserInfoItemMenuWrapper>
        </Link>
      ),
      key: appRoutes.accountProfile.url,
    },
    {
      type: 'divider',
    },
    {
      label: <Link to={`${appRoutes.offersMy.url}`}>{appRoutes.offersMy.title}</Link>,
      key: appRoutes.offersMy.url,
      icon: <FiCreditCard size={SIZE} />,
    },
    {
      label: <Link to={appRoutes.accountCompanyProfile.url}>Моя компания</Link>,
      key: appRoutes.accountCompanyProfile.url,
      icon: <FiUser size={SIZE} />,
    },
    {
      label: <Link to={appRoutes.employees.url}>{appRoutes.employees.title}</Link>,
      key: appRoutes.employees.url,
      icon: <FiList size={SIZE} />,
    },
    {
      type: 'divider',
    },
    {
      label: 'Выйти',
      key: 'exit',
      icon: <FiLogOut size={SIZE} color="red" />,
      onClick: auth.logout,
    },
    {
      type: 'divider',
    },
    {
      label: (
        <S.FeedbackItemMenuWrapper>
          <div>
            <Button size="small" type="link" onClick={() => setShowHelp(true)}>
              Помощь и обратная связь
            </Button>
          </div>
          <div>
            <Button
              size="small"
              type="link"
              href="/privacy_policy.pdf"
              target="_blank"
              onClick={(e) => e.stopPropagation()}
            >
              Пользовательское соглашение
            </Button>
          </div>
        </S.FeedbackItemMenuWrapper>
      ),
      key: 'feedback',
      className: 'feedback',
      style: { padding: 0, margin: 0, height: 'auto' },
      onClick: ({ item, key, keyPath, domEvent }) => {
        domEvent.stopPropagation()
        domEvent.preventDefault()
      },
    },
  ]
  return (
    <>
      <Dropdown menu={{ items: menuItems }} trigger={['click']}>
        <Tooltip placement="left" title={userName}>
          <Avatar style={{ cursor: 'pointer' }} src={userAvatarUrl} size={isMobile ? 32 : 46}>
            {getAbbrCompany(userName)}
          </Avatar>
        </Tooltip>
      </Dropdown>
      <HelpInfoModal isOpen={isShowHelp} onClose={() => setShowHelp(false)} />
    </>
  )
}
