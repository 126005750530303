import styled from 'styled-components'

import css from '@styled-system/css'

export const CardTitleWrapper = styled.div(
  css({
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
  })
)
