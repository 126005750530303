import styled from 'styled-components'

import { breakpoints } from '~/tokens'
import { Text } from '~/ui-components'

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 24px;
  padding-top: 0;

  @media (max-width: ${breakpoints.DESKTOP_S}px) {
    padding: 12px;
    padding-top: 0;
  }
`

export const CompanyLIstWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;

  @media (max-width: ${breakpoints.MOBILE_XL}px) {
    gap: 16px;
  }
`
export const FiltersWrapper = styled.div`
  display: grid;
  grid-template-columns: 250px 250px;
  gap: 8px;
  @media (max-width: ${breakpoints.DESKTOP_S}px) {
    grid-template-columns: 1fr;
  }
`

export const AdvertOverviewLIstWrapper = styled.article`
  display: flex !important;
  gap: 8px;
  padding-bottom: 24px;
  @media (max-width: ${breakpoints.DESKTOP_S}px) {
    flex-direction: column;
  }
`
export const CardContentWrapper = styled.div`
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`
export const AdvertsCounter = styled(Text).attrs({ variant: 'normal' })`
  color: var(--gray-6-color);
`

export const CardHeaderTop = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
`
