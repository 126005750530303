import { useQuery, UseQueryResult } from '@tanstack/react-query'

import {
  AdminGetElevatorAdvertResponse,
  ElevatorAdvertApiAdminElevatorAdvertControllerAdminGetElevatorAdvertRequest,
} from '~api/generated'
import { useApi } from '~providers/api'

export const useGetElevatorAdvertAdmin = (
  request: ElevatorAdvertApiAdminElevatorAdvertControllerAdminGetElevatorAdvertRequest
): UseQueryResult<AdminGetElevatorAdvertResponse> => {
  const api = useApi()

  const fetchFn = async (): Promise<AdminGetElevatorAdvertResponse> => {
    const res = await api.elevatorAdvert.adminElevatorAdvertControllerAdminGetElevatorAdvert(request)

    return res.data
  }

  return useQuery(['elevatorAdvertAdmin', request.elevatorAdvertId], fetchFn, { enabled: !!request.elevatorAdvertId })
}
