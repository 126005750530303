import React from 'react'

import { Flex, Table, Tooltip } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import { FiPlus } from 'react-icons/fi'

import { Text } from '~/ui-components'
import { showColumn } from '~/utils'
import { ElevatorAdvertListData, ElevatorProductTypeData, ProductParameterData } from '~api/generated'
import { useGetCurrentRole } from '~hooks/auth'
import { actionArrowColumn, ndsColumn, priceColumn } from '~shared/columns/shared'

import * as S from '~pages/BasisPage/modules/BasisAdvert/BasisAdvertsByProductType/BasisAdvertsByProductType.styled'

interface BasisAdvertsByProductTypeTableProps {
  productTypeData: ElevatorProductTypeData
  adverts: ElevatorAdvertListData[] | undefined
  isLoading: boolean
  onActionArrowClick: (record: ElevatorAdvertListData) => void
  onMakeOffer: (record: ElevatorAdvertListData) => void
}

export function BasisAdvertsByProductTypeTable({
  productTypeData,
  adverts,
  isLoading,
  onActionArrowClick,
  onMakeOffer,
}: BasisAdvertsByProductTypeTableProps) {
  const {
    company: { isSeller, isTrader },
  } = useGetCurrentRole()

  const uniqParams =
    adverts
      ?.flatMap((x) => x.product.parameters)
      .filter((el, idx, list) => list.findIndex((e) => e.type === el.type) === idx) ?? []

  const col = uniqParams?.map((k) => ({
    title: k.name,
    dataIndex: ['product', 'parameters'],
    width: '160px !important',
    render: (z: ProductParameterData[]) => {
      const parameter = z.find((x) => x.name === k.name)
      if (!parameter) {
        return null
      }

      const isYear = parameter.type === 'crop_year'
      const value = Number(parameter.value).toFixed(isYear ? 0 : 1)
      const measurementUnit =
        parameter.measurementUnit === '%'
          ? `${parameter.measurementUnit}`
          : parameter.measurementUnit === null
          ? ''
          : ` ${parameter.measurementUnit}`

      return `${value}${measurementUnit}`
    },
  }))

  const columns: ColumnsType<ElevatorAdvertListData> = [
    {
      title: '№',
      dataIndex: 'id',
      width: 60,
      fixed: 'left',
    },
    { ...priceColumn, dataIndex: 'pricePerTon', fixed: 'left' },
    { ...ndsColumn, dataIndex: 'usingNds', fixed: 'left' },
    {
      title: 'Компания',
      width: 300,
      dataIndex: ['company', 'name'],
      fixed: 'left',
    },
    ...(col as any),
    ...showColumn<ElevatorAdvertListData>(isSeller || isTrader, {
      title: '',
      dataIndex: 'addNew',
      key: 'action',
      width: 56,
      fixed: 'right',
      render: () => {
        return (
          <Tooltip title="Предложить объем">
            <FiPlus size={24} display={'block'} color={'var(--primary-main-color)'} />
          </Tooltip>
        )
      },
      onCell: (record) => ({
        onClick: (e) => {
          e.stopPropagation()
          onMakeOffer?.(record)
        },
      }),
    }),
    { ...actionArrowColumn, fixed: 'right', onCell: (record) => ({ onClick: () => onActionArrowClick?.(record) }) },
  ]

  const handleRowClick = (record: ElevatorAdvertListData) => {
    onActionArrowClick?.(record)
  }

  return (
    <Flex vertical gap={16}>
      <S.TitleWrapperInner>
        <Text variant="h4">{productTypeData.name}</Text>
        <S.CountWrapper>{adverts?.length}</S.CountWrapper>
      </S.TitleWrapperInner>

      <Table
        rowKey="type"
        className="table-interactive"
        loading={isLoading}
        columns={columns}
        dataSource={adverts}
        scroll={{ x: 'max-content' }}
        pagination={false}
        onRow={(record) => ({
          onClick: handleRowClick.bind(null, record),
        })}
      />
    </Flex>
  )
}
