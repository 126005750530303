import React, { useState } from 'react'

import { Avatar, Button, Space } from 'antd'
import { observer } from 'mobx-react-lite'
import {
  FiBriefcase,
  FiCheckCircle,
  FiChevronRight,
  FiCreditCard,
  FiEdit2,
  FiFile,
  FiLayers,
  FiList,
  FiLogOut,
  FiMapPin,
  FiMessageCircle,
  FiTruck,
  FiUser,
} from 'react-icons/fi'
import { RiAuctionLine } from 'react-icons/ri'
import { Link, useOutlet } from 'react-router-dom'

import { useMutation, useQuery } from '@apollo/client'

import { appRoutes } from '~/router'
import { Text } from '~/ui-components'
import { getAbbrCompany } from '~/utils/company/get-abbr-company'
import { UserStateFieldName } from '~api/gql-generated/graphql'
import { updateMeStateMutation } from '~api/gql-mutations/me.mutation.graphql'
import { getCompanyDataQuery } from '~api/gql-query/company.query.graphql'
import { getMeQuery, getMeState } from '~api/gql-query/me.query.graphql'
import { ReactComponent as BasisIcon } from '~assets/icons/basis.svg'
import { AccountMenuChatSupport } from '~pages/AccountPage/components/AccountMenuMobile/components/AccountMenuChatSupport'
import { AccountMenuMobileNavLink } from '~pages/AccountPage/components/AccountMenuMobile/components/AccountMenuMobileNavLink'
import { AccountMenuMobileTour } from '~pages/AccountPage/components/AccountMenuMobile/components/AccountMenuMobileTour'
import { CompanyVerificationStatusInfo } from '~pages/AccountPage/components/CompanyVerificationStatusInfo'
import { useAuth } from '~providers/auth'
import { HelpInfoModal } from '~shared/components'

import * as S from './AccountMenuMobile.styled'

const ICON_SIZE = 24

export const AccountMenuMobile: React.FC = observer(() => {
  const auth = useAuth()
  const getMeFn = useQuery(getMeQuery)
  const getMeStateFn = useQuery(getMeState)
  const [updateMeState] = useMutation(updateMeStateMutation, {
    refetchQueries: [getMeState],
  })

  const currentCompanyFn = useQuery(getCompanyDataQuery, {
    variables: { companyId: auth.companyId },
  })

  const meData = getMeFn.data?.me
  const currentCompany = currentCompanyFn.data?.company

  const outlet = useOutlet()

  const isOpenTour = getMeStateFn?.data?.me.state.multiCompanyProfileGuideShown ?? true

  const [isShowHelp, setShowHelp] = useState(false)

  const onCloseTour = async () => {
    await updateMeState({
      variables: {
        input: {
          fieldName: UserStateFieldName.MultiCompanyProfileGuideShown,
          value: String(true),
        },
      },
    })
  }

  if (outlet) {
    return outlet
  }

  return (
    <S.StyledNav>
      <AccountMenuMobileTour isOpen={!isOpenTour} onCancel={onCloseTour} />
      <S.Title>Профиль</S.Title>

      <CompanyVerificationStatusInfo isMobile />

      <Space size={16}>
        <Avatar size={70} shape="circle" style={{ backgroundColor: '#ACA7E4' }} src={meData?.avatarUrl}>
          {getAbbrCompany(meData?.name)}
        </Avatar>
        <Space direction="vertical" size={6}>
          <Text variant="normal-bold">{meData?.name}</Text>
          <Text variant="normal">{meData?.phoneNumber}</Text>
          <Button type="link">
            <Link to={appRoutes.accountProfile.url}>
              <FiEdit2 size={16} />
              &nbsp; Редактировать личные данные
            </Link>
          </Button>
        </Space>
      </Space>

      <S.StyledDivider />

      <S.SectionName>КОМПАНИЯ</S.SectionName>
      <AccountMenuMobileNavLink url={appRoutes.accountCompanyMobileMenu.url}>
        <S.CompanyInfoWrapper>
          <FiUser size={ICON_SIZE} />
          {appRoutes.accountCompanyMobileMenu.title}
          <S.CompanyNameWrapper>{currentCompany?.legalName}</S.CompanyNameWrapper>
        </S.CompanyInfoWrapper>
        <FiChevronRight size={24} />
      </AccountMenuMobileNavLink>

      <AccountMenuMobileNavLink
        text={appRoutes.warehouses.title}
        icon={<FiMapPin size={ICON_SIZE} />}
        url={appRoutes.warehouses.url}
      />
      <AccountMenuMobileNavLink
        text={appRoutes.requests.title}
        icon={<FiLayers size={ICON_SIZE} />}
        url={appRoutes.requests.url}
      />
      <AccountMenuMobileNavLink
        text={appRoutes.deals.title}
        icon={<FiBriefcase size={ICON_SIZE} />}
        url={appRoutes.deals.url}
      />

      <S.StyledDivider />

      <S.SectionName>ПРЕДЛОЖЕНИЯ</S.SectionName>
      <AccountMenuMobileNavLink
        text={appRoutes.offersMy.title}
        icon={<FiCreditCard size={ICON_SIZE} />}
        url={appRoutes.offersMy.url}
      />
      <AccountMenuMobileNavLink
        text={appRoutes.offersResponses.title}
        icon={<FiCheckCircle size={ICON_SIZE} />}
        url={appRoutes.offersResponses.url}
      />

      <S.StyledDivider />
      <S.SectionName>БАЗИСЫ</S.SectionName>

      <AccountMenuMobileNavLink
        text={appRoutes.basisMyAdvert.title}
        icon={<BasisIcon style={{ width: ICON_SIZE, height: ICON_SIZE }} />}
        url={appRoutes.basisMyAdvert.url}
      />
      <AccountMenuMobileNavLink
        text={appRoutes.basisMyBids.title}
        icon={<FiCheckCircle size={ICON_SIZE} />}
        url={appRoutes.basisMyBids.url}
      />

      <S.StyledDivider />
      <S.SectionName>ЛОГИСТИКА</S.SectionName>
      <AccountMenuMobileNavLink
        text={appRoutes.logisticMyCargos.title}
        icon={<FiTruck size={ICON_SIZE} />}
        url={appRoutes.logisticMyCargos.url}
      />
      <AccountMenuMobileNavLink
        text={appRoutes.logisticResponses.title}
        icon={<FiTruck size={ICON_SIZE} />}
        url={appRoutes.logisticResponses.url}
      />
      <AccountMenuMobileNavLink
        text={appRoutes.logisticDeals.title}
        icon={<FiTruck size={ICON_SIZE} />}
        url={appRoutes.logisticDeals.url}
      />

      <S.StyledDivider />
      <S.SectionName>ТОРГИ</S.SectionName>

      <AccountMenuMobileNavLink
        text={appRoutes.auctionsMyLots.title}
        icon={<RiAuctionLine size={ICON_SIZE} />}
        url={appRoutes.auctionsMyLots.url}
      />
      <AccountMenuMobileNavLink
        text={appRoutes.auctionsMyResponses.title}
        icon={<FiCheckCircle size={ICON_SIZE} />}
        url={appRoutes.auctionsMyResponses.url}
      />

      <S.StyledDivider />
      <S.SectionName>ДРУГОЕ</S.SectionName>

      <AccountMenuMobileNavLink
        text={appRoutes.employees.title}
        icon={<FiList size={ICON_SIZE} />}
        url={appRoutes.employees.url}
      />

      <AccountMenuMobileNavLink
        url="/help-info"
        onClick={(e) => {
          e.preventDefault()
          e.stopPropagation()
          setShowHelp(true)
        }}
      >
        <Space size={18}>
          <FiMessageCircle size={ICON_SIZE} />
          Помощь и обратная связь
        </Space>
      </AccountMenuMobileNavLink>

      <AccountMenuMobileNavLink url="/privacy_policy.pdf" target="_blank">
        <Space size={18}>
          <FiFile size={ICON_SIZE} />
          Пользовательское соглашение
        </Space>
      </AccountMenuMobileNavLink>

      <AccountMenuMobileNavLink
        text="Выйти"
        icon={<FiLogOut size={ICON_SIZE} color="red" />}
        url={appRoutes.auth.url}
        onClick={(e) => {
          e.preventDefault()
          void auth.logout()
        }}
      />

      <AccountMenuChatSupport />

      <HelpInfoModal isOpen={isShowHelp} onClose={() => setShowHelp(false)} />
    </S.StyledNav>
  )
})
