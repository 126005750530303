import axios from 'axios'

export function isApiResponseEmpty(error: any): boolean {
  if (axios.isAxiosError(error)) {
    switch (error.response?.status) {
      case 404:
      case 401:
        return true
      default:
        return false
    }
  }

  return false
}
