import React from 'react'

import { Divider } from 'antd'
import { observer } from 'mobx-react-lite'

import { appRoutes } from '~/router'
import { userUiSettingsStore } from '~/store/userUiSettingsStore'
import { ReactComponent as LogoZa } from '~assets/icons/logo-za.svg'
import logoUrl from '~assets/images/logo.svg'
import { AppMenuItems } from '~layout/AppMenuItems'
import { CompanyVerificationStatusInfo } from '~pages/AccountPage/components/CompanyVerificationStatusInfo'
import { LoginAsAdminBack, UserCompanySelect } from '~shared/components'

import * as S from './AppMenu.styled'

export const AppMenu: React.FC = observer(() => {
  // const onCollapseMenuHandler = () => userUiSettingsStore.toggleCollapseAppMenu()
  const isCollapsed = userUiSettingsStore.isAppMenuCollapsed

  return (
    <S.MenuWrapper>
      {!isCollapsed && (
        <S.LogoWrapper>
          <S.LogoLink to={appRoutes.welcomePage.url}>
            <S.LogoImg src={logoUrl} alt="logo" />
          </S.LogoLink>
        </S.LogoWrapper>
      )}

      <S.LogoWrapperIpad>
        <S.LogoLink to={appRoutes.welcomePage.url}>
          <LogoZa />
        </S.LogoLink>
      </S.LogoWrapperIpad>

      <S.NavWrapper>
        <AppMenuItems />
      </S.NavWrapper>

      <S.MenuBottomAction>
        <CompanyVerificationStatusInfo isMenu />
        <Divider />
        <UserCompanySelect />
        <LoginAsAdminBack />
      </S.MenuBottomAction>
    </S.MenuWrapper>
  )
})
