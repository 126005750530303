import React, { useCallback, useEffect } from 'react'

import { Button, Divider, Flex, InputNumber, Radio, Spin } from 'antd'
import { debounce } from 'lodash'
import { Controller, useForm } from 'react-hook-form'
import * as yup from 'yup'

import { yupResolver } from '@hookform/resolvers/yup'

import { AppConfig } from '~/appConfig'
import { FormField } from '~/ui-components'
import { inputSharedSettings } from '~/utils/inputSharedSettings'
import { FreightPaymentTerm } from '~api/gql-generated/graphql'
import { CultureSelectControlV2, RangeDatePickerControl, RegionsSelectControl, UsingNdsControl } from '~shared/controls'

import * as S from '~pages/OffersPage/components/OffersFilter/components/OffersFilterForm/OffersFilterForm.styled'

const transformEmptyNumberFn = (value?: number) => {
  if (!value) {
    return undefined
  }
  return isNaN(value) ? undefined : value
}

const cargoFiltersSchema = yup.object({
  period: yup.array().length(2).of(yup.string().required()),
  productTypeId: yup.number(),
  fromRegionId: yup.number(),
  toRegionId: yup.number(),
  paymentTerm: yup.mixed<FreightPaymentTerm>().oneOf(Object.values(FreightPaymentTerm)).nullable(),
  weightKgFrom: yup.number().transform(transformEmptyNumberFn),
  weightKgTo: yup
    .number()
    .transform(transformEmptyNumberFn)
    .test({
      name: 'is-greater',
      message: 'Значение меньше чем в поле "От"',
      test: (value, ctx) => !value || !ctx.parent.weightKgFrom || value > ctx.parent.weightKgFrom,
    }),
  pricePerTonFrom: yup.number().transform(transformEmptyNumberFn),
  pricePerTonTo: yup
    .number()
    .transform(transformEmptyNumberFn)
    .test({
      name: 'is-greater',
      message: 'Значение меньше чем в поле "От"',
      test: (value, ctx) => !value || !ctx.parent.pricePerTonFrom || value > ctx.parent.pricePerTonFrom,
    }),
  priceWithNds: yup.boolean(),
})

export type CargoFiltersFormType = yup.InferType<typeof cargoFiltersSchema>

interface CargoFiltersFormProps {
  foundResults: number | null
  loading: boolean
  onChange: (v: CargoFiltersFormType) => void
  onSubmit: (v: CargoFiltersFormType) => void
  defaultValues: CargoFiltersFormType
  currentValues: CargoFiltersFormType
  onResetToDefaultValues: () => void
}

export const CargoFiltersForm: React.FC<CargoFiltersFormProps> = (props) => {
  const {
    watch,
    control,
    handleSubmit,
    reset,
    getValues,
    formState: { errors },
  } = useForm<CargoFiltersFormType>({
    resolver: yupResolver(cargoFiltersSchema),
    defaultValues: {
      productTypeId: props.currentValues.productTypeId,
      fromRegionId: props.currentValues.fromRegionId,
      toRegionId: props.currentValues.toRegionId,
      weightKgFrom: props.currentValues.weightKgFrom,
      weightKgTo: props.currentValues.weightKgTo,
      pricePerTonFrom: props.currentValues.pricePerTonFrom,
      pricePerTonTo: props.currentValues.pricePerTonTo,
      priceWithNds: props.currentValues.priceWithNds,
      paymentTerm: props.currentValues.paymentTerm,
      period: props.currentValues.period,
    },
  })

  const watchedFields = watch()

  const debouncedChangeValues = useCallback(
    debounce(() => props.onChange(getValues()), AppConfig.DEFAULT_DEBOUNCE_TIME),
    []
  )

  useEffect(() => {
    debouncedChangeValues()
  }, [
    watchedFields.weightKgFrom,
    watchedFields.weightKgTo,
    watchedFields.pricePerTonFrom,
    watchedFields.pricePerTonTo,
    watchedFields.priceWithNds,
    watchedFields.productTypeId,
    watchedFields.fromRegionId,
    watchedFields.toRegionId,
    watchedFields.paymentTerm,
    watchedFields.period,
  ])

  const onSubmitHandler = (data: CargoFiltersFormType) => props.onSubmit(data)
  const onResetFormHandler = () => {
    reset(props.defaultValues)
    props.onSubmit(props.defaultValues)
    props.onResetToDefaultValues()
  }

  return (
    <form onSubmit={handleSubmit(onSubmitHandler)}>
      <FormField label="Дата погрузки" validateMessage={errors.period?.message}>
        <Controller
          name="period"
          control={control}
          render={({ field }) => (
            <RangeDatePickerControl
              value={field.value ? [new Date(field.value[0]), new Date(field.value[1])] : [null, null]}
              onChange={(x) => field.onChange(x?.length ? [x[0]?.toISOString(), x[1]?.toISOString()] : undefined)}
              id="period"
              hasError={!!errors.period}
              // datePickerProps={{
              //   disabledDate: (current) => isBefore(current, sub(new Date(), { days: 1 })),
              // }}
            />
          )}
        />
      </FormField>

      <FormField label="Откуда" htmlFor="fromRegionId" validateMessage={errors.fromRegionId?.message}>
        <Controller
          name="fromRegionId"
          control={control}
          render={({ field }) => <RegionsSelectControl mode="single" {...field} />}
        />
      </FormField>

      <FormField label="Куда" htmlFor="toRegionId" validateMessage={errors.toRegionId?.message}>
        <Controller
          name="toRegionId"
          control={control}
          render={({ field }) => <RegionsSelectControl mode="single" {...field} />}
        />
      </FormField>

      <FormField label="Груз" validateMessage={errors.productTypeId?.message}>
        <Controller
          name="productTypeId"
          control={control}
          render={({ field }) => <CultureSelectControlV2 mode="single" {...field} />}
        />
      </FormField>

      <FormField label="Вес" validateMessage={errors.weightKgFrom?.message || errors.weightKgTo?.message}>
        <Flex gap={2} align="center">
          <Controller
            name="weightKgFrom"
            control={control}
            render={({ field }) => (
              <InputNumber
                {...field}
                {...inputSharedSettings}
                precision={0}
                step={1}
                style={{ width: '100%' }}
                addonAfter="т"
                id="weightKgFrom"
                placeholder="От"
                status={errors.weightKgFrom && 'error'}
              />
            )}
          />
          -
          <Controller
            name="weightKgTo"
            control={control}
            render={({ field }) => (
              <InputNumber
                {...field}
                {...inputSharedSettings}
                precision={0}
                step={1}
                style={{ width: '100%' }}
                addonAfter="т"
                id="weightKgTo"
                placeholder="До"
                status={errors.weightKgTo && 'error'}
              />
            )}
          />
        </Flex>
      </FormField>

      <FormField label="Ставка" validateMessage={errors.pricePerTonFrom?.message || errors.pricePerTonTo?.message}>
        <Flex vertical gap={6}>
          <Flex gap={2} align="center">
            <Controller
              name="pricePerTonFrom"
              control={control}
              render={({ field }) => (
                <InputNumber
                  {...field}
                  {...inputSharedSettings}
                  precision={0}
                  step={100}
                  style={{ width: '100%' }}
                  addonAfter="₽/т"
                  id="pricePerTonFrom"
                  placeholder="От"
                  status={errors.pricePerTonFrom && 'error'}
                />
              )}
            />
            -
            <Controller
              name="pricePerTonTo"
              control={control}
              render={({ field }) => (
                <InputNumber
                  {...field}
                  {...inputSharedSettings}
                  precision={0}
                  step={100}
                  style={{ width: '100%' }}
                  addonAfter="₽/т"
                  id="pricePerTonTo"
                  placeholder="До"
                  status={errors.pricePerTonTo && 'error'}
                />
              )}
            />
          </Flex>

          <Controller name="priceWithNds" control={control} render={({ field }) => <UsingNdsControl {...field} />} />
        </Flex>
      </FormField>

      <FormField label="Условия оплаты" validateMessage={errors.paymentTerm?.message}>
        <Controller
          name="paymentTerm"
          control={control}
          render={({ field }) => (
            <Radio.Group onChange={(e) => field.onChange(e.target.value)} value={field.value}>
              <Radio value={FreightPaymentTerm.Deferred}>Отсрочка</Radio>
              <Radio value={FreightPaymentTerm.Advance}>Предоплата</Radio>
            </Radio.Group>
          )}
        />
      </FormField>

      <Divider />
      {props.foundResults != null && (
        <Spin spinning={props.loading}>
          <S.FoundResultWrapper>Мы нашли {props.foundResults} предложений по выбранным параметрам</S.FoundResultWrapper>
        </Spin>
      )}
      <S.ActionFooterWrapper>
        <Button htmlType="button" onClick={onResetFormHandler}>
          Сбросить все
        </Button>
        <Button htmlType="submit" type="primary">
          Показать
        </Button>
      </S.ActionFooterWrapper>
    </form>
  )
}
