import { codeUnreachable } from '~/utils/code-unreachable'
import { DayOfWeek } from '~api/generated'

export function getDayOfWeek(v: DayOfWeek) {
  switch (v) {
    case DayOfWeek.Monday:
      return 'Пн'
    case DayOfWeek.Tuesday:
      return 'Вт'
    case DayOfWeek.Wednesday:
      return 'Ср'
    case DayOfWeek.Thursday:
      return 'Чт'
    case DayOfWeek.Friday:
      return 'Пт'
    case DayOfWeek.Saturday:
      return 'Сб'
    case DayOfWeek.Sunday:
      return 'Вс'
    default:
      return codeUnreachable(v)
  }
}
