import { graphql } from '~api/gql-generated'

export const freightDealDocumentsQuery = graphql(/* GraphQL */ `
  query FreightDealDocuments($freightDealId: Int!, $filter: FreightDealDocumentFilterInput) {
    freightDealDocuments(freightDealId: $freightDealId, filter: $filter) {
      type
      instances {
        id
        status
        createdAt
        number
        formedAt
        rejectionNote
        files {
          id
          status
          name
          size
          mimetype
          source
        }
      }
    }
  }
`)

export const freightDealDocumentsArchiveQuery = graphql(/* GraphQL */ `
  query FreightDealDocumentsArchive($freightDealId: Int!, $filter: DocumentsArchiveFilterInput!) {
    freightDealDocumentsArchive(freightDealId: $freightDealId, filter: $filter) {
      name
      size
      mimetype
      source
    }
  }
`)
