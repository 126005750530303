import React from 'react'

import { Button, Table, Tooltip } from 'antd'
import { ColumnsType } from 'antd/lib/table'

import { useGetTableHeight } from '~/hooks'
import { Badge } from '~/ui-components'
import { formatNumber } from '~/utils/formatNumber'
import { getDateTimeFormat, getTimeDurationFormat } from '~/utils/getDateFormat'
import { getOfferStatus } from '~/utils/offers/get-offer-status'
import { isOfferExpireSoon } from '~/utils/offers/is-offer-expire-soon'
import { BaseUserData, OfferDataStatusEnum, UserData } from '~api/generated'
import { actionArrowColumn } from '~shared/columns/shared'

import { IModeratorOfferListTableProps, IOfferSortType, OfferListTableDataType } from './ModeratorOfferListTable.types'

import * as S from './ModeratorOfferListTable.styled'

export const ModeratorOfferListTable: React.FC<IModeratorOfferListTableProps> = ({
  tableData,
  loading,
  takeOfferToWork,
  onRowClick,
  onSortingChange,
}) => {
  const tableHeight = useGetTableHeight(294)
  const onRowClickHandler = (record: OfferListTableDataType) => {
    onRowClick(record)
  }

  const takeOfferToWorkHandler = (e: React.MouseEvent, offerId: number) => {
    e.stopPropagation()
    takeOfferToWork(offerId)
  }

  const columns: ColumnsType<OfferListTableDataType> = [
    {
      title: '№',
      dataIndex: 'id',
      key: 'id',
      ellipsis: true,
      sorter: true,
      defaultSortOrder: 'descend',
    },
    {
      title: 'Статус предложения',
      dataIndex: 'status',
      sorter: true,
      render: (status) => {
        return (
          <Badge size="s" color={getOfferStatus(status).color}>
            {getOfferStatus(status).name}
          </Badge>
        )
      },
    },
    {
      title: 'Культура',
      dataIndex: 'culture',
      key: 'culture',
    },
    {
      title: 'Объем, тонн',
      dataIndex: 'volume',
      key: 'volume',
      width: '110px',
      ellipsis: true,
      sorter: true,
    },
    {
      title: 'Цена, ₽',
      dataIndex: 'price',
      key: 'price',
      ellipsis: true,
      width: '110px',
      render: (value) => formatNumber(value),
      sorter: true,
    },
    {
      title: 'Наименование компании',
      dataIndex: 'companyName',
      key: 'companyName',
      ellipsis: true,
    },
    {
      title: 'Дата создания',
      dataIndex: 'createdAt',
      key: 'createdAt',
      ellipsis: true,
      sorter: true,
    },
    {
      title: 'До снятия, ч',
      dataIndex: 'expiredAt',
      key: 'expiredAt',
      ellipsis: true,
      sorter: true,
      render: (expiredAt: Date | null) => {
        if (!expiredAt) {
          return '-'
        }
        const dateTimeFormatted = getTimeDurationFormat(new Date(), expiredAt)
        if (dateTimeFormatted === -1) {
          return (
            <Tooltip title="Снято с публикации">
              <Badge color="red">{getDateTimeFormat(expiredAt)}</Badge>
            </Tooltip>
          )
        }
        return (
          <Tooltip title={getDateTimeFormat(expiredAt)}>
            {isOfferExpireSoon(expiredAt) ? <b>{dateTimeFormatted}</b> : dateTimeFormatted}
          </Tooltip>
        )
      },
    },
    {
      title: 'Создал предложение',
      dataIndex: 'createdBy',
      key: 'createdBy',
      ellipsis: true,
      width: 160,
      render: (createdBy: BaseUserData | null) => {
        return createdBy?.role ? (
          createdBy.role === 'ADMIN' ? (
            'Администратор'
          ) : (
            <Tooltip title={createdBy.name}>Пользователь</Tooltip>
          )
        ) : (
          '-'
        )
      },
    },
    {
      title: 'Модератор',
      dataIndex: 'moderator',
      key: 'moderator',
      width: '15%',
      render: (moderator: UserData, record: OfferListTableDataType) => {
        if (moderator) {
          return moderator.name
        }

        if (record.status === OfferDataStatusEnum.OnModeration) {
          return (
            <Button type="primary" onClick={(event) => takeOfferToWorkHandler(event, record.id)}>
              Взять в работу
            </Button>
          )
        }
      },
      ellipsis: true,
    },
    actionArrowColumn,
  ]

  return (
    <S.StyledTable>
      <Table
        rowKey="id"
        rowClassName={(record) => {
          if (record.expiredAt && isOfferExpireSoon(record.expiredAt)) {
            return 'expiration-alert'
          }
          return ''
        }}
        className="table-interactive"
        loading={loading}
        columns={columns}
        dataSource={tableData}
        scroll={{ y: tableHeight, x: 'max-content' }}
        pagination={false}
        onChange={(pagination, filters, sorter) => {
          if (Array.isArray(sorter)) {
            return
          }
          const { column, order } = sorter
          onSortingChange({
            orderBy: column?.dataIndex as IOfferSortType['orderBy'],
            order: order === 'ascend' ? 'asc' : order === 'descend' ? 'desc' : undefined,
          })
        }}
        onRow={(record, index) => ({
          onClick: onRowClickHandler.bind(null, record, index),
        })}
      />
    </S.StyledTable>
  )
}
