import { makeAutoObservable } from 'mobx'

class ModeratorCompaniesFilterStore {
  public idSearchQuery = ''
  public innSearchQuery = ''
  public companyName = ''

  constructor() {
    makeAutoObservable(this)
  }

  setIdSearchQuery(searchQuery: string) {
    this.idSearchQuery = searchQuery
  }

  setInnSearchQuery(searchQuery: string) {
    this.innSearchQuery = searchQuery
  }

  setCompanyNameQuery(searchQuery: string) {
    this.companyName = searchQuery
  }
}

export const moderatorCompaniesFilterStore = new ModeratorCompaniesFilterStore()
