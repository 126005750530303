import { codeUnreachable } from '~/utils/code-unreachable'
import { OwnershipType } from '~api/gql-generated/graphql'

export function getOwnershipTypeName(ownershipType: OwnershipType): string {
  switch (ownershipType) {
    case OwnershipType.Own:
      return 'Личное ТС'
    case OwnershipType.Rent:
      return 'Аренда'
    case OwnershipType.Leasing:
      return 'Лизинг'
    default:
      return codeUnreachable(ownershipType)
  }
}
