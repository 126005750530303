import React from 'react'

import { Radio, RadioChangeEvent, Space } from 'antd'

import { CustomControlInterface } from '~shared/controls/CustomControl.interface'

interface IUsingNdsControlProps extends CustomControlInterface {
  onChange: (value: boolean) => void
  value: boolean | undefined
}

export const UsingNdsControl: React.FC<IUsingNdsControlProps> = React.forwardRef<HTMLDivElement, IUsingNdsControlProps>(
  ({ value, onChange }, ref) => {
    const onChangeHandler = (e: RadioChangeEvent) => {
      onChange(e.target.value)
    }

    return (
      <Radio.Group onChange={onChangeHandler} value={value} ref={ref}>
        <Space direction="horizontal">
          <Radio value={false}>без НДС</Radio>
          <Radio value={true}>с НДС</Radio>
        </Space>
      </Radio.Group>
    )
  }
)

UsingNdsControl.displayName = 'UsingNdsControl'
