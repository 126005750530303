/* tslint:disable */
/* eslint-disable */
/**
 * API Зерно-Агрегатора
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface UpdateAuctionStatusBody
 */
export interface UpdateAuctionStatusBody {
    /**
     * Новый статус торгов
     * @type {string}
     * @memberof UpdateAuctionStatusBody
     */
    'status': UpdateAuctionStatusBodyStatusEnum;
}

export const UpdateAuctionStatusBodyStatusEnum = {
    OnModeration: 'ON_MODERATION',
    PreStarted: 'PRE_STARTED',
    InProgress: 'IN_PROGRESS',
    Expired: 'EXPIRED',
    Rejected: 'REJECTED',
    Realised: 'REALISED'
} as const;

export type UpdateAuctionStatusBodyStatusEnum = typeof UpdateAuctionStatusBodyStatusEnum[keyof typeof UpdateAuctionStatusBodyStatusEnum];


