import { codeUnreachable } from '~/utils/code-unreachable'
import { FreightPaymentTerm } from '~api/gql-generated/graphql'

export function getFreightPaymentTerm(paymentTerm: FreightPaymentTerm) {
  switch (paymentTerm) {
    case FreightPaymentTerm.Advance:
      return 'Предоплата'
    case FreightPaymentTerm.Deferred:
      return 'Отсрочка'
    default:
      return codeUnreachable(paymentTerm)
  }
}
