import React from 'react'

import { Table } from 'antd'
import { ColumnsType } from 'antd/lib/table'

import { useGetTableHeight } from '~/hooks'
import { getElevatorTypeName } from '~/utils/elevators/get-elevator-type-name'
import { AdminElevatorDataTypeEnum } from '~api/generated'
import { actionArrowColumn } from '~shared/columns/shared'

import {
  ElevatorListTableDataType,
  IElevatorSortType,
  IModeratorElevatorListTableProps,
} from './ElevatorsListTable.types'

import * as S from './ElevatorsListTable.styled'

export const ElevatorsListTable: React.FC<IModeratorElevatorListTableProps> = ({
  tableData,
  loading,
  onRowClick,
  onSortingChange,
}) => {
  const tableHeight = useGetTableHeight(294)
  const onRowClickHandler = (record: ElevatorListTableDataType) => {
    onRowClick?.(record)
  }

  const columns: ColumnsType<ElevatorListTableDataType> = [
    {
      title: '№',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Название элеватора',
      dataIndex: 'name',
      key: 'name',
      ellipsis: true,
    },
    {
      title: 'Адрес элеватора',
      dataIndex: 'address',
      key: 'address',
      ellipsis: false,
      width: 400,
    },
    {
      title: 'Тип элеватора',
      dataIndex: 'type',
      key: 'type',
      render: (v: AdminElevatorDataTypeEnum) => getElevatorTypeName(v),
    },
    {
      title: 'Весы',
      dataIndex: 'hasScales',
      key: 'hasScales',
      render: (hasScales: boolean) => (hasScales ? 'Есть' : 'Нет'),
    },
    {
      title: 'Тоннаж весов, т',
      dataIndex: 'scalesWeightLimit',
      key: 'scalesWeightLimit',
      width: 85,
    },

    {
      title: 'Длина весов, м',
      dataIndex: 'scalesLength',
      key: 'scalesLength',
      width: 85,
    },
    {
      title: 'Высота ворот, м',
      dataIndex: 'gateHeight',
      key: 'gateHeight',
      width: 85,
    },
    actionArrowColumn,
  ]

  return (
    <S.StyledTable>
      <Table
        rowKey="id"
        rowClassName={(record) => {
          if (record.includeDeleted) {
            return 'deleted'
          }
          if (record.isCreationRequest) {
            return 'isCreationRequest'
          }
          return ''
        }}
        className="table-interactive"
        loading={loading}
        columns={columns}
        dataSource={tableData}
        scroll={{ y: tableHeight, x: 'max-content' }}
        pagination={false}
        onChange={(pagination, filters, sorter) => {
          if (Array.isArray(sorter)) {
            return
          }
          const { column, order } = sorter
          onSortingChange?.({
            orderBy: column?.dataIndex as IElevatorSortType['orderBy'],
            order: order === 'ascend' ? 'asc' : order === 'descend' ? 'desc' : undefined,
          })
        }}
        onRow={(record, index) => ({
          onClick: onRowClickHandler.bind(null, record, index),
        })}
      />
    </S.StyledTable>
  )
}
