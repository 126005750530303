import { graphql } from '~api/gql-generated'

export const getFreightOrderDocumentsQuery = graphql(/* GraphQL */ `
  query FreightOrderDocuments($freightOrderId: Int!, $filter: FreightOrderDocumentFilterInput) {
    freightOrderDocuments(freightOrderId: $freightOrderId, filter: $filter) {
      type
      instances {
        status
        rejectionNote
        number
        id
        formedAt
        files {
          name
          size
          mimetype
          source
          id
          status
        }
        createdAt
      }
    }
  }
`)

export const freightOrderDocumentsArchiveQuery = graphql(/* GraphQL */ `
  query FreightOrderDocumentsArchive($freightOrderId: Int!, $filter: DocumentsArchiveFilterInput) {
    freightOrderDocumentsArchive(freightOrderId: $freightOrderId, filter: $filter) {
      name
      size
      mimetype
      source
    }
  }
`)
