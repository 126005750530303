import styled from 'styled-components'

import { breakpoints } from '~/tokens'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px;

  @media (max-width: ${breakpoints.MOBILE_XL}px) {
    padding: 16px;
  }
`

export const ContentWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 32px;
  @media (max-width: ${breakpoints.MOBILE_XL}px) {
    gap: 24px;
  }
`

export const FormReadItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
`
