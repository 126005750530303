import styled from 'styled-components'

import { EmptyButton } from '~/ui-components'

export const Content = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 16px;
  width: 706px;

  > * {
    min-height: 112px;
  }

  .ant-upload {
    width: 100%;
  }
`
export const AddFile = styled(EmptyButton)`
  min-height: 112px;
`
