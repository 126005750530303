import styled from 'styled-components'

import css from '@styled-system/css'

import { Box, Card, Text } from '~/ui-components'

export const FullName = styled(Text).attrs({ variant: 'h3' })(
  css({
    color: 'TxtHeading',
    mb: ['16px', '16px', '32px'],
  })
)

export const ContentWrapper = styled(Box)(
  css({
    display: 'grid',
    gridTemplateColumns: ['1fr', '1fr', '150px 1fr'],
    columnGap: '40px',
  })
)

export const Label = styled(Card.Label)(
  css({
    // display: ['none', 'none', 'unset'],
  })
)
