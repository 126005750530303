import React, { useEffect } from 'react'

import { Pagination } from 'antd'
import { observer } from 'mobx-react-lite'
import { useNavigate } from 'react-router-dom'

import { usePagination } from '~/hooks'
import { appRoutes } from '~/router'
import { moderatorCompaniesFilterStore as store } from '~/store/filters/moderatorCompaniesFilterStore'
import { getCompanyRoleOld } from '~/utils/company/get-company-role-old'
import { getDateFormat } from '~/utils/getDateFormat'
import { getUserRoleOld } from '~/utils/user/getUserRoleOld'
import { useGetModeratorCompaniesList } from '~hooks/moderator'
import {
  ModeratorCompaniesListMobile,
  ModeratorCompaniesListTable,
} from '~pages/ModeratorPage/components/ModeratorCompaniesList/components'
import { SearchInput } from '~shared/components/SearchInput'

import { CompanyListTableDataType } from './components/ModeratorCompaniesListTable/ModeratorCompaniesListTable.types'

import * as S from './ModeratorCompaniesList.styled'

export const ModeratorCompaniesList: React.FC = observer(() => {
  const navigate = useNavigate()
  const { pageSize, pageSizeOptions, currentPage, setCurrentPage, setPageSize } = usePagination()
  const companiesResponse = useGetModeratorCompaniesList({
    ids: store.idSearchQuery ? [Number(store.idSearchQuery)] : undefined,
    inns: store.innSearchQuery ? [store.innSearchQuery] : undefined,
    companyName: store.companyName.trim() ? store.companyName : undefined,
    currentPage,
    pageSize,
  })

  const companiesList = companiesResponse.data?.companies
  const pagination = companiesResponse.data?.pagination

  useEffect(() => {
    setCurrentPage(1)
  }, [store.idSearchQuery, store.innSearchQuery, store.companyName])

  const companyListTableData: CompanyListTableDataType[] =
    companiesList?.map((company) => ({
      id: company.id,
      inn: company?.inn ?? '-',
      companyName: company?.name ?? '-',
      role: getCompanyRoleOld(company?.type),
      createdBy: company.createdBy ? getUserRoleOld(company.createdBy.role) : '-',
      createdAt: company.createdAt ? getDateFormat(company.createdAt) : '-',
      status: company?.status ?? '',
    })) ?? []

  const onCompanyClickHandler = (record: CompanyListTableDataType) => {
    navigate(appRoutes.moderatorCompanies.url + '/' + record.id)
  }

  return (
    <>
      <S.Filters>
        <SearchInput
          defaultValue={store.innSearchQuery}
          onSearch={(value) => store.setInnSearchQuery(value)}
          autoFocus={false}
          placeholder="Поиск по ИНН"
          type="number"
          pattern="\d*"
        />
        <SearchInput
          defaultValue={store.companyName}
          onSearch={(value) => store.setCompanyNameQuery(value)}
          autoFocus={false}
          placeholder="Поиск по названию"
        />
        <SearchInput
          defaultValue={store.idSearchQuery}
          onSearch={(value) => store.setIdSearchQuery(value)}
          placeholder="Поиск по ID"
          type="number"
          pattern="\d*"
        />
      </S.Filters>

      <S.Content>
        <S.Desktop>
          <ModeratorCompaniesListTable
            tableData={companyListTableData}
            loading={companiesResponse.isLoading || companiesResponse.isFetching}
            onRowClick={onCompanyClickHandler}
          />
        </S.Desktop>
        <S.Mobile>
          <ModeratorCompaniesListMobile
            data={companyListTableData}
            loading={companiesResponse.isLoading || companiesResponse.isFetching}
            onItemClick={onCompanyClickHandler}
          />
        </S.Mobile>
        {pagination && (
          <Pagination
            current={pagination?.currentPage}
            total={pagination?.total}
            pageSize={pagination?.pageSize}
            pageSizeOptions={pageSizeOptions}
            onShowSizeChange={(x, size) => {
              setPageSize(size)
            }}
            showSizeChanger
            onChange={setCurrentPage}
          />
        )}
      </S.Content>
    </>
  )
})
