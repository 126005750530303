import styled from 'styled-components'

import { css } from '@styled-system/css'

import { Box } from '~/ui-components'

export const ContentWrapper = styled(Box)(
  css({
    padding: ['8px', '8px', '32px'],
  })
)

export const PersonInfoWrapper = styled(Box)(
  css({
    marginTop: '16px',
    display: 'grid',
    gridTemplateColumns: ['1fr', '1fr', '1fr 1fr'],
    gap: '16px',
  })
)

export const ActionWrapperFooter = styled(Box)(
  css({
    marginTop: '18px',
    display: 'flex',
    flexWrap: 'wrap',
    gap: '12px',
    justifyContent: 'flex-end',
  })
)

export const ActionWrapperHeader = styled(Box)(
  css({
    marginBottom: '24px',
    display: 'flex',
    flexWrap: 'wrap',
    gap: '12px',
    justifyContent: 'space-between',
    alignItems: 'center',
  })
)

export const Title = styled(Box)(
  css({
    color: 'TxtDefault',
    variant: ['typography.headingsH5'],
    fontWeight: '700',
  })
)

export const AdminInfoLabel = styled.div(
  css({
    color: 'TxtPrimary',
    minWidth: '150px',
    variant: 'typography.tableBodyText',
  })
)

export const AdminInfoValue = styled.div(
  css({
    color: 'TxtDefault',
    variant: 'typography.tableBodyText',
  })
)

export const ChangeStatusButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`
