import React from 'react'

import {
  FiBriefcase,
  FiInbox,
  FiLayers,
  FiLayout,
  FiMap,
  FiMapPin,
  FiServer,
  FiSettings,
  FiTruck,
  FiUser,
} from 'react-icons/fi'
import { RiAuctionLine } from 'react-icons/ri'

import { appRoutes } from '~/router'
import { CompanyDataTypeEnum, UserDataRoleEnum } from '~api/generated'
import { ReactComponent as BasisIcon } from '~assets/icons/basis.svg'
import { ReactComponent as CoinIcon } from '~assets/icons/coin.svg'
import { ReactComponent as WheatIcon } from '~assets/icons/wheat.svg'
import { ReactComponent as WheatCoinIcon } from '~assets/icons/wheat-coin.svg'

import { AppMenuInfo } from './AppMenu.types'

const SIZE = 20

export const menu: AppMenuInfo = {
  offers: {
    url: appRoutes.offersList.url,
    title: appRoutes.offersList.title,
    icon: <WheatCoinIcon />,
    roles: [
      CompanyDataTypeEnum.Trader,
      CompanyDataTypeEnum.Seller,
      CompanyDataTypeEnum.Buyer,
      CompanyDataTypeEnum.DemoCompany,
    ],
  },
  offersListSell: {
    url: appRoutes.offersListSell.url,
    title: appRoutes.offersListSell.title,
    icon: <WheatIcon />,
    roles: [
      CompanyDataTypeEnum.Trader,
      CompanyDataTypeEnum.Seller,
      CompanyDataTypeEnum.Buyer,
      CompanyDataTypeEnum.DemoCompany,
    ],
  },
  offersListBuy: {
    url: appRoutes.offersListBuy.url,
    title: appRoutes.offersListBuy.title,
    icon: <CoinIcon />,
    roles: [
      CompanyDataTypeEnum.Trader,
      CompanyDataTypeEnum.Seller,
      CompanyDataTypeEnum.Buyer,
      CompanyDataTypeEnum.DemoCompany,
    ],
  },
  basis: {
    url: appRoutes.basis.url,
    title: appRoutes.basis.title,
    icon: <BasisIcon />,
    roles: [
      CompanyDataTypeEnum.Trader,
      CompanyDataTypeEnum.Seller,
      CompanyDataTypeEnum.Buyer,
      CompanyDataTypeEnum.DemoCompany,
    ],
  },
  deals: {
    url: appRoutes.deals.url,
    title: appRoutes.deals.title,
    icon: <FiBriefcase size={SIZE} />,
    roles: [
      CompanyDataTypeEnum.Trader,
      CompanyDataTypeEnum.Seller,
      CompanyDataTypeEnum.Buyer,
      CompanyDataTypeEnum.DemoCompany,
    ],
  },
  auctions: {
    url: appRoutes.auctions.url,
    title: appRoutes.auctions.title,
    icon: <RiAuctionLine size={SIZE} />,
    roles: [
      CompanyDataTypeEnum.Trader,
      CompanyDataTypeEnum.Seller,
      CompanyDataTypeEnum.Buyer,
      CompanyDataTypeEnum.DemoCompany,
    ],
  },
  warehouses: {
    url: appRoutes.warehouses.url,
    title: appRoutes.warehouses.title,
    icon: <FiMapPin size={SIZE} />,
    roles: [
      CompanyDataTypeEnum.Trader,
      CompanyDataTypeEnum.Seller,
      CompanyDataTypeEnum.Buyer,
      CompanyDataTypeEnum.DemoCompany,
    ],
  },
  logistics: {
    url: appRoutes.logistics.url,
    title: appRoutes.logistics.title,
    icon: <FiTruck size={SIZE} />,
    roles: [
      CompanyDataTypeEnum.Trader,
      CompanyDataTypeEnum.Seller,
      CompanyDataTypeEnum.Buyer,
      CompanyDataTypeEnum.DemoCompany,
      CompanyDataTypeEnum.Carrier,
    ],
  },
  logisticFindCargo: {
    url: appRoutes.logisticFindCargo.url,
    title: appRoutes.logisticFindCargo.title,
    icon: <FiMap size={SIZE} />,
    roles: [
      CompanyDataTypeEnum.Trader,
      CompanyDataTypeEnum.Seller,
      CompanyDataTypeEnum.Buyer,
      CompanyDataTypeEnum.DemoCompany,
      CompanyDataTypeEnum.Carrier,
    ],
  },
  carPark: {
    url: appRoutes.carPark.url,
    title: appRoutes.carPark.title,
    icon: <FiTruck size={SIZE} />,
    roles: [CompanyDataTypeEnum.Carrier],
  },
  logisticMyCargos: {
    url: appRoutes.logisticMyCargos.url,
    title: appRoutes.logisticMyCargos.title,
    icon: <FiTruck size={SIZE} />,
    roles: [
      CompanyDataTypeEnum.Trader,
      CompanyDataTypeEnum.Seller,
      CompanyDataTypeEnum.Buyer,
      CompanyDataTypeEnum.DemoCompany,
    ],
  },
  logisticResponses: {
    url: appRoutes.logisticResponses.url,
    title: appRoutes.logisticResponses.title,
    icon: <FiServer size={SIZE} />,
    roles: [
      CompanyDataTypeEnum.Trader,
      CompanyDataTypeEnum.Seller,
      CompanyDataTypeEnum.Buyer,
      CompanyDataTypeEnum.DemoCompany,
      CompanyDataTypeEnum.Carrier,
    ],
  },
  logisticDeals: {
    url: appRoutes.logisticDeals.url,
    title: appRoutes.logisticDeals.title,
    icon: <FiInbox size={SIZE} />,
    roles: [
      CompanyDataTypeEnum.Trader,
      CompanyDataTypeEnum.Seller,
      CompanyDataTypeEnum.Buyer,
      CompanyDataTypeEnum.DemoCompany,
      CompanyDataTypeEnum.Carrier,
    ],
  },
  requests: {
    url: appRoutes.requests.url,
    title: appRoutes.requests.title,
    icon: <FiLayers size={SIZE} />,
    roles: [CompanyDataTypeEnum.Trader, UserDataRoleEnum.Admin],
  },
  moderator: {
    url: appRoutes.moderator.url,
    title: appRoutes.moderator.title,
    icon: <FiLayout size={SIZE} />,
    roles: [UserDataRoleEnum.Admin],
  },
  settings: {
    url: appRoutes.settings.url,
    title: appRoutes.settings.title,
    icon: <FiSettings size={SIZE} />,
    roles: [UserDataRoleEnum.Admin],
  },
  accountCompanyProfile: {
    url: appRoutes.accountCompanyProfile.url,
    title: 'Моя компания',
    icon: <FiUser size={SIZE} />,
  },
  // statistics: {
  //   url: appRoutes.statistics.url,
  //   title: appRoutes.statistics.title,
  //   icon: 'Layout',
  // },
  // documents: {
  //   url: appRoutes.documents.url,
  //   title: appRoutes.documents.title,
  //   icon: 'Folder',
  // },
  // settings: {
  //   url: appRoutes.settings.url,
  //   title: appRoutes.settings.title,
  //   icon: 'Settings',
  // },
  // help: {
  //   url: appRoutes.help.url,
  //   title: appRoutes.help.title,
  //   icon: 'MessageCircle',
  // },
}
