import React from 'react'

import { Select } from 'antd'
import { BaseSelectRef } from 'rc-select'

import { useQuery } from '@apollo/client'

import { getTransportLoadingMethodsQuery } from '~api/gql-query/car-park.query.graphql'
import { CustomControlInterface } from '~shared/controls/CustomControl.interface'

interface TransportLoadingMethodsSelectControlProps extends CustomControlInterface {
  id?: string
  value: number | undefined
  onChange: (value: number) => void
  hasError?: boolean
}

export const TransportLoadingMethodsSelectControl: React.FC<TransportLoadingMethodsSelectControlProps> =
  React.forwardRef<BaseSelectRef, TransportLoadingMethodsSelectControlProps>(({ value, onChange, hasError }, ref) => {
    const getTransportLoadingMethods = useQuery(getTransportLoadingMethodsQuery)
    const transportLoadingMethods = getTransportLoadingMethods.data?.transportLoadingMethods ?? []

    return (
      <Select
        ref={ref}
        allowClear
        style={{ width: '100%', maxWidth: '342px' }}
        placeholder="Выберите"
        value={value}
        onChange={onChange}
        status={hasError ? 'error' : undefined}
      >
        {transportLoadingMethods.map((transportLoadingMethod) => (
          <Select.Option key={transportLoadingMethod.id} value={transportLoadingMethod.id}>
            {transportLoadingMethod.name}
          </Select.Option>
        ))}
      </Select>
    )
  })

TransportLoadingMethodsSelectControl.displayName = 'TransportLoadingMethodsSelectControl'
