import { useMutation } from '@tanstack/react-query'

import {
  CreateDealDocumentBody,
  CreateDealDocumentResponse,
  DealDocumentApiDealDocumentControllerCreateDocumentRequest,
} from '~api/generated'
import { useUploadFilesAndGetIds } from '~hooks/_utils'
import { useApi } from '~providers/api'

interface CreateDealDocumentBodyWithFile extends Omit<CreateDealDocumentBody, 'fileKeys'> {
  documentFiles: File[]
}
interface DealDocumentUploadRequest
  extends Omit<DealDocumentApiDealDocumentControllerCreateDocumentRequest, 'createDealDocumentBody'> {
  createDealDocumentBody: CreateDealDocumentBodyWithFile
}
export const useUploadDealDocument = () => {
  const api = useApi()
  const uploadFilesAndGetIdsFn = useUploadFilesAndGetIds()

  const uploadDealDocumentFn = async (
    createDealBody: DealDocumentUploadRequest
  ): Promise<CreateDealDocumentResponse | null> => {
    const uploadedFileIds = await uploadFilesAndGetIdsFn.mutateAsync(
      createDealBody.createDealDocumentBody.documentFiles
    )

    const res = await api.dealDocument.dealDocumentControllerCreateDocument({
      dealId: createDealBody.dealId,
      documentType: createDealBody.documentType,
      createDealDocumentBody: {
        documentInfo: {
          documentNumber: createDealBody.createDealDocumentBody.documentInfo?.documentNumber ?? null,
          formationDate: createDealBody.createDealDocumentBody.documentInfo?.formationDate ?? null,
        },
        fileKeys: uploadedFileIds,
      },
    })

    return res.data
  }

  return useMutation(uploadDealDocumentFn)
}
