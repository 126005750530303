import styled from 'styled-components'

export const ContentWrapper = styled.article<{ isSelected?: boolean; isDisabled?: boolean }>`
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 12px;
  cursor: ${(props) => (props.isDisabled ? 'not-allowed' : 'pointer')};
  align-items: center;
  padding: 8px 16px;
  background: ${(props) => {
    if (props.isSelected) {
      return '#eef7ed'
    }
    if (props.isDisabled) {
      return 'rgb(242, 242, 242)'
    }
    return 'unset'
  }};
  &:hover {
    background: #eef7ed;
  }
`

export const CompanyInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  flex: 1;
`
export const CompanyNameWrapper = styled.div`
  display: flex;
  gap: 8px;
`

export const CompanyName = styled.p`
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  color: var(--gray-8-color);
  margin: 0;
`
export const CompanyNotVerified = styled.p`
  font-size: 10px;
  font-weight: 500;
  color: var(--danger-color);
  margin: 0;
`

export const AddCompanyText = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: var(--primary-main-color);
  margin: 0;
`
