import styled from 'styled-components'

export const Content = styled.div`
  width: 100%;
  height: 750px;
  display: flex;
  //padding: 16px;
  //margin: -20px;
  gap: 24px;
`
export const LeftSide = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`
export const RightSide = LeftSide

export const FooterContent = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-end;
  gap: 16px;
`
export const FooterContentCenter = styled.div`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
`
