import styled from 'styled-components'
import { variant } from 'styled-system'

import { breakpoints } from '~/tokens'

const mobileBreakpoint = breakpoints.MOBILE_XL + 'px'

const variants = {
  banner: {
    fontSize: '48px',
    lineHeight: '56px',
    fontWeight: 600,
    [`@media (max-width: ${mobileBreakpoint})`]: {
      fontSize: '24px',
      lineHeight: '32px',
    },
  },
  h1: {
    fontSize: '24px',
    lineHeight: '32px',
    fontWeight: 600,
    [`@media (max-width: ${mobileBreakpoint})`]: {
      fontSize: '21px',
      lineHeight: '24px',
    },
  },
  h2: {
    fontSize: '22px',
    lineHeight: '28px',
    fontWeight: 600,
    [`@media (max-width: ${mobileBreakpoint})`]: {
      fontSize: '18px',
      lineHeight: '24px',
    },
  },
  h3: {
    fontSize: '18px',
    lineHeight: '24px',
    fontWeight: 600,
    [`@media (max-width: ${mobileBreakpoint})`]: {
      fontSize: '16px',
      lineHeight: '16px',
    },
  },
  h4: {
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: 600,
    [`@media (max-width: ${mobileBreakpoint})`]: {
      fontSize: '15px',
      lineHeight: '16px',
    },
  },
  normal: {
    fontSize: '14px',
    lineHeight: '20px',
    [`@media (max-width: ${mobileBreakpoint})`]: {
      lineHeight: '16px',
    },
  },
  'normal-bold': {
    fontSize: '14px',
    lineHeight: '20px',
    fontWeight: 600,
    [`@media (max-width: ${mobileBreakpoint})`]: {
      lineHeight: '16px',
    },
  },
  label: {
    fontSize: '14px',
    lineHeight: '16px',
  },
  'label-bold': {
    fontSize: '14px',
    lineHeight: '16px',
    fontWeight: 600,
  },
  description: {
    fontSize: '12px',
    lineHeight: '16px',
  },
  'form-read-label': {
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '20px',
    color: 'var(--gray-5-color)',
  },
  'form-read-value': {
    fontWeight: 700,
    fontSize: '14px',
    lineHeight: '20px',
    color: 'var(--gray-8-color)',
  },
}

export const Text = styled('p')<{ variant: keyof typeof variants }>(
  {
    margin: 0,
    color: 'var(--gray-8-color)',
  },
  variant({
    variants,
  })
)
