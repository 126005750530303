import { ElevatorOfferStatus } from '~api/generated'

import { BadgeVariant } from '~ui-components/components/Badge/Badge.types'

export function getElevatorAdvertStatus(v: ElevatorOfferStatus): {
  name: string
  color: BadgeVariant
} {
  switch (v) {
    case ElevatorOfferStatus.Deactivated:
      return {
        name: 'Снято с публикации',
        color: 'gray',
      }
    case ElevatorOfferStatus.OnModeration:
      return {
        name: 'На проверке',
        color: 'yellow',
      }
    case ElevatorOfferStatus.OnModerationWithAnalysis:
      return {
        name: 'На проверке. Лаб анализ',
        color: 'yellow',
      }
    case ElevatorOfferStatus.Published:
      return {
        name: 'Опубликовано',
        color: 'purple',
      }
    case ElevatorOfferStatus.Rejected:
      return {
        name: 'Отклонено',
        color: 'red',
      }
    case ElevatorOfferStatus.Cancelled:
      return {
        name: 'Отменен',
        color: 'red',
      }
  }
}
