import React, { FC, useState } from 'react'

import { Button, Modal, Skeleton, Spin } from 'antd'
import { observer } from 'mobx-react-lite'
import { useParams } from 'react-router'
import { useNavigate } from 'react-router-dom'

import { useGetProductTypeData } from '~/hooks'
import { AppHeader } from '~/layout'
import { OfferCardDetails } from '~/pages/OffersPage/components/OfferCardDetails'
import { appToast, httpErrorHandler, modalSharedSettings } from '~/utils'
import { getOfferStatus } from '~/utils/offers/get-offer-status'
import { getOfferType } from '~/utils/offers/get-offer-type'
import { isOfferExpireSoon } from '~/utils/offers/is-offer-expire-soon'
import { CreationProductParameterData, OfferDataStatusEnum, UpdateOfferStatusBodyStatusEnum } from '~api/generated'
import { EntityModel } from '~api/gql-generated/graphql'
import { useUploadFileAndGetId } from '~hooks/_utils'
import { useGetModeratorOffer, useUpdateOffer, useUpdateOfferModerator } from '~hooks/moderator'
import { useUpdateOfferStatus } from '~hooks/offer'
import { ModeratorOfferEditForm } from '~pages/ModeratorPage/components/ModeratorOfferEditForm'
import { Notes } from '~pages/ModeratorPage/components/Notes/Notes'
import { useAuth } from '~providers/auth'

import { IModeratorOfferEditForm } from '../ModeratorOfferEditForm/ModeratorOfferEditForm.types'

import * as S from './ModeratorOfferDetails.styled'

export const ModeratorOfferDetails: FC = observer(() => {
  const { userId: currentUserId, companyId } = useAuth()
  const navigate = useNavigate()
  const { id: offerId } = useParams<{ id: string | undefined }>()
  const offerFn = useGetModeratorOffer(Number(offerId))
  const updateOfferStatusFn = useUpdateOfferStatus()
  const updateOfferFn = useUpdateOffer()
  const updateOfferModeratorFn = useUpdateOfferModerator()
  const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false)
  const [selectedOfferStatus, setSelectedOfferStatus] = useState<OfferDataStatusEnum | null>(null)
  const [isEditModalVisible, setIsEditModalVisible] = useState(false)
  const [isRepublishModalVisible, setIsRepublishModalVisible] = useState(false)
  const { getProductTypeParametersForUI_V2 } = useGetProductTypeData()
  const uploadLabAnalysisAndGetIdFn = useUploadFileAndGetId()

  const handleEditOfferModalClose = () => setIsEditModalVisible(false)
  const handleEditOfferModalOpen = () => setIsEditModalVisible(true)
  const handleRepublishOfferModalClose = () => setIsRepublishModalVisible(false)
  const handleRepublishOfferModalOpen = () => setIsRepublishModalVisible(true)

  const updateOffer = async (formValues: IModeratorOfferEditForm) => {
    const laboratoryAnalysisFileKey =
      (formValues.labAnalysis as unknown) instanceof File
        ? await uploadLabAnalysisAndGetIdFn.mutateAsync(formValues.labAnalysis as unknown as File)
        : undefined

    await updateOfferFn.mutateAsync({
      offerId: Number(offerId),
      updateOfferBody: {
        userCompanyId: companyId,
        address: formValues.address?.value ?? '',
        volume: formValues.volume,
        price: formValues.price,
        includingNds: formValues.usingNds,
        shippingRequired: formValues.shippingRequired,
        laboratoryAnalysisFileKey,
        product: {
          type: formValues?.culture?.cultureTypeId,
          parameters: formValues?.culture?.parameters as CreationProductParameterData[],
        },
        commission: formValues.commission,
        note: formValues.note,
      },
    })
  }

  const handleSubmitEditForm = async (formValues: IModeratorOfferEditForm) => {
    try {
      await updateOffer(formValues)
      appToast.success({ description: 'Предложение отредактировано' })
    } catch (e) {
      appToast.error({ description: 'Ошибка при редактировании предложения' })
    }

    handleEditOfferModalClose()
    await offerFn.refetch()
  }

  const handleSubmitRepublishForm = async (formValues: IModeratorOfferEditForm) => {
    try {
      await updateOfferStatusFn.mutateAsync({
        offerId: Number(offerId),
        updateOfferStatusBody: {
          userCompanyId: companyId,
          status: UpdateOfferStatusBodyStatusEnum.OnModeration,
        },
      })
      await updateOffer(formValues)
      appToast.success({ description: 'Предложение опубликовано заново' })
    } catch (e) {
      appToast.error({ description: 'Ошибка при публикации предложения' })
    }

    handleRepublishOfferModalClose()
    await offerFn.refetch()
  }

  const handleChangeStatus = async (_e: React.MouseEvent) => {
    if (!selectedOfferStatus) {
      return
    }
    try {
      await updateOfferStatusFn.mutateAsync({
        offerId: Number(offerId),
        updateOfferStatusBody: {
          userCompanyId: companyId,
          status: selectedOfferStatus,
        },
      })
      await offerFn.refetch()
      appToast.success({ description: 'Статус предложения изменен' })
      setSelectedOfferStatus(null)
      setIsConfirmModalVisible(false)
    } catch (e) {
      appToast.error({ description: 'Ошибка при смене статуса предложения' })
    }
  }

  const handleDealButtonClick = (id: number | null | undefined) => {
    navigate(`/moderator/deals/list/${id}/information`)
  }

  const takeOfferToWorkHandler = async () => {
    try {
      await updateOfferModeratorFn.mutateAsync({
        offerId: offer.id,
        updateOfferModeratorBody: {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          moderatorId: currentUserId!,
        },
      })
      appToast.success({ description: `Вы взяли в работу предложение ${offer.id}` })
    } catch (e) {
      httpErrorHandler(e, 'Ошибка при обновлении модератора оффера')
    }

    await offerFn.refetch()
  }

  if (offerFn.isLoading || !offerFn.data) {
    return <Skeleton />
  }

  const offer = offerFn.data

  const hasOfferModerator = !!offer?.moderator
  const isModeratorNotCurrentUser =
    currentUserId && offer?.moderator?.id ? offer?.moderator.id !== currentUserId : false
  const areActionButtonsDisabled = !hasOfferModerator || isModeratorNotCurrentUser

  return (
    <section>
      <AppHeader title={`Предложение № ${offerId}`} isBack />

      <Modal
        title="Изменить статус предложения?"
        open={isConfirmModalVisible}
        okText="Изменить"
        cancelText="Отмена"
        onOk={handleChangeStatus}
        confirmLoading={updateOfferStatusFn.isLoading}
        onCancel={() => {
          setSelectedOfferStatus(null)
          setIsConfirmModalVisible(false)
        }}
      >
        <p>
          {/* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */}
          Статус предложения будет изменен на <b>{getOfferStatus(selectedOfferStatus!).name}</b>
        </p>
      </Modal>

      <S.ContentWrapper>
        <S.ActionWrapperHeader>
          {hasOfferModerator && (
            <>
              <S.AdminInfoLabel>В работе у модератора</S.AdminInfoLabel>
              <S.AdminInfoValue>{offer.moderator?.name ?? ''}</S.AdminInfoValue>
            </>
          )}

          {offer?.moderator?.id !== currentUserId &&
            (offer.status === OfferDataStatusEnum.OnModeration ||
              offer.status === OfferDataStatusEnum.PrePublished) && (
              <Button htmlType="button" type="primary" onClick={takeOfferToWorkHandler}>
                Взять в работу
              </Button>
            )}

          <div style={{ marginLeft: 'auto' }}>
            <Notes entityId={Number(offerId)} relatedModel={EntityModel.Offer} />
          </div>
        </S.ActionWrapperHeader>

        <Spin spinning={offerFn.isFetching}>
          <OfferCardDetails
            id={offer.id}
            title={offer.product.name}
            statuses={[getOfferType(offer.type), getOfferStatus(offer.status)]}
            parameters={getProductTypeParametersForUI_V2(offer.product)}
            laboratoryAnalysisSource={offer.laboratoryAnalysis?.source}
            volume={offer.volume}
            price={offer.price}
            priceDetail={offer.priceDetail}
            includingNds={offer.includingNds}
            address={offer.address}
            distance={offer.distance}
            shippingRequired={offer.shippingRequired}
            contactPersonName={offer.contactPerson?.name}
            contactPersonPhone={offer.contactPerson?.phoneNumber}
            publishedAt={offer.publishedAt}
            acceptedAt={offer.createdAt}
            expiredAt={offer.expiredAt}
            note={offer.note}
            company={offer.company ?? undefined}
            type={offer.type}
            isParametersCorrespondsToGost={offer.product.parametersIsCorrespondsToGost}
            gost={offer.product.gost}
            createdBy={offer.createdBy}
          />
        </Spin>
        <S.ActionWrapperFooter>
          {offerFn.data.nextStatuses.includes(OfferDataStatusEnum.Rejected) && (
            <Button
              type="primary"
              onClick={() => {
                setSelectedOfferStatus(OfferDataStatusEnum.Rejected)
                setIsConfirmModalVisible(true)
              }}
              danger
              disabled={areActionButtonsDisabled}
            >
              Отклонить
            </Button>
          )}

          {offerFn.data.status === OfferDataStatusEnum.Published && (
            <>
              {offerFn.data.nextStatuses.includes(OfferDataStatusEnum.Rejected) && (
                <Button
                  htmlType="button"
                  type="primary"
                  danger
                  onClick={() => {
                    setSelectedOfferStatus(OfferDataStatusEnum.Rejected)
                    setIsConfirmModalVisible(true)
                  }}
                >
                  Снять с публикации
                </Button>
              )}
              {offer?.expiredAt &&
                isOfferExpireSoon(new Date(offer.expiredAt)) &&
                offer.nextStatuses.includes('PUBLISHED') && (
                  <Button
                    htmlType="button"
                    type="primary"
                    onClick={() => {
                      setSelectedOfferStatus(OfferDataStatusEnum.Published)
                      setIsConfirmModalVisible(true)
                    }}
                  >
                    Продлить публикацию
                  </Button>
                )}
            </>
          )}

          {offerFn.data.status === OfferDataStatusEnum.OnModeration && (
            <>
              <Button
                htmlType="button"
                type="default"
                onClick={handleEditOfferModalOpen}
                disabled={areActionButtonsDisabled}
              >
                Редактировать
              </Button>
            </>
          )}
          {offerFn.data.complexDealId && (
            <Button type="primary" onClick={() => handleDealButtonClick(offerFn.data?.complexDealId)}>
              Перейти к сделке
            </Button>
          )}

          {(offerFn.data.status === OfferDataStatusEnum.Rejected ||
            offerFn.data.status === OfferDataStatusEnum.Expired) && (
            <Button type="primary" onClick={handleRepublishOfferModalOpen}>
              Опубликовать заново
            </Button>
          )}

          {offerFn.data.nextStatuses.includes(OfferDataStatusEnum.Published) && (
            <Button
              htmlType="button"
              type="primary"
              onClick={() => {
                setSelectedOfferStatus(OfferDataStatusEnum.Published)
                setIsConfirmModalVisible(true)
              }}
              disabled={areActionButtonsDisabled}
            >
              Опубликовать
            </Button>
          )}

          {offerFn.data.nextStatuses.includes(OfferDataStatusEnum.OnModeration) && (
            <Button
              type="primary"
              onClick={() => {
                setSelectedOfferStatus(OfferDataStatusEnum.OnModeration)
                setIsConfirmModalVisible(true)
              }}
              disabled={areActionButtonsDisabled}
            >
              На модерацию
            </Button>
          )}
        </S.ActionWrapperFooter>
      </S.ContentWrapper>

      <Modal
        open={isEditModalVisible}
        onCancel={handleEditOfferModalClose}
        title="Редактирование предложения"
        {...modalSharedSettings}
        footer={[
          <Button key="cancel" onClick={handleEditOfferModalClose}>
            Отмена
          </Button>,
          <Button
            key="save"
            type="primary"
            loading={updateOfferFn.isLoading}
            form="offer-moderator-edit-form"
            htmlType="submit"
          >
            Сохранить
          </Button>,
        ]}
      >
        <ModeratorOfferEditForm
          idForm="offer-moderator-edit-form"
          onSubmit={handleSubmitEditForm}
          defaultValues={offer}
        />
      </Modal>

      <Modal
        open={isRepublishModalVisible}
        onCancel={handleRepublishOfferModalClose}
        title="Редактирование предложения"
        {...modalSharedSettings}
        footer={[
          <Button key="cancel">Отмена</Button>,
          <Button
            key="save"
            type="primary"
            loading={updateOfferFn.isLoading}
            form="offer-moderator-republish-form"
            htmlType="submit"
          >
            Опубликовать
          </Button>,
        ]}
      >
        <ModeratorOfferEditForm
          idForm="offer-moderator-republish-form"
          onSubmit={handleSubmitRepublishForm}
          defaultValues={offer}
        />
      </Modal>
    </section>
  )
})
