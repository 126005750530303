import React from 'react'
import { observer } from 'mobx-react-lite'
import { logisticResponsesFilterStore } from '~/store/filters'
import { Flex } from 'antd'
import { RegionsSelectControl } from '~shared/controls'
import { FreightDealResponsesStatusesSelectControl } from '~shared/controls/FreightDealResponsesStatusesSelectControl/FreightDealResponsesStatusesSelectControl'
import { FreightDealStatus } from '~api/gql-generated/graphql'
import { SearchInput } from '~shared/components/SearchInput'
import { ProductTypeIdSelectControl } from '~shared/controls/ProductTypeIdSelectControl'
import { SearchInputNumber } from '~shared/components/SearchInputNumber'

export const ResponsesFilter: React.FC = observer(() => {
  const store = logisticResponsesFilterStore
  const filters = store.responsesFilters

  const onChangeLoad = (v: string | number | null) => {
    store.setFilters({ ...filters, freightOrderIds: v && typeof v === 'number' ? [v] : undefined })
  }

  const onChangeRegions = (v: number[]) => {
    store.setFilters({ ...filters, regionIds: v })
  }

  const onChangeStatuses = (v: FreightDealStatus[]) => {
    store.setFilters({ ...filters, statuses: v })
  }

  const onChangeProductType = (v: number[]) => {
    store.setFilters({ ...filters, productTypeIds: v })
  }

  const onChangeSearch = (v?: string) => {
    store.setFilters({ ...filters, search: v })
  }

  return (
    <Flex justify="space-between" gap={24}>
      <Flex gap={24} flex="1 1 25%">
        <SearchInputNumber
          style={{ width: '100%' }}
          defaultValue={store.responsesFilters.freightOrderIds?.[0] ?? undefined}
          onSearch={onChangeLoad}
          placeholder="Все грузы"
        />
        <RegionsSelectControl
          mode="multiple"
          onChange={onChangeRegions}
          value={filters.regionIds}
          placeholder="Все регионы"
        />
        <FreightDealResponsesStatusesSelectControl onChange={onChangeStatuses} value={filters.statuses} />
        <ProductTypeIdSelectControl
          onChange={onChangeProductType}
          value={filters.productTypeIds}
          placeholder="Все типы грузов"
          mode="multiple"
        />
      </Flex>

      <Flex>
        <SearchInput
          style={{ width: 280 }}
          defaultValue={store.responsesFilters.search ?? ''}
          onSearch={(x) => onChangeSearch(x.trim() ? x : undefined)}
          autoFocus={true}
          placeholder="Поиск по ID, грузу, маршруту"
        />
      </Flex>
    </Flex>
  )
})
