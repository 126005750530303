import styled from 'styled-components'

import { breakpoints } from '~/tokens'

export const Filters = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 16px;
  @media (max-width: ${breakpoints.DESKTOP_S}px) {
    grid-template-columns: 1fr;
  }
`
