import { CompanyDealDataStatusEnum } from '~api/generated'

export function getDealStatusColor(status: string): string {
  switch (status) {
    case CompanyDealDataStatusEnum.OnModeration:
      return 'yellow'
    case CompanyDealDataStatusEnum.RejectedAfterModeration:
      return 'red'
    case CompanyDealDataStatusEnum.Agreement:
      return 'purple'
    case CompanyDealDataStatusEnum.Signing:
      return 'blue'
    case CompanyDealDataStatusEnum.Payment:
      return 'base'
    case CompanyDealDataStatusEnum.Shipment:
      return 'base'
    case CompanyDealDataStatusEnum.Rejected:
      return 'red'
    case CompanyDealDataStatusEnum.Realised:
      return 'gray'
    default:
      return ''
  }
}
