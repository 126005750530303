import { useMutation } from '@tanstack/react-query'

import { AuctionApiAuctionControllerCreateAuctionOfferRequest } from '~/api/generated'
import { useApi } from '~providers/api'

export const useCreateAuctionOffer = () => {
  const api = useApi()

  const createAuctionFn = async (request: AuctionApiAuctionControllerCreateAuctionOfferRequest) => {
    let auctionOfferId = null

    try {
      auctionOfferId = (await api.auction.auctionControllerCreateAuctionOffer(request)).data.auctionOfferId
    } catch (err) {
      return Promise.reject(err)
    }

    return auctionOfferId
  }

  return useMutation(createAuctionFn)
}
