import { codeUnreachable } from '~/utils/code-unreachable'
import { CompanyVerificationStatus } from '~api/generated'

export function getCompanyStatusColor(status: CompanyVerificationStatus): string {
  switch (status) {
    case CompanyVerificationStatus.OnModeration:
      return 'yellow'
    case CompanyVerificationStatus.NotVerified:
      return 'red'
    case CompanyVerificationStatus.Verified:
      return 'base'
    case CompanyVerificationStatus.Rejected:
      return 'red'
    default:
      return codeUnreachable(status)
  }
}
