import * as yup from 'yup'

import { VALIDATORS_MSG } from '~shared/validations'
import { FreightPaymentTerm } from '~api/gql-generated/graphql'

export const BetToLoadFormSchema = yup.object({
  pricePerTon: yup.number().required(VALIDATORS_MSG.required),
  priceWithNds: yup.boolean().required(VALIDATORS_MSG.required),
  weightKg: yup.number().required(VALIDATORS_MSG.required),
  paymentTerm: yup
    .mixed<FreightPaymentTerm>()
    .oneOf(Object.values(FreightPaymentTerm))
    .required(VALIDATORS_MSG.required),
  startedAt: yup.string(),
  additionalDetails: yup.string(),
})
