import { TransportFormatType } from '~api/gql-generated/graphql'

export const getTransportType = (transportFormatTypes: TransportFormatType[]) => {
  const res = []
  if (!transportFormatTypes) return ''
  if (transportFormatTypes.includes(TransportFormatType.Single)) {
    res.push('Одиночное авто')
  }
  if (transportFormatTypes.includes(TransportFormatType.RoadTrain)) {
    res.push('Автопоезд')
  }
  return res.join(', ')
}
