import React from 'react'

import { ModeratorCompaniesList } from '~pages/ModeratorPage/components/ModeratorCompaniesList'

import * as S from './ModeratorCompaniesListTab.styled'

export const ModeratorCompaniesListTab: React.FC = () => {
  return (
    <S.ContentWrapper>
      <ModeratorCompaniesList />
    </S.ContentWrapper>
  )
}
