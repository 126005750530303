import React, { useState } from 'react'

import { getTransportDocumentName } from '~/utils/car-park/get-transport-document-name'
import { DocumentViewerModal } from '~shared/components/DocumentViewer'
import { DocumentCard } from '~ui-components/components/documents/DocumentCard'
import { DocumentCardNotAvailable } from '~ui-components/components/documents/DocumentCardNotAvailable'

import { TransportDocumentProps } from './TransportDocument.types'

export const TransportDocument: React.FC<TransportDocumentProps> = ({ type, documentInstance }) => {
  const documentName = getTransportDocumentName(type)
  const filesLength = documentInstance?.files.length

  if (!documentInstance || !filesLength) {
    return <DocumentCardNotAvailable text={`${documentName} отсутствует`} />
  }

  const [isShowDocument, setIsShowDocument] = useState(false)

  const onDownload = () => {
    const link = document.createElement('a')
    link.href = documentInstance.files[0].source
    link.download = documentInstance.files[0].name
    link.target = '_blank'
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  return (
    <>
      <DocumentCard
        name={documentName}
        status={documentInstance.status}
        createdAt={documentInstance.createdAt}
        count={filesLength}
        isDownloading
        onDownload={onDownload}
        onShow={() => setIsShowDocument(true)}
      />

      <DocumentViewerModal
        isOpen={isShowDocument}
        onCancel={() => setIsShowDocument(false)}
        docInstances={[
          {
            ...documentInstance,
            documentInfo: {
              ...documentInstance,
              documentNumber: documentInstance.number ?? null,
              formationDate: documentInstance.formedAt,
              rejectionNote: documentInstance.rejectionNote ?? null,
            },
          },
        ]}
        startCheckFromDocInstanceId={0}
      />
    </>
  )
}
