import React, { MouseEvent, useState } from 'react'

import { FiArrowLeft } from 'react-icons/fi'
import { useLocation, useNavigate } from 'react-router-dom'

import { useQuery } from '@apollo/client'

import { NotificationsCounter } from '~/pages/Notifications'
import { appRoutes } from '~/router'
import { HelpButton } from '~/shared/components'
import { Drawer, Text } from '~/ui-components'
import { getMeQuery } from '~api/gql-query/me.query.graphql'
import { ReactComponent as LogoZaMobile } from '~assets/icons/LZA.svg'
import logoUrl from '~assets/images/logo.svg'
import { AppMenuItems } from '~layout/AppMenuItems'

import { UserMenu } from '../UserMenu'

import { IAppHeaderProps } from './AppHeader.types'

import * as S from './AppHeader.styled'

export const AppHeader: React.FC<IAppHeaderProps> = ({ title = '', isBack, onBack, onHelp, children }) => {
  const navigate = useNavigate()
  const getMe = useQuery(getMeQuery)
  const userData = getMe.data?.me

  const [visibleMenu, setVisibleMenu] = useState(false)

  const { pathname } = useLocation()

  if (pathname === appRoutes.auth.url) {
    return (
      <S.HeaderAuth>
        <S.HeaderAuthImg src={logoUrl} alt="logo" />
      </S.HeaderAuth>
    )
  }

  const handleNavigateBack = () => {
    if (onBack) {
      onBack()
    } else {
      navigate(-1)
    }
  }

  const handleClickMenuLink = (evt: MouseEvent<HTMLDivElement>) => {
    const target = evt.target as HTMLElement

    if (target.tagName === 'A' || target.closest('a')) {
      setVisibleMenu(false)
    }
  }

  return (
    <>
      <S.MobileHeader>
        <S.MobileHeaderRow>
          <S.LogoMobileLink to={appRoutes.welcomePage.url}>
            <LogoZaMobile />
          </S.LogoMobileLink>

          {isBack && (
            <S.BackButton onClick={handleNavigateBack}>
              <FiArrowLeft size={24} />
            </S.BackButton>
          )}

          {title && (
            <S.MobileTitleWrapper>
              <Text variant="normal-bold">{title}</Text>
            </S.MobileTitleWrapper>
          )}

          {/*<S.Inner>*/}
          {/*  <NotificationsCounter />*/}

          {/*  <UserMenu userAvatarUrl={userAvatarUrl} userNameInitials={getUserInitials(user)} />*/}
          {/*</S.Inner>*/}
        </S.MobileHeaderRow>
        {children && <S.MobileHeaderRowChildren>{children}</S.MobileHeaderRowChildren>}
      </S.MobileHeader>

      <S.DesktopHeader>
        <S.Header isSimple={!children}>
          <S.Inner>
            {isBack && (
              <S.BackButton onClick={handleNavigateBack}>
                <FiArrowLeft size={24} />
              </S.BackButton>
            )}
            <S.Title>
              {title}
              {onHelp && <HelpButton onClick={onHelp} />}
            </S.Title>

            <NotificationsCounter />

            <UserMenu userAvatarUrl={userData?.avatarUrl} userName={userData?.name} userRole={userData?.role} />
          </S.Inner>
          {children}
        </S.Header>
      </S.DesktopHeader>

      <Drawer className="mobile-menu" open={visibleMenu} onClose={() => setVisibleMenu(false)} placement="left">
        <S.MobileMenuWrapper onClick={(evt) => handleClickMenuLink(evt)}>
          <AppMenuItems />
        </S.MobileMenuWrapper>
      </Drawer>
    </>
  )
}
