import { isEqual } from 'lodash'
import { makeAutoObservable } from 'mobx'

import {
  AdminElevatorAdvertOfferDataTypeEnum,
  ElevatorAdvertOfferApiAdminGetElevatorOfferResponsesRequest,
  ElevatorOfferResponseStatus,
} from '~api/generated'

import { IStoreFilter } from '~/store/filters/_storeFilters.types'

interface IBasisAdvertOffersFilterByAdminForm {
  elevatorId: number | undefined
  elevatorAdvertId: number | undefined
  companyId: number | undefined
  statuses: ElevatorOfferResponseStatus[]
  offerType: AdminElevatorAdvertOfferDataTypeEnum | undefined
}

export class BasisAdvertOffersFilterStore implements IStoreFilter<IBasisAdvertOffersFilterByAdminForm> {
  _filters: IBasisAdvertOffersFilterByAdminForm
  readonly _defaultFiltersConfig: IBasisAdvertOffersFilterByAdminForm = {
    elevatorId: undefined,
    elevatorAdvertId: undefined,
    companyId: undefined,
    offerType: undefined,
    statuses: [],
  }

  constructor() {
    this._filters = this._defaultFiltersConfig
    makeAutoObservable(this)
  }

  setFilters(newFilters: IBasisAdvertOffersFilterByAdminForm): void {
    this._filters = newFilters
  }

  get filters(): IBasisAdvertOffersFilterByAdminForm {
    return this._filters
  }

  get defaultFiltersConfig(): IBasisAdvertOffersFilterByAdminForm {
    return this._defaultFiltersConfig
  }

  get areCurrentFiltersDefault(): boolean {
    return isEqual(this._filters, this.defaultFiltersConfig)
  }

  get mappedCurrentFiltersToResponse(): Partial<ElevatorAdvertOfferApiAdminGetElevatorOfferResponsesRequest> {
    return this.areCurrentFiltersDefault
      ? {}
      : {
          elevatorId: this._filters.elevatorId,
          elevatorAdvertId: this._filters.elevatorAdvertId,
          companyId: this._filters.companyId,
          statuses: this._filters.statuses,
          type: this._filters.offerType,
        }
  }
}

export const basisAdvertOffersAdminFilterStore = new BasisAdvertOffersFilterStore()
