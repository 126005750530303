import React from 'react'

import { Empty, Spin } from 'antd'
import { useNavigate, useParams } from 'react-router-dom'

import { AppHeader } from '~/layout'
import { appRoutes } from '~/router'
import { Text } from '~/ui-components'
import { useGetCompanyById } from '~hooks/company'
import { useGetElevatorProductTypes } from '~hooks/elevators'
import { BasisAdvertsByProductType } from '~pages/BasisPage/modules/BasisAdvert/BasisAdvertsByProductType/BasisAdvertsByProductType'

import * as S from './BasisCompanyAdverts.styled'

export const BasisCompanyAdverts: React.FC = () => {
  const { basisId: basisIdParams, companyId: companyIdParams } = useParams<{ basisId: string; companyId: string }>()
  const basisId = Number(basisIdParams)
  const companyId = Number(companyIdParams)
  const navigate = useNavigate()
  const getElevatorProductTypesFn = useGetElevatorProductTypes({ id: basisId, companyId })
  const getCompanyByIdFn = useGetCompanyById(companyId)

  const productTypes = getElevatorProductTypesFn.data?.elevatorProductTypes

  const onItemClickHandler = (advertId: number) => {
    navigate(appRoutes.basisAdvert.url.replace(':id', advertId.toString()))
  }

  return (
    <>
      <AppHeader title={appRoutes.basisCompanyAdverts.title} isBack />

      <Spin spinning={getElevatorProductTypesFn.isFetching || getCompanyByIdFn.isFetching}>
        <S.ContentWrapper>
          <Text variant="h4">{getCompanyByIdFn.data?.name}</Text>
          <Text variant="description">ИНН: {getCompanyByIdFn.data?.inn}</Text>

          <S.CompanyLIstWrapper>
            {!getElevatorProductTypesFn.isLoading && !productTypes?.length && (
              <Empty description="Культуры на базисе отсутстуют" />
            )}

            {productTypes?.map((productType) => (
              <BasisAdvertsByProductType
                key={productType.id}
                productTypeData={productType}
                elevatorId={basisId}
                companyId={companyId}
                onClick={onItemClickHandler}
              />
            ))}
          </S.CompanyLIstWrapper>
        </S.ContentWrapper>
      </Spin>
    </>
  )
}
