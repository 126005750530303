import React, { PropsWithChildren } from 'react'

import { Select } from 'antd'
import { BaseSelectRef } from 'rc-select'

import { getLoadType } from '~/utils/warehouses/get-load-type'
import { LoadingTransportType } from '~api/generated'
import { CustomControlInterface } from '~shared/controls/CustomControl.interface'

type Value = LoadingTransportType | null | undefined

interface IWarehouseLoadTypeControlProps extends CustomControlInterface {
  onChange: (value: Value) => void
  value: Value
  hasError?: boolean
  disabled?: boolean
  id?: string
}

interface IOption {
  value: LoadingTransportType
  label: string
}

const loadTypesOptions: IOption[] = [
  {
    label: getLoadType(LoadingTransportType.GrainThrower),
    value: LoadingTransportType.GrainThrower,
  },
  { label: getLoadType(LoadingTransportType.Elevator), value: LoadingTransportType.Elevator },
  {
    label: getLoadType(LoadingTransportType.Telehandler),
    value: LoadingTransportType.Telehandler,
  },
  {
    label: getLoadType(LoadingTransportType.FrontLoader),
    value: LoadingTransportType.FrontLoader,
  },
  { label: getLoadType(LoadingTransportType.VerticalPipe), value: LoadingTransportType.VerticalPipe },
]

export const WarehouseLoadTypeControl: React.FC<PropsWithChildren<IWarehouseLoadTypeControlProps>> = React.forwardRef<
  BaseSelectRef,
  PropsWithChildren<IWarehouseLoadTypeControlProps>
>(({ id, value, onChange, hasError, disabled }, ref) => {
  const onSelectAddressHandler = (v: Value): void => {
    onChange(v ?? null)
  }

  return (
    <Select
      ref={ref}
      id={id}
      style={{ width: '100%' }}
      placeholder="Выберите способ погрузки"
      value={value}
      disabled={disabled}
      onChange={onSelectAddressHandler}
      status={hasError ? 'error' : undefined}
      optionFilterProp="children"
      allowClear
    >
      {loadTypesOptions.map((it) => (
        <Select.Option key={it.value} value={it.value}>
          {it.label}
        </Select.Option>
      ))}
    </Select>
  )
})

WarehouseLoadTypeControl.displayName = 'WarehouseLoadTypeControl'
