import React, { useState } from 'react'

import { Button, Modal, Skeleton } from 'antd'

import { httpErrorHandler, modalSharedSettings } from '~/utils'
import { useGetElevatorAdvert } from '~hooks/elevator-advert'
import { useCreateElevatorAdvertOfferFromBuyer } from '~hooks/elevator-advert-offers'
import { CreateAdvertOfferAgreementStep } from '~pages/BasisPage/components/CreateAdvertBuyOfferModal/CreateAdvertOfferAgreementStep'
import { CreateBuyAdvertOfferForm } from '~pages/BasisPage/components/CreateBuyAdvertOfferForm'

import { CreateAdvertOfferSuccessStep } from './CreateAdvertOfferSuccessStep'

import { CreateBuyAdvertOfferFormValues } from '~pages/BasisPage/components/CreateBuyAdvertOfferForm/CreateBuyAdvertOfferForm.types'

const FORM_ID = 'create-advert-offer-form'

interface CreateAdvertOfferModalProps {
  isOpen: boolean
  onCancel: () => void
  advertId: number
  userCompanyId: number
}

export const CreateAdvertBuyOfferModal: React.FC<CreateAdvertOfferModalProps> = (props) => {
  const useGetElevatorAdvertFn = useGetElevatorAdvert({ id: props.advertId, userCompanyId: props.userCompanyId })
  const createElevatorAdvertOfferFromBuyerFn = useCreateElevatorAdvertOfferFromBuyer()
  const [isShowAgreementStep, setShowAgreementStep] = useState(true)
  const [isShowSuccessStep, setShowSuccessStep] = useState(false)
  const advert = useGetElevatorAdvertFn.data

  const onCancelHandler = () => props.onCancel()
  const onSubmitHandler = async (formValues: CreateBuyAdvertOfferFormValues) => {
    try {
      const res = await createElevatorAdvertOfferFromBuyerFn.mutateAsync({
        createOfferResponseFromBuyerBody: {
          userCompanyId: props.userCompanyId,
          elevatorAdvertId: props.advertId,
          includingNds: formValues.usingNds,
          volume: formValues.volume,
          price: formValues.pricePerTon,
        },
      })
      setShowSuccessStep(true)
    } catch (e) {
      httpErrorHandler(e, 'Ошибка при сохранении отклика на объявление')
    }
  }

  const renderModalContent = () => {
    if (!advert || useGetElevatorAdvertFn.isFetching) {
      return <Skeleton loading />
    }

    if (isShowAgreementStep) {
      return (
        <CreateAdvertOfferAgreementStep
          showActions
          onClose={onCancelHandler}
          onNext={() => setShowAgreementStep(false)}
        />
      )
    }

    if (isShowSuccessStep) {
      return <CreateAdvertOfferSuccessStep onClose={onCancelHandler} advertId={8} />
    }

    return (
      <CreateBuyAdvertOfferForm
        formId={FORM_ID}
        productData={advert?.product}
        onSubmit={onSubmitHandler}
        maxAvailableAdvertVolume={advert.volume ?? 0}
      />
    )
  }

  return (
    <Modal
      {...modalSharedSettings}
      width={isShowSuccessStep ? 400 : 560}
      open={props.isOpen}
      onCancel={onCancelHandler}
      title={isShowSuccessStep ? '' : 'Купить объем'}
      footer={
        isShowSuccessStep || isShowAgreementStep
          ? []
          : [
              <Button key="cancel" onClick={onCancelHandler} htmlType="button">
                Отмена
              </Button>,
              <Button
                form={FORM_ID}
                key="submit"
                type="primary"
                htmlType="submit"
                loading={createElevatorAdvertOfferFromBuyerFn.isLoading}
              >
                Купить объем
              </Button>,
            ]
      }
    >
      {renderModalContent()}
    </Modal>
  )
}
