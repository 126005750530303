import React from 'react'
import { Flex, TimelineProps } from 'antd'
import * as S from './Timeline.styled'

export const Timeline: React.FC<TimelineProps> = React.forwardRef<HTMLDivElement, TimelineProps>((props, ref) => {
  return (
    <Flex ref={ref} gap={24} style={{ width: '100%' }}>
      <S.TimelineStyled {...props} />
    </Flex>
  )
})
