import { adminOffersFilterStore, myOffersFilterStore, OffersFilterStore, offersFilterStore } from '~/store/filters'

import { IOffersFilterProps } from '~pages/OffersPage/components/OffersFilter/OffersFilter.types'

export function getStore(store: IOffersFilterProps['store']): OffersFilterStore {
  switch (store) {
    case 'admin':
      return adminOffersFilterStore
    case 'offers':
      return offersFilterStore
    case 'myOffers':
      return myOffersFilterStore
    default:
      return offersFilterStore
  }
}
