import React from 'react'

import { RouteObject } from 'react-router-dom'

import { appRoutes } from '~/router'
import AuctionsPage, {
  AuctionLotDetails,
  AuctionLotsList,
  AuctionMyLotDetails,
  AuctionMyLots,
  AuctionMyResponseLotDetails,
  AuctionMyResponses,
} from '~pages/Auctions'

import { RequireAuth } from '../../providers/auth'

export const auctionRouter: RouteObject[] = [
  {
    path: appRoutes.auctions.url,
    element: (
      <RequireAuth>
        <AuctionsPage />
      </RequireAuth>
    ),
    children: [
      {
        path: appRoutes.auctionLotsList.url,
        element: <AuctionLotsList />,
      },
      {
        path: appRoutes.auctionsMyLots.url,
        element: <AuctionMyLots />,
      },
      {
        path: appRoutes.auctionsMyResponses.url,
        element: <AuctionMyResponses />,
      },
    ],
  },
  {
    path: appRoutes.auctionLotsListDetails.url,
    element: (
      <RequireAuth>
        <AuctionLotDetails />
      </RequireAuth>
    ),
  },
  {
    path: appRoutes.auctionsMyLotsDetails.url,
    element: (
      <RequireAuth>
        <AuctionMyLotDetails />
      </RequireAuth>
    ),
  },
  {
    path: appRoutes.auctionsMyResponsesDetails.url,
    element: (
      <RequireAuth>
        <AuctionMyResponseLotDetails />
      </RequireAuth>
    ),
  },
]
