import React from 'react'

import { Skeleton } from 'antd'

import { MyUserEntity } from '~pages/EmployeesPage/components/EmployeesList/EmployeesList'

import { UserRequestedList } from './components/UserRequested/UserRequestedList'

import * as S from './CompanyEmployee.styled'
import { UserRole } from '~api/gql-generated/graphql'

interface CompanyEmployeeProps {
  users: MyUserEntity[]
  loading: boolean
  onUserReject: (userId: number) => void
  onUserAccept: (userId: number, role: UserRole) => void
}

export const CompanyEmployeeRequested: React.FC<CompanyEmployeeProps> = (props) => {
  return (
    <S.Wrapper>
      <S.Header>
        <S.Title variant="h3">Запросы</S.Title>
      </S.Header>

      <Skeleton loading={props.loading} />

      {!props.loading && (
        <UserRequestedList users={props.users} onUserReject={props.onUserReject} onUserAccept={props.onUserAccept} />
      )}
    </S.Wrapper>
  )
}
