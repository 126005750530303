/* tslint:disable */
/* eslint-disable */
/**
 * API Зерно-Агрегатора
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AddDealDocumentFilesBody } from '../models';
// @ts-ignore
import { CreateDealDocumentBody } from '../models';
// @ts-ignore
import { CreateDealDocumentResponse } from '../models';
// @ts-ignore
import { GetDealDocumentTemplatesResponse } from '../models';
// @ts-ignore
import { GetDealDocumentsResponse } from '../models';
// @ts-ignore
import { UpdateDealDocumentFileBody } from '../models';
/**
 * DealDocumentApi - axios parameter creator
 * @export
 */
export const DealDocumentApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} documentId 
         * @param {AddDealDocumentFilesBody} addDealDocumentFilesBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dealDocumentControllerAddDocumentFiles: async (documentId: number, addDealDocumentFilesBody: AddDealDocumentFilesBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'documentId' is not null or undefined
            assertParamExists('dealDocumentControllerAddDocumentFiles', 'documentId', documentId)
            // verify required parameter 'addDealDocumentFilesBody' is not null or undefined
            assertParamExists('dealDocumentControllerAddDocumentFiles', 'addDealDocumentFilesBody', addDealDocumentFilesBody)
            const localVarPath = `/deal-document/{documentId}/files`
                .replace(`{${"documentId"}}`, encodeURIComponent(String(documentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addDealDocumentFilesBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} dealId 
         * @param {'specification' | 'laboratory-analysis' | 'supply-contract' | 'agency-contract' | 'agency-order' | 'signed-agency-contract' | 'signed-agency-order' | 'signed-specification' | 'signed-supply-contract' | 'invoice-for-supply-payment' | 'payment-order-under-the-supply-contract' | 'invoice-for-goods' | 'act-of-completed-work' | 'reconciliation-act-from-seller' | 'reconciliation-act-from-buyer' | 'declaration-of-conformity-with-cu-regulations' | 'certificate-for-use-of-pesticides' | 'certificate-of-absence-of-gmo' | 'certificate-of-origin-of-goods' | 'quarantine-certificate' | 'phytosanitary-certificate' | 'veterinary-certificate-for-goods' | 'shipping-document' | 'bill-of-lading' | 'consignment-note' | 'shipping-invoice' | 'agent-report' | 'invoice-for-agency-payment' | 'act-of-completed-agency-work' | 'payment-order-under-agency-contract'} documentType Тип документа
         * @param {CreateDealDocumentBody} createDealDocumentBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dealDocumentControllerCreateDocument: async (dealId: number, documentType: 'specification' | 'laboratory-analysis' | 'supply-contract' | 'agency-contract' | 'agency-order' | 'signed-agency-contract' | 'signed-agency-order' | 'signed-specification' | 'signed-supply-contract' | 'invoice-for-supply-payment' | 'payment-order-under-the-supply-contract' | 'invoice-for-goods' | 'act-of-completed-work' | 'reconciliation-act-from-seller' | 'reconciliation-act-from-buyer' | 'declaration-of-conformity-with-cu-regulations' | 'certificate-for-use-of-pesticides' | 'certificate-of-absence-of-gmo' | 'certificate-of-origin-of-goods' | 'quarantine-certificate' | 'phytosanitary-certificate' | 'veterinary-certificate-for-goods' | 'shipping-document' | 'bill-of-lading' | 'consignment-note' | 'shipping-invoice' | 'agent-report' | 'invoice-for-agency-payment' | 'act-of-completed-agency-work' | 'payment-order-under-agency-contract', createDealDocumentBody: CreateDealDocumentBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dealId' is not null or undefined
            assertParamExists('dealDocumentControllerCreateDocument', 'dealId', dealId)
            // verify required parameter 'documentType' is not null or undefined
            assertParamExists('dealDocumentControllerCreateDocument', 'documentType', documentType)
            // verify required parameter 'createDealDocumentBody' is not null or undefined
            assertParamExists('dealDocumentControllerCreateDocument', 'createDealDocumentBody', createDealDocumentBody)
            const localVarPath = `/deal/{dealId}/document/{documentType}`
                .replace(`{${"dealId"}}`, encodeURIComponent(String(dealId)))
                .replace(`{${"documentType"}}`, encodeURIComponent(String(documentType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createDealDocumentBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {'specification' | 'laboratory-analysis' | 'supply-contract' | 'agency-contract' | 'agency-order' | 'signed-agency-contract' | 'signed-agency-order' | 'signed-specification' | 'signed-supply-contract' | 'invoice-for-supply-payment' | 'payment-order-under-the-supply-contract' | 'invoice-for-goods' | 'act-of-completed-work' | 'reconciliation-act-from-seller' | 'reconciliation-act-from-buyer' | 'declaration-of-conformity-with-cu-regulations' | 'certificate-for-use-of-pesticides' | 'certificate-of-absence-of-gmo' | 'certificate-of-origin-of-goods' | 'quarantine-certificate' | 'phytosanitary-certificate' | 'veterinary-certificate-for-goods' | 'shipping-document' | 'bill-of-lading' | 'consignment-note' | 'shipping-invoice' | 'agent-report' | 'invoice-for-agency-payment' | 'act-of-completed-agency-work' | 'payment-order-under-agency-contract'} documentType Тип документа
         * @param {CreateDealDocumentBody} createDealDocumentBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dealDocumentControllerCreateDocumentTemplate: async (documentType: 'specification' | 'laboratory-analysis' | 'supply-contract' | 'agency-contract' | 'agency-order' | 'signed-agency-contract' | 'signed-agency-order' | 'signed-specification' | 'signed-supply-contract' | 'invoice-for-supply-payment' | 'payment-order-under-the-supply-contract' | 'invoice-for-goods' | 'act-of-completed-work' | 'reconciliation-act-from-seller' | 'reconciliation-act-from-buyer' | 'declaration-of-conformity-with-cu-regulations' | 'certificate-for-use-of-pesticides' | 'certificate-of-absence-of-gmo' | 'certificate-of-origin-of-goods' | 'quarantine-certificate' | 'phytosanitary-certificate' | 'veterinary-certificate-for-goods' | 'shipping-document' | 'bill-of-lading' | 'consignment-note' | 'shipping-invoice' | 'agent-report' | 'invoice-for-agency-payment' | 'act-of-completed-agency-work' | 'payment-order-under-agency-contract', createDealDocumentBody: CreateDealDocumentBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'documentType' is not null or undefined
            assertParamExists('dealDocumentControllerCreateDocumentTemplate', 'documentType', documentType)
            // verify required parameter 'createDealDocumentBody' is not null or undefined
            assertParamExists('dealDocumentControllerCreateDocumentTemplate', 'createDealDocumentBody', createDealDocumentBody)
            const localVarPath = `/deal/document-template/{documentType}`
                .replace(`{${"documentType"}}`, encodeURIComponent(String(documentType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createDealDocumentBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dealDocumentControllerDeleteDocument: async (documentId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'documentId' is not null or undefined
            assertParamExists('dealDocumentControllerDeleteDocument', 'documentId', documentId)
            const localVarPath = `/deal-document/{documentId}`
                .replace(`{${"documentId"}}`, encodeURIComponent(String(documentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} fileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dealDocumentControllerDeleteDocumentFile: async (fileId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fileId' is not null or undefined
            assertParamExists('dealDocumentControllerDeleteDocumentFile', 'fileId', fileId)
            const localVarPath = `/deal-document-file/{fileId}`
                .replace(`{${"fileId"}}`, encodeURIComponent(String(fileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dealDocumentControllerDeleteDocumentTemplate: async (documentId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'documentId' is not null or undefined
            assertParamExists('dealDocumentControllerDeleteDocumentTemplate', 'documentId', documentId)
            const localVarPath = `/deal-document-template/{documentId}`
                .replace(`{${"documentId"}}`, encodeURIComponent(String(documentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<'specification' | 'laboratory-analysis' | 'supply-contract' | 'agency-contract' | 'agency-order' | 'signed-agency-contract' | 'signed-agency-order' | 'signed-specification' | 'signed-supply-contract' | 'invoice-for-supply-payment' | 'payment-order-under-the-supply-contract' | 'invoice-for-goods' | 'act-of-completed-work' | 'reconciliation-act-from-seller' | 'reconciliation-act-from-buyer' | 'declaration-of-conformity-with-cu-regulations' | 'certificate-for-use-of-pesticides' | 'certificate-of-absence-of-gmo' | 'certificate-of-origin-of-goods' | 'quarantine-certificate' | 'phytosanitary-certificate' | 'veterinary-certificate-for-goods' | 'shipping-document' | 'bill-of-lading' | 'consignment-note' | 'shipping-invoice' | 'agent-report' | 'invoice-for-agency-payment' | 'act-of-completed-agency-work' | 'payment-order-under-agency-contract'>} [types] Типы документов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dealDocumentControllerGetDocumentTemplates: async (types?: Array<'specification' | 'laboratory-analysis' | 'supply-contract' | 'agency-contract' | 'agency-order' | 'signed-agency-contract' | 'signed-agency-order' | 'signed-specification' | 'signed-supply-contract' | 'invoice-for-supply-payment' | 'payment-order-under-the-supply-contract' | 'invoice-for-goods' | 'act-of-completed-work' | 'reconciliation-act-from-seller' | 'reconciliation-act-from-buyer' | 'declaration-of-conformity-with-cu-regulations' | 'certificate-for-use-of-pesticides' | 'certificate-of-absence-of-gmo' | 'certificate-of-origin-of-goods' | 'quarantine-certificate' | 'phytosanitary-certificate' | 'veterinary-certificate-for-goods' | 'shipping-document' | 'bill-of-lading' | 'consignment-note' | 'shipping-invoice' | 'agent-report' | 'invoice-for-agency-payment' | 'act-of-completed-agency-work' | 'payment-order-under-agency-contract'>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/deal/document-templates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (types) {
                localVarQueryParameter['types'] = types;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} dealId 
         * @param {Array<'specification' | 'laboratory-analysis' | 'supply-contract' | 'agency-contract' | 'agency-order' | 'signed-agency-contract' | 'signed-agency-order' | 'signed-specification' | 'signed-supply-contract' | 'invoice-for-supply-payment' | 'payment-order-under-the-supply-contract' | 'invoice-for-goods' | 'act-of-completed-work' | 'reconciliation-act-from-seller' | 'reconciliation-act-from-buyer' | 'declaration-of-conformity-with-cu-regulations' | 'certificate-for-use-of-pesticides' | 'certificate-of-absence-of-gmo' | 'certificate-of-origin-of-goods' | 'quarantine-certificate' | 'phytosanitary-certificate' | 'veterinary-certificate-for-goods' | 'shipping-document' | 'bill-of-lading' | 'consignment-note' | 'shipping-invoice' | 'agent-report' | 'invoice-for-agency-payment' | 'act-of-completed-agency-work' | 'payment-order-under-agency-contract'>} [types] Типы документов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dealDocumentControllerGetDocuments: async (dealId: number, types?: Array<'specification' | 'laboratory-analysis' | 'supply-contract' | 'agency-contract' | 'agency-order' | 'signed-agency-contract' | 'signed-agency-order' | 'signed-specification' | 'signed-supply-contract' | 'invoice-for-supply-payment' | 'payment-order-under-the-supply-contract' | 'invoice-for-goods' | 'act-of-completed-work' | 'reconciliation-act-from-seller' | 'reconciliation-act-from-buyer' | 'declaration-of-conformity-with-cu-regulations' | 'certificate-for-use-of-pesticides' | 'certificate-of-absence-of-gmo' | 'certificate-of-origin-of-goods' | 'quarantine-certificate' | 'phytosanitary-certificate' | 'veterinary-certificate-for-goods' | 'shipping-document' | 'bill-of-lading' | 'consignment-note' | 'shipping-invoice' | 'agent-report' | 'invoice-for-agency-payment' | 'act-of-completed-agency-work' | 'payment-order-under-agency-contract'>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dealId' is not null or undefined
            assertParamExists('dealDocumentControllerGetDocuments', 'dealId', dealId)
            const localVarPath = `/deal/{dealId}/documents`
                .replace(`{${"dealId"}}`, encodeURIComponent(String(dealId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (types) {
                localVarQueryParameter['types'] = types;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} dealId 
         * @param {Array<number>} [documentIds] Массив с id документов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dealDocumentControllerGetDocumentsZip: async (dealId: number, documentIds?: Array<number>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dealId' is not null or undefined
            assertParamExists('dealDocumentControllerGetDocumentsZip', 'dealId', dealId)
            const localVarPath = `/deal/{dealId}/documents/zip`
                .replace(`{${"dealId"}}`, encodeURIComponent(String(dealId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (documentIds) {
                localVarQueryParameter['document-ids'] = documentIds;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} fileId 
         * @param {UpdateDealDocumentFileBody} updateDealDocumentFileBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dealDocumentControllerUpdateDocumentFile: async (fileId: number, updateDealDocumentFileBody: UpdateDealDocumentFileBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fileId' is not null or undefined
            assertParamExists('dealDocumentControllerUpdateDocumentFile', 'fileId', fileId)
            // verify required parameter 'updateDealDocumentFileBody' is not null or undefined
            assertParamExists('dealDocumentControllerUpdateDocumentFile', 'updateDealDocumentFileBody', updateDealDocumentFileBody)
            const localVarPath = `/deal-document-file/{fileId}`
                .replace(`{${"fileId"}}`, encodeURIComponent(String(fileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateDealDocumentFileBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DealDocumentApi - functional programming interface
 * @export
 */
export const DealDocumentApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DealDocumentApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} documentId 
         * @param {AddDealDocumentFilesBody} addDealDocumentFilesBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dealDocumentControllerAddDocumentFiles(documentId: number, addDealDocumentFilesBody: AddDealDocumentFilesBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dealDocumentControllerAddDocumentFiles(documentId, addDealDocumentFilesBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} dealId 
         * @param {'specification' | 'laboratory-analysis' | 'supply-contract' | 'agency-contract' | 'agency-order' | 'signed-agency-contract' | 'signed-agency-order' | 'signed-specification' | 'signed-supply-contract' | 'invoice-for-supply-payment' | 'payment-order-under-the-supply-contract' | 'invoice-for-goods' | 'act-of-completed-work' | 'reconciliation-act-from-seller' | 'reconciliation-act-from-buyer' | 'declaration-of-conformity-with-cu-regulations' | 'certificate-for-use-of-pesticides' | 'certificate-of-absence-of-gmo' | 'certificate-of-origin-of-goods' | 'quarantine-certificate' | 'phytosanitary-certificate' | 'veterinary-certificate-for-goods' | 'shipping-document' | 'bill-of-lading' | 'consignment-note' | 'shipping-invoice' | 'agent-report' | 'invoice-for-agency-payment' | 'act-of-completed-agency-work' | 'payment-order-under-agency-contract'} documentType Тип документа
         * @param {CreateDealDocumentBody} createDealDocumentBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dealDocumentControllerCreateDocument(dealId: number, documentType: 'specification' | 'laboratory-analysis' | 'supply-contract' | 'agency-contract' | 'agency-order' | 'signed-agency-contract' | 'signed-agency-order' | 'signed-specification' | 'signed-supply-contract' | 'invoice-for-supply-payment' | 'payment-order-under-the-supply-contract' | 'invoice-for-goods' | 'act-of-completed-work' | 'reconciliation-act-from-seller' | 'reconciliation-act-from-buyer' | 'declaration-of-conformity-with-cu-regulations' | 'certificate-for-use-of-pesticides' | 'certificate-of-absence-of-gmo' | 'certificate-of-origin-of-goods' | 'quarantine-certificate' | 'phytosanitary-certificate' | 'veterinary-certificate-for-goods' | 'shipping-document' | 'bill-of-lading' | 'consignment-note' | 'shipping-invoice' | 'agent-report' | 'invoice-for-agency-payment' | 'act-of-completed-agency-work' | 'payment-order-under-agency-contract', createDealDocumentBody: CreateDealDocumentBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateDealDocumentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dealDocumentControllerCreateDocument(dealId, documentType, createDealDocumentBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {'specification' | 'laboratory-analysis' | 'supply-contract' | 'agency-contract' | 'agency-order' | 'signed-agency-contract' | 'signed-agency-order' | 'signed-specification' | 'signed-supply-contract' | 'invoice-for-supply-payment' | 'payment-order-under-the-supply-contract' | 'invoice-for-goods' | 'act-of-completed-work' | 'reconciliation-act-from-seller' | 'reconciliation-act-from-buyer' | 'declaration-of-conformity-with-cu-regulations' | 'certificate-for-use-of-pesticides' | 'certificate-of-absence-of-gmo' | 'certificate-of-origin-of-goods' | 'quarantine-certificate' | 'phytosanitary-certificate' | 'veterinary-certificate-for-goods' | 'shipping-document' | 'bill-of-lading' | 'consignment-note' | 'shipping-invoice' | 'agent-report' | 'invoice-for-agency-payment' | 'act-of-completed-agency-work' | 'payment-order-under-agency-contract'} documentType Тип документа
         * @param {CreateDealDocumentBody} createDealDocumentBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dealDocumentControllerCreateDocumentTemplate(documentType: 'specification' | 'laboratory-analysis' | 'supply-contract' | 'agency-contract' | 'agency-order' | 'signed-agency-contract' | 'signed-agency-order' | 'signed-specification' | 'signed-supply-contract' | 'invoice-for-supply-payment' | 'payment-order-under-the-supply-contract' | 'invoice-for-goods' | 'act-of-completed-work' | 'reconciliation-act-from-seller' | 'reconciliation-act-from-buyer' | 'declaration-of-conformity-with-cu-regulations' | 'certificate-for-use-of-pesticides' | 'certificate-of-absence-of-gmo' | 'certificate-of-origin-of-goods' | 'quarantine-certificate' | 'phytosanitary-certificate' | 'veterinary-certificate-for-goods' | 'shipping-document' | 'bill-of-lading' | 'consignment-note' | 'shipping-invoice' | 'agent-report' | 'invoice-for-agency-payment' | 'act-of-completed-agency-work' | 'payment-order-under-agency-contract', createDealDocumentBody: CreateDealDocumentBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateDealDocumentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dealDocumentControllerCreateDocumentTemplate(documentType, createDealDocumentBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dealDocumentControllerDeleteDocument(documentId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dealDocumentControllerDeleteDocument(documentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} fileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dealDocumentControllerDeleteDocumentFile(fileId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dealDocumentControllerDeleteDocumentFile(fileId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dealDocumentControllerDeleteDocumentTemplate(documentId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dealDocumentControllerDeleteDocumentTemplate(documentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Array<'specification' | 'laboratory-analysis' | 'supply-contract' | 'agency-contract' | 'agency-order' | 'signed-agency-contract' | 'signed-agency-order' | 'signed-specification' | 'signed-supply-contract' | 'invoice-for-supply-payment' | 'payment-order-under-the-supply-contract' | 'invoice-for-goods' | 'act-of-completed-work' | 'reconciliation-act-from-seller' | 'reconciliation-act-from-buyer' | 'declaration-of-conformity-with-cu-regulations' | 'certificate-for-use-of-pesticides' | 'certificate-of-absence-of-gmo' | 'certificate-of-origin-of-goods' | 'quarantine-certificate' | 'phytosanitary-certificate' | 'veterinary-certificate-for-goods' | 'shipping-document' | 'bill-of-lading' | 'consignment-note' | 'shipping-invoice' | 'agent-report' | 'invoice-for-agency-payment' | 'act-of-completed-agency-work' | 'payment-order-under-agency-contract'>} [types] Типы документов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dealDocumentControllerGetDocumentTemplates(types?: Array<'specification' | 'laboratory-analysis' | 'supply-contract' | 'agency-contract' | 'agency-order' | 'signed-agency-contract' | 'signed-agency-order' | 'signed-specification' | 'signed-supply-contract' | 'invoice-for-supply-payment' | 'payment-order-under-the-supply-contract' | 'invoice-for-goods' | 'act-of-completed-work' | 'reconciliation-act-from-seller' | 'reconciliation-act-from-buyer' | 'declaration-of-conformity-with-cu-regulations' | 'certificate-for-use-of-pesticides' | 'certificate-of-absence-of-gmo' | 'certificate-of-origin-of-goods' | 'quarantine-certificate' | 'phytosanitary-certificate' | 'veterinary-certificate-for-goods' | 'shipping-document' | 'bill-of-lading' | 'consignment-note' | 'shipping-invoice' | 'agent-report' | 'invoice-for-agency-payment' | 'act-of-completed-agency-work' | 'payment-order-under-agency-contract'>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetDealDocumentTemplatesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dealDocumentControllerGetDocumentTemplates(types, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} dealId 
         * @param {Array<'specification' | 'laboratory-analysis' | 'supply-contract' | 'agency-contract' | 'agency-order' | 'signed-agency-contract' | 'signed-agency-order' | 'signed-specification' | 'signed-supply-contract' | 'invoice-for-supply-payment' | 'payment-order-under-the-supply-contract' | 'invoice-for-goods' | 'act-of-completed-work' | 'reconciliation-act-from-seller' | 'reconciliation-act-from-buyer' | 'declaration-of-conformity-with-cu-regulations' | 'certificate-for-use-of-pesticides' | 'certificate-of-absence-of-gmo' | 'certificate-of-origin-of-goods' | 'quarantine-certificate' | 'phytosanitary-certificate' | 'veterinary-certificate-for-goods' | 'shipping-document' | 'bill-of-lading' | 'consignment-note' | 'shipping-invoice' | 'agent-report' | 'invoice-for-agency-payment' | 'act-of-completed-agency-work' | 'payment-order-under-agency-contract'>} [types] Типы документов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dealDocumentControllerGetDocuments(dealId: number, types?: Array<'specification' | 'laboratory-analysis' | 'supply-contract' | 'agency-contract' | 'agency-order' | 'signed-agency-contract' | 'signed-agency-order' | 'signed-specification' | 'signed-supply-contract' | 'invoice-for-supply-payment' | 'payment-order-under-the-supply-contract' | 'invoice-for-goods' | 'act-of-completed-work' | 'reconciliation-act-from-seller' | 'reconciliation-act-from-buyer' | 'declaration-of-conformity-with-cu-regulations' | 'certificate-for-use-of-pesticides' | 'certificate-of-absence-of-gmo' | 'certificate-of-origin-of-goods' | 'quarantine-certificate' | 'phytosanitary-certificate' | 'veterinary-certificate-for-goods' | 'shipping-document' | 'bill-of-lading' | 'consignment-note' | 'shipping-invoice' | 'agent-report' | 'invoice-for-agency-payment' | 'act-of-completed-agency-work' | 'payment-order-under-agency-contract'>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetDealDocumentsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dealDocumentControllerGetDocuments(dealId, types, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} dealId 
         * @param {Array<number>} [documentIds] Массив с id документов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dealDocumentControllerGetDocumentsZip(dealId: number, documentIds?: Array<number>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dealDocumentControllerGetDocumentsZip(dealId, documentIds, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} fileId 
         * @param {UpdateDealDocumentFileBody} updateDealDocumentFileBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dealDocumentControllerUpdateDocumentFile(fileId: number, updateDealDocumentFileBody: UpdateDealDocumentFileBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dealDocumentControllerUpdateDocumentFile(fileId, updateDealDocumentFileBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DealDocumentApi - factory interface
 * @export
 */
export const DealDocumentApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DealDocumentApiFp(configuration)
    return {
        /**
         * 
         * @param {number} documentId 
         * @param {AddDealDocumentFilesBody} addDealDocumentFilesBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dealDocumentControllerAddDocumentFiles(documentId: number, addDealDocumentFilesBody: AddDealDocumentFilesBody, options?: any): AxiosPromise<void> {
            return localVarFp.dealDocumentControllerAddDocumentFiles(documentId, addDealDocumentFilesBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} dealId 
         * @param {'specification' | 'laboratory-analysis' | 'supply-contract' | 'agency-contract' | 'agency-order' | 'signed-agency-contract' | 'signed-agency-order' | 'signed-specification' | 'signed-supply-contract' | 'invoice-for-supply-payment' | 'payment-order-under-the-supply-contract' | 'invoice-for-goods' | 'act-of-completed-work' | 'reconciliation-act-from-seller' | 'reconciliation-act-from-buyer' | 'declaration-of-conformity-with-cu-regulations' | 'certificate-for-use-of-pesticides' | 'certificate-of-absence-of-gmo' | 'certificate-of-origin-of-goods' | 'quarantine-certificate' | 'phytosanitary-certificate' | 'veterinary-certificate-for-goods' | 'shipping-document' | 'bill-of-lading' | 'consignment-note' | 'shipping-invoice' | 'agent-report' | 'invoice-for-agency-payment' | 'act-of-completed-agency-work' | 'payment-order-under-agency-contract'} documentType Тип документа
         * @param {CreateDealDocumentBody} createDealDocumentBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dealDocumentControllerCreateDocument(dealId: number, documentType: 'specification' | 'laboratory-analysis' | 'supply-contract' | 'agency-contract' | 'agency-order' | 'signed-agency-contract' | 'signed-agency-order' | 'signed-specification' | 'signed-supply-contract' | 'invoice-for-supply-payment' | 'payment-order-under-the-supply-contract' | 'invoice-for-goods' | 'act-of-completed-work' | 'reconciliation-act-from-seller' | 'reconciliation-act-from-buyer' | 'declaration-of-conformity-with-cu-regulations' | 'certificate-for-use-of-pesticides' | 'certificate-of-absence-of-gmo' | 'certificate-of-origin-of-goods' | 'quarantine-certificate' | 'phytosanitary-certificate' | 'veterinary-certificate-for-goods' | 'shipping-document' | 'bill-of-lading' | 'consignment-note' | 'shipping-invoice' | 'agent-report' | 'invoice-for-agency-payment' | 'act-of-completed-agency-work' | 'payment-order-under-agency-contract', createDealDocumentBody: CreateDealDocumentBody, options?: any): AxiosPromise<CreateDealDocumentResponse> {
            return localVarFp.dealDocumentControllerCreateDocument(dealId, documentType, createDealDocumentBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {'specification' | 'laboratory-analysis' | 'supply-contract' | 'agency-contract' | 'agency-order' | 'signed-agency-contract' | 'signed-agency-order' | 'signed-specification' | 'signed-supply-contract' | 'invoice-for-supply-payment' | 'payment-order-under-the-supply-contract' | 'invoice-for-goods' | 'act-of-completed-work' | 'reconciliation-act-from-seller' | 'reconciliation-act-from-buyer' | 'declaration-of-conformity-with-cu-regulations' | 'certificate-for-use-of-pesticides' | 'certificate-of-absence-of-gmo' | 'certificate-of-origin-of-goods' | 'quarantine-certificate' | 'phytosanitary-certificate' | 'veterinary-certificate-for-goods' | 'shipping-document' | 'bill-of-lading' | 'consignment-note' | 'shipping-invoice' | 'agent-report' | 'invoice-for-agency-payment' | 'act-of-completed-agency-work' | 'payment-order-under-agency-contract'} documentType Тип документа
         * @param {CreateDealDocumentBody} createDealDocumentBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dealDocumentControllerCreateDocumentTemplate(documentType: 'specification' | 'laboratory-analysis' | 'supply-contract' | 'agency-contract' | 'agency-order' | 'signed-agency-contract' | 'signed-agency-order' | 'signed-specification' | 'signed-supply-contract' | 'invoice-for-supply-payment' | 'payment-order-under-the-supply-contract' | 'invoice-for-goods' | 'act-of-completed-work' | 'reconciliation-act-from-seller' | 'reconciliation-act-from-buyer' | 'declaration-of-conformity-with-cu-regulations' | 'certificate-for-use-of-pesticides' | 'certificate-of-absence-of-gmo' | 'certificate-of-origin-of-goods' | 'quarantine-certificate' | 'phytosanitary-certificate' | 'veterinary-certificate-for-goods' | 'shipping-document' | 'bill-of-lading' | 'consignment-note' | 'shipping-invoice' | 'agent-report' | 'invoice-for-agency-payment' | 'act-of-completed-agency-work' | 'payment-order-under-agency-contract', createDealDocumentBody: CreateDealDocumentBody, options?: any): AxiosPromise<CreateDealDocumentResponse> {
            return localVarFp.dealDocumentControllerCreateDocumentTemplate(documentType, createDealDocumentBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dealDocumentControllerDeleteDocument(documentId: number, options?: any): AxiosPromise<void> {
            return localVarFp.dealDocumentControllerDeleteDocument(documentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} fileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dealDocumentControllerDeleteDocumentFile(fileId: number, options?: any): AxiosPromise<void> {
            return localVarFp.dealDocumentControllerDeleteDocumentFile(fileId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dealDocumentControllerDeleteDocumentTemplate(documentId: number, options?: any): AxiosPromise<void> {
            return localVarFp.dealDocumentControllerDeleteDocumentTemplate(documentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<'specification' | 'laboratory-analysis' | 'supply-contract' | 'agency-contract' | 'agency-order' | 'signed-agency-contract' | 'signed-agency-order' | 'signed-specification' | 'signed-supply-contract' | 'invoice-for-supply-payment' | 'payment-order-under-the-supply-contract' | 'invoice-for-goods' | 'act-of-completed-work' | 'reconciliation-act-from-seller' | 'reconciliation-act-from-buyer' | 'declaration-of-conformity-with-cu-regulations' | 'certificate-for-use-of-pesticides' | 'certificate-of-absence-of-gmo' | 'certificate-of-origin-of-goods' | 'quarantine-certificate' | 'phytosanitary-certificate' | 'veterinary-certificate-for-goods' | 'shipping-document' | 'bill-of-lading' | 'consignment-note' | 'shipping-invoice' | 'agent-report' | 'invoice-for-agency-payment' | 'act-of-completed-agency-work' | 'payment-order-under-agency-contract'>} [types] Типы документов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dealDocumentControllerGetDocumentTemplates(types?: Array<'specification' | 'laboratory-analysis' | 'supply-contract' | 'agency-contract' | 'agency-order' | 'signed-agency-contract' | 'signed-agency-order' | 'signed-specification' | 'signed-supply-contract' | 'invoice-for-supply-payment' | 'payment-order-under-the-supply-contract' | 'invoice-for-goods' | 'act-of-completed-work' | 'reconciliation-act-from-seller' | 'reconciliation-act-from-buyer' | 'declaration-of-conformity-with-cu-regulations' | 'certificate-for-use-of-pesticides' | 'certificate-of-absence-of-gmo' | 'certificate-of-origin-of-goods' | 'quarantine-certificate' | 'phytosanitary-certificate' | 'veterinary-certificate-for-goods' | 'shipping-document' | 'bill-of-lading' | 'consignment-note' | 'shipping-invoice' | 'agent-report' | 'invoice-for-agency-payment' | 'act-of-completed-agency-work' | 'payment-order-under-agency-contract'>, options?: any): AxiosPromise<GetDealDocumentTemplatesResponse> {
            return localVarFp.dealDocumentControllerGetDocumentTemplates(types, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} dealId 
         * @param {Array<'specification' | 'laboratory-analysis' | 'supply-contract' | 'agency-contract' | 'agency-order' | 'signed-agency-contract' | 'signed-agency-order' | 'signed-specification' | 'signed-supply-contract' | 'invoice-for-supply-payment' | 'payment-order-under-the-supply-contract' | 'invoice-for-goods' | 'act-of-completed-work' | 'reconciliation-act-from-seller' | 'reconciliation-act-from-buyer' | 'declaration-of-conformity-with-cu-regulations' | 'certificate-for-use-of-pesticides' | 'certificate-of-absence-of-gmo' | 'certificate-of-origin-of-goods' | 'quarantine-certificate' | 'phytosanitary-certificate' | 'veterinary-certificate-for-goods' | 'shipping-document' | 'bill-of-lading' | 'consignment-note' | 'shipping-invoice' | 'agent-report' | 'invoice-for-agency-payment' | 'act-of-completed-agency-work' | 'payment-order-under-agency-contract'>} [types] Типы документов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dealDocumentControllerGetDocuments(dealId: number, types?: Array<'specification' | 'laboratory-analysis' | 'supply-contract' | 'agency-contract' | 'agency-order' | 'signed-agency-contract' | 'signed-agency-order' | 'signed-specification' | 'signed-supply-contract' | 'invoice-for-supply-payment' | 'payment-order-under-the-supply-contract' | 'invoice-for-goods' | 'act-of-completed-work' | 'reconciliation-act-from-seller' | 'reconciliation-act-from-buyer' | 'declaration-of-conformity-with-cu-regulations' | 'certificate-for-use-of-pesticides' | 'certificate-of-absence-of-gmo' | 'certificate-of-origin-of-goods' | 'quarantine-certificate' | 'phytosanitary-certificate' | 'veterinary-certificate-for-goods' | 'shipping-document' | 'bill-of-lading' | 'consignment-note' | 'shipping-invoice' | 'agent-report' | 'invoice-for-agency-payment' | 'act-of-completed-agency-work' | 'payment-order-under-agency-contract'>, options?: any): AxiosPromise<GetDealDocumentsResponse> {
            return localVarFp.dealDocumentControllerGetDocuments(dealId, types, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} dealId 
         * @param {Array<number>} [documentIds] Массив с id документов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dealDocumentControllerGetDocumentsZip(dealId: number, documentIds?: Array<number>, options?: any): AxiosPromise<void> {
            return localVarFp.dealDocumentControllerGetDocumentsZip(dealId, documentIds, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} fileId 
         * @param {UpdateDealDocumentFileBody} updateDealDocumentFileBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dealDocumentControllerUpdateDocumentFile(fileId: number, updateDealDocumentFileBody: UpdateDealDocumentFileBody, options?: any): AxiosPromise<void> {
            return localVarFp.dealDocumentControllerUpdateDocumentFile(fileId, updateDealDocumentFileBody, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for dealDocumentControllerAddDocumentFiles operation in DealDocumentApi.
 * @export
 * @interface DealDocumentApiDealDocumentControllerAddDocumentFilesRequest
 */
export interface DealDocumentApiDealDocumentControllerAddDocumentFilesRequest {
    /**
     * 
     * @type {number}
     * @memberof DealDocumentApiDealDocumentControllerAddDocumentFiles
     */
    readonly documentId: number

    /**
     * 
     * @type {AddDealDocumentFilesBody}
     * @memberof DealDocumentApiDealDocumentControllerAddDocumentFiles
     */
    readonly addDealDocumentFilesBody: AddDealDocumentFilesBody
}

/**
 * Request parameters for dealDocumentControllerCreateDocument operation in DealDocumentApi.
 * @export
 * @interface DealDocumentApiDealDocumentControllerCreateDocumentRequest
 */
export interface DealDocumentApiDealDocumentControllerCreateDocumentRequest {
    /**
     * 
     * @type {number}
     * @memberof DealDocumentApiDealDocumentControllerCreateDocument
     */
    readonly dealId: number

    /**
     * Тип документа
     * @type {'specification' | 'laboratory-analysis' | 'supply-contract' | 'agency-contract' | 'agency-order' | 'signed-agency-contract' | 'signed-agency-order' | 'signed-specification' | 'signed-supply-contract' | 'invoice-for-supply-payment' | 'payment-order-under-the-supply-contract' | 'invoice-for-goods' | 'act-of-completed-work' | 'reconciliation-act-from-seller' | 'reconciliation-act-from-buyer' | 'declaration-of-conformity-with-cu-regulations' | 'certificate-for-use-of-pesticides' | 'certificate-of-absence-of-gmo' | 'certificate-of-origin-of-goods' | 'quarantine-certificate' | 'phytosanitary-certificate' | 'veterinary-certificate-for-goods' | 'shipping-document' | 'bill-of-lading' | 'consignment-note' | 'shipping-invoice' | 'agent-report' | 'invoice-for-agency-payment' | 'act-of-completed-agency-work' | 'payment-order-under-agency-contract'}
     * @memberof DealDocumentApiDealDocumentControllerCreateDocument
     */
    readonly documentType: 'specification' | 'laboratory-analysis' | 'supply-contract' | 'agency-contract' | 'agency-order' | 'signed-agency-contract' | 'signed-agency-order' | 'signed-specification' | 'signed-supply-contract' | 'invoice-for-supply-payment' | 'payment-order-under-the-supply-contract' | 'invoice-for-goods' | 'act-of-completed-work' | 'reconciliation-act-from-seller' | 'reconciliation-act-from-buyer' | 'declaration-of-conformity-with-cu-regulations' | 'certificate-for-use-of-pesticides' | 'certificate-of-absence-of-gmo' | 'certificate-of-origin-of-goods' | 'quarantine-certificate' | 'phytosanitary-certificate' | 'veterinary-certificate-for-goods' | 'shipping-document' | 'bill-of-lading' | 'consignment-note' | 'shipping-invoice' | 'agent-report' | 'invoice-for-agency-payment' | 'act-of-completed-agency-work' | 'payment-order-under-agency-contract'

    /**
     * 
     * @type {CreateDealDocumentBody}
     * @memberof DealDocumentApiDealDocumentControllerCreateDocument
     */
    readonly createDealDocumentBody: CreateDealDocumentBody
}

/**
 * Request parameters for dealDocumentControllerCreateDocumentTemplate operation in DealDocumentApi.
 * @export
 * @interface DealDocumentApiDealDocumentControllerCreateDocumentTemplateRequest
 */
export interface DealDocumentApiDealDocumentControllerCreateDocumentTemplateRequest {
    /**
     * Тип документа
     * @type {'specification' | 'laboratory-analysis' | 'supply-contract' | 'agency-contract' | 'agency-order' | 'signed-agency-contract' | 'signed-agency-order' | 'signed-specification' | 'signed-supply-contract' | 'invoice-for-supply-payment' | 'payment-order-under-the-supply-contract' | 'invoice-for-goods' | 'act-of-completed-work' | 'reconciliation-act-from-seller' | 'reconciliation-act-from-buyer' | 'declaration-of-conformity-with-cu-regulations' | 'certificate-for-use-of-pesticides' | 'certificate-of-absence-of-gmo' | 'certificate-of-origin-of-goods' | 'quarantine-certificate' | 'phytosanitary-certificate' | 'veterinary-certificate-for-goods' | 'shipping-document' | 'bill-of-lading' | 'consignment-note' | 'shipping-invoice' | 'agent-report' | 'invoice-for-agency-payment' | 'act-of-completed-agency-work' | 'payment-order-under-agency-contract'}
     * @memberof DealDocumentApiDealDocumentControllerCreateDocumentTemplate
     */
    readonly documentType: 'specification' | 'laboratory-analysis' | 'supply-contract' | 'agency-contract' | 'agency-order' | 'signed-agency-contract' | 'signed-agency-order' | 'signed-specification' | 'signed-supply-contract' | 'invoice-for-supply-payment' | 'payment-order-under-the-supply-contract' | 'invoice-for-goods' | 'act-of-completed-work' | 'reconciliation-act-from-seller' | 'reconciliation-act-from-buyer' | 'declaration-of-conformity-with-cu-regulations' | 'certificate-for-use-of-pesticides' | 'certificate-of-absence-of-gmo' | 'certificate-of-origin-of-goods' | 'quarantine-certificate' | 'phytosanitary-certificate' | 'veterinary-certificate-for-goods' | 'shipping-document' | 'bill-of-lading' | 'consignment-note' | 'shipping-invoice' | 'agent-report' | 'invoice-for-agency-payment' | 'act-of-completed-agency-work' | 'payment-order-under-agency-contract'

    /**
     * 
     * @type {CreateDealDocumentBody}
     * @memberof DealDocumentApiDealDocumentControllerCreateDocumentTemplate
     */
    readonly createDealDocumentBody: CreateDealDocumentBody
}

/**
 * Request parameters for dealDocumentControllerDeleteDocument operation in DealDocumentApi.
 * @export
 * @interface DealDocumentApiDealDocumentControllerDeleteDocumentRequest
 */
export interface DealDocumentApiDealDocumentControllerDeleteDocumentRequest {
    /**
     * 
     * @type {number}
     * @memberof DealDocumentApiDealDocumentControllerDeleteDocument
     */
    readonly documentId: number
}

/**
 * Request parameters for dealDocumentControllerDeleteDocumentFile operation in DealDocumentApi.
 * @export
 * @interface DealDocumentApiDealDocumentControllerDeleteDocumentFileRequest
 */
export interface DealDocumentApiDealDocumentControllerDeleteDocumentFileRequest {
    /**
     * 
     * @type {number}
     * @memberof DealDocumentApiDealDocumentControllerDeleteDocumentFile
     */
    readonly fileId: number
}

/**
 * Request parameters for dealDocumentControllerDeleteDocumentTemplate operation in DealDocumentApi.
 * @export
 * @interface DealDocumentApiDealDocumentControllerDeleteDocumentTemplateRequest
 */
export interface DealDocumentApiDealDocumentControllerDeleteDocumentTemplateRequest {
    /**
     * 
     * @type {number}
     * @memberof DealDocumentApiDealDocumentControllerDeleteDocumentTemplate
     */
    readonly documentId: number
}

/**
 * Request parameters for dealDocumentControllerGetDocumentTemplates operation in DealDocumentApi.
 * @export
 * @interface DealDocumentApiDealDocumentControllerGetDocumentTemplatesRequest
 */
export interface DealDocumentApiDealDocumentControllerGetDocumentTemplatesRequest {
    /**
     * Типы документов
     * @type {Array<'specification' | 'laboratory-analysis' | 'supply-contract' | 'agency-contract' | 'agency-order' | 'signed-agency-contract' | 'signed-agency-order' | 'signed-specification' | 'signed-supply-contract' | 'invoice-for-supply-payment' | 'payment-order-under-the-supply-contract' | 'invoice-for-goods' | 'act-of-completed-work' | 'reconciliation-act-from-seller' | 'reconciliation-act-from-buyer' | 'declaration-of-conformity-with-cu-regulations' | 'certificate-for-use-of-pesticides' | 'certificate-of-absence-of-gmo' | 'certificate-of-origin-of-goods' | 'quarantine-certificate' | 'phytosanitary-certificate' | 'veterinary-certificate-for-goods' | 'shipping-document' | 'bill-of-lading' | 'consignment-note' | 'shipping-invoice' | 'agent-report' | 'invoice-for-agency-payment' | 'act-of-completed-agency-work' | 'payment-order-under-agency-contract'>}
     * @memberof DealDocumentApiDealDocumentControllerGetDocumentTemplates
     */
    readonly types?: Array<'specification' | 'laboratory-analysis' | 'supply-contract' | 'agency-contract' | 'agency-order' | 'signed-agency-contract' | 'signed-agency-order' | 'signed-specification' | 'signed-supply-contract' | 'invoice-for-supply-payment' | 'payment-order-under-the-supply-contract' | 'invoice-for-goods' | 'act-of-completed-work' | 'reconciliation-act-from-seller' | 'reconciliation-act-from-buyer' | 'declaration-of-conformity-with-cu-regulations' | 'certificate-for-use-of-pesticides' | 'certificate-of-absence-of-gmo' | 'certificate-of-origin-of-goods' | 'quarantine-certificate' | 'phytosanitary-certificate' | 'veterinary-certificate-for-goods' | 'shipping-document' | 'bill-of-lading' | 'consignment-note' | 'shipping-invoice' | 'agent-report' | 'invoice-for-agency-payment' | 'act-of-completed-agency-work' | 'payment-order-under-agency-contract'>
}

/**
 * Request parameters for dealDocumentControllerGetDocuments operation in DealDocumentApi.
 * @export
 * @interface DealDocumentApiDealDocumentControllerGetDocumentsRequest
 */
export interface DealDocumentApiDealDocumentControllerGetDocumentsRequest {
    /**
     * 
     * @type {number}
     * @memberof DealDocumentApiDealDocumentControllerGetDocuments
     */
    readonly dealId: number

    /**
     * Типы документов
     * @type {Array<'specification' | 'laboratory-analysis' | 'supply-contract' | 'agency-contract' | 'agency-order' | 'signed-agency-contract' | 'signed-agency-order' | 'signed-specification' | 'signed-supply-contract' | 'invoice-for-supply-payment' | 'payment-order-under-the-supply-contract' | 'invoice-for-goods' | 'act-of-completed-work' | 'reconciliation-act-from-seller' | 'reconciliation-act-from-buyer' | 'declaration-of-conformity-with-cu-regulations' | 'certificate-for-use-of-pesticides' | 'certificate-of-absence-of-gmo' | 'certificate-of-origin-of-goods' | 'quarantine-certificate' | 'phytosanitary-certificate' | 'veterinary-certificate-for-goods' | 'shipping-document' | 'bill-of-lading' | 'consignment-note' | 'shipping-invoice' | 'agent-report' | 'invoice-for-agency-payment' | 'act-of-completed-agency-work' | 'payment-order-under-agency-contract'>}
     * @memberof DealDocumentApiDealDocumentControllerGetDocuments
     */
    readonly types?: Array<'specification' | 'laboratory-analysis' | 'supply-contract' | 'agency-contract' | 'agency-order' | 'signed-agency-contract' | 'signed-agency-order' | 'signed-specification' | 'signed-supply-contract' | 'invoice-for-supply-payment' | 'payment-order-under-the-supply-contract' | 'invoice-for-goods' | 'act-of-completed-work' | 'reconciliation-act-from-seller' | 'reconciliation-act-from-buyer' | 'declaration-of-conformity-with-cu-regulations' | 'certificate-for-use-of-pesticides' | 'certificate-of-absence-of-gmo' | 'certificate-of-origin-of-goods' | 'quarantine-certificate' | 'phytosanitary-certificate' | 'veterinary-certificate-for-goods' | 'shipping-document' | 'bill-of-lading' | 'consignment-note' | 'shipping-invoice' | 'agent-report' | 'invoice-for-agency-payment' | 'act-of-completed-agency-work' | 'payment-order-under-agency-contract'>
}

/**
 * Request parameters for dealDocumentControllerGetDocumentsZip operation in DealDocumentApi.
 * @export
 * @interface DealDocumentApiDealDocumentControllerGetDocumentsZipRequest
 */
export interface DealDocumentApiDealDocumentControllerGetDocumentsZipRequest {
    /**
     * 
     * @type {number}
     * @memberof DealDocumentApiDealDocumentControllerGetDocumentsZip
     */
    readonly dealId: number

    /**
     * Массив с id документов
     * @type {Array<number>}
     * @memberof DealDocumentApiDealDocumentControllerGetDocumentsZip
     */
    readonly documentIds?: Array<number>
}

/**
 * Request parameters for dealDocumentControllerUpdateDocumentFile operation in DealDocumentApi.
 * @export
 * @interface DealDocumentApiDealDocumentControllerUpdateDocumentFileRequest
 */
export interface DealDocumentApiDealDocumentControllerUpdateDocumentFileRequest {
    /**
     * 
     * @type {number}
     * @memberof DealDocumentApiDealDocumentControllerUpdateDocumentFile
     */
    readonly fileId: number

    /**
     * 
     * @type {UpdateDealDocumentFileBody}
     * @memberof DealDocumentApiDealDocumentControllerUpdateDocumentFile
     */
    readonly updateDealDocumentFileBody: UpdateDealDocumentFileBody
}

/**
 * DealDocumentApi - object-oriented interface
 * @export
 * @class DealDocumentApi
 * @extends {BaseAPI}
 */
export class DealDocumentApi extends BaseAPI {
    /**
     * 
     * @param {DealDocumentApiDealDocumentControllerAddDocumentFilesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DealDocumentApi
     */
    public dealDocumentControllerAddDocumentFiles(requestParameters: DealDocumentApiDealDocumentControllerAddDocumentFilesRequest, options?: AxiosRequestConfig) {
        return DealDocumentApiFp(this.configuration).dealDocumentControllerAddDocumentFiles(requestParameters.documentId, requestParameters.addDealDocumentFilesBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DealDocumentApiDealDocumentControllerCreateDocumentRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DealDocumentApi
     */
    public dealDocumentControllerCreateDocument(requestParameters: DealDocumentApiDealDocumentControllerCreateDocumentRequest, options?: AxiosRequestConfig) {
        return DealDocumentApiFp(this.configuration).dealDocumentControllerCreateDocument(requestParameters.dealId, requestParameters.documentType, requestParameters.createDealDocumentBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DealDocumentApiDealDocumentControllerCreateDocumentTemplateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DealDocumentApi
     */
    public dealDocumentControllerCreateDocumentTemplate(requestParameters: DealDocumentApiDealDocumentControllerCreateDocumentTemplateRequest, options?: AxiosRequestConfig) {
        return DealDocumentApiFp(this.configuration).dealDocumentControllerCreateDocumentTemplate(requestParameters.documentType, requestParameters.createDealDocumentBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DealDocumentApiDealDocumentControllerDeleteDocumentRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DealDocumentApi
     */
    public dealDocumentControllerDeleteDocument(requestParameters: DealDocumentApiDealDocumentControllerDeleteDocumentRequest, options?: AxiosRequestConfig) {
        return DealDocumentApiFp(this.configuration).dealDocumentControllerDeleteDocument(requestParameters.documentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DealDocumentApiDealDocumentControllerDeleteDocumentFileRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DealDocumentApi
     */
    public dealDocumentControllerDeleteDocumentFile(requestParameters: DealDocumentApiDealDocumentControllerDeleteDocumentFileRequest, options?: AxiosRequestConfig) {
        return DealDocumentApiFp(this.configuration).dealDocumentControllerDeleteDocumentFile(requestParameters.fileId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DealDocumentApiDealDocumentControllerDeleteDocumentTemplateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DealDocumentApi
     */
    public dealDocumentControllerDeleteDocumentTemplate(requestParameters: DealDocumentApiDealDocumentControllerDeleteDocumentTemplateRequest, options?: AxiosRequestConfig) {
        return DealDocumentApiFp(this.configuration).dealDocumentControllerDeleteDocumentTemplate(requestParameters.documentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DealDocumentApiDealDocumentControllerGetDocumentTemplatesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DealDocumentApi
     */
    public dealDocumentControllerGetDocumentTemplates(requestParameters: DealDocumentApiDealDocumentControllerGetDocumentTemplatesRequest = {}, options?: AxiosRequestConfig) {
        return DealDocumentApiFp(this.configuration).dealDocumentControllerGetDocumentTemplates(requestParameters.types, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DealDocumentApiDealDocumentControllerGetDocumentsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DealDocumentApi
     */
    public dealDocumentControllerGetDocuments(requestParameters: DealDocumentApiDealDocumentControllerGetDocumentsRequest, options?: AxiosRequestConfig) {
        return DealDocumentApiFp(this.configuration).dealDocumentControllerGetDocuments(requestParameters.dealId, requestParameters.types, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DealDocumentApiDealDocumentControllerGetDocumentsZipRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DealDocumentApi
     */
    public dealDocumentControllerGetDocumentsZip(requestParameters: DealDocumentApiDealDocumentControllerGetDocumentsZipRequest, options?: AxiosRequestConfig) {
        return DealDocumentApiFp(this.configuration).dealDocumentControllerGetDocumentsZip(requestParameters.dealId, requestParameters.documentIds, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DealDocumentApiDealDocumentControllerUpdateDocumentFileRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DealDocumentApi
     */
    public dealDocumentControllerUpdateDocumentFile(requestParameters: DealDocumentApiDealDocumentControllerUpdateDocumentFileRequest, options?: AxiosRequestConfig) {
        return DealDocumentApiFp(this.configuration).dealDocumentControllerUpdateDocumentFile(requestParameters.fileId, requestParameters.updateDealDocumentFileBody, options).then((request) => request(this.axios, this.basePath));
    }
}
