import React, { FC, useState } from 'react'

import { Button, Modal } from 'antd'

import { DocumentCard } from '~/ui-components'
import { appToast, modalSharedSettings } from '~/utils'
import { getDealDocumentName } from '~/utils/deals'
import { useGetCurrentRole } from '~hooks/auth'
import { useGetDealDocumentsZip, useUploadDealDocumentFiles } from '~hooks/deal-documents'
import { DealAddDocumentForm } from '~pages/Deals/components'

import { DocumentCardControllerProps } from './DocumentCardController.types'

export const DocumentCardController: FC<DocumentCardControllerProps> = ({
  dealId,
  documentInstance,
  type,
  documentsTypes,
  index,
  isShowRemoveForAdmin,
  canChange,
  onShow,
  onRemove,
  onAddFileAfter,
  onReplaceFile,
}) => {
  const {
    user: { isAdmin },
  } = useGetCurrentRole()
  const [isOpenAddFileModal, setOpenAddFileModal] = useState(false)

  const getDealDocumentsZip = useGetDealDocumentsZip()
  const uploadDealDocumentFiles = useUploadDealDocumentFiles()

  const handleAddFileModalOpen = () => {
    setOpenAddFileModal(true)
  }

  const handleAddFileModalClose = () => {
    setOpenAddFileModal(false)
  }

  const handleDownloadFiles = async (documentIds?: number[]) => {
    if (!documentIds?.length || !dealId) return

    try {
      await getDealDocumentsZip.mutateAsync({
        request: {
          dealId,
          documentIds,
        },
        name: getDealDocumentName(type),
      })
    } catch (e) {
      console.error(e)
      appToast.error({
        description: 'Ошибка получения документов',
      })
    }
  }

  const handleAddFiles = async (files: File[]) => {
    try {
      await uploadDealDocumentFiles.mutateAsync({
        files,
        addDocumentFilesRequest: {
          documentId: documentInstance.id,
        },
      })
      handleAddFileModalClose()
      onAddFileAfter?.()
      appToast.success({
        description: 'Файл добавлен',
      })
    } catch (e) {
      console.error(e)
      appToast.error({
        description: 'Ошибка добавления файлов',
      })
    }
  }

  return (
    <>
      <DocumentCard
        type={type}
        status={documentInstance.status}
        index={index}
        count={documentInstance.files.length}
        createdAt={documentInstance.createdAt}
        canChange={canChange}
        isAdmin={isAdmin}
        isShowRemoveForAdmin={isShowRemoveForAdmin}
        isDownloading={getDealDocumentsZip.isLoading}
        onShow={onShow}
        onDownload={() => handleDownloadFiles([documentInstance.id])}
        onRemove={onRemove}
        onAddFile={handleAddFileModalOpen}
        onReplaceFile={onReplaceFile}
      />

      <Modal
        title="Добавить файл"
        {...modalSharedSettings}
        open={isOpenAddFileModal}
        onCancel={handleAddFileModalClose}
        width={400}
        footer={[
          <Button key="cancel" onClick={handleAddFileModalClose} htmlType="button">
            Отмена
          </Button>,
          <Button
            key="submit"
            form="deal-add-document-form"
            type="primary"
            htmlType="submit"
            loading={uploadDealDocumentFiles.isLoading}
          >
            Готово
          </Button>,
        ]}
      >
        <DealAddDocumentForm
          documentsTypes={documentsTypes}
          defaultDocumentsType={type}
          onSubmit={(value) => handleAddFiles(value.documentFiles)}
        />
      </Modal>
    </>
  )
}
