import React from 'react'

import { Link } from 'react-router-dom'

import { GetUserResponseRoleEnum } from '~api/generated'

/**
 * You can find this enum in the backend repo src/domains/notification/service/notification.service.ts
 */
export enum NotificationModel {
  OFFER = 'offer',
  USER = 'user',
  COMPANY = 'company',
  AUCTION = 'auction',
  BASIS_OFFER = 'elevator-offer',
  BASIS_OFFER_RESPONSE = 'elevator-offer-response',
}

/**
 * link like "offer:435", "company:104", "user:94"
 */
function parseLinkToPath(link: string, role: GetUserResponseRoleEnum | undefined): string {
  const [route, id] = link.split(':') as [NotificationModel, string]
  const isAdmin = role === GetUserResponseRoleEnum.Admin

  switch (route) {
    case NotificationModel.OFFER: {
      return `/offers/list/${id}`
    }
    case NotificationModel.COMPANY: {
      if (isAdmin) {
        return `/moderator/companies/${id}/company`
      }
      return '/profile/company'
    }
    case NotificationModel.USER: {
      if (isAdmin) {
        return `/moderator/users/${id}`
      }
      return '/profile/employees'
    }
    case NotificationModel.AUCTION: {
      if (isAdmin) {
        return `/moderator/auctions/${id}`
      }
      return `/auctions/lots-list/${id}`
    }
    case NotificationModel.BASIS_OFFER: {
      if (isAdmin) {
        return `/moderator/basis/adverts/${id}`
      }
      return `/basis/advert/${id}`
    }
    case NotificationModel.BASIS_OFFER_RESPONSE: {
      if (isAdmin) {
        return `/moderator/basis/offers/${id}`
      }
      return `/basis/offer/${id}`
    }
    default: {
      return link
    }
  }
}

interface IParseNotificationParams {
  text: string
  userRole?: GetUserResponseRoleEnum
  useRouting?: boolean
}
export function parseNotification({ text, userRole, useRouting = true }: IParseNotificationParams) {
  const regexLinkTag = /<a(.*?)<\/a>/g
  const hrefRegex = /href="(.*?)">/g

  try {
    const textArray = text.split(regexLinkTag)
    return textArray.map((str, idx) => {
      if (hrefRegex.test(str)) {
        const [_, link, name] = str.trim().split(hrefRegex)
        if (useRouting) {
          return (
            <Link key={link.concat(idx.toString())} to={parseLinkToPath(link, userRole)}>
              {name}
            </Link>
          )
        }
        // Notifications from antd is not working outside Router. for this reason using a with href
        return (
          <a key={link.concat(idx.toString())} href={parseLinkToPath(link, userRole)}>
            {name}
          </a>
        )
      }
      return str
    })
  } catch (e) {
    console.error('[parseNotification]: something was wrong')
    return text
  }
}
