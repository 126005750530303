import React, { useEffect, useRef, useState } from 'react'

import { Button, Select } from 'antd'
import { SizeType } from 'antd/es/config-provider/SizeContext'
import { BaseSelectRef } from 'rc-select'

import { useGetRegions } from '~/hooks'
import { RegionData } from '~api/generated'
import { CustomControlInterface } from '~shared/controls/CustomControl.interface'

import * as S from './RegionSelectControl.styled'

export type RegionSelectControlValue = RegionData['id'][]

interface RegionSelectControlProps extends CustomControlInterface {
  onChange: (value: RegionSelectControlValue) => void
  value: RegionSelectControlValue
  size?: SizeType
  hasError?: boolean
  id?: string
  isHiddenDisabled?: boolean
  mode?: 'single' | 'multiple'
}

export const RegionSelectControl: React.FC<RegionSelectControlProps> = React.forwardRef<
  BaseSelectRef,
  RegionSelectControlProps
>(({ mode = 'multiple', id, value, onChange, hasError, size, isHiddenDisabled }, ref) => {
  const regions = useGetRegions()
  const [isOpen, setOpen] = useState(false)

  const selectRef = useRef<BaseSelectRef | null>(null)

  const onSelectAddressHandler = (v: RegionSelectControlValue) => {
    onChange(v)
  }

  useEffect(() => {
    if (!isOpen) {
      setTimeout(() => {
        selectRef.current?.blur()
      }, 100)
    }
  }, [isOpen])

  if (mode === 'multiple') {
    return (
      <S.StyledSelect
        ref={(el) => (selectRef.current = el)}
        id={id}
        size={size}
        showSearch
        // allowClear
        mode="multiple"
        maxTagCount={1}
        style={{ width: '100%' }}
        placeholder="Выберите регион"
        value={value}
        status={hasError ? 'error' : undefined}
        optionFilterProp="children"
        onDropdownVisibleChange={(visible) => setOpen(visible)}
        loading={regions.isLoading}
        // @ts-ignore
        onChange={onSelectAddressHandler}
        suffixIcon={
          isOpen ? (
            <Button type="primary" size="small" onClick={() => document.body.focus()}>
              Ok
            </Button>
          ) : undefined
        }
        // open
        showArrow
      >
        {regions.data
          ?.filter((region) => region.hasPublishedOffers || !isHiddenDisabled)
          .map((region) => (
            <Select.Option key={region.id} value={region.id}>
              {region.name}
            </Select.Option>
          ))}
      </S.StyledSelect>
    )
  }

  return (
    <S.StyledSelect
      ref={(el) => (selectRef.current = el)}
      id={id}
      size={size}
      showSearch
      // allowClear
      maxTagCount={1}
      style={{ width: '100%' }}
      placeholder="Выберите регион"
      value={value}
      status={hasError ? 'error' : undefined}
      optionFilterProp="children"
      onDropdownVisibleChange={(visible) => setOpen(visible)}
      loading={regions.isLoading}
      // @ts-ignore
      onChange={onSelectAddressHandler}
      suffixIcon={
        isOpen ? (
          <Button type="primary" size="small" onClick={() => document.body.focus()}>
            Ok
          </Button>
        ) : undefined
      }
      // open
      showArrow
    >
      {regions.data
        ?.filter((region) => region.hasPublishedOffers || !isHiddenDisabled)
        .map((region) => (
          <Select.Option key={region.id} value={region.id}>
            {region.name}
          </Select.Option>
        ))}
    </S.StyledSelect>
  )
})

RegionSelectControl.displayName = 'RegionSelectControl'
