import { useQuery, UseQueryResult } from '@tanstack/react-query'

import {
  ElevatorAdvertApiElevatorAdvertControllerGetMyElevatorAdvertsRequest,
  GetMyElevatorAdvertsResponse,
} from '~api/generated'
import { useApi } from '~providers/api'

export const useGetMyElevatorAdverts = (
  request: ElevatorAdvertApiElevatorAdvertControllerGetMyElevatorAdvertsRequest
): UseQueryResult<GetMyElevatorAdvertsResponse> => {
  const api = useApi()

  const fetchFn = async (): Promise<GetMyElevatorAdvertsResponse> => {
    const res = await api.elevatorAdvert.elevatorAdvertControllerGetMyElevatorAdverts(request)

    return res.data
  }

  return useQuery(['myElevatorAdverts', request], fetchFn)
}
