import styled from 'styled-components'

import { css } from '@styled-system/css'

export const Wrapper = styled('div')(
  css({
    padding: '32px 24px',
  })
)

export const HeaderWrapper = styled.div(
  css({
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: ['column', 'column', 'row'],
    gap: '16px',
    // display: 'grid',
    // gridTemplateColumns: ['1fr', '1fr', 'auto 1fr'],
    // justifyItems: ['unset', 'unset', 'end'],
    marginBottom: '24px',
  })
)

export const TextWrapper = styled.div(
  css({
    // marginBottom: '24px',
    // color: 'TxtHeading',
  })
)

export const Desktop = styled.div(
  css({
    display: ['none', null, 'block'],
  })
)

export const Mobile = styled.div(
  css({
    display: ['block', null, 'none'],
  })
)
