import { useMutation } from '@tanstack/react-query'

import { CreateWarehousePositionBody } from '~/api/generated'
import { useApi } from '~providers/api'

interface useCreateWarehousePositionRequest {
  warehouseId: number
  createWarehousePositionBody: CreateWarehousePositionBody
}

export const useCreateWarehousePosition = () => {
  const api = useApi()

  const createWarehouse = async ({ warehouseId, createWarehousePositionBody }: useCreateWarehousePositionRequest) => {
    if (!warehouseId) {
      console.error('[useCreateWarehousePosition]: companyId was not passed')

      return Promise.reject('[useCreateWarehousePosition]: companyId was not passed')
    }

    const res = await api.warehouses.createWarehousePosition({
      warehouseId,
      createWarehousePositionBody,
    })
    return res
  }

  return useMutation(createWarehouse)
}
