import { Radio } from 'antd'
import styled from 'styled-components'

import css from '@styled-system/css'

export const Description = styled.div(
  css({
    color: 'Gray500',
    fontSize: '12px',
    lineHeight: '16px',
    marginTop: '8px',
  })
)
export const StyledRadio = styled(Radio)(
  css({
    '& .ant-radio': {
      top: '-10px !important',
    },
  })
)
