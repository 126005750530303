import * as yup from 'yup'

import { VALIDATORS_MSG } from '~shared/validations'

import { ProductParametersWithValue } from './ParametersCultureControl.types'

const valueSchema = yup
  .number()
  .transform((value) => (isNaN(value) ? undefined : value))
  .nullable()

export const parametersCultureControlSchema = yup.object({
  parameters: yup.array().of(
    // @ts-ignore
    yup.lazy((value: ProductParametersWithValue) => {
      const isRequired: boolean = value.required
      return yup.object({
        value: isRequired ? valueSchema.required(VALIDATORS_MSG.required) : valueSchema,
      })
    })
  ),
})
