import React from 'react'

import { Button, Input } from 'antd'
import { Controller, useForm } from 'react-hook-form'

import { yupResolver } from '@hookform/resolvers/yup'

import { useGetProductTypeData } from '~/hooks'
import { FormField, FormFooter, Text } from '~/ui-components'
import { cultureParameterEditFormSchema } from '~pages/SettingsPage/modules/CultureParametersTab/components/CultureParameterEditForm/CultureParameterEditForm.schema'
import { CheckboxControl } from '~shared/controls'

import { ICultureParameterEditFormProps, ICultureParameterEditFormValues } from './CultureParameterEditForm.types'

const FORM_ID = 'edit-culture-parameter-form'
export const CultureParameterEditForm: React.FC<ICultureParameterEditFormProps> = (props) => {
  const { getProductTypeParameter } = useGetProductTypeData()

  const currentParameterValues = getProductTypeParameter(props.productType, props.parameterType)

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<ICultureParameterEditFormValues>({
    defaultValues: {
      productType: props.productType,
      parameterType: props.parameterType,
      name: currentParameterValues.name,
      measurementUnit: currentParameterValues?.measurementUnit ?? undefined,
      minimum: currentParameterValues?.minimum ?? undefined,
      maximum: currentParameterValues?.maximum ?? undefined,
      gost: currentParameterValues?.gost ?? undefined,
      required: currentParameterValues?.required ?? false,
    },
    resolver: yupResolver(cultureParameterEditFormSchema),
  })

  const onSubmit = (formValues: ICultureParameterEditFormValues) => {
    props.onSubmit(formValues)
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} id={FORM_ID}>
      <div style={{ display: 'flex', gap: '40px' }}>
        <FormField label="productType">
          <Text variant="normal">{props.productType}</Text>
        </FormField>
        <FormField label="parameterType">
          <Text variant="normal">{props.parameterType}</Text>
        </FormField>
      </div>

      <FormField label="Название параметра" htmlFor="name" isRequired validateMessage={errors.name?.message}>
        <Controller
          name="name"
          control={control}
          render={({ field }) => <Input {...field} id="name" placeholder="Протеин" status={errors.name && 'error'} />}
        />
      </FormField>

      <FormField label="Единица измерения" htmlFor="measurementUnit" validateMessage={errors.measurementUnit?.message}>
        <Controller
          name="measurementUnit"
          control={control}
          render={({ field }) => (
            <Input {...field} id="measurementUnit" placeholder="%" status={errors.measurementUnit && 'error'} />
          )}
        />
      </FormField>

      <FormField label="Минимум" htmlFor="minimum" validateMessage={errors.minimum?.message}>
        <Controller
          name="minimum"
          control={control}
          render={({ field }) => (
            <Input {...field} type="number" id="minimum" placeholder="25" status={errors.minimum && 'error'} />
          )}
        />
      </FormField>

      <FormField label="Максимум" htmlFor="maximum" validateMessage={errors.maximum?.message}>
        <Controller
          name="maximum"
          control={control}
          render={({ field }) => (
            <Input {...field} type="number" id="maximum" placeholder="50" status={errors.maximum && 'error'} />
          )}
        />
      </FormField>

      <FormField label="ГОСТ" htmlFor="gost" validateMessage={errors.gost?.message}>
        <Controller
          name="gost"
          control={control}
          render={({ field }) => <Input {...field} id="gost" placeholder="50" status={errors.gost && 'error'} />}
        />
      </FormField>

      <Controller
        control={control}
        name="required"
        render={({ field }) => <CheckboxControl {...field}>Обязательный</CheckboxControl>}
      />

      <FormFooter>
        <Button htmlType="submit" type="primary">
          Сохранить
        </Button>
      </FormFooter>
    </form>
  )
}
