import { useQuery } from '@tanstack/react-query'

import { CompanyApiCompanyControllerGetCompaniesRequest, GetCompaniesResponse } from '~/api/generated'
import { useApi } from '~/providers/api'
import { appToast } from '~/utils'

export const useGetModeratorCompaniesList = (params: CompanyApiCompanyControllerGetCompaniesRequest) => {
  const api = useApi()

  async function fetchCompanies(p: CompanyApiCompanyControllerGetCompaniesRequest): Promise<GetCompaniesResponse> {
    const response = await api.companies.companyControllerGetCompanies(p)
    return response.data
  }

  const fetchFn = async (): Promise<GetCompaniesResponse | null> => {
    try {
      const res = await fetchCompanies(params)

      return res
    } catch (error) {
      appToast.error({ description: 'Ошибка при получении списка компаний' })
      return null
    }
  }

  return useQuery(['fetchModeratorCompanyList', params], fetchFn, {
    keepPreviousData: true,
  })
}
