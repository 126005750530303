import React from 'react'

import { Button, Modal, Space } from 'antd'
import { BsTelegram } from 'react-icons/bs'
import { FiMessageCircle } from 'react-icons/fi'
import { RiWhatsappFill } from 'react-icons/ri'

import { AppConfig } from '~/appConfig'
import { Text } from '~/ui-components'
import { PhoneNumber } from '~shared/components'

import * as S from './HelpInfoModal.styled'

interface HelpInfoModalProps {
  isOpen: boolean
  onClose: () => void
}

export const HelpInfoModal: React.FC<HelpInfoModalProps> = ({ isOpen, onClose }) => {
  return (
    <Modal footer={null} open={isOpen} onCancel={onClose}>
      <S.Content>
        <Space>
          <FiMessageCircle size={24} />
          <Text variant="h2">Помощь</Text>
        </Space>

        <Text variant="normal-bold">Свяжитесь с нами любым удобным для вас способом:</Text>

        <Space direction="vertical">
          <Text variant="normal-bold">
            Позвонить:{' '}
            <span>
              <PhoneNumber prefix="" phoneNumber={AppConfig.ADMIN_CONTACT_PHONE} />
            </span>
          </Text>
          <Text variant="description" style={{ color: '#929292' }}>
            с 10:00 до 20:00 по московскому времени,
            <br /> сб, вс — выходные
          </Text>
        </Space>

        <Space direction="vertical">
          <Text variant="normal-bold">Написать:</Text>

          <Button size="small" type="link" href={AppConfig.WHATSAPP_CONTACT_PHONE} target="_blank">
            <RiWhatsappFill color="var(--primary-main-color)" size={18} />
            Поддержка в WhatsApp
          </Button>
          <Button size="small" type="link" href={AppConfig.TELEGRAM_CONTACT_PHONE} target="_blank">
            <BsTelegram color="var(--primary-main-color)" size={16} />
            Поддержка в Telegram
          </Button>
        </Space>
      </S.Content>
    </Modal>
  )
}
