import { graphql } from '~api/gql-generated/gql'

export const Company_ActionsFragment = graphql(/* GraphQL */ `
  fragment CompanyActions on CompanyActionsEntity {
    updateLegalInfo
    updateGeneralInfo
    sendOnModeration
    deleteUser
    delete
    addUser
  }
`)

export const Company_DataFragment = graphql(/* GraphQL */ `
  fragment CompanyData on CompanyEntity {
    id
    inn
    kpp
    legalName
    ogrn
    okved
    type
    verificationStatus
    phoneNumber
    rejectionReason
    useNds
    website
    additionalInfo
    directorName
    verifiedAt
    codeOkved
    signatory
    signatoryPosition
    signatoryLegalBasis
    legalAddress
    actualAddress
    aicCharterMember
    logoUrl
  }
`)

export const Company_StateFragment = graphql(/* GraphQL */ `
  fragment CompanyState on CompanyEntity {
    state {
      firstFreightOrderCreated
      companyVerifiedMessageClosed
      canUsePriorityCompanies
    }
  }
`)

export const getCompanyStateQuery = graphql(/* GraphQL */ `
  query CompanyState($companyId: Int!) {
    company(companyId: $companyId) {
      ...CompanyState
    }
  }
`)

export const getCompanyDataQuery = graphql(/* GraphQL */ `
  query Company($companyId: Int!) {
    company(companyId: $companyId) {
      ...CompanyData
      ...CompanyState
      actions {
        ...CompanyActions
      }
    }
  }
`)
