import { graphql } from '~api/gql-generated/gql'

export const freightDealsQuery = graphql(/* GraphQL */ `
  query FreightDeals($filter: FreightDealFilterInput, $pagination: PaginationInput) {
    freightDeals(filter: $filter, pagination: $pagination) {
      freightDeals {
        id
        orderId
        dealOrderId
        customerId
        carrierId
        status
        type
        createdById
        startedAt
        rejectedAt
        rejectedById
        rejectionReason
        isRealisedByCustomer
        isRealisedByCarrier
        isAgreedByCustomer
        isAgreedByCarrier
        releasedAt
        additionalDetails
        createdAt
        updatedAt
        nextStatuses
        lastChangeRequests {
          id
          type
          status
          updatedAt
          changedFields {
            value
            fieldName
          }
          createdByCompanyId
          endedByCompanyId
          additionalDetails
          nextStatuses
          isViewedByCounterparty
        }
        order {
          id
          companyId
          routeId
          readinessType
          startedAt
          endedAt
          startDaysCount
          transportFormatTypes
          transportBodyTypes {
            id
            name
          }
          weightKg
          selectedWeightKg
          priceWithoutNds
          priceWithNds
          availableWeightKg
          pricePerTonWithoutNds
          pricePerTonWithNds
          isAuction
          paymentTerm
          advancePercent
          deferredDaysCount
          contactPerson
          contactPhoneNumber
          additionalDetails
          createdAt
          updatedAt
          deletedAt
          canCreateResponse
          route {
            id
            distance
            createdAt
            updatedAt
            points {
              id
              addressId
              type
              freightId
              roadTransportParametersId
              createdAt
              updatedAt
              address {
                full
                short
              }
              freight {
                id
                packingType
                packingTypeCustom
                bigBagsCount
                productTypeId
                weightKg
                createdAt
                updatedAt
                productType {
                  id
                  name
                  type
                  gost
                }
              }
              roadTransportParameters {
                id
                loadingTypes
                unloadingTypes
                loadingTypeCustom
                unloadingTypeCustom
                loadingThroughput
                unloadingThroughput
                vehicleLengthMaxM
                vehicleHeightMaxM
                vehicleClearanceMinCm
                hasParking
                hasScales
                hasWeighingAutomation
                distanceToScalesM
                scalesWeightLimitT
                roadSurfaceType
                roadSurfaceQuality
                additionalDetails
                createdAt
                updatedAt
              }
            }
          }
          responsesStatistics {
            previousDays {
              betsCount
              responsesCount
            }
            today {
              betsCount
              responsesCount
            }
          }
        }
        customer {
          id
          countryName
          legalForm
          inn
          kpp
          legalName
          ogrn
          okved
          type
          verificationStatus
          phoneNumber
          rejectionReason
          useNds
          website
          additionalInfo
          directorName
          verifiedAt
          codeOkved
          signatory
          signatoryPosition
          signatoryLegalBasis
          legalAddress
          actualAddress
          aicCharterMember
          logoUrl
        }
        dealOrder {
          id
          companyId
          routeId
          readinessType
          startedAt
          endedAt
          startDaysCount
          transportFormatTypes
          transportBodyTypes {
            id
            name
          }
          weightKg
          selectedWeightKg
          priceWithoutNds
          priceWithNds
          availableWeightKg
          pricePerTonWithoutNds
          pricePerTonWithNds
          isAuction
          paymentTerm
          advancePercent
          deferredDaysCount
          contactPerson
          contactPhoneNumber
          additionalDetails
          createdAt
          updatedAt
          deletedAt
          canCreateResponse
        }
      }
      pagination {
        currentPage
        pageSize
        total
        totalPages
      }
    }
  }
`)

export const freightDealQuery = graphql(/* GraphQL */ `
  query FreightDeal($freightDealId: Int!) {
    freightDeal(freightDealId: $freightDealId) {
      id
      orderId
      dealOrderId
      customerId
      carrierId
      status
      type
      createdById
      startedAt
      rejectedAt
      rejectedById
      rejectionReason
      isRealisedByCustomer
      isRealisedByCarrier
      isAgreedByCustomer
      isAgreedByCarrier
      releasedAt
      additionalDetails
      createdAt
      updatedAt
      order {
        id
        companyId
        routeId
        readinessType
        startedAt
        endedAt
        startDaysCount
        transportFormatTypes
        transportBodyTypes {
          id
          name
        }
        weightKg
        selectedWeightKg
        priceWithoutNds
        priceWithNds
        availableWeightKg
        pricePerTonWithoutNds
        pricePerTonWithNds
        isAuction
        paymentTerm
        advancePercent
        deferredDaysCount
        contactPerson
        contactPhoneNumber
        additionalDetails
        createdAt
        updatedAt
        deletedAt
        route {
          id
          distance
          createdAt
          updatedAt
          points {
            id
            addressId
            type
            freightId
            roadTransportParametersId
            createdAt
            updatedAt
            address {
              full
              short
            }
            freight {
              id
              packingType
              packingTypeCustom
              bigBagsCount
              productTypeId
              weightKg
              createdAt
              updatedAt
              productType {
                id
                name
                type
                gost
              }
            }
            roadTransportParameters {
              id
              loadingTypes
              unloadingTypes
              loadingTypeCustom
              unloadingTypeCustom
              loadingThroughput
              unloadingThroughput
              vehicleLengthMaxM
              vehicleHeightMaxM
              vehicleClearanceMinCm
              hasParking
              hasScales
              hasWeighingAutomation
              distanceToScalesM
              scalesWeightLimitT
              roadSurfaceType
              roadSurfaceQuality
              additionalDetails
              createdAt
              updatedAt
            }
          }
        }
      }
      dealOrder {
        id
        companyId
        routeId
        readinessType
        startedAt
        endedAt
        startDaysCount
        transportFormatTypes
        transportBodyTypes {
          id
          name
        }
        weightKg
        selectedWeightKg
        priceWithoutNds
        priceWithNds
        availableWeightKg
        pricePerTonWithoutNds
        pricePerTonWithNds
        isAuction
        paymentTerm
        advancePercent
        deferredDaysCount
        contactPerson
        contactPhoneNumber
        additionalDetails
        createdAt
        updatedAt
        deletedAt
        route {
          id
          distance
          createdAt
          updatedAt
          points {
            id
            addressId
            type
            freightId
            roadTransportParametersId
            createdAt
            updatedAt
            address {
              full
              short
            }
            freight {
              id
              packingType
              packingTypeCustom
              bigBagsCount
              productTypeId
              weightKg
              createdAt
              updatedAt
              productType {
                id
                name
                type
                gost
              }
            }
            roadTransportParameters {
              id
              loadingTypes
              unloadingTypes
              loadingTypeCustom
              unloadingTypeCustom
              loadingThroughput
              unloadingThroughput
              vehicleLengthMaxM
              vehicleHeightMaxM
              vehicleClearanceMinCm
              hasParking
              hasScales
              hasWeighingAutomation
              distanceToScalesM
              scalesWeightLimitT
              roadSurfaceType
              roadSurfaceQuality
              additionalDetails
              createdAt
              updatedAt
            }
          }
        }
      }
      customer {
        id
        countryName
        legalForm
        inn
        kpp
        legalName
        ogrn
        okved
        type
        verificationStatus
        phoneNumber
        rejectionReason
        useNds
        website
        additionalInfo
        directorName
        verifiedAt
        codeOkved
        signatory
        signatoryPosition
        signatoryLegalBasis
        legalAddress
        actualAddress
        aicCharterMember
        logoUrl
        bankAccounts {
          id
          companyId
          bankName
          bic
          correspondentAccount
          paymentAccount
          createdAt
          actions {
            delete
          }
        }
        users {
          id
          phoneNumber
          status
          email
          name
          position
          role
          createdAt
          deactivatedAt
          authenticatedAt
          avatarUrl
        }
        actions {
          addUser
          delete
          deleteUser
          sendOnModeration
          updateGeneralInfo
          updateLegalInfo
        }
        state {
          companyVerifiedMessageClosed
          canUsePriorityCompanies
        }
        verificationRequirements {
          requisites
          bankAccount
          documents
        }
      }
      carrier {
        id
        countryName
        legalForm
        inn
        kpp
        legalName
        ogrn
        okved
        type
        verificationStatus
        phoneNumber
        rejectionReason
        useNds
        website
        additionalInfo
        directorName
        verifiedAt
        codeOkved
        signatory
        signatoryPosition
        signatoryLegalBasis
        legalAddress
        actualAddress
        aicCharterMember
        logoUrl
        bankAccounts {
          id
          companyId
          bankName
          bic
          correspondentAccount
          paymentAccount
          createdAt
          actions {
            delete
          }
        }
        users {
          id
          phoneNumber
          status
          email
          name
          position
          role
          createdAt
          deactivatedAt
          authenticatedAt
          createdBy {
            id
            phoneNumber
            status
            email
            name
            position
            role
            createdAt
            deactivatedAt
            authenticatedAt
            avatarUrl
          }
          avatarUrl
          companies {
            id
            countryName
            legalForm
            inn
            kpp
            legalName
            ogrn
            okved
            type
            verificationStatus
            phoneNumber
            rejectionReason
            useNds
            website
            additionalInfo
            directorName
            verifiedAt
            codeOkved
            signatory
            signatoryPosition
            signatoryLegalBasis
            legalAddress
            actualAddress
            aicCharterMember
            logoUrl
            bankAccounts {
              id
              companyId
              bankName
              bic
              correspondentAccount
              paymentAccount
              createdAt
              actions {
                delete
              }
            }
            users {
              id
              phoneNumber
              status
              email
              name
              position
              role
              createdAt
              deactivatedAt
              authenticatedAt
              createdBy {
                id
                phoneNumber
                status
                email
                name
                position
                role
                createdAt
                deactivatedAt
                authenticatedAt
                avatarUrl
              }
              avatarUrl
              companies {
                id
                countryName
                legalForm
                inn
                kpp
                legalName
                ogrn
                okved
                type
                verificationStatus
                phoneNumber
                rejectionReason
                useNds
                website
                additionalInfo
                directorName
                verifiedAt
                codeOkved
                signatory
                signatoryPosition
                signatoryLegalBasis
                legalAddress
                actualAddress
                aicCharterMember
                logoUrl
              }
              actions {
                activate
                deactivate
                update
                updateAvatar
              }
            }
            actions {
              addUser
              delete
              deleteUser
              sendOnModeration
              updateGeneralInfo
              updateLegalInfo
            }
            state {
              companyVerifiedMessageClosed
              canUsePriorityCompanies
            }
            verificationRequirements {
              requisites
              bankAccount
              documents
            }
          }
          actions {
            activate
            deactivate
            update
            updateAvatar
          }
        }
        actions {
          addUser
          delete
          deleteUser
          sendOnModeration
          updateGeneralInfo
          updateLegalInfo
        }
        state {
          companyVerifiedMessageClosed
          canUsePriorityCompanies
        }
        verificationRequirements {
          requisites
          bankAccount
          documents
        }
      }
      creator {
        id
        phoneNumber
        status
        email
        name
        position
        role
        createdAt
        deactivatedAt
        authenticatedAt
        createdBy {
          id
          phoneNumber
          status
          email
          name
          position
          role
          createdAt
          deactivatedAt
          authenticatedAt
          avatarUrl
        }
        avatarUrl
        companies {
          id
          countryName
          legalForm
          inn
          kpp
          legalName
          ogrn
          okved
          type
          verificationStatus
          phoneNumber
          rejectionReason
          useNds
          website
          additionalInfo
          directorName
          verifiedAt
          codeOkved
          signatory
          signatoryPosition
          signatoryLegalBasis
          legalAddress
          actualAddress
          aicCharterMember
          logoUrl
        }
        actions {
          activate
          deactivate
          update
          updateAvatar
        }
      }
      rejectedBy {
        id
        phoneNumber
        status
        email
        name
        position
        role
        createdAt
        deactivatedAt
        authenticatedAt
        avatarUrl
      }
      nextStatuses
      lastChangeRequests {
        id
        type
        status
        updatedAt
        changedFields {
          value
          fieldName
        }
        createdByCompanyId
        endedByCompanyId
        additionalDetails
        nextStatuses
        isViewedByCounterparty
      }
    }
  }
`)
