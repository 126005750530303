import React from 'react'
import { FormField, Text } from '~/ui-components'
import * as S from './CreateOwnershipFields.styled'
import { Controller } from 'react-hook-form'
import { CreateTransportFormFieldProps } from '~pages/CarParkPage/modules/CreateTransport/CreateTransport.types'
import { Flex, Input, Radio, Space, Tooltip } from 'antd'
import { OwnershipType } from '~api/gql-generated/graphql'
import { getOwnershipTypeName } from '~/utils/car-park/get-ownership-type'
import { FiHelpCircle } from 'react-icons/fi'
import { getOwnershipTypeTooltip } from '~/utils/car-park/get-ownership-type-tooltip'

const options = [OwnershipType.Own, OwnershipType.Rent, OwnershipType.Leasing].map((ownershipType) => ({
  key: ownershipType,
  value: ownershipType,
  label: getOwnershipTypeName(ownershipType),
  tooltip: getOwnershipTypeTooltip(ownershipType),
}))

export const CreateOwnershipFields: React.FC<CreateTransportFormFieldProps> = ({ control, errors, ownershipType }) => {
  return (
    <>
      <Text variant="h1">Владение</Text>

      <Flex gap={24}>
        <FormField
          label="Тип владения"
          htmlFor="ownershipType"
          validateMessage={errors.ownershipType?.message}
          isRequired
        >
          <Controller
            name="ownershipType"
            control={control}
            defaultValue={OwnershipType.Own}
            render={({ field }) => (
              <Radio.Group {...field}>
                <Space size="large">
                  {options.map(({ key, value, label, tooltip }) => (
                    <S.RadioItem key={key} value={value}>
                      <Flex align="center" gap={16}>
                        {label}
                        <Tooltip title={<>{tooltip}</>}>
                          <FiHelpCircle size={20} color={'var(--gray-5-color)'} />
                        </Tooltip>
                      </Flex>
                    </S.RadioItem>
                  ))}
                </Space>
              </Radio.Group>
            )}
          />
        </FormField>
      </Flex>

      {ownershipType && ownershipType !== OwnershipType.Own && (
        <Flex gap={24}>
          <FormField
            label={
              <Flex align="center" gap={8}>
                <Flex gap={4}>
                  Собственник ТС <S.Required>*</S.Required>
                </Flex>
                <Tooltip title={<>Полное наименование или ФИО собственника ТС по договору</>}>
                  <FiHelpCircle size={20} color={'var(--gray-5-color)'} />
                </Tooltip>
              </Flex>
            }
            htmlFor="ownerName"
            validateMessage={errors.ownerName?.message}
            grow={false}
          >
            <Controller
              name="ownerName"
              control={control}
              render={({ field }) => (
                <Input
                  {...field}
                  id="ownerName"
                  status={errors.ownerName && 'error'}
                  placeholder="Введите собственника"
                  style={{ minWidth: '300px' }}
                />
              )}
            />
          </FormField>
        </Flex>
      )}
    </>
  )
}
