import React from 'react'

import { Button, Flex, Input } from 'antd'
import { Controller, useForm } from 'react-hook-form'

import { yupResolver } from '@hookform/resolvers/yup'

import { RejectDealFormProps, RejectDealFormValues } from './RejectDealForm.types'
import { RejectDealFormSchema } from './RejectDealForm.validation'
import { FormField, Text } from '~/ui-components'
import { FreightDealType } from '~api/gql-generated/graphql'

export const RejectDealForm: React.FC<RejectDealFormProps> = ({ onBack, loading, onSubmit, type }) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<RejectDealFormValues>({
    resolver: yupResolver(RejectDealFormSchema),
  })

  const onSubmitHandler = (data: RejectDealFormValues) => {
    onSubmit(data)
  }

  return (
    <Flex vertical gap={24}>
      <Flex>
        {type === FreightDealType.Bet && (
          <Text variant="normal">Вы уверены что хотите отменить заказ? Ваша ставка также будет отклонена.</Text>
        )}
        {type === FreightDealType.Response && (
          <Text variant="normal">Вы уверены что хотите отменить заказ? Ваш отклик также будет отклонен.</Text>
        )}
      </Flex>

      <FormField
        label="Укажите причину"
        htmlFor="rejectionReason"
        validateMessage={errors.rejectionReason?.message}
        isRequired
        grow={false}
      >
        <Controller
          name="rejectionReason"
          control={control}
          render={({ field }) => (
            <Input
              {...field}
              id="rejectionReason"
              status={errors.rejectionReason && 'error'}
              placeholder="Введите текст"
            />
          )}
        />
      </FormField>

      <Flex gap={16} justify="flex-end">
        <Button htmlType="button" onClick={onBack}>
          Отмена
        </Button>

        <Button htmlType="button" type="primary" onClick={handleSubmit(onSubmitHandler)} loading={loading}>
          Отменить заказ
        </Button>
      </Flex>
    </Flex>
  )
}
