import { codeUnreachable } from '~/utils/code-unreachable'
import { UserDataRoleEnum } from '~api/generated'
import { UserRole } from '~api/gql-generated/graphql'

/**
 * @deprecated The method should not be used. После переезда на graphQl надо его удалить
 */
export function getUserRoleOld(userRole: UserDataRoleEnum): string {
  switch (userRole) {
    case UserDataRoleEnum.Admin:
      return 'Администратор'
    case UserDataRoleEnum.Authenticated:
      return 'Пользователь'
    case UserDataRoleEnum.Accountant:
      return 'Бухгалтер'
    case UserDataRoleEnum.Owner:
      return 'Владелец'
    case UserDataRoleEnum.Manager:
      return 'Менеджер'
    case UserDataRoleEnum.DemoUser:
      return 'Демо-пользователь'
    case UserDataRoleEnum.Director:
      return 'Директор'
    case UserDataRoleEnum.Driver:
      return 'Водитель'
    default:
      return codeUnreachable(userRole)
  }
}

export function getUserRole(userRole: UserRole): string {
  switch (userRole) {
    case UserRole.Admin:
      return 'Администратор'
    case UserRole.Authenticated:
      return 'Пользователь'
    case UserRole.Accountant:
      return 'Бухгалтер'
    case UserRole.Owner:
      return 'Владелец'
    case UserRole.Manager:
      return 'Менеджер'
    case UserRole.Director:
      return 'Директор'
    case UserRole.DemoUser:
      return 'Демо-пользователь'
    case UserRole.Driver:
      return 'Водитель'
    default:
      return codeUnreachable(userRole)
  }
}

export function getUserRoleColor(userRole: UserRole): { background: string; color: string } {
  switch (userRole) {
    case UserRole.Admin:
      return {
        background: '#EDEDED',
        color: '#1D1950',
      }
    case UserRole.Authenticated:
      return {
        background: '#EDEDED',
        color: '#1D1950',
      }
    case UserRole.Accountant:
      return {
        background: '#DEF5FF',
        color: '#2654AD',
      }
    case UserRole.Owner:
      return {
        background: '#EDEDED',
        color: '#1D1950',
      }
    case UserRole.Manager:
      return {
        background: '#C7FADF',
        color: '#024C35',
      }
    case UserRole.Director:
      return {
        background: '#E5E5FF',
        color: '#1D1950',
      }
    case UserRole.DemoUser:
      return {
        background: '#E5E5FF',
        color: '#1D1950',
      }
    case UserRole.Driver:
      return {
        background: '#E5E5FF',
        color: '#1D1950',
      }
    default:
      return codeUnreachable(userRole)
  }
}
