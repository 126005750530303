import React from 'react'

import { Empty, Skeleton } from 'antd'

import { ModeratorUserListMobileItem } from './ModeratorUserListMobileItem'

import { IModeratorUserListMobileProps, ModeratorUserListMobileItemType } from './ModeratorUserListMobile.types'

import * as S from './ModeratorUserListMobileItem.styled'

export const ModeratorUserListMobile: React.FC<IModeratorUserListMobileProps> = ({
  data,
  loading,
  onItemClick,
  onDeactivateUser,
}) => {
  const onItemClickHandler = (record: ModeratorUserListMobileItemType) => {
    onItemClick(record)
  }

  if (loading) {
    return <Skeleton />
  }

  if (!data.length) {
    return <Empty />
  }

  return (
    <S.MobileItemsWrapper>
      {data.map((item) => (
        <ModeratorUserListMobileItem
          key={item.id}
          {...item}
          onItemClick={onItemClickHandler}
          onDeactivateUser={onDeactivateUser}
        />
      ))}
    </S.MobileItemsWrapper>
  )
}
