import React, { useState } from 'react'

import { Button, Flex, Spin, Tabs, TabsProps } from 'antd'
import { useNavigate, useParams } from 'react-router-dom'

import { useQuery } from '@apollo/client'

import { AppHeader } from '~/layout'
import { appRoutes } from '~/router'
import { getTransportQuery } from '~api/gql-query/car-park.query.graphql'

import { TransportDetails, TransportGeneral } from './components'

import * as S from './TransportCard.styled'

export const TransportCard: React.FC = () => {
  const { id } = useParams<{ id: string | undefined }>()
  const transportId = Number(id)

  const getTransport = useQuery(getTransportQuery, {
    variables: {
      transportId,
    },
    fetchPolicy: 'cache-and-network',
  })

  const transport = getTransport.data?.transport

  const navigate = useNavigate()
  const [activeTab, setActiveTab] = useState<'general' | 'details'>('general')

  const tabItems: TabsProps['items'] = [
    {
      label: 'Общее',
      tabKey: 'general',
      key: 'general',
      children: transport && (
        <TransportGeneral
          transportId={transportId}
          transportGeneral={{
            type: transport.type,
            brand: transport.brand,
            vin: transport.vin,
            bodyType: transport.bodyType,
            axlesCount: transport.axlesCount,
            ownershipType: transport.ownershipType,
            ownerName: transport.ownerName,
          }}
        />
      ),
    },
    {
      label: 'Детали',
      tabKey: 'details',
      key: 'details',
      children: transport && (
        <TransportDetails
          transportId={transportId}
          transportDetails={{
            registrationData: {
              manufacturingDate: transport.manufacturingDate,
              registrationCertificate: transport.registrationCertificate,
              transportPassport: transport.transportPassport,
              adrCertificate: transport.adrCertificate,
            },
            parameters: {
              loadCapacityKg: transport.loadCapacityKg,
              loadVolumeL: transport.loadVolumeL,
              lengthCm: transport.lengthCm,
              widthCm: transport.widthCm,
              heightCm: transport.heightCm,
              loadingMethod: transport.loadingMethod,
            },
            preferredUse: {
              shortRouteKm: transport.shortRouteKm,
              longRouteKm: transport.longRouteKm,
              preferredRouteType: transport.preferredRouteType,
              canWorkInField: transport.canWorkInField,
            },
            additional: {
              additionalDetails: transport.additionalDetails,
            },
          }}
        />
      ),
    },
  ]

  return (
    <Spin spinning={getTransport.loading}>
      <AppHeader
        title={appRoutes.carParkTransportCard.title}
        isBack
        onBack={() => navigate(appRoutes.carParkTransport.url)}
      />

      {transport && (
        <S.Wrapper vertical gap={8}>
          <Flex justify="space-between" gap={24}>
            <Flex vertical gap={16}>
              <S.TextId>ID: {transport.id}</S.TextId>
              <Flex align="center" gap={16}>
                <S.TextTransportType>{transport.brand}</S.TextTransportType>
                <S.TextLicensePlate>{transport.licensePlate}</S.TextLicensePlate>
              </Flex>
            </Flex>
            <Flex>
              <Button
                type="primary"
                onClick={() => navigate(appRoutes.carParkEditTransport.url.replace(':id', transportId.toString()))}
              >
                Редактировать
              </Button>
            </Flex>
          </Flex>
          <Tabs activeKey={activeTab} onChange={(key) => setActiveTab(key as 'general' | 'details')} items={tabItems} />
        </S.Wrapper>
      )}
    </Spin>
  )
}
