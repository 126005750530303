import styled from 'styled-components'

import { css } from '@styled-system/css'

export const ContentWrapper = styled.div(
  css({
    display: 'grid',
    gridTemplateColumns: '1fr',
    gap: '8px',
    cursor: 'pointer',
  })
)
export const Status = styled.div(
  css({
    display: 'flex',
    gap: 8,
  })
)

export const ActionWrapper = styled.div(
  css({
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  })
)
