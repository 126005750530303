import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  padding: 16px;
  gap: 16px;
  justify-content: space-between;

  border-radius: 16px;
  box-shadow: 0 1px 4px rgba(84, 84, 84, 0.15);
`
