import React from 'react'

import { Button } from 'antd'
import { FiPlusCircle } from 'react-icons/fi'

import { useGetDevice } from '~hooks/common'

interface RightPanelEditButtonProps {
  onClick: () => void
  text?: string
}

export const RightPanelAddButton: React.FC<RightPanelEditButtonProps> = (props) => {
  const device = useGetDevice()
  const isMobile = device === 'mobile'

  if (isMobile) {
    return <Button type="text" icon={<FiPlusCircle size={18} />} onClick={props.onClick} />
  }

  return (
    <Button type="primary" onClick={props.onClick}>
      {props?.text ?? 'Добавить'}
    </Button>
  )
}
