import styled from 'styled-components'

import { breakpoints } from '~/tokens'

export const ContentWrapper = styled.div`
  padding: 24px;

  @media (max-width: ${breakpoints.MOBILE_XL}px) {
    padding: 16px;
  }
`

export const Content = styled.div`
  margin: 0 auto;
  display: flex;
  gap: 24px;
  flex-direction: column;

  .row-own {
    background: #fff8ea;
  }

  @media (max-width: ${breakpoints.MOBILE_XL}px) {
    gap: 16px;
  }
`

export const DetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 24px;

  @media (max-width: ${breakpoints.MOBILE_XL}px) {
    padding: 16px;
  }
`

export const Actions = styled.div`
  display: flex;
  gap: 16px;
  justify-content: flex-end;

  @media (max-width: ${breakpoints.MOBILE_L}px) {
    flex-direction: column-reverse;

    > * {
      margin-right: 0 !important;
    }
  }
`

export const Spin = styled.div`
  display: flex;
  width: 100%;
  height: 100vh;
  align-items: center;
  justify-content: center;
`
