import React, { useEffect, useState } from 'react'

import { Button, Pagination, Select } from 'antd'
import { format } from 'date-fns'
import { observer } from 'mobx-react-lite'
import { useNavigate } from 'react-router-dom'

import { usePagination, useSorting } from '~/hooks'
import { appRoutes } from '~/router'
import { adminOffersFilterStore } from '~/store/filters/offersFilterStore'
import { appToast, httpErrorHandler } from '~/utils'
import { getOfferStatus } from '~/utils/offers/get-offer-status'
import { OfferCreatorType, OfferDataStatusEnum, OfferSortField } from '~api/generated'
import { useGetModeratorOfferList, useUpdateOfferModerator } from '~hooks/moderator'
import { useCreateOfferAdmin, useGetReportOffersByAdmin } from '~hooks/offer'
import { ModeratorOfferListMobile } from '~pages/ModeratorPage/components/ModeratorOfferList/components'
import { CreateOfferForAdminModal } from '~pages/OffersPage/components/CreateOfferForAdminModal'
import { OffersFilter } from '~pages/OffersPage/components/OffersFilter'
import { useAuth } from '~providers/auth'
import { ActiveFiltersTags } from '~shared/components'
import { SearchInput } from '~shared/components/SearchInput'
import { CompanySelectControl } from '~shared/controls'

import { ModeratorOfferListTable } from './components/ModeratorOfferListTable'

import {
  IOfferSortType,
  OfferListTableDataType,
} from '~pages/ModeratorPage/components/ModeratorOfferList/components/ModeratorOfferListTable/ModeratorOfferListTable.types'
import { SubmitAdminModalData } from '~pages/OffersPage/components/CreateOfferForAdminModal/CreateOfferForAdminModal.types'

import * as S from './ModeratorOfferList.styled'

export const ModeratorOfferList: React.FC = observer(() => {
  const { userId } = useAuth()
  const navigate = useNavigate()
  const [isVisibleCreateOfferModal, setVisibleCreateOfferModal] = useState(false)
  const [isSavingOffer, setSavingOffer] = useState(false)
  const { currentPage, pageSize, setCurrentPage, setPageSize, pageSizeOptions } = usePagination()
  const { orderBy, order, setOrderBy, setOrder } = useSorting()
  const filters = adminOffersFilterStore.filters
  const {
    data: offerListData,
    isFetching,
    isLoading,
    refetch,
  } = useGetModeratorOfferList({
    currentPage,
    pageSize,
    orderBy: (orderBy as OfferSortField) ?? 'id',
    order: order ?? 'desc',
    companyIds: adminOffersFilterStore.companyId ? [adminOffersFilterStore.companyId] : undefined,
    offerIds: !adminOffersFilterStore.idSearchQuery.trim() ? undefined : [Number(adminOffersFilterStore.idSearchQuery)],
    creatorType: adminOffersFilterStore.creatorType,
    statuses:
      adminOffersFilterStore.statusSearchQuery === 'all' ? undefined : [adminOffersFilterStore.statusSearchQuery],
    ...adminOffersFilterStore.mappedCurrentFiltersToResponse,
  })
  const updateOfferModerator = useUpdateOfferModerator()
  const getReportOffersFn = useGetReportOffersByAdmin()
  const createOfferAdminFn = useCreateOfferAdmin()

  useEffect(() => {
    setCurrentPage(1)
    refetch()
  }, [
    adminOffersFilterStore.idSearchQuery,
    adminOffersFilterStore.statusSearchQuery,
    adminOffersFilterStore.creatorType,
    adminOffersFilterStore.companyId,
    filters.volume.from,
    filters.volume.to,
    filters.price.from,
    filters.price.to,
    filters.offerType,
    filters.regionIds,
    filters.productTypes,
  ])

  const offerList = offerListData?.moderatedOffer
  const pagination = offerListData?.pagination

  const takeOfferToWorkHandler = async (offerId: number) => {
    if (!userId) {
      return
    }

    try {
      await updateOfferModerator.mutateAsync({
        offerId,
        updateOfferModeratorBody: {
          moderatorId: userId,
        },
      })

      appToast.success({ description: `Вы взяли в работу предложение ${offerId}` })
    } catch (e) {
      httpErrorHandler(e, 'Ошибка при обновлении модератора предложения')
    }
    await refetch()
  }

  const offerListTableData: OfferListTableDataType[] =
    offerList?.map((offer) => ({
      id: offer.id,
      culture: offer.product?.name ?? '-',
      volume: offer.volume,
      price: offer.price,
      status: offer.status,
      companyName: offer?.company?.name ?? '-',
      createdAt: offer.createdAt ? format(new Date(offer.createdAt), 'dd.MM.yyyy, HH:mm') : '',
      expiredAt: offer.expiredAt ? new Date(offer.expiredAt) : null,
      moderator: offer.moderator,
      createdBy: offer.user ?? null,
    })) ?? []

  const onOfferClickHandler = (record: OfferListTableDataType) => {
    navigate(appRoutes.moderatorOffers.url + '/' + record.id)
  }

  const onSortingChangeHandler = (value: IOfferSortType) => {
    setOrderBy(value.orderBy)
    setOrder(value.order)
    setCurrentPage(1)
  }

  const handleSubmitAdminModal = async (data: SubmitAdminModalData) => {
    setSavingOffer(true)
    try {
      await createOfferAdminFn.mutateAsync(data)
      await refetch()
      appToast.success({ description: 'Предложение успешно создано' })
      setVisibleCreateOfferModal(false)
    } catch (e) {
      httpErrorHandler(e, 'Ошибка при создании предложения')
    } finally {
      setSavingOffer(false)
    }
  }

  const downloadReportHandler = async () => {
    try {
      const response = await getReportOffersFn.mutateAsync({
        currentPage,
        pageSize,
        orderBy: orderBy as OfferSortField,
        order,
        offerIds: !adminOffersFilterStore.idSearchQuery.trim()
          ? undefined
          : [Number(adminOffersFilterStore.idSearchQuery)],
        statuses:
          adminOffersFilterStore.statusSearchQuery === 'all' ? undefined : [adminOffersFilterStore.statusSearchQuery],
        ...adminOffersFilterStore.mappedCurrentFiltersToResponse,
      })
      const objectUrl = URL.createObjectURL(response.data as any)
      const link = document.createElement('a')
      link.href = objectUrl
      link.setAttribute('download', 'Список предложений')
      document.body.appendChild(link)
      link.click()
      link?.parentNode?.removeChild(link)
    } catch (e) {
      console.log(e)
      httpErrorHandler(e, 'Ошибка при скачивании отчёта')
    }
  }

  return (
    <S.Content>
      <S.Header>
        <SearchInput
          defaultValue={adminOffersFilterStore.idSearchQuery}
          onSearch={(value) => adminOffersFilterStore.setIdSearchQuery(value)}
        />
        <CompanySelectControl value={undefined} onChange={(x) => adminOffersFilterStore.setCompanyId(x ?? null)} />
        <Select
          style={{ width: '100%' }}
          defaultValue={adminOffersFilterStore.statusSearchQuery}
          onChange={(value) => adminOffersFilterStore.setStatusSearchQuery(value)}
        >
          <Select.Option value="all">Все</Select.Option>
          <Select.Option value={OfferDataStatusEnum.OnModeration}>
            {getOfferStatus(OfferDataStatusEnum.OnModeration).name}
          </Select.Option>
          <Select.Option value={OfferDataStatusEnum.Published}>
            {getOfferStatus(OfferDataStatusEnum.Published).name}
          </Select.Option>
          <Select.Option value={OfferDataStatusEnum.PrePublished}>
            {getOfferStatus(OfferDataStatusEnum.PrePublished).name}
          </Select.Option>
          <Select.Option value={OfferDataStatusEnum.InDeal}>
            {getOfferStatus(OfferDataStatusEnum.InDeal).name}
          </Select.Option>
          <Select.Option value={OfferDataStatusEnum.Realised}>
            {getOfferStatus(OfferDataStatusEnum.Realised).name}
          </Select.Option>
          <Select.Option value={OfferDataStatusEnum.Rejected}>
            {getOfferStatus(OfferDataStatusEnum.Rejected).name}
          </Select.Option>
          <Select.Option value={OfferDataStatusEnum.Expired}>
            {getOfferStatus(OfferDataStatusEnum.Expired).name}
          </Select.Option>
        </Select>

        <Select
          style={{ width: '100%' }}
          value={adminOffersFilterStore.creatorType}
          placeholder="Создал предложение"
          onChange={(e) => adminOffersFilterStore.setCreatorType(e)}
          allowClear
        >
          <Select.Option value={OfferCreatorType.Admin}>Администратор</Select.Option>
          <Select.Option value={OfferCreatorType.User}>Пользователь</Select.Option>
        </Select>

        <OffersFilter store="admin" />

        <Button type="dashed" htmlType="button" onClick={downloadReportHandler}>
          Скачать отчет
        </Button>

        <Button type="primary" onClick={() => setVisibleCreateOfferModal(true)}>
          Создать предложение
        </Button>
      </S.Header>

      <ActiveFiltersTags store={adminOffersFilterStore} />

      <CreateOfferForAdminModal
        visible={isVisibleCreateOfferModal}
        onClose={() => setVisibleCreateOfferModal(false)}
        onSubmit={handleSubmitAdminModal}
        isFetching={isSavingOffer}
      />

      <S.Desktop>
        <ModeratorOfferListTable
          tableData={offerListTableData}
          loading={isLoading || isFetching}
          takeOfferToWork={takeOfferToWorkHandler}
          onRowClick={onOfferClickHandler}
          onSortingChange={onSortingChangeHandler}
        />
      </S.Desktop>
      <S.Mobile>
        <ModeratorOfferListMobile
          data={offerListTableData}
          loading={isLoading || isFetching}
          onItemClick={onOfferClickHandler}
          takeOfferToWork={takeOfferToWorkHandler}
        />
      </S.Mobile>
      {!!pagination && (
        <Pagination
          current={pagination?.currentPage}
          total={pagination?.total}
          pageSize={pagination?.pageSize}
          pageSizeOptions={pageSizeOptions}
          onShowSizeChange={(x, size) => setPageSize(size)}
          showSizeChanger
          onChange={setCurrentPage}
        />
      )}
    </S.Content>
  )
})
