import React, { useEffect, useState } from 'react'

import { Button, Pagination } from 'antd'
import { observer } from 'mobx-react-lite'
import { useNavigate } from 'react-router-dom'

import { usePagination } from '~/hooks'
import { basisAdvertsAdminFilterStore } from '~/store/filters'
import { appToast, httpErrorHandler } from '~/utils'
import { useUploadFileAndGetId } from '~hooks/_utils'
import { useGetCompanyZernoAgregator } from '~hooks/company/useGetCompanyZernoAgregator'
import {
  useCreateElevatorAdvertBuyByAdmin,
  useCreateElevatorAdvertSellByAdmin,
  useGetElevatorAdvertsAdmin,
  useUpdateElevatorAdvertModerator,
} from '~hooks/elevator-advert-admin'
import {
  CreateAdvertFormByAdmin,
  CreateAdvertFormToBuyByAdminFormValues,
} from '~pages/ModeratorPage/components/ModeratorBasisAdvert/CreateAdvertFormByAdmin'
import { ModeratorBasisAdvertFilters } from '~pages/ModeratorPage/components/ModeratorBasisAdvert/ModeratorBasisAdvertFilters'
import { ModeratorBasisAdvertListTable } from '~pages/ModeratorPage/components/ModeratorBasisAdvert/ModeratorBasisAdvertList/components/ModeratorBasisAdvertListTable'
import { useApi } from '~providers/api'
import { useAuth } from '~providers/auth'

import { ModeratorBasisAdvertListTableDataType } from './components/ModeratorBasisAdvertListTable/ModeratorBasisAdvertListTable.types'
import { CreateAdvertFormToSellByAdminFormValues } from '~pages/ModeratorPage/components/ModeratorBasisAdvert/CreateAdvertFormByAdmin/components/CreateAdvertFormToSellByAdmin/CreateAdvertFormToSellByAdmin.types'

import * as S from './ModeratorBasisAdvertList.styled'

export const ModeratorBasisAdvertList: React.FC = observer(() => {
  const api = useApi()
  const navigate = useNavigate()
  const { userId } = useAuth()
  const { currentPage, setCurrentPage, pageSize, setPageSize, pageSizeOptions } = usePagination()
  const [isCreateAdvertModalOpen, setIsCreateAdvertModalOpen] = useState(false)
  const companyZernoAgregatorFn = useGetCompanyZernoAgregator()
  const createElevatorAdvertBuyFn = useCreateElevatorAdvertBuyByAdmin()
  const createElevatorAdvertSellFn = useCreateElevatorAdvertSellByAdmin()
  const updateElevatorAdvertModeratorFn = useUpdateElevatorAdvertModerator()
  const uploadLabAnalysisAndGetIdFn = useUploadFileAndGetId()

  useEffect(() => {
    setCurrentPage(1)
  }, [
    basisAdvertsAdminFilterStore.advertStatuses,
    basisAdvertsAdminFilterStore.elevatorId,
    basisAdvertsAdminFilterStore.idSearchQuery,
    basisAdvertsAdminFilterStore.advertType,
  ])

  const getElevatorAdvertsAdminFn = useGetElevatorAdvertsAdmin({
    currentPage,
    pageSize,
    statuses: basisAdvertsAdminFilterStore.advertStatuses,
    type: basisAdvertsAdminFilterStore.advertType,
    elevatorIds: basisAdvertsAdminFilterStore.elevatorId ? [basisAdvertsAdminFilterStore.elevatorId] : undefined,
    elevatorAdvertIds: basisAdvertsAdminFilterStore.idSearchQuery
      ? [Number(basisAdvertsAdminFilterStore.idSearchQuery)]
      : undefined,
  })

  const onRowClickHandler = (record: ModeratorBasisAdvertListTableDataType) => {
    navigate('/moderator/basis/adverts/' + record.id)
  }

  const onCancelCreateAdvertModalHandler = () => {
    setIsCreateAdvertModalOpen(false)
  }

  const takeAdvertToWorkHandler = async (advertId: number) => {
    if (!advertId || !userId) return

    try {
      await updateElevatorAdvertModeratorFn.mutateAsync({
        elevatorAdvertId: +advertId,
      })

      appToast.success({ description: `Вы взяли в работу объявление №${advertId}` })
      await getElevatorAdvertsAdminFn.refetch()
    } catch (e) {
      httpErrorHandler(e, 'Ошибка при обновлении модератора объявления')
    }
  }

  const onSubmitCreateAdvertBuyModalHandler = async (formValues: CreateAdvertFormToBuyByAdminFormValues) => {
    const product = formValues.culture
    if (!product) {
      return
    }

    try {
      const zernoAgregatorCompany = companyZernoAgregatorFn.data

      let foundCompanyIdByInn: null | number = null

      if (formValues.company === 'other') {
        const foundCompany = await api.companies.companyControllerGetCompanies({
          inns: [formValues.companyInfo.inn],
        })
        foundCompanyIdByInn = foundCompany?.data?.companies?.length ? foundCompany.data.companies[0].id : null
      }
      const foundUserIdByPhoneNumber: null | number =
        (
          await api.users.userControllerGetUserByPhone({
            phoneNumber: formValues.contactPhone,
          })
        ).data?.user?.id ?? null

      await createElevatorAdvertBuyFn.mutateAsync({
        adminCreateElevatorAdvertBuyBody: {
          elevatorId: formValues.elevatorId,
          allowOffersWithOtherParameters: formValues.allowOffersWithOtherParameters,
          pricePerTon: formValues.pricePerTon,
          product: {
            type: product.cultureTypeId ?? '',
            parameters: product.parameters?.map((p) => ({ type: p.type, value: p.value })) ?? [],
          },
          contactUser: {
            userId: foundUserIdByPhoneNumber,
            userData: !foundUserIdByPhoneNumber
              ? {
                  phoneNumber: formValues.contactPhone,
                  name: formValues.contactUser,
                }
              : null,
          },
          company: {
            inn: formValues.companyInfo.inn,
            // companyId: formValues.company === 'zernoAgregator' ? zernoAgregatorCompany?.id : foundCompanyIdByInn,
            // companyData:
            //   formValues.company === 'other' && !foundCompanyIdByInn
            //     ? {
            //         name: formValues.companyInfo.name,
            //         inn: formValues.companyInfo.inn,
            //         kpp: formValues.companyInfo.kpp,
            //         ogrn: formValues.companyInfo.ogrn,
            //         codeOkved: formValues.companyInfo.codeOkved,
            //         director: formValues.companyInfo.director,
            //         legalAddress: formValues.companyInfo.legalAddress,
            //         actualAddress: formValues.companyInfo.actualAddress,
            //         usingNds: false,
            //         aicCharterMember: false,
            //         type: undefined,
            //         signatoryLegalBasis: undefined,
            //         signatory: null,
            //         signatoryPosition: null,
            //       }
            //     : undefined,
          },
        },
      })

      appToast.success({ description: `Объявление создано` })
      setIsCreateAdvertModalOpen(false)
      await getElevatorAdvertsAdminFn.refetch()
    } catch (e) {
      httpErrorHandler(e, 'Ошибка при создании объявления')
    }
  }

  const onSubmitCreateAdvertSellModalHandler = async (formValues: CreateAdvertFormToSellByAdminFormValues) => {
    const product = formValues.culture
    if (!product) {
      return
    }

    try {
      const zernoAgregatorCompany = companyZernoAgregatorFn.data

      let foundCompanyIdByInn: null | number = null

      const laboratoryAnalysisFileKey = formValues.requiredLaboratoryAnalysis
        ? undefined
        : await uploadLabAnalysisAndGetIdFn.mutateAsync(formValues.labAnalysis)

      if (formValues.company === 'other') {
        const foundCompany = await api.companies.companyControllerGetCompanies({
          inns: [formValues.companyInfo.inn],
        })
        foundCompanyIdByInn = foundCompany?.data?.companies?.length ? foundCompany.data.companies[0].id : null
      }
      const foundUserIdByPhoneNumber: null | number =
        (
          await api.users.userControllerGetUserByPhone({
            phoneNumber: formValues.contactPhone,
          })
        ).data?.user?.id ?? null

      await createElevatorAdvertSellFn.mutateAsync({
        adminCreateElevatorAdvertSellBody: {
          laboratoryAnalysisFileKey,
          elevatorId: formValues.elevatorId,
          pricePerTon: formValues.pricePerTon,
          usingNds: formValues.usingNds,
          volume: formValues.volume,
          priceType: formValues.priceType,
          product: {
            type: product.cultureTypeId ?? '',
            parameters: product.parameters?.map((p) => ({ type: p.type, value: p.value })) ?? [],
          },
          contactUser: {
            userId: foundUserIdByPhoneNumber,
            userData: !foundUserIdByPhoneNumber
              ? {
                  phoneNumber: formValues.contactPhone,
                  name: formValues.contactUser,
                }
              : null,
          },
          company: {
            inn: formValues.companyInfo.inn,
            // companyId: formValues.company === 'zernoAgregator' ? zernoAgregatorCompany?.id : foundCompanyIdByInn,
            // companyData:
            //   formValues.company === 'other' && !foundCompanyIdByInn
            //     ? {
            //         name: formValues.companyInfo.name,
            //         inn: formValues.companyInfo.inn,
            //         kpp: formValues.companyInfo.kpp,
            //         ogrn: formValues.companyInfo.ogrn,
            //         codeOkved: formValues.companyInfo.codeOkved,
            //         director: formValues.companyInfo.director,
            //         legalAddress: formValues.companyInfo.legalAddress,
            //         actualAddress: formValues.companyInfo.actualAddress,
            //         usingNds: false,
            //         aicCharterMember: false,
            //         type: undefined,
            //         signatoryLegalBasis: undefined,
            //         signatory: null,
            //         signatoryPosition: null,
            //       }
            //     : undefined,
          },
        },
      })

      appToast.success({ description: `Объявление создано` })
      setIsCreateAdvertModalOpen(false)
      await getElevatorAdvertsAdminFn.refetch()
    } catch (e) {
      httpErrorHandler(e, 'Ошибка при создании объявления')
    }
  }

  const pagination = getElevatorAdvertsAdminFn.data?.pagination
  const elevatorAdverts = getElevatorAdvertsAdminFn.data?.elevatorAdverts

  const tableData: ModeratorBasisAdvertListTableDataType[] =
    elevatorAdverts?.map((advert) => ({
      id: advert.id,
      status: advert.status,
      basisName: advert.elevator.name,
      cultureName: advert.product.name,
      buyer: advert.company?.name ?? '',
      publishedAt: advert.publishedAt,
      rejectedAt: advert.rejectedAt,
      moderator: advert.moderator,
      type: advert.type,
    })) ?? []

  return (
    <S.Desktop>
      <S.FiltersWrapper>
        <ModeratorBasisAdvertFilters />

        <Button type="primary" htmlType="button" onClick={() => setIsCreateAdvertModalOpen(true)}>
          Создать объявление
        </Button>
      </S.FiltersWrapper>

      <ModeratorBasisAdvertListTable
        tableData={tableData}
        loading={getElevatorAdvertsAdminFn.isFetching}
        onRowClick={onRowClickHandler}
        takeAdvertToWork={takeAdvertToWorkHandler}
      />
      <br />
      <Pagination
        current={pagination?.currentPage}
        total={pagination?.total}
        pageSize={pageSize}
        pageSizeOptions={pageSizeOptions}
        onShowSizeChange={(x, size) => setPageSize(size)}
        showSizeChanger
        onChange={setCurrentPage}
      />

      <CreateAdvertFormByAdmin
        isOpen={isCreateAdvertModalOpen}
        isLoading={createElevatorAdvertBuyFn.isLoading || createElevatorAdvertSellFn.isLoading}
        onCancel={onCancelCreateAdvertModalHandler}
        onSubmitBuyAdvertFrom={onSubmitCreateAdvertBuyModalHandler}
        onSubmitSellAdvertFrom={onSubmitCreateAdvertSellModalHandler}
      />
    </S.Desktop>
  )
})
