import { useEffect, useState } from 'react'

import { getDealDocumentName } from '~/utils/deals/get-deal-document-name'
import { DealSideType } from '~/utils/deals/get-deal-side'
import { enumToArray } from '~/utils/enum-to-array'
import { DealDocumentDataTypeEnum } from '~api/generated'

export const paymentListSeller = [
  DealDocumentDataTypeEnum.InvoiceForSupplyPayment,
  DealDocumentDataTypeEnum.InvoiceForGoods,
  DealDocumentDataTypeEnum.ReconciliationActFromSeller,
  // DealDocumentDataTypeEnum.InvoiceForAgencyPayment,
  // DealDocumentDataTypeEnum.DeclarationOfConformityWithCuRegulations,
  // DealDocumentDataTypeEnum.CertificateForUseOfPesticides,
  // DealDocumentDataTypeEnum.CertificateOfOriginOfGoods,
  // DealDocumentDataTypeEnum.QuarantineCertificate,
  // DealDocumentDataTypeEnum.VeterinaryCertificateForGoods,
]

export const paymentListSellerAdmin = [...paymentListSeller, DealDocumentDataTypeEnum.InvoiceForAgencyPayment]

export const paymentListBuyerAdmin = [
  DealDocumentDataTypeEnum.PaymentOrderUnderTheSupplyContract,
  DealDocumentDataTypeEnum.ReconciliationActFromBuyer,
]

export const paymentListBuyer = [...paymentListBuyerAdmin, DealDocumentDataTypeEnum.PaymentOrderUnderAgencyContract]

export function getDealDocumentPaymentList(dealSide: DealSideType, isAdmin = false): { value: string; text: string }[] {
  const paymentList =
    dealSide === 'seller'
      ? isAdmin
        ? paymentListSellerAdmin
        : paymentListSeller
      : dealSide === 'buyer'
      ? isAdmin
        ? paymentListBuyerAdmin
        : paymentListBuyer
      : []
  return enumToArray(DealDocumentDataTypeEnum)
    .filter((key) => (<string[]>paymentList).includes(key))
    .map((key) => ({ value: key, text: getDealDocumentName(key) }))
}

export function isDocumentTypeUsesForDealSide(
  documentType: DealDocumentDataTypeEnum,
  dealSide: DealSideType,
  isAdmin: boolean
): boolean {
  switch (dealSide) {
    case 'seller':
      return (<string[]>paymentListSellerAdmin).includes(documentType)
    case 'buyer':
      return (<string[]>paymentListBuyer).includes(documentType)
    default:
      return false
  }
}
