import { DaDataAddress, DaDataSuggestion } from 'react-dadata/dist/types'
import * as yup from 'yup'

import { VALIDATORS_MSG } from '~shared/validations/validation-messages'

export const addressSchema = yup
  .object({})
  .nullable()
  .required(VALIDATORS_MSG.required)
  .test({
    name: 'address',
    exclusive: true,
    params: {},
    message: 'Укажите полный адрес',
    test: function (value) {
      const address = value as DaDataSuggestion<DaDataAddress> | undefined
      return !!address?.data.city || !!address?.data.settlement || !!address?.data.stead
    },
  })
