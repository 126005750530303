import styled from 'styled-components'

import css from '@styled-system/css'

export const ContentWrapper = styled.div(
  css({
    padding: ['8px', '8px', '32px'],
    display: 'grid',
    gap: '40px',
    gridTemplateColumns: [
      'repeat(1, minmax(300px, 100%))',
      'repeat(1, minmax(300px, 100%))',
      'repeat(2, minmax(350px, 400px))',
      'repeat(2, minmax(350px, 450px))',
      'repeat(2, minmax(350px, 500px))',
    ],
  })
)
