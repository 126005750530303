import { useMutation } from '@tanstack/react-query'

import { WarehouseApiWarehousePositionControllerDeleteWarehousePositionRequest } from '~api/generated'
import { useApi } from '~providers/api'

export const useWarehouseCultureRemove = () => {
  const api = useApi()

  return useMutation(
    ({ warehousePositionId }: WarehouseApiWarehousePositionControllerDeleteWarehousePositionRequest) => {
      if (!warehousePositionId) {
        return Promise.reject('[useWarehouseCultureRemove]: warehousePositionId was not passed')
      }

      return api.warehouses.warehousePositionControllerDeleteWarehousePosition({
        warehousePositionId,
      })
    }
  )
}
