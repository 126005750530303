import React from 'react'

import { Flex } from 'antd'
import { observer } from 'mobx-react-lite'
import { FiArrowRight } from 'react-icons/fi'
import { useNavigate } from 'react-router-dom'

import { appRoutes } from '~/router'
import { ReactComponent as BasisIcon } from '~assets/icons/basis.svg'
import { useGetDevice } from '~hooks/common'
import { VIP_CLIENTS } from '~layout/WelcomePageV2/VipCards'

import * as S from './NewBasisNotification.styled'

export const NewBasisNotification: React.FC = observer(() => {
  const navigate = useNavigate()
  const device = useGetDevice()
  const isMobile = device === 'mobile'

  const navigateToBasisHandler = () => {
    void navigate(appRoutes.basisList.url)
  }

  const hasPriorityPass = VIP_CLIENTS.gapResurs.isShow || VIP_CLIENTS.damate.isShow

  if (!hasPriorityPass) {
    return null
  }

  return (
    <S.ContentWrapper onClick={navigateToBasisHandler}>
      <S.ContentWrapper3>
        <S.CounterWrapper>
          <BasisIcon />
          <S.Description>
            {isMobile
              ? 'Закупки ведущих компаний на базисах'
              : 'Закупки ведущих компаний на элеваторах и предприятих в РФ в разделе «Базисы»'}
          </S.Description>
        </S.CounterWrapper>

        <FiArrowRight size={isMobile ? 20 : 24} />
      </S.ContentWrapper3>

      <Flex gap={isMobile ? 32 : 60} style={{ height: isMobile ? '32px' : '40px' }}>
        {VIP_CLIENTS.gapResurs.logo}
        {VIP_CLIENTS.damate.logo}
      </Flex>
    </S.ContentWrapper>
  )
})
