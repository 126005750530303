import { OfferDataStatusEnum } from '~api/generated'

import { BadgeVariant } from '~ui-components/components/Badge/Badge.types'

export function getOfferStatus(status: string): {
  name: string
  color: BadgeVariant
} {
  switch (status) {
    case OfferDataStatusEnum.OnModeration:
      return {
        name: 'Проверка предложения',
        color: 'yellow',
      }
    case OfferDataStatusEnum.PrePublished:
      return {
        name: 'Ожидание публикации',
        color: 'yellow',
      }
    case OfferDataStatusEnum.Published:
      return {
        name: 'Опубликовано',
        color: 'purple',
      }
    case OfferDataStatusEnum.PreDeal:
      return {
        name: 'Проверка отклика',
        color: 'blue',
      }
    case OfferDataStatusEnum.InDeal:
      return {
        name: 'Сделка',
        color: 'base',
      }
    case OfferDataStatusEnum.Realised:
      return {
        name: 'Реализовано',
        color: 'gray',
      }
    case OfferDataStatusEnum.Expired:
      return {
        name: 'Снято с публикации',
        color: 'gray',
      }
    case OfferDataStatusEnum.Rejected:
      return {
        name: 'Отменено',
        color: 'red',
      }
    default:
      return {
        name: 'Ошибка',
        color: 'red',
      }
  }
}
