import React, { useEffect } from 'react'

import { Button, Input, Modal, Radio, Space } from 'antd'
import { Controller, useForm } from 'react-hook-form'

import { yupResolver } from '@hookform/resolvers/yup/dist/yup'

import { FormField } from '~/ui-components'
import { modalSharedSettings } from '~/utils'
import { getElevatorAdvertOfferRejectReason } from '~/utils/elevator-advert-offers/get-elevator-advert-offer-reject-reason'
import { ElevatorOfferResponseRejectionReason } from '~api/generated'

import { basisAdvertOfferRejectReasonModalValidation } from './BasisAdvertOfferRejectReasonModal.validation'

import { BasisAdvertOfferRejectReasonProps, RejectAdvertOfferForm } from './BasisAdvertOfferRejectReasonModal.types'

const FORM_ID = 'reject-advert-offer-modal'

export const BasisAdvertOfferRejectReasonModal: React.FC<BasisAdvertOfferRejectReasonProps> = (props) => {
  const {
    watch,
    setValue,
    control,
    handleSubmit,
    formState: { errors, isDirty },
  } = useForm<RejectAdvertOfferForm>({
    defaultValues: {
      reason: undefined,
      message: '',
    },
    resolver: yupResolver(basisAdvertOfferRejectReasonModalValidation),
  })

  const watchReason = watch('reason')

  useEffect(() => {
    if (watchReason !== ElevatorOfferResponseRejectionReason.Other) {
      setValue('message', undefined)
    }
  }, [watchReason])

  const handleFormSubmit = (values: RejectAdvertOfferForm) => {
    props.onReject(values)
    props.onClose()
  }

  return (
    <Modal
      {...modalSharedSettings}
      destroyOnClose={true}
      width={undefined}
      open={props.isOpen}
      onCancel={props.onClose}
      title="Причина отклонения"
      footer={[
        <Button key="cancel" htmlType="button" onClick={props.onClose}>
          Отмена
        </Button>,
        <Button type="primary" key="submit" htmlType="submit" form={FORM_ID} disabled={!isDirty}>
          Отклонить
        </Button>,
      ]}
    >
      <form id={FORM_ID} onSubmit={handleSubmit(handleFormSubmit)}>
        <FormField htmlFor="reason" validateMessage={errors.reason?.message}>
          <Controller
            name="reason"
            control={control}
            render={({ field }) => (
              <Radio.Group onChange={(e) => field.onChange(e.target.value)} value={field.value}>
                <Space direction="vertical">
                  <Radio value={ElevatorOfferResponseRejectionReason.PriceDoesNotFit}>
                    {getElevatorAdvertOfferRejectReason(ElevatorOfferResponseRejectionReason.PriceDoesNotFit)}
                  </Radio>
                  <Radio value={ElevatorOfferResponseRejectionReason.QualityDoesNotFit}>
                    {getElevatorAdvertOfferRejectReason(ElevatorOfferResponseRejectionReason.QualityDoesNotFit)}
                  </Radio>
                  <Radio value={ElevatorOfferResponseRejectionReason.Other}>
                    {' '}
                    {getElevatorAdvertOfferRejectReason(ElevatorOfferResponseRejectionReason.Other)}
                  </Radio>
                </Space>
              </Radio.Group>
            )}
          />
        </FormField>

        <FormField
          isVisible={watchReason === ElevatorOfferResponseRejectionReason.Other}
          label="Комментарий"
          isRequired
          htmlFor="message"
          validateMessage={errors.message?.message}
        >
          <Controller
            name="message"
            control={control}
            render={({ field }) => (
              <Input.TextArea
                {...field}
                id="message"
                rows={4}
                placeholder="Укажите причину"
                maxLength={500}
                status={errors.message && 'error'}
              ></Input.TextArea>
            )}
          />
        </FormField>
      </form>
    </Modal>
  )
}
