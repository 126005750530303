import styled from 'styled-components'

import { css } from '@styled-system/css'

import { Box, Text } from '~/ui-components'

export const Card = styled.div(
  css({
    padding: ['16px', '24px', '32px'],
    background: '#FFFFFF',
    borderRadius: '16px',
    border: '1px solid #D6D6D6',
  })
)

export const Title = styled(Text).attrs({ variant: 'h3' })(css({}))

export const Description = styled(Box)(
  css({
    color: 'TxtDefault',
    variant: ['typography.tableBodyText', 'typography.tableBodyText', 'typography.textBaseRegular'],
    mt: 16,
  })
)
