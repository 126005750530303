import styled from 'styled-components'

import { css } from '@styled-system/css'

import { Card, Text } from '~/ui-components'

export const Wrapper = styled.div(
  css({
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
  })
)

export const CardValue = styled(Card.Value)(
  css({
    fontWeight: '600',
  })
)
export const ActionsWrapper = styled(Card.ActionsContent)(
  css({
    marginLeft: 'auto',
    display: 'flex',
    alignItems: 'center',
  })
)

export const DocDescription = styled(Text).attrs({ variant: 'description' })`
  color: var(--gray-5-color);
  display: block;
`

export const StatusWrapper = styled.div(
  css({
    display: 'flex',
    marginLeft: 'auto',
    alignItems: 'center',
  })
)

export const DocumentContent = styled.div(
  css({
    display: 'grid',
    gridTemplateColumns: '20px auto 100px max-content',
    columnGap: '24px',
  })
)
