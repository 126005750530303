import React, { useState } from 'react'

import { InputNumber } from 'antd'
import { InputNumberProps } from 'antd/es/input-number'
import { SearchOutlined } from '@ant-design/icons'

interface ISearchInputNumberProps extends InputNumberProps {
  defaultValue: number | undefined
  onSearch: (value: string | number | null) => void
}

export const SearchInputNumber: React.FC<ISearchInputNumberProps> = ({
  defaultValue,
  onSearch,
  placeholder = 'Поиск по №',
  ...inputProps
}) => {
  const [value, setValue] = useState<string | number | null>(defaultValue ?? null)

  return (
    <InputNumber
      value={value}
      placeholder={placeholder}
      onChange={setValue}
      addonAfter={<SearchOutlined onClick={() => onSearch(value)} />}
      {...inputProps}
    />
  )
}
