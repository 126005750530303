import React from 'react'

import { FiArrowRight } from 'react-icons/fi'

import { Card, Text } from '~/ui-components'
import { getDateFormat } from '~/utils/getDateFormat'
import { getProductTypeParametersForUI_V3 } from '~hooks/products/useGetProductTypeData'

import { SimilarBasisAdvertCardProps } from './SimilarBasisAdvertCard.types'

import * as S from './SimilarBasisAdvertCard.styled'

export const SimilarBasisAdvertCard: React.FC<SimilarBasisAdvertCardProps> = ({ advert, onClick }) => {
  return (
    <S.StyledCard onClick={() => onClick(advert.id)}>
      {advert?.volume && <S.Green>{advert.volume} т</S.Green>}

      <Card.Header>
        <S.CardHeaderTop>
          <div>
            <Card.Title>
              {advert.pricePerTon} ₽/т{' '}
              {advert.priceUpdatedAt && <S.PublishedAt>Обновлено {getDateFormat(advert.priceUpdatedAt)}</S.PublishedAt>}
            </Card.Title>
            <Text variant="normal">Цена без НДС (с НДС +10%)</Text>
          </div>

          <FiArrowRight size={24} />
        </S.CardHeaderTop>
      </Card.Header>
      <S.CardContent>
        <S.Parameters>
          {getProductTypeParametersForUI_V3(advert.product).map(({ parameterName, measurementUnit, value }) => (
            <div key={parameterName}>
              <span>{parameterName}</span>:<strong>{` ${value}${measurementUnit}`}</strong>
            </div>
          ))}
        </S.Parameters>

        {advert.company?.name && (
          <div style={{ marginTop: 'auto' }}>
            <Text variant="normal">{advert.company.name}</Text>
          </div>
        )}
      </S.CardContent>
    </S.StyledCard>
  )
}
