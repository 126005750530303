import React from 'react'

import { Select } from 'antd'
import { BaseSelectRef } from 'rc-select'

import { CustomControlInterface } from '~shared/controls/CustomControl.interface'

import { FreightPaymentTerm } from '~api/gql-generated/graphql'
import { getFreightPaymentTerm } from '~/utils/logistics/get-freight-payment-term'

interface FreightPaymentTermSelectControlProps extends CustomControlInterface {
  id?: string
  value: FreightPaymentTerm | undefined
  onChange: (value: FreightPaymentTerm) => void
  hasError?: boolean
}

export const FreightPaymentTermSelectControl: React.FC<FreightPaymentTermSelectControlProps> = React.forwardRef<
  BaseSelectRef,
  FreightPaymentTermSelectControlProps
>(({ id, value, onChange, hasError }, ref) => {
  return (
    <Select
      ref={ref}
      style={{ width: '100%' }}
      placeholder="Выберите"
      value={value}
      onChange={onChange}
      status={!!hasError ? 'error' : undefined}
    >
      {Object.values(FreightPaymentTerm).map((freightPaymentTerm) => (
        <Select.Option key={freightPaymentTerm} value={freightPaymentTerm}>
          {getFreightPaymentTerm(freightPaymentTerm)}
        </Select.Option>
      ))}
    </Select>
  )
})

FreightPaymentTermSelectControl.displayName = 'FreightPaymentTermSelectControl'
