import { Modal } from 'antd'
import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  height: 100%;
`

export const Actions = styled.div`
  display: flex;
  gap: 16px;
  flex: 1;
  align-items: flex-end;
`
export const StepWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 24px;
`
export const StepImg = styled('img')`
  height: auto;
  width: 300px;
  object-fit: cover;
`
export const StyledModal = styled(Modal)`
  .ant-modal-body {
    height: calc(100vh - 90px);
  }
`
