import React, { PropsWithChildren } from 'react'

import { TransportMode } from '~api/generated'
import { CustomControlInterface } from '~shared/controls/CustomControl.interface'

import * as S from './WarehouseTransferTypeControl.styled'

type Value = TransportMode[] | undefined

interface IPriceControlProps extends CustomControlInterface {
  onChange: (value: Value) => void
  value: Value
  hasError?: boolean
}

const transferTypeOptions = [
  { label: 'Автомобильный', value: TransportMode.Road },
  { label: 'Железнодорожный', value: TransportMode.Rail },
  { label: 'Морской/внутренний водный (речной)', value: TransportMode.River },
]

export const WarehouseTransferTypeControl: React.FC<PropsWithChildren<IPriceControlProps>> = React.forwardRef<
  HTMLInputElement,
  PropsWithChildren<IPriceControlProps>
>(({ value, onChange, hasError }, ref) => {
  const onChangeHandler = (checkedValue: unknown) => {
    onChange(checkedValue as Value)
  }

  return <S.StyledCheckboxGroup ref={ref} options={transferTypeOptions} value={value} onChange={onChangeHandler} />
})

WarehouseTransferTypeControl.displayName = 'WarehouseTransferTypeControl'
