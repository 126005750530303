import styled from 'styled-components'

import { breakpoints } from '~/tokens'
import { Text } from '~/ui-components'

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding: 24px;

  @media (max-width: ${breakpoints.DESKTOP_S}px) {
    padding: 12px;
    gap: 16px;
  }
`

export const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-bottom: 16px;
`
export const CultureInfoWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: space-between;
  margin-bottom: 6px;
`

export const CompanyName = styled(Text).attrs({ variant: 'description' })`
  margin-bottom: 16px;
`

export const Price = styled(Text).attrs({ variant: 'h4' })`
  color: var(--primary-main-color);
`

export const PublishDateCountdown = styled(Text).attrs({ variant: 'normal-bold' })`
  color: var(--primary-main-color);
  align-self: flex-end;
  margin-bottom: 16px;
`

export const VolumeMobile = styled(Text).attrs({ variant: 'h4' })`
  color: var(--primary-main-color);
  display: none;
  @media (max-width: ${breakpoints.MOBILE_XL}px) {
    display: unset;
  }
`
export const VolumeDesktop = styled(Text).attrs({ variant: 'h4' })`
  color: var(--primary-main-color);
  display: unset;
  padding: 12px 0 8px;
  @media (max-width: ${breakpoints.MOBILE_XL}px) {
    display: none;
  }
`
export const PriceType = styled(Text).attrs({ variant: 'normal' })`
  color: var(--gray-5-color);
`
export const PriceWrapper = styled.div`
  margin-top: 16px;
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: space-between;
`

export const ParametersInfo = styled.div<{ isAllow: boolean }>`
  display: flex;
  gap: 8px;
  align-items: center;
  > * {
    min-width: 20px;
    color: ${(props) => (props.isAllow ? 'var(--badge-blue-bg-color)' : '#989898')};
  }
`
export const ActionWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 8px;
  @media (max-width: ${breakpoints.MOBILE_XL}px) {
    flex-direction: column;
  }
`
export const OffersWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 8px;

  @media (max-width: ${breakpoints.MOBILE_XL}px) {
    grid-template-columns: 1fr;
  }
`

export const PublishedAt = styled(Text).attrs({ variant: 'description' })`
  color: var(--gray-4-color);
`
