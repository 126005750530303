import { useMutation } from '@tanstack/react-query'

import { ElevatorAdvertApiGetElevatorOfferReportRequest } from '~api/generated'
import { useApi } from '~providers/api'

export const useGetReportAdvert = () => {
  const api = useApi()

  return useMutation((request: ElevatorAdvertApiGetElevatorOfferReportRequest) => {
    return api.elevatorAdvert.getElevatorOfferReport(request, { responseType: 'blob' })
  })
}
