import React, { useState } from 'react'

import { Button, Modal } from 'antd'

import { modalSharedSettings } from '~/utils'
import { CreateAdvertFormToBuyByAdmin } from '~pages/ModeratorPage/components/ModeratorBasisAdvert/CreateAdvertFormByAdmin/components/CreateAdvertFormToBuyByAdmin/CreateAdvertFormToBuyByAdmin'
import { CreateAdvertFormToSellByAdmin } from '~pages/ModeratorPage/components/ModeratorBasisAdvert/CreateAdvertFormByAdmin/components/CreateAdvertFormToSellByAdmin/CreateAdvertFormToSellByAdmin'
import { ElevatorAdvertTypeSelectControl } from '~shared/controls'

import { CreateAdvertFormToBuyByAdminFormValues } from '~pages/ModeratorPage/components/ModeratorBasisAdvert/CreateAdvertFormByAdmin/components/CreateAdvertFormToBuyByAdmin/CreateAdvertFormToBuyByAdmin.types'
import { CreateAdvertFormToSellByAdminFormValues } from '~pages/ModeratorPage/components/ModeratorBasisAdvert/CreateAdvertFormByAdmin/components/CreateAdvertFormToSellByAdmin/CreateAdvertFormToSellByAdmin.types'

interface CreateAdvertFormByAdminProps {
  isOpen: boolean
  isLoading: boolean
  onCancel: () => void
  onSubmitBuyAdvertFrom: (formValues: CreateAdvertFormToBuyByAdminFormValues) => void
  onSubmitSellAdvertFrom: (formValues: CreateAdvertFormToSellByAdminFormValues) => void
}

type AdvertType = 'BUY' | 'SELL'
const FORM_ID = 'create-advert-form-by-admin-id'

export const CreateAdvertFormByAdmin: React.FC<CreateAdvertFormByAdminProps> = ({
  isOpen,
  isLoading,
  onCancel,
  onSubmitBuyAdvertFrom,
  onSubmitSellAdvertFrom,
}) => {
  const [advertType, setAdvertType] = useState<AdvertType>('BUY')

  return (
    <Modal
      {...modalSharedSettings}
      width={500}
      open={isOpen}
      onCancel={onCancel}
      title="Создать объявление"
      footer={[
        <Button key="cancel" onClick={onCancel} htmlType="button">
          Отмена
        </Button>,
        <Button key="submit" form={FORM_ID} type="primary" htmlType="submit" loading={isLoading}>
          Создать
        </Button>,
      ]}
    >
      <div style={{ marginBottom: 24 }}>
        <ElevatorAdvertTypeSelectControl
          variant="switch"
          value={advertType}
          onChange={(advertType) => setAdvertType(advertType as AdvertType)}
        />
      </div>

      {advertType === 'BUY' && <CreateAdvertFormToBuyByAdmin formId={FORM_ID} onSubmit={onSubmitBuyAdvertFrom} />}
      {advertType === 'SELL' && <CreateAdvertFormToSellByAdmin formId={FORM_ID} onSubmit={onSubmitSellAdvertFrom} />}
    </Modal>
  )
}
