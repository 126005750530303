import React from 'react'

import { Button } from 'antd'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { FiLink } from 'react-icons/fi'

import { appToast } from '~/utils'

export const CopyURLButton = () => {
  return (
    <CopyToClipboard text={location.href} onCopy={() => appToast.success()}>
      <Button icon={<FiLink />} type="link">
        Скопировать ссылку
      </Button>
    </CopyToClipboard>
  )
}
