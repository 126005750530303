import styled from 'styled-components'

import { breakpoints } from '~/tokens'
import { Text } from '~/ui-components'

export const LIstWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`

export const Parameters = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 8px;
  max-width: 400px;
  color: var(--badge-gray-bg-color);
  @media (max-width: ${breakpoints.MOBILE_XL}px) {
    grid-template-columns: 1fr 1fr;
    max-width: 300px;
  }
`
export const CardFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
`
export const CardHeader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 8px;
  margin-bottom: 16px;
`
export const CardHeaderTop = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
`
export const PriceWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`
export const CultureWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 6px;
`
export const ValueGreen = styled(Text).attrs({ variant: 'h4' })`
  color: var(--primary-main-color);
`
export const PriceRowWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`

export const TitleAdvertNumber = styled(Text).attrs({ variant: 'normal' })`
  color: #777777;
`
export const PublishedAt = styled(Text).attrs({ variant: 'description' })`
  color: var(--gray-4-color);
`
export const OffersCounterWrapper = styled.div<{ isActive: boolean }>`
  display: flex;
  align-items: center;
  background: ${(p) => (p.isActive ? '#eef7ed' : '#F5F5F5')};
  border: 1px solid #daede5;
  border-radius: 20px;
  padding: 4px 8px;
  gap: 8px;
  min-width: max-content;
`
export const OffersCounter = styled(Text).attrs({ variant: 'normal' })<{ isActive: boolean }>`
  color: ${(p) => (p.isActive ? '#45a67d' : '#9E9E9E')};
`

export const OffersCounterNew = styled(Text).attrs({ variant: 'normal' })`
  background: #45a67d;
  color: white;
  padding: 4px 8px;
  border-radius: 20px;
  ::before {
    content: '+';
  }
`
