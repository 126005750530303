import React from 'react'

import { Input } from 'antd'
import { Controller, useForm } from 'react-hook-form'

import { yupResolver } from '@hookform/resolvers/yup'

import { FormField, UploadButton } from '~/ui-components'
import { GetOfferResponseTypeEnum } from '~api/generated'
import { AddressControlDadata, ParametersCultureControl, PriceControl, VolumeControl } from '~shared/controls'
import { CheckboxControl } from '~shared/controls/CheckboxControl'
import { ParametersCultureControlValue } from '~shared/controls/ParametersCultureControl'
import { UsingNdsControl } from '~shared/controls/UsingNdsControl'

import { moderatorOfferFormSchema } from './ModeratorOfferEditForm.validation'

import { IModeratorOfferEditForm, IModeratorOfferEditFormProps } from './ModeratorOfferEditForm.types'

import * as S from './ModeratorOfferEditForm.styled'

export const ModeratorOfferEditForm: React.FC<IModeratorOfferEditFormProps> = (props) => {
  const cultureDefault: ParametersCultureControlValue = {
    cultureTypeId: props.defaultValues.product.type,
    parameters: props.defaultValues.product.parameters,
    hasError: false,
  }

  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<IModeratorOfferEditForm>({
    defaultValues: {
      address: undefined,
      culture: cultureDefault,
      volume: props.defaultValues.volume ?? 0,
      price: props.defaultValues.priceDetail?.originalPrice ?? undefined,
      usingNds: props.defaultValues?.includingNds ?? undefined,
      shippingRequired: props.defaultValues.shippingRequired ?? false,
      commission: Math.abs(props.defaultValues.priceDetail?.commission ?? 0),
      note: props.defaultValues?.note ?? undefined,
      labAnalysis: props.defaultValues?.laboratoryAnalysis?.source ?? undefined,
    },
    resolver: yupResolver(moderatorOfferFormSchema),
  })

  watch()

  const price = watch('price')
  const commission = watch('commission')

  const onSubmitHandler = (data: IModeratorOfferEditForm) => props.onSubmit(data)

  const total = (props.defaultValues.priceDetail?.commission ?? 0) < 0 ? price - commission : price + commission

  return (
    <S.Form onSubmit={handleSubmit(onSubmitHandler)} id={props.idForm}>
      <FormField label="Адрес" htmlFor="address" validateMessage={errors.address?.message}>
        <Controller
          name="address"
          control={control}
          render={({ field }) => (
            // <AddressControlAutocomplete
            //   id="address"
            //   value={field.value}
            //   onChange={field.onChange}
            //   hasError={!!errors.address}
            //   onAddressSelect={(suggestion) => field.onChange(suggestion?.value ?? suggestion?.unrestricted_value)}
            // />
            <AddressControlDadata
              id="address"
              value={props.defaultValues.address}
              onChange={(value) => field.onChange(value)}
              hasError={!!errors.address}
            />
          )}
        />
      </FormField>

      <FormField validateMessage={errors.culture ? 'Параметры культуры заполнены с ошибками' : ''}>
        <Controller name="culture" control={control} render={({ field }) => <ParametersCultureControl {...field} />} />
      </FormField>

      {props.defaultValues.type === GetOfferResponseTypeEnum.Sell && (
        <FormField label="Результаты лаб. исследований">
          <Controller
            control={control}
            name="labAnalysis"
            render={({ field }) => (
              <UploadButton
                fileName={props.defaultValues?.laboratoryAnalysis ? 'Лаб. анализ' : undefined}
                onFileClick={() => window.open(props.defaultValues?.laboratoryAnalysis?.source, '_blank')}
                onChange={(evt) => {
                  if (evt.target.files?.length) {
                    field.onChange(evt.target.files[0])
                  }
                }}
                onDelete={() => field.onChange(undefined)}
              >
                Загрузить
              </UploadButton>
            )}
          />
        </FormField>
      )}

      <FormField label="Объем, тонн" htmlFor="volume" isRequired validateMessage={errors.volume?.message}>
        <Controller
          name="volume"
          control={control}
          render={({ field }) => <VolumeControl {...field} id="volume" hasError={!!errors.volume} />}
        />
      </FormField>

      <FormField htmlFor="price" label="Цена за тонну, ₽" validateMessage={errors.price?.message}>
        <Controller
          name="price"
          control={control}
          render={({ field }) => <PriceControl id="price" {...field} hasError={!!errors.price} />}
        />
      </FormField>

      <FormField htmlFor="price" label="Размер комиссии" validateMessage={errors.commission?.message}>
        <>
          <Controller
            name="commission"
            control={control}
            render={({ field }) => (
              <PriceControl
                addBefore={(props.defaultValues.priceDetail?.commission ?? 0) < 0 ? '-' : '+'}
                id="commission"
                {...field}
                hasError={!!errors.price}
              />
            )}
          />
          <S.TotalText variant="normal-bold">Итого за тонну: {total}</S.TotalText>
        </>
      </FormField>

      <FormField htmlFor="usingNds" validateMessage={errors.usingNds?.message}>
        <Controller name="usingNds" control={control} render={({ field }) => <UsingNdsControl {...field} />} />
      </FormField>

      <FormField htmlFor="shippingRequired" validateMessage={errors.shippingRequired?.message}>
        <Controller
          name="shippingRequired"
          control={control}
          render={({ field }) => (
            <CheckboxControl {...field}>Требуется перевозчик для доставки до склада</CheckboxControl>
          )}
        />
      </FormField>

      <FormField label="Примечание" htmlFor="note" validateMessage={errors.note?.message}>
        <Controller
          name="note"
          control={control}
          render={({ field }) => (
            <Input.TextArea {...field} id="note" placeholder="Примечание" status={errors.note && 'error'} />
          )}
        />
      </FormField>
    </S.Form>
  )
}
