import React from 'react'

import { Flex, InputNumber, Radio, Space } from 'antd'
import { Controller, useFormContext } from 'react-hook-form'
import { FiAlertCircle } from 'react-icons/fi'

import { FormField, Text } from '~/ui-components'
import { inputSharedSettings } from '~/utils/inputSharedSettings'
import { FreightPaymentTerm } from '~api/gql-generated/graphql'

import { CreateLoadFormFieldProps } from '~pages/LogisticsPage/modules/MyCargos/components/CreateLoad/CreateLoad.types'

export const Conditions: React.FC<CreateLoadFormFieldProps> = ({ control, errors }) => {
  const { watch } = useFormContext()
  const paymentTerm = watch('paymentTerm')

  return (
    <Flex vertical gap={8}>
      <Text variant="h1" style={{ paddingBottom: '24px' }}>
        Условия
      </Text>

      <Flex vertical>
        <FormField
          label="Ставка без НДС"
          htmlFor="pricePerTonWithoutNds"
          validateMessage={errors.pricePerTonWithoutNds?.message}
          isRequired
        >
          <Controller
            name="pricePerTonWithoutNds"
            control={control}
            render={({ field }) => (
              <InputNumber
                {...field}
                {...inputSharedSettings}
                addonAfter="₽/т"
                style={{ width: '100%', maxWidth: '342px' }}
                id="pricePerTonWithoutNds"
                placeholder="Введите значение"
                status={errors.pricePerTonWithoutNds && 'error'}
                step={1}
              />
            )}
          />
        </FormField>

        <FormField validateMessage={errors.isAuction?.message} isRequired>
          <Controller
            name="isAuction"
            control={control}
            render={({ field }) => (
              <Radio.Group {...field}>
                <Space size="large">
                  <Radio value={true}>Возможен торг</Radio>
                  <Radio value={false}>Без торга</Radio>
                </Space>
              </Radio.Group>
            )}
          />
        </FormField>
      </Flex>

      <Flex gap={24}>
        <FormField
          label="Условия оплаты"
          htmlFor="paymentTerm"
          validateMessage={errors.paymentTerm?.message}
          isRequired
        >
          <Controller
            name="paymentTerm"
            control={control}
            render={({ field }) => (
              <Radio.Group {...field} optionType="button" buttonStyle="solid" size="middle">
                <Radio.Button value={FreightPaymentTerm.Advance}>Предоплата</Radio.Button>
                <Radio.Button value={FreightPaymentTerm.Deferred}>Отсрочка</Radio.Button>
              </Radio.Group>
            )}
          />
        </FormField>
      </Flex>

      <Flex vertical>
        {paymentTerm === FreightPaymentTerm.Deferred && (
          <FormField
            label="Кол-во дней отсрочки"
            htmlFor="deferredDaysCount"
            validateMessage={errors.deferredDaysCount?.message}
            isRequired
          >
            <Controller
              name="deferredDaysCount"
              control={control}
              render={({ field }) => (
                <InputNumber
                  {...field}
                  {...inputSharedSettings}
                  style={{ width: '100%', maxWidth: '342px' }}
                  id="deferredDaysCount"
                  placeholder="Введите значение"
                  status={errors.deferredDaysCount && 'error'}
                  step={1}
                  addonAfter="дней"
                />
              )}
            />
          </FormField>
        )}

        {paymentTerm === FreightPaymentTerm.Advance && (
          <>
            <FormField
              label="Процент предоплаты"
              htmlFor="advancePercent"
              validateMessage={errors.advancePercent?.message}
              isRequired
            >
              <Controller
                name="advancePercent"
                control={control}
                render={({ field }) => (
                  <InputNumber
                    {...field}
                    {...inputSharedSettings}
                    addonAfter="%"
                    style={{ width: '100%', maxWidth: '342px' }}
                    id="advancePercent"
                    placeholder="Введите значение"
                    status={errors.advancePercent && 'error'}
                    step={1}
                  />
                )}
              />
            </FormField>

            <Flex align="center" gap={12}>
              <FiAlertCircle size="24" />
              <Text variant="normal">Остаток оплаты производится при получении оригинала ТТН заказчиком</Text>
            </Flex>
          </>
        )}
      </Flex>
    </Flex>
  )
}
