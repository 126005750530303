import styled from 'styled-components'
import { Button } from 'antd'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 348px;
  padding: 24px;
`

export const StyledForm = styled.form`
  width: 100%;
`

export const ActionWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 16px;
`

export const StyledButton = styled(Button)`
  background: var(--gray-2-color);
  color: var(--gray-5-color);
`
