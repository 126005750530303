import { useQuery, UseQueryOptions } from '@tanstack/react-query'

import { GetUserResponse, UserData } from '~/api/generated'
import { isApiResponseEmpty } from '~/utils/isApiResponseEmpty'
import { useApi } from '~providers/api'

type Options = Omit<
  UseQueryOptions<UserData | null, unknown, UserData | null, (string | number | null | undefined)[]>,
  'queryKey' | 'queryFn'
>

export const useGetUserById = (userId: number | undefined | null, options?: Options) => {
  const api = useApi()

  const fetchFn = async () => {
    if (!userId) {
      return null
    }

    try {
      const res = await api.users.userControllerGetUsers({ userIds: [userId], pageSize: 100 })

      return res.data?.users?.length ? res.data.users[0] : null
    } catch (error) {
      if (isApiResponseEmpty(error)) {
        return null
      }

      return Promise.reject(error)
    }
  }

  return useQuery(['fetchUser', userId], fetchFn, options)
}
