/* tslint:disable */
/* eslint-disable */
/**
 * API Зерно-Агрегатора
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { UserCreatedBy } from './user-created-by';

/**
 * 
 * @export
 * @interface GetUserResponse
 */
export interface GetUserResponse {
    /**
     * Id пользователя
     * @type {number}
     * @memberof GetUserResponse
     */
    'id': number;
    /**
     * Номер телефона
     * @type {string}
     * @memberof GetUserResponse
     */
    'phoneNumber': string;
    /**
     * Статус пользователя
     * @type {string}
     * @memberof GetUserResponse
     */
    'status': GetUserResponseStatusEnum;
    /**
     * Полное имя
     * @type {string}
     * @memberof GetUserResponse
     */
    'name': string;
    /**
     * Должность
     * @type {string}
     * @memberof GetUserResponse
     */
    'position': string | null;
    /**
     * Электронная почта
     * @type {string}
     * @memberof GetUserResponse
     */
    'email': string | null;
    /**
     * Id компании
     * @type {number}
     * @memberof GetUserResponse
     */
    'companyId': number;
    /**
     * Роль пользователя
     * @type {string}
     * @memberof GetUserResponse
     */
    'role': GetUserResponseRoleEnum;
    /**
     * Создана пользователем
     * @type {UserCreatedBy}
     * @memberof GetUserResponse
     */
    'createdBy': UserCreatedBy | null;
    /**
     * Дата создания
     * @type {string}
     * @memberof GetUserResponse
     */
    'createdAt': string;
}

export const GetUserResponseStatusEnum = {
    Activated: 'ACTIVATED',
    Blocked: 'BLOCKED'
} as const;

export type GetUserResponseStatusEnum = typeof GetUserResponseStatusEnum[keyof typeof GetUserResponseStatusEnum];
export const GetUserResponseRoleEnum = {
    Owner: 'OWNER',
    Director: 'DIRECTOR',
    Manager: 'MANAGER',
    Accountant: 'ACCOUNTANT',
    Driver: 'DRIVER',
    Admin: 'ADMIN',
    Authenticated: 'AUTHENTICATED',
    DemoUser: 'DEMO_USER'
} as const;

export type GetUserResponseRoleEnum = typeof GetUserResponseRoleEnum[keyof typeof GetUserResponseRoleEnum];


