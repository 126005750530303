import { useQuery, UseQueryResult } from '@tanstack/react-query'

import {
  ElevatorApiElevatorControllerGetElevatorProductTypesRequest,
  GetElevatorProductTypesResponse,
} from '~api/generated'
import { useApi } from '~providers/api'

export const useGetElevatorProductTypes = (
  request: ElevatorApiElevatorControllerGetElevatorProductTypesRequest
): UseQueryResult<GetElevatorProductTypesResponse> => {
  const api = useApi()

  const fetchFn = async (): Promise<GetElevatorProductTypesResponse> => {
    const res = await api.elevators.elevatorControllerGetElevatorProductTypes(request)

    return res.data
  }

  return useQuery(['elevatorProductTypes', request.id], fetchFn, { keepPreviousData: true })
}
