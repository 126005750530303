import React from 'react'

import { DealBankAccountData } from '~api/generated'
import { BankInfoView } from '~pages/ProfilePage/components/tabs/CompanyProfileTab/components/BankInfoView'
import { NotificationMessageCard } from '~shared/components'

interface IDealBankInfoProps {
  bankAccount: DealBankAccountData | null
}

export const ModeratorDealBankInfo: React.FC<IDealBankInfoProps> = ({ bankAccount }) => {
  if (!bankAccount) {
    return (
      <NotificationMessageCard
        title="Пользователь пока не указал реквизиты"
        message="Когда пользователь укажет реквизиты, можно будет заполнить договор поставки и спецификацию."
      />
    )
  }

  return <BankInfoView data={bankAccount} />
}
