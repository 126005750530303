import { useMutation } from '@tanstack/react-query'

import { ElevatorApiElevatorAdminControllerUpdateElevatorRequest } from '~api/generated'
import { useApi } from '~providers/api'

export const useUpdateElevatorAdmin = () => {
  const api = useApi()

  const useUpdateElevatorFn = async (
    request: ElevatorApiElevatorAdminControllerUpdateElevatorRequest
  ): Promise<void> => {
    const res = await api.elevators.elevatorAdminControllerUpdateElevator(request)

    return res.data
  }

  return useMutation(useUpdateElevatorFn)
}
