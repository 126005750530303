import React from 'react'

import { Table } from 'antd'
import { ColumnsType } from 'antd/lib/table'

import { useGetTableHeight } from '~/hooks'
import { CompanyStatusBadge } from '~/utils/company/CompanyStatusBadge'
import { CompanyVerificationStatus } from '~api/generated'
import { actionArrowColumn } from '~shared/columns/shared'

import { CompanyListTableDataType, IModeratorCompanyListTableProps } from './ModeratorCompaniesListTable.types'

export const ModeratorCompaniesListTable: React.FC<IModeratorCompanyListTableProps> = ({
  tableData,
  loading,
  onRowClick,
}) => {
  const tableHeight = useGetTableHeight(294)

  const onRowClickHandler = (record: CompanyListTableDataType) => {
    onRowClick(record)
  }

  const columns: ColumnsType<CompanyListTableDataType> = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      width: '80px',
    },
    {
      title: 'Компания',
      dataIndex: 'companyName',
      key: 'companyName',
      width: '250px',
    },
    {
      title: 'ИНН',
      dataIndex: 'inn',
      key: 'inn',
      width: '160px',
    },
    {
      title: 'Роль',
      dataIndex: 'role',
      key: 'role',
      width: '160px',
    },
    {
      title: 'Создал компанию',
      dataIndex: 'createdBy',
      key: 'createdBy',
      width: 160,
    },
    {
      title: 'Статус компании',
      dataIndex: 'status',
      key: 'status',
      render: (value: CompanyVerificationStatus) => <CompanyStatusBadge status={value} />,
      width: '160px',
    },
    {
      title: 'Дата регистрации',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: '160px',
    },
    actionArrowColumn,
  ]

  return (
    <Table
      className="table-interactive"
      rowKey="id"
      loading={loading}
      columns={columns}
      dataSource={tableData}
      scroll={{ y: tableHeight, x: 'min-content' }}
      pagination={false}
      onRow={(record, index) => ({
        onClick: onRowClickHandler.bind(null, record, index),
      })}
    />
  )
}
