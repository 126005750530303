import React, { useEffect, useState } from 'react'

import { Button, InputNumber, Modal, Select } from 'antd'
import { Controller, useFieldArray, useForm, useWatch } from 'react-hook-form'
import { FiChevronDown, FiChevronUp, FiX } from 'react-icons/fi'

import { yupResolver } from '@hookform/resolvers/yup/dist/yup'

import { FormField } from '~/ui-components'
import { ProductTypeData } from '~api/generated'
import { useGetProductTypes } from '~hooks/products/useGetProductTypes'

import { parametersCultureControlSchema } from './ParametersCultureControl.schema'
import { findProductByType, initCultureParameters } from './utils'

import {
  IFormValues,
  IParametersCultureControlProps,
  ParametersCultureControlValue,
} from './ParametersCultureControl.types'

import * as S from './ParametersCultureControl.styled'

export const ParametersCultureControl: React.FC<IParametersCultureControlProps> = React.forwardRef<
  HTMLDivElement,
  IParametersCultureControlProps
>((props, ref) => {
  const { data: cultureList = [] } = useGetProductTypes()
  const [isExpandCultures, setExpandCultures] = useState(false)

  // если нужно сравнивать первое значение(дефолтное) с тем которое сейчас
  // const [defaultFormValue, setDefaultFormValue] = useState<ParametersCultureControlValue>(undefined)
  // useEffect(() => {
  //   setDefaultFormValue(cloneDeep(props.value))
  // }, [])

  const cultureFromControlValue = findProductByType(cultureList, props.value?.cultureTypeId)

  const cultureParametersFromControlValue = initCultureParameters(
    props.value?.cultureTypeId,
    props.value?.parameters ?? null,
    cultureList
  )
  const [selectedCulture, setSelectedCulture] = useState<ProductTypeData | null>(cultureFromControlValue)

  const { control, reset, formState, trigger } = useForm<IFormValues>({
    defaultValues: {
      cultureTypeId: props.value?.cultureTypeId,
      parameters: cultureParametersFromControlValue,
    },
    resolver: yupResolver(parametersCultureControlSchema),
  })

  const parametersFieldArray = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: 'parameters', // unique name for your Field Array
  })

  const watchedParameters = useWatch({ name: 'parameters', control })

  // сетит дефолтные параметры культуры для выбранной культуры
  useEffect(() => {
    if (!selectedCulture) {
      reset({ parameters: [] })
      return
    }

    if (selectedCulture?.type !== cultureFromControlValue?.type) {
      const cultureParams = initCultureParameters(selectedCulture?.type, null, cultureList)
      parametersFieldArray.replace(cultureParams)
      reset({ parameters: cultureParams })
    }
  }, [selectedCulture?.type])

  useEffect(() => {
    const value: ParametersCultureControlValue = {
      cultureTypeId: selectedCulture?.type ?? undefined,
      cultureTypeName: selectedCulture?.name ?? undefined,
      parameters: watchedParameters.filter((x) => !(!x.value || x.value === '')),
      hasError: !formState.isValid || formState.isValidating,
    }
    props.onChange(value)
    void trigger()
  }, [watchedParameters, formState.isValid])

  const handleCultureSelect = (type: string) => {
    const foundCulture = cultureList?.find((x) => x.type === type)

    if (foundCulture) {
      setSelectedCulture(foundCulture)
      setExpandCultures(false)
    }
  }

  return (
    <S.Wrapper ref={ref}>
      <S.CultureSelect>
        {!props.isShowOnlyParams && (
          <>
            <S.Label>Культура {props.isRequired && <S.Required>*</S.Required>}</S.Label>
            <Select
              style={{ width: '100%' }}
              placeholder="Пшеница 3 класса"
              defaultValue={selectedCulture?.name.toString()}
              onChange={(value) => handleCultureSelect(value)}
              showSearch
              disabled={props.isSelectDisabled}
              optionFilterProp="children"
            >
              {cultureList.map((it) => (
                <Select.Option key={it.type} value={it.type}>
                  {it.name}
                </Select.Option>
              ))}
            </Select>
          </>
        )}

        {!!selectedCulture && (
          <S.CultureInputsWrap hasError={props?.value?.hasError ?? false}>
            {parametersFieldArray?.fields?.map((cultureItem, index) => {
              const controlError = formState?.errors?.parameters?.[index]?.value?.message ?? null
              const controlIsDirty = formState?.dirtyFields?.parameters?.[index]?.value ?? false
              const shouldShowError = controlIsDirty && controlError
              return (
                <div key={cultureItem.id} style={{ display: !isExpandCultures && index > 2 ? 'none' : undefined }}>
                  <Controller
                    name={`parameters.${index}.value`}
                    control={control}
                    render={({ field }) => {
                      return (
                        <FormField
                          htmlFor={cultureItem.id}
                          label={
                            <S.ItemTitle>
                              <span>{cultureItem.name}</span>
                              {!!field.value && (
                                <Button
                                  size="small"
                                  type="text"
                                  icon={<FiX size={12} color="red" />}
                                  onClick={() =>
                                    Modal.confirm({
                                      title: `Вы действительно хотите удалить параметр "${cultureItem.name}"?`,
                                      okText: 'Да',
                                      cancelText: 'Отмена',
                                      onOk() {
                                        field.onChange(null)
                                      },
                                    })
                                  }
                                />
                              )}
                            </S.ItemTitle>
                          }
                          isRequired={cultureItem.required}
                          validateMessage={shouldShowError ? controlError : ''}
                        >
                          <S.CultureInputWrap>
                            <InputNumber
                              id={cultureItem.id}
                              decimalSeparator=","
                              step={1}
                              precision={1}
                              placeholder={cultureItem?.minimum?.toString() ?? '30'}
                              name={selectedCulture?.name}
                              onChange={(x) => field.onChange(x?.toString())}
                              value={field.value}
                              min="0"
                              max="10000"
                              // min={cultureItem?.minimum ?? 0}
                              // max={cultureItem.measurementUnit === '%' ? '100' : '10000'}
                              status={
                                shouldShowError
                                  ? 'error'
                                  : // : Number(field.value ?? 0) < Number(cultureItem?.minimum ?? 0) ||
                                    //   Number(field.value ?? 0) > Number(cultureItem?.maximum ?? 0)
                                    // ? 'warning'
                                    ''
                              }
                              formatter={(value) => `${value}`.replace(',', '')}
                              addonAfter={cultureItem.measurementUnit}
                            />
                          </S.CultureInputWrap>
                        </FormField>
                      )
                    }}
                  />
                </div>
              )
            })}
            <S.CultureToggle type="button" onClick={() => setExpandCultures((prev) => !prev)}>
              {isExpandCultures ? <FiChevronUp size={20} /> : <FiChevronDown size={16} />}
            </S.CultureToggle>
          </S.CultureInputsWrap>
        )}
      </S.CultureSelect>
    </S.Wrapper>
  )
})

ParametersCultureControl.displayName = 'ParametersCultureControl'
