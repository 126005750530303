import * as yup from 'yup'

import { OfferPriceType } from '~api/generated'
import { addressSchema, VALIDATORS_MSG } from '~shared/validations'

export const createAdvertFormSchema = yup.object({
  volume: yup
    .number()
    .typeError(VALIDATORS_MSG.required)
    .min(100, VALIDATORS_MSG.minValue(100))
    .required(VALIDATORS_MSG.required),
  pricePerTon: yup
    .number()
    .typeError(VALIDATORS_MSG.required)
    .min(1000, VALIDATORS_MSG.minValue(1000))
    .required(VALIDATORS_MSG.required),
  priceType: yup.mixed().required(VALIDATORS_MSG.required),
  requiredLaboratoryAnalysis: yup.boolean(),
  labAnalysis: yup.mixed().when('requiredLaboratoryAnalysis', {
    is: (requiredLaboratoryAnalysis: boolean) => requiredLaboratoryAnalysis,
    then: yup.mixed().notRequired(),
    otherwise: yup.mixed().required(VALIDATORS_MSG.required),
  }),
  address: yup.mixed().when('priceType', {
    is: (val: OfferPriceType) => val === OfferPriceType.AtSellersGate,
    then: addressSchema,
    otherwise: yup.mixed().notRequired(),
  }),
})
