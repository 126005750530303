import { codeUnreachable } from '~/utils/code-unreachable'
import { LoadingTransportType } from '~api/generated'

export function getLoadType(loadType?: LoadingTransportType | null): string {
  if (!loadType) {
    return '-'
  }
  switch (loadType) {
    case LoadingTransportType.GrainThrower: {
      return 'Зерномёт'
    }
    case LoadingTransportType.Elevator: {
      return 'Элеватор'
    }
    case LoadingTransportType.Telehandler: {
      return 'Маниту (телескопический погрузчик)'
    }
    case LoadingTransportType.FrontLoader: {
      return 'Амкодор (фронтальный погрузчик)'
    }
    case LoadingTransportType.VerticalPipe: {
      return 'Труба (вертикальная)'
    }
    case LoadingTransportType.Custom: {
      return 'Другое'
    }
    default: {
      return codeUnreachable(loadType)
    }
  }
}
