import React, { useState } from 'react'

import { Button } from 'antd'
import { FiArrowRight } from 'react-icons/fi'

import { useGetProductTypeData } from '~/hooks'
import { Badge, Card, Text } from '~/ui-components'
import { getElevatorAdvertOfferStatus } from '~/utils/elevator-advert-offers/get-elevator-advert-offer-status'
import { getDateFormat } from '~/utils/getDateFormat'
import { AdminElevatorAdvertOfferData, ElevatorAdvertOfferData, MyElevatorAdvertOfferData } from '~api/generated'

import * as S from './OffersListItemMobile.styled'

interface OffersListItemMobileProps {
  offer: MyElevatorAdvertOfferData | AdminElevatorAdvertOfferData
  onClick: (offerId: number) => void
  hasProductName: boolean
  basisName?: string
}

export const OffersListItemMobile: React.FC<OffersListItemMobileProps> = ({
  offer,
  hasProductName,
  onClick,
  basisName,
}) => {
  const [isShowParams, setShowParams] = useState(false)
  const onClickHandler = (offerId: number) => onClick(offerId)
  const { getProductTypeParametersForUI_V2 } = useGetProductTypeData()

  return (
    <Card key={offer.id} onClick={() => onClickHandler(offer.id)}>
      <S.CardHeader>
        <S.CardHeaderTop>
          <S.CardHeaderTopLeft>
            <S.TitleOfferNumber>Отклик №{offer.id}</S.TitleOfferNumber>
            <S.PublishedAt>{offer.publishedAt ? getDateFormat(offer.publishedAt) : null}</S.PublishedAt>
          </S.CardHeaderTopLeft>
          <FiArrowRight size={24} />
        </S.CardHeaderTop>
        <S.ProductWrapper>
          <S.ProductRowWrapper>
            {hasProductName ? (
              <S.ProductName>{offer.product.name}</S.ProductName>
            ) : (
              <S.Volume>{offer.volume} т</S.Volume>
            )}
            {hasProductName ? <S.Volume>{offer.volume} т</S.Volume> : null}
          </S.ProductRowWrapper>
          <div>
            <Badge color={getElevatorAdvertOfferStatus(offer.status).color} size="s">
              {getElevatorAdvertOfferStatus(offer.status).name}
            </Badge>
          </div>
          <S.PriceWrapper>
            <Text variant="h4">{offer.price} ₽/т</Text>
            <Text variant="normal">{offer.includingNds ? 'с НДС' : 'без НДС'}</Text>
          </S.PriceWrapper>

          {isShowParams && !!offer.product?.parameters.length && (
            <S.Parameters>
              {getProductTypeParametersForUI_V2(offer.product).map((param) => (
                <div key={param}>{param}</div>
              ))}
            </S.Parameters>
          )}

          {basisName && <Text variant="normal">Базис: {basisName}</Text>}

          <div>
            <Button
              type="link"
              onClick={(e) => {
                e.stopPropagation()
                setShowParams((x) => !x)
              }}
            >
              {isShowParams ? 'Свернуть' : 'Развернуть'}
            </Button>
          </div>
        </S.ProductWrapper>
      </S.CardHeader>
      <Card.Content>
        <S.CardFooter>
          <Text variant="description">Базис: </Text>
          <Text variant="description">{offer?.elevator?.name}</Text>
        </S.CardFooter>
      </Card.Content>
    </Card>
  )
}
