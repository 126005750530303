import React, { FC, useState } from 'react'

import { Button, Empty, Modal, Popconfirm, Switch, Table } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import { FiDownload, FiEye, FiPlus, FiTrash2 } from 'react-icons/fi'

import { DealDocumentData, DocumentPartStatus, FileData } from '~/api/generated'
import { Badge, Text } from '~/ui-components'
import { appToast, modalSharedSettings } from '~/utils'
import { getDealDocumentName, getDealDocumentStatus } from '~/utils/deals'
import { useGetCurrentRole } from '~hooks/auth'
import {
  useGetDealDocumentsZip,
  useRemoveDealDocument,
  useUpdateDealDocumentStatus,
  useUploadDealDocument,
} from '~hooks/deal-documents'
import { DealAddDocumentForm } from '~pages/Deals/components'
import { FormValues } from '~pages/Deals/components/DealAddDocumentForm/types'
import { DocumentChecker } from '~shared/components'
import { DocumentViewerModal } from '~shared/components/DocumentViewer'

import { DealDocumentsGroupProps, Row } from './types'

import * as S from './DealDocumentsGroup.styled'

export const DealDocumentsGroup: FC<DealDocumentsGroupProps> = ({
  title,
  isLoading,
  documents,
  onChangeAccess,
  isDeleteDocuments,
  isAddDocuments,
  onRefetch,
  dealId,
  documentsTypes,
}) => {
  const [showedDocument, setShowedDocument] = useState<{ document: DealDocumentData; instanceId: number } | null>(null)
  const [isVisibleAddDocumentModal, setVisibleAddDocumentModal] = useState(false)
  const [selectedRow, setSelectedRow] = useState<Row>()
  const removeDealDocument = useRemoveDealDocument()
  const uploadDealDocument = useUploadDealDocument()
  const updateDealDocumentStatus = useUpdateDealDocumentStatus()

  const {
    user: { isAdmin },
  } = useGetCurrentRole()

  const getDealDocumentsZip = useGetDealDocumentsZip()

  const handleAddDocumentClick = () => {
    setSelectedRow(undefined)
    setVisibleAddDocumentModal(true)
  }

  const handleUpdateDocumentClick = (row: Row) => {
    setSelectedRow(row)
    setVisibleAddDocumentModal(true)
  }

  const handleModalCancelClick = () => {
    setVisibleAddDocumentModal(false)
  }

  const handleDownloadFiles = async (documentIds?: number[]) => {
    if (!documentIds?.length || !dealId) return

    try {
      await getDealDocumentsZip.mutateAsync({
        request: {
          dealId,
          documentIds,
        },
      })
    } catch (e) {
      console.error(e)
      appToast.error({
        description: 'Ошибка получения документов',
      })
    }
  }

  const handleRemoveDocument = async (documentId: number) => {
    try {
      await removeDealDocument.mutateAsync({
        documentId,
      })
      await onRefetch?.()
      appToast.success({
        description: 'Файл удален',
      })
    } catch (err) {
      console.error(err)
      appToast.error({
        description: 'Ошибка удаления файла',
      })
    }
  }

  const handleDocumentViewerShow = (document: DealDocumentData, instanceId: number) => {
    setShowedDocument({
      document,
      instanceId,
    })
  }

  const handleDocumentViewerClose = () => {
    setShowedDocument(null)
  }

  const changeDocumentFileStatus = async (file: FileData, status: DocumentPartStatus, reason?: string) => {
    try {
      await updateDealDocumentStatus.mutateAsync({
        fileId: file.id,
        updateDealDocumentFileBody: {
          status,
          rejectionNote: reason || null,
        },
      })
      appToast.success({ description: 'Статус документа изменен' })
      onRefetch?.()
    } catch (e) {
      appToast.error({ description: 'Ошибка при изменении статуса документа' })
    }
  }

  const handleUploadDocument = async ({ documentFiles, documentType }: FormValues) => {
    if (!documentFiles || !dealId || !documentType) return

    if (selectedRow) {
      await handleRemoveDocument(selectedRow.id)
    }

    try {
      await uploadDealDocument.mutateAsync({
        documentType: documentType,
        dealId,
        createDealDocumentBody: {
          documentFiles: documentFiles,
        },
      })
      await onRefetch?.()
      handleModalCancelClick()
      appToast.success({
        description: 'Файл загружен',
      })
    } catch (err) {
      console.error(err)
      appToast.error({
        description: 'Ошибка загрузки файла',
      })
    }
  }

  const rows: Row[] | undefined = documents
    ?.map((doc) =>
      doc.instances.map((instance) => ({
        ...instance,
        document: doc,
      }))
    )
    .flat()

  const columns: ColumnsType<Row> = [
    {
      title: 'Название документа',
      dataIndex: ['document', 'type'],
      className: 'bold-cell',
      width: '60%',
      render: (value, file) => `${getDealDocumentName(value)} ${file.index ?? ''}`,
    },
    {
      title: 'Статус',
      dataIndex: 'status',
      render: (status: DocumentPartStatus) => {
        const statusValue = getDealDocumentStatus(status)
        return (
          <Badge size="s" color={statusValue.color}>
            {statusValue.name}
          </Badge>
        )
      },
    },
    {
      title: 'Действия',
      align: 'right',
      render: (_, row) => {
        return (
          <S.TableActions>
            {row.status === DocumentPartStatus.Rejected && (
              <Button type="primary" onClick={() => handleUpdateDocumentClick(row)}>
                Загрузить еще раз
              </Button>
            )}
            <Button
              type="text"
              icon={<FiEye size={24} />}
              onClick={() => handleDocumentViewerShow(row.document, row.id)}
            />
            <Button type="text" icon={<FiDownload size={24} />} onClick={() => handleDownloadFiles([row.id])} />

            {isDeleteDocuments && row.status === DocumentPartStatus.Rejected && (
              <Popconfirm
                placement="right"
                title="Удалить файл?"
                onConfirm={() => handleRemoveDocument(row.id)}
                okText="Да"
                cancelText="Нет"
              >
                <Button danger type="text" icon={<FiTrash2 size={24} />} />
              </Popconfirm>
            )}
          </S.TableActions>
        )
      },
    },
  ]

  return (
    <>
      <S.Wrapper>
        <S.Header>
          <S.Title variant="h2">{title}</S.Title>
          {onChangeAccess && (
            <S.Toggler>
              <Text variant="label-bold">Доступ для покупателя</Text>
              <Switch disabled={!rows?.length} onChange={onChangeAccess} />
            </S.Toggler>
          )}
          {/*<Button type="primary" disabled={!documents?.length}>*/}
          {/*  Скачать все из раздела*/}
          {/*</Button>*/}
        </S.Header>

        <div>
          {rows?.length ? (
            <Table
              rowKey="id"
              loading={isLoading}
              columns={columns}
              dataSource={rows}
              scroll={{ x: 'min-content' }}
              pagination={false}
            />
          ) : (
            !isAddDocuments && <Empty description="Документы не загружены" />
          )}

          {isAddDocuments && (
            <S.AddButtonWrapper onClick={handleAddDocumentClick}>
              <FiPlus size={16} />
              <S.AddButtonText>Добавить документ</S.AddButtonText>
            </S.AddButtonWrapper>
          )}
        </div>
      </S.Wrapper>
      <Modal
        title="Выбор документа"
        open={isVisibleAddDocumentModal}
        {...modalSharedSettings}
        width={320}
        onCancel={handleModalCancelClick}
        footer={[
          <Button key="cancel" onClick={handleModalCancelClick} htmlType="button">
            Отмена
          </Button>,
          <Button
            key="submit"
            form="deal-add-document-form"
            type="primary"
            htmlType="submit"
            loading={uploadDealDocument.isLoading}
          >
            Готово
          </Button>,
        ]}
      >
        <DealAddDocumentForm
          defaultDocumentsType={selectedRow?.document.type}
          onSubmit={handleUploadDocument}
          documentsTypes={documentsTypes}
        />
      </Modal>

      {documents && documentsTypes && (
        <DocumentChecker
          isCheckerVisible={!!showedDocument?.document && isAdmin}
          docTemplateList={documentsTypes}
          documentsForCheck={documents}
          startCheckFromDocument={showedDocument?.document.type ?? null}
          startCheckFromDocumentInstanceId={showedDocument?.instanceId}
          onDocumentFileStatusChange={changeDocumentFileStatus}
          onFinishCheck={() => setShowedDocument(null)}
        />
      )}

      <DocumentViewerModal
        isOpen={!!showedDocument?.document && !isAdmin}
        onCancel={handleDocumentViewerClose}
        docInstances={showedDocument?.document.instances ?? []}
        startCheckFromDocInstanceId={0}
      />
    </>
  )
}
