import { useQuery, UseQueryResult } from '@tanstack/react-query'

import { DealApiDealControllerGetComplexDealsRequest, GetComplexDealsResponse, PaginationData } from '~/api/generated'
import { useApi } from '~/providers/api'
import { httpErrorHandler } from '~/utils'

export const useGetModeratorDealsList = (
  request: DealApiDealControllerGetComplexDealsRequest
): UseQueryResult<GetComplexDealsResponse> => {
  const api = useApi()

  async function fetchDeals(): Promise<GetComplexDealsResponse> {
    try {
      const response = await api.deal.dealControllerGetComplexDeals(request)
      return response.data
    } catch (e) {
      httpErrorHandler(e, 'Ошибка при получении списка предложений')

      return {
        complexDeals: [],
        pagination: {} as PaginationData,
      }
    }
  }

  return useQuery(['fetchModeratorDealsList', request], fetchDeals, { staleTime: 1000 * 5 })
}
