import { useContext } from 'react'

import { useMutation, useQueryClient } from '@tanstack/react-query'

import { UserApiUserControllerUpdateUserRequest, UserData } from '~/api/generated'
import { ApiContext } from '~providers/api'

import { appToast } from '../../utils'

export const useUpdateCurrentUser = () => {
  const api = useContext(ApiContext)
  const queryClient = useQueryClient()

  return useMutation(
    ({ userId, updateUserBody }: UserApiUserControllerUpdateUserRequest) => {
      return api.users.userControllerUpdateUser({ userId, updateUserBody })
    },
    {
      onSuccess: (_, variables) => {
        const currentUser = queryClient.getQueryData(['currentUser']) as UserData
        queryClient.setQueriesData(['currentUser'], {
          ...currentUser,
          ...variables.updateUserBody,
        })
      },
      onError: () => {
        appToast.error({ description: 'Ошибка при обновлении профиля пользователя' })
      },
    }
  )
}
