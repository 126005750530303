import { graphql } from '~api/gql-generated/gql'

export const freightOrderResponsesQuery = graphql(/* GraphQL */ `
  query FreightOrderResponses($filter: FreightOrderResponseFilterInput, $pagination: PaginationInput) {
    freightOrderResponses(filter: $filter, pagination: $pagination) {
      freightOrderResponses {
        id
        orderId
        status
        type
        additionalDetails
        createdAt
        order {
          id
          companyId
          routeId
          readinessType
          startedAt
          endedAt
          startDaysCount
          transportFormatTypes
          transportBodyTypes {
            id
            name
          }
          weightKg
          selectedWeightKg
          priceWithoutNds
          priceWithNds
          availableWeightKg
          pricePerTonWithoutNds
          pricePerTonWithNds
          isAuction
          paymentTerm
          advancePercent
          deferredDaysCount
          contactPerson
          contactPhoneNumber
          additionalDetails
          createdAt
          updatedAt
          deletedAt
          route {
            id
            distance
            distanceToStartKm
            createdAt
            updatedAt
            points {
              id
              addressId
              type
              freightId
              roadTransportParametersId
              createdAt
              updatedAt
              address {
                full
                short
              }
              freight {
                id
                packingType
                packingTypeCustom
                bigBagsCount
                productTypeId
                weightKg
                createdAt
                updatedAt
                productType {
                  id
                  name
                  type
                  gost
                }
              }
              roadTransportParameters {
                id
                loadingTypes
                unloadingTypes
                loadingTypeCustom
                unloadingTypeCustom
                loadingThroughput
                unloadingThroughput
                vehicleLengthMaxM
                vehicleHeightMaxM
                vehicleClearanceMinCm
                hasParking
                hasScales
                hasWeighingAutomation
                distanceToScalesM
                scalesWeightLimitT
                roadSurfaceType
                roadSurfaceQuality
                additionalDetails
                createdAt
                updatedAt
              }
            }
          }
          canCreateResponse
          responsesStatistics {
            previousDays {
              betsCount
              responsesCount
            }
            today {
              betsCount
              responsesCount
            }
          }
        }
        dealOrder {
          startedAt
          weightKg
          priceWithoutNds
          priceWithNds
          pricePerTonWithoutNds
          pricePerTonWithNds
          paymentTerm
        }
        nextStatuses
      }
      pagination {
        currentPage
        pageSize
        total
        totalPages
      }
    }
  }
`)
