import { useQuery, UseQueryResult } from '@tanstack/react-query'

import { isApiResponseEmpty } from '~/utils/isApiResponseEmpty'
import { BankAccountData } from '~api/generated'
import { sharedCompanyQueryOptions } from '~hooks/company/_sharedCompanyQueryOptions'
import { useApi } from '~providers/api'

type Result = BankAccountData[] | null

export const useGetCompanyBankAccounts = (companyId: number | undefined | null): UseQueryResult<Result> => {
  const api = useApi()

  const fetchFn = async (): Promise<Result> => {
    if (!companyId) {
      return null
    }

    try {
      const response = await api.companies.bankAccountControllerFindAllByCompanyId({
        companyId,
      })

      return response.data.bankAccounts
    } catch (error) {
      if (isApiResponseEmpty(error)) {
        return null
      }

      return Promise.reject(error)
    }
  }

  return useQuery(['fetchCompanyBankAccounts', companyId], fetchFn, sharedCompanyQueryOptions)
}
