import React, { useEffect, useMemo } from 'react'

import { Empty, Pagination, Spin, Table } from 'antd'
import { observer } from 'mobx-react-lite'
import { useNavigate } from 'react-router-dom'

import { AuctionData } from '~/api/generated'
import { useGetTableHeight, usePagination } from '~/hooks'
import { auctionsFilterStore } from '~/store/filters'
import { getDateTimeDayFormat } from '~/utils/getDateFormat'
import { useGetAuctions } from '~hooks/auctions'
import { useGetCurrentRole } from '~hooks/auth'
import { useGetDevice } from '~hooks/common'
import { useGetCompanies } from '~hooks/company/useGetCompanies'
import { useGetProductTypes } from '~hooks/products/useGetProductTypes'
import { AuctionMobileCard, AuctionsHeader } from '~pages/Auctions/components'
import { AuctionsFilter } from '~pages/Auctions/components/AuctionsFilter'
import { useGetColumnsLotsList } from '~pages/Auctions/hooks'
import { REFETCH_INTERVAL } from '~pages/Auctions/utils'

import * as S from '../../AuctionsPage.styled'

export const AuctionLotsList = observer(() => {
  const navigate = useNavigate()
  const {
    user: { isAdmin },
  } = useGetCurrentRole()
  const device = useGetDevice()
  const isMobile = device === 'mobile'

  const { currentPage, setCurrentPage, pageSize, setPageSize, pageSizeOptions } = usePagination()
  const filters = auctionsFilterStore.auctionFilters

  const {
    data: auctionsData,
    refetch: auctionsRefetch,
    isLoading: isLoadingAuctions,
  } = useGetAuctions(
    {
      currentPage,
      pageSize,
      ...auctionsFilterStore.mappedCurrentFiltersToResponse,
    },
    {
      keepPreviousData: true,
      refetchInterval: REFETCH_INTERVAL,
    }
  )

  useEffect(() => {
    setCurrentPage(1)
    void auctionsRefetch()
  }, [
    filters.volume.from,
    filters.volume.to,
    filters.price.from,
    filters.price.to,
    filters.auctionType,
    filters.regionIds,
  ])

  const tableHeight = useGetTableHeight()

  const auctions = auctionsData?.auctions
  const pagination = auctionsData?.pagination

  const companiesIds = useMemo(
    () => Array.from(new Set(auctions?.map((auction) => auction.offer.companyId).filter(Boolean))),
    [auctions]
  )

  const { data: companiesData } = useGetCompanies(
    {
      ids: companiesIds as number[],
    },
    isAdmin
  )
  const companiesMap = useMemo(() => new Map(companiesData?.map((company) => [company.id, company])), [companiesData])

  const { data: products } = useGetProductTypes()
  const productsMap = useMemo(() => new Map(products.map((product) => [product.type, product])), [products])

  const columns = useGetColumnsLotsList(productsMap, companiesMap)

  const handleRowClick = (record: AuctionData) => {
    navigate(`${record.id}`)
  }

  return (
    <>
      <AuctionsHeader onAfterCreateAuction={auctionsRefetch} filters={<AuctionsFilter store="lotsList" />} />
      {!isMobile ? (
        <S.AuctionTableWrapper>
          <Table<AuctionData>
            rowKey="id"
            className="table-interactive"
            loading={isLoadingAuctions}
            columns={columns}
            dataSource={auctions}
            scroll={{ x: 'max-content', y: tableHeight }}
            pagination={false}
            onRow={(record) => ({
              onClick: handleRowClick.bind(null, record),
            })}
          />
        </S.AuctionTableWrapper>
      ) : (
        <Spin spinning={isLoadingAuctions}>
          {!auctions?.length && <Empty />}

          <S.AuctionMobileCardList>
            {auctions?.map((auction) => (
              <AuctionMobileCard
                key={auction.id}
                auctionId={auction.id}
                productName={auction.offer.product.name}
                offerType={auction.offer.type}
                volume={auction.offer.volume}
                price={auction.offer.price}
                includingNds={auction.offer.includingNds}
                companyName={
                  isAdmin && auction.offer.companyId ? companiesMap.get(auction.offer.companyId)?.name : undefined
                }
                location={auction.offer.address}
                endedAt={auction.endedAt ? getDateTimeDayFormat(auction.endedAt) : ''}
                onClick={() => handleRowClick(auction)}
              />
            ))}
          </S.AuctionMobileCardList>
        </Spin>
      )}

      {pagination && (
        <Pagination
          current={pagination?.currentPage}
          total={pagination?.total}
          pageSize={pagination?.pageSize}
          pageSizeOptions={pageSizeOptions}
          onShowSizeChange={(x, size) => setPageSize(size)}
          showSizeChanger
          onChange={setCurrentPage}
        />
      )}
    </>
  )
})
