import React, { useEffect, useState } from 'react'

import { Button, Modal, Spin } from 'antd'
import { FiEdit2 } from 'react-icons/fi'

import { Text } from '~/ui-components'
import { appToast, httpErrorHandler } from '~/utils'
import { UpdateDealCarrierBodyCarriageByEnum } from '~api/generated'
import { useGetCurrentRole } from '~hooks/auth'
import { useGetDealLogisticsInfo, useUpdateDealLogisticsCarrier } from '~hooks/deal-logistics'
import { getCarrierCardDescription } from '~pages/Deals/modules/DealLogistics/components/CarrierInfoCard/components/_utils'
import { CarrierInfoCardForm } from '~pages/Deals/modules/DealLogistics/components/CarrierInfoCard/components/CarrierInfoCardForm'
import { CarrierInfoCardView } from '~pages/Deals/modules/DealLogistics/components/CarrierInfoCard/components/CarrierInfoCardView'
import { SelectCarrierSide } from '~pages/Deals/modules/DealLogistics/components/CarrierInfoCard/components/SelectCarrierSide'
import { LogisticCard } from '~pages/Deals/modules/DealLogistics/components/LogisticCard'

import { CarrierInfoCardProps } from './CarrierInfoCard.types'
import { DealCarrierInfoCardFormValues } from './components/CarrierInfoCardForm/CarrierInfoCardForm.types'

const FORM_ID = 'carrier-info-card-form-id'

export const CarrierInfoCard: React.FC<CarrierInfoCardProps> = ({ isEditable, dealId, dealSide }) => {
  const {
    user: { isAdmin },
  } = useGetCurrentRole()
  const useGetDealLogisticsInfoFn = useGetDealLogisticsInfo({ dealId })
  const useUpdateDealLogisticsCarrierFn = useUpdateDealLogisticsCarrier()
  const [visible, setVisible] = useState(false)
  const [changeModalVisible, setChangeModalVisible] = useState(false)
  const [defaultCardValues, setDefaultCardValues] = useState<DealCarrierInfoCardFormValues | null>(null)
  const [carrierSide, setCarrierSide] = useState<UpdateDealCarrierBodyCarriageByEnum | null>(null)
  const dealCarrierData = useGetDealLogisticsInfoFn.data?.carrier

  useEffect(() => {
    if (dealCarrierData) {
      setCarrierSide(dealCarrierData.carriageBy)

      setDefaultCardValues({
        organization: dealCarrierData.companyName ?? '',
        contactUser: dealCarrierData.contactPerson?.name ?? '',
        contactPhone: dealCarrierData.contactPerson?.phoneNumber ?? '',
        dateFirstCar: dealCarrierData.startedAt ? new Date(dealCarrierData.startedAt) : null,
        dateOfPerformance: dealCarrierData.endedAt ? new Date(dealCarrierData.endedAt) : null,
      })
    } else {
      setDefaultCardValues(null)
      setCarrierSide(null)
    }
  }, [dealCarrierData])

  const handleModalClose = () => setVisible(false)

  const handleModalShow = () => setVisible(true)

  const handleSubmit = async (data: DealCarrierInfoCardFormValues) => {
    if (!data) {
      appToast.info({ description: 'Не все обязательные поля заполнены' })
      return
    }

    try {
      await useUpdateDealLogisticsCarrierFn.mutateAsync({
        dealId,
        updateDealCarrierBody: {
          companyName: data.organization,
          contactPerson: data.contactUser,
          contactPhoneNumber: data.contactPhone,
          startedAt: data.dateFirstCar?.toISOString() ?? null,
          endedAt: data.dateOfPerformance?.toISOString() ?? null,
        },
      })

      appToast.success({ description: 'Информации о перевозчике изменена' })
      await useGetDealLogisticsInfoFn.refetch()
      handleModalClose()
    } catch (e) {
      httpErrorHandler(e, 'Ошибка при изменении информации о перевозчике')
      return Promise.reject(e)
    }
  }

  const handleChangeCarrierSide = async (side: UpdateDealCarrierBodyCarriageByEnum) => {
    try {
      await useUpdateDealLogisticsCarrierFn.mutateAsync({
        dealId,
        updateDealCarrierBody: {
          carriageBy: side,
        },
      })

      appToast.success({ description: 'Информации о перевозчике изменена' })
      await useGetDealLogisticsInfoFn.refetch()
      setChangeModalVisible(false)
    } catch (e) {
      httpErrorHandler(e, 'Ошибка при изменении информации о перевозчике')

      return Promise.reject(e)
    }
  }

  return (
    <>
      <Spin spinning={useGetDealLogisticsInfoFn.isLoading}>
        <LogisticCard
          title={
            <>
              Перевозчик
              {isAdmin && !!carrierSide && (
                <Button
                  size="small"
                  type="text"
                  icon={<FiEdit2 size={24} />}
                  onClick={() => setChangeModalVisible(true)}
                />
              )}
            </>
          }
          description={getCarrierCardDescription(carrierSide)}
          onClickChangeButton={handleModalShow}
          isEditable={
            (carrierSide && isEditable) ||
            (dealSide === 'buyer' && carrierSide === 'BUYER') ||
            (dealSide === 'seller' && carrierSide === 'SELLER')
          }
        >
          {carrierSide && dealCarrierData ? (
            <CarrierInfoCardView data={defaultCardValues} />
          ) : (
            <Text variant="normal">Перевозчик ещё не установлен</Text>
          )}

          {!carrierSide && dealCarrierData && (
            <SelectCarrierSide
              currentValue={carrierSide}
              onSelect={handleChangeCarrierSide}
              isLoading={useUpdateDealLogisticsCarrierFn.isLoading}
            />
          )}
        </LogisticCard>
      </Spin>

      <Modal
        closable={true}
        destroyOnClose={true}
        title="Выбор перевозчика"
        open={changeModalVisible}
        centered
        width={500}
        onCancel={() => setChangeModalVisible(false)}
        footer={<></>}
      >
        <SelectCarrierSide
          currentValue={carrierSide}
          onSelect={handleChangeCarrierSide}
          isLoading={useUpdateDealLogisticsCarrierFn.isLoading}
        />
      </Modal>

      <Modal
        closable={true}
        destroyOnClose={true}
        title="Перевозчик"
        open={visible}
        centered
        width={500}
        onCancel={handleModalClose}
        footer={[
          <Button key="cancel" onClick={handleModalClose} htmlType="button">
            Отмена
          </Button>,
          <Button
            key="submit"
            form={FORM_ID}
            type="primary"
            htmlType="submit"
            loading={useUpdateDealLogisticsCarrierFn.isLoading}
          >
            Завершить
          </Button>,
        ]}
      >
        <Spin spinning={useUpdateDealLogisticsCarrierFn.isLoading}>
          <CarrierInfoCardForm formId={FORM_ID} defaultValues={defaultCardValues} onSubmit={handleSubmit} />
        </Spin>
      </Modal>
    </>
  )
}
