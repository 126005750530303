import React from 'react'

import { Button } from 'antd'

import { RejectEmployeeFromCompanyProps } from './RejectEmployeeFromCompany.types'

import * as S from './RejectEmployeeFromCompany.styled'
import { Text } from '~/ui-components'

export const RejectEmployeeFromCompany: React.FC<RejectEmployeeFromCompanyProps> = ({ onBack, loading, onSubmit }) => {
  return (
    <S.Wrapper>
      <Text variant="h4">
        Вы действительно хотите отклонить запрос на добавление данного пользователя, как сотрудника компании?
      </Text>

      <S.ActionWrapper>
        <S.StyledButton htmlType="button" onClick={onBack}>
          Отмена
        </S.StyledButton>

        <Button htmlType="button" type="primary" danger onClick={onSubmit} loading={loading}>
          Да, отклонить
        </Button>
      </S.ActionWrapper>
    </S.Wrapper>
  )
}
