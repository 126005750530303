import { useQuery, UseQueryResult } from '@tanstack/react-query'

import { isApiResponseEmpty } from '~/utils/isApiResponseEmpty'
import { useApi } from '~providers/api'

type Result = string | null

export const useGetCompanyDocumentsArchiveUrl = (companyId: number | undefined | null): UseQueryResult<Result> => {
  const api = useApi()

  const fetchFn = async (): Promise<Result> => {
    if (!companyId) {
      return null
    }

    try {
      const res = await api.companies.companyDocumentControllerGetDocumentsArchiveUrl({
        companyId,
      })

      return res.data?.documentsArchiveUrl ?? null
    } catch (error) {
      if (isApiResponseEmpty(error)) {
        return null
      }

      return Promise.reject(error)
    }
  }

  return useQuery(['fetchDocumentsArchive', companyId], fetchFn, { enabled: !!companyId })
}
