import { AuctionOfferStatus } from '~api/generated'

import { BadgeVariant } from '~ui-components/components/Badge/Badge.types'

export function getOfferStatusForAuction(status: string): {
  name: string
  color: BadgeVariant
} {
  switch (status) {
    case AuctionOfferStatus.Reviewed:
      return {
        name: 'Рассмотрена',
        color: 'gray',
      }
    case AuctionOfferStatus.Published:
      return {
        name: 'На рассмотрении',
        color: 'yellow',
      }
    case AuctionOfferStatus.Realised:
      return {
        name: 'Принято',
        color: 'base',
      }
    case AuctionOfferStatus.Rejected:
      return {
        name: 'Отклонено',
        color: 'red',
      }
    default:
      return {
        name: 'Ошибка',
        color: 'red',
      }
  }
}
