import styled from 'styled-components'
import { Timeline } from 'antd'

export const TimelineStyled = styled(Timeline)`
  &.ant-timeline.ant-timeline-label {
    & .ant-timeline-item {
      & .ant-timeline-item-label {
        width: calc(15% - 12px);
      }

      & .ant-timeline-item-tail {
        inset-inline-start: 15%;
      }

      & .ant-timeline-item-head {
        inset-inline-start: 15%;
      }

      & .ant-timeline-item-content {
        inset-inline-start: calc(15% - 4px);
        width: calc(85% - 12px);
      }
    }

    & .ant-timeline-item-last > .ant-timeline-item-tail {
      display: block;
    }
  }
`
