import { useMutation } from '@tanstack/react-query'

import { useApi } from '~providers/api'
import { fullPhoneNumberValidator } from '~shared/validations/schemas'

interface CheckUserProps {
  phoneNumber: string
  inn: string
}

export const useCheckCreateUserForCompany = () => {
  const api = useApi()

  const checkUserFn = async ({ phoneNumber, inn }: CheckUserProps) => {
    const isFullNumber = fullPhoneNumberValidator(phoneNumber)
    if (isFullNumber) {
      const user = (
        await api.users.userControllerGetUserByPhone({
          phoneNumber,
        })
      ).data?.user

      if (!user) return true

      const company = (
        await api.companies.companyControllerGetCompanies({
          inns: [inn],
        })
      ).data.companies?.[0]

      if (!company) return true

      return company.id === user.companyId
    }
    return true
  }

  return useMutation(checkUserFn)
}
