import { FreightPackingType } from '~api/gql-generated/graphql'
import { codeUnreachable } from '~/utils/code-unreachable'

export function getFreightPackingType(freightPackingType: FreightPackingType) {
  switch (freightPackingType) {
    case FreightPackingType.Bulk:
      return 'Насыпью'
    case FreightPackingType.BigBag:
      return 'Бэги'
    case FreightPackingType.Custom:
      return 'Другое'
    default:
      return codeUnreachable(freightPackingType)
  }
}
