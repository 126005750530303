import React from 'react'

import { Select } from 'antd'
import { BaseSelectRef } from 'rc-select'

import { getTransportTypeName } from '~/utils/car-park/get-transport-type'
import { TransportType } from '~api/gql-generated/graphql'
import { CustomControlInterface } from '~shared/controls/CustomControl.interface'

interface TransportTypeSelectControlProps extends CustomControlInterface {
  id?: string
  value: TransportType[] | undefined
  onChange: (value: TransportType[]) => void
  hasError?: boolean
}

export const TransportTypeSelectControl: React.FC<TransportTypeSelectControlProps> = React.forwardRef<
  BaseSelectRef,
  TransportTypeSelectControlProps
>(({ id, value, onChange, hasError }, ref) => {
  return (
    <Select
      ref={ref}
      allowClear
      style={{ width: '100%' }}
      placeholder="Все типы ТС"
      value={value}
      onChange={onChange}
      status={hasError ? 'error' : undefined}
      mode="multiple"
    >
      {Object.values(TransportType).map((transportType) => (
        <Select.Option key={transportType} value={transportType}>
          {getTransportTypeName(transportType)}
        </Select.Option>
      ))}
    </Select>
  )
})

TransportTypeSelectControl.displayName = 'TransportTypeSelectControl'
