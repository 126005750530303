import React, { useEffect } from 'react'

import { InputNumber } from 'antd'
import { Controller, useForm } from 'react-hook-form'

import { yupResolver } from '@hookform/resolvers/yup/dist/yup'

import { AppConfig } from '~/appConfig'
import { FormField } from '~/ui-components'
import { PhoneNumber } from '~shared/components'
import { CheckboxControl, ElevatorSelectControl, ParametersCultureControl, PriceControl } from '~shared/controls'

import { createAdvertFormByBuyerSchema } from './CreateAdvertFormByBuyer.validation'

import { CreateAdvertFormByBuyerProps, CreateAdvertFormByBuyerValues } from './CreateAdvertFormByBuyer.types'

import * as S from './CreateAdvertFormByBuyer.styled'

export const CreateAdvertFormByBuyer: React.FC<CreateAdvertFormByBuyerProps> = ({
  onSubmit,
  formId,
  defaultValues,
}) => {
  const {
    handleSubmit,
    control,
    watch,
    setValue,
    formState: { errors },
  } = useForm<CreateAdvertFormByBuyerValues>({
    defaultValues: {
      elevatorId: defaultValues?.elevatorId,
      culture: defaultValues?.culture,
      pricePerTon: defaultValues?.pricePerTon,
      allowOffersWithOtherParameters: defaultValues?.allowOffersWithOtherParameters ?? false,
      publicationDateCount: undefined,
      isUnlimitedDate: defaultValues?.isUnlimitedDate ?? true,
    },
    resolver: yupResolver(createAdvertFormByBuyerSchema),
  })

  const isUnlimitedDateWatch = watch('isUnlimitedDate')

  useEffect(() => {
    setValue('publicationDateCount', undefined)
  }, [isUnlimitedDateWatch])

  const onSubmitHandler = (formValues: CreateAdvertFormByBuyerValues) => {
    onSubmit(formValues)
  }

  return (
    <form id={formId} onSubmit={handleSubmit(onSubmitHandler)}>
      <FormField label="Базис" htmlFor="elevatorId" validateMessage={errors.elevatorId?.message} isRequired>
        <Controller
          name="elevatorId"
          control={control}
          render={({ field }) => (
            <>
              <ElevatorSelectControl id="elevatorId" {...field} hasError={!!errors.elevatorId} />{' '}
              <S.InfoText>
                Не нашли нужный базис? Свяжитесь с нами:{' '}
                <PhoneNumber prefix="" phoneNumber={AppConfig.ADMIN_CONTACT_PHONE} />
              </S.InfoText>
            </>
          )}
        />
      </FormField>

      <FormField validateMessage={errors.culture ? 'Параметры культуры заполнены с ошибками' : ''}>
        <Controller
          name="culture"
          control={control}
          render={({ field }) => <ParametersCultureControl isRequired {...field} />}
        />
      </FormField>

      <FormField htmlFor="pricePerTon" label="Цена без НДС" validateMessage={errors.pricePerTon?.message} isRequired>
        <Controller
          name="pricePerTon"
          control={control}
          render={({ field }) => (
            <S.HalfRow>
              <PriceControl id="pricePerTon" {...field} hasError={!!errors.pricePerTon} addAfter="₽/т" />
            </S.HalfRow>
          )}
        />
      </FormField>

      <FormField
        htmlFor="publicationDateCount"
        label="Срок публикации"
        validateMessage={errors.publicationDateCount?.message}
        isRequired
      >
        <Controller
          name="publicationDateCount"
          control={control}
          render={({ field }) => (
            <S.HalfRow>
              <InputNumber
                onChange={field.onChange}
                addonAfter="дней"
                value={field.value}
                disabled={isUnlimitedDateWatch}
                status={errors.publicationDateCount ? 'error' : undefined}
                precision={0}
              />
            </S.HalfRow>
          )}
        />
      </FormField>

      <FormField validateMessage={errors.publicationDateCount?.message}>
        <Controller
          control={control}
          name="isUnlimitedDate"
          render={({ field }) => <CheckboxControl {...field}>Опубликовать бессрочно</CheckboxControl>}
        />
      </FormField>

      <FormField>
        <Controller
          control={control}
          name="allowOffersWithOtherParameters"
          render={({ field }) => (
            <div>
              <CheckboxControl {...field}>Рассматриваю предложения с похожими показателями</CheckboxControl>
              <S.InfoText>Продавцы смогут предлагать вам культуру с собственными показателями</S.InfoText>
            </div>
          )}
        />
      </FormField>
    </form>
  )
}
