import React from 'react'

import { Radio, RadioChangeEvent } from 'antd'

import { CustomControlInterface } from '~shared/controls/CustomControl.interface'

interface ISellOrBuyRadioControlProps extends CustomControlInterface {
  onChange: (value: Value) => void
  value: Value | undefined
}

type Value = 'buyer' | 'seller'

export const SellerOrBuyerRadioControl: React.FC<ISellOrBuyRadioControlProps> = React.forwardRef<
  HTMLDivElement,
  ISellOrBuyRadioControlProps
>(({ value, onChange }, ref) => {
  const onChangeHandler = (e: RadioChangeEvent) => {
    onChange(e.target.value)
  }

  return (
    <Radio.Group onChange={onChangeHandler} value={value} optionType="button" buttonStyle="solid">
      <Radio value="seller">Продавец</Radio>
      <Radio value="buyer">Покупатель</Radio>
    </Radio.Group>
  )
})

SellerOrBuyerRadioControl.displayName = 'SellerOrBuyerRadioControl'
