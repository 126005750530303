import React, { useState } from 'react'

import { Space } from 'antd'
import { SizeType } from 'antd/es/config-provider/SizeContext'
import { BaseSelectRef } from 'rc-select'

import { Text } from '~/ui-components'
import { CompanyData } from '~api/generated'
import { useApi } from '~providers/api'
import { CustomControlInterface } from '~shared/controls/CustomControl.interface'
import { DebounceSelect } from '~shared/controls/DebounceSelect/DebounceSelect'

export interface CompanySelectControlValue {
  label: JSX.Element | string
  value: CompanyData['id']
}

interface CompanySelectControlProps extends CustomControlInterface {
  onChange: (value: CompanySelectControlValue['value'] | undefined) => void
  value: CompanySelectControlValue['value'] | undefined
  size?: SizeType
  hasError?: boolean
  id?: string
  placeholder?: string
}

export const CompanySelectControl: React.FC<CompanySelectControlProps> = React.forwardRef<
  BaseSelectRef,
  CompanySelectControlProps
>(({ id, value, onChange, hasError, size, placeholder = 'Название компании или ИНН' }, ref) => {
  const api = useApi()
  const [selected, setSelected] = useState<CompanySelectControlValue | undefined>(
    value ? { value, label: `Компания #${value}` } : undefined
  )

  // useEffect(() => {
  //   if (value) {
  //     api.companies
  //       .companyControllerGetCompanies({ id: value })
  //       .then((r) => setSelected({ value: r.data.id, label: r.data.name }))
  //   }
  // }, [])

  const fetchCompanyList = async (companyNameOrInn: string): Promise<CompanySelectControlValue[]> => {
    if (companyNameOrInn.length < 3) {
      return []
    }
    const isInn = /^\d+$/.test(companyNameOrInn)

    const foundCompanies = await api.companies.companyControllerGetCompanies({
      companyName: !isInn ? companyNameOrInn : undefined,
      inns: isInn ? [companyNameOrInn] : undefined,
      currentPage: 1,
      pageSize: 99,
    })
    return foundCompanies.data?.companies?.map((company) => ({
      label: (
        <Space direction="vertical">
          <span>{company.name}</span>
          <Text variant="description" style={{ color: '#C2C2C2' }}>
            {company.inn}
          </Text>
        </Space>
      ),
      value: company.id,
    }))
  }

  const onSelectHandler = (v: CompanySelectControlValue | CompanySelectControlValue[]) => {
    if (Array.isArray(v)) {
      const value = v.length ? v[0] : undefined
      setSelected(value)
      return onChange(value?.value)
    }
    setSelected(v)
    onChange(v?.value ?? undefined)
  }

  return (
    <DebounceSelect
      allowClear
      showSearch
      showArrow
      value={selected}
      placeholder={placeholder}
      fetchOptions={fetchCompanyList}
      onChange={onSelectHandler}
      notFoundContent="Компаний не найдено"
      onClear={() => setSelected(undefined)}
      id={id}
      size={size}
      status={hasError ? 'error' : undefined}
      style={{ width: '100%' }}
      maxTagTextLength={16}
      maxTagCount={1}
      mode="tags"
    />
  )
})

CompanySelectControl.displayName = 'CompanySelectControl'
