import { useMutation } from '@tanstack/react-query'

import { useApi } from '~providers/api'

export const useLogoutMutation = () => {
  const api = useApi()
  const fn = async () => {
    const res = await api.auth.authControllerLogout()

    return res.data
  }

  return useMutation(fn)
}
