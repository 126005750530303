import styled, { css } from 'styled-components'

import { Text } from '~/ui-components/components/Text'

export const Name = styled(Text).attrs({ variant: 'normal-bold' })``

export const DocumentCard = styled.div<{ danger: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: space-between;
  min-height: 112px;
  padding: 16px;
  border-radius: 16px;
  box-shadow: 0 1px 4px rgba(84, 84, 84, 0.15);
  background: #fff;

  ${({ danger }) =>
    danger &&
    css`
      border: 1px dashed var(--danger-color);
      box-shadow: none;

      ${Name} {
        color: var(--danger-color) !important;
      }
    `}
`

export const Header = styled.div`
  display: flex;
  gap: 16px;
  justify-content: space-between;
`

export const Footer = styled.div`
  display: flex;
  gap: 8px;
  justify-content: space-between;
  align-items: flex-end;

  .ant-btn-link {
    height: auto;
  }
`

export const Actions = styled.div`
  display: flex;
  gap: 8px;
  color: var(--gray-8-color);
`

export const Date = styled(Text).attrs({ variant: 'label' })``
