import styled from 'styled-components'

import { Text } from '~/ui-components'

export const NotFoundAdvertText = styled(Text).attrs({ variant: 'description' })`
  color: #696e73;
`
export const InfoText = styled(Text).attrs({ variant: 'description' })`
  margin-top: 4px;
  color: var(--gray-6-color);
`
export const ParametersInfo = styled.div<{ isAllow: boolean }>`
  //margin: 20px 0;
  display: flex;
  gap: 8px;
  align-items: center;
  > * {
    color: ${(props) => (props.isAllow ? 'var(--badge-blue-bg-color)' : '#989898')};
  }
`
export const FoundAdvertInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`
export const Parameters = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 8px;
`
export const PriceControlWrapper = styled.div`
  display: grid;
  grid-template-columns: 160px 1fr;
  align-items: center;
  gap: 50px;
`
export const EditCultureParamsText = styled(Text).attrs({ variant: 'normal' })`
  color: #45a67d;
`
export const EditCultureParamsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  background: #f5faf6;
  padding: 10px;
  border-radius: 6px;
`
