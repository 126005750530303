import styled from 'styled-components'

import { Text } from '~/ui-components'

export const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  height: 100%;
  background: #f7faf9;
`

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`

export const ProductName = styled(Text).attrs({ variant: 'normal-bold' })``
export const Volume = styled(Text).attrs({ variant: 'h4' })`
  color: var(--primary-main-color);
`

export const CardFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
`
export const CardHeader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 16px;
  //margin-bottom: 24px;
`
export const CardHeaderTop = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
`
export const CardHeaderTopLeft = styled.div`
  display: flex;
  gap: 8px;
`
export const ProductWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`
export const ProductRowWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
`
export const PriceWrapper = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`

export const TitleOfferNumber = styled(Text).attrs({ variant: 'normal' })`
  color: #777777;
`
export const PublishedAt = styled(Text).attrs({ variant: 'normal' })`
  color: var(--gray-4-color);
`

export const ChangeStatusText = styled(Text).attrs({ variant: 'normal' })`
  color: var(--gray-4-color);
  text-align: center;
`
export const Parameters = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  color: var(--badge-gray-bg-color);
`

export const ActionWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
  padding: 0 32px;
`

export const CompanyInfo = styled.div`
  display: grid;
  grid-template-columns: 150px 1fr;
  gap: 8px;
`

export const VolumeDesktop = styled(Text).attrs({ variant: 'h4' })`
  color: var(--primary-main-color);
`

export const Price = styled(Text).attrs({ variant: 'h4' })``
