import { useEffect, useState } from 'react'

import { isAfter } from 'date-fns'

export const useIsTimeEnd = (endTime: Date | undefined) => {
  const [isEndTime, setEndTime] = useState(false)

  useEffect(() => {
    if (!endTime) return

    const checkTime = () => {
      const isCurrentEndTime = isAfter(new Date(), endTime)
      if (isCurrentEndTime !== isEndTime) {
        setEndTime(isCurrentEndTime)
      }
    }

    checkTime()

    const intervalId = setInterval(checkTime, 1000)

    return () => clearInterval(intervalId)
  }, [endTime])

  return isEndTime
}
