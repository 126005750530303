import styled from 'styled-components'

import { breakpoints } from '~/tokens'
import { Text } from '~/ui-components'

export const Wrapper = styled.div`
  display: inline-flex;
  gap: 8px;
  width: 100%;

  & .warehouseId-select {
    min-width: 220px;
    max-width: 220px;
    @media (max-width: ${breakpoints.MOBILE_XL}px) {
      flex-wrap: wrap;
      max-width: unset;
      width: 100%;
    }
  }
  @media (max-width: ${breakpoints.MOBILE_XL}px) {
    flex-wrap: wrap;
  }
`
export const WarehouseAddress = styled(Text).attrs({ variant: 'description' })`
  color: var(--gray-4-color);
`

export const Title = styled(Text).attrs({ variant: 'normal' })`
  white-space: nowrap;
  color: var(--gray-6-color);
`
