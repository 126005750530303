import React from 'react'

import { Select } from 'antd'
import { BaseSelectRef } from 'rc-select'

import { getElevatorAdvertOfferStatus } from '~/utils/elevator-advert-offers/get-elevator-advert-offer-status'
import { ElevatorOfferResponseStatus } from '~api/generated'
import { CustomControlInterface } from '~shared/controls/CustomControl.interface'

type Value = ElevatorOfferResponseStatus[]

interface ElevatorAdvertOfferStatusSelectControlProps extends CustomControlInterface {
  onChange: (value: Value) => void
  value: Value
  hasError?: boolean
  id?: string
  placeholder?: string
}

interface IOption {
  value: ElevatorOfferResponseStatus
  label: string
}
const selectOptions: IOption[] = [
  {
    value: ElevatorOfferResponseStatus.Accepted,
    label: getElevatorAdvertOfferStatus(ElevatorOfferResponseStatus.Accepted).name,
  },
  {
    value: ElevatorOfferResponseStatus.OnModeration,
    label: getElevatorAdvertOfferStatus(ElevatorOfferResponseStatus.OnModeration).name,
  },
  {
    value: ElevatorOfferResponseStatus.UnderConsideration,
    label: getElevatorAdvertOfferStatus(ElevatorOfferResponseStatus.UnderConsideration).name,
  },
  {
    value: ElevatorOfferResponseStatus.RejectedByBuyer,
    label: getElevatorAdvertOfferStatus(ElevatorOfferResponseStatus.RejectedByBuyer).name,
  },
  {
    value: ElevatorOfferResponseStatus.RejectedOnModeration,
    label: getElevatorAdvertOfferStatus(ElevatorOfferResponseStatus.RejectedOnModeration).name,
  },
  {
    value: ElevatorOfferResponseStatus.Cancelled,
    label: getElevatorAdvertOfferStatus(ElevatorOfferResponseStatus.Cancelled).name,
  },
  {
    value: ElevatorOfferResponseStatus.Controversial,
    label: getElevatorAdvertOfferStatus(ElevatorOfferResponseStatus.Controversial).name,
  },
  {
    value: ElevatorOfferResponseStatus.Purchased,
    label: getElevatorAdvertOfferStatus(ElevatorOfferResponseStatus.Purchased).name,
  },
]

export const ElevatorAdvertOfferStatusSelectControl: React.FC<ElevatorAdvertOfferStatusSelectControlProps> =
  React.forwardRef<BaseSelectRef, ElevatorAdvertOfferStatusSelectControlProps>(
    ({ id, value, onChange, hasError, placeholder = 'Статус' }, ref) => {
      const onSelectAddressHandler = (v: Value) => {
        onChange(v)
      }

      return (
        <Select
          allowClear
          mode="multiple"
          ref={ref}
          id={id}
          style={{ width: '100%' }}
          placeholder={placeholder}
          value={value}
          onChange={onSelectAddressHandler}
          status={hasError ? 'error' : undefined}
          optionFilterProp="children"
        >
          {selectOptions.map((it) => (
            <Select.Option key={it.value} value={it.value}>
              {it.label}
            </Select.Option>
          ))}
        </Select>
      )
    }
  )

ElevatorAdvertOfferStatusSelectControl.displayName = 'ElevatorAdvertOfferStatusSelectControl'
