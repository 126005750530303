import React, { useCallback, useEffect } from 'react'

import { Button, Divider, Segmented, Select, Space } from 'antd'
import { debounce } from 'lodash'
import { Controller, useForm } from 'react-hook-form'
import { useLocation } from 'react-router-dom'

import { AppConfig } from '~/appConfig'
import { appRoutes } from '~/router'
import { filterTypeOptions } from '~/store/filters/_utils'
import { FormField, Text } from '~/ui-components'
import {
  CultureSelectControl,
  DistanceFromWarehouseControl,
  RangeSliderControl,
  RegionSelectControl,
} from '~shared/controls'

import { IOffersFilterForm, IOffersFilterFormProps } from './OffersFilterForm.types'

import * as S from './OffersFilterForm.styled'

export const OffersFilterForm: React.FC<IOffersFilterFormProps> = ({
  defaultValues,
  currentValues,
  isDisabledOfferType,
  isShowDistanceFromWarehouseControl = false,
  onSubmit,
  onChange,
  onResetToDefaultValues,
  foundResults,
}) => {
  const {
    watch,
    control,
    handleSubmit,
    reset,
    getValues,
    formState: { errors },
  } = useForm<IOffersFilterForm>({
    defaultValues: {
      offerType: currentValues.offerType,
      volume: {
        from: currentValues.volume.from,
        to: currentValues.volume.to,
      },
      price: {
        from: currentValues.price.from,
        to: currentValues.price.to,
      },
      regionIds: currentValues.regionIds,
      productTypes: currentValues.productTypes,
      distanceFromWarehouse: currentValues.distanceFromWarehouse,
      usingNds: currentValues.usingNds,
    },
  })

  const location = useLocation()

  const watchedFields = watch()

  const debouncedChangeValues = useCallback(
    debounce(() => onChange(getValues()), AppConfig.DEFAULT_DEBOUNCE_TIME),
    []
  )

  useEffect(() => {
    debouncedChangeValues()
  }, [
    watchedFields.offerType,
    watchedFields.regionIds,
    watchedFields.productTypes,
    watchedFields.volume.from,
    watchedFields.volume.to,
    watchedFields.price.to,
    watchedFields.price.from,
    watchedFields.distanceFromWarehouse?.distance,
    watchedFields.distanceFromWarehouse?.warehouseId,
    watchedFields.usingNds,
  ])

  const onSubmitHandler = (data: IOffersFilterForm) => onSubmit(data)
  const onResetFormHandler = () => {
    reset(defaultValues)
    onSubmit(defaultValues)
    onResetToDefaultValues()
  }

  return (
    <form onSubmit={handleSubmit(onSubmitHandler)}>
      <FormField validateMessage={errors.offerType?.message}>
        <Controller
          name="offerType"
          control={control}
          render={({ field }) => (
            <Segmented disabled={isDisabledOfferType} block options={filterTypeOptions} {...field} />
          )}
        />
      </FormField>

      <FormField label="Культура" validateMessage={errors.productTypes?.message}>
        <Controller name="productTypes" control={control} render={({ field }) => <CultureSelectControl {...field} />} />
      </FormField>

      <FormField label="Объем, т" htmlFor="volume" validateMessage={errors.volume?.message}>
        <Controller
          name="volume"
          control={control}
          render={({ field }) => (
            <RangeSliderControl
              {...field}
              id="volume"
              hasError={!!errors.volume}
              minValue={defaultValues.volume.from}
              maxValue={defaultValues.volume.to}
            />
          )}
        />
      </FormField>

      <FormField label="Цена, Р/т" htmlFor="price" validateMessage={errors.price?.message}>
        <Controller
          name="price"
          control={control}
          render={({ field }) => (
            <RangeSliderControl
              {...field}
              id="price"
              hasError={!!errors.price}
              step={200}
              minValue={defaultValues.price.from}
              maxValue={defaultValues.price.to}
            />
          )}
        />
      </FormField>

      <FormField htmlFor="usingNds" label="НДС" validateMessage={errors.usingNds?.message}>
        <Controller
          name="usingNds"
          control={control}
          render={({ field }) => (
            <Select
              style={{ width: '100%' }}
              value={field.value ? field.value.toString() : null}
              onChange={(x) => field.onChange(x ?? undefined)}
              options={[
                { value: null, label: 'не выбрано' },
                { value: 'true', label: 'с НДС' },
                { value: 'false', label: 'без НДС' },
              ]}
            />
          )}
        />
      </FormField>

      <FormField label="Регион" htmlFor="regionIds" validateMessage={errors.regionIds?.message}>
        <Controller
          name="regionIds"
          control={control}
          render={({ field }) => (
            <RegionSelectControl {...field} isHiddenDisabled={location.pathname === appRoutes.offersList.url} />
          )}
        />
      </FormField>

      {isShowDistanceFromWarehouseControl && (
        <Space direction="vertical">
          <Text variant="normal">Вы можете произвести расчет расстояния от вашего склада до предполагаемого груза</Text>
          <FormField
            label="Выберите склад"
            htmlFor="distanceFromWarehouse"
            validateMessage={errors.distanceFromWarehouse?.message}
          >
            <Controller
              name="distanceFromWarehouse"
              control={control}
              render={({ field }) => <DistanceFromWarehouseControl {...field} isColumnView />}
            />
          </FormField>
        </Space>
      )}

      <Divider />
      {foundResults != null && (
        <S.FoundResultWrapper>Мы нашли {foundResults} предложений по выбранным параметрам</S.FoundResultWrapper>
      )}
      <S.ActionFooterWrapper>
        <Button htmlType="button" onClick={onResetFormHandler}>
          Сбросить все
        </Button>
        <Button htmlType="submit" type="primary">
          Показать
        </Button>
      </S.ActionFooterWrapper>
    </form>
  )
}
