import { useMutation } from '@tanstack/react-query'

import {
  CreateDealDocumentBody,
  CreateDealDocumentResponse,
  DealDocumentApiDealDocumentControllerCreateDocumentTemplateRequest,
} from '~api/generated'
import { useUploadFileAndGetId } from '~hooks/_utils'
import { useApi } from '~providers/api'

interface CreateDealDocumentBodyWithFile extends Omit<CreateDealDocumentBody, 'fileKeys'> {
  documentFile: File
}
interface DealDocumentTemplateUploadRequest
  extends Omit<DealDocumentApiDealDocumentControllerCreateDocumentTemplateRequest, 'createDealDocumentBody'> {
  createDealDocumentBody: CreateDealDocumentBodyWithFile
}
export const useUploadDealTemplateDocument = () => {
  const api = useApi()
  const useUploadFileAndGetIdFn = useUploadFileAndGetId()

  const uploadDealDocumentFn = async (
    createDealBody: DealDocumentTemplateUploadRequest
  ): Promise<CreateDealDocumentResponse | null> => {
    const uploadedFileId = await useUploadFileAndGetIdFn.mutateAsync(createDealBody.createDealDocumentBody.documentFile)

    const res = await api.dealDocument.dealDocumentControllerCreateDocumentTemplate({
      ...createDealBody,
      createDealDocumentBody: { ...createDealBody.createDealDocumentBody, fileKeys: [uploadedFileId] },
    })

    return res.data
  }

  return useMutation(uploadDealDocumentFn)
}
