import React from 'react'

import { RouteObject } from 'react-router-dom'

import { appRoutes } from '~/router'
import LogisticsPage from '~pages/LogisticsPage'
import { FindCargo } from '~pages/LogisticsPage/modules/FindCargo/FindCargo'
import { LoadCard } from '~pages/LogisticsPage/modules/LoadCard'
import { LogisticDeals } from '~pages/LogisticsPage/modules/LogisticDeals/LogisticDeals'
import { LogisticResponses } from '~pages/LogisticsPage/modules/LogisticResponses/LogisticResponses'
import { CreateLoad } from '~pages/LogisticsPage/modules/MyCargos/components/CreateLoad/CreateLoad'
import { MyCargos } from '~pages/LogisticsPage/modules/MyCargos/MyCargos'

import { RequireAuth } from '../../providers/auth'
import { LogisticDealCard } from '~pages/LogisticsPage/modules/LogisticDealCard'

export const logisticsRouter: RouteObject = {
  path: appRoutes.logistics.url,
  element: (
    <RequireAuth>
      <LogisticsPage />
    </RequireAuth>
  ),
  children: [
    {
      path: appRoutes.logisticMyCargos.url,
      element: <MyCargos />,
    },
    {
      path: appRoutes.logisticFindCargo.url,
      element: <FindCargo />,
    },
    {
      path: appRoutes.logisticResponses.url,
      element: <LogisticResponses />,
    },
    {
      path: appRoutes.logisticDeals.url,
      element: <LogisticDeals />,
    },
    {
      path: appRoutes.logisticsCreateLoad.url,
      element: <CreateLoad />,
    },
    {
      path: appRoutes.logisticsLoadCard.url,
      element: <LoadCard />,
    },
    {
      path: appRoutes.logisticDealCard.url,
      element: <LogisticDealCard />,
    },
  ],
}
