import { isEqual } from 'lodash'
import { makeAutoObservable } from 'mobx'

import { getActiveFilters } from '~/store/filters/_utils'
import { ElevatorApiElevatorControllerGetElevatorsRequest } from '~api/generated'
import { CultureSelectControlValue } from '~shared/controls/CultureSelectControl'
import { RegionSelectControlValue } from '~shared/controls/RegionSelectControl'

export interface IBasisFilterForm {
  name: string
  regionIds: RegionSelectControlValue
  productTypes: CultureSelectControlValue
}

// Filter panel shows this values after first open
const defaultFiltersConfig: IBasisFilterForm = {
  name: '',
  regionIds: [],
  productTypes: [],
}

class BasisFilterStore {
  private filters: IBasisFilterForm

  constructor() {
    this.filters = defaultFiltersConfig
    makeAutoObservable(this)
  }

  setFilters(newFilters: IBasisFilterForm): void {
    this.filters = newFilters
  }

  get basisFilters(): IBasisFilterForm {
    return this.filters
  }

  get defaultFiltersConfig(): IBasisFilterForm {
    return defaultFiltersConfig
  }

  get isCurrentFilterDefault(): boolean {
    return isEqual(this.filters, defaultFiltersConfig)
  }

  get mappedCurrentFiltersToResponse(): Partial<ElevatorApiElevatorControllerGetElevatorsRequest> {
    return this.isCurrentFilterDefault
      ? {}
      : {
          elevatorNameOrAddress: !this.filters.name.trim() ? undefined : this.filters.name,
          regionIds: this.filters.regionIds.length ? this.filters.regionIds : undefined,
          productTypes: this.filters.productTypes.length ? this.filters.productTypes : undefined,
        }
  }

  get activeFilters(): string[] {
    return getActiveFilters(this.filters, defaultFiltersConfig)
  }

  get hasActiveFilters(): boolean {
    return this.activeFilters.length > 0
  }

  get activeFiltersCount(): number {
    return this.activeFilters.length
  }
}

export const basisFilterStore = new BasisFilterStore()
export const elevatorsAdminFilterStore = new BasisFilterStore()
