import { Control, DeepRequired, FieldErrorsImpl, GlobalError, IsAny } from 'react-hook-form'
import * as yup from 'yup'

import {
  FreightPackingType,
  FreightPaymentTerm,
  FreightReadinessType,
  LoadingTransportType,
  RoadSurfaceQuality,
  RoadSurfaceType,
  RoutePointType,
  TransportFormatType,
  UnloadingTransportType,
} from '~api/gql-generated/graphql'
import { VALIDATORS_MSG } from '~shared/validations'
import { fullPhoneNumberValidator } from '~shared/validations/schemas'

export enum AddressTypes {
  address = 'Новый адрес',
  warehouse = 'Мой склад',
  elevator = 'Базис',
}

export const CreateLoadSchema = yup.object({
  routePoints: yup
    .array()
    .of(
      yup
        .object({
          address: yup.string(),
          elevatorId: yup.number(),
          warehouseId: yup.number(),
        })
        .shape(
          {
            address: yup.string().when(['elevatorId', 'warehouseId'], {
              is: (elevatorId: number, warehouseId: number) => !elevatorId && !warehouseId,
              then: (schema) => schema.required(VALIDATORS_MSG.required),
            }),
            elevatorId: yup.number().when(['address', 'warehouseId'], {
              is: (address: string, warehouseId: number) => !address && !warehouseId,
              then: (schema) => schema.required(VALIDATORS_MSG.required),
            }),
            warehouseId: yup.number().when(['elevatorId', 'address'], {
              is: (elevatorId: number, address: string) => !elevatorId && !address,
              then: (schema) => schema.required(VALIDATORS_MSG.required),
            }),
            type: yup.mixed<RoutePointType>().oneOf(Object.values(RoutePointType)).required(VALIDATORS_MSG.required),
            freight: yup
              .object({
                packingType: yup
                  .mixed<FreightPackingType>()
                  .oneOf(Object.values(FreightPackingType))
                  .required(VALIDATORS_MSG.required),
                packingTypeCustom: yup.string().when(['packingType'], {
                  is: (packingType: FreightPackingType) => packingType === FreightPackingType.Custom,
                  then: (schema) => schema.required(VALIDATORS_MSG.required),
                }),
                bigBagsCount: yup.number().when(['packingType'], {
                  is: (packingType: FreightPackingType) => packingType === FreightPackingType.BigBag,
                  then: (schema) => schema.required(VALIDATORS_MSG.required),
                }),
                productTypeId: yup.mixed<number>().required(VALIDATORS_MSG.required),
                weightKg: yup.number().required(VALIDATORS_MSG.required),
              })
              .notRequired(),
            roadTransportParameters: yup
              .object({
                loadingTypes: yup
                  .array()
                  .of(
                    yup
                      .mixed<LoadingTransportType>()
                      .oneOf(Object.values(LoadingTransportType))
                      .required(VALIDATORS_MSG.required)
                  )
                  .required(VALIDATORS_MSG.required),
                unloadingTypes: yup
                  .array()
                  .of(
                    yup
                      .mixed<UnloadingTransportType>()
                      .oneOf(Object.values(UnloadingTransportType))
                      .required(VALIDATORS_MSG.required)
                  )
                  .required(VALIDATORS_MSG.required),
              })
              .shape(
                {
                  loadingTypes: yup
                    .array()
                    .when(['unloadingTypes'], {
                      is: (unloadingTypes: UnloadingTransportType) => !unloadingTypes?.length,
                      then: (schema) => schema.min(1, VALIDATORS_MSG.required),
                    })
                    .of(
                      yup
                        .mixed<LoadingTransportType>()
                        .oneOf(Object.values(LoadingTransportType))
                        .required(VALIDATORS_MSG.required)
                    )
                    .required(VALIDATORS_MSG.required),
                  unloadingTypes: yup
                    .array()
                    .of(
                      yup
                        .mixed<UnloadingTransportType>()
                        .oneOf(Object.values(UnloadingTransportType))
                        .required(VALIDATORS_MSG.required)
                    )
                    .when(['loadingTypes'], {
                      is: (loadingTypes: LoadingTransportType) => !loadingTypes?.length,
                      then: (schema) => schema.min(1, VALIDATORS_MSG.required),
                    })
                    .required(VALIDATORS_MSG.required),
                  loadingTypeCustom: yup.string().when('loadingTypes', {
                    is: (loadingTypes: LoadingTransportType[]) => loadingTypes?.includes(LoadingTransportType.Custom),
                    then: (schema) => schema.required(VALIDATORS_MSG.required),
                  }),
                  unloadingTypeCustom: yup.string().when('unloadingTypes', {
                    is: (unloadingTypes: UnloadingTransportType[]) =>
                      unloadingTypes?.includes(UnloadingTransportType.Custom),
                    then: (schema) => schema.required(VALIDATORS_MSG.required),
                  }),
                  loadingThroughput: yup.number(),
                  unloadingThroughput: yup.number(),
                  vehicleLengthMaxM: yup.number(),
                  vehicleHeightMaxM: yup.number(),
                  vehicleClearanceMinCm: yup.number(),
                  hasParking: yup.boolean(),
                  hasScales: yup.boolean(),
                  hasWeighingAutomation: yup.boolean(),
                  distanceToScalesM: yup.number(),
                  scalesWeightLimitT: yup.number(),
                  roadSurfaceType: yup.mixed<RoadSurfaceType>().oneOf(Object.values(RoadSurfaceType)),
                  roadSurfaceQuality: yup.mixed<RoadSurfaceQuality>().oneOf(Object.values(RoadSurfaceQuality)),
                  additionalDetails: yup.string(),
                },
                [['loadingTypes', 'unloadingTypes']]
              ),
          },
          [
            ['address', 'warehouseId'],
            ['address', 'elevatorId'],
            ['warehouseId', 'elevatorId'],
          ]
        )
    )
    .required(VALIDATORS_MSG.required),
  readinessType: yup
    .mixed<FreightReadinessType>()
    .oneOf(Object.values(FreightReadinessType))
    .required(VALIDATORS_MSG.required),
  startedAt: yup.string().when('readinessType', {
    is: (readinessType: FreightReadinessType) => readinessType === FreightReadinessType.FromDate,
    then: (schema) => schema.required(VALIDATORS_MSG.required),
  }),
  endedAt: yup.string(),
  startDaysCount: yup.number(),
  transportFormatTypes: yup.array().of(yup.mixed<TransportFormatType>().required(VALIDATORS_MSG.required)),
  transportBodyTypeIds: yup.array().of(yup.mixed<number>().defined(VALIDATORS_MSG.defined)),
  pricePerTonWithoutNds: yup.number().required(VALIDATORS_MSG.required),
  isAuction: yup.boolean().required(VALIDATORS_MSG.required),
  paymentTerm: yup
    .mixed<FreightPaymentTerm>()
    .oneOf(Object.values(FreightPaymentTerm))
    .required(VALIDATORS_MSG.required),
  advancePercent: yup
    .number()
    .when('paymentTerm', {
      is: (freightPaymentTerm: FreightPaymentTerm) => freightPaymentTerm === FreightPaymentTerm.Advance,
      then: (schema) => schema.required(VALIDATORS_MSG.required),
    })
    .typeError(VALIDATORS_MSG.required),
  deferredDaysCount: yup
    .number()
    .when('paymentTerm', {
      is: (freightPaymentTerm: FreightPaymentTerm) => freightPaymentTerm === FreightPaymentTerm.Deferred,
      then: (schema) => schema.required(VALIDATORS_MSG.required),
    })
    .typeError(VALIDATORS_MSG.required),
  contactPerson: yup.string().required(VALIDATORS_MSG.required),
  contactPhoneNumber: yup
    .string()
    .trim()
    .test('mobilePhone', VALIDATORS_MSG.notFullPhoneNumber, fullPhoneNumberValidator)
    .required(VALIDATORS_MSG.required),
  additionalDetails: yup.string(),
  fromAddressType: yup.mixed<AddressTypes>().oneOf(Object.values(AddressTypes)).required(VALIDATORS_MSG.required),
  toAddressType: yup.mixed<AddressTypes>().oneOf(Object.values(AddressTypes)).required(VALIDATORS_MSG.required),
})

export type CreateLoadValues = yup.InferType<typeof CreateLoadSchema>

export type CreateLoadErrors<T extends CreateLoadValues = CreateLoadValues> = Partial<
  CreateLoadValues extends IsAny<CreateLoadValues> ? any : FieldErrorsImpl<DeepRequired<T>>
> & {
  root?: Record<string, GlobalError> & GlobalError
}

export interface CreateLoadFormFieldProps {
  control: Control<CreateLoadValues>
  errors: CreateLoadErrors
}
