import { TransportDocumentType } from '~api/gql-generated/graphql'
import { codeUnreachable } from '~/utils/code-unreachable'

export const getTransportDocumentName = (transportDocumentType: TransportDocumentType) => {
  switch (transportDocumentType) {
    case TransportDocumentType.Photo:
      return 'Фото'
    case TransportDocumentType.RegistrationCertificate:
      return 'СТС'
    case TransportDocumentType.TransportPassport:
      return 'ПТС'
    case TransportDocumentType.LeasingContract:
      return 'Договор аренды/лизинга ТС'
    default:
      return codeUnreachable(transportDocumentType)
  }
}
