import { makeAutoObservable } from 'mobx'

class ModeratorUsersFilterStore {
  public idSearchQuery = ''
  public phoneSearchQuery = ''

  constructor() {
    makeAutoObservable(this)
  }

  setIdSearchQuery(searchQuery: string) {
    this.idSearchQuery = searchQuery
  }

  setPhoneSearchQuery(searchQuery: string) {
    this.phoneSearchQuery = searchQuery
  }
}

export const moderatorUsersFilterStore = new ModeratorUsersFilterStore()
