import styled from 'styled-components'

import css from '@styled-system/css'

import { Box, EmptyButton } from '~/ui-components'

export const Content = styled(Box)(
  css({
    width: ['100%', null, '300px'],
  })
)

export const AddDocumentButton = styled(EmptyButton)`
  min-height: 112px;
`
