import React from 'react'

import { Select } from 'antd'
import { SelectProps } from 'antd/es/select'
import { BaseSelectRef } from 'rc-select'

import { useGetProductTypes } from '~/hooks'
import { ProductTypeData } from '~api/generated'
import { CustomControlInterface } from '~shared/controls/CustomControl.interface'

type Value = ProductTypeData['id']

interface CommonProps extends CustomControlInterface {
  hasError?: boolean
  id?: string
  placeholder?: string
  allowClear?: boolean
}

interface SelectPropsMult extends CommonProps {
  mode: 'multiple'
  onChange: (value: Value[]) => void
  value: Value[] | undefined
}

interface SelectPropsSingl extends CommonProps {
  mode: 'single'
  onChange: (value: Value | undefined) => void
  value: Value | undefined
}

type CultureSelectControlProps = SelectPropsMult | SelectPropsSingl

export const CultureSelectControl: React.FC<CultureSelectControlProps> = React.forwardRef<
  BaseSelectRef,
  CultureSelectControlProps
>(({ mode, id, value, onChange, hasError, placeholder = 'Выберите культуру' }, ref) => {
  const cultureList = useGetProductTypes()

  const filterOption = (input: string, option: { label: string | null; value: string; children: string }) => {
    return (option?.children ?? '').toLowerCase().includes(input?.toLowerCase())
  }

  const sharedProps: SelectProps & { ref?: React.Ref<BaseSelectRef> } = {
    id,
    ref,
    defaultValue: value,
    status: hasError ? 'error' : undefined,
    style: { width: '100%' },
    onChange: onSelectHandler,
    loading: cultureList.isLoading,
    disabled: cultureList.isLoading,
    placeholder,
    allowClear: true,
    showSearch: true,
    optionFilterProp: 'children',
    filterOption: filterOption as any,
  }

  function onSelectHandler(v: Value | Value[]) {
    if (Array.isArray(v) && mode === 'multiple') {
      onChange(v)
    } else if (mode === 'single') {
      onChange(v as Value)
    }
  }

  const options = cultureList.data?.map((culture) => (
    <Select.Option key={culture.type} value={culture.id}>
      {culture.name}
    </Select.Option>
  ))

  if (mode === 'multiple') {
    return (
      <Select {...sharedProps} mode="multiple" maxTagCount={1}>
        {options}
      </Select>
    )
  }

  return <Select {...sharedProps}>{options}</Select>
})

CultureSelectControl.displayName = 'CultureSelectControl'
