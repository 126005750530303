import { isEqual } from 'lodash'
import { makeAutoObservable } from 'mobx'

import { getActiveFilters } from '~/store/filters/_utils'
import { FreightOrderFilterInput } from '~api/gql-generated/graphql'
import { CargoFiltersFormType } from '~pages/LogisticsPage/shared/CargoFilters/CargoFiltersForm'

import { IActiveFilterTag, IActiveTags, IStoreFilter } from '~/store/filters/_storeFilters.types'

export class CargoFilterStore implements IActiveTags<CargoFiltersFormType>, IStoreFilter<CargoFiltersFormType> {
  _filters: CargoFiltersFormType

  search: string | undefined

  readonly _defaultFiltersConfig: CargoFiltersFormType = {
    weightKgFrom: undefined,
    weightKgTo: undefined,
    pricePerTonFrom: undefined,
    pricePerTonTo: undefined,
    priceWithNds: false,
    productTypeId: undefined,
    fromRegionId: undefined,
    toRegionId: undefined,
    paymentTerm: undefined,
    period: undefined,
  }

  constructor() {
    this._filters = this._defaultFiltersConfig
    makeAutoObservable(this)
  }

  setSearch(searchQuery: string | undefined): void {
    this.search = searchQuery
  }

  setFilters(newFilters: CargoFiltersFormType): void {
    this._filters = newFilters
  }

  setFilterByDefault(filterKey: keyof CargoFiltersFormType): void {
    const defaultValue = this.defaultFiltersConfig[filterKey]
    this.setFilters({ ...this.filters, [filterKey]: defaultValue })
  }

  get filters(): CargoFiltersFormType {
    return this._filters
  }

  get defaultFiltersConfig(): CargoFiltersFormType {
    return this._defaultFiltersConfig
  }

  get areCurrentFiltersDefault(): boolean {
    return isEqual(this._filters, this.defaultFiltersConfig)
  }

  get mappedCurrentFiltersToResponse(): Partial<FreightOrderFilterInput> {
    return this.areCurrentFiltersDefault
      ? {}
      : {
          weightKgFrom: this._filters.weightKgFrom ? this._filters.weightKgFrom * 1000 : undefined,
          weightKgTo: this._filters.weightKgTo ? this._filters.weightKgTo * 1000 : undefined,
          pricePerTonFrom: this._filters.pricePerTonFrom,
          pricePerTonTo: this._filters.pricePerTonTo,
          priceWithNds: this._filters.priceWithNds,
          fromRegionIds: this._filters.fromRegionId ? [this._filters.fromRegionId] : undefined,
          toRegionIds: this._filters.toRegionId ? [this._filters.toRegionId] : undefined,
          paymentTerm: this._filters.paymentTerm,
          productTypeIds: this._filters.productTypeId ? [this._filters.productTypeId] : undefined,
          startedAtFrom: this._filters.period?.length ? this._filters.period[0] : undefined,
          startedAtTo: this._filters.period?.length ? this._filters.period[1] : undefined,
          search: this.search,
        }
  }

  get hasActiveFilters(): boolean {
    return this._activeFiltersKeys.length > 0
  }

  get activeFiltersCount(): number {
    return this._activeFiltersKeys.length
  }

  getActiveFiltersTags(): IActiveFilterTag<CargoFiltersFormType>[] {
    return this._activeFiltersKeys.map((key) => {
      const label = this._getFilterLabel(key)
      return {
        key,
        label,
      }
    })
  }

  get _activeFiltersKeys(): (keyof CargoFiltersFormType)[] {
    return getActiveFilters(this._filters, this.defaultFiltersConfig)
  }

  _getFilterLabel(filterKey: keyof CargoFiltersFormType): string {
    return '' // TODO
    // switch (filterKey) {
    //   case 'offerType': {
    //     return getOperationTypeLabel(this.filters.offerType)
    //   }
    //   case 'volume': {
    //     return getVolumeLabel(this.filters.volume)
    //   }
    //   case 'price': {
    //     return getPriceLabel(this.filters.price)
    //   }
    //   case 'productTypes': {
    //     return foundProductNamesByIds(this.filters.productTypes)
    //   }
    //   case 'regionIds': {
    //     return foundRegionNamesByIds(this.filters.regionIds)
    //   }
    //   default: {
    //     return ''
    //   }
    // }
  }
}

export const myCargoFilterStore = new CargoFilterStore()
export const findCargoFilterStore = new CargoFilterStore()
export const logisticDealsFilterStore = new CargoFilterStore()
