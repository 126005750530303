import React from 'react'

import { Button, Tooltip } from 'antd'
import { FiChevronRight } from 'react-icons/fi'

import { Badge, Card } from '~/ui-components'
import { formatNumber } from '~/utils/formatNumber'
import { getDateTimeFormat, getTimeDurationFormat } from '~/utils/getDateFormat'
import { isOfferExpireSoon } from '~/utils/offers/is-offer-expire-soon'

import { ModeratorOfferListMobileItemType } from './ModeratorOfferListMobile.types'

import * as S from './ModeratorOfferListMobileItem.styled'
interface IModeratorOfferListMobileItemProps extends ModeratorOfferListMobileItemType {
  takeOfferToWork: (offerId: number) => void
  onItemClick: (item: ModeratorOfferListMobileItemType) => void
}

export const ModeratorOfferListMobileItem: React.FC<IModeratorOfferListMobileItemProps> = (props) => {
  const takeOfferToWorkHandler = (e: React.MouseEvent) => {
    e.stopPropagation()
    props.takeOfferToWork(props.id)
  }

  const onItemClickHandler = (e: React.MouseEvent) => {
    e.stopPropagation()
    props.onItemClick(props)
  }

  const renderExpiredAtDate = () => {
    if (!props.expiredAt) {
      return null
    }
    const dateTimeFormatted = getTimeDurationFormat(new Date(), props.expiredAt)

    if (dateTimeFormatted === -1) {
      return (
        <Tooltip title="Снято с публикации">
          <Badge color="red">{getDateTimeFormat(props.expiredAt)}</Badge>
        </Tooltip>
      )
    }
    return (
      <Tooltip title={getDateTimeFormat(props.expiredAt)}>
        {isOfferExpireSoon(props.expiredAt) ? <b>{dateTimeFormatted}</b> : dateTimeFormatted}
      </Tooltip>
    )
  }

  return (
    <S.StyledCard onClick={onItemClickHandler} selected={props.expiredAt && isOfferExpireSoon(props.expiredAt)}>
      <S.ContentWrapper>
        <S.Row>
          <Card.Label>№</Card.Label>
          <Card.Value>{props.id}</Card.Value>
        </S.Row>
        <S.Row>
          <Card.Label>Культура</Card.Label>
          <Card.Value>{props.culture}</Card.Value>
        </S.Row>
        <S.Row>
          <Card.Label>Объем,т</Card.Label>
          <Card.Value>{props.volume}</Card.Value>
        </S.Row>
        <S.Row>
          <Card.Label>Цена ₽/т</Card.Label>
          <Card.Value>{formatNumber(props.price)}</Card.Value>
        </S.Row>
        <S.Row>
          <Card.Label>Наименование компании</Card.Label>
          <Card.Value>{props.companyName}</Card.Value>
        </S.Row>
        <S.Row>
          <Card.Label>Cнятие с публикации</Card.Label>
          <Card.Value>{renderExpiredAtDate()}</Card.Value>
        </S.Row>
        <S.Row>
          <Card.Label>Дата создания</Card.Label>
          <Card.Value>{props.createdAt}</Card.Value>
        </S.Row>
        <S.Row>
          <S.ActionWrapper>
            {props.moderator ? (
              <div>
                <Card.Label>Модератор</Card.Label>
                <Card.Value>{props.moderator?.name}</Card.Value>
              </div>
            ) : (
              <Button type="primary" onClick={takeOfferToWorkHandler}>
                Взять в работу
              </Button>
            )}

            <FiChevronRight size={24} display={'block'} />
          </S.ActionWrapper>
        </S.Row>
      </S.ContentWrapper>
    </S.StyledCard>
  )
}
