import React, { useMemo } from 'react'

import { Empty, Pagination, Spin, Table } from 'antd'
import { useNavigate } from 'react-router-dom'

import { CompanyAuctionData } from '~/api/generated'
import { useGetTableHeight, usePagination } from '~/hooks'
import { getDateTimeDayFormat } from '~/utils/getDateFormat'
import { useGetMyAuctions } from '~hooks/auctions'
import { useGetCurrentRole } from '~hooks/auth'
import { useGetDevice } from '~hooks/common'
import { useGetProductTypes } from '~hooks/products/useGetProductTypes'
import { AuctionMobileCard, AuctionsHeader } from '~pages/Auctions/components'
import { useGetColumnsMyLots } from '~pages/Auctions/hooks'
import { REFETCH_INTERVAL } from '~pages/Auctions/utils'
import { useAuth } from '~providers/auth'

import * as S from '~pages/Auctions/AuctionsPage.styled'

export const AuctionMyLots = () => {
  const navigate = useNavigate()
  const { companyId } = useAuth()
  const device = useGetDevice()
  const isMobile = device === 'mobile'
  const {
    company: { isTrader },
  } = useGetCurrentRole()

  const { currentPage, setCurrentPage, pageSize, setPageSize, pageSizeOptions } = usePagination()
  const {
    data: myAuctionsData,
    refetch: myAuctionsRefetch,
    isLoading: isLoadingAuctions,
  } = useGetMyAuctions(
    {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      companyId: companyId!,
      currentPage,
      pageSize,
    },
    {
      keepPreviousData: true,
      refetchInterval: REFETCH_INTERVAL,
    }
  )

  const tableHeight = useGetTableHeight()

  const myAuctions = myAuctionsData?.companyAuctions
  const pagination = myAuctionsData?.pagination

  const { data: products } = useGetProductTypes()

  const productsMap = useMemo(() => new Map(products.map((product) => [product.type, product])), [products])

  const columns = useGetColumnsMyLots(productsMap)

  const handleRowClick = (record: CompanyAuctionData) => {
    navigate(`${record.id}`)
  }

  return (
    <>
      <AuctionsHeader onAfterCreateAuction={myAuctionsRefetch} />
      {!isMobile ? (
        <S.AuctionTableWrapper>
          <Table<CompanyAuctionData>
            rowKey="id"
            className="table-interactive"
            loading={isLoadingAuctions}
            columns={columns}
            dataSource={myAuctions}
            scroll={{ x: 'max-content', y: tableHeight }}
            pagination={false}
            onRow={(record) => ({
              onClick: handleRowClick.bind(null, record),
            })}
          />
        </S.AuctionTableWrapper>
      ) : (
        <Spin spinning={isLoadingAuctions}>
          {!myAuctions?.length && <Empty />}
          <S.AuctionMobileCardList>
            {myAuctions?.map((auction) => (
              <AuctionMobileCard
                key={auction.id}
                auctionId={auction.id}
                productName={auction.offer.product.name}
                offerType={isTrader ? auction.offer.type : undefined}
                status={auction.status}
                volume={auction.offer.volume}
                price={auction.offer.price}
                includingNds={auction.offer.includingNds}
                location={auction.offer.address}
                endedAt={auction.endedAt ? getDateTimeDayFormat(auction.endedAt) : ''}
                onClick={() => handleRowClick(auction)}
              />
            ))}
          </S.AuctionMobileCardList>
        </Spin>
      )}

      {pagination && (
        <Pagination
          current={pagination?.currentPage}
          total={pagination?.total}
          pageSize={pagination?.pageSize}
          pageSizeOptions={pageSizeOptions}
          onShowSizeChange={(x, size) => setPageSize(size)}
          showSizeChanger
          onChange={setCurrentPage}
        />
      )}
    </>
  )
}
