import React from 'react'

import { Button, Modal } from 'antd'

import { modalSharedSettings } from '~/utils'
import { DocumentViewer, IDocumentViewerProps } from '~shared/components/DocumentViewer'

import * as S from './DocumentViewerModal.styled'

interface IViewerModalProps {
  isOpen: boolean
  onCancel: () => void
}
type DocumentViewerModalProps = IDocumentViewerProps & IViewerModalProps
export const DocumentViewerModal: React.FC<DocumentViewerModalProps> = ({
  onCancel,
  isOpen,
  ...documentViewerProps
}) => {
  const handleCancel = () => onCancel()

  return (
    <S.StyledModal
      {...modalSharedSettings}
      width={700}
      open={isOpen}
      onCancel={handleCancel}
      title="Просмотр документа"
      footer={[
        <Button key="cancel" onClick={handleCancel}>
          Закрыть
        </Button>,
      ]}
    >
      <S.Content>
        <DocumentViewer {...documentViewerProps} />
      </S.Content>
    </S.StyledModal>
  )
}
