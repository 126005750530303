import * as yup from 'yup'

import { OfferDataTypeEnum } from '~/api/generated'
import { addressSchema, VALIDATORS_MSG } from '~shared/validations'
import { fullPhoneNumberValidatorNotRequired } from '~shared/validations/schemas'

export const getCreateOfferForLotModalSchema = (offerType: OfferDataTypeEnum, price: number) => {
  const max = offerType === OfferDataTypeEnum.Buy ? price : 999999
  const min = offerType === OfferDataTypeEnum.Buy ? 1000 : price

  return yup.object({
    address: addressSchema,
    volume: yup
      .number()
      .typeError(VALIDATORS_MSG.required)
      .min(100, VALIDATORS_MSG.minValue(100))
      .required(VALIDATORS_MSG.required),
    price: yup
      .number()
      .typeError(VALIDATORS_MSG.required)
      .min(min, VALIDATORS_MSG.minValue(min))
      .max(max, VALIDATORS_MSG.maxValue(max))
      .required(VALIDATORS_MSG.required),
    contactPhone: yup
      .string()
      .trim()
      .test('mobilePhone', VALIDATORS_MSG.notFullPhoneNumber, fullPhoneNumberValidatorNotRequired),
    labAnalysis:
      offerType === OfferDataTypeEnum.Buy ? yup.mixed().required(VALIDATORS_MSG.required) : yup.mixed().notRequired(),
  })
}
