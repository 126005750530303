import styled from 'styled-components'

import { breakpoints } from '~/tokens'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  padding: 24px;
  width: 400px;

  @media (max-width: ${breakpoints.MOBILE_XL}px) {
    width: 100%;
    padding: 16px;
  }
`

export const StyledForm = styled.form`
  width: 100%;
`

export const ActionWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 16px;
  @media (max-width: ${breakpoints.MOBILE_XL}px) {
    display: none;
  }
`
