import { UpdateDealCarrierBodyCarriageByEnum } from '~api/generated'

export function getCarrierCardDescription(carrierSide: UpdateDealCarrierBodyCarriageByEnum | null): string | null {
  if (carrierSide) {
    switch (carrierSide) {
      case UpdateDealCarrierBodyCarriageByEnum.Seller:
        return 'Данная карточка заполняется продавцом'
      case UpdateDealCarrierBodyCarriageByEnum.Buyer:
        return 'Данная карточка заполняется покупателем'
      case UpdateDealCarrierBodyCarriageByEnum.Intermediary:
        return 'Данная карточка заполняется Зерно-Агрегатором'
    }
  }
  return null
}
