import React, { useCallback, useEffect } from 'react'

import { SegmentedLabeledOption } from 'antd/es/segmented'
import { debounce, isEqual } from 'lodash'
import { observer } from 'mobx-react-lite'
import { Controller, useForm } from 'react-hook-form'
import { useLocation } from 'react-router-dom'

import { AppConfig } from '~/appConfig'
import { appRoutes } from '~/router'
import { useGetDevice } from '~hooks/common'
import { useGetOffers } from '~hooks/offer'
import { getStore } from '~pages/OffersPage/components/OffersFilter/_utils'
import { CultureSelectControl, DistanceFromWarehouseControl, RegionSelectControl } from '~shared/controls'

import { ActiveFiltersInlineProps, IActiveFiltersInlineForm } from './ActiveFiltersInline.types'

import * as S from './ActiveFiltersInline.styled'

export const ActiveFiltersInline: React.FC<ActiveFiltersInlineProps> = observer(
  ({ isShowDistanceFromWarehouseControl = false, ...props }) => {
    const device = useGetDevice()
    const isMobile = device === 'mobile'
    const size = 'middle'
    const store = getStore(props.store)

    const location = useLocation()

    const currentValues = store.filters

    const offersBuy = useGetOffers({ type: 'BUY', statuses: ['PUBLISHED'], pageSize: 1 })
    const offersSell = useGetOffers({ type: 'SELL', statuses: ['PUBLISHED'], pageSize: 1 })

    const totalBuy = offersBuy?.data?.pagination?.total ?? 0
    const totalSell = offersSell?.data?.pagination?.total ?? 0

    const { watch, control, getValues, setValue } = useForm<IActiveFiltersInlineForm>({
      defaultValues: {
        offerType: currentValues.offerType,
        regionIds: currentValues.regionIds,
        productTypes: currentValues.productTypes,
        distanceFromWarehouse: currentValues.distanceFromWarehouse,
      },
    })
    const watchedFields = watch()

    useEffect(() => {
      const formValues = getValues()
      if (currentValues.offerType !== formValues.offerType) {
        setValue('offerType', currentValues.offerType)
      }
      if (!isEqual(currentValues.regionIds, formValues.regionIds)) {
        setValue('regionIds', currentValues.regionIds)
      }
      if (!isEqual(currentValues.productTypes, formValues.productTypes)) {
        setValue('productTypes', currentValues.productTypes)
      }
      if (!isEqual(currentValues.distanceFromWarehouse, formValues.distanceFromWarehouse)) {
        setValue('distanceFromWarehouse', currentValues.distanceFromWarehouse)
      }
    }, [
      currentValues.offerType,
      currentValues.regionIds,
      currentValues.productTypes,
      currentValues.distanceFromWarehouse,
    ])

    const onFiltersChange = (formValues: IActiveFiltersInlineForm) => {
      store.setFilters({
        ...store.filters,
        offerType: formValues.offerType,
        regionIds: formValues.regionIds,
        productTypes: formValues.productTypes,
        distanceFromWarehouse: formValues.distanceFromWarehouse,
      })
    }

    const debouncedChangeValues = useCallback(
      debounce(() => onFiltersChange(getValues()), AppConfig.DEFAULT_DEBOUNCE_TIME),
      []
    )

    useEffect(() => {
      debouncedChangeValues()
    }, [
      watchedFields.offerType,
      watchedFields.regionIds,
      watchedFields.productTypes,
      watchedFields.distanceFromWarehouse,
    ])

    const filterTypeOptions: SegmentedLabeledOption[] = [
      {
        label: `Все (${totalBuy + totalSell})`,
        value: 'ALL',
      },
      {
        label: `Покупка (${totalBuy})`,
        value: 'BUY',
      },
      {
        label: `Продажа (${totalSell})`,
        value: 'SELL',
      },
    ]

    return (
      <S.StyledForm>
        <S.Item>
          <Controller
            name="offerType"
            control={control}
            render={({ field }) => (
              <S.StyledSegmented
                size={size}
                options={filterTypeOptions}
                value={field.value}
                onChange={field.onChange}
              />
            )}
          />
        </S.Item>
        <S.ItemSelect>
          <Controller
            name="productTypes"
            control={control}
            render={({ field }) => <CultureSelectControl size={size} {...field} />}
          />
        </S.ItemSelect>
        <S.ItemSelect>
          <Controller
            name="regionIds"
            control={control}
            render={({ field }) => (
              <RegionSelectControl
                size={size}
                {...field}
                isHiddenDisabled={location.pathname === appRoutes.offersList.url}
              />
            )}
          />
        </S.ItemSelect>

        {isShowDistanceFromWarehouseControl && (
          <S.ItemSelect>
            <Controller
              name="distanceFromWarehouse"
              control={control}
              render={({ field }) => <DistanceFromWarehouseControl {...field} />}
            />
          </S.ItemSelect>
        )}
      </S.StyledForm>
    )
  }
)
