import { useQuery, UseQueryResult } from '@tanstack/react-query'

import {
  ElevatorAdvertApiElevatorAdvertControllerGetElevatorAdvertsRequest,
  GetElevatorAdvertsResponse,
} from '~api/generated'
import { useApi } from '~providers/api'

export const useGetElevatorAdverts = (
  request: ElevatorAdvertApiElevatorAdvertControllerGetElevatorAdvertsRequest
): UseQueryResult<GetElevatorAdvertsResponse> => {
  const api = useApi()

  const fetchFn = async (): Promise<GetElevatorAdvertsResponse> => {
    const res = await api.elevatorAdvert.elevatorAdvertControllerGetElevatorAdverts(request)

    return res.data
  }

  return useQuery(['elevatorAdverts', request], fetchFn, { enabled: !!request.elevatorId })
}
