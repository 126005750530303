import React, { useEffect, useState } from 'react'

import { Button } from 'antd'
import { Controller, useForm } from 'react-hook-form'

import { yupResolver } from '@hookform/resolvers/yup/dist/yup'

import { DaDataBankSuggestion, FormField, InputDadata, InputMask } from '~/ui-components'
import { ResponsePageHeader } from '~shared/components'

import { companyAccountPayRequisitesAddSchema } from './CompanyAccountPayRequisitesAdd.validation'

import {
  CompanyAccountPayRequisitesAddProps,
  CompanyAccountPayRequisitesAddValues,
} from './CompanyAccountPayRequisitesAdd.types'

import * as S from './CompanyAccountPayRequisitesAdd.styled'

export const CompanyAccountPayRequisitesAdd: React.FC<CompanyAccountPayRequisitesAddProps> = ({
  loading,
  onBack,
  onSubmit,
}) => {
  const {
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = useForm<CompanyAccountPayRequisitesAddValues>({
    resolver: yupResolver(companyAccountPayRequisitesAddSchema),
  })

  const [dadataSelected, setDadataSelected] = useState<DaDataBankSuggestion | null>(null)
  const [oneOfDadataInputWasChanged, setOneOfDadataInputWasChanged] = useState<boolean>(false)

  // set dadata's inputs after selecting dadata suggestion
  const setFormAccordingByDadata = (value: DaDataBankSuggestion | undefined) => {
    const data = value?.data
    if (!data) {
      console.log('There is no data for set')

      return
    }
    setValue('bankName', data.name?.payment ?? value?.value, { shouldValidate: true })
    setValue('correspondentAccount', data?.correspondent_account, { shouldValidate: true })
    setValue('bic', data.bic, { shouldValidate: true })

    setOneOfDadataInputWasChanged(false)
  }

  useEffect(() => {
    if (!oneOfDadataInputWasChanged) {
      return
    }

    // clear form fields when one of dadata's inputs was changed
    if (oneOfDadataInputWasChanged) {
      setValue('bankName', '', { shouldTouch: true })
      setValue('correspondentAccount', '', { shouldTouch: true })
      setValue('bic', '', { shouldTouch: true })
      setDadataSelected(null)
      setOneOfDadataInputWasChanged(false)

      return
    }
  }, [oneOfDadataInputWasChanged])

  const handleDadataSelect = (val?: DaDataBankSuggestion | undefined): void => {
    setDadataSelected(val ?? null)
    setFormAccordingByDadata(val)
  }

  return (
    <S.Wrapper>
      <S.StyledForm>
        <ResponsePageHeader
          variant="edit"
          title="Добавить платежные реквизиты"
          titleMobile="Добавить реквизиты"
          subTitle="МОЯ КОМПАНИЯ"
          loading={loading}
          onSubmit={handleSubmit(onSubmit)}
          onBack={onBack}
        />

        <FormField label="Название банка" isRequired htmlFor="bankName" validateMessage={errors.bankName?.message}>
          <Controller
            name="bankName"
            control={control}
            render={({ field }) => (
              <InputDadata
                id="bankName"
                placeholder="Введите наименование банка"
                inputValue={dadataSelected?.data?.name?.short ?? field.value}
                onSelect={handleDadataSelect}
                onChangeInput={(v) => {
                  setOneOfDadataInputWasChanged(true)
                }}
                variant="banks"
                inputStatus={errors.bankName && 'error'}
              />
            )}
          />
        </FormField>

        <FormField
          label="Расчетный счет"
          isRequired
          htmlFor="paymentAccount"
          validateMessage={errors.paymentAccount?.message}
        >
          <Controller
            name="paymentAccount"
            control={control}
            render={({ field }) => (
              <InputMask
                id="paymentAccount"
                status={errors.paymentAccount && 'error'}
                placeholder="Введите номер счета"
                format="####################"
                value={field.value}
                onValueChange={(evt) => field.onChange(evt.value)}
                mask="_"
              />
            )}
          />
        </FormField>

        <FormField
          label="Корр. счет"
          isRequired
          htmlFor="correspondentAccount"
          validateMessage={errors.correspondentAccount?.message}
        >
          <Controller
            name="correspondentAccount"
            control={control}
            render={({ field }) => (
              <InputMask
                id="correspondentAccount"
                status={errors.correspondentAccount && 'error'}
                placeholder="Введите номер счета"
                format="####################"
                value={field.value}
                onValueChange={(evt) => field.onChange(evt.value)}
                mask="_"
              />
            )}
          />
        </FormField>

        <FormField label="БИК" isRequired htmlFor="bic" validateMessage={errors.bic?.message}>
          <Controller
            name="bic"
            control={control}
            render={({ field }) => (
              <InputDadata
                id="bic"
                placeholder="Введите БИК"
                inputValue={dadataSelected?.data?.bic ?? field.value}
                onSelect={handleDadataSelect}
                onChangeInput={(v) => {
                  setOneOfDadataInputWasChanged(true)
                }}
                variant="banks"
                inputStatus={errors.bic && 'error'}
              />
            )}
          />
        </FormField>

        <S.ActionWrapper>
          <Button type="default" htmlType="button" onClick={onBack}>
            Отмена
          </Button>
          <Button type="primary" htmlType="button" loading={loading} onClick={handleSubmit(onSubmit)}>
            Готово
          </Button>
        </S.ActionWrapper>
      </S.StyledForm>
    </S.Wrapper>
  )
}
