import React, { FC } from 'react'

import { Button } from 'antd'
import { FiBriefcase, FiClock, FiEye, FiMousePointer, FiUpload } from 'react-icons/fi'
import { useNavigate } from 'react-router-dom'

import { appRoutes } from '~/router'
import { Text } from '~/ui-components'
import auction_pic_all_url from '~assets/images/auction_pic_all.png'
import {
  IPromoFlowStepProps,
  LoadDocumentsStep,
  PromoFlowStep,
  PromoFlowWrapper,
} from '~shared/components/PromoFlowStep'

import * as S from './AuctionPromo.styled'

const CheckDealsStepText: FC = () => {
  const navigate = useNavigate()
  return (
    <div>
      Узнать, как проходят сделки между продавцом и покупателем вы сможете в{' '}
      <Button type="link" onClick={() => navigate(appRoutes.deals.url, { replace: true })}>
        разделе “Сделки”
      </Button>
    </div>
  )
}

const flowSteps: IPromoFlowStepProps[] = [
  {
    Icon: FiUpload,
    text: <LoadDocumentsStep />,
  },
  {
    Icon: FiMousePointer,
    text: (
      <>
        Сделайте ставку на заинтересовавший вас лот или создайте свой. Минимальный объем для лота - 200 тонн, для ставки
        - 100 тонн.
        <br /> Срок действия лота 24 часа. Если ваш лот все еще актуален, то его можно опубликовать заново.
      </>
    ),
  },
  {
    Icon: FiClock,
    text: 'Сделка начнется сразу, как ставка будет принята.',
  },
  {
    Icon: FiBriefcase,
    text: 'Если ваша ставка не была принята другой стороной, вы можете предложить более выгодные условия, либо найти более подходящий лот.',
  },
  {
    Icon: FiEye,
    text: <CheckDealsStepText />,
  },
]

export const AuctionPromoContent: FC = () => {
  return (
    <S.ContentWrapper>
      <Text variant="h2">Как работают торги</Text>

      <S.ImgWrapper>
        <S.IntegrationImg src={auction_pic_all_url} alt="deals flow" />
      </S.ImgWrapper>

      <Text variant="h2">Полный процесс торгов выглядит так</Text>

      <PromoFlowWrapper>
        {flowSteps.map(({ text, Icon }, index) => (
          <PromoFlowStep key={index} Icon={Icon} text={text} />
        ))}
      </PromoFlowWrapper>
    </S.ContentWrapper>
  )
}
