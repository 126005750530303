import { Button } from 'antd'
import styled from 'styled-components'

import { breakpoints } from '~/tokens'

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px;
  gap: 16px;

  @media (max-width: ${breakpoints.MOBILE_XL}px) {
    padding: 16px;
  }
`

export const Header = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 16px;
`

export const AuctionTableWrapper = styled.div`
  display: block;

  @media (max-width: ${breakpoints.MOBILE_XL}px) {
    display: none;
  }
`

export const OfferTableHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  margin-top: 16px;
`

export const AddOfferButton = styled(Button)`
  margin-left: auto;
`

export const SpinWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100vh;
  align-items: center;
  justify-content: center;
`

export const Own = styled.div`
  color: var(--primary-main-color);
`

export const StatusWrapper = styled.div`
  display: flex;
  gap: 16px;
  justify-content: flex-end;
  width: 100%;
`

export const AuctionCardActions = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`

export const Parameters = styled.div`
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
`

export const ExpandedIcon = styled.div`
  color: var(--gray-8-color);
`

export const AuctionMobileCardList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`
