import { graphql } from '~api/gql-generated/gql'

export const getMeQuery = graphql(/* GraphQL */ `
  query GetMeQuery {
    me {
      id
      name
      email
      position
      phoneNumber
      role
      avatarUrl
      companies {
        id
      }
      actions {
        createCompany
        createUser
      }
      state {
        multiCompanyProfileGuideShown
      }
      moderator {
        id
        name
      }
      activeCompanyId
    }
  }
`)

export const getMeState = graphql(/* GraphQL */ `
  query GetMeStateQuery {
    me {
      state {
        multiCompanyProfileGuideShown
        isShowEmployeesGuide
      }
    }
  }
`)

export const getMeCompaniesQuery = graphql(/* GraphQL */ `
  query GetMeCompaniesQuery {
    me {
      companies {
        id
        inn
        kpp
        legalName
        ogrn
        okved
        type
        signatory
        signatoryLegalBasis
        signatoryPosition
        aicCharterMember
        verificationStatus
        phoneNumber
        rejectionReason
        useNds
        website
        logoUrl
        additionalInfo
        directorName
        verifiedAt
        codeOkved
        actualAddress
        legalAddress
        actions {
          addUser
          delete
          deleteUser
          sendOnModeration
          updateGeneralInfo
          updateLegalInfo
        }
      }
    }
  }
`)

export const getBankAccountsQuery = graphql(/* GraphQL */ `
  query BankAccounts($companyId: Int!) {
    bankAccounts(companyId: $companyId) {
      bankName
      id
      paymentAccount
      correspondentAccount
      companyId
      bic
      actions {
        delete
      }
    }
  }
`)

export const getCompanyVerificationRequirements = graphql(/* GraphQL */ `
  query VerificationRequirements {
    me {
      companies {
        id
        rejectionReason
        verificationStatus
        verificationRequirements {
          requisites
          bankAccount
          documents
        }
      }
    }
  }
`)
