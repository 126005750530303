import { createGlobalStyle } from 'styled-components'

export const DadataStyles = createGlobalStyle`
.react-dadata__container {
  position: relative;
}
.react-dadata__input {
  width: 100%;
}

.react-dadata__suggestions {
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 1px 6px 3px rgba(0, 0, 0, 0.1);
  left: 0;
  list-style: none;
  margin: 0;
  overflow: hidden;
  padding: 0;
  position: absolute;
  right: 0;
  text-align: left;
  top: calc(100% + 8px);
  z-index: 10;
}
.react-dadata__suggestion-note {
  color: #828282;
  font-size: 14px;
  padding: 10px 10px 5px;
}
.react-dadata__suggestion {
  background: none;
  border: none;
  box-sizing: border-box;
  cursor: pointer;
  display: block;
  font-size: 15px;
  padding: 7px 10px;
  text-align: left;
  width: 100%;
}
.react-dadata__suggestion--line-through {
  text-decoration: line-through;
}
.react-dadata__suggestion-subtitle {
  color: #777;
  font-size: 14px;
  margin-top: 4px;
}
.react-dadata__suggestion-subtitle-item {
  display: inline-block;
  margin-bottom: 4px;
  margin-right: 16px;
}
.react-dadata__suggestion-subtitle-item:last-child {
  margin-right: 0;
}
.react-dadata__suggestion--current {
  background-color: rgba(0, 124, 214, 0.15);
}
.react-dadata__suggestion:hover {
  background-color: rgba(0, 124, 214, 0.1);
}
.react-dadata mark {
  background: none;
}
.react-dadata--highlighted {
  color: #0094ff;
}
`
