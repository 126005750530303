import React from 'react'

import { Flex, Table, Typography } from 'antd'
import { ColumnsType } from 'antd/lib/table'

import { Badge, Text } from '~/ui-components'
import { getDateFormat } from '~/utils/getDateFormat'
import { getFreightResponseStatusColor } from '~/utils/logistics/get-freight-response-status-color'
import { getFreightResponseStatusName } from '~/utils/logistics/get-freight-response-status-name'
import { FreightOrderResponseEntity, RoutePointType } from '~api/gql-generated/graphql'
import { formatNumber } from '~/utils/formatNumber'

export function getFreightOrderResponseColumns() {
  const columns: ColumnsType<FreightOrderResponseEntity> = [
    {
      title: '№',
      dataIndex: 'id',
      width: 60,
    },
    {
      title: 'Дата погрузки',
      className: 'bold-cell',
      dataIndex: ['order', 'startedAt'],
      key: 'startedAt',
      width: 150,
      render: (startedAt) => startedAt && getDateFormat(startedAt),
    },
    {
      title: 'Статус',
      dataIndex: 'status',
      render: (status: FreightOrderResponseEntity['status']) => (
        <Badge color={getFreightResponseStatusColor(status)} size="s">
          {getFreightResponseStatusName(status)}
        </Badge>
      ),
    },
    {
      title: 'Маршрут',
      dataIndex: ['order', 'route', 'points'],
      width: 200,
      className: 'bold-cell',
      render: (points: FreightOrderResponseEntity['order']['route']['points']) => {
        const start = points.find((x) => x.type === RoutePointType.Start)
        const end = points.find((x) => x.type === RoutePointType.End)

        if (start && end) {
          return (
            <Flex align="center" gap={4}>
              <Typography.Text style={{ maxWidth: 100 }} ellipsis={{ tooltip: start.address.short }}>
                {start.address.short}
              </Typography.Text>
              &ndash;
              <Typography.Text style={{ maxWidth: 100 }} ellipsis={{ tooltip: start.address.short }}>
                {end.address.short}
              </Typography.Text>
            </Flex>
          )
        }
        if (start || end) {
          return (
            <Typography.Text
              style={{ maxWidth: 100 }}
              ellipsis={{ tooltip: start?.address.short ?? end?.address.short }}
            >
              {start?.address.short ?? end?.address.short}
            </Typography.Text>
          )
        }
      },
    },
    {
      title: 'Груз',
      dataIndex: ['order', 'route', 'points'],
      render: (points: FreightOrderResponseEntity['order']['route']['points']) => {
        const freights = points.filter((x) => !!x.freight).map((x) => x.freight)
        if (!freights.length) {
          return null
        }
        return freights[0]?.productType?.name ?? ''
      },
    },
    {
      title: 'Вес, т',
      dataIndex: 'weightKg',
      render: (weightKg: FreightOrderResponseEntity['order'], record: FreightOrderResponseEntity) => {
        return <Text variant="normal">{formatNumber(record.order.weightKg / 1000)} т</Text>
        // return (
        //   <Flex vertical gap={4}>
        //     <Text variant="normal-bold">{weightKg / 1000} т</Text>
        //     <Text variant="normal">из {record.order.weightKg / 1000} т</Text>
        //   </Flex>
        // )
      },
    },
    {
      title: 'Ставка, ₽/т',
      render: (v: FreightOrderResponseEntity) => {
        return (
          <Flex vertical>
            <Typography.Text>{formatNumber(v.order.pricePerTonWithNds)} (с НДС)</Typography.Text>
            <Typography.Text>{formatNumber(v.order.pricePerTonWithoutNds)} (без НДС)</Typography.Text>
          </Flex>
        )
      },
    },
    Table.EXPAND_COLUMN,
  ]

  return columns
}
