import React from 'react'

import { Avatar, Button, Space, Tooltip } from 'antd'
import { BsFillQuestionSquareFill } from 'react-icons/bs'
import { FiTrash2 } from 'react-icons/fi'

import { Text } from '~/ui-components'
import { getAbbrCompany } from '~/utils/company/get-abbr-company'
import { getCompanyRole } from '~/utils/company/get-company-role-old'
import { formatPhoneNumber } from '~/utils/formatPhoneNumber'
import { ReadValue, ResponsePageHeader } from '~shared/components'

import { CompanyAccountInfoRightMenu } from './CompanyAccountInfoRightMenu'

import { PersonalAccountInfoProps } from './CompanyAccountInfo.types'

import * as S from './CompanyAccountInfo.styled'

export const CompanyAccountInfo: React.FC<PersonalAccountInfoProps> = ({
  legalName,
  mobileNumber,
  companyType,
  website,
  logo,
  additionalInfo,
  canEdit,
  canRemove,
  onEdit,
  onRemove,
}) => {
  return (
    <S.Wrapper>
      <ResponsePageHeader
        variant="view"
        title="Общая информация"
        subTitle="МОЯ КОМПАНИЯ"
        rightElement={
          <CompanyAccountInfoRightMenu canEdit={canEdit} canRemove={canRemove} onEdit={onEdit} onRemove={onRemove} />
        }
      />

      <S.ContentWrapper>
        <Space size={8}>
          <Avatar size={55} shape="circle" src={logo}>
            {getAbbrCompany(legalName)}
          </Avatar>

          <Space direction="vertical" size={8}>
            <Text variant="normal-bold">{legalName}</Text>

            {/*<Space size={8}>*/}
            {/*  <FaStar size={16} color="#FFD300" />*/}
            {/*  <Text variant="normal" style={{ color: 'var(--gray-6-color)' }}>*/}
            {/*    {4.27}*/}
            {/*  </Text>*/}
            {/*</Space>*/}
          </Space>
        </Space>
        <Space size={4}>
          <ReadValue
            name="Роль компании"
            value={
              <Space>
                <Text variant="form-read-value">{companyType ? getCompanyRole(companyType) : null}</Text>
                <Tooltip
                  title={
                    <>
                      Роль компании.
                      <br />
                      Чтобы сменить ее, свяжитесь с нами по номеру +7 (962) 966-44-22
                    </>
                  }
                >
                  <BsFillQuestionSquareFill size={16} color="#CECECE" />
                </Tooltip>
              </Space>
            }
          />
          {/*<Button type="link">Изменить</Button>*/}
        </Space>
        <ReadValue name="Телефон компании" value={mobileNumber ? formatPhoneNumber(mobileNumber) : null} />
        <ReadValue
          name="Сайт компании"
          value={
            website ? (
              <a target="_blank" href={'https://' + website} rel="noreferrer">
                {website}
              </a>
            ) : null
          }
        />
        <ReadValue name="Дополнительная информация" value={additionalInfo} />
        <S.StyledDeleteButton onClick={onRemove} disabled={!canRemove}>
          <FiTrash2 size={18} />
          Удалить компанию
        </S.StyledDeleteButton>{' '}
      </S.ContentWrapper>
    </S.Wrapper>
  )
}
