import React, { useState } from 'react'

import { Modal } from 'antd'
import { observer } from 'mobx-react-lite'

import { useMutation, useQuery } from '@apollo/client'
import { ApolloError } from '@apollo/client/errors'

import { userUiSettingsStore } from '~/store/userUiSettingsStore'
import { appToast } from '~/utils'
import { graphqlErrorHandler } from '~/utils/graphqlErrorHandler'
import {
  deleteCompanyMutation,
  updateCompanyGeneralInfoMutation,
  updateCompanyLogoMutation,
} from '~api/gql-mutations/company.mutation.graphql'
import { getCompanyDataQuery } from '~api/gql-query/company.query.graphql'
import { getMeCompaniesQuery } from '~api/gql-query/me.query.graphql'
import { useUploadFileAndGetId } from '~hooks/_utils'
import { useAuth } from '~providers/auth'

import { CompanyAccountInfo } from './components/CompanyAccountInfo'
import { CompanyAccountInfoEdit, CompanyAccountInfoEditValues } from './components/CompanyAccountInfoEdit'

export const CompanyAccountInfoPage: React.FC = observer(() => {
  const { isDemoUser, companyId } = useAuth()
  const currentCompany = useQuery(getCompanyDataQuery, {
    variables: { companyId },
    skip: !companyId,
  })
  const [updateCompany, updateCompanyState] = useMutation(updateCompanyGeneralInfoMutation)
  const [updateCompanyLogo, updateCompanyLogoState] = useMutation(updateCompanyLogoMutation)
  const [deleteCompany] = useMutation(deleteCompanyMutation, { refetchQueries: [getMeCompaniesQuery] })
  const uploadCompanyLogoAndGetIdFn = useUploadFileAndGetId()

  const [isEdit, setEdit] = useState(false)

  const companyData = currentCompany?.data?.company ?? null
  const canEdit = (companyData?.actions?.updateGeneralInfo || isDemoUser) ?? false
  const canRemove = (companyData?.actions?.delete || isDemoUser) ?? false

  const onEditHandler = () => {
    if (isDemoUser) {
      userUiSettingsStore.showDemoUserAuthModal()
      return
    }
    setEdit(true)
  }
  const onCloseEditHandler = () => setEdit(false)

  const onRemoveHandler = () => {
    if (isDemoUser) {
      userUiSettingsStore.showDemoUserAuthModal()
      return
    }
    Modal.confirm({
      title: 'Удалить компанию',
      content: 'Вы действительно хотите удалить компанию? Вся информация также будет удалена.',
      okText: 'Да, удалить',
      okButtonProps: { danger: true },
      cancelText: 'Отмена',
      async onOk() {
        if (!companyId) {
          return
        }
        try {
          await deleteCompany({ variables: { companyId } })
        } catch (e) {
          if (e instanceof ApolloError) {
            if (e.message === 'Cannot delete company with activity') {
              Modal.info({
                title: 'Что-то пошло не так',
                content:
                  'Не получилось удалить Компанию.  Снимите с публикации все объявления и отклики, завершите активные сделки и попробуйте еще раз.',
              })
              return
            }
            graphqlErrorHandler(e, 'Ошибка при удалении компании')
          }
        }
      },
    })
  }

  const onSubmit = async (formValues: CompanyAccountInfoEditValues) => {
    const companyId = companyData?.id
    if (!companyId) {
      return
    }
    try {
      if (formValues.avatarFile || formValues.avatarFile === null) {
        const logoFileKey =
          formValues.avatarFile === null
            ? undefined
            : await uploadCompanyLogoAndGetIdFn.mutateAsync(formValues.avatarFile)
        await updateCompanyLogo({ variables: { companyId, input: { logoFileKey } } })
      }

      await updateCompany({
        variables: {
          companyId,
          input: {
            phoneNumber: formValues.mobileNumber,
            additionalInfo: formValues.additionalInfo,
            website: formValues.website,
          },
        },
      })
    } catch (e) {
      e instanceof ApolloError && graphqlErrorHandler(e, 'Ошибка при изменении данных о компании')
    }
    appToast.success({ message: 'Данные о компании изменены' })
    setEdit(false)
    await currentCompany.refetch()
  }

  return isEdit ? (
    <CompanyAccountInfoEdit
      onSubmit={onSubmit}
      onBack={onCloseEditHandler}
      loading={updateCompanyState.loading || updateCompanyLogoState.loading}
      defaultValues={{
        mobileNumber: companyData?.phoneNumber ?? undefined,
        website: companyData?.website ?? undefined,
        additionalInfo: companyData?.additionalInfo ?? undefined,
        logoUrl: companyData?.logoUrl ?? undefined,
      }}
    />
  ) : (
    <CompanyAccountInfo
      canEdit={canEdit}
      canRemove={canRemove}
      onEdit={onEditHandler}
      onRemove={onRemoveHandler}
      loading={currentCompany.loading}
      legalName={companyData?.legalName}
      logo={companyData?.logoUrl ?? undefined}
      mobileNumber={companyData?.phoneNumber ?? undefined}
      website={companyData?.website ?? undefined}
      additionalInfo={companyData?.additionalInfo ?? undefined}
      companyType={companyData?.type ?? undefined}
    />
  )
})
