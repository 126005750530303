import React from 'react'

import { Flex, Modal } from 'antd'
import { observer } from 'mobx-react-lite'
import { FiChevronLeft } from 'react-icons/fi'

import { useMutation, useQuery } from '@apollo/client'

import { appRoutes } from '~/router'
import { userUiSettingsStore } from '~/store/userUiSettingsStore'
import { appToast } from '~/utils'
import { graphqlErrorHandler } from '~/utils/graphqlErrorHandler'
import { logOutUserReturnModeratorMutation } from '~api/gql-mutations/auth.mutation.graphql'
import { getMeCompaniesQuery, getMeQuery } from '~api/gql-query/me.query.graphql'

import * as S from './LoginAsAdminBack.styled'

export const LoginAsAdminBack: React.FC = observer(() => {
  const [logOutUserReturnModerator, logOutUserReturnModeratorState] = useMutation(logOutUserReturnModeratorMutation)
  const getMe = useQuery(getMeQuery)
  const getCompanies = useQuery(getMeCompaniesQuery)

  const onLogOutUserReturnModerator = async () => {
    await logOutUserReturnModerator({ onError: (e) => graphqlErrorHandler(e) })

    await new Promise((r) => setTimeout(r, 2000))
    await window.location.replace(appRoutes.accountProfile.url)
    await getMe.refetch()
    await getCompanies.refetch()
    appToast.success({
      description: 'Вы вернулись к работе от лица администратора',
    })
  }

  const onClickHandler = async () => {
    Modal.confirm({
      title: 'Вернуться к администрированию',
      content: 'Вы закончите работать от лица текущего пользователя и вернетесь обратно в свой аккаунт администратора',
      okText: 'Да',
      okButtonProps: { loading: logOutUserReturnModeratorState.loading },
      cancelText: 'Отмена',
      onOk: onLogOutUserReturnModerator,
    })
  }

  if (getMe.loading || !getMe.data?.me.moderator) {
    return null
  }

  return (
    <S.Wrapper onClick={onClickHandler}>
      <FiChevronLeft size={32} color="#1677ff" />
      <Flex vertical gap={10}>
        <S.Title>Вернуться к администрированию</S.Title>
        <S.Message>Вы подключены к компании от лица пользователя</S.Message>
      </Flex>
    </S.Wrapper>
  )
})
