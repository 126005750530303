import { useQuery, UseQueryResult } from '@tanstack/react-query'

import { isApiResponseEmpty } from '~/utils/isApiResponseEmpty'
import { CompanyData } from '~api/generated'
import { sharedCompanyQueryOptions } from '~hooks/company/_sharedCompanyQueryOptions'
import { useApi } from '~providers/api'

export const useGetCompanyById = (companyId: number | undefined | null): UseQueryResult<CompanyData | null> => {
  const api = useApi()

  const fetchFn = async (): Promise<CompanyData | null> => {
    if (!companyId) {
      return null
    }

    try {
      const res = await api.companies.companyControllerGetCompanies({ ids: [companyId] })
      return res.data.companies?.[0]
    } catch (error) {
      if (isApiResponseEmpty(error)) {
        return null
      }

      return Promise.reject(error)
    }
  }

  return useQuery(['fetchCompany', companyId], fetchFn, { ...sharedCompanyQueryOptions, enabled: !!companyId })
}
