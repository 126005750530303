import React, { useState } from 'react'

import { Button, Flex, Modal, Skeleton } from 'antd'
import { observer } from 'mobx-react-lite'
import { useParams } from 'react-router'

import { useGetProductTypeData } from '~/hooks'
import { AppHeader } from '~/layout'
import { appRoutes } from '~/router'
import { Badge, Card, Divider, Text, UploadButton } from '~/ui-components'
import { appToast, httpErrorHandler } from '~/utils'
import { getElevatorAdvertStatus } from '~/utils/elevator-adverts/get-elevator-advert-status'
import { ElevatorOfferResponseStatus } from '~api/generated'
import { useGetElevatorAdvert } from '~hooks/elevator-advert'
import { useAcceptAdvertOffer, useGetElevatorAdvertOffer, useRejectAdvertOffer } from '~hooks/elevator-advert-offers'
import { BasisAdvertOfferChangeStatusButton } from '~pages/BasisPage/components/BasisAdvertOfferChangeStatusButton'
import { BasisAdvertOfferRejectReasonModal } from '~pages/BasisPage/components/BasisAdvertOfferRejectReasonModal'
import { CreateAdvertOfferAgreementStep } from '~pages/BasisPage/components/CreateAdvertBuyOfferModal/CreateAdvertOfferAgreementStep'
import { MyAdvertOfferAcceptManualStep } from '~pages/BasisPage/modules/MyAdvertOffers/components/MyAdvertOffersList/components/MyAdvertOfferAcceptManualStep'
import { useAuth } from '~providers/auth'
import { PhoneNumber } from '~shared/components'

import { RejectAdvertOfferForm } from '~pages/BasisPage/components/BasisAdvertOfferRejectReasonModal/BasisAdvertOfferRejectReasonModal.types'

import * as S from './MyAdvertOffersListItemDesktop.styled'

export const MyAdvertOffersListItemDesktop: React.FC = observer(() => {
  const { getProductTypeParametersForUI_V2 } = useGetProductTypeData()
  const urlParams = useParams<{ advertId: string | undefined; id: string | undefined }>()

  const advertId = Number(urlParams.advertId)
  const offerId = Number(urlParams.id)
  const { companyId } = useAuth()

  const offerFn = useGetElevatorAdvertOffer({ id: offerId, userCompanyId: companyId })
  const advertFn = useGetElevatorAdvert({ id: advertId, userCompanyId: companyId })

  const offer = offerFn.data
  const advert = advertFn.data

  const [isOpenRejectOfferModal, setOpenRejectOfferModal] = useState(false)
  const acceptAdvertOfferFn = useAcceptAdvertOffer()
  const rejectAdvertOfferFn = useRejectAdvertOffer()

  const handleAcceptOffer = async () => {
    Modal.confirm({
      title: 'Принятие отклика',
      content: (
        <div style={{ marginLeft: '-32px' }}>
          {advert?.type === 'SELL' ? <MyAdvertOfferAcceptManualStep /> : <CreateAdvertOfferAgreementStep />}
        </div>
      ),
      okText: 'Принять отклик',
      cancelText: 'Отмена',
      width: 600,
      async onOk() {
        try {
          await acceptAdvertOfferFn.mutateAsync({
            id: offerId,
            updateElevatorOfferResponseAcceptBody: { userCompanyId: companyId },
          })
          await offerFn.refetch()
          appToast.success({ description: 'Отклик принят' })
        } catch (e) {
          httpErrorHandler(e, 'Ошибка при принятии отклика')
        }
      },
    })
  }

  const handleDeclineOffer = async (declineReason: RejectAdvertOfferForm) => {
    try {
      await rejectAdvertOfferFn.mutateAsync({
        id: offerId,
        updateElevatorAdvertOfferRejectBody: {
          userCompanyId: companyId,
          rejectionReason: declineReason.reason,
          rejectionComment: declineReason.message,
        },
      })
      await offerFn.refetch()
      appToast.success({ description: 'Отклик отклонен' })
    } catch (e) {
      httpErrorHandler(e, 'Ошибка при отклонении отклика')
    }
  }

  if (offerFn.isLoading || advertFn.isLoading) {
    return (
      <S.PageWrapper>
        <AppHeader title={appRoutes.basisMyAdvertResponse.title + offerId} isBack />
        <Skeleton />
      </S.PageWrapper>
    )
  }

  function renderContactDataOfSeller() {
    if (offer?.status === ElevatorOfferResponseStatus.Accepted) {
      return (
        <Flex vertical gap={16}>
          <Text variant="h3">Данные продавца</Text>
          <S.CompanyInfo>
            <Card.Label>Компания</Card.Label>
            <Card.Label>{offer?.contactCompany?.name ?? '-'}</Card.Label>

            <Card.Label>ИНН</Card.Label>
            <Card.Label>{offer?.contactCompany?.inn ?? '-'}</Card.Label>

            <Card.Label>Контактное лицо</Card.Label>
            <Card.Label>{offer?.contactPerson?.name ?? '-'}</Card.Label>

            <Card.Label>Телефон</Card.Label>
            <Card.Label>
              <PhoneNumber phoneNumber={offer?.contactPerson?.phoneNumber} prefix="" />
            </Card.Label>

            <Card.Label>Адрес</Card.Label>
            <Card.Label>
              <PhoneNumber phoneNumber={offer?.address} prefix="" />
            </Card.Label>
          </S.CompanyInfo>

          <Text variant="form-read-label">Вы приняли ставку. Теперь вам доступны контактные данные продавца.</Text>
          <Divider />
        </Flex>
      )
    }
  }

  function renderAdvertData() {
    if (!advert) {
      return null
    }

    return (
      <Flex vertical gap={16}>
        <Text variant="normal">Базис: {advert.elevator.name}</Text>

        <Flex vertical gap={8}>
          <Card.Title>{advert.product.name}</Card.Title>
          <div>
            <Badge color={getElevatorAdvertStatus(advert.status).color} size="s">
              {getElevatorAdvertStatus(advert.status).name}
            </Badge>
          </div>
        </Flex>

        <Flex gap={8} align="center">
          <Card.Label>Цена</Card.Label> {advert.pricePerTon && <S.Price>{advert.pricePerTon} ₽/т</S.Price>}
        </Flex>
        <Flex gap={8}>
          <Card.Label>Объем</Card.Label> {advert.volume && <S.VolumeDesktop>{advert.volume} т</S.VolumeDesktop>}
        </Flex>

        <Card.Title>Показатели</Card.Title>

        <S.Parameters>
          {getProductTypeParametersForUI_V2(advert.product).map((param) => (
            <Badge color="gray" isRound key={param}>
              {param}
            </Badge>
          ))}
        </S.Parameters>

        {offer?.laboratoryAnalysis && (
          <UploadButton
            fileName="Лаб. анализ"
            isReadOnly
            onFileClick={() => window.open(offer.laboratoryAnalysis?.source, '_blank')}
          />
        )}
      </Flex>
    )
  }

  function renderActions() {
    if (offer?.status === ElevatorOfferResponseStatus.UnderConsideration) {
      return (
        <S.ActionWrapper>
          <Text variant="form-read-label" style={{ flex: 1 }}>
            Вы можете принять решение по этой ставке в течение 24 часов. По истечению срока мы направим эту ставку
            другому покупателю.
          </Text>

          <Button htmlType="button" onClick={() => setOpenRejectOfferModal(true)}>
            Отклонить
          </Button>

          <Button htmlType="button" type="primary" onClick={handleAcceptOffer}>
            Принять
          </Button>
        </S.ActionWrapper>
      )
    }
  }

  function renderStatusMessage() {
    if (offer?.status === ElevatorOfferResponseStatus.UnderConsideration) {
      return (
        <Flex vertical gap={8}>
          <Text variant="form-read-label">
            Если вам подходит отклик, то нажмите кнопку “Принять”, после чего мы покажем данные продавца.
          </Text>
          <Divider />
        </Flex>
      )
    }
  }

  return (
    <S.PageWrapper>
      <AppHeader title={appRoutes.basisMyAdvertResponse.title + offerId} isBack />

      <Card style={{ margin: '0 32px' }}>
        <S.ContentWrapper>
          {renderStatusMessage()}
          {renderContactDataOfSeller()}
          {renderAdvertData()}
        </S.ContentWrapper>
      </Card>

      {renderActions()}

      {offer && (
        <div style={{ margin: '0 32px' }}>
          <BasisAdvertOfferChangeStatusButton
            offerId={offerId}
            offerStatus={offer.status}
            type="SELL"
            myPurchaseStatus={offer.myPurchaseStatus}
            counterpartyPurchaseStatus={offer.counterpartyPurchaseStatus}
            onAfterStatusChange={() => offerFn.refetch()}
          />
        </div>
      )}

      <BasisAdvertOfferRejectReasonModal
        isOpen={isOpenRejectOfferModal}
        onReject={handleDeclineOffer}
        onClose={() => setOpenRejectOfferModal(false)}
      />
    </S.PageWrapper>
  )

  //
  //
  // return (
  //   <Card key={offer.id}>
  //     <S.CardHeader>
  //       <S.CardHeaderTop>
  //         <S.CardHeaderTopLeft>
  //           <S.TitleOfferNumber>ОтклASDASDASDик №{offer.id}</S.TitleOfferNumber>
  //           <S.PublishedAt>{offer.publishedAt ? getDateFormat(offer.publishedAt) : null}</S.PublishedAt>
  //         </S.CardHeaderTopLeft>
  //         {/*<FiArrowRight size={24} />*/}
  //       </S.CardHeaderTop>
  //       <S.ProductWrapper>
  //         <S.ProductRowWrapper>
  //           <S.PriceWrapper>
  //             <Text variant="h4">{offer.price} ₽/т</Text>
  //             <Text variant="normal">{offer.includingNds ? 'с НДС' : 'без НДС'}</Text>
  //           </S.PriceWrapper>
  //           <S.Volume>{offer.volume} т</S.Volume>
  //         </S.ProductRowWrapper>
  //         <div>
  //           <Badge color={getElevatorAdvertOfferStatus(offer.status).color} size="s">
  //             {getElevatorAdvertOfferStatus(offer.status).name}
  //           </Badge>
  //         </div>
  //         <Text variant="normal" style={{ marginTop: 4 }}>
  //           {offer.company?.name}
  //         </Text>
  //       </S.ProductWrapper>
  //
  //       {/*<Text variant="normal">Базис: {offer.}</Text>*/}
  //
  //       {offer.status === 'UNDER_CONSIDERATION' && (
  //         <S.ActionWrapper>
  //           <Button block htmlType="button" onClick={() => setOpenRejectOfferModal(true)}>
  //             Отклонить
  //           </Button>
  //
  //           <Button block htmlType="button" type="primary" onClick={handleAcceptOffer}>
  //             Принять
  //           </Button>
  //         </S.ActionWrapper>
  //       )}
  //
  //       <BasisAdvertOfferChangeStatusButton
  //         offerId={offer.id}
  //         offerStatus={offer.status}
  //         type="SELL"
  //         myPurchaseStatus={offer.myPurchaseStatus}
  //         counterpartyPurchaseStatus={offer.counterpartyPurchaseStatus}
  //         onAfterStatusChange={() => onNeedToRefetch()}
  //       />
  //

  //       )}
  //     </S.CardHeader>
  //
  //   </Card>
  // )
})
