import styled from 'styled-components'

import { Text } from '../Text'

export const Name = styled(Text).attrs({ variant: 'normal-bold' })``

export const DocumentCard = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: space-between;
  min-height: 112px;
  padding: 16px;
  border-radius: 16px;
  box-shadow: 0 1px 4px rgba(84, 84, 84, 0.15);
  background: #fff;
  cursor: pointer;
`

export const Header = styled.div`
  display: flex;
  gap: 16px;
  justify-content: space-between;
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

export const Actions = styled.div`
  display: flex;
  gap: 8px;
  color: var(--gray-8-color);
`

export const CountText = styled(Text).attrs({ variant: 'normal' })``
export const Message = styled(Text).attrs({ variant: 'normal' })`
  display: flex;
  gap: 8px;
  color: var(--gray-6-color);

  svg {
    flex-shrink: 0;
  }
`
