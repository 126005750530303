import React from 'react'

import type { RadioChangeEvent } from 'antd'
import { Space } from 'antd'

import { AppConfig } from '~/appConfig'
import { getCompanyRole } from '~/utils/company/get-company-role-old'
import { CompanyType } from '~api/gql-generated/graphql'
import { CustomControlInterface } from '~shared/controls/CustomControl.interface'

import * as S from './CompanyRoleControl.styled'
import { StyledRadio } from './CompanyRoleControl.styled'

interface ICompanyRoleControl extends CustomControlInterface {
  onChange: (value: string) => void
  value: CompanyType | undefined
  isAdmin: boolean
}

export const CompanyRoleControl: React.FC<ICompanyRoleControl> = ({ onChange, value, isAdmin }) => {
  const onChangeHandler = (e: RadioChangeEvent) => {
    onChange(e.target.value)
  }

  return (
    <StyledRadio.Group onChange={onChangeHandler} value={value}>
      <Space direction="vertical" size="large">
        <StyledRadio value={CompanyType.Seller} className="kek">
          {getCompanyRole(CompanyType.Seller)}
          <S.Description>Продажа культур и поиск продавцов</S.Description>
        </StyledRadio>
        <StyledRadio value={CompanyType.Buyer}>
          {getCompanyRole(CompanyType.Buyer)}
          <S.Description>Поиск и покупка культур</S.Description>
        </StyledRadio>
        <StyledRadio value={CompanyType.Trader} disabled={!isAdmin}>
          {getCompanyRole(CompanyType.Trader)}

          <S.Description>
            {/*Трейдера может назначить только администратор. Свяжитесь с нами по телефону{' '}*/}
            {/*<a href={`tel:${AppConfig.ADMIN_CONTACT_PHONE}`}>{AppConfig.ADMIN_CONTACT_PHONE}</a> для уточнения деталей*/}
            Трейдера может назначить только администратор. Свяжитесь с нами{' '}
            <a href={`mailto:${AppConfig.ADMIN_CONTACT_EMAIL}`}>{AppConfig.ADMIN_CONTACT_EMAIL}</a> для уточнения
            деталей
          </S.Description>
        </StyledRadio>
        <StyledRadio value={CompanyType.Carrier}>
          {getCompanyRole(CompanyType.Carrier)}
          <S.Description>Поиск грузов для перевозки</S.Description>
        </StyledRadio>
      </Space>
    </StyledRadio.Group>
  )
}
