import React from 'react'

import { Button, Input, Select } from 'antd'
import { Controller, useForm } from 'react-hook-form'

import { yupResolver } from '@hookform/resolvers/yup'

import { FormField, FormFooter } from '~/ui-components'
import { getDealDocumentPaymentList } from '~/utils/deals/get-deal-document-payment-list'
import { useGetCurrentRole } from '~hooks/auth'
import { DatePickerControl, UploadDocumentsControl } from '~shared/controls'

import { dealPaymentFormSchema } from './DealPaymentForm.validation'

import {
  IDealPaymentForm,
  IDealPaymentFormProps,
} from '~pages/Deals/modules/DealPayment/components/DealPaymentForm/DealPaymentForm.types'

export const DealPaymentForm: React.FC<IDealPaymentFormProps> = ({ onSubmit, defaultValues, isLoading, dealSide }) => {
  const {
    user: { isAdmin },
  } = useGetCurrentRole()
  const {
    control,
    handleSubmit,
    formState: { errors, isDirty },
  } = useForm<IDealPaymentForm>({
    defaultValues: {
      documentType: defaultValues?.documentType ?? undefined,
      documentNumber: defaultValues?.documentNumber ?? undefined,
      documentDate: defaultValues?.documentDate ?? undefined,
      documentFiles: undefined,
    },
    resolver: yupResolver(dealPaymentFormSchema),
  })
  const onSubmitHandler = (data: IDealPaymentForm) => onSubmit(data)

  return (
    <form onSubmit={handleSubmit(onSubmitHandler)}>
      <FormField label="Тип документа" isRequired htmlFor="documentType" validateMessage={errors.documentType?.message}>
        <Controller
          name="documentType"
          control={control}
          render={({ field }) => (
            <Select
              style={{ width: '100%' }}
              defaultValue={field.value}
              placeholder="Тип документа"
              onChange={(e) => field.onChange(e)}
            >
              {getDealDocumentPaymentList(dealSide, isAdmin).map((option) => (
                <Select.Option key={option.value} value={option.value}>
                  {option.text}
                </Select.Option>
              ))}
            </Select>
          )}
        />
      </FormField>

      <FormField
        label="Порядковый номер"
        isRequired
        htmlFor="documentNumber"
        validateMessage={errors.documentNumber?.message}
      >
        <Controller
          name="documentNumber"
          control={control}
          render={({ field }) => (
            <Input
              {...field}
              id="documentNumber"
              placeholder="Введите номер"
              status={errors.documentNumber && 'error'}
            />
          )}
        />
      </FormField>

      <FormField
        label="Дата формирования документа"
        isRequired
        htmlFor="documentDate"
        validateMessage={errors.documentDate?.message}
      >
        <Controller
          name="documentDate"
          control={control}
          render={({ field }) => (
            <DatePickerControl worksWith="isoString" id="documentDate" {...field} hasError={!!errors.documentDate} />
          )}
        />
      </FormField>
      <FormField isRequired validateMessage={errors.documentFiles?.message}>
        <Controller
          control={control}
          name="documentFiles"
          render={({ field }) => <UploadDocumentsControl {...field} />}
        />
      </FormField>

      <FormFooter>
        <Button htmlType="submit" type="primary" disabled={!isDirty || isLoading}>
          {isLoading ? 'Сохранение' : 'Сохранить'}
        </Button>
      </FormFooter>
    </form>
  )
}
