import styled from 'styled-components'

import { Text } from '~/ui-components'

export const Card = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 16px;
  gap: 16px;
  background: #ffffff;
  box-shadow: 0 1px 4px rgba(84, 84, 84, 0.15);
  border-radius: 16px;
`

export const Row = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 4px;
`

export const RowVertical = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
`

export const Label = styled(Text).attrs({ variant: 'normal' })`
  flex-shrink: 0;
  color: var(--gray-6-color);
`

export const Value = styled(Text).attrs({ variant: 'normal' })`
  flex-shrink: 0;
  color: var(--gray-8-color);
`

export const ValueBold = styled(Value).attrs({ variant: 'normal-bold' })`
  color: var(--gray-8-color);
`

export const Icon = styled.div`
  position: absolute;
  top: 16px;
  right: 16px;
  color: var(--primary-main-color);
`
