import React, { useState } from 'react'

import { Button } from 'antd'
import { observer } from 'mobx-react-lite'
import { FiFilter } from 'react-icons/fi'

import { useQuery } from '@apollo/client'

import { findCargoFilterStore, myCargoFilterStore } from '~/store/filters'
import { CargoFilterStore, logisticDealsFilterStore } from '~/store/filters/myCargoFilterStore'
import { graphqlErrorHandler } from '~/utils/graphqlErrorHandler'
import { FreightDealFilterInput, FreightOrderFilterInput } from '~api/gql-generated/graphql'
import { freightDealsQuery } from '~api/gql-query/freight-deals.query.graphql'
import { freightOrdersQuery } from '~api/gql-query/freight-orders.query.graphql'
import { useGetCurrentRole } from '~hooks/auth'
import { CargoFiltersForm, CargoFiltersFormType } from '~pages/LogisticsPage/shared/CargoFilters/CargoFiltersForm'
import { useAuth } from '~providers/auth'

import * as S from './CargoFilters.styled'

type StoreType = 'findCargoFilterStore' | 'myCargoFilterStore' | 'logisticDealsFilterStore'

interface CargoFiltersProps {
  store: StoreType
}

function getStore(store: StoreType): CargoFilterStore {
  switch (store) {
    case 'findCargoFilterStore':
      return findCargoFilterStore
    case 'myCargoFilterStore':
      return myCargoFilterStore
    case 'logisticDealsFilterStore':
      return logisticDealsFilterStore
  }
}

export const CargoFilters: React.FC<CargoFiltersProps> = observer((props) => {
  const { companyId } = useAuth()
  const {
    company: { isCarrier },
  } = useGetCurrentRole()
  const [isVisible, setVisible] = useState(false)
  const [foundResults, setFoundResults] = useState<number | null>(null)
  const [isLoading, setLoading] = useState(false)
  const store = getStore(props.store)

  const freightOrdersFn = useQuery(freightOrdersQuery, {
    onError: graphqlErrorHandler,
    fetchPolicy: 'cache-and-network',
    skip: true,
  })

  const freightDealsFn = useQuery(freightDealsQuery, {
    onError: graphqlErrorHandler,
    fetchPolicy: 'cache-and-network',
    skip: true,
  })

  const onOpenHandler = () => setVisible(true)
  const onCloseHandler = () => setVisible(false)
  const onResetToDefaultValuesHandler = () => setFoundResults(null)

  const onApplyFilters = (values: CargoFiltersFormType) => {
    onCloseHandler()
    store.setFilters(values)
  }

  const onChangeFilters = async (values: CargoFiltersFormType): Promise<void> => {
    setLoading(true)

    switch (props.store) {
      case 'logisticDealsFilterStore': {
        const filter: FreightDealFilterInput = {
          carrierIds: isCarrier ? [companyId] : undefined,
          customerIds: !isCarrier ? [companyId] : undefined,
          weightKgFrom: values.weightKgFrom ? values.weightKgFrom * 1000 : undefined,
          weightKgTo: values.weightKgTo ? values.weightKgTo * 1000 : undefined,
          pricePerTonFrom: values.pricePerTonFrom,
          pricePerTonTo: values.pricePerTonTo,
          priceWithNds: values.priceWithNds,
          fromRegionIds: values.fromRegionId ? [values.fromRegionId] : undefined,
          toRegionIds: values.toRegionId ? [values.toRegionId] : undefined,
          paymentTerm: values.paymentTerm,
          startedAtFrom: values.period?.length ? values.period[0] : undefined,
          startedAtTo: values.period?.length ? values.period[1] : undefined,
          productTypeIds: values.productTypeId ? [values.productTypeId] : undefined,
        }

        const resDeals = await freightDealsFn.refetch({ filter, pagination: { pageSize: 1 } })
        const foundResults = resDeals.data.freightDeals.pagination.total
        setFoundResults(foundResults)
        break
      }
      case 'findCargoFilterStore':
      case 'myCargoFilterStore': {
        const filter: FreightOrderFilterInput = {
          companyIds: props.store === 'myCargoFilterStore' ? [companyId] : undefined,
          weightKgFrom: values.weightKgFrom ? values.weightKgFrom * 1000 : undefined,
          weightKgTo: values.weightKgTo ? values.weightKgTo * 1000 : undefined,
          pricePerTonFrom: values.pricePerTonFrom,
          pricePerTonTo: values.pricePerTonTo,
          priceWithNds: values.priceWithNds,
          fromRegionIds: values.fromRegionId ? [values.fromRegionId] : undefined,
          toRegionIds: values.toRegionId ? [values.toRegionId] : undefined,
          paymentTerm: values.paymentTerm,
          startedAtFrom: values.period?.length ? values.period[0] : undefined,
          startedAtTo: values.period?.length ? values.period[1] : undefined,
          productTypeIds: values.productTypeId ? [values.productTypeId] : undefined,
        }

        const resFreightOrders = await freightOrdersFn.refetch({ filter, pagination: { pageSize: 1 } })
        const foundResults = resFreightOrders.data.freightOrders.pagination.total
        setFoundResults(foundResults)
        break
      }
    }
    setLoading(false)
  }

  return (
    <>
      <Button icon={<FiFilter />} onClick={onOpenHandler}>
        Фильтры
        {store.hasActiveFilters ? (
          <S.ActiveFilterCount variant="normal-bold">({store.activeFiltersCount})</S.ActiveFilterCount>
        ) : null}
      </Button>

      <S.StyledDrawer
        destroyOnClose
        className="offers-filter-menu"
        title="Фильтры"
        placement="right"
        onClose={onCloseHandler}
        open={isVisible}
      >
        <CargoFiltersForm
          loading={isLoading}
          foundResults={foundResults}
          onSubmit={onApplyFilters}
          onChange={onChangeFilters}
          currentValues={store.filters}
          defaultValues={store.defaultFiltersConfig}
          onResetToDefaultValues={onResetToDefaultValuesHandler}
        />
      </S.StyledDrawer>
    </>
  )
})
