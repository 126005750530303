import React from 'react'
import { FormField, Text } from '~/ui-components'
import { TransportTypeRadioControl } from '~shared/controls/TransportTypeRadioControl'
import { Controller } from 'react-hook-form'
import { CreateTransportFormFieldProps } from '~pages/CarParkPage/modules/CreateTransport/CreateTransport.types'
import { TransportType } from '~api/gql-generated/graphql'

export const CreateTransportTypeFields: React.FC<CreateTransportFormFieldProps> = ({ control, errors }) => {
  return (
    <>
      <Text variant="h1">Тип транспорта</Text>

      <FormField validateMessage={errors.type?.message}>
        <Controller
          name="type"
          control={control}
          defaultValue={TransportType.Truck}
          render={({ field }) => <TransportTypeRadioControl {...field} />}
        />
      </FormField>
    </>
  )
}
