import React, { FC, useState } from 'react'

import { Button } from 'antd'
import { observer } from 'mobx-react-lite'
import { FiMessageCircle } from 'react-icons/fi'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

import { useGetProductTypeData } from '~/hooks'
import { appRoutes } from '~/router'
import { userUiSettingsStore } from '~/store/userUiSettingsStore'
import { getOfferStatus } from '~/utils/offers/get-offer-status'
import { getOfferType } from '~/utils/offers/get-offer-type'
import { UpdateOfferBody } from '~api/generated'
import { useGetCurrentRole } from '~hooks/auth'
import { useGetOfferById, useUpdateOfferPriceAndVolume } from '~hooks/offer'
import { ChangeOfferModal } from '~pages/OffersPage/components/ChangeOfferModal'
import { Comments } from '~pages/OffersPage/components/Comments'
import { OfferActionMenu } from '~pages/OffersPage/components/OfferCard/components'
import { OfferDetailsSpin } from '~pages/OffersPage/components/OfferDetailsSpin'
import { useAuth } from '~providers/auth'

import { OfferCardDetails } from '../../components/OfferCardDetails'

import * as S from '../../OffersPage.styled'

export const AcceptedOffersDetails: FC = observer(() => {
  const { companyId } = useAuth()

  const navigate = useNavigate()
  const { id } = useParams<{ id: string | undefined }>()
  const location = useLocation()
  const [isVisibleComments, setVisibleComments] = useState(false)
  const { getProductTypeParametersForUI_V2 } = useGetProductTypeData()
  const [isChangeModalVisible, setChangeModalVisible] = useState(false)
  const {
    user: { isAdmin },
  } = useGetCurrentRole()
  const updateOfferPriceAndVolume = useUpdateOfferPriceAndVolume()

  const {
    data: offer,
    isLoading: isFetchingOffer,
    refetch: refetchOffer,
  } = useGetOfferById({ offerId: Number(id), userCompanyId: companyId })

  if (isFetchingOffer) {
    return <OfferDetailsSpin />
  }

  const handleBack = () => {
    navigate({
      pathname: appRoutes.offersResponses.url,
      search: location.search,
    })
  }

  const handleDealClick = async () => {
    navigate(`/deals/${offer?.deal?.id}/information`)
  }

  const handleChangePriceAndVolume = async (updateOfferBody: UpdateOfferBody) => {
    if (!offer?.id) return
    await updateOfferPriceAndVolume.mutateAsync({
      offerId: offer?.id,
      updateOfferBody,
    })
    await refetchOffer()
    setChangeModalVisible(false)
    handleBack()
  }

  if (!offer) return null

  return (
    <>
      <S.DetailsWrapper>
        <OfferCardDetails
          title={offer.product.name}
          actions={
            <OfferActionMenu
              status={offer.status}
              offerId={offer.id}
              onSuccess={handleBack}
              onChangePriceAndVolumeClick={() => setChangeModalVisible(true)}
            />
          }
          statuses={isAdmin ? [getOfferType(offer.type), getOfferStatus(offer.status)] : [getOfferStatus(offer.status)]}
          parameters={getProductTypeParametersForUI_V2(offer.product)}
          laboratoryAnalysisSource={offer.laboratoryAnalysis?.source}
          volume={offer.volume}
          price={offer.price}
          includingNds={offer.includingNds}
          address={offer.address}
          distance={offer.distance}
          shippingRequired={offer.shippingRequired}
          contactPersonName={offer.contactPerson?.name}
          contactPersonPhone={offer.contactPerson?.phoneNumber}
          acceptedAt={offer.deal?.acceptedAt}
          note={offer.note}
          company={offer.company ?? undefined}
          type={offer.type}
          isParametersCorrespondsToGost={offer.product.parametersIsCorrespondsToGost}
          gost={offer.product.gost}
        />
        <S.Actions>
          {/*{isAdmin && (*/}
          {/*  <Button*/}
          {/*    onClick={() => setVisibleComments(true)}*/}
          {/*    style={{ marginRight: 'auto' }}*/}
          {/*    type="link"*/}
          {/*    icon={<FiMessageCircle />}*/}
          {/*  >*/}
          {/*    Комментарии*/}
          {/*  </Button>*/}
          {/*)}*/}
          {offer.deal?.id && (
            <Button type="primary" onClick={handleDealClick}>
              Перейти к сделке
            </Button>
          )}
        </S.Actions>

        {/*<Comments offerId={offer.id} visible={isVisibleComments} onClose={() => setVisibleComments(false)} />*/}
      </S.DetailsWrapper>

      {isChangeModalVisible && (
        <ChangeOfferModal
          defaultValues={{
            price: isAdmin ? offer.priceDetail?.originalPrice : offer.price,
            volume: offer.volume,
          }}
          visible={isChangeModalVisible}
          onClose={() => setChangeModalVisible(false)}
          onSubmit={(formValues) => handleChangePriceAndVolume({ ...formValues, userCompanyId: companyId })}
        />
      )}
    </>
  )
})
