import styled from 'styled-components'

import { breakpoints } from '~/tokens'
import { Text } from '~/ui-components'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  padding: 24px 24px 0 24px;
  border-bottom: 1px solid var(--gray-2-color);

  @media (max-width: ${breakpoints.MOBILE_XL}px) {
    padding: 16px;
  }
`

export const Header = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-bottom: 16px;
`

export const Title = styled(Text).attrs({ variant: 'h4' })`
  text-align: center;
`

export const Description = styled(Text).attrs({ variant: 'normal' })`
  margin-bottom: 32px;
  @media (max-width: ${breakpoints.MOBILE_XL}px) {
    margin-bottom: 16px;
  }
`
