import * as yup from 'yup'

import { VALIDATORS_MSG } from '~shared/validations'

const requiredTrimmedString = yup.string().trim().required(VALIDATORS_MSG.required)

const notRequiredTrimmedString = yup.string().trim().notRequired()

export const companyAccountRequisitesEditFormSchema = yup
  .object({
    legalName: requiredTrimmedString,
    inn: requiredTrimmedString,
    kpp: requiredTrimmedString,
    ogrn: requiredTrimmedString,
    okved: requiredTrimmedString,
    directorName: requiredTrimmedString,
    signatory: requiredTrimmedString,
    signatoryPosition: requiredTrimmedString,
    signatoryLegalBasis: requiredTrimmedString,
    actualAddress: requiredTrimmedString,
    legalAddress: requiredTrimmedString,
    aicCharterMember: yup.boolean(),
    useNds: yup.boolean().required(VALIDATORS_MSG.required),
  })
  .required()
