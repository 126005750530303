import { AxiosResponse } from 'axios'

import { QueryObserverSuccessResult, useQuery, UseQueryOptions } from '@tanstack/react-query'

import { GetRegionsResponse, RegionData } from '~/api/generated'
import { useApi } from '~providers/api'

type Options = Omit<UseQueryOptions<RegionData[], unknown>, 'queryKey' | 'queryFn'>

export const useGetRegions = (options?: Options) => {
  const api = useApi()

  const fetchFn = async (): Promise<RegionData[]> => {
    try {
      const res: AxiosResponse<GetRegionsResponse> = await api.products.getRegions()

      return res.data.regions
    } catch (error) {
      console.error({ description: 'Ошибка при загрузке списка регионов' })
      return []
    }
  }
  return useQuery<RegionData[]>(['fetchRegions'], fetchFn, {
    staleTime: Infinity,
    refetchInterval: 1000 * 60,
    retry: 3,
    retryDelay: 1000,
    ...options,
    // https://github.com/TanStack/query/discussions/1331
  }) as QueryObserverSuccessResult<RegionData[], never>
}
