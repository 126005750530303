import React from 'react'

import { Button, Input, InputNumber, Space } from 'antd'
import { Controller, useForm } from 'react-hook-form'

import { yupResolver } from '@hookform/resolvers/yup/dist/yup'

import { FormField, FormFooter, Text } from '~/ui-components'
import { inputSharedSettings } from '~/utils/inputSharedSettings'
import { createElevatorFormStep5ServicePriceSchema } from '~pages/SettingsPage/modules/ElevatorsAdmin/components/CreateElevatorForm/step5ServicePrice/CreateElevatorFormStep5ServicePriceSchema'
import { YesOrNotControl } from '~shared/controls'

import {
  CreateElevatorFormStep5ServicePriceProps,
  CreateElevatorFormStep5ServicePriceValues,
} from '~pages/SettingsPage/modules/ElevatorsAdmin/components/CreateElevatorForm/step5ServicePrice/CreateElevatorFormStep5ServicePrice.types'

export const CreateElevatorFormStep5ServicePrice: React.FC<CreateElevatorFormStep5ServicePriceProps> = ({
  step1Data,
  onNext,
  onCancel,
  onPrev,
  defaultValues,
  loading,
}) => {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<CreateElevatorFormStep5ServicePriceValues>({
    defaultValues: {
      usingNds: defaultValues?.usingNds,
      receptionPrice: defaultValues?.receptionPrice,
      dryingPrice: defaultValues?.dryingPrice,
      storingPrice: defaultValues?.storingPrice,
      mixingPrice: defaultValues?.mixingPrice,
      autoShipmentPrice: defaultValues?.autoShipmentPrice,
      trainShipmentPrice: defaultValues?.trainShipmentPrice,
      paperworkPrice: defaultValues?.paperworkPrice,
      cleaningPrice: defaultValues?.cleaningPrice,
      censusPrice: defaultValues?.censusPrice,
      otherServices: defaultValues?.otherServices,
    },
    resolver: yupResolver(createElevatorFormStep5ServicePriceSchema),
  })

  const onSubmitHandler = (formValues: CreateElevatorFormStep5ServicePriceValues) => {
    onNext(formValues)
  }

  return (
    <form onSubmit={handleSubmit(onSubmitHandler)}>
      <Space direction="vertical" size={6} style={{ marginBottom: 16 }}>
        <Text variant="normal-bold">{step1Data?.name}</Text>
        <Text variant="normal">{step1Data?.address}</Text>
      </Space>

      <FormField label="Цены с НДС?" validateMessage={errors.usingNds?.message}>
        <Controller name="usingNds" control={control} render={({ field }) => <YesOrNotControl {...field} />} />
      </FormField>

      <FormField htmlFor="receptionPrice" label="Приемка" validateMessage={errors.receptionPrice?.message}>
        <Controller
          name="receptionPrice"
          control={control}
          render={({ field }) => (
            <InputNumber
              {...field}
              {...inputSharedSettings}
              addonAfter="₽/т"
              style={{ width: '100%' }}
              id="receptionPrice"
              status={errors.receptionPrice && 'error'}
            />
          )}
        />
      </FormField>

      <FormField htmlFor="dryingPrice" label="Сушка" validateMessage={errors.dryingPrice?.message}>
        <Controller
          name="dryingPrice"
          control={control}
          render={({ field }) => (
            <InputNumber
              {...field}
              {...inputSharedSettings}
              addonAfter="₽/т"
              style={{ width: '100%' }}
              id="dryingPrice"
              status={errors.dryingPrice && 'error'}
            />
          )}
        />
      </FormField>

      <FormField
        htmlFor="cleaningPrice"
        label="Очистка от сорной примеси"
        validateMessage={errors.cleaningPrice?.message}
      >
        <Controller
          name="cleaningPrice"
          control={control}
          render={({ field }) => (
            <InputNumber
              {...field}
              {...inputSharedSettings}
              addonAfter="₽/т"
              style={{ width: '100%' }}
              id="cleaningPrice"
              status={errors.cleaningPrice && 'error'}
            />
          )}
        />
      </FormField>

      <FormField htmlFor="storingPrice" label="Хранение" validateMessage={errors.storingPrice?.message}>
        <Controller
          name="storingPrice"
          control={control}
          render={({ field }) => (
            <InputNumber
              {...field}
              {...inputSharedSettings}
              addonAfter="₽/т/день"
              style={{ width: '100%' }}
              id="storingPrice"
              status={errors.storingPrice && 'error'}
            />
          )}
        />
      </FormField>

      <FormField htmlFor="mixingPrice" label="Смешение партий/улучшение" validateMessage={errors.mixingPrice?.message}>
        <Controller
          name="mixingPrice"
          control={control}
          render={({ field }) => (
            <InputNumber
              {...field}
              {...inputSharedSettings}
              addonAfter="₽/т"
              style={{ width: '100%' }}
              id="mixingPrice"
              status={errors.mixingPrice && 'error'}
            />
          )}
        />
      </FormField>

      <FormField htmlFor="censusPrice" label="Перепись" validateMessage={errors.censusPrice?.message}>
        <Controller
          name="censusPrice"
          control={control}
          render={({ field }) => (
            <InputNumber
              {...field}
              {...inputSharedSettings}
              addonAfter="₽"
              style={{ width: '100%' }}
              id="censusPrice"
              status={errors.censusPrice && 'error'}
            />
          )}
        />
      </FormField>

      <FormField htmlFor="autoShipmentPrice" label="Отгрузка авто" validateMessage={errors.autoShipmentPrice?.message}>
        <Controller
          name="autoShipmentPrice"
          control={control}
          render={({ field }) => (
            <InputNumber
              {...field}
              {...inputSharedSettings}
              addonAfter="₽/т"
              style={{ width: '100%' }}
              id="autoShipmentPrice"
              status={errors.autoShipmentPrice && 'error'}
            />
          )}
        />
      </FormField>

      <FormField htmlFor="trainShipmentPrice" label="Отгрузка Ж/Д" validateMessage={errors.trainShipmentPrice?.message}>
        <Controller
          name="trainShipmentPrice"
          control={control}
          render={({ field }) => (
            <InputNumber
              {...field}
              {...inputSharedSettings}
              addonAfter="₽/т"
              style={{ width: '100%' }}
              id="trainShipmentPrice"
              status={errors.trainShipmentPrice && 'error'}
            />
          )}
        />
      </FormField>

      <FormField
        htmlFor="paperworkPrice"
        label="Оформление документов"
        validateMessage={errors.paperworkPrice?.message}
      >
        <Controller
          name="paperworkPrice"
          control={control}
          render={({ field }) => (
            <InputNumber
              {...field}
              {...inputSharedSettings}
              addonAfter="₽/т"
              style={{ width: '100%' }}
              id="paperworkPrice"
              status={errors.paperworkPrice && 'error'}
            />
          )}
        />
      </FormField>

      <FormField label="Остальные услуги" htmlFor="otherServices" validateMessage={errors.otherServices?.message}>
        <Controller
          name="otherServices"
          control={control}
          render={({ field }) => (
            <Input.TextArea
              {...field}
              id="otherServices"
              rows={2}
              placeholder="Например: взвешивание вагонов - 2000 ₽/вагон"
              maxLength={500}
              status={errors.otherServices && 'error'}
            ></Input.TextArea>
          )}
        />
      </FormField>

      <FormFooter>
        <Button onClick={onCancel} htmlType="button">
          Отмена
        </Button>
        <Button onClick={onPrev} htmlType="button">
          Назад
        </Button>
        <Button type="primary" htmlType="submit" loading={loading}>
          Готово
        </Button>
      </FormFooter>
    </form>
  )
}
