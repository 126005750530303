import { useQuery, UseQueryResult } from '@tanstack/react-query'

import {
  AdminGetElevatorAdvertOffersResponse,
  ElevatorAdvertOfferApiAdminGetElevatorOfferResponsesRequest,
} from '~api/generated'
import { useApi } from '~providers/api'

export const useGetElevatorAdvertOffersByAdmin = (
  request: ElevatorAdvertOfferApiAdminGetElevatorOfferResponsesRequest
): UseQueryResult<AdminGetElevatorAdvertOffersResponse> => {
  const api = useApi()

  const fetchFn = async (): Promise<AdminGetElevatorAdvertOffersResponse> => {
    const res = await api.elevatorAdvertOffer.adminGetElevatorOfferResponses(request)

    return res.data
  }

  return useQuery(['elevatorsAdvertOffersAdmin', request], fetchFn, { keepPreviousData: true })
}
