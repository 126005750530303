import React, { FC, useEffect } from 'react'

import { Button, Input, Modal } from 'antd'
import { Controller, useForm } from 'react-hook-form'

import { yupResolver } from '@hookform/resolvers/yup'

import {
  AddressControlDadata,
  CheckboxControl,
  ParametersCultureControl,
  PhoneNumberControl,
  PriceControl,
  SellOrBuyRadioControl,
  UsingNdsControl,
  VolumeControl,
  ZernoAgrOrAnotherCompanyRadioControl,
} from '~/shared/controls'
import { DaDataPartySuggestion, DatePicker, FormField, InputDadata, UploadButton } from '~/ui-components'
import { modalSharedSettings } from '~/utils'
import { CreateOfferBodyTypeEnum, CreationProductParameterData, OfferDataTypeEnum } from '~api/generated'
import { useAuth } from '~providers/auth'
import { fullPhoneNumberValidator } from '~shared/validations/schemas'

import { getDisabledDate, getDisabledTime } from '../../utils'

import { useGetCreateLotAdminModalSchema } from './useGetCreateLotAdminModalSchema'

import { CreateOfferModalProps, FormValues } from './CreateLotAdminModal.types'

import * as S from './CreateLotAdminModal.styled'

const FORM_ID = 'create-lot-modal-by-admin'

export const CreateLotAdminModal: FC<CreateOfferModalProps> = ({ visible, onClose, onSubmit, isFetching }) => {
  const schema = useGetCreateLotAdminModalSchema()
  const { phoneNumber, user } = useAuth()
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors, isValidating },
    watch,
    reset,
    trigger,
  } = useForm<FormValues>({
    defaultValues: {
      offerType: CreateOfferBodyTypeEnum.Sell,
      address: undefined,
      culture: undefined,
      volume: undefined,
      price: undefined,
      includingNds: false,
      labAnalysis: undefined,
      shippingRequired: false,
      startedAt: undefined,
      publishedAt: undefined,
      contactUser: '',
      contactPhone: '',
      comment: '',
      note: '',
      company: 'other',
      companyInfo: {
        name: '',
        inn: '',
        kpp: '',
        ogrn: '',
        codeOkved: '',
        director: '',
        actualAddress: '',
        legalAddress: '',
      },
      aicCharterMember: false,
    },
    resolver: yupResolver(schema),
  })

  useEffect(() => reset(), [visible])

  // TODO: дублируется запрос
  const contactPhoneWatch = watch('contactPhone')
  const companyInfo = watch('companyInfo')
  useEffect(() => {
    if (fullPhoneNumberValidator(contactPhoneWatch) && companyInfo.inn) {
      trigger('contactPhone')
    }
  }, [companyInfo.inn])

  const offerType = watch('offerType')

  const handleDadataSelect = (value?: DaDataPartySuggestion | undefined): void => {
    const data = value?.data

    if (!data) {
      console.log('There is no data for set')
      return
    }

    setValue(
      'companyInfo',
      {
        name: data.name?.short_with_opf ?? value?.value,
        inn: data?.inn,
        kpp: data?.kpp ?? '',
        ogrn: data?.ogrn ?? '',
        codeOkved: data?.okved,
        director: data.type === 'INDIVIDUAL' ? data.name?.full : data.management?.name ?? '',
        legalAddress: data.address?.value,
        actualAddress: data.address?.value,
      },
      { shouldValidate: true }
    )
  }

  const handleDatasetChange = (name?: string) => {
    setValue('companyInfo', {
      name: name ?? '',
      inn: '',
      kpp: '',
      ogrn: '',
      codeOkved: '',
      director: '',
      legalAddress: '',
      actualAddress: '',
    })
  }

  const handleFormSubmit = async (values: FormValues) => {
    const {
      address,
      culture,
      volume,
      offerType,
      price,
      includingNds,
      shippingRequired,
      publishedAt,
      contactUser,
      contactPhone,
      labAnalysis,
      company,
      companyInfo,
      aicCharterMember,
      comment,
      note,
      startedAt,
    } = values

    onSubmit({
      isCompanyZernoAgregator: company === 'zernoAgregator',
      createCompanyBody: {
        inn: companyInfo.inn,
        bindingUser: false,
      },
      createOfferBody: {
        address: address?.value ?? '',
        product: {
          parameters: culture?.parameters as CreationProductParameterData[],
          type: culture?.cultureTypeId ?? '',
        },
        volume: volume ?? 25,
        type: offerType,
        price: price ?? 1000,
        includingNds,
        shippingRequired,
        publishedAt: (publishedAt ?? new Date()).toISOString(),
        contactPerson: contactPhone
          ? {
              name: contactUser,
              phoneNumber: contactPhone,
            }
          : undefined,
        note,
      },
      labAnalysis,
      comment,
      startedAt,
    })
  }

  const offerTypeWatch = watch('offerType')

  useEffect(() => {
    setValue('shippingRequired', false)
  }, [offerTypeWatch])

  const company = watch('company')

  useEffect(() => {
    const isZernoCompany = company === 'zernoAgregator'
    const name = user ? user.name : ''
    const phone = ''
    setValue('contactUser', isZernoCompany ? name : '')
    setValue('contactPhone', isZernoCompany ? phone : '')
  }, [company])

  return (
    <Modal
      {...modalSharedSettings}
      open={visible}
      onCancel={onClose}
      width={600}
      title="Создать лот"
      footer={[
        <Button key="cancel" onClick={onClose} htmlType="button">
          Отмена
        </Button>,
        <Button key="submit" type="primary" htmlType="submit" loading={isValidating || isFetching} form={FORM_ID}>
          Разместить
        </Button>,
      ]}
    >
      <S.Form onSubmit={handleSubmit(handleFormSubmit)} id={FORM_ID}>
        <FormField>
          <Controller name="offerType" control={control} render={({ field }) => <SellOrBuyRadioControl {...field} />} />
        </FormField>

        <FormField label="Адрес" htmlFor="address" isRequired validateMessage={errors.address?.message}>
          <Controller
            name="address"
            control={control}
            render={({ field }) => (
              <AddressControlDadata
                id="address"
                value={undefined}
                onChange={(value) => field.onChange(value)}
                hasError={!!errors.address}
              />
            )}
          />
        </FormField>

        <FormField validateMessage={errors.culture ? 'Параметры культуры заполнены с ошибками' : ''}>
          <Controller
            name="culture"
            control={control}
            render={({ field }) => <ParametersCultureControl isRequired {...field} />}
          />
        </FormField>

        {offerType === OfferDataTypeEnum.Sell && (
          <FormField label="Результаты лаб. исследований" validateMessage={errors.labAnalysis?.message} isRequired>
            <Controller
              control={control}
              name="labAnalysis"
              render={({ field }) => (
                <UploadButton
                  onChange={(evt) => {
                    if (evt.target.files?.length) {
                      field.onChange(evt.target.files[0])
                    }
                  }}
                >
                  Загрузить
                </UploadButton>
              )}
            />
          </FormField>
        )}

        <FormField label="Объем, тонн" htmlFor="volume" validateMessage={errors.volume?.message} isRequired>
          <S.InputWrapper>
            <Controller
              name="volume"
              control={control}
              render={({ field }) => <VolumeControl id="volume" {...field} hasError={!!errors.volume} />}
            />
          </S.InputWrapper>
        </FormField>

        <S.Price>
          <S.InputWrapper>
            <FormField
              label={(offerType === OfferDataTypeEnum.Sell ? 'Минимальная' : 'Максимальная') + ' ставка, ₽/тонн'}
              htmlFor="price"
              validateMessage={errors.price?.message}
              isRequired
            >
              <Controller
                name="price"
                control={control}
                render={({ field }) => <PriceControl id="price" {...field} hasError={!!errors.price} />}
              />
            </FormField>
          </S.InputWrapper>
          <S.NdsWrapper>
            <Controller control={control} name="includingNds" render={({ field }) => <UsingNdsControl {...field} />} />
          </S.NdsWrapper>
        </S.Price>

        {offerType === 'BUY' && (
          <FormField>
            <Controller
              control={control}
              name="shippingRequired"
              render={({ field }) => (
                <CheckboxControl {...field}>Требуется перевозчик для доставки до склада</CheckboxControl>
              )}
            />
          </FormField>
        )}

        <S.DividerStyled />

        <FormField label="Разместить от лица">
          <Controller
            name="company"
            control={control}
            render={({ field }) => <ZernoAgrOrAnotherCompanyRadioControl {...field} />}
          />
        </FormField>

        {company === 'other' && (
          <FormField
            label="Наименование компании или ИНН"
            htmlFor="companyInfo"
            validateMessage={errors.companyInfo?.inn?.message}
            isRequired
          >
            <Controller
              name="companyInfo"
              control={control}
              render={({ field }) => (
                <InputDadata
                  id="name"
                  placeholder="Введите наименование компании"
                  inputValue={field.value.name}
                  onSelect={handleDadataSelect}
                  variant="organizations"
                  inputStatus={errors.companyInfo && 'error'}
                  onChangeInput={handleDatasetChange}
                />
              )}
            />
          </FormField>
        )}

        <FormField label="Контактное лицо" htmlFor="contactUser" validateMessage={errors.contactUser?.message}>
          <Controller
            name="contactUser"
            control={control}
            render={({ field }) => (
              <Input id="contactUser" placeholder="Укажите ФИО" {...field} status={errors.contactUser && 'error'} />
            )}
          />
        </FormField>

        <FormField validateMessage={errors.contactPhone?.message} label="Телефон" htmlFor="contactPhone">
          <Controller
            name="contactPhone"
            control={control}
            render={({ field }) => <PhoneNumberControl {...field} id="contactPhone" hasError={!!errors.contactPhone} />}
            rules={{
              validate: {
                contactPhone: () => false,
              },
            }}
          />
        </FormField>

        <FormField label="Примечание" htmlFor="note" validateMessage={errors.note?.message}>
          <Controller
            name="note"
            control={control}
            render={({ field }) => (
              <Input id="note" placeholder="Примечание" {...field} status={errors.note && 'error'} />
            )}
          />
        </FormField>

        <FormField label="Начать торги">
          <S.DateWrapper>
            <Controller
              name="startedAt"
              control={control}
              render={({ field }) => (
                <DatePicker
                  showTime
                  format="DD.MM.YYYY HH:mm"
                  {...field}
                  disabledDate={getDisabledDate}
                  disabledTime={getDisabledTime}
                />
              )}
            />
          </S.DateWrapper>
        </FormField>
      </S.Form>
    </Modal>
  )
}
