import { Select } from 'antd'
import styled from 'styled-components'

import { css } from '@styled-system/css'

export const StyledSelect = styled(Select)(
  css({
    '.ant-select-selection-overflow-item-suffix': {
      width: '40px',
    },
  })
)
