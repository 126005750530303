import { useQuery, UseQueryResult } from '@tanstack/react-query'

import { isApiResponseEmpty } from '~/utils/isApiResponseEmpty'
import { GetEdmOperatorResponse } from '~api/generated'
import { sharedCompanyQueryOptions } from '~hooks/company/_sharedCompanyQueryOptions'
import { useApi } from '~providers/api'

export const useGetCompanyEdo = (
  companyId: number | undefined | null
): UseQueryResult<GetEdmOperatorResponse | null> => {
  const api = useApi()

  const fetchFn = async (): Promise<GetEdmOperatorResponse | null> => {
    if (!companyId) {
      return null
    }

    try {
      const res = await api.companies.edmOperatorControllerGetEdmOperator({
        companyId,
      })

      return res.data ?? null
    } catch (error) {
      if (isApiResponseEmpty(error)) {
        return null
      }

      return Promise.reject(error)
    }
  }

  return useQuery(['fetchCompanyEdo', companyId], fetchFn, sharedCompanyQueryOptions)
}
