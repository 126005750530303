import React, { StrictMode } from 'react'

import { ConfigProvider } from 'antd'
import ru_RU from 'antd/lib/locale/ru_RU'
import { IconContext } from 'react-icons'
import {
  BrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  Route,
  Routes,
  useLocation,
  useNavigationType,
} from 'react-router-dom'
import { ThemeProvider } from 'styled-components'

import { ApolloProvider } from '@apollo/client'
import { injectStores } from '@mobx-devtools/tools'
import * as Sentry from '@sentry/react'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

// import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import 'antd/dist/reset.css'
import { PrecacheData } from '~/PrecacheData'
import {
  adminOffersFilterStore,
  auctionsFilterStore,
  basisAdvertOffersAdminFilterStore,
  basisAdvertsAdminFilterStore,
  basisFilterStore,
  dealsFilterStore,
  findCargoFilterStore,
  initialFilterStore,
  logisticDealsFilterStore,
  moderatorCompaniesFilterStore,
  moderatorUsersFilterStore,
  myBasisAdvertsFilterStore,
  myCargoFilterStore,
  myOffersFilterStore,
  offersFilterStore,
  requestsFilterStore,
} from '~/store/filters'
import { userUiSettingsStore } from '~/store/userUiSettingsStore'
import { ZernoTheme } from '~/theme'
import { light } from '~/ui-components'
import { graphQlClient } from '~api/gql-client'
import { Notifications } from '~layout/Notifications'
import { ApiProvider } from '~providers/api'
import { AuthProvider } from '~providers/auth'
import { DemoUserAuthRequiredModal, NewAppVersionModal } from '~shared/components'

import { Router } from './router/Router'
import { AppConfig } from './appConfig'
import { FallbackPage } from './layout'
import { LandingCreateOffer } from './pages'

Sentry.init({
  dsn: 'https://e47b7b855df24ac98dd22bcac35b8352@o1389947.ingest.sentry.io/6712118',
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes
      ),
    }),
    new Sentry.Replay(),
  ],
  attachStacktrace: true,
  tracesSampleRate: 1.0,
  replaysOnErrorSampleRate: 1.0,
  environment: process.env.REACT_SENTRY_ENVIRONMENT ?? 'production',
  enabled: process.env.REACT_APP_ENABLE_SENTRY === '1',
  release: process.env.REACT_APP_RELEASE,
})

injectStores({
  userUiSettingsStore,
  initialFilterStore,
  basisFilterStore,
  basisAdvertsAdminFilterStore,
  basisAdvertOffersAdminFilterStore,
  myBasisFilterStore: myBasisAdvertsFilterStore,
  auctionsFilterStore,
  dealsFilterStore,
  moderatorCompaniesFilterStore,
  moderatorUsersFilterStore,
  offersFilterStore,
  myOffersFilterStore,
  adminOffersFilterStore,
  requestsFilterStore,
  myCargoFilterStore,
  findCargoFilterStore,
  logisticDealsFilterStore,
})

export const queryClientInstance = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      retry: false,
    },
  },
})

const App: React.FC = () => (
  <StrictMode>
    <Sentry.ErrorBoundary fallback={FallbackPage}>
      <ConfigProvider locale={ru_RU} theme={ZernoTheme}>
        <ThemeProvider theme={light}>
          <IconContext.Provider value={{ className: 'anticon' }}>
            <BrowserRouter basename={AppConfig.BASE_PATH}>
              <ApolloProvider client={graphQlClient}>
                <QueryClientProvider client={queryClientInstance}>
                  <ApiProvider>
                    {location.pathname === '/landing' ? (
                      <Routes>
                        <Route path="landing" element={<LandingCreateOffer />} />
                      </Routes>
                    ) : (
                      <AuthProvider>
                        <PrecacheData>
                          <Notifications />
                          <Router />
                          <DemoUserAuthRequiredModal />
                          <NewAppVersionModal />
                        </PrecacheData>
                        {/*<ReactQueryDevtools initialIsOpen={false} position="bottom-right" />*/}
                      </AuthProvider>
                    )}
                  </ApiProvider>
                </QueryClientProvider>
              </ApolloProvider>
            </BrowserRouter>
          </IconContext.Provider>
        </ThemeProvider>
      </ConfigProvider>
    </Sentry.ErrorBoundary>
  </StrictMode>
)

export default App
