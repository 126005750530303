import { codeUnreachable } from '~/utils/code-unreachable'
import { FreightOrderDocumentType } from '~api/gql-generated/graphql'

export function getLogisticOrderDocumentName(documentType: FreightOrderDocumentType): string {
  switch (documentType) {
    case FreightOrderDocumentType.CarriageContractTemplate:
      return 'Образец договора перевозки'
    default:
      return codeUnreachable(documentType)
  }
}
