import React from 'react'

import { Button } from 'antd'
import { FiEdit2, FiTrash2 } from 'react-icons/fi'

import { Card } from '~/ui-components'

import { IBankInfoViewProps } from './BankInfoView.types'

import * as S from './BankInfoView.styled'

export const BankInfoView: React.FC<IBankInfoViewProps> = ({ data, onChange, onRemove }) => {
  const handleChange = () => {
    onChange?.()
  }

  const handleRemove = () => {
    onRemove?.()
  }

  return (
    <Card>
      <Card.Header>
        <Card.Title>Реквизиты «{data.name}»</Card.Title>

        <Card.ActionsContent>
          {onRemove && (
            <Button size="small" type="text" icon={<FiTrash2 size={24} color="red" />} onClick={handleRemove} />
          )}
          {onChange && <Button size="small" type="text" icon={<FiEdit2 size={24} />} onClick={handleChange} />}
        </Card.ActionsContent>
      </Card.Header>

      <S.ContentWrapper>
        <Card.Label>Расчетный счет</Card.Label>
        <Card.Value>{data.paymentAccount}</Card.Value>

        <Card.Label>Корр. счет</Card.Label>
        <Card.Value>{data.correspondentAccount}</Card.Value>

        <Card.Label>БИК</Card.Label>
        <Card.Value>{data.bic}</Card.Value>
      </S.ContentWrapper>
    </Card>
  )
}
