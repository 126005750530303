import { Checkbox } from 'antd'
import styled from 'styled-components'

export const StyledCheckboxGroup = styled(Checkbox.Group)`
  display: flex;
  flex-direction: column;
  gap: 4px;

  .ant-checkbox-group-item {
    margin: 0;
  }
`
