import {
  AuctionApi,
  AuthApi,
  CompanyApi,
  Configuration,
  DealApi,
  DealDocumentApi,
  ElevatorAdvertApi,
  ElevatorAdvertOfferApi,
  ElevatorApi,
  FileApi,
  NotificationApi,
  OfferApi,
  OrderApi,
  ProductApi,
  UserApi,
  WarehouseApi,
} from '~/api/generated'

import { IApiContext } from './api-context'

interface ICreateApisParams {
  basePath?: string
}

export function createApis({ basePath }: ICreateApisParams): IApiContext {
  const param = {
    basePath,
    baseOptions: {
      withCredentials: true,
    },
  }

  return {
    auth: new AuthApi(new Configuration(param)),
    users: new UserApi(new Configuration(param)),
    companies: new CompanyApi(new Configuration(param)),
    warehouses: new WarehouseApi(new Configuration(param)),
    products: new ProductApi(new Configuration(param)),
    offers: new OfferApi(new Configuration(param)),
    deal: new DealApi(new Configuration(param)),
    dealDocument: new DealDocumentApi(new Configuration(param)),
    order: new OrderApi(new Configuration(param)),
    auction: new AuctionApi(new Configuration(param)),
    notifications: new NotificationApi(new Configuration(param)),
    file: new FileApi(new Configuration(param)),
    elevators: new ElevatorApi(new Configuration(param)),
    elevatorAdvert: new ElevatorAdvertApi(new Configuration(param)),
    elevatorAdvertOffer: new ElevatorAdvertOfferApi(new Configuration(param)),
  }
}
