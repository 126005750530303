import { useMutation } from '@tanstack/react-query'

import { ElevatorAdvertOfferApiAdminElevatorAdvertOfferControllerUpdateElevatorAdvertOfferStatusRequest } from '~/api/generated'
import { useApi } from '~providers/api'

export const useUpdateElevatorAdvertOfferStatusByAdmin = () => {
  const api = useApi()

  return useMutation(
    (request: ElevatorAdvertOfferApiAdminElevatorAdvertOfferControllerUpdateElevatorAdvertOfferStatusRequest) => {
      return api.elevatorAdvertOffer.adminElevatorAdvertOfferControllerUpdateElevatorAdvertOfferStatus(request)
    }
  )
}
