import { useMutation } from '@tanstack/react-query'

import { UpdateBankAccountBody } from '~/api/generated'
import { useApi } from '~providers/api'

interface UpdateBankAccount {
  bankAccountId: number
  updateBankAccountBody: UpdateBankAccountBody
}

export const useUpdateBankAccount = () => {
  const api = useApi()

  return useMutation(({ bankAccountId, updateBankAccountBody }: UpdateBankAccount) => {
    return api.companies.bankAccountControllerUpdateBankAccounts({
      bankAccountId,
      updateBankAccountBody,
    })
  })
}
