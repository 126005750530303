import styled from 'styled-components'

import { breakpoints } from '~/tokens'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  gap: 24px;
  margin-bottom: 28px;
  padding: 24px;
  padding-bottom: 0;

  @media (max-width: ${breakpoints.MOBILE_XL}px) {
    gap: 16px;
    padding: 16px;
  }
`

export const ContentWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
`
