import { Button } from 'antd'
import styled from 'styled-components'

import css from '@styled-system/css'

import { Divider } from '~/ui-components'

export const Form = styled('form')(css({}))

export const Price = styled.div(
  css({
    display: 'flex',
    gap: [0, null, 16],
    flexDirection: ['column', 'column', 'unset'],
  })
)

export const NdsWrapper = styled('div')(
  css({
    mt: [0, 0, 14],
    marginBottom: [14, 14, 2],
  })
)

export const InputWrapper = styled('div')(
  css({
    width: '250px',
  })
)

export const DividerStyled = styled(Divider)(
  css({
    marginBottom: '24px',
  })
)

export const UploadButton = styled(Button)(
  css({
    justifyContent: 'flex-start',
  })
)

export const Bet = styled.div(
  css({
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
  })
)

export const DateWrapper = styled.div(
  css({
    width: '170px',
  })
)
