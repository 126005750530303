import React from 'react'

import { observer } from 'mobx-react-lite'

import { basisAdvertsAdminFilterStore } from '~/store/filters'
import { SearchInput } from '~shared/components/SearchInput'
import {
  ElevatorAdvertStatusSelectControl,
  ElevatorAdvertTypeSelectControl,
  ElevatorSelectControl,
} from '~shared/controls'

import * as S from './ModeratorBasisAdvertFilters.styled'

export const ModeratorBasisAdvertFilters: React.FC = observer(() => {
  return (
    <S.Filters>
      <ElevatorSelectControl
        id="elevatorId"
        value={basisAdvertsAdminFilterStore.elevatorId}
        onChange={(elevatorId) => basisAdvertsAdminFilterStore.setElevatorId(elevatorId)}
        placeholder="Название базиза"
      />
      <SearchInput
        defaultValue={basisAdvertsAdminFilterStore.idSearchQuery}
        onSearch={(value) => basisAdvertsAdminFilterStore.setIdSearchQuery(value)}
        placeholder="Поиск по №"
        type="number"
        pattern="\d*"
      />
      <ElevatorAdvertStatusSelectControl
        id="elevatorStatus"
        value={basisAdvertsAdminFilterStore.advertStatuses}
        onChange={(advertStatuses) => basisAdvertsAdminFilterStore.setAdvertStatuses(advertStatuses)}
      />
      <ElevatorAdvertTypeSelectControl
        id="elevatorAdvertType"
        value={basisAdvertsAdminFilterStore.advertType}
        onChange={(advertTypes) => basisAdvertsAdminFilterStore.setAdvertType(advertTypes)}
      />
    </S.Filters>
  )
})
