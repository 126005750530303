import styled from 'styled-components'

import { EmptyButton, Text } from '~/ui-components'
export const Wrapper = styled.div`
  display: grid;
  grid-gap: 24px;
`

export const Header = styled.div`
  display: flex;
  gap: 24px;
`

export const Title = styled(Text)`
  display: flex;
  gap: 24px;
  margin-right: auto;
`

export const Toggler = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
`

export const TableActions = styled.div`
  display: flex;
  gap: 16px;
  justify-content: flex-end;
`

export const AddButtonWrapper = styled.div`
  position: relative;
  margin-top: 8px;
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 16px;
  border: 1px dashed var(--primary-main-color);
  color: var(--primary-main-color);
  cursor: pointer;

  &::before {
    content: '';
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: 0.25s;
    opacity: 0.05;
  }

  &:hover {
    border-style: solid;

    &::before {
      background: var(--primary-main-color);
    }
  }
`

export const AddButtonText = styled(Text).attrs({ variant: 'normal-bold' })`
  color: var(--primary-main-color);
`
