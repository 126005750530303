import { AdminElevatorAdvertOfferDataTypeEnum } from '~api/generated'

import { BadgeVariant } from '~ui-components/components/Badge/Badge.types'

export function getElevatorAdvertOfferType(v: AdminElevatorAdvertOfferDataTypeEnum): {
  name: string
  color: BadgeVariant
} {
  switch (v) {
    case AdminElevatorAdvertOfferDataTypeEnum.Buy:
      return {
        name: 'Покупка',
        color: 'base',
      }
    case AdminElevatorAdvertOfferDataTypeEnum.Sell:
      return {
        name: 'Продажа',
        color: 'yellow',
      }
  }
}
