import styled from 'styled-components'

import { css } from '@styled-system/css'

export const ActionFooterWrapper = styled.div(
  css({
    marginTop: '24px',
    display: 'flex',
    justifyContent: 'flex-end',
    gap: '8px',
  })
)

export const FoundResultWrapper = styled.div(
  css({
    backgroundColor: 'Gray75',
    padding: '8px',
    borderRadius: '4px',
  })
)
