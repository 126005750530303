import React from 'react'

import { Button } from 'antd'
import { observer } from 'mobx-react-lite'
import { Controller, useForm } from 'react-hook-form'

import { yupResolver } from '@hookform/resolvers/yup'

import { userUiSettingsStore } from '~/store/userUiSettingsStore'
import { FormField } from '~/ui-components'
import { useAuth } from '~providers/auth'
import { UsersSelectControl } from '~shared/controls'

import { addEmployeeFromAnotherCompanyFormSchema } from './AddEmployeeFromAnotherCompanyForm.validation'

import {
  AddEmployeeFromAnotherCompanyFormProps,
  AddEmployeeFromAnotherCompanyFormValues,
} from './AddEmployeeFromAnotherCompanyForm.types'

import * as S from './AddEmployeeFromAnotherCompanyForm.styled'

export const AddEmployeeFromAnotherCompanyForm: React.FC<AddEmployeeFromAnotherCompanyFormProps> = observer(
  ({ onBack, loading, onSubmit }) => {
    const { companyId } = useAuth()

    const {
      control,
      handleSubmit,
      formState: { errors },
    } = useForm<AddEmployeeFromAnotherCompanyFormValues>({
      defaultValues: {
        usersIds: [],
      },
      resolver: yupResolver(addEmployeeFromAnotherCompanyFormSchema),
    })

    const onSubmitHandler = (data: AddEmployeeFromAnotherCompanyFormValues) => {
      onSubmit(data)
    }

    return (
      <S.Wrapper>
        <S.FormRow>
          <FormField
            label="Выберите сотрудника"
            isRequired
            htmlFor="usersIds"
            validateMessage={errors.usersIds?.message}
          >
            <Controller
              name="usersIds"
              control={control}
              render={({ field }) => (
                <UsersSelectControl
                  hasError={!!errors.usersIds}
                  isMultiple
                  value={field.value}
                  onChange={field.onChange}
                  excludeCompanyIds={[companyId]}
                />
              )}
            />
          </FormField>
        </S.FormRow>

        <S.ActionWrapper>
          <Button htmlType="button" onClick={onBack}>
            Отмена
          </Button>

          <Button htmlType="button" type="primary" onClick={handleSubmit(onSubmitHandler)} loading={loading}>
            Готово
          </Button>
        </S.ActionWrapper>
      </S.Wrapper>
    )
  }
)
