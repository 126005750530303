import React from 'react'

import { Flex, Input } from 'antd'
import { Controller } from 'react-hook-form'

import { FormField, Text } from '~/ui-components'
import { PhoneNumberControl } from '~shared/controls'

import { CreateLoadFormFieldProps } from '~pages/LogisticsPage/modules/MyCargos/components/CreateLoad/CreateLoad.types'

export const Additional: React.FC<CreateLoadFormFieldProps> = ({ control, errors }) => {
  return (
    <>
      <Text variant="h1" style={{ paddingBottom: '24px' }}>
        Дополнительно
      </Text>

      <Flex gap={24} justify="flex-start">
        <FormField label="Примечание" htmlFor="additionalDetails" validateMessage={errors.additionalDetails?.message}>
          <Controller
            name="additionalDetails"
            control={control}
            render={({ field }) => (
              <Input.TextArea
                {...field}
                value={field.value ?? undefined}
                id="additional"
                placeholder="Введите примечание"
                status={errors.additionalDetails && 'error'}
                autoSize={{ minRows: 3, maxRows: 6 }}
              />
            )}
          />
        </FormField>
      </Flex>

      <Flex gap={24} justify="flex-start" style={{ maxWidth: '708px' }}>
        <Flex flex="1 1 50%">
          <FormField
            label="ФИО контактного лица"
            htmlFor="contactPerson"
            validateMessage={errors.contactPerson?.message}
            isRequired
          >
            <Controller
              name="contactPerson"
              control={control}
              render={({ field }) => (
                <Input
                  {...field}
                  style={{ maxWidth: '342px' }}
                  id="name"
                  status={errors.contactPerson && 'error'}
                  placeholder="Введите ФИО"
                />
              )}
            />
          </FormField>
        </Flex>

        <Flex flex="1 1 50%">
          <FormField
            label="Телефон"
            htmlFor="contactPhoneNumber"
            validateMessage={errors.contactPhoneNumber?.message}
            isRequired
          >
            <Controller
              name="contactPhoneNumber"
              control={control}
              render={({ field }) => (
                <PhoneNumberControl {...field} id="phone" hasError={!!errors.contactPhoneNumber} />
              )}
            />
          </FormField>
        </Flex>
      </Flex>
    </>
  )
}
