import { graphql } from '~api/gql-generated/gql'

export const getTransportationDataQuery = graphql(/* GraphQL */ `
  query TransportationData($companyId: Int!) {
    transportData(companyId: $companyId) {
      transportModes
      transportationArea
      regions {
        id
        name
      }
      address
    }
  }
`)

export const getRegionsQuery = graphql(/* GraphQL */ `
  query Regions {
    regions {
      id
      name
    }
  }
`)

export const getTransportsQuery = graphql(/* GraphQL */ `
  query Transports($filter: TransportFilterInput, $pagination: PaginationInput) {
    transports(filter: $filter, pagination: $pagination) {
      transports {
        id
        companyId
        transportStationId
        type
        brand
        bodyTypeId
        axlesCount
        manufacturingDate
        vin
        registrationCertificate
        transportPassport
        licensePlate
        ownershipType
        ownerName
        lengthCm
        widthCm
        heightCm
        loadCapacityKg
        loadVolumeL
        loadingMethodId
        adrCertificate
        shortRouteKm
        longRouteKm
        preferredRouteType
        canWorkInField
        additionalDetails
        createdAt
        updatedAt
        deletedAt
        bodyType {
          id
          name
        }
        loadingMethod {
          id
          name
        }
      }
      pagination {
        currentPage
        pageSize
        total
        totalPages
      }
    }
  }
`)

export const getTransportQuery = graphql(/* GraphQL */ `
  query Transport($transportId: Int!) {
    transport(transportId: $transportId) {
      id
      companyId
      transportStationId
      type
      brand
      bodyTypeId
      axlesCount
      manufacturingDate
      vin
      registrationCertificate
      transportPassport
      licensePlate
      ownershipType
      ownerName
      lengthCm
      widthCm
      heightCm
      loadCapacityKg
      loadVolumeL
      loadingMethodId
      adrCertificate
      shortRouteKm
      longRouteKm
      preferredRouteType
      canWorkInField
      additionalDetails
      createdAt
      updatedAt
      deletedAt
      bodyType {
        id
        name
      }
      loadingMethod {
        id
        name
      }
    }
  }
`)

export const getTransportBodyTypesQuery = graphql(/* GraphQL */ `
  query TransportBodyTypes {
    transportBodyTypes {
      id
      name
    }
  }
`)

export const getTransportLoadingMethodsQuery = graphql(/* GraphQL */ `
  query TransportLoadingMethods {
    transportLoadingMethods {
      id
      name
    }
  }
`)

export const getTransportStationsQuery = graphql(/* GraphQL */ `
  query TransportStations($filter: TransportStationFilter) {
    transportStations(filter: $filter) {
      id
      address
    }
  }
`)

export const getTransportDocumentsQuery = graphql(/* GraphQL */ `
  query TransportDocuments($transportId: Int!, $filter: TransportDocumentFilterInput) {
    transportDocuments(transportId: $transportId, filter: $filter) {
      instances {
        id
        status
        createdAt
        number
        formedAt
        rejectionNote
        files {
          id
          status
          name
          size
          mimetype
          source
        }
      }
      type
    }
  }
`)
