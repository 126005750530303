import { useMutation } from '@tanstack/react-query'

import {
  AdminCreateOfferResponseFromSellerResponse,
  ElevatorAdvertOfferApiAdminCreateOfferResponseFromSellerRequest,
} from '~api/generated'
import { useApi } from '~providers/api'

export const useCreateElevatorAdvertOfferFromSellerByAdmin = () => {
  const api = useApi()

  const useCreateElevatorAdvertOfferFn = async (
    request: ElevatorAdvertOfferApiAdminCreateOfferResponseFromSellerRequest
  ): Promise<AdminCreateOfferResponseFromSellerResponse> => {
    const res = await api.elevatorAdvertOffer.adminCreateOfferResponseFromSeller(request)

    return res.data
  }

  return useMutation(useCreateElevatorAdvertOfferFn)
}
