import React from 'react'
import { TransportDetailsProps } from './TransportDetails.types'
import { Flex, Spin } from 'antd'
import { ReadValue } from '~shared/components'
import * as S from './TransportDetails.styled'
import { Text } from '~/ui-components'
import { getPreferredRouteTypeName } from '~/utils/car-park/get-preferred-route-type'
import { TransportDocuments } from './components/TransportDocuments'
import { useQuery } from '@apollo/client'
import { getTransportDocumentsQuery } from '~api/gql-query/car-park.query.graphql'
import { TransportDocumentType } from '~api/gql-generated/graphql'

export const TransportDetails: React.FC<TransportDetailsProps> = ({ transportId, transportDetails }) => {
  const { registrationData, parameters, preferredUse, additional } = transportDetails
  const { manufacturingDate, registrationCertificate, transportPassport, adrCertificate } = registrationData
  const { loadCapacityKg, loadVolumeL, lengthCm, widthCm, heightCm, loadingMethod } = parameters
  const { shortRouteKm, longRouteKm, preferredRouteType, canWorkInField } = preferredUse
  const { additionalDetails } = additional

  const getTransportDocuments = useQuery(getTransportDocumentsQuery, {
    variables: {
      transportId,
      filter: {
        types: [
          TransportDocumentType.RegistrationCertificate,
          TransportDocumentType.TransportPassport,
          TransportDocumentType.LeasingContract,
        ],
      },
    },
    fetchPolicy: 'cache-and-network',
  })

  const transportDocuments = getTransportDocuments.data?.transportDocuments ?? []

  const hasMeasurements = lengthCm || widthCm || heightCm

  return (
    <S.Wrapper>
      <Flex vertical gap={60}>
        <Flex vertical gap={32}>
          <Flex>
            <Text variant="h2">📃 Регистрационные данные</Text>
          </Flex>
          <Flex gap={32}>
            <ReadValue name="Год выпуска" value={manufacturingDate?.toString()} />
            <ReadValue name="Номер СТС" value={registrationCertificate} />
            <ReadValue name="Серия и номер ПТС" value={transportPassport} />
            <ReadValue name="Разрешение ADR" value={adrCertificate} />
          </Flex>
        </Flex>

        <Flex vertical gap={32}>
          <Flex>
            <Text variant="h2">🚙 Параметры ТС</Text>
          </Flex>
          <Flex gap={32}>
            <ReadValue name="Грузоподъемность" value={loadCapacityKg ? `${loadCapacityKg / 1000} т` : undefined} />
            <ReadValue name="Объем" value={loadVolumeL ? `${loadVolumeL / 1000} м³` : undefined} />
            <ReadValue
              name="Длина х ширина х высота"
              value={
                hasMeasurements
                  ? `${lengthCm ? `${lengthCm / 100} м` : 'Не заполнено'} x ${
                      widthCm ? `${widthCm / 100} м` : 'Не заполнено'
                    } x ${heightCm ? `${heightCm / 100} м` : 'Не заполнено'}`
                  : undefined
              }
            />
            <ReadValue name="Способ погрузки" value={loadingMethod?.name} />
          </Flex>
        </Flex>

        <Flex vertical gap={32}>
          <Flex>
            <Text variant="h2">🛣 Предпочтительное использование</Text>
          </Flex>
          <Flex gap={32}>
            <ReadValue name="Короткий рейс" value={shortRouteKm?.toString()} />
            <ReadValue name="Дальний рейс" value={longRouteKm?.toString()} />
            <ReadValue
              name="ТС больше подходит для"
              value={preferredRouteType ? getPreferredRouteTypeName(preferredRouteType) : undefined}
            />
            <ReadValue name="ТС подходит для работ в поле" value={canWorkInField ? 'Да' : 'Нет'} />
          </Flex>
        </Flex>

        <Flex vertical gap={16}>
          <Flex>
            <Text variant="h2">Дополнительно</Text>
          </Flex>
          <Flex>
            <Text variant="normal-bold">{additionalDetails ? additionalDetails : 'Не заполнено'}</Text>
          </Flex>
        </Flex>
      </Flex>

      {getTransportDocuments.loading ? (
        <Flex justify="center" align="center" flex="1 0 auto">
          <Spin spinning={true} size="large" />
        </Flex>
      ) : (
        <Flex>
          <TransportDocuments transportDocuments={transportDocuments} />
        </Flex>
      )}
    </S.Wrapper>
  )
}
