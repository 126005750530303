import React, { FC } from 'react'

import { Table } from 'antd'
import { FiChevronDown, FiChevronUp } from 'react-icons/fi'

import { useGetProductTypeData } from '~/hooks'
import { Badge } from '~/ui-components'
import { AuctionOfferData, AuctionOfferStatus } from '~api/generated'
import { useGetCurrentRole } from '~hooks/auth'
import { ActionOfferMenu } from '~pages/Auctions/components'

import { AuctionOffersTableProps } from '~pages/Auctions/components/AuctionOffersTable/AuctionOffersTable.types'

import * as S from '~pages/Auctions/AuctionsPage.styled'

export const AuctionOffersTable: FC<AuctionOffersTableProps> = ({
  isRowExpandable,
  columns,
  isLoading,
  data,
  onchangeStatusOnRejected,
}) => {
  const {
    user: { isAdmin },
  } = useGetCurrentRole()
  const { getProductTypeParametersForUI_V2 } = useGetProductTypeData()

  return (
    <Table<AuctionOfferData>
      rowKey="id"
      columns={[
        ...columns,
        {
          title: '',
          dataIndex: 'action',
          width: 60,
          render: (value, row) => {
            return (
              row.status === AuctionOfferStatus.Published &&
              (row.isOwn || isAdmin) && (
                <ActionOfferMenu auctionOfferId={row.id} onCancelOffer={onchangeStatusOnRejected} />
              )
            )
          },
        },
      ]}
      loading={isLoading}
      dataSource={data}
      className={isRowExpandable ? 'table-interactive' : ''}
      scroll={{ x: 'max-content' }}
      pagination={false}
      expandable={{
        showExpandColumn: isRowExpandable,
        expandRowByClick: isRowExpandable,
        rowExpandable: (row) => !!Object.keys(row.product.parameters).length,
        expandIcon: ({ expanded, onExpand, record }) =>
          expanded ? (
            <S.ExpandedIcon onClick={(e) => onExpand(record, e)}>
              <FiChevronUp size={20} />
            </S.ExpandedIcon>
          ) : (
            <S.ExpandedIcon onClick={(e) => onExpand(record, e)}>
              <FiChevronDown size={20} />
            </S.ExpandedIcon>
          ),
        expandedRowRender: (record) => (
          <S.Parameters>
            {getProductTypeParametersForUI_V2(record.product).map((param) => (
              <Badge color="gray" isRound key={param}>
                {param}
              </Badge>
            ))}
          </S.Parameters>
        ),
      }}
    />
  )
}
