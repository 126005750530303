/* tslint:disable */
/* eslint-disable */
/**
 * API Зерно-Агрегатора
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */

export const DocumentPartStatus = {
    Unchecked: 'UNCHECKED',
    Confirmed: 'CONFIRMED',
    Rejected: 'REJECTED'
} as const;

export type DocumentPartStatus = typeof DocumentPartStatus[keyof typeof DocumentPartStatus];



