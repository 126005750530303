import React from 'react'

import { InputNumber } from 'antd'

import { formatNumber } from '~/utils/formatNumber'
import { CustomControlInterface } from '~shared/controls/CustomControl.interface'

type Value = number | undefined

interface IPriceControlProps extends CustomControlInterface {
  onChange: (value: Value) => void
  value: Value
  hasError?: boolean
  id?: string
  addBefore?: string
  addAfter?: string
  placeholder?: string
}

export const PriceControl: React.FC<IPriceControlProps> = React.forwardRef<HTMLInputElement, IPriceControlProps>(
  ({ id, value, onChange, hasError, addBefore, addAfter, placeholder }, ref) => {
    const onChangeHandler = (value: number | null) => {
      if (!value) {
        return
      }
      onChange(value)
    }

    return (
      <InputNumber
        addonBefore={addBefore}
        addonAfter={addAfter}
        ref={ref}
        id={id}
        style={{ width: '100%' }}
        placeholder={placeholder || '2000'}
        status={hasError ? 'error' : undefined}
        value={value}
        onChange={onChangeHandler}
        formatter={(value) => formatNumber(value)}
        step={50}
        precision={0}
        min={0}
        max={9999999}
        decimalSeparator="."
      />
    )
  }
)

PriceControl.displayName = 'PriceControl'
