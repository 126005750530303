import { Button } from 'antd'
import styled from 'styled-components'

import { breakpoints } from '~/tokens'
import { Text } from '~/ui-components'

export const BadgeWrapper = styled.div<{ background: string }>`
  background: ${({ background }) => background};
  border-radius: 16px;
  padding: 16px;
  margin: 16px;
  box-shadow: 0px 1px 4px 0px rgba(84, 84, 84, 0.15);
  display: flex;
  flex-direction: column;
  @media (max-width: ${breakpoints.MOBILE_XL}px) {
    padding: 8px;
    margin: 0;
    margin-bottom: 16px;
  }
`

export const BadgeWrapperSmall = styled(BadgeWrapper)`
  margin: 0;
  margin-bottom: 20px;
  gap: 10px;

  @media (max-width: ${breakpoints.DESKTOP_L}px) {
    border-radius: 9px;
    padding: 8px;
    cursor: pointer;
  }
  //font-size: 12px;
  // @media (max-width: ${breakpoints.MOBILE_XL}px) {
  // }
`

export const BadgeTitleWrapper = styled.div`
  display: flex;
  gap: 8px;
  @media (max-width: ${breakpoints.MOBILE_XL}px) {
    flex-direction: row;
    margin-bottom: 8px;
  }
`

export const BadgeTitle = styled.p`
  color: var(--gray-8-color);
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  margin: 0;
`

export const BadgeTitleSmall = styled(BadgeTitle)`
  line-height: 20px;

  @media (max-width: ${breakpoints.DESKTOP_L}px) {
    display: none;
  }
  @media (max-width: ${breakpoints.MOBILE_XL}px) {
    display: flex;
    flex: 1;
  }
`
export const BadgeTitleButtonSmall = styled(Button).attrs({ type: 'link' })`
  text-align: unset;
  @media (max-width: ${breakpoints.DESKTOP_L}px) {
    display: none;
  }
`

export const BadgeContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

export const BadgeSubmitButton = styled(Button)`
  margin-left: auto;
  @media (max-width: ${breakpoints.MOBILE_XL}px) {
    display: none;
  }
`

export const BadgeSubmitButtonMobile = styled(Button)`
  display: none;
  @media (max-width: ${breakpoints.MOBILE_XL}px) {
    display: unset;
  }
`

export const BadgeContentText = styled(Text).attrs({ variant: 'normal' })<{ isDone?: boolean }>`
  text-decoration: ${({ isDone }) => (isDone ? 'line-through' : 'unset')};
  color: var(--gray-8-color) !important;
  @media (max-width: ${breakpoints.MOBILE_XL}px) {
    font-size: 12px;
  }
`
