import styled from 'styled-components'

import { breakpoints } from '~/tokens'

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`

export const ColumnWrapper = styled.div`
  display: grid;
  grid-template-columns: 700px 410px;
  grid-gap: 16px;
  align-items: start;
  justify-content: center;

  @media (max-width: ${breakpoints.DESKTOP_L}px) {
    grid-template-columns: 1fr 410px;
  }

  @media (max-width: ${breakpoints.DESKTOP_S}px) {
    grid-template-columns: 1fr;
  }
`
export const Column = styled.div`
  display: grid;
  grid-gap: 16px;
`

export const TopActionsWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 400px max-content;
  gap: 16px;
  max-width: 1126px;
  width: 100%;
  margin: 0 auto;
`
