import React, { FC, useCallback, useEffect, useState } from 'react'

import { Flex, Select, Tooltip } from 'antd'
import { observer } from 'mobx-react-lite'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'

import { offersFilterStore } from '~/store/filters'
import { userUiSettingsStore } from '~/store/userUiSettingsStore'
import { Text } from '~/ui-components'
import { appToast, httpErrorHandler } from '~/utils'
import { CreateOfferBody, OfferSortField, OrderDirection } from '~api/generated'
import { useGetCurrentRole } from '~hooks/auth'
import { useGetDevice } from '~hooks/common'
import { useCreateOffer, useCreateOfferAdmin } from '~hooks/offer'
import { CreateOfferForAdminModal } from '~pages/OffersPage/components/CreateOfferForAdminModal'
import { CreateOfferModal } from '~pages/OffersPage/components/CreateOfferModal'
import { ViewToggle } from '~pages/OffersPage/components/ViewToggle'
import { useAuth } from '~providers/auth'

import { SubmitAdminModalData } from '../CreateOfferForAdminModal/CreateOfferForAdminModal.types'
import { OffersHeaderProps } from './OffersHeader.types'

import * as S from './OffersHeader.styled'

export const OffersHeader: FC<OffersHeaderProps> = observer(({ view, onChangeView, onAfterCreateOffer, filters }) => {
  const { isDemoUser, companyId } = useAuth()
  const [searchParams, setSearchParams] = useSearchParams()
  const navigate = useNavigate()
  const location = useLocation()

  const isOpenCreateOfferModal = Boolean(searchParams.get('isOpenCreateOfferModal'))
  const createOffer = useCreateOffer()
  const createOfferAdminFn = useCreateOfferAdmin()
  const device = useGetDevice()
  const [isVisibleCreateOfferModal, setVisibleCreateOfferModal] = useState(isOpenCreateOfferModal ?? false)
  const [isFetching, setFetching] = useState(false)
  const [curSortValue, setCurSortValue] = useState('dateOfAddition')

  const {
    user: { isAdmin },
  } = useGetCurrentRole()

  const handleCancelCreateOfferModal = () => {
    const filter = searchParams.get('filter')
    setSearchParams(filter ? `filter=${filter}` : '')

    setVisibleCreateOfferModal(false)
  }

  const handleSubmitOffer = async (createOfferBody: CreateOfferBody, isAddAddress: boolean, labAnalysis?: File) => {
    if (companyId === null) return

    setFetching(true)
    try {
      await createOffer.mutateAsync({
        createOfferBody,
        isAddAddress,
        labAnalysis,
        companyId,
      })
      await onAfterCreateOffer?.()
      setVisibleCreateOfferModal(false)
      appToast.success({
        description:
          'Здравствуйте! Мы получили ваше предложение, наш менеджер свяжется с вами в ближайшее время, чтобы подтвердить его размещение на платформе.',
      })
    } catch (e) {
      httpErrorHandler(e, 'Ошибка при создании предложения')
    } finally {
      setFetching(false)
    }
  }

  const handleSubmitAdminModal = async (data: SubmitAdminModalData) => {
    setFetching(true)
    try {
      await createOfferAdminFn.mutateAsync(data)
      await onAfterCreateOffer?.()
      appToast.success({ description: 'Предложение успешно создано' })
      setVisibleCreateOfferModal(false)
    } catch (e) {
      httpErrorHandler(e, 'Ошибка при создании предложения')
    } finally {
      setFetching(false)
    }
  }

  const handleCreateOfferClick = useCallback(() => {
    if (isDemoUser) {
      userUiSettingsStore.showDemoUserAuthModal()
      return
    }
    if (!companyId) {
      appToast.info({ description: 'Сначала заполните данные о компании' })
      void navigate('/profile/company')
      return
    }
    setVisibleCreateOfferModal(true)
  }, [])

  const isMobile = device === 'mobile'

  const options = [
    { value: 'dateOfAddition', label: 'Дата добавления' },
    { value: 'priceIncrease', label: 'Возрастание цены' },
    {
      value: 'priceDecrease',
      label: 'Убывание цены',
    },
    {
      value: 'distance',
      label: 'Расстояние',
      disabled: !offersFilterStore.filters.distanceFromWarehouse.warehouseId,
    },
  ]

  function onChangeSorting(value: string) {
    switch (value) {
      case 'dateOfAddition':
        offersFilterStore.setOrderBy(OfferSortField.PublishedAt)
        offersFilterStore.setOrder(OrderDirection.Desc)
        break
      case 'priceIncrease':
        offersFilterStore.setOrderBy(OfferSortField.Price)
        offersFilterStore.setOrder(OrderDirection.Asc)
        break
      case 'priceDecrease':
        offersFilterStore.setOrderBy(OfferSortField.Price)
        offersFilterStore.setOrder(OrderDirection.Desc)
        break
      case 'distance':
        offersFilterStore.setOrderBy(OfferSortField.Distance)
        offersFilterStore.setOrder(OrderDirection.Asc)
        break
      default:
        offersFilterStore.setOrderBy(undefined)
        offersFilterStore.setOrder(undefined)
        break
    }
    setCurSortValue(value)
  }

  useEffect(() => {
    if (!offersFilterStore.filters.distanceFromWarehouse.warehouseId && offersFilterStore.orderBy === undefined) {
      onChangeSorting('dateOfAddition')
    }
  }, [offersFilterStore.filters.distanceFromWarehouse.warehouseId, offersFilterStore.orderBy])

  return (
    <S.Header>
      {!isAdmin ? (
        <S.CreatOfferButton type="primary" onClick={handleCreateOfferClick}>
          Создать предложение
        </S.CreatOfferButton>
      ) : (
        <span style={{ marginLeft: 'auto' }} />
      )}

      {location.pathname === '/offers/list' && (
        <Flex gap={8} align="center">
          <Text variant="normal">Сортировать по:</Text>
          <Select
            style={{ width: 200 }}
            value={curSortValue}
            onChange={onChangeSorting}
            options={options}
            optionRender={(option) => (
              <Tooltip title={option.data.disabled ? 'Доступно только при выбранном складе' : undefined}>
                {option.label}
              </Tooltip>
            )}
          />
        </Flex>
      )}

      <div style={{ display: isMobile ? 'none' : 'unset' }}>{filters}</div>

      {!isMobile && <ViewToggle onChange={onChangeView} value={view} />}

      <CreateOfferModal
        visible={isVisibleCreateOfferModal && !isAdmin}
        onClose={handleCancelCreateOfferModal}
        onSubmit={handleSubmitOffer}
        isFetching={isFetching}
      />

      <CreateOfferForAdminModal
        visible={isVisibleCreateOfferModal && isAdmin}
        onClose={handleCancelCreateOfferModal}
        onSubmit={handleSubmitAdminModal}
        isFetching={isFetching}
      />
    </S.Header>
  )
})
