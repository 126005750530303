import type { Theme as StyledSystemTheme } from 'styled-system'

import { addAliases, pxToEm } from '../utils'

import type { Breakpoints } from './types'

export const BREAKPOINTS_LIST = {
  sm: 360,
  md: 768,
  lg: 1024,
  xl: 1366,
  xxl: 1920,
}

export const breakpoints = Object.values(BREAKPOINTS_LIST).map((v) => `${pxToEm(v)}em`) as Breakpoints

/* Добавляем алиасы в breakpoints */
addAliases(breakpoints, Object.keys(BREAKPOINTS_LIST))

export const mediaQueries: StyledSystemTheme['mediaQueries'] = Object.entries(breakpoints).reduce(
  (acc, [k, v]) => ({ ...acc, [k]: `(min-width: ${v})` }),
  {}
)
