import React, { useState } from 'react'

import { Modal } from 'antd'
import { observer } from 'mobx-react-lite'

import { useMutation, useQuery } from '@apollo/client'
import { ApolloError } from '@apollo/client/errors'

import { userUiSettingsStore } from '~/store/userUiSettingsStore'
import { appToast } from '~/utils'
import { graphqlErrorHandler } from '~/utils/graphqlErrorHandler'
import { bankAccountCreateMutation, bankAccountDeleteMutation } from '~api/gql-mutations/bankAccount.mutation.graphql'
import { getBankAccountsQuery, getCompanyVerificationRequirements, getMeQuery } from '~api/gql-query/me.query.graphql'
import { useAuth } from '~providers/auth'

import {
  CompanyAccountPayRequisitesAdd,
  CompanyAccountPayRequisitesAddValues,
} from './components/CompanyAccountPayRequisitesAdd'
import { CompanyAccountPayRequisitesList } from './components/CompanyAccountPayRequisitesList'

export const CompanyAccountPayRequisitesPage: React.FC = observer(() => {
  const { isDemoUser, companyId } = useAuth()
  const getBankAccounts = useQuery(getBankAccountsQuery, {
    skip: !companyId,
    variables: { companyId: Number(companyId) },
  })

  const [bankAccountCreate, bankAccountCreateState] = useMutation(bankAccountCreateMutation, {
    refetchQueries: [getCompanyVerificationRequirements],
  })
  const [bankAccountDelete, bankAccountDeleteState] = useMutation(bankAccountDeleteMutation, {
    refetchQueries: [getCompanyVerificationRequirements],
  })

  const bankAccounts = getBankAccounts?.data?.bankAccounts ?? []
  const [isAdd, setAdd] = useState(false)

  const canAdd = true || isDemoUser // FIXME: заменить на флаг который будет приходить с бэка

  const onAddHandler = () => {
    if (isDemoUser) {
      userUiSettingsStore.showDemoUserAuthModal()
      return
    }
    setAdd(true)
  }
  const onAddHandlerClose = () => setAdd(false)
  const onSubmitHandler = async (formValues: CompanyAccountPayRequisitesAddValues) => {
    if (!companyId) {
      return
    }
    try {
      await bankAccountCreate({
        variables: {
          input: {
            companyId,
            bankName: formValues.bankName,
            paymentAccount: formValues.paymentAccount,
            correspondentAccount: formValues.correspondentAccount,
            bic: formValues.bic,
          },
        },
      })
      setAdd(false)
      appToast.success({ message: 'Платежные реквизиты добавлены' })
    } catch (e) {
      e instanceof ApolloError && graphqlErrorHandler(e, 'Ошибка при добавлении платежных реквизитов')
    }
    await getBankAccounts.refetch()
  }

  const onRemoveHandler = (bankAccountId: number) => {
    Modal.confirm({
      title: 'Удалить платежные реквизиты',
      content: 'Вы действительно хотите платежные реквизиты?',
      okText: 'Да, удалить',
      okButtonProps: { danger: true, loading: bankAccountDeleteState.loading },
      cancelText: 'Отмена',
      async onOk() {
        try {
          await bankAccountDelete({ variables: { bankAccountId } })
          appToast.success({ message: 'Платежные реквизиты удалены' })
        } catch (e) {
          e instanceof ApolloError && graphqlErrorHandler(e, 'Ошибка при удалении платежных реквизитов')
        }
        await getBankAccounts.refetch()
      },
    })
  }

  return (
    <>
      {isAdd ? (
        <CompanyAccountPayRequisitesAdd
          loading={bankAccountCreateState.loading}
          onSubmit={onSubmitHandler}
          onBack={onAddHandlerClose}
        />
      ) : (
        <CompanyAccountPayRequisitesList
          data={bankAccounts}
          onAdd={onAddHandler}
          onRemove={onRemoveHandler}
          loading={getBankAccounts.loading}
        />
      )}
    </>
  )
})
