import React, { FC } from 'react'

import { Button } from 'antd'
import { useNavigate } from 'react-router-dom'

export const LoadDocumentsStep: FC = () => {
  const navigate = useNavigate()
  return (
    <div>
      Загрузите правоустанавливающие документы в профиле в разделе{' '}
      <Button type="link" onClick={() => navigate('/profile/documents', { replace: true })}>
        документы
      </Button>
      <br /> Данный пакет документов загружется один раз и всегда доступен в профиле.
    </div>
  )
}
