import React, { MouseEvent, useState } from 'react'

import { Button, Modal, Skeleton, Space, Tooltip } from 'antd'
import { observer } from 'mobx-react-lite'
import { FiAlertCircle, FiX } from 'react-icons/fi'
import { Link, useNavigate } from 'react-router-dom'

import { useMutation, useQuery } from '@apollo/client'
import { ApolloError } from '@apollo/client/errors'

import { appRoutes } from '~/router'
import { userUiSettingsStore } from '~/store/userUiSettingsStore'
import { Text } from '~/ui-components'
import { appToast } from '~/utils'
import { graphqlErrorHandler } from '~/utils/graphqlErrorHandler'
import { CompanyVerificationStatus } from '~api/generated'
import { UserStateFieldName } from '~api/gql-generated/graphql'
import { companySendOnModerationMutation } from '~api/gql-mutations/company.mutation.graphql'
import { updateMeStateMutation } from '~api/gql-mutations/me.mutation.graphql'
import { getCompanyStateQuery } from '~api/gql-query/company.query.graphql'
import { getCompanyVerificationRequirements } from '~api/gql-query/me.query.graphql'
import { useAuth } from '~providers/auth'

import * as S from './CompanyVerificationStatusInfo.styled'

interface CompanyVerificationStatusInfoProps {
  isMobile?: boolean
  isMenu?: boolean
}

export const CompanyVerificationStatusInfo: React.FC<CompanyVerificationStatusInfoProps> = observer(
  ({ isMobile: isMobile = false, isMenu = false }) => {
    const { companyId } = useAuth()

    const navigate = useNavigate()
    const [isDetailsModalOpen, setIsDetailsModalOpen] = useState(false)
    const companiesVerificationRequirements = useQuery(getCompanyVerificationRequirements)
    const companyStateFn = useQuery(getCompanyStateQuery, {
      variables: { companyId },
      skip: !companyId,
    })
    const [updateMeState] = useMutation(updateMeStateMutation, {
      refetchQueries: [getCompanyStateQuery],
    })
    const [sendOnModeration, sendOnModerationState] = useMutation(companySendOnModerationMutation)
    const isShow = companyStateFn?.data?.company.state.companyVerifiedMessageClosed ?? true

    const selectedCompanyVerificationStatus = companiesVerificationRequirements.data?.me?.companies?.find(
      (x) => x.id === companyId
    )

    const status = selectedCompanyVerificationStatus?.verificationStatus
    const requirements = selectedCompanyVerificationStatus?.verificationRequirements
    const rejectionReason = selectedCompanyVerificationStatus?.rejectionReason

    const onHideStatusInfoHandler = async () => {
      try {
        await updateMeState({
          variables: {
            input: {
              companyId,
              value: String(true),
              fieldName: UserStateFieldName.CompanyVerifiedMessageClosed,
            },
          },
        })
      } catch (e) {
        if (e instanceof ApolloError) {
          graphqlErrorHandler(e, 'Ошибка при изменении состояния компании')
        }
      }
    }

    const onSendCompanyToModeration = async () => {
      try {
        await sendOnModeration({
          variables: {
            companyId,
          },
        })

        appToast.success({ message: 'Компания отправлена на модерацию' })
      } catch (e) {
        e instanceof ApolloError && graphqlErrorHandler(e, 'Ошибка при отправке компании на модерацию')
      }
      await companiesVerificationRequirements.refetch()
    }

    const renderNotVerifiedContent = () => {
      return (
        <S.BadgeContentWrapper>
          <S.BadgeContentText>Чтобы пользоваться преимуществами платформы, вам нужно:</S.BadgeContentText>
          <S.BadgeContentText isDone={requirements?.requisites ?? false}>
            1. Указать <Link to={appRoutes.accountCompanyRequisites.url}>реквизиты компании</Link>
          </S.BadgeContentText>
          <S.BadgeContentText isDone={requirements?.bankAccount ?? false}>
            2. Указать <Link to={appRoutes.accountCompanyPayRequisites.url}>платежные реквизиты</Link> компании
          </S.BadgeContentText>
          <S.BadgeContentText isDone={requirements?.documents ?? false}>
            3. В разделе <Link to={appRoutes.accountCompanyDocs.url}>«Документы»</Link> прикрепить необходимые
            документы, это позволит совершать сделки.
          </S.BadgeContentText>
          <S.BadgeContentText>4. Отправить нам на модерацию.</S.BadgeContentText>
        </S.BadgeContentWrapper>
      )
    }

    const clickOnOpenDetailsHandler = (evt: MouseEvent) => {
      evt.stopPropagation()
      setIsDetailsModalOpen(true)
    }

    const clickOnCloseDetailsHandler = (evt: MouseEvent) => {
      evt.stopPropagation()
      setIsDetailsModalOpen(false)
    }

    const canSendToModeration = requirements?.requisites && requirements?.bankAccount && requirements?.documents

    if (isShow || !status) {
      return null
    }

    if (companiesVerificationRequirements.loading) {
      return <Skeleton />
    }

    if (isMenu) {
      switch (status) {
        case CompanyVerificationStatus.NotVerified: {
          if (canSendToModeration) {
            return (
              <S.BadgeWrapperSmall background="#FFF8EA">
                <FiAlertCircle size={24} onClick={clickOnOpenDetailsHandler} />
                <S.BadgeTitleSmall>Отлично! Информация заполнена.</S.BadgeTitleSmall>
                <S.BadgeTitleButtonSmall onClick={onSendCompanyToModeration} loading={sendOnModerationState.loading}>
                  Отправить на модерацию
                </S.BadgeTitleButtonSmall>
                <Modal
                  open={isDetailsModalOpen}
                  onCancel={() => setIsDetailsModalOpen(false)}
                  width={420}
                  footer={null}
                >
                  <Space direction="vertical" size={32}>
                    <FiAlertCircle size={32} />

                    <Text variant="h1">
                      Отлично!
                      <br /> Информация заполнена.
                    </Text>

                    <Space>
                      <Button
                        type="primary"
                        onClick={onSendCompanyToModeration}
                        loading={sendOnModerationState.loading}
                      >
                        Отправить на модерацию
                      </Button>
                      <Button type="default" onClick={() => setIsDetailsModalOpen(false)}>
                        Отмена
                      </Button>
                    </Space>
                  </Space>
                </Modal>
              </S.BadgeWrapperSmall>
            )
          }

          return (
            <S.BadgeWrapperSmall background={getBgColor(status)}>
              <FiAlertCircle size={24} onClick={clickOnOpenDetailsHandler} />
              <S.BadgeTitleSmall>Доступ к функциям платформы частично ограничен</S.BadgeTitleSmall>
              <S.BadgeTitleButtonSmall onClick={clickOnOpenDetailsHandler}>Подробнее</S.BadgeTitleButtonSmall>

              <Modal open={isDetailsModalOpen} onCancel={() => setIsDetailsModalOpen(false)} width={420} footer={null}>
                <Space direction="vertical" size={32}>
                  <FiAlertCircle size={32} />

                  <Text variant="h1">Доступ к функциям платформы ограничен</Text>

                  {renderNotVerifiedContent()}

                  <Space>
                    <Button
                      type="primary"
                      onClick={() => {
                        navigate(appRoutes.accountCompanyRequisites.url)
                        setIsDetailsModalOpen(false)
                      }}
                    >
                      Перейти к заполнению
                    </Button>
                    <Button type="default" onClick={() => setIsDetailsModalOpen(false)}>
                      Отмена
                    </Button>
                  </Space>
                </Space>
              </Modal>
            </S.BadgeWrapperSmall>
          )
        }
        default: {
          return null
        }
      }
    }

    if (isMobile) {
      switch (status) {
        case CompanyVerificationStatus.NotVerified: {
          if (canSendToModeration) {
            return (
              <S.BadgeWrapperSmall background={getBgColor(status)}>
                <S.BadgeTitleSmall>&#128079; Отлично! Информация заполнена.</S.BadgeTitleSmall>
                <S.BadgeSubmitButtonMobile
                  type="primary"
                  onClick={onSendCompanyToModeration}
                  loading={sendOnModerationState.loading}
                >
                  Отправить на модерацию
                </S.BadgeSubmitButtonMobile>

                <Modal open={isDetailsModalOpen} onCancel={clickOnCloseDetailsHandler} width={420} footer={null}>
                  <Space direction="vertical" size={32}>
                    <FiAlertCircle size={32} />

                    <Text variant="h1">Доступ к функциям платформы ограничен</Text>

                    {renderNotVerifiedContent()}

                    <Space>
                      <Button type="default" onClick={clickOnCloseDetailsHandler}>
                        Закрыть
                      </Button>
                      <Button
                        type="primary"
                        onClick={() => {
                          navigate(appRoutes.accountCompanyRequisites.url)
                          setIsDetailsModalOpen(false)
                        }}
                      >
                        Перейти к заполнению
                      </Button>
                    </Space>
                  </Space>
                </Modal>
              </S.BadgeWrapperSmall>
            )
          }
          return (
            <S.BadgeWrapperSmall background={getBgColor(status)} onClick={clickOnOpenDetailsHandler}>
              <Space style={{ justifyContent: 'space-between' }}>
                <S.BadgeTitleSmall>&#128543; Есть ограничения</S.BadgeTitleSmall>
                <FiAlertCircle size={24} color="#757575" />
              </Space>

              <Modal open={isDetailsModalOpen} onCancel={clickOnCloseDetailsHandler} width={420} footer={null}>
                <Space direction="vertical" size={32}>
                  <FiAlertCircle size={32} />

                  <Text variant="h1">Доступ к функциям платформы ограничен</Text>

                  {renderNotVerifiedContent()}

                  <Space>
                    <Button type="default" onClick={clickOnCloseDetailsHandler}>
                      Закрыть
                    </Button>
                    <Button
                      type="primary"
                      onClick={() => {
                        navigate(appRoutes.accountCompanyRequisites.url)
                        setIsDetailsModalOpen(false)
                      }}
                    >
                      Перейти к заполнению
                    </Button>
                  </Space>
                </Space>
              </Modal>
            </S.BadgeWrapperSmall>
          )
        }
        case CompanyVerificationStatus.OnModeration: {
          return (
            <S.BadgeWrapperSmall background={getBgColor(status)}>
              <S.BadgeTitleSmall>&#128064; На модерации</S.BadgeTitleSmall>
              <S.BadgeContentText>Пожалуйста, подождите. Мы проверяем информацию о компании.</S.BadgeContentText>
            </S.BadgeWrapperSmall>
          )
        }
        case CompanyVerificationStatus.Verified: {
          return (
            <S.BadgeWrapperSmall background={getBgColor(status)}>
              <Space style={{ justifyContent: 'space-between' }}>
                <S.BadgeTitleSmall>&#129321; Модерация прошла успешно! </S.BadgeTitleSmall>
                <S.BadgeSubmitButton type="text" icon={<FiX size={20} />} onClick={onHideStatusInfoHandler} />
              </Space>
              <S.BadgeContentText>
                Теперь вам открыт доступ к полному функционалу платформы. Начнем работу!{' '}
              </S.BadgeContentText>
            </S.BadgeWrapperSmall>
          )
        }
        case CompanyVerificationStatus.Rejected: {
          return (
            <S.BadgeWrapperSmall background={getBgColor(status)}>
              <S.BadgeTitleSmall>&#128532; Модерация не прошла</S.BadgeTitleSmall>
              <S.BadgeContentText>Ой! Кажется вы заполнили данные некорректно.</S.BadgeContentText>
            </S.BadgeWrapperSmall>
          )
        }
        default: {
          return null
        }
      }
    }

    switch (status) {
      case CompanyVerificationStatus.NotVerified: {
        if (canSendToModeration) {
          return (
            <S.BadgeWrapper background="#FFF8EA">
              <S.BadgeTitleWrapper>
                <S.BadgeTitle>&#128079; Отлично!</S.BadgeTitle>
                <S.BadgeSubmitButton
                  type="primary"
                  onClick={onSendCompanyToModeration}
                  loading={sendOnModerationState.loading}
                >
                  Отправить на модерацию
                </S.BadgeSubmitButton>
              </S.BadgeTitleWrapper>
              <S.BadgeContentText>
                Вы заполнили всю необходимую информацию.
                <br /> Давайте же начнем работу, но сначала мы проверим данные.
                <S.BadgeSubmitButtonMobile
                  type="primary"
                  onClick={onSendCompanyToModeration}
                  loading={sendOnModerationState.loading}
                >
                  Отправить на модерацию
                </S.BadgeSubmitButtonMobile>
              </S.BadgeContentText>
            </S.BadgeWrapper>
          )
        }
        return (
          <S.BadgeWrapper background={getBgColor(status)}>
            <S.BadgeTitleWrapper>
              <S.BadgeTitle>&#128543; Доступ к функциям платформы частично ограничен</S.BadgeTitle>
            </S.BadgeTitleWrapper>

            {renderNotVerifiedContent()}
          </S.BadgeWrapper>
        )
      }
      case CompanyVerificationStatus.OnModeration: {
        return (
          <S.BadgeWrapper background={getBgColor(status)}>
            <S.BadgeTitleWrapper>
              <S.BadgeTitle>&#128064; На модерации</S.BadgeTitle>
            </S.BadgeTitleWrapper>
            <S.BadgeContentText>Пожалуйста, подождите. Мы проверяем информацию о компании.</S.BadgeContentText>
          </S.BadgeWrapper>
        )
      }
      case CompanyVerificationStatus.Verified: {
        return (
          <S.BadgeWrapper background={getBgColor(status)}>
            <S.BadgeTitleWrapper>
              <S.BadgeTitle>&#129321; Модерация прошла успешно!</S.BadgeTitle>
              <S.BadgeSubmitButton type="text" icon={<FiX size={20} />} onClick={onHideStatusInfoHandler} />
            </S.BadgeTitleWrapper>
            <S.BadgeContentText>
              Теперь вам открыт доступ к полному функционалу платформы. Начнем работу!
            </S.BadgeContentText>
          </S.BadgeWrapper>
        )
      }
      case CompanyVerificationStatus.Rejected: {
        return (
          <S.BadgeWrapper background={getBgColor(status)}>
            <S.BadgeTitleWrapper>
              <S.BadgeTitle>&#128532; Модерация не прошла</S.BadgeTitle>
              <S.BadgeSubmitButton type="primary" disabled>
                Отправить на модерацию
              </S.BadgeSubmitButton>
            </S.BadgeTitleWrapper>
            <S.BadgeContentWrapper>
              <S.BadgeContentText>
                Ой! Кажется вы заполнили данные некорректно.
                <br /> Посмотрите{' '}
                <Tooltip title={rejectionReason} trigger={['hover', 'click']}>
                  <Button type="link">сообщение от модератора</Button>
                </Tooltip>
                , чтобы подробнее узнать о причине отклонения.
                <S.BadgeSubmitButtonMobile type="primary" disabled>
                  Отправить на модерацию
                </S.BadgeSubmitButtonMobile>
              </S.BadgeContentText>
            </S.BadgeContentWrapper>
          </S.BadgeWrapper>
        )
      }
      default: {
        return null
      }
    }
  }
)

function getBgColor(status: CompanyVerificationStatus) {
  switch (status) {
    case CompanyVerificationStatus.NotVerified:
      return '#F2F3F5'
    case CompanyVerificationStatus.OnModeration:
      return '#FFF'
    case CompanyVerificationStatus.Verified:
      return '#EEF7ED'
    case CompanyVerificationStatus.Rejected:
      return '#FFE4E4'
    default:
      return 'white'
  }
}
