import styled from 'styled-components'

import { breakpoints } from '~/tokens'

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  //gap: 32px;
  @media (max-width: ${breakpoints.MOBILE_XL}px) {
    //width: unset;
  }
`

export const ActionWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 16px;
`
