import styled from 'styled-components'

import { css } from '@styled-system/css'

export const Desktop = styled.div(
  css({
    display: ['none', null, 'block'],
    maxWidth: '100%',
    overflow: 'hidden',
  })
)

export const Mobile = styled.div(
  css({
    display: ['block', null, 'none'],
  })
)
