/* tslint:disable */
/* eslint-disable */
/**
 * API Зерно-Агрегатора
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface BaseDealAdditionalServiceDataType
 */
export interface BaseDealAdditionalServiceDataType {
    /**
     * Тип дополнительной услуги по сделке
     * @type {string}
     * @memberof BaseDealAdditionalServiceDataType
     */
    'type': BaseDealAdditionalServiceDataTypeTypeEnum;
    /**
     * Выбрана ли дополнительная услуга?
     * @type {boolean}
     * @memberof BaseDealAdditionalServiceDataType
     */
    'isSelected': boolean;
    /**
     * Стоимость дополнительной услуги
     * @type {number}
     * @memberof BaseDealAdditionalServiceDataType
     */
    'price': number | null;
    /**
     * Расстояние в километрах
     * @type {number}
     * @memberof BaseDealAdditionalServiceDataType
     */
    'distance': number | null;
}

export const BaseDealAdditionalServiceDataTypeTypeEnum = {
    Shipping: 'SHIPPING',
    Service: 'SERVICE',
    EmptyService: 'EMPTY_SERVICE'
} as const;

export type BaseDealAdditionalServiceDataTypeTypeEnum = typeof BaseDealAdditionalServiceDataTypeTypeEnum[keyof typeof BaseDealAdditionalServiceDataTypeTypeEnum];


