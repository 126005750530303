import styled from 'styled-components'
import { Flex } from 'antd'

export const Wrapper = styled(Flex)`
  justify-content: space-between;
  padding: 32px 0;
  gap: 60px;
`

export const FlexItem = styled(Flex)`
  padding-bottom: 32px;
  border-bottom: 1px solid var(--gray-2-color);
`

export const FlexCompanyWrapper = styled(Flex)`
  background-color: var(--gray-1-color);
  border-radius: 8px;
  padding: 16px;
`

export const FlexNotificationWrapper = styled(Flex)`
  background-color: var(--gray-1-color);
  border-radius: 8px;
  padding: 8px 16px;
`
