import { codeUnreachable } from '~/utils/code-unreachable'
import { RoadSurfaceQuality } from '~api/generated'

export function getRoadSurfaceQualityName(loadType?: RoadSurfaceQuality | null): string {
  if (!loadType) {
    return '-'
  }
  switch (loadType) {
    case RoadSurfaceQuality.AllSeasonal: {
      return 'Всесезонное'
    }
    case RoadSurfaceQuality.Seasonal: {
      return 'Сезонное'
    }
    default: {
      return codeUnreachable(loadType)
    }
  }
}
