import styled from 'styled-components'

import { css } from '@styled-system/css'

import { breakpoints } from '~/tokens'

export const Desktop = styled.div(
  css({
    display: ['none', null, 'block'],
  })
)

export const Mobile = styled.div(
  css({
    display: ['block', null, 'none'],
  })
)

export const Content = styled.div`
  margin: 0 auto;
  display: flex;
  gap: 24px;
  flex-direction: column;

  .row-own {
    background: #fff8ea;
  }

  @media (max-width: ${breakpoints.MOBILE_XL}px) {
    gap: 16px;
  }
`

export const NotificationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 24px;
  width: 1000px;

  @media (max-width: ${breakpoints.DESKTOP_S}px) {
    padding: 16px;
    width: unset;
  }

  @media (max-width: ${breakpoints.MOBILE_XL}px) {
    padding: 16px;
    width: unset;
  }
`

export const Notification = styled.div`
  padding: 16px;
  background: #ffffff;
  box-shadow:
    0 1px 2px #d6d6d6,
    0 4px 12px #e3e3e3;
  border-radius: 8px;
  display: flex;
  gap: 16px;
  align-items: center;
  @media (max-width: ${breakpoints.MOBILE_XL}px) {
    align-items: unset;
  }
`

export const TextWrapper = styled.div`
  flex-grow: 1;
`

export const ReadButtonWrapper = styled.div<{ isShow: boolean }>`
  opacity: 0;
  display: ${(props) => (props.isShow ? 'unset' : 'none')};
  pointer-events: none;
  min-width: 30px;
  ${Notification}:hover & {
    pointer-events: all;
    opacity: 1;
  }
`

export const NotificationDate = styled.div`
  color: #494949;
  text-align: end;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  white-space: nowrap;
`

export const NotReadStatus = styled.div<{ isShow: boolean }>`
  background: var(--badge-green-text-color);
  color: var(--badge-green-bg-color);
  border-radius: 6px;
  padding: 4px 12px;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  display: ${(props) => (props.isShow ? 'unset' : 'none')};
  height: max-content;
`

export const ActionWrapper = styled.div`
  margin-bottom: 16px;
`

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
`

export const NotificationMobile = styled(Notification)`
  display: flex;
  flex-direction: column;
`
