/* tslint:disable */
/* eslint-disable */
/**
 * API Зерно-Агрегатора
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { BaseOfferData } from './base-offer-data';

/**
 * 
 * @export
 * @interface OrderData
 */
export interface OrderData {
    /**
     * Id заявки
     * @type {number}
     * @memberof OrderData
     */
    'id': number;
    /**
     * Предложение-основа заявки
     * @type {BaseOfferData}
     * @memberof OrderData
     */
    'offer': BaseOfferData;
    /**
     * Ещё не выбранный по заявке объём
     * @type {number}
     * @memberof OrderData
     */
    'notSelectedVolume': number;
    /**
     * Статус заявки
     * @type {string}
     * @memberof OrderData
     */
    'status': OrderDataStatusEnum;
    /**
     * Следующие возможные статусы
     * @type {Array<string>}
     * @memberof OrderData
     */
    'nextStatuses': Array<OrderDataNextStatusesEnum>;
    /**
     * Id модератора, проверяющего заявку
     * @type {number}
     * @memberof OrderData
     */
    'moderatedBy': number | null;
    /**
     * Время создания заявки
     * @type {string}
     * @memberof OrderData
     */
    'createdAt': string;
    /**
     * Время начала заявки
     * @type {string}
     * @memberof OrderData
     */
    'startedAt': string;
    /**
     * Время окончании заявки
     * @type {string}
     * @memberof OrderData
     */
    'endedAt': string;
}

export const OrderDataStatusEnum = {
    OnModeration: 'ON_MODERATION',
    InSearching: 'IN_SEARCHING',
    Rejected: 'REJECTED',
    Realised: 'REALISED'
} as const;

export type OrderDataStatusEnum = typeof OrderDataStatusEnum[keyof typeof OrderDataStatusEnum];
export const OrderDataNextStatusesEnum = {
    OnModeration: 'ON_MODERATION',
    InSearching: 'IN_SEARCHING',
    Rejected: 'REJECTED',
    Realised: 'REALISED'
} as const;

export type OrderDataNextStatusesEnum = typeof OrderDataNextStatusesEnum[keyof typeof OrderDataNextStatusesEnum];


