import React from 'react'

import { Button } from 'antd'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'

import { appRoutes } from '~/router'
import { Text } from '~/ui-components'

import { ActionsContent } from '~ui-components/components/Card/Card.styled'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`

export const Description = styled(Text).attrs({ variant: 'normal' })`
  margin-top: 4px;
  color: var(--gray-6-color);
`
export const Actions = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
`

interface CreateAdvertOfferSuccessStepProps {
  onClose: () => void
}
export const CreateAdvertOfferSuccessStep: React.FC<CreateAdvertOfferSuccessStepProps> = (props) => {
  const navigate = useNavigate()

  const navigateToMyBidsHandler = () => navigate(appRoutes.basisMyBids.url)
  const navigateBackHandler = () => props.onClose()

  return (
    <Wrapper>
      <Text variant="h3" style={{ alignSelf: 'center' }}>
        Отклик оставлен
      </Text>
      <Description>Сейчас отклик на проверке. Мы пришлем уведомление, когда администратор его проверит.</Description>
      <Description variant="description">
        Эту и другие ставки вы можете посмотреть во вкладке&nbsp;
        <Button type="link" onClick={navigateToMyBidsHandler}>
          Мои отклики
        </Button>
      </Description>

      <Actions>
        <Button htmlType="button" type="primary" block onClick={navigateBackHandler}>
          Вернуться к предложениям
        </Button>
        <Button htmlType="button" type="default" block onClick={navigateToMyBidsHandler}>
          Перейти к моим откликам
        </Button>
      </Actions>
    </Wrapper>
  )
}
