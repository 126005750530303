import React from 'react'

import { useParams } from 'react-router'

import { AppHeader } from '~/layout'
import { Box } from '~/ui-components'
import { EntityModel } from '~api/gql-generated/graphql'
import { Notes } from '~pages/ModeratorPage/components/Notes/Notes'
import { PersonalProfileSection } from '~pages/ProfilePage/components/tabs/EmployeeProfileTab/components'

import * as S from './ModeratorUserListTab.styled'

export const ModeratorUserPage: React.FC = () => {
  const { id } = useParams<{ id: string | undefined }>()
  const userId = Number(id)

  return (
    <>
      <AppHeader title={'Пользователь ID ' + userId} isBack />

      <div style={{ marginLeft: 'auto', padding: '4px 24px' }}>
        <Notes entityId={userId} relatedModel={EntityModel.User} />
      </div>

      <S.ContentWrapper>
        <Box minHeight="268px">
          <PersonalProfileSection hideAddUser userId={userId} />
        </Box>
      </S.ContentWrapper>
    </>
  )
}
