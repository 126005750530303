import React, { useEffect } from 'react'

import { Pagination, Skeleton } from 'antd'
import { observer } from 'mobx-react-lite'
import { useNavigate } from 'react-router-dom'

import { usePagination } from '~/hooks'
import { appRoutes } from '~/router'
import { myBasisAdvertOffersFilterStore } from '~/store/filters/myBasisAdvertsFilterStore'
import { userUiSettingsStore } from '~/store/userUiSettingsStore'
import { useGetCurrentRole } from '~hooks/auth'
import { useGetDevice } from '~hooks/common'
import { useGetElevatorAdvertOffersMy } from '~hooks/elevator-advert-offers'
import { MyBasisAdvertsListFiltersInline } from '~pages/BasisPage/components/MyBasisAdvertsListFiltersInline'
import { MyAdvertOffersListMobile } from '~pages/BasisPage/modules/MyAdvertOffers/components/MyAdvertOffersList/components/MyAdvertOffersListMobile'
import { MyAdvertOffersListTable } from '~pages/BasisPage/modules/MyAdvertOffers/components/MyAdvertOffersList/components/MyAdvertOffersListTable'
import { useAuth } from '~providers/auth'

import * as S from './MyAdvertOffersList.styled'

export const MyAdvertOffersList: React.FC = observer(() => {
  const { companyId } = useAuth()
  const {
    company: { companyRole },
  } = useGetCurrentRole()
  const navigate = useNavigate()
  const device = useGetDevice()
  const isMobile = device === 'mobile'

  const { currentPage, setCurrentPage, pageSize, setPageSize, pageSizeOptions } = usePagination()

  const filters = myBasisAdvertOffersFilterStore.basisFilters

  const getMyAdvertOffersFn = useGetElevatorAdvertOffersMy({
    userCompanyId: companyId,
    currentPage,
    pageSize,
    elevatorIds: filters.elevatorId ? [filters.elevatorId] : undefined,
    productTypes: filters.productTypes,
    elevatorOfferType: filters.advertType,
  })

  useEffect(() => {
    setCurrentPage(1)
  }, [filters.productTypes.length, filters.elevatorId, filters.advertStatuses.length, filters.advertType])

  const pagination = getMyAdvertOffersFn.data?.pagination
  const offers = getMyAdvertOffersFn.data?.elevatorAdvertOffers

  const onItemClickHandler = (offerId: number) => {
    navigate(appRoutes.basisAdvertOffer.url.replace(':id', offerId.toString()))
  }

  if (getMyAdvertOffersFn.isLoading || !companyRole) {
    return <Skeleton />
  }

  return (
    <div>
      <S.FiltersWrapper>
        <MyBasisAdvertsListFiltersInline store={myBasisAdvertOffersFilterStore} />
      </S.FiltersWrapper>

      {isMobile ? (
        <MyAdvertOffersListMobile
          offers={offers}
          onOfferClick={onItemClickHandler}
          isLoading={getMyAdvertOffersFn.isFetching}
          currentCompanyRole={companyRole}
        />
      ) : (
        <MyAdvertOffersListTable
          offers={offers}
          onOfferClick={onItemClickHandler}
          isLoading={getMyAdvertOffersFn.isFetching}
          currentCompanyRole={companyRole}
        />
      )}
      <br />
      {pagination && (
        <Pagination
          current={pagination?.currentPage}
          total={pagination?.total}
          pageSize={pagination?.pageSize}
          pageSizeOptions={pageSizeOptions}
          onShowSizeChange={(x, size) => setPageSize(size)}
          showSizeChanger
          onChange={setCurrentPage}
        />
      )}
    </div>
  )
})
