import { lazy } from 'react'

export { LandingCreateOffer } from './LandingCreateOffer'

export const AuthPage = lazy(() => import('./AuthPage'))
export const AccountPage = lazy(() => import('./AccountPage'))
export const Warehouses = lazy(() => import('./Warehouses'))
export const OffersPage = lazy(() => import('./OffersPage'))
export const BasisPage = lazy(() => import('./BasisPage'))
export const SettingsPage = lazy(() => import('./SettingsPage'))
export const EmployeesPage = lazy(() => import('./EmployeesPage'))
export const NotificationsPage = lazy(() => import('./Notifications'))
export const ModeratorPage = lazy(() => import('./ModeratorPage'))
export const CarParkPage = lazy(() => import('./CarParkPage'))
