import React from 'react'

import { formatPhoneNumber } from '~/utils/formatPhoneNumber'

interface IPhoneNumberProps {
  phoneNumber: string | undefined | null
  prefix?: string
  defaultValueWhenPhoneEmpty?: string
}

export const PhoneNumber: React.FC<IPhoneNumberProps> = ({
  phoneNumber,
  prefix = '',
  defaultValueWhenPhoneEmpty = '-',
}) => {
  if (!phoneNumber) {
    return <span>{defaultValueWhenPhoneEmpty}</span>
  }
  return (
    <a href={`tel:${prefix}${phoneNumber.replace(/\s/g, '')}`}>
      {prefix}
      {formatPhoneNumber(phoneNumber)}
    </a>
  )
}
