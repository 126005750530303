import React, { FC, useEffect, useRef } from 'react'

import { getNumberFromString } from '~/utils/getNumberFromString'

import { InputCodeProps } from './Input.types'

import * as S from './Input.styled'

const INPUT_ID = 'input-code'

const setInputFocus = (wrapper: HTMLDivElement | null, index: number) => {
  ;(wrapper?.querySelector('#' + INPUT_ID + index) as HTMLInputElement)?.focus()
}

export const InputCode: FC<InputCodeProps> = ({ code, onChange }) => {
  const wrapperRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (code.join('').trim().length === 0) {
      setTimeout(() => setInputFocus(wrapperRef.current, 0), 300)
    }
  }, [code])

  const handleInputChange = (target: HTMLInputElement, inputIndex: number) => {
    const value = target.value.trim().slice(0, 1)

    if (value !== getNumberFromString(value)) {
      return
    }

    if (value) {
      setInputFocus(wrapperRef.current, inputIndex + 1)
    }

    onChange(code.map((it, index) => (index === inputIndex ? value : it)))
  }

  const handleInputKeyDown = (keyCode: string, index: number) => {
    if (keyCode !== 'Backspace') {
      return
    }

    if (code[index] === '') {
      setInputFocus(wrapperRef.current, index - 1)
    }
  }

  return (
    <S.Wrapper ref={wrapperRef}>
      {code.map((value, index) => (
        <S.Input
          key={index}
          id={INPUT_ID + index}
          max={1}
          type="number"
          value={value}
          autoComplete="off"
          pattern="\d*"
          onKeyDown={(evt) => {
            handleInputKeyDown(evt.key, index)
          }}
          onChange={(evt) => handleInputChange(evt.target, index)}
        />
      ))}
    </S.Wrapper>
  )
}
