import styled from 'styled-components'

import { breakpoints } from '~/tokens'
import { Text } from '~/ui-components'

export const ChangeStatusText = styled(Text).attrs({ variant: 'normal' })`
  color: var(--gray-4-color);

  flex: 1;
  @media (max-width: ${breakpoints.MOBILE_XL}px) {
    text-align: center;
  }
`

export const SpaceWrapper = styled.div<{ gap?: number }>`
  display: flex;
  width: 100%;
  align-items: center;
  gap: ${(props) => (props.gap ? '' + props.gap + 'px' : '16px')};

  @media (max-width: ${breakpoints.MOBILE_XL}px) {
    flex-direction: column;
  }
`
