import styled from 'styled-components'

import { breakpoints } from '~/tokens'

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 0;

  @media (max-width: ${breakpoints.DESKTOP_S}px) {
    padding: 0;
  }
`

export const FiltersWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 0 24px;

  @media (max-width: ${breakpoints.DESKTOP_S}px) {
    padding: 0;
  }
`
