import { codeUnreachable } from '~/utils/code-unreachable'
import { FreightDealDocumentType } from '~api/gql-generated/graphql'

export function getLogisticDealDocumentName(documentType: FreightDealDocumentType): string {
  switch (documentType) {
    case FreightDealDocumentType.ActOfCompletedWork:
      return 'Акт выполненных работ'
    case FreightDealDocumentType.ActOfCompletedWorkSigned:
      return 'Акт выполненных работ (подписанный)'
    case FreightDealDocumentType.CarriageContractAnnex:
      return 'Приложение'
    case FreightDealDocumentType.CarriageContractAnnexSigned:
      return 'Приложение (подписанное)'
    case FreightDealDocumentType.CarriageContract:
      return 'Договор перевозки'
    case FreightDealDocumentType.CarriageContractSigned:
      return 'Договор перевозки (подписанный)'
    case FreightDealDocumentType.BillOfLadingForLoading:
      return 'ТТН(товарно-транспортная накладная) погрузки'
    case FreightDealDocumentType.BillOfLadingForUnloading:
      return 'ТТН(товарно-транспортная накладная) приемки'
    case FreightDealDocumentType.ConsignmentNoteForLoading:
      return 'ТН(транспортная накладная) погрузки'
    case FreightDealDocumentType.ConsignmentNoteForUnloading:
      return 'ТН(транспортная накладная) приемки'
    case FreightDealDocumentType.RegisterOfActualLoading:
      return 'Реестр фактической погрузки(выгрузки)'
    case FreightDealDocumentType.RegisterOfActualUnloading:
      return 'Реестр фактической приемки'
    case FreightDealDocumentType.TransportRegisterForLoading:
      return 'Реестр авто на погрузку'
    case FreightDealDocumentType.TransportRegisterForUnloading:
      return 'Реестр авто на приемку'
    default:
      return codeUnreachable(documentType)
  }
}
