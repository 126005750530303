import styled from 'styled-components'

import { breakpoints } from '~/tokens'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  @media (max-width: ${breakpoints.MOBILE_XL}px) {
    width: 100%;
  }
`
export const FormRow = styled.div`
  display: flex;
  gap: 32px;
  @media (max-width: ${breakpoints.MOBILE_XL}px) {
    flex-direction: column;
    gap: unset;
  }
`

export const ActionWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 16px;
  // @media (max-width: ${breakpoints.MOBILE_XL}px) {
  //   width: unset;
  //   display: none;
  // }
`
