import styled from 'styled-components'

import css from '@styled-system/css'

import { Text } from '~/ui-components'
import { Box } from '~/ui-components'

export const ContentWrapper = styled(Box)(
  css({
    display: 'flex',
    alignItems: 'center',
    gap: '16px',
    marginBottom: '24px',
  })
)

export const ActionsWrapper = styled.div`
  display: flex;
  gap: 16px;
`

export const Avatar = styled.div`
  flex-shrink: 0;
`

export const Description = styled(Text).attrs({ variant: 'description' })`
  color: #696e73;
  font-size: 10px;
  font-weight: 500;
  line-height: 16px;
`
