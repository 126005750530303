import styled from 'styled-components'
import { Flex } from 'antd'

export const Wrapper = styled(Flex)`
  padding: 32px 0;
`

export const ReadValueWrapper = styled.div`
  border: 1px solid var(--gray-2-color);
  border-radius: 6px;
  padding: 8px 16px;
`

export const OwnerWrapper = styled(Flex)`
  border-left: 2px solid var(--primary-main-color);
  padding: 0 16px;
`
