// TODO: подумать как объединить с маской у инпута
export const formatPhoneNumber = (str: string | null | undefined = '') => {
  if (typeof str !== 'string') return ''

  const cleaned = str.replace(/\D/g, '')
  const match = cleaned.match(/^(\?*\d|)?(\d{3})(\d{3})(\d{2})(\d{2})$/)

  if (match) {
    const intlCode = match[1] ? `+${match[1]}` : ''

    return [intlCode, ' (', match[2], ') ', match[3], '-', match[4], '-', match[5]].join('')
  }

  return str
}
