import { useMutation } from '@tanstack/react-query'

import { useApi } from '~providers/api'

interface IRemoveBankAccount {
  warehouseId: number
}

export const useRemoveWarehouse = () => {
  const api = useApi()

  return useMutation(({ warehouseId }: IRemoveBankAccount) => {
    return api.warehouses.warehouseControllerDeleteWarehouse({
      warehouseId,
    })
  })
}
