import { useQuery, UseQueryResult } from '@tanstack/react-query'

import { GetOfferResponse, OfferApiOfferControllerGetOfferRequest } from '~/api/generated'
import { useApi } from '~/providers/api'

export const useGetOfferById = ({
  offerId,
  userCompanyId,
}: OfferApiOfferControllerGetOfferRequest): UseQueryResult<GetOfferResponse> => {
  const api = useApi()

  const fetchFn = async (): Promise<GetOfferResponse> => {
    if (!offerId) {
      throw new Error('[useGetOfferById]: !offerId')
    }

    const response = await api.offers.offerControllerGetOffer({
      userCompanyId,
      offerId,
    })

    return response.data
  }

  return useQuery(['offer', offerId], fetchFn, { enabled: !!offerId })
}
