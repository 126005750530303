import React from 'react'

import { Button, Dropdown, MenuProps, Modal } from 'antd'
import { useNavigate } from 'react-router-dom'

import { DownOutlined } from '@ant-design/icons'

import { appRoutes } from '~/router'
import { TransportEntity } from '~api/gql-generated/graphql'

interface AssignedDriverTransportActionMenuProps {
  transport: TransportEntity
  onUnassignTransport: (transportId: number) => void
}
export function AssignedDriverTransportActionMenu({
  transport,
  onUnassignTransport,
}: AssignedDriverTransportActionMenuProps) {
  const navigate = useNavigate()

  const items: MenuProps['items'] = [
    {
      label: 'Отвязать ТС от водителя',
      key: '1',
      onClick: () =>
        Modal.confirm({
          title: 'Отвязать ТС от водителя?',
          content: 'Вы точно хотите отвязать данного водителя от транспорта?',
          okText: 'Да, отвязать',
          cancelText: 'Отмена',
          async onOk() {
            onUnassignTransport(transport.id)
          },
        }),
    },
    {
      label: 'На страницу ТС',
      key: '2',
      onClick: () => navigate(appRoutes.carParkTransportCard.url.replace(':id', transport.id.toString())),
    },
  ]

  const menuProps = {
    items,
  }

  return (
    <Dropdown menu={menuProps}>
      <Button>
        <DownOutlined />
        Действия
      </Button>
    </Dropdown>
  )
}
