import { useQuery, UseQueryResult } from '@tanstack/react-query'

import { isApiResponseEmpty } from '~/utils/isApiResponseEmpty'
import { GetDocumentsResponse } from '~api/generated'
import { useApi } from '~providers/api'

export const useGetCompanyDocumentList = (
  companyId: number | undefined | null
): UseQueryResult<GetDocumentsResponse> => {
  const api = useApi()

  const fetchFn = async (): Promise<GetDocumentsResponse> => {
    if (!companyId) {
      return Promise.reject('companyId was not passed')
    }

    try {
      const res = await api.companies.companyDocumentControllerGetDocuments({
        companyId,
      })

      return res.data
    } catch (error) {
      if (isApiResponseEmpty(error)) {
        return Promise.reject(error)
      }

      return Promise.reject(error)
    }
  }

  return useQuery(['companyDocumentList', companyId], fetchFn)
}
