import React from 'react'
import * as S from './TransportationDataInfo.styled'
import { Text } from '~/ui-components'
import { TransportationDataInfoProps } from './TransportationDataInfo.types'
import { Button, Flex } from 'antd'
import { useNavigate } from 'react-router-dom'
import { appRoutes } from '~/router'

export const TransportationDataInfo: React.FC<TransportationDataInfoProps> = ({
  transportModes,
  transportationArea,
  regions,
  address,
  isExistTransportationData,
}) => {
  const navigate = useNavigate()

  return (
    <S.Wrapper>
      <S.ContentWrapper>
        {isExistTransportationData && (
          <Flex justify="space-between" align="center">
            <Text variant="normal">
              Транспортные данные позволяют видеть заявки в вашем регионе и удаленность от вашего автопарка
            </Text>
            <Button
              type="primary"
              htmlType="button"
              onClick={() => navigate(appRoutes.carParkEditTransportationData.url)}
            >
              Редактировать
            </Button>
          </Flex>
        )}

        <Flex vertical gap={24}>
          <S.FormReadItem>
            <Text variant="form-read-label">Виды грузоперевозок</Text>
            <Text variant="form-read-value">{transportModes}</Text>
          </S.FormReadItem>

          <S.FormReadItem>
            <Text variant="form-read-label">Территория грузоперевозок</Text>
            <Text variant="form-read-value">{transportationArea}</Text>
          </S.FormReadItem>

          <S.FormReadItem>
            <Text variant="form-read-label">Регионы грузоперевозок</Text>
            <Text variant="form-read-value">{regions}</Text>
          </S.FormReadItem>

          <S.FormReadItem>
            <Text variant="form-read-label">Адрес парка</Text>
            <Text variant="form-read-value">{address}</Text>
          </S.FormReadItem>
        </Flex>
      </S.ContentWrapper>
    </S.Wrapper>
  )
}
