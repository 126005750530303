import { TransportType } from '~api/gql-generated/graphql'
import { ReactComponent as TruckIcon } from '~assets/icons/truck.svg'
import { ReactComponent as TractorIcon } from '~assets/icons/tractor.svg'
import { ReactComponent as TrailerIcon } from '~assets/icons/trailer.svg'
import { ReactComponent as SemitrailerIcon } from '~assets/icons/semitrailer.svg'
import { codeUnreachable } from '~/utils/code-unreachable'

export const getTransportTypeIcon = (transportType: TransportType) => {
  switch (transportType) {
    case TransportType.Truck:
      return <TruckIcon />
    case TransportType.Tractor:
      return <TractorIcon />
    case TransportType.Trailer:
      return <TrailerIcon />
    case TransportType.SemiTrailer:
      return <SemitrailerIcon />
    default:
      return codeUnreachable(transportType)
  }
}
