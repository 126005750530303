import React, { useEffect } from 'react'

import { Pagination, Skeleton } from 'antd'
import { observer } from 'mobx-react-lite'
import { useNavigate } from 'react-router-dom'

import { usePagination } from '~/hooks'
import { appRoutes } from '~/router'
import { myBasisAdvertsFilterStore } from '~/store/filters'
import { userUiSettingsStore } from '~/store/userUiSettingsStore'
import { useGetCurrentRole } from '~hooks/auth'
import { useGetDevice } from '~hooks/common'
import { useGetMyElevatorAdverts } from '~hooks/elevator-advert'
import { MyAdvertsListMobile } from '~pages/BasisPage/modules/MyAdvert/components/MyAdvertsList/components/MyAdvertsListMobile/MyAdvertsListMobile'
import { MyAdvertsListTable } from '~pages/BasisPage/modules/MyAdvert/components/MyAdvertsList/components/MyAdvertsListTable'
import { useAuth } from '~providers/auth'

// import * as S from './MyAdvertsList.styled'

export const MyAdvertsList: React.FC = observer(() => {
  const { companyId } = useAuth()
  const navigate = useNavigate()
  const device = useGetDevice()
  const isMobile = device === 'mobile'
  const {
    company: { companyRole },
  } = useGetCurrentRole()

  const { currentPage, setCurrentPage, pageSize, setPageSize, pageSizeOptions } = usePagination()

  const filters = myBasisAdvertsFilterStore.basisFilters
  const getElevatorAdvertsFn = useGetMyElevatorAdverts({
    userCompanyId: companyId,
    elevatorIds: filters.elevatorId ? [filters.elevatorId] : undefined,
    productTypes: filters.productTypes,
    statuses: filters.advertStatuses,
    type: filters.advertType,
    currentPage,
    pageSize,
  })

  useEffect(() => {
    setCurrentPage(1)
  }, [filters.productTypes.length, filters.elevatorId, filters.advertStatuses.length, filters.advertType])

  const adverts = getElevatorAdvertsFn.data?.elevatorAdverts
  const pagination = getElevatorAdvertsFn.data?.pagination

  const onItemClickHandler = (advertId: number) => {
    navigate(appRoutes.basisAdvert.url.replace(':id', advertId.toString()))
  }

  if (!companyRole) {
    return <Skeleton />
  }

  return (
    <>
      {isMobile ? (
        <MyAdvertsListMobile
          adverts={adverts}
          isLoading={getElevatorAdvertsFn.isFetching}
          onAdvertClick={onItemClickHandler}
          currentCompanyRole={companyRole}
        />
      ) : (
        <MyAdvertsListTable
          adverts={adverts}
          isLoading={getElevatorAdvertsFn.isFetching}
          onAdvertClick={onItemClickHandler}
          currentCompanyRole={companyRole}
        />
      )}

      {!!adverts?.length && pagination && (
        <Pagination
          current={pagination?.currentPage}
          total={pagination?.total}
          pageSize={pagination?.pageSize}
          pageSizeOptions={pageSizeOptions}
          onShowSizeChange={(x, size) => setPageSize(size)}
          showSizeChanger
          onChange={setCurrentPage}
        />
      )}
    </>
  )
})
