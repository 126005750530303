import React from 'react'

import { Modal } from 'antd'

import { ApolloClient, from, HttpLink, InMemoryCache } from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import * as Sentry from '@sentry/react'

import { ZaGraphQLError } from '~/utils/graphqlErrorHandler'
import { NoAccessCompanyModalErrorHandler } from '~/utils/no-access-company-modal-error-handler'

const httpLink = new HttpLink({
  uri: process.env.REACT_APP_GRAPHQL_URL,
  credentials: 'include',
})

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach((error) => {
      const { message, locations, path, exceptionName } = error as ZaGraphQLError
      const errorMsg = `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      console.error(errorMsg, error)
      Sentry.captureException(errorMsg)

      if (exceptionName === 'UserHasNoAccessToCompanyException') {
        NoAccessCompanyModalErrorHandler()
      }
    })

  if (networkError) {
    console.error(`[Network error]: ${networkError}`)
    Sentry.captureException(new Error(networkError.message))
  }
})

export const graphQlClient = new ApolloClient({
  link: from([errorLink, httpLink]),
  cache: new InMemoryCache(),
  connectToDevTools: true,
  name: 'ZernoAgregator',
  version: '1.0',
})
