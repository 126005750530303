import styled from 'styled-components'

import { breakpoints } from '~/tokens'

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 24px;

  @media (max-width: ${breakpoints.DESKTOP_S}px) {
    padding: 12px;
  }
`
export const AdvertTypeToggle = styled.div`
  margin-bottom: 8px;
  margin-top: 24px;
  width: 300px;
  display: flex;
  flex-direction: column;
  gap: 8px;

  @media (max-width: ${breakpoints.DESKTOP_S}px) {
    margin-top: 16px;
    width: 100%;
  }
`

export const Header = styled.div`
  display: flex;
  gap: 16px;
  justify-content: space-between;
  align-items: end;
  @media (max-width: ${breakpoints.DESKTOP_S}px) {
    flex-direction: column-reverse;
    align-items: unset;
  }
`
