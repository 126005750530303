import styled from 'styled-components'

import css from '@styled-system/css'

export const ContentWrapper = styled.div(
  css({
    minWidth: ['260px', '320px', '460px'],
  })
)
export const ActionWrapper = styled.div(
  css({
    display: 'flex',
    justifyContent: 'end',
  })
)
