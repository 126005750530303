import React from 'react'

import { Checkbox, Flex, Modal, Skeleton } from 'antd'
import { CheckboxChangeEvent } from 'antd/es/checkbox'
import { useParams } from 'react-router'

import { useMutation, useQuery } from '@apollo/client'
import { ApolloError } from '@apollo/client/errors'

import { graphqlErrorHandler } from '~/utils/graphqlErrorHandler'
import { EntityModel, UserStateFieldName } from '~api/gql-generated/graphql'
import { userUpdateStateMutation } from '~api/gql-mutations/user.mutation.graphql'
import { getCompanyStateQuery } from '~api/gql-query/company.query.graphql'
import { VIP_CLIENTS } from '~layout/WelcomePageV2/VipCards'
import { Notes } from '~pages/ModeratorPage/components/Notes/Notes'
import { CompanyProfileTab } from '~pages/ProfilePage/components'
import { useAuth } from '~providers/auth'

import * as S from './ModeratorCompanyInfo.styled'

export const ModeratorCompanyInfo: React.FC = () => {
  const { userId } = useAuth()
  const { id } = useParams<{ id: string | undefined }>()
  const companyId = Number(id)

  const companyStateFn = useQuery(getCompanyStateQuery, {
    variables: { companyId },
    skip: !companyId,
  })

  const [updateState] = useMutation(userUpdateStateMutation, {
    refetchQueries: [getCompanyStateQuery],
  })

  const hasPriorityPass = VIP_CLIENTS.gapResurs.isShow || VIP_CLIENTS.damate.isShow

  const handleSetPriorityAccess = (e: CheckboxChangeEvent) => {
    Modal.confirm({
      title: 'Дать приоритетный доступ?',
      content: 'После этого данная компания сможет видеть vip клиентов',
      okText: 'Да',
      cancelText: 'Отмена',
      async onOk() {
        if (!companyId) {
          return
        }
        try {
          await updateState({
            variables: {
              userId: Number(userId),
              input: {
                fieldName: UserStateFieldName.CanUsePriorityCompanies,
                value: String(e.target.checked),
                companyId,
              },
            },
          })
        } catch (e) {
          if (e instanceof ApolloError) {
            graphqlErrorHandler(e, 'Ошибка при установки признака компании')
          }
        }
      },
    })
  }

  if (companyStateFn.loading) {
    return <Skeleton loading />
  }

  return (
    <S.ContentWrapper>
      <Flex>
        <Checkbox value={hasPriorityPass} onChange={handleSetPriorityAccess}>
          Приоритетный доступ
        </Checkbox>

        <div style={{ marginLeft: 'auto' }}>
          <Notes entityId={companyId} relatedModel={EntityModel.Company} />
        </div>
      </Flex>

      <CompanyProfileTab companyId={companyId} isAdmin={true} />
    </S.ContentWrapper>
  )
}
