import React, { useCallback, useEffect } from 'react'

import { debounce } from 'lodash'
import { isEqual } from 'lodash'
import { observer } from 'mobx-react-lite'
import { Controller, useForm } from 'react-hook-form'

import { AppConfig } from '~/appConfig'
import { SearchInput } from '~shared/components/SearchInput'
import { CultureSelectControl, RegionSelectControl } from '~shared/controls'

import { ActiveFiltersInlineProps, IBasisListFiltersInlineForm } from './BasisListFiltersInline.types'

import * as S from './BasisListFiltersInline.styled'

export const BasisListFiltersInline: React.FC<ActiveFiltersInlineProps> = observer((props) => {
  const store = props.store

  const currentValues = store.basisFilters

  const { watch, control, getValues, setValue } = useForm<IBasisListFiltersInlineForm>({
    defaultValues: {
      name: currentValues.name,
      regionIds: currentValues.regionIds,
      productTypes: currentValues.productTypes,
    },
  })
  const watchedFields = watch()

  useEffect(() => {
    const formValues = getValues()
    if (!isEqual(currentValues.regionIds, formValues.regionIds)) {
      setValue('regionIds', currentValues.regionIds)
    }
    if (!isEqual(currentValues.name, formValues.name)) {
      setValue('name', currentValues.name)
    }
    if (!isEqual(currentValues.productTypes, formValues.productTypes)) {
      setValue('productTypes', currentValues.productTypes)
    }
  }, [currentValues.regionIds, currentValues.name, currentValues.productTypes])

  const onFiltersChange = (formValues: IBasisListFiltersInlineForm) => {
    store.setFilters({
      ...store.basisFilters,
      regionIds: formValues.regionIds,
      name: formValues.name,
      productTypes: formValues.productTypes,
    })
  }

  const debouncedChangeValues = useCallback(
    debounce(() => onFiltersChange(getValues()), AppConfig.DEFAULT_DEBOUNCE_TIME),
    []
  )

  useEffect(() => {
    debouncedChangeValues()
  }, [watchedFields.regionIds, watchedFields.name, watchedFields.productTypes])

  return (
    <S.StyledForm>
      <S.ItemSelect>
        <Controller
          name="name"
          control={control}
          render={({ field }) => (
            <SearchInput
              defaultValue={field.value}
              onSearch={field.onChange}
              autoFocus={false}
              placeholder="Поиск базиса"
            />
          )}
        />
      </S.ItemSelect>
      <S.ItemSelect>
        <Controller
          name="regionIds"
          control={control}
          render={({ field }) => <RegionSelectControl {...field} isHiddenDisabled={false} />}
        />
      </S.ItemSelect>
      <S.ItemSelect>
        <Controller name="productTypes" control={control} render={({ field }) => <CultureSelectControl {...field} />} />
      </S.ItemSelect>
    </S.StyledForm>
  )
})
