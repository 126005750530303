import React from 'react'

import { Select } from 'antd'
import { SelectProps } from 'antd/es/select'
import { BaseSelectRef } from 'rc-select'

import { useQuery } from '@apollo/client'

import { getRegionsQuery } from '~api/gql-query/car-park.query.graphql'
import { CustomControlInterface } from '~shared/controls/CustomControl.interface'

type Value = number

interface CommonProps extends CustomControlInterface {
  hasError?: boolean
  id?: string
  placeholder?: string
  allowClear?: boolean
}

interface SelectPropsMult extends CommonProps {
  mode: 'multiple'
  onChange: (value: Value[]) => void
  value: Value[] | undefined
}

interface SelectPropsSingl extends CommonProps {
  mode: 'single'
  onChange: (value: Value | undefined) => void
  value: Value | undefined
}

type RegionsSelectControlProps = SelectPropsMult | SelectPropsSingl

export const RegionsSelectControl: React.FC<RegionsSelectControlProps> = React.forwardRef<
  BaseSelectRef,
  RegionsSelectControlProps
>(({ mode, value, onChange, hasError, id, placeholder = 'Выберите' }, ref) => {
  const getRegions = useQuery(getRegionsQuery)
  const regions = getRegions.data?.regions ?? []

  const sharedProps: SelectProps & { ref?: React.Ref<BaseSelectRef> } = {
    id,
    ref,
    defaultValue: value,
    status: hasError ? 'error' : undefined,
    style: { width: '100%' },
    onChange: onSelectHandler,
    loading: getRegions.loading,
    disabled: getRegions.loading,
    placeholder,
    allowClear: true,
  }

  function onSelectHandler(v: Value | Value[]) {
    if (Array.isArray(v) && mode === 'multiple') {
      onChange(v)
    } else if (mode === 'single') {
      onChange(v as Value)
    }
  }

  const options = regions.map((region) => (
    <Select.Option key={region.id} value={region.id}>
      {region.name}
    </Select.Option>
  ))

  if (mode === 'multiple') {
    return (
      <Select {...sharedProps} mode="multiple" maxTagCount={1}>
        {options}
      </Select>
    )
  }

  return <Select {...sharedProps}>{options}</Select>
})

RegionsSelectControl.displayName = 'RegionsSelectControl'
