import * as yup from 'yup'

import { phoneNumberValidator, VALIDATORS_MSG } from '~shared/validations'

const requiredTrimmedString = yup.string().trim().required(VALIDATORS_MSG.required)

const notRequiredTrimmedString = yup.string().trim().notRequired()

export const personalProfileFormSchema = yup
  .object({
    name: requiredTrimmedString,
    surname: notRequiredTrimmedString,
    patronymic: notRequiredTrimmedString,
    position: notRequiredTrimmedString,
    email: notRequiredTrimmedString
      .nullable()
      .transform((value, originalValue) => {
        return originalValue === '' ? null : value
      })
      .email(VALIDATORS_MSG.email),
    phoneNumber: yup
      .string()
      .test('mobileNumber', 'Неверный формат номера телефона', phoneNumberValidator)
      .trim()
      .notRequired(),
  })
  .required()
