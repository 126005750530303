import React, { useState } from 'react'

import { Button, Modal } from 'antd'
import { FiArrowLeft } from 'react-icons/fi'

import { modalSharedSettings } from '~/utils'
import { TransportMode } from '~api/generated'
import {
  CreateElevatorFormStep1,
  CreateElevatorFormStep2,
  CreateElevatorFormStep3Rail,
  CreateElevatorFormStep4Car,
  CreateElevatorFormStep5ServicePrice,
} from '~pages/SettingsPage/modules/ElevatorsAdmin/components/CreateElevatorForm'

import { CreateElevatorFormStep1Values } from '~pages/SettingsPage/modules/ElevatorsAdmin/components/CreateElevatorForm/step1/CreateElevatorFormStep1.types'
import { CreateElevatorFormStep2Values } from '~pages/SettingsPage/modules/ElevatorsAdmin/components/CreateElevatorForm/step2/CreateElevatorFormStep2.types'
import { CreateElevatorFormStep3RailValues } from '~pages/SettingsPage/modules/ElevatorsAdmin/components/CreateElevatorForm/step3Rail/CreateElevatorFormStep3Rail.types'
import { CreateElevatorFormStep4CarValues } from '~pages/SettingsPage/modules/ElevatorsAdmin/components/CreateElevatorForm/step4Car/CreateElevatorFormStep4Car.types'
import { CreateElevatorFormStep5ServicePriceValues } from '~pages/SettingsPage/modules/ElevatorsAdmin/components/CreateElevatorForm/step5ServicePrice/CreateElevatorFormStep5ServicePrice.types'

export interface CreateBasisFormValue {
  step1: CreateElevatorFormStep1Values
  step2: CreateElevatorFormStep2Values
  step3Rail: CreateElevatorFormStep3RailValues | null
  step4Car: CreateElevatorFormStep4CarValues | null
  step5ServicePrice: CreateElevatorFormStep5ServicePriceValues
}

interface CreateElevatorModalProps {
  open: boolean
  onCancel: () => void
  onSubmit: (formValues: CreateBasisFormValue) => void
  defaultValues?: CreateBasisFormValue
  lastStepSubmitLoading: boolean
}

export const CreateElevatorModal: React.FC<CreateElevatorModalProps> = (props) => {
  const [step, setStep] = useState<number>(1)
  const [step1FormValues, setStep1FormValues] = useState<CreateElevatorFormStep1Values | null>(null)
  const [step2FormValues, setStep2FormValues] = useState<CreateElevatorFormStep2Values | null>(null)
  const [step3FormValues, setStep3FormValues] = useState<CreateElevatorFormStep3RailValues | null>(null)
  const [step4FormValues, setStep4FormValues] = useState<CreateElevatorFormStep4CarValues | null>(null)
  const [step5FormValues, setStep5FormValues] = useState<CreateElevatorFormStep5ServicePriceValues | null>(null)
  const isEdit = !!props.defaultValues

  const onCancelHandler = () => {
    setStep1FormValues(null)
    setStep(1)
    props.onCancel()
  }

  const onSubmit = (step5FormValues: CreateElevatorFormStep5ServicePriceValues) => {
    if (!step1FormValues || !step2FormValues) {
      console.warn('Не заполнен шаг 1 или 2')
      return
    }
    props.onSubmit({
      step1: step1FormValues,
      step2: step2FormValues,
      step3Rail: step3FormValues,
      step4Car: step4FormValues,
      step5ServicePrice: step5FormValues,
    })
    onCancelHandler()
  }

  const getTitle = () => {
    if (step === 1) {
      return isEdit ? 'Редактирование базиса' : 'Добавление базиса'
    }
    if (step === 2) {
      return (
        <div style={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
          <Button type="text" icon={<FiArrowLeft size={24} />} onClick={() => setStep(1)} />
          Параметры базиса
        </div>
      )
    }
    if (step === 3) {
      return (
        <div style={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
          <Button type="text" icon={<FiArrowLeft size={24} />} onClick={() => setStep(2)} />
          Параметры по Ж/Д транспорту
        </div>
      )
    }
    if (step === 4) {
      return (
        <div style={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
          <Button type="text" icon={<FiArrowLeft size={24} />} onClick={() => setStep(step3FormValues ? 3 : 2)} />
          Параметры по автотранспорту
        </div>
      )
    }
    if (step === 5) {
      return (
        <div style={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
          <Button type="text" icon={<FiArrowLeft size={24} />} onClick={() => setStep(2)} />
          Услуги базиса
        </div>
      )
    }
  }

  return (
    <Modal {...modalSharedSettings} width={500} open={props.open} onCancel={onCancelHandler} title={getTitle()}>
      <div hidden={step !== 1}>
        <CreateElevatorFormStep1
          onNext={(formValues) => {
            setStep1FormValues(formValues)
            setStep(2)
          }}
          onCancel={onCancelHandler}
          defaultValues={props.defaultValues?.step1}
        />
      </div>
      <div hidden={step !== 2}>
        <CreateElevatorFormStep2
          step1Data={step1FormValues}
          onPrev={() => setStep(1)}
          onNext={(formValues) => {
            setStep2FormValues(formValues)
            if (formValues.transport.includes(TransportMode.Rail)) {
              setStep(3)
              return
            }
            if (formValues.transport.includes(TransportMode.Road)) {
              setStep(4)
              return
            }
            setStep(5)
          }}
          onCancel={onCancelHandler}
          defaultValues={props.defaultValues?.step2}
        />
      </div>
      <div hidden={step !== 3}>
        <CreateElevatorFormStep3Rail
          step1Data={step1FormValues}
          onPrev={() => setStep(2)}
          onNext={(formValues) => {
            setStep3FormValues(formValues)
            if (step2FormValues?.transport.includes(TransportMode.Road)) {
              setStep(4)
              return
            }
            setStep(5)
          }}
          onCancel={onCancelHandler}
          defaultValues={props.defaultValues?.step3Rail ?? undefined}
        />
      </div>
      <div hidden={step !== 4}>
        <CreateElevatorFormStep4Car
          step1Data={step1FormValues}
          onPrev={() => setStep(step3FormValues ? 3 : 2)}
          onNext={(formValues) => {
            setStep4FormValues(formValues)
            setStep(5)
          }}
          onCancel={onCancelHandler}
          defaultValues={props.defaultValues?.step4Car ?? undefined}
        />
      </div>
      <div hidden={step !== 5}>
        <CreateElevatorFormStep5ServicePrice
          step1Data={step1FormValues}
          loading={props.lastStepSubmitLoading}
          onPrev={() => setStep(2)}
          onNext={(formValues) => {
            setStep5FormValues(formValues)
            onSubmit(formValues)
          }}
          onCancel={onCancelHandler}
          defaultValues={props.defaultValues?.step5ServicePrice}
        />
      </div>
    </Modal>
  )
}
