import { useQuery, UseQueryResult } from '@tanstack/react-query'

import { AuctionApiAuctionControllerGetModeratedAuctionsRequest, GetModeratedAuctionsResponse } from '~/api/generated'
import { useApi } from '~/providers/api'
import { isApiResponseEmpty } from '~/utils/isApiResponseEmpty'

export const useGetModeratedAuctions = (
  request: AuctionApiAuctionControllerGetModeratedAuctionsRequest
): UseQueryResult<GetModeratedAuctionsResponse> => {
  const api = useApi()

  const fetchFn = async (): Promise<GetModeratedAuctionsResponse | null> => {
    try {
      return (await api.auction.auctionControllerGetModeratedAuctions(request)).data
    } catch (error) {
      if (isApiResponseEmpty(error)) {
        return null
      }

      return Promise.reject(error)
    }
  }

  return useQuery(['moderatedAuction', request.currentPage, request.pageSize], fetchFn, { keepPreviousData: true })
}
