import styled from 'styled-components'

import css from '@styled-system/css'

export const CultureSelect = styled('div')(css({}))

export const CultureInputsWrap = styled('div')<{ hasError: boolean }>(({ hasError }) =>
  css({
    position: 'relative',
    display: 'grid',
    gridTemplateColumns: ['repeat(2, 1fr)', null, 'repeat(3, 1fr)'],
    alignItems: 'end',
    columnGap: '16px',
    padding: '16px 16px 32px',
    border: '1px solid',
    borderColor: hasError ? 'red' : 'Gray300',
    borderRadius: 3,
    overflow: 'hidden',
    marginTop: '24px',
    minHeight: '130px',
  })
)

export const Wrapper = styled('div')(
  css({
    // width: ['100%', null, '550px']
  })
)

export const ItemTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  & button {
    height: auto !important;
  }
`

export const Required = styled('span')(
  css({
    color: 'RedTertiary',
  })
)

export const CultureInputWrap = styled.div(
  css({
    display: 'flex',
    alignItems: 'center',
    gap: 4,

    '.ant-input-number': {
      flexGrow: 1,
      width: 'unset !important',
    },
  })
)

export const Label = styled('label')(
  css({
    display: 'block',
    marginBottom: '8px',
    color: 'TxtDefault',
    variant: 'typography.navbarFormsLabel',
    fontWeight: 600,
  })
)

export const CultureDescription = styled('div')(
  css({
    variant: 'typography.textExtrasmallRegular',
    marginTop: 4,
    span: {
      color: 'RedTertiary',
    },
  })
)

export const CultureUnit = styled('div')(
  css({
    width: '25px',
    variant: 'typography.navbarFormsLabel',
    whiteSpace: 'nowrap',
  })
)

export const CultureToggle = styled('button')(
  css({
    position: 'absolute',
    bottom: 0,
    left: 0,
    display: 'block',
    width: '100%',
    height: '32px',
    padding: '0',
    backgroundColor: 'transparent',
    border: 'none',
    cursor: 'pointer',

    '&:hover': {
      backgroundColor: 'GreenTertiary',
    },

    '&:focus': {
      outline: 'none',
    },
  })
)
