import * as yup from 'yup'

import { VALIDATORS_MSG } from '~shared/validations'

const transformEmptyStringFn = (value: string) => (value === '' ? undefined : value)
const transformEmptyNumberFn = (value: number) =>
  isNaN(value) || value === null || value === undefined ? undefined : value
export const cultureParameterEditFormSchema = yup.object({
  name: yup.string().trim().required(VALIDATORS_MSG.required),
  measurementUnit: yup.string().trim().transform(transformEmptyStringFn),
  minimum: yup.number().transform(transformEmptyNumberFn),
  maximum: yup.number().transform(transformEmptyNumberFn),
  gost: yup.string().trim().transform(transformEmptyStringFn),
})
