import { useMutation } from '@tanstack/react-query'

import { CreateElevatorAdvertResponse, ElevatorAdvertApiCreateElevatorAdvertSellRequest } from '~api/generated'
import { useApi } from '~providers/api'

export const useCreateElevatorAdvertSell = () => {
  const api = useApi()

  const useCreateElevatorAdvertFn = async (
    request: ElevatorAdvertApiCreateElevatorAdvertSellRequest
  ): Promise<CreateElevatorAdvertResponse> => {
    const res = await api.elevatorAdvert.createElevatorAdvertSell(request)

    return res.data
  }

  return useMutation(useCreateElevatorAdvertFn)
}
