/* tslint:disable */
/* eslint-disable */
/**
 * API Зерно-Агрегатора
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { BaseOfferData } from './base-offer-data';

/**
 * 
 * @export
 * @interface CompanyAuctionData
 */
export interface CompanyAuctionData {
    /**
     * Id торгов
     * @type {number}
     * @memberof CompanyAuctionData
     */
    'id': number;
    /**
     * Предложение-основа торгов
     * @type {BaseOfferData}
     * @memberof CompanyAuctionData
     */
    'offer': BaseOfferData;
    /**
     * Ещё не выбранный по торгам объём
     * @type {number}
     * @memberof CompanyAuctionData
     */
    'notSelectedVolume': number;
    /**
     * Статус торгов
     * @type {string}
     * @memberof CompanyAuctionData
     */
    'status': CompanyAuctionDataStatusEnum;
    /**
     * Время окончании торгов
     * @type {string}
     * @memberof CompanyAuctionData
     */
    'endedAt': string;
    /**
     * Следующие возможные статусы
     * @type {Array<string>}
     * @memberof CompanyAuctionData
     */
    'nextStatuses': Array<CompanyAuctionDataNextStatusesEnum>;
    /**
     * Время создания аукциона
     * @type {string}
     * @memberof CompanyAuctionData
     */
    'createdAt': string;
}

export const CompanyAuctionDataStatusEnum = {
    OnModeration: 'ON_MODERATION',
    PreStarted: 'PRE_STARTED',
    InProgress: 'IN_PROGRESS',
    Expired: 'EXPIRED',
    Rejected: 'REJECTED',
    Realised: 'REALISED'
} as const;

export type CompanyAuctionDataStatusEnum = typeof CompanyAuctionDataStatusEnum[keyof typeof CompanyAuctionDataStatusEnum];
export const CompanyAuctionDataNextStatusesEnum = {
    OnModeration: 'ON_MODERATION',
    PreStarted: 'PRE_STARTED',
    InProgress: 'IN_PROGRESS',
    Expired: 'EXPIRED',
    Rejected: 'REJECTED',
    Realised: 'REALISED'
} as const;

export type CompanyAuctionDataNextStatusesEnum = typeof CompanyAuctionDataNextStatusesEnum[keyof typeof CompanyAuctionDataNextStatusesEnum];


