import React, { useEffect, useState } from 'react'

import { DaDataAddress, DaDataSuggestion } from 'react-dadata/dist/types'

import { useGetAddressDadata } from '~/hooks'
import { InputDadata } from '~/ui-components'
import { CustomControlInterface } from '~shared/controls/CustomControl.interface'

interface AddressControlDadataProps extends CustomControlInterface {
  value: string | undefined
  defaultValue?: string
  onChange: (value: DaDataSuggestion<DaDataAddress> | undefined) => void
  hasError?: boolean
  id?: string
  disabled?: boolean
  placeholder?: string
}

export const AddressControlDadata: React.FC<AddressControlDadataProps> = React.forwardRef<
  HTMLInputElement,
  AddressControlDadataProps
>(
  (
    { id, value, onChange, hasError, disabled = false, placeholder = 'г Москва, Московская ул. 1 дом 1. стр. 1.' },
    _ref
  ) => {
    const [inputValue, setInputValue] = useState<string | undefined>('')

    const { data: address } = useGetAddressDadata(value)

    useEffect(() => {
      if (!value) {
        setInputValue('')
      }
    }, [value])

    useEffect(() => {
      if (address) {
        onChange(address)
        setSelectedValue(address)
        setInputValue(address.value)
      }
    }, [address])

    const [selectedValue, setSelectedValue] = useState<DaDataSuggestion<DaDataAddress> | undefined>(undefined)
    const handleChangeInput = (v: string | undefined) => {
      setInputValue(v)

      if (v === '') {
        onChange(undefined)
        setSelectedValue(undefined)
      }
    }

    const handleSelectAddress = (v: DaDataSuggestion<DaDataAddress> | undefined) => {
      onChange(v)
      setSelectedValue(v)
    }

    const handleInputBlur = () => {
      setInputValue(selectedValue?.value)
    }

    return (
      <InputDadata
        id={id}
        disabled={disabled}
        placeholder={placeholder}
        inputValue={inputValue}
        onChangeInput={handleChangeInput}
        onSelect={handleSelectAddress}
        variant="address"
        inputStatus={hasError ? 'error' : undefined}
        onBlurInput={handleInputBlur}
      />
    )
  }
)

AddressControlDadata.displayName = 'AddressControlDadata'
