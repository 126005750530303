import React, { useState } from 'react'

import { Button, Flex, Modal, Skeleton, Space, Spin } from 'antd'
import { AiFillCheckCircle, AiOutlineClockCircle } from 'react-icons/ai'
import { Link, useNavigate, useParams } from 'react-router-dom'

import { useGetProductTypeData } from '~/hooks'
import { AppHeader } from '~/layout'
import { OffersListItemMobile } from '~/pages/BasisPage/modules/MyAdvertOffers/components/OffersListItemMobile'
import { appRoutes } from '~/router'
import { Badge, Card, Text, UploadButton } from '~/ui-components'
import { appToast, httpErrorHandler, modalSharedSettings } from '~/utils'
import { getElevatorAdvertStatus } from '~/utils/elevator-adverts/get-elevator-advert-status'
import { formatNumber } from '~/utils/formatNumber'
import { getDateTimeDayFormat, getDateTimeFormat, getTimeDurationFormat } from '~/utils/getDateFormat'
import { getNdsString } from '~/utils/getNdsString'
import { getUserRoleOld } from '~/utils/user/getUserRoleOld'
import { ElevatorOfferStatus } from '~api/generated'
import { EntityModel } from '~api/gql-generated/graphql'
import { useUploadFileAndGetId } from '~hooks/_utils'
import {
  useGetElevatorAdvertAdmin,
  useUpdateElevatorAdvertByAdmin,
  useUpdateElevatorAdvertModerator,
  useUpdateElevatorAdvertStatusByAdmin,
} from '~hooks/elevator-advert-admin'
import { useGetElevatorAdvertOffersByAdmin } from '~hooks/elevator-advert-offers-admin'
import {
  CreateAdvertFormByBuyer,
  CreateAdvertFormByBuyerValues,
} from '~pages/BasisPage/components/CreateAdvertFormByBuyer'
import {
  CreateAdvertFormBySeller,
  CreateAdvertFormBySellerValues,
} from '~pages/BasisPage/components/CreateAdvertFormBySeller'
import { ModeratorBasisAdvertOfferRemoveModal } from '~pages/ModeratorPage/components/ModeratorBasisAdvert/ModeratorBasisAdvertRemoveModal'
import { Notes } from '~pages/ModeratorPage/components/Notes/Notes'
import { useAuth } from '~providers/auth'
import { PhoneNumber } from '~shared/components'
import { ParametersCultureControlValue } from '~shared/controls/ParametersCultureControl'

import * as S from './ModeratorBasisAdvertDetails.styled'
import { PublishDateCountdown } from '~pages/BasisPage/modules/BasisAdvert/BasisAdvert.styled'

const FORM_ID = 'edit-advert-form-id'

export const ModeratorBasisAdvertDetails: React.FC = () => {
  const navigate = useNavigate()
  const { userId } = useAuth()

  const { id: basisAdvertId } = useParams<{ id: string | undefined }>()
  const { getProductTypeParametersForUI_V2 } = useGetProductTypeData()
  const [isRejectAdvertModalVisible, setIsRejectAdvertModalVisible] = useState(false)
  const [isEditAdvertModalOpen, setIsEditAdvertModalOpen] = useState(false)

  const getElevatorAdvertAdminFn = useGetElevatorAdvertAdmin({ elevatorAdvertId: Number(basisAdvertId) })
  const getElevatorAdvertOffers = useGetElevatorAdvertOffersByAdmin({ elevatorAdvertId: Number(basisAdvertId) })
  const offers = getElevatorAdvertOffers.data?.elevatorAdvertOffers

  const updateAdvertStatusFn = useUpdateElevatorAdvertStatusByAdmin()
  const updateElevatorAdvertModeratorFn = useUpdateElevatorAdvertModerator()
  const updateElevatorAdvertFn = useUpdateElevatorAdvertByAdmin()
  const uploadLabAnalysisAndGetIdFn = useUploadFileAndGetId()

  const elevatorAdvert = getElevatorAdvertAdminFn.data

  const parameters: string[] = getProductTypeParametersForUI_V2(elevatorAdvert?.product)
  const status = elevatorAdvert ? getElevatorAdvertStatus(elevatorAdvert.status) : null
  const cultureName = elevatorAdvert?.product.name

  const onCancelEditAdvertModalHandler = () => {
    setIsEditAdvertModalOpen(false)
  }

  const changeAdvertStatus = async (status: ElevatorOfferStatus, description: string, rejectionReason?: string) => {
    if (!basisAdvertId) return

    try {
      await updateAdvertStatusFn.mutateAsync({
        id: Number(basisAdvertId),
        adminUpdateElevatorAdvertStatusBody: {
          status,
          rejectionReason,
        },
      })
      appToast.success({ description })
    } catch (e) {
      httpErrorHandler(e, 'Ошибка при обновлении статуса объявления')
    }

    await getElevatorAdvertAdminFn.refetch()
  }

  const handleAuctionToPublishClick = () => {
    Modal.confirm({
      title: 'Вы уверены что хотите опубликовать объявление?',
      okText: 'Опубликовать',
      cancelText: 'Отмена',
      onOk() {
        void changeAdvertStatus(ElevatorOfferStatus.Published, 'Объявление опубликовано')
      },
    })
  }

  const handleAuctionToRejectClick = (reason: string) => {
    void changeAdvertStatus(ElevatorOfferStatus.Rejected, 'Объявление отклонено', reason)
  }
  const handleAuctionToDeactivateClick = () => {
    Modal.confirm({
      title: 'Вы уверены что хотите снять с публикации объявление?',
      okText: 'Снять с публикации',
      cancelText: 'Отмена',
      onOk() {
        void changeAdvertStatus(ElevatorOfferStatus.Deactivated, 'Объявление снято')
      },
    })
  }

  const handleAuctionToWorkButtonClick = async () => {
    if (!basisAdvertId || !userId) return

    try {
      await updateElevatorAdvertModeratorFn.mutateAsync({
        elevatorAdvertId: +basisAdvertId,
      })

      appToast.success({ description: `Вы взяли в работу объявление №${basisAdvertId}` })
    } catch (e) {
      httpErrorHandler(e, 'Ошибка при обновлении модератора объявления')
    }
    await getElevatorAdvertAdminFn.refetch()
  }

  const onSubmitEditAdvertModalHandler = async (formValues: CreateAdvertFormByBuyerValues) => {
    if (!basisAdvertId || !userId) return

    try {
      await updateElevatorAdvertFn.mutateAsync({
        id: +basisAdvertId,
        adminUpdateElevatorAdvertBody: {
          elevatorId: formValues.elevatorId,
          pricePerTon: formValues.pricePerTon,
          allowOffersWithOtherParameters: formValues.allowOffersWithOtherParameters,
          product: {
            type: formValues.culture?.cultureTypeId ?? '',
            parameters: formValues.culture?.parameters?.map((p) => ({ type: p.type, value: p.value })) ?? [],
          },
        },
      })

      appToast.success({ description: `Объявление №${basisAdvertId} отредактировано` })
      setIsEditAdvertModalOpen(false)
      await getElevatorAdvertAdminFn.refetch()
    } catch (e) {
      httpErrorHandler(e, 'Ошибка при изменении объявления')
    }
  }
  const onSubmitEditAdvertBySellerModalHandler = async (formValues: CreateAdvertFormBySellerValues) => {
    if (!basisAdvertId || !userId) return

    let laboratoryAnalysisFileKey: undefined | string
    if (formValues.labAnalysis instanceof File) {
      laboratoryAnalysisFileKey = await uploadLabAnalysisAndGetIdFn.mutateAsync(formValues.labAnalysis)
    }

    try {
      await updateElevatorAdvertFn.mutateAsync({
        id: +basisAdvertId,
        adminUpdateElevatorAdvertBody: {
          elevatorId: formValues.elevatorId,
          pricePerTon: formValues.pricePerTon,
          priceType: formValues.priceType,
          includingNds: formValues.usingNds,
          volume: formValues.volume,
          laboratoryAnalysisFileKey,
          product: {
            type: formValues.culture?.cultureTypeId ?? '',
            parameters: formValues.culture?.parameters?.map((p) => ({ type: p.type, value: p.value })) ?? [],
          },
        },
      })

      appToast.success({ description: `Объявление №${basisAdvertId} отредактировано` })
      setIsEditAdvertModalOpen(false)
      await getElevatorAdvertAdminFn.refetch()
    } catch (e) {
      httpErrorHandler(e, 'Ошибка при изменении объявления')
    }
  }

  const handleNavigateToOfferInfo = (offerId: number) => {
    if (!offerId) return
    navigate(appRoutes.moderatorBasisAdvertOfferDetails.url.replace(':id', offerId.toString()))
  }

  const isShowActionButtons = elevatorAdvert?.moderator?.id === userId

  const cultureDefaultControl: ParametersCultureControlValue = {
    cultureTypeId: elevatorAdvert?.product?.type,
    parameters: elevatorAdvert?.product?.parameters,
    hasError: false,
  }

  const isShowPublishDateCountdown =
    elevatorAdvert?.expiredAt &&
    [
      ElevatorOfferStatus.Published,
      ElevatorOfferStatus.OnModeration,
      ElevatorOfferStatus.OnModerationWithAnalysis,
    ].some((s) => s === elevatorAdvert.status)

  return (
    <>
      <AppHeader title={`${appRoutes.moderatorBasisAdvertDetails.title} ${basisAdvertId}`} isBack />
      <S.ContentWrapper>
        <S.Header>
          {elevatorAdvert?.moderator?.id && (
            <>
              <S.TextModerator variant="normal">В работе у модератора</S.TextModerator>
              <S.TextModeratorName variant="normal">{elevatorAdvert.moderator.name}</S.TextModeratorName>
            </>
          )}

          {elevatorAdvert?.moderator?.id !== userId && (
            <Button htmlType="button" type="primary" onClick={handleAuctionToWorkButtonClick}>
              Взять в работу
            </Button>
          )}

          <div style={{ marginLeft: 'auto' }}>
            <Notes entityId={Number(basisAdvertId)} relatedModel={EntityModel.ElevatorOffer} />
          </div>
        </S.Header>

        {elevatorAdvert?.status === 'ON_MODERATION_WITH_ANALYSIS' && (
          <S.AnalysisWrapper>
            <S.HasAnalysisText>
              <AiFillCheckCircle size={22} />
              Выбрана услуга <b>«Заказать отбор качества»</b>
            </S.HasAnalysisText>
            <Text variant="normal">Свяжитесь с продавцом для уточнения информации!</Text>
          </S.AnalysisWrapper>
        )}

        <Spin spinning={getElevatorAdvertAdminFn.isFetching}>
          <Card>
            <S.CardContent>
              <Flex justify="space-between">
                <Card.Title>{cultureName}</Card.Title>

                {isShowPublishDateCountdown && (
                  <PublishDateCountdown>
                    <AiOutlineClockCircle size={16} /> {getTimeDurationFormat(new Date(), elevatorAdvert.expiredAt!)}
                  </PublishDateCountdown>
                )}
              </Flex>

              {status && (
                <S.StatusWrapper>
                  <Badge color={status.color} size="s">
                    {status.name}
                  </Badge>

                  {elevatorAdvert?.rejectionReason && (
                    <Text variant="normal-bold">
                      Причина отклонения:{' '}
                      <Text as="span" variant="normal-bold" color="red">
                        {elevatorAdvert?.rejectionReason}
                      </Text>
                    </Text>
                  )}
                </S.StatusWrapper>
              )}

              {!!parameters.length && (
                <S.Parameters>
                  {parameters.map((param) => (
                    <Badge key={param} color="gray" isRound>
                      {param}
                    </Badge>
                  ))}
                </S.Parameters>
              )}
              {elevatorAdvert?.laboratoryAnalysis?.source && (
                <UploadButton
                  fileName="Лаб. анализ"
                  isReadOnly
                  onFileClick={() => window.open(elevatorAdvert?.laboratoryAnalysis?.source, '_blank')}
                />
              )}

              <S.DividerStyled />

              <S.Row>
                <Card.Label>Цена</Card.Label>
                <Space align="baseline">
                  <S.CardValueGreen>{formatNumber(elevatorAdvert?.pricePerTon)} ₽/тонна</S.CardValueGreen>
                  <S.CardValueGreen>
                    {elevatorAdvert?.usingNds !== undefined && `(${getNdsString(elevatorAdvert?.usingNds ?? false)})`}
                  </S.CardValueGreen>
                  {elevatorAdvert?.priceUpdatedAt && (
                    <S.PublishedAt>
                      Обновлено {getDateTimeFormat(new Date(elevatorAdvert.priceUpdatedAt))}
                    </S.PublishedAt>
                  )}
                </Space>
              </S.Row>

              {elevatorAdvert?.volume && (
                <S.Row>
                  <Card.Label>Объем</Card.Label>
                  <Card.Value>
                    <S.CardValueGreen>{elevatorAdvert.volume} т</S.CardValueGreen>
                  </Card.Value>
                </S.Row>
              )}

              <S.Row>
                <Card.Label>Базис</Card.Label>
                <Card.Value>
                  <Link to={appRoutes.basisInfo.url.replace(':id', elevatorAdvert?.elevator?.id?.toString() ?? '')}>
                    {elevatorAdvert?.elevator?.name ?? '-'}
                  </Link>
                </Card.Value>
              </S.Row>

              <S.Row>
                <Card.Label>Локация</Card.Label>
                <Card.Value>{elevatorAdvert?.elevator?.address ?? '-'}</Card.Value>
              </S.Row>

              <S.Row>
                <Card.Label>Дата создания</Card.Label>
                <Card.Value>
                  {elevatorAdvert?.createdAt ? getDateTimeFormat(new Date(elevatorAdvert?.createdAt)) : '-'}
                </Card.Value>
              </S.Row>

              <S.DividerStyled />

              <S.Row>
                <Card.Label>Компания</Card.Label>
                <Card.Value>
                  <Link
                    to={appRoutes.moderatorCompanyDetails.url.replace(
                      ':id',
                      elevatorAdvert?.company?.id?.toString() ?? ''
                    )}
                  >
                    {elevatorAdvert?.company?.name ?? '-'}
                  </Link>
                </Card.Value>
              </S.Row>

              <S.Row>
                <Card.Label>ИНН</Card.Label>
                <Card.Value>{elevatorAdvert?.company?.inn ?? '-'}</Card.Value>
              </S.Row>

              <S.Row>
                <Card.Label>Контактное лицо</Card.Label>
                <Card.Value>{elevatorAdvert?.contactUser ? elevatorAdvert.contactUser.name : '-'}</Card.Value>
              </S.Row>
              <S.Row>
                <Card.Label>Телефон</Card.Label>
                <Card.Value>
                  <PhoneNumber phoneNumber={elevatorAdvert?.contactUser?.phoneNumber} prefix={''} />
                </Card.Value>
              </S.Row>

              <S.DividerStyled />

              <S.Row>
                <Card.Label>Объявление создал</Card.Label>
                <Card.Value>
                  <>
                    {elevatorAdvert?.createdByUser.role ? getUserRoleOld(elevatorAdvert.createdByUser.role) : null}:
                    <br />
                    <span>{elevatorAdvert?.createdByUser?.name}</span>
                    <br />
                    ID:
                    <Button type="link">
                      <Link
                        to={appRoutes.moderatorUserDetails.url.replace(
                          ':id',
                          elevatorAdvert?.createdByUser?.id.toString() ?? ''
                        )}
                      >
                        {elevatorAdvert?.createdByUser.id}
                      </Link>
                    </Button>
                  </>
                </Card.Value>
              </S.Row>
              <S.Row>
                <Card.Label>Дата создания</Card.Label>
                <Card.Value>
                  {elevatorAdvert?.createdAt ? getDateTimeDayFormat(elevatorAdvert.createdAt) : '-'}
                </Card.Value>
              </S.Row>
            </S.CardContent>
          </Card>
        </Spin>

        <S.ActionButtons>
          {elevatorAdvert?.status === 'ON_MODERATION_WITH_ANALYSIS' && (
            <Text variant="description">
              Прежде чем опубликовать объявление, необходимо загрузить лаб. анализы. Убедитесь, что указанные параметры
              совпадают с полученными лаб. анализами.
            </Text>
          )}
          <Button
            type="text"
            onClick={() => setIsRejectAdvertModalVisible(true)}
            disabled={!(isShowActionButtons && elevatorAdvert?.nextStatuses.includes('REJECTED'))}
          >
            Отклонить
          </Button>
          <Button
            type="text"
            onClick={handleAuctionToDeactivateClick}
            disabled={!(isShowActionButtons && elevatorAdvert?.nextStatuses.includes('DEACTIVATED'))}
          >
            Снять с публикации
          </Button>
          <Button type="default" onClick={() => setIsEditAdvertModalOpen(true)} disabled={!isShowActionButtons}>
            Редактировать
          </Button>
          <Button
            type="primary"
            onClick={handleAuctionToPublishClick}
            disabled={!(isShowActionButtons && elevatorAdvert?.nextStatuses.includes('PUBLISHED'))}
          >
            Опубликовать
          </Button>
        </S.ActionButtons>
      </S.ContentWrapper>

      {!!offers?.length && (
        <S.ContentWrapper>
          <Text variant="h3">Ставки: {offers?.length}</Text>

          <S.OffersWrapper>
            <Skeleton loading={getElevatorAdvertOffers.isFetching} />

            {offers?.map((offer) => (
              <OffersListItemMobile
                hasProductName={false}
                key={offer.id}
                offer={offer}
                onClick={handleNavigateToOfferInfo}
              />
            ))}
          </S.OffersWrapper>
        </S.ContentWrapper>
      )}

      <ModeratorBasisAdvertOfferRemoveModal
        isOpen={isRejectAdvertModalVisible}
        onClose={() => setIsRejectAdvertModalVisible(false)}
        onReject={({ message }) => handleAuctionToRejectClick(message)}
      />

      <Modal
        {...modalSharedSettings}
        width={500}
        open={isEditAdvertModalOpen}
        onCancel={onCancelEditAdvertModalHandler}
        title="Редактировать объявление"
        footer={[
          <Button key="cancel" onClick={onCancelEditAdvertModalHandler} htmlType="button">
            Отмена
          </Button>,
          <Button
            key="submit"
            form={FORM_ID}
            type="primary"
            htmlType="submit"
            loading={updateElevatorAdvertFn.isLoading}
          >
            Сохранить
          </Button>,
        ]}
      >
        {elevatorAdvert?.type === 'BUY' && (
          <CreateAdvertFormByBuyer
            formId={FORM_ID}
            onSubmit={onSubmitEditAdvertModalHandler}
            defaultValues={
              elevatorAdvert
                ? {
                    elevatorId: elevatorAdvert.elevator.id,
                    pricePerTon: elevatorAdvert.pricePerTon,
                    allowOffersWithOtherParameters: elevatorAdvert.allowOffersWithOtherParameters ?? false,
                    culture: cultureDefaultControl,
                  }
                : undefined
            }
          />
        )}
        {elevatorAdvert?.type === 'SELL' && (
          <CreateAdvertFormBySeller
            formId={FORM_ID}
            onSubmit={onSubmitEditAdvertBySellerModalHandler}
            defaultValues={
              elevatorAdvert
                ? {
                    elevatorId: elevatorAdvert.elevator.id,
                    pricePerTon: elevatorAdvert.pricePerTon,
                    culture: cultureDefaultControl,
                    volume: elevatorAdvert.volume ?? undefined,
                    usingNds: elevatorAdvert.usingNds ?? false,
                    priceType: elevatorAdvert.priceType,
                    labAnalysis: elevatorAdvert.laboratoryAnalysis?.source,
                  }
                : undefined
            }
          />
        )}
      </Modal>
    </>
  )
}
