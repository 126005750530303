import React from 'react'

import { CardProps } from './Card.types'

import * as S from './Card.styled'
import { ValueBold } from './Card.styled'

export const Card = (props: CardProps) => <S.Card {...props} />

Card.Title = S.Title
Card.Header = S.Header
Card.ActionsContent = S.ActionsContent
Card.Content = S.Content
Card.Label = S.Label
Card.Value = S.Value
Card.ValueBold = S.ValueBold
