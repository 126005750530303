import { useMutation } from '@tanstack/react-query'

import { CreateBankAccountBody } from '~/api/generated'
import { useApi } from '~providers/api'

export const useCreateBankAccount = (companyId: number) => {
  const api = useApi()

  return useMutation((createBankAccountBody: CreateBankAccountBody) => {
    return api.companies.bankAccountControllerCreate({
      companyId,
      createBankAccountBody,
    })
  })
}
