/* tslint:disable */
/* eslint-disable */
/**
 * API Зерно-Агрегатора
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface WarehousePositionActionData
 */
export interface WarehousePositionActionData {
    /**
     * Id события
     * @type {number}
     * @memberof WarehousePositionActionData
     */
    'id': number;
    /**
     * Тип действия
     * @type {string}
     * @memberof WarehousePositionActionData
     */
    'type': WarehousePositionActionDataTypeEnum;
    /**
     * Изменение текущего объёма
     * @type {number}
     * @memberof WarehousePositionActionData
     */
    'currentVolumeChange': number;
    /**
     * Метаданные о действии
     * @type {object}
     * @memberof WarehousePositionActionData
     */
    'metadata': object;
    /**
     * Время события
     * @type {string}
     * @memberof WarehousePositionActionData
     */
    'createdAt': string;
}

export const WarehousePositionActionDataTypeEnum = {
    Open: 'OPEN',
    Close: 'CLOSE',
    Decrease: 'DECREASE',
    Increase: 'INCREASE',
    SellOutside: 'SELL_OUTSIDE',
    BuyOutside: 'BUY_OUTSIDE',
    TransferTo: 'TRANSFER_TO',
    TransferFrom: 'TRANSFER_FROM',
    ReserveToSell: 'RESERVE_TO_SELL',
    ReserveToBuy: 'RESERVE_TO_BUY',
    ChangeOffer: 'CHANGE_OFFER',
    Sell: 'SELL',
    Buy: 'BUY',
    RejectSell: 'REJECT_SELL',
    RejectBuy: 'REJECT_BUY'
} as const;

export type WarehousePositionActionDataTypeEnum = typeof WarehousePositionActionDataTypeEnum[keyof typeof WarehousePositionActionDataTypeEnum];


