import React from 'react'
import { TransportDocumentsProps } from './TransportDocuments.types'
import { Card } from '~/ui-components'
import { TransportDocument } from './components/TransportDocument'

export const TransportDocuments: React.FC<TransportDocumentsProps> = ({ transportDocuments }) => {
  return (
    <Card>
      <Card.Header>
        <Card.Title>Документы на ТС</Card.Title>
      </Card.Header>
      <Card.Content>
        {transportDocuments.map((transportDocument) => (
          <TransportDocument
            key={transportDocument.type}
            type={transportDocument.type}
            documentInstance={transportDocument.instances[0]}
          />
        ))}
      </Card.Content>
    </Card>
  )
}
