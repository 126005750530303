import React, { useState } from 'react'

import { Button, Divider, Drawer, Empty, Flex, Input, Pagination, Select, Skeleton, Spin, Typography } from 'antd'

import { MessageOutlined } from '@ant-design/icons'
import { useMutation, useQuery } from '@apollo/client'

import { usePagination } from '~/hooks'
import { appToast } from '~/utils'
import { graphqlErrorHandler } from '~/utils/graphqlErrorHandler'
import { EntityModel, NoteEntity, NoteSortField, OrderDirection } from '~api/gql-generated/graphql'
import { addNoteMutation, updateNoteMutation } from '~api/gql-mutations/notes.mutation.graphql'
import { getNotesQuery } from '~api/gql-query/notes.query.graphql'
import { useAuth } from '~providers/auth'

import { Note } from './Note'

interface NotesProps {
  entityId: number
  relatedModel: EntityModel
  buttonText?: string
}

export const Notes: React.FC<NotesProps> = (props) => {
  const { userId } = useAuth()

  const [open, setOpen] = useState(false)
  const [noteText, setNoteText] = useState<string>('')
  const [isLoadingNoteId, setLoadingNoteId] = useState<number | null>(null)
  const [sortingByCreatedAt, setSortingByCreatedAt] = useState<OrderDirection>(OrderDirection.Desc)
  const { currentPage, setCurrentPage, pageSize, setPageSize, pageSizeOptions } = usePagination()

  const getNotes = useQuery(getNotesQuery, {
    skip: !open || !props.entityId || !props.relatedModel,
    variables: {
      pagination: { currentPage, pageSize },
      sorting: {
        field: NoteSortField.CreatedAt,
        direction: sortingByCreatedAt,
      },
      filter: {
        entityId: props.entityId,
        model: props.relatedModel,
      },
    },
    onError: graphqlErrorHandler,
  })

  const [addNote, addNoteState] = useMutation(addNoteMutation)
  const [updateNote, updateNoteState] = useMutation(updateNoteMutation)

  const onOpen = () => {
    setOpen(true)
  }

  const onClose = () => {
    setOpen(false)
  }
  const onChangeNoteText = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setNoteText(e.target.value)
  }

  const onSortingChange = (sorting: OrderDirection) => {
    setSortingByCreatedAt(sorting)
  }

  const onSaveNote = async () => {
    await addNote({
      variables: {
        input: {
          text: noteText.trim(),
          entityId: props.entityId,
          model: props.relatedModel,
        },
      },
      onError: (e) => graphqlErrorHandler(e, 'Ошибка при добавлении заметки'),
      refetchQueries: [getNotesQuery],
      onCompleted: () => {
        setNoteText('')
        appToast.success({ description: 'Ваш комментарий добавлен' })
      },
    })
  }

  const onEditNote = async (note: NoteEntity, changedText: string) => {
    setLoadingNoteId(note.id)

    await updateNote({
      variables: {
        noteId: note.id,
        input: {
          text: changedText,
        },
      },
      onError: (e) => graphqlErrorHandler(e, 'Ошибка при редактировании заметки'),
      refetchQueries: [getNotesQuery],
      onCompleted: () => {
        appToast.success({ description: 'Ваш комментарий изменен' })
        setLoadingNoteId(null)
      },
    })
  }

  const notes = getNotes?.data?.notes.notes

  return (
    <>
      <Button type="link" icon={<MessageOutlined />} onClick={onOpen}>
        {props.buttonText ?? 'Заметки'}
      </Button>

      <Drawer title={`Заметки (ID ${props.entityId})`} placement="right" onClose={onClose} open={open} size="large">
        <Flex vertical justify="space-between" style={{ height: '100%' }}>
          <Select
            defaultValue={OrderDirection.Desc}
            style={{ width: 160 }}
            bordered={false}
            options={[
              { value: OrderDirection.Asc, label: <Typography.Text strong>Сначала старые</Typography.Text> },
              { value: OrderDirection.Desc, label: <Typography.Text strong>Сначала новые</Typography.Text> },
            ]}
            onChange={onSortingChange}
          />

          <Skeleton loading={getNotes.loading} />

          <Flex style={{ overflow: 'auto' }} vertical gap={24}>
            {!notes?.length && !getNotes.loading && <Empty />}
            {notes?.map((n) => (
              <Spin spinning={n.id === isLoadingNoteId && updateNoteState.loading} key={n.id}>
                <Note
                  noteData={n as NoteEntity}
                  relatedModel={props.relatedModel}
                  editable={userId === n.authorId}
                  onEdit={onEditNote}
                />
              </Spin>
            ))}
          </Flex>

          <div style={{ margin: 'auto', marginBottom: 0 }}>
            <Pagination
              onChange={setCurrentPage}
              pageSizeOptions={pageSizeOptions}
              pageSize={pageSize}
              current={currentPage}
              onShowSizeChange={(x, size) => setPageSize(size)}
              total={getNotes.data?.notes?.totalCount}
              hideOnSinglePage
            />
          </div>

          <Flex vertical gap="small">
            <Divider />
            <Input.TextArea
              value={noteText}
              placeholder="Добавить комментарий"
              autoSize={{ minRows: 4, maxRows: 8 }}
              onChange={onChangeNoteText}
              allowClear
            />
            <Button type="primary" disabled={!noteText.trim()} loading={addNoteState.loading} onClick={onSaveNote}>
              Отправить
            </Button>
          </Flex>
        </Flex>
      </Drawer>
    </>
  )
}
