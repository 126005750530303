import { useMutation } from '@tanstack/react-query'

import { UserApiUserControllerUpdateUserStatusRequest } from '~/api/generated'
import { useApi } from '~providers/api'

export const useUpdateUserStatus = () => {
  const api = useApi()

  return useMutation((request: UserApiUserControllerUpdateUserStatusRequest) => {
    return api.users.userControllerUpdateUserStatus(request)
  })
}
