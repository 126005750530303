import * as yup from 'yup'

import { ElevatorOfferResponseRejectionReason } from '~api/generated'
import { VALIDATORS_MSG } from '~shared/validations'

export const basisAdvertOfferRejectReasonModalValidation = yup
  .object({
    reason: yup.string(),
    message: yup.string().when('reason', {
      is: (val: ElevatorOfferResponseRejectionReason) => val === ElevatorOfferResponseRejectionReason.Other,
      then: yup.string().trim().required(VALIDATORS_MSG.required),
      otherwise: yup.string().trim().notRequired(),
    }),
  })
  .required()
