import * as yup from 'yup'

import { VALIDATORS_MSG } from '~shared/validations'

export const basisAdvertOfferChangeStatusModalValidation = yup.object({
  isDealComplete: yup.boolean().required(VALIDATORS_MSG.required),
  rejectDealComment: yup.string().when('isDealComplete', {
    is: (val: boolean) => !val,
    then: yup.string().trim().required(VALIDATORS_MSG.required),
    otherwise: yup.string().trim().notRequired(),
  }),
  successDealComment: yup.string().when('isDealComplete', {
    is: (val: boolean) => val,
    then: yup.string().trim().notRequired(),
    otherwise: yup.string().trim().notRequired(),
  }),
})
