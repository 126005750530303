import React, { FC } from 'react'

import { Button, InputNumber } from 'antd'
import { Controller, useForm } from 'react-hook-form'

import { yupResolver } from '@hookform/resolvers/yup'

import { FormField, FormFooter, UploadButton } from '~/ui-components'
import { inputSharedSettings } from '~/utils/inputSharedSettings'
import { ParametersCultureControl } from '~shared/controls'

import { warehouseCultureFormSchema } from '~pages/Warehouses/components/WarehouseCultureForm/WarehouseCultureForm.validation'
import { warehouseCultureFormEditSchema } from '~pages/Warehouses/components/WarehouseCultureForm/WarehouseCultureFormEdit.validation'

import { WarehouseCultureFormProps, WarehouseCultureFormValues } from './WarehouseCultureForm.types'

import * as S from '../CreateAndEditWarehouse/CreateAndEditWarehouse.styled'

export const WarehouseCultureForm: FC<WarehouseCultureFormProps> = ({
  onCancel,
  onPrev,
  onSave,
  isLoading,
  isPrevButton,
  isEdit,
}) => {
  const {
    handleSubmit,
    control,
    watch,
    formState: { errors, isDirty },
  } = useForm<WarehouseCultureFormValues>({
    defaultValues: {
      volumeTons: 0,
      culture: undefined,
      labAnalysis: undefined,
    },
    resolver: yupResolver(isEdit ? warehouseCultureFormEditSchema : warehouseCultureFormSchema),
  })

  const isSelectedCulture = !!watch().culture?.cultureTypeId

  const handleSubmitForm = (data: WarehouseCultureFormValues) => {
    onSave?.(data)
  }

  return (
    <form onSubmit={handleSubmit(handleSubmitForm)}>
      <FormField validateMessage={errors.culture ? 'Параметры культуры заполнены с ошибками' : ''}>
        <Controller
          name="culture"
          control={control}
          render={({ field }) => <ParametersCultureControl {...field} isRequired={isEdit} />}
        />
      </FormField>

      <FormField label="Результаты лаб. исследований" validateMessage={errors.labAnalysis?.message}>
        <Controller
          control={control}
          name="labAnalysis"
          render={({ field }) => (
            <UploadButton
              disabled={!isSelectedCulture}
              onChange={(evt) => {
                if (evt.target.files?.length) {
                  field.onChange(evt.target.files[0])
                }
              }}
              onDelete={() => field.onChange(undefined)}
            >
              Загрузить
            </UploadButton>
          )}
        />
      </FormField>

      <FormField
        htmlFor="volumeTons"
        label="Объем, тонн"
        validateMessage={errors.volumeTons?.message}
        isRequired={isEdit}
      >
        <Controller
          name="volumeTons"
          control={control}
          render={({ field }) => (
            <S.SmallInputWrapper>
              <InputNumber
                {...field}
                {...inputSharedSettings}
                style={{ width: '100%' }}
                id="volumeTons"
                placeholder="20"
                status={errors.volumeTons && 'error'}
                value={field.value ?? ''}
                disabled={!isSelectedCulture}
                step={1}
                precision={0}
              />
            </S.SmallInputWrapper>
          )}
        />
      </FormField>

      <FormFooter>
        <Button onClick={onCancel} htmlType="button">
          Отмена
        </Button>
        {isPrevButton && (
          <Button onClick={onPrev} htmlType="button">
            Назад
          </Button>
        )}
        <Button
          type="primary"
          htmlType="submit"
          disabled={!!errors.culture || (isEdit && !isDirty)}
          loading={isLoading}
        >
          Сохранить
        </Button>
      </FormFooter>
    </form>
  )
}
