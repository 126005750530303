import styled from 'styled-components'
import { Button, Upload } from 'antd'

export const StyledUpload = styled(Upload)`
  & .ant-upload-list {
    display: flex;
    flex-wrap: wrap;

    & .ant-upload-list-item-container {
      margin-right: 8px;

      & .ant-upload-list-item {
        background-color: #fafafb;
        margin-top: 16px;
      }
    }
  }
`

export const StyledUploadButton = styled(Button)`
  color: var(--gray-8-color);
  background-color: #eeeff1;
`
