import { useQuery, UseQueryResult } from '@tanstack/react-query'

import { GetComplexDealResponse } from '~/api/generated'
import { useApi } from '~providers/api'

export const useGetDealById = (dealId: number): UseQueryResult<GetComplexDealResponse> => {
  const api = useApi()

  async function fetchDeal(): Promise<GetComplexDealResponse> {
    const response = await api.deal.dealControllerGetComplexDeal({ complexDealId: dealId })
    return response.data
  }

  return useQuery(['fetchDealById', dealId], fetchDeal, {
    staleTime: 1000 * 20,
    enabled: !!dealId,
  })
}
