import React, { FC, useState } from 'react'

import { observer } from 'mobx-react-lite'

import { userUiSettingsStore } from '~/store/userUiSettingsStore'
import { appToast, httpErrorHandler } from '~/utils'
import { CreateOfferBody } from '~api/generated'
import { useCreateAuction, useCreateAuctionForAdmin } from '~hooks/auctions'
import { useGetCurrentRole } from '~hooks/auth'
import { CreateLotModal } from '~pages/Auctions/components'
import { CreateLotAdminModal } from '~pages/Auctions/components/CreateLotAdminModal'
import { useAuth } from '~providers/auth'

import { AuctionsHeaderProps } from './AuctionsHeader.types'
import { SubmitAdminModalData } from '~pages/Auctions/components/CreateLotAdminModal/CreateLotAdminModal.types'

import * as S from './AuctionsHeader.styled'

export const AuctionsHeader: FC<AuctionsHeaderProps> = observer(
  ({ isCreateLot = true, onAfterCreateAuction, filters }) => {
    const { companyId } = useAuth()
    const {
      user: { isAdmin },
    } = useGetCurrentRole()
    const [isVisibleCreateLotModal, setVisibleCreateLotModal] = useState(false)
    const [isFetching, setFetching] = useState(false)

    const createAuction = useCreateAuction()
    const createAuctionForAdmin = useCreateAuctionForAdmin()

    const handleSubmitAdminOffer = async (data: SubmitAdminModalData) => {
      if (companyId === null) return

      setFetching(true)
      try {
        await createAuctionForAdmin.mutateAsync(data)
        onAfterCreateAuction?.()
        setVisibleCreateLotModal(false)
        appToast.success({
          description: 'Для публикации вашего Лота, наш менеджер свяжется с вами, чтобы подтвердить все данные.',
        })
      } catch (e) {
        httpErrorHandler(e, 'Ошибка при создании лота')
      } finally {
        setFetching(false)
      }
    }

    const handleSubmitOffer = async (
      createOfferBody: CreateOfferBody,
      isAddAddress: boolean,
      labAnalysis: File | undefined,
      startedAt: Date | undefined
    ) => {
      if (companyId === null) return

      setFetching(true)
      try {
        await createAuction.mutateAsync({
          createOfferBody,
          isAddAddress,
          labAnalysis,
          startedAt,
          companyId,
        })
        onAfterCreateAuction?.()
        setVisibleCreateLotModal(false)
        appToast.success({
          description: 'Для публикации вашего Лота, наш менеджер свяжется с вами, чтобы подтвердить все данные.',
        })
      } catch (e) {
        const description = 'Ошибка при создании лота'
        console.error(description, e)
        appToast.error({ description })
      } finally {
        setFetching(false)
      }
    }

    // TODO: Поправить когда будут данные кроме создание лота
    if (!isCreateLot) {
      return null
    }

    return (
      <>
        <S.Header>
          {isCreateLot && (
            <S.CreateLotButton type="primary" onClick={() => setVisibleCreateLotModal(true)}>
              Создать лот
            </S.CreateLotButton>
          )}
          {filters}
        </S.Header>

        {isCreateLot &&
          (isAdmin ? (
            <CreateLotAdminModal
              isFetching={isFetching}
              visible={isVisibleCreateLotModal}
              onSubmit={handleSubmitAdminOffer}
              onClose={() => setVisibleCreateLotModal(false)}
            />
          ) : (
            <CreateLotModal
              isFetching={isFetching}
              visible={isVisibleCreateLotModal}
              onSubmit={handleSubmitOffer}
              onClose={() => setVisibleCreateLotModal(false)}
            />
          ))}
      </>
    )
  }
)
