import styled from 'styled-components'

import { breakpoints } from '~/tokens'
import { Button } from 'antd'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  @media (max-width: ${breakpoints.MOBILE_XL}px) {
    width: 100%;
  }
`

export const ActionWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 16px;
`

export const StyledButton = styled(Button)`
  background: var(--gray-2-color);
  color: var(--gray-5-color);
`
