import React, { useState } from 'react'

import { Button, Flex, Skeleton, Space, Spin } from 'antd'
import { observer } from 'mobx-react-lite'
import { AiOutlineClockCircle } from 'react-icons/ai'
import { FiAlertCircle } from 'react-icons/fi'
import { useNavigate, useParams } from 'react-router-dom'

import { AppHeader } from '~/layout'
import { appRoutes } from '~/router'
import { Badge, Card, Text, UploadButton } from '~/ui-components'
import { getElevatorAdvertStatus } from '~/utils/elevator-adverts/get-elevator-advert-status'
import { getElevatorPriceTypeName } from '~/utils/elevators/get-elevator-price-type-name'
import { getDateFormat, getTimeDurationFormat } from '~/utils/getDateFormat'
import { ElevatorOfferStatus } from '~api/generated'
import { useGetCurrentRole } from '~hooks/auth'
import { useGetDevice } from '~hooks/common'
import { useGetElevatorAdvert, useGetSimilarElevators, useGetSimilarElevatorsAdverts } from '~hooks/elevator-advert'
import { useGetElevatorAdvertOffers } from '~hooks/elevator-advert-offers'
import { BasisCultureParams } from '~pages/BasisPage/components/BasisCultureParams'
import { CreateAdvertBuyOfferModal } from '~pages/BasisPage/components/CreateAdvertBuyOfferModal'
import { CreateAdvertOfferModal } from '~pages/BasisPage/components/CreateAdvertOfferModal'
import { SimilarBasisAdvertCard } from '~pages/BasisPage/components/SimilarBasisAdvertCard'
import { SimilarBasisCard } from '~pages/BasisPage/components/SimilarBasisCard'
import { ResponsesDesktopBuyer } from '~pages/BasisPage/modules/BasisAdvert/Responses/ResponsesDesktopBuyer'
import { ResponsesDesktopSeller } from '~pages/BasisPage/modules/BasisAdvert/Responses/ResponsesDesktopSeller'
import { MyAdvertActionsDropdown } from '~pages/BasisPage/modules/MyAdvert/components/MyAdvertActionsDropdown'
import { CopyURLButton } from '~pages/OffersPage/components/CopyURLButton'
import { useAuth } from '~providers/auth'

import { MyAdvertOffersListItemMobile } from '../MyAdvertOffers/components/MyAdvertOffersList/components/MyAdvertOffersListItemMobile'

import { BasisAdvertOfferPreviewCard } from './BasisAdvertOfferPreviewCard'

import * as S from './BasisAdvert.styled'
import { PublishDateCountdown } from './BasisAdvert.styled'

export const BasisAdvert: React.FC = observer(() => {
  const { companyId: currentCompanyId } = useAuth()

  const device = useGetDevice()
  const isMobile = device === 'mobile'

  const {
    company: { isSeller, isBuyer, isTrader },
    user: { isAdmin },
  } = useGetCurrentRole()
  const { id } = useParams<{ id: string | undefined }>()
  const advertId = Number(id)
  const navigate = useNavigate()
  const getElevatorAdvertFn = useGetElevatorAdvert({ id: advertId, userCompanyId: currentCompanyId })
  const [isCreateAdvertModalOpen, setIsCreateAdvertModalOpen] = useState(false)
  const [isCreateAdvertBuyModalOpen, setIsCreateAdvertBuyModalOpen] = useState(false)

  const advert = getElevatorAdvertFn.data
  const isMyAdvert = advert?.isOwnedByMyCompany

  const getSimilarElevatorsFn = useGetSimilarElevators({ elevatorAdvertId: advertId })
  const getSimilarElevatorsAdvertsFn = useGetSimilarElevatorsAdverts({ id: advertId })
  const getElevatorAdvertOffers = useGetElevatorAdvertOffers(
    { id: advertId, userCompanyId: currentCompanyId },
    { enabled: isMyAdvert }
  )
  const offers = getElevatorAdvertOffers.data?.elevatorAdvertOffers

  const isShowPublishDateCountdown =
    advert?.expiredAt &&
    [
      ElevatorOfferStatus.Published,
      ElevatorOfferStatus.OnModeration,
      ElevatorOfferStatus.OnModerationWithAnalysis,
    ].some((s) => s === advert.status)
  // const offersPagination = getElevatorAdvertOffers.data?.pagination

  const handleNavigateToBasisInfo = () => {
    navigate(appRoutes.basisInfo.url.replace(':id', advert?.elevator.id.toString() ?? ''))
  }
  const handleNavigateToOfferInfo = (offerId: number) => {
    navigate(appRoutes.basisAdvertOffer.url.replace(':id', offerId.toString()))
  }
  const handleNavigateToSimilarBasis = (basisId: number) => {
    navigate(appRoutes.basisDetails.url.replace(':id', basisId.toString()))
  }
  const handleNavigateToSimilarAdvert = (advertId: number) => {
    navigate(appRoutes.basisAdvert.url.replace(':id', advertId.toString()))
  }

  return (
    <>
      <AppHeader title={appRoutes.basisAdvert.title + advertId} isBack />

      <Spin spinning={getElevatorAdvertFn.isFetching}>
        <S.ContentWrapper>
          <div>
            <Text variant="h3">{advert?.elevator?.name ?? ''}</Text>
            <Button type="link" onClick={handleNavigateToBasisInfo}>
              Подробнее о базисе
            </Button>
          </div>

          {advert && (
            <Card key={advert.id}>
              <S.HeaderWrapper>
                <Flex justify="space-between">
                  {advert?.company?.name ? <S.CompanyName>{advert?.company?.name}</S.CompanyName> : <span />}
                  {isShowPublishDateCountdown && (
                    <PublishDateCountdown>
                      <AiOutlineClockCircle size={16} /> {getTimeDurationFormat(new Date(), advert.expiredAt!)}
                    </PublishDateCountdown>
                  )}
                </Flex>

                <S.CultureInfoWrapper>
                  <Card.Title>{advert.product.name}</Card.Title>
                  {advert.volume && <S.VolumeMobile>{advert.volume} т</S.VolumeMobile>}
                </S.CultureInfoWrapper>
                {(isAdmin || isMyAdvert) && (
                  <div>
                    <Badge color={getElevatorAdvertStatus(advert.status).color} size="s">
                      {getElevatorAdvertStatus(advert.status).name}
                    </Badge>
                  </div>
                )}
                <S.PriceWrapper>
                  <Space>
                    <S.Price>{advert.pricePerTon} ₽/т</S.Price>
                    {advert.type === 'SELL' && <Text variant="normal">{advert.usingNds ? 'с НДС' : 'без НДС'}</Text>}
                    {advert.priceUpdatedAt && (
                      <S.PublishedAt>Обновлено {getDateFormat(new Date(advert.priceUpdatedAt))}</S.PublishedAt>
                    )}
                  </Space>
                </S.PriceWrapper>
                {advert.type === 'BUY' && <Text variant="normal">Цена без НДС (с НДС +10%)</Text>}
                <S.PriceType>{getElevatorPriceTypeName(advert.priceType)}</S.PriceType>
                {advert.volume && <S.VolumeDesktop>{advert.volume} т</S.VolumeDesktop>}
              </S.HeaderWrapper>

              <Card.Content>
                {isMobile && <Text variant="normal-bold">Показатели</Text>}

                {advert.type === 'BUY' && !advert.isOwnedByMyCompany && (
                  <>
                    {advert.allowOffersWithOtherParameters ? (
                      <S.ParametersInfo isAllow={true}>
                        <FiAlertCircle size="24" />
                        <Text variant="normal">
                          {isSeller && 'Покупатель также рассматривает культуру с другими показателями'}
                          {isBuyer && 'Продавцы могут предлагать вам культуру с собственными показателями'}
                        </Text>
                      </S.ParametersInfo>
                    ) : (
                      <S.ParametersInfo isAllow={false}>
                        <FiAlertCircle size="24" />
                        <Text variant="normal">
                          {isSeller && 'Показатели вашей культуры должны совпадать с указанными в объявлении'}
                          {isBuyer && 'Продавцы не могут предлагать вам культуру с собственными показателями'}
                        </Text>
                      </S.ParametersInfo>
                    )}
                  </>
                )}

                {!!advert.product.parameters.length && (
                  <BasisCultureParams product={advert.product} variant={isMobile ? 'list' : 'badge'} />
                )}

                {advert?.laboratoryAnalysis?.source && (
                  <UploadButton
                    fileName="Лаб. анализ"
                    isReadOnly
                    onFileClick={() => window.open(advert?.laboratoryAnalysis?.source, '_blank')}
                  />
                )}
              </Card.Content>
            </Card>
          )}

          <S.ActionWrapper>
            <CopyURLButton />

            {((isSeller && advert?.type === 'BUY') || (isTrader && advert?.type === 'BUY')) && !isMyAdvert && (
              <>
                <Button htmlType="button" type="primary" onClick={() => setIsCreateAdvertModalOpen(true)}>
                  Предложить объем
                </Button>

                <CreateAdvertOfferModal
                  advertId={advertId}
                  userCompanyId={currentCompanyId}
                  isOpen={isCreateAdvertModalOpen}
                  onCancel={() => setIsCreateAdvertModalOpen(false)}
                />
              </>
            )}

            {((isBuyer && advert?.type === 'SELL') || (isTrader && advert?.type === 'SELL')) && !isMyAdvert && (
              <>
                <Button htmlType="button" type="primary" onClick={() => setIsCreateAdvertBuyModalOpen(true)}>
                  Купить объем
                </Button>

                <CreateAdvertBuyOfferModal
                  userCompanyId={currentCompanyId}
                  advertId={advertId}
                  isOpen={isCreateAdvertBuyModalOpen}
                  onCancel={() => setIsCreateAdvertBuyModalOpen(false)}
                />
              </>
            )}

            {isMyAdvert && advert && (
              <MyAdvertActionsDropdown
                companyId={currentCompanyId}
                advertId={advertId}
                currentStatus={advert.status}
                nextStatuses={advert.nextStatuses}
                price={advert.pricePerTon}
                afterAction={() => void getElevatorAdvertFn.refetch()}
              />
            )}
          </S.ActionWrapper>

          {/*// отклики под мобилку */}
          {isMobile && isMyAdvert && advert && !!offers?.length && (
            <>
              <Text variant="h3">Отклики: {offers?.length}</Text>

              <S.OffersWrapper>
                <Skeleton loading={getElevatorAdvertOffers.isFetching} />

                {offers?.map((offer) =>
                  isMyAdvert ? (
                    <MyAdvertOffersListItemMobile
                      key={offer.id}
                      advert={advert}
                      offer={offer}
                      onNeedToRefetch={() => getElevatorAdvertOffers.refetch()}
                    />
                  ) : (
                    <BasisAdvertOfferPreviewCard key={offer.id} offer={offer} onClick={handleNavigateToOfferInfo} />
                  )
                )}
              </S.OffersWrapper>
            </>
          )}

          {!isMobile && advert && isMyAdvert && (
            <>
              <>{(isBuyer || isTrader) && <ResponsesDesktopBuyer advertId={advertId} companyId={currentCompanyId} />}</>
              <>{isSeller && <ResponsesDesktopSeller advert={advert} companyId={currentCompanyId} />}</>
            </>
          )}

          {!!getSimilarElevatorsAdvertsFn.data?.elevatorOffers?.length && (
            <Flex vertical gap={16}>
              <Text variant="h3">Похожие варианты</Text>

              <S.OffersWrapper>
                <Skeleton loading={getSimilarElevatorsAdvertsFn.isFetching} />

                {getSimilarElevatorsAdvertsFn.data?.elevatorOffers?.map((advert) => (
                  <SimilarBasisAdvertCard key={advert.id} advert={advert} onClick={handleNavigateToSimilarAdvert} />
                ))}
              </S.OffersWrapper>
            </Flex>
          )}

          {!!getSimilarElevatorsFn.data?.elevators?.length && (
            <Flex vertical gap={16}>
              <Text variant="h3">Базисы региона с похожими объявлениями</Text>

              <S.OffersWrapper>
                <Skeleton loading={getSimilarElevatorsFn.isFetching} />

                {getSimilarElevatorsFn.data?.elevators?.map((elevator) => (
                  <SimilarBasisCard key={elevator.id} elevatorData={elevator} onClick={handleNavigateToSimilarBasis} />
                ))}
              </S.OffersWrapper>
            </Flex>
          )}
        </S.ContentWrapper>
      </Spin>
    </>
  )
})
