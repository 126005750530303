import { add, isAfter, isToday } from 'date-fns'

import { getRange } from '~/utils/get-range'

const minutesRange = getRange(0, 60)
const hoursRange = getRange(0, 24)

export const getDisabledTime = (current: Date | null) => {
  if (!current) return {}

  const hours = new Date()?.getHours()
  const minutes = new Date()?.getMinutes()

  const isCurrentHours = current.getHours() === hours

  if (isToday(current)) {
    return {
      disabledHours: () => hoursRange.slice(0, hours),
      disabledMinutes: () => (isCurrentHours ? minutesRange.slice(0, minutes) : []),
    }
  } else if (isAfter(current, add(new Date(), { days: 6 }))) {
    return {
      disabledHours: () => hoursRange.slice(hours + 1),
      disabledMinutes: () => (isCurrentHours ? minutesRange.slice(minutes + 1) : []),
    }
  }

  return {}
}
