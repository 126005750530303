import React from 'react'

import { Button, Input, InputNumber, Space } from 'antd'
import { Controller, useForm } from 'react-hook-form'

import { yupResolver } from '@hookform/resolvers/yup/dist/yup'

import { FormField, FormFooter, Text } from '~/ui-components'
import { inputSharedSettings } from '~/utils/inputSharedSettings'
import { createElevatorFormStep4CarSchema } from '~pages/SettingsPage/modules/ElevatorsAdmin/components/CreateElevatorForm/step4Car/CreateElevatorFormStep4CarSchema'
import { ElevatorLoadTypeControl, ElevatorUnloadTypeControl, YesOrNotControl } from '~shared/controls'

import {
  CreateElevatorFormStep4CarProps,
  CreateElevatorFormStep4CarValues,
} from '~pages/SettingsPage/modules/ElevatorsAdmin/components/CreateElevatorForm/step4Car/CreateElevatorFormStep4Car.types'

export const CreateElevatorFormStep4Car: React.FC<CreateElevatorFormStep4CarProps> = ({
  step1Data,
  onNext,
  onCancel,
  onPrev,
  defaultValues,
}) => {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<CreateElevatorFormStep4CarValues>({
    defaultValues: {
      autoReceptionSpeed: defaultValues?.autoReceptionSpeed,
      autoUnloadSpeed: defaultValues?.autoUnloadSpeed,
      scalesWeightLimit: defaultValues?.scalesWeightLimit,
      scalesLength: defaultValues?.scalesLength,
      autoLength: defaultValues?.autoLength,
      autoHeight: defaultValues?.autoHeight ? defaultValues.autoHeight / 100 : undefined,
      autoClearance: defaultValues?.autoClearance,
      loadingTransport: defaultValues?.loadingTransport,
      unloadingTransport: defaultValues?.unloadingTransport,
      hasAutoStorage: defaultValues?.hasAutoStorage,
      autoAdditionalInfo: defaultValues?.autoAdditionalInfo,
    },
    resolver: yupResolver(createElevatorFormStep4CarSchema),
  })
  const onSubmitHandler = (formValues: CreateElevatorFormStep4CarValues) => {
    onNext(formValues)
  }

  return (
    <form onSubmit={handleSubmit(onSubmitHandler)}>
      <Space direction="vertical" size={6} style={{ marginBottom: 16 }}>
        <Text variant="normal-bold">{step1Data?.name}</Text>
        <Text variant="normal">{step1Data?.address}</Text>
      </Space>

      <FormField htmlFor="scalesWeightLimit" label="Тоннаж весов" validateMessage={errors.scalesWeightLimit?.message}>
        <Controller
          name="scalesWeightLimit"
          control={control}
          render={({ field }) => (
            <InputNumber
              {...field}
              {...inputSharedSettings}
              precision={0}
              addonAfter="т"
              style={{ width: '100%' }}
              id="scalesWeightLimit"
              placeholder="Введите значение"
              status={errors.scalesWeightLimit && 'error'}
              step={1}
            />
          )}
        />
      </FormField>

      <FormField htmlFor="scalesLength" label="Длина весов" validateMessage={errors.scalesLength?.message}>
        <Controller
          name="scalesLength"
          control={control}
          render={({ field }) => (
            <InputNumber
              {...field}
              style={{ width: '100%' }}
              addonAfter="м"
              id="scalesLength"
              placeholder="10,55"
              status={errors.scalesLength && 'error'}
              {...inputSharedSettings}
              precision={0}
            />
          )}
        />
      </FormField>

      <FormField htmlFor="autoLength" label="Ограничения для Т/С по длине" validateMessage={errors.autoLength?.message}>
        <Controller
          name="autoLength"
          control={control}
          render={({ field }) => (
            <InputNumber
              {...field}
              {...inputSharedSettings}
              precision={0}
              addonAfter="м"
              style={{ width: '100%' }}
              id="autoLength"
              placeholder="Введите значение"
              status={errors.autoLength && 'error'}
              step={1}
            />
          )}
        />
      </FormField>

      <FormField
        htmlFor="autoHeight"
        label="Ограничения для Т/С по высоте"
        validateMessage={errors.autoHeight?.message}
      >
        <Controller
          name="autoHeight"
          control={control}
          render={({ field }) => (
            <InputNumber
              {...field}
              {...inputSharedSettings}
              addonAfter="м"
              style={{ width: '100%' }}
              id="autoHeight"
              placeholder="Введите значение"
              status={errors.autoHeight && 'error'}
              step={0.1}
            />
          )}
        />
      </FormField>

      <FormField
        htmlFor="autoClearance"
        label="Ограничения для Т/С по высоте клиренса"
        validateMessage={errors.autoClearance?.message}
      >
        <Controller
          name="autoClearance"
          control={control}
          render={({ field }) => (
            <InputNumber
              {...field}
              {...inputSharedSettings}
              precision={0}
              addonAfter="см"
              style={{ width: '100%' }}
              id="autoClearance"
              placeholder="Введите значение"
              status={errors.autoClearance && 'error'}
              step={1}
            />
          )}
        />
      </FormField>

      <FormField
        label="Способ приемки"
        htmlFor="unloadingTransport"
        validateMessage={errors.unloadingTransport?.message}
      >
        <Controller
          name="unloadingTransport"
          control={control}
          render={({ field }) => <ElevatorUnloadTypeControl {...field} hasError={!!errors.unloadingTransport} />}
        />
      </FormField>

      <FormField
        htmlFor="autoReceptionSpeed"
        label="Скорость приемки"
        validateMessage={errors.autoReceptionSpeed?.message}
      >
        <Controller
          name="autoReceptionSpeed"
          control={control}
          render={({ field }) => (
            <InputNumber
              {...field}
              {...inputSharedSettings}
              addonAfter="Авто/сутки"
              precision={0}
              style={{ width: '100%' }}
              id="autoReceptionSpeed"
              placeholder="Введите значение"
              status={errors.autoReceptionSpeed && 'error'}
              step={1}
            />
          )}
        />
      </FormField>

      <FormField htmlFor="autoUnloadSpeed" label="Скорость погрузки" validateMessage={errors.autoUnloadSpeed?.message}>
        <Controller
          name="autoUnloadSpeed"
          control={control}
          render={({ field }) => (
            <InputNumber
              {...field}
              {...inputSharedSettings}
              precision={0}
              addonAfter="Авто/сутки"
              style={{ width: '100%' }}
              id="autoUnloadSpeed"
              placeholder="Введите значение"
              status={errors.autoUnloadSpeed && 'error'}
              step={1}
            />
          )}
        />
      </FormField>

      <FormField label="Способ погрузки" htmlFor="loadingTransport" validateMessage={errors.loadingTransport?.message}>
        <Controller
          name="loadingTransport"
          control={control}
          render={({ field }) => <ElevatorLoadTypeControl {...field} hasError={!!errors.loadingTransport} />}
        />
      </FormField>

      <FormField label="Площадка накопитель для Т/С" validateMessage={errors.hasAutoStorage?.message}>
        <Controller name="hasAutoStorage" control={control} render={({ field }) => <YesOrNotControl {...field} />} />
      </FormField>

      <FormField
        label="Дополнительные сведения по автотранспорту"
        htmlFor="autoAdditionalInfo"
        validateMessage={errors.autoAdditionalInfo?.message}
      >
        <Controller
          name="autoAdditionalInfo"
          control={control}
          render={({ field }) => (
            <Input
              {...field}
              id="autoAdditionalInfo"
              placeholder="Дополнительные сведения"
              status={errors.autoAdditionalInfo && 'error'}
            />
          )}
        />
      </FormField>

      <FormFooter>
        <Button onClick={onCancel} htmlType="button">
          Отмена
        </Button>
        <Button onClick={onPrev} htmlType="button">
          Назад
        </Button>
        <Button type="primary" htmlType="submit">
          Далее
        </Button>
      </FormFooter>
    </form>
  )
}
