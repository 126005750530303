import { Modal } from 'antd'
import styled from 'styled-components'

export const StyledModal = styled(Modal)`
  top: 20px;
  .ant-modal-content {
    height: calc(100vh - 40px);
    flex-direction: column;
    display: flex;
  }
  .ant-modal-body {
    flex-grow: 1;
  }
`
export const Content = styled.div`
  min-height: 500px;
  height: 100%;
`
