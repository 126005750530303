import React from 'react'

import { FiChevronRight } from 'react-icons/fi'

import { Card } from '~/ui-components'
import { CompanyStatusBadge } from '~/utils/company/CompanyStatusBadge'

import { ModeratorCompanyListMobileItemType } from './ModeratorCompaniesListMobile.types'

import * as S from './ModeratorCompaniesListMobileItem.styled'

interface IModeratorCompanyListMobileItemProps extends ModeratorCompanyListMobileItemType {
  onItemClick: (item: ModeratorCompanyListMobileItemType) => void
}

export const ModeratorCompaniesListMobileItem: React.FC<IModeratorCompanyListMobileItemProps> = (props) => {
  const onItemClickHandler = (e: React.MouseEvent) => {
    e.stopPropagation()
    props.onItemClick(props)
  }

  return (
    <Card onClick={onItemClickHandler}>
      <S.ContentWrapper>
        <S.Row>
          <Card.Label>ID</Card.Label>
          <Card.Value>{props.id}</Card.Value>
        </S.Row>
        <S.Row>
          <Card.Label>ИНН</Card.Label>
          <Card.Value>{props.inn}</Card.Value>
        </S.Row>
        <S.Row>
          <Card.Label>Компания</Card.Label>
          <Card.Value>{props.companyName}</Card.Value>
        </S.Row>
        <S.Row>
          <Card.Label>Роль</Card.Label>
          <Card.Value>{props.role}</Card.Value>
        </S.Row>
        <S.Row>
          <Card.Label>Создал компанию</Card.Label>
          <Card.Value>{props.createdBy}</Card.Value>
        </S.Row>
        <S.Row>
          <Card.Label>Дата регистрации</Card.Label>
          <Card.Value>{props.createdAt}</Card.Value>
        </S.Row>
        <S.Row>
          <Card.Label>Статус компании</Card.Label>
          <Card.Value>
            <CompanyStatusBadge status={props.status} />
          </Card.Value>
        </S.Row>
        <S.Row>
          <S.ActionWrapper>
            <FiChevronRight size={24} display={'block'} />
          </S.ActionWrapper>
        </S.Row>
      </S.ContentWrapper>
    </Card>
  )
}
