import React from 'react'

import { Tooltip } from 'antd'

import * as S from '~pages/Deals/modules/DealLogistics/components/LogisticCard/LogisticCard.styled'

export const CardValue: React.FC<{ value: any }> = ({ value }) => {
  return (
    <S.Value variant="normal">
      <Tooltip title={value}>{value ?? '-'}</Tooltip>
    </S.Value>
  )
}

export const CardLabel: React.FC<{ value: string }> = ({ value }) => {
  return <S.Label variant="normal">{value}</S.Label>
}
