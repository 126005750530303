import React from 'react'

import { Modal } from 'antd'

import { NoAccessToUserCompany } from '~shared/components/NoAccessToUserCompany'

export function NoAccessCompanyModalErrorHandler() {
  const isErrorModalOpen = localStorage.getItem('isErrorModalOpen') === 'true'
  console.log({ isErrorModalOpen })
  if (!isErrorModalOpen) {
    Modal.destroyAll()
    Modal.info({
      title: '🔃 Автоматическая  смена компании',
      content: <NoAccessToUserCompany />,
      okCancel: false,
      okText: 'Понятно',
      onOk: () => {
        window.location.reload()
      },
      closable: false,
    })
    localStorage.setItem('isErrorModalOpen', 'true')
  }
}
