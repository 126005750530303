import React, { FC, useState } from 'react'

import { Button, Tooltip } from 'antd'
import isNumber from 'lodash/isNumber'
import { FiMessageCircle } from 'react-icons/fi'
import { Link } from 'react-router-dom'

import { OfferDataTypeEnum } from '~/api/generated'
import { appRoutes } from '~/router'
import { AdminLinkToCompanyById, PhoneNumber, QualityIndicators } from '~/shared/components'
import { Badge, Card, UploadButton } from '~/ui-components'
import { formatNumber } from '~/utils/formatNumber'
import { formatPhoneNumber } from '~/utils/formatPhoneNumber'
import { getDateFormat, getDateTimeFormat, getTimeDurationFormat } from '~/utils/getDateFormat'
import { getNdsString } from '~/utils/getNdsString'
import { getValueIfExistOrDefault } from '~/utils/getValueIfExistOrDefault'
import { getUserRoleOld } from '~/utils/user/getUserRoleOld'
import { useGetCurrentRole } from '~hooks/auth'
import { Comments } from '~pages/OffersPage/components/Comments'

import { OfferCardDetailsProps } from './OfferCardDetails.types'

import * as S from './OfferCardDetails.styled'

export const OfferCardDetails: FC<OfferCardDetailsProps> = ({
  id,
  title,
  parameters,
  actions,
  statuses,
  laboratoryAnalysisSource,
  volume,
  initialVolume,
  availableVolume,
  price,
  priceDetail,
  minPrice,
  maxPrice,
  includingNds,
  address,
  distance,
  shippingRequired,
  contactPersonName,
  contactPersonPhone,
  note,
  company,
  publishedAt,
  acceptedAt,
  expiredAt,
  addedAt,
  startAuction,
  endAuction,
  type,
  isShowCompanyInfo = true,
  isShowQualityIndicators = true,
  isShowDivider = true,
  isParametersCorrespondsToGost,
  gost,
  createdBy,
}) => {
  const {
    user: { isAdmin },
  } = useGetCurrentRole()

  const [isVisibleComments, setVisibleComments] = useState(false)

  return (
    <Card>
      <S.Content>
        {(title || actions) && (
          <S.Header>
            <S.Title>{title ?? ''}</S.Title>
            {expiredAt &&
              (getTimeDurationFormat(new Date(), expiredAt) !== -1 ? (
                <S.ExpiredAtTitle>
                  Снимется с публикации через{' '}
                  <Tooltip title={getDateTimeFormat(expiredAt)}>
                    <S.ExpiredAtValue>{getTimeDurationFormat(new Date(), expiredAt)}</S.ExpiredAtValue>
                  </Tooltip>
                </S.ExpiredAtTitle>
              ) : (
                <Tooltip title={getDateTimeFormat(expiredAt)}>
                  <Badge color="red">Снято с публикации</Badge>
                </Tooltip>
              ))}
            {actions}
          </S.Header>
        )}

        {!!statuses?.length && (
          <S.Statuses>
            {statuses.map(({ name, color }) => (
              <Badge key={name} color={color} size="s">
                {name}
              </Badge>
            ))}
          </S.Statuses>
        )}

        {isShowQualityIndicators && !!parameters.length && (
          <QualityIndicators
            gost={gost}
            isLabAnalysis={!!laboratoryAnalysisSource}
            isParametersCorrespondsToGost={!!isParametersCorrespondsToGost}
          />
        )}

        {!!parameters.length && (
          <S.Parameters>
            {parameters.map((param) => (
              <Badge key={param} color="gray" isRound>
                {param}
              </Badge>
            ))}
          </S.Parameters>
        )}

        {laboratoryAnalysisSource && (
          <UploadButton
            fileName="Лаб. анализ"
            isReadOnly
            onFileClick={() => window.open(laboratoryAnalysisSource, '_blank')}
          />
        )}

        {isNumber(volume) && (
          <S.Row>
            <S.Label>Объем</S.Label>
            <S.Volume>{formatNumber(volume)} тонн</S.Volume>
          </S.Row>
        )}

        {isNumber(initialVolume) && (
          <S.Row>
            <S.Label>Исходный объем</S.Label>
            <S.Volume>{formatNumber(initialVolume)} тонн</S.Volume>
          </S.Row>
        )}

        {isNumber(availableVolume) && (
          <S.Row>
            <S.Label>Доступный к продаже</S.Label>
            <S.Volume>{formatNumber(availableVolume)} тонн</S.Volume>
          </S.Row>
        )}

        {!priceDetail && isNumber(price) && (
          <S.Row>
            <S.Label>Цена</S.Label>
            <S.Price>
              {formatNumber(price)} ₽ {includingNds !== undefined && `(${getNdsString(includingNds)})`}
            </S.Price>
          </S.Row>
        )}

        {isAdmin && !!priceDetail && (
          <>
            <S.Row>
              <S.Label>Цена без комиссии</S.Label>
              <S.Price>
                {formatNumber(priceDetail.originalPrice)} ₽{' '}
                {includingNds !== undefined && `(${getNdsString(includingNds)})`}
              </S.Price>
            </S.Row>
            <S.Row>
              <S.Label>Комиссия</S.Label>
              <S.Price>{getValueIfExistOrDefault(formatNumber(priceDetail?.commission), '₽')}</S.Price>
            </S.Row>

            <S.Row>
              <S.Label>Цена с учетом комиссии</S.Label>
              <S.Price>
                {formatNumber(priceDetail.correctedPrice)} ₽{' '}
                {includingNds !== undefined && `(${getNdsString(includingNds)})`}
              </S.Price>
            </S.Row>
          </>
        )}

        {isNumber(minPrice) && (
          <S.Row>
            <S.Label>Минимальная ставка</S.Label>
            <S.Price>
              {formatNumber(minPrice)} ₽ {includingNds !== undefined && `(${getNdsString(includingNds)})`}
            </S.Price>
          </S.Row>
        )}

        {isNumber(maxPrice) && (
          <S.Row>
            <S.Label>Максимальная ставка</S.Label>
            <S.Price>
              {formatNumber(maxPrice)} ₽ {includingNds !== undefined && `(${getNdsString(includingNds)})`}
            </S.Price>
          </S.Row>
        )}

        {isShowDivider && <S.DividerStyled />}

        {address && (
          <S.Row>
            <S.Label>Локация</S.Label>
            <S.Value>{address}</S.Value>
          </S.Row>
        )}

        {isNumber(distance) && (
          <S.Row>
            <S.Label>Расстояние до склада</S.Label>
            <S.Value>{distance} км</S.Value>
          </S.Row>
        )}

        {shippingRequired && type === OfferDataTypeEnum.Buy && (
          <S.Row>
            <S.Label>Перевозка груза</S.Label>
            <S.Value>Требуется перевозчик для доставки до склада</S.Value>
          </S.Row>
        )}

        {publishedAt && (
          <S.Row>
            <S.Label>Дата публикации</S.Label>
            <S.Value>{getDateFormat(new Date(publishedAt))}</S.Value>
          </S.Row>
        )}

        {acceptedAt && (
          <S.Row>
            <S.Label>Дата создания</S.Label>
            <S.Value>{getDateFormat(new Date(acceptedAt))}</S.Value>
          </S.Row>
        )}

        {addedAt && (
          <S.Row>
            <S.Label>Дата добавления</S.Label>
            <S.Value>{getDateFormat(new Date(addedAt))}</S.Value>
          </S.Row>
        )}

        {startAuction && (
          <S.Row>
            <S.Label>Начало торгов</S.Label>
            <S.Value>{getDateTimeFormat(new Date(startAuction))}</S.Value>
          </S.Row>
        )}

        {endAuction && (
          <S.Row>
            <S.Label>Торги заканчиваются</S.Label>
            <S.Value>{getDateTimeFormat(new Date(endAuction))}</S.Value>
          </S.Row>
        )}

        {((isShowDivider && company && isShowCompanyInfo) || contactPersonName || contactPersonPhone) && (
          <S.DividerStyled />
        )}

        {company && isShowCompanyInfo && (
          <>
            <S.Row>
              <S.Label>Компания</S.Label>
              <S.Value>
                {company.name} <AdminLinkToCompanyById companyId={company.id} />
              </S.Value>
            </S.Row>

            <S.Row>
              <S.Label>ИНН</S.Label>
              <S.Value>{company.inn}</S.Value>
            </S.Row>
          </>
        )}

        {contactPersonName && (
          <S.Row>
            <S.Label>Контактное лицо</S.Label>
            <S.Value>{contactPersonName}</S.Value>
          </S.Row>
        )}

        {contactPersonPhone && (
          <S.Row>
            <S.Label>Телефон</S.Label>
            <S.Value>{formatPhoneNumber(contactPersonPhone)}</S.Value>
          </S.Row>
        )}

        {note && (
          <>
            <S.Row>
              <S.Label>Примечание</S.Label>
              <S.Value>{note}</S.Value>
            </S.Row>
          </>
        )}

        {createdBy && (
          <>
            <S.DividerStyled />
            <S.Row>
              <S.Label>Предложение создал</S.Label>
              <S.Value>
                <>
                  {getUserRoleOld(createdBy.role)}:
                  <br />
                  <span>{createdBy.name}</span>
                  <br />
                  <PhoneNumber prefix="" phoneNumber={createdBy.phoneNumber} />
                  <br />
                  ID:{' '}
                  <Button type="link">
                    <Link to={appRoutes.moderatorUserDetails.url.replace(':id', createdBy?.id.toString() ?? '')}>
                      {createdBy?.id}
                    </Link>
                  </Button>
                </>
              </S.Value>
            </S.Row>
          </>
        )}

        {/*{isAdmin && id && (*/}
        {/*  <S.Actions>*/}
        {/*    <Button*/}
        {/*      onClick={() => setVisibleComments(true)}*/}
        {/*      style={{ marginRight: 'auto' }}*/}
        {/*      type="link"*/}
        {/*      icon={<FiMessageCircle />}*/}
        {/*    >*/}
        {/*      Комментарии*/}
        {/*    </Button>*/}
        {/*    <Comments offerId={id} visible={isVisibleComments} onClose={() => setVisibleComments(false)} />*/}
        {/*  </S.Actions>*/}
        {/*)}*/}
      </S.Content>
    </Card>
  )
}
