import * as yup from 'yup'

import { FreightOrderDocumentType } from '~api/gql-generated/graphql'
import { VALIDATORS_MSG } from '~shared/validations'

export const loadAddDocumentFormSchema = yup
  .object({
    documentType: yup.mixed().oneOf(Object.values(FreightOrderDocumentType)).required(VALIDATORS_MSG.required),
    documentFiles: yup.mixed().required(VALIDATORS_MSG.requiredUpload),
  })
  .required()
