import React, { useEffect, useState } from 'react'

import { Divider, Input, InputNumber, Result, Skeleton, Spin } from 'antd'
import { debounce } from 'lodash'
import { Controller, useForm } from 'react-hook-form'

import { yupResolver } from '@hookform/resolvers/yup/dist/yup'

import { AppConfig } from '~/appConfig'
import { useGetProductTypeData } from '~/hooks'
import { DaDataPartySuggestion, FormField, InputDadata, Text } from '~/ui-components'
import { getElevatorAdvertStatus } from '~/utils/elevator-adverts/get-elevator-advert-status'
import { useGetElevatorAdvertAdmin } from '~hooks/elevator-advert-admin'
import { PhoneNumberControl, PriceControl, UsingNdsControl, VolumeControl } from '~shared/controls'
import { ParametersCultureControlValue } from '~shared/controls/ParametersCultureControl'
import { fullPhoneNumberValidator } from '~shared/validations/schemas'

import { createAdvertOfferFormByAdminSchema } from './CreateAdvertOfferFormBuyByAdmin.validation'

import {
  CreateAdvertOfferFormBuyByAdminProps,
  CreateAdvertOfferFormBuyByAdminValues,
} from './CreateAdvertOfferFormBuyByAdmin.types'

import * as S from './CreateAdvertOfferFormBuyByAdmin.styled'
export const CreateAdvertOfferFormBuyByAdmin: React.FC<CreateAdvertOfferFormBuyByAdminProps> = ({
  defaultValues,
  onSubmit,
  formId,
}) => {
  const schema = createAdvertOfferFormByAdminSchema()
  const [isEditParams, setEditParams] = useState(false)
  const { getProductTypeParametersForUI_V2 } = useGetProductTypeData()

  const {
    trigger,
    watch,
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm<CreateAdvertOfferFormBuyByAdminValues>({
    defaultValues: {
      advertId: null,
      pricePerTon: defaultValues?.pricePerTon,
      volume: defaultValues?.volume,
      usingNds: defaultValues?.usingNds ?? false,
      companyInfo: {
        name: '',
        inn: '',
        kpp: '',
        ogrn: '',
        codeOkved: '',
        director: '',
        actualAddress: '',
        legalAddress: '',
      },
      contactUser: '',
      contactPhone: '',
    },
    resolver: yupResolver(schema),
  })

  const advertIdWatch = watch('advertId')
  const contactPhoneWatch = watch('contactPhone')
  const companyInfoWatch = watch('companyInfo')

  useEffect(() => {
    if (fullPhoneNumberValidator(contactPhoneWatch) && companyInfoWatch.inn) {
      void trigger('contactPhone')
    }
  }, [companyInfoWatch.inn, contactPhoneWatch])

  const onSubmitHandler = (formValues: CreateAdvertOfferFormBuyByAdminValues) => {
    onSubmit(formValues)
  }

  const handleDadataSelect = (value?: DaDataPartySuggestion | undefined): void => {
    const data = value?.data

    if (!data) {
      console.log('There is no data for set')
      return
    }

    setValue(
      'companyInfo',
      {
        name: data.name?.short_with_opf ?? value?.value,
        inn: data?.inn,
        kpp: data?.kpp ?? '',
        ogrn: data?.ogrn ?? '',
        codeOkved: data?.okved,
        director: data.type === 'INDIVIDUAL' ? data.name?.full : data.management?.name ?? '',
        legalAddress: data.address?.value,
        actualAddress: data.address?.value,
      },
      { shouldValidate: true }
    )
  }

  const resetCompanyInfo = (name?: string) => {
    setValue('companyInfo', {
      name: name ?? '',
      inn: '',
      kpp: '',
      ogrn: '',
      codeOkved: '',
      director: '',
      legalAddress: '',
      actualAddress: '',
    })
  }
  const getElevatorAdvertAdminFn = useGetElevatorAdvertAdmin({ elevatorAdvertId: advertIdWatch ?? NaN })

  const foundAdvert = getElevatorAdvertAdminFn.data

  useEffect(() => {
    setEditParams(false)
  }, [foundAdvert?.id])

  const isAdvertFitsForOffer = foundAdvert?.status === 'PUBLISHED' ?? false

  return (
    <form id={formId} onSubmit={handleSubmit(onSubmitHandler)}>
      <FormField
        label="Введите номер объявления"
        validateMessage={getElevatorAdvertAdminFn.isError ? 'Объявление не найдено' : errors.advertId?.message}
        isRequired
      >
        <Controller
          name="advertId"
          control={control}
          render={({ field }) => (
            <Spin spinning={getElevatorAdvertAdminFn.isFetching} size="small">
              <InputNumber
                onChange={debounce((x) => field.onChange(x), AppConfig.DEFAULT_DEBOUNCE_TIME)}
                value={field.value}
                placeholder="100"
                style={{ width: '100%' }}
                status={errors.advertId || getElevatorAdvertAdminFn.isError ? 'error' : undefined}
              />
            </Spin>
          )}
        />
      </FormField>

      <Skeleton loading={getElevatorAdvertAdminFn.isFetching} />

      {foundAdvert && !getElevatorAdvertAdminFn.isFetching ? (
        <S.FoundAdvertInfo>
          <Text variant="h4">{foundAdvert?.company?.name}</Text>
          <Text variant="h4">{foundAdvert?.product.name}</Text>

          {(!foundAdvert.allowOffersWithOtherParameters || !isEditParams) &&
            !!foundAdvert.product.parameters.length && (
              <S.Parameters>
                {getProductTypeParametersForUI_V2(foundAdvert.product).map((param) => (
                  <div key={param}>{param}</div>
                ))}
              </S.Parameters>
            )}
        </S.FoundAdvertInfo>
      ) : (
        <S.NotFoundAdvertText>Данные появятся после выбора объявления</S.NotFoundAdvertText>
      )}

      <Divider />

      {isAdvertFitsForOffer ? (
        <>
          <FormField label="Объем" htmlFor="volume" validateMessage={errors.volume?.message} isRequired>
            <Controller
              name="volume"
              control={control}
              render={({ field }) => <VolumeControl {...field} id="volume" hasError={!!errors.volume} />}
            />
          </FormField>

          <S.PriceControlWrapper>
            <FormField
              htmlFor="pricePerTon"
              label="Предлагаемая цена"
              validateMessage={errors.pricePerTon?.message}
              isRequired
            >
              <Controller
                name="pricePerTon"
                control={control}
                render={({ field }) => (
                  <PriceControl id="pricePerTon" {...field} hasError={!!errors.pricePerTon} addAfter="₽/т" />
                )}
              />
            </FormField>

            <FormField htmlFor="usingNds" validateMessage={errors.usingNds?.message} label="&nbsp;">
              <Controller name="usingNds" control={control} render={({ field }) => <UsingNdsControl {...field} />} />
            </FormField>
          </S.PriceControlWrapper>

          <Divider />

          <FormField
            label="Наименование компании или ИНН"
            htmlFor="companyInfo"
            validateMessage={errors.companyInfo?.inn?.message}
            isRequired
          >
            <Controller
              name="companyInfo"
              control={control}
              render={({ field }) => (
                <InputDadata
                  id="name"
                  placeholder="Введите наименование компании"
                  inputValue={field.value.name}
                  onSelect={handleDadataSelect}
                  variant="organizations"
                  inputStatus={errors.companyInfo && 'error'}
                  onChangeInput={resetCompanyInfo}
                />
              )}
            />
          </FormField>

          <FormField
            label="Контактное лицо"
            htmlFor="contactUser"
            validateMessage={errors.contactUser?.message}
            isRequired
          >
            <Controller
              name="contactUser"
              control={control}
              render={({ field }) => (
                <Input id="contactUser" placeholder="Укажите ФИО" {...field} status={errors.contactUser && 'error'} />
              )}
            />
          </FormField>

          <FormField validateMessage={errors.contactPhone?.message} label="Телефон" htmlFor="contactPhone" isRequired>
            <Controller
              name="contactPhone"
              control={control}
              render={({ field }) => (
                <PhoneNumberControl {...field} id="contactPhone" hasError={!!errors.contactPhone} />
              )}
              rules={{
                validate: {
                  contactPhone: () => false,
                },
              }}
            />
          </FormField>
        </>
      ) : (
        foundAdvert && (
          <Result
            status="warning"
            title="Невозможно предложить объем"
            extra={`Предложение в статусе "${getElevatorAdvertStatus(foundAdvert.status).name}"`}
          />
        )
      )}
    </form>
  )
}
