import React, { useState } from 'react'

import { Button } from 'antd'
import { observer } from 'mobx-react-lite'
import { FiFilter } from 'react-icons/fi'

import { dealsFilterStore } from '~/store/filters/dealsFilterStore'
import { useApi } from '~providers/api'

import { DealsFilterForm, IDealsFilterForm } from './components/DealsFilterForm'

import * as S from './DealsFilter.styled'

interface IDealFilterProps {
  companyId: number
}

export const DealsFilter: React.FC<IDealFilterProps> = observer((props) => {
  const store = dealsFilterStore
  const api = useApi()
  const [isVisible, setVisible] = useState(false)
  const [foundResults, setFoundResults] = useState<number | null>(null)

  const onOpenHandler = () => setVisible(true)
  const onCloseHandler = () => setVisible(false)
  const onResetToDefaultValuesHandler = () => setFoundResults(null)

  const onApplyFilters = (values: IDealsFilterForm) => {
    onCloseHandler()
    store.setFilters(values)
  }

  // filters just changed, not applied (for counting result)
  const onChangeFilters = async (values: IDealsFilterForm) => {
    const response = await api.deal.dealControllerGetCompanyDeals({
      companyId: props.companyId,
      pageSize: 1,
      minVolume: values.volume.from,
      maxVolume: values.volume.to,
      minPrice: values.price.from,
      maxPrice: values.price.to,
      type: values.dealType === 'ALL' ? undefined : values.dealType,
      statuses: values.status ? [values.status] : undefined,
      regionIds: values.regionIds,
    })
    const foundResults = response.data.pagination.total
    setFoundResults(foundResults)
  }

  return (
    <>
      <Button icon={<FiFilter />} onClick={onOpenHandler}>
        Фильтры
        {store.hasActiveFilters ? (
          <S.ActiveFilterCount variant="normal-bold">({store.activeFiltersCount})</S.ActiveFilterCount>
        ) : null}
      </Button>

      <S.StyledDrawer
        className="deal-filter-menu"
        title="Фильтры"
        placement="right"
        onClose={onCloseHandler}
        open={isVisible}
      >
        <DealsFilterForm
          defaultValues={store.defaultFiltersConfig}
          currentValues={store.dealsFilters}
          onSubmit={onApplyFilters}
          onChange={onChangeFilters}
          onResetToDefaultValues={onResetToDefaultValuesHandler}
          foundResults={foundResults}
        />
      </S.StyledDrawer>
    </>
  )
})
