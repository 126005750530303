import React from 'react'

import { RouteObject } from 'react-router-dom'

import { OffersPage } from '~/pages'
import { appRoutes } from '~/router'
import {
  AcceptedOffers,
  AcceptedOffersDetails,
  MyOffers,
  MyOffersDetails,
  OffersDetails,
  OffersList,
} from '~pages/OffersPage'

import { RequireAuth } from '../../providers/auth'

export const offersRouter: RouteObject = {
  path: appRoutes.offers.url,
  element: (
    <RequireAuth>
      <OffersPage />
    </RequireAuth>
  ),
  children: [
    {
      path: appRoutes.offersList.url,
      element: <OffersList />,
    },
    {
      path: appRoutes.offersListSell.url,
      element: <OffersList />,
    },
    {
      path: appRoutes.offersListBuy.url,
      element: <OffersList />,
    },
    {
      path: appRoutes.offersListDetails.url,
      element: <OffersDetails />,
    },
    {
      path: appRoutes.offersListSellDetails.url,
      element: <OffersDetails />,
    },
    {
      path: appRoutes.offersListBuyDetails.url,
      element: <OffersDetails />,
    },
    {
      path: appRoutes.offersMy.url,
      element: <MyOffers />,
    },
    {
      path: appRoutes.offersMyDetails.url,
      element: <MyOffersDetails />,
    },
    {
      path: appRoutes.offersResponses.url,
      element: <AcceptedOffers />,
    },
    {
      path: appRoutes.offersResponsesDetails.url,
      element: <AcceptedOffersDetails />,
    },
  ],
}
