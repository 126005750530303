import React, { useEffect, useState } from 'react'

import { Button } from 'antd'
import { Controller, useForm } from 'react-hook-form'
import { FiAlertCircle } from 'react-icons/fi'

import { yupResolver } from '@hookform/resolvers/yup/dist/yup'

import { useGetProductTypeData } from '~/hooks'
import { FormField, Text, UploadButton } from '~/ui-components'
import { OfferPriceType } from '~api/generated'
import {
  AddressControlDadata,
  CheckboxControl,
  ParametersCultureControl,
  PriceControl,
  PriceTypeControl,
  UsingNdsControl,
  VolumeControl,
} from '~shared/controls'

import { createAdvertFormSchema } from './CreateAdvertOfferForm.validation'

import { CreateAdvertOfferFormProps, CreateAdvertOfferFormValues } from './CreateAdvertOfferForm.types'

import * as S from './CreateAdvertOfferForm.styled'

export const CreateAdvertOfferForm: React.FC<CreateAdvertOfferFormProps> = ({
  onSubmit,
  formId,
  defaultValues,
  productData,
  allowOffersWithOtherParameters,
}) => {
  const { getProductTypeParametersForUI_V2 } = useGetProductTypeData()
  const [isEditParams, setEditParams] = useState(false)

  const {
    watch,
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm<CreateAdvertOfferFormValues>({
    defaultValues: {
      pricePerTon: defaultValues?.pricePerTon,
      volume: defaultValues?.volume,
      usingNds: defaultValues?.usingNds ?? false,
      priceType: defaultValues?.priceType,
      culture: defaultValues?.culture,
      labAnalysis: defaultValues?.labAnalysis,
      requiredLaboratoryAnalysis: defaultValues?.requiredLaboratoryAnalysis ?? false,
    },
    resolver: yupResolver(createAdvertFormSchema),
  })

  const priceTypeWatch = watch('priceType')
  const requiredLaboratoryAnalysisWatch = watch('requiredLaboratoryAnalysis')
  const labAnalysisWatch = watch('labAnalysis')
  const isShowAddressControl = priceTypeWatch === OfferPriceType.AtSellersGate

  useEffect(() => {
    setValue('address', undefined)
  }, [priceTypeWatch])

  const onSubmitHandler = (formValues: CreateAdvertOfferFormValues) => {
    onSubmit(formValues)
  }

  return (
    <form id={formId} onSubmit={handleSubmit(onSubmitHandler)}>
      <Text variant="h3">{productData?.name}</Text>

      {!allowOffersWithOtherParameters ? (
        <S.ParametersInfo isAllow={false}>
          <FiAlertCircle size="24" />
          <Text variant="normal">Показатели вашей культуры должны совпадать с указанными в объявлении</Text>
        </S.ParametersInfo>
      ) : (
        <FormField label="Показатели культуры" isVisible={!isEditParams}>
          <S.EditCultureParamsWrapper>
            <S.EditCultureParamsText>У вас другие показатели?</S.EditCultureParamsText>
            <div>
              <Button htmlType="button" onClick={() => setEditParams(true)}>
                Редактировать
              </Button>
            </div>
          </S.EditCultureParamsWrapper>
        </FormField>
      )}

      <FormField
        isVisible={allowOffersWithOtherParameters && isEditParams}
        validateMessage={errors.culture ? 'Параметры культуры заполнены с ошибками' : ''}
      >
        <Controller
          name="culture"
          control={control}
          render={({ field }) => <ParametersCultureControl isRequired {...field} isShowOnlyParams />}
        />
      </FormField>

      {(!allowOffersWithOtherParameters || !isEditParams) && !!productData?.parameters.length && (
        <S.Parameters>
          {getProductTypeParametersForUI_V2(productData).map((param) => (
            <div key={param}>{param}</div>
          ))}
        </S.Parameters>
      )}

      <FormField label="Анализная карта" validateMessage={errors.labAnalysis?.message} isRequired>
        <Controller
          control={control}
          name="labAnalysis"
          render={({ field }) => (
            <UploadButton
              disabled={!!requiredLaboratoryAnalysisWatch}
              onDelete={() => field.onChange(undefined)}
              onChange={(evt) => {
                if (evt.target.files?.length) {
                  field.onChange(evt.target.files[0])
                }
              }}
            >
              Загрузить
            </UploadButton>
          )}
        />
      </FormField>

      {/*<S.OrText>или</S.OrText>*/}

      <FormField htmlFor="requiredLaboratoryAnalysis" validateMessage={errors.requiredLaboratoryAnalysis?.message}>
        <Controller
          name="requiredLaboratoryAnalysis"
          control={control}
          render={({ field }) => (
            <CheckboxControl {...field} disabled={!!labAnalysisWatch}>
              Заказать отбор качества
            </CheckboxControl>
          )}
        />
      </FormField>

      <FormField label="Объем" htmlFor="volume" validateMessage={errors.volume?.message} isRequired>
        <Controller
          name="volume"
          control={control}
          render={({ field }) => <VolumeControl {...field} id="volume" hasError={!!errors.volume} />}
        />
      </FormField>

      <S.PriceControlWrapper>
        <FormField
          htmlFor="pricePerTon"
          label="Предлагаемая цена"
          validateMessage={errors.pricePerTon?.message}
          isRequired
        >
          <Controller
            name="pricePerTon"
            control={control}
            render={({ field }) => (
              <PriceControl id="pricePerTon" {...field} hasError={!!errors.pricePerTon} addAfter="₽/т" />
            )}
          />
        </FormField>

        <FormField htmlFor="usingNds" validateMessage={errors.usingNds?.message} label="&nbsp;">
          <Controller name="usingNds" control={control} render={({ field }) => <UsingNdsControl {...field} />} />
        </FormField>
      </S.PriceControlWrapper>

      <FormField htmlFor="priceType" validateMessage={errors.priceType?.message} label="Тип стоимости" isRequired>
        <Controller name="priceType" control={control} render={({ field }) => <PriceTypeControl {...field} />} />
      </FormField>

      <FormField
        label="Адрес"
        htmlFor="address"
        isRequired
        validateMessage={errors.address?.message}
        isVisible={isShowAddressControl}
      >
        <Controller
          name="address"
          control={control}
          render={({ field }) => (
            <AddressControlDadata
              id="address"
              value={undefined}
              onChange={(value) => field.onChange(value)}
              hasError={!!errors.address}
            />
          )}
        />
      </FormField>
    </form>
  )
}
