import styled from 'styled-components'
import { Flex } from 'antd'

export const DocumentCardNotAvailable = styled(Flex)`
  justify-content: center;
  align-items: center;
  min-height: 112px;
  padding: 24px;
  border: 1px dashed var(--gray-5-color);
  border-radius: 16px;
  box-shadow: 0 1px 4px rgba(84, 84, 84, 0.15);
  background: #fff;
`

export const TransportDocumentNotAvailableText = styled.div`
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  color: var(--gray-5-color);
  text-align: center;
`
