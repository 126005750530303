import React, { FC } from 'react'

import { Badge as BadgeAntd, Button, Modal, Tooltip } from 'antd'
import { FiDownload, FiEye, FiPlus, FiTrash2, FiUpload } from 'react-icons/fi'

import { Badge } from '~/ui-components'
import { getDocumentStatus } from '~/utils/documents/get-documents-status'
import { getDateFormat } from '~/utils/getDateFormat'
import { DocumentPartStatus } from '~api/gql-generated/graphql'

import { DocumentCardProps } from './DocumentCard.types'

import * as S from './DocumentCard.styled'

export const DocumentCard: FC<DocumentCardProps> = ({
  status,
  name,
  index,
  count,
  createdAt,
  canChange,
  isAdmin,
  isDownloading,
  isShowRemoveForAdmin,
  onShow,
  onDownload,
  onRemove,
  onAddFile,
  onReplaceFile,
}) => {
  const statusData = getDocumentStatus(status)

  const handleRemoveDocument = () => {
    Modal.confirm({
      title: 'Вы уверены?',
      okText: 'Да',
      cancelText: 'Отмена',
      onOk() {
        onRemove?.()
      },
    })
  }

  return (
    <S.DocumentCard danger={status === DocumentPartStatus.Rejected}>
      <S.Header>
        <S.Name>
          {name} {index !== undefined && index + 1}
        </S.Name>
        <S.Actions>
          <Tooltip title="Открыть документ">
            <BadgeAntd count={count && count > 1 ? count : undefined}>
              <Button type="text" icon={<FiEye size={24} />} onClick={onShow} />
            </BadgeAntd>
          </Tooltip>

          {isDownloading && (
            <Tooltip title="Скачать документ">
              <Button type="text" icon={<FiDownload size={24} />} onClick={onDownload} />
            </Tooltip>
          )}

          {canChange && status === DocumentPartStatus.Unchecked && !isAdmin && (
            <Tooltip title="Удалить документ">
              <Button type="text" icon={<FiTrash2 size={24} />} onClick={handleRemoveDocument} />
            </Tooltip>
          )}

          {canChange && ((status === DocumentPartStatus.Rejected && isAdmin) || isShowRemoveForAdmin) && (
            <Tooltip title="Удалить документ">
              <Button type="text" icon={<FiTrash2 size={24} />} onClick={handleRemoveDocument} />
            </Tooltip>
          )}
        </S.Actions>
      </S.Header>
      <S.Footer>
        <Badge size="s" color={statusData.color}>
          {statusData.name}
        </Badge>
        {/*{canChange && status === DocumentPartStatus.Unchecked && !isAdmin && (*/}
        {/*  <Button type="link" icon={<FiPlus />} onClick={onAddFile}>*/}
        {/*    Добавить файл*/}
        {/*  </Button>*/}
        {/*)}*/}
        {/*{canChange && status === DocumentPartStatus.Rejected && (*/}
        {/*  <Button type="link" icon={<FiUpload />} onClick={onReplaceFile}>*/}
        {/*    Загрузить новый*/}
        {/*  </Button>*/}
        {/*)}*/}
        {(status === DocumentPartStatus.Confirmed ||
          (status === DocumentPartStatus.Unchecked && isAdmin) ||
          (status === DocumentPartStatus.Unchecked && !canChange)) && <S.Date>{getDateFormat(createdAt)}</S.Date>}
      </S.Footer>
    </S.DocumentCard>
  )
}
