import { useMutation } from '@tanstack/react-query'

import { appToast } from '~/utils'
import { useApi } from '~providers/api'

export const useUploadFilesAndGetIds = () => {
  const api = useApi()

  async function uploadFilesAndGetIds(files?: File[]): Promise<string[]> {
    try {
      if (!files?.length) {
        return Promise.reject('LabAnalysis is required')
      }
      const res = await api.file.fileControllerUploadFiles({ files })
      return res.data.fileKeys
    } catch (e) {
      appToast.error({ description: 'Ошибка при загрузке файлов на сервер' })
      return Promise.reject('[uploadFilesAndGetIds]: Upload files was failed')
    }
  }

  return useMutation(uploadFilesAndGetIds, { retry: 3 })
}
