import React from 'react'

import { Empty, Skeleton } from 'antd'

import { DealCard } from '~pages/Deals/components/DealCard'

import { IDealListMobileProps } from './DealListMobile.types'
import { DealListTableDataType } from '~pages/Deals/components/DealListTable/DealListTable.types'

export const DealListMobile: React.FC<IDealListMobileProps> = ({ data, loading, onItemClick }) => {
  const onItemClickHandler = (record: DealListTableDataType) => {
    onItemClick(record)
  }

  if (loading) {
    return <Skeleton />
  }

  if (!data.length) {
    return <Empty />
  }

  return (
    <>
      {data.map((item) => (
        <DealCard key={item.id} {...item} onItemClick={onItemClickHandler} />
      ))}
    </>
  )
}
