import styled from 'styled-components'

import css from '@styled-system/css'

import { Badge, Box } from '~/ui-components'

export const Form = styled('form')(
  css({
    width: ['100%', null, '642px'],
  })
)

export const ContentWrapper = styled(Box)(
  css({
    minWidth: ['230px', '260px', '300px'],
  })
)

export const ActionWrapper = styled(Box)(
  css({
    display: 'flex',
    justifyContent: 'end',
  })
)

export const BadgeStyled = styled(Badge)(
  css({
    whiteSpace: 'unset',
    marginBottom: '32px',
  })
)

export const HalfRowWidth = styled.div(
  css({
    width: ['100%', '100%', '50%'],
  })
)

export const GridRow = styled.div(
  css({
    display: 'grid',
    gridTemplateColumns: ['1fr', null, '1fr 1fr'],
    alignItems: 'center',
    columnGap: '32px',
  })
)

export const TotalPriceWrapper = styled.div(
  css({
    color: 'TxtDefault',
    padding: ['0 0 18px 0', null, '18px 0'],
  })
)

export const Price = styled.span(
  css({
    color: 'GreenPrimary',
  })
)
