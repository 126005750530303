import { useMutation } from '@tanstack/react-query'

import { OfferApiAdminGetOffersReportRequest } from '~api/generated'
import { useApi } from '~providers/api'

export const useGetReportOffersByAdmin = () => {
  const api = useApi()

  return useMutation((request: OfferApiAdminGetOffersReportRequest) => {
    return api.offers.adminGetOffersReport(request, { responseType: 'blob' })
  })
}
