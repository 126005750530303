import React, { useState } from 'react'

import { Button, Modal, Space } from 'antd'
import { FiArrowRight } from 'react-icons/fi'

import { Text } from '~/ui-components'
import { modalSharedSettings } from '~/utils'
import { CompanyType } from '~api/gql-generated/graphql'
import { CreateAdvertFormByBuyer } from '~pages/BasisPage/components/CreateAdvertFormByBuyer'
import { CreateAdvertFormBySeller } from '~pages/BasisPage/components/CreateAdvertFormBySeller'

import { CreateAdvertFormByBuyerValues } from '~pages/BasisPage/components/CreateAdvertFormByBuyer/CreateAdvertFormByBuyer.types'
import { CreateAdvertFormBySellerValues } from '~pages/BasisPage/components/CreateAdvertFormBySeller/CreateAdvertFormBySeller.types'

import * as S from './CreateBasisAdvertFormModal.styled'

const FORM_ID = 'create-basis-advert-form-id'

interface CreateBasisAdvertFormModalProps {
  open: boolean
  loading: boolean
  currentCompanyRole: CompanyType
  onCancel: () => void
  defaultElevatorId?: number
  onSubmitFormAsSeller: (form: CreateAdvertFormBySellerValues) => void
  onSubmitFormAsBuyer: (form: CreateAdvertFormByBuyerValues) => void
}

export const CreateBasisAdvertFormModal: React.FC<CreateBasisAdvertFormModalProps> = (props) => {
  const isTrader = props.currentCompanyRole === CompanyType.Trader
  const [traderChoose, setTraderChoose] = useState<'sell' | 'buy' | null>(null)
  const onCancelHandler = () => {
    setTraderChoose(null)
    props.onCancel()
  }

  const onSubmitFormAsBuyerHandler = (form: CreateAdvertFormByBuyerValues) => {
    setTraderChoose(null)
    props.onSubmitFormAsBuyer(form)
  }

  const onSubmitFormAsSellerHandler = (form: CreateAdvertFormBySellerValues) => {
    setTraderChoose(null)
    props.onSubmitFormAsSeller(form)
  }

  const renderTraderChoose = () => {
    if (!traderChoose) {
      return (
        <div>
          <Text variant="normal-bold">Выберите тип объявления</Text>
          <br />
          <Space direction="vertical" style={{ width: '100%' }}>
            <Button size="large" block style={{ height: 56 }} onClick={() => setTraderChoose('buy')}>
              <S.TraderButton>
                <span>На покупку</span>
                <FiArrowRight size={24} />
              </S.TraderButton>
            </Button>
            <Button size="large" block style={{ height: 56 }} onClick={() => setTraderChoose('sell')}>
              <S.TraderButton>
                <span>На продажу</span>
                <FiArrowRight size={24} />
              </S.TraderButton>
            </Button>
          </Space>
        </div>
      )
    }
    switch (traderChoose) {
      case 'buy':
        return <CreateAdvertFormByBuyer formId={FORM_ID} onSubmit={onSubmitFormAsBuyerHandler} />
      case 'sell':
        return <CreateAdvertFormBySeller formId={FORM_ID} onSubmit={onSubmitFormAsSellerHandler} />
      default:
        return null
    }
  }

  return (
    <Modal
      {...modalSharedSettings}
      width={isTrader && !traderChoose ? 400 : 500}
      open={props.open}
      onCancel={onCancelHandler}
      title="Создать объявление"
      footer={
        isTrader && !traderChoose
          ? null
          : [
              <Button key="cancel" onClick={onCancelHandler} htmlType="button">
                Отмена
              </Button>,
              <Button key="submit" form={FORM_ID} type="primary" htmlType="submit" loading={props.loading}>
                Создать
              </Button>,
            ]
      }
    >
      {props.currentCompanyRole === CompanyType.Trader && renderTraderChoose()}
      {props.currentCompanyRole === CompanyType.Buyer && (
        <CreateAdvertFormByBuyer
          formId={FORM_ID}
          onSubmit={onSubmitFormAsBuyerHandler}
          defaultValues={props.defaultElevatorId ? { elevatorId: props.defaultElevatorId } : undefined}
        />
      )}
      {props.currentCompanyRole === CompanyType.Seller && (
        <CreateAdvertFormBySeller
          formId={FORM_ID}
          onSubmit={onSubmitFormAsSellerHandler}
          defaultValues={props.defaultElevatorId ? { elevatorId: props.defaultElevatorId } : undefined}
        />
      )}
    </Modal>
  )
}
