import React from 'react'

import { Button, Empty, Modal, Pagination, Skeleton, Tooltip } from 'antd'
import { FiCheckCircle, FiMail } from 'react-icons/fi'

import { useQueryClient } from '@tanstack/react-query'

import { usePagination } from '~/hooks'
import { getDateTimeFormat } from '~/utils/getDateFormat'
import { useGetNotifications, useMarkNotificationRead, useMarkNotificationReadForAll } from '~hooks/notifications'
import { parseNotification } from '~pages/Notifications/NotificationsPage/utils/parse-notification'
import { useAuth } from '~providers/auth'

import * as S from './NotificationsList.styled'

export const NotificationsList: React.FC = () => {
  const { user } = useAuth()
  const queryClient = useQueryClient()
  const { currentPage, setCurrentPage, pageSize, setPageSize, pageSizeOptions } = usePagination()
  const notificationsFn = useGetNotifications({ currentPage, pageSize })
  const markNotificationReadFn = useMarkNotificationRead()
  const markNotificationReadForAllFn = useMarkNotificationReadForAll()

  const readNotificationHandler = async (id: number) => {
    const foundNotification = notificationsFn.data?.notifications.find((x) => x.id === id)
    if (!foundNotification || !foundNotification.isViewed) {
      await markNotificationReadFn.mutateAsync(id)
      await notificationsFn.refetch()
      await queryClient.invalidateQueries(['unreadNotifications'])
    }
  }

  const handleReadAllNotifications = () => {
    Modal.confirm({
      title: 'Вы уверены?',
      content: 'Все сообщения будут прочитаны',
      okText: 'Да',
      cancelText: 'Отмена',
      async onOk() {
        await markNotificationReadForAllFn.mutateAsync()
        await queryClient.invalidateQueries(['unreadNotifications'])
        await notificationsFn.refetch()
      },
    })
  }

  if (notificationsFn.isLoading || !notificationsFn.data) {
    return <Skeleton />
  }
  const { notifications, pagination } = notificationsFn.data

  if (!notifications?.length) {
    return <Empty description="Уведомления отсутсвуют" />
  }

  return (
    <S.Content>
      <S.NotificationWrapper>
        <S.ActionWrapper>
          <Button onClick={handleReadAllNotifications}>Отметить все, как прочитанные</Button>
        </S.ActionWrapper>

        {notifications.map((n) => (
          <div key={n.id}>
            <S.Desktop key={`Desktop_${n.id}`}>
              <S.Notification>
                <div>
                  <FiCheckCircle size={22} color="var(--success-color)" />
                </div>
                <S.NotReadStatus isShow={!n.isViewed}>Новое</S.NotReadStatus>
                <S.TextWrapper>{parseNotification({ text: n.message, userRole: user?.role })}</S.TextWrapper>
                <S.ReadButtonWrapper isShow={!n.isViewed}>
                  <Tooltip title="прочитать">
                    <Button
                      icon={<FiMail size={24} />}
                      type="text"
                      size="small"
                      onClick={() => readNotificationHandler(n.id)}
                    ></Button>
                  </Tooltip>
                </S.ReadButtonWrapper>
                <S.NotificationDate>{getDateTimeFormat(n.createdAt)}</S.NotificationDate>
              </S.Notification>
            </S.Desktop>

            <S.Mobile key={`Mobile_${n.id}`}>
              <S.NotificationMobile>
                <S.Row>
                  <S.NotificationDate>{getDateTimeFormat(n.createdAt)}</S.NotificationDate>
                  <S.NotReadStatus isShow={!n.isViewed}>Новое</S.NotReadStatus>
                </S.Row>
                <S.Row>
                  <FiCheckCircle size={22} color="var(--success-color)" />
                  {!n.isViewed && (
                    <Button
                      icon={<FiMail size={24} />}
                      type="text"
                      size="small"
                      onClick={() => readNotificationHandler(n.id)}
                    ></Button>
                  )}
                </S.Row>
                <S.Row>
                  <S.TextWrapper>{parseNotification({ text: n.message, userRole: user?.role })}</S.TextWrapper>
                </S.Row>
              </S.NotificationMobile>
            </S.Mobile>
          </div>
        ))}
      </S.NotificationWrapper>

      <S.NotificationWrapper>
        {pagination && (
          <Pagination
            current={pagination?.currentPage}
            total={pagination?.total}
            pageSize={pagination?.pageSize}
            pageSizeOptions={pageSizeOptions}
            onShowSizeChange={(x, size) => setPageSize(size)}
            showSizeChanger
            onChange={setCurrentPage}
          />
        )}
      </S.NotificationWrapper>
    </S.Content>
  )
}
