import React, { FC, useState } from 'react'

import { Button } from 'antd'
import { observer } from 'mobx-react-lite'
import { FiMessageCircle } from 'react-icons/fi'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

import {
  CompanyOfferDataStatusEnum,
  GetOfferResponseStatusEnum,
  OfferDataStatusEnum,
  OfferDataTypeEnum,
  UpdateOfferBody,
} from '~/api/generated'
import { useGetProductTypeData } from '~/hooks'
import { appRoutes } from '~/router'
import { userUiSettingsStore } from '~/store/userUiSettingsStore'
import { appToast, httpErrorHandler } from '~/utils'
import { getOfferType } from '~/utils/offers/get-offer-type'
import { useGetCurrentRole } from '~hooks/auth'
import { useCreateDeal } from '~hooks/deals'
import { useGetModeratorOffer } from '~hooks/moderator'
import { useGetOfferById, useUpdateOfferPriceAndVolume } from '~hooks/offer'
import { ChangeOfferModal } from '~pages/OffersPage/components/ChangeOfferModal'
import { Comments } from '~pages/OffersPage/components/Comments'
import { CopyURLButton } from '~pages/OffersPage/components/CopyURLButton'
import { OfferActionMenu } from '~pages/OffersPage/components/OfferCard/components'
import { OfferDetailsSpin } from '~pages/OffersPage/components/OfferDetailsSpin'
import { SelectWarehouseAndCreateDealModal } from '~pages/OffersPage/components/SelectWarehouseAndCreateDealModal'
import { useAuth } from '~providers/auth'
import { ISelectedWarehouse } from '~shared/components/WarehouseSelect/WarehouseSelect'

import { OfferCardDetails } from '../../components/OfferCardDetails'

import * as S from '../../OffersPage.styled'

export const OffersDetails: FC = observer(() => {
  const { companyId } = useAuth()

  const navigate = useNavigate()
  const location = useLocation()
  const { id } = useParams<{ id: string | undefined }>()
  const [isVisibleComments, setVisibleComments] = useState(false)
  const createDeal = useCreateDeal(companyId)
  const { getProductTypeParametersForUI_V2 } = useGetProductTypeData()
  const [isChangeModalVisible, setChangeModalVisible] = useState(false)
  const [isSelectWarehouseModalVisible, setSelectWarehouseModalVisible] = useState(false)

  const updateOfferPriceAndVolume = useUpdateOfferPriceAndVolume()

  const {
    user: { isAdmin },
    company: { isBuyer, isSeller, isTrader },
  } = useGetCurrentRole()

  const {
    data: offer,
    isLoading: isFetchingOffer,
    refetch: refetchOffer,
  } = isAdmin ? useGetModeratorOffer(Number(id)) : useGetOfferById({ offerId: Number(id), userCompanyId: companyId })

  const handleMakeDeal = async (selectedWarehouse: ISelectedWarehouse) => {
    if (!offer?.id) return

    try {
      await createDeal.mutateAsync({
        offerId: offer?.id,
        warehouse: {
          warehouseId: !selectedWarehouse.isNew ? selectedWarehouse.warehouseId : null,
          address: selectedWarehouse.isNew ? selectedWarehouse.warehouseAddress : null,
        },
      })
      handleBack()
      appToast.success({ description: 'Мы получили ваш отклик. Менеджер свяжется с вами в ближайшее время.' })
    } catch (e) {
      httpErrorHandler(e, 'Ошибка при создании сделки')
    }
  }

  const handleChangePriceAndVolume = async (updateOfferBody: UpdateOfferBody) => {
    if (!offer?.id) return
    await updateOfferPriceAndVolume.mutateAsync({
      offerId: offer?.id,
      updateOfferBody,
    })
    await refetchOffer()
    setChangeModalVisible(false)
    handleBack()
  }

  if (isFetchingOffer) {
    return <OfferDetailsSpin />
  }

  const handleBack = () => {
    navigate({
      pathname: appRoutes.offersList.url,
      search: location.search,
    })
  }

  if (!offer) return null

  const isShowActionOfferButton =
    ((offer.type !== OfferDataTypeEnum.Buy ? isBuyer : isSeller) || isAdmin || isTrader) &&
    !offer.isOwn &&
    offer.status === GetOfferResponseStatusEnum.Published

  return (
    <>
      <S.DetailsWrapper>
        <OfferCardDetails
          title={offer.product.name}
          actions={
            isAdmin || offer.isOwn ? (
              <OfferActionMenu
                status={offer.status}
                offerId={offer.id}
                onSuccess={handleBack}
                onChangePriceAndVolumeClick={() => setChangeModalVisible(true)}
              />
            ) : undefined
          }
          statuses={[getOfferType(offer.type)]}
          parameters={getProductTypeParametersForUI_V2(offer.product)}
          laboratoryAnalysisSource={offer.laboratoryAnalysis?.source}
          volume={offer.volume}
          price={offer.price}
          includingNds={offer.includingNds}
          address={offer.address}
          distance={offer.distance}
          shippingRequired={offer.shippingRequired}
          contactPersonName={offer.contactPerson?.name}
          contactPersonPhone={offer.contactPerson?.phoneNumber}
          publishedAt={isAdmin ? offer.publishedAt : undefined}
          expiredAt={offer.status === CompanyOfferDataStatusEnum.Expired ? null : offer.expiredAt}
          note={offer.note}
          company={offer.company ?? undefined}
          type={offer.type}
          gost={offer.product.gost}
          isParametersCorrespondsToGost={offer.product.parametersIsCorrespondsToGost}
        />
        <S.Actions>
          {/*{isAdmin && (*/}
          {/*  <Button*/}
          {/*    onClick={() => setVisibleComments(true)}*/}
          {/*    style={{ marginRight: 'auto' }}*/}
          {/*    type="link"*/}
          {/*    icon={<FiMessageCircle />}*/}
          {/*  >*/}
          {/*    Комментарии*/}
          {/*  </Button>*/}
          {/*)}*/}
          {offer.status === OfferDataStatusEnum.Published && <CopyURLButton />}
          {isShowActionOfferButton && (
            <Button type="primary" onClick={() => setSelectWarehouseModalVisible(true)}>
              {offer.type === OfferDataTypeEnum.Sell ? 'Купить' : 'Продать'}
            </Button>
          )}
        </S.Actions>

        {/*<Comments offerId={offer.id} visible={isVisibleComments} onClose={() => setVisibleComments(false)} />*/}
      </S.DetailsWrapper>

      {isChangeModalVisible && (
        <ChangeOfferModal
          defaultValues={{
            price: isAdmin ? offer.priceDetail?.originalPrice : offer.price,
            volume: offer.volume,
          }}
          visible={isChangeModalVisible}
          onClose={() => setChangeModalVisible(false)}
          onSubmit={(formValues) => handleChangePriceAndVolume({ ...formValues, userCompanyId: companyId })}
        />
      )}
      <SelectWarehouseAndCreateDealModal
        visible={isSelectWarehouseModalVisible}
        companyId={companyId ?? NaN}
        productName={offer.product.name}
        volume={offer.volume}
        price={offer.price}
        includingNds={offer.includingNds}
        onCancel={() => setSelectWarehouseModalVisible(false)}
        onSubmit={handleMakeDeal}
      />
    </>
  )
})
