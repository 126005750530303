/* tslint:disable */
/* eslint-disable */
/**
 * API Зерно-Агрегатора
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { GetNotificationsResponse } from '../models';
// @ts-ignore
import { NotificationData } from '../models';
// @ts-ignore
import { UpdateNotificationStatusBody } from '../models';
/**
 * NotificationApi - axios parameter creator
 * @export
 */
export const NotificationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Список уведомлений для текущего пользователя
         * @param {number} [currentPage] Номер текущей страницы
         * @param {number} [pageSize] Количество элементов на странице
         * @param {boolean} [isViewed] Фильтр по статусу уведомления (просмотрен/не просмотрен)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notificationControllerGetNotifications: async (currentPage?: number, pageSize?: number, isViewed?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/notifications`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (currentPage !== undefined) {
                localVarQueryParameter['current-page'] = currentPage;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page-size'] = pageSize;
            }

            if (isViewed !== undefined) {
                localVarQueryParameter['isViewed'] = isViewed;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Пометить все уведомления текущего пользователя прочитанными
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notificationControllerMarkAllNotificationsViewed: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/notifications/mark-all-viewed`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Изменить статус уведомления (прочитанное/не прочитанное)
         * @param {number} notificationId 
         * @param {UpdateNotificationStatusBody} updateNotificationStatusBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notificationControllerMarkNotificationViewed: async (notificationId: number, updateNotificationStatusBody: UpdateNotificationStatusBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'notificationId' is not null or undefined
            assertParamExists('notificationControllerMarkNotificationViewed', 'notificationId', notificationId)
            // verify required parameter 'updateNotificationStatusBody' is not null or undefined
            assertParamExists('notificationControllerMarkNotificationViewed', 'updateNotificationStatusBody', updateNotificationStatusBody)
            const localVarPath = `/notification/{notificationId}/status`
                .replace(`{${"notificationId"}}`, encodeURIComponent(String(notificationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateNotificationStatusBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NotificationApi - functional programming interface
 * @export
 */
export const NotificationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = NotificationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Список уведомлений для текущего пользователя
         * @param {number} [currentPage] Номер текущей страницы
         * @param {number} [pageSize] Количество элементов на странице
         * @param {boolean} [isViewed] Фильтр по статусу уведомления (просмотрен/не просмотрен)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async notificationControllerGetNotifications(currentPage?: number, pageSize?: number, isViewed?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetNotificationsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.notificationControllerGetNotifications(currentPage, pageSize, isViewed, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Пометить все уведомления текущего пользователя прочитанными
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async notificationControllerMarkAllNotificationsViewed(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.notificationControllerMarkAllNotificationsViewed(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Изменить статус уведомления (прочитанное/не прочитанное)
         * @param {number} notificationId 
         * @param {UpdateNotificationStatusBody} updateNotificationStatusBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async notificationControllerMarkNotificationViewed(notificationId: number, updateNotificationStatusBody: UpdateNotificationStatusBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NotificationData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.notificationControllerMarkNotificationViewed(notificationId, updateNotificationStatusBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * NotificationApi - factory interface
 * @export
 */
export const NotificationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = NotificationApiFp(configuration)
    return {
        /**
         * 
         * @summary Список уведомлений для текущего пользователя
         * @param {number} [currentPage] Номер текущей страницы
         * @param {number} [pageSize] Количество элементов на странице
         * @param {boolean} [isViewed] Фильтр по статусу уведомления (просмотрен/не просмотрен)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notificationControllerGetNotifications(currentPage?: number, pageSize?: number, isViewed?: boolean, options?: any): AxiosPromise<GetNotificationsResponse> {
            return localVarFp.notificationControllerGetNotifications(currentPage, pageSize, isViewed, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Пометить все уведомления текущего пользователя прочитанными
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notificationControllerMarkAllNotificationsViewed(options?: any): AxiosPromise<void> {
            return localVarFp.notificationControllerMarkAllNotificationsViewed(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Изменить статус уведомления (прочитанное/не прочитанное)
         * @param {number} notificationId 
         * @param {UpdateNotificationStatusBody} updateNotificationStatusBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notificationControllerMarkNotificationViewed(notificationId: number, updateNotificationStatusBody: UpdateNotificationStatusBody, options?: any): AxiosPromise<NotificationData> {
            return localVarFp.notificationControllerMarkNotificationViewed(notificationId, updateNotificationStatusBody, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for notificationControllerGetNotifications operation in NotificationApi.
 * @export
 * @interface NotificationApiNotificationControllerGetNotificationsRequest
 */
export interface NotificationApiNotificationControllerGetNotificationsRequest {
    /**
     * Номер текущей страницы
     * @type {number}
     * @memberof NotificationApiNotificationControllerGetNotifications
     */
    readonly currentPage?: number

    /**
     * Количество элементов на странице
     * @type {number}
     * @memberof NotificationApiNotificationControllerGetNotifications
     */
    readonly pageSize?: number

    /**
     * Фильтр по статусу уведомления (просмотрен/не просмотрен)
     * @type {boolean}
     * @memberof NotificationApiNotificationControllerGetNotifications
     */
    readonly isViewed?: boolean
}

/**
 * Request parameters for notificationControllerMarkNotificationViewed operation in NotificationApi.
 * @export
 * @interface NotificationApiNotificationControllerMarkNotificationViewedRequest
 */
export interface NotificationApiNotificationControllerMarkNotificationViewedRequest {
    /**
     * 
     * @type {number}
     * @memberof NotificationApiNotificationControllerMarkNotificationViewed
     */
    readonly notificationId: number

    /**
     * 
     * @type {UpdateNotificationStatusBody}
     * @memberof NotificationApiNotificationControllerMarkNotificationViewed
     */
    readonly updateNotificationStatusBody: UpdateNotificationStatusBody
}

/**
 * NotificationApi - object-oriented interface
 * @export
 * @class NotificationApi
 * @extends {BaseAPI}
 */
export class NotificationApi extends BaseAPI {
    /**
     * 
     * @summary Список уведомлений для текущего пользователя
     * @param {NotificationApiNotificationControllerGetNotificationsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationApi
     */
    public notificationControllerGetNotifications(requestParameters: NotificationApiNotificationControllerGetNotificationsRequest = {}, options?: AxiosRequestConfig) {
        return NotificationApiFp(this.configuration).notificationControllerGetNotifications(requestParameters.currentPage, requestParameters.pageSize, requestParameters.isViewed, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Пометить все уведомления текущего пользователя прочитанными
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationApi
     */
    public notificationControllerMarkAllNotificationsViewed(options?: AxiosRequestConfig) {
        return NotificationApiFp(this.configuration).notificationControllerMarkAllNotificationsViewed(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Изменить статус уведомления (прочитанное/не прочитанное)
     * @param {NotificationApiNotificationControllerMarkNotificationViewedRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationApi
     */
    public notificationControllerMarkNotificationViewed(requestParameters: NotificationApiNotificationControllerMarkNotificationViewedRequest, options?: AxiosRequestConfig) {
        return NotificationApiFp(this.configuration).notificationControllerMarkNotificationViewed(requestParameters.notificationId, requestParameters.updateNotificationStatusBody, options).then((request) => request(this.axios, this.basePath));
    }
}
