export type Shadows = { [key: string]: string }

export const shadows = {
  light: {
    primary: '0 4px 4px rgba(0, 0, 0, 0.25)',
    secondary: '',
    inputShadow: '0 1px 2px 1px rgba(50, 50, 71, 0.15)',
    dropdownShadow: '1px 1px 3px rgba(12, 26, 75, 0.11), 5px 10px 16px rgba(20, 37, 63, 0.1)',
    shadowInner: 'inset 0 4px 8px rgba(0, 0, 0, 0.2)',
    paperShadow: '0 1px 2px rgba(84, 84, 84, 0.1), 0 0 1px rgba(74, 74, 74, 0.1)',
    tableTrShadow: '0px 1px 2px #D6D6D6, 0px 4px 12px #E3E3E3',
  },
  dark: {
    primary: '',
  },
}
