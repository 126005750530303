import React, { FC, useState } from 'react'

import { Badge, Button, Empty, Flex, Modal, Popconfirm, Table } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import { FiDownload, FiEye, FiPlus, FiTrash2 } from 'react-icons/fi'

import { Text } from '~/ui-components'
import { appToast, modalSharedSettings } from '~/utils'
import { DocumentViewerModal } from '~shared/components/DocumentViewer'

import { LoadDocumentsProps, Row } from './LoadDocuments.types'

import * as S from './LoadDocuments.styled'
import { FreightOrderDocumentEntity, FreightOrderDocumentType } from '~api/gql-generated/graphql'
import { LoadAddDocumentForm } from '~pages/LogisticsPage/modules/LoadCard/components/LoadDocuments/LoadAddDocumentForm/LoadAddDocumentForm'
import { graphqlErrorHandler } from '~/utils/graphqlErrorHandler'
import { LoadAddDocumentFormValues } from '~pages/LogisticsPage/modules/LoadCard/components/LoadDocuments/LoadAddDocumentForm/LoadAddDocumentForm.types'
import { useUploadFileAndGetId } from '~hooks/_utils'
import { useMutation, useQuery } from '@apollo/client'
import {
  freightOrderDocumentAddFilesMutation,
  freightOrderDocumentCreateMutation,
  freightOrderDocumentDeleteMutation,
} from '~api/gql-mutations/freight-order-document.mutation.graphql'
import {
  freightOrderDocumentsArchiveQuery,
  getFreightOrderDocumentsQuery,
} from '~api/gql-query/freight-order-documents.query.graphql'
import { getLogisticOrderDocumentName } from '~/utils/logistics/get-logistic-order-document-name'
import { getDateFormat } from '~/utils/getDateFormat'

const docList: FreightOrderDocumentType[] = [FreightOrderDocumentType.CarriageContractTemplate]

export const LoadDocuments: FC<LoadDocumentsProps> = ({
  title,
  freightOrderId,
  isAddDocuments = true,
  isDeleteDocuments = true,
}) => {
  const [showedDocument, setShowedDocument] = useState<{
    document: FreightOrderDocumentEntity
    instanceId: number
  } | null>(null)
  const [isVisibleAddDocumentModal, setVisibleAddDocumentModal] = useState(false)
  const [addDocumentType, setAddDocumentType] = useState<FreightOrderDocumentType | null>(null)
  const [addDocumentInstanceId, setAddDocumentInstanceId] = useState<number | null>(null)

  const getFreightOrderDocuments = useQuery(getFreightOrderDocumentsQuery, {
    variables: {
      freightOrderId,
      filter: { types: docList },
    },
    fetchPolicy: 'cache-and-network',
  })

  const freightOrderDocumentsArchive = useQuery(freightOrderDocumentsArchiveQuery, {
    onError: graphqlErrorHandler,
    fetchPolicy: 'cache-and-network',
    skip: true,
  })

  const freightOrderDocuments: FreightOrderDocumentEntity[] = getFreightOrderDocuments.data?.freightOrderDocuments ?? []

  const uploadFileAndGetId = useUploadFileAndGetId()
  const [createDocument, createDocumentState] = useMutation(freightOrderDocumentCreateMutation)
  const [deleteDocument] = useMutation(freightOrderDocumentDeleteMutation, {
    refetchQueries: [getFreightOrderDocumentsQuery],
  })
  const [addFileToDocument] = useMutation(freightOrderDocumentAddFilesMutation)

  const handleDocumentViewerShow = (document: FreightOrderDocumentEntity, instanceId: number) => {
    setShowedDocument({
      document,
      instanceId,
    })
  }

  const handleDocumentViewerClose = () => {
    setShowedDocument(null)
  }

  const handleAddDocumentClick = () => {
    setAddDocumentType(null)
    setAddDocumentInstanceId(null)
    setVisibleAddDocumentModal(true)
  }

  const handleAddFile = (type: FreightOrderDocumentType, documentInstanceId: number) => {
    setAddDocumentType(type)
    setAddDocumentInstanceId(documentInstanceId)
  }

  const handleModalCancelClick = () => {
    setVisibleAddDocumentModal(false)
    setAddDocumentType(null)
    setAddDocumentInstanceId(null)
  }

  const handleUploadDocument = async ({ documentFiles }: LoadAddDocumentFormValues) => {
    const fileKeys = await Promise.all(
      documentFiles.map((documentFile) => uploadFileAndGetId.mutateAsync(documentFile))
    )

    try {
      await createDocument({
        variables: {
          input: {
            freightOrderId,
            type: FreightOrderDocumentType.CarriageContractTemplate,
            fileKeys,
          },
        },
      })

      setVisibleAddDocumentModal(false)
      await getFreightOrderDocuments.refetch()
      appToast.success({
        description: 'Документ добавлен',
      })
    } catch (e) {
      graphqlErrorHandler(e, 'Ошибка при добавлении документа')
    }
  }

  const handleAddFileToDocument = async ({ documentFiles }: LoadAddDocumentFormValues) => {
    if (!addDocumentInstanceId) {
      appToast.error({ description: '[handleAddFileToDocument]: addDocumentInstanceId doesnt exist' })
      return
    }
    const fileKeys = await Promise.all(
      documentFiles.map((documentFile) => uploadFileAndGetId.mutateAsync(documentFile))
    )

    try {
      await addFileToDocument({
        variables: {
          documentId: addDocumentInstanceId,
          input: {
            fileKeys,
          },
        },
      })

      setVisibleAddDocumentModal(false)
      setAddDocumentInstanceId(null)
      await getFreightOrderDocuments.refetch()
      appToast.success({
        description: 'Документ добавлен',
      })
    } catch (e) {
      graphqlErrorHandler(e, 'Ошибка при добавлении документа')
    }
  }

  const downloadDocsArchive = async () => {
    if (!freightOrderDocuments?.length) {
      return
    }

    const archive = await freightOrderDocumentsArchive.refetch({
      freightOrderId,
      filter: {
        documentIds: freightOrderDocuments.flatMap((x) => x.instances.flatMap((z) => z.id)),
      },
    })
    window.open(archive.data.freightOrderDocumentsArchive.source ?? '', '_blanc')
  }

  const downloadFileArchive = async (instanceId: number) => {
    const archive = await freightOrderDocumentsArchive.refetch({
      freightOrderId,
      filter: {
        documentIds: [instanceId],
      },
    })
    window.open(archive.data.freightOrderDocumentsArchive.source ?? '', '_blanc')
  }

  const handleRemoveDocument = async (documentId: number) => {
    try {
      await deleteDocument({
        variables: {
          documentId,
        },
      })

      await getFreightOrderDocuments.refetch()
      appToast.success({
        description: 'Документ удален',
      })
    } catch (e) {
      graphqlErrorHandler(e, 'Ошибка при удалении документа')
    }
  }

  const rows: Row[] = freightOrderDocuments
    .map((doc) =>
      doc.instances.map((instance) => ({
        ...instance,
        document: doc,
      }))
    )
    .flat()

  const columns: ColumnsType<Row> = [
    {
      title: 'Название документа',
      dataIndex: ['document', 'type'],
      className: 'bold-cell',
      width: '60%',
      render: (value, row) => `${getLogisticOrderDocumentName(value)} ${row.index ?? ''}`,
    },
    {
      title: 'Дата погрузки',
      dataIndex: 'date',
      render: (_, row) => getDateFormat(row.createdAt),
    },
    {
      title: 'Действия',
      align: 'right',
      render: (_, row) => {
        return (
          <S.TableActions>
            <Badge count={row.files.length === 1 ? 0 : row.files.length} showZero={false}>
              <Button
                type="text"
                icon={<FiEye size={24} />}
                onClick={() => handleDocumentViewerShow(row.document, row.id)}
              />
            </Badge>
            <Button type="text" icon={<FiDownload size={24} />} onClick={() => downloadFileArchive(row.id)} />
            {isDeleteDocuments && (
              <Popconfirm
                placement="right"
                title="Удалить файл?"
                onConfirm={() => handleRemoveDocument(row.id)}
                okText="Да"
                cancelText="Нет"
              >
                <Button type="text" icon={<FiTrash2 size={24} />} />
              </Popconfirm>
            )}
            {isAddDocuments && (
              <Button
                type="text"
                icon={<FiPlus size={24} />}
                onClick={() => handleAddFile(FreightOrderDocumentType.CarriageContractTemplate, row.id)}
              />
            )}
          </S.TableActions>
        )
      },
    },
  ]

  return (
    <>
      <Flex justify="space-between">
        <Text variant="h2">{title}</Text>
        <Button icon={<FiDownload />} type="primary" disabled={!rows.length} onClick={downloadDocsArchive}>
          Скачать все из раздела
        </Button>
      </Flex>
      <Flex vertical gap={16}>
        {rows?.length ? (
          <Table
            rowKey="id"
            loading={getFreightOrderDocuments.loading}
            columns={columns}
            dataSource={rows}
            scroll={{ x: 'min-content' }}
            pagination={false}
          />
        ) : (
          <Empty description="Документы не загружены" />
        )}
        {isAddDocuments && (
          <Button type="dashed" icon={<FiPlus size={16} />} onClick={handleAddDocumentClick}>
            Добавить документ
          </Button>
        )}
      </Flex>

      <Modal
        title="Выбор документа"
        open={isVisibleAddDocumentModal || !!addDocumentInstanceId}
        {...modalSharedSettings}
        width={400}
        onCancel={handleModalCancelClick}
        footer={[
          <Button key="cancel" onClick={handleModalCancelClick} htmlType="button">
            Отмена
          </Button>,
          <Button
            key="submit"
            form="load-add-document-form"
            type="primary"
            htmlType="submit"
            loading={createDocumentState.loading}
          >
            Готово
          </Button>,
        ]}
      >
        {addDocumentType ? (
          <LoadAddDocumentForm defaultDocumentsType={addDocumentType} onSubmit={handleAddFileToDocument} />
        ) : (
          <LoadAddDocumentForm
            onSubmit={handleUploadDocument}
            documentsTypes={isVisibleAddDocumentModal ? docList : undefined}
          />
        )}
      </Modal>

      <DocumentViewerModal
        isOpen={!!showedDocument?.document}
        onCancel={handleDocumentViewerClose}
        docInstances={
          showedDocument?.document.instances.map((instance) => ({
            ...instance,
            documentInfo: {
              ...instance,
              documentNumber: instance.number ?? null,
              formationDate: instance.formedAt,
              rejectionNote: instance.rejectionNote ?? null,
            },
          })) ?? []
        }
        startCheckFromDocInstanceId={showedDocument?.instanceId ?? 0}
      />
    </>
  )
}
