import { OwnershipType } from '~api/gql-generated/graphql'
import { codeUnreachable } from '~/utils/code-unreachable'

export const getOwnershipTypeLabel = (ownershipType: OwnershipType) => {
  switch (ownershipType) {
    case OwnershipType.Own:
      return 'ТС в собственности'
    case OwnershipType.Rent:
      return 'ТС в аренде'
    case OwnershipType.Leasing:
      return 'ТС в лизинге'
    default:
      return codeUnreachable(ownershipType)
  }
}
