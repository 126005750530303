import React, { useState } from 'react'

import { Button, Skeleton, Space } from 'antd'
import { observer } from 'mobx-react-lite'
import { FiArrowRight, FiPlusCircle } from 'react-icons/fi'

import { useGetProductTypeData } from '~/hooks'
import { Card, Text } from '~/ui-components'
import { getDateFormat } from '~/utils/getDateFormat'
import { ElevatorProductTypeData } from '~api/generated'
import { useGetCurrentRole } from '~hooks/auth'
import { useGetDevice } from '~hooks/common'
import { useGetElevatorAdverts } from '~hooks/elevator-advert'
import { CreateAdvertOfferModal } from '~pages/BasisPage/components/CreateAdvertOfferModal'
import { BasisAdvertsByProductTypeTable } from '~pages/BasisPage/modules/BasisAdvert/BasisAdvertsByProductType/BasisAdvertsByProductTypeTable'
import { useAuth } from '~providers/auth'

import * as S from './BasisAdvertsByProductType.styled'

interface BasisAdvertsByProductTypeProps {
  elevatorId: number
  companyId?: number
  productTypeData: ElevatorProductTypeData
  onClick: (advertId: number) => void
}

export const BasisAdvertsByProductType: React.FC<BasisAdvertsByProductTypeProps> = observer(
  ({ elevatorId, companyId, productTypeData, onClick }) => {
    const device = useGetDevice()
    const isMobile = device === 'mobile'

    const { companyId: userCompanyId } = useAuth()

    const {
      company: { isSeller, isTrader },
    } = useGetCurrentRole()
    const [isCreateAdvertModalOpenForAdvertId, setIsCreateAdvertModalOpenForAdvertId] = useState<number | null>(null)
    const { getProductTypeParametersForUI_V2 } = useGetProductTypeData()
    const [isExpanded, setExpanded] = useState(true)
    const getElevatorAdvertsFn = useGetElevatorAdverts({
      elevatorId,
      userCompanyId,
      productTypes: [productTypeData.type],
      companyId,
      pageSize: 99,
    })

    const adverts = getElevatorAdvertsFn.data?.elevatorAdverts
    const pagination = getElevatorAdvertsFn.data?.pagination
    const maxAdvertsShown = 2
    const advertsListShort = !adverts
      ? []
      : adverts.length > maxAdvertsShown
      ? adverts.slice(0, maxAdvertsShown)
      : adverts
    const advertsList = isExpanded ? advertsListShort : adverts

    const onClickHandler = (advertId: number) => onClick(advertId)
    const makeOfferHandler = (advertId: number, e?: React.MouseEvent) => {
      e?.stopPropagation()
      setIsCreateAdvertModalOpenForAdvertId(advertId)
    }

    const modal = (
      <CreateAdvertOfferModal
        advertId={isCreateAdvertModalOpenForAdvertId!}
        userCompanyId={userCompanyId}
        isOpen={!!isCreateAdvertModalOpenForAdvertId}
        onCancel={() => setIsCreateAdvertModalOpenForAdvertId(null)}
      />
    )

    if (!isMobile) {
      return (
        <>
          <BasisAdvertsByProductTypeTable
            productTypeData={productTypeData}
            adverts={adverts}
            isLoading={getElevatorAdvertsFn.isLoading}
            onActionArrowClick={(advert) => onClickHandler(advert.id)}
            onMakeOffer={(advert) => makeOfferHandler(advert.id)}
          />

          {modal}
        </>
      )
    }

    return (
      <div>
        <S.TitleWrapper>
          <S.TitleWrapperInner>
            <Text variant="h4">{productTypeData.name}</Text>
            <S.CountWrapper>{adverts?.length}</S.CountWrapper>
          </S.TitleWrapperInner>
          <Button hidden={(adverts?.length ?? 0) <= maxAdvertsShown} type="link" onClick={() => setExpanded((x) => !x)}>
            {isExpanded ? 'Показать все' : 'Скрыть'}
          </Button>
        </S.TitleWrapper>

        <S.LIstWrapper>
          <Skeleton loading={getElevatorAdvertsFn.isLoading} active />

          {advertsList?.map((advert) => (
            <Card key={advert.id} onClick={() => onClickHandler(advert.id)}>
              <Card.Header>
                <S.CardHeaderTop>
                  <div>
                    <Card.Title>
                      <Space>
                        {advert.pricePerTon} ₽/т{' '}
                        {advert.priceUpdatedAt && (
                          <S.PublishedAt>обн. {getDateFormat(advert.priceUpdatedAt)}</S.PublishedAt>
                        )}
                      </Space>
                    </Card.Title>
                    <Text variant="normal">{advert.usingNds ? 'с НДС' : 'без НДС'}</Text>
                  </div>
                  <FiArrowRight size={24} />
                </S.CardHeaderTop>
              </Card.Header>
              <Card.Content>
                <div>
                  {!!advert.product.parameters.length && (
                    <S.Parameters>
                      {getProductTypeParametersForUI_V2(advert.product).map((param) => (
                        <Text variant="description" key={param}>
                          {param}
                        </Text>
                      ))}
                    </S.Parameters>
                  )}
                </div>
                <S.CardFooter>
                  {(isSeller || isTrader) && (
                    <Button
                      type="link"
                      icon={<FiPlusCircle size={16} />}
                      onClick={(e) => makeOfferHandler(advert.id, e)}
                    >
                      Предложить объем
                    </Button>
                  )}

                  <div></div>
                  <Text variant="normal">{advert.company?.name}</Text>
                </S.CardFooter>
              </Card.Content>
            </Card>
          ))}
        </S.LIstWrapper>

        {modal}
      </div>
    )
  }
)
