import React, { FC } from 'react'

import { Drawer as DrawerCustom, DrawerProps } from 'antd'
import { FiX } from 'react-icons/fi'

import './style.scss'

export const Drawer: FC<DrawerProps> = (props) => {
  return <DrawerCustom {...props} closeIcon={<FiX color="black" size={20} />} />
}
