import styled from 'styled-components'

import css from '@styled-system/css'

export const ActionWrapper = styled.div(
  css({
    display: 'flex',
    justifyContent: 'end',
  })
)
export const FormGridHalf = styled.div(
  css({
    display: 'grid',
    gridTemplateColumns: [
      'repeat(1, minmax(280px, 100%))',
      'repeat(1, minmax(320px, 100%))',
      'repeat(1, minmax(500px, 100%))',
      'repeat(2, minmax(250px, 100%))',
    ],
    columnGap: '40px',
    alignItems: 'end',
  })
)
