import styled from 'styled-components'

import css from '@styled-system/css'

export const Form = styled('form')(
  css({
    display: 'flex',
    flexDirection: 'column',
    width: ['100%', null, '500px'],
  })
)

export const Address = styled.div(
  css({
    display: 'flex',
    flexDirection: 'column',
    gap: 4,
  })
)

export const InputWrapper = styled('div')(
  css({
    width: '250px',
  })
)

export const Price = styled.div(
  css({
    display: 'flex',
    gap: [0, null, 16],
    flexDirection: ['column', 'column', 'unset'],
  })
)

export const NdsWrapper = styled('div')(
  css({
    mt: [0, 0, 14],
    marginBottom: [14, 14, 2],
  })
)
