import React, { PropsWithChildren, useEffect, useState } from 'react'

import { Modal, Spin } from 'antd'
import globalAxios from 'axios'
import { observer } from 'mobx-react-lite'
import { useNavigate } from 'react-router-dom'

import { useMutation, useQuery } from '@apollo/client'
import { ApolloError } from '@apollo/client/errors'
import * as Sentry from '@sentry/react'

import { AppConfig } from '~/appConfig'
import { appRoutes } from '~/router'
import { initialFilterStore } from '~/store/filters'
import { userUiSettingsStore } from '~/store/userUiSettingsStore'
import { NoAccessCompanyModalErrorHandler } from '~/utils/no-access-company-modal-error-handler'
import { UserRole } from '~api/gql-generated/graphql'
import { setActiveCompanyMutation } from '~api/gql-mutations/auth.mutation.graphql'
import { getMeCompaniesQuery, getMeQuery } from '~api/gql-query/me.query.graphql'
import { useLoginMutation, useLogoutMutation, useSendSecretCodeMutation } from '~hooks/auth'

import { IAuthContext } from './types'

export function initReactQueryAuth() {
  const AuthContext = React.createContext<IAuthContext | null>(null)
  AuthContext.displayName = 'ZernoAuthContext'

  const AuthProvider: React.FC<PropsWithChildren> = observer(({ children }) => {
    const navigate = useNavigate()
    const logoutMutation = useLogoutMutation()
    const sendSecretCodeMutation = useSendSecretCodeMutation()
    const loginMutation = useLoginMutation()
    const [setActiveCompany, setActiveCompanyState] = useMutation(setActiveCompanyMutation)

    const [isAuthenticated, setAuthenticated] = useState(true)

    useEffect(() => {
      if (!isAuthenticated) {
        userUiSettingsStore.resetToDefaultState()
      }
    }, [isAuthenticated])

    const userHasNoAccessToCompanyErrorHandler = async (e: ApolloError) => {
      if (e?.message === 'User has no access to company') {
        await setActiveCompany({
          variables: {
            input: { companyId: undefined },
          },
        })
      }
    }

    const userDataGraphQl = useQuery(getMeQuery, {
      skip: !isAuthenticated,
      onError: userHasNoAccessToCompanyErrorHandler,
    })
    const getCompanies = useQuery(getMeCompaniesQuery, {
      skip: !isAuthenticated,
      onError: userHasNoAccessToCompanyErrorHandler,
    })

    const selectedCompany = getCompanies.data?.me?.companies?.find(
      (x) => x.id === userDataGraphQl.data?.me.activeCompanyId
    )

    const refetchUserDataHandler = () => {
      void userDataGraphQl.refetch()
    }

    useEffect(() => {
      globalAxios.interceptors.response.use(
        (response) => response,
        async (error) => {
          const {
            request: { responseURL },
            response: { status },
          } = error

          Sentry.captureException(error, {
            user: {
              id: userDataGraphQl?.data?.me?.id.toString(),
            },
          })

          if (error.response.data.code === 'UserHasNoAccessToCompanyException') {
            NoAccessCompanyModalErrorHandler()
          }

          const isMethodForGettingAuth = responseURL === `${AppConfig.API_URL}/me`

          if (!isMethodForGettingAuth && status === 401 && window.location.pathname !== appRoutes.auth.url) {
            navigate(appRoutes.auth.url)
          }

          return Promise.reject(error)
        }
      )
    }, [])

    const authContext: IAuthContext = {
      authenticated: !!userDataGraphQl.data?.me && isAuthenticated,
      user: userDataGraphQl?.data?.me ?? null,
      userId: userDataGraphQl?.data?.me?.id ?? null,
      isDemoUser: userDataGraphQl.data?.me.role === UserRole.DemoUser,
      phoneNumber: userDataGraphQl?.data?.me.phoneNumber || null,
      companyId: selectedCompany?.id ?? 0,
      companyRole: selectedCompany?.type ?? null,
      sendSms: sendSecretCodeMutation.mutateAsync,
      login: (credentials) => {
        return loginMutation.mutateAsync(credentials, { onSuccess: () => setAuthenticated(true) })
      },
      logout,
      refetchUser: refetchUserDataHandler,
      // временно для обратной совместимости с graphql
      setAuthenticated: (isAuth: boolean) => {
        setAuthenticated(isAuth)
      },
    }

    async function logout() {
      setAuthenticated(false)
      await logoutMutation.mutateAsync()
      initialFilterStore.setFilters({ regionIds: [], operationType: 'BUY', productTypes: [] })
      await navigate(appRoutes.auth.url)
    }

    if (userDataGraphQl.loading || getCompanies.loading) {
      return (
        <div style={{ width: '100%', height: '100%' }}>
          <Spin spinning={true} size="large" style={{ position: 'relative', top: '50%', left: '50%' }} />
        </div>
      )
    }

    return <AuthContext.Provider value={authContext}>{children}</AuthContext.Provider>
  })

  function useAuth() {
    const context = React.useContext(AuthContext)
    if (!context) {
      throw new Error('useAuth must be used within an AuthProvider')
    }

    return context
  }

  return { AuthProvider, AuthConsumer: AuthContext.Consumer, useAuth }
}
