import { useEffect, useState } from 'react'

import throttle from 'lodash/throttle'

export const useGetDevice = () => {
  const [device, setDevice] = useState<'desktop' | 'tablet' | 'tablet-m' | 'mobile' | null>(null)

  useEffect(() => {
    const handleWindowResize = throttle(() => {
      if (window.innerWidth < 768) {
        setDevice('mobile')
      } else if (window.innerWidth < 1024) {
        setDevice('tablet')
      } else if (window.innerWidth < 1360) {
        setDevice('tablet-m')
      } else {
        setDevice('desktop')
      }
    }, 300)

    handleWindowResize()

    window.addEventListener('resize', handleWindowResize)
    return () => window.removeEventListener('resize', handleWindowResize)
  }, [])

  return device
}
