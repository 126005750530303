import React from 'react'
import { Text } from '~/ui-components'
import { Flex } from 'antd'
import { TransportDocumentsFieldsProps } from '~pages/CarParkPage/modules/Transport/Transport.types'
import { getTransportDocumentName } from '~/utils/car-park/get-transport-document-name'
import { Upload } from '~ui-components/components/Upload'

export const DocumentsFields: React.FC<TransportDocumentsFieldsProps> = (transportDocumentsProps) => (
  <>
    <Text variant="h1">Документы</Text>

    <Flex vertical gap={8}>
      <Text variant="label-bold">Документы для загрузки</Text>
      <Text variant="label">
        Загрузите документы, чтобы машину можно было назначить для перевозки. Форматы для загрузки: jpg, jpeg, pdf, png.
      </Text>
      <Text variant="label">Не более 100 мб один файл.</Text>
    </Flex>

    <Flex vertical gap={32}>
      {Object.values(transportDocumentsProps).map((transportDocument) => (
        <Flex key={transportDocument.type} vertical gap={24}>
          <Flex gap={8} align="center">
            <Text variant="form-read-value">{getTransportDocumentName(transportDocument.type)}</Text>
            <Text variant="form-read-label">Фото или скан с двух сторон</Text>
          </Flex>

          <Flex>
            <Upload uploadProps={transportDocument.uploadProps} />
          </Flex>
        </Flex>
      ))}
    </Flex>
  </>
)
