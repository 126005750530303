import React from 'react'

import { Button } from 'antd'
import { Controller, useForm } from 'react-hook-form'

import { yupResolver } from '@hookform/resolvers/yup'

import { FormField } from '~/ui-components'
import { CompanyRoleControl } from '~shared/controls'

import { accountTypeFormSchema } from './AccountTypeForm.validation'

import { IAccountTypeForm, IAccountTypeFormProps } from './AccountTypeForm.types'

import { FormItem } from '../../../../shared.styled'
import * as S from './AccountTypeForm.styled'

export const AccountTypeForm: React.FC<IAccountTypeFormProps> = ({ onSubmit, defaultValues, isLoading, isAdmin }) => {
  const {
    control,
    handleSubmit,
    formState: { errors, isDirty },
  } = useForm<IAccountTypeForm>({
    defaultValues: {
      companyRoleType: defaultValues?.companyRoleType ?? undefined,
    },
    resolver: yupResolver(accountTypeFormSchema),
  })

  const onSubmitHandler = (data: IAccountTypeForm) => onSubmit(data)

  return (
    <S.Form onSubmit={handleSubmit(onSubmitHandler)}>
      <S.ContentWrapper>
        <FormItem>
          <FormField validateMessage={errors.companyRoleType?.message}>
            <Controller
              name="companyRoleType"
              control={control}
              render={({ field }) => (
                <CompanyRoleControl value={field.value} onChange={field.onChange} isAdmin={isAdmin} />
              )}
            />
          </FormField>
        </FormItem>

        <S.ActionWrapper>
          <Button htmlType="submit" type="primary" disabled={!isDirty || isLoading}>
            {isLoading ? 'Сохранение' : 'Сохранить'}
          </Button>
        </S.ActionWrapper>
      </S.ContentWrapper>
    </S.Form>
  )
}
