import { isEqual } from 'lodash'
import { makeAutoObservable } from 'mobx'

import { AppConfig } from '~/appConfig'
import { getActiveFilters } from '~/store/filters/_utils'
import { AuctionApiAuctionControllerGetAuctionsRequest } from '~api/generated'
import { IAuctionsFilterForm } from '~pages/Auctions/components/AuctionsFilter/components/AuctionsFilterForm'

// Filter panel shows this values after first open
const defaultFiltersConfig: IAuctionsFilterForm = {
  auctionType: 'ALL',
  volume: {
    from: AppConfig.FILTERS.volume.from,
    to: AppConfig.FILTERS.volume.to,
  },
  price: {
    from: AppConfig.FILTERS.price.from,
    to: AppConfig.FILTERS.price.to,
  },
  regionIds: [],
}

export class AuctionsFilterStore {
  private filters: IAuctionsFilterForm

  constructor() {
    this.filters = defaultFiltersConfig
    makeAutoObservable(this)
  }

  setFilters(newFilters: IAuctionsFilterForm): void {
    this.filters = newFilters
  }

  get auctionFilters(): IAuctionsFilterForm {
    return this.filters
  }

  get defaultFiltersConfig(): IAuctionsFilterForm {
    return defaultFiltersConfig
  }

  get isCurrentFilterDefault(): boolean {
    return isEqual(this.filters, defaultFiltersConfig)
  }

  get mappedCurrentFiltersToResponse(): Partial<AuctionApiAuctionControllerGetAuctionsRequest> {
    return this.isCurrentFilterDefault
      ? {}
      : {
          minVolume: this.filters.volume.from,
          maxVolume: this.filters.volume.to,
          minPrice: this.filters.price.from,
          maxPrice: this.filters.price.to,
          type: this.filters.auctionType === 'ALL' ? undefined : this.filters.auctionType,
          regionIds: this.filters.regionIds,
        }
  }

  get activeFilters(): string[] {
    return getActiveFilters(this.filters, defaultFiltersConfig)
  }

  get hasActiveFilters(): boolean {
    return this.activeFilters.length > 0
  }

  get activeFiltersCount(): number {
    return this.activeFilters.length
  }
}

export const auctionsFilterStore = new AuctionsFilterStore()
