import { useMutation, UseMutationOptions } from '@tanstack/react-query'

import { GetAuthUserResponse } from '~/api/generated'
import { useApi } from '~providers/api'
import { LoginCredentials } from '~providers/auth/types'

export const useLoginMutation = (
  options?: Omit<UseMutationOptions<GetAuthUserResponse, Error, LoginCredentials>, 'mutationKey' | 'mutationFn'>
) => {
  const api = useApi()
  const fn = async ({ phoneNumber, secretCode }: LoginCredentials) => {
    const res = await api.auth.authControllerLogin({
      userAgent: navigator.userAgent,
      loginDto: {
        phoneNumber,
        smsCode: secretCode,
      },
    })

    return res.data
  }

  return useMutation(['login'], fn, options)
}
