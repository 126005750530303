import React, { PropsWithChildren } from 'react'

import { Select } from 'antd'
import { BaseSelectRef } from 'rc-select'

import { getUnloadType } from '~/utils/logistics/get-unload-type'
import { UnloadingTransportType } from '~api/generated'
import { CustomControlInterface } from '~shared/controls/CustomControl.interface'

type Value = UnloadingTransportType[] | undefined

interface IUnloadTypeControlProps extends CustomControlInterface {
  onChange: (value: Value) => void
  value: Value
  hasError?: boolean
  disabled?: boolean
  id?: string
}

interface IOption {
  value: UnloadingTransportType
  label: string
}

const unloadTypesOptions: IOption[] = [
  {
    label: getUnloadType(UnloadingTransportType.TailgateAutoUnloader),
    value: UnloadingTransportType.TailgateAutoUnloader,
  },
  {
    label: getUnloadType(UnloadingTransportType.SideBoardAutoUnloader),
    value: UnloadingTransportType.SideBoardAutoUnloader,
  },
  {
    label: getUnloadType(UnloadingTransportType.Manipulator),
    value: UnloadingTransportType.Manipulator,
  },
  {
    label: getUnloadType(UnloadingTransportType.DumpTruck),
    value: UnloadingTransportType.DumpTruck,
  },
  {
    label: getUnloadType(UnloadingTransportType.MechanicalShovel),
    value: UnloadingTransportType.MechanicalShovel,
  },
  {
    label: getUnloadType(UnloadingTransportType.PneumaticUnloader),
    value: UnloadingTransportType.PneumaticUnloader,
  },
  {
    label: getUnloadType(UnloadingTransportType.Custom),
    value: UnloadingTransportType.Custom,
  },
  {
    label: getUnloadType(UnloadingTransportType.ByContractorChoice),
    value: UnloadingTransportType.ByContractorChoice,
  },
]

export const UnloadTypeControl: React.FC<PropsWithChildren<IUnloadTypeControlProps>> = React.forwardRef<
  BaseSelectRef,
  PropsWithChildren<IUnloadTypeControlProps>
>(({ id, value, onChange, hasError, disabled }, ref) => {
  const onSelectAddressHandler = (v: Value): void => {
    onChange(v)
  }

  return (
    <Select
      ref={ref}
      id={id}
      mode="multiple"
      style={{ width: '100%', maxWidth: '342px' }}
      placeholder="Выберите способ погрузки"
      value={value}
      disabled={disabled}
      onChange={onSelectAddressHandler}
      status={hasError ? 'error' : undefined}
      optionFilterProp="children"
      allowClear
    >
      {unloadTypesOptions.map((it) => (
        <Select.Option key={it.value} value={it.value}>
          {it.label}
        </Select.Option>
      ))}
    </Select>
  )
})

UnloadTypeControl.displayName = 'UnloadTypeControl'
