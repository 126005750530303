import React from 'react'

import { useParams } from 'react-router'

import { DocumentsProfileTab } from '~pages/ProfilePage/components'

export const ModeratorCompanyDocuments: React.FC = () => {
  const { id: companyId } = useParams<{ id: string | undefined }>()

  return <DocumentsProfileTab companyId={Number(companyId)} />
}
