import styled from 'styled-components'

import { breakpoints } from '~/tokens'
import { Text } from '~/ui-components'

export const ContentWrapper = styled.div`
  display: grid;
  gap: 16px;
  //padding: 24px;

  @media (max-width: ${breakpoints.MOBILE_XL}px) {
    //padding: 16px;
  }
`

export const FormView = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`
export const FormView2Column = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
  @media (max-width: ${breakpoints.MOBILE_XL}px) {
    grid-template-columns: 1fr;
  }
`

export const FormView3Column = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 16px;
  @media (max-width: ${breakpoints.MOBILE_XL}px) {
    grid-template-columns: 1fr;
  }
`

export const Content = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 16px;

  @media (max-width: ${breakpoints.MOBILE_XL}px) {
    grid-template-columns: 1fr;
  }
`
export const Subtitle = styled(Text).attrs({ variant: 'normal' })`
  color: var(--gray-6-color);
  font-size: 12px;
  margin-bottom: 24px;
  border-bottom: 1px solid var(--gray-3-color);
`

export const NdsTitle = styled(Text).attrs({ variant: 'normal' })`
  color: var(--gray-6-color);
  font-size: 12px;
`

export const BasisServicePriceGrid = styled.div`
  display: grid;
  grid-template-columns: 250px 100px;
  gap: 16px;

  @media (max-width: ${breakpoints.MOBILE_XL}px) {
    grid-template-columns: 1fr;
  }
`

export const BasisServicePriceGridWrapper = styled.div`
  display: flex;
  gap: 100px;

  @media (max-width: ${breakpoints.MOBILE_XL}px) {
    flex-direction: column;
    gap: 16px;
  }
`

export const AdvertsContentWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 40px;
  justify-content: space-between;
  padding: 32px;

  @media (max-width: ${breakpoints.MOBILE_XL}px) {
    flex-direction: column;
    justify-content: center;
    gap: 6px;
    padding: 16px;
  }
`
const StyledText = styled(Text)`
  color: #00704d;
`
export const AdvertsTitle = styled(StyledText).attrs({ variant: 'description' })`
  line-height: 20px;
  font-weight: 700;
  background: #ddf5e9;
  padding: 4px 16px;
  border-radius: 8px;

  @media (max-width: ${breakpoints.MOBILE_XL}px) {
    width: 100%;
    text-align: center;
  }
`
