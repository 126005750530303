import { GraphQLError } from 'graphql/index'

import { ApolloError } from '@apollo/client/errors'

import { appToast } from '~/utils/index'

export interface ZaGraphQLError extends GraphQLError {
  exceptionName: string
}

function isGraphqlError(error: unknown): error is ApolloError {
  return error instanceof ApolloError
}

export function hasCustomGraphqlExceptionName(error: unknown, exceptionKey: string): boolean {
  return (
    isGraphqlError(error) && !!error?.graphQLErrors.find((x) => (x as ZaGraphQLError).exceptionName === exceptionKey)
  )
}

export function graphqlErrorHandler(error: unknown, errorMessage = '', isShowToast = true) {
  if (isGraphqlError(error)) {
    let description = !errorMessage.trim() ? 'Ошибка' : errorMessage
    description = `${description}: ${error.message}`
    console.error(description, error)

    if (isShowToast) {
      appToast.error({ description })
    }
  }
}
