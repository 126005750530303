import React from 'react'

import { Select } from 'antd'
import { SelectProps } from 'antd/es/select'
import { BaseSelectRef } from 'rc-select'

import { useQuery } from '@apollo/client'

import { getTransportBodyTypesQuery } from '~api/gql-query/car-park.query.graphql'
import { CustomControlInterface } from '~shared/controls/CustomControl.interface'

type Value = number

interface CommonProps extends CustomControlInterface {
  hasError?: boolean
  id?: string
  placeholder?: string
  allowClear?: boolean
}

interface SelectPropsMult extends CommonProps {
  mode: 'multiple'
  onChange: (value: Value[]) => void
  value: Value[] | undefined
}

interface SelectPropsSingl extends CommonProps {
  mode: 'single'
  onChange: (value: Value | undefined) => void
  value: Value | undefined
}

type TransportBodyTypesSelectControlProps = SelectPropsMult | SelectPropsSingl

export const TransportBodyTypesSelectControl: React.FC<TransportBodyTypesSelectControlProps> = React.forwardRef<
  BaseSelectRef,
  TransportBodyTypesSelectControlProps
>(({ mode, id, value, onChange, hasError, placeholder = 'Выберите' }, ref) => {
  const getTransportBodyTypes = useQuery(getTransportBodyTypesQuery)
  const transportBodyTypes = getTransportBodyTypes.data?.transportBodyTypes ?? []

  const sharedProps: SelectProps & { ref?: React.Ref<BaseSelectRef> } = {
    id,
    ref,
    defaultValue: value,
    status: hasError ? 'error' : undefined,
    style: { width: '100%', maxWidth: '342px' },
    onChange: onSelectHandler,
    loading: getTransportBodyTypes.loading,
    disabled: getTransportBodyTypes.loading,
    placeholder,
    allowClear: true,
  }

  function onSelectHandler(v: Value | Value[]) {
    if (Array.isArray(v) && mode === 'multiple') {
      onChange(v)
    } else if (mode === 'single') {
      onChange(v as Value)
    }
  }

  const options = transportBodyTypes.map((transportBodyType) => (
    <Select.Option key={transportBodyType.id} value={transportBodyType.id}>
      {transportBodyType.name}
    </Select.Option>
  ))

  if (mode === 'multiple') {
    return (
      <Select {...sharedProps} mode="multiple" maxTagCount={1}>
        {options}
      </Select>
    )
  }

  return <Select {...sharedProps}>{options}</Select>
})

TransportBodyTypesSelectControl.displayName = 'TransportBodyTypesSelectControl'
