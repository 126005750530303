import React from 'react'

import { Button, Flex } from 'antd'

import { Badge, Text } from '~/ui-components'
import { getDateFormat, getDateTimeDayFormat } from '~/utils/getDateFormat'
import { getFreightPaymentTerm } from '~/utils/logistics/get-freight-payment-term'
import { getFreightResponseStatusColor } from '~/utils/logistics/get-freight-response-status-color'
import { getFreightResponseStatusName } from '~/utils/logistics/get-freight-response-status-name'
import { FreightDealStatus, FreightDealType, FreightOrderResponseEntity } from '~api/gql-generated/graphql'
import { useGetCurrentRole } from '~hooks/auth'
import { formatNumber } from '~/utils/formatNumber'

interface FreightOrderResponsesTableRowDetailsProps {
  freightOrderResponse: FreightOrderResponseEntity
  onAcceptResponse: (freightOrderResponse: FreightOrderResponseEntity) => void
  onDeclineResponse: (freightOrderResponse: FreightOrderResponseEntity) => void
  onCarrierDeclineResponse: (freightOrderResponse: FreightOrderResponseEntity) => void
  onCarrierDeclineBet: (freightOrderResponse: FreightOrderResponseEntity) => void
  onGoToOrder: (freightOrderResponse: FreightOrderResponseEntity) => void
}

export const FreightOrderResponsesTableRowDetails: React.FC<FreightOrderResponsesTableRowDetailsProps> = ({
  freightOrderResponse,
  ...props
}) => {
  const {
    company: { isCarrier },
  } = useGetCurrentRole()

  function renderActionButtons() {
    if (
      freightOrderResponse.status === FreightDealStatus.Realised ||
      freightOrderResponse.status === FreightDealStatus.Agreement
    ) {
      return (
        <Flex gap={6} flex={1} justify="flex-end" onClick={() => props.onGoToOrder(freightOrderResponse)}>
          <Button type="primary">Перейти к заказу</Button>
        </Flex>
      )
    }

    if (!isCarrier && freightOrderResponse.status === FreightDealStatus.OnModeration) {
      return (
        <Flex gap={6} flex={1} justify="flex-end">
          <Button type="primary" onClick={() => props.onAcceptResponse(freightOrderResponse)}>
            Принять
          </Button>
          <Button onClick={() => props.onDeclineResponse(freightOrderResponse)}>Отклонить</Button>
        </Flex>
      )
    } else {
      if (freightOrderResponse.status === FreightDealStatus.OnModeration) {
        return (
          <Flex gap={6} flex={1} justify="flex-end">
            {freightOrderResponse.type === FreightDealType.Response && (
              <Button onClick={() => props.onCarrierDeclineResponse(freightOrderResponse)}>Отменить отклик</Button>
            )}
            {freightOrderResponse.type === FreightDealType.Bet && (
              <Button onClick={() => props.onCarrierDeclineBet(freightOrderResponse)}>Отменить ставку</Button>
            )}
          </Flex>
        )
      }

      if (
        freightOrderResponse.status === FreightDealStatus.Rejected ||
        freightOrderResponse.status === FreightDealStatus.RejectedAfterModeration
      ) {
        return null
      }
    }
  }

  return (
    <Flex vertical gap={16}>
      <Flex gap={24} justify="space-between">
        <Flex gap={47}>
          <Text variant="normal-bold">{freightOrderResponse.type === FreightDealType.Bet ? 'Ставка' : 'Отклик'}</Text>
          <Badge color={getFreightResponseStatusColor(freightOrderResponse.status)} size="s">
            {getFreightResponseStatusName(freightOrderResponse.status)}
          </Badge>
        </Flex>

        <Text variant="form-read-label">
          {freightOrderResponse.type === FreightDealType.Bet ? 'Ставка сделана' : 'Отклик оставлен'}:{' '}
          {getDateTimeDayFormat(freightOrderResponse.createdAt).toLowerCase()}
        </Text>
      </Flex>

      <Flex gap={50}>
        <Flex gap={8}>
          <Text variant="form-read-label">Дата погрузки:</Text>
          <Text variant="form-read-value">{getDateFormat(freightOrderResponse.dealOrder.startedAt)}</Text>
        </Flex>

        <Flex gap={8}>
          <Text variant="form-read-label">Условия оплаты:</Text>
          <Text variant="form-read-value">{getFreightPaymentTerm(freightOrderResponse.dealOrder.paymentTerm)}</Text>
        </Flex>

        <Flex gap={8}>
          <Text variant="form-read-label">Вес:</Text>
          {/*<Text variant="form-read-value">{freightOrderResponse.weightKg / 1000} т</Text>*/}
          <Flex vertical gap={4}>
            <Text variant="normal-bold">{formatNumber(freightOrderResponse.dealOrder.weightKg / 1000)} т</Text>
            <Text variant="normal">из {formatNumber(freightOrderResponse.order.weightKg / 1000)} т</Text>
          </Flex>
        </Flex>

        <Flex gap={4}>
          <Text variant="form-read-label">Ставка:</Text>
          <Flex gap={4} vertical>
            <Text variant="form-read-value">
              {formatNumber(freightOrderResponse.dealOrder.pricePerTonWithNds)} ₽/т (с НДС)
            </Text>
            <Text variant="form-read-value">
              {formatNumber(freightOrderResponse.dealOrder.pricePerTonWithoutNds)} ₽/т (без НДС)
            </Text>
          </Flex>
        </Flex>

        {renderActionButtons()}
      </Flex>

      {!!freightOrderResponse?.additionalDetails?.trim() && (
        <Flex gap={8}>
          <Text variant="form-read-label">Комментарий:</Text>
          <Text variant="form-read-value">{freightOrderResponse.additionalDetails}</Text>
        </Flex>
      )}
    </Flex>
  )
}
