import * as yup from 'yup'

import { VALIDATORS_MSG } from '~shared/validations'

export const createWarehouseFormStep3CarSchema = yup.object({
  autoReceptionSpeed: yup.number().typeError(VALIDATORS_MSG.required),
  autoUnloadSpeed: yup.number().typeError(VALIDATORS_MSG.required),
  autoLength: yup.number().integer(VALIDATORS_MSG.integer).typeError(VALIDATORS_MSG.required),
  autoHeight: yup.number().typeError(VALIDATORS_MSG.required),
  autoClearance: yup.number().integer(VALIDATORS_MSG.integer).typeError(VALIDATORS_MSG.required),
  hasScales: yup.boolean(),
  hasWeighingAutomation: yup.boolean(),
  scalesWeightLimit: yup.number().typeError(VALIDATORS_MSG.required),
  distanceToScalesM: yup.number().typeError(VALIDATORS_MSG.required),
  scalesLength: yup.number().typeError(VALIDATORS_MSG.required),
  hasAutoStorage: yup.boolean(),
  autoAdditionalInfo: yup.string(),
})
