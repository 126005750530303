import React, { useEffect } from 'react'

import { Empty, Flex, Pagination, Table } from 'antd'
import { observer } from 'mobx-react-lite'
import { FiChevronDown, FiChevronUp } from 'react-icons/fi'
import { useNavigate } from 'react-router-dom'

import { useQuery } from '@apollo/client'

import { usePagination } from '~/hooks'
import { AppHeader } from '~/layout'
import { appRoutes } from '~/router'
import { logisticDealsFilterStore } from '~/store/filters'
import { Text } from '~/ui-components'
import { graphqlErrorHandler } from '~/utils/graphqlErrorHandler'
import { FreightDealEntity } from '~api/gql-generated/graphql'
import { freightDealsQuery } from '~api/gql-query/freight-deals.query.graphql'
import { useGetCurrentRole } from '~hooks/auth'
import { CargoTableRowDetails } from '~pages/LogisticsPage/modules/MyCargos/components/CargoTableRowDetails/CargoTableRowDetails'
import { CargoFilters } from '~pages/LogisticsPage/shared/CargoFilters/CargoFilters'
import { getFreightDealColumns } from '~pages/LogisticsPage/shared/getFreightDealsColumns'
import { useAuth } from '~providers/auth'

export const LogisticDeals: React.FC = observer(() => {
  const navigate = useNavigate()
  const { companyId } = useAuth()
  const {
    company: { isCarrier },
  } = useGetCurrentRole()
  const { currentPage, setCurrentPage, pageSize, setPageSize, pageSizeOptions } = usePagination()

  const freightDealsFn = useQuery(freightDealsQuery, {
    variables: {
      filter: {
        carrierIds: isCarrier ? [companyId] : undefined,
        customerIds: !isCarrier ? [companyId] : undefined,
        ...logisticDealsFilterStore.mappedCurrentFiltersToResponse,
      },
      pagination: { currentPage, pageSize },
    },
    onError: graphqlErrorHandler,
    fetchPolicy: 'cache-and-network',
  })

  useEffect(() => {
    void freightDealsFn.refetch()
    setCurrentPage(1)
  }, [
    logisticDealsFilterStore.filters.weightKgFrom,
    logisticDealsFilterStore.filters.weightKgTo,
    logisticDealsFilterStore.filters.pricePerTonTo,
    logisticDealsFilterStore.filters.pricePerTonFrom,
    logisticDealsFilterStore.filters.priceWithNds,
    logisticDealsFilterStore.filters.fromRegionId,
    logisticDealsFilterStore.filters.productTypeId,
    logisticDealsFilterStore.filters.paymentTerm,
    logisticDealsFilterStore.filters.period,
  ])

  const freightDeals = freightDealsFn.data?.freightDeals.freightDeals ?? []
  const pagination = freightDealsFn.data?.freightDeals.pagination

  const onActionArrowClickHandler = (record: FreightDealEntity) => {
    navigate(appRoutes.logisticDealCard.url.replace(':id', record.id.toString()))
  }

  const columns = getFreightDealColumns(onActionArrowClickHandler)

  const locale = {
    emptyText: (
      <Flex vertical gap={14}>
        <Empty description="" />
        <Text variant="h4">Здесь будут ваши заказы</Text>
        <Text variant="normal">У вас пока нет ни одного заказа</Text>
      </Flex>
    ),
  }

  return (
    <Flex vertical>
      <AppHeader title={appRoutes.logisticDeals.title} />
      <Flex vertical gap={24} style={{ padding: 24 }}>
        <div>
          <CargoFilters store="logisticDealsFilterStore" />
        </div>

        <Table
          locale={logisticDealsFilterStore.hasActiveFilters ? undefined : locale}
          rowKey="id"
          className="table-interactive"
          loading={freightDealsFn.loading}
          columns={columns}
          dataSource={freightDeals as any}
          scroll={{ x: 'max-content' }}
          expandable={{
            expandedRowRender: (record) => <CargoTableRowDetails freight={record.order} />,
            showExpandColumn: true,
            expandIcon: ({ expanded, onExpand, record }) =>
              expanded ? (
                <Flex
                  align="center"
                  gap={10}
                  style={{ color: 'var(--primary-main-color)' }}
                  onClick={(e) => onExpand(record, e)}
                >
                  <FiChevronUp size={20} /> Свернуть
                </Flex>
              ) : (
                <Flex
                  align="center"
                  gap={10}
                  style={{ color: 'var(--primary-main-color)' }}
                  onClick={(e) => onExpand(record, e)}
                >
                  <FiChevronDown size={20} /> Развернуть
                </Flex>
              ),
          }}
          pagination={false}
        />

        {pagination && (
          <Pagination
            style={{ marginTop: 'auto' }}
            current={pagination?.currentPage}
            total={pagination?.total}
            pageSize={pagination?.pageSize}
            pageSizeOptions={pageSizeOptions}
            onShowSizeChange={(x, size) => setPageSize(size)}
            showSizeChanger
            onChange={setCurrentPage}
          />
        )}
      </Flex>
    </Flex>
  )
})
