import React, { useEffect, useState } from 'react'

import { Avatar, Space, Tag } from 'antd'
import { SizeType } from 'antd/es/config-provider/SizeContext'
import { BaseSelectRef } from 'rc-select'

import { useQuery } from '@apollo/client'

import { Text } from '~/ui-components'
import { getAbbrCompany } from '~/utils/company/get-abbr-company'
import { getUserRole } from '~/utils/user/getUserRoleOld'
import { getUsersQuery } from '~api/gql-query/users.query.graphql'
import { MyUserEntity } from '~pages/EmployeesPage/components/EmployeesList/EmployeesList'
import { CustomControlInterface } from '~shared/controls/CustomControl.interface'
import { DebounceSelect } from '~shared/controls/DebounceSelect/DebounceSelect'

export interface UsersSelectControlValue {
  label: React.ReactElement | string
  value: number
}

interface UsersSelectControlProps extends CustomControlInterface {
  onChange: (value: UsersSelectControlValue['value'][] | undefined) => void
  value: UsersSelectControlValue['value'][]
  isMultiple?: boolean
  size?: SizeType
  hasError?: boolean
  id?: string
  placeholder?: string
  excludeCompanyIds?: number[]
}

export const UsersSelectControl: React.FC<UsersSelectControlProps> = React.forwardRef<
  BaseSelectRef,
  UsersSelectControlProps
>(
  (
    {
      id,
      value,
      isMultiple = false,
      excludeCompanyIds,
      onChange,
      hasError,
      size,
      placeholder = 'Иванов Иван Иванович',
    },
    ref
  ) => {
    const getUsers = useQuery(getUsersQuery, {
      variables: {
        pagination: { take: 5, skip: 0 },
        filter: {
          excludeFromCompanyIds: excludeCompanyIds ? excludeCompanyIds : undefined,
        },
      },
    })

    const [loading, setLoading] = useState(false)

    const usersResponse = getUsers.data?.users.users ?? []

    const [selected, setSelected] = useState<UsersSelectControlValue[] | undefined>(undefined)

    useEffect(() => {
      onChange(selected?.map((x) => x.value))
    }, [selected])

    const fetchOptionsList = async (userNameQuery: string): Promise<UsersSelectControlValue[]> => {
      setLoading(true)
      const foundItems = await getUsers.refetch({
        filter: { name: userNameQuery, excludeFromCompanyIds: excludeCompanyIds ? excludeCompanyIds : undefined },
      })
      setLoading(false)
      return foundItems.data?.users?.users.map((user) => renderSelectOption(user))
    }

    const renderSelectOptionLabel = (user: MyUserEntity) => {
      return (
        <Space>
          <Avatar size={32} shape="circle" src={user.avatarUrl}>
            {getAbbrCompany(user.name)}
          </Avatar>
          <Space direction="vertical" size={0}>
            <Text variant="normal-bold">{user.name}</Text>
            <Text variant="description">{user.role && getUserRole(user.role)}</Text>
          </Space>
        </Space>
      )
    }

    const renderSelectOption = (user: MyUserEntity) => {
      return {
        label: renderSelectOptionLabel(user),
        value: user.id!,
      }
    }

    const onSelectHandler = (v?: UsersSelectControlValue | UsersSelectControlValue[]) => {
      if (!v) {
        setSelected(undefined)
        return
      }

      if (isMultiple) {
        const values = v as UsersSelectControlValue[]
        setSelected(values)
      } else {
        const value = v as UsersSelectControlValue
        setSelected([value])
      }
    }

    return (
      <DebounceSelect
        mode={isMultiple ? 'multiple' : undefined}
        allowClear
        showSearch
        showArrow
        value={selected}
        placeholder={placeholder}
        fetchOptions={fetchOptionsList}
        onChange={onSelectHandler}
        loading={loading || getUsers.loading}
        notFoundContent="Не найдено"
        defaultOptions={usersResponse.map((x) => renderSelectOption(x))}
        onClear={() => {
          setSelected([])
          void fetchOptionsList('')
        }}
        tagRender={(x) => {
          const foundUser = usersResponse.find((user) => user.id === x.value)

          return (
            <Tag closable onClose={x.onClose}>
              {foundUser && renderSelectOptionLabel(foundUser)}
            </Tag>
          )
        }}
        id={id}
        size={size}
        status={hasError ? 'error' : undefined}
        style={{ width: '100%' }}
      />
    )
  }
)

UsersSelectControl.displayName = 'UsersSelectControl'
