import React, { useState } from 'react'

import { Button, Empty, Flex, Modal, Radio, Skeleton } from 'antd'
import { FiDownload, FiPlus } from 'react-icons/fi'

import { useMutation, useQuery } from '@apollo/client'

import { Text } from '~/ui-components'
import { appToast, modalSharedSettings } from '~/utils'
import { graphqlErrorHandler } from '~/utils/graphqlErrorHandler'
import { getLogisticDealDocumentName } from '~/utils/logistics/get-logistic-deal-document-name'
import { DocumentInstanceEntity, FreightDealDocumentType, FreightDealStatus } from '~api/gql-generated/graphql'
import {
  deleteFreightDealDocumentCreateMutation,
  freightDealDocumentAddFilesMutation,
  freightDealDocumentCreateMutation,
} from '~api/gql-mutations/freight-deal-document.mutation.graphql'
import {
  freightDealDocumentsArchiveQuery,
  freightDealDocumentsQuery,
} from '~api/gql-query/freight-deal-documents.query.graphql'
import { useUploadFileAndGetId } from '~hooks/_utils'
import { useGetCurrentRole } from '~hooks/auth'
import { LogisticDealAddDocumentForm } from '~pages/LogisticsPage/modules/LogisticDealCard/components/LogisticDealDocuments/components/LogisticDealAddDocumentForm'
import { LogisticDealDocument } from '~pages/LogisticsPage/modules/LogisticDealCard/components/LogisticDealDocuments/components/LogisticDealDocument/LogisticDealDocument'

import { LogisticDealAddDocumentFormValues } from '~pages/LogisticsPage/modules/LogisticDealCard/components/LogisticDealDocuments/components/LogisticDealAddDocumentForm/LogisticDealAddDocumentForm.types'

interface LogisticOrderDocumentsProps {
  freightDealId: number
  freightDealStatus: FreightDealStatus | undefined
}

type DocumentSide = 'OWNER' | 'CARRIER'
type DocType = 'forCargo' | 'forAccountant'

const docList: Record<DocumentSide, FreightDealDocumentType[]> = {
  OWNER: [
    FreightDealDocumentType.TransportRegisterForLoading,
    FreightDealDocumentType.BillOfLadingForLoading,
    FreightDealDocumentType.ConsignmentNoteForLoading,
    FreightDealDocumentType.RegisterOfActualLoading,
    FreightDealDocumentType.CarriageContract,
    FreightDealDocumentType.CarriageContractAnnex,
    FreightDealDocumentType.ActOfCompletedWork,
  ],
  CARRIER: [
    FreightDealDocumentType.TransportRegisterForUnloading,
    FreightDealDocumentType.BillOfLadingForUnloading,
    FreightDealDocumentType.ConsignmentNoteForUnloading,
    FreightDealDocumentType.RegisterOfActualUnloading,
    FreightDealDocumentType.CarriageContractSigned,
    FreightDealDocumentType.CarriageContractAnnexSigned,
    FreightDealDocumentType.ActOfCompletedWorkSigned,
  ],
}

function getDocumentsByType(type: DocType) {
  return type === 'forCargo'
    ? [
        FreightDealDocumentType.BillOfLadingForLoading,
        FreightDealDocumentType.BillOfLadingForUnloading,
        FreightDealDocumentType.ConsignmentNoteForLoading,
        FreightDealDocumentType.ConsignmentNoteForUnloading,
        FreightDealDocumentType.RegisterOfActualLoading,
        FreightDealDocumentType.RegisterOfActualUnloading,
        FreightDealDocumentType.TransportRegisterForLoading,
        FreightDealDocumentType.TransportRegisterForUnloading,
      ]
    : [
        FreightDealDocumentType.ActOfCompletedWork,
        FreightDealDocumentType.ActOfCompletedWorkSigned,
        FreightDealDocumentType.CarriageContractAnnex,
        FreightDealDocumentType.CarriageContractAnnexSigned,
        FreightDealDocumentType.CarriageContract,
        FreightDealDocumentType.CarriageContractSigned,
      ]
}

export const LogisticDealDocuments: React.FC<LogisticOrderDocumentsProps> = (props) => {
  const {
    company: { isCarrier },
  } = useGetCurrentRole()
  const [isVisibleAddDocumentModal, setVisibleAddDocumentModal] = useState<DocType | null>(null)
  const [documentSide, setDocumentSide] = useState<DocumentSide>('OWNER')
  const [addDocumentType, setAddDocumentType] = useState<FreightDealDocumentType | null>(null)
  const [addDocumentInstanceId, setAddDocumentInstanceId] = useState<number | null>(null)

  const freightDealDocumentsFn = useQuery(freightDealDocumentsQuery, {
    variables: { freightDealId: props.freightDealId, filter: { types: docList[documentSide] } },
    onError: graphqlErrorHandler,
    fetchPolicy: 'cache-and-network',
  })

  const freightDealDocumentsArchive = useQuery(freightDealDocumentsArchiveQuery, {
    onError: graphqlErrorHandler,
    fetchPolicy: 'cache-and-network',
    skip: true,
  })

  const uploadFileAndGetId = useUploadFileAndGetId()
  const [createDocument, createDocumentState] = useMutation(freightDealDocumentCreateMutation)
  const [deleteDocument, deleteDocumentState] = useMutation(deleteFreightDealDocumentCreateMutation)
  const [addFileToDocument, addFileToDocumentState] = useMutation(freightDealDocumentAddFilesMutation)

  const handleAddDocumentClick = (docType: DocType) => {
    setAddDocumentType(null)
    setAddDocumentInstanceId(null)
    setVisibleAddDocumentModal(docType)
  }

  const handleAddFile = (type: FreightDealDocumentType, documentInstance: DocumentInstanceEntity) => {
    setAddDocumentType(type)
    setAddDocumentInstanceId(documentInstance.id)
  }

  const handleModalCancelClick = () => {
    setVisibleAddDocumentModal(null)
    setAddDocumentType(null)
    setAddDocumentInstanceId(null)
  }

  const handleUploadDocument = async ({ documentFiles, documentType }: LogisticDealAddDocumentFormValues) => {
    const fileKeys = await Promise.all(
      documentFiles.map((documentFile) => uploadFileAndGetId.mutateAsync(documentFile))
    )

    try {
      await createDocument({
        variables: {
          input: {
            freightDealId: props.freightDealId,
            type: documentType,
            fileKeys,
          },
        },
      })

      setVisibleAddDocumentModal(null)
      await freightDealDocumentsFn.refetch()
      appToast.success({
        description: 'Документ добавлен',
      })
    } catch (e) {
      graphqlErrorHandler(e, 'Ошибка при добавлении документа')
    }
  }

  const handleAddFileToDocument = async ({ documentFiles, documentType }: LogisticDealAddDocumentFormValues) => {
    if (!addDocumentInstanceId) {
      appToast.error({ description: '[handleAddFileToDocument]: addDocumentInstanceId doesnt exist' })
      return
    }
    const fileKeys = await Promise.all(
      documentFiles.map((documentFile) => uploadFileAndGetId.mutateAsync(documentFile))
    )

    try {
      await addFileToDocument({
        variables: {
          documentId: addDocumentInstanceId,
          input: {
            fileKeys,
          },
        },
      })

      setVisibleAddDocumentModal(null)
      setAddDocumentInstanceId(null)
      await freightDealDocumentsFn.refetch()
      appToast.success({
        description: 'Документ добавлен',
      })
    } catch (e) {
      graphqlErrorHandler(e, 'Ошибка при добавлении документа')
    }
  }

  const handleRemoveDocument = async (documentId: number) => {
    try {
      await deleteDocument({
        variables: {
          documentId,
        },
      })

      await freightDealDocumentsFn.refetch()
      appToast.success({
        description: 'Документ удален',
      })
    } catch (e) {
      graphqlErrorHandler(e, 'Ошибка при удалении документа')
    }
  }

  const docs = freightDealDocumentsFn.data?.freightDealDocuments

  const renderDocs = (type: DocType) => {
    if (freightDealDocumentsFn.loading) {
      return <Skeleton loading />
    }

    const documents = type === 'forCargo' ? cargoDocs : accountantDocs
    return (
      <Flex vertical gap={8}>
        {documents.map((doc) =>
          doc.instances.map((instance) => (
            <LogisticDealDocument
              key={doc.type}
              documentType={doc.type}
              documentInstance={instance}
              date={instance.createdAt}
              canDelete={!((documentSide === 'CARRIER' && !isCarrier) || (documentSide === 'OWNER' && isCarrier))}
              onAddFile={({ documentType, documentInstance }) => handleAddFile(documentType, documentInstance)}
              onDownloadFile={(doc) => downloadFileArchive(doc.documentInstance.id)}
              onDelete={({ documentType, documentInstance }) =>
                Modal.confirm({
                  title: 'Вы действительно хотите удалить документ?',
                  content: getLogisticDealDocumentName(documentType),
                  okText: 'Удалить',
                  cancelText: 'Отмена',
                  okButtonProps: { danger: true },
                  async onOk() {
                    await handleRemoveDocument(documentInstance.id)
                  },
                })
              }
            />
          ))
        )}
      </Flex>
    )
  }

  const renderEmpty = () => {
    if (!docs?.filter((d) => !!d.instances?.length).length && !freightDealDocumentsFn.loading) {
      return <Empty description="Документы не загружены" />
    }
  }

  const renderAddDocumentButton = (docType: DocType) => {
    if ((documentSide === 'CARRIER' && !isCarrier) || (documentSide === 'OWNER' && isCarrier)) {
      return null
    }
    if (
      props.freightDealStatus &&
      [
        FreightDealStatus.Realised,
        FreightDealStatus.Rejected,
        FreightDealStatus.RejectedAfterModeration,
        FreightDealStatus.OnModeration,
      ].includes(props.freightDealStatus)
    ) {
      return null
    }
    return (
      <Button type="dashed" icon={<FiPlus size={16} />} onClick={() => handleAddDocumentClick(docType)}>
        Добавить документ
      </Button>
    )
  }
  const downloadDocsArchive = async (type?: DocType) => {
    if (!docs?.length) {
      return
    }

    const documents = type ? (type === 'forCargo' ? cargoDocs : accountantDocs) : docs
    const archive = await freightDealDocumentsArchive.refetch({
      freightDealId: props.freightDealId,
      filter: {
        documentIds: documents?.flatMap((x) => x.instances.flatMap((z) => z.id)),
      },
    })
    window.open(archive.data.freightDealDocumentsArchive.source ?? '', '_blanc')
  }

  const downloadFileArchive = async (instanceId: number) => {
    const archive = await freightDealDocumentsArchive.refetch({
      freightDealId: props.freightDealId,
      filter: {
        documentIds: [instanceId],
      },
    })
    window.open(archive.data.freightDealDocumentsArchive.source ?? '', '_blanc')
  }

  const cargoDocs =
    docs?.filter((d) => getDocumentsByType('forCargo').includes(d.type))?.filter((d) => !!d.instances?.length) ?? []

  const accountantDocs =
    docs?.filter((d) => getDocumentsByType('forAccountant').includes(d.type))?.filter((d) => !!d.instances?.length) ??
    []

  return (
    <Flex vertical gap={32} style={{ marginTop: '32px' }}>
      <Radio.Group
        value={documentSide}
        onChange={(e) => setDocumentSide(e.target.value)}
        optionType="button"
        buttonStyle="solid"
      >
        <Radio.Button value={'OWNER' as DocumentSide}>Заказчик</Radio.Button>
        <Radio.Button value={'CARRIER' as DocumentSide}>Перевозчик</Radio.Button>
      </Radio.Group>

      <div>
        <Button type="link" icon={<FiDownload />} disabled={!docs?.length} onClick={() => downloadDocsArchive()}>
          Скачать все документы архивом
        </Button>
      </div>

      <Flex vertical gap={24}>
        <Flex justify="space-between">
          <Text variant="h3">Товаросопроводительные документы</Text>
          <Button
            icon={<FiDownload />}
            type="primary"
            disabled={!cargoDocs.length}
            onClick={() => downloadDocsArchive('forCargo')}
          >
            Скачать все из раздела
          </Button>
        </Flex>

        {renderEmpty()}
        {renderAddDocumentButton('forCargo')}
        {renderDocs('forCargo')}
      </Flex>

      <Flex vertical gap={24}>
        <Flex justify="space-between">
          <Text variant="h3">Бухгалтерские и юридические документы</Text>
          <Button
            icon={<FiDownload />}
            type="primary"
            disabled={!accountantDocs.length}
            onClick={() => downloadDocsArchive('forAccountant')}
          >
            Скачать все из раздела
          </Button>
        </Flex>

        {renderEmpty()}
        {renderAddDocumentButton('forAccountant')}
        {renderDocs('forAccountant')}
      </Flex>

      <Modal
        title="Выбор документа"
        open={!!isVisibleAddDocumentModal || !!addDocumentInstanceId}
        {...modalSharedSettings}
        width={400}
        onCancel={handleModalCancelClick}
        footer={[
          <Button key="cancel" onClick={handleModalCancelClick} htmlType="button">
            Отмена
          </Button>,
          <Button
            key="submit"
            form="logistic-deal-add-document-form"
            type="primary"
            htmlType="submit"
            loading={createDocumentState.loading}
          >
            Готово
          </Button>,
        ]}
      >
        {addDocumentType ? (
          <LogisticDealAddDocumentForm defaultDocumentsType={addDocumentType} onSubmit={handleAddFileToDocument} />
        ) : (
          <LogisticDealAddDocumentForm
            onSubmit={handleUploadDocument}
            documentsTypes={
              isVisibleAddDocumentModal
                ? docList[documentSide].filter((x) => getDocumentsByType(isVisibleAddDocumentModal).includes(x))
                : undefined
            }
          />
        )}
      </Modal>
    </Flex>
  )
}
