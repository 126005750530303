import styled from 'styled-components'
import { RadioItem } from '~shared/controls/TransportTypeRadioControl/TransportTypeRadioControl.styled'

export const RadioItemDisabled = styled(RadioItem)`
  border: 1px solid #e9ecee;
  border-radius: 6px;
  padding: 16px 24px;
  background: #fafafb;
  margin-bottom: 24px;

  &.ant-radio-wrapper-checked.ant-radio-wrapper-disabled {
    border-color: var(--gray-5-color);
    background: var(--primary-bg-color);
    color: #494949;
    cursor: default;

    & .ant-radio-inner,
    .ant-radio-checked::after {
      border-color: var(--gray-5-color);
    }
  }

  &.ant-radio-wrapper-checked {
    border-color: var(--primary-main-color);
    background: var(--primary-bg-color);
  }
`
