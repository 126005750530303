import * as yup from 'yup'

import { VALIDATORS_MSG } from '~shared/validations'
import { FreightOrderChangeRequestBlockType, FreightPackingType, FreightPaymentTerm } from '~api/gql-generated/graphql'

export const OfferOfConditionsFormSchema = yup.object({
  weightKg: yup
    .number()
    .when('$type', {
      is: (type: FreightOrderChangeRequestBlockType) => type === FreightOrderChangeRequestBlockType.Freight,
      then: (schema) => schema.required(VALIDATORS_MSG.required),
    })
    .typeError(VALIDATORS_MSG.required),
  packingType: yup
    .mixed<FreightPackingType>()
    .oneOf(Object.values(FreightPackingType))
    .when('$type', {
      is: (type: FreightOrderChangeRequestBlockType) => type === FreightOrderChangeRequestBlockType.Freight,
      then: (schema) => schema.required(VALIDATORS_MSG.required),
    }),
  bigBagsCount: yup
    .number()
    .when('packingType', {
      is: (packingType: FreightPackingType) => packingType === FreightPackingType.BigBag,
      then: (schema) => schema.required(VALIDATORS_MSG.required),
    })
    .typeError(VALIDATORS_MSG.required),
  packingTypeCustom: yup
    .string()
    .trim()
    .when('packingType', {
      is: (packingType: FreightPackingType) => packingType === FreightPackingType.Custom,
      then: (schema) => schema.required(VALIDATORS_MSG.required),
    }),
  pricePerTonWithoutNds: yup
    .number()
    .when('$type', {
      is: (type: FreightOrderChangeRequestBlockType) => type === FreightOrderChangeRequestBlockType.Price,
      then: (schema) => schema.required(VALIDATORS_MSG.required),
    })
    .typeError(VALIDATORS_MSG.required),
  paymentTerm: yup
    .mixed<FreightPaymentTerm>()
    .oneOf(Object.values(FreightPaymentTerm))
    .when('$type', {
      is: (type: FreightOrderChangeRequestBlockType) => type === FreightOrderChangeRequestBlockType.Price,
      then: (schema) => schema.required(VALIDATORS_MSG.required),
    }),
  deferredDaysCount: yup
    .number()
    .when('paymentTerm', {
      is: (paymentTerm: FreightPaymentTerm) => paymentTerm === FreightPaymentTerm.Deferred,
      then: (schema) => schema.required(VALIDATORS_MSG.required),
    })
    .typeError(VALIDATORS_MSG.required),
  advancePercent: yup
    .number()
    .when('paymentTerm', {
      is: (paymentTerm: FreightPaymentTerm) => paymentTerm === FreightPaymentTerm.Advance,
      then: (schema) => schema.required(VALIDATORS_MSG.required),
    })
    .typeError(VALIDATORS_MSG.required),
  canUpdateTransportRegistryInPast: yup.boolean(),
  distance: yup
    .number()
    .when('$type', {
      is: (type: FreightOrderChangeRequestBlockType) => type === FreightOrderChangeRequestBlockType.Route,
      then: (schema) => schema.required(VALIDATORS_MSG.required),
    })
    .typeError(VALIDATORS_MSG.required),
  additionalDetails: yup
    .string()
    .trim()
    .when('$type', {
      is: (type: FreightOrderChangeRequestBlockType) => type === FreightOrderChangeRequestBlockType.Route,
      then: (schema) => schema.required(VALIDATORS_MSG.required),
    }),
})
