import styled from 'styled-components'

import { breakpoints } from '~/tokens'

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding: 8px;
  @media (max-width: ${breakpoints.MOBILE_XL}px) {
    padding: 8px;
  }
`
