import React from 'react'

import { MyUserEntity } from '~pages/EmployeesPage/components/EmployeesList/EmployeesList'

import { UserRequestedListItem } from '../UserRequestedListItem'

import * as S from './UserRequestedList.styled'
import { UserRole } from '~api/gql-generated/graphql'

interface UserListProps {
  users: MyUserEntity[]
  onUserReject: (userId: number) => void
  onUserAccept: (userId: number, role: UserRole) => void
}

export const UserRequestedList: React.FC<UserListProps> = (props) => {
  const onUserRejectHandler = (user: MyUserEntity) => user.id && props.onUserReject(user.id)
  const onUserAcceptHandler = (user: MyUserEntity, role: UserRole) => user.id && props.onUserAccept(user.id, role)

  return (
    <S.Wrapper>
      {props.users.map((user) => (
        <UserRequestedListItem
          key={user.id}
          user={user}
          onReject={() => onUserRejectHandler(user)}
          onAccept={(role) => onUserAcceptHandler(user, role)}
        />
      ))}
    </S.Wrapper>
  )
}
