import styled from 'styled-components'

import { css } from '@styled-system/css'

import { Box, Text } from '~/ui-components'

export const Title = styled(Text).attrs({ variant: 'h3' })(css({}))

export const OfferCard = styled.div<{ isOwn: boolean }>(({ isOwn }) =>
  css({
    display: 'flex',
    flexDirection: 'column',
    gap: ['16px', null, '16px'],
    padding: ['16px', '16px', '24px'],
    backgroundColor: isOwn ? 'YellowPrimary' : '#FFFFFF',
    boxShadow: '0px 1px 4px rgba(84, 84, 84, 0.15)',
    borderRadius: '16px',
    border: '1px solid',
    borderColor: 'Gray300',
  })
)

export const ID = styled.div(
  css({
    color: 'TxtPrimary',
    variant: 'typography.headingsH5',
  })
)

export const Desktop = styled.div(
  css({
    display: ['none', null, 'block'],
  })
)

export const Mobile = styled.div(
  css({
    display: ['block', null, 'none'],
  })
)

export const ContentWrap = styled.div(
  css({
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: ['column', null, null, 'row'],
    gap: '16px',
  })
)

export const Content = styled.div(
  css({
    flexGrow: 1,
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: ['column', null, null, 'row'],
    alignItems: 'flex-start',
    alignContent: 'flex-start',
    rowGap: '16px',
  })
)

export const Info = styled.div(
  css({
    display: 'flex',
    flexDirection: ['row', null, 'column'],
    alignItems: 'flex-end',
    justifyContent: 'space-between',
    flexShrink: 0,
  })
)

export const ContentInfo = styled.div(
  css({
    order: [-1, null, 0],
    display: 'flex',
    gap: ['16px'],
    width: ['100%', null, null, '50%'],
    paddingRight: ['0', null, null, '16px'],
  })
)

export const TitleWrapper = styled.div(
  css({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '8px',
  })
)

export const LocationWrapper = styled(Box)(
  css({
    // order: [1, null, 0],
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    width: ['100%', null, null, '50%'],
  })
)

export const Location = styled.div(
  css({
    color: 'TxtDefault',
    variant: 'typography.headingsH5',
  })
)

export const Distance = styled.div(
  css({
    color: 'TxtPrimary',
    variant: 'typography.textBaseRegular',
  })
)

export const ShippingRequired = styled(Distance)({})

export const Parameters = styled.div(
  css({
    order: [-1, null, 0],
    display: 'flex',
    flexWrap: 'wrap',
    gap: '8px',
    width: '100%',
  })
)

export const InfoContent = styled.div(
  css({
    display: 'flex',
    flexDirection: 'column',
    textAlign: ['left', null, 'right'],
    gap: '4px',
    color: 'TxtDefault',
    minWidth: ['auto', null, '170px'],
  })
)

export const Action = styled.div(
  css({
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    marginTop: '16px',
  })
)

export const Volume = styled.div`
  margin-bottom: 4px;
  font-weight: 700;
  font-size: 22px;
  line-height: 24px;
  white-space: nowrap;
`

export const Price = styled.div`
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  white-space: nowrap;
`

export const Nds = styled.div`
  font-size: 16px;
  line-height: 24px;
  white-space: nowrap;
`

export const AdminInfoWrapper = styled.div(
  css({
    display: 'flex',
    flexDirection: ['column', null, null, 'row'],
    flexWrap: 'wrap',
    columnGap: '80px',
    rowGap: '8px',
  })
)

export const BadgesWrapper = styled.div(
  css({
    display: 'flex',
    flexWrap: 'wrap',
    gap: '8px',
  })
)

export const AdminInfo = styled.div(
  css({
    display: 'flex',
    flexDirection: 'column',
    gridTemplateColumns: 'max-content',
    gap: '8px',
    maxWidth: ['100%', null, null, 'calc(50% - 40px)'],
  })
)

export const RowWrapper = styled.div(
  css({
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    gap: '8px',
  })
)

export const Row = styled.div(
  css({
    display: ['block', null, 'flex'],
    gap: '32px',
    variant: 'typography.tableBodyText',
  })
)

export const TextStyled = styled.div(
  css({
    width: '100%',
    color: 'TxtPrimary',
    variant: 'typography.tableBodyText',
  })
)

export const RowLabel = styled.div(
  css({
    color: 'TxtPrimary',
    minWidth: '140px',
  })
)

export const RowValue = styled.div(
  css({
    color: 'TxtDefault',
  })
)

export const Value = styled.span(
  css({
    color: 'TxtDefault',
  })
)
