import React from 'react'

import { Col, Flex, Input, InputNumber, Radio, Row } from 'antd'
import { Controller, useFormContext } from 'react-hook-form'

import { FormField, Text } from '~/ui-components'
import { inputSharedSettings } from '~/utils/inputSharedSettings'
import { getFreightPackingType } from '~/utils/logistics/get-freight-packing-type'
import { FreightPackingType } from '~api/gql-generated/graphql'
import { ProductTypeIdSelectControl } from '~shared/controls/ProductTypeIdSelectControl'

import { CreateLoadFormFieldProps } from '~pages/LogisticsPage/modules/MyCargos/components/CreateLoad/CreateLoad.types'

import * as S from '~pages/LogisticsPage/modules/MyCargos/components/CreateLoad/components/LoadingDate/LoadingDate.styled'

export const Load: React.FC<CreateLoadFormFieldProps & { index: number }> = ({ control, errors, index }) => {
  const { watch } = useFormContext()
  const routePoints = watch('routePoints')

  return (
    <Flex vertical gap={24} style={{ margin: '0 12px', padding: '12px', backgroundColor: '#FAFAFB' }}>
      <Text variant="h3">
        Груз <S.Required>*</S.Required>
      </Text>

      <Row gutter={32}>
        <Col span={12}>
          <FormField validateMessage={errors.routePoints?.[index]?.freight?.packingType?.message}>
            <Controller
              name={`routePoints.${index}.freight.packingType`}
              control={control}
              render={({ field }) => (
                <Radio.Group {...field} optionType="button" buttonStyle="solid" size="middle">
                  {[FreightPackingType.Bulk, FreightPackingType.BigBag, FreightPackingType.Custom].map((value) => (
                    <Radio.Button key={value} value={value}>
                      {getFreightPackingType(value)}
                    </Radio.Button>
                  ))}
                </Radio.Group>
              )}
            />
          </FormField>
        </Col>
        <Col span={12}></Col>

        <Col span={12}>
          {routePoints[index].freight.packingType === FreightPackingType.BigBag && (
            <FormField
              label="Кол-во"
              htmlFor={`routePoints.${index}.freight.bigBagsCount`}
              validateMessage={errors.routePoints?.[index]?.freight?.bigBagsCount?.message}
              isRequired
            >
              <Controller
                name={`routePoints.${index}.freight.bigBagsCount`}
                control={control}
                render={({ field }) => (
                  <InputNumber
                    {...field}
                    {...inputSharedSettings}
                    style={{ width: '100%', maxWidth: '342px' }}
                    id={`routePoints.${index}.freight.bigBagsCount`}
                    placeholder="Введите значение"
                    status={errors.routePoints?.[index]?.freight?.bigBagsCount && 'error'}
                    step={1}
                  />
                )}
              />
            </FormField>
          )}
          {routePoints[index].freight.packingType === FreightPackingType.Custom && (
            <FormField
              label="Вид упаковки"
              htmlFor={`routePoints.${index}.freight.packingTypeCustom`}
              validateMessage={errors.routePoints?.[index]?.freight?.packingTypeCustom?.message}
              isRequired
            >
              <Controller
                name={`routePoints.${index}.freight.packingTypeCustom`}
                control={control}
                render={({ field }) => (
                  <Input
                    {...field}
                    style={{ maxWidth: '342px' }}
                    id={`routePoints.${index}.freight.packingTypeCustom`}
                    status={errors.routePoints?.[index]?.freight?.packingTypeCustom && 'error'}
                    placeholder="Например, мешки"
                  />
                )}
              />
            </FormField>
          )}
        </Col>
        <Col span={12}></Col>

        <Col sm={{ span: 12 }} lg={{ span: 10 }}>
          <FormField
            label="Наименование груза"
            htmlFor={`routePoints.${index}.freight.productTypeId`}
            validateMessage={errors.routePoints?.[index]?.freight?.productTypeId?.message}
          >
            <Controller
              name={`routePoints.${index}.freight.productTypeId`}
              control={control}
              render={({ field }) => (
                <ProductTypeIdSelectControl
                  {...field}
                  hasError={!!errors.routePoints?.[index]?.freight?.productTypeId?.message}
                  mode="single"
                />
              )}
            />
          </FormField>
        </Col>

        <Col sm={{ span: 12 }} lg={{ span: 10 }}>
          <FormField
            label="Вес"
            htmlFor={`routePoints.${index}.freight.weightKg`}
            validateMessage={errors.routePoints?.[index]?.freight?.weightKg?.message}
          >
            <Controller
              name={`routePoints.${index}.freight.weightKg`}
              control={control}
              render={({ field }) => (
                <InputNumber
                  {...field}
                  {...inputSharedSettings}
                  addonAfter="т"
                  style={{ width: '100%', maxWidth: '342px' }}
                  id={`routePoints.${index}.freight.weightKg`}
                  placeholder="Введите значение"
                  status={errors.routePoints?.[index]?.freight?.weightKg && 'error'}
                  step={1}
                />
              )}
            />
          </FormField>
        </Col>
        <Col lg={{ span: 4 }}></Col>
      </Row>
    </Flex>
  )
}
