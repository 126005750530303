import styled from 'styled-components'

import css from '@styled-system/css'

export const ContentWrapper = styled.div(
  css({
    display: 'grid',
    gridTemplateColumns: ['1fr', '1fr', '140px 1fr'],
    columnGap: '40px',
    marginBottom: '16px',
  })
)
