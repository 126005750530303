import { Image, Pagination } from 'antd'
import styled from 'styled-components'

export const Content = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
`

export const DocumentInfo = styled.div`
  display: flex;
  gap: 24px;
  margin-bottom: 4px;
`
export const IFrame = styled.iframe`
  width: 100%;
  height: 100%;
  border: 1px solid #d6d6d6;
  box-shadow: 2px 4px 4px rgba(84, 84, 84, 0.15);
`
export const StyledImage = styled(Image)`
  width: 100%;
  height: 100%;
  max-height: 630px;
  object-fit: contain;
  border: 1px solid #d6d6d6;
  box-shadow: 2px 4px 4px rgba(84, 84, 84, 0.15);
`
export const StyledPagination = styled(Pagination)`
  align-self: center;
  padding-top: 6px;
  margin-top: auto;
`
