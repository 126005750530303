import { DealDocumentDataTypeEnum } from '~api/generated'

export const dealDocumentsGroupsTypes = {
  main: [
    DealDocumentDataTypeEnum.SignedSpecification,
    DealDocumentDataTypeEnum.SignedSupplyContract,
    DealDocumentDataTypeEnum.LaboratoryAnalysis,
    DealDocumentDataTypeEnum.InvoiceForSupplyPayment,
    DealDocumentDataTypeEnum.PaymentOrderUnderAgencyContract,
    DealDocumentDataTypeEnum.PaymentOrderUnderTheSupplyContract,
    DealDocumentDataTypeEnum.InvoiceForGoods,
    DealDocumentDataTypeEnum.ReconciliationActFromBuyer,
    DealDocumentDataTypeEnum.ReconciliationActFromSeller,
  ],
  agent: [
    DealDocumentDataTypeEnum.SignedAgencyContract,
    DealDocumentDataTypeEnum.SignedAgencyOrder,
    DealDocumentDataTypeEnum.InvoiceForAgencyPayment,
    DealDocumentDataTypeEnum.AgentReport,
    DealDocumentDataTypeEnum.ActOfCompletedAgencyWork,
  ],
  product: [
    DealDocumentDataTypeEnum.DeclarationOfConformityWithCuRegulations,
    DealDocumentDataTypeEnum.CertificateOfOriginOfGoods,
    DealDocumentDataTypeEnum.CertificateForUseOfPesticides,
    DealDocumentDataTypeEnum.VeterinaryCertificateForGoods,
    DealDocumentDataTypeEnum.QuarantineCertificate,
    DealDocumentDataTypeEnum.CertificateOfAbsenceOfGmo,
    DealDocumentDataTypeEnum.PhytosanitaryCertificate,
  ],
  transportation: [
    DealDocumentDataTypeEnum.ShippingDocument,
    DealDocumentDataTypeEnum.BillOfLading,
    DealDocumentDataTypeEnum.ConsignmentNote,
    DealDocumentDataTypeEnum.ShippingInvoice,
  ],
}
