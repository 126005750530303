import { graphql } from '~api/gql-generated'

export const freightOrderChangeRequestBlocksQuery = graphql(/* GraphQL */ `
  query FreightOrderChangeRequestBlocks($freightDealId: Int!, $filter: FreightOrderChangeRequestBlockFilterInput) {
    freightOrderChangeRequestBlocks(freightDealId: $freightDealId, filter: $filter) {
      id
      type
      updatedAt
      changeRequests {
        type
        status
        nextStatuses
        endedByCompanyId
        createdByCompanyId
        changedFields {
          fieldName
          value
        }
        additionalDetails
        id
        createdByCompany {
          legalName
        }
        updatedAt
      }
    }
  }
`)
