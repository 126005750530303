import React, { useState } from 'react'

import { Button } from 'antd'
import { FiAlertCircle } from 'react-icons/fi'
import styled from 'styled-components'

import { breakpoints } from '~/tokens'
import { Text } from '~/ui-components'
import { useGetDevice } from '~hooks/common'
import { CheckboxControl } from '~shared/controls'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`

export const DescriptionWrapper = styled.div`
  display: grid;
  grid-template-columns: 30px 1fr;
  align-items: center;
  gap: 6px;
  flex-wrap: wrap;
`
export const DescriptionAttention = styled(Text).attrs({ variant: 'normal' })`
  border-left: 2px solid #00c781;
  padding-left: 10px;
`

export const AgreementText = styled(Text).attrs({ variant: 'normal-bold' })`
  text-align: unset;
  color: var(--primary-main-color);
  a {
    color: var(--primary-main-color);
  }
  @media (max-width: ${breakpoints.MOBILE_XL}px) {
    text-align: center;
  }
`

export const DescriptionBold = styled(Text).attrs({ variant: 'normal-bold' })``
export const Actions = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: row-reverse;
  gap: 8px;
  @media (max-width: ${breakpoints.MOBILE_XL}px) {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
  }
`

interface CreateAdvertOfferSuccessStepProps {
  onClose?: () => void
  onNext?: () => void
  okText?: string
  showActions?: boolean
}
export const CreateAdvertOfferAgreementStep: React.FC<CreateAdvertOfferSuccessStepProps> = (props) => {
  const [isAgree, setAgree] = useState(false)
  const device = useGetDevice()
  const isMobile = device === 'mobile'

  const onNextHandler = () => props?.onNext?.()
  const navigateBackHandler = () => props.onClose?.()

  return (
    <Wrapper>
      <Text variant="h2" style={{ alignSelf: isMobile ? 'center' : 'unset' }}>
        Важно!
      </Text>

      <DescriptionWrapper>
        <FiAlertCircle size={24} />
        <DescriptionBold>
          Мы <span style={{ color: '#45A67D' }}>НЕ берем</span> комиссию, если сделка не состоялась.
        </DescriptionBold>
      </DescriptionWrapper>

      <DescriptionAttention>
        Успешной считается сделка, когда объем продукции был передан продавцом в системе ФГИС “Зерно“ покупателю, и
        покупатель погасил входящий СДИЗ.
      </DescriptionAttention>

      <DescriptionBold>
        Сделка заключается <span style={{ color: '#45A67D' }}>напрямую</span> между продавцом и покупателем
      </DescriptionBold>

      <Text variant="normal">
        Он может принять или отклонить ваше предложение. Если продавец принимает отклик, мы передаем вам данные продавца
        для заключения сделки между вами.
      </Text>

      <Text variant="normal-bold">Ознакомьтесь, пожалуйста, с договором:</Text>

      <AgreementText as="a" href="/privacy_policy.pdf" target="_blank">
        Договор оферта
      </AgreementText>

      <CheckboxControl value={isAgree} onChange={(x) => setAgree(x ?? false)}>
        С условиями договора оферты ознакомился и согласен
      </CheckboxControl>

      {props.showActions && (
        <Actions>
          <Button htmlType="button" type="primary" block={isMobile} onClick={onNextHandler} disabled={!isAgree}>
            {props.okText ?? 'Продолжить'}
          </Button>
          <Button htmlType="button" block={isMobile} onClick={navigateBackHandler}>
            Назад
          </Button>
        </Actions>
      )}
    </Wrapper>
  )
}
