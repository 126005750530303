import { makeAutoObservable } from 'mobx'

import { AdminElevatorAdvertDataTypeEnum, ElevatorOfferStatus } from '~api/generated'

class BasisAdvertsAdminFilterStore {
  public idSearchQuery = ''
  public elevatorId: number | undefined = undefined
  public advertStatuses: ElevatorOfferStatus[] = []
  public advertType: AdminElevatorAdvertDataTypeEnum | undefined = undefined

  constructor() {
    makeAutoObservable(this)
  }

  setIdSearchQuery(searchQuery: string) {
    this.idSearchQuery = searchQuery
  }

  setElevatorId(elevatorId: number | undefined) {
    this.elevatorId = elevatorId
  }
  setAdvertStatuses(advertStatuses: ElevatorOfferStatus[]) {
    this.advertStatuses = advertStatuses
  }

  setAdvertType(advertType: AdminElevatorAdvertDataTypeEnum | undefined) {
    this.advertType = advertType
  }
}

export const basisAdvertsAdminFilterStore = new BasisAdvertsAdminFilterStore()
