import { useQuery, UseQueryResult } from '@tanstack/react-query'

import { GetCompanyOffersResponse, OfferApiOfferControllerGetCompanyOffersRequest } from '~/api/generated'
import { useApi } from '~/providers/api'
import { isApiResponseEmpty } from '~/utils/isApiResponseEmpty'

export const useGetOffersCompany = (
  request: OfferApiOfferControllerGetCompanyOffersRequest
): UseQueryResult<GetCompanyOffersResponse> => {
  const api = useApi()
  const fetchFn = async (): Promise<GetCompanyOffersResponse | null> => {
    try {
      const response = await api.offers.offerControllerGetCompanyOffers(request)

      return response.data
    } catch (error) {
      if (isApiResponseEmpty(error)) {
        return null
      }

      return Promise.reject(error)
    }
  }

  return useQuery(['offers', request], fetchFn, {
    keepPreviousData: true,
  })
}
