import React, { FC } from 'react'

import { FiChevronRight } from 'react-icons/fi'

import { Card } from '~/ui-components'
import { DealStatusBadge } from '~/utils/deals/DealStatusBadge'

import { ModeratorDealListMobileItemType } from '~pages/ModeratorPage/components/ModeratorDealList/components/ModeratorDealListMobile/ModeratorDealListMobile.types'

import * as S from './ModeratorDealListMobileItem.styled'

interface IModeratorUserListMobileItemProps extends ModeratorDealListMobileItemType {
  onItemClick: (item: ModeratorDealListMobileItemType) => void
}

export const ModeratorDealListMobileItem: FC<IModeratorUserListMobileItemProps> = ({ onItemClick, ...item }) => {
  const onClickHandler = () => {
    onItemClick(item)
  }

  return (
    <Card onClick={onClickHandler}>
      <S.ContentWrapper>
        <div>
          <Card.Label>Компания продавец</Card.Label>
          <Card.Value>{item.sellerCompanyName}</Card.Value>
        </div>

        <div>
          <Card.Label>Компания покупатель</Card.Label>
          <Card.Value>{item.buyerCompanyName}</Card.Value>
        </div>
        <div>
          <Card.Label>Объем,т</Card.Label>
          <Card.Value>{item.quantity}</Card.Value>
        </div>
        <div>
          <Card.Label>Цена, ₽/т</Card.Label>
          <Card.Value>{item.price}</Card.Value>
        </div>

        <S.Status>
          <Card.Label>Статус сделки на продажу:</Card.Label>
          <Card.Value>
            <DealStatusBadge status={item.statusSeller} />
          </Card.Value>
        </S.Status>

        <S.Status>
          <Card.Label>Статус сделки на покупку:</Card.Label>
          <Card.Value>
            <DealStatusBadge status={item.statusBuyer} />
          </Card.Value>
        </S.Status>

        <div>
          <S.ActionWrapper>
            <FiChevronRight size={24} display={'block'} />
          </S.ActionWrapper>
        </div>
      </S.ContentWrapper>
    </Card>
  )
}
