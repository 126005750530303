import { Checkbox } from 'antd'
import styled from 'styled-components'

import { Text } from '~/ui-components'

export const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 16px;
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`

export const StyledCheckboxGroup = styled(Checkbox.Group)`
  display: flex;
  flex-direction: column;
  gap: 16px;
`

export const Item = styled.div<{ isDefault?: boolean }>`
  background: ${(p) => (p.isDefault ? 'var(--gray-2-color)' : undefined)};

  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 8px 16px;
  border: ${(p) => (p.isDefault ? undefined : '2px solid var(--primary-main-color)')};
  border-radius: 6px;
`

export const Description = styled(Text).attrs({ variant: 'description' })`
  color: var(--gray-6-color);
`

export const SubmitWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: end;
  justify-content: space-between;
`

export const CheckboxWrapper = styled.label`
  display: flex;
  gap: 8px;
  align-items: center;
  cursor: pointer;
  user-select: none;
  > * {
    flex-shrink: 0;
  }
`

export const ItemSubtitle = styled(Text).attrs({ variant: 'label-bold' })`
  color: var(--primary-main-color);
`
