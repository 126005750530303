import styled from 'styled-components'

import { breakpoints } from '~/tokens'
import { Text } from '~/ui-components'

export const InfoText = styled(Text).attrs({ variant: 'description' })`
  margin-top: 4px;
  color: var(--gray-6-color);
`

export const OrText = styled(Text).attrs({ variant: 'normal' })`
  //margin-top: 4px;
  color: #969696;
`
export const ParametersInfo = styled.div<{ isAllow: boolean }>`
  margin: 20px 0;
  display: flex;
  gap: 8px;
  align-items: center;
  > * {
    color: ${(props) => (props.isAllow ? 'var(--badge-blue-bg-color)' : '#989898')};
  }
`

export const Parameters = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 8px;
  max-width: 500px;
  color: var(--badge-gray-bg-color);
  margin-bottom: 16px;
  @media (max-width: ${breakpoints.MOBILE_XL}px) {
    grid-template-columns: 1fr;
    grid-row-gap: 4px;
  }
`

export const PriceControlWrapper = styled.div`
  display: grid;
  grid-template-columns: 160px 1fr;
  align-items: center;
  gap: 50px;
`

export const EditCultureParamsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  background: #f5faf6;
  padding: 10px;
  border-radius: 6px;
`
export const EditCultureParamsText = styled(Text).attrs({ variant: 'normal' })`
  color: #45a67d;
`
