import * as yup from 'yup'

import { VALIDATORS_MSG } from '~shared/validations'

export const editAdvertFormByAdminSchema = yup.object({
  pricePerTon: yup
    .number()
    .typeError(VALIDATORS_MSG.required)
    .min(1000, VALIDATORS_MSG.minValue(1000))
    .required(VALIDATORS_MSG.required),
  priceType: yup.mixed().required(VALIDATORS_MSG.required),
  // labAnalysis: yup.mixed().required(VALIDATORS_MSG.required),
})
