import { graphql } from '~api/gql-generated'

export const freightOrderChangeRequestSetViewedMutation = graphql(/* GraphQL */ `
  mutation FreightOrderChangeRequest($changeRequestId: Int!) {
    freightOrderChangeRequest(changeRequestId: $changeRequestId) {
      setViewed {
        id
      }
    }
  }
`)
