import React from 'react'

import { Button, Dropdown, Tooltip } from 'antd'
import { ItemType } from 'antd/es/menu/interface'
import { FiEdit2, FiMoreVertical, FiTrash2 } from 'react-icons/fi'

import { useGetDevice } from '~hooks/common'

interface CompanyAccountInfoRightMenuProps {
  canEdit: boolean
  canRemove: boolean
  onEdit: () => void
  onRemove: () => void
}
export const CompanyAccountInfoRightMenu: React.FC<CompanyAccountInfoRightMenuProps> = (props) => {
  const device = useGetDevice()
  const isMobile = device === 'mobile'

  const menuItems: ItemType[] = [
    {
      label: <Tooltip title={!props.canEdit ? 'Не хватает прав' : undefined}>Редактировать</Tooltip>,
      key: 'edit',
      icon: <FiEdit2 />,
      onClick: props.onEdit,
      disabled: !props.canEdit,
    },
    {
      label: <Tooltip title={!props.canRemove ? 'Не хватает прав' : undefined}>Удалить компанию</Tooltip>,
      danger: true,
      key: 'remove',
      icon: <FiTrash2 />,
      onClick: props.onRemove,
      disabled: !props.canRemove,
    },
  ]

  if (isMobile) {
    return (
      <Dropdown menu={{ items: menuItems }} trigger={['click']}>
        <Button type="text" icon={<FiMoreVertical size={24} />} />
      </Dropdown>
    )
  }
  return (
    <Tooltip title={!props.canEdit ? 'Не хватает прав' : undefined}>
      <Button type="primary" onClick={props.onEdit} disabled={!props.canEdit}>
        Редактировать
      </Button>
    </Tooltip>
  )
}
