import React, { FC } from 'react'

import { Spin } from 'antd'

import { OffersPreviewWrapperProps } from './OffersCardPreviewWrapper.types'

import * as S from './OffersCardPreviewWrapper.styled'

export const OffersCardPreviewWrapper: FC<OffersPreviewWrapperProps> = ({ children, isLoading }) => {
  if (isLoading) {
    return (
      <S.SpinWrapper>
        <Spin spinning={isLoading} size="large" />
      </S.SpinWrapper>
    )
  }

  return <S.Wrapper>{children}</S.Wrapper>
}
