import React, { useState } from 'react'

import { Button, Modal, Skeleton } from 'antd'

import { httpErrorHandler, modalSharedSettings } from '~/utils'
import { useUploadFileAndGetId } from '~hooks/_utils'
import { useGetElevatorAdvert } from '~hooks/elevator-advert'
import { useCreateElevatorAdvertOfferFromSeller } from '~hooks/elevator-advert-offers'
import { CreateAdvertOfferForm } from '~pages/BasisPage/components/CreateAdvertOfferForm'
import { CreateAdvertOfferSuccessStep } from '~pages/BasisPage/components/CreateAdvertOfferModal/CreateAdvertOfferSuccessStep'
import { ParametersCultureControlValue } from '~shared/controls/ParametersCultureControl'

import { CreateAdvertOfferFormValues } from '~pages/BasisPage/components/CreateAdvertOfferForm/CreateAdvertOfferForm.types'

const FORM_ID = 'create-advert-offer-form'

interface CreateAdvertOfferModalProps {
  isOpen: boolean
  onCancel: () => void
  advertId: number
  userCompanyId: number
}

export const CreateAdvertOfferModal: React.FC<CreateAdvertOfferModalProps> = (props) => {
  const useGetElevatorAdvertFn = useGetElevatorAdvert({ id: props.advertId, userCompanyId: props.userCompanyId })
  const uploadLabAnalysisAndGetIdFn = useUploadFileAndGetId()
  const createElevatorAdvertOfferFromSellerFn = useCreateElevatorAdvertOfferFromSeller()
  const [isShowSuccessStep, setShowSuccessStep] = useState(false)
  const advert = useGetElevatorAdvertFn.data

  const onCancelHandler = () => props.onCancel()
  const onSubmitHandler = async (formValues: CreateAdvertOfferFormValues) => {
    try {
      const laboratoryAnalysisFileKey = formValues.requiredLaboratoryAnalysis
        ? undefined
        : await uploadLabAnalysisAndGetIdFn.mutateAsync(formValues.labAnalysis)

      const res = await createElevatorAdvertOfferFromSellerFn.mutateAsync({
        createOfferResponseFromSellerBody: {
          userCompanyId: props.userCompanyId,
          laboratoryAnalysisFileKey,
          elevatorAdvertId: props.advertId,
          address: formValues?.address?.value ?? undefined,
          includingNds: formValues.usingNds,
          volume: formValues.volume,
          pricePerTon: formValues.pricePerTon,
          priceType: formValues.priceType,
          parameters: advert?.allowOffersWithOtherParameters ? formValues.culture?.parameters : null,
          requiredLaboratoryAnalysis: formValues.requiredLaboratoryAnalysis,
        },
      })
      setShowSuccessStep(true)
    } catch (e) {
      httpErrorHandler(e, 'Ошибка при сохранении отклика на объявление')
    }
  }

  const cultureDefaultControl: ParametersCultureControlValue | undefined = advert
    ? {
        cultureTypeId: advert.product.type,
        parameters: advert.product.parameters,
        hasError: false,
      }
    : undefined

  const renderModalContent = () => {
    if (!advert || useGetElevatorAdvertFn.isFetching) {
      return <Skeleton loading />
    }

    if (isShowSuccessStep) {
      return <CreateAdvertOfferSuccessStep onClose={onCancelHandler} />
    }

    return (
      <CreateAdvertOfferForm
        formId={FORM_ID}
        productData={advert?.product}
        onSubmit={onSubmitHandler}
        allowOffersWithOtherParameters={advert?.allowOffersWithOtherParameters ?? false}
        defaultValues={{ culture: cultureDefaultControl }}
      />
    )
  }

  return (
    <Modal
      {...modalSharedSettings}
      width={isShowSuccessStep ? 400 : 500}
      open={props.isOpen}
      onCancel={onCancelHandler}
      title={isShowSuccessStep ? '' : 'Предложить объем'}
      footer={
        isShowSuccessStep
          ? []
          : [
              <Button key="cancel" onClick={onCancelHandler} htmlType="button">
                Отмена
              </Button>,
              <Button
                form={FORM_ID}
                key="submit"
                type="primary"
                htmlType="submit"
                loading={createElevatorAdvertOfferFromSellerFn.isLoading || uploadLabAnalysisAndGetIdFn.isLoading}
              >
                Предложить объем
              </Button>,
            ]
      }
    >
      {renderModalContent()}
    </Modal>
  )
}
