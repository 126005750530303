import React from 'react'

import { Table } from 'antd'
import { ColumnsType } from 'antd/lib/table'

import { useGetTableHeight } from '~/hooks'
import { ElevatorData } from '~api/generated'
import { actionArrowColumn } from '~shared/columns/shared'

import { BasisListProps } from '~pages/BasisPage/modules/BasisList/BasisList.types'

export const BasisListTable: React.FC<BasisListProps> = ({ basisList, isLoading, onBasisClick }) => {
  const tableHeight = useGetTableHeight(350)

  const onRowClickHandler = (record: ElevatorData) => {
    onBasisClick(record.id)
  }

  const columns: ColumnsType<ElevatorData> = [
    {
      title: '№',
      dataIndex: 'id',
      width: 60,
    },
    {
      title: 'Базис',
      dataIndex: 'name',
      className: 'bold-cell',
    },
    {
      title: 'Кол-во объявлений',
      dataIndex: 'elevatorAdvertsCount',
      width: 160,
      className: 'bold-cell',
    },
    {
      title: 'Локация',
      dataIndex: 'address',
    },
    actionArrowColumn,
  ]

  return (
    <Table
      className="table-interactive"
      rowKey="id"
      loading={isLoading}
      columns={columns}
      dataSource={basisList}
      scroll={{ y: tableHeight, x: 'min-content' }}
      pagination={false}
      onRow={(record, index) => ({
        onClick: onRowClickHandler.bind(null, record, index),
      })}
    />
  )
}
