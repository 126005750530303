import React, { useMemo } from 'react'

import { Flex, Tabs } from 'antd'
import { useParams } from 'react-router'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'

import { AppHeader } from '~/layout'
import { appRoutes, IAppRoute } from '~/router'
import { getTabsFromUrl } from '~/utils/getTabsFromUrl'
import { EntityModel } from '~api/gql-generated/graphql'
import { Notes } from '~pages/ModeratorPage/components/Notes/Notes'

import * as S from './ModeratorDealDetails.styled'

export const ModeratorDealDetails = () => {
  const tabs = [
    appRoutes.dealDetailsInformation,
    appRoutes.dealDetailsPayment,
    appRoutes.dealDetailsLogistics,
    appRoutes.dealDetailsDocuments,
  ]

  const { id: dealId } = useParams<{ id: string | undefined }>()
  const navigate = useNavigate()
  const { pathname } = useLocation()

  const handleTabsChange = (url: IAppRoute['url']) => {
    navigate(url)
  }

  const handleBackClick = () => {
    navigate(appRoutes.moderatorDeals.url)
  }

  const activeTab = useMemo(() => {
    return tabs.find((tab) => pathname.includes(tab.url))
  }, [pathname])

  return (
    <div>
      <AppHeader title={`${appRoutes.dealDetails.title} № ${dealId}`} isBack onBack={handleBackClick}>
        <Flex>
          <Tabs activeKey={activeTab?.url} size="small" onChange={handleTabsChange} items={getTabsFromUrl(tabs)} />
          <div style={{ marginLeft: 'auto' }}>
            <Notes entityId={Number(dealId)} relatedModel={EntityModel.Deal} />
          </div>
        </Flex>
      </AppHeader>
      <S.Content>
        <Outlet />
      </S.Content>
    </div>
  )
}
