import styled from 'styled-components'

import { css } from '@styled-system/css'

import { Box } from '~/ui-components'

export const Placeholder = styled(Box)(
  css({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    minHeight: '250px',
    gap: '16px',
    borderRadius: '16px',
    padding: '16px',
    border: '1px solid',
    borderColor: 'GreenPrimary',
    textAlign: 'center',
  })
)

export const Title = styled.div(
  css({
    color: 'TxtHeading',
    variant: 'typography.headingsH5',
  })
)

export const Description = styled.div(
  css({
    color: 'TxtPrimary',
    variant: 'typography.textSmallRegular',
  })
)
