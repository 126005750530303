import React from 'react'

import { Button, Input, Modal } from 'antd'
import { Controller, useForm } from 'react-hook-form'

import { yupResolver } from '@hookform/resolvers/yup/dist/yup'

import { FormField } from '~/ui-components'
import { modalSharedSettings } from '~/utils'

import { dealPaymentRemoveDocumentSchema } from '~pages/Deals/modules/DealPayment/components/DealPaymentRemoveModal/DealPaymentRemoveModal.validation'

import { IDealPaymentRemoveModalForm, IDealPaymentRemoveModalProps } from './DealPaymentRemoveModal.types'

const FORM_ID = 'remove-payment-doc-modal'

export const DealPaymentRemoveModal: React.FC<IDealPaymentRemoveModalProps> = (props) => {
  const {
    control,
    handleSubmit,
    formState: { errors, isDirty },
  } = useForm<IDealPaymentRemoveModalForm>({
    defaultValues: {
      message: '',
    },
    resolver: yupResolver(dealPaymentRemoveDocumentSchema),
  })

  const handleFormSubmit = (values: IDealPaymentRemoveModalForm) => {
    props.onRemove(values)
    props.onClose()
  }

  const title = props.type === 'rejectDocument' ? 'Отклонить документ' : 'Удалить документ'
  const removeMessage = props.type === 'rejectDocument' ? 'отклонения' : 'удаления'

  return (
    <Modal
      {...modalSharedSettings}
      destroyOnClose={true}
      width={undefined}
      open={props.isOpen}
      onCancel={props.onClose}
      title={title}
      footer={[
        <Button key="cancel" htmlType="button" onClick={props.onClose}>
          Отмена
        </Button>,
        <Button type="primary" key="submit" htmlType="submit" form={FORM_ID} disabled={!isDirty}>
          Отправить
        </Button>,
      ]}
    >
      <form id={FORM_ID} onSubmit={handleSubmit(handleFormSubmit)}>
        <p>Укажите причину {removeMessage} документа. Пользователю придет уведомление с текстом причины удаления.</p>

        <FormField label="Укажите причину" isRequired htmlFor="message" validateMessage={errors.message?.message}>
          <Controller
            name="message"
            control={control}
            render={({ field }) => (
              <Input.TextArea
                {...field}
                id="message"
                rows={4}
                placeholder="Не тот документ"
                maxLength={500}
                status={errors.message && 'error'}
              ></Input.TextArea>
            )}
          />
        </FormField>
      </form>
    </Modal>
  )
}
