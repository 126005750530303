import { graphql } from '~api/gql-generated'

export const freightDealUpdateStatusMutation = graphql(/* GraphQL */ `
  mutation FreightDealUpdateStatus($freightDealId: Int!, $input: FreightDealStatusUpdateInput!) {
    freightDeal(freightDealId: $freightDealId) {
      updateStatus(input: $input) {
        id
      }
    }
  }
`)
