import React from 'react'

import { Skeleton } from 'antd'
import { useParams } from 'react-router'

import { Text } from '~/ui-components'
import { getDealSide } from '~/utils/deals/get-deal-side'
import { useGetDealById } from '~hooks/deals'
import { DocumentsProduct } from '~pages/Deals/components'
import { CarrierInfoCard, LoadingInfoCard, UnloadingInfoCard } from '~pages/Deals/modules/DealLogistics/components'
import { useAuth } from '~providers/auth'

import * as S from './DealLogistics.styled'

export const DealLogistics = () => {
  const { companyId } = useAuth()
  const { id: dealIdParam } = useParams<{ id: string | undefined }>()
  const dealId = Number(dealIdParam)

  const { data: deal, isLoading: isDealLoading } = useGetDealById(dealId)

  if (isDealLoading || !companyId || !deal) {
    return <Skeleton />
  }
  const dealSide = getDealSide({ userCompanyId: companyId, buyerId: deal.buyer.id, sellerId: deal.seller.id })

  if (deal.dealWithBuyer?.status !== 'SHIPMENT' || deal.dealWithSeller?.status !== 'SHIPMENT') {
    return (
      <S.ContentWrapper>
        <Text variant="h4" style={{ textAlign: 'center' }}>
          Доступно только в статусе <b>Перевозка</b>
        </Text>
      </S.ContentWrapper>
    )
  }

  return (
    <S.ContentWrapper>
      <S.Column>
        <LoadingInfoCard dealId={dealId} isEditable={dealSide === 'seller'} />
        <UnloadingInfoCard dealId={dealId} isEditable={dealSide === 'buyer'} />
        <CarrierInfoCard dealId={dealId} dealSide={dealSide} />
      </S.Column>

      <S.Column>
        {dealId && (
          <DocumentsProduct
            dealId={dealId}
            dealSide={dealSide}
            documentType="transportation"
            title="Товаросопроводительные документы"
          />
        )}
      </S.Column>
    </S.ContentWrapper>
  )
}
