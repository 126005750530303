import { Control, DeepRequired, FieldErrorsImpl, GlobalError, IsAny } from 'react-hook-form'
import * as yup from 'yup'

import { OwnershipType, PreferredRouteType, TransportType } from '~api/gql-generated/graphql'
import { VALIDATORS_MSG } from '~shared/validations'

import { TransportDocumentsFieldsProps } from '~pages/CarParkPage/modules/Transport/Transport.types'

export const CreateTransportSchema = yup.object({
  type: yup.mixed<TransportType>().oneOf(Object.values(TransportType)).required(VALIDATORS_MSG.required),
  brand: yup.string().trim().required(VALIDATORS_MSG.required),
  bodyTypeId: yup.number().when('type', {
    is: (type: TransportType) => type === TransportType.Truck,
    then: (schema) => schema.required(VALIDATORS_MSG.required),
  }),
  manufacturingDate: yup.number().min(0, VALIDATORS_MSG.minValue(0)).nullable(),
  vin: yup.string().trim(),
  registrationCertificate: yup.string().trim(),
  transportPassport: yup.string().trim(),
  licensePlate: yup.string().trim().required(VALIDATORS_MSG.required),
  axlesCount: yup.number().required(VALIDATORS_MSG.required).min(0, VALIDATORS_MSG.minValue(0)).nullable(),
  loadCapacityKg: yup
    .number()
    .min(0, VALIDATORS_MSG.minValue(0))
    .test('maxDigitsAfterDecimal', VALIDATORS_MSG.maxDigitsAfterDecimal(3), (number) =>
      number ? /^\d+(\.\d{1,3})?$/.test(number.toString()) : true
    )
    .nullable(),
  loadVolumeL: yup
    .number()
    .min(0, VALIDATORS_MSG.minValue(0))
    .test('maxDigitsAfterDecimal', VALIDATORS_MSG.maxDigitsAfterDecimal(3), (number) =>
      number ? /^\d+(\.\d{1,3})?$/.test(number.toString()) : true
    )
    .nullable(),
  lengthCm: yup
    .number()
    .min(0, VALIDATORS_MSG.minValue(0))
    .test('maxDigitsAfterDecimal', VALIDATORS_MSG.maxDigitsAfterDecimal(2), (number) =>
      number ? /^\d+(\.\d{1,2})?$/.test(number.toString()) : true
    )
    .nullable(),
  widthCm: yup
    .number()
    .min(0, VALIDATORS_MSG.minValue(0))
    .test('maxDigitsAfterDecimal', VALIDATORS_MSG.maxDigitsAfterDecimal(2), (number) =>
      number ? /^\d+(\.\d{1,2})?$/.test(number.toString()) : true
    )
    .nullable(),
  heightCm: yup
    .number()
    .min(0, VALIDATORS_MSG.minValue(0))
    .test('maxDigitsAfterDecimal', VALIDATORS_MSG.maxDigitsAfterDecimal(2), (number) =>
      number ? /^\d+(\.\d{1,2})?$/.test(number.toString()) : true
    )
    .nullable(),
  loadingMethodId: yup.number(),
  adrCertificate: yup.string().trim(),
  shortRouteKm: yup.number().integer(VALIDATORS_MSG.integer).min(0, VALIDATORS_MSG.minValue(0)).nullable(),
  longRouteKm: yup.number().integer(VALIDATORS_MSG.integer).min(0, VALIDATORS_MSG.minValue(0)).nullable(),
  preferredRouteType: yup.mixed<PreferredRouteType>().oneOf(Object.values(PreferredRouteType)),
  canWorkInField: yup.boolean(),
  ownershipType: yup.mixed<OwnershipType>().oneOf(Object.values(OwnershipType)).required(VALIDATORS_MSG.required),
  ownerName: yup.string().when('ownershipType', {
    is: (type: OwnershipType) => type !== OwnershipType.Own,
    then: (schema) => schema.required(VALIDATORS_MSG.required),
  }),
  additionalDetails: yup.string(),
})

export type CreateTransportValues = yup.InferType<typeof CreateTransportSchema>

export type CreateTransportErrors<T extends CreateTransportValues = CreateTransportValues> = Partial<
  CreateTransportValues extends IsAny<CreateTransportValues> ? any : FieldErrorsImpl<DeepRequired<T>>
> & {
  root?: Record<string, GlobalError> & GlobalError
}

export interface CreateTransportFormFieldProps {
  control: Control<CreateTransportValues>
  errors: CreateTransportErrors
  type?: TransportType
  ownershipType?: OwnershipType
  transportPhotosProps?: TransportDocumentsFieldsProps
}
