import React, { Suspense } from 'react'

import { RouteObject, useRoutes } from 'react-router-dom'

import { wrapUseRoutes } from '@sentry/react'

import { GlobalLayout, NotImplementedYetPage } from '~/layout'
import { WelcomePageV2 } from '~layout/WelcomePageV2'
import { RequireAuth } from '~providers/auth'

import {
  accountRouter,
  auctionRouter,
  authRouter,
  basisRouter,
  carParkRouter,
  dealsRouter,
  employeesRouter,
  logisticsRouter,
  moderatorRouter,
  notificationsRouter,
  offersRouter,
  requestsRouter,
  settingsRouter,
  warehousesRouter,
} from './routers'
import { appRoutes } from './'

const useSentryRoutes = wrapUseRoutes(useRoutes)

const routes: RouteObject[] = [
  authRouter,
  {
    path: '/',
    element: <GlobalLayout />,
    children: [
      {
        path: appRoutes.welcomePage.url,
        element: (
          <RequireAuth>
            <WelcomePageV2 />
          </RequireAuth>
        ),
      },
      accountRouter,
      notificationsRouter,
      offersRouter,
      ...carParkRouter,
      warehousesRouter,
      logisticsRouter,
      employeesRouter,
      ...basisRouter,
      ...settingsRouter,
      ...dealsRouter,
      ...auctionRouter,
      ...moderatorRouter,
      ...requestsRouter,
      {
        path: '*',
        element: <NotImplementedYetPage />,
      },
    ],
  },
]

export const Router: React.FC = () => <Suspense fallback={null}>{useSentryRoutes(routes)}</Suspense>
