import React, { forwardRef, useEffect, useRef } from 'react'

import { Input, InputProps, InputRef } from 'antd'
import { AddressSuggestions, BankSuggestions, PartySuggestions } from 'react-dadata'
import type { DaDataAddress, DaDataBank, DaDataParty, DaDataSuggestion } from 'react-dadata/dist/types'

import { DADATA_TOKEN } from './InputDadata.constants'

import type { InputDadataProps } from '~ui-components/components/InputDadata/InputDadata.types'

import { DadataStyles } from './InputDadata.styled'

type SuggestionType = DaDataBank | DaDataParty | DaDataAddress

const components = {
  organizations: PartySuggestions,
  address: AddressSuggestions,
  banks: BankSuggestions,
}

// eslint-disable-next-line react/display-name
const CustomInput = forwardRef<InputRef, InputProps>((props, ref) => (
  <Input ref={ref} width="100%" style={{ maxWidth: '342px' }} {...props} />
))
/**
 * Controlled input with autocomplete suggestions
 */

export const InputDadata: React.FC<InputDadataProps> = ({
  value,
  inputValue,
  onSelect,
  onChangeInput,
  onBlurInput,
  variant = 'organizations',
  defaultQuery,
  id,
  inputStatus,
  placeholder,
  disabled = false,
  selectOnBlur = false,
}) => {
  const suggestionsRef = useRef<BankSuggestions>(null)

  useEffect(() => {
    suggestionsRef?.current?.setInputValue(inputValue ?? undefined)
  }, [inputValue])

  const InputComponent = components[variant]

  const handleSelect = (val: DaDataSuggestion<SuggestionType> | undefined): void => {
    onSelect?.(val as never)
  }

  // https://confluence.hflabs.ru/pages/viewpage.action?pageId=1023737939
  const locations = [
    {
      country_iso_code: 'RU', // RUSSIA
    },
    {
      country_iso_code: 'BY', // BELARUS
    },
    {
      country_iso_code: 'AM', // ARMENIA
    },
    {
      country_iso_code: 'BR', // BRAZIL
    },
    {
      country_iso_code: 'CN', // CHINA
    },
    {
      country_iso_code: 'CU', // CUBA
    },
    {
      country_iso_code: 'IN', // INDIA
    },
    {
      country_iso_code: 'KZ', // KAZAKHSTAN
    },
    {
      country_iso_code: 'KG', // KYRGYZSTAN
    },
    {
      country_iso_code: 'MD', // MOLDOVA
    },
    {
      country_iso_code: 'ZA', // SOUTH AFRICA
    },
    {
      country_iso_code: 'TJ', // TAJIKISTAN
    },
    {
      country_iso_code: 'UZ', // UZBEKISTAN
    },
  ]

  return (
    <>
      <DadataStyles />

      <InputComponent
        filterLocations={locations}
        ref={suggestionsRef}
        inputProps={{
          //@ts-ignore
          status: inputStatus,
          placeholder: placeholder ?? '',
          autoComplete: 'new-password',
          disabled,
          onChange: (e) => {
            const inputValue = (e?.target as HTMLInputElement)?.value
            onChangeInput?.(inputValue)
          },
          onBlur: onBlurInput,
          id,
        }}
        defaultQuery={defaultQuery}
        token={DADATA_TOKEN}
        value={value}
        minChars={3}
        onChange={handleSelect}
        count={5}
        delay={700}
        customInput={CustomInput}
        selectOnBlur={selectOnBlur}
      />
    </>
  )
}
