import { Tag } from 'antd'
import styled from 'styled-components'

export const ActiveFiltersTagsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
`

export const StyledTag = styled(Tag)`
  background: white;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  padding: 4px 12px;
  color: #424242;

  .ant-tag-close-icon {
    font-size: 20px;
  }
`
