/* tslint:disable */
/* eslint-disable */
/**
 * API Зерно-Агрегатора
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { GetProductTypesResponse } from '../models';
// @ts-ignore
import { GetPublishedProductTypesResponse } from '../models';
// @ts-ignore
import { GetRegionsResponse } from '../models';
// @ts-ignore
import { UpdateProductTypeParameterBody } from '../models';
// @ts-ignore
import { UpdateProductTypePictureBody } from '../models';
/**
 * ProductApi - axios parameter creator
 * @export
 */
export const ProductApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Получить список товаров с опубликованными предложениями
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPublishedProductTypes: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/product-types/published`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получить список регионов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRegions: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/regions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productControllerGetProductTypes: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/product-types`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} productType 
         * @param {string} parameterType 
         * @param {UpdateProductTypeParameterBody} updateProductTypeParameterBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productControllerUpdateProductTypeParameter: async (productType: string, parameterType: string, updateProductTypeParameterBody: UpdateProductTypeParameterBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'productType' is not null or undefined
            assertParamExists('productControllerUpdateProductTypeParameter', 'productType', productType)
            // verify required parameter 'parameterType' is not null or undefined
            assertParamExists('productControllerUpdateProductTypeParameter', 'parameterType', parameterType)
            // verify required parameter 'updateProductTypeParameterBody' is not null or undefined
            assertParamExists('productControllerUpdateProductTypeParameter', 'updateProductTypeParameterBody', updateProductTypeParameterBody)
            const localVarPath = `/product-type/{productType}/parameter/{parameterType}`
                .replace(`{${"productType"}}`, encodeURIComponent(String(productType)))
                .replace(`{${"parameterType"}}`, encodeURIComponent(String(parameterType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateProductTypeParameterBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Обновить изображение товара
         * @param {string} productType 
         * @param {UpdateProductTypePictureBody} updateProductTypePictureBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProductTypePicture: async (productType: string, updateProductTypePictureBody: UpdateProductTypePictureBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'productType' is not null or undefined
            assertParamExists('updateProductTypePicture', 'productType', productType)
            // verify required parameter 'updateProductTypePictureBody' is not null or undefined
            assertParamExists('updateProductTypePicture', 'updateProductTypePictureBody', updateProductTypePictureBody)
            const localVarPath = `/product-type/{productType}/picture`
                .replace(`{${"productType"}}`, encodeURIComponent(String(productType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateProductTypePictureBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProductApi - functional programming interface
 * @export
 */
export const ProductApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProductApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Получить список товаров с опубликованными предложениями
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPublishedProductTypes(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetPublishedProductTypesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPublishedProductTypes(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Получить список регионов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRegions(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetRegionsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRegions(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productControllerGetProductTypes(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetProductTypesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productControllerGetProductTypes(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} productType 
         * @param {string} parameterType 
         * @param {UpdateProductTypeParameterBody} updateProductTypeParameterBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productControllerUpdateProductTypeParameter(productType: string, parameterType: string, updateProductTypeParameterBody: UpdateProductTypeParameterBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productControllerUpdateProductTypeParameter(productType, parameterType, updateProductTypeParameterBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Обновить изображение товара
         * @param {string} productType 
         * @param {UpdateProductTypePictureBody} updateProductTypePictureBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateProductTypePicture(productType: string, updateProductTypePictureBody: UpdateProductTypePictureBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateProductTypePicture(productType, updateProductTypePictureBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ProductApi - factory interface
 * @export
 */
export const ProductApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProductApiFp(configuration)
    return {
        /**
         * 
         * @summary Получить список товаров с опубликованными предложениями
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPublishedProductTypes(options?: any): AxiosPromise<GetPublishedProductTypesResponse> {
            return localVarFp.getPublishedProductTypes(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получить список регионов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRegions(options?: any): AxiosPromise<GetRegionsResponse> {
            return localVarFp.getRegions(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productControllerGetProductTypes(options?: any): AxiosPromise<GetProductTypesResponse> {
            return localVarFp.productControllerGetProductTypes(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} productType 
         * @param {string} parameterType 
         * @param {UpdateProductTypeParameterBody} updateProductTypeParameterBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productControllerUpdateProductTypeParameter(productType: string, parameterType: string, updateProductTypeParameterBody: UpdateProductTypeParameterBody, options?: any): AxiosPromise<void> {
            return localVarFp.productControllerUpdateProductTypeParameter(productType, parameterType, updateProductTypeParameterBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Обновить изображение товара
         * @param {string} productType 
         * @param {UpdateProductTypePictureBody} updateProductTypePictureBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProductTypePicture(productType: string, updateProductTypePictureBody: UpdateProductTypePictureBody, options?: any): AxiosPromise<void> {
            return localVarFp.updateProductTypePicture(productType, updateProductTypePictureBody, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for productControllerUpdateProductTypeParameter operation in ProductApi.
 * @export
 * @interface ProductApiProductControllerUpdateProductTypeParameterRequest
 */
export interface ProductApiProductControllerUpdateProductTypeParameterRequest {
    /**
     * 
     * @type {string}
     * @memberof ProductApiProductControllerUpdateProductTypeParameter
     */
    readonly productType: string

    /**
     * 
     * @type {string}
     * @memberof ProductApiProductControllerUpdateProductTypeParameter
     */
    readonly parameterType: string

    /**
     * 
     * @type {UpdateProductTypeParameterBody}
     * @memberof ProductApiProductControllerUpdateProductTypeParameter
     */
    readonly updateProductTypeParameterBody: UpdateProductTypeParameterBody
}

/**
 * Request parameters for updateProductTypePicture operation in ProductApi.
 * @export
 * @interface ProductApiUpdateProductTypePictureRequest
 */
export interface ProductApiUpdateProductTypePictureRequest {
    /**
     * 
     * @type {string}
     * @memberof ProductApiUpdateProductTypePicture
     */
    readonly productType: string

    /**
     * 
     * @type {UpdateProductTypePictureBody}
     * @memberof ProductApiUpdateProductTypePicture
     */
    readonly updateProductTypePictureBody: UpdateProductTypePictureBody
}

/**
 * ProductApi - object-oriented interface
 * @export
 * @class ProductApi
 * @extends {BaseAPI}
 */
export class ProductApi extends BaseAPI {
    /**
     * 
     * @summary Получить список товаров с опубликованными предложениями
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductApi
     */
    public getPublishedProductTypes(options?: AxiosRequestConfig) {
        return ProductApiFp(this.configuration).getPublishedProductTypes(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получить список регионов
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductApi
     */
    public getRegions(options?: AxiosRequestConfig) {
        return ProductApiFp(this.configuration).getRegions(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductApi
     */
    public productControllerGetProductTypes(options?: AxiosRequestConfig) {
        return ProductApiFp(this.configuration).productControllerGetProductTypes(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProductApiProductControllerUpdateProductTypeParameterRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductApi
     */
    public productControllerUpdateProductTypeParameter(requestParameters: ProductApiProductControllerUpdateProductTypeParameterRequest, options?: AxiosRequestConfig) {
        return ProductApiFp(this.configuration).productControllerUpdateProductTypeParameter(requestParameters.productType, requestParameters.parameterType, requestParameters.updateProductTypeParameterBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Обновить изображение товара
     * @param {ProductApiUpdateProductTypePictureRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductApi
     */
    public updateProductTypePicture(requestParameters: ProductApiUpdateProductTypePictureRequest, options?: AxiosRequestConfig) {
        return ProductApiFp(this.configuration).updateProductTypePicture(requestParameters.productType, requestParameters.updateProductTypePictureBody, options).then((request) => request(this.axios, this.basePath));
    }
}
