import { addHours, isAfter } from 'date-fns'

import { AppConfig } from '~/appConfig'

export function isOfferExpireSoon(expiredAtTime: Date): boolean {
  const isExpired = isAfter(new Date(), expiredAtTime)
  if (isExpired) {
    return false
  }
  const alertTime = addHours(new Date(), AppConfig.OFFER_EXPIRATION_ALERT_HOURS)

  return isAfter(alertTime, expiredAtTime)
}
