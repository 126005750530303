import { useQuery, UseQueryResult } from '@tanstack/react-query'

import { AppConfig } from '~/appConfig'
import { NotificationData } from '~api/generated'
import { useApi } from '~providers/api'
import { useAuth } from '~providers/auth'

interface IGetUnreadNotificationsByInterval {
  lastUnreadNotification: NotificationData | null
  totalUnreadNotifications: number
}

export const useGetUnreadNotificationsByInterval = (): UseQueryResult<IGetUnreadNotificationsByInterval> => {
  const api = useApi()
  const { authenticated } = useAuth()

  const fetchFn = async (): Promise<IGetUnreadNotificationsByInterval> => {
    const res = await api.notifications.notificationControllerGetNotifications({
      isViewed: false,
      pageSize: 1,
      currentPage: 1,
    })

    const lastUnreadNotification = res.data.notifications?.length ? res.data.notifications[0] : null
    const totalUnreadNotifications = res.data.pagination.total

    return {
      lastUnreadNotification,
      totalUnreadNotifications,
    }
  }

  return useQuery(['unreadNotifications'], fetchFn, {
    refetchInterval: AppConfig.REFETCH_UNREAD_NOTIFICATION_INTERVAL,
    staleTime: Infinity,
    enabled: authenticated,
  })
}
