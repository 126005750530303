import React from 'react'

import { Button, Space } from 'antd'
import { FiX } from 'react-icons/fi'
import { useNavigate } from 'react-router-dom'

import { Text } from '~/ui-components'
import { BackButton } from '~shared/components'

import * as S from './ResponsePageHeader.styled'

interface ResponsePageHeaderViewProps {
  variant: 'view'
  title: string
  subTitle: string
  onBack?: () => void
  rightElement?: React.ReactElement
}

interface ResponsePageHeaderEditProps {
  variant: 'edit'
  title: string
  titleMobile: string
  subTitle: string
  loading: boolean
  onSubmit: () => void
  onBack: () => void
}
type ResponsePageHeaderProps = ResponsePageHeaderViewProps | ResponsePageHeaderEditProps

export const ResponsePageHeader: React.FC<ResponsePageHeaderProps> = (props) => {
  const navigate = useNavigate()

  const onBackHandler = () => {
    if (props.onBack) {
      props.onBack()
      return
    }
    navigate(-1)
  }

  if (props.variant === 'view') {
    return (
      <>
        <S.HeaderWrapperDesktop>
          <S.TitleWrapper>
            <S.ViewTitle>{props.title}</S.ViewTitle>
            <Text variant="description">{props.subTitle}</Text>
          </S.TitleWrapper>
          {props.rightElement}
        </S.HeaderWrapperDesktop>

        <S.HeaderWrapperMobile>
          <Space style={{ justifyContent: 'space-between' }}>
            <>
              <BackButton isShowText={false} onClick={onBackHandler} />
              {props.rightElement}
            </>
          </Space>
          <S.ViewTitle>{props.title}</S.ViewTitle>
        </S.HeaderWrapperMobile>
      </>
    )
  }

  if (props.variant === 'edit') {
    return (
      <>
        <S.HeaderWrapperEditDesktop>
          <BackButton onClick={onBackHandler} />
          <S.EditTitle>{props.title}</S.EditTitle>
        </S.HeaderWrapperEditDesktop>

        <S.HeaderWrapperMobileEdit>
          <Button type="text" icon={<FiX color="black" size={14} />} onClick={onBackHandler} />
          <S.EditTitle>{props.titleMobile}</S.EditTitle>
          <Button type="link" htmlType="button" loading={props.loading} onClick={props.onSubmit}>
            Готово
          </Button>
        </S.HeaderWrapperMobileEdit>
      </>
    )
  }

  return null
}
