import React from 'react'

import { Select } from 'antd'
import { SizeType } from 'antd/es/config-provider/SizeContext'
import { BaseSelectRef } from 'rc-select'

import { WarehouseData } from '~api/generated'
import { useGetWarehouses } from '~hooks/warehouses'
import { useAuth } from '~providers/auth'
import { CustomControlInterface } from '~shared/controls/CustomControl.interface'

export interface WarehouseSelectControlValue {
  label: WarehouseData['name']
  value: WarehouseData['id']
  data: WarehouseData
}

interface WarehouseSelectControlProps extends CustomControlInterface {
  onChange: (value: WarehouseSelectControlValue['data'] | undefined) => void
  value: WarehouseSelectControlValue['value'] | undefined
  size?: SizeType
  hasError?: boolean
  id?: string
  placeholder?: string
}

export const WarehouseSelectControl: React.FC<WarehouseSelectControlProps> = React.forwardRef<
  BaseSelectRef,
  WarehouseSelectControlProps
>(({ id, value, onChange, hasError, size }, ref) => {
  const { companyId } = useAuth()
  const warehouses = useGetWarehouses(companyId)

  const dropdownOptions =
    warehouses.data?.map((x) => ({
      value: x.id,
      label: `${x.name ? x.name.concat(' / ') : ''}${x.address}`,
      data: x,
    })) ?? []

  return (
    <>
      <Select
        style={{ width: '100%', maxWidth: '342px' }}
        id={id}
        value={value}
        loading={warehouses.isLoading || warehouses.isFetching}
        onChange={(value) => {
          const originalItem = dropdownOptions.find((option) => option.value === value)
          originalItem && onChange(originalItem.data)
        }}
        placeholder="Выберите склад"
        size={size}
        status={hasError ? 'error' : undefined}
      >
        {dropdownOptions.map((option) => (
          <Select.Option key={option.value} value={option.value}>
            {option.label}
          </Select.Option>
        ))}
      </Select>
    </>
  )
})

WarehouseSelectControl.displayName = 'WarehouseSelectControl'
