import React from 'react'

import { Table } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import { ColumnType } from 'antd/lib/table/interface'

import { useGetTableHeight } from '~/hooks'
import { Badge } from '~/ui-components'
import { getElevatorAdvertOfferStatus } from '~/utils/elevator-advert-offers/get-elevator-advert-offer-status'
import { getElevatorAdvertType } from '~/utils/elevator-adverts/get-elevator-advert-type'
import { getDateFormat } from '~/utils/getDateFormat'
import { MyElevatorAdvertOfferData } from '~api/generated'
import { CompanyType } from '~api/gql-generated/graphql'
import { actionArrowColumn, ndsColumn, priceColumn, volumeColumn } from '~shared/columns/shared'

import { MyAdvertOffersListProps } from '~pages/BasisPage/modules/MyAdvertOffers/MyAdvertOffers.types'

export const MyAdvertOffersListTable: React.FC<MyAdvertOffersListProps> = ({
  offers,
  isLoading,
  onOfferClick,
  currentCompanyRole,
}) => {
  const tableHeight = useGetTableHeight(294)

  const onRowClickHandler = (record: MyElevatorAdvertOfferData) => {
    onOfferClick(record.id)
  }

  const columns: ColumnsType<MyElevatorAdvertOfferData> = [
    {
      title: '№',
      dataIndex: 'id',
      width: 60,
    },
    {
      title: 'Тип объявления',
      key: currentCompanyRole === CompanyType.Trader ? 'advert-type' : 'hidden',
      dataIndex: 'elevatorOfferType',
      render: (v: MyElevatorAdvertOfferData['elevatorOfferType']) => {
        const type = getElevatorAdvertType(v)
        return (
          <Badge color={type.color} size="s">
            {type.name}
          </Badge>
        )
      },
    },
    {
      title: 'Статус',
      dataIndex: 'status',
      render: (status: MyElevatorAdvertOfferData['status']) =>
        status ? (
          <Badge color={getElevatorAdvertOfferStatus(status)?.color} size="s">
            {getElevatorAdvertOfferStatus(status)?.name}
          </Badge>
        ) : null,
    },
    {
      title: 'Культура',
      dataIndex: ['product', 'name'],
      className: 'bold-cell',
    },
    priceColumn,
    ndsColumn,
    volumeColumn,
    {
      title: 'Базис',
      dataIndex: ['elevator', 'name'],
    },
    {
      title: 'Дата создания',
      dataIndex: 'publishedAt',
      key: 'publishedAt',
      width: '160px',
      render: (volume: string) => {
        return getDateFormat(volume)
      },
    },
    actionArrowColumn,
  ].filter((x: ColumnType<MyElevatorAdvertOfferData>) => x.key !== 'hidden')

  return (
    <Table
      className="table-interactive"
      rowKey="id"
      loading={isLoading}
      columns={columns}
      dataSource={offers}
      scroll={{ y: tableHeight, x: 'min-content' }}
      pagination={false}
      onRow={(record, index) => ({
        onClick: onRowClickHandler.bind(null, record, index),
      })}
    />
  )
}
