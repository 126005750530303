import styled from 'styled-components'

import { css } from '@styled-system/css'

export const AppWrapper = styled.div(
  css({
    display: 'grid',
    gridTemplateColumns: ['100%', '100%', 'min-content 1fr'],
    height: '100%',
  })
)

export const ContentWrapper = styled.main({
  flex: 1,
  overflowY: 'auto',
  display: 'flex',
  flexDirection: 'column',
})
