import React from 'react'

import { useAuth } from '~providers/auth'

import { AccountTypeSection, BankInfoSection, CompanyInfoSection, EdoSection } from './components'

import * as S from './CompanyProfileTab.styled'

interface ICompanyProfileTabProps {
  companyId?: number
  isAdmin?: boolean
}

export const CompanyProfileTab: React.FC<ICompanyProfileTabProps> = (props) => {
  const { companyId: userCompanyId } = useAuth()

  const companyId: number | null = props.companyId ? props.companyId : userCompanyId

  return (
    <S.ContentWrapper>
      <S.Section>
        <CompanyInfoSection companyId={companyId} hasChangeStatusSelect={props.isAdmin ?? false} />
      </S.Section>
      {companyId && (
        <>
          <S.Section>
            <AccountTypeSection companyId={companyId} isAdmin={props.isAdmin ?? false} />
          </S.Section>

          <S.Section>
            <EdoSection companyId={companyId} />
          </S.Section>

          <BankInfoSection companyId={companyId} />
        </>
      )}
    </S.ContentWrapper>
  )
}
