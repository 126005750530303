import React from 'react'

import { Input } from 'antd'
import { isBefore, sub } from 'date-fns'
import { Controller, useForm } from 'react-hook-form'

import { yupResolver } from '@hookform/resolvers/yup'

import { DatePicker, FormField } from '~/ui-components'
import { OrganizationControlAutocomplete, PhoneNumberControl } from '~shared/controls'

import { carrierInfoCardFormSchema } from './CarrierInfoCardFormSchema'

import { DealCarrierInfoCardForm, DealCarrierInfoCardFormValues } from './CarrierInfoCardForm.types'

export const CarrierInfoCardForm: React.FC<DealCarrierInfoCardForm> = ({ defaultValues, onSubmit, formId }) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<DealCarrierInfoCardFormValues>({
    defaultValues: {
      contactUser: defaultValues?.contactUser ?? '',
      contactPhone: defaultValues?.contactPhone ?? '',
      organization: defaultValues?.organization ?? '',
      dateFirstCar: defaultValues?.dateFirstCar ?? null,
      dateOfPerformance: defaultValues?.dateOfPerformance ?? null,
    },
    resolver: yupResolver(carrierInfoCardFormSchema),
  })

  return (
    <form id={formId} onSubmit={handleSubmit(onSubmit)}>
      <FormField htmlFor="organization" label="Грузоотправитель" validateMessage={errors.organization?.message}>
        <Controller
          name="organization"
          control={control}
          render={({ field }) => (
            <OrganizationControlAutocomplete
              id="organization"
              {...field}
              hasError={!!errors.organization}
              onSelect={(x) => field.onChange(x?.value)}
            />
          )}
        />
      </FormField>

      <FormField
        label="Ответственный за перевозку"
        htmlFor="contactUser"
        validateMessage={errors.contactUser?.message}
        isRequired
      >
        <Controller
          name="contactUser"
          control={control}
          render={({ field }) => (
            <Input
              id="contactUser"
              placeholder="Введите фамилию и имя"
              {...field}
              status={errors.contactUser && 'error'}
            />
          )}
        />
      </FormField>

      <FormField
        validateMessage={errors.contactPhone?.message}
        label="Телефон ответственного за перевозку "
        htmlFor="contactPhone"
        isRequired
      >
        <Controller
          name="contactPhone"
          control={control}
          render={({ field }) => <PhoneNumberControl {...field} id="contactPhone" hasError={!!errors.contactPhone} />}
        />
      </FormField>

      <FormField label="Дата прибытия первой машины для погрузки" validateMessage={errors.dateFirstCar?.message}>
        <Controller
          control={control}
          name="dateFirstCar"
          render={({ field }) => (
            <DatePicker {...field} disabledDate={(current) => isBefore(current, sub(new Date(), { days: 1 }))} />
          )}
        />
      </FormField>

      <FormField label="Срок исполнения перевозки" validateMessage={errors.dateOfPerformance?.message}>
        <Controller
          control={control}
          name="dateOfPerformance"
          render={({ field }) => (
            <DatePicker {...field} disabledDate={(current) => isBefore(current, sub(new Date(), { days: 1 }))} />
          )}
        />
      </FormField>
    </form>
  )
}
