import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { breakpoints } from '~/tokens'

export const FiltersWrapper = styled.div`
  display: flex;
  gap: 16px;
  justify-content: space-between;

  @media (max-width: ${breakpoints.DESKTOP_S}px) {
    flex-direction: column-reverse;
  }
`

export const VipCard = styled(Link)`
  display: flex;
  justify-content: space-between;
  padding: 16px;
  gap: 10px;

  border-radius: 8px;
  border: 2px solid #45a67d;

  background: rgba(69, 166, 125, 0.05) !important;
  max-width: 390px;
  cursor: pointer;
  margin-bottom: 16px;
`

export const Counter = styled.div`
  color: #45a67d;
  text-align: right;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
`

export const GapResource = styled.div`
  color: #757575;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
`
