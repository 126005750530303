import { useQuery, UseQueryResult } from '@tanstack/react-query'

import { GetComplexDealResponse } from '~/api/generated'
import { useApi } from '~/providers/api'
import { httpErrorHandler } from '~/utils'

export const useGetModeratorDeal = (dealId: number): UseQueryResult<GetComplexDealResponse> => {
  const api = useApi()

  async function fetchDealById(dealId: number): Promise<GetComplexDealResponse | null> {
    const response = await api.deal.dealControllerGetComplexDeal({ complexDealId: dealId })

    return response.data ?? null
  }

  const fetchFn = async (): Promise<GetComplexDealResponse | null> => {
    try {
      return await fetchDealById(dealId)
    } catch (e) {
      httpErrorHandler(e, 'Ошибка при загрузке комплексной сделки')
      return null
    }
  }

  return useQuery(['fetchModeratorDeal', dealId], fetchFn, { staleTime: 1000 * 5 })
}
