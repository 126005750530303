import {
  format,
  formatDuration,
  intervalToDuration,
  isAfter,
  isDate,
  isToday,
  isTomorrow,
  isYesterday,
  parse,
} from 'date-fns'
import { ru } from 'date-fns/locale'

const DATE_FORMAT = 'dd.MM.yyyy'
const TIME_FORMAT = 'HH:mm'
const TIME_FORMAT_PARSE = 'HH:mm:ss'

// // Date => 'dd.MM.yyyy'
export const getDateFormat = (date: string | Date) => format(new Date(date), DATE_FORMAT, { locale: ru })

// Date => 'HH:mm'
export const getDateTimeFormat = (date: string | Date) =>
  format(new Date(date), `${DATE_FORMAT} ${TIME_FORMAT}`, { locale: ru })

// 'HH:mm:ss' => Date
export const parseTimeToDate = (date: string): Date => parse(date, TIME_FORMAT_PARSE, new Date(), { locale: ru })

// DATE => 'HH:mm:ss'
export const transformDateToTimeString = (date: Date): typeof TIME_FORMAT_PARSE =>
  format(date, TIME_FORMAT_PARSE, { locale: ru }) as typeof TIME_FORMAT_PARSE

export const getTimeFormat = (date: string | Date) => {
  try {
    let d: Date
    if (typeof date === 'string') {
      d = parseTimeToDate(date)
    } else {
      d = date
    }

    return format(d, TIME_FORMAT, { locale: ru })
  } catch (e) {
    console.error('[getTimeFormat]:', e)
    return ''
  }
}

export const getDateTimeDayFormat = (value: string) => {
  const date = new Date(value)
  if (isToday(date)) {
    return `Сегодня в ${getTimeFormat(date)}`
  } else if (isTomorrow(date)) {
    return `Завтра в ${getTimeFormat(date)}`
  } else if (isYesterday(date)) {
    return `Вчера в ${getTimeFormat(date)}`
  }
  return format(date, `${DATE_FORMAT} ${TIME_FORMAT}`, { locale: ru })
}

export const getTimeDurationFormat = (
  startDate: number | string | Date,
  endDate: number | string | Date
): string | -1 => {
  const start = isDate(startDate) ? (startDate as Date) : new Date(startDate)
  const end = isDate(endDate) ? (endDate as Date) : new Date(endDate)

  if (isAfter(start, end)) {
    return -1
  }
  const duration = intervalToDuration({ start, end })
  return formatDuration(duration, { format: ['months', 'days', 'hours'], locale: ru })
}

export function getWorkTime(from: Date | string | null | undefined, to: Date | string | null | undefined): string {
  if (from && to) {
    return `C ${getTimeFormat(from)} до ${getTimeFormat(to)}`
  }
  if (from && !to) {
    return `C ${getTimeFormat(from)}`
  }
  if (!from && to) {
    return `До ${getTimeFormat(to)}`
  }
  return '-'
}
