import React from 'react'

import { useGetProductTypeData } from '~/hooks'
import { Badge, Card, UploadButton } from '~/ui-components'
import { getLastDealDocument } from '~/utils/deals'
import { DealSideType } from '~/utils/deals/get-deal-side'
import { formatNumber } from '~/utils/formatNumber'
import { getDateFormat } from '~/utils/getDateFormat'
import { DealDocumentDataTypeEnum, DealSpecificationData } from '~api/generated'
import { useGetDealDocuments } from '~hooks/deal-documents'
import {
  getTransportationTypeName,
  TransportationTypeControlValue,
} from '~shared/controls/TransportationTypeSelectControl'

import * as S from './SpecificationCard.styled'

interface ISpecificationCardProps {
  dealId: number
  specification: DealSpecificationData
  offerLaboratoryAnalysis?: string
  dealSide: DealSideType
}

export const SpecificationCard: React.FC<ISpecificationCardProps> = ({
  specification,
  dealId,
  offerLaboratoryAnalysis,
  dealSide,
}) => {
  const { getProductTypeParametersForUI_V2 } = useGetProductTypeData()

  const { data: dealDocuments } = useGetDealDocuments({
    dealId,
  })

  const documentsMap = new Map(dealDocuments?.map((doc) => [doc.type, doc]))

  const laboratoryAnalysis = getLastDealDocument(documentsMap.get(DealDocumentDataTypeEnum.LaboratoryAnalysis))

  const usingNds = specification.includingNds ? 'с НДС' : 'без НДС'

  const isBuyer = dealSide === 'buyer'

  return (
    <Card>
      <S.CardContent>
        <Card.Title>Карточка сделки</Card.Title>

        <S.Row>
          <Card.Label>Культура</Card.Label>
          <Card.Value>{specification.product.name}</Card.Value>
        </S.Row>

        <S.Properties>
          {getProductTypeParametersForUI_V2(specification.product).map((param) => (
            <Badge color="gray" isRound key={param}>
              {param}
            </Badge>
          ))}
        </S.Properties>

        {(laboratoryAnalysis || offerLaboratoryAnalysis) && (
          <div style={{ width: '100%' }}>
            {laboratoryAnalysis && (
              <UploadButton
                fileName="Лаб. анализ из спецификации"
                isReadOnly
                onFileClick={() => window.open(laboratoryAnalysis?.files[0].source, '_blank')}
              />
            )}

            {offerLaboratoryAnalysis && (
              <UploadButton
                fileName="Лаб. анализ из предложения"
                isReadOnly
                onFileClick={() => window.open(offerLaboratoryAnalysis, '_blank')}
              />
            )}
          </div>
        )}

        <S.DividerStyled />

        <S.Row>
          <Card.Label>Объем</Card.Label>
          <Card.Value>{specification.volume} тонн</Card.Value>
        </S.Row>

        <S.Row>
          <Card.Label>Цена за 1 тонну</Card.Label>
          <Card.Value>
            {formatNumber(specification.priceDetail.price)} ₽ ({usingNds})
          </Card.Value>
        </S.Row>
        {isBuyer && specification.priceDetail.commission && (
          <S.Row>
            <Card.Label>Вознаграждение агента</Card.Label>
            <Card.Value>
              {formatNumber(specification.priceDetail.commission)} ₽ ({usingNds})
            </Card.Value>
          </S.Row>
        )}
        {isBuyer && specification.priceDetail?.totalPrice && (
          <S.Row>
            <Card.Label>Общая стоимость продукции</Card.Label>
            <Card.Value>
              {formatNumber(specification.priceDetail.totalPrice)} ₽ ({usingNds})
            </Card.Value>
          </S.Row>
        )}
        {isBuyer && specification.priceDetail?.totalCommission && (
          <S.Row>
            <Card.Label>Общая стоимость услуг Агента</Card.Label>
            <Card.Value>
              {formatNumber(specification.priceDetail.totalCommission)} ₽ ({usingNds})
            </Card.Value>
          </S.Row>
        )}
        {isBuyer && specification.priceDetail?.totalPriceWithCommission && (
          <S.Row>
            <Card.Label>Общая стоимость сделки</Card.Label>
            <Card.Value>
              {formatNumber(specification.priceDetail.totalPriceWithCommission)} ₽ ({usingNds})
            </Card.Value>
          </S.Row>
        )}

        <S.Row>
          <Card.Label>Тип грузоперевозки</Card.Label>
          <Card.Value>
            {getTransportationTypeName(specification.transportationType as TransportationTypeControlValue)}
          </Card.Value>
        </S.Row>
        {specification.shipper && (
          <S.Row>
            <Card.Label>Грузоотправитель</Card.Label>
            <Card.Value>{specification.shipper}</Card.Value>
          </S.Row>
        )}
        {specification.loadingAddress && (
          <S.Row>
            <Card.Label>Адрес погрузки</Card.Label>
            <Card.Value>{specification.loadingAddress}</Card.Value>
          </S.Row>
        )}
        {specification.consignee && (
          <S.Row>
            <Card.Label>Грузополучатель</Card.Label>
            <Card.Value>{specification.consignee}</Card.Value>
          </S.Row>
        )}
        {specification.unloadingAddress && (
          <S.Row>
            <Card.Label>Адрес разгрузки</Card.Label>
            <Card.Value>{specification.unloadingAddress}</Card.Value>
          </S.Row>
        )}
        <S.Row>
          <Card.Label>Срок поставки</Card.Label>
          <Card.Value>{getDateFormat(new Date(specification.deliveryDate))}</Card.Value>
        </S.Row>
        <S.Row>
          <Card.Label>Способ оплаты</Card.Label>
          <Card.Value>{specification.paymentMethod}</Card.Value>
        </S.Row>
      </S.CardContent>
    </Card>
  )
}
