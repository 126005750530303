import { codeUnreachable } from '~/utils/code-unreachable'
import { FreightDealStatus } from '~api/gql-generated/graphql'

export function getFreightDealStatusName(value: FreightDealStatus, completed = false): string {
  switch (value) {
    case FreightDealStatus.Agreement:
      return completed ? 'Завершение' : 'В работе'
    case FreightDealStatus.OnModeration:
      return ''
    case FreightDealStatus.Realised:
      return 'Завершено'
    case FreightDealStatus.Rejected:
      return 'Отклонено'
    case FreightDealStatus.RejectedAfterModeration:
      return ''
    case FreightDealStatus.Shipment:
      return 'Доставка'
    default:
      return codeUnreachable(value)
  }
}
