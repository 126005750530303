import { codeUnreachable } from '~/utils/code-unreachable'
import { CountryName } from '~api/gql-generated/graphql'

export function getCountryName(userRole: CountryName): string {
  switch (userRole) {
    case CountryName.Armenia:
      return 'Армения'
    case CountryName.Belarus:
      return 'Беларусь'
    case CountryName.Brazil:
      return 'Бразилия'
    case CountryName.China:
      return 'Китай'
    case CountryName.Cuba:
      return 'Куба'
    case CountryName.India:
      return 'Индия'
    case CountryName.Kazakhstan:
      return 'Казахстан'
    case CountryName.Kyrgyzstan:
      return 'Кыргызстан'
    case CountryName.Moldova:
      return 'Молдова'
    case CountryName.Russia:
      return 'Российская Федерация'
    case CountryName.SouthAfrica:
      return 'Южная Африка'
    case CountryName.Tajikistan:
      return 'Таджикистан'
    case CountryName.Uzbekistan:
      return 'Узбекистан'
    default:
      return codeUnreachable(userRole)
  }
}
