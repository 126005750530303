import styled from 'styled-components'

export const Wrapper = styled.div`
  display: grid;
  gap: 16px;
`

export const Content = styled.div`
  display: grid;
  gap: 32px;
`
