import { DaDataAddress, DaDataSuggestion } from 'react-dadata/dist/types'

import { useQuery } from '@tanstack/react-query'

interface Result {
  suggestions: DaDataSuggestion<DaDataAddress>[]
}

export const useGetAddressDadata = (query?: string) => {
  const fetchFn = async () => {
    if (!query) return

    const response = (await (
      await fetch('https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address', {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: 'Token ' + 'd6d772cf07a8b282198d74aeb98be4cafa2567b9',
        },
        body: JSON.stringify({ query }),
      })
    ).json()) as Result

    return response?.suggestions?.[0]

    // .then((response) => response.text())
    // .then((result) => {
    //   const response = JSON.parse(result) as Result
    //   if ((response?.suggestions || []).length) {
    //     setResult(response?.suggestions[0])
    //   }
    // })
    // .catch((error) => console.log('error', error))
  }

  return useQuery(['addressDadata', query ?? ''], fetchFn, { enabled: !!query })
}
