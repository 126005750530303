/* tslint:disable */
/* eslint-disable */
/**
 * API Зерно-Агрегатора
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface UpdateDealCarrierBody
 */
export interface UpdateDealCarrierBody {
    /**
     * Перевозчик сделки
     * @type {string}
     * @memberof UpdateDealCarrierBody
     */
    'carriageBy'?: UpdateDealCarrierBodyCarriageByEnum;
    /**
     * Название компании
     * @type {string}
     * @memberof UpdateDealCarrierBody
     */
    'companyName'?: string | null;
    /**
     * Контактные данные ответственного за перевозку
     * @type {string}
     * @memberof UpdateDealCarrierBody
     */
    'contactPerson'?: string | null;
    /**
     * Номер телефона ответственного за перевозку
     * @type {string}
     * @memberof UpdateDealCarrierBody
     */
    'contactPhoneNumber'?: string | null;
    /**
     * Дата начала перевозки
     * @type {string}
     * @memberof UpdateDealCarrierBody
     */
    'startedAt'?: string | null;
    /**
     * Дата окнчания перевозки
     * @type {string}
     * @memberof UpdateDealCarrierBody
     */
    'endedAt'?: string | null;
}

export const UpdateDealCarrierBodyCarriageByEnum = {
    Buyer: 'BUYER',
    Seller: 'SELLER',
    Intermediary: 'INTERMEDIARY'
} as const;

export type UpdateDealCarrierBodyCarriageByEnum = typeof UpdateDealCarrierBodyCarriageByEnum[keyof typeof UpdateDealCarrierBodyCarriageByEnum];


