import * as yup from 'yup'

import { TransportationArea, TransportMode } from '~api/gql-generated/graphql'
import { VALIDATORS_MSG } from '~shared/validations'

export const CreateTransportationDataSchema = yup.object({
  transportModes: yup.array().of(yup.mixed<TransportMode>().defined(VALIDATORS_MSG.defined)),
  transportationArea: yup.mixed<TransportationArea>().oneOf(Object.values(TransportationArea)),
  regions: yup.array().of(yup.mixed<number>().defined(VALIDATORS_MSG.defined)),
  address: yup.string().trim().required(VALIDATORS_MSG.required),
})

export type CreateTransportationDataValues = yup.InferType<typeof CreateTransportationDataSchema>
