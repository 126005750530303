import React from 'react'

import { Spin } from 'antd'

import * as S from './OfferDetailsSpin.styled'

export const OfferDetailsSpin = () => {
  return (
    <S.SpinWrapper>
      <Spin />
    </S.SpinWrapper>
  )
}
