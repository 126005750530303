export function getAbbrCompany(companyName?: string | null): string {
  if (!companyName) {
    return ''
  }
  const splittedName = companyName.split(' ')

  if (splittedName[0].includes('ООО') || splittedName[0].includes('ИП')) {
    return splittedName
      .slice(1)
      .map((x) => (x[0] === '"' ? x[1] : x[0]))
      .join(' ')
      .toUpperCase()
  }

  return companyName
    .split(' ')
    .map((x) => (x[0] === '"' ? x[1] : x[0]))
    .join(' ')
    .toUpperCase()
}
