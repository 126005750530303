import { useQuery, UseQueryResult } from '@tanstack/react-query'

import {
  AdminGetElevatorAdvertsResponse,
  ElevatorAdvertApiAdminElevatorAdvertControllerAdminGetElevatorAdvertsRequest,
} from '~api/generated'
import { useApi } from '~providers/api'

export const useGetElevatorAdvertsAdmin = (
  request: ElevatorAdvertApiAdminElevatorAdvertControllerAdminGetElevatorAdvertsRequest
): UseQueryResult<AdminGetElevatorAdvertsResponse> => {
  const api = useApi()

  const fetchFn = async (): Promise<AdminGetElevatorAdvertsResponse> => {
    const res = await api.elevatorAdvert.adminElevatorAdvertControllerAdminGetElevatorAdverts(request)

    return res.data
  }

  return useQuery(['elevatorAdvertsAdmin', request], fetchFn)
}
