import { useQuery, UseQueryOptions } from '@tanstack/react-query'

import { CompanyAuctionData } from '~/api/generated'
import { useApi } from '~/providers/api'
import { isApiResponseEmpty } from '~/utils/isApiResponseEmpty'

type Result = CompanyAuctionData | null

export const useGetMyAuctionById = (
  companyId: number | null,
  id: number | undefined,
  options?: Omit<UseQueryOptions<Result, Error>, 'queryKey' | 'queryFn'>
) => {
  const api = useApi()

  const fetchFn = async (): Promise<Result> => {
    if (!id || !companyId) return null

    try {
      return (
        (await api.auction.auctionControllerGetCompanyAuctions({ companyId, auctionIds: [id] })).data
          .companyAuctions?.[0] ?? null
      )
    } catch (error) {
      if (isApiResponseEmpty(error)) {
        return null
      }

      return Promise.reject(error)
    }
  }

  return useQuery(['auction', id], fetchFn, options)
}
