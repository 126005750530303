import React from 'react'

import { Badge } from '~/ui-components'
import { getCompanyStatusColor } from '~/utils/company/get-company-status-color'
import { getCompanyStatusName } from '~/utils/company/get-company-status-name'
import { CompanyVerificationStatus } from '~api/generated'

import { BadgeVariant } from '~ui-components/components/Badge/Badge.types'

interface ICompanyStatusBadgeProps {
  status?: string
}

export const CompanyStatusBadge: React.FC<ICompanyStatusBadgeProps> = ({ status }) => {
  if (!status?.trim()) {
    return <span>-</span>
  }

  const companyStatus = status as CompanyVerificationStatus

  return (
    <Badge color={getCompanyStatusColor(companyStatus) as BadgeVariant}>{getCompanyStatusName(companyStatus)}</Badge>
  )
}
