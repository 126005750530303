import { useQuery, UseQueryResult } from '@tanstack/react-query'

import { GetAcceptedOffersResponse, OfferApiOfferControllerGetAcceptedOffersRequest } from '~/api/generated'
import { useApi } from '~/providers/api'
import { isApiResponseEmpty } from '~/utils/isApiResponseEmpty'

export const useGetAcceptedOffers = (
  request: OfferApiOfferControllerGetAcceptedOffersRequest
): UseQueryResult<GetAcceptedOffersResponse | null> => {
  const api = useApi()

  const fetchFn = async () => {
    try {
      const response = await api.offers.offerControllerGetAcceptedOffers(request)

      return response.data
    } catch (error) {
      if (isApiResponseEmpty(error)) {
        return null
      }

      return Promise.reject(error)
    }
  }

  return useQuery(['acceptedOffers', request.currentPage, request.pageSize], fetchFn, { keepPreviousData: true })
}
