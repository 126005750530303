import React, { FC } from 'react'

import { Modal, Skeleton } from 'antd'
import { observer } from 'mobx-react-lite'

import { useQuery } from '@apollo/client'

import { userUiSettingsStore } from '~/store/userUiSettingsStore'
import { Card, Text } from '~/ui-components'
import { appToast } from '~/utils'
import { getDocumentInfo } from '~/utils/profile'
import { CompanyApiUpdateDocumentRequest, DocumentPartStatus } from '~api/generated'
import { getCompanyVerificationRequirements } from '~api/gql-query/me.query.graphql'
import {
  useGetCompanyDocumentList,
  useGetCompanyDocumentsArchiveUrl,
  useRemoveCompanyDocument,
  useUpdateDocumentStatus,
  useUploadCompanyDocument,
  useUploadCompanyDocumentsArchive,
} from '~hooks/companyDocuments'
import { useAuth } from '~providers/auth'

import { UploadedDocumentFile } from './models/documentsView.model'
import { UploadAllDocumentsButton } from './components'
import { DocumentsCardView, DocumentsTableView } from './views'

import * as S from './DocumentsProfileTab.styled'

interface IDocumentsProfileTabProps {
  companyId?: number | null
}

export const DocumentsProfileTab: FC<IDocumentsProfileTabProps> = observer((props) => {
  const { isDemoUser, companyId: currentUserCompanyId } = useAuth()
  const companyId = props.companyId ?? currentUserCompanyId

  const companiesVerificationRequirements = useQuery(getCompanyVerificationRequirements)

  const companyDocumentList = useGetCompanyDocumentList(companyId)
  const uploadDocument = useUploadCompanyDocument()
  const removeDocument = useRemoveCompanyDocument()
  const uploadCompanyDocumentsArchive = useUploadCompanyDocumentsArchive()
  const companyDocumentsArchiveUrl = useGetCompanyDocumentsArchiveUrl(companyId)
  const updateDocumentStatus = useUpdateDocumentStatus()

  const handleShowFile = (url: string) => window.open(url, '_blank')
  const handleRemoveDocumentConfirmation = (documentType: string) => showRemoveDocumentConfirm(documentType)
  const handleDocumentChangeStatus = async (documentType: string, status: DocumentPartStatus) => {
    if (!companyId) {
      return
    }
    try {
      await updateDocumentStatus.mutateAsync({
        companyId,
        documentType: documentType as CompanyApiUpdateDocumentRequest['documentType'],
        updateDocumentStatusBody: {
          status,
        },
      })
      appToast.success({ description: 'Статус документа изменен' })
    } catch (e) {
      const description = 'Ошибка при изменении статуса документа'
      console.error(description, e)
      appToast.error({ description })
    }
    await companyDocumentList.refetch()
    await companiesVerificationRequirements.refetch()
  }

  const handleDownloadArchive = (url: string) => {
    const anchor = document.createElement('a')
    anchor.href = url
    anchor.download = 'Архив_документов_компании'
    document.body.appendChild(anchor)
    anchor.click()
    document.body.removeChild(anchor)
  }

  const handleRemoveArchive = () => {
    Modal.confirm({
      title: 'Вы действительно хотите удалить загруженные документы?',
      content: 'Архив с ранее загруженными документами будет удален',
      okText: 'Удалить',
      cancelText: 'Отмена',
      async onOk() {
        await handleRemoveDocument('all_documents')
        await companyDocumentsArchiveUrl.refetch()
        await companiesVerificationRequirements.refetch()
      },
    })
  }

  const handleUploadFile = async ({ file, documentType }: UploadedDocumentFile) => {
    if (isDemoUser) {
      userUiSettingsStore.showDemoUserAuthModal()
      return
    }

    if (!companyId) {
      return
    }
    try {
      await uploadDocument.mutateAsync({
        companyId,
        documentType: documentType as CompanyApiUpdateDocumentRequest['documentType'],
        companyDocument: file,
      })
      appToast.success({ description: 'Документ загружен' })
    } catch (e) {
      const description = 'Ошибка при загрузке документа'
      console.error(description, e)
      appToast.error({ description })
    }
    await companyDocumentList.refetch()
    await companiesVerificationRequirements.refetch()
  }

  const handleUploadDocumentsInArchive = async (file: File) => {
    if (isDemoUser) {
      userUiSettingsStore.showDemoUserAuthModal()
      return
    }

    if (!companyId) {
      return
    }

    try {
      await uploadCompanyDocumentsArchive.mutateAsync({
        companyId,
        documentsArchive: file,
      })
      appToast.success({ description: 'Архив с документами загружен' })
    } catch (e) {
      const description = 'Ошибка при загрузке архива с документами'
      console.error(description, e)
      appToast.error({ description })
    }
    await companyDocumentList.refetch()
    await companyDocumentsArchiveUrl.refetch()
    await companiesVerificationRequirements.refetch()
  }

  const handleRemoveDocument = async (documentType: string) => {
    if (isDemoUser) {
      userUiSettingsStore.showDemoUserAuthModal()
      return
    }

    if (!companyId) {
      return
    }
    try {
      await removeDocument.mutateAsync({
        companyId,
        documentType: documentType as CompanyApiUpdateDocumentRequest['documentType'],
      })
      appToast.success({ description: 'Документ удален' })
    } catch (e) {
      const description = 'Ошибка при удалении документа'
      console.error(description, e)
      appToast.error({ description })
    }
    await companyDocumentList.refetch()
    await companiesVerificationRequirements.refetch()
  }

  if (!companyId) {
    return (
      <S.Wrapper>
        <Card>
          <Card.Title>Сначала заполните данные о компании</Card.Title>
        </Card>
      </S.Wrapper>
    )
  }

  if (companyDocumentList.isLoading) {
    return (
      <S.Wrapper>
        <Skeleton />
      </S.Wrapper>
    )
  }

  const documentList = companyDocumentList?.data?.documents ?? []
  const isUploading = uploadDocument.isLoading || uploadCompanyDocumentsArchive.isLoading

  const showRemoveDocumentConfirm = (documentType: string) => {
    const findDocType = documentList?.find((x) => x.type === documentType)?.type

    Modal.confirm({
      title: 'Вы действительно хотите удалить документ?',
      content: findDocType ? getDocumentInfo(findDocType).name : '',
      okText: 'Удалить',
      cancelText: 'Отмена',
      async onOk() {
        await handleRemoveDocument(documentType)
      },
    })
  }

  return (
    <S.Wrapper>
      <S.HeaderWrapper>
        <S.TextWrapper>
          <Text variant="h3">
            Чтобы заключить сделку Вам потребуется стандартный пакет документов.
            <br />
            Будет здорово, если вы загрузите заранее.
          </Text>
        </S.TextWrapper>

        <UploadAllDocumentsButton
          currentArchiveLink={companyDocumentsArchiveUrl.data}
          onUpload={handleUploadDocumentsInArchive}
          isLoading={uploadCompanyDocumentsArchive.isLoading}
          onDownload={handleDownloadArchive}
          onRemove={handleRemoveArchive}
        />
      </S.HeaderWrapper>

      <S.Desktop>
        <DocumentsTableView
          isUploading={isUploading}
          documents={documentList}
          onUploadFile={handleUploadFile}
          onShow={handleShowFile}
          onRemove={handleRemoveDocumentConfirmation}
          onChangeStatus={handleDocumentChangeStatus}
        />
      </S.Desktop>

      <S.Mobile>
        <DocumentsCardView
          isUploading={isUploading}
          documents={documentList}
          onUploadFile={handleUploadFile}
          onShow={handleShowFile}
          onRemove={handleRemoveDocumentConfirmation}
          onChangeStatus={handleDocumentChangeStatus}
        />
      </S.Mobile>
    </S.Wrapper>
  )
})
