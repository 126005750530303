import { codeUnreachable } from '~/utils/code-unreachable'
import { FreightReadinessType } from '~api/gql-generated/graphql'

export function getFreightReadinessType(freightReadinessType: FreightReadinessType): string {
  switch (freightReadinessType) {
    case FreightReadinessType.FromDate: {
      return 'С даты'
    }
    case FreightReadinessType.Always: {
      return 'Постоянно'
    }
    default: {
      return codeUnreachable(freightReadinessType)
    }
  }
}
