import React from 'react'

import { Controller, useForm } from 'react-hook-form'

import { yupResolver } from '@hookform/resolvers/yup/dist/yup'

import { useGetProductTypeData } from '~/hooks'
import { FormField, Text } from '~/ui-components'
import { PriceControl, UsingNdsControl, VolumeControl } from '~shared/controls'

import { createBuyAdvertFormSchema } from './CreateBuyAdvertOfferForm.validation'

import { CreateAdvertOfferFormProps, CreateBuyAdvertOfferFormValues } from './CreateBuyAdvertOfferForm.types'

import * as S from './CreateBuyAdvertOfferForm.styled'

export const CreateBuyAdvertOfferForm: React.FC<CreateAdvertOfferFormProps> = ({
  onSubmit,
  formId,
  defaultValues,
  productData,
  maxAvailableAdvertVolume,
}) => {
  const { getProductTypeParametersForUI_V2 } = useGetProductTypeData()

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<CreateBuyAdvertOfferFormValues>({
    defaultValues: {
      pricePerTon: defaultValues?.pricePerTon,
      volume: defaultValues?.volume,
      usingNds: defaultValues?.usingNds ?? false,
    },
    resolver: yupResolver(createBuyAdvertFormSchema),
    context: {
      maxAvailableAdvertVolume,
    },
  })

  const onSubmitHandler = (formValues: CreateBuyAdvertOfferFormValues) => {
    onSubmit(formValues)
  }

  return (
    <form id={formId} onSubmit={handleSubmit(onSubmitHandler)}>
      <Text variant="h3">{productData?.name}</Text>

      {!!productData?.parameters.length && (
        <S.Parameters>
          {getProductTypeParametersForUI_V2(productData).map((param) => (
            <div key={param}>{param}</div>
          ))}
        </S.Parameters>
      )}

      <FormField label="Объем" htmlFor="volume" validateMessage={errors.volume?.message} isRequired>
        <Controller
          name="volume"
          control={control}
          render={({ field }) => <VolumeControl {...field} id="volume" hasError={!!errors.volume} />}
        />
      </FormField>

      <S.PriceControlWrapper>
        <FormField
          htmlFor="pricePerTon"
          label="Предлагаемая цена"
          validateMessage={errors.pricePerTon?.message}
          isRequired
        >
          <Controller
            name="pricePerTon"
            control={control}
            render={({ field }) => (
              <PriceControl id="pricePerTon" {...field} hasError={!!errors.pricePerTon} addAfter="₽/т" />
            )}
          />
        </FormField>

        <FormField htmlFor="usingNds" validateMessage={errors.usingNds?.message} label="&nbsp;">
          <Controller name="usingNds" control={control} render={({ field }) => <UsingNdsControl {...field} />} />
        </FormField>
      </S.PriceControlWrapper>
    </form>
  )
}
