/* tslint:disable */
/* eslint-disable */
/**
 * API Зерно-Агрегатора
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { UsersCreatedBy } from './users-created-by';

/**
 * 
 * @export
 * @interface UserData
 */
export interface UserData {
    /**
     * Id пользователя
     * @type {number}
     * @memberof UserData
     */
    'id': number;
    /**
     * Номер телефона
     * @type {string}
     * @memberof UserData
     */
    'phoneNumber': string;
    /**
     * Статус пользователя
     * @type {string}
     * @memberof UserData
     */
    'status': UserDataStatusEnum;
    /**
     * Полное имя
     * @type {string}
     * @memberof UserData
     */
    'name': string;
    /**
     * Должность
     * @type {string}
     * @memberof UserData
     */
    'position': string | null;
    /**
     * Электронная почта
     * @type {string}
     * @memberof UserData
     */
    'email': string | null;
    /**
     * Id компании
     * @type {number}
     * @memberof UserData
     */
    'companyId': number;
    /**
     * Роль пользователя
     * @type {string}
     * @memberof UserData
     */
    'role': UserDataRoleEnum;
    /**
     * Дата аутентификации
     * @type {string}
     * @memberof UserData
     */
    'authenticatedAt': string;
    /**
     * Создана пользователем
     * @type {UsersCreatedBy}
     * @memberof UserData
     */
    'createdBy': UsersCreatedBy | null;
    /**
     * Дата создания
     * @type {string}
     * @memberof UserData
     */
    'createdAt': string;
}

export const UserDataStatusEnum = {
    Activated: 'ACTIVATED',
    Blocked: 'BLOCKED'
} as const;

export type UserDataStatusEnum = typeof UserDataStatusEnum[keyof typeof UserDataStatusEnum];
export const UserDataRoleEnum = {
    Owner: 'OWNER',
    Director: 'DIRECTOR',
    Manager: 'MANAGER',
    Accountant: 'ACCOUNTANT',
    Driver: 'DRIVER',
    Admin: 'ADMIN',
    Authenticated: 'AUTHENTICATED',
    DemoUser: 'DEMO_USER'
} as const;

export type UserDataRoleEnum = typeof UserDataRoleEnum[keyof typeof UserDataRoleEnum];


