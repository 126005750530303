import { useMutation } from '@tanstack/react-query'

import { useApi } from '~providers/api'

export const useMarkNotificationRead = () => {
  const api = useApi()

  return useMutation((notificationId: number) => {
    return api.notifications.notificationControllerMarkNotificationViewed({
      notificationId,
      updateNotificationStatusBody: { status: 'viewed' },
    })
  })
}
