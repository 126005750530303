import { codeUnreachable } from '~/utils/code-unreachable'
import { FreightDealStatus } from '~api/gql-generated/graphql'

export function getFreightResponseStatusName(value: FreightDealStatus): string {
  switch (value) {
    case FreightDealStatus.Agreement:
      return 'Принято'
    case FreightDealStatus.OnModeration:
      return 'На рассмотрении'
    case FreightDealStatus.Realised:
      return 'Принято'
    case FreightDealStatus.Rejected:
      return 'Принято'
    case FreightDealStatus.RejectedAfterModeration:
      return 'Отклонено'
    case FreightDealStatus.Shipment:
      return 'Доставка'
    default:
      return codeUnreachable(value)
  }
}
