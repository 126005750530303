import React from 'react'

import { Button, Table } from 'antd'
import { ColumnsType } from 'antd/lib/table'

import { useGetTableHeight } from '~/hooks'
import { Badge } from '~/ui-components'
import { getElevatorAdvertStatus } from '~/utils/elevator-adverts/get-elevator-advert-status'
import { getElevatorAdvertType } from '~/utils/elevator-adverts/get-elevator-advert-type'
import { getDateFormat } from '~/utils/getDateFormat'
import { OfferDataStatusEnum } from '~api/generated'
import { actionArrowColumn } from '~shared/columns/shared'

import {
  IModeratorBasisAdvertListTableProps,
  ModeratorBasisAdvertListTableDataType,
} from './ModeratorBasisAdvertListTable.types'

import * as S from './ModeratorBasisAdvertListTable.styled'

export const ModeratorBasisAdvertListTable: React.FC<IModeratorBasisAdvertListTableProps> = ({
  tableData,
  loading,
  onRowClick,
  takeAdvertToWork,
}) => {
  const tableHeight = useGetTableHeight(345)
  const onRowClickHandler = (record: ModeratorBasisAdvertListTableDataType) => {
    onRowClick(record)
  }

  const takeOfferToWorkHandler = (e: React.MouseEvent, offerId: number) => {
    e.stopPropagation()
    takeAdvertToWork(offerId)
  }

  const columns: ColumnsType<ModeratorBasisAdvertListTableDataType> = [
    {
      title: '№',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Статус',
      dataIndex: 'status',
      key: 'status',
      render: (v: ModeratorBasisAdvertListTableDataType['status']) => {
        const offerStatus = getElevatorAdvertStatus(v)
        return (
          <Badge color={offerStatus.color} size="s">
            {offerStatus.name}
          </Badge>
        )
      },
    },
    {
      title: 'Культура',
      dataIndex: 'cultureName',
      key: 'cultureName',
      render: (v: ModeratorBasisAdvertListTableDataType['cultureName']) => <b>{v}</b>,
    },
    {
      title: 'Базис',
      dataIndex: 'basisName',
      key: 'basisName',
    },
    {
      title: 'Тип объявления',
      dataIndex: 'type',
      key: 'type',
      render: (v: ModeratorBasisAdvertListTableDataType['type']) => {
        const type = getElevatorAdvertType(v)
        return (
          <Badge color={type.color} size="s">
            {type.name}
          </Badge>
        )
      },
    },
    {
      title: 'Компания',
      dataIndex: 'buyer',
      key: 'buyer',
    },
    {
      title: 'Дата публикации',
      dataIndex: 'publishedAt',
      key: 'publishedAt',
      width: 150,
      render: (d: ModeratorBasisAdvertListTableDataType['publishedAt']) => (d ? getDateFormat(d) : '-'),
    },
    {
      title: 'Дата снятия',
      dataIndex: 'rejectedAt',
      key: 'rejectedAt',
      width: 150,
      render: (d: ModeratorBasisAdvertListTableDataType['rejectedAt']) => (d ? getDateFormat(d) : '-'),
    },

    {
      title: 'Модератор',
      dataIndex: 'moderator',
      key: 'moderator',
      ellipsis: true,
      width: 150,
      render: (
        moderator: ModeratorBasisAdvertListTableDataType['moderator'],
        record: ModeratorBasisAdvertListTableDataType
      ) => {
        if (moderator) {
          return moderator?.name
        }

        if (record.status === OfferDataStatusEnum.OnModeration) {
          return (
            <Button type="primary" onClick={(event) => takeOfferToWorkHandler(event, record.id)}>
              Взять в работу
            </Button>
          )
        }
      },
    },
    actionArrowColumn,
  ]

  return (
    <S.StyledTable>
      <Table
        rowKey="id"
        className="table-interactive"
        loading={loading}
        columns={columns}
        dataSource={tableData}
        scroll={{ y: tableHeight, x: 'max-content' }}
        pagination={false}
        onRow={(record, index) => ({
          onClick: onRowClickHandler.bind(null, record, index),
        })}
      />
    </S.StyledTable>
  )
}
