import React, { useEffect, useMemo } from 'react'

import { Tabs } from 'antd'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'

import { AppHeader } from '~/layout'
import { appRoutes } from '~/router'
import { getTabsFromUrl } from '~/utils/getTabsFromUrl'

export const ProfilePage: React.FC = () => {
  const navigate = useNavigate()
  const { pathname } = useLocation()

  const tabs = [appRoutes.profileCompany, appRoutes.profileEmployees, appRoutes.profileDocuments]

  useEffect(() => {
    if (pathname === appRoutes.profile.url) {
      navigate(appRoutes.profileCompany.url, { replace: true })
    }
  }, [pathname])

  const handleTabsChange = (url: string) => {
    navigate(url)
  }

  const activeTab = useMemo(() => {
    return tabs.find((tab) => pathname.endsWith(tab.url))
  }, [pathname])

  return (
    <>
      <AppHeader title={appRoutes.profile.title}>
        <Tabs activeKey={activeTab?.url} size="small" onChange={handleTabsChange} items={getTabsFromUrl(tabs)} />
      </AppHeader>
      <Outlet />
    </>
  )
}

export default ProfilePage
