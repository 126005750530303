import styled from 'styled-components'

export const AccountPageWrapper = styled.div`
  display: flex;
  flex: 1;
  overflow: auto;
`

export const AccountPageLeftSide = styled.div`
  width: 260px;
  min-width: 260px;
  display: flex;
  flex-direction: column;
  border-right: 1px solid var(--gray-2-color);
`

export const AccountPageRightSide = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  overflow: auto;
`
