import React from 'react'

import { Text } from '~/ui-components'
import acceptAdvertOfferManualPic from '~assets/images/acceptAdvertOfferManual.png'
import acceptAdvertOfferManualPicMobile from '~assets/images/acceptAdvertOfferManualMobile.png'
import { useGetDevice } from '~hooks/common'

import * as S from './MyAdvertOfferAcceptManualStep.styled'

export const MyAdvertOfferAcceptManualStep: React.FC = () => {
  const device = useGetDevice()
  const isMobile = device === 'mobile'

  return (
    <S.Wrapper>
      <Text variant="h2" style={{ alignSelf: 'center' }}>
        Важно!
      </Text>

      <S.DescriptionWrapper>
        <S.DescriptionBold>
          Мы делаем <S.TextGreen>платформу</S.TextGreen> лучше с вашей помощью
        </S.DescriptionBold>

        <Text variant="normal">
          Как только сделка состоится, измените, пожалуйста, статус отклика, чтобы мы знали, что все прошло хорошо.
        </Text>

        <Text variant="normal">
          Если по каким-то причинам сделка не состоится, то нажмите “Сделка не состоялась” внутри отклика.
        </Text>
      </S.DescriptionWrapper>

      <Text variant="h3">
        Как сменить статус <S.TextGreen>отклика</S.TextGreen>?
      </Text>

      <S.ImageManual src={isMobile ? acceptAdvertOfferManualPicMobile : acceptAdvertOfferManualPic} />
    </S.Wrapper>
  )
}
