export const VALIDATORS_MSG = {
  required: 'Обязательное поле',
  requiredUpload: 'Загрузите файл',
  defined: 'Должно быть определено',
  typeNumber: 'Должно быть числом',
  integer: 'Должно быть целым числом',
  email: 'Введите корректный e-mail',
  notFullPhoneNumber: 'Номер введен не полностью',
  codeError: 'Код введен неверно',
  minValue: function (x: number) {
    return `Минимальное значение ${x}`
  },
  maxValue: function (x: number) {
    return `Максимальное значение ${x}`
  },
  maxDigitsAfterDecimal: function (x: number) {
    return `Должно содержать ${x} цифры после запятой или меньше`
  },
}
