import React, { useState } from 'react'

import { Button, Select, Space } from 'antd'

import { Text } from '~/ui-components'
import { MyUserEntity } from '~pages/EmployeesPage/components/EmployeesList/EmployeesList'
import { PhoneNumber } from '~shared/components'

import * as S from './UserRequestedListItem.styled'
import { UserRole } from '~api/gql-generated/graphql'
import { getUserRole } from '~/utils/user/getUserRoleOld'
import { AiOutlineClose } from 'react-icons/ai'
import { useGetDevice } from '~hooks/common'

interface UserListItemProps {
  user: MyUserEntity
  onReject: (role: UserRole) => void
  onAccept: (role: UserRole) => void
}

export const UserRequestedListItem: React.FC<UserListItemProps> = (props) => {
  const device = useGetDevice()
  const isMobile = device === 'mobile'
  const defaultRole = UserRole.Manager
  const [role, setRole] = useState<UserRole>(defaultRole)

  return (
    <S.Wrapper>
      <S.EmployeeInfoWrapper>
        <Space direction="vertical">
          <Text variant="normal-bold">{props.user.name}</Text>
          <Text variant="normal">
            <PhoneNumber phoneNumber={props.user.phoneNumber} prefix="" />
          </Text>
          <a href={`mailto:${props.user.email}`}>{props.user.email}</a>
        </Space>
      </S.EmployeeInfoWrapper>

      <S.ActionsWrapper>
        <S.ActionWrapper>
          <Select style={{ width: '100%' }} defaultValue={defaultRole} onChange={(value) => setRole(value)}>
            <Select.Option value={UserRole.Director}>{getUserRole(UserRole.Director)}</Select.Option>
            <Select.Option value={UserRole.Manager}>{getUserRole(UserRole.Manager)}</Select.Option>
            <Select.Option value={UserRole.Accountant}>{getUserRole(UserRole.Accountant)}</Select.Option>
          </Select>
        </S.ActionWrapper>

        <S.ActionWrapper>
          <S.StyledButton onClick={() => props.onReject(role)}>
            {isMobile ? <AiOutlineClose size={16} /> : 'Отклонить'}
          </S.StyledButton>
          <Button type="primary" htmlType="button" onClick={() => props.onAccept(role)}>
            Принять
          </Button>
        </S.ActionWrapper>
      </S.ActionsWrapper>
    </S.Wrapper>
  )
}
