import * as yup from 'yup'

import { VALIDATORS_MSG } from '~shared/validations'
import { fullPhoneNumberValidator } from '~shared/validations/schemas'

export const carrierInfoCardFormSchema = yup.object({
  contactUser: yup.string().trim().required(VALIDATORS_MSG.required),
  contactPhone: yup
    .string()
    .trim()
    .test('mobilePhone', VALIDATORS_MSG.notFullPhoneNumber, fullPhoneNumberValidator)
    .required(VALIDATORS_MSG.required),
  dateFirstCar: yup.date().nullable(),
  dateOfPerformance: yup.date().nullable(),
  organization: yup.string().trim(),
})
