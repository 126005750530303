import React, { useState } from 'react'

import type { MenuProps } from 'antd'
import { Button, Dropdown, Modal, Space } from 'antd'

import { DownOutlined } from '@ant-design/icons'

import { appToast, httpErrorHandler } from '~/utils'
import { ElevatorOfferStatus } from '~api/generated'
import { useGetReportAdvert, useUpdateElevatorAdvertPrice, useUpdateElevatorAdvertStatus } from '~hooks/elevator-advert'
import { MyAdvertEdit } from '~pages/BasisPage/modules/MyAdvert/components/MyAdvertEditForm'
import { RepublishMyAdvertModal } from '~pages/BasisPage/modules/MyAdvert/components/RepublishMyAdvertModal/RepublishMyAdvertModal'

import { MyAdvertEditFormValues } from '~pages/BasisPage/modules/MyAdvert/components/MyAdvertEditForm/MyAdvertEdit.types'

enum SelectKeys {
  EDIT = 'EDIT',
  DEACTIVATE = 'DEACTIVATE',
  PUBLISHED = 'PUBLISHED',
  CANCEL = 'CANCEL',
  DOWNLOAD = 'DOWNLOAD',
}

interface MyAdvertActionsDropdownProps {
  advertId: number
  companyId: number
  price: number
  currentStatus?: ElevatorOfferStatus
  nextStatuses?: ElevatorOfferStatus[]
  afterAction: () => void
}

export const MyAdvertActionsDropdown: React.FC<MyAdvertActionsDropdownProps> = (props) => {
  const [isEditAdvertModalOpen, setIsEditAdvertModalOpen] = useState(false)
  const [isRepublishAdvertModalOpen, setIsRepublishAdvertModalOpen] = useState(false)
  const updateElevatorAdvertStatusFn = useUpdateElevatorAdvertStatus()
  const updateElevatorAdvertPriceFn = useUpdateElevatorAdvertPrice()
  const getReportFn = useGetReportAdvert()

  const items: MenuProps['items'] = [
    {
      label: 'Редактировать цену',
      key: SelectKeys.EDIT,
      disabled: props.currentStatus === 'CANCELLED' || props.currentStatus === 'DEACTIVATED',
    },
    {
      label: 'Снять с публикации',
      key: SelectKeys.DEACTIVATE,
      danger: true,
      style: { display: !props.nextStatuses?.includes('DEACTIVATED') ? 'none' : undefined },
    },
    {
      label: 'Опубликовать',
      key: SelectKeys.PUBLISHED,
      style: { display: !props.nextStatuses?.includes('PUBLISHED') ? 'none' : undefined },
    },
    {
      label: 'Отменить',
      key: SelectKeys.CANCEL,
      danger: true,
      style: { display: !props.nextStatuses?.includes('CANCELLED') ? 'none' : undefined },
    },
    {
      label: 'Скачать отчет',
      key: SelectKeys.DOWNLOAD,
    },
  ]

  const changeStatusAdvertHandler = (status: ElevatorOfferStatus) => {
    if (status === ElevatorOfferStatus.Published) {
      setIsRepublishAdvertModalOpen(true)
      return
    }

    Modal.confirm({
      title: 'Снять с публикации',
      content:
        'Сняв с публикации объявление, вы скрываете его от продавцов. Восстановить видимость объявление можно во вкладке “Мои объявления”.',
      okText: 'Снять с публикации',
      cancelText: 'Отмена',
      async onOk() {
        try {
          await updateElevatorAdvertStatusFn.mutateAsync({
            id: props.advertId,
            updateElevatorAdvertStatusBody: {
              userCompanyId: props.companyId,
              status,
            },
          })
          await props.afterAction()
          appToast.success({
            description: 'Объявление снято с публикации',
          })
        } catch (e) {
          httpErrorHandler(e, 'Ошибка при снятии с публикации')
        }
      },
    })
  }
  const editAdvertHandler = async (formValues: MyAdvertEditFormValues) => {
    try {
      await updateElevatorAdvertPriceFn.mutateAsync({
        id: props.advertId,
        updateElevatorAdvertPriceBody: {
          userCompanyId: props.companyId,
          pricePerTon: formValues.pricePerTon,
        },
      })
      appToast.success({ description: 'Объявление отредактировано' })
      setIsEditAdvertModalOpen(false)
      await props.afterAction()
    } catch (e) {
      httpErrorHandler(e, 'Ошибка при редактировании объявления')
    }
  }

  const downloadReportHandler = async () => {
    try {
      const response = await getReportFn.mutateAsync({
        userCompanyId: props.companyId,
        elevatorOfferId: props.advertId,
      })
      const objectUrl = URL.createObjectURL(response.data as unknown as Blob)
      const link = document.createElement('a')
      link.href = objectUrl

      link.setAttribute('download', `Отчет по объявлению ${props.advertId}`)
      document.body.appendChild(link)
      link.click()
      link?.parentNode?.removeChild(link)
    } catch (e) {
      console.log(e)
      httpErrorHandler(e, 'Ошибка при скачивании отчёта')
    }
  }

  const handleMenuClick: MenuProps['onClick'] = (e) => {
    const action = e.key as SelectKeys

    switch (action) {
      case SelectKeys.DEACTIVATE:
        changeStatusAdvertHandler('DEACTIVATED')
        break
      case SelectKeys.CANCEL:
        changeStatusAdvertHandler('CANCELLED')
        break
      case SelectKeys.PUBLISHED:
        changeStatusAdvertHandler('PUBLISHED')
        break
      case SelectKeys.EDIT:
        setIsEditAdvertModalOpen(true)
        break
      case SelectKeys.DOWNLOAD:
        void downloadReportHandler()
        break
    }
  }

  const menuProps = {
    items,
    onClick: handleMenuClick,
  }

  return (
    <>
      <Dropdown menu={menuProps}>
        <Button>
          <Space>
            Действия
            <DownOutlined />
          </Space>
        </Button>
      </Dropdown>

      <MyAdvertEdit
        defaultValues={{ pricePerTon: props.price }}
        isOpen={isEditAdvertModalOpen}
        onClose={() => setIsEditAdvertModalOpen(false)}
        onSubmit={editAdvertHandler}
      />

      <RepublishMyAdvertModal
        isOpen={isRepublishAdvertModalOpen}
        onClose={() => setIsRepublishAdvertModalOpen(false)}
        advertId={props.advertId}
        companyId={props.companyId}
        afterAction={props.afterAction}
      />
    </>
  )
}
