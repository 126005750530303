import React, { PropsWithChildren } from 'react'

import { Select } from 'antd'
import { BaseSelectRef } from 'rc-select'

import { getElevatorUnloadType } from '~/utils/elevators/get-elevator-unload-type'
import { UnloadingTransportType } from '~api/generated'
import { CustomControlInterface } from '~shared/controls/CustomControl.interface'

type Value = UnloadingTransportType[] | undefined

interface IElevatorLoadTypeControlProps extends CustomControlInterface {
  onChange: (value: Value) => void
  value: Value
  hasError?: boolean
  disabled?: boolean
  id?: string
}

interface IOption {
  value: UnloadingTransportType
  label: string
}

const loadTypesOptions: IOption[] = [
  {
    label: getElevatorUnloadType(UnloadingTransportType.TailgateAutoUnloader),
    value: UnloadingTransportType.TailgateAutoUnloader,
  },
  {
    label: getElevatorUnloadType(UnloadingTransportType.SideBoardAutoUnloader),
    value: UnloadingTransportType.SideBoardAutoUnloader,
  },
  {
    label: getElevatorUnloadType(UnloadingTransportType.Manipulator),
    value: UnloadingTransportType.Manipulator,
  },
  {
    label: getElevatorUnloadType(UnloadingTransportType.DumpTruck),
    value: UnloadingTransportType.DumpTruck,
  },
  {
    label: getElevatorUnloadType(UnloadingTransportType.MechanicalShovel),
    value: UnloadingTransportType.MechanicalShovel,
  },
  {
    label: getElevatorUnloadType(UnloadingTransportType.PneumaticUnloader),
    value: UnloadingTransportType.PneumaticUnloader,
  },
]

export const ElevatorUnloadTypeControl: React.FC<PropsWithChildren<IElevatorLoadTypeControlProps>> = React.forwardRef<
  BaseSelectRef,
  PropsWithChildren<IElevatorLoadTypeControlProps>
>(({ id, value, onChange, hasError, disabled }, ref) => {
  const onSelectAddressHandler = (v: Value): void => {
    // if (!v?.length) {
    //   onChange(undefined)
    //   return
    // }
    onChange(v)
  }

  return (
    <Select
      ref={ref}
      id={id}
      style={{ width: '100%' }}
      mode="multiple"
      placeholder="Выберите способ приемки"
      value={value}
      disabled={disabled}
      onChange={onSelectAddressHandler}
      status={hasError ? 'error' : undefined}
      optionFilterProp="children"
      allowClear
    >
      {loadTypesOptions.map((it) => (
        <Select.Option key={it.value} value={it.value}>
          {it.label}
        </Select.Option>
      ))}
    </Select>
  )
})

ElevatorUnloadTypeControl.displayName = 'ElevatorUnloadTypeControl'
