import { Checkbox } from 'antd'
import styled from 'styled-components'

import { Text } from '~/ui-components'

export const ServiceListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 16px 0;
  gap: 8px;
`
export const ServicePriceWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`
export const AgentFormWrapper = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-column-gap: 24px;
  grid-row-gap: 16px;
`
export const AgentFormTitle = styled(Text).attrs({ variant: 'normal' })`
  color: var(--gray-6-color);
  margin-bottom: 16px;
`
export const AgentFormValue = styled(Text).attrs({ variant: 'normal' })``
