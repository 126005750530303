import { useQuery, UseQueryResult } from '@tanstack/react-query'

import { DealApiDealControllerGetCompanyDealsRequest, GetCompanyDealsResponse } from '~/api/generated'
import { useApi } from '~providers/api'

export const useGetDealsByCompanyId = (
  request: DealApiDealControllerGetCompanyDealsRequest
): UseQueryResult<GetCompanyDealsResponse> => {
  const api = useApi()
  const companyId = request.companyId

  const fetchFn = async (): Promise<GetCompanyDealsResponse> => {
    try {
      const response = await api.deal.dealControllerGetCompanyDeals(request)
      return response.data
    } catch (error) {
      return Promise.reject(error)
    }
  }

  return useQuery(['fetchCompanyDeals', companyId, request.currentPage, request.pageSize], fetchFn, {
    enabled: !!companyId,
    staleTime: 1000 * 5,
  })
}
