import { useMutation } from '@tanstack/react-query'

import { ElevatorApiElevatorAdminControllerDeleteElevatorRequest } from '~api/generated'
import { useApi } from '~providers/api'

export const useDeleteElevatorAdmin = () => {
  const api = useApi()

  const useDeleteElevatorFn = async (
    request: ElevatorApiElevatorAdminControllerDeleteElevatorRequest
  ): Promise<void> => {
    const res = await api.elevators.elevatorAdminControllerDeleteElevator(request)

    return res.data
  }

  return useMutation(useDeleteElevatorFn)
}
