import React from 'react'

import { Select } from 'antd'
import { BaseSelectRef } from 'rc-select'

import { getTransportFormatType } from '~/utils/logistics/get-transport-format-types'
import { TransportFormatType } from '~api/gql-generated/graphql'
import { CustomControlInterface } from '~shared/controls/CustomControl.interface'

interface TransportFormatTypeSelectControlProps extends CustomControlInterface {
  id?: string
  value: TransportFormatType[] | undefined
  onChange: (value: TransportFormatType[]) => void
  hasError?: boolean
}

export const TransportFormatTypeSelectControl: React.FC<TransportFormatTypeSelectControlProps> = React.forwardRef<
  BaseSelectRef,
  TransportFormatTypeSelectControlProps
>(({ id, value, onChange, hasError }, ref) => {
  return (
    <Select
      ref={ref}
      allowClear
      style={{ width: '100%', maxWidth: '342px' }}
      placeholder="Выберите"
      value={value}
      onChange={onChange}
      status={hasError ? 'error' : undefined}
      mode="multiple"
    >
      {Object.values(TransportFormatType).map((transportFormatType) => (
        <Select.Option key={transportFormatType} value={transportFormatType}>
          {getTransportFormatType(transportFormatType)}
        </Select.Option>
      ))}
    </Select>
  )
})

TransportFormatTypeSelectControl.displayName = 'TransportFormatTypeSelectControl'
