import { Button } from 'antd'
import styled from 'styled-components'

import css from '@styled-system/css'

export const Wrapper = styled.div(
  css({
    display: 'flex',
    flexDirection: 'column',
    marginTop: ['unset', null, '30px'],
  })
)

export const Content = styled.div(
  css({
    position: 'relative',
    margin: '0 auto',
    padding: ['16px', null, '32px', '50px'],
    width: ['100%', null, null, '1000px'],
    boxShadow: '7px 23px 60px rgba(0, 78, 3, 0.17)',
  })
)

export const ContentWrapper = styled.div`
  background: var(--white-color);
`

export const ImgWrapper = styled.div(
  css({
    display: 'flex',
  })
)

export const IntegrationImg = styled('img')(
  css({
    width: '100%',
    height: 'auto',
    objectFit: 'cover',
  })
)

export const RedirectButton = styled(Button)(
  css({
    marginTop: '40px',
    width: '100%',
    height: '60px',
  })
)
