import React from 'react'

import { FiArrowRight } from 'react-icons/fi'

import { Card } from '~/ui-components'
import { getNoun } from '~/utils/getNoun'
import { ElevatorData } from '~api/generated'

import * as S from './BasisListItemMobile.styled'
import { AdvertCounter } from './BasisListItemMobile.styled'

interface BasisListItemMobileProps {
  item: ElevatorData
  onClick: (id: number) => void
}

export const BasisListItemMobile: React.FC<BasisListItemMobileProps> = (props) => {
  const { item } = props
  const onClickHandler = () => props.onClick(item.id)

  return (
    <Card onClick={onClickHandler}>
      <Card.Header>
        <Card.Title>{item.name}</Card.Title>
      </Card.Header>
      <Card.Content>
        <S.Content>
          <div>
            {item.elevatorAdvertsCount > 0 ? (
              <S.AdvertCounter>
                {item.elevatorAdvertsCount}&nbsp;
                {getNoun(item.elevatorAdvertsCount, 'объявление', 'объявления', 'объявлений')}
              </S.AdvertCounter>
            ) : (
              <S.AdvertCounter>Нет объявлений</S.AdvertCounter>
            )}
            <S.Address>{item.address}</S.Address>
          </div>

          <FiArrowRight size={24} />
        </S.Content>
      </Card.Content>
    </Card>
  )
}
