import { uniq } from 'lodash'

import { useQuery } from '@tanstack/react-query'

import { CompanyData, GetOffersResponse, OfferApiGetOffersRequest, OfferData, UserData } from '~/api/generated'
import { useApi } from '~/providers/api'

interface ModeratedOffer extends OfferData {
  company: CompanyData | null
  moderator: UserData | null
}

interface ModeratedOfferResult extends GetOffersResponse {
  moderatedOffer?: ModeratedOffer[]
}

export const useGetModeratorOfferList = (request: OfferApiGetOffersRequest) => {
  const api = useApi()

  async function fetchOffers() {
    const response = await api.offers.adminGetOffers(request)
    return response.data
  }

  async function fetchCompanies(ids: number[]) {
    const response = await api.companies.companyControllerGetCompanies({
      ids,
      pageSize: 100,
    })
    return response.data.companies
  }

  async function fetchUsers(userIds: number[]) {
    const response = await api.users.userControllerGetUsers({
      userIds,
      pageSize: 100,
    })
    return response.data.users
  }

  const fetchFn = async (): Promise<ModeratedOfferResult> => {
    try {
      const offersData = await fetchOffers()
      const offers = offersData.offers

      const companiesIds: number[] = uniq(offers?.map((x) => x.companyId)).filter(Boolean) as number[]
      const moderatorsIds: number[] = uniq(offers?.map((x) => x.moderatedBy)).filter(Boolean) as number[]
      const companies = await fetchCompanies(companiesIds)
      const moderators = await fetchUsers(moderatorsIds)

      const moderatedOffer: ModeratedOffer[] = offers.map((offer) => {
        const foundCompany: CompanyData | null = offer.companyId
          ? companies.find((c) => c.id === offer.companyId) ?? null
          : null

        const foundModerator = offer?.moderatedBy
          ? moderators.find((moderator) => moderator.id === offer.moderatedBy) ?? null
          : null
        return { ...offer, company: foundCompany, moderator: foundModerator }
      })
      return {
        ...offersData,
        moderatedOffer,
      }
    } catch (error) {
      console.error('[useGetModeratorOfferList]: has error', error)
      return Promise.reject('useGetModeratorOfferList error')
    }
  }

  return useQuery(['fetchModeratorOfferList', request], fetchFn, { staleTime: 1000 * 5, keepPreviousData: true })
}
