import React from 'react'

import { observer } from 'mobx-react-lite'

import { basisAdvertOffersAdminFilterStore as store } from '~/store/filters'
import { SearchInput } from '~shared/components/SearchInput'
import {
  ElevatorAdvertOfferStatusSelectControl,
  ElevatorAdvertTypeSelectControl,
  ElevatorSelectControl,
} from '~shared/controls'

import * as S from './ModeratorBasisAdvertOffersFilters.styled'

export const ModeratorBasisAdvertOffersFilters: React.FC = observer(() => {
  return (
    <S.Filters>
      <ElevatorSelectControl
        id="elevatorId"
        value={store.filters.elevatorId}
        onChange={(elevatorId) => store.setFilters({ ...store.filters, elevatorId })}
        placeholder="Название базиза"
      />
      <SearchInput
        defaultValue={store.filters.elevatorAdvertId?.toString() ?? ''}
        onSearch={(elevatorAdvertId) =>
          store.setFilters({
            ...store.filters,
            elevatorAdvertId: elevatorAdvertId ? Number(elevatorAdvertId) : undefined,
          })
        }
        placeholder="№ объявления"
        type="number"
        pattern="\d*"
      />
      <ElevatorAdvertOfferStatusSelectControl
        id="status"
        value={store.filters.statuses}
        onChange={(advertStatuses) => store.setFilters({ ...store.filters, statuses: advertStatuses })}
      />
      <ElevatorAdvertTypeSelectControl
        id="offerType"
        value={store.filters.offerType}
        onChange={(offerType) => store.setFilters({ ...store.filters, offerType: offerType })}
      />
    </S.Filters>
  )
})
