import React, { useState } from 'react'

import { Button, Skeleton } from 'antd'
import { FiArrowRight, FiPlusCircle } from 'react-icons/fi'

import { Card, Text } from '~/ui-components'
import { getDateFormat } from '~/utils/getDateFormat'
import { ElevatorProductTypeData } from '~api/generated'
import { useGetElevatorAdverts } from '~hooks/elevator-advert'
import { BasisCultureParams } from '~pages/BasisPage/components/BasisCultureParams'
import { CreateAdvertBuyOfferModal } from '~pages/BasisPage/components/CreateAdvertBuyOfferModal'

import * as S from './BasisAdvertsForBuyerToSell.styled'

interface BasisAdvertsForBuyerToSellProps {
  elevatorId: number
  companyId: number
  productTypeData: ElevatorProductTypeData
  onClick: (advertId: number) => void
}

export const BasisAdvertsForBuyerToSell: React.FC<BasisAdvertsForBuyerToSellProps> = ({
  elevatorId,
  companyId,
  productTypeData,
  onClick,
}) => {
  const [isCreateAdvertModalOpenForAdvertId, setIsCreateAdvertModalOpenForAdvertId] = useState<number | null>(null)
  const [isExpanded, setExpanded] = useState(true)
  const getElevatorAdvertsFn = useGetElevatorAdverts({
    elevatorId,
    productTypes: [productTypeData.type],
    userCompanyId: companyId,
    pageSize: 99,
    type: 'SELL',
  })

  const adverts = getElevatorAdvertsFn.data?.elevatorAdverts
  const pagination = getElevatorAdvertsFn.data?.pagination
  const maxAdvertsShown = 2
  const advertsListShort = !adverts
    ? []
    : adverts.length > maxAdvertsShown
    ? adverts.slice(0, maxAdvertsShown)
    : adverts
  const advertsList = isExpanded ? advertsListShort : adverts

  const onClickHandler = (advertId: number) => onClick(advertId)
  const makeOfferHandler = (e: React.MouseEvent, advertId: number) => {
    e.stopPropagation()
    setIsCreateAdvertModalOpenForAdvertId(advertId)
  }

  return (
    <div>
      <S.TitleWrapper>
        <S.TitleWrapperInner>
          <Text variant="h3">{productTypeData.name}</Text>
          <S.CountWrapper>{adverts?.length}</S.CountWrapper>
        </S.TitleWrapperInner>
        <Button hidden={(adverts?.length ?? 0) <= maxAdvertsShown} type="link" onClick={() => setExpanded((x) => !x)}>
          {isExpanded ? 'Показать все' : 'Скрыть'}
        </Button>
      </S.TitleWrapper>

      <S.LIstWrapper>
        <Skeleton loading={getElevatorAdvertsFn.isLoading} active />

        {advertsList?.map((advert) => (
          <Card key={advert.id} onClick={() => onClickHandler(advert.id)}>
            <Card.Header>
              <S.CardHeaderTop>
                <div>
                  <S.Volume>{advert.volume} т</S.Volume>
                  <Card.Title>
                    {advert.pricePerTon} ₽/т{' '}
                    {advert.priceUpdatedAt && (
                      <S.PublishedAt>обн. {getDateFormat(advert.priceUpdatedAt)}</S.PublishedAt>
                    )}
                  </Card.Title>
                  <Text variant="normal">{advert.usingNds ? 'с НДС' : 'без НДС'}</Text>
                </div>
                <FiArrowRight size={24} />
              </S.CardHeaderTop>
            </Card.Header>
            <Card.Content>
              <div>{!!advert.product.parameters.length && <BasisCultureParams product={advert.product} />}</div>
              <S.CardFooter>
                <div />
                <Button type="link" icon={<FiPlusCircle size={16} />} onClick={(e) => makeOfferHandler(e, advert.id)}>
                  Купить объем
                </Button>
              </S.CardFooter>
            </Card.Content>
          </Card>
        ))}
      </S.LIstWrapper>

      <CreateAdvertBuyOfferModal
        userCompanyId={companyId}
        advertId={isCreateAdvertModalOpenForAdvertId!}
        isOpen={!!isCreateAdvertModalOpenForAdvertId}
        onCancel={() => setIsCreateAdvertModalOpenForAdvertId(null)}
      />
    </div>
  )
}
