import React, { FC } from 'react'

import { Avatar } from 'antd'

import { Badge } from '~/ui-components'
import { formatNumber } from '~/utils/formatNumber'
import { getDateFormat, getDateTimeFormat } from '~/utils/getDateFormat'
import { getNdsString } from '~/utils/getNdsString'
import imagePlaceholder from '~assets/images/agro-culture-1.png'

import { OfferCardPreviewProps } from './OfferCardPreview.types'

import * as S from './OfferCardPreview.styled'

export const OfferCardPreview: FC<OfferCardPreviewProps> = ({
  name,
  parameters,
  volume,
  price,
  isNds,
  address,
  distance,
  statuses,
  publishedAt,
  acceptedAt,
  companyName,
  onClick,
  isOwn,
  img,
}) => {
  return (
    <S.CardPreview isOwn={isOwn} onClick={onClick}>
      <S.ContentWrapper>
        <S.Content>
          <S.MainContentWrapper>
            <S.MainContent>
              <S.Desktop>
                <Avatar size="large" src={img ?? imagePlaceholder} />
              </S.Desktop>
              <div>
                {name && <S.Name>{name}</S.Name>}
                {statuses?.length && (
                  <S.Statuses>
                    {statuses.map(({ name, color }) => (
                      <Badge key={name} color={color} size="s">
                        {name}
                      </Badge>
                    ))}
                  </S.Statuses>
                )}
              </div>
            </S.MainContent>
            {!!parameters.length && (
              <S.Parameters>
                {parameters.slice(0, 2).map((param) => (
                  <Badge key={param} color="gray" isRound>
                    {param}
                  </Badge>
                ))}
              </S.Parameters>
            )}
          </S.MainContentWrapper>
          <S.LocationWrapper>
            <S.Address>{address}</S.Address>
            {!!distance && <S.Distance>{distance} км от вас</S.Distance>}
            {companyName && <S.CompanyName>{companyName}</S.CompanyName>}
          </S.LocationWrapper>
        </S.Content>
        <S.Info>
          <S.Volume>{formatNumber(volume)} тонн</S.Volume>
          <S.Price>{formatNumber(price)} ₽/т</S.Price>
          <S.Nds>{getNdsString(isNds)}</S.Nds>
        </S.Info>
      </S.ContentWrapper>
      {publishedAt && <S.Date>Дата публикации: {getDateFormat(publishedAt)}</S.Date>}
      {acceptedAt && <S.Date>Дата отклика: {getDateTimeFormat(acceptedAt)}</S.Date>}
    </S.CardPreview>
  )
}
