import { graphql } from '~api/gql-generated'

export const freightDealDocumentCreateMutation = graphql(/* GraphQL */ `
  mutation FreightDealDocumentCreate($input: FreightDealDocumentCreateInput!) {
    freightDealDocumentCreate(input: $input) {
      type
      instances {
        id
        number
        rejectionNote
        status
        formedAt
        files {
          id
          mimetype
          name
          size
          source
          status
        }
        createdAt
      }
    }
  }
`)

export const deleteFreightDealDocumentCreateMutation = graphql(/* GraphQL */ `
  mutation FreightDealDocument($documentId: Int!) {
    freightDealDocument(documentId: $documentId) {
      delete
    }
  }
`)

export const freightDealDocumentAddFilesMutation = graphql(/* GraphQL */ `
  mutation AddFiles($input: DocumentFilesCreateInput!, $documentId: Int!) {
    freightDealDocument(documentId: $documentId) {
      addFiles(input: $input) {
        type
        instances {
          id
          status
          createdAt
          number
          formedAt
          rejectionNote
          files {
            id
            status
            name
            size
            mimetype
            source
          }
        }
      }
    }
  }
`)
