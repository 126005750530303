import React from 'react'

import { CompanyEntity } from '~api/gql-generated/graphql'

import { CompaniesListItem } from '../CompaniesListItem/CompaniesListItem'

interface CompaniesListProps {
  companies: Partial<CompanyEntity>[]
  selectedCompanyId: number
  canUserCreateNewCompany: boolean
  onAdd: () => void
  onSelect: (companyId: number) => void
}
export const CompaniesList: React.FC<CompaniesListProps> = ({
  companies,
  selectedCompanyId,
  onAdd,
  onSelect,
  canUserCreateNewCompany,
}) => {
  return (
    <div>
      {companies.map((company) => (
        <CompaniesListItem
          key={company.id}
          variant="company-info"
          data={company}
          isSelected={company.id === selectedCompanyId}
          onSelect={onSelect}
        />
      ))}

      <CompaniesListItem variant="company-add" onAdd={onAdd} canUserCreateNewCompany={canUserCreateNewCompany} />
    </div>
  )
}
