import styled from 'styled-components'

import { Text } from '~/ui-components'

export const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 16px;
  gap: 16px;
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
`

export const Description = styled(Text).attrs({ variant: 'description' })`
  color: var(--gray-6-color);
`

export const Subtitle = styled(Text).attrs({ variant: 'h4' })`
  margin-bottom: 16px;
`
export const SubtitleFormItem = styled(Text).attrs({ variant: 'h4' })`
  margin-bottom: 16px;
`

export const AgentFormWrapper = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-column-gap: 24px;
  grid-row-gap: 16px;
`
export const AgentFormTitle = styled(Text).attrs({ variant: 'normal' })`
  color: var(--gray-6-color);
  margin-bottom: 16px;
`
export const AgentFormValue = styled(Text).attrs({ variant: 'normal' })``
