import React from 'react'

import { Tooltip } from 'antd'

import { appRoutes } from '~/router'
import { useGetDevice } from '~hooks/common'

import * as S from './AppMenuItems.styled'

interface IAppMenuItemProps {
  url: string
  title: string
  icon?: JSX.Element
  search?: string
}

export const AppMenuItem: React.FC<IAppMenuItemProps> = ({ url, title, icon }) => {
  const device = useGetDevice()
  const isMobile = device === 'mobile'

  return (
    <S.StyledNavLink to={url}>
      <Tooltip title={title} placement="right">
        {icon}
      </Tooltip>
      <S.MenuText>
        {title} {isMobile && url === appRoutes.basis.url ? <S.NewLabel>Новое</S.NewLabel> : undefined}
      </S.MenuText>
    </S.StyledNavLink>
  )
}
