import { ProductData, ProductParameter, ProductTypeData } from '~api/generated'
import { useGetProductTypes } from '~hooks/products/useGetProductTypes'

export const useGetProductTypeData = () => {
  const { data: products } = useGetProductTypes()

  const getProductType = (productType: string): ProductTypeData => {
    const foundProduct = products.find((x) => x.type === productType)
    if (!foundProduct) {
      throw new Error(`Product with type: "${productType}" was not found`)
    }
    return foundProduct
  }
  const getProductTypeParameter = (productType: string, parameterType: string): ProductParameter => {
    const foundProductParameter = getProductType(productType).parameters.find((x) => x.type === parameterType)
    if (!foundProductParameter) {
      throw new Error(`Product parameter with type: "${productType}:${parameterType}" was not found`)
    }
    return foundProductParameter
  }
  const getProductTypeParameters = (productTypeId: string, parameterTypes: string[]): ProductParameter[] =>
    parameterTypes.map((parameterType) => getProductTypeParameter(productTypeId, parameterType))

  const getProductTypeParametersForUI = (productTypeId: string, parameterType: string, value: number) => {
    const { name = '', measurementUnit = {} } = getProductTypeParameter(productTypeId, parameterType) ?? {}
    return `${name} ${value}${measurementUnit}`
  }

  const getProductTypeParametersForUI_V2 = (product?: ProductData): string[] => {
    if (!product) {
      return []
    }

    const params = getProductTypeParametersForUI_V3(product)
    return params.map(({ parameterName, value, measurementUnit }) => `${parameterName}: ${value}${measurementUnit}`)
  }

  return {
    getProductType,
    getProductTypeParameter,
    getProductTypeParameters,
    getProductTypeParametersForUI,
    getProductTypeParametersForUI_V2,
    getProductTypeParametersForUI_V3,
  }
}

export const getProductTypeParametersForUI_V3 = (product?: ProductData) => {
  if (!product) {
    return []
  }
  return product.parameters.map((parameter) => {
    const isYear = parameter.type === 'crop_year'
    const value = Number(parameter.value).toFixed(isYear ? 0 : 1)
    const measurementUnit =
      parameter.measurementUnit === '%'
        ? `${parameter.measurementUnit}`
        : parameter.measurementUnit === null
        ? ''
        : ` ${parameter.measurementUnit}`
    return {
      parameterName: parameter.name,
      value,
      measurementUnit,
    }
  })
}
