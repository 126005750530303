import React from 'react'

import { Button, Pagination, Table } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import uniq from 'lodash/uniq'
import { useNavigate } from 'react-router-dom'

import { useGetTableHeight, usePagination } from '~/hooks'
import { Badge } from '~/ui-components'
import { appToast, httpErrorHandler } from '~/utils'
import { formatNumber } from '~/utils/formatNumber'
import { getDateFormat } from '~/utils/getDateFormat'
import { getNdsString } from '~/utils/getNdsString'
import { getOfferType } from '~/utils/offers/get-offer-type'
import { ModeratedAuctionData, ProductData } from '~api/generated'
import { useGetModeratedAuctions, useUpdateAuctionModerator } from '~hooks/auctions'
import { useGetUsers } from '~hooks/user/useGetUsers'
import { useAuth } from '~providers/auth'
import { actionArrowColumn } from '~shared/columns/shared'

import * as S from './ModeratorAuctions.styled'

export const ModeratorAuctions: React.FC = () => {
  const navigate = useNavigate()
  const { userId } = useAuth()
  const { currentPage, setCurrentPage, pageSize, setPageSize, pageSizeOptions } = usePagination()
  const tableHeight = useGetTableHeight(248)
  const {
    data: moderatedAuctionsData,
    isFetching: isFetchingModeratedAuction,
    refetch: refetchModeratedAuction,
  } = useGetModeratedAuctions({
    currentPage,
    pageSize,
  })

  const moderatedAuctions = moderatedAuctionsData?.moderatedAuctions
  const pagination = moderatedAuctionsData?.pagination

  const userIds = uniq(moderatedAuctions?.map((auction) => auction.moderatedBy)).filter(Boolean) as number[]
  const { data: users } = useGetUsers({ userIds, pageSize: 100 })
  const usersMap = new Map(users?.map((user) => [user.id, user]))
  const updateAuctionModerator = useUpdateAuctionModerator()

  const handleRowClick = (record: ModeratedAuctionData) => {
    navigate(`${record.id}`)
  }

  const takeAuctionToWorkHandler = async (err: React.MouseEvent, AuctionId: number) => {
    err.stopPropagation()
    if (userId === null) {
      return
    }

    try {
      await updateAuctionModerator.mutateAsync({
        auctionId: AuctionId,
        updateAuctionModeratorBody: {
          moderatorId: userId,
        },
      })

      appToast.success({ description: `Вы взяли в работу лот ${AuctionId}` })
    } catch (e) {
      httpErrorHandler(e, 'Ошибка при обновлении модератора лота')
    }
    await refetchModeratedAuction()
  }

  const columns: ColumnsType<ModeratedAuctionData> = [
    {
      title: 'Наименование культуры',
      dataIndex: ['offer', 'product'],
      width: 200,
      className: 'bold-cell',
      render: (product: ProductData) => {
        return product.name
      },
    },
    {
      title: '№',
      dataIndex: 'id',
      width: 50,
    },
    {
      title: 'Предложение',
      dataIndex: ['offer', 'type'],
      width: 120,
      render: (value) => {
        const offerType = getOfferType(value)
        return (
          <Badge color={offerType.color} size="s">
            {offerType.name}
          </Badge>
        )
      },
    },
    {
      title: 'Объем, т',
      dataIndex: ['offer', 'volume'],
      align: 'right',
      width: 100,
      className: 'bold-cell',
      render: (volume) => {
        return formatNumber(volume)
      },
    },
    {
      title: 'Минимальная ставка ₽/т',
      dataIndex: ['offer', 'price'],
      align: 'right',
      width: 120,
      className: 'bold-cell',
      render: (price) => {
        return formatNumber(price)
      },
    },
    {
      title: 'НДС',
      dataIndex: ['offer', 'includingNds'],
      width: 100,
      render: (includingNds) => {
        return getNdsString(includingNds.includingNds)
      },
    },
    {
      title: 'Локация',
      dataIndex: ['offer', 'address'],
    },
    {
      title: 'Дата создания',
      dataIndex: 'createdAt',
      width: 120,
      render: (createdAt) => {
        return createdAt ? getDateFormat(new Date(createdAt)) : ''
      },
    },
    {
      title: 'Модератор',
      dataIndex: 'moderatedBy',
      render: (moderatedBy, record) => {
        if (moderatedBy) {
          const user = usersMap.get(moderatedBy)
          return user?.name ?? ''
        }
        return (
          <Button type="primary" onClick={(event) => takeAuctionToWorkHandler(event, record.id)}>
            Взять в работу
          </Button>
        )
      },
    },
    actionArrowColumn,
  ]
  return (
    <S.ContentWrapper>
      <Table
        rowKey="id"
        className="table-interactive"
        loading={isFetchingModeratedAuction}
        columns={columns}
        dataSource={moderatedAuctions}
        pagination={false}
        scroll={{ x: moderatedAuctions?.length ? 'max-content' : 'auto', y: tableHeight }}
        onRow={(record, index) => ({
          onClick: handleRowClick.bind(null, record, index),
        })}
      />
      {pagination && (
        <Pagination
          current={pagination?.currentPage}
          total={pagination?.total}
          pageSize={pagination?.pageSize}
          pageSizeOptions={pageSizeOptions}
          onShowSizeChange={(x, size) => setPageSize(size)}
          showSizeChanger
          onChange={setCurrentPage}
        />
      )}
    </S.ContentWrapper>
  )
}
