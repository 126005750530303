import React, { useState } from 'react'

import { Button, Modal, Select } from 'antd'
import { FiPlus } from 'react-icons/fi'

import { Card } from '~/ui-components'
import { appToast, modalSharedSettings } from '~/utils'
import { DealSideType } from '~/utils/deals/get-deal-side'
import { useCreateBankAccount, useGetCompanyBankAccounts } from '~hooks/company'
import { BankInfoForm } from '~pages/ProfilePage/components/tabs/CompanyProfileTab/components/BankInfoForm'

import { IBankInfoForm } from '~pages/ProfilePage/components/tabs/CompanyProfileTab/components/BankInfoForm/BankInfoForm.types'

import { ButtonConfirm, CardContent, SelectWrapper } from './BankInfo.styled'

interface IDealBankInfoProps {
  companyId: number
  onUpdateBankAccount: (bankAccountId: number) => void
  dealSide: DealSideType
}

export const BankInfo: React.FC<IDealBankInfoProps> = ({ companyId, onUpdateBankAccount, dealSide }) => {
  const [selectedBankAccountId, setSelectedBankAccountId] = useState<number | null>(null)
  const bankAccounts = useGetCompanyBankAccounts(companyId)
  const createBankAccount = useCreateBankAccount(companyId)
  const [isModalVisible, setIsModalVisible] = useState(false)

  const bankAccountsOptions =
    bankAccounts.data?.map((x) => ({
      value: x.id,
      label: x.name?.concat([' **', x?.paymentAccount?.slice(-4) ?? ''].join('')),
    })) ?? []

  const onSelectHandler = (value: number | null) => {
    if (!value) {
      setIsModalVisible(true)
      return
    }
    onSelectBankAccountHandler(value)
  }

  const handleModalClose = () => {
    setIsModalVisible(false)
  }

  const handleModalOpen = () => {
    setIsModalVisible(true)
  }

  const handleSubmitChanges = async (formValues: IBankInfoForm) => {
    try {
      await createBankAccount.mutateAsync({
        name: formValues.bankName,
        correspondentAccount: formValues.correspondentAccountNumber,
        paymentAccount: formValues.accountNumber,
        bic: formValues.bik,
      })
      appToast.success({ description: 'Банковские реквизиты обновлены' })
      await bankAccounts.refetch()
      handleModalClose()
    } catch (e) {
      const description = 'Ошибка при обновлении банковских реквизитов'
      console.error(description, e)
      appToast.error({ description })
    }
  }
  const onSelectBankAccountHandler = (bankAccountId: number) => {
    setSelectedBankAccountId(bankAccountId)
  }
  // const message = getMessage(dealSide)

  return (
    <>
      <Card style={{ overflow: 'hidden' }}>
        <Card.Header>
          <Card.Title>Ваши банковские реквизиты</Card.Title>
        </Card.Header>
        <CardContent>
          <SelectWrapper>
            <Select
              style={{ width: '100%' }}
              loading={bankAccounts.isLoading || bankAccounts.isFetching}
              onSelect={onSelectHandler}
              placeholder="Выберите реквизиты"
            >
              {bankAccountsOptions.map((option) => (
                <Select.Option key={option.value} value={option.value}>
                  {option.label}
                </Select.Option>
              ))}
            </Select>
          </SelectWrapper>
          <Button icon={<FiPlus />} onClick={handleModalOpen}>
            Добавить реквизиты
          </Button>
          <ButtonConfirm
            type="primary"
            disabled={!selectedBankAccountId}
            onClick={() => !!selectedBankAccountId && onUpdateBankAccount(selectedBankAccountId)}
          >
            Подтвердить
          </ButtonConfirm>
        </CardContent>
      </Card>
      <Modal
        title="Банковские реквизиты"
        open={isModalVisible}
        onCancel={handleModalClose}
        {...modalSharedSettings}
        footer={false}
      >
        <BankInfoForm onSubmit={handleSubmitChanges} isLoading={createBankAccount.isLoading} />
      </Modal>
    </>
  )
}
