import React, { ChangeEvent, FC, useEffect, useRef, useState } from 'react'

import { Button } from 'antd'
import { FiFile, FiUpload, FiX } from 'react-icons/fi'

import { UploadButtonProps } from './UploadButton.types'

import * as S from './UploadButton.styled'

export const UploadButton: FC<UploadButtonProps> = ({
  text = 'Загрузить',
  id,
  name,
  fileName = null,
  accept,
  disabled,
  isReadOnly,
  onDelete,
  onFileClick,
  onChange,
}) => {
  const [localFileName, setLocalFileName] = useState<string | null>(null)
  const fileInputRef = useRef<HTMLInputElement | null>(null)

  useEffect(() => {
    setLocalFileName(fileName)
  }, [fileName])

  const handleUploadButtonClick = () => {
    fileInputRef.current?.click()
  }

  const handleFileButtonClick = () => {
    onFileClick?.()
  }

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    onChange?.(event)

    const files = event.target.files
    if (files?.length) {
      setLocalFileName(files[0].name)
    }
  }

  const handleDeleteButtonClick = () => {
    onDelete?.()

    if (fileInputRef.current?.value) {
      fileInputRef.current.value = ''
    }
    setLocalFileName(null)
  }

  return (
    <>
      <S.Input id={id} ref={fileInputRef} type="file" name={name} accept={accept} onChange={handleInputChange} />

      {localFileName ? (
        <S.File>
          <S.FIleButton
            htmlType="button"
            onClick={handleFileButtonClick}
            disabled={!fileName || disabled}
            icon={<FiFile />}
            type="link"
          >
            {localFileName}
          </S.FIleButton>
          {!isReadOnly && (
            <S.DeleteButton type="button" onClick={handleDeleteButtonClick}>
              <FiX size={16} />
            </S.DeleteButton>
          )}
        </S.File>
      ) : (
        <Button
          type="default"
          htmlType="button"
          onClick={handleUploadButtonClick}
          disabled={disabled}
          icon={<FiUpload />}
        >
          {text}
        </Button>
      )}
    </>
  )
}
