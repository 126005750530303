import { useMutation } from '@tanstack/react-query'

import { OfferApiOfferControllerUpdateOfferModeratorRequest } from '~/api/generated'
import { useApi } from '~providers/api'

export const useUpdateOfferModerator = () => {
  const api = useApi()

  return useMutation((request: OfferApiOfferControllerUpdateOfferModeratorRequest) => {
    return api.offers.offerControllerUpdateOfferModerator(request)
  })
}
