import { isEqual } from 'lodash'
import { makeAutoObservable } from 'mobx'

import { AppConfig } from '~/appConfig'
import { getActiveFilters } from '~/store/filters/_utils'
import { DealApiDealControllerGetCompanyDealsRequest } from '~api/generated'
import { IDealsFilterForm } from '~pages/Deals/components/DealsFilter/components/DealsFilterForm'

// Filter panel shows this values after first open
const defaultFiltersConfig: IDealsFilterForm = {
  dealType: 'ALL',
  volume: {
    from: AppConfig.FILTERS.volume.from,
    to: AppConfig.FILTERS.volume.to,
  },
  price: {
    from: AppConfig.FILTERS.price.from,
    to: AppConfig.FILTERS.price.to,
  },
  status: undefined,
  regionIds: [],
}

class DealsFilterStore {
  private filters: IDealsFilterForm

  constructor() {
    this.filters = defaultFiltersConfig
    makeAutoObservable(this)
  }

  setFilters(newFilters: IDealsFilterForm): void {
    this.filters = newFilters
  }

  get dealsFilters(): IDealsFilterForm {
    return this.filters
  }

  get defaultFiltersConfig(): IDealsFilterForm {
    return defaultFiltersConfig
  }

  get isCurrentFilterDefault(): boolean {
    return isEqual(this.filters, defaultFiltersConfig)
  }

  get mappedCurrentFiltersToResponse(): Partial<DealApiDealControllerGetCompanyDealsRequest> {
    return this.isCurrentFilterDefault
      ? {}
      : {
          minVolume: this.filters.volume.from,
          maxVolume: this.filters.volume.to,
          minPrice: this.filters.price.from,
          maxPrice: this.filters.price.to,
          type: this.filters.dealType === 'ALL' ? undefined : this.filters.dealType,
          statuses: this.filters?.status ? [this.filters.status] : undefined,
          regionIds: this.filters.regionIds,
        }
  }

  get activeFilters(): string[] {
    return getActiveFilters(this.filters, defaultFiltersConfig)
  }

  get hasActiveFilters(): boolean {
    return this.activeFilters.length > 0
  }

  get activeFiltersCount(): number {
    return this.activeFilters.length
  }
}

export const dealsFilterStore = new DealsFilterStore()
