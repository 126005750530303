import { useMutation } from '@tanstack/react-query'

import { OfferApiOfferControllerUpdateOfferStatusRequest } from '~api/generated'
import { useApi } from '~providers/api'

export const useUpdateOfferStatus = () => {
  const api = useApi()

  return useMutation((request: OfferApiOfferControllerUpdateOfferStatusRequest) => {
    return api.offers.offerControllerUpdateOfferStatus(request)
  })
}
