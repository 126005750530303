/* tslint:disable */
/* eslint-disable */
/**
 * API Зерно-Агрегатора
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { BaseUserData } from './base-user-data';
import { ContactPersonData } from './contact-person-data';
import { CreatedByData } from './created-by-data';
import { FileData } from './file-data';
import { OfferActionData } from './offer-action-data';
import { OfferCompanyData } from './offer-company-data';
import { OfferDealData } from './offer-deal-data';
import { OfferModeratorData } from './offer-moderator-data';
import { OfferPriceDetail } from './offer-price-detail';
import { ProductData } from './product-data';

/**
 * 
 * @export
 * @interface GetOfferResponse
 */
export interface GetOfferResponse {
    /**
     * 
     * @type {number}
     * @memberof GetOfferResponse
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof GetOfferResponse
     */
    'type': GetOfferResponseTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof GetOfferResponse
     */
    'status': GetOfferResponseStatusEnum;
    /**
     * 
     * @type {number}
     * @memberof GetOfferResponse
     */
    'volume': number;
    /**
     * 
     * @type {number}
     * @memberof GetOfferResponse
     */
    'price': number;
    /**
     * Подробная информация о цене
     * @type {OfferPriceDetail}
     * @memberof GetOfferResponse
     */
    'priceDetail': OfferPriceDetail | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetOfferResponse
     */
    'includingNds': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetOfferResponse
     */
    'shippingRequired': boolean;
    /**
     * 
     * @type {string}
     * @memberof GetOfferResponse
     */
    'address': string;
    /**
     * 
     * @type {number}
     * @memberof GetOfferResponse
     */
    'distance': number | null;
    /**
     * Товар
     * @type {ProductData}
     * @memberof GetOfferResponse
     */
    'product': ProductData;
    /**
     * 
     * @type {FileData}
     * @memberof GetOfferResponse
     */
    'laboratoryAnalysis': FileData | null;
    /**
     * Примечание к предложению
     * @type {string}
     * @memberof GetOfferResponse
     */
    'note': string | null;
    /**
     * 
     * @type {ContactPersonData}
     * @memberof GetOfferResponse
     */
    'contactPerson': ContactPersonData | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetOfferResponse
     */
    'isOwn': boolean;
    /**
     * Время публикации
     * @type {string}
     * @memberof GetOfferResponse
     */
    'publishedAt': string | null;
    /**
     * Время окончания публикации
     * @type {string}
     * @memberof GetOfferResponse
     */
    'expiredAt': string | null;
    /**
     * Дата создания предложения
     * @type {string}
     * @memberof GetOfferResponse
     */
    'createdAt': string | null;
    /**
     * Информация о пользователе создавшем предложение
     * @type {BaseUserData}
     * @memberof GetOfferResponse
     */
    'user'?: BaseUserData | null;
    /**
     * Компания, создавшая предложение
     * @type {OfferCompanyData}
     * @memberof GetOfferResponse
     */
    'company': OfferCompanyData | null;
    /**
     * Модератор, проверяющий предложение
     * @type {OfferModeratorData}
     * @memberof GetOfferResponse
     */
    'moderator': OfferModeratorData | null;
    /**
     * Следующие возможные статусы
     * @type {Array<string>}
     * @memberof GetOfferResponse
     */
    'nextStatuses': Array<GetOfferResponseNextStatusesEnum>;
    /**
     * Id комплексной сделки, связанной с предложением
     * @type {number}
     * @memberof GetOfferResponse
     */
    'complexDealId': number | null;
    /**
     * Сделка компании, связанная с предложением
     * @type {OfferDealData}
     * @memberof GetOfferResponse
     */
    'deal': OfferDealData | null;
    /**
     * Создатель предложения
     * @type {CreatedByData}
     * @memberof GetOfferResponse
     */
    'createdBy': CreatedByData | null;
    /**
     * Действия с предложением
     * @type {Array<OfferActionData>}
     * @memberof GetOfferResponse
     */
    'actions': Array<OfferActionData>;
}

export const GetOfferResponseTypeEnum = {
    Buy: 'BUY',
    Sell: 'SELL'
} as const;

export type GetOfferResponseTypeEnum = typeof GetOfferResponseTypeEnum[keyof typeof GetOfferResponseTypeEnum];
export const GetOfferResponseStatusEnum = {
    OnModeration: 'ON_MODERATION',
    PrePublished: 'PRE_PUBLISHED',
    Published: 'PUBLISHED',
    PreDeal: 'PRE_DEAL',
    InDeal: 'IN_DEAL',
    Expired: 'EXPIRED',
    Rejected: 'REJECTED',
    Realised: 'REALISED'
} as const;

export type GetOfferResponseStatusEnum = typeof GetOfferResponseStatusEnum[keyof typeof GetOfferResponseStatusEnum];
export const GetOfferResponseNextStatusesEnum = {
    OnModeration: 'ON_MODERATION',
    PrePublished: 'PRE_PUBLISHED',
    Published: 'PUBLISHED',
    PreDeal: 'PRE_DEAL',
    InDeal: 'IN_DEAL',
    Expired: 'EXPIRED',
    Rejected: 'REJECTED',
    Realised: 'REALISED'
} as const;

export type GetOfferResponseNextStatusesEnum = typeof GetOfferResponseNextStatusesEnum[keyof typeof GetOfferResponseNextStatusesEnum];


