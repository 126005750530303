import React, { PropsWithChildren, useState } from 'react'

import { Modal } from 'antd'

import { EmptyButton } from '~/ui-components'
import { useCreateBankAccount, useGetCompanyBankAccounts, useUpdateBankAccount } from '~hooks/company'
import { useRemoveBankAccount } from '~hooks/company/useRemoveBankAccount'
import { ConfirmBankDetailsRemoveModal } from '~pages/ProfilePage/components/tabs/CompanyProfileTab/components/ConfirmBankDetailsRemoveModal'

import { appToast, modalSharedSettings } from '../../../../../../../utils'
import { BankInfoForm } from '../BankInfoForm'
import { BankInfoView } from '../BankInfoView'

import { IBankInfoForm } from '../BankInfoForm/BankInfoForm.types'

import * as TabStyles from '../../../../tabs/CompanyProfileTab/CompanyProfileTab.styled'

const ParentSection: React.FC<PropsWithChildren> = ({ children }) => <TabStyles.Section>{children}</TabStyles.Section>

interface IBankInfoSectionProps {
  companyId: number
}

export const BankInfoSection: React.FC<IBankInfoSectionProps> = ({ companyId }) => {
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [bankIdForRemove, setBankIdForRemove] = useState<number | null>(null)
  const [selectedForEditId, setSelectedForEditId] = useState<number | null>(null)
  const bankAccounts = useGetCompanyBankAccounts(companyId)
  const updateBankAccount = useUpdateBankAccount()
  const removeBankAccount = useRemoveBankAccount()
  const createBankAccount = useCreateBankAccount(companyId ?? NaN)

  const handleModalClose = () => {
    setIsModalVisible(false)
    setSelectedForEditId(null)
  }

  const handleModalOpen = (bankAccountId?: number) => () => {
    if (!companyId) {
      appToast.info({ description: 'Сначала заполните данные о компании' })
    } else {
      setIsModalVisible(true)
      setSelectedForEditId(bankAccountId ?? null)
    }
  }

  const handleBankInfoRemove = async (bankAccountId: number) => {
    try {
      await removeBankAccount.mutateAsync({ bankAccountId })
      appToast.success({ description: 'Банковские реквизиты удалены' })
    } catch (e) {
      const description = 'Ошибка при удалении банковских реквизитов'
      console.error(description, e)
      appToast.error({ description })
    }
    await bankAccounts.refetch()
    setBankIdForRemove(null)
  }

  const handleSubmitChanges = async (formValues: IBankInfoForm) => {
    try {
      if (selectedForEditId) {
        await updateBankAccount.mutateAsync({
          bankAccountId: selectedForEditId,
          updateBankAccountBody: {
            name: formValues.bankName,
            correspondentAccount: formValues.correspondentAccountNumber,
            paymentAccount: formValues.accountNumber,
            bic: formValues.bik,
          },
        })
      } else {
        await createBankAccount.mutateAsync({
          name: formValues.bankName,
          correspondentAccount: formValues.correspondentAccountNumber,
          paymentAccount: formValues.accountNumber,
          bic: formValues.bik,
        })
      }
      appToast.success({ description: 'Банковские реквизиты обновлены' })
    } catch (e) {
      const description = 'Ошибка при обновлении банковских реквизитов'
      console.error(description, e)
      appToast.error({ description })
    }

    await bankAccounts.refetch()
    handleModalClose()
  }

  if (bankAccounts.isLoading) {
    return (
      <ParentSection>
        <EmptyButton>Загрузка...</EmptyButton>
      </ParentSection>
    )
  }

  if (bankAccounts.isError) {
    return <EmptyButton>Ошибка</EmptyButton>
  }

  return (
    <>
      {bankAccounts.data?.map((bankAccount) => (
        <ParentSection key={bankAccount.id}>
          <BankInfoView
            onChange={handleModalOpen(bankAccount.id)}
            onRemove={() => setBankIdForRemove(bankAccount.id)}
            data={bankAccount}
          />
        </ParentSection>
      ))}

      <ParentSection>
        <EmptyButton onClick={handleModalOpen()}>Добавить банковские реквизиты</EmptyButton>
      </ParentSection>

      <ConfirmBankDetailsRemoveModal
        text={`Вы действительно хотите удалить банковские реквизиты "${
          bankAccounts.data?.find((x) => x.id === bankIdForRemove)?.name ?? ''
        }"`}
        isOpen={!!bankIdForRemove}
        onConfirm={() => handleBankInfoRemove(bankIdForRemove!)}
        onCancel={() => setBankIdForRemove(null)}
      />

      <Modal open={isModalVisible} title="Банковские реквизиты" onCancel={handleModalClose} {...modalSharedSettings}>
        <BankInfoForm
          onSubmit={handleSubmitChanges}
          isLoading={updateBankAccount.isLoading}
          defaultValues={bankAccounts.data?.find((x) => x.id === selectedForEditId)}
        />
      </Modal>
    </>
  )
}
