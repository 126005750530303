import { codeUnreachable } from '~/utils/code-unreachable'
import { FreightDealStatus } from '~api/gql-generated/graphql'

import { BadgeVariant } from '~ui-components/components/Badge/Badge.types'

export function getFreightResponseStatusColor(value: FreightDealStatus): BadgeVariant {
  switch (value) {
    case FreightDealStatus.Agreement:
      return 'base'
    case FreightDealStatus.OnModeration:
      return 'yellow'
    case FreightDealStatus.Realised:
      return 'base'
    case FreightDealStatus.Rejected:
      return 'base'
    case FreightDealStatus.RejectedAfterModeration:
      return 'red'
    case FreightDealStatus.Shipment:
      return 'purple'
    default:
      return codeUnreachable(value)
  }
}
