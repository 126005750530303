import React from 'react'

import { Controller, useForm } from 'react-hook-form'
import { FiAlertCircle } from 'react-icons/fi'

import { yupResolver } from '@hookform/resolvers/yup/dist/yup'

import { FormField, Text, UploadButton } from '~/ui-components'
import { ParametersCultureControl, PriceControl, PriceTypeControl, UsingNdsControl } from '~shared/controls'

import { editAdvertFormByAdminSchema } from './EditAdvertOfferFormByAdmin.validation'

import { EditAdvertOfferFormByAdminProps, EditAdvertOfferFormByAdminValues } from './EditAdvertOfferFormByAdmin.types'

import * as S from './EditAdvertOfferFormByAdmin.styled'

export const EditAdvertOfferFormByAdmin: React.FC<EditAdvertOfferFormByAdminProps> = ({
  onSubmit,
  formId,
  defaultValues,
  productData,
  allowOffersWithOtherParameters,
}) => {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<EditAdvertOfferFormByAdminValues>({
    defaultValues: {
      culture: defaultValues?.culture,
      pricePerTon: defaultValues?.pricePerTon,
      usingNds: defaultValues?.usingNds ?? false,
      priceType: defaultValues?.priceType,
      labAnalysis: defaultValues?.labAnalysis,
    },
    resolver: yupResolver(editAdvertFormByAdminSchema),
  })

  const onSubmitHandler = (formValues: EditAdvertOfferFormByAdminValues) => {
    onSubmit(formValues)
  }

  return (
    <form id={formId} onSubmit={handleSubmit(onSubmitHandler)}>
      <Text variant="h4">{productData?.name}</Text>

      {allowOffersWithOtherParameters !== undefined && (
        <S.ParametersInfo isAllow={allowOffersWithOtherParameters}>
          <FiAlertCircle size="24" />
          <Text variant="normal">
            {allowOffersWithOtherParameters
              ? 'Покупатель также рассматривает культуру с другими показателями'
              : 'Показатели вашей культуры должны совпадать с указанными в объявлении'}
          </Text>
        </S.ParametersInfo>
      )}

      <FormField validateMessage={errors.culture ? 'Параметры культуры заполнены с ошибками' : ''}>
        <Controller
          name="culture"
          control={control}
          render={({ field }) => <ParametersCultureControl isRequired {...field} isSelectDisabled isShowOnlyParams />}
        />
      </FormField>

      <FormField label="Анализная карта" validateMessage={errors.labAnalysis?.message} isRequired>
        <Controller
          control={control}
          name="labAnalysis"
          render={({ field }) => (
            <UploadButton
              fileName="Лаб. анализ"
              onFileClick={() => window.open(defaultValues?.labAnalysis, '_blank')}
              onChange={(evt) => {
                if (evt.target.files?.length) {
                  field.onChange(evt.target.files[0])
                }
              }}
              onDelete={() => field.onChange(undefined)}
            >
              Загрузить
            </UploadButton>
          )}
        />
      </FormField>

      <S.PriceControlWrapper>
        <FormField
          htmlFor="pricePerTon"
          label="Предлагаемая цена"
          validateMessage={errors.pricePerTon?.message}
          isRequired
        >
          <Controller
            name="pricePerTon"
            control={control}
            render={({ field }) => (
              <PriceControl id="pricePerTon" {...field} hasError={!!errors.pricePerTon} addAfter="₽/т" />
            )}
          />
        </FormField>

        <FormField htmlFor="usingNds" validateMessage={errors.usingNds?.message} label="&nbsp;">
          <Controller name="usingNds" control={control} render={({ field }) => <UsingNdsControl {...field} />} />
        </FormField>
      </S.PriceControlWrapper>

      <FormField htmlFor="priceType" validateMessage={errors.priceType?.message} label="Тип стоимости" isRequired>
        <Controller name="priceType" control={control} render={({ field }) => <PriceTypeControl {...field} />} />
      </FormField>
    </form>
  )
}
