import React, { useState } from 'react'

import { Button, Modal, Table, Upload } from 'antd'
import { ColumnsType } from 'antd/lib/table'

import { useUpdateProductImage, useUpdateProductTypeParameter } from '~/hooks'
import { appToast, httpErrorHandler, modalSharedSettings } from '~/utils'
import { ProductParameter, ProductTypeData } from '~api/generated'
import { useUploadFileAndGetId } from '~hooks/_utils'
import { useGetProductTypes } from '~hooks/products/useGetProductTypes'
import { CultureParameterEditForm } from '~pages/SettingsPage/modules/CultureParametersTab/components/CultureParameterEditForm'

import { ICultureParameterEditFormValues } from '~pages/SettingsPage/modules/CultureParametersTab/components/CultureParameterEditForm/CultureParameterEditForm.types'

export const CultureParametersTable: React.FC = () => {
  const { data: productTypes, isFetching, refetch: refetchProduct } = useGetProductTypes()
  const uploadFileAndGetId = useUploadFileAndGetId()
  const updateProductImage = useUpdateProductImage()
  const updateProductTypeParameter = useUpdateProductTypeParameter()
  const [selectedParameterForEdit, setSelectedParameter] = useState<{
    productType: string
    parameterType: string
  } | null>(null)

  const handleChangeProductParameters = async (formValues: ICultureParameterEditFormValues) => {
    try {
      await updateProductTypeParameter.mutateAsync({
        productType: formValues.productType,
        parameterType: formValues.parameterType,
        updateProductTypeParameterBody: {
          name: formValues.name,
          measurementUnit: formValues?.measurementUnit ?? null,
          minimum: formValues.minimum?.toString() ?? null,
          maximum: formValues.maximum?.toString() ?? null,
          gost: formValues?.gost ?? null,
          required: formValues.required,
        },
      })
      setSelectedParameter(null)
      void refetchProduct()
      appToast.success({ description: 'Параметры культуры изменены' })
    } catch (e) {
      httpErrorHandler(e, 'Ошибка при изменении параметров культуры')
    }
  }

  const handleProductImageChange = async (productTypeId: string, productTypePicture: File) => {
    try {
      const productTypePictureFileKey = await uploadFileAndGetId.mutateAsync(productTypePicture)

      await updateProductImage.mutateAsync({
        productType: productTypeId,
        updateProductTypePictureBody: {
          productTypePictureFileKey,
        },
      })
      void refetchProduct()
      appToast.success({ description: 'Параметры культуры изменены' })
    } catch (e) {
      httpErrorHandler(e, 'Ошибка при изменении параметров культуры')
    }
  }

  const handleClickOnParameterRow = (product: ProductTypeData, parameter: ProductParameter) => {
    setSelectedParameter({ productType: product.type, parameterType: parameter.type })
  }

  const columns: ColumnsType<ProductTypeData> = [
    {
      title: 'type',
      dataIndex: 'type',
      key: 'type',
      sorter: (a, b) => a.type.localeCompare(b.type),
    },
    {
      title: 'Название',
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: 'Картинка',
      dataIndex: ['picture', 'source'],
      key: 'source',
      width: 80,
      render: (source: string) => {
        return <img width={50} height={50} src={source} alt="" />
      },
    },
    {
      title: '',
      dataIndex: 'id',
      key: 'id',
      width: 100,
      render: (source: string, record) => {
        return (
          <div onClick={(evt) => evt.stopPropagation()}>
            <Upload
              showUploadList={false}
              accept=".png, .jpg, .jpeg"
              customRequest={(r) => handleProductImageChange(record.type, r.file as File)}
            >
              <Button>Изменить картинку</Button>
            </Upload>
          </div>
        )
      },
    },
  ]

  const expandedRowRender = (productTypeItem: ProductTypeData) => {
    const columns: ColumnsType<ProductParameter> = [
      { title: 'Название параметра', dataIndex: 'name', key: 'name', sorter: (a, b) => a.name.localeCompare(b.name) },
      {
        title: 'Единица измерения',
        dataIndex: 'measurementUnit',
        key: 'measurementUnit',
        sorter: (a, b) =>
          a?.measurementUnit && b?.measurementUnit ? a.measurementUnit.localeCompare(b.measurementUnit) : 0,
      },
      {
        title: 'Минимум',
        dataIndex: 'minimum',
        key: 'minimum',
      },
      { title: 'Максимум', dataIndex: 'maximum', key: 'maximum' },
      {
        title: 'ГОСТ',
        dataIndex: 'gost',
        key: 'gost',
      },
      { title: 'Обязательность', dataIndex: 'required', key: 'required', render: (v) => (!v ? 'нет' : 'да') },
      { title: 'type', dataIndex: 'type', key: 'type', sorter: (a, b) => a.type.localeCompare(b.type) },
    ]
    return (
      <Table
        columns={columns}
        dataSource={productTypeItem.parameters}
        pagination={false}
        onRow={(record) => {
          return {
            onClick: (event) => handleClickOnParameterRow(productTypeItem, record),
          }
        }}
      />
    )
  }

  return (
    <>
      <Table
        rowKey="type"
        className="table-interactive"
        loading={isFetching}
        columns={columns}
        expandable={{ expandedRowRender, expandRowByClick: true }}
        dataSource={productTypes}
        scroll={{ x: 'max-content' }}
        pagination={{ defaultPageSize: 100 }}
      />

      {selectedParameterForEdit && (
        <Modal
          {...modalSharedSettings}
          width={500}
          open={!!selectedParameterForEdit}
          onCancel={() => setSelectedParameter(null)}
          title="Редактирование параметров культуры"
        >
          <CultureParameterEditForm
            productType={selectedParameterForEdit.productType}
            parameterType={selectedParameterForEdit.parameterType}
            onSubmit={handleChangeProductParameters}
          />
        </Modal>
      )}
    </>
  )
}
