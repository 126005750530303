import React, { ChangeEvent, useState } from 'react'

import { Input } from 'antd'
import { SearchProps } from 'antd/es/input/Search'

interface ISearchInputProps extends SearchProps {
  defaultValue: string
  onSearch: (value: string) => void
}

export const SearchInput: React.FC<ISearchInputProps> = ({
  defaultValue,
  onSearch,
  placeholder = 'Поиск по №',
  ...inputProps
}) => {
  const [value, setValue] = useState<string>(defaultValue)
  const onChangeHandler = (e: ChangeEvent<HTMLInputElement>) => setValue(e.target.value)
  const onSearchByIdHandler = (searchQuery: string) => onSearch(searchQuery)

  return (
    <Input.Search
      allowClear
      value={value}
      placeholder={placeholder}
      onSearch={onSearchByIdHandler}
      onChange={onChangeHandler}
      {...inputProps}
    />
  )
}
