import styled from 'styled-components'

export const ChatMobileMenuItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 18px 16px;
  cursor: pointer;

  background: #f5faf6;

  margin-left: -16px;
  margin-right: -16px;

  * {
    color: #45a67d;
  }
`
