import { useQuery, UseQueryResult } from '@tanstack/react-query'

import { ElevatorAdvertApiGetSimilarElevatorOffersRequest, GetSimilarElevatorOffersResponse } from '~api/generated'
import { useApi } from '~providers/api'

export const useGetSimilarElevatorsAdverts = (
  request: ElevatorAdvertApiGetSimilarElevatorOffersRequest
): UseQueryResult<GetSimilarElevatorOffersResponse> => {
  const api = useApi()

  const fetchFn = async (): Promise<GetSimilarElevatorOffersResponse> => {
    const res = await api.elevatorAdvert.getSimilarElevatorOffers(request)

    return res.data
  }

  return useQuery(['similarElevatorsAdverts', request], fetchFn, { enabled: !!request.id })
}
