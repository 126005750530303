import styled from 'styled-components'

import css from '@styled-system/css'

export const Form = styled('form')(
  css({
    width: ['100%', null, '350px'],
  })
)

export const ContentWrapper = styled.div(
  css({
    minWidth: ['230px', '260px', '300px'],
  })
)

export const ActionWrapper = styled.div(
  css({
    display: 'flex',
    justifyContent: 'end',
  })
)
