import { useQuery, UseQueryResult } from '@tanstack/react-query'

import { GetNotificationsResponse, NotificationApiNotificationControllerGetNotificationsRequest } from '~api/generated'
import { useApi } from '~providers/api'

export const useGetNotifications = (
  request: NotificationApiNotificationControllerGetNotificationsRequest
): UseQueryResult<GetNotificationsResponse> => {
  const api = useApi()

  const fetchFn = async (): Promise<GetNotificationsResponse> => {
    const res = await api.notifications.notificationControllerGetNotifications(request)

    return res.data
  }

  return useQuery(['notifications', request.currentPage, request.pageSize], fetchFn, { keepPreviousData: true })
}
