import { InputNumberProps } from 'antd/lib/input-number'

/**
 * Shared settings for the InputNumber control from ANTD library
 */
export const inputSharedSettings: InputNumberProps = {
  decimalSeparator: ',',
  min: 0,
  max: 999999,
  // The number to which the current value is increased or decreased. It can be an integer or decimal
  step: 0.01,
  precision: 2,
  formatter: (value) => `${value}`.replace(',', ''),
  placeholder: 'Введите значение',
}

export const inputPriceSettings: InputNumberProps = {
  decimalSeparator: '.',
  min: 0,
  step: 50,
}

export const inputVolumeSettings: InputNumberProps = {
  decimalSeparator: '.',
  min: 0,
  step: 5,
}

export const inputMaskPhoneSettings = {
  placeholder: '+7 (900) 123-23-23',
  format: '+7 (###) ###-##-##',
  mask: '_',
  allowEmptyFormatting: true,
}
