import React from 'react'

import { Button, Input } from 'antd'
import { Controller, useForm } from 'react-hook-form'

import { yupResolver } from '@hookform/resolvers/yup'

import { FormField, FormFooter } from '~/ui-components'

import { personalProfileFormSchema } from './PersonalProfileForm.validation'

import { IPersonalProfileForm, IPersonalProfileFormProps } from './PersonalProfileForm.types'

export const PersonalProfileForm: React.FC<IPersonalProfileFormProps> = ({
  defaultValues,
  isLoading,
  onSubmit,
  onCancel,
}) => {
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors, isDirty },
  } = useForm<IPersonalProfileForm>({
    defaultValues: {
      name: defaultValues?.name ?? '',
      position: defaultValues?.position ?? '',
      email: defaultValues?.email,
      phoneNumber: defaultValues?.phoneNumber,
    },
    resolver: yupResolver(personalProfileFormSchema),
  })

  const handleCancelClick = () => {
    onCancel()
    reset()
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormField htmlFor="name" label="Имя" validateMessage={errors.name?.message}>
        <Controller
          name="name"
          control={control}
          render={({ field }) => (
            <Input
              id="name"
              placeholder="Андрей"
              status={errors.name && 'error'}
              {...field}
              value={field.value ?? ''}
            />
          )}
        />
      </FormField>

      <FormField htmlFor="phoneNumber" label="Номер телефона" validateMessage={errors.phoneNumber?.message}>
        <Controller
          name="phoneNumber"
          control={control}
          render={({ field }) => (
            <Input
              disabled
              id="phoneNumber"
              placeholder="+7 (000) 000 0000"
              status={errors.phoneNumber && 'error'}
              {...field}
              value={field.value ?? ''}
            />
          )}
        />
      </FormField>

      <FormField htmlFor="position" label="Должность" validateMessage={errors.position?.message}>
        <Controller
          name="position"
          control={control}
          render={({ field }) => (
            <Input
              id="position"
              placeholder="Менеджер"
              status={errors.position && 'error'}
              {...field}
              value={field.value ?? ''}
            />
          )}
        />
      </FormField>

      <FormField htmlFor="email" label="Электронная почта" validateMessage={errors.email?.message}>
        <Controller
          name="email"
          control={control}
          render={({ field }) => (
            <Input
              id="email"
              placeholder="zerno@mail.ru"
              status={errors.email && 'error'}
              {...field}
              value={field.value ?? ''}
            />
          )}
        />
      </FormField>

      <FormFooter>
        <Button htmlType="button" onClick={handleCancelClick}>
          Отмена
        </Button>
        <Button htmlType="submit" type="primary" disabled={!isDirty || isLoading}>
          {isLoading ? 'Сохранение' : 'Сохранить'}
        </Button>
      </FormFooter>
    </form>
  )
}
