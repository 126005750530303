import React from 'react'

import { Flex } from 'antd'

import { Text } from '~/ui-components'
import { formatNumber } from '~/utils/formatNumber'
import { getDateFormat } from '~/utils/getDateFormat'
import { getNoun } from '~/utils/getNoun'
import { getFreightPaymentTerm } from '~/utils/logistics/get-freight-payment-term'
import { getLoadType } from '~/utils/logistics/get-load-type'
import { getUnloadType } from '~/utils/logistics/get-unload-type'
import { FreightOrderEntity, FreightPaymentTerm, RoutePointType } from '~api/gql-generated/graphql'

interface CargoTableRowDetailsProps {
  freight: FreightOrderEntity
}

export const CargoTableRowDetails: React.FC<CargoTableRowDetailsProps> = ({ freight }) => {
  return (
    <Flex gap={80}>
      <Flex vertical gap={16}>
        <Text variant="label-bold">Маршрут</Text>

        <Flex vertical>
          <Text variant="form-read-label">Погрузка</Text>
          <Text variant="normal">
            {freight.route.points.find((x) => x.type === RoutePointType.Start)?.address.full}
          </Text>
        </Flex>

        <Flex vertical>
          <Text variant="form-read-label">Разгрузка</Text>
          <Text variant="normal">{freight.route.points.find((x) => x.type === RoutePointType.End)?.address.full}</Text>
        </Flex>

        <Flex vertical>
          <Text variant="form-read-label">Возможные даты погрузки</Text>
          <Text variant="normal">
            {freight.startedAt ? (
              <>
                {getDateFormat(freight.startedAt)}{' '}
                {freight.startDaysCount ? (
                  <span>
                    + {freight.startDaysCount} {getNoun(freight.startDaysCount, 'день', 'дня', 'дней')}
                  </span>
                ) : null}
              </>
            ) : (
              '-'
            )}
          </Text>
        </Flex>
      </Flex>

      <Flex vertical gap={16}>
        <Text variant="label-bold">Транспорт</Text>

        <Flex vertical>
          <Text variant="form-read-label">Тип перевозки</Text>
          <Text variant="normal">Автомобильная</Text>
        </Flex>

        {/*{freight?.transportFormatTypes.length > 0 && (*/}
        {/*  <Flex vertical>*/}
        {/*    <Text variant="form-read-label">Грузоподъемность</Text>*/}
        {/*    <Text variant="normal">*/}
        {/*      {freight?.transportFormatTypes.map((x) => getTransportFormatTypeName(x)).join(', ')}*/}
        {/*    </Text>*/}
        {/*  </Flex>*/}
        {/*)}*/}

        <Flex vertical>
          <Text variant="form-read-label">Способы выгрузки</Text>
          <Text variant="normal">
            {freight.route.points
              .flatMap((x) => x.roadTransportParameters?.unloadingTypes)
              .filter(Boolean)
              .map((x) => getUnloadType(x))
              .join(', ')}
          </Text>
        </Flex>

        <Flex vertical>
          <Text variant="form-read-label">Способы погрузки</Text>
          <Text variant="normal">
            {freight.route.points
              .flatMap((x) => x.roadTransportParameters?.loadingTypes)
              .filter(Boolean)
              .map((x) => getLoadType(x))
              .join(', ')}
          </Text>
        </Flex>
      </Flex>

      <Flex vertical gap={16}>
        <Text variant="label-bold">Груз</Text>
        <Flex vertical>
          <Text variant="form-read-label">Тип груза</Text>
          <Text variant="normal">
            {freight.route.points
              .flatMap((x) => x.freight?.productType?.name)
              .filter(Boolean)
              .join(', ')}
          </Text>
        </Flex>
      </Flex>

      <Flex vertical gap={16}>
        <Text variant="label-bold">Общая стоимость</Text>

        <Flex vertical>
          <Text variant="normal">{formatNumber(freight.priceWithNds)}₽ (с НДС)</Text>
          <Text variant="normal">{formatNumber(freight.priceWithoutNds)}₽ (без НДС)</Text>
        </Flex>

        <Flex vertical>
          <Text variant="form-read-label">Условия оплаты</Text>
          <Text variant="normal">
            {freight.paymentTerm === FreightPaymentTerm.Advance ? (
              <span>
                {getFreightPaymentTerm(freight.paymentTerm)}{' '}
                {freight?.advancePercent ? `${freight.advancePercent} %` : '-'}
              </span>
            ) : (
              <span>
                {getFreightPaymentTerm(freight.paymentTerm)}{' '}
                {freight?.deferredDaysCount
                  ? `${freight.deferredDaysCount} ${getNoun(freight.deferredDaysCount, 'день', 'дня', 'дней')}`
                  : '-'}
              </span>
            )}
          </Text>
        </Flex>
      </Flex>
    </Flex>
  )
}
