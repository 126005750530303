import { useMutation } from '@tanstack/react-query'

import { CompanyApiUpdateDocumentRequest } from '~api/generated'
import { useApi } from '~providers/api'

export const useRemoveCompanyDocument = () => {
  const api = useApi()

  return useMutation(({ companyId, documentType }: Omit<CompanyApiUpdateDocumentRequest, 'updateDocumentBody'>) => {
    return api.companies.updateDocument({
      companyId,
      documentType,
      updateDocumentBody: {
        companyDocumentFileKey: null,
      },
    })
  })
}
