import React from 'react'

import { Button, Skeleton } from 'antd'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'

import { appRoutes } from '~/router'
import { Text } from '~/ui-components'
import { useGetSimilarElevatorsAdverts } from '~hooks/elevator-advert'
import { SimilarBasisAdvertCard } from '~pages/BasisPage/components/SimilarBasisAdvertCard'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`

export const Description = styled(Text).attrs({ variant: 'normal' })`
  margin-top: 4px;
  color: var(--gray-6-color);
`
export const Actions = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-bottom: 30px;
`

export const OffersWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 8px;
`

interface CreateAdvertOfferSuccessStepProps {
  advertId: number
  onClose: () => void
}
export const CreateAdvertOfferSuccessStep: React.FC<CreateAdvertOfferSuccessStepProps> = (props) => {
  const navigate = useNavigate()

  const getSimilarElevatorsAdvertsFn = useGetSimilarElevatorsAdverts({ id: props.advertId })

  const navigateToMyBidsHandler = () => navigate(appRoutes.basisMyBids.url)
  const navigateBackHandler = () => props.onClose()

  const handleNavigateToSimilarAdvert = (advertId: number) => {
    navigate(appRoutes.basisAdvert.url.replace(':id', advertId.toString()))
  }

  return (
    <Wrapper>
      <Text variant="h3" style={{ alignSelf: 'center' }}>
        Отклик оставлен
      </Text>
      <Description>Сейчас отклик на проверке. Мы пришлем уведомление, когда администратор его проверит.</Description>
      <Description variant="description">
        Этот и другие отлики вы можете посмотреть во вкладке Мои Отлики.&nbsp;
        <Button type="link" onClick={navigateToMyBidsHandler}>
          Мои Отклики
        </Button>
      </Description>

      <Actions>
        <Button htmlType="button" type="primary" block onClick={navigateBackHandler}>
          Вернуться к объявлениям
        </Button>
        <Button htmlType="button" type="default" block onClick={navigateToMyBidsHandler}>
          Перейти к моим откликам
        </Button>
      </Actions>

      {!!getSimilarElevatorsAdvertsFn.data?.elevatorOffers?.length && (
        <>
          <Text variant="h3">Похожие варианты</Text>

          <OffersWrapper>
            <Skeleton loading={getSimilarElevatorsAdvertsFn.isFetching} />

            {getSimilarElevatorsAdvertsFn.data?.elevatorOffers?.map((advert) => (
              <SimilarBasisAdvertCard key={advert.id} advert={advert} onClick={handleNavigateToSimilarAdvert} />
            ))}
          </OffersWrapper>
        </>
      )}
    </Wrapper>
  )
}
