/* tslint:disable */
/* eslint-disable */
/**
 * API Зерно-Агрегатора
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface UpdateComplexDealStatusBody
 */
export interface UpdateComplexDealStatusBody {
    /**
     * Новый статус комплексной сделки
     * @type {string}
     * @memberof UpdateComplexDealStatusBody
     */
    'status': UpdateComplexDealStatusBodyStatusEnum;
    /**
     * Необходимо опубликовать предложение заново. Используется только при отмене во время модерации
     * @type {boolean}
     * @memberof UpdateComplexDealStatusBody
     */
    'needPublishOffer'?: boolean;
}

export const UpdateComplexDealStatusBodyStatusEnum = {
    OnModeration: 'ON_MODERATION',
    RejectedAfterModeration: 'REJECTED_AFTER_MODERATION',
    Agreement: 'AGREEMENT',
    Signing: 'SIGNING',
    Payment: 'PAYMENT',
    Shipment: 'SHIPMENT',
    Rejected: 'REJECTED',
    Realised: 'REALISED'
} as const;

export type UpdateComplexDealStatusBodyStatusEnum = typeof UpdateComplexDealStatusBodyStatusEnum[keyof typeof UpdateComplexDealStatusBodyStatusEnum];


