import React from 'react'

import * as S from './EmptyMyOfferList.styled'

export const EmptyMyOfferList = () => (
  <S.Card>
    <S.Title>Вы еще не создали ни одного предложения</S.Title>
    <S.Description>Тут будут отображаться предложения, которые вы создали.</S.Description>
  </S.Card>
)
