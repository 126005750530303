/* tslint:disable */
/* eslint-disable */
/**
 * API Зерно-Агрегатора
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AdminCreateOfferBody } from '../models';
// @ts-ignore
import { AdminCreateOfferResponse } from '../models';
// @ts-ignore
import { CreateDiscussionMessageBody } from '../models';
// @ts-ignore
import { CreateOfferBody } from '../models';
// @ts-ignore
import { CreateOfferResponse } from '../models';
// @ts-ignore
import { CreateOfferV2Body } from '../models';
// @ts-ignore
import { CreateOfferV2Response } from '../models';
// @ts-ignore
import { GetAcceptedOffersResponse } from '../models';
// @ts-ignore
import { GetCompanyOffersResponse } from '../models';
// @ts-ignore
import { GetDiscussionResponse } from '../models';
// @ts-ignore
import { GetOfferResponse } from '../models';
// @ts-ignore
import { GetOffersResponse } from '../models';
// @ts-ignore
import { OfferCreatorType } from '../models';
// @ts-ignore
import { OfferSortField } from '../models';
// @ts-ignore
import { OrderDirection } from '../models';
// @ts-ignore
import { UpdateOfferBody } from '../models';
// @ts-ignore
import { UpdateOfferModeratorBody } from '../models';
// @ts-ignore
import { UpdateOfferStatusBody } from '../models';
/**
 * OfferApi - axios parameter creator
 * @export
 */
export const OfferApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Создать предложение
         * @param {AdminCreateOfferBody} adminCreateOfferBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCreateOffer: async (adminCreateOfferBody: AdminCreateOfferBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'adminCreateOfferBody' is not null or undefined
            assertParamExists('adminCreateOffer', 'adminCreateOfferBody', adminCreateOfferBody)
            const localVarPath = `/admin/offer`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminCreateOfferBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получить предложение
         * @param {number} offerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetOffer: async (offerId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'offerId' is not null or undefined
            assertParamExists('adminGetOffer', 'offerId', offerId)
            const localVarPath = `/admin/offer/{offerId}`
                .replace(`{${"offerId"}}`, encodeURIComponent(String(offerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получить список предложений
         * @param {number} [currentPage] Номер текущей страницы
         * @param {number} [pageSize] Количество элементов на странице
         * @param {number} [userCompanyId] Id компании, пользователь которой делает запрос
         * @param {OfferCreatorType} [creatorType] Роль создателя
         * @param {Array<'ON_MODERATION' | 'PRE_PUBLISHED' | 'PUBLISHED' | 'PRE_DEAL' | 'IN_DEAL' | 'EXPIRED' | 'REJECTED' | 'REALISED'>} [statuses] Статусы предложений
         * @param {Array<number>} [companyIds] Список id компаний
         * @param {Array<string>} [regionIds] Список id регионов
         * @param {number} [warehouseIdForDistance] Id склада для расчёта расстояний
         * @param {number} [maxDistance] Максимальное расстояние до выбранного склада
         * @param {Array<string>} [productTypes] Список типов товаров
         * @param {'BUY' | 'SELL'} [type] Тип предложения
         * @param {number} [minVolume] Минимальный объем
         * @param {number} [maxVolume] Максимальный объем
         * @param {number} [minPrice] Минимальная цена
         * @param {number} [maxPrice] Максимальная цена
         * @param {boolean} [includingNds] Учтен ли НДС
         * @param {Array<number>} [offerIds] Массив с id предложений
         * @param {OfferSortField} [orderBy] Поле для сортировки
         * @param {OrderDirection} [order] Направление сортировки
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetOffers: async (currentPage?: number, pageSize?: number, userCompanyId?: number, creatorType?: OfferCreatorType, statuses?: Array<'ON_MODERATION' | 'PRE_PUBLISHED' | 'PUBLISHED' | 'PRE_DEAL' | 'IN_DEAL' | 'EXPIRED' | 'REJECTED' | 'REALISED'>, companyIds?: Array<number>, regionIds?: Array<string>, warehouseIdForDistance?: number, maxDistance?: number, productTypes?: Array<string>, type?: 'BUY' | 'SELL', minVolume?: number, maxVolume?: number, minPrice?: number, maxPrice?: number, includingNds?: boolean, offerIds?: Array<number>, orderBy?: OfferSortField, order?: OrderDirection, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/offers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (currentPage !== undefined) {
                localVarQueryParameter['current-page'] = currentPage;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page-size'] = pageSize;
            }

            if (userCompanyId !== undefined) {
                localVarQueryParameter['userCompanyId'] = userCompanyId;
            }

            if (creatorType !== undefined) {
                localVarQueryParameter['creatorType'] = creatorType;
            }

            if (statuses) {
                localVarQueryParameter['statuses'] = statuses;
            }

            if (companyIds) {
                localVarQueryParameter['company-ids'] = companyIds;
            }

            if (regionIds) {
                localVarQueryParameter['region-ids'] = regionIds;
            }

            if (warehouseIdForDistance !== undefined) {
                localVarQueryParameter['warehouse-id-for-distance'] = warehouseIdForDistance;
            }

            if (maxDistance !== undefined) {
                localVarQueryParameter['max-distance'] = maxDistance;
            }

            if (productTypes) {
                localVarQueryParameter['product-types'] = productTypes;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            if (minVolume !== undefined) {
                localVarQueryParameter['min-volume'] = minVolume;
            }

            if (maxVolume !== undefined) {
                localVarQueryParameter['max-volume'] = maxVolume;
            }

            if (minPrice !== undefined) {
                localVarQueryParameter['min-price'] = minPrice;
            }

            if (maxPrice !== undefined) {
                localVarQueryParameter['max-price'] = maxPrice;
            }

            if (includingNds !== undefined) {
                localVarQueryParameter['includingNds'] = includingNds;
            }

            if (offerIds) {
                localVarQueryParameter['offer-ids'] = offerIds;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['order-by'] = orderBy;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получить отчёт по предложениям в xlsx
         * @param {number} [userCompanyId] Id компании, пользователь которой делает запрос
         * @param {OfferCreatorType} [creatorType] Роль создателя
         * @param {Array<'ON_MODERATION' | 'PRE_PUBLISHED' | 'PUBLISHED' | 'PRE_DEAL' | 'IN_DEAL' | 'EXPIRED' | 'REJECTED' | 'REALISED'>} [statuses] Статусы предложений
         * @param {Array<number>} [companyIds] Список id компаний
         * @param {Array<string>} [regionIds] Список id регионов
         * @param {number} [warehouseIdForDistance] Id склада для расчёта расстояний
         * @param {number} [maxDistance] Максимальное расстояние до выбранного склада
         * @param {Array<string>} [productTypes] Список типов товаров
         * @param {'BUY' | 'SELL'} [type] Тип предложения
         * @param {number} [minVolume] Минимальный объем
         * @param {number} [maxVolume] Максимальный объем
         * @param {number} [minPrice] Минимальная цена
         * @param {number} [maxPrice] Максимальная цена
         * @param {boolean} [includingNds] Учтен ли НДС
         * @param {Array<number>} [offerIds] Массив с id предложений
         * @param {OfferSortField} [orderBy] Поле для сортировки
         * @param {OrderDirection} [order] Направление сортировки
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetOffersReport: async (userCompanyId?: number, creatorType?: OfferCreatorType, statuses?: Array<'ON_MODERATION' | 'PRE_PUBLISHED' | 'PUBLISHED' | 'PRE_DEAL' | 'IN_DEAL' | 'EXPIRED' | 'REJECTED' | 'REALISED'>, companyIds?: Array<number>, regionIds?: Array<string>, warehouseIdForDistance?: number, maxDistance?: number, productTypes?: Array<string>, type?: 'BUY' | 'SELL', minVolume?: number, maxVolume?: number, minPrice?: number, maxPrice?: number, includingNds?: boolean, offerIds?: Array<number>, orderBy?: OfferSortField, order?: OrderDirection, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/offers/report`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (userCompanyId !== undefined) {
                localVarQueryParameter['userCompanyId'] = userCompanyId;
            }

            if (creatorType !== undefined) {
                localVarQueryParameter['creatorType'] = creatorType;
            }

            if (statuses) {
                localVarQueryParameter['statuses'] = statuses;
            }

            if (companyIds) {
                localVarQueryParameter['company-ids'] = companyIds;
            }

            if (regionIds) {
                localVarQueryParameter['region-ids'] = regionIds;
            }

            if (warehouseIdForDistance !== undefined) {
                localVarQueryParameter['warehouse-id-for-distance'] = warehouseIdForDistance;
            }

            if (maxDistance !== undefined) {
                localVarQueryParameter['max-distance'] = maxDistance;
            }

            if (productTypes) {
                localVarQueryParameter['product-types'] = productTypes;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            if (minVolume !== undefined) {
                localVarQueryParameter['min-volume'] = minVolume;
            }

            if (maxVolume !== undefined) {
                localVarQueryParameter['max-volume'] = maxVolume;
            }

            if (minPrice !== undefined) {
                localVarQueryParameter['min-price'] = minPrice;
            }

            if (maxPrice !== undefined) {
                localVarQueryParameter['max-price'] = maxPrice;
            }

            if (includingNds !== undefined) {
                localVarQueryParameter['includingNds'] = includingNds;
            }

            if (offerIds) {
                localVarQueryParameter['offer-ids'] = offerIds;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['order-by'] = orderBy;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Создать предложение
         * @param {CreateOfferV2Body} createOfferV2Body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOfferV2: async (createOfferV2Body: CreateOfferV2Body, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createOfferV2Body' is not null or undefined
            assertParamExists('createOfferV2', 'createOfferV2Body', createOfferV2Body)
            const localVarPath = `/offer`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createOfferV2Body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получить список предложений
         * @param {number} [currentPage] Номер текущей страницы
         * @param {number} [pageSize] Количество элементов на странице
         * @param {number} [userCompanyId] Id компании, пользователь которой делает запрос
         * @param {OfferCreatorType} [creatorType] Роль создателя
         * @param {Array<'ON_MODERATION' | 'PRE_PUBLISHED' | 'PUBLISHED' | 'PRE_DEAL' | 'IN_DEAL' | 'EXPIRED' | 'REJECTED' | 'REALISED'>} [statuses] Статусы предложений
         * @param {Array<number>} [companyIds] Список id компаний
         * @param {Array<string>} [regionIds] Список id регионов
         * @param {number} [warehouseIdForDistance] Id склада для расчёта расстояний
         * @param {number} [maxDistance] Максимальное расстояние до выбранного склада
         * @param {Array<string>} [productTypes] Список типов товаров
         * @param {'BUY' | 'SELL'} [type] Тип предложения
         * @param {number} [minVolume] Минимальный объем
         * @param {number} [maxVolume] Максимальный объем
         * @param {number} [minPrice] Минимальная цена
         * @param {number} [maxPrice] Максимальная цена
         * @param {boolean} [includingNds] Учтен ли НДС
         * @param {Array<number>} [offerIds] Массив с id предложений
         * @param {OfferSortField} [orderBy] Поле для сортировки
         * @param {OrderDirection} [order] Направление сортировки
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOffers: async (currentPage?: number, pageSize?: number, userCompanyId?: number, creatorType?: OfferCreatorType, statuses?: Array<'ON_MODERATION' | 'PRE_PUBLISHED' | 'PUBLISHED' | 'PRE_DEAL' | 'IN_DEAL' | 'EXPIRED' | 'REJECTED' | 'REALISED'>, companyIds?: Array<number>, regionIds?: Array<string>, warehouseIdForDistance?: number, maxDistance?: number, productTypes?: Array<string>, type?: 'BUY' | 'SELL', minVolume?: number, maxVolume?: number, minPrice?: number, maxPrice?: number, includingNds?: boolean, offerIds?: Array<number>, orderBy?: OfferSortField, order?: OrderDirection, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/offers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (currentPage !== undefined) {
                localVarQueryParameter['current-page'] = currentPage;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page-size'] = pageSize;
            }

            if (userCompanyId !== undefined) {
                localVarQueryParameter['userCompanyId'] = userCompanyId;
            }

            if (creatorType !== undefined) {
                localVarQueryParameter['creatorType'] = creatorType;
            }

            if (statuses) {
                localVarQueryParameter['statuses'] = statuses;
            }

            if (companyIds) {
                localVarQueryParameter['company-ids'] = companyIds;
            }

            if (regionIds) {
                localVarQueryParameter['region-ids'] = regionIds;
            }

            if (warehouseIdForDistance !== undefined) {
                localVarQueryParameter['warehouse-id-for-distance'] = warehouseIdForDistance;
            }

            if (maxDistance !== undefined) {
                localVarQueryParameter['max-distance'] = maxDistance;
            }

            if (productTypes) {
                localVarQueryParameter['product-types'] = productTypes;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            if (minVolume !== undefined) {
                localVarQueryParameter['min-volume'] = minVolume;
            }

            if (maxVolume !== undefined) {
                localVarQueryParameter['max-volume'] = maxVolume;
            }

            if (minPrice !== undefined) {
                localVarQueryParameter['min-price'] = minPrice;
            }

            if (maxPrice !== undefined) {
                localVarQueryParameter['max-price'] = maxPrice;
            }

            if (includingNds !== undefined) {
                localVarQueryParameter['includingNds'] = includingNds;
            }

            if (offerIds) {
                localVarQueryParameter['offer-ids'] = offerIds;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['order-by'] = orderBy;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} offerId 
         * @param {CreateDiscussionMessageBody} createDiscussionMessageBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        offerControllerCreateDiscussionMessage: async (offerId: number, createDiscussionMessageBody: CreateDiscussionMessageBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'offerId' is not null or undefined
            assertParamExists('offerControllerCreateDiscussionMessage', 'offerId', offerId)
            // verify required parameter 'createDiscussionMessageBody' is not null or undefined
            assertParamExists('offerControllerCreateDiscussionMessage', 'createDiscussionMessageBody', createDiscussionMessageBody)
            const localVarPath = `/offer/{offerId}/discussion/message`
                .replace(`{${"offerId"}}`, encodeURIComponent(String(offerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createDiscussionMessageBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} companyId 
         * @param {CreateOfferBody} createOfferBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        offerControllerCreateOffer: async (companyId: number, createOfferBody: CreateOfferBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('offerControllerCreateOffer', 'companyId', companyId)
            // verify required parameter 'createOfferBody' is not null or undefined
            assertParamExists('offerControllerCreateOffer', 'createOfferBody', createOfferBody)
            const localVarPath = `/company/{companyId}/offer`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createOfferBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} companyId 
         * @param {number} [currentPage] Номер текущей страницы
         * @param {number} [pageSize] Количество элементов на странице
         * @param {Array<number>} [offerIds] Массив с id предложений
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        offerControllerGetAcceptedOffers: async (companyId: number, currentPage?: number, pageSize?: number, offerIds?: Array<number>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('offerControllerGetAcceptedOffers', 'companyId', companyId)
            const localVarPath = `/company/{companyId}/accepted-offers`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (currentPage !== undefined) {
                localVarQueryParameter['current-page'] = currentPage;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page-size'] = pageSize;
            }

            if (offerIds) {
                localVarQueryParameter['offer-ids'] = offerIds;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} companyId 
         * @param {number} [currentPage] Номер текущей страницы
         * @param {number} [pageSize] Количество элементов на странице
         * @param {Array<string>} [regionIds] Список id регионов
         * @param {Array<string>} [productTypes] Список типов товаров
         * @param {'BUY' | 'SELL'} [type] Тип предложения
         * @param {number} [minVolume] Минимальный объем
         * @param {number} [maxVolume] Максимальный объем
         * @param {number} [minPrice] Минимальная цена
         * @param {number} [maxPrice] Максимальная цена
         * @param {boolean} [includingNds] Учтен ли НДС
         * @param {Array<number>} [offerIds] Массив с id предложений
         * @param {OfferSortField} [orderBy] Поле для сортировки
         * @param {OrderDirection} [order] Направление сортировки
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        offerControllerGetCompanyOffers: async (companyId: number, currentPage?: number, pageSize?: number, regionIds?: Array<string>, productTypes?: Array<string>, type?: 'BUY' | 'SELL', minVolume?: number, maxVolume?: number, minPrice?: number, maxPrice?: number, includingNds?: boolean, offerIds?: Array<number>, orderBy?: OfferSortField, order?: OrderDirection, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('offerControllerGetCompanyOffers', 'companyId', companyId)
            const localVarPath = `/company/{companyId}/offers`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (currentPage !== undefined) {
                localVarQueryParameter['current-page'] = currentPage;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page-size'] = pageSize;
            }

            if (regionIds) {
                localVarQueryParameter['region-ids'] = regionIds;
            }

            if (productTypes) {
                localVarQueryParameter['product-types'] = productTypes;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            if (minVolume !== undefined) {
                localVarQueryParameter['min-volume'] = minVolume;
            }

            if (maxVolume !== undefined) {
                localVarQueryParameter['max-volume'] = maxVolume;
            }

            if (minPrice !== undefined) {
                localVarQueryParameter['min-price'] = minPrice;
            }

            if (maxPrice !== undefined) {
                localVarQueryParameter['max-price'] = maxPrice;
            }

            if (includingNds !== undefined) {
                localVarQueryParameter['includingNds'] = includingNds;
            }

            if (offerIds) {
                localVarQueryParameter['offer-ids'] = offerIds;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['order-by'] = orderBy;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} offerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        offerControllerGetDiscussion: async (offerId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'offerId' is not null or undefined
            assertParamExists('offerControllerGetDiscussion', 'offerId', offerId)
            const localVarPath = `/offer/{offerId}/discussion`
                .replace(`{${"offerId"}}`, encodeURIComponent(String(offerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} offerId 
         * @param {number} userCompanyId Id компании, пользователь которой делает запрос
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        offerControllerGetOffer: async (offerId: number, userCompanyId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'offerId' is not null or undefined
            assertParamExists('offerControllerGetOffer', 'offerId', offerId)
            // verify required parameter 'userCompanyId' is not null or undefined
            assertParamExists('offerControllerGetOffer', 'userCompanyId', userCompanyId)
            const localVarPath = `/offer/{offerId}`
                .replace(`{${"offerId"}}`, encodeURIComponent(String(offerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (userCompanyId !== undefined) {
                localVarQueryParameter['userCompanyId'] = userCompanyId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} offerId 
         * @param {UpdateOfferBody} updateOfferBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        offerControllerUpdateOffer: async (offerId: number, updateOfferBody: UpdateOfferBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'offerId' is not null or undefined
            assertParamExists('offerControllerUpdateOffer', 'offerId', offerId)
            // verify required parameter 'updateOfferBody' is not null or undefined
            assertParamExists('offerControllerUpdateOffer', 'updateOfferBody', updateOfferBody)
            const localVarPath = `/offer/{offerId}`
                .replace(`{${"offerId"}}`, encodeURIComponent(String(offerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateOfferBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} offerId 
         * @param {UpdateOfferModeratorBody} updateOfferModeratorBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        offerControllerUpdateOfferModerator: async (offerId: number, updateOfferModeratorBody: UpdateOfferModeratorBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'offerId' is not null or undefined
            assertParamExists('offerControllerUpdateOfferModerator', 'offerId', offerId)
            // verify required parameter 'updateOfferModeratorBody' is not null or undefined
            assertParamExists('offerControllerUpdateOfferModerator', 'updateOfferModeratorBody', updateOfferModeratorBody)
            const localVarPath = `/offer/{offerId}/moderator`
                .replace(`{${"offerId"}}`, encodeURIComponent(String(offerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateOfferModeratorBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} offerId 
         * @param {UpdateOfferStatusBody} updateOfferStatusBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        offerControllerUpdateOfferStatus: async (offerId: number, updateOfferStatusBody: UpdateOfferStatusBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'offerId' is not null or undefined
            assertParamExists('offerControllerUpdateOfferStatus', 'offerId', offerId)
            // verify required parameter 'updateOfferStatusBody' is not null or undefined
            assertParamExists('offerControllerUpdateOfferStatus', 'updateOfferStatusBody', updateOfferStatusBody)
            const localVarPath = `/offer/{offerId}/status`
                .replace(`{${"offerId"}}`, encodeURIComponent(String(offerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateOfferStatusBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OfferApi - functional programming interface
 * @export
 */
export const OfferApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OfferApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Создать предложение
         * @param {AdminCreateOfferBody} adminCreateOfferBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminCreateOffer(adminCreateOfferBody: AdminCreateOfferBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminCreateOfferResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminCreateOffer(adminCreateOfferBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Получить предложение
         * @param {number} offerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetOffer(offerId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetOfferResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetOffer(offerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Получить список предложений
         * @param {number} [currentPage] Номер текущей страницы
         * @param {number} [pageSize] Количество элементов на странице
         * @param {number} [userCompanyId] Id компании, пользователь которой делает запрос
         * @param {OfferCreatorType} [creatorType] Роль создателя
         * @param {Array<'ON_MODERATION' | 'PRE_PUBLISHED' | 'PUBLISHED' | 'PRE_DEAL' | 'IN_DEAL' | 'EXPIRED' | 'REJECTED' | 'REALISED'>} [statuses] Статусы предложений
         * @param {Array<number>} [companyIds] Список id компаний
         * @param {Array<string>} [regionIds] Список id регионов
         * @param {number} [warehouseIdForDistance] Id склада для расчёта расстояний
         * @param {number} [maxDistance] Максимальное расстояние до выбранного склада
         * @param {Array<string>} [productTypes] Список типов товаров
         * @param {'BUY' | 'SELL'} [type] Тип предложения
         * @param {number} [minVolume] Минимальный объем
         * @param {number} [maxVolume] Максимальный объем
         * @param {number} [minPrice] Минимальная цена
         * @param {number} [maxPrice] Максимальная цена
         * @param {boolean} [includingNds] Учтен ли НДС
         * @param {Array<number>} [offerIds] Массив с id предложений
         * @param {OfferSortField} [orderBy] Поле для сортировки
         * @param {OrderDirection} [order] Направление сортировки
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetOffers(currentPage?: number, pageSize?: number, userCompanyId?: number, creatorType?: OfferCreatorType, statuses?: Array<'ON_MODERATION' | 'PRE_PUBLISHED' | 'PUBLISHED' | 'PRE_DEAL' | 'IN_DEAL' | 'EXPIRED' | 'REJECTED' | 'REALISED'>, companyIds?: Array<number>, regionIds?: Array<string>, warehouseIdForDistance?: number, maxDistance?: number, productTypes?: Array<string>, type?: 'BUY' | 'SELL', minVolume?: number, maxVolume?: number, minPrice?: number, maxPrice?: number, includingNds?: boolean, offerIds?: Array<number>, orderBy?: OfferSortField, order?: OrderDirection, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetOffersResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetOffers(currentPage, pageSize, userCompanyId, creatorType, statuses, companyIds, regionIds, warehouseIdForDistance, maxDistance, productTypes, type, minVolume, maxVolume, minPrice, maxPrice, includingNds, offerIds, orderBy, order, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Получить отчёт по предложениям в xlsx
         * @param {number} [userCompanyId] Id компании, пользователь которой делает запрос
         * @param {OfferCreatorType} [creatorType] Роль создателя
         * @param {Array<'ON_MODERATION' | 'PRE_PUBLISHED' | 'PUBLISHED' | 'PRE_DEAL' | 'IN_DEAL' | 'EXPIRED' | 'REJECTED' | 'REALISED'>} [statuses] Статусы предложений
         * @param {Array<number>} [companyIds] Список id компаний
         * @param {Array<string>} [regionIds] Список id регионов
         * @param {number} [warehouseIdForDistance] Id склада для расчёта расстояний
         * @param {number} [maxDistance] Максимальное расстояние до выбранного склада
         * @param {Array<string>} [productTypes] Список типов товаров
         * @param {'BUY' | 'SELL'} [type] Тип предложения
         * @param {number} [minVolume] Минимальный объем
         * @param {number} [maxVolume] Максимальный объем
         * @param {number} [minPrice] Минимальная цена
         * @param {number} [maxPrice] Максимальная цена
         * @param {boolean} [includingNds] Учтен ли НДС
         * @param {Array<number>} [offerIds] Массив с id предложений
         * @param {OfferSortField} [orderBy] Поле для сортировки
         * @param {OrderDirection} [order] Направление сортировки
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetOffersReport(userCompanyId?: number, creatorType?: OfferCreatorType, statuses?: Array<'ON_MODERATION' | 'PRE_PUBLISHED' | 'PUBLISHED' | 'PRE_DEAL' | 'IN_DEAL' | 'EXPIRED' | 'REJECTED' | 'REALISED'>, companyIds?: Array<number>, regionIds?: Array<string>, warehouseIdForDistance?: number, maxDistance?: number, productTypes?: Array<string>, type?: 'BUY' | 'SELL', minVolume?: number, maxVolume?: number, minPrice?: number, maxPrice?: number, includingNds?: boolean, offerIds?: Array<number>, orderBy?: OfferSortField, order?: OrderDirection, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetOffersReport(userCompanyId, creatorType, statuses, companyIds, regionIds, warehouseIdForDistance, maxDistance, productTypes, type, minVolume, maxVolume, minPrice, maxPrice, includingNds, offerIds, orderBy, order, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Создать предложение
         * @param {CreateOfferV2Body} createOfferV2Body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createOfferV2(createOfferV2Body: CreateOfferV2Body, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateOfferV2Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createOfferV2(createOfferV2Body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Получить список предложений
         * @param {number} [currentPage] Номер текущей страницы
         * @param {number} [pageSize] Количество элементов на странице
         * @param {number} [userCompanyId] Id компании, пользователь которой делает запрос
         * @param {OfferCreatorType} [creatorType] Роль создателя
         * @param {Array<'ON_MODERATION' | 'PRE_PUBLISHED' | 'PUBLISHED' | 'PRE_DEAL' | 'IN_DEAL' | 'EXPIRED' | 'REJECTED' | 'REALISED'>} [statuses] Статусы предложений
         * @param {Array<number>} [companyIds] Список id компаний
         * @param {Array<string>} [regionIds] Список id регионов
         * @param {number} [warehouseIdForDistance] Id склада для расчёта расстояний
         * @param {number} [maxDistance] Максимальное расстояние до выбранного склада
         * @param {Array<string>} [productTypes] Список типов товаров
         * @param {'BUY' | 'SELL'} [type] Тип предложения
         * @param {number} [minVolume] Минимальный объем
         * @param {number} [maxVolume] Максимальный объем
         * @param {number} [minPrice] Минимальная цена
         * @param {number} [maxPrice] Максимальная цена
         * @param {boolean} [includingNds] Учтен ли НДС
         * @param {Array<number>} [offerIds] Массив с id предложений
         * @param {OfferSortField} [orderBy] Поле для сортировки
         * @param {OrderDirection} [order] Направление сортировки
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOffers(currentPage?: number, pageSize?: number, userCompanyId?: number, creatorType?: OfferCreatorType, statuses?: Array<'ON_MODERATION' | 'PRE_PUBLISHED' | 'PUBLISHED' | 'PRE_DEAL' | 'IN_DEAL' | 'EXPIRED' | 'REJECTED' | 'REALISED'>, companyIds?: Array<number>, regionIds?: Array<string>, warehouseIdForDistance?: number, maxDistance?: number, productTypes?: Array<string>, type?: 'BUY' | 'SELL', minVolume?: number, maxVolume?: number, minPrice?: number, maxPrice?: number, includingNds?: boolean, offerIds?: Array<number>, orderBy?: OfferSortField, order?: OrderDirection, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetOffersResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOffers(currentPage, pageSize, userCompanyId, creatorType, statuses, companyIds, regionIds, warehouseIdForDistance, maxDistance, productTypes, type, minVolume, maxVolume, minPrice, maxPrice, includingNds, offerIds, orderBy, order, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} offerId 
         * @param {CreateDiscussionMessageBody} createDiscussionMessageBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async offerControllerCreateDiscussionMessage(offerId: number, createDiscussionMessageBody: CreateDiscussionMessageBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.offerControllerCreateDiscussionMessage(offerId, createDiscussionMessageBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} companyId 
         * @param {CreateOfferBody} createOfferBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async offerControllerCreateOffer(companyId: number, createOfferBody: CreateOfferBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateOfferResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.offerControllerCreateOffer(companyId, createOfferBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} companyId 
         * @param {number} [currentPage] Номер текущей страницы
         * @param {number} [pageSize] Количество элементов на странице
         * @param {Array<number>} [offerIds] Массив с id предложений
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async offerControllerGetAcceptedOffers(companyId: number, currentPage?: number, pageSize?: number, offerIds?: Array<number>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAcceptedOffersResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.offerControllerGetAcceptedOffers(companyId, currentPage, pageSize, offerIds, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} companyId 
         * @param {number} [currentPage] Номер текущей страницы
         * @param {number} [pageSize] Количество элементов на странице
         * @param {Array<string>} [regionIds] Список id регионов
         * @param {Array<string>} [productTypes] Список типов товаров
         * @param {'BUY' | 'SELL'} [type] Тип предложения
         * @param {number} [minVolume] Минимальный объем
         * @param {number} [maxVolume] Максимальный объем
         * @param {number} [minPrice] Минимальная цена
         * @param {number} [maxPrice] Максимальная цена
         * @param {boolean} [includingNds] Учтен ли НДС
         * @param {Array<number>} [offerIds] Массив с id предложений
         * @param {OfferSortField} [orderBy] Поле для сортировки
         * @param {OrderDirection} [order] Направление сортировки
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async offerControllerGetCompanyOffers(companyId: number, currentPage?: number, pageSize?: number, regionIds?: Array<string>, productTypes?: Array<string>, type?: 'BUY' | 'SELL', minVolume?: number, maxVolume?: number, minPrice?: number, maxPrice?: number, includingNds?: boolean, offerIds?: Array<number>, orderBy?: OfferSortField, order?: OrderDirection, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCompanyOffersResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.offerControllerGetCompanyOffers(companyId, currentPage, pageSize, regionIds, productTypes, type, minVolume, maxVolume, minPrice, maxPrice, includingNds, offerIds, orderBy, order, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} offerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async offerControllerGetDiscussion(offerId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetDiscussionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.offerControllerGetDiscussion(offerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} offerId 
         * @param {number} userCompanyId Id компании, пользователь которой делает запрос
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async offerControllerGetOffer(offerId: number, userCompanyId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetOfferResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.offerControllerGetOffer(offerId, userCompanyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} offerId 
         * @param {UpdateOfferBody} updateOfferBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async offerControllerUpdateOffer(offerId: number, updateOfferBody: UpdateOfferBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.offerControllerUpdateOffer(offerId, updateOfferBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} offerId 
         * @param {UpdateOfferModeratorBody} updateOfferModeratorBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async offerControllerUpdateOfferModerator(offerId: number, updateOfferModeratorBody: UpdateOfferModeratorBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.offerControllerUpdateOfferModerator(offerId, updateOfferModeratorBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} offerId 
         * @param {UpdateOfferStatusBody} updateOfferStatusBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async offerControllerUpdateOfferStatus(offerId: number, updateOfferStatusBody: UpdateOfferStatusBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.offerControllerUpdateOfferStatus(offerId, updateOfferStatusBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OfferApi - factory interface
 * @export
 */
export const OfferApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OfferApiFp(configuration)
    return {
        /**
         * 
         * @summary Создать предложение
         * @param {AdminCreateOfferBody} adminCreateOfferBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCreateOffer(adminCreateOfferBody: AdminCreateOfferBody, options?: any): AxiosPromise<AdminCreateOfferResponse> {
            return localVarFp.adminCreateOffer(adminCreateOfferBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получить предложение
         * @param {number} offerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetOffer(offerId: number, options?: any): AxiosPromise<GetOfferResponse> {
            return localVarFp.adminGetOffer(offerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получить список предложений
         * @param {number} [currentPage] Номер текущей страницы
         * @param {number} [pageSize] Количество элементов на странице
         * @param {number} [userCompanyId] Id компании, пользователь которой делает запрос
         * @param {OfferCreatorType} [creatorType] Роль создателя
         * @param {Array<'ON_MODERATION' | 'PRE_PUBLISHED' | 'PUBLISHED' | 'PRE_DEAL' | 'IN_DEAL' | 'EXPIRED' | 'REJECTED' | 'REALISED'>} [statuses] Статусы предложений
         * @param {Array<number>} [companyIds] Список id компаний
         * @param {Array<string>} [regionIds] Список id регионов
         * @param {number} [warehouseIdForDistance] Id склада для расчёта расстояний
         * @param {number} [maxDistance] Максимальное расстояние до выбранного склада
         * @param {Array<string>} [productTypes] Список типов товаров
         * @param {'BUY' | 'SELL'} [type] Тип предложения
         * @param {number} [minVolume] Минимальный объем
         * @param {number} [maxVolume] Максимальный объем
         * @param {number} [minPrice] Минимальная цена
         * @param {number} [maxPrice] Максимальная цена
         * @param {boolean} [includingNds] Учтен ли НДС
         * @param {Array<number>} [offerIds] Массив с id предложений
         * @param {OfferSortField} [orderBy] Поле для сортировки
         * @param {OrderDirection} [order] Направление сортировки
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetOffers(currentPage?: number, pageSize?: number, userCompanyId?: number, creatorType?: OfferCreatorType, statuses?: Array<'ON_MODERATION' | 'PRE_PUBLISHED' | 'PUBLISHED' | 'PRE_DEAL' | 'IN_DEAL' | 'EXPIRED' | 'REJECTED' | 'REALISED'>, companyIds?: Array<number>, regionIds?: Array<string>, warehouseIdForDistance?: number, maxDistance?: number, productTypes?: Array<string>, type?: 'BUY' | 'SELL', minVolume?: number, maxVolume?: number, minPrice?: number, maxPrice?: number, includingNds?: boolean, offerIds?: Array<number>, orderBy?: OfferSortField, order?: OrderDirection, options?: any): AxiosPromise<GetOffersResponse> {
            return localVarFp.adminGetOffers(currentPage, pageSize, userCompanyId, creatorType, statuses, companyIds, regionIds, warehouseIdForDistance, maxDistance, productTypes, type, minVolume, maxVolume, minPrice, maxPrice, includingNds, offerIds, orderBy, order, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получить отчёт по предложениям в xlsx
         * @param {number} [userCompanyId] Id компании, пользователь которой делает запрос
         * @param {OfferCreatorType} [creatorType] Роль создателя
         * @param {Array<'ON_MODERATION' | 'PRE_PUBLISHED' | 'PUBLISHED' | 'PRE_DEAL' | 'IN_DEAL' | 'EXPIRED' | 'REJECTED' | 'REALISED'>} [statuses] Статусы предложений
         * @param {Array<number>} [companyIds] Список id компаний
         * @param {Array<string>} [regionIds] Список id регионов
         * @param {number} [warehouseIdForDistance] Id склада для расчёта расстояний
         * @param {number} [maxDistance] Максимальное расстояние до выбранного склада
         * @param {Array<string>} [productTypes] Список типов товаров
         * @param {'BUY' | 'SELL'} [type] Тип предложения
         * @param {number} [minVolume] Минимальный объем
         * @param {number} [maxVolume] Максимальный объем
         * @param {number} [minPrice] Минимальная цена
         * @param {number} [maxPrice] Максимальная цена
         * @param {boolean} [includingNds] Учтен ли НДС
         * @param {Array<number>} [offerIds] Массив с id предложений
         * @param {OfferSortField} [orderBy] Поле для сортировки
         * @param {OrderDirection} [order] Направление сортировки
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetOffersReport(userCompanyId?: number, creatorType?: OfferCreatorType, statuses?: Array<'ON_MODERATION' | 'PRE_PUBLISHED' | 'PUBLISHED' | 'PRE_DEAL' | 'IN_DEAL' | 'EXPIRED' | 'REJECTED' | 'REALISED'>, companyIds?: Array<number>, regionIds?: Array<string>, warehouseIdForDistance?: number, maxDistance?: number, productTypes?: Array<string>, type?: 'BUY' | 'SELL', minVolume?: number, maxVolume?: number, minPrice?: number, maxPrice?: number, includingNds?: boolean, offerIds?: Array<number>, orderBy?: OfferSortField, order?: OrderDirection, options?: any): AxiosPromise<void> {
            return localVarFp.adminGetOffersReport(userCompanyId, creatorType, statuses, companyIds, regionIds, warehouseIdForDistance, maxDistance, productTypes, type, minVolume, maxVolume, minPrice, maxPrice, includingNds, offerIds, orderBy, order, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Создать предложение
         * @param {CreateOfferV2Body} createOfferV2Body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOfferV2(createOfferV2Body: CreateOfferV2Body, options?: any): AxiosPromise<CreateOfferV2Response> {
            return localVarFp.createOfferV2(createOfferV2Body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получить список предложений
         * @param {number} [currentPage] Номер текущей страницы
         * @param {number} [pageSize] Количество элементов на странице
         * @param {number} [userCompanyId] Id компании, пользователь которой делает запрос
         * @param {OfferCreatorType} [creatorType] Роль создателя
         * @param {Array<'ON_MODERATION' | 'PRE_PUBLISHED' | 'PUBLISHED' | 'PRE_DEAL' | 'IN_DEAL' | 'EXPIRED' | 'REJECTED' | 'REALISED'>} [statuses] Статусы предложений
         * @param {Array<number>} [companyIds] Список id компаний
         * @param {Array<string>} [regionIds] Список id регионов
         * @param {number} [warehouseIdForDistance] Id склада для расчёта расстояний
         * @param {number} [maxDistance] Максимальное расстояние до выбранного склада
         * @param {Array<string>} [productTypes] Список типов товаров
         * @param {'BUY' | 'SELL'} [type] Тип предложения
         * @param {number} [minVolume] Минимальный объем
         * @param {number} [maxVolume] Максимальный объем
         * @param {number} [minPrice] Минимальная цена
         * @param {number} [maxPrice] Максимальная цена
         * @param {boolean} [includingNds] Учтен ли НДС
         * @param {Array<number>} [offerIds] Массив с id предложений
         * @param {OfferSortField} [orderBy] Поле для сортировки
         * @param {OrderDirection} [order] Направление сортировки
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOffers(currentPage?: number, pageSize?: number, userCompanyId?: number, creatorType?: OfferCreatorType, statuses?: Array<'ON_MODERATION' | 'PRE_PUBLISHED' | 'PUBLISHED' | 'PRE_DEAL' | 'IN_DEAL' | 'EXPIRED' | 'REJECTED' | 'REALISED'>, companyIds?: Array<number>, regionIds?: Array<string>, warehouseIdForDistance?: number, maxDistance?: number, productTypes?: Array<string>, type?: 'BUY' | 'SELL', minVolume?: number, maxVolume?: number, minPrice?: number, maxPrice?: number, includingNds?: boolean, offerIds?: Array<number>, orderBy?: OfferSortField, order?: OrderDirection, options?: any): AxiosPromise<GetOffersResponse> {
            return localVarFp.getOffers(currentPage, pageSize, userCompanyId, creatorType, statuses, companyIds, regionIds, warehouseIdForDistance, maxDistance, productTypes, type, minVolume, maxVolume, minPrice, maxPrice, includingNds, offerIds, orderBy, order, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} offerId 
         * @param {CreateDiscussionMessageBody} createDiscussionMessageBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        offerControllerCreateDiscussionMessage(offerId: number, createDiscussionMessageBody: CreateDiscussionMessageBody, options?: any): AxiosPromise<void> {
            return localVarFp.offerControllerCreateDiscussionMessage(offerId, createDiscussionMessageBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} companyId 
         * @param {CreateOfferBody} createOfferBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        offerControllerCreateOffer(companyId: number, createOfferBody: CreateOfferBody, options?: any): AxiosPromise<CreateOfferResponse> {
            return localVarFp.offerControllerCreateOffer(companyId, createOfferBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} companyId 
         * @param {number} [currentPage] Номер текущей страницы
         * @param {number} [pageSize] Количество элементов на странице
         * @param {Array<number>} [offerIds] Массив с id предложений
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        offerControllerGetAcceptedOffers(companyId: number, currentPage?: number, pageSize?: number, offerIds?: Array<number>, options?: any): AxiosPromise<GetAcceptedOffersResponse> {
            return localVarFp.offerControllerGetAcceptedOffers(companyId, currentPage, pageSize, offerIds, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} companyId 
         * @param {number} [currentPage] Номер текущей страницы
         * @param {number} [pageSize] Количество элементов на странице
         * @param {Array<string>} [regionIds] Список id регионов
         * @param {Array<string>} [productTypes] Список типов товаров
         * @param {'BUY' | 'SELL'} [type] Тип предложения
         * @param {number} [minVolume] Минимальный объем
         * @param {number} [maxVolume] Максимальный объем
         * @param {number} [minPrice] Минимальная цена
         * @param {number} [maxPrice] Максимальная цена
         * @param {boolean} [includingNds] Учтен ли НДС
         * @param {Array<number>} [offerIds] Массив с id предложений
         * @param {OfferSortField} [orderBy] Поле для сортировки
         * @param {OrderDirection} [order] Направление сортировки
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        offerControllerGetCompanyOffers(companyId: number, currentPage?: number, pageSize?: number, regionIds?: Array<string>, productTypes?: Array<string>, type?: 'BUY' | 'SELL', minVolume?: number, maxVolume?: number, minPrice?: number, maxPrice?: number, includingNds?: boolean, offerIds?: Array<number>, orderBy?: OfferSortField, order?: OrderDirection, options?: any): AxiosPromise<GetCompanyOffersResponse> {
            return localVarFp.offerControllerGetCompanyOffers(companyId, currentPage, pageSize, regionIds, productTypes, type, minVolume, maxVolume, minPrice, maxPrice, includingNds, offerIds, orderBy, order, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} offerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        offerControllerGetDiscussion(offerId: number, options?: any): AxiosPromise<GetDiscussionResponse> {
            return localVarFp.offerControllerGetDiscussion(offerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} offerId 
         * @param {number} userCompanyId Id компании, пользователь которой делает запрос
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        offerControllerGetOffer(offerId: number, userCompanyId: number, options?: any): AxiosPromise<GetOfferResponse> {
            return localVarFp.offerControllerGetOffer(offerId, userCompanyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} offerId 
         * @param {UpdateOfferBody} updateOfferBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        offerControllerUpdateOffer(offerId: number, updateOfferBody: UpdateOfferBody, options?: any): AxiosPromise<void> {
            return localVarFp.offerControllerUpdateOffer(offerId, updateOfferBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} offerId 
         * @param {UpdateOfferModeratorBody} updateOfferModeratorBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        offerControllerUpdateOfferModerator(offerId: number, updateOfferModeratorBody: UpdateOfferModeratorBody, options?: any): AxiosPromise<void> {
            return localVarFp.offerControllerUpdateOfferModerator(offerId, updateOfferModeratorBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} offerId 
         * @param {UpdateOfferStatusBody} updateOfferStatusBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        offerControllerUpdateOfferStatus(offerId: number, updateOfferStatusBody: UpdateOfferStatusBody, options?: any): AxiosPromise<void> {
            return localVarFp.offerControllerUpdateOfferStatus(offerId, updateOfferStatusBody, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for adminCreateOffer operation in OfferApi.
 * @export
 * @interface OfferApiAdminCreateOfferRequest
 */
export interface OfferApiAdminCreateOfferRequest {
    /**
     * 
     * @type {AdminCreateOfferBody}
     * @memberof OfferApiAdminCreateOffer
     */
    readonly adminCreateOfferBody: AdminCreateOfferBody
}

/**
 * Request parameters for adminGetOffer operation in OfferApi.
 * @export
 * @interface OfferApiAdminGetOfferRequest
 */
export interface OfferApiAdminGetOfferRequest {
    /**
     * 
     * @type {number}
     * @memberof OfferApiAdminGetOffer
     */
    readonly offerId: number
}

/**
 * Request parameters for adminGetOffers operation in OfferApi.
 * @export
 * @interface OfferApiAdminGetOffersRequest
 */
export interface OfferApiAdminGetOffersRequest {
    /**
     * Номер текущей страницы
     * @type {number}
     * @memberof OfferApiAdminGetOffers
     */
    readonly currentPage?: number

    /**
     * Количество элементов на странице
     * @type {number}
     * @memberof OfferApiAdminGetOffers
     */
    readonly pageSize?: number

    /**
     * Id компании, пользователь которой делает запрос
     * @type {number}
     * @memberof OfferApiAdminGetOffers
     */
    readonly userCompanyId?: number

    /**
     * Роль создателя
     * @type {OfferCreatorType}
     * @memberof OfferApiAdminGetOffers
     */
    readonly creatorType?: OfferCreatorType

    /**
     * Статусы предложений
     * @type {Array<'ON_MODERATION' | 'PRE_PUBLISHED' | 'PUBLISHED' | 'PRE_DEAL' | 'IN_DEAL' | 'EXPIRED' | 'REJECTED' | 'REALISED'>}
     * @memberof OfferApiAdminGetOffers
     */
    readonly statuses?: Array<'ON_MODERATION' | 'PRE_PUBLISHED' | 'PUBLISHED' | 'PRE_DEAL' | 'IN_DEAL' | 'EXPIRED' | 'REJECTED' | 'REALISED'>

    /**
     * Список id компаний
     * @type {Array<number>}
     * @memberof OfferApiAdminGetOffers
     */
    readonly companyIds?: Array<number>

    /**
     * Список id регионов
     * @type {Array<string>}
     * @memberof OfferApiAdminGetOffers
     */
    readonly regionIds?: Array<string>

    /**
     * Id склада для расчёта расстояний
     * @type {number}
     * @memberof OfferApiAdminGetOffers
     */
    readonly warehouseIdForDistance?: number

    /**
     * Максимальное расстояние до выбранного склада
     * @type {number}
     * @memberof OfferApiAdminGetOffers
     */
    readonly maxDistance?: number

    /**
     * Список типов товаров
     * @type {Array<string>}
     * @memberof OfferApiAdminGetOffers
     */
    readonly productTypes?: Array<string>

    /**
     * Тип предложения
     * @type {'BUY' | 'SELL'}
     * @memberof OfferApiAdminGetOffers
     */
    readonly type?: 'BUY' | 'SELL'

    /**
     * Минимальный объем
     * @type {number}
     * @memberof OfferApiAdminGetOffers
     */
    readonly minVolume?: number

    /**
     * Максимальный объем
     * @type {number}
     * @memberof OfferApiAdminGetOffers
     */
    readonly maxVolume?: number

    /**
     * Минимальная цена
     * @type {number}
     * @memberof OfferApiAdminGetOffers
     */
    readonly minPrice?: number

    /**
     * Максимальная цена
     * @type {number}
     * @memberof OfferApiAdminGetOffers
     */
    readonly maxPrice?: number

    /**
     * Учтен ли НДС
     * @type {boolean}
     * @memberof OfferApiAdminGetOffers
     */
    readonly includingNds?: boolean

    /**
     * Массив с id предложений
     * @type {Array<number>}
     * @memberof OfferApiAdminGetOffers
     */
    readonly offerIds?: Array<number>

    /**
     * Поле для сортировки
     * @type {OfferSortField}
     * @memberof OfferApiAdminGetOffers
     */
    readonly orderBy?: OfferSortField

    /**
     * Направление сортировки
     * @type {OrderDirection}
     * @memberof OfferApiAdminGetOffers
     */
    readonly order?: OrderDirection
}

/**
 * Request parameters for adminGetOffersReport operation in OfferApi.
 * @export
 * @interface OfferApiAdminGetOffersReportRequest
 */
export interface OfferApiAdminGetOffersReportRequest {
    /**
     * Id компании, пользователь которой делает запрос
     * @type {number}
     * @memberof OfferApiAdminGetOffersReport
     */
    readonly userCompanyId?: number

    /**
     * Роль создателя
     * @type {OfferCreatorType}
     * @memberof OfferApiAdminGetOffersReport
     */
    readonly creatorType?: OfferCreatorType

    /**
     * Статусы предложений
     * @type {Array<'ON_MODERATION' | 'PRE_PUBLISHED' | 'PUBLISHED' | 'PRE_DEAL' | 'IN_DEAL' | 'EXPIRED' | 'REJECTED' | 'REALISED'>}
     * @memberof OfferApiAdminGetOffersReport
     */
    readonly statuses?: Array<'ON_MODERATION' | 'PRE_PUBLISHED' | 'PUBLISHED' | 'PRE_DEAL' | 'IN_DEAL' | 'EXPIRED' | 'REJECTED' | 'REALISED'>

    /**
     * Список id компаний
     * @type {Array<number>}
     * @memberof OfferApiAdminGetOffersReport
     */
    readonly companyIds?: Array<number>

    /**
     * Список id регионов
     * @type {Array<string>}
     * @memberof OfferApiAdminGetOffersReport
     */
    readonly regionIds?: Array<string>

    /**
     * Id склада для расчёта расстояний
     * @type {number}
     * @memberof OfferApiAdminGetOffersReport
     */
    readonly warehouseIdForDistance?: number

    /**
     * Максимальное расстояние до выбранного склада
     * @type {number}
     * @memberof OfferApiAdminGetOffersReport
     */
    readonly maxDistance?: number

    /**
     * Список типов товаров
     * @type {Array<string>}
     * @memberof OfferApiAdminGetOffersReport
     */
    readonly productTypes?: Array<string>

    /**
     * Тип предложения
     * @type {'BUY' | 'SELL'}
     * @memberof OfferApiAdminGetOffersReport
     */
    readonly type?: 'BUY' | 'SELL'

    /**
     * Минимальный объем
     * @type {number}
     * @memberof OfferApiAdminGetOffersReport
     */
    readonly minVolume?: number

    /**
     * Максимальный объем
     * @type {number}
     * @memberof OfferApiAdminGetOffersReport
     */
    readonly maxVolume?: number

    /**
     * Минимальная цена
     * @type {number}
     * @memberof OfferApiAdminGetOffersReport
     */
    readonly minPrice?: number

    /**
     * Максимальная цена
     * @type {number}
     * @memberof OfferApiAdminGetOffersReport
     */
    readonly maxPrice?: number

    /**
     * Учтен ли НДС
     * @type {boolean}
     * @memberof OfferApiAdminGetOffersReport
     */
    readonly includingNds?: boolean

    /**
     * Массив с id предложений
     * @type {Array<number>}
     * @memberof OfferApiAdminGetOffersReport
     */
    readonly offerIds?: Array<number>

    /**
     * Поле для сортировки
     * @type {OfferSortField}
     * @memberof OfferApiAdminGetOffersReport
     */
    readonly orderBy?: OfferSortField

    /**
     * Направление сортировки
     * @type {OrderDirection}
     * @memberof OfferApiAdminGetOffersReport
     */
    readonly order?: OrderDirection
}

/**
 * Request parameters for createOfferV2 operation in OfferApi.
 * @export
 * @interface OfferApiCreateOfferV2Request
 */
export interface OfferApiCreateOfferV2Request {
    /**
     * 
     * @type {CreateOfferV2Body}
     * @memberof OfferApiCreateOfferV2
     */
    readonly createOfferV2Body: CreateOfferV2Body
}

/**
 * Request parameters for getOffers operation in OfferApi.
 * @export
 * @interface OfferApiGetOffersRequest
 */
export interface OfferApiGetOffersRequest {
    /**
     * Номер текущей страницы
     * @type {number}
     * @memberof OfferApiGetOffers
     */
    readonly currentPage?: number

    /**
     * Количество элементов на странице
     * @type {number}
     * @memberof OfferApiGetOffers
     */
    readonly pageSize?: number

    /**
     * Id компании, пользователь которой делает запрос
     * @type {number}
     * @memberof OfferApiGetOffers
     */
    readonly userCompanyId?: number

    /**
     * Роль создателя
     * @type {OfferCreatorType}
     * @memberof OfferApiGetOffers
     */
    readonly creatorType?: OfferCreatorType

    /**
     * Статусы предложений
     * @type {Array<'ON_MODERATION' | 'PRE_PUBLISHED' | 'PUBLISHED' | 'PRE_DEAL' | 'IN_DEAL' | 'EXPIRED' | 'REJECTED' | 'REALISED'>}
     * @memberof OfferApiGetOffers
     */
    readonly statuses?: Array<'ON_MODERATION' | 'PRE_PUBLISHED' | 'PUBLISHED' | 'PRE_DEAL' | 'IN_DEAL' | 'EXPIRED' | 'REJECTED' | 'REALISED'>

    /**
     * Список id компаний
     * @type {Array<number>}
     * @memberof OfferApiGetOffers
     */
    readonly companyIds?: Array<number>

    /**
     * Список id регионов
     * @type {Array<string>}
     * @memberof OfferApiGetOffers
     */
    readonly regionIds?: Array<string>

    /**
     * Id склада для расчёта расстояний
     * @type {number}
     * @memberof OfferApiGetOffers
     */
    readonly warehouseIdForDistance?: number

    /**
     * Максимальное расстояние до выбранного склада
     * @type {number}
     * @memberof OfferApiGetOffers
     */
    readonly maxDistance?: number

    /**
     * Список типов товаров
     * @type {Array<string>}
     * @memberof OfferApiGetOffers
     */
    readonly productTypes?: Array<string>

    /**
     * Тип предложения
     * @type {'BUY' | 'SELL'}
     * @memberof OfferApiGetOffers
     */
    readonly type?: 'BUY' | 'SELL'

    /**
     * Минимальный объем
     * @type {number}
     * @memberof OfferApiGetOffers
     */
    readonly minVolume?: number

    /**
     * Максимальный объем
     * @type {number}
     * @memberof OfferApiGetOffers
     */
    readonly maxVolume?: number

    /**
     * Минимальная цена
     * @type {number}
     * @memberof OfferApiGetOffers
     */
    readonly minPrice?: number

    /**
     * Максимальная цена
     * @type {number}
     * @memberof OfferApiGetOffers
     */
    readonly maxPrice?: number

    /**
     * Учтен ли НДС
     * @type {boolean}
     * @memberof OfferApiGetOffers
     */
    readonly includingNds?: boolean

    /**
     * Массив с id предложений
     * @type {Array<number>}
     * @memberof OfferApiGetOffers
     */
    readonly offerIds?: Array<number>

    /**
     * Поле для сортировки
     * @type {OfferSortField}
     * @memberof OfferApiGetOffers
     */
    readonly orderBy?: OfferSortField

    /**
     * Направление сортировки
     * @type {OrderDirection}
     * @memberof OfferApiGetOffers
     */
    readonly order?: OrderDirection
}

/**
 * Request parameters for offerControllerCreateDiscussionMessage operation in OfferApi.
 * @export
 * @interface OfferApiOfferControllerCreateDiscussionMessageRequest
 */
export interface OfferApiOfferControllerCreateDiscussionMessageRequest {
    /**
     * 
     * @type {number}
     * @memberof OfferApiOfferControllerCreateDiscussionMessage
     */
    readonly offerId: number

    /**
     * 
     * @type {CreateDiscussionMessageBody}
     * @memberof OfferApiOfferControllerCreateDiscussionMessage
     */
    readonly createDiscussionMessageBody: CreateDiscussionMessageBody
}

/**
 * Request parameters for offerControllerCreateOffer operation in OfferApi.
 * @export
 * @interface OfferApiOfferControllerCreateOfferRequest
 */
export interface OfferApiOfferControllerCreateOfferRequest {
    /**
     * 
     * @type {number}
     * @memberof OfferApiOfferControllerCreateOffer
     */
    readonly companyId: number

    /**
     * 
     * @type {CreateOfferBody}
     * @memberof OfferApiOfferControllerCreateOffer
     */
    readonly createOfferBody: CreateOfferBody
}

/**
 * Request parameters for offerControllerGetAcceptedOffers operation in OfferApi.
 * @export
 * @interface OfferApiOfferControllerGetAcceptedOffersRequest
 */
export interface OfferApiOfferControllerGetAcceptedOffersRequest {
    /**
     * 
     * @type {number}
     * @memberof OfferApiOfferControllerGetAcceptedOffers
     */
    readonly companyId: number

    /**
     * Номер текущей страницы
     * @type {number}
     * @memberof OfferApiOfferControllerGetAcceptedOffers
     */
    readonly currentPage?: number

    /**
     * Количество элементов на странице
     * @type {number}
     * @memberof OfferApiOfferControllerGetAcceptedOffers
     */
    readonly pageSize?: number

    /**
     * Массив с id предложений
     * @type {Array<number>}
     * @memberof OfferApiOfferControllerGetAcceptedOffers
     */
    readonly offerIds?: Array<number>
}

/**
 * Request parameters for offerControllerGetCompanyOffers operation in OfferApi.
 * @export
 * @interface OfferApiOfferControllerGetCompanyOffersRequest
 */
export interface OfferApiOfferControllerGetCompanyOffersRequest {
    /**
     * 
     * @type {number}
     * @memberof OfferApiOfferControllerGetCompanyOffers
     */
    readonly companyId: number

    /**
     * Номер текущей страницы
     * @type {number}
     * @memberof OfferApiOfferControllerGetCompanyOffers
     */
    readonly currentPage?: number

    /**
     * Количество элементов на странице
     * @type {number}
     * @memberof OfferApiOfferControllerGetCompanyOffers
     */
    readonly pageSize?: number

    /**
     * Список id регионов
     * @type {Array<string>}
     * @memberof OfferApiOfferControllerGetCompanyOffers
     */
    readonly regionIds?: Array<string>

    /**
     * Список типов товаров
     * @type {Array<string>}
     * @memberof OfferApiOfferControllerGetCompanyOffers
     */
    readonly productTypes?: Array<string>

    /**
     * Тип предложения
     * @type {'BUY' | 'SELL'}
     * @memberof OfferApiOfferControllerGetCompanyOffers
     */
    readonly type?: 'BUY' | 'SELL'

    /**
     * Минимальный объем
     * @type {number}
     * @memberof OfferApiOfferControllerGetCompanyOffers
     */
    readonly minVolume?: number

    /**
     * Максимальный объем
     * @type {number}
     * @memberof OfferApiOfferControllerGetCompanyOffers
     */
    readonly maxVolume?: number

    /**
     * Минимальная цена
     * @type {number}
     * @memberof OfferApiOfferControllerGetCompanyOffers
     */
    readonly minPrice?: number

    /**
     * Максимальная цена
     * @type {number}
     * @memberof OfferApiOfferControllerGetCompanyOffers
     */
    readonly maxPrice?: number

    /**
     * Учтен ли НДС
     * @type {boolean}
     * @memberof OfferApiOfferControllerGetCompanyOffers
     */
    readonly includingNds?: boolean

    /**
     * Массив с id предложений
     * @type {Array<number>}
     * @memberof OfferApiOfferControllerGetCompanyOffers
     */
    readonly offerIds?: Array<number>

    /**
     * Поле для сортировки
     * @type {OfferSortField}
     * @memberof OfferApiOfferControllerGetCompanyOffers
     */
    readonly orderBy?: OfferSortField

    /**
     * Направление сортировки
     * @type {OrderDirection}
     * @memberof OfferApiOfferControllerGetCompanyOffers
     */
    readonly order?: OrderDirection
}

/**
 * Request parameters for offerControllerGetDiscussion operation in OfferApi.
 * @export
 * @interface OfferApiOfferControllerGetDiscussionRequest
 */
export interface OfferApiOfferControllerGetDiscussionRequest {
    /**
     * 
     * @type {number}
     * @memberof OfferApiOfferControllerGetDiscussion
     */
    readonly offerId: number
}

/**
 * Request parameters for offerControllerGetOffer operation in OfferApi.
 * @export
 * @interface OfferApiOfferControllerGetOfferRequest
 */
export interface OfferApiOfferControllerGetOfferRequest {
    /**
     * 
     * @type {number}
     * @memberof OfferApiOfferControllerGetOffer
     */
    readonly offerId: number

    /**
     * Id компании, пользователь которой делает запрос
     * @type {number}
     * @memberof OfferApiOfferControllerGetOffer
     */
    readonly userCompanyId: number
}

/**
 * Request parameters for offerControllerUpdateOffer operation in OfferApi.
 * @export
 * @interface OfferApiOfferControllerUpdateOfferRequest
 */
export interface OfferApiOfferControllerUpdateOfferRequest {
    /**
     * 
     * @type {number}
     * @memberof OfferApiOfferControllerUpdateOffer
     */
    readonly offerId: number

    /**
     * 
     * @type {UpdateOfferBody}
     * @memberof OfferApiOfferControllerUpdateOffer
     */
    readonly updateOfferBody: UpdateOfferBody
}

/**
 * Request parameters for offerControllerUpdateOfferModerator operation in OfferApi.
 * @export
 * @interface OfferApiOfferControllerUpdateOfferModeratorRequest
 */
export interface OfferApiOfferControllerUpdateOfferModeratorRequest {
    /**
     * 
     * @type {number}
     * @memberof OfferApiOfferControllerUpdateOfferModerator
     */
    readonly offerId: number

    /**
     * 
     * @type {UpdateOfferModeratorBody}
     * @memberof OfferApiOfferControllerUpdateOfferModerator
     */
    readonly updateOfferModeratorBody: UpdateOfferModeratorBody
}

/**
 * Request parameters for offerControllerUpdateOfferStatus operation in OfferApi.
 * @export
 * @interface OfferApiOfferControllerUpdateOfferStatusRequest
 */
export interface OfferApiOfferControllerUpdateOfferStatusRequest {
    /**
     * 
     * @type {number}
     * @memberof OfferApiOfferControllerUpdateOfferStatus
     */
    readonly offerId: number

    /**
     * 
     * @type {UpdateOfferStatusBody}
     * @memberof OfferApiOfferControllerUpdateOfferStatus
     */
    readonly updateOfferStatusBody: UpdateOfferStatusBody
}

/**
 * OfferApi - object-oriented interface
 * @export
 * @class OfferApi
 * @extends {BaseAPI}
 */
export class OfferApi extends BaseAPI {
    /**
     * 
     * @summary Создать предложение
     * @param {OfferApiAdminCreateOfferRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OfferApi
     */
    public adminCreateOffer(requestParameters: OfferApiAdminCreateOfferRequest, options?: AxiosRequestConfig) {
        return OfferApiFp(this.configuration).adminCreateOffer(requestParameters.adminCreateOfferBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получить предложение
     * @param {OfferApiAdminGetOfferRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OfferApi
     */
    public adminGetOffer(requestParameters: OfferApiAdminGetOfferRequest, options?: AxiosRequestConfig) {
        return OfferApiFp(this.configuration).adminGetOffer(requestParameters.offerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получить список предложений
     * @param {OfferApiAdminGetOffersRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OfferApi
     */
    public adminGetOffers(requestParameters: OfferApiAdminGetOffersRequest = {}, options?: AxiosRequestConfig) {
        return OfferApiFp(this.configuration).adminGetOffers(requestParameters.currentPage, requestParameters.pageSize, requestParameters.userCompanyId, requestParameters.creatorType, requestParameters.statuses, requestParameters.companyIds, requestParameters.regionIds, requestParameters.warehouseIdForDistance, requestParameters.maxDistance, requestParameters.productTypes, requestParameters.type, requestParameters.minVolume, requestParameters.maxVolume, requestParameters.minPrice, requestParameters.maxPrice, requestParameters.includingNds, requestParameters.offerIds, requestParameters.orderBy, requestParameters.order, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получить отчёт по предложениям в xlsx
     * @param {OfferApiAdminGetOffersReportRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OfferApi
     */
    public adminGetOffersReport(requestParameters: OfferApiAdminGetOffersReportRequest = {}, options?: AxiosRequestConfig) {
        return OfferApiFp(this.configuration).adminGetOffersReport(requestParameters.userCompanyId, requestParameters.creatorType, requestParameters.statuses, requestParameters.companyIds, requestParameters.regionIds, requestParameters.warehouseIdForDistance, requestParameters.maxDistance, requestParameters.productTypes, requestParameters.type, requestParameters.minVolume, requestParameters.maxVolume, requestParameters.minPrice, requestParameters.maxPrice, requestParameters.includingNds, requestParameters.offerIds, requestParameters.orderBy, requestParameters.order, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Создать предложение
     * @param {OfferApiCreateOfferV2Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OfferApi
     */
    public createOfferV2(requestParameters: OfferApiCreateOfferV2Request, options?: AxiosRequestConfig) {
        return OfferApiFp(this.configuration).createOfferV2(requestParameters.createOfferV2Body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получить список предложений
     * @param {OfferApiGetOffersRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OfferApi
     */
    public getOffers(requestParameters: OfferApiGetOffersRequest = {}, options?: AxiosRequestConfig) {
        return OfferApiFp(this.configuration).getOffers(requestParameters.currentPage, requestParameters.pageSize, requestParameters.userCompanyId, requestParameters.creatorType, requestParameters.statuses, requestParameters.companyIds, requestParameters.regionIds, requestParameters.warehouseIdForDistance, requestParameters.maxDistance, requestParameters.productTypes, requestParameters.type, requestParameters.minVolume, requestParameters.maxVolume, requestParameters.minPrice, requestParameters.maxPrice, requestParameters.includingNds, requestParameters.offerIds, requestParameters.orderBy, requestParameters.order, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {OfferApiOfferControllerCreateDiscussionMessageRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OfferApi
     */
    public offerControllerCreateDiscussionMessage(requestParameters: OfferApiOfferControllerCreateDiscussionMessageRequest, options?: AxiosRequestConfig) {
        return OfferApiFp(this.configuration).offerControllerCreateDiscussionMessage(requestParameters.offerId, requestParameters.createDiscussionMessageBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {OfferApiOfferControllerCreateOfferRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OfferApi
     */
    public offerControllerCreateOffer(requestParameters: OfferApiOfferControllerCreateOfferRequest, options?: AxiosRequestConfig) {
        return OfferApiFp(this.configuration).offerControllerCreateOffer(requestParameters.companyId, requestParameters.createOfferBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {OfferApiOfferControllerGetAcceptedOffersRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OfferApi
     */
    public offerControllerGetAcceptedOffers(requestParameters: OfferApiOfferControllerGetAcceptedOffersRequest, options?: AxiosRequestConfig) {
        return OfferApiFp(this.configuration).offerControllerGetAcceptedOffers(requestParameters.companyId, requestParameters.currentPage, requestParameters.pageSize, requestParameters.offerIds, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {OfferApiOfferControllerGetCompanyOffersRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OfferApi
     */
    public offerControllerGetCompanyOffers(requestParameters: OfferApiOfferControllerGetCompanyOffersRequest, options?: AxiosRequestConfig) {
        return OfferApiFp(this.configuration).offerControllerGetCompanyOffers(requestParameters.companyId, requestParameters.currentPage, requestParameters.pageSize, requestParameters.regionIds, requestParameters.productTypes, requestParameters.type, requestParameters.minVolume, requestParameters.maxVolume, requestParameters.minPrice, requestParameters.maxPrice, requestParameters.includingNds, requestParameters.offerIds, requestParameters.orderBy, requestParameters.order, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {OfferApiOfferControllerGetDiscussionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OfferApi
     */
    public offerControllerGetDiscussion(requestParameters: OfferApiOfferControllerGetDiscussionRequest, options?: AxiosRequestConfig) {
        return OfferApiFp(this.configuration).offerControllerGetDiscussion(requestParameters.offerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {OfferApiOfferControllerGetOfferRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OfferApi
     */
    public offerControllerGetOffer(requestParameters: OfferApiOfferControllerGetOfferRequest, options?: AxiosRequestConfig) {
        return OfferApiFp(this.configuration).offerControllerGetOffer(requestParameters.offerId, requestParameters.userCompanyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {OfferApiOfferControllerUpdateOfferRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OfferApi
     */
    public offerControllerUpdateOffer(requestParameters: OfferApiOfferControllerUpdateOfferRequest, options?: AxiosRequestConfig) {
        return OfferApiFp(this.configuration).offerControllerUpdateOffer(requestParameters.offerId, requestParameters.updateOfferBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {OfferApiOfferControllerUpdateOfferModeratorRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OfferApi
     */
    public offerControllerUpdateOfferModerator(requestParameters: OfferApiOfferControllerUpdateOfferModeratorRequest, options?: AxiosRequestConfig) {
        return OfferApiFp(this.configuration).offerControllerUpdateOfferModerator(requestParameters.offerId, requestParameters.updateOfferModeratorBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {OfferApiOfferControllerUpdateOfferStatusRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OfferApi
     */
    public offerControllerUpdateOfferStatus(requestParameters: OfferApiOfferControllerUpdateOfferStatusRequest, options?: AxiosRequestConfig) {
        return OfferApiFp(this.configuration).offerControllerUpdateOfferStatus(requestParameters.offerId, requestParameters.updateOfferStatusBody, options).then((request) => request(this.axios, this.basePath));
    }
}
