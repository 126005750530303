import styled from 'styled-components'
import { Empty } from 'antd'

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  flex-grow: 1;
`

export const EmptyWrapper = styled(Empty)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 12px;
`
