import React from 'react'

import { Skeleton } from 'antd'
import { useParams } from 'react-router'

import { useGetUsers } from '~hooks/user/useGetUsers'
import { EmployeeProfileTab } from '~pages/ProfilePage/components'

export const ModeratorEmployeeInfo: React.FC = () => {
  const { id } = useParams<{ id: string | undefined }>()
  const companyId = Number(id)
  const userFn = useGetUsers({ companyIds: [Number(companyId)], pageSize: 100 })

  if (!userFn) {
    return null
  }

  if (userFn.isFetching || !userFn.data) {
    return <Skeleton />
  }

  const userIds = userFn.data?.map((x) => x.id)

  return <EmployeeProfileTab userIds={userIds} />
}
