import styled from 'styled-components'

import { breakpoints } from '~/tokens'
import { Card } from '~/ui-components'

export const Content = styled(Card.Content)<{ bordered?: boolean }>`
  display: flex;
  margin-top: 32px;
  border: ${(p) => p.bordered && '1px solid darkgray'};
  border-radius: ${(p) => p.bordered && '10px'};
  padding: ${(p) => p.bordered && '20px'};

  @media (max-width: ${breakpoints.MOBILE_XL}px) {
    margin-top: 16px;
  }
`
