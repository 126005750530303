import React, { FC } from 'react'

import { FiAlertCircle, FiFolder } from 'react-icons/fi'

import { getDealDocumentName } from '~/utils/deals'

import { DocumentCardProps } from './DocumentCardFolder.types'

import * as S from './DocumentCardFolder.styled'

export const DocumentCardFolder: FC<DocumentCardProps> = ({ type, count, onOpen, status }) => {
  return (
    <S.DocumentCard onClick={onOpen}>
      <S.Header>
        <S.Name>{getDealDocumentName(type)}</S.Name>
        <S.Actions>
          <FiFolder size={24} />
        </S.Actions>
      </S.Header>
      <S.Content>
        <S.CountText>{count} документов</S.CountText>
        {status === 'success' && <S.Message>Все документы проверены</S.Message>}
        {status === 'warning' && (
          <S.Message>
            <FiAlertCircle size={24} color="var(--warning-color)" />
            Есть непроверенные документы
          </S.Message>
        )}
        {status === 'error' && (
          <S.Message>
            <FiAlertCircle size={24} color="var(--danger-color)" />
            Есть отклоненные документы
          </S.Message>
        )}
      </S.Content>
    </S.DocumentCard>
  )
}
