import React from 'react'

import { Card } from '~/ui-components'
import { formatPhoneNumber } from '~/utils/formatPhoneNumber'
import { AdminLinkToCompanyById } from '~shared/components'

import * as S from './DealPersonCard.styled'

interface IDealPersonCardProps {
  personType: 'seller' | 'buyer'
  name: string
  companyName: string
  companyId: number
  inn: string
}

export const DealPersonCard: React.FC<IDealPersonCardProps> = ({ personType, name, companyId, companyName, inn }) => {
  return (
    <Card>
      <Card.Header>
        <S.Header>{personType === 'buyer' ? 'Покупатель' : 'Продавец'}</S.Header>
      </Card.Header>
      <Card.Content>
        <S.NameTitle>{name}</S.NameTitle>
        <S.Row>
          <Card.Label>Компания</Card.Label>
          <Card.Value>
            {companyName} <AdminLinkToCompanyById companyId={companyId} />
          </Card.Value>
        </S.Row>
        <S.Row>
          <Card.Label>ИНН</Card.Label>
          <Card.Value>{inn}</Card.Value>
        </S.Row>
      </Card.Content>
    </Card>
  )
}
