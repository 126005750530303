import React from 'react'

import { Button } from 'antd'
import { FiEdit2 } from 'react-icons/fi'

import { Card } from '~/ui-components'

import { IEdoViewProps } from './EdoView.types'

import * as S from './EdoView.styled'

export const EdoView: React.FC<IEdoViewProps> = ({ data, onChange }) => {
  const handleChange = () => {
    onChange()
  }

  return (
    <Card>
      <Card.Header>
        <Card.Title>Электронный Документооборот</Card.Title>

        <Card.ActionsContent>
          <Button size="small" type="text" icon={<FiEdit2 size={24} />} onClick={handleChange} />
        </Card.ActionsContent>
      </Card.Header>

      <S.ContentWrapper>
        <Card.Label>Оператор ЭДО</Card.Label>
        <Card.Value>{data.name}</Card.Value>
      </S.ContentWrapper>

      <S.ContentWrapper>
        <Card.Label>Идентификатор в ЭДО</Card.Label>
        <Card.Value>{data.edmId}</Card.Value>
      </S.ContentWrapper>
    </Card>
  )
}
