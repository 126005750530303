import styled from 'styled-components'

import { css } from '@styled-system/css'

export const Desktop = styled.div(
  css({
    display: ['none', null, 'block'],
  })
)

export const Mobile = styled.div(
  css({
    display: ['block', null, 'none'],
  })
)

export const CardPreview = styled.div<{ isOwn?: boolean }>(({ isOwn }) =>
  css({
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    padding: ['16px', null, '24px'],
    background: isOwn ? '#FFF8EA' : '#fff',
    boxShadow: '0px 1px 4px rgba(84, 84, 84, 0.15)',
    borderRadius: '16px',
    cursor: 'pointer',
  })
)

export const ContentWrapper = styled.div(
  css({
    display: 'grid',
    gridTemplateColumns: ['1fr', null, '1fr max-content', '1fr max-content'],
    gap: '24px',
  })
)

export const Content = styled.div(
  css({
    display: 'grid',
    gridTemplateColumns: ['1fr', null, null, '360px 1fr'],
    gap: '16px',
  })
)

export const MainContent = styled.div(
  css({
    display: 'flex',
    gap: '16px',
  })
)

export const MainContentWrapper = styled.div(
  css({
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
  })
)

export const Statuses = styled.div(
  css({
    display: 'flex',
    gap: '8px',
  })
)

export const Name = styled.div(
  css({
    fontSize: ['21px', null, '24px'],
    lineHeight: ['24px', null, '32px'],
    fontWeight: '700',
    color: 'TxtDefault',
    marginBottom: ['16px', null, '8px'],
  })
)

export const Parameters = styled.div(
  css({
    display: 'flex',
    gap: '8px',
    flexWrap: 'wrap',
    alignItems: 'flex-start',
  })
)

export const LocationWrapper = styled.div(
  css({
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
  })
)

export const Address = styled.div(
  css({
    variant: 'typography.headingsH5',
    color: 'TxtDefault',
  })
)

export const Distance = styled.div(
  css({
    variant: 'typography.textBaseRegular',
    color: 'TxtPrimary',
    fontWeight: 600,
  })
)

export const CompanyName = styled.div(
  css({
    fontSize: '16px',
    lineHeight: '24px',
    color: 'TxtPrimary',
  })
)

export const Info = styled.div(
  css({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    gap: '4px',
  })
)

export const Volume = styled.div(
  css({
    fontSize: ['18px', null, '22px'],
    lineHeight: '24px',
    fontWeight: '700',
    color: 'TxtDefault',
  })
)

export const Price = styled.div(
  css({
    fontSize: ['21px', null, '24px'],
    lineHeight: ['24px', null, '32px'],
    fontWeight: '700',
    color: 'TxtDefault',
  })
)

export const Nds = styled.div(
  css({
    fontSize: ['14px', null, '16px'],
    lineHeight: ['16px', null, '24px'],
    color: 'TxtDefault',
  })
)

export const Date = styled.div(
  css({
    alignSelf: 'flex-end',
    fontSize: '12px',
    lineHeight: '24px',
    color: 'TxtPrimary',
  })
)
