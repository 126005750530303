import { graphql } from '~api/gql-generated'

export const updateNoteMutation = graphql(/* GraphQL */ `
  mutation UpdateNote($noteId: Int!, $input: NoteUpdateInput!) {
    note(noteId: $noteId) {
      update(input: $input) {
        id
        entityId
        createdAt
        authorId
        model
        text
        updatedAt
      }
    }
  }
`)

export const addNoteMutation = graphql(/* GraphQL */ `
  mutation AddNote($input: NoteCreateInput!) {
    noteCreate(input: $input) {
      id
      text
      authorId
      model
      entityId
      createdAt
      updatedAt
    }
  }
`)
