import { codeUnreachable } from '~/utils/code-unreachable'
import { CompanyVerificationStatus } from '~api/generated'

export function getCompanyStatusName(status: CompanyVerificationStatus) {
  switch (status) {
    case CompanyVerificationStatus.OnModeration:
      return 'На модерации'
    case CompanyVerificationStatus.Verified:
      return 'Подтвержден'
    case CompanyVerificationStatus.NotVerified:
      return 'Не подтвержден'
    case CompanyVerificationStatus.Rejected:
      return 'Отклонен'
    default:
      return codeUnreachable(status)
  }
}
