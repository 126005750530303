import { useMutation } from '@tanstack/react-query'

import { ElevatorAdvertApiElevatorAdvertControllerUpdateElevatorAdvertPriceRequest } from '~/api/generated'
import { useApi } from '~providers/api'

export const useUpdateElevatorAdvertPrice = () => {
  const api = useApi()

  return useMutation((request: ElevatorAdvertApiElevatorAdvertControllerUpdateElevatorAdvertPriceRequest) => {
    return api.elevatorAdvert.elevatorAdvertControllerUpdateElevatorAdvertPrice(request)
  })
}
