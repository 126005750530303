import styled from 'styled-components'

import { css } from '@styled-system/css'

export const Wrapper = styled.div(
  css({
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
  })
)

export const SpinWrapper = styled.div(
  css({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '150px',
  })
)
