import { useQuery, UseQueryOptions } from '@tanstack/react-query'

import { UserApiUserControllerGetUsersRequest, UserData } from '~/api/generated'
import { isApiResponseEmpty } from '~/utils/isApiResponseEmpty'
import { useApi } from '~providers/api'

export const useGetUsers = (
  request: UserApiUserControllerGetUsersRequest,
  options?: Omit<
    UseQueryOptions<UserData[] | null, unknown, UserData[] | null, (string | { ids: number[] | null })[]>,
    'queryKey' | 'queryFn'
  >
) => {
  const api = useApi()

  const fetchFn = async () => {
    try {
      const res = await api.users.userControllerGetUsers(request)

      return res.data.users
    } catch (error) {
      if (isApiResponseEmpty(error)) {
        return null
      }

      return Promise.reject(error)
    }
  }

  return useQuery(['fetchUsers'], fetchFn, options)
}
