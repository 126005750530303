import { Button } from 'antd'
import styled from 'styled-components'

import { css } from '@styled-system/css'

export const Header = styled.div(
  css({
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: ['space-between', null, 'flex-start'],
    columnGap: ['16px', null, '24px'],
    rowGap: '16px',
  })
)

export const SortingWrapper = styled.div(
  css({
    width: ['100%', null, null, 'auto'],
    order: [1, null, null, 0],
  })
)

export const CreatOfferButton = styled(Button)(
  css({
    marginRight: 'auto',
    width: ['100%', null, 'auto'],
  })
)
