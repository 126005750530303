import { useMutation } from '@tanstack/react-query'

import { UpdateEdmOperatorBody } from '~/api/generated'
import { useApi } from '~providers/api'

export const useUpdateCompanyEdo = (companyId: number) => {
  const api = useApi()

  return useMutation((updateEdmOperatorBody: UpdateEdmOperatorBody) => {
    return api.companies.edmOperatorControllerUpdateEdmOperator({
      companyId,
      updateEdmOperatorBody,
    })
  })
}
