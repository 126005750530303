import styled from 'styled-components'

import { css } from '@styled-system/css'

import { breakpoints } from '~/tokens'

export const Header = styled.div`
  display: grid;
  grid-template-columns: max-content auto;
  gap: 16px;
  align-items: center;
  @media (max-width: ${breakpoints.DESKTOP_S}px) {
    grid-template-columns: 1fr;
  }
`

export const Content = styled.div`
  display: grid;
  gap: 16px;
`
export const ShowDeletedToggler = styled.div`
  display: flex;
  gap: 24px;
  align-items: center;
  margin-left: auto;
`

export const Desktop = styled.div(
  css({
    // display: ['none', null, 'block'],
    maxWidth: '100%',
    overflow: 'hidden',
  })
)
