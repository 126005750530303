import React, { useEffect, useState } from 'react'

import { Button, Checkbox, Input, Select } from 'antd'
import { Controller, useForm } from 'react-hook-form'

import { yupResolver } from '@hookform/resolvers/yup'

import { Box, DaDataPartySuggestion, FormField, FormFooter, InputDadata } from '~/ui-components'
import { CompanyDataSignatoryLegalBasisEnum } from '~api/generated'
import { AddressControlAutocomplete } from '~shared/controls'
import { UsingNdsControl } from '~shared/controls/UsingNdsControl'

import { companyInfoFormSchema } from './CompanyInfoForm.validation'

import { ICompanyInfoForm, ICompanyInfoFormProps } from './CompanyInfoForm.types'

import { FormItem, RequiredFieldsMessage } from '../../../../shared.styled'
import * as S from './CompanyInfoForm.styled'

export const CompanyInfoForm: React.FC<ICompanyInfoFormProps> = ({ isLoading, defaultValues, onSubmit }) => {
  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    watch,
    formState: { errors, isDirty },
  } = useForm<ICompanyInfoForm>({
    defaultValues: {
      name: defaultValues?.name ?? '',
      inn: defaultValues?.inn ?? '',
      kpp: defaultValues?.kpp ?? '',
      ogrn: defaultValues?.ogrn ?? '',
      codeOkved: defaultValues?.codeOkved ?? '',
      director: defaultValues?.director ?? '',
      signatory: defaultValues?.signatory ?? '',
      signatoryPosition: defaultValues?.signatoryPosition ?? '',
      signatoryLegalBasis: defaultValues?.signatoryLegalBasis ?? undefined,
      actualAddress: defaultValues?.actualAddress ?? '',
      legalAddress: defaultValues?.legalAddress ?? '',
      usingNds: defaultValues?.usingNds ?? undefined,
      aicCharterMember: defaultValues?.aicCharterMember ?? false,
    },
    resolver: yupResolver(companyInfoFormSchema),
  })

  const legalAddressFormControl = watch('legalAddress')
  const actualAddressFormControl = watch('actualAddress')
  const nameFormControl = watch('name')

  const [isSameAddress, setIsSameAddress] = useState<boolean>(legalAddressFormControl === actualAddressFormControl)

  console.log(watch())
  useEffect(() => {
    if (!legalAddressFormControl?.trim() || !actualAddressFormControl?.trim()) {
      setIsSameAddress(false)

      return
    }

    setIsSameAddress(legalAddressFormControl === actualAddressFormControl)
  }, [legalAddressFormControl, actualAddressFormControl])

  const [dadataSelected, setDadataSelected] = useState<DaDataPartySuggestion | null>(null)
  const [oneOfDadataInputWasChanged, setOneOfDadataInputWasChanged] = useState<boolean>(false)

  useEffect(() => {
    if (isSameAddress) {
      setValue('actualAddress', getValues('legalAddress'), { shouldTouch: true })
    } else {
      setValue('actualAddress', defaultValues?.actualAddress ?? '', { shouldTouch: true })
    }
  }, [isSameAddress])

  // set dadata's inputs after selecting dadata suggestion
  const setFormAccordingByDadata = (value: DaDataPartySuggestion | undefined) => {
    const data = value?.data
    if (!data) {
      console.log('There is no data for set')

      return
    }
    setValue('name', data.name?.short_with_opf ?? value?.value, {
      shouldValidate: true,
    })
    setValue('inn', data?.inn, { shouldValidate: true })
    setValue('kpp', data?.kpp, { shouldValidate: true })
    setValue('ogrn', data?.ogrn, { shouldValidate: true })
    setValue('codeOkved', data?.okved, { shouldValidate: true })
    setValue('director', data.type === 'INDIVIDUAL' ? data.name?.full : data.management?.name ?? '', {
      shouldValidate: true,
    })
    setValue('legalAddress', data.address?.value, { shouldValidate: true })

    setOneOfDadataInputWasChanged(false)
  }

  useEffect(() => {
    if (!oneOfDadataInputWasChanged) {
      return
    }

    // clear form fields when one of dadata's inputs was changed
    if (oneOfDadataInputWasChanged) {
      setValue('name', '', { shouldTouch: true })
      setValue('inn', '', { shouldTouch: true })
      setValue('kpp', '', { shouldTouch: true })
      setValue('ogrn', '', { shouldTouch: true })
      setValue('codeOkved', '', { shouldTouch: true })
      setValue('director', '', { shouldTouch: true })
      setValue('legalAddress', '', { shouldTouch: true })
      setDadataSelected(null)
      setOneOfDadataInputWasChanged(false)

      return
    }
  }, [oneOfDadataInputWasChanged])

  const handleDadataSelect = (val?: DaDataPartySuggestion | undefined): void => {
    setDadataSelected(val ?? null)
    setFormAccordingByDadata(val)
  }

  const onSubmitHandler = (data: ICompanyInfoForm) => {
    onSubmit(data)
    // reset();
  }

  const isKppControlDisabled = dadataSelected?.data?.type === 'INDIVIDUAL' || nameFormControl.startsWith('ИП ')

  return (
    <form onSubmit={handleSubmit(onSubmitHandler)}>
      <FormItem>
        <FormField label="Наименование компании" isRequired htmlFor="name" validateMessage={errors.name?.message}>
          <Controller
            name="name"
            control={control}
            render={({ field }) => (
              <InputDadata
                id="name"
                placeholder="Введите наименование компании"
                inputValue={dadataSelected?.data?.name?.short_with_opf ?? field.value}
                onChangeInput={(v) => {
                  setOneOfDadataInputWasChanged(true)
                }}
                onSelect={handleDadataSelect}
                variant="organizations"
                inputStatus={errors.name && 'error'}
              />
            )}
          />
        </FormField>
      </FormItem>

      <S.FormGridHalf>
        <FormItem>
          <FormField label="ИНН" isRequired htmlFor="inn" validateMessage={errors.inn?.message}>
            <Controller
              name="inn"
              control={control}
              render={({ field }) => (
                <InputDadata
                  id="inn"
                  placeholder="Введите номер"
                  inputValue={dadataSelected?.data?.inn ?? field.value}
                  onChangeInput={(v) => {
                    setOneOfDadataInputWasChanged(true)
                  }}
                  onSelect={handleDadataSelect}
                  variant="organizations"
                  inputStatus={errors.inn && 'error'}
                />
              )}
            />
          </FormField>
        </FormItem>

        <FormItem>
          <FormField label="КПП" isRequired htmlFor="kpp" validateMessage={errors.kpp?.message}>
            <Controller
              name="kpp"
              control={control}
              render={({ field }) => (
                <Input
                  {...field}
                  id="kpp"
                  status={errors.kpp && 'error'}
                  placeholder="Введите КПП"
                  disabled={isKppControlDisabled}
                />
              )}
            />
          </FormField>
        </FormItem>

        <FormItem>
          <FormField label="ОГРН" isRequired htmlFor="ogrn" validateMessage={errors.ogrn?.message}>
            <Controller
              name="ogrn"
              control={control}
              render={({ field }) => (
                <Input {...field} id="ogrn" status={errors.ogrn && 'error'} placeholder="Введите ОГРН" />
              )}
            />
          </FormField>
        </FormItem>

        <FormItem>
          <FormField label="ОКВЭД" isRequired htmlFor="codeOkved" validateMessage={errors.codeOkved?.message}>
            <Controller
              name="codeOkved"
              control={control}
              render={({ field }) => (
                <Input {...field} id="codeOkved" status={errors.codeOkved && 'error'} placeholder="Введите ОКВЭД" />
              )}
            />
          </FormField>
        </FormItem>
      </S.FormGridHalf>

      <FormItem>
        <FormItem>
          <FormField
            label="Генеральный директор"
            isRequired
            htmlFor="director"
            validateMessage={errors.director?.message}
          >
            <Controller
              name="director"
              control={control}
              render={({ field }) => (
                <Input {...field} id="director" status={errors.director && 'error'} placeholder="Введите ФИО" />
              )}
            />
          </FormField>
        </FormItem>

        <FormItem>
          <FormField label="Подписант" isRequired htmlFor="signatory" validateMessage={errors.signatory?.message}>
            <Controller
              name="signatory"
              control={control}
              render={({ field }) => (
                <Input {...field} id="signatory" status={errors.signatory && 'error'} placeholder="Введите ФИО" />
              )}
            />
          </FormField>
        </FormItem>

        <FormItem>
          <FormField
            label="Должность подписанта"
            isRequired
            htmlFor="signatoryPosition"
            validateMessage={errors.signatoryPosition?.message}
          >
            <Controller
              name="signatoryPosition"
              control={control}
              render={({ field }) => (
                <Input
                  {...field}
                  id="signatoryPosition"
                  status={errors.signatoryPosition && 'error'}
                  placeholder="Введите название должности"
                />
              )}
            />
          </FormField>
        </FormItem>

        <FormItem>
          <FormField
            label="Основание"
            isRequired
            htmlFor="signatoryLegalBasis"
            validateMessage={errors.signatoryLegalBasis?.message}
          >
            <Controller
              name="signatoryLegalBasis"
              control={control}
              render={({ field }) => (
                <Select
                  status={errors.signatoryLegalBasis && 'error'}
                  style={{ width: '100%' }}
                  value={field.value}
                  placeholder="Выберите основание права подписи"
                  onChange={(e) => field.onChange(e)}
                >
                  <Select.Option value={CompanyDataSignatoryLegalBasisEnum.CompanyRegulation}>Устав</Select.Option>
                  <Select.Option value={CompanyDataSignatoryLegalBasisEnum.PowerOfAttorney}>Доверенность</Select.Option>
                </Select>
              )}
            />
          </FormField>
        </FormItem>

        <FormField label="Юридический адрес" htmlFor="legalAddress" validateMessage={errors.legalAddress?.message}>
          <Controller
            name="legalAddress"
            control={control}
            render={({ field }) => (
              <AddressControlAutocomplete
                id="legalAddress"
                value={field.value}
                onChange={field.onChange}
                hasError={!!errors.legalAddress}
                onAddressSelect={(suggestion) => field.onChange(suggestion?.value ?? suggestion?.unrestricted_value)}
              />
            )}
          />
        </FormField>
      </FormItem>

      <FormItem>
        <FormField label="Физический адрес" htmlFor="actualAddress" validateMessage={errors.actualAddress?.message}>
          <>
            <Controller
              name="actualAddress"
              control={control}
              render={({ field }) => (
                <AddressControlAutocomplete
                  id="actualAddress"
                  value={field.value}
                  onChange={field.onChange}
                  hasError={!!errors.actualAddress}
                  onAddressSelect={(suggestion) => field.onChange(suggestion?.value ?? suggestion?.unrestricted_value)}
                />
              )}
            />
            <Box mt={4}>
              <Checkbox id="sameAddress" checked={isSameAddress} onChange={(e) => setIsSameAddress(e.target.checked)}>
                Адрес совпадает с юридическим
              </Checkbox>
            </Box>
          </>
        </FormField>
      </FormItem>

      <S.FormGridHalf>
        <FormItem>
          <FormField label="Применение НДС" isRequired htmlFor="usingNds" validateMessage={errors.usingNds?.message}>
            <Controller name="usingNds" control={control} render={({ field }) => <UsingNdsControl {...field} />} />
          </FormField>
        </FormItem>
        <FormItem>
          <FormField validateMessage={errors.aicCharterMember?.message}>
            <Controller
              name="aicCharterMember"
              control={control}
              render={({ field }) => (
                <Checkbox
                  id="aicCharterMember"
                  checked={field.value}
                  onChange={(e) => field.onChange(e.target.checked)}
                >
                  Участник хартии АПК
                </Checkbox>
              )}
            />
          </FormField>
        </FormItem>
      </S.FormGridHalf>

      <FormItem style={{ alignItems: 'end' }}>
        <RequiredFieldsMessage />
      </FormItem>

      <FormFooter>
        <S.ActionWrapper>
          <Button htmlType="submit" type="primary" disabled={!isDirty || isLoading}>
            {isLoading ? 'Сохранение' : 'Сохранить'}
          </Button>
        </S.ActionWrapper>
      </FormFooter>
    </form>
  )
}
