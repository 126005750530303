import { useMutation } from '@tanstack/react-query'

import { useUploadFileAndGetId } from '~hooks/_utils'
import { useApi } from '~providers/api'

interface useUploadCompanyDocumentsArchiveProps {
  companyId: number
  documentsArchive: File
}

export const useUploadCompanyDocumentsArchive = () => {
  const api = useApi()
  const uploadFileAndGetId = useUploadFileAndGetId()

  return useMutation(async ({ companyId, documentsArchive }: useUploadCompanyDocumentsArchiveProps) => {
    const companyDocumentsArchiveFileKey = await uploadFileAndGetId.mutateAsync(documentsArchive)

    return api.companies.updateDocumentArchive({
      companyId,
      updateDocumentsArchiveBody: {
        companyDocumentsArchiveFileKey,
      },
    })
  })
}
