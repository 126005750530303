import React from 'react'

import { Empty, Spin } from 'antd'

import { BasisListItemMobile } from '~pages/BasisPage/modules/BasisList/components/BasisListItemMobile'

import { BasisListProps } from '~pages/BasisPage/modules/BasisList/BasisList.types'

import * as S from './BasisListMobile.styled'

export const BasisListMobile: React.FC<BasisListProps> = ({ onBasisClick, basisList, isLoading }) => {
  return (
    <Spin spinning={isLoading}>
      <S.ContentWrapper>
        {!basisList?.length && !isLoading && <Empty />}
        {basisList?.map((basis) => <BasisListItemMobile key={basis.id} item={basis} onClick={onBasisClick} />)}
      </S.ContentWrapper>
    </Spin>
  )
}
