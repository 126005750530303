import * as yup from 'yup'

import { VALIDATORS_MSG } from '~shared/validations'

export const changeOfferModalSchema = yup.object({
  volume: yup
    .number()
    .typeError(VALIDATORS_MSG.required)
    .min(20, VALIDATORS_MSG.minValue(20))
    .required(VALIDATORS_MSG.required),
  price: yup
    .number()
    .typeError(VALIDATORS_MSG.required)
    .min(1000, VALIDATORS_MSG.minValue(1000))
    .required(VALIDATORS_MSG.required),
})
