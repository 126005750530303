import { useQuery, UseQueryResult } from '@tanstack/react-query'

import { CompanyData, GetCompanyZernoAgregatorResponse } from '~api/generated'
import { useApi } from '~providers/api'
import { useAuth } from '~providers/auth'

export const useGetCompanyZernoAgregator = (): UseQueryResult<CompanyData | null> => {
  const api = useApi()
  const { authenticated } = useAuth()

  const fetchFn = async (): Promise<GetCompanyZernoAgregatorResponse> => {
    try {
      const res = await api.companies.companyControllerGetCompanyZernoAgregator()
      return res.data
    } catch (err) {
      const description = 'Ошибка при получении данных Zerno Agregator'
      console.error(description, err)
      return Promise.reject(err)
    }
  }

  return useQuery(['fetchCompanyZernoAgregator'], fetchFn, {
    staleTime: Infinity,
    refetchOnMount: false,
    enabled: authenticated,
    retry: 3,
  })
}
