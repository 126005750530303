import { graphql } from '~api/gql-generated'

export const driverCreateMutation = graphql(/* GraphQL */ `
  mutation DriverCreate($input: DriverCreateInput!) {
    driverCreate(input: $input) {
      id
    }
  }
`)

export const driverUpdateMutation = graphql(/* GraphQL */ `
  mutation UpdateDriver($input: DriverUpdateInput!, $driverId: Int!) {
    driver(driverId: $driverId) {
      updateDriver(input: $input) {
        id
      }
    }
  }
`)

export const driverSetTransportMutation = graphql(/* GraphQL */ `
  mutation SetTransport($transportId: Int!, $driverId: Int!) {
    driver(driverId: $driverId) {
      setTransport(transportId: $transportId) {
        id
      }
    }
  }
`)

export const driverUserCreateMutation = graphql(/* GraphQL */ `
  mutation DriverUserCreate($input: DriverUserCreateInput!) {
    driverUserCreate(input: $input) {
      id
    }
  }
`)
