import styled from 'styled-components'

import { breakpoints } from '~/tokens'
import { Card, Divider } from '~/ui-components'

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`

export const ColumnWrapper = styled.div`
  display: grid;
  grid-template-columns: 700px 410px;
  grid-gap: 16px;
  align-items: start;
  justify-content: center;

  @media (max-width: ${breakpoints.DESKTOP_L}px) {
    grid-template-columns: 1fr 410px;
  }

  @media (max-width: ${breakpoints.DESKTOP_S}px) {
    grid-template-columns: 1fr;
  }
`

export const Column = styled.div`
  display: grid;
  grid-gap: 16px;
`

export const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`

export const Row = styled.div`
  display: grid;
  grid-template-columns: 170px 1fr;
`

export const CardValueGreen = styled(Card.Value)`
  color: var(--primary-main-color);
`

export const DividerStyled = styled(Divider)`
  width: 100%;
`

export const Parameters = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
`
