import { codeUnreachable } from '~/utils/code-unreachable'
import { BaseCompanyDataTypeEnum, CompanyDataTypeEnum, UserDataRoleEnum } from '~api/generated'
import { CompanyType } from '~api/gql-generated/graphql'

/**
 * @deprecated The method should not be used. После переезда на graphQl надо его удалить
 */
export function getCompanyRoleOld(role?: CompanyDataTypeEnum): string {
  if (!role) {
    return ''
  }

  switch (role) {
    case CompanyDataTypeEnum.Seller:
      return 'Продавец'
    case CompanyDataTypeEnum.Trader:
      return 'Трейдер'
    case CompanyDataTypeEnum.Buyer:
      return 'Покупатель'
    case CompanyDataTypeEnum.DemoCompany:
      return 'Демо-компания'
    case CompanyDataTypeEnum.Carrier:
      return 'Перевозчик'
    default:
      return codeUnreachable(role)
  }
}
export function getCompanyRole(role: CompanyType): string {
  switch (role) {
    case CompanyType.Seller:
      return 'Продавец'
    case CompanyType.Trader:
      return 'Трейдер'
    case CompanyType.Buyer:
      return 'Покупатель'
    case CompanyType.DemoCompany:
      return 'Демо-компания'
    case CompanyType.Carrier:
      return 'Перевозчик'
    default:
      return codeUnreachable(role)
  }
}
