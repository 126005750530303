import { DocumentPartStatus } from '~api/generated'

import { BadgeVariant } from '~ui-components/components/Badge/Badge.types'

interface Status {
  name: string
  color: BadgeVariant
}

export function getDealDocumentStatus(status?: string): Status {
  switch (status) {
    case DocumentPartStatus.Confirmed:
      return {
        name: 'Подтвержден',
        color: 'base',
      }
    case DocumentPartStatus.Unchecked:
      return {
        name: 'На проверке',
        color: 'yellow',
      }
    case DocumentPartStatus.Rejected:
      return {
        name: 'Отклонено',
        color: 'red',
      }
    default:
      return {
        name: 'Ошибка',
        color: 'red',
      }
  }
}
