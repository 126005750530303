import * as yup from 'yup'

import { VALIDATORS_MSG } from '~shared/validations'

export const addNewCompanyFormValidation = yup
  .object({
    companyRoleType: yup.string().trim().required(VALIDATORS_MSG.required),
    companyInn: yup.string().trim().required(VALIDATORS_MSG.required),
  })
  .required()
