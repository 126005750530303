import styled from 'styled-components'

import { breakpoints } from '~/tokens'

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  //padding: 24px;
  padding-top: 0;

  @media (max-width: ${breakpoints.DESKTOP_S}px) {
    //padding: 12px;
  }
`

export const FiltersWrapper = styled.div`
  display: grid;
  grid-template-columns: 250px 250px;
  gap: 8px;
  margin-bottom: 16px;

  @media (max-width: ${breakpoints.DESKTOP_S}px) {
    grid-template-columns: 1fr;
  }
`
