import { useQuery, UseQueryOptions } from '@tanstack/react-query'

import { AuctionApiAuctionControllerGetAuctionsRequest, GetAuctionsResponse } from '~/api/generated'
import { useApi } from '~/providers/api'
import { isApiResponseEmpty } from '~/utils/isApiResponseEmpty'

type Result = GetAuctionsResponse | null

export const useGetAuctions = (
  request: AuctionApiAuctionControllerGetAuctionsRequest,
  options?: Omit<UseQueryOptions<Result, Error>, 'queryKey' | 'queryFn'>
) => {
  const api = useApi()

  const fetchFn = async (): Promise<Result> => {
    try {
      const response = await api.auction.auctionControllerGetAuctions(request)
      return response.data
    } catch (error) {
      if (isApiResponseEmpty(error)) {
        return null
      }

      return Promise.reject(error)
    }
  }

  return useQuery(['auctions', request.currentPage, request.pageSize], fetchFn, options)
}
