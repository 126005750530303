/* tslint:disable */
/* eslint-disable */
/**
 * API Зерно-Агрегатора
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CreateComplexDealCarrierBody } from '../models';
// @ts-ignore
import { CreateDealBody } from '../models';
// @ts-ignore
import { CreateDealResponse } from '../models';
// @ts-ignore
import { CreateDealSpecificationBody } from '../models';
// @ts-ignore
import { GetCompanyDealsResponse } from '../models';
// @ts-ignore
import { GetComplexDealResponse } from '../models';
// @ts-ignore
import { GetComplexDealsResponse } from '../models';
// @ts-ignore
import { GetDealLogisticsInfoResponse } from '../models';
// @ts-ignore
import { OrderDirection } from '../models';
// @ts-ignore
import { UpdateAdditionalServicesBody } from '../models';
// @ts-ignore
import { UpdateComplexDealModeratorBody } from '../models';
// @ts-ignore
import { UpdateComplexDealOfferBody } from '../models';
// @ts-ignore
import { UpdateComplexDealStatusBody } from '../models';
// @ts-ignore
import { UpdateDealCarrierBody } from '../models';
// @ts-ignore
import { UpdateDealCompanyBankAccountBody } from '../models';
// @ts-ignore
import { UpdateDealLogisticsInfoBody } from '../models';
// @ts-ignore
import { UpdateDealStatusBody } from '../models';
/**
 * DealApi - axios parameter creator
 * @export
 */
export const DealApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} complexDealId 
         * @param {CreateComplexDealCarrierBody} createComplexDealCarrierBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dealControllerCreateComplexDealCarrier: async (complexDealId: number, createComplexDealCarrierBody: CreateComplexDealCarrierBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'complexDealId' is not null or undefined
            assertParamExists('dealControllerCreateComplexDealCarrier', 'complexDealId', complexDealId)
            // verify required parameter 'createComplexDealCarrierBody' is not null or undefined
            assertParamExists('dealControllerCreateComplexDealCarrier', 'createComplexDealCarrierBody', createComplexDealCarrierBody)
            const localVarPath = `/complex-deal/{complexDealId}/carrier`
                .replace(`{${"complexDealId"}}`, encodeURIComponent(String(complexDealId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createComplexDealCarrierBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} companyId 
         * @param {CreateDealBody} createDealBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dealControllerCreateDeal: async (companyId: number, createDealBody: CreateDealBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('dealControllerCreateDeal', 'companyId', companyId)
            // verify required parameter 'createDealBody' is not null or undefined
            assertParamExists('dealControllerCreateDeal', 'createDealBody', createDealBody)
            const localVarPath = `/company/{companyId}/deal`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createDealBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} dealId 
         * @param {CreateDealSpecificationBody} createDealSpecificationBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dealControllerCreateDealSpecification: async (dealId: number, createDealSpecificationBody: CreateDealSpecificationBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dealId' is not null or undefined
            assertParamExists('dealControllerCreateDealSpecification', 'dealId', dealId)
            // verify required parameter 'createDealSpecificationBody' is not null or undefined
            assertParamExists('dealControllerCreateDealSpecification', 'createDealSpecificationBody', createDealSpecificationBody)
            const localVarPath = `/deal/{dealId}/specification`
                .replace(`{${"dealId"}}`, encodeURIComponent(String(dealId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createDealSpecificationBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} companyId 
         * @param {number} [currentPage] Номер текущей страницы
         * @param {number} [pageSize] Количество элементов на странице
         * @param {Array<number>} [ids] Список id сделок
         * @param {Array<string>} [regionIds] Список id регионов
         * @param {Array<string>} [productTypes] Список типов товаров
         * @param {'BUY' | 'SELL'} [type] Тип заявки
         * @param {Array<'AGREEMENT' | 'SIGNING' | 'PAYMENT' | 'SHIPMENT' | 'REJECTED' | 'REALISED'>} [statuses] Статусы сделок
         * @param {number} [minVolume] Минимальный объем
         * @param {number} [maxVolume] Максимальный объем
         * @param {number} [minPrice] Минимальная цена
         * @param {number} [maxPrice] Максимальная цена
         * @param {'id' | 'offerId' | 'status' | 'sellerId' | 'buyerId' | 'specificationId' | 'sellerBankAccountId' | 'pairedDealId' | 'createdAt' | 'updatedAt' | 'createdBy' | 'moderatedBy' | 'buyerBankAccountId' | 'startedAt'} [orderBy] Поле для сортировки
         * @param {OrderDirection} [order] Направление сортировки
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dealControllerGetCompanyDeals: async (companyId: number, currentPage?: number, pageSize?: number, ids?: Array<number>, regionIds?: Array<string>, productTypes?: Array<string>, type?: 'BUY' | 'SELL', statuses?: Array<'AGREEMENT' | 'SIGNING' | 'PAYMENT' | 'SHIPMENT' | 'REJECTED' | 'REALISED'>, minVolume?: number, maxVolume?: number, minPrice?: number, maxPrice?: number, orderBy?: 'id' | 'offerId' | 'status' | 'sellerId' | 'buyerId' | 'specificationId' | 'sellerBankAccountId' | 'pairedDealId' | 'createdAt' | 'updatedAt' | 'createdBy' | 'moderatedBy' | 'buyerBankAccountId' | 'startedAt', order?: OrderDirection, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('dealControllerGetCompanyDeals', 'companyId', companyId)
            const localVarPath = `/company/{companyId}/deals`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (currentPage !== undefined) {
                localVarQueryParameter['current-page'] = currentPage;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page-size'] = pageSize;
            }

            if (ids) {
                localVarQueryParameter['ids'] = ids;
            }

            if (regionIds) {
                localVarQueryParameter['region-ids'] = regionIds;
            }

            if (productTypes) {
                localVarQueryParameter['product-types'] = productTypes;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            if (statuses) {
                localVarQueryParameter['statuses'] = statuses;
            }

            if (minVolume !== undefined) {
                localVarQueryParameter['min-volume'] = minVolume;
            }

            if (maxVolume !== undefined) {
                localVarQueryParameter['max-volume'] = maxVolume;
            }

            if (minPrice !== undefined) {
                localVarQueryParameter['min-price'] = minPrice;
            }

            if (maxPrice !== undefined) {
                localVarQueryParameter['max-price'] = maxPrice;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['order-by'] = orderBy;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} complexDealId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dealControllerGetComplexDeal: async (complexDealId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'complexDealId' is not null or undefined
            assertParamExists('dealControllerGetComplexDeal', 'complexDealId', complexDealId)
            const localVarPath = `/complex-deal/{complexDealId}`
                .replace(`{${"complexDealId"}}`, encodeURIComponent(String(complexDealId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [currentPage] Номер текущей страницы
         * @param {number} [pageSize] Количество элементов на странице
         * @param {Array<'ON_MODERATION' | 'REJECTED_AFTER_MODERATION' | 'AGREEMENT' | 'SIGNING' | 'PAYMENT' | 'SHIPMENT' | 'REJECTED' | 'REALISED'>} [statuses] Статусы сделок
         * @param {Array<number>} [ids] Список id комплексных сделок
         * @param {Array<string>} [regionIds] Список id регионов
         * @param {Array<string>} [productTypes] Список типов товаров
         * @param {'BUY' | 'SELL'} [type] Тип заявки
         * @param {number} [minVolume] Минимальный объем
         * @param {number} [maxVolume] Максимальный объем
         * @param {number} [minPrice] Минимальная цена
         * @param {number} [maxPrice] Максимальная цена
         * @param {'id' | 'offerId' | 'status' | 'sellerId' | 'buyerId' | 'specificationId' | 'sellerBankAccountId' | 'pairedDealId' | 'createdAt' | 'updatedAt' | 'createdBy' | 'moderatedBy' | 'buyerBankAccountId' | 'startedAt'} [orderBy] Поле для сортировки
         * @param {OrderDirection} [order] Направление сортировки
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dealControllerGetComplexDeals: async (currentPage?: number, pageSize?: number, statuses?: Array<'ON_MODERATION' | 'REJECTED_AFTER_MODERATION' | 'AGREEMENT' | 'SIGNING' | 'PAYMENT' | 'SHIPMENT' | 'REJECTED' | 'REALISED'>, ids?: Array<number>, regionIds?: Array<string>, productTypes?: Array<string>, type?: 'BUY' | 'SELL', minVolume?: number, maxVolume?: number, minPrice?: number, maxPrice?: number, orderBy?: 'id' | 'offerId' | 'status' | 'sellerId' | 'buyerId' | 'specificationId' | 'sellerBankAccountId' | 'pairedDealId' | 'createdAt' | 'updatedAt' | 'createdBy' | 'moderatedBy' | 'buyerBankAccountId' | 'startedAt', order?: OrderDirection, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/complex-deals`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (currentPage !== undefined) {
                localVarQueryParameter['current-page'] = currentPage;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page-size'] = pageSize;
            }

            if (statuses) {
                localVarQueryParameter['statuses'] = statuses;
            }

            if (ids) {
                localVarQueryParameter['ids'] = ids;
            }

            if (regionIds) {
                localVarQueryParameter['region-ids'] = regionIds;
            }

            if (productTypes) {
                localVarQueryParameter['product-types'] = productTypes;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            if (minVolume !== undefined) {
                localVarQueryParameter['min-volume'] = minVolume;
            }

            if (maxVolume !== undefined) {
                localVarQueryParameter['max-volume'] = maxVolume;
            }

            if (minPrice !== undefined) {
                localVarQueryParameter['min-price'] = minPrice;
            }

            if (maxPrice !== undefined) {
                localVarQueryParameter['max-price'] = maxPrice;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['order-by'] = orderBy;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} dealId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dealControllerGetDealLogisticsInfo: async (dealId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dealId' is not null or undefined
            assertParamExists('dealControllerGetDealLogisticsInfo', 'dealId', dealId)
            const localVarPath = `/deal/{dealId}/logistics-info`
                .replace(`{${"dealId"}}`, encodeURIComponent(String(dealId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} dealId 
         * @param {UpdateAdditionalServicesBody} updateAdditionalServicesBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dealControllerUpdateAdditionalServices: async (dealId: number, updateAdditionalServicesBody: UpdateAdditionalServicesBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dealId' is not null or undefined
            assertParamExists('dealControllerUpdateAdditionalServices', 'dealId', dealId)
            // verify required parameter 'updateAdditionalServicesBody' is not null or undefined
            assertParamExists('dealControllerUpdateAdditionalServices', 'updateAdditionalServicesBody', updateAdditionalServicesBody)
            const localVarPath = `/deal/{dealId}/additional-services`
                .replace(`{${"dealId"}}`, encodeURIComponent(String(dealId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateAdditionalServicesBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} complexDealId 
         * @param {UpdateComplexDealModeratorBody} updateComplexDealModeratorBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dealControllerUpdateComplexDealModerator: async (complexDealId: number, updateComplexDealModeratorBody: UpdateComplexDealModeratorBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'complexDealId' is not null or undefined
            assertParamExists('dealControllerUpdateComplexDealModerator', 'complexDealId', complexDealId)
            // verify required parameter 'updateComplexDealModeratorBody' is not null or undefined
            assertParamExists('dealControllerUpdateComplexDealModerator', 'updateComplexDealModeratorBody', updateComplexDealModeratorBody)
            const localVarPath = `/complex-deal/{complexDealId}/moderator`
                .replace(`{${"complexDealId"}}`, encodeURIComponent(String(complexDealId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateComplexDealModeratorBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} complexDealId 
         * @param {UpdateComplexDealOfferBody} updateComplexDealOfferBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dealControllerUpdateComplexDealOffer: async (complexDealId: number, updateComplexDealOfferBody: UpdateComplexDealOfferBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'complexDealId' is not null or undefined
            assertParamExists('dealControllerUpdateComplexDealOffer', 'complexDealId', complexDealId)
            // verify required parameter 'updateComplexDealOfferBody' is not null or undefined
            assertParamExists('dealControllerUpdateComplexDealOffer', 'updateComplexDealOfferBody', updateComplexDealOfferBody)
            const localVarPath = `/complex-deal/{complexDealId}/offer`
                .replace(`{${"complexDealId"}}`, encodeURIComponent(String(complexDealId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateComplexDealOfferBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} complexDealId 
         * @param {UpdateComplexDealStatusBody} updateComplexDealStatusBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dealControllerUpdateComplexDealStatus: async (complexDealId: number, updateComplexDealStatusBody: UpdateComplexDealStatusBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'complexDealId' is not null or undefined
            assertParamExists('dealControllerUpdateComplexDealStatus', 'complexDealId', complexDealId)
            // verify required parameter 'updateComplexDealStatusBody' is not null or undefined
            assertParamExists('dealControllerUpdateComplexDealStatus', 'updateComplexDealStatusBody', updateComplexDealStatusBody)
            const localVarPath = `/complex-deal/{complexDealId}/status`
                .replace(`{${"complexDealId"}}`, encodeURIComponent(String(complexDealId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateComplexDealStatusBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} dealId 
         * @param {UpdateDealCarrierBody} updateDealCarrierBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dealControllerUpdateDealCarrier: async (dealId: number, updateDealCarrierBody: UpdateDealCarrierBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dealId' is not null or undefined
            assertParamExists('dealControllerUpdateDealCarrier', 'dealId', dealId)
            // verify required parameter 'updateDealCarrierBody' is not null or undefined
            assertParamExists('dealControllerUpdateDealCarrier', 'updateDealCarrierBody', updateDealCarrierBody)
            const localVarPath = `/deal/{dealId}/carrier`
                .replace(`{${"dealId"}}`, encodeURIComponent(String(dealId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateDealCarrierBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} dealId 
         * @param {UpdateDealCompanyBankAccountBody} updateDealCompanyBankAccountBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dealControllerUpdateDealCompanyBankAccount: async (dealId: number, updateDealCompanyBankAccountBody: UpdateDealCompanyBankAccountBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dealId' is not null or undefined
            assertParamExists('dealControllerUpdateDealCompanyBankAccount', 'dealId', dealId)
            // verify required parameter 'updateDealCompanyBankAccountBody' is not null or undefined
            assertParamExists('dealControllerUpdateDealCompanyBankAccount', 'updateDealCompanyBankAccountBody', updateDealCompanyBankAccountBody)
            const localVarPath = `/deal/{dealId}/bank-account`
                .replace(`{${"dealId"}}`, encodeURIComponent(String(dealId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateDealCompanyBankAccountBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} dealId 
         * @param {UpdateDealLogisticsInfoBody} updateDealLogisticsInfoBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dealControllerUpdateDealLogisticsInfoLoading: async (dealId: number, updateDealLogisticsInfoBody: UpdateDealLogisticsInfoBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dealId' is not null or undefined
            assertParamExists('dealControllerUpdateDealLogisticsInfoLoading', 'dealId', dealId)
            // verify required parameter 'updateDealLogisticsInfoBody' is not null or undefined
            assertParamExists('dealControllerUpdateDealLogisticsInfoLoading', 'updateDealLogisticsInfoBody', updateDealLogisticsInfoBody)
            const localVarPath = `/deal/{dealId}/logistics-info/loading`
                .replace(`{${"dealId"}}`, encodeURIComponent(String(dealId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateDealLogisticsInfoBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} dealId 
         * @param {UpdateDealLogisticsInfoBody} updateDealLogisticsInfoBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dealControllerUpdateDealLogisticsInfoUnloading: async (dealId: number, updateDealLogisticsInfoBody: UpdateDealLogisticsInfoBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dealId' is not null or undefined
            assertParamExists('dealControllerUpdateDealLogisticsInfoUnloading', 'dealId', dealId)
            // verify required parameter 'updateDealLogisticsInfoBody' is not null or undefined
            assertParamExists('dealControllerUpdateDealLogisticsInfoUnloading', 'updateDealLogisticsInfoBody', updateDealLogisticsInfoBody)
            const localVarPath = `/deal/{dealId}/logistics-info/unloading`
                .replace(`{${"dealId"}}`, encodeURIComponent(String(dealId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateDealLogisticsInfoBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} dealId 
         * @param {UpdateDealStatusBody} updateDealStatusBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dealControllerUpdateDealStatus: async (dealId: number, updateDealStatusBody: UpdateDealStatusBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dealId' is not null or undefined
            assertParamExists('dealControllerUpdateDealStatus', 'dealId', dealId)
            // verify required parameter 'updateDealStatusBody' is not null or undefined
            assertParamExists('dealControllerUpdateDealStatus', 'updateDealStatusBody', updateDealStatusBody)
            const localVarPath = `/deal/{dealId}/status`
                .replace(`{${"dealId"}}`, encodeURIComponent(String(dealId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateDealStatusBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DealApi - functional programming interface
 * @export
 */
export const DealApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DealApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} complexDealId 
         * @param {CreateComplexDealCarrierBody} createComplexDealCarrierBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dealControllerCreateComplexDealCarrier(complexDealId: number, createComplexDealCarrierBody: CreateComplexDealCarrierBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dealControllerCreateComplexDealCarrier(complexDealId, createComplexDealCarrierBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} companyId 
         * @param {CreateDealBody} createDealBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dealControllerCreateDeal(companyId: number, createDealBody: CreateDealBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateDealResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dealControllerCreateDeal(companyId, createDealBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} dealId 
         * @param {CreateDealSpecificationBody} createDealSpecificationBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dealControllerCreateDealSpecification(dealId: number, createDealSpecificationBody: CreateDealSpecificationBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dealControllerCreateDealSpecification(dealId, createDealSpecificationBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} companyId 
         * @param {number} [currentPage] Номер текущей страницы
         * @param {number} [pageSize] Количество элементов на странице
         * @param {Array<number>} [ids] Список id сделок
         * @param {Array<string>} [regionIds] Список id регионов
         * @param {Array<string>} [productTypes] Список типов товаров
         * @param {'BUY' | 'SELL'} [type] Тип заявки
         * @param {Array<'AGREEMENT' | 'SIGNING' | 'PAYMENT' | 'SHIPMENT' | 'REJECTED' | 'REALISED'>} [statuses] Статусы сделок
         * @param {number} [minVolume] Минимальный объем
         * @param {number} [maxVolume] Максимальный объем
         * @param {number} [minPrice] Минимальная цена
         * @param {number} [maxPrice] Максимальная цена
         * @param {'id' | 'offerId' | 'status' | 'sellerId' | 'buyerId' | 'specificationId' | 'sellerBankAccountId' | 'pairedDealId' | 'createdAt' | 'updatedAt' | 'createdBy' | 'moderatedBy' | 'buyerBankAccountId' | 'startedAt'} [orderBy] Поле для сортировки
         * @param {OrderDirection} [order] Направление сортировки
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dealControllerGetCompanyDeals(companyId: number, currentPage?: number, pageSize?: number, ids?: Array<number>, regionIds?: Array<string>, productTypes?: Array<string>, type?: 'BUY' | 'SELL', statuses?: Array<'AGREEMENT' | 'SIGNING' | 'PAYMENT' | 'SHIPMENT' | 'REJECTED' | 'REALISED'>, minVolume?: number, maxVolume?: number, minPrice?: number, maxPrice?: number, orderBy?: 'id' | 'offerId' | 'status' | 'sellerId' | 'buyerId' | 'specificationId' | 'sellerBankAccountId' | 'pairedDealId' | 'createdAt' | 'updatedAt' | 'createdBy' | 'moderatedBy' | 'buyerBankAccountId' | 'startedAt', order?: OrderDirection, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCompanyDealsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dealControllerGetCompanyDeals(companyId, currentPage, pageSize, ids, regionIds, productTypes, type, statuses, minVolume, maxVolume, minPrice, maxPrice, orderBy, order, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} complexDealId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dealControllerGetComplexDeal(complexDealId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetComplexDealResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dealControllerGetComplexDeal(complexDealId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [currentPage] Номер текущей страницы
         * @param {number} [pageSize] Количество элементов на странице
         * @param {Array<'ON_MODERATION' | 'REJECTED_AFTER_MODERATION' | 'AGREEMENT' | 'SIGNING' | 'PAYMENT' | 'SHIPMENT' | 'REJECTED' | 'REALISED'>} [statuses] Статусы сделок
         * @param {Array<number>} [ids] Список id комплексных сделок
         * @param {Array<string>} [regionIds] Список id регионов
         * @param {Array<string>} [productTypes] Список типов товаров
         * @param {'BUY' | 'SELL'} [type] Тип заявки
         * @param {number} [minVolume] Минимальный объем
         * @param {number} [maxVolume] Максимальный объем
         * @param {number} [minPrice] Минимальная цена
         * @param {number} [maxPrice] Максимальная цена
         * @param {'id' | 'offerId' | 'status' | 'sellerId' | 'buyerId' | 'specificationId' | 'sellerBankAccountId' | 'pairedDealId' | 'createdAt' | 'updatedAt' | 'createdBy' | 'moderatedBy' | 'buyerBankAccountId' | 'startedAt'} [orderBy] Поле для сортировки
         * @param {OrderDirection} [order] Направление сортировки
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dealControllerGetComplexDeals(currentPage?: number, pageSize?: number, statuses?: Array<'ON_MODERATION' | 'REJECTED_AFTER_MODERATION' | 'AGREEMENT' | 'SIGNING' | 'PAYMENT' | 'SHIPMENT' | 'REJECTED' | 'REALISED'>, ids?: Array<number>, regionIds?: Array<string>, productTypes?: Array<string>, type?: 'BUY' | 'SELL', minVolume?: number, maxVolume?: number, minPrice?: number, maxPrice?: number, orderBy?: 'id' | 'offerId' | 'status' | 'sellerId' | 'buyerId' | 'specificationId' | 'sellerBankAccountId' | 'pairedDealId' | 'createdAt' | 'updatedAt' | 'createdBy' | 'moderatedBy' | 'buyerBankAccountId' | 'startedAt', order?: OrderDirection, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetComplexDealsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dealControllerGetComplexDeals(currentPage, pageSize, statuses, ids, regionIds, productTypes, type, minVolume, maxVolume, minPrice, maxPrice, orderBy, order, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} dealId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dealControllerGetDealLogisticsInfo(dealId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetDealLogisticsInfoResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dealControllerGetDealLogisticsInfo(dealId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} dealId 
         * @param {UpdateAdditionalServicesBody} updateAdditionalServicesBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dealControllerUpdateAdditionalServices(dealId: number, updateAdditionalServicesBody: UpdateAdditionalServicesBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dealControllerUpdateAdditionalServices(dealId, updateAdditionalServicesBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} complexDealId 
         * @param {UpdateComplexDealModeratorBody} updateComplexDealModeratorBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dealControllerUpdateComplexDealModerator(complexDealId: number, updateComplexDealModeratorBody: UpdateComplexDealModeratorBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dealControllerUpdateComplexDealModerator(complexDealId, updateComplexDealModeratorBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} complexDealId 
         * @param {UpdateComplexDealOfferBody} updateComplexDealOfferBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dealControllerUpdateComplexDealOffer(complexDealId: number, updateComplexDealOfferBody: UpdateComplexDealOfferBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dealControllerUpdateComplexDealOffer(complexDealId, updateComplexDealOfferBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} complexDealId 
         * @param {UpdateComplexDealStatusBody} updateComplexDealStatusBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dealControllerUpdateComplexDealStatus(complexDealId: number, updateComplexDealStatusBody: UpdateComplexDealStatusBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dealControllerUpdateComplexDealStatus(complexDealId, updateComplexDealStatusBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} dealId 
         * @param {UpdateDealCarrierBody} updateDealCarrierBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dealControllerUpdateDealCarrier(dealId: number, updateDealCarrierBody: UpdateDealCarrierBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dealControllerUpdateDealCarrier(dealId, updateDealCarrierBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} dealId 
         * @param {UpdateDealCompanyBankAccountBody} updateDealCompanyBankAccountBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dealControllerUpdateDealCompanyBankAccount(dealId: number, updateDealCompanyBankAccountBody: UpdateDealCompanyBankAccountBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dealControllerUpdateDealCompanyBankAccount(dealId, updateDealCompanyBankAccountBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} dealId 
         * @param {UpdateDealLogisticsInfoBody} updateDealLogisticsInfoBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dealControllerUpdateDealLogisticsInfoLoading(dealId: number, updateDealLogisticsInfoBody: UpdateDealLogisticsInfoBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dealControllerUpdateDealLogisticsInfoLoading(dealId, updateDealLogisticsInfoBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} dealId 
         * @param {UpdateDealLogisticsInfoBody} updateDealLogisticsInfoBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dealControllerUpdateDealLogisticsInfoUnloading(dealId: number, updateDealLogisticsInfoBody: UpdateDealLogisticsInfoBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dealControllerUpdateDealLogisticsInfoUnloading(dealId, updateDealLogisticsInfoBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} dealId 
         * @param {UpdateDealStatusBody} updateDealStatusBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dealControllerUpdateDealStatus(dealId: number, updateDealStatusBody: UpdateDealStatusBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dealControllerUpdateDealStatus(dealId, updateDealStatusBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DealApi - factory interface
 * @export
 */
export const DealApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DealApiFp(configuration)
    return {
        /**
         * 
         * @param {number} complexDealId 
         * @param {CreateComplexDealCarrierBody} createComplexDealCarrierBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dealControllerCreateComplexDealCarrier(complexDealId: number, createComplexDealCarrierBody: CreateComplexDealCarrierBody, options?: any): AxiosPromise<void> {
            return localVarFp.dealControllerCreateComplexDealCarrier(complexDealId, createComplexDealCarrierBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} companyId 
         * @param {CreateDealBody} createDealBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dealControllerCreateDeal(companyId: number, createDealBody: CreateDealBody, options?: any): AxiosPromise<CreateDealResponse> {
            return localVarFp.dealControllerCreateDeal(companyId, createDealBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} dealId 
         * @param {CreateDealSpecificationBody} createDealSpecificationBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dealControllerCreateDealSpecification(dealId: number, createDealSpecificationBody: CreateDealSpecificationBody, options?: any): AxiosPromise<void> {
            return localVarFp.dealControllerCreateDealSpecification(dealId, createDealSpecificationBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} companyId 
         * @param {number} [currentPage] Номер текущей страницы
         * @param {number} [pageSize] Количество элементов на странице
         * @param {Array<number>} [ids] Список id сделок
         * @param {Array<string>} [regionIds] Список id регионов
         * @param {Array<string>} [productTypes] Список типов товаров
         * @param {'BUY' | 'SELL'} [type] Тип заявки
         * @param {Array<'AGREEMENT' | 'SIGNING' | 'PAYMENT' | 'SHIPMENT' | 'REJECTED' | 'REALISED'>} [statuses] Статусы сделок
         * @param {number} [minVolume] Минимальный объем
         * @param {number} [maxVolume] Максимальный объем
         * @param {number} [minPrice] Минимальная цена
         * @param {number} [maxPrice] Максимальная цена
         * @param {'id' | 'offerId' | 'status' | 'sellerId' | 'buyerId' | 'specificationId' | 'sellerBankAccountId' | 'pairedDealId' | 'createdAt' | 'updatedAt' | 'createdBy' | 'moderatedBy' | 'buyerBankAccountId' | 'startedAt'} [orderBy] Поле для сортировки
         * @param {OrderDirection} [order] Направление сортировки
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dealControllerGetCompanyDeals(companyId: number, currentPage?: number, pageSize?: number, ids?: Array<number>, regionIds?: Array<string>, productTypes?: Array<string>, type?: 'BUY' | 'SELL', statuses?: Array<'AGREEMENT' | 'SIGNING' | 'PAYMENT' | 'SHIPMENT' | 'REJECTED' | 'REALISED'>, minVolume?: number, maxVolume?: number, minPrice?: number, maxPrice?: number, orderBy?: 'id' | 'offerId' | 'status' | 'sellerId' | 'buyerId' | 'specificationId' | 'sellerBankAccountId' | 'pairedDealId' | 'createdAt' | 'updatedAt' | 'createdBy' | 'moderatedBy' | 'buyerBankAccountId' | 'startedAt', order?: OrderDirection, options?: any): AxiosPromise<GetCompanyDealsResponse> {
            return localVarFp.dealControllerGetCompanyDeals(companyId, currentPage, pageSize, ids, regionIds, productTypes, type, statuses, minVolume, maxVolume, minPrice, maxPrice, orderBy, order, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} complexDealId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dealControllerGetComplexDeal(complexDealId: number, options?: any): AxiosPromise<GetComplexDealResponse> {
            return localVarFp.dealControllerGetComplexDeal(complexDealId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [currentPage] Номер текущей страницы
         * @param {number} [pageSize] Количество элементов на странице
         * @param {Array<'ON_MODERATION' | 'REJECTED_AFTER_MODERATION' | 'AGREEMENT' | 'SIGNING' | 'PAYMENT' | 'SHIPMENT' | 'REJECTED' | 'REALISED'>} [statuses] Статусы сделок
         * @param {Array<number>} [ids] Список id комплексных сделок
         * @param {Array<string>} [regionIds] Список id регионов
         * @param {Array<string>} [productTypes] Список типов товаров
         * @param {'BUY' | 'SELL'} [type] Тип заявки
         * @param {number} [minVolume] Минимальный объем
         * @param {number} [maxVolume] Максимальный объем
         * @param {number} [minPrice] Минимальная цена
         * @param {number} [maxPrice] Максимальная цена
         * @param {'id' | 'offerId' | 'status' | 'sellerId' | 'buyerId' | 'specificationId' | 'sellerBankAccountId' | 'pairedDealId' | 'createdAt' | 'updatedAt' | 'createdBy' | 'moderatedBy' | 'buyerBankAccountId' | 'startedAt'} [orderBy] Поле для сортировки
         * @param {OrderDirection} [order] Направление сортировки
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dealControllerGetComplexDeals(currentPage?: number, pageSize?: number, statuses?: Array<'ON_MODERATION' | 'REJECTED_AFTER_MODERATION' | 'AGREEMENT' | 'SIGNING' | 'PAYMENT' | 'SHIPMENT' | 'REJECTED' | 'REALISED'>, ids?: Array<number>, regionIds?: Array<string>, productTypes?: Array<string>, type?: 'BUY' | 'SELL', minVolume?: number, maxVolume?: number, minPrice?: number, maxPrice?: number, orderBy?: 'id' | 'offerId' | 'status' | 'sellerId' | 'buyerId' | 'specificationId' | 'sellerBankAccountId' | 'pairedDealId' | 'createdAt' | 'updatedAt' | 'createdBy' | 'moderatedBy' | 'buyerBankAccountId' | 'startedAt', order?: OrderDirection, options?: any): AxiosPromise<GetComplexDealsResponse> {
            return localVarFp.dealControllerGetComplexDeals(currentPage, pageSize, statuses, ids, regionIds, productTypes, type, minVolume, maxVolume, minPrice, maxPrice, orderBy, order, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} dealId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dealControllerGetDealLogisticsInfo(dealId: number, options?: any): AxiosPromise<GetDealLogisticsInfoResponse> {
            return localVarFp.dealControllerGetDealLogisticsInfo(dealId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} dealId 
         * @param {UpdateAdditionalServicesBody} updateAdditionalServicesBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dealControllerUpdateAdditionalServices(dealId: number, updateAdditionalServicesBody: UpdateAdditionalServicesBody, options?: any): AxiosPromise<void> {
            return localVarFp.dealControllerUpdateAdditionalServices(dealId, updateAdditionalServicesBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} complexDealId 
         * @param {UpdateComplexDealModeratorBody} updateComplexDealModeratorBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dealControllerUpdateComplexDealModerator(complexDealId: number, updateComplexDealModeratorBody: UpdateComplexDealModeratorBody, options?: any): AxiosPromise<void> {
            return localVarFp.dealControllerUpdateComplexDealModerator(complexDealId, updateComplexDealModeratorBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} complexDealId 
         * @param {UpdateComplexDealOfferBody} updateComplexDealOfferBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dealControllerUpdateComplexDealOffer(complexDealId: number, updateComplexDealOfferBody: UpdateComplexDealOfferBody, options?: any): AxiosPromise<void> {
            return localVarFp.dealControllerUpdateComplexDealOffer(complexDealId, updateComplexDealOfferBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} complexDealId 
         * @param {UpdateComplexDealStatusBody} updateComplexDealStatusBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dealControllerUpdateComplexDealStatus(complexDealId: number, updateComplexDealStatusBody: UpdateComplexDealStatusBody, options?: any): AxiosPromise<void> {
            return localVarFp.dealControllerUpdateComplexDealStatus(complexDealId, updateComplexDealStatusBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} dealId 
         * @param {UpdateDealCarrierBody} updateDealCarrierBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dealControllerUpdateDealCarrier(dealId: number, updateDealCarrierBody: UpdateDealCarrierBody, options?: any): AxiosPromise<void> {
            return localVarFp.dealControllerUpdateDealCarrier(dealId, updateDealCarrierBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} dealId 
         * @param {UpdateDealCompanyBankAccountBody} updateDealCompanyBankAccountBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dealControllerUpdateDealCompanyBankAccount(dealId: number, updateDealCompanyBankAccountBody: UpdateDealCompanyBankAccountBody, options?: any): AxiosPromise<void> {
            return localVarFp.dealControllerUpdateDealCompanyBankAccount(dealId, updateDealCompanyBankAccountBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} dealId 
         * @param {UpdateDealLogisticsInfoBody} updateDealLogisticsInfoBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dealControllerUpdateDealLogisticsInfoLoading(dealId: number, updateDealLogisticsInfoBody: UpdateDealLogisticsInfoBody, options?: any): AxiosPromise<void> {
            return localVarFp.dealControllerUpdateDealLogisticsInfoLoading(dealId, updateDealLogisticsInfoBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} dealId 
         * @param {UpdateDealLogisticsInfoBody} updateDealLogisticsInfoBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dealControllerUpdateDealLogisticsInfoUnloading(dealId: number, updateDealLogisticsInfoBody: UpdateDealLogisticsInfoBody, options?: any): AxiosPromise<void> {
            return localVarFp.dealControllerUpdateDealLogisticsInfoUnloading(dealId, updateDealLogisticsInfoBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} dealId 
         * @param {UpdateDealStatusBody} updateDealStatusBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dealControllerUpdateDealStatus(dealId: number, updateDealStatusBody: UpdateDealStatusBody, options?: any): AxiosPromise<void> {
            return localVarFp.dealControllerUpdateDealStatus(dealId, updateDealStatusBody, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for dealControllerCreateComplexDealCarrier operation in DealApi.
 * @export
 * @interface DealApiDealControllerCreateComplexDealCarrierRequest
 */
export interface DealApiDealControllerCreateComplexDealCarrierRequest {
    /**
     * 
     * @type {number}
     * @memberof DealApiDealControllerCreateComplexDealCarrier
     */
    readonly complexDealId: number

    /**
     * 
     * @type {CreateComplexDealCarrierBody}
     * @memberof DealApiDealControllerCreateComplexDealCarrier
     */
    readonly createComplexDealCarrierBody: CreateComplexDealCarrierBody
}

/**
 * Request parameters for dealControllerCreateDeal operation in DealApi.
 * @export
 * @interface DealApiDealControllerCreateDealRequest
 */
export interface DealApiDealControllerCreateDealRequest {
    /**
     * 
     * @type {number}
     * @memberof DealApiDealControllerCreateDeal
     */
    readonly companyId: number

    /**
     * 
     * @type {CreateDealBody}
     * @memberof DealApiDealControllerCreateDeal
     */
    readonly createDealBody: CreateDealBody
}

/**
 * Request parameters for dealControllerCreateDealSpecification operation in DealApi.
 * @export
 * @interface DealApiDealControllerCreateDealSpecificationRequest
 */
export interface DealApiDealControllerCreateDealSpecificationRequest {
    /**
     * 
     * @type {number}
     * @memberof DealApiDealControllerCreateDealSpecification
     */
    readonly dealId: number

    /**
     * 
     * @type {CreateDealSpecificationBody}
     * @memberof DealApiDealControllerCreateDealSpecification
     */
    readonly createDealSpecificationBody: CreateDealSpecificationBody
}

/**
 * Request parameters for dealControllerGetCompanyDeals operation in DealApi.
 * @export
 * @interface DealApiDealControllerGetCompanyDealsRequest
 */
export interface DealApiDealControllerGetCompanyDealsRequest {
    /**
     * 
     * @type {number}
     * @memberof DealApiDealControllerGetCompanyDeals
     */
    readonly companyId: number

    /**
     * Номер текущей страницы
     * @type {number}
     * @memberof DealApiDealControllerGetCompanyDeals
     */
    readonly currentPage?: number

    /**
     * Количество элементов на странице
     * @type {number}
     * @memberof DealApiDealControllerGetCompanyDeals
     */
    readonly pageSize?: number

    /**
     * Список id сделок
     * @type {Array<number>}
     * @memberof DealApiDealControllerGetCompanyDeals
     */
    readonly ids?: Array<number>

    /**
     * Список id регионов
     * @type {Array<string>}
     * @memberof DealApiDealControllerGetCompanyDeals
     */
    readonly regionIds?: Array<string>

    /**
     * Список типов товаров
     * @type {Array<string>}
     * @memberof DealApiDealControllerGetCompanyDeals
     */
    readonly productTypes?: Array<string>

    /**
     * Тип заявки
     * @type {'BUY' | 'SELL'}
     * @memberof DealApiDealControllerGetCompanyDeals
     */
    readonly type?: 'BUY' | 'SELL'

    /**
     * Статусы сделок
     * @type {Array<'AGREEMENT' | 'SIGNING' | 'PAYMENT' | 'SHIPMENT' | 'REJECTED' | 'REALISED'>}
     * @memberof DealApiDealControllerGetCompanyDeals
     */
    readonly statuses?: Array<'AGREEMENT' | 'SIGNING' | 'PAYMENT' | 'SHIPMENT' | 'REJECTED' | 'REALISED'>

    /**
     * Минимальный объем
     * @type {number}
     * @memberof DealApiDealControllerGetCompanyDeals
     */
    readonly minVolume?: number

    /**
     * Максимальный объем
     * @type {number}
     * @memberof DealApiDealControllerGetCompanyDeals
     */
    readonly maxVolume?: number

    /**
     * Минимальная цена
     * @type {number}
     * @memberof DealApiDealControllerGetCompanyDeals
     */
    readonly minPrice?: number

    /**
     * Максимальная цена
     * @type {number}
     * @memberof DealApiDealControllerGetCompanyDeals
     */
    readonly maxPrice?: number

    /**
     * Поле для сортировки
     * @type {'id' | 'offerId' | 'status' | 'sellerId' | 'buyerId' | 'specificationId' | 'sellerBankAccountId' | 'pairedDealId' | 'createdAt' | 'updatedAt' | 'createdBy' | 'moderatedBy' | 'buyerBankAccountId' | 'startedAt'}
     * @memberof DealApiDealControllerGetCompanyDeals
     */
    readonly orderBy?: 'id' | 'offerId' | 'status' | 'sellerId' | 'buyerId' | 'specificationId' | 'sellerBankAccountId' | 'pairedDealId' | 'createdAt' | 'updatedAt' | 'createdBy' | 'moderatedBy' | 'buyerBankAccountId' | 'startedAt'

    /**
     * Направление сортировки
     * @type {OrderDirection}
     * @memberof DealApiDealControllerGetCompanyDeals
     */
    readonly order?: OrderDirection
}

/**
 * Request parameters for dealControllerGetComplexDeal operation in DealApi.
 * @export
 * @interface DealApiDealControllerGetComplexDealRequest
 */
export interface DealApiDealControllerGetComplexDealRequest {
    /**
     * 
     * @type {number}
     * @memberof DealApiDealControllerGetComplexDeal
     */
    readonly complexDealId: number
}

/**
 * Request parameters for dealControllerGetComplexDeals operation in DealApi.
 * @export
 * @interface DealApiDealControllerGetComplexDealsRequest
 */
export interface DealApiDealControllerGetComplexDealsRequest {
    /**
     * Номер текущей страницы
     * @type {number}
     * @memberof DealApiDealControllerGetComplexDeals
     */
    readonly currentPage?: number

    /**
     * Количество элементов на странице
     * @type {number}
     * @memberof DealApiDealControllerGetComplexDeals
     */
    readonly pageSize?: number

    /**
     * Статусы сделок
     * @type {Array<'ON_MODERATION' | 'REJECTED_AFTER_MODERATION' | 'AGREEMENT' | 'SIGNING' | 'PAYMENT' | 'SHIPMENT' | 'REJECTED' | 'REALISED'>}
     * @memberof DealApiDealControllerGetComplexDeals
     */
    readonly statuses?: Array<'ON_MODERATION' | 'REJECTED_AFTER_MODERATION' | 'AGREEMENT' | 'SIGNING' | 'PAYMENT' | 'SHIPMENT' | 'REJECTED' | 'REALISED'>

    /**
     * Список id комплексных сделок
     * @type {Array<number>}
     * @memberof DealApiDealControllerGetComplexDeals
     */
    readonly ids?: Array<number>

    /**
     * Список id регионов
     * @type {Array<string>}
     * @memberof DealApiDealControllerGetComplexDeals
     */
    readonly regionIds?: Array<string>

    /**
     * Список типов товаров
     * @type {Array<string>}
     * @memberof DealApiDealControllerGetComplexDeals
     */
    readonly productTypes?: Array<string>

    /**
     * Тип заявки
     * @type {'BUY' | 'SELL'}
     * @memberof DealApiDealControllerGetComplexDeals
     */
    readonly type?: 'BUY' | 'SELL'

    /**
     * Минимальный объем
     * @type {number}
     * @memberof DealApiDealControllerGetComplexDeals
     */
    readonly minVolume?: number

    /**
     * Максимальный объем
     * @type {number}
     * @memberof DealApiDealControllerGetComplexDeals
     */
    readonly maxVolume?: number

    /**
     * Минимальная цена
     * @type {number}
     * @memberof DealApiDealControllerGetComplexDeals
     */
    readonly minPrice?: number

    /**
     * Максимальная цена
     * @type {number}
     * @memberof DealApiDealControllerGetComplexDeals
     */
    readonly maxPrice?: number

    /**
     * Поле для сортировки
     * @type {'id' | 'offerId' | 'status' | 'sellerId' | 'buyerId' | 'specificationId' | 'sellerBankAccountId' | 'pairedDealId' | 'createdAt' | 'updatedAt' | 'createdBy' | 'moderatedBy' | 'buyerBankAccountId' | 'startedAt'}
     * @memberof DealApiDealControllerGetComplexDeals
     */
    readonly orderBy?: 'id' | 'offerId' | 'status' | 'sellerId' | 'buyerId' | 'specificationId' | 'sellerBankAccountId' | 'pairedDealId' | 'createdAt' | 'updatedAt' | 'createdBy' | 'moderatedBy' | 'buyerBankAccountId' | 'startedAt'

    /**
     * Направление сортировки
     * @type {OrderDirection}
     * @memberof DealApiDealControllerGetComplexDeals
     */
    readonly order?: OrderDirection
}

/**
 * Request parameters for dealControllerGetDealLogisticsInfo operation in DealApi.
 * @export
 * @interface DealApiDealControllerGetDealLogisticsInfoRequest
 */
export interface DealApiDealControllerGetDealLogisticsInfoRequest {
    /**
     * 
     * @type {number}
     * @memberof DealApiDealControllerGetDealLogisticsInfo
     */
    readonly dealId: number
}

/**
 * Request parameters for dealControllerUpdateAdditionalServices operation in DealApi.
 * @export
 * @interface DealApiDealControllerUpdateAdditionalServicesRequest
 */
export interface DealApiDealControllerUpdateAdditionalServicesRequest {
    /**
     * 
     * @type {number}
     * @memberof DealApiDealControllerUpdateAdditionalServices
     */
    readonly dealId: number

    /**
     * 
     * @type {UpdateAdditionalServicesBody}
     * @memberof DealApiDealControllerUpdateAdditionalServices
     */
    readonly updateAdditionalServicesBody: UpdateAdditionalServicesBody
}

/**
 * Request parameters for dealControllerUpdateComplexDealModerator operation in DealApi.
 * @export
 * @interface DealApiDealControllerUpdateComplexDealModeratorRequest
 */
export interface DealApiDealControllerUpdateComplexDealModeratorRequest {
    /**
     * 
     * @type {number}
     * @memberof DealApiDealControllerUpdateComplexDealModerator
     */
    readonly complexDealId: number

    /**
     * 
     * @type {UpdateComplexDealModeratorBody}
     * @memberof DealApiDealControllerUpdateComplexDealModerator
     */
    readonly updateComplexDealModeratorBody: UpdateComplexDealModeratorBody
}

/**
 * Request parameters for dealControllerUpdateComplexDealOffer operation in DealApi.
 * @export
 * @interface DealApiDealControllerUpdateComplexDealOfferRequest
 */
export interface DealApiDealControllerUpdateComplexDealOfferRequest {
    /**
     * 
     * @type {number}
     * @memberof DealApiDealControllerUpdateComplexDealOffer
     */
    readonly complexDealId: number

    /**
     * 
     * @type {UpdateComplexDealOfferBody}
     * @memberof DealApiDealControllerUpdateComplexDealOffer
     */
    readonly updateComplexDealOfferBody: UpdateComplexDealOfferBody
}

/**
 * Request parameters for dealControllerUpdateComplexDealStatus operation in DealApi.
 * @export
 * @interface DealApiDealControllerUpdateComplexDealStatusRequest
 */
export interface DealApiDealControllerUpdateComplexDealStatusRequest {
    /**
     * 
     * @type {number}
     * @memberof DealApiDealControllerUpdateComplexDealStatus
     */
    readonly complexDealId: number

    /**
     * 
     * @type {UpdateComplexDealStatusBody}
     * @memberof DealApiDealControllerUpdateComplexDealStatus
     */
    readonly updateComplexDealStatusBody: UpdateComplexDealStatusBody
}

/**
 * Request parameters for dealControllerUpdateDealCarrier operation in DealApi.
 * @export
 * @interface DealApiDealControllerUpdateDealCarrierRequest
 */
export interface DealApiDealControllerUpdateDealCarrierRequest {
    /**
     * 
     * @type {number}
     * @memberof DealApiDealControllerUpdateDealCarrier
     */
    readonly dealId: number

    /**
     * 
     * @type {UpdateDealCarrierBody}
     * @memberof DealApiDealControllerUpdateDealCarrier
     */
    readonly updateDealCarrierBody: UpdateDealCarrierBody
}

/**
 * Request parameters for dealControllerUpdateDealCompanyBankAccount operation in DealApi.
 * @export
 * @interface DealApiDealControllerUpdateDealCompanyBankAccountRequest
 */
export interface DealApiDealControllerUpdateDealCompanyBankAccountRequest {
    /**
     * 
     * @type {number}
     * @memberof DealApiDealControllerUpdateDealCompanyBankAccount
     */
    readonly dealId: number

    /**
     * 
     * @type {UpdateDealCompanyBankAccountBody}
     * @memberof DealApiDealControllerUpdateDealCompanyBankAccount
     */
    readonly updateDealCompanyBankAccountBody: UpdateDealCompanyBankAccountBody
}

/**
 * Request parameters for dealControllerUpdateDealLogisticsInfoLoading operation in DealApi.
 * @export
 * @interface DealApiDealControllerUpdateDealLogisticsInfoLoadingRequest
 */
export interface DealApiDealControllerUpdateDealLogisticsInfoLoadingRequest {
    /**
     * 
     * @type {number}
     * @memberof DealApiDealControllerUpdateDealLogisticsInfoLoading
     */
    readonly dealId: number

    /**
     * 
     * @type {UpdateDealLogisticsInfoBody}
     * @memberof DealApiDealControllerUpdateDealLogisticsInfoLoading
     */
    readonly updateDealLogisticsInfoBody: UpdateDealLogisticsInfoBody
}

/**
 * Request parameters for dealControllerUpdateDealLogisticsInfoUnloading operation in DealApi.
 * @export
 * @interface DealApiDealControllerUpdateDealLogisticsInfoUnloadingRequest
 */
export interface DealApiDealControllerUpdateDealLogisticsInfoUnloadingRequest {
    /**
     * 
     * @type {number}
     * @memberof DealApiDealControllerUpdateDealLogisticsInfoUnloading
     */
    readonly dealId: number

    /**
     * 
     * @type {UpdateDealLogisticsInfoBody}
     * @memberof DealApiDealControllerUpdateDealLogisticsInfoUnloading
     */
    readonly updateDealLogisticsInfoBody: UpdateDealLogisticsInfoBody
}

/**
 * Request parameters for dealControllerUpdateDealStatus operation in DealApi.
 * @export
 * @interface DealApiDealControllerUpdateDealStatusRequest
 */
export interface DealApiDealControllerUpdateDealStatusRequest {
    /**
     * 
     * @type {number}
     * @memberof DealApiDealControllerUpdateDealStatus
     */
    readonly dealId: number

    /**
     * 
     * @type {UpdateDealStatusBody}
     * @memberof DealApiDealControllerUpdateDealStatus
     */
    readonly updateDealStatusBody: UpdateDealStatusBody
}

/**
 * DealApi - object-oriented interface
 * @export
 * @class DealApi
 * @extends {BaseAPI}
 */
export class DealApi extends BaseAPI {
    /**
     * 
     * @param {DealApiDealControllerCreateComplexDealCarrierRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DealApi
     */
    public dealControllerCreateComplexDealCarrier(requestParameters: DealApiDealControllerCreateComplexDealCarrierRequest, options?: AxiosRequestConfig) {
        return DealApiFp(this.configuration).dealControllerCreateComplexDealCarrier(requestParameters.complexDealId, requestParameters.createComplexDealCarrierBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DealApiDealControllerCreateDealRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DealApi
     */
    public dealControllerCreateDeal(requestParameters: DealApiDealControllerCreateDealRequest, options?: AxiosRequestConfig) {
        return DealApiFp(this.configuration).dealControllerCreateDeal(requestParameters.companyId, requestParameters.createDealBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DealApiDealControllerCreateDealSpecificationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DealApi
     */
    public dealControllerCreateDealSpecification(requestParameters: DealApiDealControllerCreateDealSpecificationRequest, options?: AxiosRequestConfig) {
        return DealApiFp(this.configuration).dealControllerCreateDealSpecification(requestParameters.dealId, requestParameters.createDealSpecificationBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DealApiDealControllerGetCompanyDealsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DealApi
     */
    public dealControllerGetCompanyDeals(requestParameters: DealApiDealControllerGetCompanyDealsRequest, options?: AxiosRequestConfig) {
        return DealApiFp(this.configuration).dealControllerGetCompanyDeals(requestParameters.companyId, requestParameters.currentPage, requestParameters.pageSize, requestParameters.ids, requestParameters.regionIds, requestParameters.productTypes, requestParameters.type, requestParameters.statuses, requestParameters.minVolume, requestParameters.maxVolume, requestParameters.minPrice, requestParameters.maxPrice, requestParameters.orderBy, requestParameters.order, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DealApiDealControllerGetComplexDealRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DealApi
     */
    public dealControllerGetComplexDeal(requestParameters: DealApiDealControllerGetComplexDealRequest, options?: AxiosRequestConfig) {
        return DealApiFp(this.configuration).dealControllerGetComplexDeal(requestParameters.complexDealId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DealApiDealControllerGetComplexDealsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DealApi
     */
    public dealControllerGetComplexDeals(requestParameters: DealApiDealControllerGetComplexDealsRequest = {}, options?: AxiosRequestConfig) {
        return DealApiFp(this.configuration).dealControllerGetComplexDeals(requestParameters.currentPage, requestParameters.pageSize, requestParameters.statuses, requestParameters.ids, requestParameters.regionIds, requestParameters.productTypes, requestParameters.type, requestParameters.minVolume, requestParameters.maxVolume, requestParameters.minPrice, requestParameters.maxPrice, requestParameters.orderBy, requestParameters.order, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DealApiDealControllerGetDealLogisticsInfoRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DealApi
     */
    public dealControllerGetDealLogisticsInfo(requestParameters: DealApiDealControllerGetDealLogisticsInfoRequest, options?: AxiosRequestConfig) {
        return DealApiFp(this.configuration).dealControllerGetDealLogisticsInfo(requestParameters.dealId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DealApiDealControllerUpdateAdditionalServicesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DealApi
     */
    public dealControllerUpdateAdditionalServices(requestParameters: DealApiDealControllerUpdateAdditionalServicesRequest, options?: AxiosRequestConfig) {
        return DealApiFp(this.configuration).dealControllerUpdateAdditionalServices(requestParameters.dealId, requestParameters.updateAdditionalServicesBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DealApiDealControllerUpdateComplexDealModeratorRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DealApi
     */
    public dealControllerUpdateComplexDealModerator(requestParameters: DealApiDealControllerUpdateComplexDealModeratorRequest, options?: AxiosRequestConfig) {
        return DealApiFp(this.configuration).dealControllerUpdateComplexDealModerator(requestParameters.complexDealId, requestParameters.updateComplexDealModeratorBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DealApiDealControllerUpdateComplexDealOfferRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DealApi
     */
    public dealControllerUpdateComplexDealOffer(requestParameters: DealApiDealControllerUpdateComplexDealOfferRequest, options?: AxiosRequestConfig) {
        return DealApiFp(this.configuration).dealControllerUpdateComplexDealOffer(requestParameters.complexDealId, requestParameters.updateComplexDealOfferBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DealApiDealControllerUpdateComplexDealStatusRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DealApi
     */
    public dealControllerUpdateComplexDealStatus(requestParameters: DealApiDealControllerUpdateComplexDealStatusRequest, options?: AxiosRequestConfig) {
        return DealApiFp(this.configuration).dealControllerUpdateComplexDealStatus(requestParameters.complexDealId, requestParameters.updateComplexDealStatusBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DealApiDealControllerUpdateDealCarrierRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DealApi
     */
    public dealControllerUpdateDealCarrier(requestParameters: DealApiDealControllerUpdateDealCarrierRequest, options?: AxiosRequestConfig) {
        return DealApiFp(this.configuration).dealControllerUpdateDealCarrier(requestParameters.dealId, requestParameters.updateDealCarrierBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DealApiDealControllerUpdateDealCompanyBankAccountRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DealApi
     */
    public dealControllerUpdateDealCompanyBankAccount(requestParameters: DealApiDealControllerUpdateDealCompanyBankAccountRequest, options?: AxiosRequestConfig) {
        return DealApiFp(this.configuration).dealControllerUpdateDealCompanyBankAccount(requestParameters.dealId, requestParameters.updateDealCompanyBankAccountBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DealApiDealControllerUpdateDealLogisticsInfoLoadingRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DealApi
     */
    public dealControllerUpdateDealLogisticsInfoLoading(requestParameters: DealApiDealControllerUpdateDealLogisticsInfoLoadingRequest, options?: AxiosRequestConfig) {
        return DealApiFp(this.configuration).dealControllerUpdateDealLogisticsInfoLoading(requestParameters.dealId, requestParameters.updateDealLogisticsInfoBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DealApiDealControllerUpdateDealLogisticsInfoUnloadingRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DealApi
     */
    public dealControllerUpdateDealLogisticsInfoUnloading(requestParameters: DealApiDealControllerUpdateDealLogisticsInfoUnloadingRequest, options?: AxiosRequestConfig) {
        return DealApiFp(this.configuration).dealControllerUpdateDealLogisticsInfoUnloading(requestParameters.dealId, requestParameters.updateDealLogisticsInfoBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DealApiDealControllerUpdateDealStatusRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DealApi
     */
    public dealControllerUpdateDealStatus(requestParameters: DealApiDealControllerUpdateDealStatusRequest, options?: AxiosRequestConfig) {
        return DealApiFp(this.configuration).dealControllerUpdateDealStatus(requestParameters.dealId, requestParameters.updateDealStatusBody, options).then((request) => request(this.axios, this.basePath));
    }
}
