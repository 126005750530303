import { useMutation } from '@tanstack/react-query'

import { useApi } from '~providers/api'

export const useGetCompanyDocumentTemplate = () => {
  const api = useApi()

  const fetchFn = async (): Promise<void> => {
    try {
      const res = await api.companies.getStaticFile({ responseType: 'blob' })
      return res.data
    } catch (err) {
      const description = 'Ошибка при загрузке шаблона документа'
      console.error(description, err)
      return Promise.reject(err)
    }
  }

  return useMutation(fetchFn)
}
