import React from 'react'

import { Modal, Space } from 'antd'
import { observer } from 'mobx-react-lite'
import { useNavigate } from 'react-router-dom'

import { appRoutes } from '~/router'
import { userUiSettingsStore } from '~/store/userUiSettingsStore'
import { Text } from '~/ui-components'
import { useAuth } from '~providers/auth'

export const DemoUserAuthRequiredModal: React.FC = observer(() => {
  const auth = useAuth()
  const navigate = useNavigate()

  const onCancelHandler = () => {
    userUiSettingsStore.hideDemoUserAuthModal()
  }

  const onRegisterHandler = () => {
    userUiSettingsStore.hideDemoUserAuthModal()
    void auth.logout()
    navigate(appRoutes.auth.url)
  }

  return (
    <Modal
      closable={false}
      destroyOnClose={true}
      width={380}
      open={userUiSettingsStore.isShowDemoUserAuthModal}
      onOk={onRegisterHandler}
      onCancel={onCancelHandler}
      okText="Зарегистрироваться"
      cancelText="Закрыть"
    >
      <Space direction="vertical" size={32}>
        <Text variant="h2">Вы в режиме гостевого доступа</Text>

        <Text variant="normal">
          Чтобы получить полный доступ к функциям платформы, заполните данные о компаннии и зарегистируйтесь
        </Text>
      </Space>
    </Modal>
  )
})
