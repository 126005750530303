import React, { FC, useState } from 'react'

import { Button, Modal } from 'antd'
import { FiDownload } from 'react-icons/fi'

import {
  useGetDealDocuments,
  useGetDealTemplateDocuments,
  useUpdateDealDocumentStatus,
  useUploadDealDocument,
} from '~/hooks/deal-documents'
import { Card, Text } from '~/ui-components'
import { appToast, modalSharedSettings } from '~/utils'
import { DealSideType } from '~/utils/deals/get-deal-side'
import { DealDocumentData, DealDocumentDataTypeEnum, DocumentPartStatus, FileData } from '~api/generated'
import { DealAddDocumentForm } from '~pages/Deals/components'
import { Document, DocumentChecker } from '~shared/components'

import * as S from './DealInformationDocuments.styled'

interface DealInformationDocumentCardProps {
  isHasSpecification: boolean
  dealId: number
  dealSide: DealSideType
  openSpecificationFormButtonDisabled: boolean
  onSpecificationFormOpen: () => void
}

const emptyDoc = (
  <S.EmptyDocument isReadOnly>
    Пользователь сможет подписать
    <br />
    документ после того как вы загрузите
  </S.EmptyDocument>
)

export const DealInformationDocuments: FC<DealInformationDocumentCardProps> = (props) => {
  const [uploadDocumentType, setUploadDocumentType] = useState<DealDocumentDataTypeEnum | null>(null)

  const [documentForCheck, setDocumentForCheck] = useState<{ document: DealDocumentData; instanceId: number } | null>(
    null
  )

  const { data: dealDocuments, refetch: dealDocumentsRefetch } = useGetDealDocuments({
    dealId: props.dealId,
  })

  const { data: documentsTemplates } = useGetDealTemplateDocuments({})
  const uploadDealDocument = useUploadDealDocument()

  const updateDocumentStatusFn = useUpdateDealDocumentStatus()

  const documentsMap = new Map(dealDocuments?.map((doc) => [doc.type, doc]))

  const handleAddDocumentModalOpen = (documentType?: DealDocumentDataTypeEnum) => {
    if (!documentType) return
    setUploadDocumentType(documentType)
  }

  const handleAddDocumentModalClose = () => {
    setUploadDocumentType(null)
  }

  const handleUploadDocument = async (files: File[]) => {
    if (!uploadDocumentType || !props.dealId) return

    try {
      await uploadDealDocument.mutateAsync({
        dealId: props.dealId,
        documentType: uploadDocumentType,
        createDealDocumentBody: {
          documentFiles: files,
        },
      })
      handleAddDocumentModalClose()
      await dealDocumentsRefetch()
      appToast.success({
        description: 'Документ успешно загружен',
      })
    } catch (e) {
      console.error(e)
      appToast.error({
        description: 'Ошибка загрузки документа',
      })
    } finally {
      handleAddDocumentModalClose()
    }
  }

  const changeDocumentFileStatus = async (file: FileData, status: DocumentPartStatus, reason?: string) => {
    try {
      await updateDocumentStatusFn.mutateAsync({
        fileId: file.id,
        updateDealDocumentFileBody: {
          status,
          rejectionNote: reason || null,
        },
      })
    } catch (e) {
      appToast.error({ description: 'Ошибка при изменении статуса документа' })
    }

    appToast.success({ description: 'Статус документа изменен' })

    await dealDocumentsRefetch()
  }

  const specification = documentsMap.get(DealDocumentDataTypeEnum.Specification)
  const supplyContract = documentsMap.get(DealDocumentDataTypeEnum.SupplyContract)
  const agencyContract = documentsMap.get(DealDocumentDataTypeEnum.AgencyContract)
  const agencyOrder = documentsMap.get(DealDocumentDataTypeEnum.AgencyOrder)

  const signedSpecification = documentsMap.get(DealDocumentDataTypeEnum.SignedSpecification)
  const signedSupplyContract = documentsMap.get(DealDocumentDataTypeEnum.SignedSupplyContract)
  const signedAgencyContract = documentsMap.get(DealDocumentDataTypeEnum.SignedAgencyContract)
  const signedAgencyOrder = documentsMap.get(DealDocumentDataTypeEnum.SignedAgencyOrder)

  const getDocument = (doc: DealDocumentData | undefined) =>
    doc?.instances.length ? (
      <Document
        dealId={props.dealId}
        document={doc}
        isShowRemoveForAdmin={getIsRemovedDoc(doc)}
        onRemoveAfter={dealDocumentsRefetch}
        onUploadAfter={dealDocumentsRefetch}
        onShow={() =>
          setDocumentForCheck({
            document: doc,
            instanceId: doc?.instances[0].id,
          })
        }
      />
    ) : (
      <>{emptyDoc}</>
    )

  const getIsRemovedDoc = (doc: DealDocumentData | undefined) => {
    return doc?.instances?.[0].status !== DocumentPartStatus.Confirmed
  }

  return (
    <>
      {props.dealSide === 'buyer' ? (
        <Card>
          <Card.Header>
            <Card.Title>Агентский договор и поручение</Card.Title>
          </Card.Header>

          <S.Content>
            {!!signedAgencyContract?.instances.length || !!signedAgencyOrder?.instances.length ? (
              <Text variant="normal">
                1. Скачайте агентский договор и поручение, загруженные покупателем;
                <br />
                2. Проверьте оба документа на наличие подписей и печатей с обеих сторон.
                <br />
                3. Измените статус на “Подписано”.
              </Text>
            ) : (
              <Text variant="normal">
                1. Заполните агентский договор и поручение;
                <br />
                2. Загрузите заполненные документы с подписью и печатью;
              </Text>
            )}
            <div>
              <S.Subtitle>Агентский договор</S.Subtitle>
              <S.DocumentWrapper>
                {agencyContract?.instances.length ? (
                  <Document
                    dealId={props.dealId}
                    document={agencyContract}
                    isShowRemoveForAdmin={getIsRemovedDoc(agencyContract)}
                    onRemoveAfter={dealDocumentsRefetch}
                    onUploadAfter={dealDocumentsRefetch}
                    onShow={(id) =>
                      setDocumentForCheck({
                        document: agencyContract,
                        instanceId: id,
                      })
                    }
                  />
                ) : (
                  <Button icon={<FiDownload />} onClick={() => handleAddDocumentModalOpen(agencyContract?.type)}>
                    Загрузить агентский договор
                  </Button>
                )}
                {getDocument(signedAgencyContract)}
              </S.DocumentWrapper>
            </div>
            <div>
              <S.Subtitle>Поручение</S.Subtitle>
              <S.DocumentWrapper>
                {agencyOrder?.instances.length ? (
                  <Document
                    dealId={props.dealId}
                    document={agencyOrder}
                    isShowRemoveForAdmin={getIsRemovedDoc(agencyOrder)}
                    onRemoveAfter={dealDocumentsRefetch}
                    onUploadAfter={dealDocumentsRefetch}
                    onShow={(id) =>
                      setDocumentForCheck({
                        document: agencyOrder,
                        instanceId: id,
                      })
                    }
                  />
                ) : (
                  <Button
                    icon={<FiDownload />}
                    disabled={!agencyContract}
                    onClick={() => handleAddDocumentModalOpen(agencyOrder?.type)}
                  >
                    Загрузить поручение
                  </Button>
                )}
                {getDocument(signedAgencyOrder)}
              </S.DocumentWrapper>
            </div>
          </S.Content>
        </Card>
      ) : (
        <Card>
          <Card.Header>
            <Card.Title>Договор поставки и спецификация</Card.Title>
          </Card.Header>
          <S.Content>
            {!!signedSpecification?.instances.length || !!signedSupplyContract?.instances.length ? (
              <Text variant="normal">
                1. Скачайте договор поставки и спецификацию, загруженные продавцом.
                <br />
                2. Проверьте оба документа на наличие подписей и печатей с обеих сторон.
                <br />
                3. Измените статус на “Подписано”.
              </Text>
            ) : (
              <Text variant="normal">
                1. Загрузите договор поставки с подписью и печатью;
                <br />
                2. Заполните форму спецификации и прикрепите к ней карту отбора с подтвержденным качеством лаборатории;
                <br />
                3. Загрузите спецификацию с подписью и печатью.
              </Text>
            )}
            <div>
              <S.Subtitle>Договор поставки</S.Subtitle>
              <S.DocumentWrapper>
                {supplyContract?.instances.length ? (
                  <Document
                    dealId={props.dealId}
                    document={supplyContract}
                    isShowRemoveForAdmin={getIsRemovedDoc(supplyContract)}
                    onRemoveAfter={dealDocumentsRefetch}
                    onUploadAfter={dealDocumentsRefetch}
                    onShow={(id) =>
                      setDocumentForCheck({
                        document: supplyContract,
                        instanceId: id,
                      })
                    }
                  />
                ) : (
                  <Button icon={<FiDownload />} onClick={() => handleAddDocumentModalOpen(supplyContract?.type)}>
                    Загрузить договор поставки
                  </Button>
                )}
                {getDocument(signedSupplyContract)}
              </S.DocumentWrapper>
            </div>
            <div>
              <S.Subtitle>Спецификация</S.Subtitle>
              <S.DocumentWrapper>
                <Button
                  icon={<FiDownload />}
                  disabled={props.openSpecificationFormButtonDisabled || !supplyContract?.instances.length}
                  onClick={props.onSpecificationFormOpen}
                >
                  Заполнить спецификацию
                </Button>
                {specification?.instances.length ? (
                  <Document
                    dealId={props.dealId}
                    document={specification}
                    isShowRemoveForAdmin={getIsRemovedDoc(specification)}
                    onRemoveAfter={dealDocumentsRefetch}
                    onUploadAfter={dealDocumentsRefetch}
                    onShow={(id) =>
                      setDocumentForCheck({
                        document: specification,
                        instanceId: id,
                      })
                    }
                  />
                ) : (
                  <Button
                    icon={<FiDownload />}
                    disabled={!supplyContract || !props.isHasSpecification}
                    onClick={() => handleAddDocumentModalOpen(specification?.type)}
                  >
                    Загрузить спецификацию
                  </Button>
                )}
                {getDocument(signedSpecification)}
              </S.DocumentWrapper>
            </div>
          </S.Content>
        </Card>
      )}

      <DocumentChecker
        isCheckerVisible={!!documentForCheck}
        docTemplateList={documentsTemplates?.map((el) => el.type) ?? []}
        documentsForCheck={dealDocuments ?? []}
        startCheckFromDocument={documentForCheck?.document.type ?? null}
        startCheckFromDocumentInstanceId={documentForCheck?.instanceId}
        onDocumentFileStatusChange={changeDocumentFileStatus}
        onFinishCheck={() => setDocumentForCheck(null)}
      />

      <Modal
        title="Добавить файл"
        {...modalSharedSettings}
        open={!!uploadDocumentType}
        onCancel={handleAddDocumentModalClose}
        width={400}
        footer={[
          <Button key="cancel" onClick={handleAddDocumentModalClose} htmlType="button">
            Отмена
          </Button>,
          <Button
            key="submit"
            form="deal-add-document-form"
            type="primary"
            htmlType="submit"
            loading={uploadDealDocument.isLoading}
          >
            Готово
          </Button>,
        ]}
      >
        <DealAddDocumentForm
          documentsTypes={dealDocuments?.map((doc) => doc.type)}
          defaultDocumentsType={uploadDocumentType ?? undefined}
          onSubmit={(value) => handleUploadDocument(value.documentFiles)}
        />
      </Modal>
    </>
  )
}
