import { useMutation } from '@tanstack/react-query'

import { CreateOfferBody } from '~/api/generated'
import { useUploadFileAndGetId } from '~hooks/_utils'
import { useCreateOffer } from '~hooks/offer'
import { useApi } from '~providers/api'

interface CreateAuctionRequest {
  createOfferBody: CreateOfferBody
  isAddAddress: boolean
  labAnalysis: File | undefined
  startedAt: Date | undefined
  companyId: number
}

export const useCreateAuction = () => {
  const api = useApi()
  const createOffer = useCreateOffer()
  const uploadLabAnalysisAndGetIdFn = useUploadFileAndGetId()

  const createAuctionFn = async ({
    createOfferBody,
    isAddAddress,
    labAnalysis,
    companyId,
    startedAt,
  }: CreateAuctionRequest) => {
    const offer = await createOffer.mutateAsync({
      createOfferBody,
      isAddAddress,
      labAnalysis,
      companyId,
    })

    let auctionId = null
    let laboratoryAnalysisFileKey: string | null = null

    if (createOfferBody.type === 'SELL' && labAnalysis) {
      laboratoryAnalysisFileKey = await uploadLabAnalysisAndGetIdFn.mutateAsync(labAnalysis)
    }

    try {
      auctionId = (
        await api.auction.auctionControllerCreateAuction({
          companyId,
          createAuctionBody: {
            offerId: offer.id,
            startedAt: (startedAt ?? new Date()).toISOString(),
            laboratoryAnalysisFileKey,
          },
        })
      ).data.auctionId
    } catch (err) {
      return Promise.reject(err)
    }

    return auctionId
  }

  return useMutation(createAuctionFn)
}
