import { graphql } from '~api/gql-generated'

export const updateMeMutation = graphql(/* GraphQL */ `
  mutation UpdateMe($input: MeUpdateProfileInput!) {
    me {
      updateProfile(input: $input) {
        email
        name
        position
      }
    }
  }
`)

export const updateMeAvatarMutation = graphql(/* GraphQL */ `
  mutation UpdateAvatar($input: UpdateAvatarInput!) {
    me {
      updateAvatar(input: $input) {
        avatarUrl
      }
    }
  }
`)

export const updateMeStateMutation = graphql(/* GraphQL */ `
  mutation UpdateState($input: UpdateStateInput!) {
    me {
      updateState(input: $input) {
        state {
          multiCompanyProfileGuideShown
        }
      }
    }
  }
`)

export const requestChangePhoneNumberMutation = graphql(/* GraphQL */ `
  mutation RequestChangePhoneNumber {
    me {
      requestChangePhoneNumber {
        id
        phoneNumber
        isConfirmed
        numberOfAttempts
      }
    }
  }
`)

export const setNewPhoneNumberMutation = graphql(/* GraphQL */ `
  mutation SetNewPhoneNumber($input: SetNewPhoneNumberInput!) {
    me {
      setNewPhoneNumber(input: $input) {
        id
        phoneNumber
        isConfirmed
        numberOfAttempts
      }
    }
  }
`)

export const confirmNewPhoneNumberMutation = graphql(/* GraphQL */ `
  mutation ConfirmNewPhoneNumber($input: ConfirmNewPhoneNumberInput!) {
    me {
      confirmNewPhoneNumber(input: $input) {
        id
        phoneNumber
        isConfirmed
        numberOfAttempts
      }
    }
  }
`)
