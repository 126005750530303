import React, { useState } from 'react'

import { Button, Empty, Flex, Modal, Popconfirm } from 'antd'
import { FiPaperclip, FiPlus, FiTrash2 } from 'react-icons/fi'

import { Text } from '~/ui-components'
import { modalSharedSettings } from '~/utils'
import { getLogisticOrderDocumentName } from '~/utils/logistics/get-logistic-order-document-name'
import { FreightOrderDocumentType } from '~api/gql-generated/graphql'
import { LoadAddDocumentForm } from '~pages/LogisticsPage/modules/LoadCard/components/LoadDocuments/LoadAddDocumentForm'

import { AddDocumentFormValues, DocumentsProps } from './Documents.types'

import * as S from './Documents.styled'

const docList: FreightOrderDocumentType[] = [FreightOrderDocumentType.CarriageContractTemplate]

export const Documents: React.FC<DocumentsProps> = ({ documents, setDocuments }) => {
  const [isVisibleAddDocumentModal, setVisibleAddDocumentModal] = useState(false)
  const [addDocumentType, setAddDocumentType] = useState<FreightOrderDocumentType | null>(null)
  const [addDocumentId, setAddDocumentId] = useState<number | null>(null)

  const handleAddDocumentClick = () => {
    setVisibleAddDocumentModal(true)
    setAddDocumentType(null)
    setAddDocumentId(null)
  }

  const handleAddFile = (type: FreightOrderDocumentType, documentId: number) => {
    setAddDocumentType(type)
    setAddDocumentId(documentId)
  }

  const handleModalCancelClick = () => {
    setVisibleAddDocumentModal(false)
    setAddDocumentType(null)
    setAddDocumentId(null)
  }

  const handleUploadDocument = async ({ documentType, documentFiles }: AddDocumentFormValues) => {
    setDocuments([...documents, { files: documentFiles, type: documentType, index: documents.length }])
    setVisibleAddDocumentModal(false)
  }

  const handleAddFileToDocument = async ({ documentFiles }: AddDocumentFormValues) => {
    if (addDocumentId === null) {
      return
    }
    setDocuments(
      documents.map((item, index) =>
        addDocumentId === index
          ? {
              ...item,
              files: [...item.files, ...documentFiles],
            }
          : item
      )
    )
    setVisibleAddDocumentModal(false)
    setAddDocumentId(null)
  }

  const handleRemoveDocument = async (documentId: number) => {
    setDocuments(documents.filter((document) => documentId !== document.index))
  }

  return (
    <Flex vertical gap={8}>
      <Text variant="h1" style={{ paddingBottom: '24px' }}>
        Образцы документов
      </Text>

      <Flex vertical gap={24} style={{ paddingBottom: '16px' }}>
        <S.FlexWrapper vertical gap={24}>
          {documents?.length ? (
            documents.map((document, index) => (
              <Flex key={index} vertical gap={16}>
                <Flex justify="space-between">
                  <Flex vertical gap={8}>
                    <Text variant="normal-bold">{`${index + 1}. ${getLogisticOrderDocumentName(document.type)}`}</Text>
                    <Flex vertical gap={8}>
                      {document.files.map((file, idx) => (
                        <Flex key={idx} gap={8} align="center">
                          <FiPaperclip color={'var(--gray-4-color)'} size={14} />
                          <Text variant="normal" style={{ color: 'var(--primary-main-color)' }}>
                            {file.name}
                          </Text>
                        </Flex>
                      ))}
                    </Flex>
                  </Flex>
                  <Flex>
                    <Popconfirm
                      placement="right"
                      title="Удалить документ?"
                      onConfirm={() => handleRemoveDocument(document.index)}
                      okText="Да"
                      cancelText="Нет"
                    >
                      <Button type="text" icon={<FiTrash2 size={24} />} />
                    </Popconfirm>
                    <Button
                      type="text"
                      icon={<FiPlus size={24} />}
                      onClick={() => handleAddFile(document.type, document.index)}
                    />
                  </Flex>
                </Flex>
              </Flex>
            ))
          ) : (
            <Empty description="Документы не загружены" />
          )}
        </S.FlexWrapper>
        <Button type="dashed" icon={<FiPlus size={16} />} onClick={handleAddDocumentClick}>
          Добавить документ
        </Button>
      </Flex>

      <Modal
        title="Выбор документа"
        open={isVisibleAddDocumentModal || addDocumentId !== null}
        {...modalSharedSettings}
        width={400}
        onCancel={handleModalCancelClick}
        footer={[
          <Button key="cancel" onClick={handleModalCancelClick} htmlType="button">
            Отмена
          </Button>,
          <Button key="submit" form="load-add-document-form" type="primary" htmlType="submit">
            Готово
          </Button>,
        ]}
      >
        {addDocumentType ? (
          <LoadAddDocumentForm defaultDocumentsType={addDocumentType} onSubmit={handleAddFileToDocument} />
        ) : (
          <LoadAddDocumentForm
            onSubmit={handleUploadDocument}
            documentsTypes={isVisibleAddDocumentModal ? docList : undefined}
          />
        )}
      </Modal>
    </Flex>
  )
}
