import React from 'react'
import {
  OfferOfConditionsErrors,
  OfferOfConditionsFormProps,
  OfferOfConditionsFormValues,
} from './OfferOfConditionsForm.types'
import { Button, Flex, Input, InputNumber, Radio, Space } from 'antd'
import { FormField, Text } from '~/ui-components'
import { getFreightOrderChangeRequestBlockTypeName } from '~/utils/logistics/get-freight-order-change-request-block-type-name'
import {
  FreightDealEntity,
  FreightOrderChangeRequestBlockType,
  FreightPackingType,
  FreightPaymentTerm,
} from '~api/gql-generated/graphql'
import { Control, Controller, useForm, UseFormResetField, useWatch } from 'react-hook-form'
import { inputSharedSettings } from '~/utils/inputSharedSettings'
import { yupResolver } from '@hookform/resolvers/yup'
import { FreightPackingTypeSelectControl } from '~shared/controls/FreightPackingTypeSelectControl/FreightPackingTypeSelectControl'
import { OfferOfConditionsFormSchema } from './OfferOfConditionsForm.validation'
import { FreightPaymentTermSelectControl } from '~shared/controls/FreightPaymentTermSelectControl'
import { getFreightPackingType } from '~/utils/logistics/get-freight-packing-type'
import { getFreightPaymentTerm } from '~/utils/logistics/get-freight-payment-term'
import { getNoun } from '~/utils/getNoun'

export const CurrentOfferOfConditionsValues = ({
  type,
  freightDeal,
}: {
  type: FreightOrderChangeRequestBlockType
  freightDeal: FreightDealEntity
}) => {
  switch (type) {
    case FreightOrderChangeRequestBlockType.Freight:
      return (
        <Flex gap={4}>
          <Text variant="form-read-value">{freightDeal.dealOrder.weightKg / 1000} т;</Text>
          {freightDeal.dealOrder.route.points[0].freight && (
            <>
              <Text variant="form-read-value">
                {getFreightPackingType(freightDeal.dealOrder.route.points[0].freight.packingType)},
              </Text>
              {freightDeal.dealOrder.route.points[0].freight.packingType === FreightPackingType.BigBag && (
                <Text variant="form-read-value">{freightDeal.dealOrder.route.points[0].freight.bigBagsCount} шт</Text>
              )}
              {freightDeal.dealOrder.route.points[0].freight.packingType === FreightPackingType.Custom && (
                <Text variant="form-read-value">{freightDeal.dealOrder.route.points[0].freight.packingTypeCustom}</Text>
              )}
            </>
          )}
        </Flex>
      )
    case FreightOrderChangeRequestBlockType.Price:
      return (
        <Flex gap={4}>
          <Text variant="form-read-value">{freightDeal.dealOrder.pricePerTonWithoutNds} ₽/т;</Text>
          <Text variant="form-read-value">{getFreightPaymentTerm(freightDeal.dealOrder.paymentTerm)},</Text>
          {freightDeal.dealOrder.paymentTerm === FreightPaymentTerm.Advance && freightDeal.dealOrder.advancePercent && (
            <Text variant="form-read-value">{freightDeal.dealOrder.advancePercent} %</Text>
          )}
          {freightDeal.dealOrder.paymentTerm === FreightPaymentTerm.Deferred &&
            freightDeal.dealOrder.deferredDaysCount && (
              <Text variant="form-read-value">
                {freightDeal.dealOrder.deferredDaysCount}{' '}
                {getNoun(freightDeal.dealOrder.deferredDaysCount, 'день', 'дня', 'дней')}
              </Text>
            )}
        </Flex>
      )
    case FreightOrderChangeRequestBlockType.TransportRegistry:
      return (
        <Flex gap={4}>
          <Text variant="form-read-value">Без возможности редактирования</Text>
        </Flex>
      )
    case FreightOrderChangeRequestBlockType.Route:
      return (
        <Flex gap={4}>
          <Text variant="form-read-value">{freightDeal.dealOrder.route.distance} км</Text>
        </Flex>
      )
    default:
      return null
  }
}

const NewOfferOfConditionsFormValues = ({
  type,
  control,
  errors,
  resetField,
}: {
  type: FreightOrderChangeRequestBlockType
  control: Control<OfferOfConditionsFormValues>
  errors: OfferOfConditionsErrors
  resetField: UseFormResetField<OfferOfConditionsFormValues>
}) => {
  const [packingType, paymentTerm] = useWatch({ control, name: ['packingType', 'paymentTerm'] })

  switch (type) {
    case FreightOrderChangeRequestBlockType.Freight:
      return (
        <Flex vertical>
          <FormField label="Вес" htmlFor="weightKg" validateMessage={errors.weightKg?.message} isRequired>
            <Controller
              name="weightKg"
              control={control}
              render={({ field }) => (
                <InputNumber
                  {...field}
                  {...inputSharedSettings}
                  addonAfter="т"
                  style={{ width: '100%' }}
                  id="weightKg"
                  placeholder="Введите значение"
                  status={errors.weightKg && 'error'}
                  step={1}
                />
              )}
            />
          </FormField>

          <Flex gap={24}>
            <Flex flex="1 1 50%">
              <FormField
                label="Вид упаковки"
                htmlFor="packingType"
                validateMessage={errors.packingType?.message}
                isRequired
              >
                <Controller
                  name="packingType"
                  control={control}
                  render={({ field }) => (
                    <FreightPackingTypeSelectControl
                      {...field}
                      onChange={(value) => {
                        resetField('bigBagsCount')
                        resetField('packingTypeCustom')
                        field.onChange(value)
                      }}
                      id="packingType"
                      hasError={!!errors.packingType}
                    />
                  )}
                />
              </FormField>
            </Flex>

            <Flex flex="1 1 50%">
              {packingType === FreightPackingType.BigBag && (
                <FormField
                  label="Кол-во"
                  htmlFor="bigBagsCount"
                  validateMessage={errors.bigBagsCount?.message}
                  isRequired
                >
                  <Controller
                    name="bigBagsCount"
                    control={control}
                    render={({ field }) => (
                      <InputNumber
                        {...field}
                        {...inputSharedSettings}
                        style={{ width: '100%' }}
                        id="bigBagsCount"
                        placeholder="Введите значение"
                        status={errors?.bigBagsCount && 'error'}
                        step={1}
                        addonAfter="шт"
                      />
                    )}
                  />
                </FormField>
              )}

              {packingType === FreightPackingType.Custom && (
                <FormField
                  label="Наименование"
                  htmlFor="packingTypeCustom"
                  validateMessage={errors.packingTypeCustom?.message}
                  isRequired
                >
                  <Controller
                    name="packingTypeCustom"
                    control={control}
                    render={({ field }) => (
                      <Input
                        {...field}
                        id="packingTypeCustom"
                        status={errors?.packingTypeCustom && 'error'}
                        placeholder="Например, мешки"
                      />
                    )}
                  />
                </FormField>
              )}
            </Flex>
          </Flex>
        </Flex>
      )
    case FreightOrderChangeRequestBlockType.Price:
      return (
        <Flex vertical>
          <FormField
            label="Ставка без НДС"
            htmlFor="pricePerTonWithoutNds"
            validateMessage={errors.pricePerTonWithoutNds?.message}
            isRequired
          >
            <Controller
              name="pricePerTonWithoutNds"
              control={control}
              render={({ field }) => (
                <InputNumber
                  {...field}
                  {...inputSharedSettings}
                  addonAfter="₽/т"
                  style={{ width: '100%' }}
                  id="pricePerTonWithoutNds"
                  placeholder="Введите значение"
                  status={errors.pricePerTonWithoutNds && 'error'}
                  step={1}
                />
              )}
            />
          </FormField>

          <Flex gap={24}>
            <Flex flex="1 1 50%">
              <FormField
                label="Условия оплаты"
                htmlFor="paymentTerm"
                validateMessage={errors.paymentTerm?.message}
                isRequired
              >
                <Controller
                  name="paymentTerm"
                  control={control}
                  defaultValue={FreightPaymentTerm.Deferred}
                  render={({ field }) => (
                    <FreightPaymentTermSelectControl
                      {...field}
                      onChange={(value) => {
                        resetField('deferredDaysCount')
                        resetField('advancePercent')
                        field.onChange(value)
                      }}
                      id="paymentTerm"
                      hasError={!!errors.paymentTerm}
                    />
                  )}
                />
              </FormField>
            </Flex>

            <Flex flex="1 1 50%">
              {paymentTerm === FreightPaymentTerm.Deferred && (
                <FormField
                  label="Кол-во дней отсрочки"
                  htmlFor="deferredDaysCount"
                  validateMessage={errors.deferredDaysCount?.message}
                  isRequired
                >
                  <Controller
                    name="deferredDaysCount"
                    control={control}
                    render={({ field }) => (
                      <InputNumber
                        {...field}
                        {...inputSharedSettings}
                        style={{ width: '100%' }}
                        id="deferredDaysCount"
                        placeholder="Введите значение"
                        status={errors.deferredDaysCount && 'error'}
                        step={1}
                        addonAfter="дней"
                      />
                    )}
                  />
                </FormField>
              )}

              {paymentTerm === FreightPaymentTerm.Advance && (
                <>
                  <FormField
                    label="Процент предоплаты"
                    htmlFor="advancePercent"
                    validateMessage={errors.advancePercent?.message}
                    isRequired
                  >
                    <Controller
                      name="advancePercent"
                      control={control}
                      render={({ field }) => (
                        <InputNumber
                          {...field}
                          {...inputSharedSettings}
                          addonAfter="%"
                          style={{ width: '100%' }}
                          id="advancePercent"
                          placeholder="Введите значение"
                          status={errors.advancePercent && 'error'}
                          step={1}
                        />
                      )}
                    />
                  </FormField>
                </>
              )}
            </Flex>
          </Flex>
        </Flex>
      )
    case FreightOrderChangeRequestBlockType.TransportRegistry:
      return (
        <Flex vertical>
          <FormField
            label="Редактирование реестра"
            htmlFor="canUpdateTransportRegistryInPast"
            validateMessage={errors.canUpdateTransportRegistryInPast?.message}
          >
            <Controller
              name="canUpdateTransportRegistryInPast"
              control={control}
              render={({ field }) => (
                <Radio.Group {...field}>
                  <Space size="small" direction="vertical">
                    <Radio value={false}>Без возможности редактирования реестра по требованию</Radio>
                    <Radio value={true}>С возможностью редактирования реестра по требованию</Radio>
                  </Space>
                </Radio.Group>
              )}
            />
          </FormField>
        </Flex>
      )
    case FreightOrderChangeRequestBlockType.Route:
      return (
        <Flex vertical>
          <Text variant="form-read-label" style={{ marginBottom: '24px' }}>
            Наш счетчик автоматически определяет длину маршрута. Чтобы предложить изменение, необходимо объяснить
            контрагенту в комментарии, почему вы изменили значение. Например, «Счетчик дал неправильное значение» или
            «Предлагаю альтернативный маршрут, чтобы избежать платной дороги».
          </Text>

          <FormField label="Длина маршрута" htmlFor="distance" validateMessage={errors.distance?.message} isRequired>
            <Controller
              name="distance"
              control={control}
              render={({ field }) => (
                <InputNumber
                  {...field}
                  {...inputSharedSettings}
                  addonAfter="км"
                  style={{ width: '100%' }}
                  id="distance"
                  placeholder="Введите"
                  status={errors.distance && 'error'}
                  step={1}
                />
              )}
            />
          </FormField>

          <FormField
            label="Комментарий"
            htmlFor="additionalDetails"
            validateMessage={errors.additionalDetails?.message}
            isRequired
          >
            <Controller
              name="additionalDetails"
              control={control}
              render={({ field }) => (
                <Input.TextArea
                  {...field}
                  id="additionalDetails"
                  placeholder="Введите"
                  status={errors.additionalDetails && 'error'}
                  autoSize={{ minRows: 3, maxRows: 6 }}
                />
              )}
            />
          </FormField>
        </Flex>
      )
    default:
      return null
  }
}

const CurrentOfferOfConditions = ({
  type,
  freightDeal,
  control,
  errors,
  resetField,
}: {
  type: FreightOrderChangeRequestBlockType
  freightDeal: FreightDealEntity
  control: Control<OfferOfConditionsFormValues>
  errors: OfferOfConditionsErrors
  resetField: UseFormResetField<OfferOfConditionsFormValues>
}) => {
  return (
    <Flex vertical gap={24}>
      <Flex vertical gap={24}>
        <Text variant="h4">{getFreightOrderChangeRequestBlockTypeName(type)}</Text>
        <Flex gap={8}>
          <Text variant="form-read-label">Текущее</Text>
          <CurrentOfferOfConditionsValues type={type} freightDeal={freightDeal} />
        </Flex>
      </Flex>

      <Flex vertical gap={24}>
        <NewOfferOfConditionsFormValues type={type} control={control} errors={errors} resetField={resetField} />
      </Flex>
    </Flex>
  )
}

export const OfferOfConditionsForm: React.FC<OfferOfConditionsFormProps> = ({
  onBack,
  onSubmit,
  freightDeal,
  type,
}) => {
  const {
    control,
    handleSubmit,
    resetField,
    formState: { errors, dirtyFields },
  } = useForm<OfferOfConditionsFormValues>({
    resolver: yupResolver(OfferOfConditionsFormSchema),
    defaultValues: {
      weightKg: freightDeal.dealOrder.weightKg / 1000,
      packingType: freightDeal.dealOrder.route.points[0].freight?.packingType,
      bigBagsCount: freightDeal.dealOrder.route.points[0].freight?.bigBagsCount ?? undefined,
      packingTypeCustom: freightDeal.dealOrder.route.points[0].freight?.packingTypeCustom ?? undefined,
      pricePerTonWithoutNds: freightDeal.dealOrder.pricePerTonWithoutNds,
      paymentTerm: freightDeal.dealOrder.paymentTerm,
      deferredDaysCount: freightDeal.dealOrder.deferredDaysCount ?? undefined,
      advancePercent: freightDeal.dealOrder.advancePercent ?? undefined,
      canUpdateTransportRegistryInPast: false,
      distance: freightDeal.dealOrder.route.distance,
      additionalDetails: '',
    },
    context: { type },
  })

  const onSubmitHandler = (data: OfferOfConditionsFormValues) => {
    onSubmit(data, dirtyFields)
  }

  const isDisabled = () => {
    return (
      !Object.keys(dirtyFields).length || (type === FreightOrderChangeRequestBlockType.Route && !dirtyFields.distance)
    )
  }

  return (
    <Flex vertical gap={24}>
      <CurrentOfferOfConditions
        type={type}
        freightDeal={freightDeal}
        control={control}
        errors={errors}
        resetField={resetField}
      />

      <Flex gap={16} justify="flex-end">
        <Button htmlType="button" onClick={onBack}>
          Отмена
        </Button>

        <Button htmlType="button" type="primary" onClick={handleSubmit(onSubmitHandler)} disabled={isDisabled()}>
          Предложить условия
        </Button>
      </Flex>
    </Flex>
  )
}
