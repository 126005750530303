import React from 'react'

import { Space } from 'antd'
import { RiRadioButtonLine } from 'react-icons/ri'

import { Text } from '~/ui-components'

import * as S from './AccountMenuChatSupport.styled'

export const AccountMenuChatSupport: React.FC = () => {
  const onOpenChatHandler = () => {
    // @ts-ignore смотри подключаемый скрипт в html. jivo chat
    jivo_api.open()
  }

  return (
    <S.ChatMobileMenuItemWrapper onClick={onOpenChatHandler}>
      <Space>
        <RiRadioButtonLine />
        <Text variant="normal-bold">Поддержка онлайн</Text>
      </Space>
      <Text variant="description">Возникли вопросы? Напишите нам</Text>
    </S.ChatMobileMenuItemWrapper>
  )
}
