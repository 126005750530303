import { Button } from 'antd'
import styled from 'styled-components'

export const Input = styled.input({
  display: 'none',
})

export const File = styled.div({
  display: 'flex',
  gap: 2,
  alignItems: 'center',
  width: '100%',
})

export const FIleButton = styled(Button)`
  opacity: 1 !important;
  max-width: calc(100% - 16px);
  display: flex;
  align-items: center;

  svg {
    flex-shrink: 0;
  }

  span {
    overflow: hidden;
    text-overflow: ellipsis;
  }
`

export const DeleteButton = styled.button({
  display: 'block',
  margin: 0,
  padding: 0,
  background: 'transparent',
  border: 'none',
  cursor: 'pointer',

  svg: {
    display: 'block',
  },
})
