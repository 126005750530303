/* tslint:disable */
/* eslint-disable */
/**
 * API Зерно-Агрегатора
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { UploadFileResponse } from '../models';
// @ts-ignore
import { UploadFilesResponse } from '../models';
/**
 * FileApi - axios parameter creator
 * @export
 */
export const FileApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {any} file Файл
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileControllerUploadFile: async (file: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'file' is not null or undefined
            assertParamExists('fileControllerUploadFile', 'file', file)
            const localVarPath = `/file`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<any>} files Файлы
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileControllerUploadFiles: async (files: Array<any>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'files' is not null or undefined
            assertParamExists('fileControllerUploadFiles', 'files', files)
            const localVarPath = `/files`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            if (files) {
                files.forEach((element) => {
                    localVarFormParams.append('files', element as any);
                })
            }

    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FileApi - functional programming interface
 * @export
 */
export const FileApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FileApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {any} file Файл
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fileControllerUploadFile(file: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UploadFileResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fileControllerUploadFile(file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Array<any>} files Файлы
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fileControllerUploadFiles(files: Array<any>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UploadFilesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fileControllerUploadFiles(files, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FileApi - factory interface
 * @export
 */
export const FileApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FileApiFp(configuration)
    return {
        /**
         * 
         * @param {any} file Файл
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileControllerUploadFile(file: any, options?: any): AxiosPromise<UploadFileResponse> {
            return localVarFp.fileControllerUploadFile(file, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<any>} files Файлы
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileControllerUploadFiles(files: Array<any>, options?: any): AxiosPromise<UploadFilesResponse> {
            return localVarFp.fileControllerUploadFiles(files, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for fileControllerUploadFile operation in FileApi.
 * @export
 * @interface FileApiFileControllerUploadFileRequest
 */
export interface FileApiFileControllerUploadFileRequest {
    /**
     * Файл
     * @type {any}
     * @memberof FileApiFileControllerUploadFile
     */
    readonly file: any
}

/**
 * Request parameters for fileControllerUploadFiles operation in FileApi.
 * @export
 * @interface FileApiFileControllerUploadFilesRequest
 */
export interface FileApiFileControllerUploadFilesRequest {
    /**
     * Файлы
     * @type {Array<any>}
     * @memberof FileApiFileControllerUploadFiles
     */
    readonly files: Array<any>
}

/**
 * FileApi - object-oriented interface
 * @export
 * @class FileApi
 * @extends {BaseAPI}
 */
export class FileApi extends BaseAPI {
    /**
     * 
     * @param {FileApiFileControllerUploadFileRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileApi
     */
    public fileControllerUploadFile(requestParameters: FileApiFileControllerUploadFileRequest, options?: AxiosRequestConfig) {
        return FileApiFp(this.configuration).fileControllerUploadFile(requestParameters.file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FileApiFileControllerUploadFilesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileApi
     */
    public fileControllerUploadFiles(requestParameters: FileApiFileControllerUploadFilesRequest, options?: AxiosRequestConfig) {
        return FileApiFp(this.configuration).fileControllerUploadFiles(requestParameters.files, options).then((request) => request(this.axios, this.basePath));
    }
}
