import { codeUnreachable } from '~/utils/code-unreachable'
import { TransportFormatType } from '~api/gql-generated/graphql'

export function getTransportFormatType(transportFormatType: TransportFormatType) {
  switch (transportFormatType) {
    case TransportFormatType.Single:
      return 'Одиночное авто'
    case TransportFormatType.RoadTrain:
      return 'Автопоезд'
    default:
      return codeUnreachable(transportFormatType)
  }
}
