import React, { useState } from 'react'

import { Button, Modal } from 'antd'
import { Link } from 'react-router-dom'

import { AppConfig } from '~/appConfig'
import { Card, Text } from '~/ui-components'
import { appToast, modalSharedSettings } from '~/utils'
import { DealSideType } from '~/utils/deals/get-deal-side'
import { DealDocumentData, DealDocumentDataTypeEnum } from '~api/generated'
import {
  useGetDealDocuments,
  useGetDealDocumentsZip,
  useGetDealTemplateDocuments,
  useUploadDealDocument,
} from '~hooks/deal-documents'
import { DealAddDocumentForm } from '~pages/Deals/components'
import { Document, PhoneNumber } from '~shared/components'
import { DocumentViewerModal } from '~shared/components/DocumentViewer'

import * as S from './DealInformationDocument.styled'

interface DealInformationDocumentCardProps {
  dealId: number
  dealSide: DealSideType
}

export const DealInformationDocument: React.FC<DealInformationDocumentCardProps> = ({ dealSide, dealId }) => {
  const { data: dealDocuments, refetch: dealDocumentsRefetch } = useGetDealDocuments({
    dealId,
  })
  const [uploadDocumentType, setUploadDocumentType] = useState<DealDocumentDataTypeEnum | null>(null)
  const [showedDocument, setShowedDocument] = useState<DealDocumentData | null>(null)

  const { data: templateDocuments } = useGetDealTemplateDocuments({
    types: ['supply-contract', 'agency-contract'],
  })

  const uploadDealDocument = useUploadDealDocument()

  const getDealDocumentsZip = useGetDealDocumentsZip()

  const documentsMap = new Map(dealDocuments?.map((doc) => [doc.type, doc]))
  const templateDocumentsMap = new Map(templateDocuments?.map((doc) => [doc.type, doc]))

  const specification = documentsMap.get('specification')
  const supplyContract = documentsMap.get('supply-contract')
  const agencyContract = documentsMap.get('agency-contract')
  const agencyOrder = documentsMap.get('agency-order')

  const signedSpecification = documentsMap.get('signed-specification')
  const signedSupplyContract = documentsMap.get('signed-supply-contract')
  const signedAgencyContract = documentsMap.get('signed-agency-contract')
  const signedAgencyOrder = documentsMap.get('signed-agency-order')

  const supplyContractTemplate = templateDocumentsMap.get('supply-contract')
  const agencyContractTemplate = templateDocumentsMap.get('agency-contract')

  console.log(supplyContract)
  console.log(agencyContractTemplate)

  const handleDocumentViewerShow = (doc: DealDocumentData) => {
    setShowedDocument(doc)
  }

  const handleDocumentViewerClose = () => {
    setShowedDocument(null)
  }

  const handleAddDocumentModalOpen = (documentType?: DealDocumentDataTypeEnum) => {
    if (!documentType) return
    setUploadDocumentType(documentType)
  }

  const handleAddDocumentModalClose = () => {
    setUploadDocumentType(null)
  }

  const handleDownloadFiles = async (documentIds?: number[]) => {
    if (!documentIds?.length || !dealId) return

    try {
      await getDealDocumentsZip.mutateAsync({
        request: {
          dealId,
          documentIds,
        },
      })
    } catch (e) {
      console.error(e)
      appToast.error({
        description: 'Ошибка получения документов',
      })
    }
  }

  const handleUploadDocument = async (files: File[]) => {
    if (!uploadDocumentType || !dealId) return

    try {
      await uploadDealDocument.mutateAsync({
        dealId: dealId,
        documentType: uploadDocumentType,
        createDealDocumentBody: {
          documentFiles: files,
        },
      })
      handleAddDocumentModalClose()
      await dealDocumentsRefetch()
      appToast.success({
        description: 'Документ успешно загружен',
      })
    } catch (e) {
      console.error(e)
      appToast.error({
        description: 'Ошибка загрузки документа',
      })
    } finally {
      handleAddDocumentModalClose()
    }
  }

  const feedback: JSX.Element = (
    <Text variant="normal">
      По всем вопросам связанными с договором, свяжитесь с нами по почте{' '}
      <a href={`mailto:${AppConfig.ADMIN_CONTACT_EMAIL}`}>{AppConfig.ADMIN_CONTACT_EMAIL}</a> для уточнения или по
      телефону
      <PhoneNumber phoneNumber={AppConfig.ADMIN_CONTACT_PHONE} prefix="" />
    </Text>
  )

  if (!agencyContract?.instances.length && !agencyOrder?.instances.length && dealSide === 'buyer') {
    return (
      <Card type="primary">
        <Card.Header>
          <Card.Title>Формируем агентский договор и&nbsp;поручение</Card.Title>
        </Card.Header>
        <S.TextNotification>
          Спасибо, мы получили ваши реквизиты. Агентский договор и поручение будут сформированы в течение часа.
          <br />
          Вам придет уведомление.
          {!!agencyContractTemplate?.instances.length && (
            <>
              <br />
              Пока мы формируем документы, вы можете ознакомиться с{' '}
              <Button type="link">
                <Link target="_blank" to={agencyContractTemplate?.instances?.[0]?.files[0]?.source ?? ''}>
                  шаблоном договора
                </Link>
              </Button>
            </>
          )}
        </S.TextNotification>
      </Card>
    )
  }

  if (!specification?.instances.length && !supplyContract?.instances.length && dealSide === 'seller') {
    return (
      <Card type="primary">
        <Card.Header>
          <Card.Title>Формируем договор и&nbsp;спецификацию&nbsp;на подпись</Card.Title>
        </Card.Header>
        <S.TextNotification>
          Мы получили ваши реквизиты. Договор и спецификация будут сформированы в течение часа.
          <br />
          Вам придет уведомление.
          {!!supplyContractTemplate?.instances.length && (
            <>
              <br />
              Пока мы формируем документы, вы можете ознакомиться с{' '}
              <Button type="link">
                <Link target="_blank" to={supplyContractTemplate?.instances?.[0]?.files[0]?.source ?? ''}>
                  шаблоном договора
                </Link>
              </Button>
            </>
          )}
        </S.TextNotification>
      </Card>
    )
  }

  return (
    <>
      {dealSide === 'buyer' ? (
        <Card>
          <Card.Header>
            <Card.Title>Агентский договор и поручение</Card.Title>
          </Card.Header>

          <S.Content>
            <Text variant="normal">
              {!!signedAgencyContract?.instances.length && !!signedAgencyOrder?.instances.length ? (
                'Документы загружены. Администратор проверит и можно будет перейти к оплате.'
              ) : (
                <>
                  1. Скачайте договор и поручение и ознакомьтесь с условиями. Они уже подписаны с нашей стороны;
                  <br />
                  2. Распечатайте договор и поручение, и подпишите их;
                  <br />
                  3. Отсканируйте оба документа и загрузите их в формате PDF.
                  <br />
                </>
              )}
              {feedback}
            </Text>

            <div>
              <S.Subtitle>Агентский договор</S.Subtitle>
              <S.DocumentWrapper>
                {!!agencyContract?.instances.length && (
                  <Document
                    dealId={dealId}
                    document={agencyContract}
                    onRemoveAfter={dealDocumentsRefetch}
                    onUploadAfter={dealDocumentsRefetch}
                    onShow={() => handleDocumentViewerShow(agencyContract)}
                  />
                )}
                {signedAgencyContract?.instances.length ? (
                  <Document
                    dealId={dealId}
                    document={signedAgencyContract}
                    onRemoveAfter={dealDocumentsRefetch}
                    onUploadAfter={dealDocumentsRefetch}
                    onShow={() => handleDocumentViewerShow(signedAgencyContract)}
                  />
                ) : (
                  <S.EmptyDocument onClick={() => handleAddDocumentModalOpen(signedAgencyContract?.type)}>
                    Загрузить подписанный агентский договор
                  </S.EmptyDocument>
                )}
              </S.DocumentWrapper>
            </div>
            <div>
              <S.Subtitle>Поручение</S.Subtitle>
              <S.DocumentWrapper>
                {!!agencyOrder?.instances.length && (
                  <Document
                    dealId={dealId}
                    document={agencyOrder}
                    onRemoveAfter={dealDocumentsRefetch}
                    onUploadAfter={dealDocumentsRefetch}
                    onShow={() => handleDocumentViewerShow(agencyOrder)}
                  />
                )}
                {signedAgencyOrder?.instances.length ? (
                  <Document
                    dealId={dealId}
                    document={signedAgencyOrder}
                    onRemoveAfter={dealDocumentsRefetch}
                    onUploadAfter={dealDocumentsRefetch}
                    onShow={() => handleDocumentViewerShow(signedAgencyOrder)}
                  />
                ) : (
                  <S.EmptyDocument onClick={() => handleAddDocumentModalOpen(signedAgencyOrder?.type)}>
                    Загрузить подписанное поручение
                  </S.EmptyDocument>
                )}
              </S.DocumentWrapper>
            </div>
          </S.Content>
        </Card>
      ) : (
        <Card>
          <Card.Header>
            <Card.Title>Договор поставки и спецификация</Card.Title>
          </Card.Header>
          <S.Content>
            <Text variant="normal">
              {!!signedSpecification?.instances.length && !!signedSupplyContract?.instances.length ? (
                'Документы загружены. Администратор проверит и можно будет перейти к оплате.'
              ) : (
                <>
                  1. Скачайте договор поставки и спецификацию и ознакомьтесь с условиями. Они уже подписаны с нашей
                  стороны;
                  <br />
                  2. Распечатайте договор поставки и спецификацию и подпишите их;
                  <br />
                  3. Отсканируйте докуаменты и загрузите их в формате PDF.
                  <br />
                </>
              )}
              {feedback}
            </Text>
            <div>
              <S.Subtitle>Договор поставки</S.Subtitle>
              <S.DocumentWrapper>
                {!!supplyContract?.instances.length && (
                  <Document
                    dealId={dealId}
                    document={supplyContract}
                    onRemoveAfter={dealDocumentsRefetch}
                    onUploadAfter={dealDocumentsRefetch}
                    onShow={() => handleDocumentViewerShow(supplyContract)}
                  />
                )}
                {signedSupplyContract?.instances.length ? (
                  <Document
                    dealId={dealId}
                    document={signedSupplyContract}
                    onRemoveAfter={dealDocumentsRefetch}
                    onUploadAfter={dealDocumentsRefetch}
                    onShow={() => handleDocumentViewerShow(signedSupplyContract)}
                  />
                ) : (
                  <S.EmptyDocument onClick={() => handleAddDocumentModalOpen(signedSupplyContract?.type)}>
                    Загрузить подписанный договор поставки
                  </S.EmptyDocument>
                )}
              </S.DocumentWrapper>
            </div>
            <div>
              <S.Subtitle>Спецификация</S.Subtitle>
              <S.DocumentWrapper>
                {!!specification?.instances.length && (
                  <Document
                    dealId={dealId}
                    document={specification}
                    onRemoveAfter={dealDocumentsRefetch}
                    onUploadAfter={dealDocumentsRefetch}
                    onShow={() => handleDocumentViewerShow(specification)}
                  />
                )}
                {signedSpecification?.instances.length ? (
                  <Document
                    dealId={dealId}
                    document={signedSpecification}
                    onRemoveAfter={dealDocumentsRefetch}
                    onUploadAfter={dealDocumentsRefetch}
                    onShow={() => handleDocumentViewerShow(signedSpecification)}
                  />
                ) : (
                  <S.EmptyDocument onClick={() => handleAddDocumentModalOpen(signedSpecification?.type)}>
                    Загрузить подписанную спецификацию
                  </S.EmptyDocument>
                )}
              </S.DocumentWrapper>
            </div>
          </S.Content>
        </Card>
      )}

      <DocumentViewerModal
        isOpen={!!showedDocument}
        onCancel={handleDocumentViewerClose}
        docInstances={showedDocument?.instances ?? []}
        startCheckFromDocInstanceId={0}
      />

      <Modal
        title="Добавить файл"
        {...modalSharedSettings}
        open={!!uploadDocumentType}
        onCancel={handleAddDocumentModalClose}
        width={400}
        footer={[
          <Button key="cancel" onClick={handleAddDocumentModalClose} htmlType="button">
            Отмена
          </Button>,
          <Button
            key="submit"
            form="deal-add-document-form"
            type="primary"
            htmlType="submit"
            loading={uploadDealDocument.isLoading}
          >
            Готово
          </Button>,
        ]}
      >
        <DealAddDocumentForm
          documentsTypes={dealDocuments?.map((doc) => doc.type)}
          defaultDocumentsType={uploadDocumentType ?? undefined}
          onSubmit={(value) => handleUploadDocument(value.documentFiles)}
        />
      </Modal>
    </>
  )
}
