import React, { FC } from 'react'

import generatePicker, { PickerProps } from 'antd/es/date-picker/generatePicker'
import ruLocale from 'antd/es/date-picker/locale/ru_RU'
import dateFnsGenerateConfig from 'rc-picker/lib/generate/dateFns'

ruLocale.lang.locale = 'ru'

const DatePickerDateFns = generatePicker<Date>(dateFnsGenerateConfig)

export const TimePicker: FC<PickerProps<Date> & { status?: '' | 'warning' | 'error' | undefined }> = (props) => (
  <DatePickerDateFns
    picker={'time' as any}
    format="HH:mm"
    needConfirm={false}
    {...props}
    locale={ruLocale}
    style={{ width: '100%' }}
  />
)
