import React, { useEffect, useState } from 'react'

import { SizeType } from 'antd/es/config-provider/SizeContext'
import { BaseSelectRef } from 'rc-select'

import { ElevatorData } from '~api/generated'
import { useApi } from '~providers/api'
import { CustomControlInterface } from '~shared/controls/CustomControl.interface'
import { DebounceSelect } from '~shared/controls/DebounceSelect/DebounceSelect'

export interface ElevatorSelectControlValue {
  label: ElevatorData['name']
  value: ElevatorData['id']
}

interface ElevatorSelectControlProps extends CustomControlInterface {
  onChange: (value: ElevatorSelectControlValue['value'] | undefined) => void
  value: ElevatorSelectControlValue['value'] | undefined
  size?: SizeType
  hasError?: boolean
  id?: string
  placeholder?: string
}

export const ElevatorSelectControl: React.FC<ElevatorSelectControlProps> = React.forwardRef<
  BaseSelectRef,
  ElevatorSelectControlProps
>(({ id, value, onChange, hasError, size, placeholder = 'Найдите базис' }, ref) => {
  const api = useApi()
  const [selected, setSelected] = useState<ElevatorSelectControlValue | undefined>(
    value ? { value, label: `Базис #${value}` } : undefined
  )

  useEffect(() => {
    if (value) {
      api.elevators
        .elevatorControllerGetElevator({ id: value })
        .then((r) => setSelected({ value: r.data.id, label: r.data.name }))
    }
  }, [])

  const fetchElevatorList = async (elevatorNameOrAddress: string): Promise<ElevatorSelectControlValue[]> => {
    if (elevatorNameOrAddress.length < 3) {
      return []
    }
    const foundElevators = await api.elevators.elevatorControllerGetElevators({
      currentPage: 1,
      pageSize: 99,
      elevatorNameOrAddress,
    })
    return foundElevators.data?.elevators?.map((elevator) => ({ label: elevator.name, value: elevator.id }))
  }

  const onSelectHandler = (v: ElevatorSelectControlValue | ElevatorSelectControlValue[]) => {
    if (Array.isArray(v)) {
      return
    }
    setSelected(v)
    onChange(v?.value ?? undefined)
  }

  return (
    <DebounceSelect
      allowClear
      showSearch
      showArrow
      value={selected}
      placeholder={placeholder}
      fetchOptions={fetchElevatorList}
      onChange={onSelectHandler}
      id={id}
      size={size}
      status={hasError ? 'error' : undefined}
      style={{ width: '100%' }}
    />
  )
})

ElevatorSelectControl.displayName = 'ElevatorSelectControl'
