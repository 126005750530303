import React from 'react'

import { Button } from 'antd'
import { FiEdit2 } from 'react-icons/fi'
import { Link } from 'react-router-dom'

import { appRoutes } from '~/router'
import { Card } from '~/ui-components'
import { getCompanySignatoryLegalBasisOld } from '~/utils/company/get-company-signatory-legal-basis'
import { getDateFormat } from '~/utils/getDateFormat'
import { getUserRoleOld } from '~/utils/user/getUserRoleOld'
import { useGetCurrentRole } from '~hooks/auth'
import { CompanyStatusSelect } from '~pages/ProfilePage/components/tabs/CompanyProfileTab/components/CompanyStatusSelect'

import { ICompanyInfoViewProps } from './CompanyInfoView.types'

import * as S from './CompanyInfoView.styled'
import { Label } from './CompanyInfoView.styled'

export const CompanyInfoView: React.FC<ICompanyInfoViewProps> = ({ data, onChange, onStatusChange }) => {
  const {
    user: { isAdmin },
  } = useGetCurrentRole()

  const handleChange = () => {
    onChange(data)
  }

  console.log({ data })
  return (
    <Card>
      <Card.Header>
        <Card.Title>О компании</Card.Title>
        <Card.ActionsContent>
          {onStatusChange && (
            <CompanyStatusSelect
              nextStatuses={data.nextStatuses}
              defaultValue={data.status}
              onChange={onStatusChange}
            />
          )}

          <Button size="small" type="text" icon={<FiEdit2 size={24} />} onClick={handleChange} />
        </Card.ActionsContent>
      </Card.Header>

      <S.Content4Column>
        <Label>Компания</Label>
        <S.ValueColumnFull>{data.name}</S.ValueColumnFull>

        {isAdmin && data.createdBy && (
          <>
            <S.Label>Создал компанию</S.Label>
            <S.ValueColumnFull>
              {getUserRoleOld(data.createdBy.role)} (ID:
              <Button type="link">
                <Link to={appRoutes.moderatorUserDetails.url.replace(':id', data.createdBy.id.toString())}>
                  {data.createdBy.id}
                </Link>
              </Button>
              )
            </S.ValueColumnFull>
          </>
        )}

        {isAdmin && data.createdAt && (
          <>
            <S.Label>Дата регистрации</S.Label>
            <S.ValueColumnFull>{getDateFormat(data.createdAt)}</S.ValueColumnFull>
          </>
        )}

        <S.DividerFull />

        <S.Content4ColumnResponsive>
          <Label>ИНН</Label>
          <Card.Value>{data.inn}</Card.Value>

          <Label>Генеральный директор</Label>
          <Card.Value>{data.director}</Card.Value>

          <Label>КПП</Label>
          <Card.Value>{data.kpp}</Card.Value>

          <Label>Подписант</Label>
          <Card.Value>{data.signatory}</Card.Value>

          <Label>ОКВЭД</Label>
          <Card.Value>{data.codeOkved}</Card.Value>

          <Label>Должность</Label>
          <Card.Value>{data.signatoryPosition}</Card.Value>

          <Label>ОГРН</Label>
          <Card.Value>{data.ogrn}</Card.Value>

          <Label>Основание</Label>
          <Card.Value>{getCompanySignatoryLegalBasisOld(data.signatoryLegalBasis)}</Card.Value>

          <Label>Налогообложение</Label>
          <Card.Value>{data.usingNds ? 'с НДС' : 'без НДС'}</Card.Value>

          <Label>Участник хартии АПК</Label>
          <Card.Value>{data.aicCharterMember ? 'Да' : 'Нет'}</Card.Value>
        </S.Content4ColumnResponsive>

        <S.DividerFull />

        <Label>Физический адрес</Label>
        <S.ValueColumnFull>{data.actualAddress}</S.ValueColumnFull>

        <Label>Юридический адрес</Label>
        <S.ValueColumnFull>{data.legalAddress}</S.ValueColumnFull>
      </S.Content4Column>
    </Card>
  )
}
