import React from 'react'

import { Select } from 'antd'
import { SizeType } from 'antd/es/config-provider/SizeContext'
import { BaseSelectRef } from 'rc-select'

import { ElevatorData } from '~api/generated'
import { useGetElevators } from '~hooks/elevators'
import { CustomControlInterface } from '~shared/controls/CustomControl.interface'

export interface ElevatorSelectControlValue {
  label: ElevatorData['name']
  value: ElevatorData['id']
  data: ElevatorData
}

interface ElevatorSelectControlProps extends CustomControlInterface {
  onChange: (value: ElevatorSelectControlValue['data'] | undefined) => void
  value: ElevatorSelectControlValue['value'] | undefined
  size?: SizeType
  hasError?: boolean
  id?: string
  placeholder?: string
}

export const ElevatorSelectControl: React.FC<ElevatorSelectControlProps> = React.forwardRef<
  BaseSelectRef,
  ElevatorSelectControlProps
>(({ id, value, onChange, hasError, size }, ref) => {
  const elevators = useGetElevators({ currentPage: 1, pageSize: 99 })

  const dropdownOptions =
    elevators.data?.elevators?.map((x) => ({
      value: x.id,
      label: `${x.name ? x.name.concat(' / ') : ''}${x.address}`,
      data: x,
    })) ?? []

  return (
    <>
      <Select
        style={{ width: '100%', maxWidth: '342px' }}
        id={id}
        value={value}
        loading={elevators.isLoading || elevators.isFetching}
        onChange={(value) => {
          const originalItem = dropdownOptions.find((option) => option.value === value)
          originalItem && onChange(originalItem.data)
        }}
        placeholder="Выберите базис"
        size={size}
        status={hasError ? 'error' : undefined}
      >
        {dropdownOptions.map((option) => (
          <Select.Option key={option.value} value={option.value}>
            {option.label}
          </Select.Option>
        ))}
      </Select>
    </>
  )
})

ElevatorSelectControl.displayName = 'ElevatorSelectControl'
