import React, { FC, useState } from 'react'

import { Button, Modal } from 'antd'

import { DealDocumentData, DealDocumentDataTypeEnum, FileData, DocumentPartStatus } from '~/api/generated'
import { Card, EmptyButton } from '~/ui-components'
import { appToast, modalSharedSettings } from '~/utils'
import { dealDocumentsGroupsTypes } from '~/utils/constants'
import { useGetDealDocumentsGroups } from '~/utils/deals'
import { useGetCurrentRole } from '~hooks/auth'
import { useGetDealDocuments, useUpdateDealDocumentStatus, useUploadDealDocument } from '~hooks/deal-documents'
import { DealAddDocumentForm } from '~pages/Deals/components'
import { Document, DocumentChecker } from '~shared/components'
import { DocumentViewerModal } from '~shared/components/DocumentViewer'

interface DocumentsProductProps {
  dealId: number

  dealSide: 'seller' | 'buyer' | unknown

  documentType: keyof typeof dealDocumentsGroupsTypes

  title: string
}

export const DocumentsProduct: FC<DocumentsProductProps> = ({ dealId, dealSide, documentType, title }) => {
  const [isOpenAddDocumentModal, setOpenAddDocumentModal] = useState(false)
  const [showedDocument, setShowedDocument] = useState<{ document: DealDocumentData; instanceId: number } | null>(null)

  const updateDocumentStatusFn = useUpdateDealDocumentStatus()
  const uploadDealDocument = useUploadDealDocument()

  const { data: documents, refetch: refetchDocument } = useGetDealDocuments({
    dealId,
  })

  const {
    user: { isAdmin },
  } = useGetCurrentRole()

  const { [documentType]: groupOfDocuments } = useGetDealDocumentsGroups(documents)

  const handleUploadDocument = async (files: File[], documentType: DealDocumentDataTypeEnum) => {
    try {
      await uploadDealDocument.mutateAsync({
        dealId,
        documentType,
        createDealDocumentBody: {
          documentFiles: files,
          documentInfo: {
            documentNumber: null,
            formationDate: null,
          },
        },
      })
      handleAddDocumentModalClose()
      await refetchDocument()
      appToast.success({
        description: 'Документ успешно загружен',
      })
    } catch (e) {
      console.error(e)
      appToast.error({
        description: 'Ошибка загрузки документа',
      })
    }
  }

  const changeDocumentFileStatus = async (file: FileData, status: DocumentPartStatus, reason?: string) => {
    try {
      await updateDocumentStatusFn.mutateAsync({
        fileId: file.id,
        updateDealDocumentFileBody: {
          status,
          rejectionNote: reason || null,
        },
      })
    } catch (e) {
      appToast.error({ description: 'Ошибка при изменении статуса документа' })
    }

    appToast.success({ description: 'Статус документа изменен' })
    await refetchDocument()
  }

  const handleDocumentViewerShow = (document: DealDocumentData, instanceId: number) => {
    setShowedDocument({
      document,
      instanceId,
    })
  }

  const handleDocumentViewerClose = () => {
    setShowedDocument(null)
  }

  const handleAddDocumentModalOpen = () => {
    setOpenAddDocumentModal(true)
  }

  const handleAddDocumentModalClose = () => {
    setOpenAddDocumentModal(false)
  }

  if (!(dealSide === 'seller' || !!groupOfDocuments.length)) {
    return null
  }

  return (
    <>
      <Card>
        <Card.Header>
          <Card.Title>{title}</Card.Title>
        </Card.Header>
        <Card.Content>
          {groupOfDocuments?.map((document) => (
            <Document
              key={document.type}
              dealId={dealId}
              document={document}
              onShow={(instanceId) => handleDocumentViewerShow(document, instanceId)}
            />
          ))}
          <EmptyButton style={{ minHeight: 112 }} onClick={handleAddDocumentModalOpen}>
            Загрузить документ
          </EmptyButton>
        </Card.Content>
      </Card>

      <DocumentChecker
        isCheckerVisible={!!showedDocument?.document && isAdmin}
        docTemplateList={dealDocumentsGroupsTypes[documentType]}
        documentsForCheck={groupOfDocuments}
        startCheckFromDocument={showedDocument?.document.type ?? null}
        startCheckFromDocumentInstanceId={showedDocument?.instanceId}
        onDocumentFileStatusChange={changeDocumentFileStatus}
        onFinishCheck={() => setShowedDocument(null)}
      />

      <DocumentViewerModal
        isOpen={!!showedDocument?.document && !isAdmin}
        onCancel={handleDocumentViewerClose}
        docInstances={showedDocument?.document.instances ?? []}
        startCheckFromDocInstanceId={0}
      />

      <Modal
        title="Загрузить документ"
        {...modalSharedSettings}
        open={isOpenAddDocumentModal}
        onCancel={handleAddDocumentModalClose}
        width={400}
        footer={[
          <Button key="cancel" onClick={handleAddDocumentModalClose} htmlType="button">
            Отмена
          </Button>,
          <Button
            key="submit"
            form="deal-add-document-form"
            type="primary"
            htmlType="submit"
            loading={uploadDealDocument.isLoading}
          >
            Готово
          </Button>,
        ]}
      >
        <DealAddDocumentForm
          documentsTypes={dealDocumentsGroupsTypes[documentType]}
          onSubmit={(value) => handleUploadDocument(value.documentFiles, value.documentType)}
        />
      </Modal>
    </>
  )
}
