import React, { useState } from 'react'

import { Button, Empty, Flex, Pagination, Space, Spin } from 'antd'

import { useQuery } from '@apollo/client'

import { usePagination } from '~/hooks'
import { Text } from '~/ui-components'
import { graphqlErrorHandler } from '~/utils/graphqlErrorHandler'
import { DriverEntity, TransportType } from '~api/gql-generated/graphql'
import { getTransportsQuery } from '~api/gql-query/car-park.query.graphql'
import { AssignTransportToDriver } from '~pages/CarParkPage/modules/AssignTransportToDriver'
import { AssignedDriverTransportActionMenu } from '~pages/CarParkPage/modules/DriverInfo/components/AssignedDriverTransportActionMenu'
import { TransportListItem } from '~pages/CarParkPage/modules/Transport/components/TransportListItem'
import { useAuth } from '~providers/auth'
import { SearchInput } from '~shared/components/SearchInput'
import { TransportTypeSelectControl } from '~shared/controls'

interface DriverInfoCardProps {
  driver: DriverEntity
}
export function AssignedDriverTransport({ driver }: DriverInfoCardProps) {
  const { companyId } = useAuth()
  const { currentPage, setCurrentPage, pageSize, setPageSize, pageSizeOptions } = usePagination()
  const [assignTransportOpen, setAssignTransportOpen] = useState<boolean>(false)
  const [searchTransportQuery, setSearchTransportQuery] = useState<string>('')
  const [transportTypeQuery, setTransportTypeQuery] = useState<TransportType[]>([])
  const [transportStationId, setTransportStationId] = useState<number>(0)

  const getTransports = useQuery(getTransportsQuery, {
    variables: {
      filter: {
        companyIds: [companyId],
        search: searchTransportQuery,
        types: transportTypeQuery,
        driverIds: [driver.id],
        // transportStationIds: [0],
        transportStationIds: transportStationId ? [transportStationId] : undefined,
      },
      pagination: { currentPage, pageSize },
    },
    onError: graphqlErrorHandler,
  })

  const transports = getTransports.data?.transports.transports
  const pagination = getTransports.data?.transports.pagination

  const hasActiveFilters = !!searchTransportQuery.length || !!transportTypeQuery.length

  if (!transports?.length && !hasActiveFilters && !getTransports.loading) {
    return (
      <Flex justify="center">
        <Empty
          description={
            <>
              <Space direction="vertical">
                <Text variant="normal-bold">Здесь будет назначенный транспорт</Text>
              </Space>
            </>
          }
        >
          <Button type="primary" onClick={() => setAssignTransportOpen(true)}>
            Назначить транспорт
          </Button>
        </Empty>
        <AssignTransportToDriver
          driverId={driver.id}
          open={assignTransportOpen}
          onClose={() => setAssignTransportOpen(false)}
        />
      </Flex>
    )
  }

  return (
    <Spin spinning={getTransports.loading}>
      <Flex vertical gap={20}>
        <Flex gap={24} style={{ marginTop: 24 }}>
          <div style={{ width: 180 }}>
            <TransportTypeSelectControl value={undefined} onChange={setTransportTypeQuery} />
          </div>
          <div style={{ width: 180 }}>
            <SearchInput defaultValue={searchTransportQuery} onSearch={setSearchTransportQuery} placeholder="Поиск" />
          </div>
        </Flex>

        <Flex vertical flex={1} gap={8}>
          {transports?.map((transport) => (
            <TransportListItem key={transport.id} transport={transport} onClickCard={() => console.log('onClickCard')}>
              <Flex flex={1}>
                <Flex justify="flex-end" flex={1}>
                  <AssignedDriverTransportActionMenu
                    transport={transport}
                    onUnassignTransport={(transportId) => console.log('onUnassignTransport', transportId)}
                  />
                </Flex>
              </Flex>
            </TransportListItem>
          ))}

          {hasActiveFilters && !transports?.length && !getTransports.loading && (
            <Empty
              description={
                <>
                  <Space direction="vertical">
                    <Text variant="normal-bold">Поиск не дал результатов</Text>
                  </Space>
                </>
              }
            />
          )}

          {pagination && (
            <Pagination
              style={{ marginTop: 'auto' }}
              current={pagination?.currentPage}
              total={pagination?.total}
              pageSize={pageSize}
              pageSizeOptions={pageSizeOptions}
              onShowSizeChange={(x, size) => setPageSize(size)}
              showSizeChanger
              onChange={setCurrentPage}
            />
          )}
        </Flex>
      </Flex>
    </Spin>
  )
}
