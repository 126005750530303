import React from 'react'

import { Button } from 'antd'

import { UserRole } from '~api/gql-generated/graphql'

import * as S from './UserRoleDescription.styled'

interface UserRoleDescriptionProps {
  role: UserRole | undefined
  onClose: () => void
}
export const UserRoleDescription: React.FC<UserRoleDescriptionProps> = ({ role, onClose }) => {
  if (!role) {
    return null
  }

  const renderContent = () => {
    switch (role) {
      case UserRole.Director: {
        return (
          <S.ContentWrapper>
            <S.Title>Возможности и ограничения роли «Директор»</S.Title>
            <S.Description>Доступно добавление, редактирование и удаление данных о компании.</S.Description>
            <S.Description>
              Полный функционал без ограничений в разделе «Предложения» и в остальных основных разделах.
            </S.Description>
            <S.Description>
              Доступно управление сотрудниками (внутри своей компании), это – назначение роли и привязка/отвязка
              пользователя к/от компании.
            </S.Description>
            <S.Description>
              Дополнительные настройки компании недоступны, это – добавление новой компании и сотрудников.
            </S.Description>
          </S.ContentWrapper>
        )
      }
      case UserRole.Manager: {
        return (
          <S.ContentWrapper>
            <S.Title>Возможности и ограничения роли «Менеджер»</S.Title>
            <S.Description>
              Возможность добавления данных о компании. Редактирование и удаление данных недоступно.
            </S.Description>
            <S.Description>
              Возможность создания объявления в разделе «Базисы». Отправка отклика и отклонение ставки недоступна.
            </S.Description>
            <S.Description>
              Возможность создания лотов, принятия и отклонения ставок в разделе «Торги». Снятие лота с публикации
              недоступно.
            </S.Description>
            <S.Description>
              На этапе сделки доступен весь функционал, кроме проверки документов и самостоятельного завершения сделки.{' '}
            </S.Description>
            <S.Description>
              Возможность добавление нового склада и культуры в разделе «Склады». Редактирование и удаление данных о
              складе и культуре недоступно.
            </S.Description>
          </S.ContentWrapper>
        )
      }
      case UserRole.Accountant: {
        return (
          <S.ContentWrapper>
            <S.Title>Возможности и ограничения роли «Бухгалтер»</S.Title>
            <S.Description>Доступно заполнение, редактирование и удаление данных о компании.</S.Description>
            <S.Description>Полный функционал без ограничений на этапе сделки. </S.Description>
            <S.Description>Полный функционал без ограничений в разделе «Склады». </S.Description>
            <S.Description>Раздел «Предложения» доступен только в режиме просмотра.</S.Description>
            <S.Description>
              Раздел «Базисы» доступен только в режиме просмотра, но можно скачать отчет по объявлению.
            </S.Description>
            <S.Description>Раздел «Торги» доступен только в режиме просмотра. </S.Description>
            <S.Description>
              Дополнительные настройки компании недоступны, это – добавление и управление новой компание и сотрудниками.
            </S.Description>
          </S.ContentWrapper>
        )
      }
      default: {
        return 'нет описания роли'
      }
    }
  }

  return (
    <S.Wrapper>
      {renderContent()}
      <S.ActionWrapper>
        <Button onClick={onClose}>Закрыть</Button>
      </S.ActionWrapper>
    </S.Wrapper>
  )
}
