import React from 'react'

import { Input, Space, Tag, theme, Tooltip } from 'antd'
import { FiX } from 'react-icons/fi'

import { IActiveTags } from '~/store/filters'

import * as S from './ActiveFiltersTags.styled'

interface IActiveFiltersTagsProps<T> {
  store: IActiveTags<T>
}

export const ActiveFiltersTags = <T extends object>(props: IActiveFiltersTagsProps<T>) => {
  const onRemoveFilter = (filterKey: keyof T) => () => {
    props.store.setFilterByDefault(filterKey)
  }

  return (
    <S.ActiveFiltersTagsWrapper>
      {props.store.getActiveFiltersTags()?.map((filter) => (
        <S.StyledTag
          key={filter.key as string}
          closable
          closeIcon={<FiX size={18} />}
          onClose={onRemoveFilter(filter.key)}
        >
          {filter.label}
        </S.StyledTag>
      ))}
    </S.ActiveFiltersTagsWrapper>
  )
}
