import { useMutation } from '@tanstack/react-query'

import {
  CreateOfferResponseFromBuyerResponse,
  ElevatorAdvertOfferApiCreateOfferResponseFromBuyerRequest,
} from '~api/generated'
import { useApi } from '~providers/api'

export const useCreateElevatorAdvertOfferFromBuyer = () => {
  const api = useApi()

  const useCreateElevatorAdvertOfferFn = async (
    request: ElevatorAdvertOfferApiCreateOfferResponseFromBuyerRequest
  ): Promise<CreateOfferResponseFromBuyerResponse> => {
    const res = await api.elevatorAdvertOffer.createOfferResponseFromBuyer(request)

    return res.data
  }

  return useMutation(useCreateElevatorAdvertOfferFn)
}
