import React from 'react'

import { Button, Input } from 'antd'
import { Controller, useForm } from 'react-hook-form'

import { yupResolver } from '@hookform/resolvers/yup'

import { FormField } from '~/ui-components'

import { edoFormSchema } from './EdoForm.validation'

import { IEdoForm, IIEdoFormProps } from './EdoForm.types'

import { FormItem } from '../../../../shared.styled'
import * as S from './EdoForm.styled'

export const EdoForm: React.FC<IIEdoFormProps> = ({ defaultValues, isLoading, onSubmit }) => {
  const {
    control,
    handleSubmit,
    formState: { errors, isDirty },
  } = useForm<IEdoForm>({
    defaultValues: {
      operatorEdoName: defaultValues?.name ?? '',
      operatorEdoId: defaultValues?.edmId ?? '',
    },
    resolver: yupResolver(edoFormSchema),
  })

  const onSubmitHandler = (data: IEdoForm) => onSubmit(data)

  return (
    <form onSubmit={handleSubmit(onSubmitHandler)}>
      <S.ContentWrapper>
        <FormItem>
          <FormField label="Оператор ЭДО" htmlFor="operatorEdoName" validateMessage={errors.operatorEdoName?.message}>
            <Controller
              name="operatorEdoName"
              control={control}
              render={({ field }) => (
                <Input
                  {...field}
                  id="operatorEdoName"
                  placeholder="СКБ Контур"
                  status={errors.operatorEdoName && 'error'}
                />
              )}
            />
          </FormField>
        </FormItem>

        <FormItem>
          <FormField
            label="Идентификатор в ЭДО"
            htmlFor="operatorEdoId"
            validateMessage={errors.operatorEdoId?.message ?? ''}
          >
            <Controller
              name="operatorEdoId"
              control={control}
              render={({ field }) => (
                <Input
                  {...field}
                  id="operatorEdoId"
                  placeholder="2AE5F994152-D65A-4717-B0B3-55F0F4B7C448"
                  status={errors.operatorEdoId && 'error'}
                />
              )}
            />
          </FormField>
        </FormItem>

        <S.ActionWrapper>
          <Button htmlType="submit" type="primary" disabled={!isDirty || isLoading}>
            {isLoading ? 'Сохранение' : 'Сохранить'}
          </Button>
        </S.ActionWrapper>
      </S.ContentWrapper>
    </form>
  )
}
