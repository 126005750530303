import { FreightOrderChangeRequestStatus } from '~api/gql-generated/graphql'
import {
  AiFillCheckCircle,
  AiFillClockCircle,
  AiFillCloseCircle,
  AiFillExclamationCircle,
  AiFillMinusCircle,
  AiFillPlusCircle,
} from 'react-icons/ai'

export const StatusIcon = ({
  status,
  isCreator,
  isFinalizing,
}: {
  status: FreightOrderChangeRequestStatus
  isCreator: boolean
  isFinalizing: boolean
}) => {
  switch (status) {
    case FreightOrderChangeRequestStatus.OnModeration:
      return isCreator ? (
        <AiFillClockCircle size={20} color="var(--gray-4-color)" />
      ) : (
        <AiFillExclamationCircle size={20} color="var(--warning-color)" />
      )
    case FreightOrderChangeRequestStatus.CounterOffer:
      return <AiFillPlusCircle size={20} color="var(--gray-4-color)" />
    case FreightOrderChangeRequestStatus.Accepted:
      return <AiFillCheckCircle size={20} color="var(--success-color)" />
    case FreightOrderChangeRequestStatus.Rejected:
      return isCreator === isFinalizing ? (
        <AiFillMinusCircle size={20} color="var(--gray-4-color)" />
      ) : (
        <AiFillCloseCircle size={20} color="var(--danger-color)" />
      )
    default:
      return null
  }
}
