import { useMutation } from '@tanstack/react-query'

import { DealApiDealControllerUpdateDealLogisticsInfoUnloadingRequest } from '~api/generated'
import { useApi } from '~providers/api'

export const useUpdateDealLogisticsUnloading = () => {
  const api = useApi()

  const updateDealLogisticsUnloadingFn = async (
    request: DealApiDealControllerUpdateDealLogisticsInfoUnloadingRequest
  ): Promise<void> => {
    const res = await api.deal.dealControllerUpdateDealLogisticsInfoUnloading(request)
    return res.data
  }

  return useMutation(updateDealLogisticsUnloadingFn)
}
