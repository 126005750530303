import React, { useEffect, useMemo, useState } from 'react'

import { Tabs } from 'antd'
import { observer } from 'mobx-react-lite'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'

import { AppHeader } from '~/layout'
import { appRoutes, IAppRoute } from '~/router'
import { userUiSettingsStore } from '~/store/userUiSettingsStore'
import { getTabsFromUrl } from '~/utils/getTabsFromUrl'
import { AuctionPromo } from '~pages/Auctions/components'
import { AuctionPromoModal } from '~pages/Auctions/components/AuctionPromo'

import * as S from './AuctionsPage.styled'

const tabs = [appRoutes.auctionLotsList, appRoutes.auctionsMyLots, appRoutes.auctionsMyResponses]

const AuctionsPage = observer(() => {
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const isShowAuctionDemoPage = userUiSettingsStore.isShowAuctionDemoPage
  const [isShowAuctionDemoPageModal, setShowAuctionDemoPageModal] = useState(false)

  useEffect(() => {
    if (pathname === appRoutes.auctions.url) {
      navigate(appRoutes.auctionLotsList.url, { replace: true })
    }
  }, [])

  const onHideAuctionDemoPage = () => userUiSettingsStore.hideAuctionDemoPage()

  const onHelpClickHandler = () => {
    setShowAuctionDemoPageModal(true)
  }

  const handleTabsChange = (url: IAppRoute['url']) => {
    navigate(url)
  }

  const activeTab = useMemo(() => {
    return tabs.find((tab) => pathname.includes(tab.url))
  }, [pathname])

  if (isShowAuctionDemoPage) {
    return (
      <>
        <AppHeader title={appRoutes.auctions.title} />
        <AuctionPromo navigateToAuctions={onHideAuctionDemoPage} />
      </>
    )
  }

  return (
    <div>
      <AuctionPromoModal visible={isShowAuctionDemoPageModal} onClose={() => setShowAuctionDemoPageModal(false)} />
      <AppHeader title={appRoutes.auctions.title} onHelp={onHelpClickHandler}>
        <Tabs activeKey={activeTab?.url} size="small" onChange={handleTabsChange} items={getTabsFromUrl(tabs)} />
      </AppHeader>
      <S.Content>
        <Outlet />
      </S.Content>
    </div>
  )
})

export default AuctionsPage
