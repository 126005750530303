import React, { useEffect, useState } from 'react'

import { Button, Space } from 'antd'
import { Controller, useForm } from 'react-hook-form'

import { yupResolver } from '@hookform/resolvers/yup'

import { DaDataPartySuggestion, FormField, InputDadata } from '~/ui-components'
import { CompanyRoleControl } from '~shared/controls'

import { addNewCompanyFormValidation } from './AddNewCompanyForm.validation'

import { AddNewCompanyFormProps, AddNewCompanyFormValues } from './AddNewCompanyForm.types'

export const AddNewCompanyForm: React.FC<AddNewCompanyFormProps> = ({
  onSubmit,
  onCancel,
  defaultValues,
  isLoading,
  isAdmin,
}) => {
  const [selectedDadataCompany, setSelectedDadataCompany] = useState<DaDataPartySuggestion | null>(null)
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors, isDirty },
  } = useForm<AddNewCompanyFormValues>({
    defaultValues: {
      companyRoleType: defaultValues?.companyRoleType ?? undefined,
      companyInn: defaultValues?.companyInn ?? '',
    },
    resolver: yupResolver(addNewCompanyFormValidation),
  })

  const onSubmitHandler = (data: AddNewCompanyFormValues) => onSubmit(data)

  useEffect(() => {
    if (!selectedDadataCompany) {
      setValue('companyInn', '')
    } else {
      setValue('companyInn', selectedDadataCompany.data.inn)
    }
  }, [selectedDadataCompany])

  return (
    <form onSubmit={handleSubmit(onSubmitHandler)} onClick={(e) => e.stopPropagation()}>
      <FormField
        label="Наименование компании или ИНН"
        htmlFor="companyInfo"
        validateMessage={errors.companyInn?.message}
        isRequired
      >
        <Controller
          name="companyInn"
          control={control}
          render={({ field }) => (
            <InputDadata
              id="companyInn"
              placeholder="Введите наименование компании"
              inputValue={selectedDadataCompany?.data?.name?.short_with_opf ?? ''}
              onSelect={(value?: DaDataPartySuggestion) => setSelectedDadataCompany(value ?? null)}
              variant="organizations"
              inputStatus={errors.companyInn && 'error'}
              onChangeInput={() => setSelectedDadataCompany(null)}
            />
          )}
        />
      </FormField>

      <FormField validateMessage={errors.companyRoleType?.message} label="Зарегистрируйте меня как">
        <Controller
          name="companyRoleType"
          control={control}
          render={({ field }) => <CompanyRoleControl value={field.value} onChange={field.onChange} isAdmin={isAdmin} />}
        />
      </FormField>

      <Space style={{ width: '100%', justifyContent: 'end' }}>
        <Button htmlType="button" onClick={onCancel}>
          Отмена
        </Button>

        <Button htmlType="submit" type="primary" disabled={!isDirty || isLoading}>
          Готово
        </Button>
      </Space>
    </form>
  )
}
