import React, { FC } from 'react'

import { FiChevronRight } from 'react-icons/fi'

import { Badge } from '~/ui-components'
import { getAuctionStatus } from '~/utils/auctions/get-auction-status'
import { getOfferStatusForAuction } from '~/utils/auctions/get-offer-status-for-auction'
import { formatNumber } from '~/utils/formatNumber'
import { getNdsString } from '~/utils/getNdsString'
import { getOfferType } from '~/utils/offers/get-offer-type'

import { AuctionMobileCardProps } from './types'

import * as S from './AuctionMobileCard.styled'

export const AuctionMobileCard: FC<AuctionMobileCardProps> = ({
  productName,
  auctionId,
  offerType,
  status,
  offerStatus,
  price,
  myPrice,
  includingNds,
  volume,
  companyName,
  location,
  endedAt,
  onClick,
  dealButton,
}) => {
  const offerTypeConfig = offerType ? getOfferType(offerType) : null
  const auctionStatusConfig = status ? getAuctionStatus(status) : null
  const offerStatusStatusConfig = offerStatus ? getOfferStatusForAuction(offerStatus) : null

  return (
    <S.Card onClick={onClick}>
      <S.RowVertical>
        <S.Label>№</S.Label>
        <S.Value>{auctionId}</S.Value>
      </S.RowVertical>

      {auctionStatusConfig && (
        <S.RowVertical>
          <S.Label>Статус лота</S.Label>
          <Badge color={auctionStatusConfig.color} size="s">
            {auctionStatusConfig.name}
          </Badge>
        </S.RowVertical>
      )}

      {offerStatusStatusConfig && (
        <S.RowVertical>
          <S.Label>Статус ставки</S.Label>
          <Badge color={offerStatusStatusConfig.color} size="s">
            {offerStatusStatusConfig.name}
          </Badge>
        </S.RowVertical>
      )}

      {offerTypeConfig && (
        <S.RowVertical>
          <S.Label>Тип лота</S.Label>
          <Badge color={offerTypeConfig.color} size="s">
            {offerTypeConfig.name}
          </Badge>
        </S.RowVertical>
      )}

      <S.Row>
        <S.Label>Наименование культуры</S.Label>
        <S.ValueBold>{productName}</S.ValueBold>
      </S.Row>

      <S.Row>
        <S.Label>Объем, т</S.Label>
        <S.Value>{formatNumber(volume)}</S.Value>
      </S.Row>

      <S.Row>
        <S.Label>Ставка лота, ₽/т</S.Label>
        <S.ValueBold>
          {formatNumber(price)} ({getNdsString(includingNds)})
        </S.ValueBold>
      </S.Row>

      {myPrice && (
        <S.Row>
          <S.Label>Моя ставка, ₽/т</S.Label>
          <S.ValueBold>
            {formatNumber(myPrice)} ({getNdsString(includingNds)})
          </S.ValueBold>
        </S.Row>
      )}

      {companyName && (
        <S.Row>
          <S.Label>Компания</S.Label>
          <S.Value>{companyName}</S.Value>
        </S.Row>
      )}

      <S.Row>
        <S.Label>Локация</S.Label>
        <S.Value>{location}</S.Value>
      </S.Row>

      {endedAt && (
        <S.Row>
          <S.Label>Торги закончатся</S.Label>
          <S.Value>{endedAt}</S.Value>
        </S.Row>
      )}

      <S.Icon>
        <FiChevronRight size={24} />
      </S.Icon>

      {dealButton}
    </S.Card>
  )
}
