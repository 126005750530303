import { AxiosError } from 'axios'

import { appToast } from '~/utils/index'

export function httpErrorHandler(error: unknown, errorMessage = '', isShowToast = true) {
  // Sentry.captureException(error)

  let description = !errorMessage.trim() ? 'Ошибка' : errorMessage
  if (error instanceof AxiosError) {
    description = `${description}: ${error.response?.data?.message}`
    console.error(description, error)
  }
  if (isShowToast) {
    appToast.error({ description })
  }
}
