import React, { FC, PropsWithChildren } from 'react'

import { Tooltip } from 'antd'

import { Card } from '~/ui-components'

import { LogisticCardProps } from './LogisticCard.types'

import * as S from './LogisticCard.styled'

export const LogisticCard: FC<PropsWithChildren<LogisticCardProps>> = ({
  children,
  title,
  description,
  onClickChangeButton,
  isEditable = false,
}) => {
  return (
    <Card>
      <S.Header>
        <S.Title variant="h3">{title}</S.Title>
        {isEditable && (
          <S.DesktopModalButton type="primary" onClick={onClickChangeButton}>
            Заполнить
          </S.DesktopModalButton>
        )}
      </S.Header>

      {description && <S.Description variant="description">{description}</S.Description>}

      {children}

      <S.MobileModalButton type="primary" onClick={onClickChangeButton}>
        Заполнить
      </S.MobileModalButton>
    </Card>
  )
}
