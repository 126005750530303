import React, { PropsWithChildren } from 'react'

import { Avatar, Select, Space, Tag } from 'antd'
import { CheckboxOptionType } from 'antd/es/checkbox/Group'
import { BaseSelectRef } from 'rc-select'

import { getAbbrCompany } from '~/utils/company/get-abbr-company'
import { CustomControlInterface } from '~shared/controls/CustomControl.interface'

type Value = number[]

export interface CompaniesSelectControlCompanyData {
  legalName: string
  id: number
  logoUrl?: string | null
}

interface CompaniesSelectControlProps extends CustomControlInterface {
  companiesData: CompaniesSelectControlCompanyData[]
  onChange: (value: Value) => void
  value: Value
  hasError?: boolean
  isDisabled?: boolean
}

export const CompaniesSelectControl: React.FC<PropsWithChildren<CompaniesSelectControlProps>> = React.forwardRef<
  BaseSelectRef,
  PropsWithChildren<CompaniesSelectControlProps>
>(({ children, value, onChange, companiesData, hasError, isDisabled = false }, ref) => {
  const onChangeHandler = (val: unknown) => {
    onChange(val as Value)
  }

  const options: CheckboxOptionType[] = companiesData.map((company) => ({
    label: (
      <Space>
        <Avatar size={32} shape="circle" src={company.logoUrl}>
          {getAbbrCompany(company.legalName)}
        </Avatar>
        {company.legalName}
      </Space>
    ),
    value: company.id,
  }))

  const defaultValue = companiesData.map((x) => x.id).filter((id) => value.includes(id))

  return (
    <Select
      defaultValue={defaultValue}
      tagRender={(props) => (
        <Tag closable={!isDisabled} bordered={false} onClose={props.onClose}>
          {companiesData.find((x) => x.id === props.value)?.legalName}
        </Tag>
      )}
      placeholder="Выберите компании"
      disabled={isDisabled}
      style={{ width: '100%' }}
      filterOption={(input, option) =>
        companiesData
          .find((x) => x.id === option?.value)
          ?.legalName.toLowerCase()
          .includes(input.toLowerCase()) ?? false
      }
      ref={ref}
      mode="multiple"
      status={hasError ? 'error' : undefined}
      options={options}
      onChange={onChangeHandler}
    />
  )
})

CompaniesSelectControl.displayName = 'CompaniesSelectControl'
