import React from 'react'

import { Card } from '~/ui-components'
import { formatPhoneNumber } from '~/utils/formatPhoneNumber'
import { BaseCompanyData, BaseUserData } from '~api/generated'
import { useGetCurrentRole } from '~hooks/auth'
import { AdminLinkToCompanyById } from '~shared/components'

import * as S from '../../DealInformation.styled'

interface IDealCounterpartProps {
  company: BaseCompanyData | null
  user: BaseUserData | null
  title?: string
}
export const Counterpart: React.FC<IDealCounterpartProps> = ({ title, company, user }) => {
  const {
    user: { isAdmin },
  } = useGetCurrentRole()

  if (!company) {
    return null
  }

  return (
    <Card>
      <Card.Header>
        <Card.Title>{title ? title : 'Реквизиты вашей компании для договора'}</Card.Title>
      </Card.Header>
      <S.CardContent>
        <S.Row>
          <Card.Label>Компания</Card.Label>
          <Card.Value>
            {company.name || '-'} <AdminLinkToCompanyById companyId={company.id} />
          </Card.Value>
        </S.Row>

        <S.Row>
          <Card.Label>ИНН</Card.Label>
          <Card.Value>{company.inn || '-'}</Card.Value>
        </S.Row>

        <S.Row>
          <Card.Label>КПП</Card.Label>
          <Card.Value>{company.kpp || '-'}</Card.Value>
        </S.Row>

        <S.Row>
          <Card.Label>ОКВЭД</Card.Label>
          <Card.Value>{company.codeOkved || '-'}</Card.Value>
        </S.Row>

        <S.Row>
          <Card.Label>Подписант</Card.Label>
          <Card.Value>{company.director || '-'}</Card.Value>
        </S.Row>

        {isAdmin && (
          <S.Row>
            <Card.Label>Телефон</Card.Label>
            <Card.Value>{user?.phoneNumber?.length ? formatPhoneNumber(user.phoneNumber) : '-'}</Card.Value>
          </S.Row>
        )}

        <S.Row>
          <Card.Label>Налогообложение</Card.Label>
          <Card.Value>{company.usingNds ? 'с НДС' : 'без НДС'}</Card.Value>
        </S.Row>

        <S.Row>
          <Card.Label>Участник хартии АПК</Card.Label>
          <Card.Value>{company.aicCharterMember === null ? '-' : company.aicCharterMember ? 'Да' : 'Нет'}</Card.Value>
        </S.Row>

        <S.Row>
          <Card.Label>Физический адрес</Card.Label>
          <Card.Value>{company.actualAddress || '-'}</Card.Value>
        </S.Row>

        <S.Row>
          <Card.Label>Юридический адрес</Card.Label>
          <Card.Value>{company.legalAddress || '-'}</Card.Value>
        </S.Row>
      </S.CardContent>
    </Card>
  )
}
