import { Divider } from 'antd'
import styled from 'styled-components'

import { Text } from '~/ui-components'

export const Title = styled.p`
  color: var(--gray-8-color);
  font-weight: 700;
  font-size: 22px;
  line-height: 28px;
`

export const StyledNav = styled.nav`
  display: flex;
  flex-direction: column;
  padding: 16px;
`
export const SectionName = styled(Text).attrs({ variant: 'description' })`
  color: var(--gray-6-color);
  margin-bottom: 8px;
`
export const StyledDivider = styled(Divider)`
  margin: 16px 0;
`

export const CompanyInfoWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 18px;
`
export const CompanyNameWrapper = styled.div`
  margin-left: auto;
  margin-right: 12px;
  padding: 4px 12px;
  border-radius: 6px;
  background: #696e73;
  color: var(--gray-1-color);
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;

  max-width: 140px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`
