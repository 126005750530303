import { useMutation } from '@tanstack/react-query'

import { ElevatorAdvertApiAdminElevatorAdvertControllerUpdateElevatorAdvertStatusRequest } from '~/api/generated'
import { useApi } from '~providers/api'

export const useUpdateElevatorAdvertStatusByAdmin = () => {
  const api = useApi()

  return useMutation((request: ElevatorAdvertApiAdminElevatorAdvertControllerUpdateElevatorAdvertStatusRequest) => {
    return api.elevatorAdvert.adminElevatorAdvertControllerUpdateElevatorAdvertStatus(request)
  })
}
