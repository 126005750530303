import React, { useState } from 'react'

import { Button, Modal, Skeleton, Space } from 'antd'
import { FiArrowRight } from 'react-icons/fi'
import { useParams } from 'react-router'
import { useNavigate } from 'react-router-dom'

import { useGetProductTypeData } from '~/hooks'
import { AppHeader } from '~/layout'
import { appRoutes } from '~/router'
import { appToast, httpErrorHandler } from '~/utils'
import { getDealStatusName } from '~/utils/deals/get-deal-status-name'
import { getDateTimeFormat } from '~/utils/getDateFormat'
import { getOfferStatus } from '~/utils/offers/get-offer-status'
import { getOfferType } from '~/utils/offers/get-offer-type'
import { UpdateComplexDealStatusBody, UpdateComplexDealStatusBodyStatusEnum } from '~api/generated'
import { useGetModeratorDeal, useUpdateComplexDealStatus, useUpdateDealModerator } from '~hooks/moderator'
import { OfferCardDetails } from '~pages/OffersPage/components/OfferCardDetails'
import { useAuth } from '~providers/auth'

import { DealPersonCard } from './components/DealPersonCard'

import * as S from './ModeratorDealResponseDetails.styled'

export const ModeratorDealResponseDetails: React.FC = () => {
  const { userId: currentUserId } = useAuth()
  const navigate = useNavigate()
  const { id: dealId } = useParams<{ id: string | undefined }>()
  const dealFn = useGetModeratorDeal(Number(dealId))
  const updateDealModeratorFn = useUpdateDealModerator()
  const useUpdateDealStatusFn = useUpdateComplexDealStatus()
  const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false)
  const [selectedDealStatus, setSelectedDealStatus] = useState<UpdateComplexDealStatusBodyStatusEnum | null>(null)
  const { getProductTypeParametersForUI_V2 } = useGetProductTypeData()

  const handleBackClick = () => {
    navigate(appRoutes.moderatorDeals.url.concat('/').concat(appRoutes.moderatorDealsResponsesList.url))
  }

  const handleChangeStatus = async ({ status, needPublishOffer }: UpdateComplexDealStatusBody) => {
    try {
      await useUpdateDealStatusFn.mutateAsync({
        complexDealId: Number(dealId),
        updateComplexDealStatusBody: {
          status,
          needPublishOffer,
        },
      })
      await dealFn.refetch()
      appToast.success({ description: 'Статус предложения изменен' })
      setSelectedDealStatus(null)
      setIsConfirmModalVisible(false)
      handleBackClick()
    } catch (e) {
      appToast.error({ description: 'Ошибка при смене статуса предложения' })
    }
  }

  const takeDealToWorkHandler = async () => {
    try {
      await updateDealModeratorFn.mutateAsync({
        complexDealId: deal.id,
        updateComplexDealModeratorBody: {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          moderatorId: currentUserId!,
        },
      })
      appToast.success({ description: `Вы взяли в работу предложение ${offer.id}` })
    } catch (e) {
      httpErrorHandler(e, 'Ошибка при обновлении модератора оффера')
    }

    await dealFn.refetch()
  }

  if (dealFn.isLoading || !dealFn.data) {
    return <Skeleton />
  }

  const deal = dealFn.data
  const offer = deal.offer
  const buyer = deal.buyer
  const seller = deal.seller

  const isSameSellerAndBuyer = buyer.id === seller.id
  const hasOfferModerator = !!deal?.moderator
  const isModeratorNotCurrentUser = currentUserId && deal?.moderator ? deal?.moderator?.id !== currentUserId : false
  const areActionButtonsDisabled = !hasOfferModerator || isModeratorNotCurrentUser

  return (
    <>
      <AppHeader title={`Отклик на предложение № ${dealId}`} isBack onBack={handleBackClick} />
      <Modal
        title={
          selectedDealStatus === UpdateComplexDealStatusBodyStatusEnum.RejectedAfterModeration
            ? 'Что делать с предложением?'
            : 'Изменить статус отклика?'
        }
        open={isConfirmModalVisible}
        okText="Изменить"
        cancelText="Отмена"
        onOk={() => handleChangeStatus({ status: UpdateComplexDealStatusBodyStatusEnum.Agreement })}
        // confirmLoading={updateOfferFn.isLoading}
        onCancel={() => {
          setSelectedDealStatus(null)
          setIsConfirmModalVisible(false)
        }}
        footer={selectedDealStatus === UpdateComplexDealStatusBodyStatusEnum.RejectedAfterModeration ? null : undefined}
      >
        <p>
          {/* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */}
          Статус отклика будет изменен на <b>{getDealStatusName(selectedDealStatus!)}</b>
        </p>
        {selectedDealStatus === UpdateComplexDealStatusBodyStatusEnum.RejectedAfterModeration && (
          <Space direction="vertical" style={{ width: '100%', marginTop: 16 }}>
            <Button
              size="large"
              block
              style={{ height: 56 }}
              onClick={() =>
                handleChangeStatus({
                  status: UpdateComplexDealStatusBodyStatusEnum.RejectedAfterModeration,
                  needPublishOffer: false,
                })
              }
            >
              <S.ChangeStatusButton>
                <span>Снять с публикации</span>
                <FiArrowRight size={24} />
              </S.ChangeStatusButton>
            </Button>
            <Button
              size="large"
              block
              style={{ height: 56 }}
              onClick={() =>
                handleChangeStatus({
                  status: UpdateComplexDealStatusBodyStatusEnum.RejectedAfterModeration,
                  needPublishOffer: true,
                })
              }
            >
              <S.ChangeStatusButton>
                <span>Отправить на проверку</span>
                <FiArrowRight size={24} />
              </S.ChangeStatusButton>
            </Button>
          </Space>
        )}
      </Modal>
      <S.ContentWrapper>
        <S.ActionWrapperHeader>
          {hasOfferModerator && (
            <Space>
              <S.AdminInfoLabel>В работе у модератора</S.AdminInfoLabel>
              <S.AdminInfoValue>{deal?.moderator?.name ?? '-'}</S.AdminInfoValue>
            </Space>
          )}
          <Space>
            <S.AdminInfoLabel>Дата создания отклика</S.AdminInfoLabel>
            <S.AdminInfoValue>{getDateTimeFormat(deal?.createdAt)}</S.AdminInfoValue>
          </Space>
          {isModeratorNotCurrentUser && (
            <Button htmlType="button" type="primary" onClick={takeDealToWorkHandler}>
              Взять в работу
            </Button>
          )}
        </S.ActionWrapperHeader>

        <OfferCardDetails
          title={offer.product.name}
          statuses={[getOfferType(offer.type), getOfferStatus(offer.status)]}
          parameters={getProductTypeParametersForUI_V2(offer.product)}
          laboratoryAnalysisSource={offer.laboratoryAnalysis?.source}
          volume={offer.volume}
          price={offer.price}
          priceDetail={offer.priceDetail}
          includingNds={offer.includingNds}
          address={offer.address}
          distance={offer.distance}
          shippingRequired={offer.shippingRequired}
          contactPersonName={offer.contactPerson?.name}
          contactPersonPhone={offer.contactPerson?.phoneNumber}
          // publishedAt={offer.publishedAt}
          // acceptedAt={offer.createdAt}
          // expiredAt={offer.expiredAt}
          note={offer.note}
          // company={offer.company ?? undefined}
          type={offer.type}
          isParametersCorrespondsToGost={offer.product.parametersIsCorrespondsToGost}
          gost={offer.product.gost}
          // createdBy={offer.createdBy}
        />

        <S.PersonInfoWrapper>
          <DealPersonCard
            personType="buyer"
            name={buyer.director ?? '-'}
            companyName={buyer.name}
            companyId={buyer.id}
            inn={buyer.inn}
          />

          <DealPersonCard
            personType="seller"
            name={seller.director ?? '-'}
            companyName={seller.name}
            companyId={seller.id}
            inn={seller.inn}
          />
        </S.PersonInfoWrapper>

        <S.ActionWrapperFooter>
          <Button
            htmlType="button"
            type="text"
            onClick={() => {
              setSelectedDealStatus(UpdateComplexDealStatusBodyStatusEnum.RejectedAfterModeration)
              setIsConfirmModalVisible(true)
            }}
            disabled={areActionButtonsDisabled}
          >
            Отклонить отклик
          </Button>
          <Button
            htmlType="button"
            type="primary"
            onClick={() => {
              setSelectedDealStatus(UpdateComplexDealStatusBodyStatusEnum.Agreement)
              setIsConfirmModalVisible(true)
            }}
            disabled={areActionButtonsDisabled || isSameSellerAndBuyer}
          >
            Начать сделку
          </Button>
        </S.ActionWrapperFooter>
      </S.ContentWrapper>
    </>
  )
}
