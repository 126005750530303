import { getDateFormat } from '~/utils/getDateFormat'
import { DocumentInstanceData } from '~api/generated'

/**
 * Возвращает id первого экземпляра документа определенного типа
 * @param documentInstancesData все инстансы документа
 * @param startFromInstanceId если задан, то берем его
 */
export function getFirstDocInstanceId(
  documentInstancesData: DocumentInstanceData[],
  startFromInstanceId?: number | null
): number | null {
  if (!documentInstancesData?.length) {
    return null
  }
  const isInstanceIdExistInDocument = documentInstancesData.some((x) => x.id === startFromInstanceId)

  if (startFromInstanceId && isInstanceIdExistInDocument) {
    const foundInstance = documentInstancesData?.find((x) => x.id === startFromInstanceId)
    return foundInstance?.id ?? null
  }
  return documentInstancesData[0].id
}

/**
 * Список опций для дропдауна содержащий все экземпляры одного типа документа
 */
export function getDocumentInstancesOptions(instances: DocumentInstanceData[]) {
  if (!instances) {
    return []
  }
  return instances.map((instance, idx) => {
    const docDate = instance.documentInfo?.formationDate ? getDateFormat(instance.documentInfo.formationDate) : null
    const docNumber = instance.documentInfo?.documentNumber ?? idx + 1
    const label = `№${docNumber}${docDate ? ` от ${docDate}` : ''}`
    return {
      value: instance.id,
      label,
    }
  })
}

/**
 * поиск экземпляра документа по ID
 */
export function getDocumentInstanceById(
  instances: DocumentInstanceData[],
  instanceId: number | null
): DocumentInstanceData | null {
  if (!instances?.length || !instanceId) {
    return null
  }
  return instances.find((x) => x.id === instanceId) ?? null
}

/**
 * Получение количества файлов из которых состоит документ
 */
export function getDocumentInstanceTotalFilesCount(
  instances: DocumentInstanceData[],
  instanceId: number | null
): number {
  const foundInstance = getDocumentInstanceById(instances, instanceId)
  return foundInstance?.files?.length ?? 0
}

/**
 * Получение конкретного файла документа по его индексу в массиве файлов документа
 */
export function getDocInstanceFileByIndex(
  instances: DocumentInstanceData[],
  instanceId: number | null,
  fileIndex: number
) {
  const foundInstance = getDocumentInstanceById(instances, instanceId)
  if (!foundInstance?.files || foundInstance.files.length < fileIndex + 1) {
    console.error('[DocumentViewer]: incorrect file index or document has`t files')
    return null
  }
  return foundInstance?.files[fileIndex] ?? null
}
