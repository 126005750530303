import styled from 'styled-components'

import { Text } from '~/ui-components'

export const Wrapper = styled.div`
  background: var(--gray-1-color);
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  gap: 4px;
  padding: 8px;
  flex: 1;
`
export const CountWrapper = styled.div`
  background: #e3e3e3;
  border-radius: 8px;
  padding: 2px 6px;
`
export const Row = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
`
export const Price = styled(Text).attrs({ variant: 'normal' })`
  color: var(--gray-6-color);
`
