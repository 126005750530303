import styled, { css } from 'styled-components'

import { breakpoints } from '~/tokens'
import { Text } from '~/ui-components/components/Text'

import { CardProps } from './Card.types'

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 8px;
  margin-bottom: 24px;

  @media (max-width: ${breakpoints.MOBILE_XL}px) {
    margin-bottom: 16px;
  }
`

export const Card = styled.div<CardProps>`
  padding: 32px;
  background: #fff;
  border-radius: 16px;
  box-shadow: 0 1px 4px rgba(84, 84, 84, 0.15);

  @media (max-width: ${breakpoints.MOBILE_XL}px) {
    padding: 16px;
  }

  ${({ type }) =>
    type === 'primary' &&
    css`
      background: #f5faf6;

      ${Header} {
        margin-bottom: 16px;
      }
    `}
`

export const Title = styled(Text).attrs({ variant: 'h3' })``

export const ActionsContent = styled.div`
  display: flex;
  gap: 16px;
  @media (max-width: ${breakpoints.MOBILE_XL}px) {
    gap: 8px;
  }
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  @media (max-width: ${breakpoints.MOBILE_XL}px) {
    gap: 8px;
  }
`

export const Label = styled(Text).attrs({ variant: 'normal' })`
  color: var(--gray-6-color);
`

export const Value = styled(Text).attrs({ variant: 'normal' })`
  word-break: break-word;
`
export const ValueBold = styled(Text).attrs({ variant: 'normal' })`
  word-break: break-word;
  font-weight: bold;
`
