import React, { useState } from 'react'

import { Badge, Button, Checkbox, Flex, Modal, Tabs, TabsProps } from 'antd'
import { FiCheck, FiClock } from 'react-icons/fi'
import { IoCloseOutline } from 'react-icons/io5'
import { useParams } from 'react-router-dom'

import { useMutation, useQuery } from '@apollo/client'
import { ApolloError } from '@apollo/client/errors'

import { AppHeader } from '~/layout'
import { appRoutes } from '~/router'
import { Text } from '~/ui-components'
import { appToast, modalSharedSettings } from '~/utils'
import { graphqlErrorHandler } from '~/utils/graphqlErrorHandler'
import { getFreightDealStatusName } from '~/utils/logistics/get-freight-deal-status-name'
import { FreightDealEntity, FreightDealStatus, FreightOrderChangeRequestStatus } from '~api/gql-generated/graphql'
import { freightDealUpdateStatusMutation } from '~api/gql-mutations/freight-deal-update-status.mutation.graphql'
import { freightOrderChangeRequestAcceptAllMutation } from '~api/gql-mutations/freight-order-change-request-accept-all.mutation.graphql'
import { freightDealQuery } from '~api/gql-query/freight-deals.query.graphql'
import { useGetCurrentRole } from '~hooks/auth'
import { RejectDealForm } from '~pages/LogisticsPage/modules/LogisticDealCard/components/RejectDealForm'
import { useAuth } from '~providers/auth'

import { LogisticDealDetails } from './components/LogisticDealDetails'
import { LogisticDealDocuments } from './components/LogisticDealDocuments'

import { RejectDealFormValues } from '~pages/LogisticsPage/modules/LogisticDealCard/components/RejectDealForm/RejectDealForm.types'

import * as S from './LogisticDealCard.styled'

export const LogisticDealCard: React.FC = () => {
  const { id } = useParams<{ id: string | undefined }>()
  const freightDealId = Number(id)
  const {
    company: { isCarrier },
  } = useGetCurrentRole()
  const { companyId } = useAuth()

  const [isCompleteDealOpenModal, setIsCompleteDealOpenModal] = useState(false)
  const [isSuccessCompleteDealOpenModal, setIsSuccessCompleteDealOpenModal] = useState(false)
  const [isDocumentsReceived, setIsDocumentsReceived] = useState(false)
  const [isCancelDealOpenModal, setIsCancelDealOpenModal] = useState(false)
  const [isAcceptAllOpenModal, setIsAcceptAllOpenModal] = useState(false)
  const [activeTab, setActiveTab] = useState<'details' | 'documents'>('details')

  const getFreightDeal = useQuery(freightDealQuery, { variables: { freightDealId }, fetchPolicy: 'cache-and-network' })
  const freightDeal: FreightDealEntity | undefined = getFreightDeal.data?.freightDeal as FreightDealEntity | undefined

  const [freightDealUpdateStatus] = useMutation(freightDealUpdateStatusMutation, {
    refetchQueries: [freightDealQuery],
  })
  const [freightOrderChangeRequestAcceptAll] = useMutation(freightOrderChangeRequestAcceptAllMutation, {
    refetchQueries: [freightDealQuery],
  })

  const onFreightDealUpdateStatus = async (status: FreightDealStatus, formValues?: RejectDealFormValues) => {
    try {
      await freightDealUpdateStatus({
        variables: {
          freightDealId,
          input: {
            status,
            rejectionReason: formValues?.rejectionReason,
          },
        },
      })
      setIsCompleteDealOpenModal(false)
      setIsCancelDealOpenModal(false)
      appToast.success({ message: 'Статус заказа изменен' })
      if (
        (freightDeal?.isRealisedByCarrier && !freightDeal?.isRealisedByCustomer) ||
        (freightDeal?.isRealisedByCustomer && !freightDeal?.isRealisedByCarrier)
      ) {
        setIsSuccessCompleteDealOpenModal(true)
      }
    } catch (e) {
      e instanceof ApolloError && graphqlErrorHandler(e, 'Ошибка при изменении статуса заказа')
    }
  }

  const onFreightOrderChangeRequestAcceptAll = async () => {
    try {
      await freightOrderChangeRequestAcceptAll({
        variables: {
          freightDealId,
        },
      })
      setIsAcceptAllOpenModal(false)
      appToast.success({ message: 'Все условия приняты' })
    } catch (e) {
      e instanceof ApolloError && graphqlErrorHandler(e, 'Ошибка при принятии всех условий')
    }
  }

  const tabItems: TabsProps['items'] = [
    {
      label: 'Детали',
      tabKey: 'details',
      key: 'details',
      children: (
        <LogisticDealDetails
          freightDeal={freightDeal}
          loading={getFreightDeal.loading}
          refetchFreightDeal={getFreightDeal.refetch}
        />
      ),
    },
    {
      label: 'Документы',
      tabKey: 'documents',
      key: 'documents',
      children: <LogisticDealDocuments freightDealId={freightDealId} freightDealStatus={freightDeal?.status} />,
    },
  ]

  const isDisabledActionsCompleted = !freightDeal?.nextStatuses.includes(FreightDealStatus.Realised)

  const isDisabledActionsRejected = !freightDeal?.nextStatuses.includes(FreightDealStatus.Rejected)

  const isCompletedDealByCounterparty = isCarrier ? freightDeal?.isRealisedByCustomer : freightDeal?.isRealisedByCarrier

  const isNotCompletedDealByCounterparty = isCarrier
    ? freightDeal?.isRealisedByCarrier
    : freightDeal?.isRealisedByCustomer

  const isCompletedDeal = freightDeal?.isRealisedByCarrier && freightDeal?.isRealisedByCustomer

  const isDisabledActionsAgreed = !freightDeal?.nextStatuses.includes(FreightDealStatus.Shipment)

  const isAgreedDealByCounterparty = isCarrier ? freightDeal?.isAgreedByCustomer : freightDeal?.isAgreedByCarrier

  const isNotAgreedDealByCounterparty = isCarrier ? freightDeal?.isAgreedByCarrier : freightDeal?.isAgreedByCustomer

  const isAcceptAll =
    freightDeal &&
    freightDeal.lastChangeRequests.filter(
      (item) => item.status === FreightOrderChangeRequestStatus.OnModeration && item.createdByCompanyId !== companyId
    ).length > 0

  return (
    <>
      <AppHeader title={appRoutes.logisticDeals.title} isBack />

      {freightDeal && (
        <S.Wrapper vertical gap={8}>
          <Flex vertical gap={16}>
            <Flex justify="space-between" gap={24}>
              <Flex align="center" gap={16}>
                <S.TextDeal>Заказ</S.TextDeal>
                <S.TextId>ID: {freightDealId}</S.TextId>
                <Flex align="center" gap={8}>
                  {freightDeal?.status === FreightDealStatus.Agreement && (
                    <>
                      <Badge color="var(--primary-hover-color)" />
                      <Text variant="form-read-label" style={{ color: 'var(--gray-8-color)' }}>
                        {getFreightDealStatusName(
                          freightDeal.status,
                          Boolean(freightDeal?.isRealisedByCarrier || freightDeal?.isRealisedByCustomer)
                        )}
                      </Text>
                      {(freightDeal?.isRealisedByCarrier || freightDeal?.isRealisedByCustomer) && <FiClock size={20} />}
                    </>
                  )}
                  {freightDeal?.status === FreightDealStatus.Realised && isCompletedDeal && (
                    <>
                      <Badge color="var(--primary-hover-color)" />
                      <Text variant="form-read-label" style={{ color: 'var(--gray-8-color)' }}>
                        {getFreightDealStatusName(
                          freightDeal.status,
                          Boolean(freightDeal?.isRealisedByCarrier || freightDeal?.isRealisedByCustomer)
                        )}
                      </Text>
                      <FiCheck size={20} />
                    </>
                  )}
                  {freightDeal?.status === FreightDealStatus.Rejected && (
                    <>
                      <Badge color="red" />
                      <Text variant="form-read-label" style={{ color: 'var(--gray-8-color)' }}>
                        {getFreightDealStatusName(
                          freightDeal.status,
                          Boolean(freightDeal?.isRealisedByCarrier || freightDeal?.isRealisedByCustomer)
                        )}
                      </Text>
                      <IoCloseOutline size={20} />
                    </>
                  )}
                </Flex>
              </Flex>
              <Flex gap={12}>
                <Button
                  type="default"
                  onClick={() => setIsCancelDealOpenModal(true)}
                  disabled={isDisabledActionsRejected}
                >
                  Отменить заказ
                </Button>
                {freightDeal?.status === FreightDealStatus.Agreement && (
                  <Button
                    type="primary"
                    onClick={() => onFreightDealUpdateStatus(FreightDealStatus.Shipment)}
                    disabled={isAcceptAll || isDisabledActionsAgreed}
                  >
                    Перейти к доставке
                  </Button>
                )}
                {freightDeal?.status === FreightDealStatus.Shipment && (
                  <Button
                    type="primary"
                    onClick={() => setIsCompleteDealOpenModal(true)}
                    disabled={isDisabledActionsCompleted}
                  >
                    Завершить заказ
                  </Button>
                )}
              </Flex>
            </Flex>
            <Flex vertical gap={16}>
              {/*{freightDeal?.status !== FreightDealStatus.Rejected && (*/}
              {/*  <Text variant="normal">*/}
              {/*    На данном этапе вы можете приложить документы о сделке и подтвердить завершение заказа.*/}
              {/*  </Text>*/}
              {/*)}*/}
              {freightDeal?.status === FreightDealStatus.Realised && <Text variant="normal-bold">Заказ завершен</Text>}
              {freightDeal?.status === FreightDealStatus.Agreement && (
                <Flex vertical gap={16}>
                  <Text variant="normal">
                    На данном этапе вы можете согласовать информацию и при необходимости изменить условия.
                  </Text>
                  {!isAcceptAll && (
                    <Text variant="normal">
                      Обратите внимание, что после предложения своих условий потребуется некоторое время на их
                      согласование, поэтому доставка будет невозможна до того момента, пока обе стороны не примут все
                      условия.
                    </Text>
                  )}
                  {isAcceptAll && (
                    <Text variant="normal">
                      Для перехода на следующий этап необходимо принятие всех условий с обеих сторон.
                    </Text>
                  )}
                  <S.CompleteOrderWrapper vertical gap={4}>
                    {isAgreedDealByCounterparty && (
                      <>
                        <Flex align="center">
                          <Text variant="normal-bold">Запрос на переход к доставке</Text>
                          <Badge dot />
                        </Flex>
                        <Text variant="normal">
                          Для перехода к этапу «Доставка» требуется получить подтверждение от обеих сторон. Если вы
                          уверены, что больше не хотите вносить изменения, нажмите кнопку «Перейти к доставке».
                        </Text>
                      </>
                    )}
                    {isNotAgreedDealByCounterparty && (
                      <>
                        <Text variant="normal-bold">Запрос на переход к доставке отправлен контрагенту</Text>
                        <Text variant="normal">
                          Для перехода на следующий этап «Доставка» необходимо подтверждение с обеих сторон. Дождитесь
                          решения контрагента.
                        </Text>
                      </>
                    )}
                  </S.CompleteOrderWrapper>
                </Flex>
              )}
              {freightDeal?.status === FreightDealStatus.Shipment && (
                <S.CompleteOrderWrapper vertical gap={4}>
                  {isCompletedDealByCounterparty && (
                    <>
                      <Flex align="center">
                        <Text variant="normal-bold">Запрос на завершение заказа</Text>
                        <Badge dot />
                      </Flex>
                      <Text variant="normal">
                        Контрагент подтвердил выполнение всех обязательств сделки. Завершите заказ, если вы согласны с
                        заявленным.
                      </Text>
                    </>
                  )}
                  {isNotCompletedDealByCounterparty && (
                    <>
                      <Text variant="normal-bold">Заявка на завершение заказа отправлена контрагенту</Text>
                      <Text variant="normal">
                        После того, как контрагент подтвердит завершение сделки со своей стороны, заказ будет считаться
                        завершенным.
                      </Text>
                    </>
                  )}
                </S.CompleteOrderWrapper>
              )}
              {isAcceptAll && (
                <S.AcceptAllWrapper justify="space-between" align="center">
                  <Flex vertical gap={4}>
                    <Text variant="normal-bold">Контрагент ожидает вашего решения</Text>
                    <Text variant="normal">
                      Обратите внимание на условия, выделенные оранжевым цветом – их необходимо подтвердить или
                      предложить свои.
                    </Text>
                  </Flex>
                  <Flex>
                    <Button htmlType="button" type="primary" onClick={() => setIsAcceptAllOpenModal(true)}>
                      Принять все условия
                    </Button>
                  </Flex>
                </S.AcceptAllWrapper>
              )}
            </Flex>
          </Flex>
          <Tabs
            activeKey={activeTab}
            onChange={(key) => setActiveTab(key as 'details' | 'documents')}
            items={tabItems}
          />

          <Modal
            {...modalSharedSettings}
            width={400}
            open={isCompleteDealOpenModal}
            onCancel={() => {
              setIsCompleteDealOpenModal(false)
              setIsDocumentsReceived(false)
            }}
            title="Завершить заказ"
          >
            <Flex vertical gap={24}>
              <Flex>
                <Text variant="normal">
                  Вы подтверждаете, что контрагент выполнил все обязательства и вами получены все документы, необходимые
                  для закрытия сделки?
                </Text>
              </Flex>

              <Flex>
                <Checkbox
                  id="documentsReceived"
                  checked={isDocumentsReceived}
                  onChange={(e) => setIsDocumentsReceived(e.target.checked)}
                >
                  Все необходимые документы получены
                </Checkbox>
              </Flex>

              <Flex gap={16} justify="flex-end">
                <Button
                  htmlType="button"
                  onClick={() => {
                    setIsCompleteDealOpenModal(false)
                    setIsDocumentsReceived(false)
                  }}
                >
                  Отмена
                </Button>

                <Button
                  htmlType="button"
                  type="primary"
                  disabled={!isDocumentsReceived}
                  onClick={() => onFreightDealUpdateStatus(FreightDealStatus.Realised)}
                >
                  Завершить заказ
                </Button>
              </Flex>
            </Flex>
          </Modal>

          <Modal
            {...modalSharedSettings}
            width={400}
            open={isSuccessCompleteDealOpenModal}
            onCancel={() => setIsSuccessCompleteDealOpenModal(false)}
            title="Завершить заказ"
          >
            <Flex vertical gap={24}>
              <Flex>
                <Text variant="normal-bold">Заявка на завершение заказа отправлена контрагенту</Text>
              </Flex>

              <Flex>
                <Text variant="normal">
                  После того, как контрагент подтвердит завершение сделки со своей стороны, заказ будет считаться
                  завершенным.
                </Text>
              </Flex>

              <Flex gap={16} justify="flex-end">
                <Button htmlType="button" onClick={() => setIsSuccessCompleteDealOpenModal(false)}>
                  Закрыть
                </Button>
              </Flex>
            </Flex>
          </Modal>

          <Modal
            {...modalSharedSettings}
            width={400}
            open={isCancelDealOpenModal}
            onCancel={() => setIsCancelDealOpenModal(false)}
            title="Отменить заказ"
          >
            <RejectDealForm
              loading={getFreightDeal.loading}
              onBack={() => setIsCancelDealOpenModal(false)}
              onSubmit={(formValues) => onFreightDealUpdateStatus(FreightDealStatus.Rejected, formValues)}
              type={freightDeal.type}
            />
          </Modal>

          <Modal
            {...modalSharedSettings}
            width={400}
            open={isAcceptAllOpenModal}
            onCancel={() => setIsAcceptAllOpenModal(false)}
            title="Принять все условия"
          >
            <Flex vertical gap={24}>
              <Flex>
                <Text variant="normal-bold">Вы уверены, что все предложенные условия соответствуют требованиям?</Text>
              </Flex>

              <Flex>
                <Text variant="normal">
                  После принятия всех условий вы сможете перейти к доставке, но изменить условия после будет нельзя.
                </Text>
              </Flex>

              <Flex gap={16} justify="flex-end">
                <Button
                  htmlType="button"
                  onClick={() => {
                    setIsAcceptAllOpenModal(false)
                  }}
                >
                  Отмена
                </Button>

                <Button htmlType="button" type="primary" onClick={() => onFreightOrderChangeRequestAcceptAll()}>
                  Принять все условия
                </Button>
              </Flex>
            </Flex>
          </Modal>
        </S.Wrapper>
      )}
    </>
  )
}
