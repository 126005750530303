import { LoadingTransportType } from '~api/generated'
import { LoadingTransportType as LoadingTransportTypeGQL } from '~api/gql-generated/graphql'

export function getElevatorLoadType(loadType?: LoadingTransportType | LoadingTransportTypeGQL | null): string {
  if (!loadType) {
    return '-'
  }
  switch (loadType) {
    case LoadingTransportType.GrainThrower: {
      return 'Зерномёт'
    }
    case LoadingTransportType.Elevator: {
      return 'Элеватор'
    }
    case LoadingTransportType.Telehandler: {
      return 'Маниту (телескопический погрузчик)'
    }
    case LoadingTransportType.FrontLoader: {
      return 'Амкодор (фронтальный погрузчик)'
    }
    case LoadingTransportType.VerticalPipe: {
      return 'Труба (вертикальная)'
    }
    case LoadingTransportType.Custom: {
      return 'Другое'
    }
    default: {
      return ''
    }
  }
}
