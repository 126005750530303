interface IParams {
  userCompanyId: number
  sellerId: number
  buyerId: number
}

export type DealSideType = 'buyer' | 'seller' | 'unknown'

export function getDealSide({ userCompanyId, buyerId, sellerId }: IParams): DealSideType {
  if (userCompanyId === buyerId) {
    return 'buyer'
  }
  if (userCompanyId === sellerId) {
    return 'seller'
  }
  return 'unknown'
}
