import React from 'react'

import { Button } from 'antd'

import { Box, Text } from '~/ui-components'

export const FallbackPage = () => {
  return (
    <div style={{ width: 400, marginLeft: 'auto', marginRight: 'auto', marginTop: 20, backgroundColor: '#F7FAF9' }}>
      <Text variant="normal">Ошибка</Text>
      <Box>
        <Button type="primary" onClick={() => (window.location.href = window.location.origin)}>
          Перейти на главную страницу
        </Button>
      </Box>
    </div>
  )
}
