import React, { useMemo } from 'react'

import { Spin } from 'antd'

import { useQuery } from '@apollo/client'

import { getTransportModeName } from '~/utils/car-park/get-transport-mode'
import { getTransportationAreaName } from '~/utils/car-park/get-transportation-area'
import { getTransportationDataQuery } from '~api/gql-query/car-park.query.graphql'
import { useAuth } from '~providers/auth'

import { TransportationDataFill } from './components/TransportationDataFill'
import { TransportationDataInfo } from './components/TransportationDataInfo'

import { emptyValue } from './TransportationData.types'

import * as S from './TransportationData.styled'

export const TransportationData: React.FC = () => {
  const { companyId } = useAuth()

  const getTransportationData = useQuery(getTransportationDataQuery, {
    variables: {
      companyId: companyId,
    },
    fetchPolicy: 'cache-and-network',
  })

  const transportModes = getTransportationData.data?.transportData?.transportModes
  const transportationArea = getTransportationData.data?.transportData?.transportationArea
  const regions = getTransportationData.data?.transportData?.regions
  const address = getTransportationData.data?.transportData?.address

  const getTransportModes = useMemo(() => {
    return transportModes?.length
      ? transportModes.map((transportMode) => getTransportModeName(transportMode)).join(', ')
      : emptyValue
  }, [transportModes])

  const getTransportationArea = useMemo(() => {
    return transportationArea ? getTransportationAreaName(transportationArea) : emptyValue
  }, [transportationArea])

  const getRegions = useMemo(() => {
    return regions?.length ? regions.map((region) => region.name).join(', ') : emptyValue
  }, [regions])

  const getAddress = useMemo(() => {
    return address ? address : emptyValue
  }, [address])

  return (
    <Spin spinning={getTransportationData.loading}>
      <S.Wrapper>
        {!getTransportationData.data?.transportData && <TransportationDataFill />}
        <TransportationDataInfo
          transportModes={getTransportModes}
          transportationArea={getTransportationArea}
          regions={getRegions}
          address={getAddress}
          isExistTransportationData={!!getTransportationData.data?.transportData}
        />
      </S.Wrapper>
    </Spin>
  )
}
