import { useQuery, UseQueryResult } from '@tanstack/react-query'

import {
  ElevatorAdvertApiElevatorAdvertControllerGetElevatorAdvertRequest,
  GetElevatorAdvertResponse,
} from '~api/generated'
import { useApi } from '~providers/api'

export const useGetElevatorAdvert = (
  request: ElevatorAdvertApiElevatorAdvertControllerGetElevatorAdvertRequest
): UseQueryResult<GetElevatorAdvertResponse> => {
  const api = useApi()

  const fetchFn = async (): Promise<GetElevatorAdvertResponse> => {
    const res = await api.elevatorAdvert.elevatorAdvertControllerGetElevatorAdvert(request)

    return res.data
  }

  return useQuery(['elevatorAdvert', request.id], fetchFn, { enabled: !!request.id })
}
