import styled from 'styled-components'

import { breakpoints } from '~/tokens'
import { Card } from '~/ui-components'

export const Content = styled(Card.Content)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 24px;
  grid-row-gap: 16px;
  margin-top: 32px;

  @media (max-width: ${breakpoints.MOBILE_XL}px) {
    grid-template-columns: 1fr;
    grid-row-gap: 4px;
    margin-top: 16px;
  }
`
