import React, { useEffect, useState } from 'react'

import { Button, Modal, Radio, RadioChangeEvent } from 'antd'

import { FormField } from '~/ui-components'
import { UpdateDealCarrierBodyCarriageByEnum } from '~api/generated'
import { useGetDevice } from '~hooks/common'

import * as S from './SelectCarrierSide.styled'

interface ISelectCarrierSideProps {
  currentValue: UpdateDealCarrierBodyCarriageByEnum | null
  isLoading: boolean
  onSelect: (value: UpdateDealCarrierBodyCarriageByEnum) => void
}

export const SelectCarrierSide: React.FC<ISelectCarrierSideProps> = ({ onSelect, isLoading, currentValue }) => {
  const device = useGetDevice()
  const isMobile = device === 'mobile'

  const [carrierSide, setCarrierSide] = useState<UpdateDealCarrierBodyCarriageByEnum | null>(currentValue)

  useEffect(() => setCarrierSide(currentValue), [currentValue])

  const onChangeHandler = (evt: RadioChangeEvent) => setCarrierSide(evt.target.value)

  const handleSubmitConfirm = () => {
    Modal.confirm({
      title: 'Вы действительно хотите изменить перевозчика?',
      okText: 'Да',
      cancelText: 'Отмена',
      onOk() {
        onSubmitHandler()
      },
    })
  }

  const onSubmitHandler = () => {
    if (!carrierSide) {
      return
    }

    onSelect(carrierSide)
  }

  return (
    <S.Content>
      <FormField label="Укажите, кто будет заниматься перевозкой">
        <Radio.Group
          value={carrierSide}
          onChange={onChangeHandler}
          optionType="button"
          buttonStyle="solid"
          disabled={isLoading}
          size={isMobile ? 'small' : 'middle'}
        >
          <Radio.Button value={UpdateDealCarrierBodyCarriageByEnum.Seller}>Продавец</Radio.Button>
          <Radio.Button value={UpdateDealCarrierBodyCarriageByEnum.Buyer}>Покупатель</Radio.Button>
          <Radio.Button value={UpdateDealCarrierBodyCarriageByEnum.Intermediary}>Зерно-Агрегатор</Radio.Button>
        </Radio.Group>
      </FormField>

      <div>
        <Button type="primary" onClick={handleSubmitConfirm} loading={isLoading}>
          Подтвердить
        </Button>
      </div>
    </S.Content>
  )
}
