import { useMutation } from '@tanstack/react-query'

import {
  AdminCreateElevatorAdvertResponse,
  ElevatorAdvertApiAdminCreateElevatorAdvertBuyRequest,
} from '~/api/generated'
import { useApi } from '~providers/api'

export const useCreateElevatorAdvertBuyByAdmin = () => {
  const api = useApi()

  const useCreateElevatorAdvertFn = async (
    request: ElevatorAdvertApiAdminCreateElevatorAdvertBuyRequest
  ): Promise<AdminCreateElevatorAdvertResponse> => {
    const res = await api.elevatorAdvert.adminCreateElevatorAdvertBuy(request)

    return res.data
  }

  return useMutation(useCreateElevatorAdvertFn)
}
