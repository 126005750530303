import { ElevatorOfferResponseRejectionReason } from '~api/generated'

export function getElevatorAdvertOfferRejectReason(v: ElevatorOfferResponseRejectionReason): string {
  switch (v) {
    case ElevatorOfferResponseRejectionReason.PriceDoesNotFit:
      return 'Не подходит цена'
    case ElevatorOfferResponseRejectionReason.QualityDoesNotFit:
      return 'Не подходит качество'
    case ElevatorOfferResponseRejectionReason.Other:
      return 'Другое'
  }
}
