import React, { FC } from 'react'

import { DatePicker as AntdDatePicker } from 'antd'
import { PickerProps } from 'antd/es/date-picker/generatePicker'
import { RangePickerProps } from 'antd/es/date-picker/generatePicker/interface'
import ruLocale from 'antd/es/date-picker/locale/ru_RU'
import dateFnsGenerateConfig from 'rc-picker/lib/generate/dateFns'

// import 'antd/es/date-picker/style/index'

ruLocale.lang.locale = 'ru'

const DatePickerDateFns = AntdDatePicker.generatePicker<Date>(dateFnsGenerateConfig)
export type DatePickerProps = PickerProps<Date> & { status?: '' | 'warning' | 'error' | undefined }
export type RangeDatePickerProps = RangePickerProps<Date> & { status?: '' | 'warning' | 'error' | undefined }

export const DatePicker: FC<DatePickerProps> = React.forwardRef<null, DatePickerProps>((props, ref) => (
  <DatePickerDateFns ref={ref} format="DD MMMM YYYY" {...props} locale={ruLocale} style={{ width: '100%' }} />
))

export const RangeDatePicker: FC<RangeDatePickerProps> = React.forwardRef<null, RangeDatePickerProps>((props, ref) => (
  <DatePickerDateFns.RangePicker
    ref={ref}
    format="DD MMMM YYYY"
    {...props}
    locale={ruLocale}
    style={{ width: '100%' }}
  />
))

DatePicker.displayName = 'DatePicker'
RangeDatePicker.displayName = 'RangeDatePicker'
