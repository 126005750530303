import React from 'react'

import { useOutlet } from 'react-router-dom'

import { ResponsePageHeader, UserCompanySelect } from '~shared/components'

import * as S from './AccountMenuMobile.styled'

// this is submenu of main mobile menu
export const AccountSelectCompanyMenuMobile: React.FC = () => {
  const outlet = useOutlet()

  if (outlet) {
    return outlet
  }

  return (
    <div>
      <div style={{ padding: 16 }}>
        <ResponsePageHeader variant="view" title="Все компании" subTitle="Компания" />
      </div>

      <UserCompanySelect isShowOnlyCompanyList />
    </div>
  )
}
