import styled from 'styled-components'

import { Text } from '~/ui-components'

export const ImageManual = styled.img`
  width: 100%;
  height: auto;
  object-fit: cover;
`
export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`

export const DescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`

export const DescriptionBold = styled(Text).attrs({ variant: 'normal-bold' })``

export const TextGreen = styled.span`
  color: var(--primary-main-color);
`
export const Actions = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
`
