import { Button, Flex } from 'antd'
import styled from 'styled-components'

export const Wrapper = styled(Flex)`
  width: 70%;
  padding: 24px;
`

export const FlexItem = styled(Flex)`
  padding-bottom: 8px;
  border-bottom: 1px solid var(--gray-2-color);
`

export const StyledButton = styled(Button)`
  background: var(--gray-2-color);
  color: var(--gray-5-color);
`
