import React from 'react'

import { Button } from 'antd'
import { FiEdit2 } from 'react-icons/fi'

import { Card, Text } from '~/ui-components'
import { BaseDealAdditionalServiceDataTypeTypeEnum as ServiceItemEnum } from '~api/generated'
import { getTitleByServiceName } from '~pages/Deals/components/DealAdditionalServicesBuyer/_utils'

import { DealAdditionalServicesAdminViewProps } from '~pages/ModeratorPage/components/ModeratorDeals/components/DealAdditionalServicesAdmin/components/DealAdditionalServicesAdminView/DealAdditionalServicesAdminView.types'

import * as S from './DealAdditionalServicesAdminView.styled'

export const DealAdditionalServicesAdminView: React.FC<DealAdditionalServicesAdminViewProps> = ({
  value,
  onChange,
  fromAddress,
  toAddress,
  volume,
}) => {
  const withoutServices = value.find((service) => service.type === ServiceItemEnum.EmptyService)?.isSelected
  const selectedServices = value.filter((service) => service.isSelected)

  const handleChange = () => {
    onChange()
  }

  const shippingPrice = value.find((x) => x.type === ServiceItemEnum.Shipping)?.price ?? null
  const shippingDistance = value.find((x) => x.type === ServiceItemEnum.Shipping)?.distance ?? null
  const servicePrice = value.find((x) => x.type === ServiceItemEnum.Service)?.price ?? null

  const renderUserSelectedServices = () => {
    if (!value?.length) {
      return <Text variant="normal">Пользователь пока не выбрал дополнительных услуг.</Text>
    } else if (withoutServices) {
      return (
        <div>
          <Text variant="h4">Пользователь отказался от доп. услуг</Text>
          <br />
          <Text variant="description">Свяжитесь с покупателем по телефону для подтверждения.</Text>
        </div>
      )
    } else {
      return (
        <div>
          <Text variant="h4">Пользователь выбрал:</Text>
          <S.ServiceListWrapper>
            {selectedServices.map((service) => (
              <Text variant="label" key={service.type}>
                {getTitleByServiceName(service.type)}
              </Text>
            ))}
          </S.ServiceListWrapper>
          <br />
          <Text variant="description">Свяжитесь с покупателем по телефону для подтверждения выбора услуг.</Text>
        </div>
      )
    }
  }

  return (
    <Card>
      <Card.Header>
        <Card.Title>Доп. услуги по сделке</Card.Title>
        <Card.ActionsContent>
          <Button size="small" type="text" icon={<FiEdit2 size={24} />} onClick={handleChange} />
        </Card.ActionsContent>
      </Card.Header>

      <Card.Content>
        <S.AgentFormWrapper>
          <S.AgentFormTitle>Откуда</S.AgentFormTitle>
          <S.AgentFormValue>{fromAddress}</S.AgentFormValue>

          <S.AgentFormTitle>Куда</S.AgentFormTitle>
          <S.AgentFormValue>{toAddress}</S.AgentFormValue>

          <S.AgentFormTitle>Объем</S.AgentFormTitle>
          <S.AgentFormValue>{volume} тонн</S.AgentFormValue>
        </S.AgentFormWrapper>

        <S.ServicePriceWrapper>
          <Text variant="label-bold">Организация перевозки агентом</Text>
          <Text variant="normal">{shippingPrice ? `${shippingPrice} р/т (${shippingDistance} км)` : '-'}</Text>
          <Text variant="description">Цена указана за одну тонну от всего перевезенного объеъма</Text>
        </S.ServicePriceWrapper>

        <Text variant="label-bold">Независимый анализ</Text>
        <Text variant="normal">{servicePrice ? `${servicePrice} р` : '-'}</Text>

        {renderUserSelectedServices()}
      </Card.Content>
    </Card>
  )
}
