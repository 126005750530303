import React from 'react'

import { Button } from 'antd'
import { Controller, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

import { useMutation } from '@apollo/client'
import { ApolloError } from '@apollo/client/errors'
import { yupResolver } from '@hookform/resolvers/yup'

import { AppHeader } from '~/layout'
import { appRoutes } from '~/router'
import { FormField } from '~/ui-components'
import { appToast } from '~/utils'
import { graphqlErrorHandler } from '~/utils/graphqlErrorHandler'
import { createTransportationDataMutation } from '~api/gql-mutations/car-park.mutation.graphql'
import { getTransportationDataQuery } from '~api/gql-query/car-park.query.graphql'
import { useAuth } from '~providers/auth'
import {
  AddressControlDadata,
  RegionsSelectControl,
  TransportationAreaSelectControl,
  TransportModesSelectControl,
} from '~shared/controls'

import { CreateTransportationDataSchema, CreateTransportationDataValues } from './CreateTransportationData.types'

import * as S from './CreateTransportationData.styled'

export const CreateTransportationData: React.FC = () => {
  const navigate = useNavigate()
  const { companyId } = useAuth()

  const [createTransportationData] = useMutation(createTransportationDataMutation, {
    refetchQueries: [getTransportationDataQuery],
  })

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<CreateTransportationDataValues>({
    resolver: yupResolver(CreateTransportationDataSchema),
  })

  const onCreateTransportationData = async (formValues: CreateTransportationDataValues) => {
    try {
      await createTransportationData({
        variables: {
          input: {
            companyId: companyId,
            transportModes: formValues.transportModes,
            transportationArea: formValues.transportationArea ?? null,
            regionIds: formValues.regions ?? [],
            address: formValues.address,
          },
        },
      })
      appToast.success({ message: 'Транспортные данные добавлены' })
      navigate(appRoutes.carParkTransportationData.url)
    } catch (e) {
      if (e instanceof ApolloError) {
        graphqlErrorHandler(e, 'Ошибка при добавлении транспортных данных')
      }
    }
  }

  return (
    <>
      <AppHeader title={appRoutes.carParkCreateTransportationData.title} isBack />

      <S.Wrapper>
        <S.StyledForm>
          <FormField
            label="Виды грузоперевозок"
            htmlFor="transportModes"
            validateMessage={errors.transportModes?.message}
          >
            <Controller
              name="transportModes"
              control={control}
              render={({ field }) => <TransportModesSelectControl {...field} hasError={!!errors.transportModes} />}
            />
          </FormField>

          <FormField
            label="Территория грузоперевозок"
            htmlFor="transportationArea"
            validateMessage={errors.transportationArea?.message}
          >
            <Controller
              name="transportationArea"
              control={control}
              render={({ field }) => (
                <TransportationAreaSelectControl {...field} hasError={!!errors.transportationArea} />
              )}
            />
          </FormField>

          <FormField label="Регионы грузоперевозок" htmlFor="regions" validateMessage={errors.regions?.message}>
            <Controller
              name="regions"
              control={control}
              render={({ field }) => <RegionsSelectControl {...field} mode="multiple" hasError={!!errors.regions} />}
            />
          </FormField>

          <FormField label="Адрес парка" htmlFor="address" validateMessage={errors.address?.message} isRequired>
            <Controller
              name="address"
              control={control}
              render={({ field: { value, onChange } }) => (
                <AddressControlDadata
                  id="address"
                  placeholder="Введите адрес"
                  value={value ?? undefined}
                  onChange={(val) => onChange(val?.value)}
                  hasError={!!errors.address}
                />
              )}
            />
          </FormField>

          <S.ActionWrapper>
            <S.StyledButton type="default" htmlType="button" onClick={() => navigate(-1)}>
              Отмена
            </S.StyledButton>
            <Button type="primary" htmlType="button" onClick={handleSubmit(onCreateTransportationData)}>
              Сохранить
            </Button>
          </S.ActionWrapper>
        </S.StyledForm>
      </S.Wrapper>
    </>
  )
}
