import styled from 'styled-components'

import { breakpoints } from '~/tokens'
import { Text } from '~/ui-components'

export const Wrapper = styled.div`
  display: flex;
  gap: 8px;
`

export const InfoText = styled(Text)`
  color: var(--gray-8-color);
  display: block;

  @media (max-width: ${breakpoints.MOBILE_L}px) {
    display: none;
  }
`

export const Time = styled(Text)`
  color: var(--primary-main-color);
`
