import React from 'react'

import { Flex } from 'antd'
import { Controller } from 'react-hook-form'

import { FormField, Text } from '~/ui-components'
import { TransportFormatTypeSelectControl } from '~shared/controls'
import { TransportBodyTypesSelectControl } from '~shared/controls/TransportBodyTypesSelectControl'

import { CreateLoadFormFieldProps } from '~pages/LogisticsPage/modules/MyCargos/components/CreateLoad/CreateLoad.types'

export const Transport: React.FC<CreateLoadFormFieldProps> = ({ control, errors }) => {
  return (
    <Flex vertical gap={32} style={{ width: '40%' }}>
      <Text variant="h1">Транспорт</Text>

      <Flex vertical gap={8}>
        <FormField
          label="Тип транспорта"
          htmlFor="transportFormatTypes"
          validateMessage={errors.transportFormatTypes?.message}
        >
          <Controller
            name="transportFormatTypes"
            control={control}
            render={({ field }) => (
              <TransportFormatTypeSelectControl {...field} hasError={!!errors.transportFormatTypes} />
            )}
          />
        </FormField>

        <FormField
          label="Тип кузова"
          htmlFor="transportBodyTypeIds"
          validateMessage={errors.transportBodyTypeIds?.message}
        >
          <Controller
            name="transportBodyTypeIds"
            control={control}
            render={({ field }) => (
              <TransportBodyTypesSelectControl {...field} mode="multiple" hasError={!!errors.transportBodyTypeIds} />
            )}
          />
        </FormField>
      </Flex>
    </Flex>
  )
}
