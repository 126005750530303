import React, { useState } from 'react'

import { Modal, Spin } from 'antd'

import { props } from '@styled-system/should-forward-prop'

import { appToast, modalSharedSettings } from '~/utils'
import { BaseDealAdditionalServiceDataType, UpdateAdditionalServiceTypeEnum } from '~api/generated'
import { useUpdateDealAdditionalService } from '~hooks/deals'
import { DealAdditionalServicesAdminView } from '~pages/ModeratorPage/components/ModeratorDeals/components/DealAdditionalServicesAdmin/components/DealAdditionalServicesAdminView'

import { DealAdditionalServicesAdminForm } from '../DealAdditionalServicesAdminForm'

import { DealAdditionalServicesAdminFormValues } from '~pages/ModeratorPage/components/ModeratorDeals/components/DealAdditionalServicesAdmin/components/DealAdditionalServicesAdminForm/DealAdditionalServicesAdminForm.types'

interface DealAdditionalServicesAdminSectionProps {
  dealId: number | null
  additionalServices: BaseDealAdditionalServiceDataType[]
  fromAddress: string
  toAddress: string
  volume: number
  afterChanges: () => void
}
export const DealAdditionalServicesAdminSection: React.FC<DealAdditionalServicesAdminSectionProps> = ({
  dealId,
  additionalServices,
  afterChanges,
  fromAddress,
  toAddress,
  volume,
}) => {
  const [isEdit, setIsEdit] = useState(false)
  const updateDealAdditionalServiceFn = useUpdateDealAdditionalService()

  const handleSubmitChanges = async (formValues: DealAdditionalServicesAdminFormValues) => {
    await onUpdateAdditionalServicesHandler(formValues)
    afterChanges()
    setIsEdit(false)
  }

  const onUpdateAdditionalServicesHandler = async (value: DealAdditionalServicesAdminFormValues) => {
    if (!dealId) {
      appToast.error({ description: '[onUpdateAdditionalServicesHandler]: Deal id was not found' })
      return
    }

    try {
      await updateDealAdditionalServiceFn.mutateAsync({
        dealId,
        updateAdditionalServicesBody: {
          additionalServices: [
            {
              type: UpdateAdditionalServiceTypeEnum.Service,
              price: value.servicePrice,
              isSelected:
                additionalServices.find((x) => x.type === UpdateAdditionalServiceTypeEnum.Service)?.isSelected ?? false,
            },
            {
              type: UpdateAdditionalServiceTypeEnum.Shipping,
              price: value.shippingPrice,
              distance: value.distance,
              isSelected:
                additionalServices.find((x) => x.type === UpdateAdditionalServiceTypeEnum.Shipping)?.isSelected ??
                false,
            },
          ],
        },
      })
      appToast.success({ description: 'Доп. услуги по сделке обновлены' })
    } catch (e) {
      const description = 'Ошибка при обновлении Доп. услуги по сделке'
      console.error(description, e)
      appToast.error({ description })
    }
  }

  return (
    <Spin spinning={updateDealAdditionalServiceFn.isLoading}>
      {!isEdit ? (
        <DealAdditionalServicesAdminView
          value={additionalServices}
          onChange={() => setIsEdit(true)}
          fromAddress={fromAddress}
          toAddress={toAddress}
          volume={volume}
        />
      ) : (
        <DealAdditionalServicesAdminForm
          value={additionalServices}
          onSubmit={handleSubmitChanges}
          onCancel={() => setIsEdit(false)}
          fromAddress={fromAddress}
          toAddress={toAddress}
          volume={volume}
        />
      )}
    </Spin>
  )
}
