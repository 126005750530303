import { useQuery, UseQueryResult } from '@tanstack/react-query'

import { WarehouseData } from '~/api/generated'
import { useApi } from '~providers/api'

type Result = WarehouseData[]

export const useGetWarehouses = (companyId: number | undefined | null): UseQueryResult<Result> => {
  const api = useApi()

  async function getWarehouseList(companyId: number): Promise<Result> {
    try {
      const response = await api.warehouses.warehouseControllerGetWarehouses({
        companyId,
        orderBy: 'name',
      })

      return response.data.warehouses
    } catch (e) {
      console.error('[useGetWarehouses]: failed to fetch warehouse list')

      return []
    }
  }

  const fetchFn = async (): Promise<Result> => {
    if (!companyId) {
      console.error('[useGetWarehouses]: companyId was not passed')

      return []
    }

    try {
      const warehouses = await getWarehouseList(companyId)

      return warehouses
    } catch (error) {
      return Promise.reject(error)
    }
  }

  return useQuery(['fetchWarehouses', companyId], fetchFn, { enabled: !!companyId })
}
