import styled from 'styled-components'

import { breakpoints } from '~/tokens'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  gap: 24px;
  margin-bottom: 28px;
  padding: 24px;
  padding-bottom: 0;

  @media (max-width: ${breakpoints.MOBILE_XL}px) {
    gap: 16px;
    padding: 16px;
  }
`

export const ContentWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 250px 250px 250px;
  grid-column-gap: 32px;
  grid-row-gap: 24px;

  @media (max-width: ${breakpoints.DESKTOP_S}px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: ${breakpoints.MOBILE_XL}px) {
    grid-template-columns: 1fr;
  }
`

export const SameAddressText = styled.div`
  display: flex;
  gap: 6px;
  color: #2654ad;
  align-items: center;
  margin-top: 8px;
`
