import { useMutation } from '@tanstack/react-query'

import { CreateOfferBody, CreateOfferV2Response } from '~/api/generated'
import { useUploadFileAndGetId } from '~hooks/_utils'
import { useApi } from '~providers/api'

interface CreateOfferRequest {
  createOfferBody: CreateOfferBody
  isAddAddress?: boolean
  labAnalysis?: File
  companyId: number
}

export const useCreateOffer = () => {
  const api = useApi()
  const uploadLabAnalysisAndGetIdFn = useUploadFileAndGetId()

  const createOfferFn = async (createOfferRequest: CreateOfferRequest): Promise<CreateOfferV2Response> => {
    const { createOfferBody, isAddAddress, labAnalysis, companyId } = createOfferRequest

    const laboratoryAnalysisFileKey = labAnalysis ? await uploadLabAnalysisAndGetIdFn.mutateAsync(labAnalysis) : null

    const offerResponse = await api.offers.createOfferV2({
      createOfferV2Body: {
        userCompanyId: companyId,
        offer: {
          contactPerson: createOfferBody.contactPerson,
          type: createOfferBody.type,
          note: createOfferBody.note,
          price: createOfferBody.price,
          volume: createOfferBody.volume,
          includingNds: createOfferBody.includingNds,
          publishedAt: createOfferBody.publishedAt,
          shippingRequired: createOfferBody.shippingRequired,
        },
        product: {
          productData: createOfferBody.product,
          warehousePositionId: null,
          laboratoryAnalysisFileKey,
        },
        warehouse: {
          warehouseId: undefined,
          warehouseData: {
            address: createOfferBody.address,
          },
        },
      },
    })

    return offerResponse.data
  }

  return useMutation(createOfferFn)
}
