/**
 *
 * Для проверки, является ли строка целым числом
 */
export function isIntegerString(str: string | undefined | null): boolean {
  if (!str) {
    return false
  }
  const num = parseFloat(str)
  return Number.isInteger(num) && num.toString() === str
}
