import React from 'react'
import { TransportationArea } from '~api/gql-generated/graphql'
import { Select } from 'antd'
import { CustomControlInterface } from '~shared/controls/CustomControl.interface'
import { BaseSelectRef } from 'rc-select'
import { getTransportationAreaName } from '~/utils/car-park/get-transportation-area'

interface TransportationAreaSelectControlProps extends CustomControlInterface {
  id?: string
  value: TransportationArea | undefined
  onChange: (value: TransportationArea) => void
  hasError?: boolean
}

export const TransportationAreaSelectControl: React.FC<TransportationAreaSelectControlProps> = React.forwardRef<
  BaseSelectRef,
  TransportationAreaSelectControlProps
>(({ value, onChange, hasError }, ref) => {
  return (
    <Select
      ref={ref}
      allowClear
      style={{ width: '100%' }}
      placeholder="Выберите"
      defaultValue={value}
      onChange={onChange}
      status={!!hasError ? 'error' : undefined}
    >
      {Object.values(TransportationArea).map((transportationArea) => (
        <Select.Option key={transportationArea} value={transportationArea}>
          {getTransportationAreaName(transportationArea)}
        </Select.Option>
      ))}
    </Select>
  )
})
