import { CompanyDealDataStatusEnum } from '~api/generated'
import { RangeSliderValue } from '~shared/controls/RangeSliderControl'
import { RegionSelectControlValue } from '~shared/controls/RegionSelectControl'

export enum DealAccessibleStatusEnum {
  AGREEMENT = 'AGREEMENT',
  SIGNING = 'SIGNING',
  PAYMENT = 'PAYMENT',
  SHIPMENT = 'SHIPMENT',
  REJECTED = 'REJECTED',
  REALISED = 'REALISED',
}
export interface IDealsFilterForm {
  dealType: 'BUY' | 'SELL' | 'ALL'
  volume: RangeSliderValue
  price: RangeSliderValue
  status: DealAccessibleStatusEnum | undefined
  regionIds: RegionSelectControlValue
}

export interface IDealsFilterFormProps {
  defaultValues: IDealsFilterForm
  currentValues: IDealsFilterForm
  foundResults: number | null
  onSubmit: (values: IDealsFilterForm) => void
  onChange: (values: IDealsFilterForm) => void
  onResetToDefaultValues: () => void
}
