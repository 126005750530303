import React, { FC } from 'react'

import { Button, Input, Modal } from 'antd'
import { Controller, useForm } from 'react-hook-form'
import * as yup from 'yup'

import { yupResolver } from '@hookform/resolvers/yup'

import { FormField } from '~/ui-components'
import { modalSharedSettings } from '~/utils'
import { VALIDATORS_MSG } from '~shared/validations'

import { OfferPriceModalProps } from './OfferPriceModal.types'

const schema = yup.object({
  price: yup.number().nullable().required(VALIDATORS_MSG.required),
  size: yup.number().nullable().required(VALIDATORS_MSG.required),
})

const FORM_ID = 'offer-price-modal'

export const OfferPriceModal: FC<OfferPriceModalProps> = ({ onSubmit, visible, onCancel }) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      price: '',
      size: '',
    },
    resolver: yupResolver(schema),
  })

  return (
    <Modal
      {...modalSharedSettings}
      open={visible}
      onCancel={onCancel}
      title="Предложить цену"
      footer={[
        <Button key="cancel" htmlType="button" onClick={onCancel}>
          Отмена
        </Button>,
        <Button type="primary" key="submit" htmlType="submit" form={FORM_ID}>
          Предложить цену
        </Button>,
      ]}
    >
      <form onSubmit={handleSubmit(onSubmit)} id={FORM_ID}>
        <FormField label="Цена за 1 тонну" isRequired validateMessage={errors.price?.message}>
          <Controller
            name="price"
            control={control}
            render={({ field }) => (
              <Input {...field} id="price" type="number" placeholder="Введите ценур" status={errors.price && 'error'} />
            )}
          />
        </FormField>
        <FormField label="Объем в тоннах" isRequired validateMessage={errors.size?.message}>
          <Controller
            name="size"
            control={control}
            render={({ field }) => (
              <Input {...field} id="size" type="number" placeholder="100" status={errors.size && 'error'} />
            )}
          />
        </FormField>
      </form>
    </Modal>
  )
}
