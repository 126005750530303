import React from 'react'

import { Col, Flex, Input, InputNumber, Radio, Row, Space, Tooltip } from 'antd'
import { Controller } from 'react-hook-form'
import { FiHelpCircle } from 'react-icons/fi'

import { Text } from '~/ui-components'
import { getPreferredRouteTypeName } from '~/utils/car-park/get-preferred-route-type'
import { inputSharedSettings } from '~/utils/inputSharedSettings'
import { PreferredRouteType, TransportType } from '~api/gql-generated/graphql'
import { PhotosFields } from '~pages/CarParkPage/components/PhotosFields'
import { AxlesCountSelectControl } from '~shared/controls'
import { TransportBodyTypesSelectControl } from '~shared/controls/TransportBodyTypesSelectControl'
import { TransportLoadingMethodsSelectControl } from '~shared/controls/TransportLoadingMethodsSelectControl'

import { CreateTransportFormFieldProps } from '~pages/CarParkPage/modules/CreateTransport/CreateTransport.types'

import * as S from './CreateMainFields.styled'

export const CreateMainFields: React.FC<CreateTransportFormFieldProps> = ({
  control,
  errors,
  type,
  transportPhotosProps,
}) => {
  return (
    <Flex vertical gap={32} style={{ maxWidth: '708px' }}>
      <Text variant="h1">Основное</Text>

      <Row>
        <Col span={24}>{transportPhotosProps && <PhotosFields {...transportPhotosProps} />}</Col>
      </Row>

      <Row gutter={24}>
        <Col span={12}>
          <S.FormFieldItem label="Марка" htmlFor="brand" validateMessage={errors.brand?.message} isRequired>
            <Controller
              name="brand"
              control={control}
              render={({ field }) => (
                <Input
                  {...field}
                  style={{ maxWidth: '342px' }}
                  id="brand"
                  status={errors.brand && 'error'}
                  placeholder="Введите"
                />
              )}
            />
          </S.FormFieldItem>
        </Col>

        {type !== TransportType.Tractor && (
          <Col span={12}>
            <S.FormFieldItem
              label="Тип кузова"
              htmlFor="bodyTypeId"
              validateMessage={errors.bodyTypeId?.message}
              isRequired
            >
              <Controller
                name="bodyTypeId"
                control={control}
                render={({ field }) => (
                  <TransportBodyTypesSelectControl {...field} mode="single" hasError={!!errors.bodyTypeId} />
                )}
              />
            </S.FormFieldItem>
          </Col>
        )}

        <Col span={12}>
          <S.FormFieldItem
            label="Год выпуска"
            htmlFor="manufacturingDate"
            validateMessage={errors.manufacturingDate?.message}
          >
            <Controller
              name="manufacturingDate"
              control={control}
              render={({ field }) => (
                <InputNumber
                  {...field}
                  {...inputSharedSettings}
                  value={field.value ?? undefined}
                  style={{ width: '100%', maxWidth: '342px' }}
                  id="manufacturingDate"
                  placeholder="Введите год выпуска"
                  status={errors.manufacturingDate && 'error'}
                />
              )}
            />
          </S.FormFieldItem>
        </Col>

        <Col span={12}>
          <S.FormFieldItem label="VIN номер" htmlFor="vin" validateMessage={errors.vin?.message}>
            <Controller
              name="vin"
              control={control}
              render={({ field }) => (
                <Input
                  {...field}
                  style={{ maxWidth: '342px' }}
                  id="vin"
                  status={errors.vin && 'error'}
                  placeholder="Введите VIN"
                />
              )}
            />
          </S.FormFieldItem>
        </Col>

        <Col span={12}>
          <S.FormFieldItem
            label="Номер СТС"
            htmlFor="registrationCertificate"
            validateMessage={errors.registrationCertificate?.message}
          >
            <Controller
              name="registrationCertificate"
              control={control}
              render={({ field }) => (
                <Input
                  {...field}
                  style={{ maxWidth: '342px' }}
                  id="registrationCertificate"
                  status={errors.registrationCertificate && 'error'}
                  placeholder="AA00AA 777"
                />
              )}
            />
          </S.FormFieldItem>
        </Col>

        <Col span={12}>
          <S.FormFieldItem
            label="Серия и номер ПТС"
            htmlFor="transportPassport"
            validateMessage={errors.transportPassport?.message}
          >
            <Controller
              name="transportPassport"
              control={control}
              render={({ field }) => (
                <Input
                  {...field}
                  style={{ maxWidth: '342px' }}
                  id="transportPassport"
                  status={errors.transportPassport && 'error'}
                  placeholder="AA00AA 777"
                />
              )}
            />
          </S.FormFieldItem>
        </Col>

        <Col span={12}>
          <S.FormFieldItem
            label="Госномер"
            htmlFor="licensePlate"
            validateMessage={errors.licensePlate?.message}
            isRequired
          >
            <Controller
              name="licensePlate"
              control={control}
              render={({ field }) => (
                <Input
                  {...field}
                  style={{ maxWidth: '342px' }}
                  id="licensePlate"
                  status={errors.licensePlate && 'error'}
                  placeholder="AA00AA 777"
                />
              )}
            />
          </S.FormFieldItem>
        </Col>

        <Col span={12}>
          <S.FormFieldItem
            label="Количество осей"
            htmlFor="axlesCount"
            validateMessage={errors.axlesCount?.message}
            isRequired
          >
            <Controller
              name="axlesCount"
              control={control}
              render={({ field }) => (
                <AxlesCountSelectControl {...field} id="axlesCount" hasError={!!errors.axlesCount} />
              )}
            />
          </S.FormFieldItem>
        </Col>

        <Col span={12}>
          <S.FormFieldItem
            label="Грузоподъемность, т"
            htmlFor="loadCapacityKg"
            validateMessage={errors.loadCapacityKg?.message}
          >
            <Controller
              name="loadCapacityKg"
              control={control}
              render={({ field }) => (
                <InputNumber
                  {...field}
                  {...inputSharedSettings}
                  style={{ width: '100%', maxWidth: '342px' }}
                  id="loadCapacityKg"
                  placeholder="Введите"
                  status={errors.loadCapacityKg && 'error'}
                />
              )}
            />
          </S.FormFieldItem>
        </Col>

        <Col span={12}>
          <S.FormFieldItem label="Объем, м³" htmlFor="loadVolumeL" validateMessage={errors.loadVolumeL?.message}>
            <Controller
              name="loadVolumeL"
              control={control}
              render={({ field }) => (
                <InputNumber
                  {...field}
                  {...inputSharedSettings}
                  style={{ width: '100%', maxWidth: '342px' }}
                  id="loadVolumeL"
                  placeholder="Введите"
                  status={errors.loadVolumeL && 'error'}
                />
              )}
            />
          </S.FormFieldItem>
        </Col>

        {type !== TransportType.Tractor && (
          <Col span={12}>
            <S.FormFieldItem
              label="Способ погрузки"
              htmlFor="loadingMethodId"
              validateMessage={errors.loadingMethodId?.message}
            >
              <Controller
                name="loadingMethodId"
                control={control}
                render={({ field }) => (
                  <TransportLoadingMethodsSelectControl {...field} hasError={!!errors.loadingMethodId} />
                )}
              />
            </S.FormFieldItem>
          </Col>
        )}

        <Col span={12}>
          <S.FormFieldItem
            label="Разрешения ADR"
            htmlFor="adrCertificate"
            validateMessage={errors.adrCertificate?.message}
          >
            <Controller
              name="adrCertificate"
              control={control}
              render={({ field }) => (
                <Input
                  {...field}
                  style={{ maxWidth: '342px' }}
                  id="adrCertificate"
                  status={errors.adrCertificate && 'error'}
                  placeholder="Введите"
                />
              )}
            />
          </S.FormFieldItem>
        </Col>

        <Col span={24}>
          <Row gutter={24}>
            <Col span={8}>
              <S.FormFieldItem label="Длина, м" htmlFor="lengthCm" validateMessage={errors.lengthCm?.message}>
                <Controller
                  name="lengthCm"
                  control={control}
                  render={({ field }) => (
                    <InputNumber
                      {...field}
                      {...inputSharedSettings}
                      style={{ width: '100%', maxWidth: '342px' }}
                      id="lengthCm"
                      placeholder="Введите"
                      status={errors.lengthCm && 'error'}
                    />
                  )}
                />
              </S.FormFieldItem>
            </Col>

            <Col span={8}>
              <S.FormFieldItem label="Ширина, м" htmlFor="widthCm" validateMessage={errors.widthCm?.message}>
                <Controller
                  name="widthCm"
                  control={control}
                  render={({ field }) => (
                    <InputNumber
                      {...field}
                      {...inputSharedSettings}
                      style={{ width: '100%', maxWidth: '342px' }}
                      id="widthCm"
                      placeholder="Введите"
                      status={errors.widthCm && 'error'}
                    />
                  )}
                />
              </S.FormFieldItem>
            </Col>

            <Col span={8}>
              <S.FormFieldItem label="Высота, м" htmlFor="heightCm" validateMessage={errors.heightCm?.message}>
                <Controller
                  name="heightCm"
                  control={control}
                  render={({ field }) => (
                    <InputNumber
                      {...field}
                      {...inputSharedSettings}
                      style={{ width: '100%', maxWidth: '342px' }}
                      id="heightCm"
                      placeholder="Введите"
                      status={errors.heightCm && 'error'}
                    />
                  )}
                />
              </S.FormFieldItem>
            </Col>
          </Row>
        </Col>

        {type !== TransportType.Trailer && type !== TransportType.SemiTrailer && (
          <>
            <Col span={24}>
              <Row gutter={24} align="middle">
                <Col span={11}>
                  <S.FormFieldItem
                    label="Короткий рейс, км"
                    htmlFor="shortRouteKm"
                    validateMessage={errors.shortRouteKm?.message}
                  >
                    <Controller
                      name="shortRouteKm"
                      control={control}
                      render={({ field }) => (
                        <InputNumber
                          {...field}
                          {...inputSharedSettings}
                          style={{ width: '100%', maxWidth: '342px' }}
                          id="shortRouteKm"
                          placeholder="Введите"
                          status={errors.shortRouteKm && 'error'}
                        />
                      )}
                    />
                  </S.FormFieldItem>
                </Col>

                <Col span={11}>
                  <S.FormFieldItem
                    label="Дальний рейс, км"
                    htmlFor="longRouteKm"
                    validateMessage={errors.longRouteKm?.message}
                  >
                    <Controller
                      name="longRouteKm"
                      control={control}
                      render={({ field }) => (
                        <InputNumber
                          {...field}
                          {...inputSharedSettings}
                          style={{ width: '100%', maxWidth: '342px' }}
                          id="longRouteKm"
                          placeholder="Введите"
                          status={errors.longRouteKm && 'error'}
                        />
                      )}
                    />
                  </S.FormFieldItem>
                </Col>

                <Col span={2}>
                  <Tooltip
                    title={
                      <>
                        Короткий и дальний рейс позволит указать минимальное и максимальное «плечо» для данного авто,
                        заполнив их и указав предпочтение, система будет автоматически предлагать этот транспорт для
                        перевозок с подходящим расстоянием
                      </>
                    }
                  >
                    <FiHelpCircle size={20} color={'var(--gray-5-color)'} />
                  </Tooltip>
                </Col>
              </Row>
            </Col>

            <Col span={24}>
              <S.FormFieldItem
                label="Данное ТС больше подходит для"
                htmlFor="preferredRouteType"
                validateMessage={errors.preferredRouteType?.message}
              >
                <Controller
                  name="preferredRouteType"
                  control={control}
                  defaultValue={PreferredRouteType.Short}
                  render={({ field }) => (
                    <Radio.Group {...field}>
                      <Space size="large">
                        {[PreferredRouteType.Short, PreferredRouteType.Long].map((preferredRouteType) => (
                          <Radio key={preferredRouteType} value={preferredRouteType}>
                            {getPreferredRouteTypeName(preferredRouteType)}
                          </Radio>
                        ))}
                      </Space>
                    </Radio.Group>
                  )}
                />
              </S.FormFieldItem>
            </Col>
          </>
        )}

        <Col span={24}>
          <S.FormFieldItem
            label="Данное ТС подходит для работ в поле"
            htmlFor="canWorkInField"
            validateMessage={errors.canWorkInField?.message}
          >
            <Controller
              name="canWorkInField"
              control={control}
              defaultValue={true}
              render={({ field }) => (
                <Radio.Group {...field}>
                  <Space size="large">
                    <Radio value={true}>Да</Radio>
                    <Radio value={false}>Нет</Radio>
                  </Space>
                </Radio.Group>
              )}
            />
          </S.FormFieldItem>
        </Col>
      </Row>
    </Flex>
  )
}
