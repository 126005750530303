import React from 'react'

import {
  AuctionApi,
  AuthApi,
  CompanyApi,
  DealApi,
  DealDocumentApi,
  ElevatorAdvertApi,
  ElevatorAdvertOfferApi,
  ElevatorApi,
  FileApi,
  NotificationApi,
  OfferApi,
  OrderApi,
  ProductApi,
  UserApi,
  WarehouseApi,
} from '~/api/generated'

export interface IApiContext {
  auth: AuthApi
  users: UserApi
  companies: CompanyApi
  warehouses: WarehouseApi
  products: ProductApi
  offers: OfferApi
  deal: DealApi
  dealDocument: DealDocumentApi
  order: OrderApi
  auction: AuctionApi
  notifications: NotificationApi
  file: FileApi
  elevators: ElevatorApi
  elevatorAdvert: ElevatorAdvertApi
  elevatorAdvertOffer: ElevatorAdvertOfferApi
}

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
export const ApiContext = React.createContext<IApiContext>(undefined!)
