import React, { PropsWithChildren } from 'react'

import { NoUndefinedRangeValueType } from 'rc-picker/lib/PickerInput/RangePicker'

import { CustomControlInterface } from '~shared/controls/CustomControl.interface'
import { RangeDatePicker, RangeDatePickerProps } from '~ui-components/components/DatePicker/DatePicker'

type RangeValue = [start: Date | null, end: Date | null]

type Value = RangeValue

interface IRangeDatePickerControlProps extends CustomControlInterface {
  onChange: (value: Value) => void
  value: Value
  hasError?: boolean
  id?: string
  datePickerProps?: RangeDatePickerProps
}

export const RangeDatePickerControl: React.FC<PropsWithChildren<IRangeDatePickerControlProps>> = React.forwardRef<
  HTMLInputElement,
  PropsWithChildren<IRangeDatePickerControlProps>
>(({ datePickerProps, id, value, onChange, hasError }, ref) => {
  const onChangeHandler = (values: NoUndefinedRangeValueType<Date> | null, _dateStrings: [string, string]) => {
    onChange(values && values[0] && values[1] ? [values[0], values[1]] : [null, null])
  }

  return (
    <RangeDatePicker
      {...datePickerProps}
      id={id}
      value={value}
      onChange={onChangeHandler}
      status={hasError ? 'error' : undefined}
    />
  )
})

RangeDatePickerControl.displayName = 'RangeDatePickerControl'
