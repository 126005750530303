import React, { FC } from 'react'

import { FormFieldProps } from '~/ui-components'

import * as S from './FormField.styled'

export const FormField: FC<FormFieldProps> = ({
  validateMessage,
  label,
  htmlFor,
  children,
  isRequired,
  isDisabled,
  isVisible = true,
  grow = true,
  ...props
}) => (
  <S.Wrapper isVisible={isVisible} grow={grow} {...props}>
    {label && (
      <S.Label variant="label-bold" as="label" htmlFor={htmlFor} className="label">
        {label} {isRequired && !isDisabled && <S.Required>*</S.Required>}
      </S.Label>
    )}
    <div>{children}</div>
    {validateMessage !== null && (
      <S.Message variant="description" className="validate-message">
        {validateMessage}
      </S.Message>
    )}
  </S.Wrapper>
)
