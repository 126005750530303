import React from 'react'

import { Modal, Select } from 'antd'

import { getDealStatusName } from '~/utils/deals/get-deal-status-name'
import { enumToArray } from '~/utils/enum-to-array'
import { UpdateDealStatusBodyStatusEnum } from '~api/generated'

import * as S from './DealStatusSelect.styled'

interface IDealStatusSelectProps {
  defaultValue?: UpdateDealStatusBodyStatusEnum
  label: string
  onChange: (status: UpdateDealStatusBodyStatusEnum) => void
  nextStatuses?: UpdateDealStatusBodyStatusEnum[]
}

export const DealStatusSelect: React.FC<IDealStatusSelectProps> = ({
  nextStatuses = [],
  defaultValue,
  label,
  onChange,
}) => {
  const options = enumToArray(UpdateDealStatusBodyStatusEnum)
    .filter((option) => nextStatuses.includes(option as UpdateDealStatusBodyStatusEnum) || option === defaultValue)
    .map((option) => (
      <Select.Option key={option} value={option}>
        {getDealStatusName(option as UpdateDealStatusBodyStatusEnum)}
      </Select.Option>
    ))

  const showChangeStatusConfirm = (newStatus: UpdateDealStatusBodyStatusEnum) => {
    Modal.confirm({
      title: 'Вы действительно хотите изменить статус сделки?',
      content: (
        <>
          Статус будет изменен на <b>{getDealStatusName(newStatus)}</b>
        </>
      ),
      okText: 'Изменить',
      cancelText: 'Отмена',
      onOk() {
        handleChangeStatus(newStatus)
      },
    })
  }

  const handleChangeStatus = (selectedStatus: UpdateDealStatusBodyStatusEnum) => {
    onChange(selectedStatus)
  }

  return (
    <S.Wrapper>
      <div>{label}</div>
      <Select value={defaultValue} style={{ width: '100%' }} onSelect={showChangeStatusConfirm}>
        {options}
      </Select>
    </S.Wrapper>
  )
}
