import React, { FC } from 'react'

import { Modal } from 'antd'

import { modalSharedSettings } from '~/utils'
import { getDealDocumentName } from '~/utils/deals'
import { DocumentCardController } from '~shared/components'

import { ModalDocumentInstancesProps } from './ModalDocumentInstances.types'

import * as S from './ModalDocumentInstances.styled'

export const ModalDocumentInstances: FC<ModalDocumentInstancesProps> = ({
  dealId,
  document,
  open,
  isAddFile,
  isShowRemoveForAdmin,
  canChange,
  documentsTypes,
  onShow,
  onAddFile,
  onAddFileAfter,
  onRemove,
  onReplaceFile,
  onCancel,
}) => {
  return (
    <>
      <Modal
        title={getDealDocumentName(document.type)}
        open={open}
        onCancel={onCancel}
        {...modalSharedSettings}
        footer={false}
      >
        <S.Content>
          {document.instances.map((instance, i) => (
            <DocumentCardController
              dealId={dealId}
              documentInstance={instance}
              key={instance.id}
              index={i}
              type={document.type}
              canChange={canChange}
              isShowRemoveForAdmin={isShowRemoveForAdmin}
              documentsTypes={documentsTypes}
              onAddFileAfter={onAddFileAfter}
              onShow={() => onShow(instance.id)}
              onRemove={() => onRemove(instance.id)}
              onReplaceFile={() => onReplaceFile(instance.id)}
            />
          ))}
          {isAddFile && <S.AddFile onClick={onAddFile}>Добавить файл</S.AddFile>}
        </S.Content>
      </Modal>
    </>
  )
}
