import { useMutation } from '@tanstack/react-query'

import {
  AdminUpdateElevatorOfferResponseResponse,
  ElevatorAdvertOfferApiAdminUpdateElevatorOfferResponseRequest,
} from '~api/generated'
import { useApi } from '~providers/api'

export const useEditElevatorAdvertOffer = () => {
  const api = useApi()

  const fn = async (
    request: ElevatorAdvertOfferApiAdminUpdateElevatorOfferResponseRequest
  ): Promise<AdminUpdateElevatorOfferResponseResponse> => {
    const res = await api.elevatorAdvertOffer.adminUpdateElevatorOfferResponse(request)

    return res.data
  }

  return useMutation(fn)
}
