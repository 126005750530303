import React, { useState } from 'react'

import { Button, Flex, Modal, Radio, Space } from 'antd'

import { Text } from '~/ui-components'
import { modalSharedSettings } from '~/utils'
import { ReactComponent as BasisIcon } from '~assets/icons/basis.svg'
import { CreateElevatorFormStep1 } from '~pages/SettingsPage/modules/ElevatorsAdmin/components/CreateElevatorForm'
import {
  CreateBasisFormValue,
  CreateElevatorModal,
} from '~pages/SettingsPage/modules/ElevatorsAdmin/components/CreateElevatorModal/CreateElevatorModal'

import { CreateElevatorFormStep1Values } from '~pages/SettingsPage/modules/ElevatorsAdmin/components/CreateElevatorForm/step1/CreateElevatorFormStep1.types'

import * as S from './CreateBasisModal.styled'

interface CreateBasisModal {
  open: boolean
  loading: boolean
  onCancel: () => void
  onSubmitForOtherCompany: (formValues: CreateElevatorFormStep1Values) => void
  onSubmitForMyCompany: (formValues: CreateBasisFormValue) => void
}
type Step = 'intro' | 'selectType' | 'form'

type BasisType = 'forMyCompany' | 'forOtherCompany'
export const CreateBasisByUserModal: React.FC<CreateBasisModal> = (props) => {
  const [step, setStep] = useState<Step>('intro')
  const [typeOfBasis, setTypeOfBasis] = useState<BasisType | null>(null)

  const onCancelHandler = () => {
    setStep('intro')
    setTypeOfBasis(null)
    props.onCancel()
  }

  const renderContent = () => {
    switch (step) {
      case 'intro':
        return (
          <>
            <BasisIcon />
            <Text variant="normal">
              Базис - <b>точка приемки</b> продукции или <b>элеватор</b>, на базе которого будут отторговываться объемы.
              <br />
              <br />
              Через раздел “Базисы” вы сможете найти культуру или разместить свою, а другие пользователи смогут
              откликнуться на ваше предложение.
            </Text>
            <br />
            <Flex gap="small" justify="flex-end">
              <Button type="default" onClick={onCancelHandler} htmlType="button">
                Отмена
              </Button>
              <Button type="primary" onClick={() => setStep('selectType')} htmlType="button">
                Далее
              </Button>
            </Flex>
          </>
        )
      case 'selectType':
        return (
          <>
            <Radio.Group onChange={(x) => setTypeOfBasis(x.target.value as BasisType)} style={{ marginBottom: 24 }}>
              <Space direction="vertical">
                <S.StyledRadio value={'forMyCompany' as BasisType}>
                  <Flex vertical gap="small">
                    <Text variant="normal-bold">Вы - представитель базиса</Text>
                    <Text variant="normal">
                      Заполните подробную информацию о вашем базисе. Мы проверим информацию и ваш базис появится на
                      платформе.
                    </Text>
                  </Flex>
                </S.StyledRadio>
                <S.StyledRadio value={'forOtherCompany' as BasisType}>
                  <Flex vertical gap="small">
                    <Text variant="normal-bold">Базис принадлежит не вам</Text>
                    <Text variant="normal">
                      Заполните краткую информацию о базисе. Мы далее свяжемся с компанией, которой принадлежит базис,
                      для проверки и уточнения дополнительной информации.
                    </Text>
                  </Flex>
                </S.StyledRadio>
              </Space>
            </Radio.Group>

            <Flex gap="small" justify="flex-end">
              <Button type="default" onClick={onCancelHandler} htmlType="button">
                Отмена
              </Button>
              <Button type="primary" disabled={!typeOfBasis} onClick={() => setStep('form')} htmlType="button">
                Далее
              </Button>
            </Flex>
          </>
        )
    }
  }

  if (step === 'form') {
    switch (typeOfBasis) {
      case 'forMyCompany':
        return (
          <CreateElevatorModal
            open={props.open}
            onCancel={onCancelHandler}
            onSubmit={props.onSubmitForMyCompany}
            lastStepSubmitLoading={props.loading}
          />
        )
      case 'forOtherCompany':
        return (
          <Modal
            {...modalSharedSettings}
            width={500}
            open={props.open}
            onCancel={onCancelHandler}
            title="Заявка на добавление"
          >
            <CreateElevatorFormStep1
              onNext={props.onSubmitForOtherCompany}
              nextButtonText="Отправить заявку"
              onCancel={onCancelHandler}
              loading={props.loading}
            />
          </Modal>
        )
    }
  }

  return (
    <Modal
      {...modalSharedSettings}
      width={500}
      open={props.open}
      onCancel={onCancelHandler}
      title="Добавление базиса на платформу"
      footer={null}
    >
      {renderContent()}
    </Modal>
  )
}
