import { useMutation } from '@tanstack/react-query'

import { AuctionApiAuctionControllerUpdateAuctionOfferStatusRequest } from '~/api/generated'
import { appToast } from '~/utils'
import { useApi } from '~providers/api'

export const useUpdateAuctionOfferStatus = () => {
  const api = useApi()

  const updateFn = async (request: AuctionApiAuctionControllerUpdateAuctionOfferStatusRequest) => {
    try {
      await api.auction.auctionControllerUpdateAuctionOfferStatus(request)
      appToast.success()
    } catch (err) {
      appToast.error({
        message: 'Ошибка, обратитесь к администратору',
      })
      return Promise.reject(err)
    }
  }

  return useMutation(updateFn)
}
