import { useQuery, UseQueryOptions } from '@tanstack/react-query'

import {
  AuctionApiAuctionControllerGetCompanyAuctionOffersRequest,
  GetCompanyAuctionOffersResponse,
} from '~/api/generated'
import { useApi } from '~/providers/api'
import { isApiResponseEmpty } from '~/utils/isApiResponseEmpty'

type Result = GetCompanyAuctionOffersResponse | null

export const useGetCompanyAuctionOffers = (
  request: AuctionApiAuctionControllerGetCompanyAuctionOffersRequest,
  options?: Omit<UseQueryOptions<Result, Error>, 'queryKey' | 'queryFn'>
) => {
  const api = useApi()

  const fetchFn = async (): Promise<Result> => {
    if (!request.companyId) return null

    try {
      const response = await api.auction.auctionControllerGetCompanyAuctionOffers(request)
      return response.data
    } catch (error) {
      if (isApiResponseEmpty(error)) {
        return null
      }

      return Promise.reject(error)
    }
  }

  return useQuery(['companyAuctionOffers', request.companyId, request.currentPage, request.pageSize], fetchFn, options)
}
