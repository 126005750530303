/* tslint:disable */
/* eslint-disable */
/**
 * API Зерно-Агрегатора
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { BaseDealAdditionalServiceDataType } from './base-deal-additional-service-data-type';
import { BaseOfferData } from './base-offer-data';
import { DealBankAccountData } from './deal-bank-account-data';
import { DealSpecificationData } from './deal-specification-data';

/**
 * 
 * @export
 * @interface CompanyDealData
 */
export interface CompanyDealData {
    /**
     * 
     * @type {number}
     * @memberof CompanyDealData
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof CompanyDealData
     */
    'mainDealId': number;
    /**
     * 
     * @type {BaseOfferData}
     * @memberof CompanyDealData
     */
    'offer': BaseOfferData;
    /**
     * 
     * @type {string}
     * @memberof CompanyDealData
     */
    'status': CompanyDealDataStatusEnum;
    /**
     * Следующие возможные статусы
     * @type {Array<string>}
     * @memberof CompanyDealData
     */
    'nextStatuses': Array<CompanyDealDataNextStatusesEnum>;
    /**
     * 
     * @type {number}
     * @memberof CompanyDealData
     */
    'sellerId': number;
    /**
     * 
     * @type {number}
     * @memberof CompanyDealData
     */
    'buyerId': number;
    /**
     * Спецификация
     * @type {DealSpecificationData}
     * @memberof CompanyDealData
     */
    'specification': DealSpecificationData | null;
    /**
     * Реквизиты продавца
     * @type {DealBankAccountData}
     * @memberof CompanyDealData
     */
    'sellerBankAccount': DealBankAccountData | null;
    /**
     * Реквизиты покупателя
     * @type {DealBankAccountData}
     * @memberof CompanyDealData
     */
    'buyerBankAccount': DealBankAccountData | null;
    /**
     * Время начала сделки
     * @type {string}
     * @memberof CompanyDealData
     */
    'startedAt': string | null;
    /**
     * Дополнительные услуги по сделке
     * @type {Array<BaseDealAdditionalServiceDataType>}
     * @memberof CompanyDealData
     */
    'additionalServices': Array<BaseDealAdditionalServiceDataType>;
    /**
     * Дата создания сделки
     * @type {string}
     * @memberof CompanyDealData
     */
    'createdAt': string;
}

export const CompanyDealDataStatusEnum = {
    OnModeration: 'ON_MODERATION',
    RejectedAfterModeration: 'REJECTED_AFTER_MODERATION',
    Agreement: 'AGREEMENT',
    Signing: 'SIGNING',
    Payment: 'PAYMENT',
    Shipment: 'SHIPMENT',
    Rejected: 'REJECTED',
    Realised: 'REALISED'
} as const;

export type CompanyDealDataStatusEnum = typeof CompanyDealDataStatusEnum[keyof typeof CompanyDealDataStatusEnum];
export const CompanyDealDataNextStatusesEnum = {
    OnModeration: 'ON_MODERATION',
    RejectedAfterModeration: 'REJECTED_AFTER_MODERATION',
    Agreement: 'AGREEMENT',
    Signing: 'SIGNING',
    Payment: 'PAYMENT',
    Shipment: 'SHIPMENT',
    Rejected: 'REJECTED',
    Realised: 'REALISED'
} as const;

export type CompanyDealDataNextStatusesEnum = typeof CompanyDealDataNextStatusesEnum[keyof typeof CompanyDealDataNextStatusesEnum];


