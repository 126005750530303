import React from 'react'

import { Button, Dropdown, Modal } from 'antd'
import { ItemType } from 'antd/es/menu/interface'
import { observer } from 'mobx-react-lite'
import { FiEdit2, FiMoreVertical, FiTrash2 } from 'react-icons/fi'

import { appToast } from '~/utils'
import { getOfferStatus } from '~/utils/offers/get-offer-status'
import { OfferDataStatusEnum, UpdateOfferStatusBodyStatusEnum } from '~api/generated'
import { useUpdateOfferStatus } from '~hooks/offer'
import { useAuth } from '~providers/auth'

interface IOfferActionMenuProps {
  status: OfferDataStatusEnum
  offerId: number
  onSuccess?: () => void
  onChangePriceAndVolumeClick?: () => void
}

export const OfferActionMenu: React.FC<IOfferActionMenuProps> = observer((props) => {
  const { companyId } = useAuth()

  const updateOfferStatus = useUpdateOfferStatus()

  const handleUnpublish = async () => {
    try {
      await updateOfferStatus.mutateAsync({
        offerId: props.offerId,
        updateOfferStatusBody: {
          userCompanyId: companyId,
          status: UpdateOfferStatusBodyStatusEnum.Rejected,
        },
      })
      await props.onSuccess?.()
      appToast.success({ description: 'Ваше предложение снято с публикации' })
    } catch (e) {
      const description = 'Ошибка при отклонении предложения'
      console.error(description, e)
      appToast.error({ description })
    }
  }

  const unpublishConfirmHandler = () => {
    const canUnpublish =
      props.status === OfferDataStatusEnum.OnModeration || props.status === OfferDataStatusEnum.Published

    Modal[canUnpublish ? 'confirm' : 'info']({
      title: canUnpublish ? 'Снять с публикации?' : 'Невозможно снять с публикации',
      content: canUnpublish ? undefined : (
        <>
          Предложение со статусом <b>{getOfferStatus(props.status).name}</b> нельзя снять с публикации
        </>
      ),
      onOk: canUnpublish ? handleUnpublish : undefined,
      cancelText: 'Отмена',
      okText: canUnpublish ? 'Да' : 'Понятно',
    })
  }

  const isShowUnpublished = props.status === OfferDataStatusEnum.Published
  const isShowChangePriceAndVolume =
    props.onChangePriceAndVolumeClick &&
    // @ts-ignore
    [OfferDataStatusEnum.Published, OfferDataStatusEnum.Expired].includes(props.status)

  if (!isShowUnpublished && !isShowChangePriceAndVolume) return null

  const menuItems: ItemType[] = [
    {
      label: 'Опубликовать повторно',
      key: 'changePriceAndVolume',
      icon: <FiEdit2 />,
      onClick: props.onChangePriceAndVolumeClick,
      disabled: !isShowChangePriceAndVolume,
    },
    {
      label: 'Снять с публикации',
      key: 'unpublish',
      icon: <FiTrash2 />,
      onClick: unpublishConfirmHandler,
      disabled: !isShowUnpublished,
    },
  ]

  return (
    <>
      <Dropdown menu={{ items: menuItems }} trigger={['click']}>
        <Button size="small" type="text" icon={<FiMoreVertical size={24} />} />
      </Dropdown>
    </>
  )
})
