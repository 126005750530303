import { useMutation } from '@tanstack/react-query'

import { CreateWarehouseBody, CreateWarehousePositionBody, CreateWarehousePositionResponse } from '~/api/generated'
import { useUploadFileAndGetId } from '~hooks/_utils'
import { useApi } from '~providers/api'

interface CreateWarehouseRequest {
  info: CreateWarehouseBody
  culture: CreateWarehousePositionBody & { laboratoryAnalysis?: File }
  companyId: number
}

type WarehouseId = number

export const useCreateWarehouse = () => {
  const api = useApi()
  const uploadFileAndGetId = useUploadFileAndGetId()

  async function createWarehouseOrFail(companyId: number, data: CreateWarehouseBody): Promise<WarehouseId> {
    try {
      const createdWarehouse = await api.warehouses.warehouseControllerCreateWarehouse({
        companyId,
        createWarehouseBody: data,
      })

      return createdWarehouse.data.warehouseId
    } catch (e) {
      console.error('[useCreateWarehouse]: create warehouse info error', e)

      throw new Error((<Error>e)?.message ?? 'create warehouse info error')
    }
  }

  async function updateWarehouseWithCultureOrFail(
    warehouseId: number,
    data: CreateWarehousePositionBody & { laboratoryAnalysis?: File }
  ): Promise<CreateWarehousePositionResponse> {
    try {
      let laboratoryAnalysisFileKey: string | null = null
      if (data?.laboratoryAnalysis) {
        laboratoryAnalysisFileKey = await uploadFileAndGetId.mutateAsync(data.laboratoryAnalysis)
      }

      const createdWarehouseCulture = await api.warehouses.createWarehousePosition({
        warehouseId,
        createWarehousePositionBody: { ...data, laboratoryAnalysisFileKey },
      })

      return createdWarehouseCulture.data
    } catch (e) {
      console.error('[useCreateWarehouse]: create warehouse culture parameters error', e)

      throw new Error((<Error>e)?.message ?? 'create warehouse culture parameters error')
    }
  }

  const createWarehouseFn = async ({ info, culture, companyId }: CreateWarehouseRequest): Promise<number | void> => {
    if (!companyId) {
      const msg = '[useCreateWarehouse]: companyId was not passed'
      console.error(msg)

      return Promise.reject(msg)
    }

    let warehouseId: number | null = null

    try {
      warehouseId = await createWarehouseOrFail(companyId, info)
    } catch (e) {
      return Promise.resolve()
    }

    if (!warehouseId) {
      const msg = '[useCreateWarehouse]: warehouseId is empty. Cannot update'
      console.error(msg)

      return Promise.reject(msg)
    }

    const isCultureSkip = !culture?.volume
    if (!isCultureSkip)
      try {
        const createWarehousePosition = await updateWarehouseWithCultureOrFail(warehouseId, culture)

        // if (createWarehousePosition?.warehousePositionId && culture?.laboratoryAnalysis) {
        //   await uploadLaboratoryAnalysis(createWarehousePosition?.warehousePositionId, culture?.laboratoryAnalysis)
        // }
      } catch (e) {
        const msg = '[useCreateWarehouse]: updating failed'
        console.error(msg)

        return Promise.reject(msg)
      }

    return warehouseId
  }

  return useMutation(createWarehouseFn)
}
