import { getDealDocumentName, getDealDocumentStatus } from '~/utils/deals'
import { enumToArray } from '~/utils/enum-to-array'
import { DealDocumentData, DealDocumentDataTypeEnum, DocumentPartStatus } from '~api/generated'

export function getDocumentsTypesDropdownOptions(list: DealDocumentDataTypeEnum[]) {
  return list.map((key) => ({
    value: key,
    label: getDealDocumentName(key),
  }))
}

export function findUserDocumentByType(
  documentsForCheck: DealDocumentData[],
  docType: DealDocumentDataTypeEnum | null
): DealDocumentDataTypeEnum | null {
  return documentsForCheck.find((x) => x.type === docType)?.type ?? null
}

export function getDealDocumentStatusDropdownOptions() {
  return enumToArray(DocumentPartStatus).map((key) => {
    const status = getDealDocumentStatus(key)
    return {
      value: key as DocumentPartStatus,
      label: status.name,
      color: status.color,
    }
  })
}
