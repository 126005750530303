import React from 'react'

import { Button } from 'antd'
import { FiEdit2 } from 'react-icons/fi'

import { Card } from '~/ui-components'
import { CompanyStatusBadge } from '~/utils/company/CompanyStatusBadge'
import { getCompanyRoleOld } from '~/utils/company/get-company-role-old'
import { useGetCurrentRole } from '~hooks/auth'
import { CompanyRating } from '~shared/components'

import { IAccountTypeViewProps } from './AccountTypeView.types'

import * as S from './AccountTypeView.styled'

export const AccountTypeView: React.FC<IAccountTypeViewProps> = ({ companyStatus, accountType, onChange }) => {
  const {
    user: { isAdmin },
  } = useGetCurrentRole()

  const handleChange = () => {
    onChange()
  }

  return (
    <Card>
      <Card.Header>
        <Card.Title>Тип Аккаунта</Card.Title>
        {isAdmin && (
          <Card.ActionsContent>
            <Button size="small" type="text" icon={<FiEdit2 size={24} />} onClick={handleChange} />
          </Card.ActionsContent>
        )}
      </Card.Header>

      <Card.Content>
        <S.ContentWrapper>
          <Card.Label>Тип компании</Card.Label>
          <S.AccountType>{getCompanyRoleOld(accountType.companyRoleType)}</S.AccountType>
        </S.ContentWrapper>
        <S.ContentWrapper>
          <Card.Label>Учетная запись</Card.Label>
          <Card.Value>
            <CompanyStatusBadge status={companyStatus} />
          </Card.Value>
        </S.ContentWrapper>
        <S.ContentWrapper>
          <Card.Label>Рейтинг компании</Card.Label>
          <Card.Value>
            <CompanyRating rating={'high'} />
          </Card.Value>
        </S.ContentWrapper>
      </Card.Content>
    </Card>
  )
}
