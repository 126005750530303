import React from 'react'

import { Button, Input, Modal } from 'antd'
import { Controller, useForm } from 'react-hook-form'

import { yupResolver } from '@hookform/resolvers/yup/dist/yup'

import { FormField } from '~/ui-components'
import { modalSharedSettings } from '~/utils'

import { moderatorBasisAdvertOfferRemoveModalValidation } from './ModeratorBasisAdvertOfferRemoveModal.validation'

import { IRejectAdvertOfferModalForm, IRejectAdvertOfferModalProps } from './ModeratorBasisAdvertOfferRemoveModal.types'

const FORM_ID = 'reject-advert-offer-modal'

export const ModeratorBasisAdvertOfferRemoveModal: React.FC<IRejectAdvertOfferModalProps> = (props) => {
  const {
    control,
    handleSubmit,
    formState: { errors, isDirty },
  } = useForm<IRejectAdvertOfferModalForm>({
    defaultValues: {
      message: '',
    },
    resolver: yupResolver(moderatorBasisAdvertOfferRemoveModalValidation),
  })

  const handleFormSubmit = (values: IRejectAdvertOfferModalForm) => {
    props.onReject(values)
    props.onClose()
  }

  return (
    <Modal
      {...modalSharedSettings}
      destroyOnClose={true}
      width={undefined}
      open={props.isOpen}
      onCancel={props.onClose}
      title="Отклонить ставку"
      footer={[
        <Button key="cancel" htmlType="button" onClick={props.onClose}>
          Отмена
        </Button>,
        <Button type="primary" key="submit" htmlType="submit" form={FORM_ID} disabled={!isDirty}>
          Отклонить
        </Button>,
      ]}
    >
      <form id={FORM_ID} onSubmit={handleSubmit(handleFormSubmit)}>
        <p>Укажите причину отклонения ставки</p>

        <FormField label="Укажите причину" isRequired htmlFor="message" validateMessage={errors.message?.message}>
          <Controller
            name="message"
            control={control}
            render={({ field }) => (
              <Input.TextArea
                {...field}
                id="message"
                rows={4}
                placeholder="Укажите причину"
                maxLength={500}
                status={errors.message && 'error'}
              ></Input.TextArea>
            )}
          />
        </FormField>
      </form>
    </Modal>
  )
}
