/* tslint:disable */
/* eslint-disable */
/**
 * API Зерно-Агрегатора
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { BaseOfferData } from './base-offer-data';

/**
 * 
 * @export
 * @interface ModeratedAuctionData
 */
export interface ModeratedAuctionData {
    /**
     * Id торгов
     * @type {number}
     * @memberof ModeratedAuctionData
     */
    'id': number;
    /**
     * Предложение-основа торгов
     * @type {BaseOfferData}
     * @memberof ModeratedAuctionData
     */
    'offer': BaseOfferData;
    /**
     * Ещё не выбранный по торгам объём
     * @type {number}
     * @memberof ModeratedAuctionData
     */
    'notSelectedVolume': number;
    /**
     * Статус торгов
     * @type {string}
     * @memberof ModeratedAuctionData
     */
    'status': ModeratedAuctionDataStatusEnum;
    /**
     * Время окончании торгов
     * @type {string}
     * @memberof ModeratedAuctionData
     */
    'endedAt': string;
    /**
     * Следующие возможные статусы
     * @type {Array<string>}
     * @memberof ModeratedAuctionData
     */
    'nextStatuses': Array<ModeratedAuctionDataNextStatusesEnum>;
    /**
     * Id модератора, проверяющего торги
     * @type {number}
     * @memberof ModeratedAuctionData
     */
    'moderatedBy': number | null;
    /**
     * Время начала торгов
     * @type {string}
     * @memberof ModeratedAuctionData
     */
    'startedAt': string;
    /**
     * Время создания торгов
     * @type {string}
     * @memberof ModeratedAuctionData
     */
    'createdAt': string;
}

export const ModeratedAuctionDataStatusEnum = {
    OnModeration: 'ON_MODERATION',
    PreStarted: 'PRE_STARTED',
    InProgress: 'IN_PROGRESS',
    Expired: 'EXPIRED',
    Rejected: 'REJECTED',
    Realised: 'REALISED'
} as const;

export type ModeratedAuctionDataStatusEnum = typeof ModeratedAuctionDataStatusEnum[keyof typeof ModeratedAuctionDataStatusEnum];
export const ModeratedAuctionDataNextStatusesEnum = {
    OnModeration: 'ON_MODERATION',
    PreStarted: 'PRE_STARTED',
    InProgress: 'IN_PROGRESS',
    Expired: 'EXPIRED',
    Rejected: 'REJECTED',
    Realised: 'REALISED'
} as const;

export type ModeratedAuctionDataNextStatusesEnum = typeof ModeratedAuctionDataNextStatusesEnum[keyof typeof ModeratedAuctionDataNextStatusesEnum];


