import styled from 'styled-components'

import { breakpoints } from '~/tokens'
import { Text } from '~/ui-components'

export const Wrapper = styled.div`
  width: 100%;
  padding: 16px;
  border-radius: 6px;
  border: 1px solid var(--gray-2-color);
  box-shadow: 0 1px 4px 0 rgba(84, 84, 84, 0.15);
  gap: 16px;
  display: flex;
  align-items: center;
`
export const EmployeeInfoWrapper = styled.div`
  display: flex;
  flex-grow: 1;

  @media (max-width: ${breakpoints.MOBILE_XL}px) {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }
`

export const EmployeeInfo = styled.div<{ isDeactivated: boolean }>`
  display: flex;
  gap: 16px;
  flex-grow: 1;
  opacity: ${({ isDeactivated }) => (isDeactivated ? 0.4 : 'unset')};
`

export const EmployeePosition = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 16px;

  @media (max-width: ${breakpoints.MOBILE_XL}px) {
    gap: 8px;
    justify-content: unset;
    align-items: flex-start;
    margin-left: 66px;
    flex-direction: column-reverse;
  }
`

export const ActionsWrapper = styled.div`
  @media (max-width: ${breakpoints.MOBILE_XL}px) {
    align-self: flex-start;
  }
`

export const DeactivatedUserMessage = styled(Text).attrs({ variant: 'description' })`
  color: var(--gray-5-color);
  display: flex;
  align-items: center;
  text-align: center;
`
