import React, { useState } from 'react'

import { Button, Checkbox } from 'antd'

import { Card, Text } from '~/ui-components'
import { getNoun } from '~/utils/getNoun'
import {
  BaseDealAdditionalServiceDataType,
  BaseDealAdditionalServiceDataTypeTypeEnum as ServiceItemEnum,
} from '~api/generated'

import { getTitleByServiceName } from './_utils'

import { DealAdditionalServicesBuyerTypesProps } from './DealAdditionalServicesBuyer.types'

import * as S from './DealAdditionalServicesBuyer.styled'

export const DealAdditionalServicesBuyer: React.FC<DealAdditionalServicesBuyerTypesProps> = (props) => {
  const [selectedValues, setSelectedValues] = useState<ServiceItemEnum[]>(
    props.value.filter((x) => x.isSelected).map((x) => x.type)
  )

  const [isEdit, setEdit] = useState(props.value.length && props.value.every((x) => !x.isSelected))

  const servicePrice = props.value.find((x) => x.type === ServiceItemEnum.Service)?.price ?? null
  const shippingPrice = props.value.find((x) => x.type === ServiceItemEnum.Shipping)?.price ?? null

  const handleChange = (checkedValues: ServiceItemEnum[]) => {
    // если уже выбрано Без доп услуг и мы выбираем другую услугу, то очищаем Без доп услуг
    if (selectedValues.includes(ServiceItemEnum.EmptyService)) {
      setSelectedValues(checkedValues.filter((x) => x !== ServiceItemEnum.EmptyService))
      return
    }
    // если выбрали без доп услуг, то очищаем все другие услуги
    if (checkedValues.includes(ServiceItemEnum.EmptyService)) {
      setSelectedValues([ServiceItemEnum.EmptyService])
      return
    }

    setSelectedValues(checkedValues)
  }

  const handleEditButtonClick = () => {
    setEdit(true)
  }

  const handleSaveButtonClick = () => {
    const defaultValues = [...props.value]
    if (!defaultValues.length) {
      const newServices: BaseDealAdditionalServiceDataType[] = selectedValues.map((serviceType) => ({
        type: serviceType,
        isSelected: true,
        price: null,
        distance: null,
      }))
      props.onSubmit(newServices)
    } else {
      const output: BaseDealAdditionalServiceDataType[] = defaultValues.map((service) => {
        if (selectedValues.includes(service.type)) {
          return { ...service, isSelected: true }
        }
        return { ...service, isSelected: false }
      })
      props.onSubmit(output)
    }
    setEdit(false)
  }

  const previewContent = () => {
    const withoutServices = props.value.find((service) => service.type === ServiceItemEnum.EmptyService)?.isSelected
    const selectedServices = props.value.filter((service) => service.isSelected)

    if (withoutServices) {
      return <Text variant="normal">Вы не выбрали ни одной услуги</Text>
    }
    return (
      <>
        <Text variant="normal">Спасибо, мы получили ваш запрос. С вами свяжется администратор.</Text>

        <Text variant="label-bold">Вы выбрали:</Text>
        {selectedServices.map((service) => (
          <Text variant="label" key={service.type}>
            {getTitleByServiceName(service.type)}
          </Text>
        ))}
      </>
    )
  }

  const editContent = (
    <>
      <S.StyledCheckboxGroup value={selectedValues} onChange={(x) => handleChange(x as ServiceItemEnum[])}>
        <S.Item>
          <S.CheckboxWrapper>
            <Checkbox value={ServiceItemEnum.Service} />
            <S.ItemSubtitle>Организация перевозки агентом</S.ItemSubtitle>
          </S.CheckboxWrapper>
          {shippingPrice && (
            <S.Description>
              Примерная стоимость перевозки: {shippingPrice} р/т <br />
            </S.Description>
          )}
        </S.Item>

        <S.Item>
          <S.CheckboxWrapper>
            <Checkbox value={ServiceItemEnum.Shipping} />
            <S.ItemSubtitle>Проба для проведения анализа</S.ItemSubtitle>
          </S.CheckboxWrapper>
          {servicePrice && <S.Description>Стоимость отправки: {servicePrice} р</S.Description>}
        </S.Item>

        <S.Item isDefault>
          <S.CheckboxWrapper>
            <Checkbox value={ServiceItemEnum.EmptyService} />
            <S.ItemSubtitle>Без дополнительных услуг</S.ItemSubtitle>
          </S.CheckboxWrapper>
          <S.Description>Стоимость останется без изменений</S.Description>
        </S.Item>
      </S.StyledCheckboxGroup>
    </>
  )

  return (
    <Card>
      <Card.Header>
        <Card.Title>Доп. услуги по сделке</Card.Title>
      </Card.Header>
      <S.Content>{isEdit ? editContent : previewContent()}</S.Content>
      <S.Footer>
        {isEdit ? (
          <S.SubmitWrapper>
            {selectedValues.filter((x) => x !== ServiceItemEnum.EmptyService).length > 0 ? (
              <S.ItemSubtitle>
                Вы выбрали {selectedValues.length} {getNoun(selectedValues.length, 'услугу', 'услуги', 'услуг')}
              </S.ItemSubtitle>
            ) : (
              <span />
            )}

            <Button type="primary" onClick={handleSaveButtonClick} disabled={!selectedValues.length}>
              Отправить запрос
            </Button>
          </S.SubmitWrapper>
        ) : (
          <Button type="primary" onClick={handleEditButtonClick}>
            Изменить решение
          </Button>
        )}
      </S.Footer>
    </Card>
  )
}
