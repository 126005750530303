import React from 'react'

import { RouteObject } from 'react-router-dom'

import { appRoutes } from '~/router'
import { RequestDetails, RequestOfferDetails, RequestsPage } from '~pages/RequestsPage'

import { RequireAuth } from '../../providers/auth'

export const requestsRouter: RouteObject[] = [
  {
    path: appRoutes.requests.url,
    element: (
      <RequireAuth>
        <RequestsPage />
      </RequireAuth>
    ),
  },
  {
    path: appRoutes.requestDetails.url,
    element: (
      <RequireAuth>
        <RequestDetails />
      </RequireAuth>
    ),
  },
  {
    path: appRoutes.requestOfferDetails.url,
    element: (
      <RequireAuth>
        <RequestOfferDetails />
      </RequireAuth>
    ),
  },
]
