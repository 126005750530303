import React, { FC } from 'react'

import { BiRuble } from 'react-icons/bi'
import {
  FiClipboard,
  FiClock,
  FiCreditCard,
  FiMousePointer,
  FiPaperclip,
  FiPenTool,
  FiThumbsUp,
  FiUpload,
} from 'react-icons/fi'

import { Text } from '~/ui-components'
import deal_pic_all_url from '~assets/images/deal_pic_all.png'
import {
  IPromoFlowStepProps,
  LoadDocumentsStep,
  PromoFlowStep,
  PromoFlowWrapper,
} from '~shared/components/PromoFlowStep'

import * as S from './DealsPromo.styled'

const flowSteps: IPromoFlowStepProps[] = [
  {
    Icon: FiUpload,
    text: <LoadDocumentsStep />,
  },
  {
    Icon: FiMousePointer,
    text: 'Оставьте отклик на заинтересовавшее вас предложение. Для этого достаточно нажать кнопку “купить” или “продать” на предложении.',
  },
  {
    Icon: FiClock,
    text: 'Дождитесь подтверждения администратора о начале сделки. Займет не больше часа.',
  },
  {
    Icon: FiCreditCard,
    text: 'Перейдите в сделку и укажите банковские реквизиты для формирования договора.',
  },
  {
    Icon: FiClipboard,
    text: 'Согласуйте спецификацию на основании предоставленных лаб исследований со стороны производителя или закажите у нас независимый лаб. анализ.',
  },
  {
    Icon: FiPenTool,
    text: 'Подпишите договор и приложение к нему с помощью ЭДО, или распечатайте договор, подпишите и загрузите на платформу.',
  },
  {
    Icon: BiRuble,
    text: (
      <>
        Загрузите платежные документы:
        <br /> для продавцов: счет на оплату;
        <br /> для покупателей: Платежное поручение с отметкой банка.,
      </>
    ),
  },
  {
    Icon: FiPaperclip,
    text: 'Загрузите товарно-транспортные накладные.',
  },
  {
    Icon: FiThumbsUp,
    text: 'Процесс сделки на этом этапе будет завершен, а все документы по сделке будут доступны в вашем личном кабинете.',
  },
]

export const DealsPromoContent: FC = () => {
  return (
    <S.ContentWrapper>
      <Text variant="h2">Как работают сделки</Text>

      <S.ImgWrapper>
        <S.IntegrationImg src={deal_pic_all_url} alt="deals flow" />
      </S.ImgWrapper>

      <Text variant="h2">Полный процесс сделки выглядит так</Text>

      <PromoFlowWrapper>
        {flowSteps.map(({ text, Icon }, index) => (
          <PromoFlowStep key={index} Icon={Icon} text={text} />
        ))}
      </PromoFlowWrapper>
    </S.ContentWrapper>
  )
}
