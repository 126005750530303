import styled from 'styled-components'

import { breakpoints } from '~/tokens'
import { Text } from '~/ui-components'

export const ContentWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 12px;
  background: #ddf5e9;
  padding: 4px 16px;
  border-radius: 8px;

  @media (max-width: ${breakpoints.MOBILE_XL}px) {
    justify-content: center;
    gap: 6px;
  }
`
const StyledText = styled(Text)`
  color: #00704d;
`
export const Title = styled(StyledText).attrs({ variant: 'description' })`
  line-height: 20px;
  font-weight: 700;

  @media (max-width: ${breakpoints.MOBILE_XL}px) {
    width: 100%;
    text-align: center;
  }
`
