import React, { useRef, useState } from 'react'

import { Input, Select } from 'antd'
import { PatternFormat } from 'react-number-format'
import { NumberFormatValues, SourceInfo } from 'react-number-format/types/types'

import { AppConfig } from '~/appConfig'
import { CustomControlInterface } from '~shared/controls/CustomControl.interface'

type Value = string | undefined

interface IPhoneNumberControlProps extends CustomControlInterface {
  onChange: (value: Value) => void
  value: Value
  hasError?: boolean
  id?: string
  disabled?: boolean
  autoFocus?: boolean
}

export const PhoneNumberControl: React.FC<IPhoneNumberControlProps> = React.forwardRef<
  HTMLInputElement,
  IPhoneNumberControlProps
>(({ id, value, onChange, hasError, autoFocus, disabled }, ref) => {
  const inputRef = useRef<HTMLInputElement>(null)
  const [countryCode, setCountryCode] = useState<string>(AppConfig.COUNTRIES_CODE_LIST[0].value)
  const [format, setFormat] = useState<string>(AppConfig.COUNTRIES_CODE_LIST[0].format)
  const [placeholder, setPlaceholder] = useState<string>(AppConfig.COUNTRIES_CODE_LIST[0].placeholder)

  const onChangeHandler = (values: NumberFormatValues, _sourceInfo: SourceInfo) => {
    const formattedValue = values.value

    if (!formattedValue) {
      return onChange(undefined)
    }
    onChange(countryCode + formattedValue)
  }

  const onChangeCountryCodeHandler = (code: string) => {
    setCountryCode(code)
    const country = AppConfig.COUNTRIES_CODE_LIST.find((item) => item.value === code)
    setFormat(country?.format ?? '')
    setPlaceholder(country?.placeholder ?? '')
    onChange('')
    inputRef.current?.focus()
  }

  const selectBefore = (
    <Select value={countryCode} popupMatchSelectWidth={70} onSelect={onChangeCountryCodeHandler}>
      {AppConfig.COUNTRIES_CODE_LIST.map((countryCode) => (
        <Select.Option key={countryCode.value} value={countryCode.value}>
          {countryCode.title}
        </Select.Option>
      ))}
    </Select>
  )

  return (
    <PatternFormat
      style={{ maxWidth: '342px' }}
      getInputRef={inputRef}
      customInput={Input}
      id={id}
      autoFocus={autoFocus}
      addonBefore={selectBefore}
      value={value?.replace(countryCode, '')}
      type="tel"
      status={hasError ? 'error' : undefined}
      onValueChange={onChangeHandler}
      placeholder={placeholder}
      format={format}
      mask="_"
      allowEmptyFormatting={true}
      disabled={disabled}
    />
  )
})

PhoneNumberControl.displayName = 'PhoneNumberControl'
