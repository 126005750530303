import React from 'react'

import { Space } from 'antd'

import { Text } from '~/ui-components'

interface ReadValueProps {
  name: React.ReactElement | string
  value?: React.ReactElement | string | null
}

const UNFILLED = 'Не заполнено'

export const ReadValue: React.FC<ReadValueProps> = (props) => {
  return (
    <Space direction="vertical" size={4}>
      <Text variant="form-read-label">{props.name}</Text>
      {props?.value ? <Text variant="form-read-value">{props.value}</Text> : <Text variant="label">{UNFILLED}</Text>}
    </Space>
  )
}
