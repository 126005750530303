import { useQuery, UseQueryOptions } from '@tanstack/react-query'

import { AuctionApiAuctionControllerGetCompanyAuctionsRequest, GetCompanyAuctionsResponse } from '~/api/generated'
import { useApi } from '~/providers/api'
import { isApiResponseEmpty } from '~/utils/isApiResponseEmpty'

type Result = GetCompanyAuctionsResponse | null

export const useGetMyAuctions = (
  request: AuctionApiAuctionControllerGetCompanyAuctionsRequest,
  options?: Omit<UseQueryOptions<Result, Error>, 'queryKey' | 'queryFn'>
) => {
  const api = useApi()

  const fetchFn = async (): Promise<Result> => {
    if (!request.companyId) return null

    try {
      const response = await api.auction.auctionControllerGetCompanyAuctions(request)
      return response.data
    } catch (error) {
      if (isApiResponseEmpty(error)) {
        return null
      }

      return Promise.reject(error)
    }
  }

  return useQuery(['myAuctions', request.currentPage, request.pageSize], fetchFn, options)
}
