import React from 'react'

import { Flex, Select } from 'antd'
import { BaseSelectRef } from 'rc-select'
import { IoIosArrowDown } from 'react-icons/io'

import { useQuery } from '@apollo/client'

import { getTransportStationsQuery } from '~api/gql-query/car-park.query.graphql'
import { ReactComponent as MarkerIcon } from '~assets/icons/marker.svg'
import { CustomControlInterface } from '~shared/controls/CustomControl.interface'

import * as S from '~shared/controls/TransportStationSelectControl/TransportStationSelectControl.styled'

interface TransportStationSelectControlProps extends CustomControlInterface {
  id?: string
  value: number | undefined
  onChange: (value: number) => void
  hasError?: boolean
  variant?: 'list'
}

export const TransportStationSelectControl: React.FC<TransportStationSelectControlProps> = React.forwardRef<
  BaseSelectRef,
  TransportStationSelectControlProps
>(({ value, onChange, hasError, variant }, ref) => {
  const getTransportStations = useQuery(getTransportStationsQuery)
  const transportStations = getTransportStations.data?.transportStations ?? []

  if (variant === 'list') {
    return (
      <Select
        style={{ width: '100%' }}
        ref={ref}
        popupMatchSelectWidth={false}
        placeholder="Выберите"
        defaultValue={value}
        // @ts-ignore
        onChange={onChange}
        status={hasError ? 'error' : undefined}
        loading={getTransportStations.loading}
        disabled={getTransportStations.loading}
      >
        <Select.Option value={0}>Все транспортные средства</Select.Option>
        {transportStations.map((transportStation) => (
          <Select.Option key={transportStation.id} value={transportStation.id}>
            {transportStation.address}
          </Select.Option>
        ))}
      </Select>
    )
  }

  return (
    <Flex>
      <MarkerIcon />

      <S.StyledSelect
        ref={ref}
        bordered={false}
        popupMatchSelectWidth={false}
        placeholder="Выберите"
        defaultValue={value}
        // @ts-ignore
        onChange={onChange}
        status={hasError ? 'error' : undefined}
        loading={getTransportStations.loading}
        disabled={getTransportStations.loading}
        suffixIcon={<IoIosArrowDown size={20} />}
      >
        <Select.Option value={0}>Все транспортные средства</Select.Option>
        {transportStations.map((transportStation) => (
          <Select.Option key={transportStation.id} value={transportStation.id}>
            {transportStation.address}
          </Select.Option>
        ))}
      </S.StyledSelect>
    </Flex>
  )
})

TransportStationSelectControl.displayName = 'TransportStationSelectControl'
