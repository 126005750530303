import React, { PropsWithChildren, useState } from 'react'

import { Space } from 'antd'
import CheckableTag from 'antd/es/tag/CheckableTag'

import { getDayOfWeek } from '~/utils/getDayOfWeek'
import { DayOfWeek } from '~api/generated'
import { CustomControlInterface } from '~shared/controls/CustomControl.interface'

export type DaysOfWeekControlValue = DayOfWeek[]

interface DaysOfWeekControlProps extends CustomControlInterface {
  onChange: (value: DaysOfWeekControlValue) => void
  value: DaysOfWeekControlValue
  hasError?: boolean
}

interface DayOfWeekTag {
  label: string
  value: DayOfWeek
}

const daysOfWeek: DayOfWeekTag[] = [
  {
    label: getDayOfWeek(DayOfWeek.Monday),
    value: DayOfWeek.Monday,
  },
  {
    label: getDayOfWeek(DayOfWeek.Tuesday),
    value: DayOfWeek.Tuesday,
  },
  {
    label: getDayOfWeek(DayOfWeek.Wednesday),
    value: DayOfWeek.Wednesday,
  },
  {
    label: getDayOfWeek(DayOfWeek.Thursday),
    value: DayOfWeek.Thursday,
  },
  {
    label: getDayOfWeek(DayOfWeek.Friday),
    value: DayOfWeek.Friday,
  },
  {
    label: getDayOfWeek(DayOfWeek.Saturday),
    value: DayOfWeek.Saturday,
  },
  {
    label: getDayOfWeek(DayOfWeek.Sunday),
    value: DayOfWeek.Sunday,
  },
]

export const DaysOfWeekControl: React.FC<PropsWithChildren<DaysOfWeekControlProps>> = React.forwardRef<
  HTMLInputElement,
  PropsWithChildren<DaysOfWeekControlProps>
>(({ children, value, onChange, hasError }, ref) => {
  const [selectedTags, setSelectedTags] = useState<DayOfWeek[]>(value ?? [])

  const handleChange = (tag: DayOfWeekTag, checked: boolean) => {
    const nextSelectedTags = checked ? [...selectedTags, tag.value] : selectedTags.filter((t) => t !== tag.value)
    setSelectedTags(nextSelectedTags)
    onChange(nextSelectedTags)
  }

  return (
    <Space size={[0, 0]} wrap>
      {daysOfWeek.map((tag) => (
        <CheckableTag
          key={tag.value}
          checked={selectedTags.includes(tag.value)}
          onChange={(checked) => handleChange(tag, checked)}
        >
          {tag.label}
        </CheckableTag>
      ))}
    </Space>
  )
})

DaysOfWeekControl.displayName = 'DaysOfWeekControl'
