import React from 'react'

import { Spin } from 'antd'

import { Card } from '~/ui-components'
import { getDocumentInfo } from '~/utils/profile'

import { DocumentActions, DocumentUploadStatusBadge, DownloadTemplateDocButton } from '../../components'
import { IDocumentsView } from '../../models/documentsView.model'

import * as S from './DocumentsCardView.styled'

export const DocumentsCardView: React.FC<IDocumentsView> = ({
  documents,
  onShow,
  onUploadFile,
  onRemove,
  isUploading,
  onChangeStatus,
}) => {
  return (
    <S.Wrapper>
      {documents.map((document, idx) => {
        const isUploaded = !!document.file?.source

        return (
          <Spin key={document.type} spinning={isUploading}>
            <Card>
              <Card.Header>
                <Card.Title>№{idx + 1}</Card.Title>

                <Card.ActionsContent>
                  <DocumentActions
                    status={document.file?.status}
                    onChangeDocumentStatus={(newStatus) => onChangeStatus(document.type, newStatus)}
                    isUploaded={isUploaded}
                    onShow={() => onShow(document.file?.source ?? '')}
                    onRemove={() => onRemove(document.type)}
                    onUploadFile={(file) => onUploadFile({ file, documentType: document.type })}
                  />
                </Card.ActionsContent>
              </Card.Header>

              <Card.Content>
                <div>
                  <Card.Label>Наименование документа</Card.Label>
                  <S.CardValue>{getDocumentInfo(document.type).name}</S.CardValue>
                  <S.CardValue>
                    {document.type === 'last-year-income-statement' && <DownloadTemplateDocButton />}
                  </S.CardValue>
                </div>

                <S.StatusWrapper>
                  <Card.Label>Статус</Card.Label>
                  <Card.Value>
                    <DocumentUploadStatusBadge isUploaded={isUploaded} />
                  </Card.Value>
                </S.StatusWrapper>
              </Card.Content>
            </Card>
          </Spin>
        )
      })}
    </S.Wrapper>
  )
}
