import { CompanyDataTypeEnum, GetUserResponseRoleEnum } from '~api/generated'
import { useAuth } from '~providers/auth'

export const useGetCurrentRole = () => {
  const { companyRole, user } = useAuth()

  return {
    user: {
      isAdmin: user?.role === GetUserResponseRoleEnum.Admin,
    },
    company: {
      companyRole: companyRole,
      isBuyer: companyRole === CompanyDataTypeEnum.Buyer,
      isSeller: companyRole === CompanyDataTypeEnum.Seller,
      isTrader: companyRole === CompanyDataTypeEnum.Trader,
      isCarrier: companyRole === CompanyDataTypeEnum.Carrier,
    },
  }
}
