import { useQuery, UseQueryResult } from '@tanstack/react-query'

import { GetVipElevatorsResponse } from '~api/generated'
import { useApi } from '~providers/api'

export const useGetVipElevators = (): UseQueryResult<GetVipElevatorsResponse> => {
  const api = useApi()

  const fetchFn = async (): Promise<GetVipElevatorsResponse> => {
    const res = await api.elevators.elevatorControllerGetVipElevators()

    return res.data
  }

  return useQuery(['vipElevators'], fetchFn)
}
