import React, { FC, useState } from 'react'

import { Button, Space } from 'antd'
import { FiChevronDown, FiChevronUp } from 'react-icons/fi'

import { Card, Text } from '~/ui-components'
import { getElevatorLoadType } from '~/utils/elevators/get-elevator-load-type'
import { getElevatorUnloadType } from '~/utils/elevators/get-elevator-unload-type'
import { getWorkTime } from '~/utils/getDateFormat'
import { getValueIfExistOrDefault } from '~/utils/getValueIfExistOrDefault'
import { useGetCurrentRole } from '~hooks/auth'
import { PhoneNumber } from '~shared/components'
import { getRoadSurfaceQualityName } from '~shared/controls/RoadSurfaceQualitySelectControl/get-road-surface-quality-name'
import { getRoadSurfaceTypeName } from '~shared/controls/RoadSurfaceTypeSelectControl/get-road-surface-type-name'

import { CardLabel, CardValue } from './CardValue'

import { UnloadingInfoCardViewProps } from './UnloadingInfoCardView.types'

import * as S from './UnloadingInfoCardView.styled'

export const UnloadingInfoCardView: FC<UnloadingInfoCardViewProps> = ({
  formValues,
  companyName,
  warehouse,
  warehousePhone,
}) => {
  const {
    company: { isSeller, isBuyer, isTrader },
  } = useGetCurrentRole()

  const [isExpanded, setExpanded] = useState(true)

  return (
    <S.Content>
      <S.FormView1Column>
        <CardLabel value="Грузополучатель" />
        <CardValue value={companyName} />

        <CardLabel value="Тел. грузополучателя" />
        <CardValue value={<PhoneNumber phoneNumber={warehousePhone} prefix="" />} />

        <CardLabel value="Адрес разгрузки" />
        <CardValue value={getValueIfExistOrDefault(warehouse?.address)} />

        <CardLabel value="Время работы пункта погрузки" />
        <CardValue value={getWorkTime(formValues?.openingTime, formValues?.closingTime)} />

        <CardLabel value="Наличие весов" />
        <CardValue value={getValueIfExistOrDefault(formValues?.hasScales)} />

        <CardLabel value="Тоннаж весов" />
        <CardValue value={getValueIfExistOrDefault(formValues?.scalesWeightLimit)} />

        <CardLabel value="Ответственный за перевозку" />
        <CardValue value={getValueIfExistOrDefault(formValues?.contactUser)} />

        <CardLabel value="Тел. ответственного за перевозку" />
        <CardValue value={<PhoneNumber phoneNumber={formValues?.contactPhone} />} />
      </S.FormView1Column>

      <div>
        <Button type="link" onClick={() => setExpanded((x) => !x)}>
          {isExpanded ? (
            <Space>
              Показать полностью
              <FiChevronDown size={14} />
            </Space>
          ) : (
            <Space>
              Скрыть
              <FiChevronUp size={14} />
            </Space>
          )}
        </Button>
      </div>

      {!isExpanded && (
        <>
          <Text variant="h3">Параметры по автотранспорту</Text>
          <S.FormView1Column>
            {(isBuyer || isTrader) && (
              <>
                <Card.Label>Скорость приемки</Card.Label>
                <Card.ValueBold>
                  {getValueIfExistOrDefault(warehouse?.roadTransportParameters?.unloadingThroughput, 'Авто/сутки')}
                </Card.ValueBold>

                <Card.Label>Способ приемки</Card.Label>
                <Card.ValueBold>
                  {' '}
                  {warehouse?.roadTransportParameters?.unloadingTypes.length
                    ? warehouse.roadTransportParameters.unloadingTypes.map((u) => getElevatorUnloadType(u)).join(', ')
                    : '-'}
                </Card.ValueBold>
              </>
            )}
            {(isSeller || isTrader) && (
              <>
                <Card.Label>Скорость погрузки</Card.Label>
                <Card.ValueBold>
                  {getValueIfExistOrDefault(warehouse?.roadTransportParameters?.loadingThroughput, 'ТС/сутки')}
                </Card.ValueBold>

                <Card.Label>Способы погрузки</Card.Label>
                <Card.ValueBold>
                  {warehouse?.roadTransportParameters?.loadingTypes.length
                    ? warehouse.roadTransportParameters.loadingTypes.map((u) => getElevatorLoadType(u)).join(', ')
                    : '-'}
                </Card.ValueBold>
              </>
            )}
            <Card.Label>Ограничения по длине</Card.Label>
            <Card.ValueBold>
              {getValueIfExistOrDefault(warehouse?.roadTransportParameters?.vehicleLengthMaxM, 'м')}
            </Card.ValueBold>

            <Card.Label>Ограничения по высоте</Card.Label>
            <Card.ValueBold>
              {getValueIfExistOrDefault(
                warehouse?.roadTransportParameters?.vehicleHeightMaxM
                  ? warehouse.roadTransportParameters.vehicleHeightMaxM / 100
                  : undefined,
                'м'
              )}
            </Card.ValueBold>

            <Card.Label>Ограничения по высоте клиренса</Card.Label>
            <Card.ValueBold>
              {getValueIfExistOrDefault(warehouse?.roadTransportParameters?.vehicleClearanceMinCm, 'см')}
            </Card.ValueBold>

            <Card.Label>Весы</Card.Label>
            <Card.ValueBold>{getValueIfExistOrDefault(warehouse?.roadTransportParameters?.hasScales)}</Card.ValueBold>

            <Card.Label>Тоннаж весов</Card.Label>
            <Card.ValueBold>
              {getValueIfExistOrDefault(warehouse?.roadTransportParameters?.scalesWeightLimitT, 'т')}
            </Card.ValueBold>

            <Card.Label>Автоматизация взвешивания (ПО)</Card.Label>
            <Card.ValueBold>
              {getValueIfExistOrDefault(warehouse?.roadTransportParameters?.hasWeighingAutomation)}
            </Card.ValueBold>

            <Card.Label>Расстояние от ворот до весов</Card.Label>
            <Card.ValueBold>
              {getValueIfExistOrDefault(warehouse?.roadTransportParameters?.distanceToScalesM, 'м')}
            </Card.ValueBold>

            <Card.Label>Тип покрытия для Т/С</Card.Label>
            <Card.ValueBold>
              {getRoadSurfaceTypeName(warehouse?.roadTransportParameters?.roadSurfaceType)}
            </Card.ValueBold>

            <Card.Label>Качество покрытия</Card.Label>
            <Card.ValueBold>
              {getRoadSurfaceQualityName(warehouse?.roadTransportParameters?.roadSurfaceQuality)}
            </Card.ValueBold>

            <Card.Label>Площадка накопитель для Т/С</Card.Label>
            <Card.ValueBold>{getValueIfExistOrDefault(warehouse?.roadTransportParameters?.hasParking)}</Card.ValueBold>

            <Card.Label>Дополнительные сведения по автотранспорту</Card.Label>
            <Card.ValueBold>
              {getValueIfExistOrDefault(warehouse?.roadTransportParameters?.additionalDetails)}
            </Card.ValueBold>
          </S.FormView1Column>

          {warehouse?.railTransportParameters && (
            <>
              <br />
              <Text variant="h3">Параметры по Ж/Д транспорту</Text>
              <S.FormView1Column>
                <Card.Label>Название станции</Card.Label>
                <Card.ValueBold>
                  {getValueIfExistOrDefault(warehouse?.railTransportParameters?.stationName)}
                </Card.ValueBold>

                <Card.Label>Наличие собственных Ж/Д весов</Card.Label>
                <Card.ValueBold>
                  {getValueIfExistOrDefault(warehouse?.railTransportParameters?.hasScales)}
                </Card.ValueBold>

                <Card.Label>Скорость погрузки</Card.Label>
                <Card.ValueBold>
                  {getValueIfExistOrDefault(warehouse?.railTransportParameters?.loadingThroughput, 'вагона/сутки')}
                </Card.ValueBold>

                <Card.Label>Наличие собственныз путей</Card.Label>
                <Card.ValueBold>
                  {getValueIfExistOrDefault(warehouse?.railTransportParameters?.hasOwnRailroad)}
                </Card.ValueBold>

                <Card.Label>Наличие собственного тягового подвижного состава</Card.Label>
                <Card.ValueBold>
                  {getValueIfExistOrDefault(warehouse?.railTransportParameters?.hasOwnLocomotives)}
                </Card.ValueBold>

                <Card.Label>Фронт подачи вагонов</Card.Label>
                <Card.ValueBold>
                  {getValueIfExistOrDefault(warehouse?.railTransportParameters?.servicedWagonsCount)}
                </Card.ValueBold>

                <Card.Label>Дополнительные сведения по Ж/Д транспорту</Card.Label>
                <Card.ValueBold>
                  {getValueIfExistOrDefault(warehouse?.railTransportParameters?.additionalDetails)}
                </Card.ValueBold>
              </S.FormView1Column>
            </>
          )}
        </>
      )}
    </S.Content>
  )
}
