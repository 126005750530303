import styled from 'styled-components'

import { breakpoints } from '~/tokens'

export const ContentWrapper = styled.article`
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 12px;
  cursor: pointer;
  @media (max-width: ${breakpoints.DESKTOP_L}px) {
    justify-content: center;
  }
`

export const CompanyInfoWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 4px;
  @media (max-width: ${breakpoints.DESKTOP_L}px) {
    display: none;
  }
  @media (max-width: ${breakpoints.MOBILE_XL}px) {
    display: flex;
  }
`

export const RightIcon = styled.div`
  align-self: center;
  color: var(--gray-8-color);

  @media (max-width: ${breakpoints.DESKTOP_L}px) {
    display: none;
  }
  @media (max-width: ${breakpoints.MOBILE_XL}px) {
    display: unset;
  }
`

export const CompanyTitle = styled.p`
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  color: #8c8c8c;
  margin: 0;
  @media (max-width: ${breakpoints.MOBILE_XL}px) {
    display: none;
  }
`

export const CompanyName = styled.p`
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  color: var(--gray-8-color);
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 130px;
`
export const CompanyRole = styled.p`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: var(--gray-8-color);
  margin: 0;

  @media (max-width: ${breakpoints.MOBILE_XL}px) {
    max-width: fit-content;
    display: inline-block;
    padding: 4px 12px;
    border-radius: 6px;
    background: #696e73;
    color: var(--gray-1-color);
  }
`
