import {
  AdminElevatorAdvertDataTypeEnum,
  MyElevatorAdvertListDataTypeEnum,
  MyElevatorAdvertOfferDataElevatorOfferTypeEnum,
} from '~api/generated'

import { BadgeVariant } from '~ui-components/components/Badge/Badge.types'

export function getElevatorAdvertType(
  v: AdminElevatorAdvertDataTypeEnum | MyElevatorAdvertListDataTypeEnum | MyElevatorAdvertOfferDataElevatorOfferTypeEnum
): {
  name: string
  color: BadgeVariant
} {
  switch (v) {
    case AdminElevatorAdvertDataTypeEnum.Buy:
      return {
        name: 'Покупка',
        color: 'base',
      }
    case AdminElevatorAdvertDataTypeEnum.Sell:
      return {
        name: 'Продажа',
        color: 'yellow',
      }
  }
}
