import React from 'react'

import * as S from './EmptyAcceptedOfferList.styled'

export const EmptyAcceptedOfferList = () => (
  <S.Card>
    <S.Title>Вы еще не оставили ни одного отклика</S.Title>
    <S.Description>Тут будут появляться предложения, на которые вы откликнулись.</S.Description>
  </S.Card>
)
