import React from 'react'

import { getUserRole, getUserRoleColor } from '~/utils/user/getUserRoleOld'
import { UserRole } from '~api/gql-generated/graphql'

import * as S from './UserRoleTag.styled'

interface UserRoleTagProps {
  role: UserRole | undefined | null
}
export const UserRoleTag: React.FC<UserRoleTagProps> = ({ role }) => {
  if (!role) {
    return (
      <S.Wrapper background="white" color="black">
        -
      </S.Wrapper>
    )
  }

  const { background, color } = getUserRoleColor(role)
  const roleName = getUserRole(role)

  return (
    <S.Wrapper background={background} color={color}>
      {roleName}
    </S.Wrapper>
  )
}
