import styled from 'styled-components'

import { breakpoints } from '~/tokens'

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 24px;

  @media (max-width: ${breakpoints.DESKTOP_S}px) {
    padding: 12px;
  }
`

export const CompanyLIstWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;

  @media (max-width: ${breakpoints.DESKTOP_S}px) {
    gap: 16px;
  }
`

export const FiltersWrapper = styled.div`
  display: grid;
  grid-template-columns: 250px 250px;
  gap: 8px;
  @media (max-width: ${breakpoints.DESKTOP_S}px) {
    grid-template-columns: 1fr;
  }
`
