import { SegmentedLabeledOption } from 'antd/es/segmented'
import { isEqual, reduce } from 'lodash'

import { queryClientInstance } from '~/App'
import { ProductTypeData, RegionData } from '~api/generated'

/**
 * compares current selected filters with filters by default
 * returns keys of object of filters which differs from default state or empty list. eg: ['price', 'requestType']
 */
export function getActiveFilters<T extends object>(currentFilters: T, defaultFilters: T): (keyof T)[] {
  return reduce(
    currentFilters,
    (result, value, key) => {
      return isEqual(value, defaultFilters[key as keyof T]) ? [...result] : ([...result, key] as (keyof T)[])
    },
    [] as (keyof T)[]
  )
}

export const filterTypeOptions: SegmentedLabeledOption[] = [
  {
    label: 'Все',
    value: 'ALL',
  },
  {
    label: 'Покупка',
    value: 'BUY',
  },
  {
    label: 'Продажа',
    value: 'SELL',
  },
]

export function foundRegionNamesByIds(regionIds: string[]): string {
  if (!regionIds.length) {
    return ''
  }
  const cachedRegions = queryClientInstance.getQueryData(['fetchRegions']) as RegionData[]
  const foundRegions = cachedRegions.filter((x) => regionIds.includes(x.id))
  return foundRegions.map((region) => region.name).join(', ')
}

export function foundProductNamesByIds(productIds: string[]): string {
  if (!productIds.length) {
    return ''
  }
  const cachedProducts = queryClientInstance.getQueryData(['fetchProducts']) as ProductTypeData[]
  const foundProducts = cachedProducts.filter((x) => productIds.includes(x.type))

  return foundProducts.map((product) => product.name).join(', ')
}

export function getPriceLabel(value: { from: number; to: number }): string {
  return `Цена от ${value?.from}Р/т. до ${value?.to}Р/т.`
}
export function getVolumeLabel(value: { from: number; to: number }): string {
  return `Объем от ${value?.from}т. до ${value?.to}т.`
}
export function getOperationTypeLabel(value: 'BUY' | 'SELL' | string): string {
  return value === 'BUY' ? 'Покупка' : value === 'SELL' ? 'Продажа' : ''
}
