import React from 'react'

import { FiChevronRight } from 'react-icons/fi'

import { formatNumber } from '~/utils/formatNumber'
import { getNdsString } from '~/utils/getNdsString'
import { ProductTypeData } from '~api/generated'

export const idColumn = {
  title: '№',
  dataIndex: 'id',
  width: 20,
}

export const actionArrowColumn = {
  title: '',
  dataIndex: 'action',
  key: 'action',
  width: 56,
  render: () => {
    return <FiChevronRight size={24} display={'block'} color={'var(--primary-main-color)'} />
  },
}

export const productNameColumn = (products: ProductTypeData[]) => ({
  title: 'Наименование культуры',
  dataIndex: ['product', 'name'],
  width: 200,
  className: 'bold-cell',
})

export const volumeColumn = {
  title: 'Объем, т',
  dataIndex: 'volume',
  // align: 'right',
  width: 100,
  className: 'bold-cell',
  render: (volume: number) => {
    return formatNumber(volume)
  },
}

export const priceColumn = {
  title: 'Цена, ₽/т',
  dataIndex: 'price',
  // align: 'right',
  width: 120,
  className: 'bold-cell',
  render: (price: number) => {
    return formatNumber(price)
  },
}

export const ndsColumn = {
  title: 'НДС',
  dataIndex: 'includingNds',
  width: '100px',
  render: (includingNds: boolean) => {
    return getNdsString(includingNds)
  },
}

export const locationColumn = {
  title: 'Локация',
  dataIndex: 'address',
}
