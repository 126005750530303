import { ElevatorOfferResponseStatus } from '~api/generated'

import { BadgeVariant } from '~ui-components/components/Badge/Badge.types'

export function getElevatorAdvertOfferStatus(v: ElevatorOfferResponseStatus): {
  name: string
  color: BadgeVariant
} {
  switch (v) {
    case ElevatorOfferResponseStatus.Accepted:
      return {
        name: 'Принято',
        color: 'base',
      }
    case ElevatorOfferResponseStatus.OnModeration:
      return {
        name: 'На проверке',
        color: 'yellow',
      }
    case ElevatorOfferResponseStatus.OnModerationWithAnalysis:
      return {
        name: 'На проверке. Отбор качества',
        color: 'yellow',
      }
    case ElevatorOfferResponseStatus.UnderConsideration:
      return {
        name: 'На рассмотрении',
        color: 'purple',
      }
    case ElevatorOfferResponseStatus.RejectedByBuyer:
      return {
        name: 'Отклонено',
        color: 'red',
      }
    case ElevatorOfferResponseStatus.RejectedOnModeration:
      return {
        name: 'Не прошел модерацию',
        color: 'red',
      }
    case ElevatorOfferResponseStatus.Cancelled:
      return {
        name: 'Отменено',
        color: 'red',
      }
    case ElevatorOfferResponseStatus.DealRealised:
      return {
        name: 'Сделка закончена',
        color: 'purple',
      }
    case ElevatorOfferResponseStatus.Purchased:
      return {
        name: 'Откуплено',
        color: 'purple',
      }
    case ElevatorOfferResponseStatus.Controversial:
      return {
        name: 'Спорный',
        color: 'red',
      }
  }
}
