import styled from 'styled-components'

import css from '@styled-system/css'

export const FlowStepWrapper = styled.div(
  css({
    width: '100%',
    padding: '24px',
    display: 'grid',
    gridTemplateColumns: '32px auto',
    gap: '16px',
    alignItems: 'center',
    fontSize: '14px',
    lineHeight: '20px',
    boxShadow: '1px 1px 1px #CAD2E1, 4px 4px 16px rgba(137, 141, 154, 0.12)',
  })
)

export const PromoFlowWrapper = styled.div(
  css({
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
  })
)
