import styled from 'styled-components'

import css from '@styled-system/css'

export const OpeningHoursWrapper = styled.div({
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
})

export const SmallInputWrapper = styled('div')({
  width: '124px',
})

export const FormSmall = styled.form(
  css({
    width: ['100%', null, '390px'],
  })
)

export const FormLarge = styled.form(
  css({
    width: ['100%', null, '560px'],
  })
)
