import React, { FC } from 'react'

import { IconType } from 'react-icons'

import * as S from './PromoFlowStep.styled'

export interface IPromoFlowStepProps {
  Icon: IconType
  text: string | JSX.Element
}

export const PromoFlowStep: FC<IPromoFlowStepProps> = ({ Icon, text }) => {
  return (
    <S.FlowStepWrapper>
      <Icon size="32px" color={'var(--success-color)'} />
      {text}
    </S.FlowStepWrapper>
  )
}
