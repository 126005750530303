import styled from 'styled-components'

import css from '@styled-system/css'

export const Content = styled.div(
  css({
    // width: ['386px', null, '536px'],
    minWidth: 386,
    maxWidth: '100%',
    variant: 'typography.textBaseRegular',
    color: 'TxtPrimary',
  })
)
