import React from 'react'

import { FiChevronRight } from 'react-icons/fi'
import { Link } from 'react-router-dom'

import { appRoutes } from '~/router'
import { Card } from '~/ui-components'
import { formatPhoneNumber } from '~/utils/formatPhoneNumber'
import { UserDataStatusEnum } from '~api/generated'
import { DeactivateUserToggle } from '~pages/ModeratorPage/components/ModeratorUserList/components/DeactivateUserToggle'

import { ModeratorUserListMobileItemType } from './ModeratorUserListMobile.types'

import * as S from './ModeratorUserListMobileItem.styled'

interface IModeratorUserListMobileItemProps extends ModeratorUserListMobileItemType {
  onItemClick: (item: ModeratorUserListMobileItemType) => void
  onDeactivateUser: (userId: number, status: UserDataStatusEnum) => void
}

export const ModeratorUserListMobileItem: React.FC<IModeratorUserListMobileItemProps> = (props) => {
  const onItemClickHandler = (e: React.MouseEvent) => {
    e.stopPropagation()
    props.onItemClick(props)
  }

  return (
    <Card onClick={onItemClickHandler}>
      <S.ContentWrapper>
        <S.MobileDeactivateUserIcon>
          <DeactivateUserToggle
            status={props.userStatus}
            onChangeUserStatus={() =>
              props.onDeactivateUser(
                props.id,
                props.userStatus === UserDataStatusEnum.Activated
                  ? UserDataStatusEnum.Blocked
                  : UserDataStatusEnum.Activated
              )
            }
            userName={props.name !== '-' ? props.name : props.id.toString()}
          />
        </S.MobileDeactivateUserIcon>
        <S.Row>
          <Card.Label>ID</Card.Label>
          <Card.Value>{props.id}</Card.Value>
        </S.Row>
        <S.Row>
          <Card.Label>Последняя авторизация</Card.Label>
          <Card.Value>{props.authenticatedAt}</Card.Value>
        </S.Row>
        <S.Row>
          <Card.Label>Имя</Card.Label>
          <Card.Value>{props.name}</Card.Value>
        </S.Row>
        <S.Row>
          <Card.Label>Телефон</Card.Label>
          <Card.Value>{formatPhoneNumber(props.phoneNumber)}</Card.Value>
        </S.Row>
        <S.Row>
          <Card.Label>Создал пользователя</Card.Label>
          <Card.Value>{props.createdBy}</Card.Value>
        </S.Row>
        <S.Row>
          <Card.Label>Дата регистрации</Card.Label>
          <Card.Value>{props.createdAt}</Card.Value>
        </S.Row>
        <S.Row>
          <Card.Label>Компания</Card.Label>
          <Card.Value>
            <Link
              onClick={(e) => e.stopPropagation()}
              to={appRoutes.moderatorCompanies.url + '/' + props.companyId}
              target="_blank"
            >
              {props.companyName}
            </Link>
          </Card.Value>
        </S.Row>
        <S.Row>
          <S.ActionWrapper>
            <FiChevronRight size={24} display={'block'} />
          </S.ActionWrapper>
        </S.Row>
      </S.ContentWrapper>
    </Card>
  )
}
