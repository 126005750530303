import React, { useState } from 'react'

import { Avatar, Button, Empty, Flex, Pagination, Table } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'

import { UserOutlined } from '@ant-design/icons'
import { useQuery } from '@apollo/client'

import { usePagination } from '~/hooks'
import { appRoutes } from '~/router'
import { logisticDealsFilterStore } from '~/store/filters'
import { Text } from '~/ui-components'
import { getTransportTypeIcon } from '~/utils/car-park/get-transport-type-icon'
import { formatPhoneNumber } from '~/utils/formatPhoneNumber'
import { DriverEntity } from '~api/gql-generated/graphql'
import { getDriversListQuery } from '~api/gql-query/drivers.query.graphql'
import { AssignTransportToDriver } from '~pages/CarParkPage/modules/AssignTransportToDriver'
import { AssignedTransportCounter } from '~pages/CarParkPage/modules/Drivers/components/AssignedTransportCounter'
import { DriverActionMenu } from '~pages/CarParkPage/modules/Drivers/components/DriverActionMenu'
import { useAuth } from '~providers/auth'
import { SearchInput } from '~shared/components/SearchInput'

export function Drivers() {
  const { companyId } = useAuth()
  const navigate = useNavigate()
  const [searchDriversQuery, setSearchDriversQuery] = useState<string>('')
  const [assignTransportToDriverId, setAssignTransportToDriverId] = useState<number | null>(null)

  const { pageSize, pageSizeOptions, currentPage, setCurrentPage, setPageSize } = usePagination({
    pageSize: 5,
  })

  const getDriversListFn = useQuery(getDriversListQuery, {
    variables: {
      filter: {
        companyIds: [companyId],
        search: searchDriversQuery,
        userIds: [],
      },
      pagination: { pageSize, currentPage },
    },
  })

  const pagination = getDriversListFn.data?.drivers.pagination
  const driversList: DriverEntity[] = getDriversListFn.data?.drivers?.drivers ?? []

  const onCreateDriver = () => navigate(appRoutes.carParkCreateDriver.url)

  function renderCreateDriverButton() {
    return (
      <div>
        <Button type="primary" onClick={onCreateDriver}>
          Добавить водителя
        </Button>
      </div>
    )
  }

  const locale = {
    emptyText: (
      <Flex vertical gap={14}>
        <Empty description="" />
        <Text variant="h4">Здесь будут ваши водители</Text>
        <Text variant="normal">
          <span>Вы можете добавлять водителей, распределять их по ТС,</span>
          <br />
          <span>а также отслеживать частоту их перевозок.</span>
        </Text>

        {renderCreateDriverButton()}
      </Flex>
    ),
  }

  const columns: ColumnsType<DriverEntity> = [
    {
      title: 'Водитель',
      dataIndex: 'id',
      width: 400,
      render: (_id, driver: DriverEntity) => {
        return (
          <Flex gap={12} align="center">
            <Avatar size={50} shape="circle" icon={<UserOutlined />} />
            <Flex vertical gap={8}>
              <Text variant="label-bold">{driver?.name}</Text>
              <Text variant="label">{formatPhoneNumber(driver?.phoneNumber)}</Text>
            </Flex>
          </Flex>
        )
      },
    },
    {
      title: 'Транспорт',
      dataIndex: 'ids',
      render: (_id, driver: DriverEntity) => {
        if (!driver.transports.length) {
          return (
            <Flex gap={12} align="center">
              <Text variant="label">Не назначен</Text>
            </Flex>
          )
        }

        const icon = (
          <Flex gap={2}>
            {driver.transports.slice(0, 3).map((t) => (
              <TransportIcon key={t.id}>{getTransportTypeIcon(t.type)}</TransportIcon>
            ))}
          </Flex>
        )
        const firstTransport = driver.transports.at(0)

        return (
          <Flex gap={4} align="center">
            {icon}
            <Flex vertical>
              <Text variant="normal">Транспорт</Text>
              <Flex gap={4}>
                <Text variant="normal-bold">{firstTransport?.brand}</Text>
                <Text variant="form-read-label">{firstTransport?.licensePlate}</Text>
                <AssignedTransportCounter transports={driver.transports} />
              </Flex>
            </Flex>
          </Flex>
        )
      },
    },
    {
      title: 'Статус',
      dataIndex: 'st',
    },
    {
      title: 'Действия',
      fixed: 'right',
      width: 120,
      dataIndex: 'actions',
      render: (_id, driver: DriverEntity) => {
        return (
          <div onClick={(e) => e.stopPropagation()}>
            <DriverActionMenu
              driver={driver}
              onAssignTransport={(driverId) => setAssignTransportToDriverId(driverId)}
            />
          </div>
        )
      },
    },
  ]

  const handleRowClick = (record: DriverEntity) => {
    navigate(appRoutes.carParkDriver.url.replace(':id', record.id.toString()))
  }

  return (
    <Flex vertical gap={32}>
      <Flex justify="space-between">
        <Text variant="normal">Всего водителей: {driversList?.length}</Text>
      </Flex>

      <Flex justify="space-between">
        <SearchInput
          style={{ maxWidth: 200 }}
          defaultValue={searchDriversQuery}
          onSearch={setSearchDriversQuery}
          placeholder="Поиск"
        />
        {renderCreateDriverButton()}
      </Flex>

      <Table
        locale={logisticDealsFilterStore.hasActiveFilters ? undefined : locale}
        rowKey="id"
        className="table-interactive"
        loading={getDriversListFn.loading}
        columns={columns}
        dataSource={driversList}
        scroll={{ x: 'max-content' }}
        onRow={(record) => ({
          onClick: handleRowClick.bind(null, record),
        })}
      />

      {pagination && (
        <Pagination
          style={{ marginTop: 'auto' }}
          current={currentPage}
          total={pagination?.total}
          pageSize={pagination?.pageSize}
          pageSizeOptions={pageSizeOptions}
          onShowSizeChange={(x, size) => setPageSize(size)}
          showSizeChanger
          onChange={setCurrentPage}
        />
      )}

      {assignTransportToDriverId && (
        <AssignTransportToDriver
          driverId={assignTransportToDriverId}
          open={!!assignTransportToDriverId}
          onClose={() => setAssignTransportToDriverId(null)}
        />
      )}
    </Flex>
  )
}

export const TransportIcon = styled.div`
  background: #ededed;
  width: 40px;
  height: 40px;
  border-radius: 8px;
  border: 2px solid white;
  position: relative; /* Для горизонтального смещения */
  left: 0; /* Начальное смещение для первой иконки */
  z-index: 10;
  & > svg {
    height: 100%;
    width: 100%;
  }
  &:nth-child(2) {
    z-index: 9;
    left: -15px;
  }
  &:nth-child(3) {
    z-index: 8;
    left: -30px;
  }
`
