import React, { useState } from 'react'

import { Modal } from 'antd'

import { EmptyButton } from '~/ui-components'
import { appToast, modalSharedSettings } from '~/utils'
import { CompanyVerificationStatus } from '~api/generated'
import { useCreateCompany, useGetCompanyById, useUpdateCompany, useUpdateCompanyStatus } from '~hooks/company'
import { mapCompanyFormValueToCreateRequest } from '~pages/ProfilePage/utils/mapCompanyFormValueToCreateRequest'
import { useAuth } from '~providers/auth'

import { CompanyInfoForm, ICompanyInfoForm } from '../CompanyInfoForm'
import { CompanyInfoView } from '../CompanyInfoView'

interface ICompanyInfoSectionProps {
  companyId: number | null
  hasChangeStatusSelect: boolean
}

export const CompanyInfoSection: React.FC<ICompanyInfoSectionProps> = (props) => {
  const auth = useAuth()
  const [isModalVisible, setIsModalVisible] = useState(!auth.companyId)
  const [companyId, setCompanyId] = useState<number | null>(props.companyId)

  const companyInfo = useGetCompanyById(companyId)
  const createCompanyInfo = useCreateCompany()
  const updateCompanyInfo = useUpdateCompany(companyId ?? NaN)
  const updateCompanyStatus = useUpdateCompanyStatus()

  const handleModalClose = () => setIsModalVisible(false)
  const handleModalOpen = () => setIsModalVisible(true)

  const handleSubmitChanges = async (formValues: ICompanyInfoForm) => {
    try {
      if (companyId) {
        await updateCompanyInfo.mutateAsync({
          ...mapCompanyFormValueToCreateRequest(formValues),
        })
      } else {
        const createdCompanyResponse = await createCompanyInfo.mutateAsync({
          bindingUser: true,
          ...mapCompanyFormValueToCreateRequest(formValues),
        })
        setCompanyId(createdCompanyResponse?.companyId ?? null)
        await auth.refetchUser()
      }
      await handleModalClose()
      await companyInfo.refetch()
      appToast.success({ description: 'Данные о компании обновлены' })
    } catch (e) {
      const description = 'Ошибка при обновлении данных о компании'
      console.error(description, e)
      appToast.error({ description })
    }
  }

  const handleCompanyStatusChange = async (statusObj: { status: CompanyVerificationStatus; rejectReason?: string }) => {
    const { status, rejectReason } = statusObj
    if (!companyId || status === companyInfo.data?.status) {
      return
    }

    try {
      await updateCompanyStatus.mutateAsync({
        companyId,
        updateCompanyStatusBody: {
          status,
          rejectionReason: rejectReason,
        },
      })
      await companyInfo.refetch()
      appToast.success({ description: 'Статус компании обновлен' })
    } catch (e) {
      const description = 'Ошибка при обновлении статуса компании'
      console.error(description, e)
      appToast.error({ description })
    }
  }

  if (companyInfo.isFetching) {
    return <EmptyButton>Загрузка...</EmptyButton>
  }

  if (companyInfo.isError) {
    return <EmptyButton>Ошибка</EmptyButton>
  }

  return (
    <>
      {!companyInfo.data ? (
        <EmptyButton onClick={handleModalOpen}>Заполнить данные о компании</EmptyButton>
      ) : (
        <CompanyInfoView
          onChange={handleModalOpen}
          onStatusChange={props.hasChangeStatusSelect ? handleCompanyStatusChange : undefined}
          data={companyInfo.data}
        />
      )}

      <Modal {...modalSharedSettings} open={isModalVisible} onCancel={handleModalClose} title="Данные о компании">
        <CompanyInfoForm
          onSubmit={handleSubmitChanges}
          defaultValues={companyInfo.data}
          isLoading={updateCompanyInfo.isLoading}
        />
      </Modal>
    </>
  )
}
