import { graphql } from '~api/gql-generated'

export const userCreateMutation = graphql(/* GraphQL */ `
  mutation UserCreate($input: UserCreateInput!) {
    userCreate(input: $input) {
      role
      phoneNumber
      name
      companies {
        id
      }
    }
  }
`)

export const userAddToCompanyMutation = graphql(/* GraphQL */ `
  mutation AddUsers($userIds: [Int!]!, $companyId: Int!) {
    company(companyId: $companyId) {
      addUsers(userIds: $userIds) {
        id
        role
        status
        phoneNumber
        name
        email
        avatarUrl
      }
    }
  }
`)

export const userUpdateMutation = graphql(/* GraphQL */ `
  mutation UpdateUser($input: UserUpdateInput!, $userId: Int!) {
    user(userId: $userId) {
      update(input: $input) {
        role
        name
        email
        id
        avatarUrl
        phoneNumber
      }
    }
  }
`)

export const userActivateMutation = graphql(/* GraphQL */ `
  mutation UserActivate($userId: Int!) {
    user(userId: $userId) {
      activate {
        id
      }
    }
  }
`)

export const userDeactivateMutation = graphql(/* GraphQL */ `
  mutation UserDeactivate($userId: Int!) {
    user(userId: $userId) {
      deactivate {
        id
      }
    }
  }
`)

export const userAcceptAccessToCompanyMutation = graphql(/* GraphQL */ `
  mutation UserAcceptAccess($input: AcceptAccessToCompanyInput!, $userId: Int!) {
    user(userId: $userId) {
      acceptAccessToCompany(input: $input) {
        id
      }
    }
  }
`)

export const userRejectAccessToCompanyMutation = graphql(/* GraphQL */ `
  mutation UserRejectAccess($input: RejectAccessToCompanyInput!, $userId: Int!) {
    user(userId: $userId) {
      rejectAccessToCompany(input: $input) {
        id
      }
    }
  }
`)

export const userUpdateStateMutation = graphql(/* GraphQL */ `
  mutation UserUpdateState($input: UpdateStateInput!, $userId: Int!) {
    user(userId: $userId) {
      updateState(input: $input) {
        id
      }
    }
  }
`)
