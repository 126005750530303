import React from 'react'

import { Flex, Typography } from 'antd'

import { getDateTimeFormat } from '~/utils/getDateFormat'
import { EntityModel, NoteEntity } from '~api/gql-generated/graphql'

interface NoteProps {
  noteData: NoteEntity
  relatedModel: EntityModel
  editable: boolean
  onEdit: (editableNote: NoteEntity, changedText: string) => void
}
export const Note: React.FC<NoteProps> = ({ noteData, editable, onEdit, relatedModel }) => {
  return (
    <Flex vertical gap={2} style={{ marginLeft: 12 }}>
      <Flex align="baseline">
        <Typography.Title level={5}>{noteData.author.name}</Typography.Title>
        {/*<LinkToEntityById relatedModel={relatedModel} entityId={noteData.authorId} />*/}
        ID:{noteData.authorId}
      </Flex>
      <Typography.Paragraph
        ellipsis={{ rows: 2, expandable: true, symbol: 'more' }}
        copyable
        editable={editable ? { onChange: (v) => onEdit(noteData, v) } : undefined}
      >
        {noteData.text}
      </Typography.Paragraph>
      <Typography.Text type="secondary">
        {noteData?.createdAt ? getDateTimeFormat(noteData?.createdAt as string) : '-'}
      </Typography.Text>
    </Flex>
  )
}
