import { useMutation } from '@tanstack/react-query'

export interface CreateDealSpecification {
  dealId: number
  createDealSpecificationBody: CreateDealSpecificationBody
  labAnalysis?: File
}

import { CreateDealSpecificationBody } from '~api/generated'
import { useUploadFileAndGetId } from '~hooks/_utils'
import { useApi } from '~providers/api'

export const useCreateDealSpecification = () => {
  const api = useApi()
  const uploadFileAndGetId = useUploadFileAndGetId()

  const useCreateDealFn = async (data: CreateDealSpecification) => {
    if (!data.dealId) {
      return null
    }

    const fileKey = await uploadFileAndGetId.mutateAsync(data.labAnalysis)

    const res = await api.deal.dealControllerCreateDealSpecification({
      dealId: data.dealId,
      createDealSpecificationBody: {
        ...data.createDealSpecificationBody,
        fileKeys: [fileKey],
      },
    })

    return res.data
  }

  return useMutation(useCreateDealFn)
}
