import React, { useState } from 'react'

import { Modal } from 'antd'
import { observer } from 'mobx-react-lite'

import { useMutation, useQuery } from '@apollo/client'
import { ApolloError } from '@apollo/client/errors'

import { userUiSettingsStore } from '~/store/userUiSettingsStore'
import { appToast, httpErrorHandler, modalSharedSettings } from '~/utils'
import { graphqlErrorHandler } from '~/utils/graphqlErrorHandler'
import { UserRole } from '~api/gql-generated/graphql'
import { companyDeleteUserMutation } from '~api/gql-mutations/company.mutation.graphql'
import {
  userAcceptAccessToCompanyMutation,
  userActivateMutation,
  userAddToCompanyMutation,
  userCreateMutation,
  userDeactivateMutation,
  userRejectAccessToCompanyMutation,
  userUpdateMutation,
} from '~api/gql-mutations/user.mutation.graphql'
import { getMeCompaniesQuery, getMeQuery } from '~api/gql-query/me.query.graphql'
import { getUsersQuery } from '~api/gql-query/users.query.graphql'
import { CompanyEmployeeAdded } from '~pages/AccountPage/modules/CompanyEmployeePage/components/CompanyEmployee/CompanyEmployeeAdded'
import { CompanyEmployeeHeader } from '~pages/AccountPage/modules/CompanyEmployeePage/components/CompanyEmployee/CompanyEmployeeHeader'
import { CompanyEmployeeRequested } from '~pages/AccountPage/modules/CompanyEmployeePage/components/CompanyEmployee/CompanyEmployeeRequested'
import { useAuth } from '~providers/auth'

import {
  AddEmployeeFromAnotherCompanyForm,
  AddEmployeeFromAnotherCompanyFormValues,
} from './components/AddEmployeeFromAnotherCompanyForm'
import { AddEmployeeToCompanyForm, AddEmployeeToCompanyFormFormValues } from './components/AddEmployeeToCompanyForm'
import { RejectEmployeeFromCompany } from './components/RejectEmployeeFromCompany'

export const CompanyEmployeePage: React.FC = observer(() => {
  const { isDemoUser, companyId: currentCompanyId } = useAuth()
  const companyId = currentCompanyId

  const getMe = useQuery(getMeQuery)
  const getCompanies = useQuery(getMeCompaniesQuery)
  const getUsersRequested = useQuery(getUsersQuery, {
    variables: {
      pagination: { take: 100, skip: 0 },
      filter: { fromCompanyIds: [companyId], onlyRequestedAccess: true },
    },
  })
  const getUsersAdded = useQuery(getUsersQuery, {
    variables: {
      pagination: { take: 100, skip: 0 },
      filter: { fromCompanyIds: [companyId], onlyRequestedAccess: false },
    },
  })
  const [userCreate, userCreateState] = useMutation(userCreateMutation)
  const [userAddToCompany, userAddToCompanyState] = useMutation(userAddToCompanyMutation)
  const [userUpdate, userUpdateState] = useMutation(userUpdateMutation)
  const [deleteUserFromCompany, deleteUserFromCompanyState] = useMutation(companyDeleteUserMutation)
  const [userActivate, userActivateState] = useMutation(userActivateMutation)
  const [userDeactivate, userDeactivateState] = useMutation(userDeactivateMutation)
  const [userRejectAccess, userRejectAccessState] = useMutation(userRejectAccessToCompanyMutation)
  const [userAcceptAccess, userAcceptAccessState] = useMutation(userAcceptAccessToCompanyMutation)

  const [isAddEmployeeModalOpen, setAddEmployeeModalOpen] = useState(false)
  const [isTransferEmployeeModalOpen, setTransferEmployeeModalOpen] = useState(false)
  const [selectedUserAddedId, setSelectedUserAddedId] = useState<number | null>(null)
  const [selectedUserRequestedId, setSelectedUserRequestedId] = useState<number | null>(null)

  const selectedCompany = getCompanies.data?.me?.companies?.find((x) => x.id === companyId)

  const usersRequested = getUsersRequested.data?.users.users ?? []
  const usersAdded = getUsersAdded.data?.users.users ?? []
  const selectedUserAdded = usersAdded.find((x) => x.id === selectedUserAddedId)

  const canUserCreateNewUser = (getMe.data?.me.actions.createUser || isDemoUser) ?? false
  const canUserTransferUser = (selectedCompany?.actions.addUser || isDemoUser) ?? false
  const canDeleteUserFromCompany = (selectedCompany?.actions.deleteUser || isDemoUser) ?? false

  const getUsersRefetch = async () => {
    await Promise.all([getUsersAdded.refetch(), getUsersRequested.refetch()])
  }

  const onRejectEmployeeHandler = async () => {
    if (!selectedUserRequestedId) {
      console.error('[onEditEmployeeHandler]: userId is required')
      return
    }
    try {
      await userRejectAccess({
        variables: {
          userId: selectedUserRequestedId,
          input: {
            companyId,
          },
        },
      })
      appToast.success({ message: 'Сотрудник отклонен' })
    } catch (e) {
      if (e instanceof ApolloError) {
        graphqlErrorHandler(e, 'Ошибка при отклонении сотрудника')
      }
    }
    await getUsersRefetch()
    setSelectedUserRequestedId(null)
  }

  const onAcceptEmployeeHandler = async (userId: number, role: UserRole) => {
    if (isDemoUser) {
      userUiSettingsStore.showDemoUserAuthModal()
      return
    }
    try {
      await userAcceptAccess({
        variables: {
          userId,
          input: {
            companyId,
            role,
          },
        },
      })
      appToast.success({ message: 'Сотрудник принят' })
    } catch (e) {
      if (e instanceof ApolloError) {
        graphqlErrorHandler(e, 'Ошибка при принятии сотрудника')
      }
    }
    await getUsersRefetch()
  }

  const onCreateEmployeeHandler = async (formValues: AddEmployeeToCompanyFormFormValues) => {
    if (isDemoUser) {
      userUiSettingsStore.showDemoUserAuthModal()
      return
    }
    try {
      await userCreate({
        variables: {
          input: {
            companyIds: [companyId],
            name: formValues.name,
            phoneNumber: formValues.phoneNumber,
            role: formValues.position,
            avatarFileKey: undefined,
            email: formValues.email,
            position: formValues.position,
          },
        },
      })
      setAddEmployeeModalOpen(false)
      appToast.success({ message: 'Сотрудник добавлен' })
    } catch (e) {
      if (e instanceof ApolloError) {
        const regex = /User with phone number \+7\d{10} already exists/
        if (regex.test(e.message)) {
          Modal.error({
            title: 'Ошибка при добавлении сотрудника',
            content: 'Такой номер телефона уже используется другим пользователем',
          })
          return
        }
        graphqlErrorHandler(e, 'Ошибка при добавлении сотрудника')
      }
    }
    await getUsersRefetch()
  }

  const onTransferEmployeeHandler = async (formValues: AddEmployeeFromAnotherCompanyFormValues) => {
    if (isDemoUser) {
      userUiSettingsStore.showDemoUserAuthModal()
      return
    }
    try {
      await userAddToCompany({
        variables: {
          companyId,
          userIds: formValues.usersIds,
        },
      })
      setTransferEmployeeModalOpen(false)
      appToast.success({ message: formValues.usersIds.length > 1 ? 'Сотрудники добавлен' : 'Сотрудник добавлен' })
    } catch (e) {
      if (e instanceof ApolloError) {
        graphqlErrorHandler(e, 'Ошибка при добавлении сотрудника')
      }
    }
    await getUsersRefetch()
  }

  const onEditEmployeeHandler = async (formValues: AddEmployeeToCompanyFormFormValues) => {
    if (!selectedUserAddedId) {
      console.error('[onEditEmployeeHandler]: userId is required')
      return
    }

    try {
      await userUpdate({
        variables: {
          userId: selectedUserAddedId,
          input: {
            name: formValues.name,
            role: formValues.position,
            email: formValues.email,
            position: formValues.position,
          },
        },
      })
      setTransferEmployeeModalOpen(false)
      appToast.success({ message: 'Данные о сотруднике изменены' })
      await getUsersRefetch()
      setSelectedUserAddedId(null)
    } catch (e) {
      if (e instanceof ApolloError) {
        graphqlErrorHandler(e, 'Ошибка при редактировании данных сотрудника')
      }
    }
  }

  const onDeleteUserFromCompanyHandler = async (userId: number) => {
    if (isDemoUser) {
      userUiSettingsStore.showDemoUserAuthModal()
      return
    }
    Modal.confirm({
      title: 'Удалить из компании',
      content: 'Вы действительно хотите удалить данного сотрудника из компании? ',
      okText: 'Удалить',
      cancelText: 'Отмена',
      async onOk() {
        try {
          await deleteUserFromCompany({ variables: { userId, companyId } })
          await getUsersRefetch()
          appToast.success({ description: 'Сотрудник удален из компании' })
        } catch (e) {
          httpErrorHandler(e, 'Ошибка при удалении сотрудника из компании')
        }
      },
    })
  }

  const onActivateEmployeeHandler = async (userId: number) => {
    Modal.confirm({
      title: 'Активировать',
      content: 'После активации сотрудник снова сможет иметь доступ к компании.',
      okText: 'Активировать',
      okButtonProps: { loading: userActivateState.loading },
      cancelText: 'Отмена',
      async onOk() {
        try {
          await userActivate({
            variables: { userId },
          })
          appToast.success({ message: 'Сотрудник активирован' })
        } catch (e) {
          if (e instanceof ApolloError) {
            graphqlErrorHandler(e, 'Ошибка при активации сотрудника')
          }
        }
        await getUsersRefetch()
      },
    })
  }

  const onDeactivateEmployeeHandler = async (userId: number) => {
    Modal.confirm({
      title: 'Деактивировать',
      content: 'Вы действительно хотите деактивировать данного сотрудника? Его доступ к компании приостановится.',
      okText: 'Деактивировать',
      okButtonProps: { loading: userDeactivateState.loading },
      cancelText: 'Отмена',
      async onOk() {
        try {
          await userDeactivate({
            variables: { userId },
          })
          appToast.success({ message: 'Сотрудник деактивирован' })
        } catch (e) {
          if (e instanceof ApolloError) {
            graphqlErrorHandler(e, 'Ошибка при деактивации сотрудника')
          }
        }
        await getUsersRefetch()
      },
    })
  }

  return (
    <>
      <CompanyEmployeeHeader
        canUserCreateNewUser={canUserCreateNewUser}
        canUserTransferUser={canUserTransferUser}
        onUserCreate={() => setAddEmployeeModalOpen(true)}
        onUserTransfer={() => setTransferEmployeeModalOpen(true)}
      />

      {usersRequested.length > 0 && (
        <CompanyEmployeeRequested
          users={usersRequested}
          loading={getUsersRequested.loading}
          onUserReject={(userId) => setSelectedUserRequestedId(userId)}
          onUserAccept={onAcceptEmployeeHandler}
        />
      )}

      <CompanyEmployeeAdded
        users={usersAdded}
        loading={getUsersAdded.loading}
        onUserEdit={(userId) => setSelectedUserAddedId(userId)}
        onUserDelete={onDeleteUserFromCompanyHandler}
        onUserActivate={onActivateEmployeeHandler}
        onUserDeactivate={onDeactivateEmployeeHandler}
        canDeleteUserFromCompany={canDeleteUserFromCompany}
      />

      <Modal
        {...modalSharedSettings}
        width={400}
        open={!!selectedUserRequestedId}
        onCancel={() => setSelectedUserRequestedId(null)}
        title="Отклонить запрос"
      >
        <RejectEmployeeFromCompany
          loading={userRejectAccessState.loading}
          onBack={() => setSelectedUserRequestedId(null)}
          onSubmit={onRejectEmployeeHandler}
        />
      </Modal>

      <Modal
        {...modalSharedSettings}
        width={400}
        open={isAddEmployeeModalOpen}
        onCancel={() => setAddEmployeeModalOpen(false)}
        title="Добавить нового сотрудника"
      >
        <AddEmployeeToCompanyForm
          loading={userCreateState.loading}
          onBack={() => setAddEmployeeModalOpen(false)}
          onSubmit={onCreateEmployeeHandler}
        />
      </Modal>

      <Modal
        {...modalSharedSettings}
        width={400}
        open={isTransferEmployeeModalOpen}
        onCancel={() => setTransferEmployeeModalOpen(false)}
        title="Добавить сотрудника из другой компании"
      >
        <AddEmployeeFromAnotherCompanyForm
          loading={userAddToCompanyState.loading}
          onBack={() => setTransferEmployeeModalOpen(false)}
          onSubmit={onTransferEmployeeHandler}
        />
      </Modal>

      <Modal
        {...modalSharedSettings}
        width={400}
        open={!!selectedUserAddedId}
        onCancel={() => setSelectedUserAddedId(null)}
        title="Редактировать"
      >
        <AddEmployeeToCompanyForm
          defaultValues={
            selectedUserAdded
              ? {
                  name: selectedUserAdded.name,
                  phoneNumber: selectedUserAdded.phoneNumber,
                  position: selectedUserAdded.role,
                  email: selectedUserAdded.email ?? undefined,
                }
              : undefined
          }
          loading={userUpdateState.loading}
          onBack={() => setSelectedUserAddedId(null)}
          onSubmit={onEditEmployeeHandler}
        />
      </Modal>
    </>
  )
})
