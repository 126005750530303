import React from 'react'

import { DaDataAddress, DaDataSuggestion } from 'react-dadata/dist/types'

import { InputDadata } from '~/ui-components'
import { CustomControlInterface } from '~shared/controls/CustomControl.interface'

interface AddressControlAutocompleteProps extends CustomControlInterface {
  onChange: (value: string | undefined) => void
  value: string | undefined
  onAddressSelect: (value: DaDataSuggestion<DaDataAddress> | undefined) => void
  hasError?: boolean
  id?: string
  disabled?: boolean
}

export const AddressControlAutocomplete: React.FC<AddressControlAutocompleteProps> = React.forwardRef<
  HTMLInputElement,
  AddressControlAutocompleteProps
>(({ id, value, onChange, onAddressSelect, hasError, disabled = false }, ref) => {
  const onChangeInputHandler = (v: string | undefined) => {
    onChange(v)
  }
  const onSelectAddressHandler = (v: DaDataSuggestion<DaDataAddress> | undefined) => {
    onAddressSelect(v)
  }

  return (
    <InputDadata
      id={id}
      disabled={disabled}
      placeholder="г Москва, Московская ул. 1 дом 1. стр. 1."
      inputValue={value}
      onChangeInput={onChangeInputHandler}
      onSelect={onSelectAddressHandler}
      variant="address"
      inputStatus={hasError ? 'error' : undefined}
    />
  )
})

AddressControlAutocomplete.displayName = 'AddressControlAutocomplete'
