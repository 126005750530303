import React from 'react'

import { Button, Table, Tooltip, Upload } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import { FiDownload, FiTrash2, FiUpload } from 'react-icons/fi'

import { appToast } from '~/utils'
import { getDealDocumentName, getLastDealDocument } from '~/utils/deals'
import { DealDocumentDataTypeEnum, DealDocumentTemplateData } from '~api/generated'
import { useGetDealTemplateDocuments, useUploadDealTemplateDocument } from '~hooks/deal-documents'

import * as S from './TemplateDocuments.styled'

export const TemplateDocuments = () => {
  const {
    data: documentsData,
    refetch: refetchTemplateDocuments,
    isLoading: isLoadingTemplateDocuments,
    isFetching: isFetchingTemplateDocuments,
  } = useGetDealTemplateDocuments({})
  const uploadDealTemplateDocument = useUploadDealTemplateDocument()

  const handleUploadDocument = async (documentType: DealDocumentDataTypeEnum, document: File) => {
    try {
      await uploadDealTemplateDocument.mutateAsync({
        documentType,
        createDealDocumentBody: {
          documentFile: document,
        },
      })
      appToast.success({
        description: 'Документ успешно загружен',
      })
      refetchTemplateDocuments()
    } catch (err) {
      appToast.success({
        description: 'Ошибка загрузки документа',
      })
    }
  }

  const columns: ColumnsType<DealDocumentTemplateData> = [
    {
      title: 'Документ',
      dataIndex: 'type',
      defaultSortOrder: 'ascend',
      sorter: (a, b) => getDealDocumentName(a.type).localeCompare(getDealDocumentName(b.type)),
      render: (value) => {
        return getDealDocumentName(value) || value
      },
    },
    {
      title: 'Тип',
      dataIndex: 'type',
      sorter: (a, b) => a.type.localeCompare(b.type),
      render: (value) => {
        return value
      },
    },
    {
      title: 'Действия',
      width: 100,
      render: (value) => {
        const lastFile = getLastDealDocument(value)
        return (
          <S.ActionsWrapper>
            {!!lastFile && (
              <Tooltip title="Скачать">
                <Button href={lastFile.files[0].source} target="_blank" type="text" icon={<FiDownload size={24} />} />
              </Tooltip>
            )}
            <Tooltip title="Загрузить">
              <Upload showUploadList={false} customRequest={(r) => handleUploadDocument(value.type, r.file as File)}>
                <Button type="text" icon={<FiUpload size={24} />} />
              </Upload>
            </Tooltip>
            {/*<Tooltip title="Удалить">*/}
            {/*  <Button danger type="text" icon={<FiTrash2 size={24} />} />*/}
            {/*</Tooltip>*/}
          </S.ActionsWrapper>
        )
      },
    },
  ]

  return (
    <>
      <Table
        rowKey="type"
        loading={isLoadingTemplateDocuments || isFetchingTemplateDocuments}
        columns={columns}
        dataSource={documentsData}
        pagination={false}
        scroll={{ x: 'min-content' }}
        // expandable={{
        //   expandedRowRender: (record) => <div>{record.instances.toString()}</div>,
        // }}
      />
    </>
  )
}
