import { makeAutoObservable } from 'mobx'
import { makePersistable } from 'mobx-persist-store'

import { VipClientId } from '~layout/WelcomePageV2/VipCards'

type AdvertType = 'BUY' | 'SELL'

class UserUiSettingsStore {
  isAppMenuCollapsed = false
  isShowAuctionDemoPage = true
  basisAdvertType: AdvertType = 'BUY'
  isShowDemoUserAuthModal = false
  vipClientId: VipClientId = VipClientId.GAP

  constructor() {
    makeAutoObservable(this)
    makePersistable(this, {
      name: 'UserUiSettingsStore',
      properties: ['isShowAuctionDemoPage', 'vipClientId'],
      storage: window.localStorage,
    })
  }

  resetToDefaultState() {
    this.isAppMenuCollapsed = false
    this.isShowAuctionDemoPage = true
    this.basisAdvertType = 'BUY'
  }

  toggleCollapseAppMenu() {
    this.isAppMenuCollapsed = !this.isAppMenuCollapsed
  }

  hideAuctionDemoPage() {
    this.isShowAuctionDemoPage = false
  }

  setBasisAdvertType(type: AdvertType) {
    this.basisAdvertType = type
  }

  showDemoUserAuthModal() {
    this.isShowDemoUserAuthModal = true
  }

  hideDemoUserAuthModal() {
    this.isShowDemoUserAuthModal = false
  }

  setVipClientId(id: VipClientId) {
    this.vipClientId = id
  }
}

export const userUiSettingsStore = new UserUiSettingsStore()
