import React from 'react'

import { RouteObject } from 'react-router-dom'

import { SettingsPage } from '~/pages'
import { appRoutes } from '~/router'
import { CultureParametersTab, ElevatorDetails, ElevatorsAdmin, TemplateDocuments } from '~pages/SettingsPage/modules'

import { RequireAuth } from '../../providers/auth'

export const settingsRouter: RouteObject[] = [
  {
    path: appRoutes.settings.url,
    element: (
      <RequireAuth>
        <SettingsPage />
      </RequireAuth>
    ),
    children: [
      {
        path: appRoutes.settingsCultureParams.url,
        element: <CultureParametersTab />,
      },
      {
        path: appRoutes.settingsTemplateDocuments.url,
        element: <TemplateDocuments />,
      },
      {
        path: appRoutes.settingsElevators.url,
        element: <ElevatorsAdmin />,
      },
    ],
  },
  {
    path: appRoutes.settingsElevatorsDetails.url,
    element: <ElevatorDetails />,
  },
]
