import { codeUnreachable } from '~/utils/code-unreachable'
import { FreightDealStatus } from '~api/gql-generated/graphql'

export function getFreightLastResponseColor(value: FreightDealStatus): string {
  switch (value) {
    case FreightDealStatus.Agreement:
    case FreightDealStatus.Realised:
    case FreightDealStatus.Shipment:
    case FreightDealStatus.Rejected:
      return '#DCF5E8'
    case FreightDealStatus.OnModeration:
      return '#FCF2BD'
    case FreightDealStatus.RejectedAfterModeration:
      return '#FFCCC7'
    default:
      return codeUnreachable(value)
  }
}
