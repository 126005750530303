import React, { useEffect } from 'react'

import { Button, Empty, Flex, Pagination, Table, TabsProps } from 'antd'
import { observer } from 'mobx-react-lite'
import { FiChevronDown, FiChevronUp } from 'react-icons/fi'
import { useNavigate } from 'react-router-dom'

import { useQuery } from '@apollo/client'

import { usePagination } from '~/hooks'
import { AppHeader } from '~/layout'
import { appRoutes } from '~/router'
import { myCargoFilterStore } from '~/store/filters'
import { Text } from '~/ui-components'
import { graphqlErrorHandler } from '~/utils/graphqlErrorHandler'
import { isIntegerString } from '~/utils/isIntegerString'
import { FreightOrderEntity, FreightOrderFilterInput } from '~api/gql-generated/graphql'
import { freightOrdersQuery } from '~api/gql-query/freight-orders.query.graphql'
import { CargoTableRowDetails } from '~pages/LogisticsPage/modules/MyCargos/components/CargoTableRowDetails/CargoTableRowDetails'
import { CargoFilters } from '~pages/LogisticsPage/shared/CargoFilters/CargoFilters'
import { getFreightOrdersColumns } from '~pages/LogisticsPage/shared/getFreightOrdersColumns'
import { useAuth } from '~providers/auth'
import { SearchInput } from '~shared/components/SearchInput'

export const MyCargos: React.FC = observer(() => {
  const { companyId } = useAuth()
  const navigate = useNavigate()
  const { currentPage, setCurrentPage, pageSize, setPageSize, pageSizeOptions } = usePagination()

  const searchFilters: Partial<FreightOrderFilterInput> = {
    search: isIntegerString(myCargoFilterStore.search) ? undefined : myCargoFilterStore.search,
    freightOrderIds: isIntegerString(myCargoFilterStore.search) ? [Number(myCargoFilterStore.search)] : undefined,
  }

  const freightOrdersFn = useQuery(freightOrdersQuery, {
    variables: {
      filter: {
        companyIds: [companyId],
        ...myCargoFilterStore.mappedCurrentFiltersToResponse,
        ...searchFilters,
      },
      pagination: { currentPage, pageSize },
    },
    onError: graphqlErrorHandler,
    fetchPolicy: 'cache-and-network',
  })

  useEffect(() => {
    void freightOrdersFn.refetch()
    setCurrentPage(1)
  }, [
    myCargoFilterStore.filters.weightKgFrom,
    myCargoFilterStore.filters.weightKgTo,
    myCargoFilterStore.filters.pricePerTonTo,
    myCargoFilterStore.filters.pricePerTonFrom,
    myCargoFilterStore.filters.priceWithNds,
    myCargoFilterStore.filters.fromRegionId,
    myCargoFilterStore.filters.toRegionId,
    myCargoFilterStore.filters.productTypeId,
    myCargoFilterStore.filters.paymentTerm,
    myCargoFilterStore.filters.period,
    myCargoFilterStore.search,
  ])

  const onCreateNewCargo = async () => {
    navigate(appRoutes.logisticsCreateLoad.url)
  }

  const freightOrders = freightOrdersFn.data?.freightOrders.freightOrders ?? []
  const pagination = freightOrdersFn.data?.freightOrders.pagination

  const onActionArrowClickHandler = (record: FreightOrderEntity) => {
    navigate(appRoutes.logisticsLoadCard.url.replace(':id', record.id.toString()))
  }

  const columns = getFreightOrdersColumns(onActionArrowClickHandler, { hasDistanceToStartKm: false })

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: 'Все',
    },
    {
      key: '2',
      label: 'Опубликованные',
      disabled: true,
    },
    {
      key: '3',
      label: 'В работе',
      disabled: true,
    },
    {
      key: '4',
      label: 'Завершенные',
      disabled: true,
    },
  ]

  const locale = {
    emptyText: (
      <Flex vertical gap={14}>
        <Empty description="" />
        <Text variant="h4">Здесь будут ваши грузы</Text>
        <Text variant="normal">Чтобы совершать сделки вам необходимо добавить груз.</Text>
        <div>
          <Button type="primary" onClick={onCreateNewCargo}>
            Добавить груз
          </Button>
        </div>
      </Flex>
    ),
  }

  return (
    <Flex vertical>
      <AppHeader title={appRoutes.logisticMyCargos.title} />
      <Flex vertical gap={24} style={{ padding: 24 }}>
        <Flex justify="space-between">
          <Text variant="h1">Грузы</Text>
          <Button type="primary" onClick={onCreateNewCargo}>
            Добавить груз
          </Button>
        </Flex>

        {/*<Tabs defaultActiveKey="1" items={items} />*/}

        <Flex justify="space-between">
          <CargoFilters store="myCargoFilterStore" />
          <SearchInput
            style={{ width: 280 }}
            defaultValue={myCargoFilterStore.search ?? ''}
            onSearch={(x) => myCargoFilterStore.setSearch(x.trim() ? x : undefined)}
            autoFocus={true}
            placeholder="Поиск по ID, грузу, маршруту"
          />
        </Flex>

        <Table
          locale={myCargoFilterStore.hasActiveFilters || !!myCargoFilterStore.search ? undefined : locale}
          rowKey="id"
          className="table-interactive"
          loading={freightOrdersFn.loading}
          columns={columns}
          dataSource={freightOrders}
          scroll={{ x: 'max-content' }}
          expandable={{
            expandedRowRender: (record) => <CargoTableRowDetails freight={record} />,
            showExpandColumn: true,
            expandIcon: ({ expanded, onExpand, record }) =>
              expanded ? (
                <Flex
                  align="center"
                  gap={10}
                  style={{ color: 'var(--primary-main-color)' }}
                  onClick={(e) => onExpand(record, e)}
                >
                  <FiChevronUp size={20} /> Свернуть
                </Flex>
              ) : (
                <Flex
                  align="center"
                  gap={10}
                  style={{ color: 'var(--primary-main-color)' }}
                  onClick={(e) => onExpand(record, e)}
                >
                  <FiChevronDown size={20} /> Развернуть
                </Flex>
              ),
          }}
          pagination={false}
        />

        {pagination && (
          <Pagination
            style={{ marginTop: 'auto' }}
            current={pagination?.currentPage}
            total={pagination?.total}
            pageSize={pagination?.pageSize}
            pageSizeOptions={pageSizeOptions}
            onShowSizeChange={(x, size) => setPageSize(size)}
            showSizeChanger
            onChange={setCurrentPage}
          />
        )}
      </Flex>
    </Flex>
  )
})
