import styled from 'styled-components'

import { css } from '@styled-system/css'

export const Desktop = styled.div(
  css({
    display: ['none', null, 'block'],
  })
)

export const Mobile = styled.div(
  css({
    display: ['block', null, 'none'],
  })
)

export const Content = styled.div`
  display: grid;
  gap: 16px;
`

export const Filters = styled.div(
  css({
    maxWidth: '700px',
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    marginLeft: 'auto',
    marginBottom: '16px',
    gap: '16px',
  })
)
