import { AddressTypes } from '~pages/LogisticsPage/modules/MyCargos/components/CreateLoad/CreateLoad.types'
import { codeUnreachable } from '~/utils/code-unreachable'

export const getAddressType = (addressType: AddressTypes) => {
  switch (addressType) {
    case AddressTypes.address:
      return 'Новый адрес'
    case AddressTypes.warehouse:
      return 'Мой склад'
    case AddressTypes.elevator:
      return 'Базис'
    default:
      return codeUnreachable(addressType)
  }
}
