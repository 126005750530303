import React from 'react'

import { Select } from 'antd'
import { BaseSelectRef } from 'rc-select'

import { CustomControlInterface } from '~shared/controls/CustomControl.interface'

interface AxlesCountSelectControlProps extends CustomControlInterface {
  id?: string
  value: number | undefined
  onChange: (value: number) => void
  hasError?: boolean
}

export const AxlesCountSelectControl: React.FC<AxlesCountSelectControlProps> = React.forwardRef<
  BaseSelectRef,
  AxlesCountSelectControlProps
>(({ id, value, onChange, hasError }, ref) => {
  return (
    <Select
      ref={ref}
      allowClear
      style={{ width: '100%', maxWidth: '342px' }}
      placeholder="Выберите"
      value={value}
      onChange={onChange}
      status={hasError ? 'error' : undefined}
    >
      {[2, 3, 4, 5, 6].map((axlesCount) => (
        <Select.Option key={axlesCount} value={axlesCount}>
          {axlesCount}
        </Select.Option>
      ))}
    </Select>
  )
})

AxlesCountSelectControl.displayName = 'AxlesCountSelectControl'
