import { graphql } from '~api/gql-generated'

export const freightResponseUpdateStatusMutation = graphql(/* GraphQL */ `
  mutation FreightResponseUpdateStatus($freightDealId: Int!, $input: FreightDealStatusUpdateInput!) {
    freightOrderResponse(freightDealId: $freightDealId) {
      updateStatus(input: $input) {
        id
      }
    }
  }
`)
