import { useMutation } from '@tanstack/react-query'

import { DealApiDealControllerUpdateAdditionalServicesRequest } from '~api/generated'
import { useApi } from '~providers/api'

export const useUpdateDealAdditionalService = () => {
  const api = useApi()

  return useMutation((request: DealApiDealControllerUpdateAdditionalServicesRequest) => {
    return api.deal.dealControllerUpdateAdditionalServices(request)
  })
}
