import { Upload } from 'antd'
import styled from 'styled-components'

import { css } from '@styled-system/css'

export const UploadStyled = styled(Upload)(
  css({
    '.ant-upload': {
      width: '100%',
    },
  })
)

export const ActionWrapper = styled.div(
  css({
    display: 'flex',
    gap: '16px',
    alignItems: 'center',
  })
)
