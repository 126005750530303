import React, { useState } from 'react'

import { Skeleton } from 'antd'
import { useParams } from 'react-router'

import { useGetDealLogisticsInfo } from '~hooks/deal-logistics'
import { useGetModeratorDeal } from '~hooks/moderator'
import { DocumentsProduct } from '~pages/Deals/components'
import { CarrierInfoCard, LoadingInfoCard, UnloadingInfoCard } from '~pages/Deals/modules/DealLogistics/components'
import { SellerOrBuyerRadioControl } from '~pages/ModeratorPage/components/ModeratorDeals/components'

import * as S from './ModeratorDealLogistics.styled'

export const ModeratorDealLogistics = () => {
  const params = useParams<{ id: string | undefined }>()
  const complexDealId = Number(params.id)
  const dealFn = useGetModeratorDeal(complexDealId)
  const [dealSide, setDealSide] = useState<'seller' | 'buyer'>('seller')
  const dealId = dealSide === 'seller' ? dealFn.data?.dealWithSeller?.id : dealFn.data?.dealWithBuyer?.id

  const dealLogisticsInfoFn = useGetDealLogisticsInfo({ dealId: dealId ?? NaN })

  if (dealLogisticsInfoFn.isLoading || dealFn.isLoading || !dealId) {
    return <Skeleton />
  }

  return (
    <div>
      <S.TopActionsWrapper>
        <SellerOrBuyerRadioControl value={dealSide} onChange={(value) => setDealSide(value)} />
      </S.TopActionsWrapper>

      <S.ContentWrapper>
        <S.Column>
          <LoadingInfoCard dealId={dealId} isEditable />
          <UnloadingInfoCard dealId={dealId} isEditable />
          <CarrierInfoCard dealId={dealId} isEditable dealSide={dealSide} />
        </S.Column>

        <S.Column>
          {dealId && (
            <DocumentsProduct
              dealId={dealId}
              dealSide={dealSide}
              title="Товаросопроводительные документы"
              documentType="transportation"
            />
          )}
        </S.Column>
      </S.ContentWrapper>
    </div>
  )
}
