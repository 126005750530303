import React, { useState } from 'react'

import { Button } from 'antd'
import { addDays } from 'date-fns'
import { observer } from 'mobx-react-lite'
import { Link, useParams } from 'react-router-dom'

import { useQueryClient } from '@tanstack/react-query'

import { AppHeader } from '~/layout'
import { appRoutes } from '~/router'
import { userUiSettingsStore } from '~/store/userUiSettingsStore'
import { Text } from '~/ui-components'
import { appToast, httpErrorHandler } from '~/utils'
import { getNoun } from '~/utils/getNoun'
import { useUploadFileAndGetId } from '~hooks/_utils'
import { useGetCurrentRole } from '~hooks/auth'
import { useCreateElevatorAdvertBuy, useCreateElevatorAdvertSell } from '~hooks/elevator-advert'
import { useGetElevator } from '~hooks/elevators'
import { CreateAdvertFormByBuyerValues } from '~pages/BasisPage/components/CreateAdvertFormByBuyer'
import { CreateAdvertFormBySellerValues } from '~pages/BasisPage/components/CreateAdvertFormBySeller'
import { CreateBasisAdvertFormModal } from '~pages/BasisPage/components/CreateBasisAdvertFormModal'
import { useAuth } from '~providers/auth'
import { ElevatorAdvertTypeSelectControl } from '~shared/controls'

import { ToBuyAdvertList } from './components/ToBuyAdvertList'
import { ToSellAdvertList } from './components/ToSellAdvertList'

import * as S from './BasisDetails.styled'

type AdvertType = 'BUY' | 'SELL'

export const BasisDetails: React.FC = observer(() => {
  const queryClient = useQueryClient()
  const { id } = useParams<{ id: string | undefined }>()
  const basisId = Number(id)
  const { companyId } = useAuth()
  const {
    company: { companyRole },
  } = useGetCurrentRole()
  const getElevatorFn = useGetElevator({ id: basisId })
  const [isCreateAdvertModalOpen, setIsCreateAdvertModalOpen] = useState(false)

  const uploadLabAnalysisAndGetIdFn = useUploadFileAndGetId()
  const createElevatorAdvertBuyFn = useCreateElevatorAdvertBuy()
  const createElevatorAdvertSellFn = useCreateElevatorAdvertSell()

  const basis = getElevatorFn?.data

  const onCancelCreateAdvertModalHandler = () => {
    setIsCreateAdvertModalOpen(false)
  }

  const onSubmitCreateAdvertModalHandler = async (formValues: CreateAdvertFormByBuyerValues) => {
    const product = formValues.culture
    if (!product || !companyId) {
      return
    }
    try {
      await createElevatorAdvertBuyFn.mutateAsync({
        createElevatorAdvertBuyBody: {
          elevatorId: formValues.elevatorId,
          companyId,
          allowOffersWithOtherParameters: formValues.allowOffersWithOtherParameters,
          pricePerTon: formValues.pricePerTon,
          product: {
            type: product.cultureTypeId ?? '',
            parameters: product.parameters?.map((p) => ({ type: p.type, value: p.value })) ?? [],
          },
          expiredAt: formValues.publicationDateCount
            ? addDays(new Date(), formValues.publicationDateCount).toISOString()
            : null,
        },
      })
      appToast.success({ description: 'Объявление создано' })

      setIsCreateAdvertModalOpen(false)
      await getElevatorFn.refetch()
      await queryClient.invalidateQueries(['elevatorsMy'])
      await queryClient.invalidateQueries(['myElevatorAdverts'])
    } catch (e) {
      httpErrorHandler(e, 'Ошибка при создании объявления')
    }
  }

  const onSubmitCreateAdvertModalBySellerHandler = async (formValues: CreateAdvertFormBySellerValues) => {
    const product = formValues.culture
    if (!product || !companyId) {
      return
    }
    try {
      const laboratoryAnalysisFileKey = formValues.requiredLaboratoryAnalysis
        ? undefined
        : await uploadLabAnalysisAndGetIdFn.mutateAsync(formValues.labAnalysis as File)

      const res = await createElevatorAdvertSellFn.mutateAsync({
        createElevatorAdvertSellBody: {
          companyId,
          laboratoryAnalysisFileKey,
          requiredLaboratoryAnalysis: formValues.requiredLaboratoryAnalysis,
          elevatorId: formValues.elevatorId,
          usingNds: formValues.usingNds,
          volume: formValues.volume,
          pricePerTon: formValues.pricePerTon,
          priceType: formValues.priceType,
          product: {
            type: product.cultureTypeId ?? '',
            parameters: product.parameters?.map((p) => ({ type: p.type, value: p.value })) ?? [],
          },
          expiredAt: formValues.publicationDateCount
            ? addDays(new Date(), formValues.publicationDateCount).toISOString()
            : null,
        },
      })
      appToast.success({ description: 'Объявление создано' })

      setIsCreateAdvertModalOpen(false)
      await queryClient.invalidateQueries(['elevatorsMy'])
      await queryClient.invalidateQueries(['myElevatorAdverts'])
    } catch (e) {
      httpErrorHandler(e, 'Ошибка при создании объявления')
    }
  }

  if (!companyRole) {
    return null
  }

  const totalOffers: number = (basis?.buyOfferCount ?? 0) + (basis?.sellOfferCount ?? 0)

  return (
    <>
      <AppHeader title={appRoutes.basisDetails.title} isBack />

      <S.ContentWrapper>
        <S.Header>
          <Text variant="h3">{basis?.name}</Text>
          <Button type="primary" htmlType="button" onClick={() => setIsCreateAdvertModalOpen(true)}>
            Создать объявление
          </Button>
        </S.Header>

        <Link to={appRoutes.basisInfo.url.replace(':id', basisId.toString())}>Подробнее о базисе</Link>

        <Text variant="normal">
          {totalOffers} {getNoun(totalOffers, 'объявление', 'объявления', 'объявлений')}
        </Text>

        <S.AdvertTypeToggle>
          <ElevatorAdvertTypeSelectControl
            variant="switch"
            value={userUiSettingsStore.basisAdvertType}
            onChange={(advertType) => userUiSettingsStore.setBasisAdvertType(advertType as AdvertType)}
          />

          <Text variant="normal">
            {userUiSettingsStore.basisAdvertType === 'BUY' ? basis?.buyOfferCount : basis?.sellOfferCount}{' '}
            {getNoun(basis?.buyOfferCount ?? 0, 'объявление', 'объявления', 'объявлений')} {''}{' '}
            {userUiSettingsStore.basisAdvertType === 'BUY' ? 'на покупку' : 'на продажу'}
          </Text>
        </S.AdvertTypeToggle>
      </S.ContentWrapper>

      {userUiSettingsStore.basisAdvertType === 'BUY' && <ToBuyAdvertList basisId={basisId} />}
      {userUiSettingsStore.basisAdvertType === 'SELL' && <ToSellAdvertList basisId={basisId} companyId={companyId} />}

      <CreateBasisAdvertFormModal
        open={isCreateAdvertModalOpen}
        onCancel={onCancelCreateAdvertModalHandler}
        currentCompanyRole={companyRole}
        loading={createElevatorAdvertSellFn.isLoading || createElevatorAdvertBuyFn.isLoading}
        onSubmitFormAsBuyer={onSubmitCreateAdvertModalHandler}
        onSubmitFormAsSeller={onSubmitCreateAdvertModalBySellerHandler}
        defaultElevatorId={basisId}
      />
    </>
  )
})
