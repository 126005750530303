import React, { useState } from 'react'

import { Button, Modal } from 'antd'
import { flushSync } from 'react-dom'
import { FiArrowLeft } from 'react-icons/fi'

import { Badge, Box } from '~/ui-components'
import { modalSharedSettings } from '~/utils'
import { TransportMode } from '~api/generated'
import { WarehouseCultureForm } from '~pages/Warehouses/components/WarehouseCultureForm'
import { useAuth } from '~providers/auth'

import { CreateWarehouseFormStep1 } from '../CreateWarehouseForms/step1'
import { CreateWarehouseFormStep2Rail } from '../CreateWarehouseForms/step3Rail'
import { CreateWarehouseFormStep3Car } from '../CreateWarehouseForms/step4Car'

import { CreateWarehouseFormStep1Values } from '../CreateWarehouseForms/step1/CreateWarehouseFormStep1.types'
import { CreateWarehouseFormStep2RailValues } from '../CreateWarehouseForms/step3Rail/CreateWarehouseFormStep2Rail.types'
import { CreateWarehouseFormStep3CarValues } from '../CreateWarehouseForms/step4Car/CreateWarehouseFormStep3Car.types'
import { WarehouseCultureFormValues } from '~pages/Warehouses/components/WarehouseCultureForm/WarehouseCultureForm.types'

export interface CreateWarehouseFormValue {
  step1OverallInfo: CreateWarehouseFormStep1Values
  step2Rail: CreateWarehouseFormStep2RailValues | null
  step3Car: CreateWarehouseFormStep3CarValues | null
  step4Culture: WarehouseCultureFormValues | null
}

export interface EditWarehouseFormValue {
  step1OverallInfo?: Partial<CreateWarehouseFormStep1Values>
  step2Rail?: Partial<CreateWarehouseFormStep2RailValues>
  step3Car?: Partial<CreateWarehouseFormStep3CarValues>
  step4Culture?: Partial<WarehouseCultureFormValues>
}

interface CreateWarehouseModalProps {
  open: boolean
  onCancel: () => void
  onSubmitOnCreate: (formValues: CreateWarehouseFormValue) => void
  onSubmitOnEdit?: (formValues: EditWarehouseFormValue) => void
  defaultValues?: EditWarehouseFormValue
  lastStepSubmitLoading: boolean
}

export const CreateWarehouseModal: React.FC<CreateWarehouseModalProps> = (props) => {
  const { companyRole } = useAuth()

  const [step, setStep] = useState<1 | 2 | 3 | 4>(1)
  const [step1FormValues, setStep1FormValues] = useState<CreateWarehouseFormStep1Values | null>(null)
  const [step2FormValues, setStep2FormValues] = useState<CreateWarehouseFormStep2RailValues | null>(null)
  const [step3FormValues, setStep3FormValues] = useState<CreateWarehouseFormStep3CarValues | null>(null)
  const [step4FormValues, setStep4FormValues] = useState<WarehouseCultureFormValues | null>(null)

  const isEdit = !!props.defaultValues

  const onCancelHandler = () => {
    setStep1FormValues(null)
    setStep(1)
    props.onCancel()
  }

  const onSubmitOnCreateHandler = (step4FormValues: WarehouseCultureFormValues) => {
    if (!step1FormValues) {
      console.warn('Не заполнен шаг 1')
      return
    }
    props.onSubmitOnCreate({
      step1OverallInfo: step1FormValues,
      step2Rail: step2FormValues,
      step3Car: step3FormValues,
      step4Culture: step4FormValues,
    })
    onCancelHandler()
  }

  const onSubmitOnEditHandler = (
    formValues: CreateWarehouseFormStep1Values | CreateWarehouseFormStep2RailValues | CreateWarehouseFormStep3CarValues,
    step: 1 | 2 | 3
  ) => {
    if (!props.onSubmitOnEdit) {
      return
    }

    if (step === 1) {
      props.onSubmitOnEdit({
        step1OverallInfo: formValues as CreateWarehouseFormStep1Values,
      })
      onCancelHandler()
      return
    }
    if (step === 2) {
      props.onSubmitOnEdit({
        step1OverallInfo: step1FormValues ?? undefined,
        step2Rail: formValues as CreateWarehouseFormStep2RailValues,
      })
      onCancelHandler()
      return
    }
    if (step === 3) {
      props.onSubmitOnEdit({
        step1OverallInfo: step1FormValues ?? undefined,
        step2Rail: step2FormValues ?? undefined,
        step3Car: formValues as CreateWarehouseFormStep3CarValues,
      })
      onCancelHandler()
      return
    }
  }

  const getTitle = () => {
    if (step === 1) {
      return isEdit ? 'Редактирование склада' : 'Создание склада'
    }
    if (step === 2) {
      return (
        <div style={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
          <Button type="text" icon={<FiArrowLeft size={24} />} onClick={() => setStep(2)} />
          Параметры по Ж/Д транспорту
        </div>
      )
    }
    if (step === 3) {
      return (
        <div style={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
          <Button type="text" icon={<FiArrowLeft size={24} />} onClick={() => setStep(step3FormValues ? 3 : 2)} />
          Параметры по автотранспорту
        </div>
      )
    }
    if (step === 4) {
      return (
        <div style={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
          <Button type="text" icon={<FiArrowLeft size={24} />} onClick={() => setStep(2)} />
          Добавление культуры
        </div>
      )
    }
  }

  return (
    <Modal {...modalSharedSettings} width={500} open={props.open} onCancel={onCancelHandler} title={getTitle()}>
      <div hidden={step !== 1}>
        <CreateWarehouseFormStep1
          companyRole={companyRole}
          nextButtonText={
            isEdit &&
            !(
              props.defaultValues?.step1OverallInfo?.transport?.includes(TransportMode.Road) ||
              props.defaultValues?.step1OverallInfo?.transport?.includes(TransportMode.Rail)
            )
              ? 'Сохранить'
              : 'Далее'
          }
          onNext={(formValues) => {
            setStep1FormValues(formValues)
            if (formValues.transport.includes(TransportMode.Rail)) {
              setStep(2)
              return
            }
            if (formValues.transport.includes(TransportMode.Road)) {
              setStep(3)
              return
            }
            if (isEdit) {
              onSubmitOnEditHandler(formValues, 1)
              return
            }
            setStep(4)
          }}
          onCancel={onCancelHandler}
          defaultValues={props.defaultValues?.step1OverallInfo}
        />
      </div>
      <div hidden={step !== 2}>
        <CreateWarehouseFormStep2Rail
          onPrev={() => setStep(1)}
          nextButtonText={
            isEdit &&
            !(
              props.defaultValues?.step1OverallInfo?.transport?.includes(TransportMode.Road) ||
              props.defaultValues?.step1OverallInfo?.transport?.includes(TransportMode.Rail)
            )
              ? 'Сохранить'
              : 'Далее'
          }
          onNext={(formValues) => {
            setStep2FormValues(formValues)
            if (step1FormValues?.transport.includes(TransportMode.Road)) {
              setStep(3)
              return
            }
            if (isEdit) {
              onSubmitOnEditHandler(formValues, 2)
              return
            }
            setStep(4)
          }}
          onCancel={onCancelHandler}
          defaultValues={props.defaultValues?.step2Rail}
        />
      </div>
      <div hidden={step !== 3}>
        <CreateWarehouseFormStep3Car
          nextButtonText={isEdit ? 'Сохранить' : 'Далее'}
          companyRole={companyRole}
          onPrev={() => setStep(step2FormValues ? 2 : 1)}
          onNext={(formValues) => {
            setStep3FormValues(formValues)

            if (isEdit) {
              onSubmitOnEditHandler(formValues, 3)
              return
            }
            setStep(4)
          }}
          onCancel={onCancelHandler}
          defaultValues={props.defaultValues?.step3Car}
        />
      </div>
      <div hidden={step !== 4}>
        <Box mt="-16px" mb={16}>
          <Badge color="yellow">Вы можете пропустить добавление культуры, нажав «Сохранить»</Badge>
        </Box>

        <WarehouseCultureForm
          // loading={props.lastStepSubmitLoading}
          // defaultValues={props.defaultValues?.step5ServicePrice}
          isEdit={false}
          onPrev={() => setStep(2)}
          onSave={(formValues) => {
            setStep4FormValues(formValues)
            onSubmitOnCreateHandler(formValues)
          }}
          onCancel={onCancelHandler}
          isLoading={props.lastStepSubmitLoading}
        />
      </div>
    </Modal>
  )
}
