import { useMutation } from '@tanstack/react-query'

import { CompanyApiCompanyControllerUpdateCompanyTypeRequest } from '~/api/generated'
import { useApi } from '~providers/api'

export const useUpdateCompanyAccountType = () => {
  const api = useApi()

  return useMutation((request: CompanyApiCompanyControllerUpdateCompanyTypeRequest) => {
    return api.companies.companyControllerUpdateCompanyType(request)
  })
}
