import { useMutation } from '@tanstack/react-query'

import { AddWarehousePositionActionBuyOutsideBody, AddWarehousePositionActionSellOutsideBody } from '~api/generated'
import { useApi } from '~providers/api'

export const useWarehouseCultureOutsideChange = (
  type: 'sellOutside' | 'buyOutside',
  warehousePositionId: number | null
) => {
  const api = useApi()

  const mutationFn = async (
    request: AddWarehousePositionActionSellOutsideBody | AddWarehousePositionActionBuyOutsideBody
  ): Promise<void> => {
    if (!warehousePositionId) {
      return Promise.reject('[useWarehouseCultureExternalSell]: warehousePositionId was not passed')
    }

    switch (type) {
      case 'buyOutside':
        await api.warehouses.warehousePositionControllerAddWarehousePositionActionBuyOutside({
          warehousePositionId,
          addWarehousePositionActionBuyOutsideBody: request,
        })
        return
      case 'sellOutside':
        await api.warehouses.warehousePositionControllerAddWarehousePositionActionSellOutside({
          warehousePositionId,
          addWarehousePositionActionSellOutsideBody: request,
        })
        return
      default:
        return
    }
  }
  return useMutation(mutationFn)
}
