/* tslint:disable */
/* eslint-disable */
/**
 * API Зерно-Агрегатора
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { ContactPersonData } from './contact-person-data';
import { FileData } from './file-data';
import { OfferPriceDetail } from './offer-price-detail';
import { ProductData } from './product-data';

/**
 * 
 * @export
 * @interface CompanyAuctionOfferData
 */
export interface CompanyAuctionOfferData {
    /**
     * 
     * @type {number}
     * @memberof CompanyAuctionOfferData
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof CompanyAuctionOfferData
     */
    'companyId': number | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyAuctionOfferData
     */
    'type': CompanyAuctionOfferDataTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof CompanyAuctionOfferData
     */
    'status': CompanyAuctionOfferDataStatusEnum;
    /**
     * 
     * @type {number}
     * @memberof CompanyAuctionOfferData
     */
    'volume': number;
    /**
     * 
     * @type {number}
     * @memberof CompanyAuctionOfferData
     */
    'price': number;
    /**
     * Подробная информация о цене
     * @type {OfferPriceDetail}
     * @memberof CompanyAuctionOfferData
     */
    'priceDetail': OfferPriceDetail | null;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyAuctionOfferData
     */
    'includingNds': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyAuctionOfferData
     */
    'shippingRequired': boolean;
    /**
     * 
     * @type {string}
     * @memberof CompanyAuctionOfferData
     */
    'address': string;
    /**
     * 
     * @type {number}
     * @memberof CompanyAuctionOfferData
     */
    'distance': number | null;
    /**
     * Товар
     * @type {ProductData}
     * @memberof CompanyAuctionOfferData
     */
    'product': ProductData;
    /**
     * 
     * @type {FileData}
     * @memberof CompanyAuctionOfferData
     */
    'laboratoryAnalysis': FileData | null;
    /**
     * Примечание к предложению
     * @type {string}
     * @memberof CompanyAuctionOfferData
     */
    'note': string | null;
    /**
     * 
     * @type {ContactPersonData}
     * @memberof CompanyAuctionOfferData
     */
    'contactPerson': ContactPersonData | null;
    /**
     * Id торгов
     * @type {number}
     * @memberof CompanyAuctionOfferData
     */
    'auctionId': number;
    /**
     * Цена предложения-основы торгов
     * @type {number}
     * @memberof CompanyAuctionOfferData
     */
    'auctionOfferPrice': number;
    /**
     * Id сделки
     * @type {number}
     * @memberof CompanyAuctionOfferData
     */
    'dealId': number | null;
}

export const CompanyAuctionOfferDataTypeEnum = {
    Buy: 'BUY',
    Sell: 'SELL'
} as const;

export type CompanyAuctionOfferDataTypeEnum = typeof CompanyAuctionOfferDataTypeEnum[keyof typeof CompanyAuctionOfferDataTypeEnum];
export const CompanyAuctionOfferDataStatusEnum = {
    OnModeration: 'ON_MODERATION',
    PrePublished: 'PRE_PUBLISHED',
    Published: 'PUBLISHED',
    PreDeal: 'PRE_DEAL',
    InDeal: 'IN_DEAL',
    Expired: 'EXPIRED',
    Rejected: 'REJECTED',
    Realised: 'REALISED'
} as const;

export type CompanyAuctionOfferDataStatusEnum = typeof CompanyAuctionOfferDataStatusEnum[keyof typeof CompanyAuctionOfferDataStatusEnum];


