import React from 'react'

import { Select } from 'antd'
import { BaseSelectRef } from 'rc-select'

import { getElevatorTypeName } from '~/utils/elevators/get-elevator-type-name'
import { AdminElevatorDataTypeEnum } from '~api/generated'
import { CustomControlInterface } from '~shared/controls/CustomControl.interface'

type Value = AdminElevatorDataTypeEnum[] | undefined

interface ElevatorTypeSelectControlProps extends CustomControlInterface {
  onChange: (value: Value) => void
  value: Value | null
  hasError?: boolean
  id?: string
  disabled?: boolean
}
export const ElevatorTypeSelectControl: React.FC<ElevatorTypeSelectControlProps> = React.forwardRef<
  BaseSelectRef,
  ElevatorTypeSelectControlProps
>(({ id, value, onChange, hasError, disabled }, ref) => {
  const onSelectHandler = (v: Value) => {
    onChange(v)
  }

  return (
    <Select
      ref={ref}
      id={id}
      mode="multiple"
      style={{ width: '100%' }}
      value={value}
      onChange={onSelectHandler}
      placeholder="Перевалка"
      status={hasError ? 'error' : undefined}
      disabled={disabled}
    >
      <Select.Option value={AdminElevatorDataTypeEnum.Transshipment}>
        {getElevatorTypeName(AdminElevatorDataTypeEnum.Transshipment)}
      </Select.Option>
      <Select.Option value={AdminElevatorDataTypeEnum.Port}>
        {getElevatorTypeName(AdminElevatorDataTypeEnum.Port)}
      </Select.Option>
      <Select.Option value={AdminElevatorDataTypeEnum.Recast}>
        {getElevatorTypeName(AdminElevatorDataTypeEnum.Recast)}
      </Select.Option>
    </Select>
  )
})

ElevatorTypeSelectControl.displayName = 'ElevatorTypeSelectControl'
