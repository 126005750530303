import styled from 'styled-components'

import { breakpoints } from '~/tokens'
import { Text } from '~/ui-components'

export const InfoText = styled(Text).attrs({ variant: 'description' })`
  margin-top: 4px;
  color: var(--gray-5-color);
`

export const HalfRow = styled.div`
  width: 50%;
  @media (max-width: ${breakpoints.MOBILE_XL}px) {
    width: 100%;
  }
`
