import React, { useState } from 'react'

import { Button, Modal, Spin, Tag } from 'antd'
import { FiTrash2 } from 'react-icons/fi'
import { useParams } from 'react-router'

import { useMutation } from '@apollo/client'

import { AppHeader } from '~/layout'
import { appRoutes } from '~/router'
import { appToast, httpErrorHandler } from '~/utils'
import { graphqlErrorHandler } from '~/utils/graphqlErrorHandler'
import { elevatorApproveCreationRequestMutation } from '~api/gql-mutations/elevator.mutation.graphql'
import {
  useDeleteElevatorAdmin,
  useGetElevatorAdmin,
  useRecoverElevatorAdmin,
  useUpdateElevatorAdmin,
} from '~hooks/elevators-admin'
import { CreateElevatorModal } from '~pages/SettingsPage/modules/ElevatorsAdmin/components/CreateElevatorModal'
import { CreateBasisFormValue } from '~pages/SettingsPage/modules/ElevatorsAdmin/components/CreateElevatorModal/CreateElevatorModal'
import { ElevatorDetailsInfo } from '~shared/components/ElevatorDetailsInfo'

import * as S from './ElevatorDetails.styled'

export const ElevatorDetails: React.FC = () => {
  const { id } = useParams<{ id: string | undefined }>()
  const elevatorId = Number(id)
  const [isEditElevatorModalOpen, setEditElevatorModalOpen] = useState(false)
  const getElevatorFn = useGetElevatorAdmin({ id: elevatorId })
  const updateElevatorFn = useUpdateElevatorAdmin()
  const deleteElevatorFn = useDeleteElevatorAdmin()
  const recoverElevatorFn = useRecoverElevatorAdmin()
  const [approveCreationRequest, approveCreationRequestState] = useMutation(elevatorApproveCreationRequestMutation)

  const elevator = getElevatorFn.data

  const isDeleted = elevator?.isDeleted

  const onApproveCreationRequest = () => {
    Modal.confirm({
      title: 'Вы уверены что хотите принять эту заявку?',
      okText: 'Принять',
      cancelText: 'Отмена',
      okButtonProps: { loading: approveCreationRequestState.loading },
      async onOk() {
        try {
          await approveCreationRequest({
            variables: {
              elevatorId: elevator?.id ?? NaN,
            },
          })
          await getElevatorFn.refetch()
          appToast.success({ description: 'Заявка принята' })
        } catch (e) {
          graphqlErrorHandler(e, 'Ошибка при принятии заявки')
        }
      },
    })
  }

  const handleDeleteElevator = async () => {
    Modal.confirm({
      title: 'Вы уверены что хотите удалить элеватор?',
      content: 'Его можно будет восстановить позже',
      okText: 'Удалить',
      cancelText: 'Отмена',
      async onOk() {
        try {
          await deleteElevatorFn.mutateAsync({ elevatorId })
          await getElevatorFn.refetch()
          appToast.success({ description: 'Элеватор удален' })
        } catch (e) {
          httpErrorHandler(e, 'Ошибка при удалении элеватора')
        }
      },
    })
  }

  const handleRecoverElevator = async () => {
    Modal.confirm({
      title: 'Вы уверены что хотите восстановить элеватор?',
      content: 'Его можно будет удалить позже',
      okText: 'Восстановить',
      cancelText: 'Отмена',
      async onOk() {
        try {
          await recoverElevatorFn.mutateAsync({ elevatorId })
          await getElevatorFn.refetch()
          appToast.success({ description: 'Элеватор восстановлен' })
        } catch (e) {
          httpErrorHandler(e, 'Ошибка при восстановлении элеватора')
        }
      },
    })
  }

  const handleCloseEditElevatorModal = () => setEditElevatorModalOpen(false)

  const handleEditElevator = async (formValues: CreateBasisFormValue) => {
    try {
      await updateElevatorFn.mutateAsync({
        elevatorId,
        adminUpdateElevatorBody: {
          // step 1
          name: formValues.step1.name,
          address: formValues.step1.address,
          type: formValues.step1.type,
          // step 2
          storageVolume: formValues.step2.storageVolume,
          // productOnElevator: formValues.step2.productTypes, // FIXME: нет поля на бэке. обещали поправить
          transport: formValues.step2.transport,
          openingTime: formValues.step2.openingTime?.toISOString(),
          closingTime: formValues.step2.closingTime?.toISOString(),
          workingDaysOfWeek: formValues.step2.workingDaysOfWeek,
          hasLab: formValues.step2.hasLab,
          hasDigitalQueue: formValues.step2.hasDigitalQueue,
          // step 3 Rail
          stationName: formValues.step3Rail?.stationName,
          hasRailWay: formValues.step3Rail?.hasRailWay,
          hasRailScales: formValues.step3Rail?.hasRailScales,
          hasRailStock: formValues.step3Rail?.hasRailStock,
          hasTecEco: formValues.step3Rail?.hasTecEco,
          railLoadSpeed: formValues.step3Rail?.railLoadSpeed,
          waitingArea: formValues.step3Rail?.waitingArea,
          railAdditionalInfo: formValues.step3Rail?.railAdditionalInfo,
          // step 4 Car
          hasScales: true, // радов сказал что у базисов всегда есть весы
          scalesWeightLimit: formValues.step4Car?.scalesWeightLimit,
          scalesLength: formValues.step4Car?.scalesLength,
          autoReceptionSpeed: formValues.step4Car?.autoReceptionSpeed,
          autoUnloadSpeed: formValues.step4Car?.autoUnloadSpeed,
          autoLength: formValues.step4Car?.autoLength,
          autoHeight: formValues.step4Car?.autoHeight,
          autoClearance: formValues.step4Car?.autoClearance,
          loadingTransport: formValues.step4Car?.loadingTransport,
          unloadingTransport: formValues.step4Car?.unloadingTransport,
          hasAutoStorage: formValues.step4Car?.hasAutoStorage,
          autoAdditionalInfo: formValues.step4Car?.autoAdditionalInfo,
          // step 5 additional service
          additionalServiceWithNds: formValues.step5ServicePrice?.usingNds,
          receptionPrice: formValues.step5ServicePrice?.receptionPrice,
          dryingPrice: formValues.step5ServicePrice?.dryingPrice,
          storingPrice: formValues.step5ServicePrice?.storingPrice,
          mixingPrice: formValues.step5ServicePrice?.mixingPrice,
          autoShipmentPrice: formValues.step5ServicePrice?.autoShipmentPrice,
          trainShipmentPrice: formValues.step5ServicePrice?.trainShipmentPrice,
          paperworkPrice: formValues.step5ServicePrice?.paperworkPrice,
          cleaningPrice: formValues.step5ServicePrice?.cleaningPrice,
          censusPrice: formValues.step5ServicePrice?.censusPrice,
          anotherServices: formValues.step5ServicePrice?.otherServices,
        },
      })
      appToast.success({ description: 'Базис обновлен' })
      await getElevatorFn.refetch()
      handleCloseEditElevatorModal()
    } catch (e) {
      httpErrorHandler(e, 'Ошибка при редактировании элеватора')
    }
  }

  return (
    <Spin spinning={getElevatorFn.isFetching}>
      <AppHeader title={`${appRoutes.settingsElevatorsDetails.title} №${elevatorId}`} isBack />

      <S.ContentWrapper>
        <div>
          <Tag hidden={!isDeleted} color="red" icon={<FiTrash2 />}>
            Удален
          </Tag>
        </div>
        <S.Header>
          <Button htmlType="button" type="primary" onClick={() => setEditElevatorModalOpen(true)}>
            Редактировать
          </Button>
          <Button
            hidden={!elevator?.isCreationRequest}
            htmlType="button"
            type="primary"
            onClick={onApproveCreationRequest}
          >
            Принять заявку
          </Button>
          <Button hidden={isDeleted} htmlType="button" type="primary" danger onClick={handleDeleteElevator}>
            Удалить
          </Button>
          <Button hidden={!isDeleted} htmlType="button" onClick={handleRecoverElevator}>
            Восстановить
          </Button>
        </S.Header>

        <ElevatorDetailsInfo elevator={elevator} />
      </S.ContentWrapper>

      {elevator && (
        <CreateElevatorModal
          open={isEditElevatorModalOpen}
          onCancel={handleCloseEditElevatorModal}
          onSubmit={handleEditElevator}
          lastStepSubmitLoading={updateElevatorFn.isLoading}
          defaultValues={{
            step1: {
              name: elevator.name,
              address: elevator.address,
              type: elevator.type,
            },
            step2: {
              transport: elevator.transport ?? [],
              openingTime: elevator.openingTime ? new Date(elevator.openingTime) : null,
              closingTime: elevator.closingTime ? new Date(elevator.closingTime) : null,
              productTypes: elevator.productOnElevator ?? [],
              hasLab: elevator.hasLab,
              hasDigitalQueue: elevator.hasDigitalQueue,
              workingDaysOfWeek: elevator.workingDaysOfWeek,
              storageVolume: elevator.storageVolume ?? 0,
            },
            step3Rail: {
              stationName: elevator.stationName ?? '',
              hasRailWay: elevator.hasRailWay ?? false,
              hasRailScales: elevator.hasRailScales ?? false,
              hasRailStock: elevator.hasRailStock ?? false,
              hasTecEco: elevator.hasTecEco ?? false,
              railLoadSpeed: elevator.railLoadSpeed ?? NaN,
              waitingArea: elevator.waitingArea ?? NaN,
              railAdditionalInfo: elevator.railAdditionalInfo ?? '',
            },
            step4Car: {
              scalesLength: elevator.scalesLength ?? NaN,
              scalesWeightLimit: elevator.scalesWeightLimit ?? NaN,
              autoLength: elevator.autoLength ?? NaN,
              autoHeight: elevator.autoHeight ?? NaN,
              autoReceptionSpeed: elevator.autoReceptionSpeed ?? NaN,
              unloadingTransport: elevator.unloadingTransport ?? [],
              loadingTransport: elevator.loadingTransport ?? [],
              autoClearance: elevator.autoClearance ?? NaN,
              autoUnloadSpeed: elevator.autoUnloadSpeed ?? NaN,
              hasAutoStorage: elevator.hasAutoStorage ?? false,
              autoAdditionalInfo: elevator.autoAdditionalInfo ?? '',
            },
            step5ServicePrice: {
              usingNds: elevator.additionalServiceWithNds ?? false,
              censusPrice: elevator.censusPrice ?? undefined,
              cleaningPrice: elevator.cleaningPrice ?? undefined,
              dryingPrice: elevator.dryingPrice ?? undefined,
              mixingPrice: elevator.mixingPrice ?? undefined,
              receptionPrice: elevator.receptionPrice ?? undefined,
              storingPrice: elevator.storingPrice ?? undefined,
              autoShipmentPrice: elevator.autoShipmentPrice ?? undefined,
              trainShipmentPrice: elevator.trainShipmentPrice ?? undefined,
              paperworkPrice: elevator.paperworkPrice ?? undefined,
              otherServices: elevator.anotherServices ?? undefined,
            },
          }}
        />
      )}
    </Spin>
  )
}
