import React, { useState } from 'react'

import { Button } from 'antd'
import { addDays } from 'date-fns'
import { observer } from 'mobx-react-lite'

import { useQueryClient } from '@tanstack/react-query'

import { myBasisAdvertsFilterStore } from '~/store/filters'
import { appToast, httpErrorHandler } from '~/utils'
import { useUploadFileAndGetId } from '~hooks/_utils'
import { useGetCurrentRole } from '~hooks/auth'
import { useCreateElevatorAdvertBuy, useCreateElevatorAdvertSell } from '~hooks/elevator-advert'
import { CreateBasisAdvertFormModal } from '~pages/BasisPage/components/CreateBasisAdvertFormModal'
import { MyBasisAdvertsListFiltersInline } from '~pages/BasisPage/components/MyBasisAdvertsListFiltersInline'
import { MyAdvertsList } from '~pages/BasisPage/modules/MyAdvert/components/MyAdvertsList'
import { useAuth } from '~providers/auth'

import { CreateAdvertFormByBuyerValues } from '~pages/BasisPage/components/CreateAdvertFormByBuyer/CreateAdvertFormByBuyer.types'
import { CreateAdvertFormBySellerValues } from '~pages/BasisPage/components/CreateAdvertFormBySeller/CreateAdvertFormBySeller.types'

import * as S from './MyAdvert.styled'

export const MyAdvert: React.FC = observer(() => {
  const { companyId } = useAuth()
  const {
    company: { companyRole },
  } = useGetCurrentRole()
  const queryClient = useQueryClient()
  const [isCreateAdvertModalOpen, setIsCreateAdvertModalOpen] = useState(false)
  const uploadLabAnalysisAndGetIdFn = useUploadFileAndGetId()
  const createElevatorAdvertBuyFn = useCreateElevatorAdvertBuy()
  const createElevatorAdvertSellFn = useCreateElevatorAdvertSell()

  const onCancelCreateAdvertModalHandler = () => {
    setIsCreateAdvertModalOpen(false)
  }

  const onSubmitCreateAdvertModalHandler = async (formValues: CreateAdvertFormByBuyerValues) => {
    const product = formValues.culture
    if (!product || !companyId) {
      return
    }
    try {
      await createElevatorAdvertBuyFn.mutateAsync({
        createElevatorAdvertBuyBody: {
          elevatorId: formValues.elevatorId,
          companyId,
          allowOffersWithOtherParameters: formValues.allowOffersWithOtherParameters,
          pricePerTon: formValues.pricePerTon,
          product: {
            type: product.cultureTypeId ?? '',
            parameters: product.parameters?.map((p) => ({ type: p.type, value: p.value })) ?? [],
          },
          expiredAt: formValues.publicationDateCount
            ? addDays(new Date(), formValues.publicationDateCount).toISOString()
            : null,
        },
      })
      appToast.success({ description: 'Объявление создано' })

      setIsCreateAdvertModalOpen(false)
      await queryClient.invalidateQueries(['elevatorsMy'])
      await queryClient.invalidateQueries(['myElevatorAdverts'])
    } catch (e) {
      httpErrorHandler(e, 'Ошибка при создании объявления')
    }
  }

  const onSubmitCreateAdvertModalBySellerHandler = async (formValues: CreateAdvertFormBySellerValues) => {
    const product = formValues.culture
    if (!product || !companyId) {
      return
    }
    try {
      const laboratoryAnalysisFileKey = formValues.requiredLaboratoryAnalysis
        ? undefined
        : await uploadLabAnalysisAndGetIdFn.mutateAsync(formValues.labAnalysis as File)

      const res = await createElevatorAdvertSellFn.mutateAsync({
        createElevatorAdvertSellBody: {
          companyId,
          laboratoryAnalysisFileKey,
          requiredLaboratoryAnalysis: formValues.requiredLaboratoryAnalysis,
          elevatorId: formValues.elevatorId,
          usingNds: formValues.usingNds,
          volume: formValues.volume,
          pricePerTon: formValues.pricePerTon,
          priceType: formValues.priceType,
          product: {
            type: product.cultureTypeId ?? '',
            parameters: product.parameters?.map((p) => ({ type: p.type, value: p.value })) ?? [],
          },
          expiredAt: formValues.publicationDateCount
            ? addDays(new Date(), formValues.publicationDateCount).toISOString()
            : null,
        },
      })
      appToast.success({ description: 'Объявление создано' })

      setIsCreateAdvertModalOpen(false)
      await queryClient.invalidateQueries(['elevatorsMy'])
      await queryClient.invalidateQueries(['myElevatorAdverts'])
    } catch (e) {
      httpErrorHandler(e, 'Ошибка при создании объявления')
    }
  }

  if (!companyRole) {
    return null
  }

  return (
    <S.ContentWrapper>
      <S.FiltersWrapper>
        <Button type="primary" htmlType="button" onClick={() => setIsCreateAdvertModalOpen(true)}>
          Создать объявление
        </Button>

        <MyBasisAdvertsListFiltersInline store={myBasisAdvertsFilterStore} />
      </S.FiltersWrapper>

      <MyAdvertsList />

      <CreateBasisAdvertFormModal
        open={isCreateAdvertModalOpen}
        onCancel={onCancelCreateAdvertModalHandler}
        currentCompanyRole={companyRole}
        loading={createElevatorAdvertSellFn.isLoading || createElevatorAdvertBuyFn.isLoading}
        onSubmitFormAsBuyer={onSubmitCreateAdvertModalHandler}
        onSubmitFormAsSeller={onSubmitCreateAdvertModalBySellerHandler}
      />
    </S.ContentWrapper>
  )
})
