import styled from 'styled-components'

import { breakpoints } from '~/tokens'
import { Text } from '~/ui-components'

export const Wrapper = styled.div`
  width: 400px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  @media (max-width: ${breakpoints.MOBILE_XL}px) {
    width: unset;
  }
`
export const ActionWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 16px;
`

export const ContentWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
`

export const Title = styled(Text).attrs({ variant: 'normal-bold' })`
  margin-bottom: 4px;
`
export const Description = styled(Text).attrs({ variant: 'description' })``
