import React, { useState } from 'react'

import { Empty, Spin } from 'antd'
import { useNavigate } from 'react-router-dom'

import { appRoutes } from '~/router'
import { useGetCurrentRole } from '~hooks/auth'
import { useGetDevice } from '~hooks/common'
import { useGetElevatorProductTypes } from '~hooks/elevators'
import { BasisAdvertsForBuyerToSell } from '~pages/BasisPage/modules/BasisAdvert'
import { BasisAdvertsForBuyerToSellTable } from '~pages/BasisPage/modules/BasisAdvert/BasisAdvertsForBuyerToSell/BasisAdvertsForBuyerToSellTable'
import { BasisAdvertsForSellerToBuy } from '~pages/BasisPage/modules/BasisAdvert/BasisAdvertsForSellerToBuy'
import { CultureSelectControl } from '~shared/controls'

import * as S from './ToSellAdvertList.styled'

interface ToSellAdvertListProps {
  basisId: number
  companyId: number
}

export const ToSellAdvertList: React.FC<ToSellAdvertListProps> = ({ basisId, companyId }) => {
  const device = useGetDevice()
  const isMobile = device === 'mobile'

  const {
    company: { isSeller, isBuyer, isTrader },
  } = useGetCurrentRole()
  const navigate = useNavigate()
  const [selectedProductTypes, setSelectedProductTypes] = useState<string[]>([])
  const getElevatorProductTypesFn = useGetElevatorProductTypes({ id: basisId, offerType: 'SELL' })

  const productTypes = getElevatorProductTypesFn.data?.elevatorProductTypes.filter((x) =>
    selectedProductTypes.length ? selectedProductTypes.includes(x.type) : true
  )

  const handleNavigateToAdvert = (advertId: number) => {
    navigate(appRoutes.basisAdvert.url.replace(':id', advertId.toString()))
  }

  return (
    <S.ContentWrapper>
      <S.FiltersWrapper>
        <CultureSelectControl value={selectedProductTypes} onChange={(x) => setSelectedProductTypes(x)} />
      </S.FiltersWrapper>

      <S.CompanyLIstWrapper>
        {!productTypes?.length && <Empty description="Объявления на базисе отсутствуют" />}

        {productTypes?.map((productType) => {
          if (isBuyer || isTrader) {
            return isMobile ? (
              <Spin spinning={getElevatorProductTypesFn.isFetching}>
                <BasisAdvertsForBuyerToSell
                  companyId={companyId}
                  key={productType.id}
                  productTypeData={productType}
                  elevatorId={basisId}
                  onClick={handleNavigateToAdvert}
                />
              </Spin>
            ) : (
              <BasisAdvertsForBuyerToSellTable
                companyId={companyId}
                key={productType.id}
                productTypeData={productType}
                elevatorId={basisId}
                onClick={handleNavigateToAdvert}
              />
            )
          }
          if (isSeller) {
            return isMobile ? (
              <Spin spinning={getElevatorProductTypesFn.isFetching}>
                <BasisAdvertsForSellerToBuy
                  companyId={companyId}
                  key={productType.id}
                  productTypeData={productType}
                  elevatorId={basisId}
                  onClick={handleNavigateToAdvert}
                />
              </Spin>
            ) : (
              <BasisAdvertsForBuyerToSellTable
                companyId={companyId}
                key={productType.id}
                productTypeData={productType}
                elevatorId={basisId}
                onClick={handleNavigateToAdvert}
              />
            )
          }
        })}
      </S.CompanyLIstWrapper>
    </S.ContentWrapper>
  )
}
