import React from 'react'

import { Badge } from '~/ui-components'

interface Props {
  isUploaded: boolean
}

export const DocumentUploadStatusBadge: React.FC<Props> = ({ isUploaded }) => {
  return (
    <Badge size="s" color={isUploaded ? 'base' : 'red'}>
      {isUploaded ? 'Загружен' : 'Не загружен'}
    </Badge>
  )
}
