import React, { useState } from 'react'

import { Button, Modal } from 'antd'

import { useQueryClient } from '@tanstack/react-query'

import { appToast, httpErrorHandler, modalSharedSettings } from '~/utils'
import { useCreateElevatorAdvertBuy } from '~hooks/elevator-advert'
import {
  CreateAdvertFormByBuyer,
  CreateAdvertFormByBuyerValues,
} from '~pages/BasisPage/components/CreateAdvertFormByBuyer'
import { useAuth } from '~providers/auth'

import { MyAdvertOffersList } from './components/MyAdvertOffersList'

import * as S from './MyAdvertOffers.styled'

const FORM_ID = 'create-advert-form-id'

export const MyAdvertOffers: React.FC = () => {
  const queryClient = useQueryClient()
  const { companyId } = useAuth()
  const [isCreateAdvertModalOpen, setIsCreateAdvertModalOpen] = useState(false)
  const createElevatorAdvertFn = useCreateElevatorAdvertBuy()

  const onCancelCreateAdvertModalHandler = () => {
    setIsCreateAdvertModalOpen(false)
  }

  const onSubmitCreateAdvertModalHandler = async (formValues: CreateAdvertFormByBuyerValues) => {
    const product = formValues.culture
    if (!product || !companyId) {
      return
    }
    try {
      await createElevatorAdvertFn.mutateAsync({
        createElevatorAdvertBuyBody: {
          elevatorId: formValues.elevatorId,
          companyId,
          allowOffersWithOtherParameters: formValues.allowOffersWithOtherParameters,
          pricePerTon: formValues.pricePerTon,
          product: {
            type: product.cultureTypeId ?? '',
            parameters: product.parameters?.map((p) => ({ type: p.type, value: p.value })) ?? [],
          },
        },
      })
      appToast.success({ description: 'Объявление создано' })

      setIsCreateAdvertModalOpen(false)
      await queryClient.invalidateQueries(['elevatorsMy'])
      await queryClient.invalidateQueries(['myElevatorAdverts'])
    } catch (e) {
      httpErrorHandler(e, 'Ошибка при создании объявления')
    }
  }

  return (
    <S.ContentWrapper>
      {/*<S.FiltersWrapper>*/}
      {/*  <Button*/}
      {/*    style={{ width: isMobile ? '100%' : 'max-content' }}*/}
      {/*    type="primary"*/}
      {/*    htmlType="button"*/}
      {/*    onClick={() => setIsCreateAdvertModalOpen(true)}*/}
      {/*  >*/}
      {/*    Предложить объем*/}
      {/*  </Button>*/}
      {/*</S.FiltersWrapper>*/}

      <MyAdvertOffersList />

      <Modal
        {...modalSharedSettings}
        width={500}
        open={isCreateAdvertModalOpen}
        onCancel={onCancelCreateAdvertModalHandler}
        title="Создать объявление"
        footer={[
          <Button key="cancel" onClick={onCancelCreateAdvertModalHandler} htmlType="button">
            Отмена
          </Button>,
          <Button
            key="submit"
            form={FORM_ID}
            type="primary"
            htmlType="submit"
            loading={createElevatorAdvertFn.isLoading}
          >
            Создать
          </Button>,
        ]}
      >
        <CreateAdvertFormByBuyer formId={FORM_ID} onSubmit={onSubmitCreateAdvertModalHandler} />
      </Modal>
    </S.ContentWrapper>
  )
}
