import React from 'react'
import { OfferYourErrors, OfferYourFormProps, OfferYourFormValues } from './OfferYourForm.types'
import { Button, Flex, Input, InputNumber, Radio, Space } from 'antd'
import { FormField, Text } from '~/ui-components'
import {
  FreightOrderChangeRequestEntity,
  FreightOrderChangeRequestType,
  FreightOrderFieldName,
  FreightPackingType,
  FreightPaymentTerm,
} from '~api/gql-generated/graphql'
import { getFreightPackingType } from '~/utils/logistics/get-freight-packing-type'
import { getFreightPaymentTerm } from '~/utils/logistics/get-freight-payment-term'
import { getNoun } from '~/utils/getNoun'
import { Control, Controller, useForm, UseFormResetField, useWatch } from 'react-hook-form'
import { inputSharedSettings } from '~/utils/inputSharedSettings'
import { yupResolver } from '@hookform/resolvers/yup'
import { FreightPackingTypeSelectControl } from '~shared/controls/FreightPackingTypeSelectControl/FreightPackingTypeSelectControl'
import { OfferOfConditionsFormSchema } from './OfferYourForm.validation'
import { FreightPaymentTermSelectControl } from '~shared/controls/FreightPaymentTermSelectControl'

const CurrentOfferYourValues = ({ lastChangeRequest }: { lastChangeRequest: FreightOrderChangeRequestEntity }) => {
  let result = ''
  switch (lastChangeRequest.type) {
    case FreightOrderChangeRequestType.Weight:
      const weight = lastChangeRequest.changedFields.find((item) => item.fieldName === FreightOrderFieldName.Weight)
        ?.value

      return weight ? (
        <Flex vertical gap={16}>
          <Flex gap={8}>
            <Text variant="form-read-label">Предложение контрагента</Text>
            <Text variant="form-read-value">{+weight / 1000} т</Text>
          </Flex>
        </Flex>
      ) : null
    case FreightOrderChangeRequestType.PackingType:
      const packingType = lastChangeRequest.changedFields.find(
        (item) => item.fieldName === FreightOrderFieldName.PackingType
      )?.value
      const bigBagsCount = lastChangeRequest.changedFields.find(
        (item) => item.fieldName === FreightOrderFieldName.BigBagsCount
      )?.value
      const packingTypeCustom = lastChangeRequest.changedFields.find(
        (item) => item.fieldName === FreightOrderFieldName.PackingTypeCustom
      )?.value

      result += packingType ? getFreightPackingType(packingType as FreightPackingType) : ''
      result += bigBagsCount ? `, ${bigBagsCount} шт` : ''
      result += packingTypeCustom ? `, ${packingTypeCustom}` : ''

      return result ? (
        <Flex vertical gap={16}>
          <Flex gap={8}>
            <Text variant="form-read-label">Предложение контрагента</Text>
            <Text variant="form-read-value">{result}</Text>
          </Flex>
        </Flex>
      ) : null
    case FreightOrderChangeRequestType.PricePerTon:
      const pricePerTon = lastChangeRequest.changedFields.find(
        (item) => item.fieldName === FreightOrderFieldName.PricePerTonWithoutNds
      )?.value

      return pricePerTon ? (
        <Flex vertical gap={16}>
          <Flex gap={8}>
            <Text variant="form-read-label">Предложение контрагента</Text>
            <Text variant="form-read-value">{pricePerTon} ₽/т</Text>
          </Flex>
        </Flex>
      ) : null
    case FreightOrderChangeRequestType.PaymentTerm:
      const paymentTerm = lastChangeRequest.changedFields.find(
        (item) => item.fieldName === FreightOrderFieldName.PaymentTerm
      )?.value
      const deferredDaysCount = lastChangeRequest.changedFields.find(
        (item) => item.fieldName === FreightOrderFieldName.DeferredDaysCount
      )?.value
      const advancePercent = lastChangeRequest.changedFields.find(
        (item) => item.fieldName === FreightOrderFieldName.AdvancePercent
      )?.value

      result += paymentTerm ? getFreightPaymentTerm(paymentTerm as FreightPaymentTerm) : ''
      result += deferredDaysCount ? `, ${deferredDaysCount} ${getNoun(+deferredDaysCount, 'день', 'дня', 'дней')}` : ''
      result += advancePercent ? `, ${advancePercent} %` : ''

      return result ? (
        <Flex vertical gap={16}>
          <Flex gap={8}>
            <Text variant="form-read-label">Предложение контрагента</Text>
            <Text variant="form-read-value">{result}</Text>
          </Flex>
        </Flex>
      ) : null
    case FreightOrderChangeRequestType.TransportRegistry:
      const canUpdateTransportRegistryInPast = lastChangeRequest.changedFields.find(
        (item) => item.fieldName === FreightOrderFieldName.CanUpdateTransportRegistryInPast
      )?.value

      return canUpdateTransportRegistryInPast ? (
        <Flex vertical gap={16}>
          <Flex gap={8}>
            <Text variant="form-read-label">Предложение контрагента</Text>
            <Text variant="form-read-value">{canUpdateTransportRegistryInPast}</Text>
          </Flex>
        </Flex>
      ) : null
    case FreightOrderChangeRequestType.Distance:
      const distance = lastChangeRequest.changedFields.find((item) => item.fieldName === FreightOrderFieldName.Distance)
        ?.value
      const comment = lastChangeRequest.additionalDetails

      return distance && comment ? (
        <Flex vertical gap={16}>
          <Flex gap={8}>
            <Text variant="form-read-label">Предложение контрагента</Text>
            <Text variant="form-read-value">{distance} км</Text>
          </Flex>

          <Flex gap={8}>
            <Text variant="form-read-label">Комментарий:</Text>
            <Text variant="normal">{comment}</Text>
          </Flex>
        </Flex>
      ) : null
    default:
      return null
  }
}

const NewOfferYourFormValues = ({
  type,
  control,
  errors,
  resetField,
}: {
  type: FreightOrderChangeRequestType
  control: Control<OfferYourFormValues>
  errors: OfferYourErrors
  resetField: UseFormResetField<OfferYourFormValues>
}) => {
  const [packingType, paymentTerm] = useWatch({ control, name: ['packingType', 'paymentTerm'] })

  switch (type) {
    case FreightOrderChangeRequestType.Weight:
      return (
        <Flex vertical>
          <FormField label="Вес" htmlFor="weightKg" validateMessage={errors.weightKg?.message} isRequired>
            <Controller
              name="weightKg"
              control={control}
              render={({ field }) => (
                <InputNumber
                  {...field}
                  {...inputSharedSettings}
                  addonAfter="т"
                  style={{ width: '100%' }}
                  id="weightKg"
                  placeholder="Введите значение"
                  status={errors.weightKg && 'error'}
                  step={1}
                />
              )}
            />
          </FormField>
        </Flex>
      )
    case FreightOrderChangeRequestType.PackingType:
      return (
        <Flex vertical>
          <Flex gap={24}>
            <Flex flex="1 1 50%">
              <FormField
                label="Вид упаковки"
                htmlFor="packingType"
                validateMessage={errors.packingType?.message}
                isRequired
              >
                <Controller
                  name="packingType"
                  control={control}
                  render={({ field }) => (
                    <FreightPackingTypeSelectControl
                      {...field}
                      onChange={(value) => {
                        resetField('bigBagsCount')
                        resetField('packingTypeCustom')
                        field.onChange(value)
                      }}
                      id="packingType"
                      hasError={!!errors.packingType}
                    />
                  )}
                />
              </FormField>
            </Flex>

            <Flex flex="1 1 50%">
              {packingType === FreightPackingType.BigBag && (
                <FormField
                  label="Кол-во"
                  htmlFor="bigBagsCount"
                  validateMessage={errors.bigBagsCount?.message}
                  isRequired
                >
                  <Controller
                    name="bigBagsCount"
                    control={control}
                    render={({ field }) => (
                      <InputNumber
                        {...field}
                        {...inputSharedSettings}
                        style={{ width: '100%' }}
                        id="bigBagsCount"
                        placeholder="Введите значение"
                        status={errors?.bigBagsCount && 'error'}
                        step={1}
                        addonAfter="шт"
                      />
                    )}
                  />
                </FormField>
              )}

              {packingType === FreightPackingType.Custom && (
                <FormField
                  label="Наименование"
                  htmlFor="packingTypeCustom"
                  validateMessage={errors.packingTypeCustom?.message}
                  isRequired
                >
                  <Controller
                    name="packingTypeCustom"
                    control={control}
                    render={({ field }) => (
                      <Input
                        {...field}
                        id="packingTypeCustom"
                        status={errors?.packingTypeCustom && 'error'}
                        placeholder="Например, мешки"
                      />
                    )}
                  />
                </FormField>
              )}
            </Flex>
          </Flex>
        </Flex>
      )
    case FreightOrderChangeRequestType.PricePerTon:
      return (
        <Flex vertical>
          <FormField
            label="Ставка без НДС"
            htmlFor="pricePerTonWithoutNds"
            validateMessage={errors.pricePerTonWithoutNds?.message}
            isRequired
          >
            <Controller
              name="pricePerTonWithoutNds"
              control={control}
              render={({ field }) => (
                <InputNumber
                  {...field}
                  {...inputSharedSettings}
                  addonAfter="₽/т"
                  style={{ width: '100%' }}
                  id="pricePerTonWithoutNds"
                  placeholder="Введите значение"
                  status={errors.pricePerTonWithoutNds && 'error'}
                  step={1}
                />
              )}
            />
          </FormField>
        </Flex>
      )
    case FreightOrderChangeRequestType.PaymentTerm:
      return (
        <Flex vertical>
          <Flex gap={24}>
            <Flex flex="1 1 50%">
              <FormField
                label="Условия оплаты"
                htmlFor="paymentTerm"
                validateMessage={errors.paymentTerm?.message}
                isRequired
              >
                <Controller
                  name="paymentTerm"
                  control={control}
                  defaultValue={FreightPaymentTerm.Deferred}
                  render={({ field }) => (
                    <FreightPaymentTermSelectControl
                      {...field}
                      onChange={(value) => {
                        resetField('deferredDaysCount')
                        resetField('advancePercent')
                        field.onChange(value)
                      }}
                      id="paymentTerm"
                      hasError={!!errors.paymentTerm}
                    />
                  )}
                />
              </FormField>
            </Flex>

            <Flex flex="1 1 50%">
              {paymentTerm === FreightPaymentTerm.Deferred && (
                <FormField
                  label="Кол-во дней отсрочки"
                  htmlFor="deferredDaysCount"
                  validateMessage={errors.deferredDaysCount?.message}
                  isRequired
                >
                  <Controller
                    name="deferredDaysCount"
                    control={control}
                    render={({ field }) => (
                      <InputNumber
                        {...field}
                        {...inputSharedSettings}
                        style={{ width: '100%' }}
                        id="deferredDaysCount"
                        placeholder="Введите значение"
                        status={errors.deferredDaysCount && 'error'}
                        step={1}
                        addonAfter="дней"
                      />
                    )}
                  />
                </FormField>
              )}

              {paymentTerm === FreightPaymentTerm.Advance && (
                <>
                  <FormField
                    label="Процент предоплаты"
                    htmlFor="advancePercent"
                    validateMessage={errors.advancePercent?.message}
                    isRequired
                  >
                    <Controller
                      name="advancePercent"
                      control={control}
                      render={({ field }) => (
                        <InputNumber
                          {...field}
                          {...inputSharedSettings}
                          addonAfter="%"
                          style={{ width: '100%' }}
                          id="advancePercent"
                          placeholder="Введите значение"
                          status={errors.advancePercent && 'error'}
                          step={1}
                        />
                      )}
                    />
                  </FormField>
                </>
              )}
            </Flex>
          </Flex>
        </Flex>
      )
    case FreightOrderChangeRequestType.TransportRegistry:
      return (
        <Flex vertical>
          <FormField
            label="Редактирование реестра"
            htmlFor="canUpdateTransportRegistryInPast"
            validateMessage={errors.canUpdateTransportRegistryInPast?.message}
          >
            <Controller
              name="canUpdateTransportRegistryInPast"
              control={control}
              render={({ field }) => (
                <Radio.Group {...field}>
                  <Space size="small" direction="vertical">
                    <Radio value={false}>Без возможности редактирования реестра по требованию</Radio>
                    <Radio value={true}>С возможностью редактирования реестра по требованию</Radio>
                  </Space>
                </Radio.Group>
              )}
            />
          </FormField>
        </Flex>
      )
    case FreightOrderChangeRequestType.Distance:
      return (
        <Flex vertical>
          <FormField label="Длина маршрута" htmlFor="distance" validateMessage={errors.distance?.message} isRequired>
            <Controller
              name="distance"
              control={control}
              render={({ field }) => (
                <InputNumber
                  {...field}
                  {...inputSharedSettings}
                  addonAfter="км"
                  style={{ width: '100%' }}
                  id="distance"
                  placeholder="Введите"
                  status={errors.distance && 'error'}
                  step={1}
                />
              )}
            />
          </FormField>

          <FormField
            label="Комментарий"
            htmlFor="additionalDetails"
            validateMessage={errors.additionalDetails?.message}
            isRequired
          >
            <Controller
              name="additionalDetails"
              control={control}
              render={({ field }) => (
                <Input.TextArea
                  {...field}
                  id="additionalDetails"
                  placeholder="Введите"
                  status={errors.additionalDetails && 'error'}
                  autoSize={{ minRows: 3, maxRows: 6 }}
                />
              )}
            />
          </FormField>
        </Flex>
      )
    default:
      return null
  }
}

const CurrentOfferYour = ({
  lastChangeRequest,
  control,
  errors,
  resetField,
}: {
  lastChangeRequest: FreightOrderChangeRequestEntity
  control: Control<OfferYourFormValues>
  errors: OfferYourErrors
  resetField: UseFormResetField<OfferYourFormValues>
}) => {
  return (
    <Flex vertical gap={24} style={{ borderBottom: '1px solid var(--gray-2-color)' }}>
      <Flex vertical gap={24}>
        <Flex gap={8}>
          <CurrentOfferYourValues lastChangeRequest={lastChangeRequest} />
        </Flex>
      </Flex>

      <Flex vertical gap={24}>
        <NewOfferYourFormValues
          type={lastChangeRequest.type}
          control={control}
          errors={errors}
          resetField={resetField}
        />
      </Flex>
    </Flex>
  )
}

export const OfferYourForm: React.FC<OfferYourFormProps> = ({ onBack, onSubmit, lastChangeRequest }) => {
  const defaultValues: Partial<OfferYourFormValues> = {
    weightKg: undefined,
    packingType: undefined,
    bigBagsCount: undefined,
    packingTypeCustom: undefined,
    pricePerTonWithoutNds: undefined,
    paymentTerm: undefined,
    deferredDaysCount: undefined,
    advancePercent: undefined,
    canUpdateTransportRegistryInPast: undefined,
    distance: undefined,
    additionalDetails: lastChangeRequest.additionalDetails ?? '',
  }

  lastChangeRequest.changedFields.forEach((changedField) => {
    if (changedField.fieldName === FreightOrderFieldName.Weight) defaultValues.weightKg = +changedField.value / 1000
    if (changedField.fieldName === FreightOrderFieldName.PackingType)
      defaultValues.packingType = changedField.value as FreightPackingType
    if (changedField.fieldName === FreightOrderFieldName.BigBagsCount) defaultValues.bigBagsCount = +changedField.value
    if (changedField.fieldName === FreightOrderFieldName.PackingTypeCustom)
      defaultValues.packingTypeCustom = changedField.value
    if (changedField.fieldName === FreightOrderFieldName.PricePerTonWithoutNds)
      defaultValues.pricePerTonWithoutNds = +changedField.value
    if (changedField.fieldName === FreightOrderFieldName.PaymentTerm)
      defaultValues.paymentTerm = changedField.value as FreightPaymentTerm
    if (changedField.fieldName === FreightOrderFieldName.DeferredDaysCount)
      defaultValues.deferredDaysCount = +changedField.value
    if (changedField.fieldName === FreightOrderFieldName.AdvancePercent)
      defaultValues.advancePercent = +changedField.value
    if (changedField.fieldName === FreightOrderFieldName.CanUpdateTransportRegistryInPast)
      defaultValues.canUpdateTransportRegistryInPast = changedField.value === 'true'
    if (changedField.fieldName === FreightOrderFieldName.Distance) defaultValues.distance = +changedField.value
  })

  const {
    control,
    handleSubmit,
    resetField,
    formState: { errors, dirtyFields },
  } = useForm<OfferYourFormValues>({
    resolver: yupResolver(OfferOfConditionsFormSchema),
    defaultValues,
    context: { type: lastChangeRequest.type },
  })

  const onSubmitHandler = (data: OfferYourFormValues) => {
    onSubmit(data)
  }

  const isDisabled = () => {
    return (
      !Object.keys(dirtyFields).length ||
      (lastChangeRequest.type === FreightOrderChangeRequestType.Distance && !dirtyFields.distance)
    )
  }

  return (
    <Flex vertical gap={24}>
      <CurrentOfferYour
        lastChangeRequest={lastChangeRequest}
        control={control}
        errors={errors}
        resetField={resetField}
      />

      <Flex gap={16} justify="flex-end">
        <Button htmlType="button" onClick={onBack}>
          Отмена
        </Button>

        <Button htmlType="button" type="primary" onClick={handleSubmit(onSubmitHandler)} disabled={isDisabled()}>
          Предложить условие
        </Button>
      </Flex>
    </Flex>
  )
}
