import React, { useEffect, useMemo } from 'react'

import { Skeleton, Tabs } from 'antd'
import { useParams } from 'react-router'
import { matchRoutes, Outlet, useLocation, useNavigate } from 'react-router-dom'

import { AppHeader } from '~/layout'
import { appRoutes, IAppRoute } from '~/router'
import { getTabsFromUrl } from '~/utils/getTabsFromUrl'
import { useGetModeratorCompaniesList } from '~hooks/moderator'

export const ModeratorCompanyDetails: React.FC = () => {
  const { id: companyId } = useParams<{ id: string | undefined }>()

  const navigate = useNavigate()
  const { pathname } = useLocation()

  useEffect(() => {
    const routes = matchRoutes([{ path: appRoutes.moderatorCompanyDetails.url }], pathname)

    if (routes?.length && routes[0].route.path === appRoutes.moderatorCompanyDetails.url) {
      navigate(appRoutes.moderatorCompanyInformation.url, { replace: true })
    }
  }, [pathname, companyId])

  const companyFn = useGetModeratorCompaniesList({ ids: [Number(companyId)] })

  const handleTabsChange = (url: IAppRoute['url']) => {
    navigate(url)
  }

  const tabs = [
    appRoutes.moderatorCompanyInformation,
    appRoutes.moderatorCompanyDocumentsInformation,
    appRoutes.moderatorCompanyEmployees,
  ]

  const activeTab = useMemo(() => {
    return tabs.find((tab) => pathname.endsWith(tab.url))
  }, [pathname])

  if (companyFn.isFetching || !companyFn.data) {
    return <Skeleton />
  }

  const companyData = companyFn.data?.companies[0]

  return (
    <>
      <AppHeader title={companyData.name ?? `Company №: ${companyData.id.toString()}`} isBack>
        <Tabs activeKey={activeTab?.url} size="small" onChange={handleTabsChange} items={getTabsFromUrl(tabs)} />
      </AppHeader>

      <Outlet />
    </>
  )
}
