import React, { useState } from 'react'

import { Button, Flex, Modal, Spin } from 'antd'
import { FiArrowLeft, FiArrowRight, FiCheck, FiChevronDown, FiChevronUp, FiList, FiTruck } from 'react-icons/fi'
import { IoCloseOutline } from 'react-icons/io5'
import { useNavigate, useParams } from 'react-router-dom'

import { useMutation, useQuery } from '@apollo/client'
import { ApolloError } from '@apollo/client/errors'

import { AppHeader } from '~/layout'
import { appRoutes } from '~/router'
import { logisticResponsesFilterStore } from '~/store/filters'
import { Text } from '~/ui-components'
import { modalSharedSettings } from '~/utils'
import { formatNumber } from '~/utils/formatNumber'
import { formatPhoneNumber } from '~/utils/formatPhoneNumber'
import { getDateFormat } from '~/utils/getDateFormat'
import { getNoun } from '~/utils/getNoun'
import { graphqlErrorHandler } from '~/utils/graphqlErrorHandler'
import { getFreightLastResponseColor } from '~/utils/logistics/get-freight-last-response-color'
import { getFreightLastResponseName } from '~/utils/logistics/get-freight-last-response-name'
import { getFreightLastResponseText } from '~/utils/logistics/get-freight-last-response-text'
import { getFreightPackingType } from '~/utils/logistics/get-freight-packing-type'
import { getFreightPaymentTerm } from '~/utils/logistics/get-freight-payment-term'
import { getIsAuctionName } from '~/utils/logistics/get-is-auction-name'
import { getLoadType } from '~/utils/logistics/get-load-type'
import { getTransportType } from '~/utils/logistics/get-transport-type'
import { getUnloadType } from '~/utils/logistics/get-unload-type'
import {
  FreightDealStatus,
  FreightDealType,
  FreightOrderEntity,
  FreightOrderResponseEntity,
  FreightPackingType,
  FreightPaymentTerm,
} from '~api/gql-generated/graphql'
import { freightResponseUpdateStatusMutation } from '~api/gql-mutations/freight-response-update-status.mutation.graphql'
import {
  createFreightOrderBetMutation,
  createFreightOrderResponseMutation,
} from '~api/gql-mutations/logistics.mutation.graphql'
import { freightOrderResponsesQuery } from '~api/gql-query/freight-order-responses.query.graphql'
import { freightOrderQuery } from '~api/gql-query/freight-orders.query.graphql'
import { useGetCurrentRole } from '~hooks/auth'
import { BetToLoadForm } from '~pages/LogisticsPage/modules/LoadCard/components/BetToLoadForm'
import { LoadDocuments } from '~pages/LogisticsPage/modules/LoadCard/components/LoadDocuments/LoadDocuments'
import { ResponseForm } from '~pages/LogisticsPage/modules/LogisticResponses/components/ResponseForm'
import { useAuth } from '~providers/auth'
import { ReadValue } from '~shared/components'
import { getRoadSurfaceQualityName } from '~shared/controls/RoadSurfaceQualitySelectControl/get-road-surface-quality-name'
import { getRoadSurfaceTypeName } from '~shared/controls/RoadSurfaceTypeSelectControl/get-road-surface-type-name'
import { Timeline } from '~ui-components/components/Timeline'

import { ResponseToLoadForm } from './components/ResponseToLoadForm/ResponseToLoadForm'

import { ResponseToLoadFormValues } from './components/ResponseToLoadForm/ResponseToLoadForm.types'
import { BetToLoadFormValues } from '~pages/LogisticsPage/modules/LoadCard/components/BetToLoadForm/BetToLoadForm.types'
import { ResponseFormValues } from '~pages/LogisticsPage/modules/LogisticResponses/components/ResponseForm/ResponseForm.types'

import * as S from './LoadCard.styled'

export const LoadCard: React.FC = () => {
  const { id } = useParams<{ id: string | undefined }>()
  const freightOrderId = Number(id)
  const navigate = useNavigate()
  const { companyId } = useAuth()
  const {
    company: { isCarrier },
  } = useGetCurrentRole()
  const [isOpenTechnicalCharacteristics, setIsOpenTechnicalCharacteristics] = useState(false)
  const [isResponseModalOpen, setIsResponseModalOpen] = useState(false)
  const [isSuccessResponseModalOpen, setIsSuccessResponseModalOpen] = useState(false)
  const [isBetModalOpen, setIsBetModalOpen] = useState(false)
  const [isSuccessBetModalOpen, setIsSuccessBetModalOpen] = useState(false)
  const [isCancelResponseModalOpen, setIsCancelResponseModalOpen] = useState(false)
  const [isSuccessCancelResponseModalOpen, setIsSuccessCancelResponseModalOpen] = useState(false)

  const getFreightOrder = useQuery(freightOrderQuery, { variables: { freightOrderId } })
  const freightOrder: FreightOrderEntity | undefined = getFreightOrder.data?.freightOrder

  const getFreightOrderResponses = useQuery(freightOrderResponsesQuery, {
    variables: {
      filter: {
        carrierIds: [companyId],
        freightOrderIds: [freightOrderId],
      },
    },
    fetchPolicy: 'cache-and-network',
    skip: !isCarrier,
  })

  const freightOrderResponses: FreightOrderResponseEntity[] | undefined =
    getFreightOrderResponses.data?.freightOrderResponses.freightOrderResponses

  const lastResponse =
    freightOrderResponses?.find((response) => response.status === FreightDealStatus.OnModeration) ??
    freightOrderResponses?.[0]

  const [createFreightOrderResponse] = useMutation(createFreightOrderResponseMutation, {
    refetchQueries: [freightOrderQuery, freightOrderResponsesQuery],
  })
  const [createFreightOrderBet] = useMutation(createFreightOrderBetMutation, {
    refetchQueries: [freightOrderQuery, freightOrderResponsesQuery],
  })

  const [freightResponseUpdateStatus] = useMutation(freightResponseUpdateStatusMutation, {
    refetchQueries: [freightOrderQuery, freightOrderResponsesQuery],
  })

  const onCreateFreightOrderResponseHandler = async (formValues: ResponseToLoadFormValues) => {
    try {
      await createFreightOrderResponse({
        variables: {
          input: {
            freightOrderId,
            carrierId: companyId,
            weightKg: formValues.weightKg * 1000,
            additionalDetails: formValues.additionalDetails,
          },
        },
      })
      setIsResponseModalOpen(false)
      setIsSuccessResponseModalOpen(true)
    } catch (e) {
      if (e instanceof ApolloError) {
        graphqlErrorHandler(e, 'Ошибка при направлении отклика')
      }
    }
  }

  const onCreateFreightOrderBetHandler = async (formValues: BetToLoadFormValues) => {
    try {
      await createFreightOrderBet({
        variables: {
          input: {
            freightOrderId,
            carrierId: companyId,
            pricePerTon: formValues.pricePerTon,
            priceWithNds: formValues.priceWithNds,
            weightKg: formValues.weightKg * 1000,
            paymentTerm: formValues.paymentTerm,
            startedAt: formValues.startedAt,
            additionalDetails: formValues.additionalDetails,
          },
        },
      })
      setIsBetModalOpen(false)
      setIsSuccessBetModalOpen(true)
    } catch (e) {
      if (e instanceof ApolloError) {
        graphqlErrorHandler(e, 'Ошибка при направлении ставки')
      }
    }
  }

  const onFreightResponseUpdateStatus = async (formValues: ResponseFormValues) => {
    try {
      await freightResponseUpdateStatus({
        variables: {
          freightDealId: lastResponse?.id ?? 0,
          input: {
            status: FreightDealStatus.RejectedAfterModeration,
            rejectionReason: formValues?.rejectionReason,
          },
        },
      })
      setIsCancelResponseModalOpen(false)
      setIsSuccessCancelResponseModalOpen(true)
    } catch (e) {
      e instanceof ApolloError && graphqlErrorHandler(e, 'Ошибка при изменении статуса отклика')
    }
  }

  const timelineItems = [
    {
      label: 'Погрузка',
      children: (
        <Flex vertical gap={16}>
          <Flex gap={16}>
            <Flex gap={8}>
              <FiTruck size={20} />
              <FiArrowLeft size={20} />
            </Flex>
            <Text variant="form-read-value">{freightOrder?.route.points[0].address.short}</Text>
          </Flex>
          <Text variant="normal">{freightOrder?.route.points[0].address.full}</Text>
        </Flex>
      ),
    },
    {
      label: 'Приемка',
      children: (
        <Flex vertical gap={16}>
          <Flex gap={16}>
            <Flex gap={8}>
              <FiTruck size={20} />
              <FiArrowRight size={20} />
            </Flex>
            <Text variant="form-read-value">{freightOrder?.route.points[1].address.short}</Text>
          </Flex>
          <Text variant="normal">{freightOrder?.route.points[1].address.full}</Text>
        </Flex>
      ),
    },
  ]

  const isActiveCounter = Boolean(
    freightOrder?.responsesStatistics?.previousDays.responsesCount ||
      freightOrder?.responsesStatistics?.previousDays.betsCount ||
      freightOrder?.responsesStatistics?.today.responsesCount ||
      freightOrder?.responsesStatistics?.today.betsCount
  )

  return (
    <Spin spinning={getFreightOrder.loading}>
      <AppHeader title={appRoutes.logisticsLoadCard.title} isBack />

      {freightOrder && (
        <S.Wrapper vertical gap={32}>
          <Flex justify="space-between" gap={24}>
            <Flex vertical gap={24}>
              <Flex align="center" gap={16}>
                <S.TextLoad>Груз</S.TextLoad>
                <S.TextId>ID: {freightOrder.id}</S.TextId>
              </Flex>
              <Flex>
                <S.AvailableVolumeWrapper isAvailable={freightOrder.weightKg !== freightOrder.selectedWeightKg}>
                  {freightOrder.weightKg !== freightOrder.selectedWeightKg
                    ? `Доступный объем: ${freightOrder.availableWeightKg / 1000} т`
                    : 'Весь объем укомплектован'}
                </S.AvailableVolumeWrapper>
              </Flex>
            </Flex>
            {isCarrier && (
              <Flex gap={12}>
                <Button
                  type="default"
                  onClick={() => setIsBetModalOpen(true)}
                  disabled={!freightOrder.canCreateResponse || !freightOrder.isAuction}
                >
                  Предложить свою ставку
                </Button>
                <Button
                  type="primary"
                  onClick={() => setIsResponseModalOpen(true)}
                  disabled={!freightOrder.canCreateResponse}
                >
                  Откликнуться
                </Button>
              </Flex>
            )}
            {/*{!isCarrier && (*/}
            {/*  <Flex gap={12}>*/}
            {/*    <Button type="default" onClick={() => setIsBetModalOpen(true)} disabled={!freightOrder.isAuction}>*/}
            {/*      Удалить груз*/}
            {/*    </Button>*/}
            {/*    <Button type="primary" onClick={() => setIsResponseModalOpen(true)}>*/}
            {/*      Редактировать груз*/}
            {/*    </Button>*/}
            {/*  </Flex>*/}
            {/*)}*/}
          </Flex>

          <Flex justify="space-between">
            <Flex vertical gap={60} style={{ width: lastResponse ? '70%' : '100%' }}>
              {!isCarrier && (
                <Flex vertical gap={32}>
                  <Flex>
                    <Text variant="h2">📞 Контакты</Text>
                  </Flex>
                  <Flex gap={32}>
                    <ReadValue name="Контактное лицо" value={freightOrder.contactPerson} />
                    <ReadValue name="Телефон" value={formatPhoneNumber(freightOrder.contactPhoneNumber)} />
                  </Flex>
                </Flex>
              )}

              {freightOrder.route.points[0].freight && (
                <Flex vertical gap={32}>
                  <Flex>
                    <Text variant="h2">📦 Груз</Text>
                  </Flex>
                  <Flex gap={32}>
                    <ReadValue name="Тип груза" value={freightOrder.route.points[0].freight?.productType.name} />
                    <ReadValue
                      name="Вес"
                      value={
                        <p>
                          {formatNumber(freightOrder.availableWeightKg / 1000)} т из{' '}
                          {formatNumber(freightOrder.weightKg / 1000)} т
                        </p>
                      }
                    />
                    <ReadValue
                      name="Вид упаковки"
                      value={getFreightPackingType(freightOrder.route.points[0].freight?.packingType)}
                    />
                    {freightOrder.route.points[0].freight?.packingType === FreightPackingType.BigBag && (
                      <ReadValue name="Кол-во" value={freightOrder.route.points[0].freight?.bigBagsCount?.toString()} />
                    )}
                  </Flex>
                </Flex>
              )}

              <Flex vertical gap={32}>
                <Flex>
                  <Text variant="h2">💳 Условия оплаты</Text>
                </Flex>
                <Flex gap={32}>
                  <ReadValue
                    name="Ставка"
                    value={
                      <>
                        <p>{formatNumber(freightOrder.pricePerTonWithNds)} ₽/т с НДС</p>
                        <p>{formatNumber(freightOrder.pricePerTonWithoutNds)} ₽/т без НДС</p>
                      </>
                    }
                  />
                  <ReadValue
                    name="Общая стоимость"
                    value={
                      freightOrder.route.points[0].freight?.weightKg ? (
                        <>
                          <p>{formatNumber(freightOrder.priceWithNds)} ₽ с НДС</p>
                          <p>{formatNumber(freightOrder.priceWithoutNds)} ₽ без НДС</p>
                        </>
                      ) : undefined
                    }
                  />
                  <ReadValue name="Возможность торга" value={getIsAuctionName(freightOrder.isAuction)} />
                  <ReadValue name="Условия оплаты" value={getFreightPaymentTerm(freightOrder.paymentTerm)} />
                  {freightOrder.paymentTerm === FreightPaymentTerm.Advance ? (
                    <ReadValue
                      name="Процент предоплаты"
                      value={freightOrder.advancePercent ? freightOrder.advancePercent?.toString() + ' %' : undefined}
                    />
                  ) : (
                    <ReadValue
                      name="Кол-во дней отсрочки"
                      value={freightOrder.deferredDaysCount ? freightOrder.deferredDaysCount?.toString() : undefined}
                    />
                  )}
                </Flex>
              </Flex>

              <Flex vertical gap={32}>
                <Flex>
                  <Text variant="h2">🚙 Транспорт</Text>
                </Flex>
                <Flex gap={32}>
                  <ReadValue name="Вид грузоперевозки" value={'Автомобильные'} />
                  <ReadValue name="Тип транспорта" value={getTransportType(freightOrder.transportFormatTypes)} />
                  <ReadValue
                    name="Тип кузова"
                    value={freightOrder.transportBodyTypes
                      .map((transportBodyType) => transportBodyType.name)
                      .join(', ')}
                  />
                  <ReadValue
                    name="Способ погрузки"
                    value={freightOrder.route.points[0].roadTransportParameters.loadingTypes
                      .map((loadingType) => getLoadType(loadingType))
                      .join(', ')}
                  />
                  <ReadValue
                    name="Способ приемки"
                    value={freightOrder.route.points[1].roadTransportParameters.unloadingTypes
                      .map((unloadingType) => getUnloadType(unloadingType))
                      .join(', ')}
                  />
                </Flex>
              </Flex>

              <Flex vertical gap={32}>
                <Flex>
                  <Text variant="h2">🗺 Маршрут</Text>
                </Flex>

                <Flex gap={32}>
                  {freightOrder.route.distanceToStartKm && (
                    <Flex gap={8}>
                      <Text variant="label">Удаленность от автопарка</Text>
                      <Text variant="label-bold">{freightOrder.route.distanceToStartKm} км</Text>
                    </Flex>
                  )}
                  {freightOrder.startedAt && (
                    <Flex gap={8}>
                      <Text variant="label">Дата начала погрузки</Text>
                      <Text variant="label-bold">{getDateFormat(freightOrder.startedAt)}</Text>
                      {freightOrder.startDaysCount && (
                        <Text variant="label-bold">{`+${freightOrder.startDaysCount} ${getNoun(
                          freightOrder.startDaysCount,
                          'день',
                          'дня',
                          'дней'
                        )}`}</Text>
                      )}
                    </Flex>
                  )}
                </Flex>
                <Flex>
                  <Timeline mode="left" items={timelineItems} style={{ width: '100%' }} />
                </Flex>
                <Flex>
                  <Text variant="h4">Длина маршрута: {freightOrder.route.distance} км</Text>
                </Flex>
              </Flex>

              <Flex vertical gap={32}>
                <Flex
                  justify="space-between"
                  style={{
                    padding: '16px',
                    borderTop: '1px solid #e9ecee',
                    borderBottom: '1px solid #e9ecee',
                    cursor: 'pointer',
                  }}
                  onClick={() => setIsOpenTechnicalCharacteristics((prev) => !prev)}
                >
                  <Flex align="center" gap={8}>
                    <FiList size={24} />
                    <Text variant="h2">Тех. характеристики</Text>
                  </Flex>

                  <Flex align="center" gap={10} style={{ color: 'var(--primary-main-color)' }}>
                    Подробнее{' '}
                    {!isOpenTechnicalCharacteristics ? <FiChevronDown size={20} /> : <FiChevronUp size={20} />}
                  </Flex>
                </Flex>
                {isOpenTechnicalCharacteristics && (
                  <>
                    <Flex>
                      <Text variant="h4">Основное</Text>
                    </Flex>
                    <Flex gap={32}>
                      <Flex vertical gap={32}>
                        <ReadValue
                          name="Способ погрузки"
                          value={freightOrder.route.points[0].roadTransportParameters.loadingTypes
                            .map((loadingType) => getLoadType(loadingType))
                            .join(', ')}
                        />
                        <ReadValue
                          name="Скорость погрузки"
                          value={
                            freightOrder.route.points[0].roadTransportParameters.loadingThroughput
                              ? freightOrder.route.points[0].roadTransportParameters.loadingThroughput?.toString() +
                                ' авто/сутки'
                              : undefined
                          }
                        />
                        <ReadValue
                          name="Длина весов"
                          value={
                            freightOrder.route.points[0].roadTransportParameters.vehicleLengthMaxM
                              ? freightOrder.route.points[0].roadTransportParameters.vehicleLengthMaxM?.toString() +
                                ' м'
                              : undefined
                          }
                        />
                        <ReadValue
                          name="Ограничения для ТС по высоте"
                          value={
                            freightOrder.route.points[0].roadTransportParameters.vehicleHeightMaxM
                              ? freightOrder.route.points[0].roadTransportParameters.vehicleHeightMaxM?.toString() +
                                ' м'
                              : undefined
                          }
                        />
                        {/*<ReadValue*/}
                        {/*  name="Ограничения для ТС по высоте клиренса"*/}
                        {/*  value={*/}
                        {/*    freightOrder.route.points[0].roadTransportParameters.vehicleClearanceMinCm*/}
                        {/*      ? freightOrder.route.points[0].roadTransportParameters.vehicleClearanceMinCm?.toString() +*/}
                        {/*      ' см'*/}
                        {/*      : undefined*/}
                        {/*  }*/}
                        {/*/>*/}
                        <ReadValue
                          name="Наличие весов"
                          value={
                            freightOrder.route.points[0].roadTransportParameters.hasScales !== null
                              ? freightOrder.route.points[0].roadTransportParameters.hasScales
                                ? 'Есть'
                                : 'Нет'
                              : undefined
                          }
                        />
                        <ReadValue
                          name="Тоннаж весов"
                          value={
                            freightOrder.route.points[0].roadTransportParameters.scalesWeightLimitT
                              ? freightOrder.route.points[0].roadTransportParameters.scalesWeightLimitT?.toString() +
                                ' т'
                              : undefined
                          }
                        />
                        {/*<ReadValue*/}
                        {/*  name="Автоматизация взвешивания (ПО)"*/}
                        {/*  value={*/}
                        {/*    freightOrder.route.points[0].roadTransportParameters.hasWeighingAutomation !== null ? freightOrder.route.points[0].roadTransportParameters.hasWeighingAutomation ? 'Есть' : 'Нет' : undefined*/}
                        {/*  }*/}
                        {/*/>*/}
                      </Flex>
                      <Flex vertical gap={32}>
                        <ReadValue
                          name="Способ приемки"
                          value={freightOrder.route.points[1].roadTransportParameters.unloadingTypes
                            .map((unloadingType) => getUnloadType(unloadingType))
                            .join(', ')}
                        />
                        <ReadValue
                          name="Скорость приемки"
                          value={
                            freightOrder.route.points[1].roadTransportParameters.unloadingThroughput
                              ? freightOrder.route.points[1].roadTransportParameters.unloadingThroughput?.toString() +
                                ' авто/сутки'
                              : undefined
                          }
                        />
                        <ReadValue
                          name="Длина весов"
                          value={
                            freightOrder.route.points[1].roadTransportParameters.vehicleLengthMaxM
                              ? freightOrder.route.points[1].roadTransportParameters.vehicleLengthMaxM?.toString() +
                                ' м'
                              : undefined
                          }
                        />
                        <ReadValue
                          name="Ограничения для ТС по высоте"
                          value={
                            freightOrder.route.points[1].roadTransportParameters.vehicleHeightMaxM
                              ? freightOrder.route.points[1].roadTransportParameters.vehicleHeightMaxM?.toString() +
                                ' м'
                              : undefined
                          }
                        />
                        {/*<ReadValue*/}
                        {/*  name="Ограничения для ТС по высоте клиренса"*/}
                        {/*  value={*/}
                        {/*    freightOrder.route.points[1].roadTransportParameters.vehicleClearanceMinCm*/}
                        {/*      ? freightOrder.route.points[1].roadTransportParameters.vehicleClearanceMinCm?.toString() +*/}
                        {/*      ' см'*/}
                        {/*      : undefined*/}
                        {/*  }*/}
                        {/*/>*/}
                        <ReadValue
                          name="Наличие весов"
                          value={
                            freightOrder.route.points[1].roadTransportParameters.hasScales !== null
                              ? freightOrder.route.points[1].roadTransportParameters.hasScales
                                ? 'Есть'
                                : 'Нет'
                              : undefined
                          }
                        />
                        <ReadValue
                          name="Тоннаж весов"
                          value={
                            freightOrder.route.points[1].roadTransportParameters.scalesWeightLimitT
                              ? freightOrder.route.points[1].roadTransportParameters.scalesWeightLimitT?.toString() +
                                ' т'
                              : undefined
                          }
                        />
                        {/*<ReadValue*/}
                        {/*  name="Автоматизация взвешивания (ПО)"*/}
                        {/*  value={*/}
                        {/*    freightOrder.route.points[1].roadTransportParameters.hasWeighingAutomation !== null ? freightOrder.route.points[1].roadTransportParameters.hasWeighingAutomation ? 'Есть' : 'Нет' : undefined*/}
                        {/*  }*/}
                        {/*/>*/}
                      </Flex>
                    </Flex>
                    <Flex>
                      <Text variant="h4">Дорога к подъезду и складу</Text>
                    </Flex>
                    <Flex gap={32}>
                      <Flex vertical gap={32}>
                        <ReadValue
                          name="Тип покрытия"
                          value={
                            freightOrder.route.points[0].roadTransportParameters.roadSurfaceType !== null
                              ? getRoadSurfaceTypeName(
                                  freightOrder.route.points[0].roadTransportParameters.roadSurfaceType
                                )
                              : undefined
                          }
                        />
                        <ReadValue
                          name="Качество покрытия"
                          value={
                            freightOrder.route.points[0].roadTransportParameters.roadSurfaceQuality !== null
                              ? getRoadSurfaceQualityName(
                                  freightOrder.route.points[0].roadTransportParameters.roadSurfaceQuality
                                )
                              : undefined
                          }
                        />
                        <ReadValue
                          name="Расстояние от ворот до весов"
                          value={
                            freightOrder.route.points[0].roadTransportParameters.distanceToScalesM
                              ? freightOrder.route.points[0].roadTransportParameters.distanceToScalesM?.toString() +
                                ' м'
                              : undefined
                          }
                        />
                        <ReadValue
                          name="Площадка-накопитель для ТС"
                          value={
                            freightOrder.route.points[0].roadTransportParameters.hasParking !== null
                              ? freightOrder.route.points[0].roadTransportParameters.hasParking
                                ? 'Есть'
                                : 'Нет'
                              : undefined
                          }
                        />
                        <ReadValue
                          name="Дополнительные сведения"
                          value={freightOrder.route.points[0].roadTransportParameters.additionalDetails}
                        />
                      </Flex>
                      <Flex vertical gap={32}>
                        <ReadValue
                          name="Тип покрытия"
                          value={
                            freightOrder.route.points[1].roadTransportParameters.roadSurfaceType !== null
                              ? getRoadSurfaceTypeName(
                                  freightOrder.route.points[1].roadTransportParameters.roadSurfaceType
                                )
                              : undefined
                          }
                        />
                        <ReadValue
                          name="Качество покрытия"
                          value={
                            freightOrder.route.points[1].roadTransportParameters.roadSurfaceQuality !== null
                              ? getRoadSurfaceQualityName(
                                  freightOrder.route.points[1].roadTransportParameters.roadSurfaceQuality
                                )
                              : undefined
                          }
                        />
                        <ReadValue
                          name="Расстояние от ворот до весов"
                          value={
                            freightOrder.route.points[1].roadTransportParameters.distanceToScalesM
                              ? freightOrder.route.points[1].roadTransportParameters.distanceToScalesM?.toString() +
                                ' м'
                              : undefined
                          }
                        />
                        <ReadValue
                          name="Площадка-накопитель для ТС"
                          value={
                            freightOrder.route.points[1].roadTransportParameters.hasParking !== null
                              ? freightOrder.route.points[1].roadTransportParameters.hasParking
                                ? 'Есть'
                                : 'Нет'
                              : undefined
                          }
                        />
                        <ReadValue
                          name="Дополнительные сведения"
                          value={freightOrder.route.points[1].roadTransportParameters.additionalDetails}
                        />
                      </Flex>
                    </Flex>
                  </>
                )}
              </Flex>

              {freightOrder.companyId === companyId && (
                <Flex vertical gap={16}>
                  <LoadDocuments freightOrderId={freightOrderId} title="Образцы документов" />
                </Flex>
              )}

              <Flex vertical gap={16}>
                <Flex>
                  <Text variant="h2">Дополнительно</Text>
                </Flex>
                <Flex>
                  <Text variant="normal">
                    {freightOrder.additionalDetails ? freightOrder.additionalDetails : 'Не заполнено'}
                  </Text>
                </Flex>
              </Flex>
            </Flex>

            {!isCarrier && freightOrder.responsesStatistics && (
              <Flex align="flex-start" style={{ width: '30%' }}>
                <S.CounterWrapper
                  active={isActiveCounter}
                  gap={60}
                  align="center"
                  onClick={() => {
                    logisticResponsesFilterStore.setFilters({
                      ...logisticResponsesFilterStore.responsesFilters,
                      freightOrderIds: [freightOrderId],
                    })
                    navigate(appRoutes.logisticResponses.url)
                  }}
                >
                  <Flex vertical>
                    <Flex gap={4} align="center">
                      <Text variant="h2">{freightOrder.responsesStatistics?.previousDays.responsesCount}</Text>
                      <Text variant="h4" style={{ color: isActiveCounter ? '#45A67D' : '#424242' }}>
                        +{freightOrder.responsesStatistics?.today.responsesCount}
                      </Text>
                    </Flex>
                    <Flex>
                      <Text variant="normal-bold">
                        {getNoun(
                          freightOrder.responsesStatistics?.previousDays.responsesCount ?? 0,
                          'Отклик',
                          'Отклика',
                          'Откликов'
                        )}
                      </Text>
                    </Flex>
                  </Flex>
                  <Flex vertical>
                    <Flex gap={4} align="center">
                      <Text variant="h2">{freightOrder.responsesStatistics?.previousDays.betsCount}</Text>
                      <Text variant="h4" style={{ color: isActiveCounter ? '#45A67D' : '#424242' }}>
                        +{freightOrder.responsesStatistics?.today.betsCount}
                      </Text>
                    </Flex>
                    <Flex>
                      <Text variant="normal-bold">
                        {getNoun(
                          freightOrder.responsesStatistics?.previousDays.betsCount ?? 0,
                          'Ставка',
                          'Ставки',
                          'Ставок'
                        )}
                      </Text>
                    </Flex>
                  </Flex>
                  <Flex>
                    <FiArrowRight size={24} />
                  </Flex>
                </S.CounterWrapper>
              </Flex>
            )}

            {isCarrier && lastResponse && (
              <Flex vertical gap={16} style={{ width: '30%', maxWidth: '300px' }}>
                <Flex
                  vertical
                  gap={8}
                  style={{
                    backgroundColor: getFreightLastResponseColor(lastResponse.status),
                    padding: '16px',
                    borderRadius: '6px',
                  }}
                >
                  <Flex gap={8} align="center">
                    {lastResponse.status === FreightDealStatus.OnModeration && <>👀</>}
                    {[FreightDealStatus.Agreement, FreightDealStatus.Realised, FreightDealStatus.Rejected].includes(
                      lastResponse.status
                    ) && <FiCheck size={20} />}
                    {lastResponse.status === FreightDealStatus.RejectedAfterModeration && <IoCloseOutline size={20} />}
                    <Text variant="normal-bold">
                      {(lastResponse.type === FreightDealType.Response ? 'Отклик ' : 'Ставка ') +
                        getFreightLastResponseName(lastResponse.status, lastResponse.type).toLowerCase()}
                    </Text>
                  </Flex>
                  <Text variant="normal">
                    {getFreightLastResponseText(
                      lastResponse.status,
                      lastResponse.type,
                      lastResponse.order.canCreateResponse
                    )}
                  </Text>
                  <Text variant="form-read-label">Дата отклика: {getDateFormat(lastResponse.createdAt)}</Text>
                </Flex>

                <Flex
                  vertical
                  gap={16}
                  style={{
                    backgroundColor: '#FAFAFB',
                    padding: '16px',
                  }}
                >
                  {lastResponse.type === FreightDealType.Bet && (
                    <Flex gap={8}>
                      <Text variant="form-read-label">Ставка: </Text>
                      <Text variant="normal">{formatNumber(lastResponse.dealOrder.pricePerTonWithNds)} ₽/т с НДС</Text>
                    </Flex>
                  )}
                  <Flex gap={8}>
                    <Text variant="form-read-label">Вес: </Text>
                    <Text variant="normal">{formatNumber(lastResponse.dealOrder.weightKg / 1000)} т</Text>
                  </Flex>
                  {lastResponse.type === FreightDealType.Bet && (
                    <Flex gap={8}>
                      <Text variant="form-read-label">Условия оплаты: </Text>
                      <Text variant="normal">{getFreightPaymentTerm(lastResponse.dealOrder.paymentTerm)}</Text>
                    </Flex>
                  )}
                  {lastResponse.type === FreightDealType.Bet && (
                    <Flex gap={8}>
                      <Text variant="form-read-label">Дата погрузки: </Text>
                      <Text variant="normal">{getDateFormat(lastResponse.dealOrder.startedAt)}</Text>
                    </Flex>
                  )}
                  {[FreightDealStatus.Agreement, FreightDealStatus.Realised, FreightDealStatus.Rejected].includes(
                    lastResponse.status
                  ) && (
                    <Flex gap={8}>
                      <Button
                        type="primary"
                        onClick={() =>
                          navigate(appRoutes.logisticDealCard.url.replace(':id', lastResponse.id.toString()))
                        }
                        style={{ width: '100%' }}
                      >
                        Перейти к заказу
                      </Button>
                    </Flex>
                  )}
                  {lastResponse.status === FreightDealStatus.OnModeration && (
                    <Flex gap={8}>
                      {lastResponse.type === FreightDealType.Response && (
                        <Button onClick={() => setIsCancelResponseModalOpen(true)} style={{ width: '100%' }}>
                          Отменить отклик
                        </Button>
                      )}
                      {lastResponse.type === FreightDealType.Bet && (
                        <Button onClick={() => setIsCancelResponseModalOpen(true)} style={{ width: '100%' }}>
                          Отменить ставку
                        </Button>
                      )}
                    </Flex>
                  )}
                </Flex>
              </Flex>
            )}
          </Flex>

          <Modal
            {...modalSharedSettings}
            width={800}
            open={isResponseModalOpen}
            onCancel={() => setIsResponseModalOpen(false)}
            title="Направить отклик"
          >
            <ResponseToLoadForm
              onBack={() => setIsResponseModalOpen(false)}
              onSubmit={onCreateFreightOrderResponseHandler}
              freightOrder={freightOrder}
            />
          </Modal>

          <Modal
            {...modalSharedSettings}
            width={400}
            open={isSuccessResponseModalOpen}
            onCancel={() => setIsSuccessResponseModalOpen(false)}
            title="Отклик направлен"
          >
            <Flex vertical gap={24}>
              <Flex>
                <Text variant="normal">Вы можете найти его в разделе “Отклики”</Text>
              </Flex>

              <Flex gap={16} justify="flex-end">
                <Button htmlType="button" type="primary" onClick={() => setIsSuccessResponseModalOpen(false)}>
                  Понятно!
                </Button>
              </Flex>
            </Flex>
          </Modal>

          <Modal
            {...modalSharedSettings}
            width={800}
            open={isBetModalOpen}
            onCancel={() => setIsBetModalOpen(false)}
            title="Направить ставку"
          >
            <BetToLoadForm
              onBack={() => setIsBetModalOpen(false)}
              onSubmit={onCreateFreightOrderBetHandler}
              freightOrder={freightOrder}
            />
          </Modal>

          <Modal
            {...modalSharedSettings}
            width={400}
            open={isSuccessBetModalOpen}
            onCancel={() => setIsSuccessBetModalOpen(false)}
            title="Ставка направлена"
          >
            <Flex vertical gap={24}>
              <Flex>
                <Text variant="normal">Вы можете найти ее в разделе “Отклики”</Text>
              </Flex>

              <Flex gap={16} justify="flex-end">
                <Button htmlType="button" type="primary" onClick={() => setIsSuccessBetModalOpen(false)}>
                  Понятно!
                </Button>
              </Flex>
            </Flex>
          </Modal>

          <Modal
            {...modalSharedSettings}
            width={400}
            open={isCancelResponseModalOpen}
            onCancel={() => setIsCancelResponseModalOpen(false)}
            title={lastResponse?.type === FreightDealType.Response ? 'Отменить отклик' : 'Отменить ставку'}
          >
            <ResponseForm onBack={() => setIsCancelResponseModalOpen(false)} onSubmit={onFreightResponseUpdateStatus} />
          </Modal>

          <Modal
            {...modalSharedSettings}
            width={400}
            open={isSuccessCancelResponseModalOpen}
            onCancel={() => setIsSuccessCancelResponseModalOpen(false)}
            title={lastResponse?.type === FreightDealType.Response ? 'Отклик отменен' : 'Ставка отменена'}
          >
            <Flex vertical gap={24}>
              <Flex>
                <Text variant="normal">
                  {`Причина будет указана в карточке ${
                    lastResponse?.type === FreightDealType.Response ? 'отклика' : 'ставки'
                  }`}
                </Text>
              </Flex>

              <Flex gap={16} justify="flex-end">
                <Button htmlType="button" type="primary" onClick={() => setIsSuccessCancelResponseModalOpen(false)}>
                  Понятно!
                </Button>
              </Flex>
            </Flex>
          </Modal>
        </S.Wrapper>
      )}
    </Spin>
  )
}
