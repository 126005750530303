import styled from 'styled-components'

import { css } from '@styled-system/css'

export const Desktop = styled.div(
  css({
    display: ['none', null, 'block'],
  })
)

export const Mobile = styled.div(
  css({
    display: ['block', null, 'none'],
  })
)

export const Filters = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
  margin-left: auto;
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  overflow: hidden;
`
