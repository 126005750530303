import styled from 'styled-components'

import css from '@styled-system/css'

import { Card, Divider } from '~/ui-components'

export const Content4Column = styled.div(
  css({
    display: 'grid',
    gridTemplateColumns: '1fr 2fr 2fr 4fr',
    columnGap: ['4px', '10px', '20px'],
    rowGap: '16px',
    gridColumn: '1/5',
    alignItems: 'center',
  })
)
export const Content4ColumnResponsive = styled(Content4Column)(
  css({
    gridTemplateColumns: ['1fr 1fr', '1fr 1fr', '1fr 1fr', '1fr 2fr 2fr 4fr', '1fr 2fr 2fr 4fr'],
  })
)
export const Label = styled(Card.Label)(
  css({
    minWidth: '150px',
  })
)
export const ValueColumnFull = styled(Card.Value)(
  css({
    gridColumn: ['1/-1', '1/-1', '1/-1', '2/-1'],
  })
)
export const DividerFull = styled(Divider)(
  css({
    gridColumn: '1/-1',
  })
)
