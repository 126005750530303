import { useMutation } from '@tanstack/react-query'

import { ElevatorAdvertOfferApiCreateOfferResponsePurchaseStatusRequest } from '~api/generated'
import { useApi } from '~providers/api'

export const useSetPurchaseStatusAdvertOffer = () => {
  const api = useApi()

  const fn = async (request: ElevatorAdvertOfferApiCreateOfferResponsePurchaseStatusRequest) => {
    const res = await api.elevatorAdvertOffer.createOfferResponsePurchaseStatus(request)

    return res.data
  }

  return useMutation(fn)
}
