import React, { useState } from 'react'

import { Button } from 'antd'
import { observer } from 'mobx-react-lite'

import { Text } from '~/ui-components'
import { appToast, httpErrorHandler } from '~/utils'
import { useGetDevice } from '~hooks/common'
import { useSetPurchaseStatusAdvertOffer } from '~hooks/elevator-advert-offers'
import { BasisAdvertOfferChangeStatusModal } from '~pages/BasisPage/components/BasisAdvertOfferChangeStatusModal'
import { useAuth } from '~providers/auth'

import { BasisAdvertOfferChangeStatusButtonProps } from './BasisAdvertOfferChangeStatusButton.types'
import { ChangeStatusBasisOfferForm } from '~pages/BasisPage/components/BasisAdvertOfferChangeStatusModal/BasisAdvertOfferChangeStatusModal.types'

import * as S from './BasisAdvertOfferChangeStatusButton.styled'

export const BasisAdvertOfferChangeStatusButton: React.FC<BasisAdvertOfferChangeStatusButtonProps> = observer(
  (props) => {
    const device = useGetDevice()
    const isMobile = device === 'mobile'

    const { companyId } = useAuth()
    const [isOpenModal, setIsOpenModal] = useState(false)
    const setPurchaseStatusAdvertOfferFn = useSetPurchaseStatusAdvertOffer()

    const onChangeStatusHandler = () => setIsOpenModal(true)
    const handleConfirmOfferDealWithComment = async (form: ChangeStatusBasisOfferForm) => {
      try {
        const comment = form.isDealComplete ? form.successDealComment ?? '' : form.rejectDealComment ?? ''
        await setPurchaseStatusAdvertOfferFn.mutateAsync({
          id: props.offerId,
          createOfferResponsePurchaseStatusBody: {
            userCompanyId: companyId,
            isPurchaseTookPlace: form.isDealComplete,
            comment,
          },
        })
        void props.onAfterStatusChange()
        appToast.success({ description: 'Статус отклика изменен' })
      } catch (e) {
        httpErrorHandler(e, 'Ошибка при изменении статуса отклика')
      }
    }
    const handleConfirmOfferDeal = async (isPurchaseTookPlace: boolean) => {
      try {
        await setPurchaseStatusAdvertOfferFn.mutateAsync({
          id: props.offerId,
          createOfferResponsePurchaseStatusBody: {
            userCompanyId: companyId,
            isPurchaseTookPlace,
            comment: '',
          },
        })
        void props.onAfterStatusChange()
        appToast.success({ description: 'Статус отклика изменен' })
      } catch (e) {
        httpErrorHandler(e, 'Ошибка при изменении статуса отклика')
      }
    }

    if (props.offerStatus !== 'ACCEPTED') {
      return null
    }

    const renderStatusButton = () => {
      // когда ещё никто не изменил статус
      if (props.counterpartyPurchaseStatus === null && props.myPurchaseStatus === null) {
        return (
          <S.SpaceWrapper>
            {isMobile && (
              <S.ChangeStatusText>
                Если вы {props.type === 'SELL' ? 'продали' : 'купили'} товар или сделка не состоялась, вы можете
                изменить статус вашего отклика.
              </S.ChangeStatusText>
            )}
            <Button type="primary" htmlType="button" onClick={onChangeStatusHandler}>
              Изменить статус отклика
            </Button>
          </S.SpaceWrapper>
        )
      }
      // когда другая сторона изменила статус
      if (props.counterpartyPurchaseStatus !== null && props.myPurchaseStatus === null) {
        // когда другая сторона подтвердила сделку, а пользователь ещё нет
        if (props.counterpartyPurchaseStatus) {
          return (
            <S.SpaceWrapper style={{ flexDirection: 'column' }}>
              <S.ChangeStatusText>
                {props.type === 'SELL' ? 'Покупатель' : 'Продавец'} сообщил, что товар откуплен. Нам нужно подтверждение
                с вашей стороны
              </S.ChangeStatusText>
              <S.SpaceWrapper gap={8} style={{ justifyContent: 'center' }}>
                <Button type="primary" htmlType="button" onClick={() => handleConfirmOfferDeal(true)}>
                  Товар откуплен
                </Button>
                <Button type="default" htmlType="button" onClick={() => handleConfirmOfferDeal(false)}>
                  Товар не откуплен
                </Button>
              </S.SpaceWrapper>
            </S.SpaceWrapper>
          )
        }
        if (!props.counterpartyPurchaseStatus) {
          return (
            <S.SpaceWrapper>
              <S.ChangeStatusText>
                {props.type === 'SELL' ? 'Покупатель' : 'Продавец'} сообщил, что сделка не состоялась. Нам нужно
                подтверждение с вашей стороны
              </S.ChangeStatusText>
              <S.SpaceWrapper gap={8}>
                <Button
                  type="primary"
                  htmlType="button"
                  style={{ width: '100%' }}
                  onClick={() => handleConfirmOfferDeal(false)}
                >
                  Сделка не состоялась
                </Button>
                <Button
                  type="default"
                  htmlType="button"
                  style={{ width: '100%' }}
                  onClick={() => handleConfirmOfferDeal(true)}
                >
                  Сделка состоялась
                </Button>
              </S.SpaceWrapper>
            </S.SpaceWrapper>
          )
        }
      }
      // когда пользователь сам изменил статус
      if (props.myPurchaseStatus !== null && props.counterpartyPurchaseStatus === null) {
        if (props.myPurchaseStatus) {
          return (
            <Text variant="normal-bold">
              Вы указали что сделка состоялась. Ожидаем подтверждения от{' '}
              {props.type === 'SELL' ? 'покупателя' : 'продавеца'}
            </Text>
          )
        }
        if (!props.myPurchaseStatus) {
          return (
            <Text variant="normal-bold">
              Вы указали что сделка НЕ состоялась. Ожидаем подтверждения от{' '}
              {props.type === 'SELL' ? 'покупателя' : 'продавеца'}
            </Text>
          )
        }
      }
    }

    return (
      <div>
        {renderStatusButton()}

        <BasisAdvertOfferChangeStatusModal
          isOpen={isOpenModal}
          onClose={() => setIsOpenModal(false)}
          onSubmit={handleConfirmOfferDealWithComment}
        />
      </div>
    )
  }
)
