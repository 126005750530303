import React from 'react'

import { FiArrowRight } from 'react-icons/fi'

import { Card } from '~/ui-components'

import { SimilarBasisCardProps } from './SimilarBasisCard.types'

import * as S from './SimilarBasisCard.styled'

export const SimilarBasisCard: React.FC<SimilarBasisCardProps> = (props) => {
  return (
    <Card style={{ cursor: 'pointer' }} onClick={() => props.onClick(props.elevatorData.id)}>
      <Card.Header>
        <Card.Title>{props.elevatorData.name}</Card.Title> <FiArrowRight size={24} />
      </Card.Header>
      <Card.Content>
        <S.Address>{props.elevatorData.address}</S.Address>
      </Card.Content>
    </Card>
  )
}
