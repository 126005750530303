import React from 'react'

import { RouteObject } from 'react-router-dom'

import { BasisPage } from '~/pages'
import { MyAdvertOffersListItemDesktop } from '~/pages/BasisPage/modules/MyAdvertOffers/components/MyAdvertOffersList/components/MyAdvertOffersListItemDesktop'
import { appRoutes } from '~/router'
import { BasisInfo } from '~pages/BasisPage/components/BasisInfo'
import { BasisAdvert } from '~pages/BasisPage/modules/BasisAdvert'
import { BasisAdvertOffer } from '~pages/BasisPage/modules/BasisAdvertOffer'
import { BasisCompaniesAdverts } from '~pages/BasisPage/modules/BasisCompaniesAdverts'
import { BasisCompanyAdverts } from '~pages/BasisPage/modules/BasisCompanyAdverts'
import { BasisDetails } from '~pages/BasisPage/modules/BasisDetails'
import { BasisList } from '~pages/BasisPage/modules/BasisList'
import { BasisVip } from '~pages/BasisPage/modules/BasisVip'
import { MyAdvert } from '~pages/BasisPage/modules/MyAdvert'
import { MyAdvertOffers } from '~pages/BasisPage/modules/MyAdvertOffers'

import { RequireAuth } from '../../providers/auth'

export const basisRouter: RouteObject[] = [
  {
    path: appRoutes.basisVip.url,
    element: (
      <RequireAuth>
        <BasisVip />
      </RequireAuth>
    ),
  },
  {
    path: appRoutes.basis.url,
    element: (
      <RequireAuth>
        <BasisPage />
      </RequireAuth>
    ),
    children: [
      {
        path: appRoutes.basisList.url,
        element: <BasisList />,
      },
      {
        path: appRoutes.basisMyBids.url,
        element: <MyAdvertOffers />,
      },
      {
        path: appRoutes.basisMyAdvert.url,
        element: <MyAdvert />,
      },
    ],
  },
  {
    path: appRoutes.basisDetails.url,
    element: (
      <RequireAuth>
        <BasisDetails />
      </RequireAuth>
    ),
  },
  {
    path: appRoutes.basisAdvert.url,
    element: (
      <RequireAuth>
        <BasisAdvert />
      </RequireAuth>
    ),
  },
  {
    path: appRoutes.basisMyAdvertResponse.url,
    element: (
      <RequireAuth>
        <MyAdvertOffersListItemDesktop />
      </RequireAuth>
    ),
  },
  {
    path: appRoutes.basisAdvertOffer.url,
    element: (
      <RequireAuth>
        <BasisAdvertOffer />
      </RequireAuth>
    ),
  },
  {
    path: appRoutes.basisAdverts.url,
    element: (
      <RequireAuth>
        <BasisCompaniesAdverts />
      </RequireAuth>
    ),
  },
  {
    path: appRoutes.basisCompanyAdverts.url,
    element: (
      <RequireAuth>
        <BasisCompanyAdverts />
      </RequireAuth>
    ),
  },
  {
    path: appRoutes.basisInfo.url,
    element: (
      <RequireAuth>
        <BasisInfo />
      </RequireAuth>
    ),
  },
]
