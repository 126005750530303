import React, { useState } from 'react'

import { Button, Col, Flex, Input, InputNumber, Radio, Row } from 'antd'
import { Controller, useFormContext } from 'react-hook-form'
import { FiAlertCircle, FiPlus, FiTrash2 } from 'react-icons/fi'

import { FormField, Text } from '~/ui-components'
import { inputSharedSettings } from '~/utils/inputSharedSettings'
import { RoadSurfaceQuality, RoadSurfaceType, RoutePointType } from '~api/gql-generated/graphql'
import { YesOrNotControl } from '~shared/controls'
import { getRoadSurfaceQualityName } from '~shared/controls/RoadSurfaceQualitySelectControl/get-road-surface-quality-name'
import { getRoadSurfaceTypeName } from '~shared/controls/RoadSurfaceTypeSelectControl/get-road-surface-type-name'

import { CreateLoadFormFieldProps } from '~pages/LogisticsPage/modules/MyCargos/components/CreateLoad/CreateLoad.types'

export const TechnicalCharacteristics: React.FC<
  CreateLoadFormFieldProps & { type?: RoutePointType; index: number }
> = ({ control, errors, type, index }) => {
  const [isExpand, setIsExpand] = useState(false)
  const { setValue, watch } = useFormContext()
  const hasScales = watch(`routePoints.${index}.roadTransportParameters.hasScales`)

  const deleteRoadTransportParameters = () => {
    setIsExpand(false)
    setValue(`routePoints.${index}.roadTransportParameters.loadingThroughput`, undefined)
    setValue(`routePoints.${index}.roadTransportParameters.unloadingThroughput`, undefined)
    setValue(`routePoints.${index}.roadTransportParameters.vehicleLengthMaxM`, undefined)
    setValue(`routePoints.${index}.roadTransportParameters.vehicleHeightMaxM`, undefined)
    setValue(`routePoints.${index}.roadTransportParameters.vehicleClearanceMinCm`, undefined)
    setValue(`routePoints.${index}.roadTransportParameters.hasParking`, undefined)
    setValue(`routePoints.${index}.roadTransportParameters.hasScales`, undefined)
    setValue(`routePoints.${index}.roadTransportParameters.hasWeighingAutomation`, undefined)
    setValue(`routePoints.${index}.roadTransportParameters.distanceToScalesM`, undefined)
    setValue(`routePoints.${index}.roadTransportParameters.scalesWeightLimitT`, undefined)
    setValue(`routePoints.${index}.roadTransportParameters.roadSurfaceType`, undefined)
    setValue(`routePoints.${index}.roadTransportParameters.roadSurfaceQuality`, undefined)
    setValue(`routePoints.${index}.roadTransportParameters.additionalDetails`, undefined)
  }

  return (
    <>
      {!isExpand && (
        <Col span={24}>
          <Flex align="center" gap={16}>
            <Button type="default" icon={<FiPlus />} onClick={() => setIsExpand(true)}>
              {`Тех. характеристики пункта ${type === RoutePointType.Start ? 'погрузки' : 'приемки'}`}
            </Button>
            <FiAlertCircle size="24" />
            <Text variant="normal">Вы можете заполнить тех. характеристики после получения отклика</Text>
          </Flex>
        </Col>
      )}

      {isExpand && (
        <Col span={24}>
          <Flex vertical gap={24}>
            <Flex align="center" gap={16} style={{ padding: '12px', backgroundColor: '#EEEFF1' }}>
              <Button type="text" icon={<FiTrash2 />} onClick={deleteRoadTransportParameters}>
                Удалить
              </Button>
              <Text variant="normal">{`Технические характеристики пункта ${
                type === RoutePointType.Start ? 'погрузки' : 'приемки'
              }`}</Text>
            </Flex>
            <Flex align="center" gap={16} style={{ paddingBottom: '24px' }}>
              <FiAlertCircle size="24" />
              <Text variant="normal">Вы можете заполнить тех. характеристики после получения отклика</Text>
            </Flex>
          </Flex>
        </Col>
      )}

      {isExpand && (
        <>
          <Col sm={{ span: 12 }} lg={{ span: 10 }}>
            {type === RoutePointType.Start ? (
              <FormField
                htmlFor={`routePoints.${index}.roadTransportParameters.loadingThroughput`}
                label="Скорость погрузки"
                validateMessage={errors.routePoints?.[index]?.roadTransportParameters?.loadingThroughput?.message}
              >
                <Controller
                  name={`routePoints.${index}.roadTransportParameters.loadingThroughput`}
                  control={control}
                  render={({ field }) => (
                    <InputNumber
                      {...field}
                      {...inputSharedSettings}
                      addonAfter="Авто/сутки"
                      style={{ width: '100%', maxWidth: '342px' }}
                      id={`routePoints.${index}.roadTransportParameters.loadingThroughput`}
                      placeholder="Введите значение"
                      status={errors.routePoints?.[index]?.roadTransportParameters?.loadingThroughput && 'error'}
                      step={1}
                    />
                  )}
                />
              </FormField>
            ) : (
              <FormField
                htmlFor={`routePoints.${index}.roadTransportParameters.unloadingThroughput`}
                label="Скорость приемки"
                validateMessage={errors.routePoints?.[index]?.roadTransportParameters?.unloadingThroughput?.message}
              >
                <Controller
                  name={`routePoints.${index}.roadTransportParameters.unloadingThroughput`}
                  control={control}
                  render={({ field }) => (
                    <InputNumber
                      {...field}
                      {...inputSharedSettings}
                      addonAfter="Авто/сутки"
                      style={{ width: '100%', maxWidth: '342px' }}
                      id={`routePoints.${index}.roadTransportParameters.unloadingThroughput`}
                      placeholder="Введите значение"
                      status={errors.routePoints?.[index]?.roadTransportParameters?.unloadingThroughput && 'error'}
                      step={1}
                    />
                  )}
                />
              </FormField>
            )}
          </Col>
          <Col sm={{ span: 12 }} lg={{ span: 14 }} />

          <Col span={24}>
            <Row gutter={32} align="bottom">
              <Col sm={{ span: 12 }} lg={{ span: 10 }}>
                <FormField
                  label="Ограничения для ТС по высоте"
                  htmlFor={`routePoints.${index}.roadTransportParameters.vehicleHeightMaxM`}
                  validateMessage={errors.routePoints?.[index]?.roadTransportParameters?.vehicleHeightMaxM?.message}
                >
                  <Controller
                    name={`routePoints.${index}.roadTransportParameters.vehicleHeightMaxM`}
                    control={control}
                    render={({ field }) => (
                      <InputNumber
                        {...field}
                        {...inputSharedSettings}
                        addonAfter="м"
                        style={{ width: '100%', maxWidth: '342px' }}
                        id={`routePoints.${index}.roadTransportParameters.vehicleHeightMaxM`}
                        placeholder="Введите значение"
                        status={errors.routePoints?.[index]?.roadTransportParameters?.vehicleHeightMaxM && 'error'}
                        step={1}
                      />
                    )}
                  />
                </FormField>
              </Col>
              <Col sm={{ span: 12 }} lg={{ span: 14 }}></Col>

              <Col span={8}>
                {/*<FormField*/}
                {/*  label="Ограничения для ТС по высоте клиренса"*/}
                {/*  htmlFor={`routePoints.${index}.roadTransportParameters.vehicleClearanceMinCm`}*/}
                {/*  validateMessage={errors.routePoints?.[index]?.roadTransportParameters?.vehicleClearanceMinCm?.message}*/}
                {/*>*/}
                {/*  <Controller*/}
                {/*    name={`routePoints.${index}.roadTransportParameters.vehicleClearanceMinCm`}*/}
                {/*    control={control}*/}
                {/*    render={({ field }) => (*/}
                {/*      <InputNumber*/}
                {/*        {...field}*/}
                {/*        {...inputSharedSettings}*/}
                {/*        addonAfter="см"*/}
                {/*        style={{ width: '100%', maxWidth: '342px' }}*/}
                {/*        id={`routePoints.${index}.roadTransportParameters.vehicleClearanceMinCm`}*/}
                {/*        placeholder="Введите значение"*/}
                {/*        status={errors.routePoints?.[index]?.roadTransportParameters?.vehicleClearanceMinCm && 'error'}*/}
                {/*        step={1}*/}
                {/*      />*/}
                {/*    )}*/}
                {/*  />*/}
                {/*</FormField>*/}
              </Col>
            </Row>
          </Col>

          <Col span={12}>
            <FormField
              label="Наличие весов"
              htmlFor={`routePoints.${index}.roadTransportParameters.hasScales`}
              validateMessage={errors.routePoints?.[index]?.roadTransportParameters?.hasScales?.message}
            >
              <Controller
                name={`routePoints.${index}.roadTransportParameters.hasScales`}
                control={control}
                render={({ field }) => <YesOrNotControl {...field} />}
              />
            </FormField>
          </Col>
          <Col span={12} />

          {hasScales && (
            <>
              <Col sm={{ span: 12 }} lg={{ span: 10 }}>
                <FormField
                  label="Длина весов"
                  htmlFor={`routePoints.${index}.roadTransportParameters.vehicleLengthMaxM`}
                  validateMessage={errors.routePoints?.[index]?.roadTransportParameters?.vehicleLengthMaxM?.message}
                >
                  <Controller
                    name={`routePoints.${index}.roadTransportParameters.vehicleLengthMaxM`}
                    control={control}
                    render={({ field }) => (
                      <InputNumber
                        {...field}
                        {...inputSharedSettings}
                        addonAfter="м"
                        style={{ width: '100%', maxWidth: '342px' }}
                        id={`routePoints.${index}.roadTransportParameters.vehicleLengthMaxM`}
                        placeholder="Введите значение"
                        status={errors.routePoints?.[index]?.roadTransportParameters?.vehicleLengthMaxM && 'error'}
                        step={1}
                      />
                    )}
                  />
                </FormField>
              </Col>

              <Col sm={{ span: 12 }} lg={{ span: 10 }}>
                <FormField
                  htmlFor={`routePoints.${index}.roadTransportParameters.scalesWeightLimitT`}
                  label="Тоннаж весов"
                  validateMessage={errors.routePoints?.[index]?.roadTransportParameters?.scalesWeightLimitT?.message}
                >
                  <Controller
                    name={`routePoints.${index}.roadTransportParameters.scalesWeightLimitT`}
                    control={control}
                    render={({ field }) => (
                      <InputNumber
                        {...field}
                        {...inputSharedSettings}
                        addonAfter="т"
                        style={{ width: '100%', maxWidth: '342px' }}
                        id={`routePoints.${index}.roadTransportParameters.scalesWeightLimitT`}
                        placeholder="Введите значение"
                        status={errors.routePoints?.[index]?.roadTransportParameters?.scalesWeightLimitT && 'error'}
                        step={1}
                      />
                    )}
                  />
                </FormField>
              </Col>
            </>
          )}

          {/*<Col span={12}>*/}
          {/*  <FormField*/}
          {/*    label="Автоматизация взвешивания (ПО)"*/}
          {/*    htmlFor={`routePoints.${index}.roadTransportParameters.hasWeighingAutomation`}*/}
          {/*    validateMessage={errors.routePoints?.[index]?.roadTransportParameters?.hasWeighingAutomation?.message}*/}
          {/*  >*/}
          {/*    <Controller*/}
          {/*      name={`routePoints.${index}.roadTransportParameters.hasWeighingAutomation`}*/}
          {/*      control={control}*/}
          {/*      render={({field}) => <YesOrNotControl {...field} />}*/}
          {/*    />*/}
          {/*  </FormField>*/}
          {/*</Col>*/}
          {/*<Col span={12}/>*/}

          <Col span={24}>
            <Text variant="h3" style={{ paddingBottom: '24px' }}>
              Дорога к подъезду и складу
            </Text>
          </Col>

          <Col span={12}>
            <FormField
              label="Площадка накопитель для Т/С"
              htmlFor={`routePoints.${index}.roadTransportParameters.hasParking`}
              validateMessage={errors.routePoints?.[index]?.roadTransportParameters?.hasParking?.message}
            >
              <Controller
                name={`routePoints.${index}.roadTransportParameters.hasParking`}
                control={control}
                render={({ field }) => <YesOrNotControl {...field} />}
              />
            </FormField>
          </Col>
          <Col span={12} />

          {type === RoutePointType.Start && (
            <>
              <Col sm={{ span: 12 }} lg={{ span: 10 }}>
                <FormField
                  htmlFor={`routePoints.${index}.roadTransportParameters.distanceToScalesM`}
                  label="Расстояние от точки погрузки до весов"
                  validateMessage={errors.routePoints?.[index]?.roadTransportParameters?.distanceToScalesM?.message}
                >
                  <Controller
                    name={`routePoints.${index}.roadTransportParameters.distanceToScalesM`}
                    control={control}
                    render={({ field }) => (
                      <InputNumber
                        {...field}
                        {...inputSharedSettings}
                        addonAfter="м"
                        style={{ width: '100%', maxWidth: '342px' }}
                        id={`routePoints.${index}.roadTransportParameters.distanceToScalesM`}
                        placeholder="Введите значение"
                        status={errors.routePoints?.[index]?.roadTransportParameters?.distanceToScalesM && 'error'}
                        step={1}
                      />
                    )}
                  />
                </FormField>
              </Col>
              <Col sm={{ span: 12 }} lg={{ span: 14 }} />
            </>
          )}

          <Col span={12}>
            <FormField
              label="Тип покрытия"
              htmlFor={`routePoints.${index}.roadTransportParameters.roadSurfaceType`}
              validateMessage={errors.routePoints?.[index]?.roadTransportParameters?.roadSurfaceType?.message}
            >
              <Controller
                name={`routePoints.${index}.roadTransportParameters.roadSurfaceType`}
                control={control}
                render={({ field }) => (
                  <Radio.Group {...field} optionType="button" buttonStyle="solid" size="middle">
                    <Radio.Button value={RoadSurfaceType.Paved}>
                      {getRoadSurfaceTypeName(RoadSurfaceType.Paved)}
                    </Radio.Button>
                    <Radio.Button value={RoadSurfaceType.Unpaved}>
                      {getRoadSurfaceTypeName(RoadSurfaceType.Unpaved)}
                    </Radio.Button>
                  </Radio.Group>
                )}
              />
            </FormField>
          </Col>
          <Col span={12} />

          {/*<Col span={12}>*/}
          {/*  <FormField*/}
          {/*    label="Качество покрытия"*/}
          {/*    htmlFor={`routePoints.${index}.roadTransportParameters.roadSurfaceQuality`}*/}
          {/*    validateMessage={errors.routePoints?.[index]?.roadTransportParameters?.roadSurfaceQuality?.message}*/}
          {/*  >*/}
          {/*    <Controller*/}
          {/*      name={`routePoints.${index}.roadTransportParameters.roadSurfaceQuality`}*/}
          {/*      control={control}*/}
          {/*      render={({field}) => (*/}
          {/*        <Radio.Group {...field} optionType="button" buttonStyle="solid" size="middle">*/}
          {/*          <Radio.Button value={RoadSurfaceQuality.AllSeasonal}>*/}
          {/*            {getRoadSurfaceQualityName(RoadSurfaceQuality.AllSeasonal)}*/}
          {/*          </Radio.Button>*/}
          {/*          <Radio.Button value={RoadSurfaceQuality.Seasonal}>*/}
          {/*            {getRoadSurfaceQualityName(RoadSurfaceQuality.Seasonal)}*/}
          {/*          </Radio.Button>*/}
          {/*        </Radio.Group>*/}
          {/*      )}*/}
          {/*    />*/}
          {/*  </FormField>*/}
          {/*</Col>*/}
          {/*<Col span={12}/>*/}

          <Col span={24}>
            <FormField
              label="Дополнительные сведения"
              htmlFor={`routePoints.${index}.roadTransportParameters.additionalDetails`}
              validateMessage={errors.routePoints?.[index]?.roadTransportParameters?.additionalDetails?.message}
            >
              <Controller
                name={`routePoints.${index}.roadTransportParameters.additionalDetails`}
                control={control}
                render={({ field }) => (
                  <Input.TextArea
                    {...field}
                    id={`routePoints.${index}.roadTransportParameters.additionalDetails`}
                    placeholder="Введите"
                    status={errors.routePoints?.[index]?.roadTransportParameters?.additionalDetails && 'error'}
                    autoSize={{ minRows: 3, maxRows: 6 }}
                  />
                )}
              />
            </FormField>
          </Col>
        </>
      )}
    </>
  )
}
