import React from 'react'

import { RouteObject } from 'react-router-dom'

import { NotificationsPage } from '~/pages'
import { appRoutes } from '~/router'

import { RequireAuth } from '../../providers/auth'

export const notificationsRouter: RouteObject = {
  path: appRoutes.notifications.url,
  element: (
    <RequireAuth>
      <NotificationsPage />
    </RequireAuth>
  ),
}
