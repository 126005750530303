import styled from 'styled-components'

import { Text } from '~/ui-components'

export const LIstWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`

export const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 8px;
  align-items: center;
  margin-bottom: 16px;
`
export const TitleWrapperInner = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`

export const CountWrapper = styled.div`
  background: #e3e3e3;
  border-radius: 8px;
  padding: 2px 6px;
`

export const CardFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
`
export const CardHeaderTop = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
`
export const Volume = styled(Text).attrs({ variant: 'h4' })`
  color: var(--primary-main-color);
`
export const PublishedAt = styled(Text).attrs({ variant: 'description' })`
  color: var(--gray-4-color);
  display: inline;
`
