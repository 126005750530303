import { graphql } from '~api/gql-generated'

export const companySendOnModerationMutation = graphql(/* GraphQL */ `
  mutation CompanySendOnModeration($companyId: Int!) {
    company(companyId: $companyId) {
      sendOnModeration
    }
  }
`)

export const createCompanyMutation = graphql(/* GraphQL */ `
  mutation CompanyCreate($input: CompanyCreateMutationsInput!) {
    companyCreate(input: $input) {
      inn
      type
    }
  }
`)

export const deleteCompanyMutation = graphql(/* GraphQL */ `
  mutation DeleteCompany($companyId: Int!) {
    company(companyId: $companyId) {
      delete
    }
  }
`)

export const updateCompanyGeneralInfoMutation = graphql(/* GraphQL */ `
  mutation UpdateCompanyGeneralInfo($input: CompanyUpdateGeneralInfoInput!, $companyId: Int!) {
    company(companyId: $companyId) {
      updateGeneralInfo(input: $input) {
        website
        additionalInfo
        phoneNumber
        logoUrl
      }
    }
  }
`)
export const updateCompanyLogoMutation = graphql(/* GraphQL */ `
  mutation UpdateLogo($companyId: Int!, $input: CompanyUpdateLogoInput!) {
    company(companyId: $companyId) {
      updateLogo(input: $input) {
        logoUrl
      }
    }
  }
`)

export const updateCompanyLegalInfoMutation = graphql(/* GraphQL */ `
  mutation UpdateLegalInfo($companyId: Int!, $input: CompanyUpdateLegalInfoInput!) {
    company(companyId: $companyId) {
      updateLegalInfo(input: $input) {
        useNds
        kpp
        inn
        directorName
      }
    }
  }
`)

export const companyDeleteUserMutation = graphql(/* GraphQL */ `
  mutation DeleteUser($userId: Int!, $companyId: Int!) {
    company(companyId: $companyId) {
      deleteUser(userId: $userId) {
        id
      }
    }
  }
`)
