import { useQuery, UseQueryResult } from '@tanstack/react-query'

import {
  AdminGetElevatorAdvertOfferResponse,
  ElevatorAdvertOfferApiAdminElevatorAdvertOfferControllerGetAdvertOfferRequest,
} from '~api/generated'
import { useApi } from '~providers/api'

export const useGetElevatorAdvertOfferByAdmin = (
  request: ElevatorAdvertOfferApiAdminElevatorAdvertOfferControllerGetAdvertOfferRequest
): UseQueryResult<AdminGetElevatorAdvertOfferResponse> => {
  const api = useApi()

  const fetchFn = async (): Promise<AdminGetElevatorAdvertOfferResponse> => {
    const res = await api.elevatorAdvertOffer.adminElevatorAdvertOfferControllerGetAdvertOffer(request)

    return res.data
  }

  return useQuery(['elevatorAdvertOfferAdmin', request.id], fetchFn, { enabled: !!request.id })
}
