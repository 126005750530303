import { isEqual } from 'lodash'
import { makeAutoObservable } from 'mobx'

import { IResponsesFilter } from '~pages/LogisticsPage/modules/LogisticResponses/components/ResponsesFilter/ResponsesFilter.types'
import { FreightOrderResponseFilterInput } from '~api/gql-generated/graphql'

// Filter panel shows this values after first open
const defaultFiltersConfig: IResponsesFilter = {
  freightOrderIds: undefined,
  regionIds: undefined,
  productTypeIds: undefined,
  statuses: undefined,
  search: undefined,
}

class LogisticResponsesFilterStore {
  private filters: IResponsesFilter

  constructor() {
    this.filters = defaultFiltersConfig
    makeAutoObservable(this)
  }

  setFilters(newFilters: IResponsesFilter): void {
    this.filters = newFilters
  }

  get responsesFilters(): IResponsesFilter {
    return this.filters
  }

  get defaultFiltersConfig(): IResponsesFilter {
    return defaultFiltersConfig
  }

  get isCurrentFilterDefault(): boolean {
    return isEqual(this.filters, defaultFiltersConfig)
  }

  get mappedCurrentFiltersToResponse(): Partial<FreightOrderResponseFilterInput> {
    return this.isCurrentFilterDefault
      ? {}
      : {
          freightOrderIds: this.filters.freightOrderIds?.length ? this.filters.freightOrderIds : undefined,
          regionIds: this.filters.regionIds?.length ? this.filters.regionIds : undefined,
          productTypeIds: this.filters.productTypeIds?.length ? this.filters.productTypeIds : undefined,
          statuses: this.filters.statuses?.length ? this.filters.statuses : undefined,
          search: this.filters.search?.length ? this.filters.search : undefined,
        }
  }
}

export const logisticResponsesFilterStore = new LogisticResponsesFilterStore()
