import { CompanyAuctionOfferDataStatusEnum } from '~api/generated'

import { BadgeVariant } from '~ui-components/components/Badge/Badge.types'

export function getMyResponsesOfferStatus(status: string): {
  name: string
  color: BadgeVariant
} {
  switch (status) {
    case CompanyAuctionOfferDataStatusEnum.OnModeration:
      return {
        name: 'Рассмотрена',
        color: 'gray',
      }
    case CompanyAuctionOfferDataStatusEnum.Published:
      return {
        name: 'На рассмотрении',
        color: 'yellow',
      }
    case CompanyAuctionOfferDataStatusEnum.Realised:
      return {
        name: 'Принято',
        color: 'base',
      }
    case CompanyAuctionOfferDataStatusEnum.InDeal:
      return {
        name: 'Принято',
        color: 'base',
      }
    case CompanyAuctionOfferDataStatusEnum.Rejected:
      return {
        name: 'Отклонено',
        color: 'red',
      }
    default:
      return {
        name: 'Ошибка',
        color: 'red',
      }
  }
}
