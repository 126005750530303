import React from 'react'

import { Button, Flex, Modal, Select } from 'antd'
import { Controller, useForm } from 'react-hook-form'
import * as yup from 'yup'

import { useMutation, useQuery } from '@apollo/client'
import { ApolloError } from '@apollo/client/errors'
import { yupResolver } from '@hookform/resolvers/yup'

import { FormField } from '~/ui-components'
import { appToast, modalSharedSettings } from '~/utils'
import { graphqlErrorHandler } from '~/utils/graphqlErrorHandler'
import { driverSetTransportMutation } from '~api/gql-mutations/drivers.mutation.graphql'
import {
  getTransportDocumentsQuery,
  getTransportQuery,
  getTransportsQuery,
} from '~api/gql-query/car-park.query.graphql'
import { getDriversListQuery } from '~api/gql-query/drivers.query.graphql'
import { useAuth } from '~providers/auth'
import { TransportStationSelectControl } from '~shared/controls/TransportStationSelectControl'
import { VALIDATORS_MSG } from '~shared/validations'

import * as S from './AssignTransportToDriver.styled'

const assignTransportToDriverFormSchema = yup
  .object({
    transportStationId: yup.number().required(VALIDATORS_MSG.required),
    transport: yup.number().required(VALIDATORS_MSG.required),
  })
  .required()

export type AssignTransportToDriverFormValues = yup.InferType<typeof assignTransportToDriverFormSchema>

interface AssignTransportToDriver {
  driverId: number
  open: boolean
  onClose: () => void
}

export function AssignTransportToDriver(props: AssignTransportToDriver) {
  const { companyId } = useAuth()

  const {
    control,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm<AssignTransportToDriverFormValues>({
    defaultValues: {
      transportStationId: undefined,
    },
    resolver: yupResolver(assignTransportToDriverFormSchema),
  })

  const transportStationIdWatch = watch('transportStationId')

  const [setTransport, setTransportState] = useMutation(driverSetTransportMutation)

  const getTransports = useQuery(getTransportsQuery, {
    variables: {
      filter: {
        companyIds: [companyId],
        transportStationIds: transportStationIdWatch ? [transportStationIdWatch] : undefined,
      },
      pagination: { currentPage: 1, pageSize: 100 },
    },
    skip: transportStationIdWatch == null,
    onError: graphqlErrorHandler,
  })

  const getAssignedTransports = useQuery(getTransportsQuery, {
    variables: {
      filter: {
        driverIds: [props.driverId],
        companyIds: [companyId],
      },
      pagination: { currentPage: 1, pageSize: 100 },
    },
    onError: graphqlErrorHandler,
  })

  const assignedTransportIds = getAssignedTransports.data?.transports.transports.map((x) => x.id) ?? []

  const onCloseHandler = () => {
    reset()
    props.onClose()
  }

  const onSubmitHandler = async (data: AssignTransportToDriverFormValues) => {
    try {
      await setTransport({
        variables: {
          driverId: props.driverId,
          transportId: data.transport,
        },
        refetchQueries: [getTransportQuery, getTransportDocumentsQuery, getTransportsQuery, getDriversListQuery],
      })
      appToast.success({ message: 'Транспорт назначен' })
      onCloseHandler()
    } catch (e) {
      e instanceof ApolloError && graphqlErrorHandler(e, 'Произошла ошибка при назначении ТС')
    }
  }

  return (
    <Modal {...modalSharedSettings} width={350} open={props.open} onCancel={onCloseHandler} title="Назначить ТС">
      <S.FormWrapper onSubmit={handleSubmit(onSubmitHandler)}>
        <FormField label="Автопарк" htmlFor="transportStationId" validateMessage={errors.transportStationId?.message}>
          <Controller
            name="transportStationId"
            control={control}
            render={({ field }) => <TransportStationSelectControl {...field} variant="list" />}
          />
        </FormField>

        <FormField label="TC" htmlFor="transport" validateMessage={errors.transport?.message}>
          <Controller
            name="transport"
            control={control}
            render={({ field }) => (
              <Select
                disabled={transportStationIdWatch == null}
                style={{ width: '100%' }}
                popupMatchSelectWidth={false}
                placeholder="Выберите"
                defaultValue={field.value}
                onChange={field.onChange}
                status={errors.transport?.message ? 'error' : undefined}
                loading={getTransports.loading}
              >
                {getTransports.data?.transports?.transports.map((transport) => (
                  <Select.Option
                    key={transport.id}
                    value={transport.id}
                    disabled={assignedTransportIds.includes(transport.id)}
                  >
                    {transport.brand} - {transport.licensePlate}
                  </Select.Option>
                ))}
              </Select>
            )}
          />
        </FormField>

        <Flex gap={8} justify="flex-end">
          <Button type="default" onClick={onCloseHandler}>
            Отмена
          </Button>
          <Button type="primary" htmlType="submit" loading={setTransportState.loading}>
            Готово
          </Button>
        </Flex>
      </S.FormWrapper>
    </Modal>
  )
}
