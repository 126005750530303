import React from 'react'

import type { RadioChangeEvent } from 'antd'
import { Flex, Radio, Space } from 'antd'

import { getTransportTypeName } from '~/utils/car-park/get-transport-type'
import { getTransportTypeIcon } from '~/utils/car-park/get-transport-type-icon'
import { TransportType } from '~api/gql-generated/graphql'
import { CustomControlInterface } from '~shared/controls/CustomControl.interface'

import * as S from './TransportTypeRadioControl.styled'

interface ITransportTypeRadioControl extends CustomControlInterface {
  onChange: (value: TransportType) => void
  value: TransportType | undefined
}

const options = [TransportType.Truck, TransportType.Tractor, TransportType.SemiTrailer, TransportType.Trailer].map(
  (transportType) => ({
    key: transportType,
    value: transportType,
    label: getTransportTypeName(transportType),
    icon: getTransportTypeIcon(transportType),
  })
)

export const TransportTypeRadioControl: React.FC<ITransportTypeRadioControl> = ({ onChange, value }) => {
  const onChangeHandler = (e: RadioChangeEvent) => {
    onChange(e.target.value)
  }

  return (
    <Radio.Group onChange={onChangeHandler} value={value}>
      <Space size={12}>
        {options.map(({ key, value, label, icon }) => (
          <S.RadioItem key={key} value={value}>
            <Flex vertical align="center">
              {icon}
              {label}
            </Flex>
          </S.RadioItem>
        ))}
      </Space>
    </Radio.Group>
  )
}

TransportTypeRadioControl.displayName = 'TransportTypeRadioControl'
