import React, { useState } from 'react'

import type { RadioChangeEvent } from 'antd'
import { Modal, Popover } from 'antd'
import { BsFillQuestionSquareFill } from 'react-icons/bs'

import { modalSharedSettings } from '~/utils'
import { getUserRole } from '~/utils/user/getUserRoleOld'
import { UserRole } from '~api/gql-generated/graphql'
import { useGetDevice } from '~hooks/common'
import { CustomControlInterface } from '~shared/controls/CustomControl.interface'
import { UserRoleDescription } from '~shared/controls/UserRoleControl/UserRoleDescription'

import * as S from './UserRoleControl.styled'

interface ICompanyRoleControl extends CustomControlInterface {
  onChange: (value: UserRole) => void
  value: UserRole | undefined
}

export const UserRoleControl: React.FC<ICompanyRoleControl> = ({ onChange, value }) => {
  const device = useGetDevice()
  const isMobile = device === 'mobile'

  const [openPopover, setOpenPopover] = useState<UserRole | null>(null)

  const onChangeHandler = (e: RadioChangeEvent) => {
    onChange(e.target.value)
  }

  const renderPopover = (role: UserRole) =>
    isMobile ? (
      <>
        <BsFillQuestionSquareFill size={20} color="#CECECE" onClick={() => setOpenPopover(role)} />
        <Modal title="Описание должности" closable={false} open={!!openPopover} footer={null}>
          <UserRoleDescription role={role} onClose={() => setOpenPopover(null)} />
        </Modal>
      </>
    ) : (
      <Popover
        content={<UserRoleDescription role={role} onClose={() => setOpenPopover(null)} />}
        zIndex={1040}
        placement="rightTop"
        trigger={['click', 'hover']}
        arrow={false}
        open={role === openPopover}
        onOpenChange={(newOpen: boolean) => setOpenPopover(newOpen ? role : null)}
      >
        <BsFillQuestionSquareFill size={20} color="#CECECE" />
      </Popover>
    )

  return (
    <S.StyledRadio.Group style={{ width: '100%' }} onChange={onChangeHandler} value={value}>
      <S.OptionsWrapper>
        <S.StyledRadio value={UserRole.Director} className="kek">
          <S.Row>
            <S.OptionText>{getUserRole(UserRole.Director)}</S.OptionText>
            {renderPopover(UserRole.Director)}
          </S.Row>
        </S.StyledRadio>
        <S.StyledRadio value={UserRole.Manager}>
          <S.Row>
            <S.OptionText>{getUserRole(UserRole.Manager)}</S.OptionText>
            {renderPopover(UserRole.Manager)}
          </S.Row>
        </S.StyledRadio>
        <S.StyledRadio value={UserRole.Accountant}>
          <S.Row>
            <S.OptionText>{getUserRole(UserRole.Accountant)}</S.OptionText>
            {renderPopover(UserRole.Accountant)}
          </S.Row>
        </S.StyledRadio>
      </S.OptionsWrapper>
    </S.StyledRadio.Group>
  )
}
