import React, { FC } from 'react'

import { Radio } from 'antd'
import { FiAlignJustify, FiGrid } from 'react-icons/fi'

import { View, ViewToggleProps } from './ViewToggle.types'

export const ViewToggle: FC<ViewToggleProps> = ({ value, onChange }) => {
  return (
    <Radio.Group value={value} buttonStyle="solid" onChange={(evt) => onChange(evt.target.value)}>
      <Radio.Button value={View.CARDS}>
        <FiGrid />
      </Radio.Button>
      <Radio.Button value={View.TABLE}>
        <FiAlignJustify />
      </Radio.Button>
    </Radio.Group>
  )
}
