import React, { useState } from 'react'

import { Avatar, Button, Modal, Upload } from 'antd'
import type { RcFile, UploadFile } from 'antd/es/upload/interface'
import ImgCrop from 'antd-img-crop'
import { LuMountainSnow } from 'react-icons/lu'

import { Text } from '~/ui-components'

import * as S from './AvatarUploader.styled'

interface IAvatarUploaderProps {
  avatarUrl: string | undefined
  uploadText?: string
  onUpload: (avatar: File) => void
  onDeleteAvatar: () => void
}

export const AvatarUploader: React.FC<IAvatarUploaderProps> = ({
  avatarUrl,
  onUpload,
  onDeleteAvatar,
  uploadText = 'Загрузить',
}) => {
  const [avatarUrlState, setAvatarUrl] = useState<string | undefined>(avatarUrl)

  const getBase64 = (img: RcFile, callback: (url: string) => void) => {
    const reader = new FileReader()
    reader.addEventListener('load', () => callback(reader.result as string))
    reader.readAsDataURL(img)
  }

  const onPreview = async (file: UploadFile) => {
    let src = file.url as string
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader()
        reader.readAsDataURL(file.originFileObj as RcFile)
        reader.onload = () => resolve(reader.result as string)
      })
    }
    const image = new Image()
    image.src = src
    const imgWindow = window.open(src)
    imgWindow?.document.write(image.outerHTML)
  }

  const handleDeleteAvatarConfirm = () => {
    Modal.confirm({
      title: 'Вы действительно хотите удалить изображение?',
      okText: 'Удалить',
      cancelText: 'Отмена',
      onOk() {
        setAvatarUrl(undefined)
        onDeleteAvatar()
      },
    })
  }

  return (
    <S.ContentWrapper>
      <S.Avatar>
        <Avatar size="large" src={avatarUrlState} icon={<LuMountainSnow />} />
      </S.Avatar>

      <div>
        <S.ActionsWrapper>
          <ImgCrop
            rotationSlider
            zoomSlider
            cropShape="round"
            modalTitle="Редактирование"
            modalCancel="Отмена"
            modalOk="Сохранить"
          >
            <Upload
              showUploadList={false}
              customRequest={(r) => {
                onUpload(r.file as File)
                getBase64(r.file as RcFile, (url) => {
                  setAvatarUrl(url)
                })
              }}
              onPreview={onPreview}
            >
              <Button type="link">{uploadText}</Button>
            </Upload>
          </ImgCrop>
          {avatarUrlState && (
            <Button type="link" onClick={handleDeleteAvatarConfirm}>
              Удалить
            </Button>
          )}
        </S.ActionsWrapper>
        <S.Description>(PNG или JPG не больше 1000px в ширину и высоту)</S.Description>
      </div>
    </S.ContentWrapper>
  )
}
