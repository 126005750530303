import { useMutation } from '@tanstack/react-query'

import { AddWarehousePositionActionTransferFromBody } from '~api/generated'
import { useApi } from '~providers/api'

export const useWarehouseCultureTransfer = (warehousePositionId: number | null) => {
  const api = useApi()

  return useMutation((addWarehousePositionActionTransferFromBody: AddWarehousePositionActionTransferFromBody) => {
    if (!warehousePositionId) {
      return Promise.reject('[useWarehouseCultureTransfer]: warehousePositionId was not passed')
    }

    return api.warehouses.warehousePositionControllerAddWarehousePositionActionTransferFrom({
      warehousePositionId,
      addWarehousePositionActionTransferFromBody,
    })
  })
}
