import React from 'react'

import { ColumnsType } from 'antd/lib/table'
import { ColumnType } from 'antd/lib/table/interface'

import { useGetProductTypeData } from '~/hooks'
import { offersFilterStore } from '~/store/filters'
import { Badge } from '~/ui-components'
import { getDateFormat } from '~/utils/getDateFormat'
import { getOfferType } from '~/utils/offers/get-offer-type'
import { CompanyData, OfferData, ProductData, ProductTypeData } from '~api/generated'
import { useGetCurrentRole } from '~hooks/auth'
import {
  actionArrowColumn,
  locationColumn,
  ndsColumn,
  priceColumn,
  productNameColumn,
  volumeColumn,
} from '~shared/columns/shared'

export const useGetColumnsOffersList = (
  products: ProductTypeData[],
  companiesMap: Map<CompanyData['id'], CompanyData>
) => {
  const filters = offersFilterStore.filters
  const {
    user: { isAdmin },
  } = useGetCurrentRole()
  const { getProductTypeParametersForUI_V2 } = useGetProductTypeData()

  const distanceColumn: ColumnType<OfferData> = filters.distanceFromWarehouse.warehouseId
    ? {
        title: 'Расстояние до склада, км',
        width: 130,
        dataIndex: 'distance',
        render: (distance) => {
          return distance
        },
      }
    : {}

  const columns: ColumnsType<OfferData> = [
    {
      title: '№',
      dataIndex: 'id',
      width: 20,
    },
    {
      title: 'Дата публикации',
      dataIndex: 'publishedAt',
      width: 150,
      render: (publishedAt) => publishedAt && getDateFormat(publishedAt),
    },
    {
      title: 'Предложение',
      dataIndex: 'type',
      width: 120,
      render: (value) => {
        const offerType = getOfferType(value)
        return (
          <Badge color={offerType.color} size="s">
            {offerType.name}
          </Badge>
        )
      },
    },
    productNameColumn(products),
    {
      title: 'Параметры',
      dataIndex: ['product'],
      width: 180,
      render: (product: ProductData) => {
        return getProductTypeParametersForUI_V2(product)
          .slice(0, 2)
          .map((param) => <div key={param}>{param}</div>)
      },
    },

    ...(isAdmin
      ? [
          {
            title: 'Компания',
            dataIndex: 'companyId',
            render: (value: number) => {
              return <div style={{ minWidth: 150 }}>{companiesMap.get(value)?.name}</div>
            },
          },
        ]
      : []),
    volumeColumn,
    priceColumn,
    ndsColumn,
    {
      ...locationColumn,
      render: (address) => {
        return address
      },
    },
    distanceColumn,
    actionArrowColumn,
  ]

  return columns
}
