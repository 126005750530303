import React from 'react'

import { BsCheckAll } from 'react-icons/bs'

import { Text } from '~/ui-components'
import { getCompanySignatoryLegalBasis } from '~/utils/company/get-company-signatory-legal-basis'
import { ReadValue, ResponsePageHeader } from '~shared/components'
import { RightPanelEditButton } from '~shared/components/ResponsePageHeader/right-elements'

import { CompanyAccountRequisitesProps } from './CompanyAccountRequisites.types'

import * as S from './CompanyAccountRequisites.styled'

export const CompanyAccountRequisites: React.FC<CompanyAccountRequisitesProps> = ({
  onEdit,
  canEdit,
  companyData,
  loading,
}) => {
  const isSameAddress =
    companyData?.legalAddress && companyData.legalAddress.trim() === companyData?.actualAddress?.trim()

  return (
    <S.Wrapper>
      <ResponsePageHeader
        variant="view"
        title="Реквизиты компании"
        subTitle="МОЯ КОМПАНИЯ"
        rightElement={<RightPanelEditButton onClick={onEdit} canEdit={canEdit} />}
      />

      <S.ContentWrapper>
        <ReadValue name="Название компании" value={companyData?.legalName} />
        <ReadValue name="ИНН" value={companyData?.inn} />
        <ReadValue name="КПП" value={companyData?.kpp} />
        <ReadValue name="Код ОКВЭД" value={companyData?.okved} />
        <ReadValue name="ОГРН" value={companyData?.ogrn} />
        <ReadValue name="Генеральный директор" value={companyData?.directorName} />
        <ReadValue name="Подписант" value={companyData?.signatory} />
        <ReadValue name="Должность подписанта" value={companyData?.signatoryPosition} />
        <ReadValue
          name="Основание"
          value={companyData?.signatoryLegalBasis ? getCompanySignatoryLegalBasis(companyData.signatoryLegalBasis) : ''}
        />
        <ReadValue
          name="Юридический адрес"
          value={
            companyData?.legalAddress ? (
              <>
                {companyData?.legalAddress}
                {isSameAddress && (
                  <S.SameAddressText>
                    <BsCheckAll size={16} />
                    <Text variant="normal">Совпадает с физическим</Text>
                  </S.SameAddressText>
                )}
              </>
            ) : null
          }
        />

        {!isSameAddress && <ReadValue name="Физический адрес" value={companyData?.actualAddress} />}

        <ReadValue
          name="Налогообложение"
          value={companyData?.useNds == null ? undefined : companyData.useNds ? 'с НДС' : 'без НДС'}
        />
        <ReadValue
          name="Участник хартии АПК"
          value={companyData?.aicCharterMember == null ? undefined : companyData.aicCharterMember ? 'да' : 'нет'}
        />
      </S.ContentWrapper>
    </S.Wrapper>
  )
}
