import { DealDocumentDataTypeEnum } from '~api/generated'

export function getDealDocumentName(status?: string): string {
  // TODO: Сделана мапа только для стадии Оплаты
  switch (status) {
    case DealDocumentDataTypeEnum.InvoiceForSupplyPayment:
      return 'Счёт на оплату'
    case DealDocumentDataTypeEnum.PaymentOrderUnderTheSupplyContract:
      return 'Платёжное поручение по счету на оплату'
    case DealDocumentDataTypeEnum.InvoiceForGoods:
      return 'Счёт-фактура на товар'
    case DealDocumentDataTypeEnum.ActOfCompletedWork:
      return 'Акт выполненных работ'
    case DealDocumentDataTypeEnum.ReconciliationActFromBuyer:
      return 'Акт сверки покупателя'
    case DealDocumentDataTypeEnum.ReconciliationActFromSeller:
      return 'Акт сверки продавца'
    case DealDocumentDataTypeEnum.DeclarationOfConformityWithCuRegulations:
      return 'Декларация о соответствии протокола испытаний'
    case DealDocumentDataTypeEnum.CertificateForUseOfPesticides:
      return 'Справка на применение пестицидов'
    case DealDocumentDataTypeEnum.CertificateOfOriginOfGoods:
      return 'Справка о происхождении товара'
    case DealDocumentDataTypeEnum.QuarantineCertificate:
      return 'Карантинный сертификат от ФГУ «Россельхознадзор»'
    case DealDocumentDataTypeEnum.VeterinaryCertificateForGoods:
      return 'Ветеринарное свидетельство на товар'
    case DealDocumentDataTypeEnum.LaboratoryAnalysis:
      return 'Лабораторный анализ'
    case DealDocumentDataTypeEnum.Specification:
      return 'Спецификация'
    case DealDocumentDataTypeEnum.SupplyContract:
      return 'Договор поставки'
    case DealDocumentDataTypeEnum.AgencyContract:
      return 'Агентский договор'
    case DealDocumentDataTypeEnum.AgencyOrder:
      return 'Агентское поручение'
    case DealDocumentDataTypeEnum.SignedSpecification:
      return 'Подписанная спецификация'
    case DealDocumentDataTypeEnum.SignedSupplyContract:
      return 'Подписанный договор поставки'
    case DealDocumentDataTypeEnum.SignedAgencyContract:
      return 'Подписанный агентский договор'
    case DealDocumentDataTypeEnum.SignedAgencyOrder:
      return 'Подписанное агентское поручение'
    case DealDocumentDataTypeEnum.ShippingDocument:
      return 'Товаросопроводительный документ о партии зерна'
    case DealDocumentDataTypeEnum.BillOfLading:
      return 'Товарно-транспортная накладная'
    case DealDocumentDataTypeEnum.ConsignmentNote:
      return 'Товарная накладная'
    case DealDocumentDataTypeEnum.ShippingInvoice:
      return 'Счёт-фактура к ТН'
    case DealDocumentDataTypeEnum.AgentReport:
      return 'Отчёт агента'
    case DealDocumentDataTypeEnum.InvoiceForAgencyPayment:
      return 'Счёт на агентское вознаграждение'
    case DealDocumentDataTypeEnum.ActOfCompletedAgencyWork:
      return 'Акт выполненных агентских работ'
    case DealDocumentDataTypeEnum.PaymentOrderUnderAgencyContract:
      return 'Платёжное поручение по агентскому договору'
    case DealDocumentDataTypeEnum.CertificateOfAbsenceOfGmo:
      return 'Справка об отсутствии ГМО'
    case DealDocumentDataTypeEnum.PhytosanitaryCertificate:
      return 'Фитосанитарный сертификат'

    default:
      return ''
  }
}

/**
 * 1) # спецификация [админ]
 * 2) # договор поставки [админ]
 * 3) * агентский договор [админ]
 * 4) * агентское поручение [админ]
 * 5) * подписанный агентский договор [покупатель]
 * 6) * подписанное агентское поручение [покупатель]
 * 7) подписанная спецификация [продавец и покупатель]
 * 8) подписанный договор поставки [продавец и покупатель]
 *
 * Стадия оплаты:
 *
 * 9) счёт на оплату [продавец]
 * 10) платёжное поручение по договору поставки [покупатель]
 * 11) счёт-фактура на товар [продавец]
 * 12) акт выполненных работ [продавец]
 * 13) акт сверки [продавец и покупатель]
 *
 * 14) декларация о соответствии регламенту ТС с протоколом испытаний [продавец]
 * 15) справка на применение пестицидов [продавец]
 * 16) справка о происхождении товара [продавец]
 * 17) карантинный сертификат от ФГУ «Россельхознадзор» [продавец]
 * 18) ветеринарное свидетельство на товар [продавец]
 *
 * Стадия логистики:
 *
 * 19) товаросопроводительный документ о партии зерна [продавец]
 * 20) товарно-транспортная накладная [продавец]
 * 21) товарная накладная [продавец]
 * 22) счёт-фактура на перевозку [продавец]
 *
 * Стадия оплаты агентского вознаграждения:
 *
 * 23) * отчёт агента [админ]
 * 24) * счёт на агентское вознаграждение [админ]
 * 25) * акт выполненных агентских работ [админ]
 * 26) * платёжное поручение по агентскому договору [покупатель]
 * [10:46]Иван Клименко: Design stage:
 *
 * 1) specification [admin]
 * 2) supply contract [admin]
 * 3) * agency contract [admin]
 * 4) * agency order [admin]
 * 5) * signed agency contract [buyer]
 * 6) * signed agency order [buyer]
 * 7) signed specification [real seller and real buyer]
 * 8) signed supply contract [real seller and real buyer]
 *
 * Payment stage:
 *
 * 9) invoice for supply payment [seller]
 * 10) payment order under the supply contract [buyer]
 * 11) invoice for goods [seller]
 * 12) act of completed work [seller]
 * 13) reconciliation act [seller and buyer]
 *
 * 14) declaration of conformity with CU regulations [seller]
 * 15) certificate for use of pesticides [seller]
 * 16) certificate of origin of goods [seller]
 * 17) quarantine certificate [seller]
 * 18) veterinary certificate for goods [seller]
 *
 * Logistics stage:
 *
 * 19) shipping document [seller]
 * 20) bill of lading [seller]
 * 21) consignment note [seller]
 * 22) shipping invoice [seller]
 *
 * Agency fee payment stage:
 *
 * 23) * agent report [admin]
 * 24) * invoice for agency payment [admin]
 * 25) * act of completed agency work [admin]
 * 26) * payment order under agency contract [real buyer]
 */
