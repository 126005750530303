import { useQuery, UseQueryResult } from '@tanstack/react-query'

import { GetOffersResponse, OfferApiGetOffersRequest } from '~/api/generated'
import { useApi } from '~/providers/api'
import { isApiResponseEmpty } from '~/utils/isApiResponseEmpty'

export const useGetOffers = (request: OfferApiGetOffersRequest): UseQueryResult<GetOffersResponse> => {
  const api = useApi()

  const fetchFn = async (): Promise<GetOffersResponse | null> => {
    try {
      const response = await api.offers.getOffers(request)

      return response.data
    } catch (error) {
      if (isApiResponseEmpty(error)) {
        return null
      }

      return Promise.reject(error)
    }
  }

  return useQuery(['offers', request], fetchFn, { keepPreviousData: true })
}
