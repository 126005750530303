import { useQuery } from '@tanstack/react-query'

import {
  DealDocumentApiDealDocumentControllerGetDocumentTemplatesRequest,
  DealDocumentTemplateData,
} from '~/api/generated'
import { useApi } from '~providers/api'

export const useGetDealTemplateDocuments = (
  request: DealDocumentApiDealDocumentControllerGetDocumentTemplatesRequest
) => {
  const api = useApi()

  const fetchFn = async (): Promise<DealDocumentTemplateData[]> => {
    try {
      const response = await api.dealDocument.dealDocumentControllerGetDocumentTemplates(request)
      return response.data.documentTemplates
    } catch (error) {
      return Promise.reject(error)
    }
  }

  return useQuery(['dealDocuments', request], fetchFn)
}
