import React, { useCallback, useEffect } from 'react'

import { debounce, isEqual } from 'lodash'
import { observer } from 'mobx-react-lite'
import { Controller, useForm } from 'react-hook-form'

import { AppConfig } from '~/appConfig'
import { useGetCurrentRole } from '~hooks/auth'
import {
  CultureSelectControl,
  ElevatorAdvertStatusSelectControl,
  ElevatorAdvertTypeSelectControl,
  ElevatorSelectControl,
} from '~shared/controls'

import { ActiveFiltersInlineProps, IBasisListFiltersInlineForm } from './MyBasisAdvertsListFiltersInline.types'

import * as S from './MyBasisAdvertsListFiltersInline.styled'

export const MyBasisAdvertsListFiltersInline: React.FC<ActiveFiltersInlineProps> = observer((props) => {
  const {
    company: { isTrader },
  } = useGetCurrentRole()
  const store = props.store

  const currentValues = store.basisFilters

  const { watch, control, getValues, setValue } = useForm<IBasisListFiltersInlineForm>({
    defaultValues: {
      elevatorId: currentValues.elevatorId,
      productTypes: currentValues.productTypes,
      advertStatuses: currentValues.advertStatuses,
      advertType: currentValues.advertType,
    },
  })
  const watchedFields = watch()

  useEffect(() => {
    const formValues = getValues()
    if (!isEqual(currentValues.productTypes, formValues.productTypes)) {
      setValue('productTypes', currentValues.productTypes)
    }
    if (!isEqual(currentValues.elevatorId, formValues.elevatorId)) {
      setValue('elevatorId', currentValues.elevatorId)
    }
    if (!isEqual(currentValues.advertStatuses, formValues.advertStatuses)) {
      setValue('advertStatuses', currentValues.advertStatuses)
    }
    if (!isEqual(currentValues.advertType, formValues.advertType)) {
      setValue('advertType', currentValues.advertType)
    }
  }, [currentValues.productTypes, currentValues.elevatorId, currentValues.advertStatuses, currentValues.advertType])

  const onFiltersChange = (formValues: IBasisListFiltersInlineForm) => {
    store.setFilters({
      ...store.basisFilters,
      productTypes: formValues.productTypes,
      elevatorId: formValues.elevatorId,
      advertStatuses: formValues.advertStatuses,
      advertType: formValues.advertType,
    })
  }

  const debouncedChangeValues = useCallback(
    debounce(() => onFiltersChange(getValues()), AppConfig.DEFAULT_DEBOUNCE_TIME),
    []
  )

  useEffect(() => {
    debouncedChangeValues()
  }, [watchedFields.productTypes, watchedFields.elevatorId, watchedFields.advertStatuses, watchedFields.advertType])

  return (
    <S.StyledForm>
      {isTrader && (
        <S.ItemSelect>
          <Controller
            name="advertType"
            control={control}
            render={({ field }) => (
              <ElevatorAdvertTypeSelectControl id="advertType" value={field.value} onChange={field.onChange} />
            )}
          />
        </S.ItemSelect>
      )}

      <S.ItemSelect>
        <Controller
          name="elevatorId"
          control={control}
          render={({ field }) => <ElevatorSelectControl id="elevatorId" {...field} />}
        />
      </S.ItemSelect>

      <S.ItemSelect>
        <Controller name="productTypes" control={control} render={({ field }) => <CultureSelectControl {...field} />} />
      </S.ItemSelect>

      <S.ItemSelect>
        <Controller
          name="advertStatuses"
          control={control}
          render={({ field }) => <ElevatorAdvertStatusSelectControl id="advertStatus" {...field} />}
        />
      </S.ItemSelect>
    </S.StyledForm>
  )
})
