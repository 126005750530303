import React from 'react'

import { InputNumber } from 'antd'

import { CustomControlInterface } from '~shared/controls/CustomControl.interface'

type Value = number | undefined | null

interface IVolumeControlProps extends CustomControlInterface {
  onChange: (value: Value) => void
  value: Value
  hasError?: boolean
  id?: string
}

export const VolumeControl: React.FC<IVolumeControlProps> = React.forwardRef<HTMLInputElement, IVolumeControlProps>(
  ({ id, value, onChange, hasError }, ref) => {
    const onChangeHandler = (value: Value) => {
      onChange(value)
    }

    return (
      <InputNumber
        ref={ref}
        id={id}
        style={{ width: '100%' }}
        placeholder="200"
        status={hasError ? 'error' : undefined}
        value={value ?? undefined}
        onChange={onChangeHandler}
        step={1}
        precision={0}
        min={0}
        max={99999}
        decimalSeparator="."
        addonAfter="т"
      />
    )
  }
)

VolumeControl.displayName = 'VolumeControl'
