import { Divider } from 'antd'
import styled from 'styled-components'

import { css } from '@styled-system/css'

import { breakpoints } from '~/tokens'

export const Content = styled.div(
  css({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '16px',
  })
)

export const Title = styled.div(
  css({
    fontSize: ['15px', null, '16px'],
    lineHeight: ['16px', null, '24px'],
    fontWeight: 700,
  })
)

export const Header = styled.div(
  css({
    display: 'grid',
    gridTemplateColumns: '1fr auto max-content',
    gap: '12px',
    width: '100%',
  })
)

export const ExpiredAtTitle = styled.div(
  css({
    fontSize: ['12px', null, '14px'],
    lineHeight: ['13px', null, '20px'],
    fontWeight: 700,
    color: 'Gray800',
  })
)

export const ExpiredAtValue = styled.span(
  css({
    fontSize: ['12px', null, '14px'],
    lineHeight: ['13px', null, '20px'],
    color: 'GreenPrimary',
  })
)

export const Parameters = styled.div(
  css({
    display: 'flex',
    flexWrap: 'wrap',
    gap: '8px',
  })
)

export const Statuses = styled.div(
  css({
    display: 'flex',
    gap: '8px',
  })
)

export const Info = styled.div(
  css({
    display: 'flex',
    alignItems: 'start',
    gap: '4px',
    svg: {
      flexShrink: 0,
      color: 'BadgeBlueDark',
    },
  })
)

export const InfoText = styled.div(
  css({
    marginTop: '2px',
    fontSize: '14px',
    lineHeight: '16px',
    color: 'TxtDefault',
  })
)

export const Row = styled.div(
  css({
    display: 'grid',
    gridTemplateColumns: ['1fr', null, '160px 1fr'],
    gap: ['8px', null, '24px'],
  })
)

export const Label = styled.div(
  css({
    fontSize: '14px',
    lineHeight: '16px',
    color: 'TxtPrimary',
  })
)

export const Value = styled.div(
  css({
    fontSize: '14px',
    lineHeight: '16px',
    color: 'TxtDefault',
  })
)

export const Volume = styled(Value)(
  css({
    fontWeight: '700',
  })
)

export const Price = styled(Value)(
  css({
    fontWeight: '700',
    color: 'GreenPrimary',
  })
)

export const DividerStyled = styled(Divider)(
  css({
    margin: 0,
  })
)

export const Actions = styled.div`
  display: flex;
  gap: 16px;
  justify-content: flex-end;

  @media (max-width: ${breakpoints.MOBILE_L}px) {
    flex-direction: column-reverse;

    > * {
      margin-right: 0 !important;
    }
  }
`
