import React from 'react'

import { Select } from 'antd'
import { SizeType } from 'antd/es/config-provider/SizeContext'
import { SelectProps } from 'antd/es/select'
import { BaseSelectRef } from 'rc-select'

import { useGetProductTypes } from '~/hooks'
import { CustomControlInterface } from '~shared/controls/CustomControl.interface'

type Value = number

interface CommonProps extends CustomControlInterface {
  size?: SizeType
  hasError?: boolean
  id?: string
  placeholder?: string
}

interface SelectPropsMult extends CommonProps {
  mode: 'multiple'
  onChange: (value: Value[]) => void
  value: Value[] | undefined
}

interface SelectPropsSingl extends CommonProps {
  mode: 'single'
  onChange: (value: Value | undefined) => void
  value: Value | undefined
}

type ProductTypeIdSelectControlProps = SelectPropsMult | SelectPropsSingl

export const ProductTypeIdSelectControl: React.FC<ProductTypeIdSelectControlProps> = React.forwardRef<
  BaseSelectRef,
  ProductTypeIdSelectControlProps
>(({ id, value, onChange, hasError, size, mode, placeholder = 'Выберите' }, ref) => {
  const productTypes = useGetProductTypes()

  const onSelectAddressHandler = (v: Value | Value[]) => {
    if (Array.isArray(v) && mode === 'multiple') {
      onChange(v)
    } else if (mode === 'single') {
      onChange(v as Value)
    }
  }

  const sharedProps: SelectProps & { ref?: React.Ref<BaseSelectRef> } = {
    id,
    size,
    ref,
    allowClear: true,
    defaultValue: value,
    status: hasError ? 'error' : undefined,
    style: { width: '100%', maxWidth: '342px' },
    onChange: onSelectAddressHandler,
    loading: productTypes.isLoading,
    disabled: productTypes.isLoading,
    placeholder,
    showArrow: true,
    showSearch: true,
  }

  const options = productTypes.data.map((productType) => (
    <Select.Option key={productType.id} value={productType.id}>
      {productType.name}
    </Select.Option>
  ))

  if (mode === 'multiple') {
    return (
      <Select {...sharedProps} mode="multiple" maxTagCount={1}>
        {options}
      </Select>
    )
  }

  return <Select {...sharedProps}>{options}</Select>
})

ProductTypeIdSelectControl.displayName = 'ProductTypeIdSelectControl'
