import styled from 'styled-components'

export const UserInfoItemMenuWrapper = styled.div`
  display: flex;
  gap: 8px;
`

export const FeedbackItemMenuWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 8px 14px;
  background: #fff8ea;
  cursor: auto;
`
export const UserRoleWrapper = styled.div`
  padding: 4px 12px;
  border-radius: 6px;
  background: rgb(105, 110, 115);
  color: var(--gray-1-color);
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  max-width: fit-content;
`
