import React from 'react'

import { Link, useOutlet } from 'react-router-dom'

import { appRoutes } from '~/router'
import { AccountMenuMobileNavLink } from '~pages/AccountPage/components/AccountMenuMobile/components/AccountMenuMobileNavLink'
import { CompanyVerificationStatusInfo } from '~pages/AccountPage/components/CompanyVerificationStatusInfo'
import { ResponsePageHeader, UserCompanySelect } from '~shared/components'

import * as S from './AccountMenuMobile.styled'

// this is submenu of main mobile menu
export const AccountCompanyMenuMobile: React.FC = () => {
  const outlet = useOutlet()

  if (outlet) {
    return outlet
  }

  return (
    <S.StyledNav>
      <ResponsePageHeader variant="view" title="Моя компания" subTitle="Компания" />

      <CompanyVerificationStatusInfo isMobile />

      <Link to={appRoutes.accountSelectCompanyMobileMenu.url}>
        <UserCompanySelect isMobile />
      </Link>

      <S.StyledDivider />

      <AccountMenuMobileNavLink
        text={appRoutes.accountCompanyProfile.title}
        url={appRoutes.accountCompanyProfile.url}
      />

      <AccountMenuMobileNavLink
        text={appRoutes.accountCompanyRequisites.title}
        url={appRoutes.accountCompanyRequisites.url}
      />

      <AccountMenuMobileNavLink
        text={appRoutes.accountCompanyPayRequisites.title}
        url={appRoutes.accountCompanyPayRequisites.url}
      />

      <AccountMenuMobileNavLink text={appRoutes.accountCompanyDocs.title} url={appRoutes.accountCompanyDocs.url} />

      <AccountMenuMobileNavLink
        text={appRoutes.accountCompanyEmployees.title}
        url={appRoutes.accountCompanyEmployees.url}
      />
    </S.StyledNav>
  )
}
