import React from 'react'

import { Radio, RadioChangeEvent } from 'antd'

import { CustomControlInterface } from '~shared/controls/CustomControl.interface'

type Value = boolean | null

interface IUsingNdsControlProps extends CustomControlInterface {
  onChange: (value: Value) => void
  value: boolean | undefined | null
}

const optionsYesNo = [
  { label: 'Есть', value: true },
  { label: 'Нет', value: false },
]

export const YesOrNotControl: React.FC<IUsingNdsControlProps> = React.forwardRef<HTMLDivElement, IUsingNdsControlProps>(
  ({ value, onChange }, ref) => {
    const onChangeHandler = (e: RadioChangeEvent) => {
      onChange(e.target.value)
    }

    return (
      <Radio.Group
        onChange={onChangeHandler}
        value={value == null ? undefined : value}
        ref={ref}
        optionType="button"
        buttonStyle="solid"
        options={optionsYesNo}
      />
    )
  }
)

YesOrNotControl.displayName = 'YesOrNotControl'
