import React from 'react'

import { Outlet } from 'react-router-dom'

import { useMutation, useQuery } from '@apollo/client'

import { AppHeader } from '~/layout'
import { appRoutes } from '~/router'
import { UserStateFieldName } from '~api/gql-generated/graphql'
import { updateMeStateMutation } from '~api/gql-mutations/me.mutation.graphql'
import { getMeState } from '~api/gql-query/me.query.graphql'
import { useGetDevice } from '~hooks/common'
import { AccountInfoDesktop } from '~pages/AccountPage/components/AccountInfoDesktop'
import { AccountMenuDesktop } from '~pages/AccountPage/components/AccountMenuDesktop'
import { AccountMenuMobile } from '~pages/AccountPage/components/AccountMenuMobile'
import { CompanyVerificationStatusInfo } from '~pages/AccountPage/components/CompanyVerificationStatusInfo'

import * as S from './AccountPage.styled'

const AccountPage: React.FC = () => {
  const [updateMeState] = useMutation(updateMeStateMutation, {
    refetchQueries: [getMeState],
  })
  const device = useGetDevice()
  const isMobile = device === 'mobile'

  const onRestartTour = async () => {
    await updateMeState({
      variables: {
        input: {
          fieldName: UserStateFieldName.IsShowEmployeesGuide,
          value: String(true),
        },
      },
    })
    await updateMeState({
      variables: {
        input: {
          fieldName: UserStateFieldName.MultiCompanyProfileGuideShown,
          value: String(false),
        },
      },
    })
  }

  if (isMobile) {
    return <AccountMenuMobile />
  }

  return (
    <>
      <AppHeader title={appRoutes.account.title} onHelp={onRestartTour} />
      <S.AccountPageWrapper>
        <S.AccountPageLeftSide>
          <AccountInfoDesktop />
          <AccountMenuDesktop />
        </S.AccountPageLeftSide>

        <S.AccountPageRightSide>
          <CompanyVerificationStatusInfo />
          <Outlet />
        </S.AccountPageRightSide>
      </S.AccountPageWrapper>
    </>
  )
}
export default AccountPage
