import React, { HTMLAttributeAnchorTarget, MouseEvent, PropsWithChildren } from 'react'

import { Space } from 'antd'
import { FiChevronRight } from 'react-icons/fi'

import * as S from './AccountMenuMobileNavLink.styled'

interface Props {
  text?: string
  url?: string
  icon?: React.ReactElement
  onClick?: (e: MouseEvent) => void
  target?: HTMLAttributeAnchorTarget
}
export const AccountMenuMobileNavLink: React.FC<PropsWithChildren<Props>> = ({
  children,
  text,
  icon,
  url,
  onClick,
  target,
}) => {
  if (children) {
    return (
      <S.StyledNavLink to={url ?? ''} target={target} onClick={onClick}>
        {children}
      </S.StyledNavLink>
    )
  }
  return (
    <S.StyledNavLink to={url ?? ''} target={target} onClick={onClick}>
      <Space size={18}>
        {icon}
        {text}
      </Space>
      <FiChevronRight size={24} />
    </S.StyledNavLink>
  )
}
