import React from 'react'

import { Button } from 'antd'
import { Controller, useForm } from 'react-hook-form'

import { yupResolver } from '@hookform/resolvers/yup/dist/yup'

import { FormField, FormFooter } from '~/ui-components'
import {
  AddressControlAutocomplete,
  ElevatorTypeSelectControl,
  OrganizationControlAutocomplete,
} from '~shared/controls'

import { createElevatorFormStep1Schema } from './CreateElevatorFormStep1.validation'

import { CreateElevatorFormStep1Props, CreateElevatorFormStep1Values } from './CreateElevatorFormStep1.types'

export const CreateElevatorFormStep1: React.FC<CreateElevatorFormStep1Props> = ({
  onNext,
  onCancel,
  defaultValues,
  nextButtonText = 'Далее',
  loading,
}) => {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<CreateElevatorFormStep1Values>({
    defaultValues: {
      address: defaultValues?.address,
      name: defaultValues?.name,
      type: defaultValues?.type,
    },
    resolver: yupResolver(createElevatorFormStep1Schema),
  })

  const onSubmitHandler = (formValues: CreateElevatorFormStep1Values) => {
    onNext(formValues)
  }

  return (
    <form onSubmit={handleSubmit(onSubmitHandler)}>
      <FormField
        htmlFor="name"
        label="Название организации-владельца"
        validateMessage={errors.name?.message}
        isRequired
      >
        <Controller
          name="name"
          control={control}
          render={({ field }) => (
            <OrganizationControlAutocomplete
              id="name"
              {...field}
              hasError={!!errors.name}
              onSelect={(x) => field.onChange(x?.value)}
            />
          )}
        />
      </FormField>

      <FormField label="Тип" htmlFor="type" validateMessage={errors.type?.message} isRequired>
        <Controller
          name="type"
          control={control}
          render={({ field }) => <ElevatorTypeSelectControl id="type" {...field} hasError={!!errors.type} />}
        />
      </FormField>

      <FormField htmlFor="address" label="Фактический адрес" isRequired validateMessage={errors.address?.message}>
        <Controller
          name="address"
          control={control}
          render={({ field }) => (
            <AddressControlAutocomplete
              id="address"
              value={field.value}
              onChange={field.onChange}
              hasError={!!errors.address}
              onAddressSelect={(suggestion) => field.onChange(suggestion?.value ?? suggestion?.unrestricted_value)}
            />
          )}
        />
      </FormField>

      <FormFooter>
        <Button onClick={onCancel} htmlType="button">
          Отмена
        </Button>
        <Button type="primary" htmlType="submit" loading={loading}>
          {nextButtonText}
        </Button>
      </FormFooter>
    </form>
  )
}
