import styled from 'styled-components'

import { breakpoints } from '~/tokens'

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  gap: 8px;
  margin-bottom: 28px;

  @media (max-width: ${breakpoints.MOBILE_XL}px) {
    gap: 16px;
  }
`
