import { useMutation } from '@tanstack/react-query'

import { ElevatorApiElevatorAdminControllerRecoverElevatorRequest } from '~api/generated'
import { useApi } from '~providers/api'

export const useRecoverElevatorAdmin = () => {
  const api = useApi()

  const useRecoverElevatorFn = async (
    request: ElevatorApiElevatorAdminControllerRecoverElevatorRequest
  ): Promise<void> => {
    const res = await api.elevators.elevatorAdminControllerRecoverElevator(request)

    return res.data
  }

  return useMutation(useRecoverElevatorFn)
}
