import React, { useEffect, useState } from 'react'

import { Button, Modal, Pagination, Skeleton } from 'antd'
import { observer } from 'mobx-react-lite'
import { useNavigate } from 'react-router-dom'

import { useMutation, useQuery } from '@apollo/client'
import { ApolloError } from '@apollo/client/errors'

import { usePagination } from '~/hooks'
import { appRoutes } from '~/router'
import { basisFilterStore } from '~/store/filters/basisFilterStore'
import { graphqlErrorHandler } from '~/utils/graphqlErrorHandler'
import {
  DayOfWeek,
  ElevatorType,
  LoadingTransportType,
  TransportMode,
  UnloadingTransportType,
} from '~api/gql-generated/graphql'
import { elevatorCreateMutation } from '~api/gql-mutations/elevator.mutation.graphql'
import { getCompanyStateQuery } from '~api/gql-query/company.query.graphql'
import { useGetDevice } from '~hooks/common'
import { useGetElevators } from '~hooks/elevators'
import { VIP_CLIENTS, VipCards } from '~layout/WelcomePageV2/VipCards'
import { BasisListFiltersInline } from '~pages/BasisPage/components/BasisListFiltersInline'
import { CreateBasisByUserModal } from '~pages/BasisPage/components/CreateBasisModal'
import { TotalBasisStatistics } from '~pages/BasisPage/components/TotalBasisStatistics'
import { BasisListMobile } from '~pages/BasisPage/modules/BasisList/components/BasisListMobile'
import { BasisListTable } from '~pages/BasisPage/modules/BasisList/components/BasisListTable'
import { CreateBasisFormValue } from '~pages/SettingsPage/modules/ElevatorsAdmin/components/CreateElevatorModal/CreateElevatorModal'
import { useAuth } from '~providers/auth'

import { CreateElevatorFormStep1Values } from '~pages/SettingsPage/modules/ElevatorsAdmin/components/CreateElevatorForm/step1/CreateElevatorFormStep1.types'

import * as S from './BasisList.styled'

export const BasisList: React.FC = observer(() => {
  const navigate = useNavigate()
  const device = useGetDevice()
  const isMobile = device === 'mobile'
  const { companyId } = useAuth()

  const companyStateFn = useQuery(getCompanyStateQuery, {
    variables: { companyId },
    skip: !companyId,
  })

  const hasPriorityPass = VIP_CLIENTS.gapResurs.isShow || VIP_CLIENTS.damate.isShow

  const [elevatorCreate, elevatorCreateState] = useMutation(elevatorCreateMutation)

  const [isCreateBasisModalOpen, setCreateBasisModalOpen] = useState(false)

  const filters = basisFilterStore.basisFilters
  const { currentPage, setCurrentPage, pageSize, setPageSize, pageSizeOptions } = usePagination()

  const getElevatorsFn = useGetElevators({
    currentPage,
    pageSize,
    ...basisFilterStore.mappedCurrentFiltersToResponse,
  })

  useEffect(() => {
    setCurrentPage(1)
  }, [filters.regionIds.length, filters.name, filters.productTypes])

  const pagination = getElevatorsFn.data?.pagination
  const elevators = getElevatorsFn.data?.elevators

  const onItemClickHandler = (id: number) => {
    navigate(appRoutes.basisDetails.url.replace(':id', id.toString()))
  }

  const onCreateBasisHandlerForMyCompany = async (formValues: CreateBasisFormValue) => {
    try {
      await elevatorCreate({
        variables: {
          input: {
            // step 1
            name: formValues.step1.name,
            address: formValues.step1.address,
            type: formValues.step1.type as ElevatorType[],
            // step 2
            storageVolume: formValues.step2.storageVolume,
            productOnElevator: formValues.step2.productTypes,
            transport: formValues.step2.transport as TransportMode[],
            openingTime: formValues.step2.openingTime?.toISOString(),
            closingTime: formValues.step2.closingTime?.toISOString(),
            workingDaysOfWeek: formValues.step2.workingDaysOfWeek as DayOfWeek[],
            hasLab: formValues.step2.hasLab,
            hasDigitalQueue: formValues.step2.hasDigitalQueue,
            // step 3 Rail
            stationName: formValues.step3Rail?.stationName,
            hasRailWay: formValues.step3Rail?.hasRailWay,
            hasRailScales: formValues.step3Rail?.hasRailScales,
            hasRailStock: formValues.step3Rail?.hasRailStock,
            hasTecEco: formValues.step3Rail?.hasTecEco,
            railLoadSpeed: formValues.step3Rail?.railLoadSpeed,
            waitingArea: formValues.step3Rail?.waitingArea,
            railAdditionalInfo: formValues.step3Rail?.railAdditionalInfo,
            // step 4 Car
            hasScales: true, // радов сказал что у базисов всегда есть весы
            scalesWeightLimit: formValues.step4Car?.scalesWeightLimit,
            scalesLength: formValues.step4Car?.scalesLength,
            autoReceptionSpeed: formValues.step4Car?.autoReceptionSpeed,
            autoUnloadSpeed: formValues.step4Car?.autoUnloadSpeed,
            autoLength: formValues.step4Car?.autoLength,
            autoHeight: formValues.step4Car?.autoHeight ? formValues.step4Car.autoHeight * 100 : undefined,
            autoClearance: formValues.step4Car?.autoClearance,
            loadingTransport: formValues.step4Car?.loadingTransport as LoadingTransportType[],
            unloadingTransport: formValues.step4Car?.unloadingTransport as UnloadingTransportType[],
            hasAutoStorage: formValues.step4Car?.hasAutoStorage,
            autoAdditionalInfo: formValues.step4Car?.autoAdditionalInfo,
            // step 5 additional service
            additionalServiceWithNds: formValues.step5ServicePrice?.usingNds,
            receptionPrice: formValues.step5ServicePrice?.receptionPrice,
            dryingPrice: formValues.step5ServicePrice?.dryingPrice,
            storingPrice: formValues.step5ServicePrice?.storingPrice,
            mixingPrice: formValues.step5ServicePrice?.mixingPrice,
            autoShipmentPrice: formValues.step5ServicePrice?.autoShipmentPrice,
            trainShipmentPrice: formValues.step5ServicePrice?.trainShipmentPrice,
            paperworkPrice: formValues.step5ServicePrice?.paperworkPrice,
            cleaningPrice: formValues.step5ServicePrice?.cleaningPrice,
            censusPrice: formValues.step5ServicePrice?.censusPrice,
            anotherServices: formValues.step5ServicePrice?.otherServices,
          },
        },
      })
      Modal.success({
        title: 'Спасибо! Запрос на добавление базиса отправлен',
        content: 'Мы уточним информацию и в ближайшее время добавим его на платформу.',
        okText: 'Хорошо!',
      })
      setCreateBasisModalOpen(false)
    } catch (e) {
      e instanceof ApolloError && graphqlErrorHandler(e, 'Ошибка при добавлении базиса')
    }
  }
  const onCreateBasisHandlerForOtherCompany = async (formValues: CreateElevatorFormStep1Values) => {
    try {
      await elevatorCreate({
        variables: {
          input: {
            name: formValues.name,
            address: formValues.address,
            type: formValues.type as ElevatorType[],
            // FIXME: убрать после фикса на бэке.
            hasScales: true,
            loadingTransport: [],
            unloadingTransport: [],
            transport: [],
            storageVolume: 0,
          },
        },
      })
      Modal.success({
        title: 'Спасибо! Запрос на добавление базиса отправлен',
        content: 'Мы уточним информацию и в ближайшее время добавим его на платформу.',
        okText: 'Хорошо!',
      })
      setCreateBasisModalOpen(false)
    } catch (e) {
      e instanceof ApolloError && graphqlErrorHandler(e, 'Ошибка при добавлении базиса')
    }
  }

  if (getElevatorsFn.isLoading) {
    return <Skeleton />
  }

  return (
    <>
      {hasPriorityPass && <VipCards />}

      <TotalBasisStatistics />

      <S.FiltersWrapper>
        <BasisListFiltersInline store={basisFilterStore} />
        <Button type="primary" onClick={() => setCreateBasisModalOpen(true)}>
          Добавить базис
        </Button>
      </S.FiltersWrapper>

      {isMobile ? (
        <BasisListMobile
          basisList={elevators}
          onBasisClick={onItemClickHandler}
          isLoading={getElevatorsFn.isFetching}
        />
      ) : (
        <BasisListTable basisList={elevators} onBasisClick={onItemClickHandler} isLoading={getElevatorsFn.isFetching} />
      )}

      <CreateBasisByUserModal
        open={isCreateBasisModalOpen}
        onCancel={() => setCreateBasisModalOpen(false)}
        onSubmitForMyCompany={onCreateBasisHandlerForMyCompany}
        onSubmitForOtherCompany={onCreateBasisHandlerForOtherCompany}
        loading={elevatorCreateState.loading}
      />

      {pagination && (
        <Pagination
          current={pagination?.currentPage}
          total={pagination?.total}
          pageSize={pagination?.pageSize}
          pageSizeOptions={pageSizeOptions}
          onShowSizeChange={(x, size) => setPageSize(size)}
          showSizeChanger
          onChange={setCurrentPage}
        />
      )}
    </>
  )
})
