import React from 'react'

import { Button, Input } from 'antd'
import { Controller, useForm } from 'react-hook-form'

import { yupResolver } from '@hookform/resolvers/yup/dist/yup'

import { FormField, FormFooter, TimePicker } from '~/ui-components'
import { CompanyType } from '~api/gql-generated/graphql'
import {
  AddressControlAutocomplete,
  DaysOfWeekControl,
  ElevatorTransferTypeControl,
  YesOrNotControl,
} from '~shared/controls'

import { createWarehouseFormStep1Schema } from './CreateWarehouseFormStep1.validation'

import { CreateWarehouseFormStep1Props, CreateWarehouseFormStep1Values } from './CreateWarehouseFormStep1.types'

import * as S from '~pages/SettingsPage/modules/ElevatorsAdmin/components/CreateElevatorForm/step2/CreateElevatorFormStep2.styled'

export const CreateWarehouseFormStep1: React.FC<CreateWarehouseFormStep1Props> = ({
  companyRole,
  onNext,
  onCancel,
  defaultValues,
  nextButtonText = 'Далее',
  loading,
}) => {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<CreateWarehouseFormStep1Values>({
    defaultValues: {
      name: defaultValues?.name,
      address: defaultValues?.address,
      transport: defaultValues?.transport,
      openingTime: defaultValues?.openingTime,
      closingTime: defaultValues?.closingTime,
      workingDaysOfWeek: defaultValues?.workingDaysOfWeek,
      hasLab: defaultValues?.hasLab,
      hasDigitalQueue: defaultValues?.hasDigitalQueue,
    },
    resolver: yupResolver(createWarehouseFormStep1Schema),
  })

  const onSubmitHandler = (formValues: CreateWarehouseFormStep1Values) => {
    console.log({ formValues })
    onNext(formValues)
  }

  return (
    <form onSubmit={handleSubmit(onSubmitHandler)}>
      <FormField htmlFor="name" label="Название склада" validateMessage={errors.name?.message}>
        <Controller
          name="name"
          control={control}
          render={({ field }) => (
            <Input
              {...field}
              id="name"
              placeholder="Название склада"
              status={errors.name && 'error'}
              value={field.value ?? ''}
            />
          )}
        />
      </FormField>

      <FormField htmlFor="address" label="Адрес склада" isRequired validateMessage={errors.address?.message}>
        <Controller
          name="address"
          control={control}
          render={({ field }) => (
            <AddressControlAutocomplete
              id="address"
              value={field.value}
              onChange={field.onChange}
              hasError={!!errors.address}
              onAddressSelect={(suggestion) => field.onChange(suggestion?.value ?? suggestion?.unrestricted_value)}
            />
          )}
        />
      </FormField>

      <FormField label="Виды принимаемого транспорта" validateMessage={errors.transport?.message} isRequired>
        <Controller
          name="transport"
          control={control}
          render={({ field }) => <ElevatorTransferTypeControl {...field} hasError={!!errors.transport} />}
        />
      </FormField>

      <FormField label="Часы работы" validateMessage={errors.openingTime?.message ?? errors.closingTime?.message}>
        <S.HalfRow>
          <Controller
            control={control}
            name="openingTime"
            render={({ field }) => <TimePicker value={field.value} onChange={field.onChange} />}
          />
          -
          <Controller
            control={control}
            name="closingTime"
            render={({ field }) => <TimePicker value={field.value} onChange={field.onChange} />}
          />
        </S.HalfRow>
      </FormField>

      <FormField label="Рабочие дни недели" validateMessage={errors.workingDaysOfWeek?.message}>
        <Controller
          name="workingDaysOfWeek"
          control={control}
          render={({ field }) => <DaysOfWeekControl {...field} hasError={!!errors.workingDaysOfWeek} />}
        />
      </FormField>

      <FormField
        label="Наличие лаборатории"
        validateMessage={errors.hasLab?.message}
        isVisible={companyRole === CompanyType.Buyer || companyRole === CompanyType.Trader}
      >
        <Controller name="hasLab" control={control} render={({ field }) => <YesOrNotControl {...field} />} />
      </FormField>

      <FormField
        label="Наличие электронной очереди"
        validateMessage={errors.hasDigitalQueue?.message}
        isVisible={companyRole === CompanyType.Buyer || companyRole === CompanyType.Trader}
      >
        <Controller name="hasDigitalQueue" control={control} render={({ field }) => <YesOrNotControl {...field} />} />
      </FormField>

      <FormFooter>
        <Button onClick={onCancel} htmlType="button">
          Отмена
        </Button>
        <Button type="primary" htmlType="submit" loading={loading}>
          {nextButtonText}
        </Button>
      </FormFooter>
    </form>
  )
}
