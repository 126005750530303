import React from 'react'

import { ModeratorUserList } from '~pages/ModeratorPage/components/ModeratorUserList'

import * as S from './ModeratorUserListTab.styled'

export const ModeratorUsersListTab: React.FC = () => {
  return (
    <S.ContentWrapper>
      <ModeratorUserList />
    </S.ContentWrapper>
  )
}
