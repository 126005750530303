import React from 'react'

import { Button } from 'antd'
import { FiHelpCircle } from 'react-icons/fi'

interface IHelpButtonProps {
  onClick: () => void
}

export const HelpButton: React.FC<IHelpButtonProps> = ({ onClick }) => {
  return (
    <Button
      style={{ marginLeft: 8, display: 'inline' }}
      type="text"
      icon={<FiHelpCircle size={24} color={'var(--gray-6-color)'} />}
      onClick={onClick}
    />
  )
}
