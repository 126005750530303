import React from 'react'

import { Button, Input, InputNumber } from 'antd'
import { Controller, useForm } from 'react-hook-form'

import { yupResolver } from '@hookform/resolvers/yup/dist/yup'

import { FormField, FormFooter } from '~/ui-components'
import { inputSharedSettings } from '~/utils/inputSharedSettings'
import { YesOrNotControl } from '~shared/controls'

import { createWarehouseFormStep2RailSchema } from './CreateWarehouseFormStep2RailSchema'

import {
  CreateWarehouseFormStep2RailProps,
  CreateWarehouseFormStep2RailValues,
} from './CreateWarehouseFormStep2Rail.types'

import * as S from './CreateWarehouseFormStep2Rail.styled'

export const CreateWarehouseFormStep2Rail: React.FC<CreateWarehouseFormStep2RailProps> = ({
  onNext,
  onCancel,
  onPrev,
  defaultValues,
  nextButtonText = 'Далее',
}) => {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<CreateWarehouseFormStep2RailValues>({
    defaultValues: {
      stationName: defaultValues?.stationName,
      hasRailWay: defaultValues?.hasRailWay,
      hasRailScales: defaultValues?.hasRailScales,
      hasRailStock: defaultValues?.hasRailStock,
      railLoadSpeed: defaultValues?.railLoadSpeed,
      waitingArea: defaultValues?.waitingArea,
      railAdditionalInfo: defaultValues?.railAdditionalInfo,
    },
    resolver: yupResolver(createWarehouseFormStep2RailSchema),
  })
  const onSubmitHandler = (formValues: CreateWarehouseFormStep2RailValues) => {
    onNext(formValues)
  }

  return (
    <form onSubmit={handleSubmit(onSubmitHandler)}>
      <FormField
        label="Название станции"
        htmlFor="stationName"
        validateMessage={errors.stationName?.message}
        isRequired
      >
        <Controller
          name="stationName"
          control={control}
          render={({ field }) => (
            <Input
              {...field}
              id="stationName"
              placeholder="Введите название станции"
              status={errors.stationName && 'error'}
            />
          )}
        />
      </FormField>

      <FormField label="Наличие собственных путей" validateMessage={errors.hasRailWay?.message}>
        <Controller name="hasRailWay" control={control} render={({ field }) => <YesOrNotControl {...field} />} />
      </FormField>

      <FormField label="Наличие собственных ж/д весов" validateMessage={errors.hasRailScales?.message}>
        <Controller name="hasRailScales" control={control} render={({ field }) => <YesOrNotControl {...field} />} />
      </FormField>

      <FormField
        label="Наличие собственного тягового подвижного состава"
        validateMessage={errors.hasRailStock?.message}
      >
        <Controller name="hasRailStock" control={control} render={({ field }) => <YesOrNotControl {...field} />} />
      </FormField>

      <FormField htmlFor="railLoadSpeed" label="Скорость погрузки" validateMessage={errors.railLoadSpeed?.message}>
        <Controller
          name="railLoadSpeed"
          control={control}
          render={({ field }) => (
            <InputNumber
              {...field}
              {...inputSharedSettings}
              addonAfter="вагонов/день"
              style={{ width: '100%' }}
              id="railLoadSpeed"
              placeholder="Введите значение"
              status={errors.railLoadSpeed && 'error'}
              step={1}
            />
          )}
        />
      </FormField>

      <FormField htmlFor="waitingArea" label="Фронт подачи вагонов" validateMessage={errors.waitingArea?.message}>
        <Controller
          name="waitingArea"
          control={control}
          render={({ field }) => (
            <InputNumber
              {...field}
              {...inputSharedSettings}
              addonAfter="шт"
              style={{ width: '100%' }}
              id="waitingArea"
              placeholder="Введите значение"
              status={errors.waitingArea && 'error'}
              step={1}
            />
          )}
        />
      </FormField>

      {/*<FormField*/}
      {/*  label="Возможность транспортно-экспедиционного обслуживания"*/}
      {/*  validateMessage={errors.hasTecEco?.message}*/}
      {/*>*/}
      {/*  <Controller name="hasTecEco" control={control} render={({ field }) => <YesOrNotControl {...field} />} />*/}
      {/*</FormField>*/}

      <FormField
        label="Дополнительные сведения по Ж/Д транспорту"
        htmlFor="railAdditionalInfo"
        validateMessage={errors.railAdditionalInfo?.message}
      >
        <Controller
          name="railAdditionalInfo"
          control={control}
          render={({ field }) => (
            <Input
              {...field}
              id="railAdditionalInfo"
              placeholder="Дополнительные сведения"
              status={errors.railAdditionalInfo && 'error'}
            />
          )}
        />
      </FormField>

      <FormFooter>
        <Button onClick={onCancel} htmlType="button">
          Отмена
        </Button>
        <Button onClick={onPrev} htmlType="button">
          Назад
        </Button>
        <Button type="primary" htmlType="submit">
          {nextButtonText}
        </Button>
      </FormFooter>
    </form>
  )
}
