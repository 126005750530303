import { dealDocumentsGroupsTypes } from '~/utils/constants'
import { DealDocumentData } from '~api/generated'

export const useGetDealDocumentsGroups = (documents?: DealDocumentData[]) => {
  const documentMap = new Map(documents?.filter((doc) => doc.instances.length).map((doc) => [doc.type, doc]))

  return {
    main: dealDocumentsGroupsTypes.main
      .map((docType) => documentMap.get(docType))
      .filter(Boolean) as DealDocumentData[],
    agent: dealDocumentsGroupsTypes.agent
      .map((docType) => documentMap.get(docType))
      .filter(Boolean) as DealDocumentData[],
    product: dealDocumentsGroupsTypes.product
      .map((docType) => documentMap.get(docType))
      .filter(Boolean) as DealDocumentData[],
    transportation: dealDocumentsGroupsTypes.transportation
      .map((docType) => documentMap.get(docType))
      .filter(Boolean) as DealDocumentData[],
  }
}
