import React from 'react'

import { AppHeader } from '~/layout'
import { appRoutes } from '~/router'
import { NotificationsList } from '~pages/Notifications/NotificationsPage/components/NotificationsList'

const NotificationsPage: React.FC = () => {
  return (
    <>
      <AppHeader title={appRoutes.notifications.title} />
      <NotificationsList />
    </>
  )
}

export default NotificationsPage
