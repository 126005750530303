import styled from 'styled-components'

import css from '@styled-system/css'

import { Text } from '~/ui-components'

export const Form = styled('form')(
  css({
    width: ['100%', null, '560px'],
  })
)

export const TotalText = styled(Text)`
  margin-top: 8px;
`
