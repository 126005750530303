import React, { useRef, useState } from 'react'

import { Button, Carousel } from 'antd'
import { CarouselRef } from 'antd/es/carousel'
import { useNavigate } from 'react-router-dom'

import { appRoutes } from '~/router'
import { Text } from '~/ui-components'
import { modalSharedSettings } from '~/utils'
import step1Pic from '~assets/images/new-profile-tour/step1.png'
import step2Pic from '~assets/images/new-profile-tour/step2.png'
import step3Pic from '~assets/images/new-profile-tour/step3.png'
import step4Pic from '~assets/images/new-profile-tour/step4.png'
import step5Pic from '~assets/images/new-profile-tour/step5.png'
import step6Pic from '~assets/images/new-profile-tour/step6.png'
import step7Pic from '~assets/images/new-profile-tour/step7.png'

import * as S from './AccountMenuMobileTour.styled'

interface AccountMenuMobileTourProps {
  isOpen: boolean
  onCancel: () => void
}

interface StepMobileTour {
  index: number
  title: string | React.ReactElement
  description: string | React.ReactElement
  picUrl: string
}

export const AccountMenuMobileTour: React.FC<AccountMenuMobileTourProps> = ({ isOpen, onCancel }) => {
  const navigate = useNavigate()
  const ref = useRef<CarouselRef>(null)
  const [step, setStep] = useState(1)

  const steps: StepMobileTour[] = [
    {
      index: 1,
      title: 'Мы обновили личный кабинет!',
      description: 'В этих подсказках мы расскажем про новые разделы в личном кабинете.',
      picUrl: step1Pic,
    },
    {
      index: 2,
      title: 'Моя компания',
      description:
        'Вся информация по компании находится в разделе “Моя компания”. Здесь можно заполнить реквизиты, документы, добавлять или удалять сотрудников',
      picUrl: step2Pic,
    },
    {
      index: 3,
      title: 'Общая информация',
      description:
        'Вся информация по компании находится в разделе “Моя компания”. Здесь можно заполнить реквизиты, документы, добавлять или удалять сотрудников',
      picUrl: step3Pic,
    },
    {
      index: 4,
      title: 'Реквизиты компании',
      description:
        'Чтобы совершать сделки, укажите КПП, ФИО генерального директора, должность подписанта, основание, юридический и физический адрес, а также применение НДС.',
      picUrl: step4Pic,
    },
    {
      index: 5,
      title: 'Платежные реквизиты',
      description:
        'Здесь нужно указать реквизиты вашего банковского счета, на который будут переводиться деньги по заключенным сделкам.',
      picUrl: step5Pic,
    },
    {
      index: 6,
      title: 'Документы',
      description:
        'Чтобы провести сделку через нашу платформу, вам потребуется загрузить документы. К сожалению, это пока что обязательный шаг. Мы будем стараться упростить его в будущем.',
      picUrl: step6Pic,
    },
    {
      index: 7,
      title: 'Сотрудники',
      description:
        'Раздел дает возможность добавить сотрудников вашей компании и назначить им роль на платформе. Укажите их ФИО, контактные данные и можно выбрать одну из доступных ролей: директор, менеджер или бухгалтер.',
      picUrl: step7Pic,
    },
  ]

  const isLastStep = step === steps.at(-1)?.index

  const nextHandler = () => {
    ref.current?.next()
    setStep((step) => step + 1)
  }
  const prevHandler = () => {
    ref.current?.prev()
    setStep((step) => step - 1)
  }

  return (
    <S.StyledModal
      {...modalSharedSettings}
      title="Новый личный кабинет"
      open={isOpen}
      footer={null}
      onCancel={onCancel}
    >
      <S.Wrapper>
        <Carousel ref={ref} dots={false} adaptiveHeight>
          {steps.map((step) => (
            <div key={step.index}>
              <S.StepWrapper>
                <S.StepImg src={step.picUrl} alt={`step ${step}`}></S.StepImg>

                <Text variant="h2">{step.title}</Text>
                <Text variant="normal" style={{ textAlign: 'center' }}>
                  {step.description}
                </Text>
              </S.StepWrapper>
            </div>
          ))}
        </Carousel>

        <S.Actions>
          <Button hidden={step === 1} onClick={prevHandler}>
            Назад
          </Button>

          {isLastStep ? (
            <Button
              type="primary"
              block
              onClick={async () => {
                onCancel()
                await navigate(appRoutes.accountCompanyMobileMenu.url)
              }}
            >
              В личный кабинет!
            </Button>
          ) : (
            <Button type="primary" block onClick={nextHandler}>
              Далее
            </Button>
          )}
        </S.Actions>
      </S.Wrapper>
    </S.StyledModal>
  )
}
