import React from 'react'

import styled from 'styled-components'

import { useGetProductTypeData } from '~/hooks'
import { breakpoints } from '~/tokens'
import { Badge, Text } from '~/ui-components'
import { ProductData } from '~api/generated'

import * as S from '~pages/OffersPage/components/OfferCard/OfferCard.styled'

export const Parameters = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 8px;
  max-width: 500px;
  color: var(--badge-gray-bg-color);
  @media (max-width: ${breakpoints.MOBILE_XL}px) {
    grid-template-columns: 1fr;
    grid-row-gap: 4px;
  }
`

interface BasisCultureParamsProps {
  product: ProductData
  variant?: 'list' | 'badge'
}
export const BasisCultureParams: React.FC<BasisCultureParamsProps> = ({ product, variant = 'list' }) => {
  const { getProductTypeParametersForUI_V2 } = useGetProductTypeData()

  switch (variant) {
    case 'list':
      return (
        <Parameters>
          {getProductTypeParametersForUI_V2(product).map((param) => (
            <Text variant="description" key={param}>
              {param}
            </Text>
          ))}
        </Parameters>
      )
    case 'badge':
      return (
        <S.Parameters>
          {getProductTypeParametersForUI_V2(product).map((param) => (
            <Badge color="gray" isRound key={param}>
              {param}
            </Badge>
          ))}
        </S.Parameters>
      )
  }
}
