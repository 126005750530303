import React from 'react'

import { Select } from 'antd'
import { BaseSelectRef } from 'rc-select'

import { getCountryName } from '~/utils/auth/getCountryName'
import { enumToArray } from '~/utils/enum-to-array'
import { CountryName } from '~api/gql-generated/graphql'
import { CustomControlInterface } from '~shared/controls/CustomControl.interface'

type Value = CountryName | undefined

interface CountrySelectControlProps extends CustomControlInterface {
  onChange: (value: Value) => void
  value: Value | null
  hasError?: boolean
  id?: string
  disabled?: boolean
}
export const CountrySelectControl: React.FC<CountrySelectControlProps> = React.forwardRef<
  BaseSelectRef,
  CountrySelectControlProps
>(({ id, value, onChange, hasError, disabled }, ref) => {
  const onSelectHandler = (v: Value) => {
    onChange(v)
  }

  return (
    <Select
      ref={ref}
      id={id}
      style={{ width: '100%' }}
      value={value}
      onChange={onSelectHandler}
      placeholder="Страна"
      status={hasError ? 'error' : undefined}
      disabled={disabled}
    >
      {enumToArray(CountryName).map((option) => (
        <Select.Option key={option} value={option}>
          {getCountryName(option as CountryName)}
        </Select.Option>
      ))}
    </Select>
  )
})

CountrySelectControl.displayName = 'CountrySelectControl'
