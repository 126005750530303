import React from 'react'

import styled from 'styled-components'

import css from '@styled-system/css'

import { Text } from '~/ui-components'

export const RequiredFieldsMessage: React.FC = () => <Text variant="normal">*Поля обязательные для заполнения</Text>

export const FormItem = styled.div({
  display: 'flex',
  flexDirection: 'column',
})
