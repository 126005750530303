import { graphql } from '~api/gql-generated'

export const freightOrderChangeRequestUpdateStatusMutation = graphql(/* GraphQL */ `
  mutation FreightOrderChangeRequestUpdateStatus(
    $input: FreightOrderChangeRequestStatusUpdateInput!
    $changeRequestId: Int!
  ) {
    freightOrderChangeRequest(changeRequestId: $changeRequestId) {
      updateStatus(input: $input) {
        id
      }
    }
  }
`)
