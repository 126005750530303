import { useQuery, UseQueryResult } from '@tanstack/react-query'

import { AdminGetElevatorResponse, ElevatorApiElevatorAdminControllerGetElevatorRequest } from '~api/generated'
import { useApi } from '~providers/api'

export const useGetElevatorAdmin = (
  request: ElevatorApiElevatorAdminControllerGetElevatorRequest
): UseQueryResult<AdminGetElevatorResponse> => {
  const api = useApi()

  const fetchFn = async (): Promise<AdminGetElevatorResponse> => {
    const res = await api.elevators.elevatorAdminControllerGetElevator(request)

    return res.data
  }

  return useQuery(['elevatorAdmin', request.id], fetchFn)
}
