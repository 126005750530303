import { useMutation } from '@tanstack/react-query'

import { ElevatorAdvertOfferApiElevatorAdvertOfferControllerAcceptElevatorAdvertOfferRequest } from '~api/generated'
import { useApi } from '~providers/api'

export const useAcceptAdvertOffer = () => {
  const api = useApi()

  const fn = async (request: ElevatorAdvertOfferApiElevatorAdvertOfferControllerAcceptElevatorAdvertOfferRequest) => {
    const res = await api.elevatorAdvertOffer.elevatorAdvertOfferControllerAcceptElevatorAdvertOffer(request)

    return res.data
  }

  return useMutation(fn)
}
