import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { breakpoints } from '~/tokens'
import { Text } from '~/ui-components'

export const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  padding: 24px;
  @media (max-width: ${breakpoints.MOBILE_XL}px) {
    padding: 32px 12px;
  }
`

export const CardWrapper = styled.article`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 12px;
  margin-bottom: 60px;
  @media (max-width: ${breakpoints.MOBILE_XL}px) {
    margin-bottom: 32px;
  }
`

export const Card = styled(Link)`
  display: flex;
  padding: 16px;
  gap: 16px;
  justify-content: space-between;
  align-items: flex-start;
  flex: 1 0 0;
  flex-wrap: wrap;

  border-radius: 8px;
  background: #f6fbf9 !important;
  border: 1px solid transparent;

  :hover {
    border: 1px solid #45a67d;
    cursor: pointer;
  }

  @media (max-width: ${breakpoints.MOBILE_XL}px) {
    padding: 8px 16px;
  }
`

export const Description = styled(Text).attrs({ variant: 'normal' })`
  line-height: 24px;
  @media (max-width: ${breakpoints.MOBILE_XL}px) {
    font-size: 10px;
  }
`
export const Title = styled(Text).attrs({ variant: 'h1' })`
  margin-bottom: 24px;

  @media (max-width: ${breakpoints.MOBILE_XL}px) {
    display: none;
  }
`

export const Counter = styled(Text).attrs({ variant: 'h4' })`
  text-align: end;
  color: #45a67d;
  @media (max-width: ${breakpoints.MOBILE_XL}px) {
    text-align: unset;
  }
`

export const Grey = styled.span`
  color: rgba(66, 66, 66, 0.6);
`

export const VipCard = styled(Link)`
  display: flex;
  justify-content: space-between;
  padding: 16px;
  gap: 32px;
  width: 364px;

  border-radius: 8px;
  border: 2px solid #45a67d;

  background: rgba(69, 166, 125, 0.05) !important;
  max-width: 390px;
  cursor: pointer;

  &:hover {
    background: rgba(69, 166, 125, 0.1) !important;
  }
`

// export const Counter = styled.div`
//   color: #45a67d;
//   text-align: right;
//   font-family: Inter;
//   font-size: 14px;
//   font-style: normal;
//   font-weight: 700;
//   line-height: 20px;
// `

export const GapResource = styled.div`
  color: #757575;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
`

export const VipWrapper = styled.article`
  display: flex;
  gap: 12px;
  @media (max-width: ${breakpoints.MOBILE_XL}px) {
    flex-direction: column;
  }
`
