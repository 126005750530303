import React, { useEffect, useState } from 'react'

import { Button, Flex, Modal, Spin } from 'antd'
import { UploadFile } from 'antd/es/upload/interface'
import { pick } from 'lodash'
import { useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'

import { useMutation, useQuery } from '@apollo/client'
import { ApolloError } from '@apollo/client/errors'
import { yupResolver } from '@hookform/resolvers/yup'

import { AppHeader } from '~/layout'
import { appRoutes } from '~/router'
import { Text } from '~/ui-components'
import { appToast } from '~/utils'
import { graphqlErrorHandler } from '~/utils/graphqlErrorHandler'
import { TransportDocumentType } from '~api/gql-generated/graphql'
import {
  addTransportDocumentFilesMutation,
  createTransportDocumentMutation,
  deleteTransportDocumentFileMutation,
  updateTransportMutation,
} from '~api/gql-mutations/car-park.mutation.graphql'
import { getTransportDocumentsQuery, getTransportQuery } from '~api/gql-query/car-park.query.graphql'
import { useUploadFilesAndGetIds } from '~hooks/_utils'
import { DocumentsFields } from '~pages/CarParkPage/components/DocumentsFields'

import { EditAdditionalFields, EditMainFields, EditOwnershipFields, EditTransportTypeFields } from './components'

import { EditTransportSchema, EditTransportValues } from './EditTransport.types'
import { TransportDocumentsFieldsProps } from '~pages/CarParkPage/modules/Transport/Transport.types'

import * as S from './EditTransport.styled'

export const EditTransport: React.FC = () => {
  const { id } = useParams<{ id: string | undefined }>()
  const transportId = Number(id)
  const navigate = useNavigate()
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false)
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false)
  const uploadFilesAndGetIdsFn = useUploadFilesAndGetIds()

  const getTransport = useQuery(getTransportQuery, {
    variables: {
      transportId,
    },
    fetchPolicy: 'cache-and-network',
  })

  const getTransportDocuments = useQuery(getTransportDocumentsQuery, {
    variables: {
      transportId,
      filter: {
        types: [
          TransportDocumentType.RegistrationCertificate,
          TransportDocumentType.TransportPassport,
          TransportDocumentType.LeasingContract,
          TransportDocumentType.Photo,
        ],
      },
    },
    fetchPolicy: 'cache-and-network',
  })

  const transport = getTransport.data?.transport
  const transportDocuments = getTransportDocuments.data?.transportDocuments

  const [transportDocumentsProps, setTransportDocumentsProps] = useState<TransportDocumentsFieldsProps>()

  useEffect(() => {
    if (transportDocuments) {
      setTransportDocumentsProps(
        transportDocuments?.reduce((acc, cur) => {
          acc[cur.type] = {
            type: cur.type,
            documentId: cur.instances.length ? cur.instances[0].id : undefined,
            uploadProps: {
              defaultFileList: cur.instances.length
                ? cur.instances[0].files.map((file) => ({
                    uid: file.id.toString(),
                    name: file.name,
                    url: file.source,
                  }))
                : [],
              onChange: ({ fileList }) => {
                setTransportDocumentsProps((prev) =>
                  prev
                    ? {
                        ...prev,
                        [cur.type]: { ...prev[cur.type], uploadProps: { ...prev[cur.type].uploadProps, fileList } },
                      }
                    : undefined
                )
              },
            },
          }
          return acc
        }, {} as TransportDocumentsFieldsProps)
      )
    }
  }, [transportDocuments])

  const [updateTransport, updateTransportState] = useMutation(updateTransportMutation, {
    refetchQueries: [getTransportQuery, getTransportDocumentsQuery],
  })
  const [createTransportDocument] = useMutation(createTransportDocumentMutation)
  const [addTransportDocumentFiles] = useMutation(addTransportDocumentFilesMutation)
  const [deleteTransportDocumentFile] = useMutation(deleteTransportDocumentFileMutation)

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
    trigger,
    watch,
  } = useForm<EditTransportValues>({
    resolver: yupResolver(EditTransportSchema),
  })

  useEffect(() => {
    !getTransport.loading &&
      !getTransportDocuments.loading &&
      reset({
        ...transport,
        bodyTypeId: transport?.bodyType?.id,
        preferredRouteType: transport?.preferredRouteType ? transport?.preferredRouteType : undefined,
        loadCapacityKg: transport?.loadCapacityKg ? transport.loadCapacityKg / 1000 : undefined,
        loadVolumeL: transport?.loadVolumeL ? transport.loadVolumeL / 1000 : undefined,
        lengthCm: transport?.lengthCm ? transport.lengthCm / 100 : undefined,
        widthCm: transport?.widthCm ? transport.widthCm / 100 : undefined,
        heightCm: transport?.heightCm ? transport.heightCm / 100 : undefined,
      })
  }, [getTransport.loading, getTransportDocuments.loading])

  const onUpdateTransport = async (formValues: EditTransportValues) => {
    setIsUpdateModalOpen(false)

    try {
      await attachTransportDocuments()
      await updateTransport({
        variables: {
          transportId,
          input: {
            brand: formValues.brand,
            bodyTypeId: formValues.bodyTypeId,
            manufacturingDate: formValues.manufacturingDate,
            vin: formValues.vin,
            registrationCertificate: formValues.registrationCertificate,
            transportPassport: formValues.transportPassport,
            licensePlate: formValues.licensePlate,
            axlesCount: formValues.axlesCount,
            loadCapacityKg: formValues.loadCapacityKg ? formValues.loadCapacityKg * 1000 : formValues.loadCapacityKg,
            loadVolumeL: formValues.loadVolumeL ? formValues.loadVolumeL * 1000 : formValues.loadVolumeL,
            lengthCm: formValues.lengthCm ? formValues.lengthCm * 100 : formValues.lengthCm,
            widthCm: formValues.widthCm ? formValues.widthCm * 100 : formValues.widthCm,
            heightCm: formValues.heightCm ? formValues.heightCm * 100 : formValues.heightCm,
            loadingMethodId: formValues.loadingMethodId,
            adrCertificate: formValues.adrCertificate,
            shortRouteKm: formValues.shortRouteKm,
            longRouteKm: formValues.longRouteKm,
            preferredRouteType: formValues.preferredRouteType,
            canWorkInField: formValues.canWorkInField,
            ownershipType: formValues.ownershipType,
            ownerName: formValues.ownerName,
            additionalDetails: formValues.additionalDetails,
          },
        },
      })
      appToast.success({ message: 'Транспорт изменен' })
      navigate(appRoutes.carParkTransportCard.url.replace(':id', transportId.toString()))
    } catch (e) {
      if (e instanceof ApolloError) {
        graphqlErrorHandler(e, 'Ошибка при изменении транспорта')
      }
    }
  }

  const getUploadedFileIds = async (fileList: UploadFile[]) => {
    const uploadFilesList = fileList.map((file) => file.originFileObj).filter((file) => file !== undefined)
    return uploadFilesList.length ? await uploadFilesAndGetIdsFn.mutateAsync(uploadFilesList as File[]) : []
  }

  const deleteTransportDocumentFiles = (documentId: number, files: UploadFile[]) => {
    return files.map((file) =>
      deleteTransportDocumentFile({
        variables: {
          documentFileId: +file.uid,
        },
      })
    )
  }

  const handleCreateTransportDocument = async (fileKeys: string[], type: TransportDocumentType) => {
    try {
      await createTransportDocument({
        variables: {
          input: {
            transportId,
            fileKeys,
            type,
          },
        },
      })
    } catch (e) {
      if (e instanceof ApolloError) {
        graphqlErrorHandler(e, 'Ошибка при создании документа транспорта')
      }
    }
  }

  const attachTransportDocuments = async () => {
    if (transportDocumentsProps) {
      return Promise.all(
        Object.values(transportDocumentsProps)
          .map(async (transportDocumentsPropsItem) => {
            const uploadedFileIds = await getUploadedFileIds(transportDocumentsPropsItem.uploadProps.fileList ?? [])
            const operations = []

            if (transportDocumentsPropsItem.documentId) {
              operations.push(
                deleteTransportDocumentFiles(
                  transportDocumentsPropsItem.documentId,
                  (transportDocumentsPropsItem.uploadProps.fileList &&
                    transportDocumentsPropsItem.uploadProps.defaultFileList?.filter(
                      (defaultFile) =>
                        !transportDocumentsPropsItem.uploadProps.fileList?.some((file) => defaultFile.uid === file.uid)
                    )) ??
                    []
                )
              )

              uploadedFileIds.length &&
                operations.push(
                  addTransportDocumentFiles({
                    variables: {
                      documentId: transportDocumentsPropsItem.documentId,
                      input: {
                        fileKeys: uploadedFileIds,
                      },
                    },
                  })
                )
            } else {
              uploadedFileIds.length &&
                operations.push(handleCreateTransportDocument(uploadedFileIds, transportDocumentsPropsItem.type))
            }

            return operations
          })
          .flat()
      )
    }
  }

  const checkValidForm = async () => {
    const isValid = await trigger()
    if (isValid) {
      setIsUpdateModalOpen(true)
    }
  }

  const onCancel = async () => {
    setIsCancelModalOpen(true)
  }

  const watchType = watch('type')
  const watchOwnershipType = watch('ownershipType')

  const documentsProps = pick(transportDocumentsProps, [
    TransportDocumentType.RegistrationCertificate,
    TransportDocumentType.TransportPassport,
    TransportDocumentType.LeasingContract,
  ]) as TransportDocumentsFieldsProps
  const photosProps = pick(transportDocumentsProps, [TransportDocumentType.Photo]) as TransportDocumentsFieldsProps

  return (
    <Spin spinning={getTransport.loading || updateTransportState.loading || getTransportDocuments.loading}>
      <AppHeader title={appRoutes.carParkEditTransport.title} isBack onBack={onCancel} />

      <S.Wrapper>
        <form>
          <Flex vertical gap={32}>
            {transport && (
              <S.FlexItem vertical gap={32}>
                <EditTransportTypeFields value={transport.type} />
              </S.FlexItem>
            )}
            <S.FlexItem vertical gap={32}>
              <EditMainFields control={control} errors={errors} type={watchType} transportPhotosProps={photosProps} />
            </S.FlexItem>
            <S.FlexItem vertical gap={8}>
              <EditOwnershipFields control={control} errors={errors} ownershipType={watchOwnershipType} />
            </S.FlexItem>
            {documentsProps && (
              <S.FlexItem vertical gap={32}>
                <DocumentsFields {...documentsProps} />
              </S.FlexItem>
            )}
            <Flex vertical gap={32}>
              <EditAdditionalFields control={control} errors={errors} />
            </Flex>
            <Flex justify="flex-end" gap={8}>
              <S.StyledButton type="default" htmlType="button" onClick={onCancel}>
                Отмена
              </S.StyledButton>
              <Button type="primary" htmlType="button" onClick={checkValidForm}>
                Сохранить изменения
              </Button>
            </Flex>
          </Flex>
        </form>
      </S.Wrapper>

      <Modal
        open={isCancelModalOpen}
        onCancel={() => setIsCancelModalOpen(false)}
        width={400}
        footer={null}
        title="😞 Данные не сохранятся"
      >
        <Flex vertical gap={32}>
          <Text variant="label">
            Нажимая “Продолжить”, мы не сможем сохранить введенные вами данные. Уверены, что хотите продолжить?
          </Text>

          <Flex justify="flex-end" gap={8}>
            <S.StyledButton type="default" onClick={() => setIsCancelModalOpen(false)}>
              Отмена
            </S.StyledButton>
            <Button type="primary" htmlType="button" onClick={() => navigate(-1)}>
              Продолжить
            </Button>
          </Flex>
        </Flex>
      </Modal>

      <Modal
        open={isUpdateModalOpen}
        onCancel={() => setIsUpdateModalOpen(false)}
        width={500}
        footer={null}
        title="🚛 Проверьте, поля заполнены верно?"
      >
        <Flex vertical gap={32}>
          <Text variant="label">
            С данным авто можно будет составлять реестр авто, поэтому важно, чтобы все поля были заполнены правильно.
          </Text>

          <Text variant="label">Вы точно хотите сохранить данные и закончить создание транспорта?</Text>

          <Flex justify="flex-end" gap={8}>
            <S.StyledButton type="default" onClick={() => setIsUpdateModalOpen(false)}>
              Пойду перепроверю
            </S.StyledButton>
            <Button type="primary" htmlType="button" onClick={handleSubmit(onUpdateTransport)}>
              Да, все верно
            </Button>
          </Flex>
        </Flex>
      </Modal>
    </Spin>
  )
}
