import styled from 'styled-components'

import { Divider, Text } from '~/ui-components'

export const InfoText = styled(Text).attrs({ variant: 'description' })`
  margin-top: 4px;
  color: var(--gray-5-color);
`
export const DividerStyled = styled(Divider)`
  margin-bottom: 24px;
`
