import React from 'react'

import { Spin } from 'antd'

import { getNoun } from '~/utils/getNoun'
import { ReactComponent as BasisIcon } from '~assets/icons/basis.svg'
import { useGetElevators } from '~hooks/elevators'

import * as S from './TotalBasisStatistics.styled'

export const TotalBasisStatistics: React.FC = () => {
  const getElevatorsFn = useGetElevators({ currentPage: 1, pageSize: 1 })

  const totalBasisCount = getElevatorsFn?.data?.pagination?.total

  return (
    <Spin spinning={getElevatorsFn.isLoading}>
      <S.ContentWrapper>
        <S.Title>
          <BasisIcon style={{ height: 18, marginBottom: -2, marginRight: 4 }} />
          На платформе {totalBasisCount} {getNoun(totalBasisCount ?? 0, 'базис', 'базиса', 'базисов')}
        </S.Title>
      </S.ContentWrapper>
    </Spin>
  )
}
