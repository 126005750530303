import styled from 'styled-components'

import { css } from '@styled-system/css'

import { Box } from '~/ui-components'

export const ContentWrapper = styled(Box)(
  css({
    padding: ['8px', '8px', '32px'],
  })
)
