import React, { FC } from 'react'

import { Button, Modal } from 'antd'

import { modalSharedSettings } from '~/utils'

import { ModalConfirmProps } from './ModalConfirm.types'

import * as S from './ModalConfirm.styled'

export const ModalConfirm: FC<ModalConfirmProps> = ({
  title,
  visible,
  okText = 'Ok',
  cancelText = 'Отмена',
  isShowCancel = true,
  onOk,
  onCancel,
  children,
}) => {
  return (
    <Modal
      {...modalSharedSettings}
      open={visible}
      onCancel={onCancel}
      title={title}
      footer={[
        <Button key="cancel" htmlType="button" onClick={() => onCancel?.()}>
          {cancelText}
        </Button>,
        <Button key="submit" htmlType="button" type="primary" onClick={onOk}>
          {okText}
        </Button>,
      ]}
    >
      <S.Content>{children}</S.Content>
    </Modal>
  )
}
