import React from 'react'

import { Empty, Skeleton, Spin } from 'antd'
import { useParams } from 'react-router'

import { appToast } from '~/utils'
import { getDealSide } from '~/utils/deals/get-deal-side'
import { BaseDealAdditionalServiceDataType } from '~api/generated'
import { useGetDealById, useUpdateDealAdditionalService, useUpdateDealBankAccount } from '~hooks/deals'
import { DealAdditionalServicesBuyer, DealSteps, DocumentsProduct } from '~pages/Deals/components'
import { DealResponseInfo } from '~pages/Deals/modules/DealInformation/components/DealResponseInfo'
import { BankInfoView } from '~pages/ProfilePage/components/tabs/CompanyProfileTab/components/BankInfoView'
import { useAuth } from '~providers/auth'

import { BankInfo, Counterpart, DealInformationDocument, DealOfferInfo, SpecificationCard } from './components'

import * as S from './DealInformation.styled'

export const DealInformation = () => {
  const { companyId } = useAuth()
  const params = useParams<{ id: string | undefined }>()
  const dealId = Number(params.id)
  const dealFn = useGetDealById(dealId)
  const updateDealBankAccountFn = useUpdateDealBankAccount()
  const updateDealAdditionalServiceFn = useUpdateDealAdditionalService()

  const onUpdateAdditionalServicesHandler = async (value: BaseDealAdditionalServiceDataType[]) => {
    if (!value.length) {
      return
    }

    try {
      await updateDealAdditionalServiceFn.mutateAsync({
        dealId,
        updateAdditionalServicesBody: {
          additionalServices: value.map((x) => ({
            type: x.type,
            isSelected: x.isSelected,
          })),
        },
      })
      appToast.success({ description: 'Доп. услуги по сделке обновлены' })
      await dealFn.refetch()
    } catch (e) {
      const description = 'Ошибка при обновлении Доп. услуги по сделке'
      console.error(description, e)
      appToast.error({ description })
    }
  }
  const onUpdateBankAccountHandler = async (bankAccountId: number) => {
    if (!dealId) {
      return
    }
    try {
      await updateDealBankAccountFn.mutateAsync({
        dealId,
        updateDealCompanyBankAccountBody: {
          bankAccountId,
        },
      })
      appToast.success({ description: 'Банковские реквизиты сделки обновлены' })
      await dealFn.refetch()
    } catch (e) {
      const description = 'Ошибка при обновлении банковских реквизитов сделки'
      console.error(description, e)
      appToast.error({ description })
    }
  }

  if (!companyId) {
    return <Empty description="Сначала заполните данные о компании" />
  }

  if (!dealFn.data || dealFn.isLoading) {
    return <Skeleton />
  }

  const deal = dealFn.data
  const dealSide = getDealSide({ userCompanyId: companyId, buyerId: deal.buyer.id, sellerId: deal.seller.id })
  const specification = dealSide === 'seller' ? deal.dealWithSeller?.specification : deal.dealWithBuyer?.specification
  const bankAccount = dealSide === 'seller' ? deal.dealWithSeller?.bankAccount : deal.dealWithBuyer?.bankAccount
  const hasSpecification = !!specification

  return (
    <Spin spinning={dealFn.isLoading || dealFn.isFetching}>
      <S.Content>
        <DealSteps status={deal.dealWithSeller?.status ?? deal.dealWithBuyer?.status ?? null} />
        <S.ColumnWrapper>
          <S.Column>
            {hasSpecification ? (
              <SpecificationCard dealSide={dealSide} dealId={dealId} specification={specification} />
            ) : (
              <DealOfferInfo offer={deal.offer} />
            )}

            <Counterpart
              company={dealSide === 'seller' ? deal.seller : dealSide === 'buyer' ? deal.buyer : null}
              user={null}
            />
          </S.Column>

          <S.Column>
            {dealSide === 'buyer' && (
              <DealAdditionalServicesBuyer
                value={deal.dealWithBuyer?.additionalServices ?? []}
                onSubmit={onUpdateAdditionalServicesHandler}
              />
            )}

            {!hasSpecification && !bankAccount && (
              <BankInfo companyId={companyId} dealSide={dealSide} onUpdateBankAccount={onUpdateBankAccountHandler} />
            )}

            {bankAccount && <BankInfoView data={bankAccount} />}

            {dealId && bankAccount && <DealInformationDocument dealId={dealId} dealSide={dealSide} />}

            {dealId && (
              <DocumentsProduct dealId={dealId} dealSide={dealSide} documentType="product" title="Документы на товар" />
            )}
          </S.Column>
        </S.ColumnWrapper>
      </S.Content>
    </Spin>
  )
}
