import React, { PropsWithChildren, useEffect } from 'react'

import { Spin } from 'antd'
import { observer } from 'mobx-react-lite'

import { useQuery } from '@apollo/client'

import { useGetRegions } from '~/hooks'
import { getMeCompaniesQuery } from '~api/gql-query/me.query.graphql'
import { useGetUserQuery } from '~hooks/auth'
import { useGetCompanyById } from '~hooks/company'
import { useGetProductTypes } from '~hooks/products/useGetProductTypes'
import { useGetUserAvatarUrl } from '~hooks/user/useGetUserAvatarUrl'
import { useAuth } from '~providers/auth'

export const PrecacheData: React.FC<PropsWithChildren> = observer(({ children }) => {
  const { authenticated, userId, companyId } = useAuth()

  useEffect(() => {
    const timer = setTimeout(() => {
      localStorage.setItem('isErrorModalOpen', 'false')
    }, 10000)
    return () => clearTimeout(timer)
  }, [])

  const getCompanies = useQuery(getMeCompaniesQuery, { skip: !authenticated })

  const productTypes = useGetProductTypes({ enabled: authenticated })
  const regions = useGetRegions({ enabled: authenticated })
  const userAvatarUrl = useGetUserAvatarUrl(userId, { enabled: authenticated })
  const companyData = useGetCompanyById(companyId)

  const userData = useGetUserQuery({
    options: {
      enabled: !!userId,
    },
  })

  useEffect(() => {
    if (!authenticated) {
      void getCompanies.client.resetStore()
      return
    }
  }, [authenticated])

  if (
    authenticated &&
    (getCompanies.loading ||
      productTypes.isInitialLoading ||
      userAvatarUrl.isInitialLoading ||
      userData.isInitialLoading ||
      companyData.isInitialLoading ||
      regions.isInitialLoading)
  ) {
    return (
      <div style={{ width: '100%', height: '100%' }}>
        <Spin spinning={true} size="large" style={{ position: 'relative', top: '50%', left: '50%' }} />
      </div>
    )
  }

  return <>{children}</>
})
