/* tslint:disable */
/* eslint-disable */
/**
 * API Зерно-Агрегатора
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { BaseElevatorEntity } from './base-elevator-entity';
import { ElevatorAdvertOfferAdvertData } from './elevator-advert-offer-advert-data';
import { ElevatorAdvertOfferCompanyData } from './elevator-advert-offer-company-data';
import { ElevatorAdvertOfferModeratorData } from './elevator-advert-offer-moderator-data';
import { ElevatorOfferResponseStatus } from './elevator-offer-response-status';
import { FileData } from './file-data';
import { ProductData } from './product-data';

/**
 * 
 * @export
 * @interface AdminElevatorAdvertOfferData
 */
export interface AdminElevatorAdvertOfferData {
    /**
     * Id отклика на объявление
     * @type {number}
     * @memberof AdminElevatorAdvertOfferData
     */
    'id': number;
    /**
     * Тип объявления
     * @type {string}
     * @memberof AdminElevatorAdvertOfferData
     */
    'elevatorOfferType': AdminElevatorAdvertOfferDataElevatorOfferTypeEnum;
    /**
     * 
     * @type {ElevatorOfferResponseStatus}
     * @memberof AdminElevatorAdvertOfferData
     */
    'status': ElevatorOfferResponseStatus;
    /**
     * Объём товара
     * @type {number}
     * @memberof AdminElevatorAdvertOfferData
     */
    'volume': number;
    /**
     * Объявление
     * @type {ElevatorAdvertOfferAdvertData}
     * @memberof AdminElevatorAdvertOfferData
     */
    'elevatorAdvert': ElevatorAdvertOfferAdvertData;
    /**
     * Цена за единицу объёма
     * @type {number}
     * @memberof AdminElevatorAdvertOfferData
     */
    'price': number;
    /**
     * Учтён ли НДС в цене
     * @type {boolean}
     * @memberof AdminElevatorAdvertOfferData
     */
    'includingNds': boolean;
    /**
     * Товар
     * @type {ProductData}
     * @memberof AdminElevatorAdvertOfferData
     */
    'product': ProductData;
    /**
     * Информация о модераторе
     * @type {ElevatorAdvertOfferModeratorData}
     * @memberof AdminElevatorAdvertOfferData
     */
    'moderator': ElevatorAdvertOfferModeratorData | null;
    /**
     * Компания, разместившая отклик
     * @type {ElevatorAdvertOfferCompanyData}
     * @memberof AdminElevatorAdvertOfferData
     */
    'company': ElevatorAdvertOfferCompanyData;
    /**
     * Лабораторные анализы
     * @type {FileData}
     * @memberof AdminElevatorAdvertOfferData
     */
    'laboratoryAnalysis': FileData | null;
    /**
     * Дата Публикации
     * @type {string}
     * @memberof AdminElevatorAdvertOfferData
     */
    'publishedAt': string | null;
    /**
     * Тип отклика
     * @type {string}
     * @memberof AdminElevatorAdvertOfferData
     */
    'type': AdminElevatorAdvertOfferDataTypeEnum;
    /**
     * Элеватор
     * @type {BaseElevatorEntity}
     * @memberof AdminElevatorAdvertOfferData
     */
    'elevator': BaseElevatorEntity;
    /**
     * Статус откуплено компании покупателя
     * @type {boolean}
     * @memberof AdminElevatorAdvertOfferData
     */
    'buyerPurchaseStatus': boolean | null;
    /**
     * Статус откуплено компании продавца
     * @type {boolean}
     * @memberof AdminElevatorAdvertOfferData
     */
    'sellerPurchaseStatus': boolean | null;
}

export const AdminElevatorAdvertOfferDataElevatorOfferTypeEnum = {
    Buy: 'BUY',
    Sell: 'SELL'
} as const;

export type AdminElevatorAdvertOfferDataElevatorOfferTypeEnum = typeof AdminElevatorAdvertOfferDataElevatorOfferTypeEnum[keyof typeof AdminElevatorAdvertOfferDataElevatorOfferTypeEnum];
export const AdminElevatorAdvertOfferDataTypeEnum = {
    Buy: 'BUY',
    Sell: 'SELL'
} as const;

export type AdminElevatorAdvertOfferDataTypeEnum = typeof AdminElevatorAdvertOfferDataTypeEnum[keyof typeof AdminElevatorAdvertOfferDataTypeEnum];


