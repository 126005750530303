import React from 'react'

import { Tour, TourProps } from 'antd'
import { Link } from 'react-router-dom'

import { useMutation, useQuery } from '@apollo/client'

import { appRoutes } from '~/router'
import { UserStateFieldName } from '~api/gql-generated/graphql'
import { updateMeStateMutation } from '~api/gql-mutations/me.mutation.graphql'
import { getMeState } from '~api/gql-query/me.query.graphql'
import { useGetDevice } from '~hooks/common'
import { CompanyEmployeeAddRightMenu } from '~pages/AccountPage/modules/CompanyEmployeePage/components/CompanyEmployee/components/CompanyEmployeeAddRightMenu/CompanyEmployeeAddRightMenu'
import { ResponsePageHeader } from '~shared/components'

import * as S from './CompanyEmployee.styled'

interface CompanyEmployeeProps {
  canUserCreateNewUser: boolean
  canUserTransferUser: boolean
  onUserCreate: () => void
  onUserTransfer: () => void
}

export const CompanyEmployeeHeader: React.FC<CompanyEmployeeProps> = (props) => {
  const device = useGetDevice()
  const isMobile = device === 'mobile'
  const getMeStateFn = useQuery(getMeState)
  const [updateMeState] = useMutation(updateMeStateMutation, {
    refetchQueries: [getMeState],
  })

  const isOpenTour = getMeStateFn?.data?.me.state.isShowEmployeesGuide ?? false

  const steps: TourProps['steps'] = [
    {
      title: 'Добавление сотрудников',
      description:
        'Чтобы ваши сотрудники тоже могли пользоваться платформой, добавьте их через кнопку "Добавить сотрудника".',
      target: null,
    },
  ]

  const onCloseTour = async () => {
    await updateMeState({
      variables: {
        input: {
          fieldName: UserStateFieldName.IsShowEmployeesGuide,
          value: String(false),
        },
      },
    })
  }

  return (
    <S.Wrapper>
      <Tour
        open={isOpenTour && !isMobile}
        onClose={onCloseTour}
        steps={steps}
        indicatorsRender={(current, total) => (
          <span>
            {current + 1} / {total}
          </span>
        )}
      />

      <ResponsePageHeader
        variant="view"
        title="Сотрудники"
        subTitle="МОЯ КОМПАНИЯ"
        rightElement={
          <CompanyEmployeeAddRightMenu
            onCreate={props.onUserCreate}
            onAddFromAnotherCompany={props.onUserTransfer}
            canUserCreateNewUser={props.canUserCreateNewUser}
            canUserTransferUser={props.canUserTransferUser}
          />
        }
      />

      <S.Description>
        Вы можете добавить сотрудников вашей компании и разделить их по отделам. Посмотреть сотрудников из всех ваших
        компаний можно в верхнем правом меню во вкладке <Link to={appRoutes.employees.url}>Все сотрудники</Link>.
      </S.Description>
    </S.Wrapper>
  )
}
