import React from 'react'

import { Button, Flex, Input } from 'antd'
import { Controller, useForm } from 'react-hook-form'

import { yupResolver } from '@hookform/resolvers/yup'

import { ResponseFormProps, ResponseFormValues } from './ResponseForm.types'
import { ResponseFormSchema } from './ResponseForm.validation'
import { FormField } from '~/ui-components'

export const ResponseForm: React.FC<ResponseFormProps> = ({ onBack, onSubmit, isAccept = false }) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<ResponseFormValues>({
    resolver: yupResolver(ResponseFormSchema),
    context: { isAccept },
  })

  const onSubmitHandler = (data: ResponseFormValues) => {
    onSubmit(data)
  }

  return (
    <Flex vertical gap={24}>
      {!isAccept && (
        <FormField
          label="Укажите причину"
          htmlFor="rejectionReason"
          validateMessage={errors.rejectionReason?.message}
          isRequired
          grow={false}
        >
          <Controller
            name="rejectionReason"
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                id="rejectionReason"
                status={errors.rejectionReason && 'error'}
                placeholder="Введите текст"
              />
            )}
          />
        </FormField>
      )}

      <Flex gap={16} justify="flex-end">
        <Button htmlType="button" onClick={onBack}>
          Отменить
        </Button>

        <Button htmlType="button" type="primary" onClick={handleSubmit(onSubmitHandler)}>
          Отправить
        </Button>
      </Flex>
    </Flex>
  )
}
