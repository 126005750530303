import React from 'react'

import { Table } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import { Link } from 'react-router-dom'

import { useGetTableHeight } from '~/hooks'
import { appRoutes } from '~/router'
import { formatPhoneNumber } from '~/utils/formatPhoneNumber'
import { UserApiUserControllerGetUsersRequest, UserDataStatusEnum } from '~api/generated'
import { actionArrowColumn } from '~shared/columns/shared'

import { DeactivateUserToggle } from '../DeactivateUserToggle'

import { IModeratorUserListTableProps, UserListTableDataType } from './ModeratorUserListTable.types'
import { IOfferSortType } from '~pages/ModeratorPage/components/ModeratorOfferList/components/ModeratorOfferListTable/ModeratorOfferListTable.types'

export const ModeratorUserListTable: React.FC<IModeratorUserListTableProps> = ({
  tableData,
  loading,
  onRowClick,
  onDeactivateUser,
  onSortingChange,
}) => {
  const tableHeight = useGetTableHeight(294)

  const onRowClickHandler = (record: UserListTableDataType) => {
    onRowClick(record)
  }

  const columns: ColumnsType<UserListTableDataType> = [
    {
      title: 'Статус',
      dataIndex: 'userStatus',
      key: 'status',
      render: (value: UserDataStatusEnum, column) => (
        <DeactivateUserToggle
          status={value}
          onChangeUserStatus={(status) => onDeactivateUser(column.id, status)}
          userName={column.name !== '-' ? column.name : column.id.toString()}
        />
      ),
      width: 80,
      sorter: true,
    },
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      width: 80,
      sorter: true,
    },
    {
      title: 'Имя',
      dataIndex: 'name',
      key: 'name',
      width: 300,
      sorter: true,
    },
    {
      title: 'Телефон',
      dataIndex: 'phoneNumber',
      key: 'phoneNumber',
      render: (value) => formatPhoneNumber(value),
      width: 180,
    },
    {
      title: 'Компания',
      dataIndex: 'companyName',
      key: 'companyId',
      width: 300,
      render: (value, row) => {
        return (
          <Link
            onClick={(e) => e.stopPropagation()}
            to={appRoutes.moderatorCompanies.url + '/' + row.companyId}
            target="_blank"
          >
            {value}
          </Link>
        )
      },
      sorter: true,
    },
    {
      title: 'Создал пользователя',
      dataIndex: 'createdBy',
      key: 'createdBy',
      ellipsis: true,
      width: 160,
    },
    {
      title: 'Должность',
      dataIndex: 'role',
      key: 'role',
      ellipsis: true,
      width: 160,
      sorter: true,
    },
    {
      title: 'Дата регистрации',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: '160px',
      sorter: true,
    },
    {
      title: 'Последняя авторизация',
      dataIndex: 'authenticatedAt',
      key: 'authenticatedAt',
      width: 200,
    },
    actionArrowColumn,
  ]

  return (
    <Table
      className="table-interactive"
      rowKey="id"
      loading={loading}
      columns={columns}
      dataSource={tableData}
      scroll={{ y: tableHeight, x: 'max-content' }}
      pagination={false}
      onRow={(record, index) => ({
        onClick: onRowClickHandler.bind(null, record, index),
      })}
      onChange={(pagination, filters, sorter) => {
        if (Array.isArray(sorter)) {
          return
        }
        const { column, order } = sorter
        onSortingChange({
          orderBy: column?.key as UserApiUserControllerGetUsersRequest['orderBy'],
          order: order === 'ascend' ? 'asc' : order === 'descend' ? 'desc' : undefined,
        })
      }}
    />
  )
}
