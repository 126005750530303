import { UnloadingTransportType } from '~api/generated'

export function getUnloadType(loadType?: UnloadingTransportType | null): string {
  if (!loadType?.length) {
    return '-'
  }
  switch (loadType) {
    case UnloadingTransportType.TailgateAutoUnloader: {
      return 'Авторазгрузчик (Задний)'
    }
    case UnloadingTransportType.SideBoardAutoUnloader: {
      return 'Авторазгрузчик (Боковой)'
    }
    case UnloadingTransportType.Manipulator: {
      return 'Манипулятор'
    }
    case UnloadingTransportType.DumpTruck: {
      return 'Самосвал'
    }
    case UnloadingTransportType.MechanicalShovel: {
      return 'Ручной (мехлопаты)'
    }
    case UnloadingTransportType.PneumaticUnloader: {
      return 'Пневморазгрузчик'
    }
    case UnloadingTransportType.Custom: {
      return 'Другое'
    }
    case UnloadingTransportType.ByContractorChoice: {
      return 'Самостоятельно'
    }
    default: {
      return ''
    }
  }
}
