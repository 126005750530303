import React from 'react'

import { Flex } from 'antd'
import { BsArrowRightCircleFill } from 'react-icons/bs'
import { useNavigate } from 'react-router-dom'

import { Text } from '~/ui-components'
import { formatNumber } from '~/utils/formatNumber'
import { ElevatorAdvertListData } from '~api/generated'
import { useGetElevatorAdverts } from '~hooks/elevator-advert'
import { useAuth } from '~providers/auth'

import * as S from './BasisVipItem.styled'

interface BasisVipItemProps {
  basisId: number
  basisName: string
  basisAddress: string
}

export const BasisVipItem: React.FC<BasisVipItemProps> = (props) => {
  const navigate = useNavigate()
  const { companyId } = useAuth()

  const getElevatorAdvertsFn = useGetElevatorAdverts({
    elevatorId: props.basisId,
    userCompanyId: companyId,
    pageSize: 3,
    type: 'BUY',
  })

  const onShowAllAdverts = () => {
    navigate(`/basis/basis-list/${props.basisId}/adverts`)
  }
  function renderAdvertItem(advert: ElevatorAdvertListData) {
    return (
      <S.BasisAdvertsListItem>
        <S.ProductInfo to={`/basis/advert/${advert.id}`}>
          <BsArrowRightCircleFill size={24} />
          {advert.product.name}
        </S.ProductInfo>
        <S.Price>
          {formatNumber(advert.pricePerTon)}₽ {advert.volume ? <S.Green>{advert.volume} т</S.Green> : null}
          <span style={{ fontWeight: 300 }}>{advert.usingNds ? 'С НДС' : 'Без НДС'}</span>
        </S.Price>
        <S.Company>{advert.company?.name}</S.Company>
      </S.BasisAdvertsListItem>
    )
  }
  return (
    <S.Wrapper>
      <S.BasisInfoWrapper>
        <Text variant="normal">Базис</Text>
        <S.BasisInfo>
          <Text variant="h3">
            <S.BasisNameLink to={`/basis/basis-list/${props.basisId}`}>{props.basisName}</S.BasisNameLink>
            <S.Green></S.Green>
          </Text>

          <Text variant="description">{props.basisAddress}</Text>
        </S.BasisInfo>
      </S.BasisInfoWrapper>

      <S.BasisAdvertsWrapper>
        <Flex justify="space-between" align="center">
          <Flex gap={8}>
            <Text variant="h3">Объявлений на базисе</Text>
            <S.BasisAdvertCounter>{getElevatorAdvertsFn.data?.pagination.total}</S.BasisAdvertCounter>
          </Flex>
          <S.ShowAllButtonDesktop type="primary" onClick={onShowAllAdverts}>
            Показать все
          </S.ShowAllButtonDesktop>
        </Flex>

        <S.BasisAdvertsList>{getElevatorAdvertsFn.data?.elevatorAdverts.map(renderAdvertItem)}</S.BasisAdvertsList>

        <S.ShowAllButtonMobile type="primary" onClick={onShowAllAdverts}>
          Показать все
        </S.ShowAllButtonMobile>
      </S.BasisAdvertsWrapper>
    </S.Wrapper>
  )
}
