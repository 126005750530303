import React, { useEffect } from 'react'

import { Button, Flex, Input, Skeleton } from 'antd'
import { Controller, useForm } from 'react-hook-form'
import { useParams } from 'react-router'
import { useNavigate } from 'react-router-dom'

import { useMutation, useQuery } from '@apollo/client'
import { ApolloError } from '@apollo/client/errors'
import { yupResolver } from '@hookform/resolvers/yup'

import { AppHeader } from '~/layout'
import { appRoutes } from '~/router'
import { DatePicker, FormField } from '~/ui-components'
import { appToast } from '~/utils'
import { graphqlErrorHandler } from '~/utils/graphqlErrorHandler'
import { driverUpdateMutation, driverUserCreateMutation } from '~api/gql-mutations/drivers.mutation.graphql'
import { getDriverQuery, getDriversListQuery } from '~api/gql-query/drivers.query.graphql'
import { AvatarUploader } from '~shared/components'
import { PhoneNumberControl, UploadDocumentsControl } from '~shared/controls'

import { AddDriverFormValues } from './CreateDriver.interface'

import { addDriverFormSchema } from './CreateDriver.validation'

import * as S from './CreateDriver.styled'

export function CreateDriver() {
  const params = useParams<{ id: string | undefined }>()
  const editDriverId = params?.id ? Number(params.id) : null

  const isEdit = Boolean(editDriverId)

  const navigate = useNavigate()

  const [driverCreate, driverCreateState] = useMutation(driverUserCreateMutation)
  const [driverUpdate, driverUpdateState] = useMutation(driverUpdateMutation)

  const getDriverFn = useQuery(getDriverQuery, {
    skip: !isEdit,
    variables: {
      driverId: editDriverId!,
    },
  })

  const editableDriver = getDriverFn.data?.driver ?? null

  const navigateBack = () => navigate(appRoutes.carParkDrivers.url)

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<AddDriverFormValues>({
    defaultValues: {
      name: editableDriver?.name,
      phoneNumber: editableDriver?.phoneNumber,
      birthDate: editableDriver?.driverData?.birthDate ? new Date(editableDriver?.driverData.birthDate) : undefined,
      licenseNumber: editableDriver?.driverData?.licenseNumber ?? undefined,
      licenseIssuedAt: editableDriver?.driverData?.licenseIssuedAt,
      licenseExpiredAt: editableDriver?.driverData?.licenseExpiredAt,
      additionalDetails: editableDriver?.driverData?.additionalDetails ?? undefined,
    },
    resolver: yupResolver(addDriverFormSchema),
  })

  useEffect(() => {
    if (!editableDriver) {
      return
    }

    reset({
      name: editableDriver.name,
      phoneNumber: editableDriver.phoneNumber,
      birthDate: editableDriver.driverData?.birthDate ? new Date(editableDriver.driverData.birthDate) : undefined,
      licenseNumber: editableDriver.driverData?.licenseNumber ?? undefined,
      licenseIssuedAt: editableDriver.driverData?.licenseIssuedAt
        ? new Date(editableDriver.driverData?.licenseIssuedAt)
        : undefined,
      licenseExpiredAt: editableDriver.driverData?.licenseExpiredAt
        ? new Date(editableDriver.driverData?.licenseExpiredAt)
        : undefined,
      additionalDetails: editableDriver.driverData?.additionalDetails ?? undefined,
    })
  }, [editableDriver?.id])

  const handleUploadPhoto = async (file: File) => {
    console.log('handleUploadPhoto', file)
  }
  const handleDeletePhoto = async () => {
    console.log('handleDeletePhoto')
  }

  const onSubmitHandler = async (data: AddDriverFormValues) => {
    if (isEdit && editDriverId) {
      try {
        await driverUpdate({
          variables: {
            driverId: editDriverId,
            input: {
              name: data.name,
              phoneNumber: data.phoneNumber,
              birthDate: data.birthDate?.toDateString(),
              licenseNumber: data.licenseNumber,
              licenseIssuedAt: data.licenseIssuedAt,
              licenseExpiredAt: data.licenseExpiredAt,
              additionalDetails: data.additionalDetails,
            },
          },
          refetchQueries: [getDriverQuery, getDriversListQuery],
        })
        appToast.success({ message: 'Данные о водителе изменены' })
        navigateBack()
      } catch (e) {
        e instanceof ApolloError && graphqlErrorHandler(e, 'Произошла ошибка при редактировании водителя')
      }
      return
    }

    try {
      await driverCreate({
        variables: {
          input: {
            name: data.name,
            phoneNumber: data.phoneNumber,
            birthDate: data.birthDate?.toDateString(),
            licenseNumber: data.licenseNumber,
            licenseIssuedAt: data.licenseIssuedAt,
            licenseExpiredAt: data.licenseExpiredAt,
            additionalDetails: data.additionalDetails,
          },
        },
      })
      appToast.success({ message: 'Водитель добавлен' })
      navigateBack()
    } catch (e) {
      e instanceof ApolloError && graphqlErrorHandler(e, 'Произошла ошибка при добавлении водителя')
    }
  }

  if (isEdit && getDriverFn.loading) {
    return (
      <Flex vertical>
        <AppHeader
          title={isEdit ? appRoutes.carParkEditDriver.title : appRoutes.carParkCreateDriver.title}
          isBack
          onBack={navigateBack}
        />

        <Skeleton />
      </Flex>
    )
  }

  return (
    <Flex vertical>
      <AppHeader
        title={isEdit ? appRoutes.carParkEditDriver.title : appRoutes.carParkCreateDriver.title}
        isBack
        onBack={navigateBack}
      />

      <S.ContentWrapper>
        <S.FormWrapper onSubmit={handleSubmit(onSubmitHandler)}>
          <AvatarUploader
            avatarUrl={undefined}
            onUpload={handleUploadPhoto}
            onDeleteAvatar={handleDeletePhoto}
            uploadText="Добавить фото"
          />

          <FormField label="ФИО" isRequired htmlFor="name" validateMessage={errors.name?.message}>
            <Controller
              name="name"
              control={control}
              render={({ field }) => (
                <Input {...field} id="name" status={errors.name && 'error'} placeholder="Введите ФИО" />
              )}
            />
          </FormField>

          <FormField
            htmlFor="phoneNumber"
            validateMessage={errors.phoneNumber?.message}
            label="Номер телефона"
            isRequired
          >
            <Controller
              name="phoneNumber"
              control={control}
              render={({ field }) => (
                <PhoneNumberControl {...field} autoFocus id="phoneNumber" hasError={!!errors.phoneNumber} />
              )}
            />
          </FormField>

          <FormField label="Дата рождения" validateMessage={errors.birthDate?.message}>
            <Controller control={control} name="birthDate" render={({ field }) => <DatePicker {...field} />} />
          </FormField>

          <FormField label="Скан вод-го удостоверения" isRequired validateMessage={errors.driverLicense?.message}>
            <Controller
              control={control}
              name="driverLicense"
              render={({ field }) => <UploadDocumentsControl {...field} />}
            />
          </FormField>

          <FormField
            label="Серия и номер вод-го удостоверения"
            htmlFor="licenseNumber"
            validateMessage={errors.licenseNumber?.message}
          >
            <Controller
              name="licenseNumber"
              control={control}
              render={({ field }) => (
                <Input {...field} id="licenseNumber" status={errors.licenseNumber && 'error'} placeholder="/" />
              )}
            />
          </FormField>

          <Flex justify="space-between" gap={24}>
            <FormField label="Дата выдачи" validateMessage={errors.licenseIssuedAt?.message}>
              <Controller control={control} name="licenseIssuedAt" render={({ field }) => <DatePicker {...field} />} />
            </FormField>
            <FormField label="Действителен до" validateMessage={errors.licenseExpiredAt?.message}>
              <Controller control={control} name="licenseExpiredAt" render={({ field }) => <DatePicker {...field} />} />
            </FormField>
          </Flex>

          <FormField
            htmlFor="additionalDetails"
            label="Комментарий"
            validateMessage={errors.additionalDetails?.message}
          >
            <Controller
              name="additionalDetails"
              control={control}
              render={({ field }) => (
                <Input.TextArea
                  id="additionalDetails"
                  rows={2}
                  placeholder="Введите"
                  status={errors.additionalDetails && 'error'}
                  {...field}
                  value={field.value ?? ''}
                />
              )}
            />
          </FormField>

          <Flex gap={16} justify="flex-end">
            <Button type="default" onClick={navigateBack}>
              Отмена
            </Button>
            <Button type="primary" htmlType="submit" loading={driverCreateState.loading || driverUpdateState.loading}>
              {isEdit ? 'Сохранить' : 'Добавить'}
            </Button>
          </Flex>
        </S.FormWrapper>
      </S.ContentWrapper>
    </Flex>
  )
}
