import React from 'react'
import { TransportPhotosProps } from './TransportPhotos.types'
import { Text } from '~/ui-components'
import { DocumentViewer } from '~shared/components/DocumentViewer'
import { Flex } from 'antd'

export const TransportPhotos: React.FC<TransportPhotosProps> = ({ transportPhotos }) => {
  const documentInstances = transportPhotos[0]?.instances ?? []
  const documentType = transportPhotos[0]?.type ?? null

  return (
    <>
      <Text variant="h1">Фото транспорта</Text>

      <Flex style={{ maxWidth: '600px' }}>
        {documentInstances.length && documentType ? (
          <DocumentViewer
            key={documentType}
            docInstances={documentInstances.map((documentInstance) => ({
              ...documentInstance,
              documentInfo: {
                ...documentInstance,
                documentNumber: documentInstance.number ?? null,
                formationDate: documentInstance.formedAt,
                rejectionNote: documentInstance.rejectionNote ?? null,
              },
            }))}
          />
        ) : (
          <div>Фото отсутствует</div>
        )}
      </Flex>
    </>
  )
}
