import { CompanyDataSignatoryLegalBasisEnum } from '~api/generated'
import { SignatoryLegalBasis } from '~api/gql-generated/graphql'

/**
 * @deprecated The method should not be used. После переезда на graphQl надо его удалить
 */
export function getCompanySignatoryLegalBasisOld(value: CompanyDataSignatoryLegalBasisEnum): string {
  switch (value) {
    case CompanyDataSignatoryLegalBasisEnum.CompanyRegulation:
      return 'Устав'
    case CompanyDataSignatoryLegalBasisEnum.PowerOfAttorney:
      return 'Доверенность'
    default:
      return ''
  }
}

export function getCompanySignatoryLegalBasis(value: SignatoryLegalBasis): string {
  switch (value) {
    case SignatoryLegalBasis.CompanyRegulation:
      return 'Устав'
    case SignatoryLegalBasis.PowerOfAttorney:
      return 'Доверенность'
    default:
      return ''
  }
}
