import { graphql } from '~api/gql-generated'

export const elevatorCreateMutation = graphql(/* GraphQL */ `
  mutation ElevatorCreate($input: ElevatorCreateInput!) {
    elevatorCreate(input: $input) {
      id
      name
      address
      type
      workingDaysOfWeek
      openingTime
      closingTime
      hasScales
      hasLab
      hasDigitalQueue
      gateHeight
      scalesWeightLimit
      scalesLength
      transport
      unloadingTransport
      loadingTransport
      isDeleted
      createdAt
      buyOfferCount
      sellOfferCount
      storageVolume
      autoClearance
      autoHeight
      autoLength
      autoUnloadSpeed
      hasAutoStorage
      hasRailScales
      hasRailStock
      hasRailWay
      hasTecEco
      railLoadSpeed
      autoReceptionSpeed
      autoAdditionalInfo
      railAdditionalInfo
      stationName
      waitingArea
      productOnElevator
      additionalServiceWithNds
      receptionPrice
      dryingPrice
      storingPrice
      mixingPrice
      censusPrice
      autoShipmentPrice
      trainShipmentPrice
      paperworkPrice
      cleaningPrice
      anotherServices
    }
  }
`)

export const elevatorApproveCreationRequestMutation = graphql(/* GraphQL */ `
  mutation ElevatorApproveCreationRequest($elevatorId: Int!) {
    elevator(elevatorId: $elevatorId) {
      approveCreationRequest {
        id
        name
      }
    }
  }
`)
