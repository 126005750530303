import { Button } from 'antd'
import styled from 'styled-components'

import { breakpoints } from '~/tokens'
import { Card, Text } from '~/ui-components'

export const Content = styled(Card.Content)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 24px;
  grid-row-gap: 16px;
  margin-top: 32px;

  @media (max-width: ${breakpoints.MOBILE_XL}px) {
    grid-template-columns: 1fr;
    grid-row-gap: 4px;
    margin-top: 16px;
  }
`

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
`

export const Title = styled(Text)`
  line-height: 28px;
  display: flex;
  gap: 6px;
`

export const Description = styled(Text)`
  display: inline-block;
  margin-top: 16px;
  padding: 8px;
  border-radius: 6px;
  background: var(--badge-yellow-bg-color);
  color: var(--badge-yellow-text-color);

  @media (max-width: ${breakpoints.MOBILE_XL}px) {
    margin-top: 16px;
  }
`

export const Label = styled(Text)`
  color: var(--gray-6-color);
`

export const Value = styled(Text)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  @media (max-width: ${breakpoints.MOBILE_XL}px) {
    margin-bottom: 12px;
    overflow: unset;
    text-overflow: unset;
    white-space: unset;
  }
`

export const DesktopModalButton = styled(Button)`
  @media (max-width: ${breakpoints.MOBILE_XL}px) {
    display: none;
  }
`

export const MobileModalButton = styled(Button)`
  display: none;

  @media (max-width: ${breakpoints.MOBILE_XL}px) {
    width: 100%;
    display: block;
    margin-top: 16px;
  }
`
