import { graphql } from '~api/gql-generated/gql'

export const freightOrdersQuery = graphql(/* GraphQL */ `
  query FreightOrders($filter: FreightOrderFilterInput, $pagination: PaginationInput) {
    freightOrders(filter: $filter, pagination: $pagination) {
      freightOrders {
        id
        companyId
        routeId
        readinessType
        startedAt
        endedAt
        startDaysCount
        transportFormatTypes
        transportBodyTypes {
          id
          name
        }
        weightKg
        selectedWeightKg
        priceWithoutNds
        priceWithNds
        availableWeightKg
        pricePerTonWithoutNds
        pricePerTonWithNds
        isAuction
        paymentTerm
        advancePercent
        deferredDaysCount
        contactPerson
        contactPhoneNumber
        additionalDetails
        createdAt
        updatedAt
        deletedAt
        route {
          id
          distance
          distanceToStartKm
          createdAt
          updatedAt
          points {
            id
            addressId
            type
            freightId
            roadTransportParametersId
            createdAt
            updatedAt
            address {
              full
              short
            }
            freight {
              id
              packingType
              packingTypeCustom
              bigBagsCount
              productTypeId
              weightKg
              createdAt
              updatedAt
              productType {
                id
                name
                type
                gost
              }
            }
            roadTransportParameters {
              id
              loadingTypes
              unloadingTypes
              loadingTypeCustom
              unloadingTypeCustom
              loadingThroughput
              unloadingThroughput
              vehicleLengthMaxM
              vehicleHeightMaxM
              vehicleClearanceMinCm
              hasParking
              hasScales
              hasWeighingAutomation
              distanceToScalesM
              scalesWeightLimitT
              roadSurfaceType
              roadSurfaceQuality
              additionalDetails
              createdAt
              updatedAt
            }
          }
        }
        canCreateResponse
        responsesStatistics {
          previousDays {
            betsCount
            responsesCount
          }
          today {
            betsCount
            responsesCount
          }
        }
      }
      pagination {
        currentPage
        pageSize
        total
        totalPages
      }
    }
  }
`)

export const freightOrderQuery = graphql(/* GraphQL */ `
  query FreightOrder($freightOrderId: Int!) {
    freightOrder(freightOrderId: $freightOrderId) {
      id
      companyId
      routeId
      readinessType
      startedAt
      endedAt
      startDaysCount
      transportFormatTypes
      transportBodyTypes {
        id
        name
      }
      priceWithoutNds
      priceWithNds
      availableWeightKg
      pricePerTonWithoutNds
      pricePerTonWithNds
      isAuction
      paymentTerm
      advancePercent
      deferredDaysCount
      contactPerson
      contactPhoneNumber
      additionalDetails
      createdAt
      updatedAt
      deletedAt
      selectedWeightKg
      weightKg
      route {
        id
        distance
        distanceToStartKm
        createdAt
        updatedAt
        points {
          id
          addressId
          type
          freightId
          roadTransportParametersId
          createdAt
          updatedAt
          address {
            full
            short
          }
          freight {
            id
            packingType
            packingTypeCustom
            bigBagsCount
            productTypeId
            weightKg
            createdAt
            updatedAt
            productType {
              id
              name
              type
              gost
            }
          }
          roadTransportParameters {
            id
            loadingTypes
            unloadingTypes
            loadingTypeCustom
            unloadingTypeCustom
            loadingThroughput
            unloadingThroughput
            vehicleLengthMaxM
            vehicleHeightMaxM
            vehicleClearanceMinCm
            hasParking
            hasScales
            hasWeighingAutomation
            distanceToScalesM
            scalesWeightLimitT
            roadSurfaceType
            roadSurfaceQuality
            additionalDetails
            createdAt
            updatedAt
          }
        }
      }
      responsesStatistics {
        previousDays {
          betsCount
          responsesCount
        }
        today {
          betsCount
          responsesCount
        }
      }
      canCreateResponse
    }
  }
`)
