import { useMutation } from '@tanstack/react-query'

import { DealApiDealControllerUpdateComplexDealModeratorRequest } from '~/api/generated'
import { useApi } from '~providers/api'

export const useUpdateDealModerator = () => {
  const api = useApi()

  return useMutation((request: DealApiDealControllerUpdateComplexDealModeratorRequest) => {
    return api.deal.dealControllerUpdateComplexDealModerator(request)
  })
}
