import React from 'react'

import { Menu, MenuProps, Tooltip } from 'antd'
import { NavLink, useLocation } from 'react-router-dom'
import styled from 'styled-components'

import { appRoutes } from '~/router'
import { breakpoints } from '~/tokens'
import { menu } from '~layout/AppMenu/AppMenu.data'

import { IMenuItem } from '~layout/AppMenu/AppMenu.types'

const StyledNavLink = styled(NavLink)`
  display: inline-flex;
  flex-wrap: wrap;
  align-items: center;
  height: 52px;
  width: 100%;
  justify-content: flex-start;
  box-sizing: border-box;
  cursor: pointer;
  text-decoration: none;
  font-weight: 600;
  color: unset !important;

  &&&:hover {
    color: var(--primary-hover-color);
  }

  &&&.active {
    position: relative;
    cursor: pointer;
    color: var(--primary-main-color) !important;
  }

  svg {
    display: block;
  }

  @media (max-width: ${breakpoints.DESKTOP_L}px) {
    justify-content: center;
  }
`

const MenuText = styled.div`
  display: block;
  font-weight: 600;
  margin-left: 20px;
  flex: 1;
  align-items: center;

  @media (max-width: ${breakpoints.DESKTOP_L}px) {
    display: none;
  }
`

export const LogisticMenu: React.FC = () => {
  const { pathname } = useLocation()

  return (
    <StyledMenu
      selectable={false}
      triggerSubMenuAction="click"
      key="logistics"
      mode="inline"
      items={items}
      className="logistics-menu"
      defaultOpenKeys={pathname.startsWith(appRoutes.logistics.url) ? [appRoutes.logistics.url] : []}
    />
  )
}

export const StyledMenu = styled(Menu)`
  .ant-menu-submenu-title:hover,
  .ant-menu-root:hover {
    background: unset !important;
    color: var(--primary-hover-color);
  }

  .ant-menu-submenu-title {
    padding-left: 20px !important;
  }

  .ant-menu-submenu-arrow {
    display: block;
  }

  @media (max-width: ${breakpoints.DESKTOP_L}px) {
    .ant-menu-item {
      padding: unset !important;
    }

    .ant-menu-submenu-title {
      padding: 0 !important;
    }

    .ant-menu-submenu-arrow {
      display: none;
    }
  }
`

const LogisticMenuItem = (item: Partial<IMenuItem & { showIcon?: boolean }>) => {
  return (
    <StyledNavLink to={item?.url ?? ''}>
      <StyledTooltip title={item?.title} placement="right" showIcon={item.showIcon}>
        <div>{item?.icon}</div>
      </StyledTooltip>

      <MenuText>{item?.title}</MenuText>
    </StyledNavLink>
  )
}

export const StyledTooltip = styled(Tooltip)<{ showIcon?: boolean }>`
  display: ${({ showIcon }) => (showIcon ? 'block' : 'none')};

  @media (max-width: ${breakpoints.DESKTOP_L}px) {
    display: block;
  }
`

const items: MenuProps['items'] = [
  {
    label: <LogisticMenuItem {...menu.logistics} showIcon={true} />,
    key: appRoutes.logistics.url,
    children: [
      // {
      //   label: <LogisticMenuItem {...menu.logistics} />,
      //   key: appRoutes.logistics.url,
      // },
      {
        label: <LogisticMenuItem {...menu.logisticFindCargo} />,
        key: appRoutes.logisticFindCargo.url,
      },
      {
        label: <LogisticMenuItem {...menu.logisticMyCargos} />,
        key: appRoutes.logisticMyCargos.url,
      },
      {
        label: <LogisticMenuItem {...menu.logisticResponses} />,
        key: appRoutes.logisticResponses.url,
      },
      {
        label: <LogisticMenuItem {...menu.logisticDeals} />,
        key: appRoutes.logisticDeals.url,
      },
    ],
  },
]
