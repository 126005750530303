import { makeAutoObservable } from 'mobx'
import { makePersistable } from 'mobx-persist-store'

import { IInitialFiltersForm } from '~layout/WelcomePage/components/InitialFiltersForm'

// Filter panel shows this values after first open
const defaultFiltersConfig: IInitialFiltersForm = {
  operationType: 'SELL',
  productTypes: [],
  regionIds: [],
}

class InitialFilterStore {
  _filters: IInitialFiltersForm

  constructor() {
    this._filters = defaultFiltersConfig
    makeAutoObservable(this)
    void makePersistable(this, {
      name: 'InitialFilterStore',
      properties: ['_filters'],
      storage: window.localStorage,
    })
  }

  setFilters(newFilters: IInitialFiltersForm): void {
    this._filters = newFilters
  }

  get filters(): IInitialFiltersForm {
    return this._filters
  }
}

export const initialFilterStore = new InitialFilterStore()
