import React from 'react'

import { Table } from 'antd'
import { ColumnsType } from 'antd/lib/table'

import { useGetTableHeight } from '~/hooks'
import { DealStatusBadge } from '~/utils/deals/DealStatusBadge'
import { formatNumber } from '~/utils/formatNumber'
import { getDateFormat } from '~/utils/getDateFormat'
import { CompanyDealDataStatusEnum } from '~api/generated'
import { useGetCurrentRole } from '~hooks/auth'
import { actionArrowColumn } from '~shared/columns/shared'

import { IDealListTableProps, ModeratorDealListTableDataType } from './ModeratorDealListTable.types'

export const ModeratorDealListTable: React.FC<IDealListTableProps> = ({ tableData, loading, onRowClick }) => {
  const tableHeight = useGetTableHeight(310)

  const onRowClickHandler = (record: ModeratorDealListTableDataType) => {
    onRowClick(record)
  }

  const {
    user: { isAdmin },
  } = useGetCurrentRole()

  const columns: ColumnsType<ModeratorDealListTableDataType> = [
    ...(isAdmin
      ? [
          {
            title: '№',
            dataIndex: 'id',
          },
        ]
      : []),

    {
      title: 'Компания продавец',
      dataIndex: 'sellerCompanyName',
      key: 'sellerCompanyName',
    },
    {
      title: 'Компания покупатель',
      dataIndex: 'buyerCompanyName',
      key: 'buyerCompanyName',
    },
    {
      title: 'Объем, т',
      dataIndex: 'quantity',
      key: 'quantity',
      width: '10%',
    },
    {
      title: 'Цена, ₽',
      dataIndex: 'price',
      key: 'price',
      width: '10%',
      render: (value) => formatNumber(value),
    },
    {
      title: 'Культура',
      dataIndex: 'culture',
      key: 'culture',
    },
    {
      title: 'Статус сделки на продажу',
      dataIndex: 'statusSeller',
      key: 'statusSeller',
      width: '10%',
      render: (value: CompanyDealDataStatusEnum) => <DealStatusBadge status={value} />,
    },
    {
      title: 'Статус сделки на покупку',
      dataIndex: 'statusBuyer',
      key: 'statusBuyer',
      width: '10%',
      render: (value: CompanyDealDataStatusEnum) => <DealStatusBadge status={value} />,
    },
    {
      title: 'Дата создания',
      dataIndex: 'startedAt',
      key: 'startedAt',
      width: 150,
      render: (startedAt) => startedAt && getDateFormat(startedAt),
    },
    actionArrowColumn,
  ]

  return (
    <Table
      rowKey="id"
      className="table-interactive"
      loading={loading}
      columns={columns}
      dataSource={tableData}
      scroll={{ y: tableHeight, x: 'max-content' }}
      pagination={false}
      onRow={(record, index) => ({
        onClick: onRowClickHandler.bind(null, record, index),
      })}
    />
  )
}
