import React, { useCallback, useEffect } from 'react'

import { Button, Divider, Segmented, Select } from 'antd'
import { debounce } from 'lodash'
import { Controller, useForm } from 'react-hook-form'

import { AppConfig } from '~/appConfig'
import { filterTypeOptions } from '~/store/filters/_utils'
// import { yupResolver } from '@hookform/resolvers/yup/dist/yup'
import { FormField } from '~/ui-components'
import { getDealStatusName } from '~/utils/deals/get-deal-status-name'
import { enumToArray } from '~/utils/enum-to-array'
import { RangeSliderControl, RegionSelectControl } from '~shared/controls'

import { DealAccessibleStatusEnum, IDealsFilterForm, IDealsFilterFormProps } from './DealsFilterForm.types'

import * as S from './DealsFilterForm.styled'

export const DealsFilterForm: React.FC<IDealsFilterFormProps> = ({
  defaultValues,
  currentValues,
  onSubmit,
  onChange,
  onResetToDefaultValues,
  foundResults,
}) => {
  const {
    watch,
    control,
    handleSubmit,
    reset,
    getValues,
    formState: { errors },
  } = useForm<IDealsFilterForm>({
    defaultValues: {
      dealType: currentValues.dealType,
      volume: {
        from: currentValues.volume.from,
        to: currentValues.volume.to,
      },
      price: {
        from: currentValues.price.from,
        to: currentValues.price.to,
      },
      status: currentValues.status,
      regionIds: currentValues.regionIds,
    },
  })

  const watchedFields = watch()

  const debouncedChangeValues = useCallback(
    debounce(() => onChange(getValues()), AppConfig.DEFAULT_DEBOUNCE_TIME),
    []
  )

  useEffect(() => {
    debouncedChangeValues()
  }, [
    watchedFields.dealType,
    watchedFields.volume.from,
    watchedFields.volume.to,
    watchedFields.price.to,
    watchedFields.price.from,
    watchedFields.status,
    watchedFields.regionIds,
  ])

  const onSubmitHandler = (data: IDealsFilterForm) => onSubmit(data)
  const onResetFormHandler = () => {
    reset(defaultValues)
    onSubmit(defaultValues)
    onResetToDefaultValues()
  }

  return (
    <form onSubmit={handleSubmit(onSubmitHandler)}>
      <FormField validateMessage={errors.dealType?.message}>
        <Controller
          name="dealType"
          control={control}
          render={({ field }) => <Segmented block options={filterTypeOptions} {...field} />}
        />
      </FormField>

      <FormField label="Объем, т" htmlFor="volume" validateMessage={errors.volume?.message}>
        <Controller
          name="volume"
          control={control}
          render={({ field }) => (
            <RangeSliderControl
              {...field}
              id="volume"
              hasError={!!errors.volume}
              minValue={defaultValues.volume.from}
              maxValue={defaultValues.volume.to}
            />
          )}
        />
      </FormField>

      <FormField label="Цена, Р/т" htmlFor="price" validateMessage={errors.price?.message}>
        <Controller
          name="price"
          control={control}
          render={({ field }) => (
            <RangeSliderControl
              {...field}
              id="price"
              hasError={!!errors.price}
              step={200}
              minValue={defaultValues.price.from}
              maxValue={defaultValues.price.to}
            />
          )}
        />
      </FormField>

      <FormField label="Статус" htmlFor="status" validateMessage={errors.status?.message}>
        <Controller
          name="status"
          control={control}
          render={({ field }) => (
            <Select id="status" style={{ width: '100%' }} {...field} allowClear>
              {enumToArray(DealAccessibleStatusEnum).map((option) => (
                <Select.Option key={option} value={option}>
                  {getDealStatusName(option as DealAccessibleStatusEnum)}
                </Select.Option>
              ))}
            </Select>
          )}
        />
      </FormField>

      <FormField label="Регион" htmlFor="regionIds" validateMessage={errors.regionIds?.message}>
        <Controller name="regionIds" control={control} render={({ field }) => <RegionSelectControl {...field} />} />
      </FormField>

      <Divider />

      {foundResults != null && (
        <S.FoundResultWrapper>Мы нашли {foundResults} сделок по выбранным параметрам</S.FoundResultWrapper>
      )}

      <S.ActionFooterWrapper>
        <Button htmlType="button" onClick={onResetFormHandler}>
          Сбросить все
        </Button>
        <Button htmlType="submit" type="primary">
          Показать
        </Button>
      </S.ActionFooterWrapper>
    </form>
  )
}
