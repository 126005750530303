import { useMutation } from '@tanstack/react-query'

import { OfferApiOfferControllerUpdateOfferRequest } from '~api/generated'
import { useApi } from '~providers/api'

export const useUpdateOffer = () => {
  const api = useApi()

  return useMutation((request: OfferApiOfferControllerUpdateOfferRequest) => {
    return api.offers.offerControllerUpdateOffer(request)
  })
}
