import React, { useState } from 'react'

import { Button } from 'antd'
import { observer } from 'mobx-react-lite'
import { FiFilter } from 'react-icons/fi'

import { AuctionsFilterStore, auctionsFilterStore } from '~/store/filters'
import { useApi } from '~providers/api'

import { AuctionsFilterForm, IAuctionsFilterForm } from './components/AuctionsFilterForm'

import * as S from './AuctionsFilter.styled'

type StoreType = 'lotsList' | 'myLots' | 'myResponses'

interface IOffersFilterProps {
  store: StoreType
}

function getStore(store: StoreType): AuctionsFilterStore {
  switch (store) {
    case 'lotsList':
      return auctionsFilterStore
    // case 'myLots':
    //   return auctionsFilterStore
    // case 'myResponses':
    //   return auctionsFilterStore
    default:
      return auctionsFilterStore
  }
}

export const AuctionsFilter: React.FC<IOffersFilterProps> = observer((props) => {
  const store = getStore(props.store)
  const api = useApi()
  const [isVisible, setVisible] = useState(false)
  const [foundResults, setFoundResults] = useState<number | null>(null)

  const onOpenHandler = () => setVisible(true)
  const onCloseHandler = () => setVisible(false)
  const onResetToDefaultValuesHandler = () => setFoundResults(null)

  const onApplyFilters = (values: IAuctionsFilterForm) => {
    onCloseHandler()
    store.setFilters(values)
  }

  // filters just changed, not applied (for counting result)
  const onChangeFilters = async (values: IAuctionsFilterForm) => {
    const response = await api.auction.auctionControllerGetAuctions({
      pageSize: 1,
      minVolume: values.volume.from,
      maxVolume: values.volume.to,
      minPrice: values.price.from,
      maxPrice: values.price.to,
      type: values.auctionType === 'ALL' ? undefined : values.auctionType,
      regionIds: values.regionIds,
    })
    const foundResults = response.data.pagination.total
    setFoundResults(foundResults)
  }

  return (
    <>
      <Button icon={<FiFilter />} onClick={onOpenHandler}>
        Фильтры
        {store.hasActiveFilters ? (
          <S.ActiveFilterCount variant="normal-bold">({store.activeFiltersCount})</S.ActiveFilterCount>
        ) : null}
      </Button>

      <S.StyledDrawer
        className="auction-filter-menu"
        title="Фильтры"
        placement="right"
        onClose={onCloseHandler}
        open={isVisible}
      >
        <AuctionsFilterForm
          defaultValues={store.defaultFiltersConfig}
          currentValues={store.auctionFilters}
          onSubmit={onApplyFilters}
          onChange={onChangeFilters}
          onResetToDefaultValues={onResetToDefaultValuesHandler}
          foundResults={foundResults}
        />
      </S.StyledDrawer>
    </>
  )
})
