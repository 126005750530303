import React, { useCallback, useEffect } from 'react'

import { Button, Divider, Segmented } from 'antd'
import { debounce } from 'lodash'
import { Controller, useForm } from 'react-hook-form'

import { AppConfig } from '~/appConfig'
import { filterTypeOptions } from '~/store/filters/_utils'
import { FormField } from '~/ui-components'
import { RangeSliderControl, RegionSelectControl } from '~shared/controls'

import { IAuctionsFilterForm, IAuctionsFilterFormProps } from './AuctionsFilterForm.types'

import * as S from './AuctionsFilterForm.styled'

export const AuctionsFilterForm: React.FC<IAuctionsFilterFormProps> = ({
  defaultValues,
  currentValues,
  onSubmit,
  onChange,
  onResetToDefaultValues,
  foundResults,
}) => {
  const {
    watch,
    control,
    handleSubmit,
    reset,
    getValues,
    formState: { errors },
  } = useForm<IAuctionsFilterForm>({
    defaultValues: {
      auctionType: currentValues.auctionType,
      volume: {
        from: currentValues.volume.from,
        to: currentValues.volume.to,
      },
      price: {
        from: currentValues.price.from,
        to: currentValues.price.to,
      },
      regionIds: currentValues.regionIds,
    },
  })

  const watchedFields = watch()

  const debouncedChangeValues = useCallback(
    debounce(() => onChange(getValues()), AppConfig.DEFAULT_DEBOUNCE_TIME),
    []
  )

  useEffect(() => {
    debouncedChangeValues()
  }, [
    watchedFields.auctionType,
    watchedFields.volume.from,
    watchedFields.volume.to,
    watchedFields.price.to,
    watchedFields.price.from,
    watchedFields.regionIds,
  ])

  const onSubmitHandler = (data: IAuctionsFilterForm) => onSubmit(data)
  const onResetFormHandler = () => {
    reset(defaultValues)
    onSubmit(defaultValues)
    onResetToDefaultValues()
  }

  return (
    <form onSubmit={handleSubmit(onSubmitHandler)}>
      <FormField validateMessage={errors.auctionType?.message}>
        <Controller
          name="auctionType"
          control={control}
          render={({ field }) => <Segmented block options={filterTypeOptions} {...field} />}
        />
      </FormField>

      <FormField label="Объем, т" htmlFor="volume" validateMessage={errors.volume?.message}>
        <Controller
          name="volume"
          control={control}
          render={({ field }) => (
            <RangeSliderControl
              {...field}
              id="volume"
              hasError={!!errors.volume}
              minValue={defaultValues.volume.from}
              maxValue={defaultValues.volume.to}
            />
          )}
        />
      </FormField>

      <FormField label="Цена, Р/т" htmlFor="price" validateMessage={errors.price?.message}>
        <Controller
          name="price"
          control={control}
          render={({ field }) => (
            <RangeSliderControl
              {...field}
              id="price"
              hasError={!!errors.price}
              step={200}
              minValue={defaultValues.price.from}
              maxValue={defaultValues.price.to}
            />
          )}
        />
      </FormField>

      <FormField label="Регион" htmlFor="regionIds" validateMessage={errors.regionIds?.message}>
        <Controller name="regionIds" control={control} render={({ field }) => <RegionSelectControl {...field} />} />
      </FormField>

      <Divider />

      {foundResults != null && (
        <S.FoundResultWrapper>Мы нашли {foundResults} лотов по выбранным параметрам</S.FoundResultWrapper>
      )}

      <S.ActionFooterWrapper>
        <Button htmlType="button" onClick={onResetFormHandler}>
          Сбросить все
        </Button>
        <Button htmlType="submit" type="primary">
          Показать
        </Button>
      </S.ActionFooterWrapper>
    </form>
  )
}
