/* tslint:disable */
/* eslint-disable */
/**
 * API Зерно-Агрегатора
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { ContactPersonData } from './contact-person-data';
import { FileData } from './file-data';
import { OfferPriceDetail } from './offer-price-detail';
import { ProductData } from './product-data';

/**
 * 
 * @export
 * @interface BaseOfferData
 */
export interface BaseOfferData {
    /**
     * 
     * @type {number}
     * @memberof BaseOfferData
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof BaseOfferData
     */
    'companyId': number | null;
    /**
     * 
     * @type {string}
     * @memberof BaseOfferData
     */
    'type': BaseOfferDataTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof BaseOfferData
     */
    'status': BaseOfferDataStatusEnum;
    /**
     * 
     * @type {number}
     * @memberof BaseOfferData
     */
    'volume': number;
    /**
     * 
     * @type {number}
     * @memberof BaseOfferData
     */
    'price': number;
    /**
     * Подробная информация о цене
     * @type {OfferPriceDetail}
     * @memberof BaseOfferData
     */
    'priceDetail': OfferPriceDetail | null;
    /**
     * 
     * @type {boolean}
     * @memberof BaseOfferData
     */
    'includingNds': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof BaseOfferData
     */
    'shippingRequired': boolean;
    /**
     * 
     * @type {string}
     * @memberof BaseOfferData
     */
    'address': string;
    /**
     * 
     * @type {number}
     * @memberof BaseOfferData
     */
    'distance': number | null;
    /**
     * Товар
     * @type {ProductData}
     * @memberof BaseOfferData
     */
    'product': ProductData;
    /**
     * 
     * @type {FileData}
     * @memberof BaseOfferData
     */
    'laboratoryAnalysis': FileData | null;
    /**
     * Примечание к предложению
     * @type {string}
     * @memberof BaseOfferData
     */
    'note': string | null;
    /**
     * 
     * @type {ContactPersonData}
     * @memberof BaseOfferData
     */
    'contactPerson': ContactPersonData | null;
}

export const BaseOfferDataTypeEnum = {
    Buy: 'BUY',
    Sell: 'SELL'
} as const;

export type BaseOfferDataTypeEnum = typeof BaseOfferDataTypeEnum[keyof typeof BaseOfferDataTypeEnum];
export const BaseOfferDataStatusEnum = {
    OnModeration: 'ON_MODERATION',
    PrePublished: 'PRE_PUBLISHED',
    Published: 'PUBLISHED',
    PreDeal: 'PRE_DEAL',
    InDeal: 'IN_DEAL',
    Expired: 'EXPIRED',
    Rejected: 'REJECTED',
    Realised: 'REALISED'
} as const;

export type BaseOfferDataStatusEnum = typeof BaseOfferDataStatusEnum[keyof typeof BaseOfferDataStatusEnum];


