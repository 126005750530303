import { graphql } from '~api/gql-generated'

export const createLoadMutation = graphql(/* GraphQL */ `
  mutation FreightOrderCreate($input: FreightOrderCreateInput!) {
    freightOrderCreate(input: $input) {
      id
    }
  }
`)

export const createFreightOrderResponseMutation = graphql(/* GraphQL */ `
  mutation FreightOrderResponseCreate($input: FreightOrderResponseCreateInput!) {
    freightOrderResponseCreate(input: $input) {
      id
    }
  }
`)

export const createFreightOrderBetMutation = graphql(/* GraphQL */ `
  mutation FreightOrderBetCreate($input: FreightOrderBetCreateInput!) {
    freightOrderBetCreate(input: $input) {
      id
    }
  }
`)
