import React from 'react'

import { Flex, Tooltip } from 'antd'

import { Text } from '~/ui-components'
import { FreightOrderChangeRequestStatus } from '~api/gql-generated/graphql'
import { StatusIcon } from '~shared/components/StatusIcon'
import { getFreightDealStatusIconText } from '~/utils/logistics/get-freight-deal-status-icon-text'

interface ReadValueProps {
  name: React.ReactElement | string
  value?: React.ReactElement | string | null
  onClick?: () => void
  status: FreightOrderChangeRequestStatus
  isCreator: boolean
  isFinalizing: boolean
}

const UNFILLED = 'Не заполнено'

export const LinkValue: React.FC<ReadValueProps> = (props) => {
  return (
    <Flex vertical gap={4}>
      <Flex gap={8} align="center">
        {props?.status && (
          <Tooltip title={getFreightDealStatusIconText(props.status, props.isCreator, props.isFinalizing)}>
            <Flex>
              <StatusIcon status={props.status} isCreator={props.isCreator} isFinalizing={props.isFinalizing} />
            </Flex>
          </Tooltip>
        )}
        <Text variant="form-read-label">{props.name}</Text>
      </Flex>
      {props?.value ? (
        <Text
          variant="form-read-value"
          style={
            props.onClick
              ? {
                  width: 'fit-content',
                  cursor: 'pointer',
                  textDecorationLine: 'underline',
                  textUnderlineOffset: '3px',
                }
              : {}
          }
          onClick={props.onClick}
        >
          {props.value}
        </Text>
      ) : (
        <Text variant="label">{UNFILLED}</Text>
      )}
    </Flex>
  )
}
