import styled from 'styled-components'

import { css } from '@styled-system/css'

import { breakpoints } from '~/tokens'

export const Desktop = styled.div(
  css({
    // display: ['none', null, 'block'],
    maxWidth: '100%',
    overflow: 'hidden',
  })
)

export const FiltersWrapper = styled.div`
  display: flex;
  gap: 16px;
  padding: 0;
  justify-content: space-between;
  margin-bottom: 16px;

  @media (max-width: ${breakpoints.DESKTOP_S}px) {
    flex-direction: column;
    padding: 0;
  }
`
