import React, { useEffect, useState } from 'react'

import { Button, Input } from 'antd'
import { isBefore, sub } from 'date-fns'
import { Controller, useForm } from 'react-hook-form'

import { yupResolver } from '@hookform/resolvers/yup'

import { FormField, FormFooter, UploadButton } from '~/ui-components'
import {
  AddressControlDadata,
  DatePickerControl,
  OrganizationControlAutocomplete,
  ParametersCultureControl,
  PriceControl,
  TransportationTypeSelectControl,
  UsingNdsControl,
  VolumeControl,
} from '~shared/controls'
import { ParametersCultureControlValue } from '~shared/controls/ParametersCultureControl'

import { dealSpecificationFormSchema } from './DealSpecificationForm.validation'

import { IDealSpecificationForm, IDealSpecificationFormProps } from './DealSpecificationForm.types'

import * as S from './DealSpecificationForm.styled'

export const DealSpecificationForm: React.FC<IDealSpecificationFormProps> = ({
  onSubmit,
  isLoading,
  offer,
  onClose,
}) => {
  const [totalPrice, setTotalPrice] = useState<number | null>(null)
  const cultureDefault: ParametersCultureControlValue = {
    cultureTypeId: offer.product.type,
    parameters: offer.product.parameters,
    hasError: false,
  }

  const {
    watch,
    control,
    handleSubmit,
    formState: { errors, isDirty },
  } = useForm<IDealSpecificationForm>({
    defaultValues: {
      culture: cultureDefault,
      labAnalysis: undefined,
      volume: offer.volume ?? 0,
      price: undefined,
      commission: undefined,
      usingNdsForBuyer: undefined,
      usingNdsForSeller: undefined,
      transportationType: undefined,
      consignee: '',
      shipper: '',
      loadingAddress: undefined,
      unloadingAddress: undefined,
      deliveryDate: undefined,
      paymentMethod: '',
    },
    resolver: yupResolver(dealSpecificationFormSchema),
  })

  watch()
  const watchPrice = watch('price')
  const watchVolume = watch('volume')
  const commission = watch('commission')
  useEffect(() => {
    if (!watchPrice || !watchVolume) {
      setTotalPrice(null)
    } else {
      setTotalPrice((watchPrice + (commission ?? 0)) * watchVolume)
    }
  }, [watchPrice, watchVolume, commission])

  const onSubmitHandler = (data: IDealSpecificationForm) => onSubmit(data)

  return (
    <S.Form onSubmit={handleSubmit(onSubmitHandler)}>
      <S.ContentWrapper>
        <S.BadgeStyled color="yellow">
          Часть полей мы заполнили данными, которые были указаны в объявлении. Проверьте их и исправьте, в случае
          неточности.
        </S.BadgeStyled>

        <FormField validateMessage={errors.culture ? 'Параметры культуры заполнены с ошибками' : ''}>
          <Controller
            name="culture"
            control={control}
            render={({ field }) => <ParametersCultureControl {...field} />}
          />
        </FormField>

        <FormField label="Результаты лаб. исследований" validateMessage={errors.labAnalysis?.message}>
          <Controller
            control={control}
            name="labAnalysis"
            render={({ field }) => (
              <UploadButton
                onChange={(evt) => {
                  if (evt.target.files?.length) {
                    field.onChange(evt.target.files[0])
                  }
                }}
                onDelete={() => field.onChange(undefined)}
              >
                Загрузить
              </UploadButton>
            )}
          />
        </FormField>

        <FormField label="Объем, тонн" htmlFor="volume" isRequired validateMessage={errors.volume?.message}>
          <Controller
            name="volume"
            control={control}
            render={({ field }) => (
              <S.HalfRowWidth>
                <VolumeControl {...field} id="volume" hasError={!!errors.volume} />
              </S.HalfRowWidth>
            )}
          />
        </FormField>

        <S.GridRow>
          <FormField htmlFor="price" label="Цена за 1 тонну" isRequired validateMessage={errors.price?.message}>
            <Controller
              name="price"
              control={control}
              render={({ field }) => <PriceControl id="price" {...field} hasError={!!errors.price} />}
            />
          </FormField>

          {totalPrice && (
            <S.TotalPriceWrapper>
              Общая стоимость: <S.Price>{totalPrice}</S.Price>
            </S.TotalPriceWrapper>
          )}
        </S.GridRow>

        <FormField
          htmlFor="commission"
          label="Агентское вознаграждение за 1 тонну"
          isRequired
          validateMessage={errors.commission?.message}
        >
          <Controller
            name="commission"
            control={control}
            render={({ field }) => (
              <S.HalfRowWidth>
                <PriceControl placeholder="100" id="price" {...field} hasError={!!errors.commission} />
              </S.HalfRowWidth>
            )}
          />
        </FormField>

        <FormField htmlFor="usingNdsForSeller" label="Продавец" validateMessage={errors.usingNdsForSeller?.message}>
          <Controller
            name="usingNdsForSeller"
            control={control}
            render={({ field }) => <UsingNdsControl {...field} />}
          />
        </FormField>

        <FormField htmlFor="usingNdsForBuyer" label="Покупатель" validateMessage={errors.usingNdsForBuyer?.message}>
          <Controller
            name="usingNdsForBuyer"
            control={control}
            render={({ field }) => <UsingNdsControl {...field} />}
          />
        </FormField>

        <FormField
          htmlFor="transportationType"
          label="Тип грузоперевозки"
          validateMessage={errors.transportationType?.message}
          isRequired
        >
          <Controller
            name="transportationType"
            control={control}
            render={({ field }) => (
              <S.HalfRowWidth>
                <TransportationTypeSelectControl
                  id="transportationType"
                  {...field}
                  hasError={!!errors.transportationType}
                />
              </S.HalfRowWidth>
            )}
          />
        </FormField>

        <FormField htmlFor="shipper" label="Грузоотправитель" validateMessage={errors.shipper?.message} isRequired>
          <Controller
            name="shipper"
            control={control}
            render={({ field }) => (
              <OrganizationControlAutocomplete
                id="shipper"
                {...field}
                hasError={!!errors.shipper}
                onSelect={(x) => field.onChange(x?.value)}
              />
            )}
          />
        </FormField>

        <FormField
          htmlFor="loadingAddress"
          label="Адрес погрузки"
          isRequired
          validateMessage={errors.loadingAddress?.message}
        >
          <Controller
            name="loadingAddress"
            control={control}
            render={({ field }) => (
              // <AddressControlAutocomplete
              //   id="loadingAddress"
              //   {...field}
              //   hasError={!!errors.loadingAddress}
              //   onAddressSelect={(x) => field.onChange(x?.value)}
              // />
              <AddressControlDadata
                id="loadingAddress"
                value={undefined}
                onChange={(value) => field.onChange(value)}
                hasError={!!errors.loadingAddress}
              />
            )}
          />
        </FormField>

        <FormField htmlFor="consignee" label="Грузополучатель" validateMessage={errors.consignee?.message} isRequired>
          <Controller
            name="consignee"
            control={control}
            render={({ field }) => (
              <OrganizationControlAutocomplete
                id="consignee"
                {...field}
                hasError={!!errors.consignee}
                onSelect={(x) => field.onChange(x?.value)}
              />
            )}
          />
        </FormField>

        <FormField
          htmlFor="unloadingAddress"
          label="Адрес разгрузки"
          isRequired
          validateMessage={errors.unloadingAddress?.message}
        >
          <Controller
            name="unloadingAddress"
            control={control}
            render={({ field }) => (
              // <AddressControlAutocomplete
              //   id="unloadingAddress"
              //   {...field}
              //   hasError={!!errors.unloadingAddress}
              //   onAddressSelect={(x) => field.onChange(x?.value)}
              // />
              <AddressControlDadata
                id="unloadingAddress"
                value={undefined}
                onChange={(value) => field.onChange(value)}
                hasError={!!errors.unloadingAddress}
              />
            )}
          />
        </FormField>

        <FormField
          htmlFor="deliveryDate"
          label="Срок поставки не позднее"
          isRequired
          validateMessage={errors.deliveryDate?.message}
        >
          <Controller
            name="deliveryDate"
            control={control}
            render={({ field }) => (
              <S.HalfRowWidth>
                <DatePickerControl
                  worksWith="date"
                  id="deliveryDate"
                  {...field}
                  hasError={!!errors.deliveryDate}
                  datePickerProps={{
                    disabledDate: (current) => isBefore(current, sub(new Date(), { days: 1 })),
                  }}
                />
              </S.HalfRowWidth>
            )}
          />
        </FormField>

        <FormField
          htmlFor="paymentMethod"
          label="Способ оплаты"
          isRequired
          validateMessage={errors.paymentMethod?.message}
        >
          <Controller
            name="paymentMethod"
            control={control}
            render={({ field }) => (
              <S.HalfRowWidth>
                <Input
                  {...field}
                  id="paymentMethod"
                  placeholder="Согласно выставленному счету"
                  status={errors.paymentMethod && 'error'}
                />
              </S.HalfRowWidth>
            )}
          />
        </FormField>

        <FormFooter>
          <Button onClick={onClose}>Отмена</Button>
          <Button htmlType="submit" type="primary" disabled={!isDirty || isLoading}>
            Создать
          </Button>
        </FormFooter>
      </S.ContentWrapper>
    </S.Form>
  )
}
