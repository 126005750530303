import { Divider } from 'antd'
import { NavLink } from 'react-router-dom'
import styled from 'styled-components'

import { Text } from '~/ui-components'

export const StyledNav = styled.nav`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`
export const StyledNavLink = styled(NavLink)`
  display: flex;
  align-items: center;
  height: 48px;
  padding: 12px 24px;

  font-weight: 700;
  font-size: 14px;
  line-height: 16px;

  color: var(--gray-8-color) !important;

  &.active {
    color: var(--primary-main-color) !important;
    background: rgba(69, 166, 125, 0.1) !important;
    border-left: 2px solid var(--primary-main-color);
  }

  &:hover {
    color: var(--primary-main-color) !important;
    background: rgba(69, 166, 125, 0.15) !important;
  }
`
export const SectionName = styled(Text).attrs({ variant: 'description' })`
  color: var(--gray-6-color);
  margin-left: 16px;
  margin-bottom: 8px;
`
export const StyledDivider = styled(Divider)`
  margin: 0;
  margin-bottom: 24px;
`
