import styled from 'styled-components'

import { breakpoints } from '~/tokens'
import { Text } from '~/ui-components'

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 24px;

  @media (max-width: ${breakpoints.DESKTOP_S}px) {
    padding: 12px;
  }
`

export const OfferWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;

  // @media (max-width: ${breakpoints.DESKTOP_S}px) {
  //   padding: 12px;
  // }
`

export const Price = styled(Text).attrs({ variant: 'h4' })`
  //color: var(--primary-main-color);
`
export const Parameters = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
`

export const ParametersInfo = styled.div<{ isAllow: boolean }>`
  display: flex;
  gap: 8px;
  align-items: center;
  > * {
    color: ${(props) => (props.isAllow ? 'var(--badge-blue-bg-color)' : '#989898')};
  }
`
export const ActionWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 16px;

  @media (max-width: ${breakpoints.MOBILE_XL}px) {
    gap: 8px;
    flex-direction: column;
    > * {
      width: 100%;
    }
  }
`
export const ActionWrapper2 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;

  @media (max-width: ${breakpoints.MOBILE_XL}px) {
    gap: 8px;
    flex-direction: column;
    > * {
      width: 100%;
    }
  }
`

export const TopInfoWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 8px;
  align-items: center;
`

export const BasisInfo = styled.div`
  display: flex;
  gap: 6px;
  align-items: center;
  flex-wrap: wrap;
`
export const PublishedAt = styled(Text).attrs({ variant: 'description' })`
  color: var(--gray-4-color);
`
export const OfferMessage = styled(Text).attrs({ variant: 'description' })`
  color: var(--gray-5-color);
`

export const CompanyInfo = styled.div`
  display: grid;
  grid-template-columns: 170px 1fr;
  gap: 16px;
  margin: 24px 0;
  @media (max-width: ${breakpoints.MOBILE_XL}px) {
    grid-template-columns: 1fr;
    gap: 8px;
  }
`
export const Volume = styled(Text).attrs({ variant: 'h4' })`
  color: var(--primary-main-color);
`
export const PriceType = styled(Text).attrs({ variant: 'normal' })`
  color: var(--gray-5-color);
`
export const StatusMessage = styled(Text).attrs({ variant: 'normal' })`
  color: var(--gray-5-color);
  text-align: center;
`
export const AdvertPriceMessage = styled(Text).attrs({ variant: 'normal' })`
  color: var(--gray-5-color);
  display: flex;
  align-items: center;
  text-align: center;
`
export const Label = styled(Text).attrs({ variant: 'normal-bold' })`
  //color: var(--gray-5-color);
`
