import React, { FC } from 'react'

import { getDateFormat, getTimeFormat } from '~/utils/getDateFormat'
import { PhoneNumber } from '~shared/components'

import { CardLabel, CardValue } from './CardValue'

import { CarrierInfoCardViewProps } from './CarrierInfoCardView.types'

import * as S from './CarrierInfoCardView.styled'

export const CarrierInfoCardView: FC<CarrierInfoCardViewProps> = ({ data }) => {
  return (
    <S.Content>
      <CardLabel value="Организация" />
      <CardValue value={data?.organization} />

      <CardLabel value="Ответственный за перевозку" />
      <CardValue value={data?.contactUser} />

      <CardLabel value="Тел. ответственного за перевозку" />
      <PhoneNumber phoneNumber={data?.contactPhone} />

      <CardLabel value="Дата прибытия первой машины для погрузки" />
      <CardValue value={data?.dateFirstCar ? getDateFormat(data.dateFirstCar) : null} />

      <CardLabel value="Срок исполнения перевозки" />
      <CardValue value={data?.dateOfPerformance ? getDateFormat(data.dateOfPerformance) : null} />
    </S.Content>
  )
}
