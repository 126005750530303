/* tslint:disable */
/* eslint-disable */
/**
 * API Зерно-Агрегатора
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface UpdateAdditionalService
 */
export interface UpdateAdditionalService {
    /**
     * Тип дополнительной услуги по сделке
     * @type {string}
     * @memberof UpdateAdditionalService
     */
    'type': UpdateAdditionalServiceTypeEnum;
    /**
     * Выбрана ли дополнительная услуга?
     * @type {boolean}
     * @memberof UpdateAdditionalService
     */
    'isSelected': boolean;
    /**
     * Стоимость дополнительной услуги
     * @type {number}
     * @memberof UpdateAdditionalService
     */
    'price'?: number;
    /**
     * Расстояние в километрах
     * @type {number}
     * @memberof UpdateAdditionalService
     */
    'distance'?: number;
}

export const UpdateAdditionalServiceTypeEnum = {
    Shipping: 'SHIPPING',
    Service: 'SERVICE',
    EmptyService: 'EMPTY_SERVICE'
} as const;

export type UpdateAdditionalServiceTypeEnum = typeof UpdateAdditionalServiceTypeEnum[keyof typeof UpdateAdditionalServiceTypeEnum];


