import React from 'react'

import { ElevatorCompanyAdvertsSummaryData } from '~api/generated'

import * as S from './AdvertMiniOverview.styled'

interface AdvertMiniOverviewProps {
  advert: ElevatorCompanyAdvertsSummaryData
}
export const AdvertMiniOverview: React.FC<AdvertMiniOverviewProps> = (props) => {
  return (
    <S.Wrapper>
      <S.Row>
        {props.advert.productType.name}
        <S.CountWrapper>{props.advert.count}</S.CountWrapper>
      </S.Row>
      <S.Row>
        <S.Price>{props.advert.minimalPricePerTon} ₽/т</S.Price>
      </S.Row>
    </S.Wrapper>
  )
}
