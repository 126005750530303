import styled from 'styled-components'

import { breakpoints } from '~/tokens'
import { Text } from '~/ui-components'

export const InfoText = styled(Text).attrs({ variant: 'description' })`
  margin-top: 4px;
  color: var(--gray-6-color);
`

export const PriceControlWrapper = styled.div`
  display: grid;
  grid-template-columns: 50% 1fr;
  align-items: center;
  gap: 16px;
  @media (max-width: ${breakpoints.MOBILE_XL}px) {
    grid-template-columns: 1fr;
    gap: unset;
  }
`

export const HalfRow = styled.div`
  width: 50%;
  @media (max-width: ${breakpoints.MOBILE_XL}px) {
    width: 100%;
  }
`
