import React from 'react'

import { Button } from 'antd'
import { FiChevronLeft } from 'react-icons/fi'
import { useNavigate } from 'react-router-dom'

interface BackButtonProps {
  onClick?: () => void
  text?: string
  isShowText?: boolean
}

export const BackButton: React.FC<BackButtonProps> = ({ onClick, text = 'Назад', isShowText = true }) => {
  const navigate = useNavigate()
  const onClickHandler = () => {
    if (onClick) {
      onClick()
    } else {
      navigate(-1)
    }
  }
  return (
    <Button type="text" icon={<FiChevronLeft size={isShowText ? 14 : 20} />} onClick={onClickHandler}>
      {isShowText ? text : null}
    </Button>
  )
}
