import { useState } from 'react'

type OrderBy = string
type Order = 'asc' | 'desc'

interface Params {
  orderBy?: OrderBy
  order?: Order
}

export const useSorting = (params?: Params) => {
  const [orderBy, setOrderBy] = useState<OrderBy | undefined>(params?.orderBy)
  const [order, setOrder] = useState<Order | undefined>(params?.order)

  return {
    orderBy,
    setOrderBy,
    order,
    setOrder,
  }
}
