import * as yup from 'yup'

import { VALIDATORS_MSG } from '~shared/validations'
import { fullPhoneNumberValidator } from '~shared/validations/schemas'

export const companyAccountInfoEditSchema = yup.object({
  mobileNumber: yup.string().trim().test('mobilePhone', VALIDATORS_MSG.notFullPhoneNumber, fullPhoneNumberValidator),
  website: yup.string().trim(),
  additionalInfo: yup.string().trim(),
})
