import React from 'react'

import { Button } from 'antd'

import { appToast } from '~/utils'
import { useGetCompanyDocumentTemplate } from '~hooks/companyDocuments'

export const DownloadTemplateDocButton: React.FC = () => {
  const getCompanyDocumentTemplate = useGetCompanyDocumentTemplate()

  const handleDownloadTemplate = async () => {
    try {
      const docBlob = await getCompanyDocumentTemplate.mutateAsync()
      // @ts-ignore FIXME: should have a types from backend instead of void
      const objectUrl = URL.createObjectURL(docBlob)
      window.open(objectUrl)
    } catch (err) {
      appToast.error({ message: 'Ошибка при загрузке файла' })
    }
  }

  return (
    <Button type="primary" onClick={handleDownloadTemplate}>
      Скачать образец
    </Button>
  )
}
