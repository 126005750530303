import styled from 'styled-components'

import { breakpoints } from '~/tokens'

export const Wrapper = styled.div`
  background: rgba(230, 244, 255, 0.82);
  display: flex;
  gap: 10px;
  padding: 16px 29px 32px 8px;
  margin: 6px -24px -32px;
  cursor: pointer;

  &:hover {
    background: rgba(215, 225, 252, 0.6);
  }

  @media (max-width: ${breakpoints.DESKTOP_L}px) {
    margin: 6px -16px -32px;
    padding: 20px;
  }
`

export const Title = styled.p`
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  color: #161eff;
  margin: 0;
  @media (max-width: ${breakpoints.DESKTOP_L}px) {
    display: none;
  }
`

export const Message = styled.p`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: var(--gray-7-color);
  margin: 0;
  @media (max-width: ${breakpoints.DESKTOP_L}px) {
    display: none;
  }
`
