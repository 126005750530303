/* tslint:disable */
/* eslint-disable */
/**
 * API Зерно-Агрегатора
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AddWarehousePositionActionBuyOutsideBody } from '../models';
// @ts-ignore
import { AddWarehousePositionActionDecreaseBody } from '../models';
// @ts-ignore
import { AddWarehousePositionActionIncreaseBody } from '../models';
// @ts-ignore
import { AddWarehousePositionActionSellOutsideBody } from '../models';
// @ts-ignore
import { AddWarehousePositionActionTransferFromBody } from '../models';
// @ts-ignore
import { CreateWarehouseBody } from '../models';
// @ts-ignore
import { CreateWarehousePositionBody } from '../models';
// @ts-ignore
import { CreateWarehousePositionResponse } from '../models';
// @ts-ignore
import { CreateWarehouseResponse } from '../models';
// @ts-ignore
import { GetWarehousePositionActionsResponse } from '../models';
// @ts-ignore
import { GetWarehousePositionsResponse } from '../models';
// @ts-ignore
import { GetWarehouseResponse } from '../models';
// @ts-ignore
import { GetWarehousesResponse } from '../models';
// @ts-ignore
import { OrderDirection } from '../models';
// @ts-ignore
import { UpdateWarehouseBody } from '../models';
/**
 * WarehouseApi - axios parameter creator
 * @export
 */
export const WarehouseApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Создать новый товар на складе
         * @param {number} warehouseId 
         * @param {CreateWarehousePositionBody} createWarehousePositionBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWarehousePosition: async (warehouseId: number, createWarehousePositionBody: CreateWarehousePositionBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'warehouseId' is not null or undefined
            assertParamExists('createWarehousePosition', 'warehouseId', warehouseId)
            // verify required parameter 'createWarehousePositionBody' is not null or undefined
            assertParamExists('createWarehousePosition', 'createWarehousePositionBody', createWarehousePositionBody)
            const localVarPath = `/warehouse/{warehouseId}/position`
                .replace(`{${"warehouseId"}}`, encodeURIComponent(String(warehouseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createWarehousePositionBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} companyId 
         * @param {CreateWarehouseBody} createWarehouseBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        warehouseControllerCreateWarehouse: async (companyId: number, createWarehouseBody: CreateWarehouseBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('warehouseControllerCreateWarehouse', 'companyId', companyId)
            // verify required parameter 'createWarehouseBody' is not null or undefined
            assertParamExists('warehouseControllerCreateWarehouse', 'createWarehouseBody', createWarehouseBody)
            const localVarPath = `/company/{companyId}/warehouse`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createWarehouseBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} warehouseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        warehouseControllerDeleteWarehouse: async (warehouseId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'warehouseId' is not null or undefined
            assertParamExists('warehouseControllerDeleteWarehouse', 'warehouseId', warehouseId)
            const localVarPath = `/warehouse/{warehouseId}`
                .replace(`{${"warehouseId"}}`, encodeURIComponent(String(warehouseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} warehouseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        warehouseControllerGetWarehouse: async (warehouseId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'warehouseId' is not null or undefined
            assertParamExists('warehouseControllerGetWarehouse', 'warehouseId', warehouseId)
            const localVarPath = `/warehouse/{warehouseId}`
                .replace(`{${"warehouseId"}}`, encodeURIComponent(String(warehouseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} companyId 
         * @param {Array<number>} [warehouseIds] Список id складов
         * @param {'id' | 'name' | 'address' | 'openingTime' | 'closingTime' | 'gateHeight' | 'scalesWeightLimit' | 'scalesLength' | 'totalVolume' | 'createdAt'} [orderBy] Поле для сортировки
         * @param {OrderDirection} [order] Направление сортировки
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        warehouseControllerGetWarehouses: async (companyId: number, warehouseIds?: Array<number>, orderBy?: 'id' | 'name' | 'address' | 'openingTime' | 'closingTime' | 'gateHeight' | 'scalesWeightLimit' | 'scalesLength' | 'totalVolume' | 'createdAt', order?: OrderDirection, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('warehouseControllerGetWarehouses', 'companyId', companyId)
            const localVarPath = `/company/{companyId}/warehouses`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (warehouseIds) {
                localVarQueryParameter['warehouse-ids'] = warehouseIds;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['order-by'] = orderBy;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} warehouseId 
         * @param {UpdateWarehouseBody} updateWarehouseBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        warehouseControllerUpdateWarehouse: async (warehouseId: number, updateWarehouseBody: UpdateWarehouseBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'warehouseId' is not null or undefined
            assertParamExists('warehouseControllerUpdateWarehouse', 'warehouseId', warehouseId)
            // verify required parameter 'updateWarehouseBody' is not null or undefined
            assertParamExists('warehouseControllerUpdateWarehouse', 'updateWarehouseBody', updateWarehouseBody)
            const localVarPath = `/warehouse/{warehouseId}`
                .replace(`{${"warehouseId"}}`, encodeURIComponent(String(warehouseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateWarehouseBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} warehousePositionId 
         * @param {AddWarehousePositionActionBuyOutsideBody} addWarehousePositionActionBuyOutsideBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        warehousePositionControllerAddWarehousePositionActionBuyOutside: async (warehousePositionId: number, addWarehousePositionActionBuyOutsideBody: AddWarehousePositionActionBuyOutsideBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'warehousePositionId' is not null or undefined
            assertParamExists('warehousePositionControllerAddWarehousePositionActionBuyOutside', 'warehousePositionId', warehousePositionId)
            // verify required parameter 'addWarehousePositionActionBuyOutsideBody' is not null or undefined
            assertParamExists('warehousePositionControllerAddWarehousePositionActionBuyOutside', 'addWarehousePositionActionBuyOutsideBody', addWarehousePositionActionBuyOutsideBody)
            const localVarPath = `/warehouse-position/{warehousePositionId}/action/buy-outside`
                .replace(`{${"warehousePositionId"}}`, encodeURIComponent(String(warehousePositionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addWarehousePositionActionBuyOutsideBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} warehousePositionId 
         * @param {AddWarehousePositionActionDecreaseBody} addWarehousePositionActionDecreaseBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        warehousePositionControllerAddWarehousePositionActionDecrease: async (warehousePositionId: number, addWarehousePositionActionDecreaseBody: AddWarehousePositionActionDecreaseBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'warehousePositionId' is not null or undefined
            assertParamExists('warehousePositionControllerAddWarehousePositionActionDecrease', 'warehousePositionId', warehousePositionId)
            // verify required parameter 'addWarehousePositionActionDecreaseBody' is not null or undefined
            assertParamExists('warehousePositionControllerAddWarehousePositionActionDecrease', 'addWarehousePositionActionDecreaseBody', addWarehousePositionActionDecreaseBody)
            const localVarPath = `/warehouse-position/{warehousePositionId}/action/decrease`
                .replace(`{${"warehousePositionId"}}`, encodeURIComponent(String(warehousePositionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addWarehousePositionActionDecreaseBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} warehousePositionId 
         * @param {AddWarehousePositionActionIncreaseBody} addWarehousePositionActionIncreaseBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        warehousePositionControllerAddWarehousePositionActionIncrease: async (warehousePositionId: number, addWarehousePositionActionIncreaseBody: AddWarehousePositionActionIncreaseBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'warehousePositionId' is not null or undefined
            assertParamExists('warehousePositionControllerAddWarehousePositionActionIncrease', 'warehousePositionId', warehousePositionId)
            // verify required parameter 'addWarehousePositionActionIncreaseBody' is not null or undefined
            assertParamExists('warehousePositionControllerAddWarehousePositionActionIncrease', 'addWarehousePositionActionIncreaseBody', addWarehousePositionActionIncreaseBody)
            const localVarPath = `/warehouse-position/{warehousePositionId}/action/increase`
                .replace(`{${"warehousePositionId"}}`, encodeURIComponent(String(warehousePositionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addWarehousePositionActionIncreaseBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} warehousePositionId 
         * @param {AddWarehousePositionActionSellOutsideBody} addWarehousePositionActionSellOutsideBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        warehousePositionControllerAddWarehousePositionActionSellOutside: async (warehousePositionId: number, addWarehousePositionActionSellOutsideBody: AddWarehousePositionActionSellOutsideBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'warehousePositionId' is not null or undefined
            assertParamExists('warehousePositionControllerAddWarehousePositionActionSellOutside', 'warehousePositionId', warehousePositionId)
            // verify required parameter 'addWarehousePositionActionSellOutsideBody' is not null or undefined
            assertParamExists('warehousePositionControllerAddWarehousePositionActionSellOutside', 'addWarehousePositionActionSellOutsideBody', addWarehousePositionActionSellOutsideBody)
            const localVarPath = `/warehouse-position/{warehousePositionId}/action/sell-outside`
                .replace(`{${"warehousePositionId"}}`, encodeURIComponent(String(warehousePositionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addWarehousePositionActionSellOutsideBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} warehousePositionId 
         * @param {AddWarehousePositionActionTransferFromBody} addWarehousePositionActionTransferFromBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        warehousePositionControllerAddWarehousePositionActionTransferFrom: async (warehousePositionId: number, addWarehousePositionActionTransferFromBody: AddWarehousePositionActionTransferFromBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'warehousePositionId' is not null or undefined
            assertParamExists('warehousePositionControllerAddWarehousePositionActionTransferFrom', 'warehousePositionId', warehousePositionId)
            // verify required parameter 'addWarehousePositionActionTransferFromBody' is not null or undefined
            assertParamExists('warehousePositionControllerAddWarehousePositionActionTransferFrom', 'addWarehousePositionActionTransferFromBody', addWarehousePositionActionTransferFromBody)
            const localVarPath = `/warehouse-position/{warehousePositionId}/action/transfer-from`
                .replace(`{${"warehousePositionId"}}`, encodeURIComponent(String(warehousePositionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addWarehousePositionActionTransferFromBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} warehousePositionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        warehousePositionControllerDeleteWarehousePosition: async (warehousePositionId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'warehousePositionId' is not null or undefined
            assertParamExists('warehousePositionControllerDeleteWarehousePosition', 'warehousePositionId', warehousePositionId)
            const localVarPath = `/warehouse-position/{warehousePositionId}`
                .replace(`{${"warehousePositionId"}}`, encodeURIComponent(String(warehousePositionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} warehousePositionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        warehousePositionControllerGetWarehousePositionActions: async (warehousePositionId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'warehousePositionId' is not null or undefined
            assertParamExists('warehousePositionControllerGetWarehousePositionActions', 'warehousePositionId', warehousePositionId)
            const localVarPath = `/warehouse-position/{warehousePositionId}/actions`
                .replace(`{${"warehousePositionId"}}`, encodeURIComponent(String(warehousePositionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} warehouseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        warehousePositionControllerGetWarehousePositions: async (warehouseId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'warehouseId' is not null or undefined
            assertParamExists('warehousePositionControllerGetWarehousePositions', 'warehouseId', warehouseId)
            const localVarPath = `/warehouse/{warehouseId}/positions`
                .replace(`{${"warehouseId"}}`, encodeURIComponent(String(warehouseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WarehouseApi - functional programming interface
 * @export
 */
export const WarehouseApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = WarehouseApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Создать новый товар на складе
         * @param {number} warehouseId 
         * @param {CreateWarehousePositionBody} createWarehousePositionBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createWarehousePosition(warehouseId: number, createWarehousePositionBody: CreateWarehousePositionBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateWarehousePositionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createWarehousePosition(warehouseId, createWarehousePositionBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} companyId 
         * @param {CreateWarehouseBody} createWarehouseBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async warehouseControllerCreateWarehouse(companyId: number, createWarehouseBody: CreateWarehouseBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateWarehouseResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.warehouseControllerCreateWarehouse(companyId, createWarehouseBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} warehouseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async warehouseControllerDeleteWarehouse(warehouseId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.warehouseControllerDeleteWarehouse(warehouseId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} warehouseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async warehouseControllerGetWarehouse(warehouseId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetWarehouseResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.warehouseControllerGetWarehouse(warehouseId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} companyId 
         * @param {Array<number>} [warehouseIds] Список id складов
         * @param {'id' | 'name' | 'address' | 'openingTime' | 'closingTime' | 'gateHeight' | 'scalesWeightLimit' | 'scalesLength' | 'totalVolume' | 'createdAt'} [orderBy] Поле для сортировки
         * @param {OrderDirection} [order] Направление сортировки
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async warehouseControllerGetWarehouses(companyId: number, warehouseIds?: Array<number>, orderBy?: 'id' | 'name' | 'address' | 'openingTime' | 'closingTime' | 'gateHeight' | 'scalesWeightLimit' | 'scalesLength' | 'totalVolume' | 'createdAt', order?: OrderDirection, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetWarehousesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.warehouseControllerGetWarehouses(companyId, warehouseIds, orderBy, order, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} warehouseId 
         * @param {UpdateWarehouseBody} updateWarehouseBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async warehouseControllerUpdateWarehouse(warehouseId: number, updateWarehouseBody: UpdateWarehouseBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.warehouseControllerUpdateWarehouse(warehouseId, updateWarehouseBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} warehousePositionId 
         * @param {AddWarehousePositionActionBuyOutsideBody} addWarehousePositionActionBuyOutsideBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async warehousePositionControllerAddWarehousePositionActionBuyOutside(warehousePositionId: number, addWarehousePositionActionBuyOutsideBody: AddWarehousePositionActionBuyOutsideBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.warehousePositionControllerAddWarehousePositionActionBuyOutside(warehousePositionId, addWarehousePositionActionBuyOutsideBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} warehousePositionId 
         * @param {AddWarehousePositionActionDecreaseBody} addWarehousePositionActionDecreaseBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async warehousePositionControllerAddWarehousePositionActionDecrease(warehousePositionId: number, addWarehousePositionActionDecreaseBody: AddWarehousePositionActionDecreaseBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.warehousePositionControllerAddWarehousePositionActionDecrease(warehousePositionId, addWarehousePositionActionDecreaseBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} warehousePositionId 
         * @param {AddWarehousePositionActionIncreaseBody} addWarehousePositionActionIncreaseBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async warehousePositionControllerAddWarehousePositionActionIncrease(warehousePositionId: number, addWarehousePositionActionIncreaseBody: AddWarehousePositionActionIncreaseBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.warehousePositionControllerAddWarehousePositionActionIncrease(warehousePositionId, addWarehousePositionActionIncreaseBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} warehousePositionId 
         * @param {AddWarehousePositionActionSellOutsideBody} addWarehousePositionActionSellOutsideBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async warehousePositionControllerAddWarehousePositionActionSellOutside(warehousePositionId: number, addWarehousePositionActionSellOutsideBody: AddWarehousePositionActionSellOutsideBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.warehousePositionControllerAddWarehousePositionActionSellOutside(warehousePositionId, addWarehousePositionActionSellOutsideBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} warehousePositionId 
         * @param {AddWarehousePositionActionTransferFromBody} addWarehousePositionActionTransferFromBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async warehousePositionControllerAddWarehousePositionActionTransferFrom(warehousePositionId: number, addWarehousePositionActionTransferFromBody: AddWarehousePositionActionTransferFromBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.warehousePositionControllerAddWarehousePositionActionTransferFrom(warehousePositionId, addWarehousePositionActionTransferFromBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} warehousePositionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async warehousePositionControllerDeleteWarehousePosition(warehousePositionId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.warehousePositionControllerDeleteWarehousePosition(warehousePositionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} warehousePositionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async warehousePositionControllerGetWarehousePositionActions(warehousePositionId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetWarehousePositionActionsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.warehousePositionControllerGetWarehousePositionActions(warehousePositionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} warehouseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async warehousePositionControllerGetWarehousePositions(warehouseId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetWarehousePositionsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.warehousePositionControllerGetWarehousePositions(warehouseId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * WarehouseApi - factory interface
 * @export
 */
export const WarehouseApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = WarehouseApiFp(configuration)
    return {
        /**
         * 
         * @summary Создать новый товар на складе
         * @param {number} warehouseId 
         * @param {CreateWarehousePositionBody} createWarehousePositionBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWarehousePosition(warehouseId: number, createWarehousePositionBody: CreateWarehousePositionBody, options?: any): AxiosPromise<CreateWarehousePositionResponse> {
            return localVarFp.createWarehousePosition(warehouseId, createWarehousePositionBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} companyId 
         * @param {CreateWarehouseBody} createWarehouseBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        warehouseControllerCreateWarehouse(companyId: number, createWarehouseBody: CreateWarehouseBody, options?: any): AxiosPromise<CreateWarehouseResponse> {
            return localVarFp.warehouseControllerCreateWarehouse(companyId, createWarehouseBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} warehouseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        warehouseControllerDeleteWarehouse(warehouseId: number, options?: any): AxiosPromise<void> {
            return localVarFp.warehouseControllerDeleteWarehouse(warehouseId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} warehouseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        warehouseControllerGetWarehouse(warehouseId: number, options?: any): AxiosPromise<GetWarehouseResponse> {
            return localVarFp.warehouseControllerGetWarehouse(warehouseId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} companyId 
         * @param {Array<number>} [warehouseIds] Список id складов
         * @param {'id' | 'name' | 'address' | 'openingTime' | 'closingTime' | 'gateHeight' | 'scalesWeightLimit' | 'scalesLength' | 'totalVolume' | 'createdAt'} [orderBy] Поле для сортировки
         * @param {OrderDirection} [order] Направление сортировки
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        warehouseControllerGetWarehouses(companyId: number, warehouseIds?: Array<number>, orderBy?: 'id' | 'name' | 'address' | 'openingTime' | 'closingTime' | 'gateHeight' | 'scalesWeightLimit' | 'scalesLength' | 'totalVolume' | 'createdAt', order?: OrderDirection, options?: any): AxiosPromise<GetWarehousesResponse> {
            return localVarFp.warehouseControllerGetWarehouses(companyId, warehouseIds, orderBy, order, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} warehouseId 
         * @param {UpdateWarehouseBody} updateWarehouseBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        warehouseControllerUpdateWarehouse(warehouseId: number, updateWarehouseBody: UpdateWarehouseBody, options?: any): AxiosPromise<void> {
            return localVarFp.warehouseControllerUpdateWarehouse(warehouseId, updateWarehouseBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} warehousePositionId 
         * @param {AddWarehousePositionActionBuyOutsideBody} addWarehousePositionActionBuyOutsideBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        warehousePositionControllerAddWarehousePositionActionBuyOutside(warehousePositionId: number, addWarehousePositionActionBuyOutsideBody: AddWarehousePositionActionBuyOutsideBody, options?: any): AxiosPromise<void> {
            return localVarFp.warehousePositionControllerAddWarehousePositionActionBuyOutside(warehousePositionId, addWarehousePositionActionBuyOutsideBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} warehousePositionId 
         * @param {AddWarehousePositionActionDecreaseBody} addWarehousePositionActionDecreaseBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        warehousePositionControllerAddWarehousePositionActionDecrease(warehousePositionId: number, addWarehousePositionActionDecreaseBody: AddWarehousePositionActionDecreaseBody, options?: any): AxiosPromise<void> {
            return localVarFp.warehousePositionControllerAddWarehousePositionActionDecrease(warehousePositionId, addWarehousePositionActionDecreaseBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} warehousePositionId 
         * @param {AddWarehousePositionActionIncreaseBody} addWarehousePositionActionIncreaseBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        warehousePositionControllerAddWarehousePositionActionIncrease(warehousePositionId: number, addWarehousePositionActionIncreaseBody: AddWarehousePositionActionIncreaseBody, options?: any): AxiosPromise<void> {
            return localVarFp.warehousePositionControllerAddWarehousePositionActionIncrease(warehousePositionId, addWarehousePositionActionIncreaseBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} warehousePositionId 
         * @param {AddWarehousePositionActionSellOutsideBody} addWarehousePositionActionSellOutsideBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        warehousePositionControllerAddWarehousePositionActionSellOutside(warehousePositionId: number, addWarehousePositionActionSellOutsideBody: AddWarehousePositionActionSellOutsideBody, options?: any): AxiosPromise<void> {
            return localVarFp.warehousePositionControllerAddWarehousePositionActionSellOutside(warehousePositionId, addWarehousePositionActionSellOutsideBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} warehousePositionId 
         * @param {AddWarehousePositionActionTransferFromBody} addWarehousePositionActionTransferFromBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        warehousePositionControllerAddWarehousePositionActionTransferFrom(warehousePositionId: number, addWarehousePositionActionTransferFromBody: AddWarehousePositionActionTransferFromBody, options?: any): AxiosPromise<void> {
            return localVarFp.warehousePositionControllerAddWarehousePositionActionTransferFrom(warehousePositionId, addWarehousePositionActionTransferFromBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} warehousePositionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        warehousePositionControllerDeleteWarehousePosition(warehousePositionId: number, options?: any): AxiosPromise<void> {
            return localVarFp.warehousePositionControllerDeleteWarehousePosition(warehousePositionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} warehousePositionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        warehousePositionControllerGetWarehousePositionActions(warehousePositionId: number, options?: any): AxiosPromise<GetWarehousePositionActionsResponse> {
            return localVarFp.warehousePositionControllerGetWarehousePositionActions(warehousePositionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} warehouseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        warehousePositionControllerGetWarehousePositions(warehouseId: number, options?: any): AxiosPromise<GetWarehousePositionsResponse> {
            return localVarFp.warehousePositionControllerGetWarehousePositions(warehouseId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createWarehousePosition operation in WarehouseApi.
 * @export
 * @interface WarehouseApiCreateWarehousePositionRequest
 */
export interface WarehouseApiCreateWarehousePositionRequest {
    /**
     * 
     * @type {number}
     * @memberof WarehouseApiCreateWarehousePosition
     */
    readonly warehouseId: number

    /**
     * 
     * @type {CreateWarehousePositionBody}
     * @memberof WarehouseApiCreateWarehousePosition
     */
    readonly createWarehousePositionBody: CreateWarehousePositionBody
}

/**
 * Request parameters for warehouseControllerCreateWarehouse operation in WarehouseApi.
 * @export
 * @interface WarehouseApiWarehouseControllerCreateWarehouseRequest
 */
export interface WarehouseApiWarehouseControllerCreateWarehouseRequest {
    /**
     * 
     * @type {number}
     * @memberof WarehouseApiWarehouseControllerCreateWarehouse
     */
    readonly companyId: number

    /**
     * 
     * @type {CreateWarehouseBody}
     * @memberof WarehouseApiWarehouseControllerCreateWarehouse
     */
    readonly createWarehouseBody: CreateWarehouseBody
}

/**
 * Request parameters for warehouseControllerDeleteWarehouse operation in WarehouseApi.
 * @export
 * @interface WarehouseApiWarehouseControllerDeleteWarehouseRequest
 */
export interface WarehouseApiWarehouseControllerDeleteWarehouseRequest {
    /**
     * 
     * @type {number}
     * @memberof WarehouseApiWarehouseControllerDeleteWarehouse
     */
    readonly warehouseId: number
}

/**
 * Request parameters for warehouseControllerGetWarehouse operation in WarehouseApi.
 * @export
 * @interface WarehouseApiWarehouseControllerGetWarehouseRequest
 */
export interface WarehouseApiWarehouseControllerGetWarehouseRequest {
    /**
     * 
     * @type {number}
     * @memberof WarehouseApiWarehouseControllerGetWarehouse
     */
    readonly warehouseId: number
}

/**
 * Request parameters for warehouseControllerGetWarehouses operation in WarehouseApi.
 * @export
 * @interface WarehouseApiWarehouseControllerGetWarehousesRequest
 */
export interface WarehouseApiWarehouseControllerGetWarehousesRequest {
    /**
     * 
     * @type {number}
     * @memberof WarehouseApiWarehouseControllerGetWarehouses
     */
    readonly companyId: number

    /**
     * Список id складов
     * @type {Array<number>}
     * @memberof WarehouseApiWarehouseControllerGetWarehouses
     */
    readonly warehouseIds?: Array<number>

    /**
     * Поле для сортировки
     * @type {'id' | 'name' | 'address' | 'openingTime' | 'closingTime' | 'gateHeight' | 'scalesWeightLimit' | 'scalesLength' | 'totalVolume' | 'createdAt'}
     * @memberof WarehouseApiWarehouseControllerGetWarehouses
     */
    readonly orderBy?: 'id' | 'name' | 'address' | 'openingTime' | 'closingTime' | 'gateHeight' | 'scalesWeightLimit' | 'scalesLength' | 'totalVolume' | 'createdAt'

    /**
     * Направление сортировки
     * @type {OrderDirection}
     * @memberof WarehouseApiWarehouseControllerGetWarehouses
     */
    readonly order?: OrderDirection
}

/**
 * Request parameters for warehouseControllerUpdateWarehouse operation in WarehouseApi.
 * @export
 * @interface WarehouseApiWarehouseControllerUpdateWarehouseRequest
 */
export interface WarehouseApiWarehouseControllerUpdateWarehouseRequest {
    /**
     * 
     * @type {number}
     * @memberof WarehouseApiWarehouseControllerUpdateWarehouse
     */
    readonly warehouseId: number

    /**
     * 
     * @type {UpdateWarehouseBody}
     * @memberof WarehouseApiWarehouseControllerUpdateWarehouse
     */
    readonly updateWarehouseBody: UpdateWarehouseBody
}

/**
 * Request parameters for warehousePositionControllerAddWarehousePositionActionBuyOutside operation in WarehouseApi.
 * @export
 * @interface WarehouseApiWarehousePositionControllerAddWarehousePositionActionBuyOutsideRequest
 */
export interface WarehouseApiWarehousePositionControllerAddWarehousePositionActionBuyOutsideRequest {
    /**
     * 
     * @type {number}
     * @memberof WarehouseApiWarehousePositionControllerAddWarehousePositionActionBuyOutside
     */
    readonly warehousePositionId: number

    /**
     * 
     * @type {AddWarehousePositionActionBuyOutsideBody}
     * @memberof WarehouseApiWarehousePositionControllerAddWarehousePositionActionBuyOutside
     */
    readonly addWarehousePositionActionBuyOutsideBody: AddWarehousePositionActionBuyOutsideBody
}

/**
 * Request parameters for warehousePositionControllerAddWarehousePositionActionDecrease operation in WarehouseApi.
 * @export
 * @interface WarehouseApiWarehousePositionControllerAddWarehousePositionActionDecreaseRequest
 */
export interface WarehouseApiWarehousePositionControllerAddWarehousePositionActionDecreaseRequest {
    /**
     * 
     * @type {number}
     * @memberof WarehouseApiWarehousePositionControllerAddWarehousePositionActionDecrease
     */
    readonly warehousePositionId: number

    /**
     * 
     * @type {AddWarehousePositionActionDecreaseBody}
     * @memberof WarehouseApiWarehousePositionControllerAddWarehousePositionActionDecrease
     */
    readonly addWarehousePositionActionDecreaseBody: AddWarehousePositionActionDecreaseBody
}

/**
 * Request parameters for warehousePositionControllerAddWarehousePositionActionIncrease operation in WarehouseApi.
 * @export
 * @interface WarehouseApiWarehousePositionControllerAddWarehousePositionActionIncreaseRequest
 */
export interface WarehouseApiWarehousePositionControllerAddWarehousePositionActionIncreaseRequest {
    /**
     * 
     * @type {number}
     * @memberof WarehouseApiWarehousePositionControllerAddWarehousePositionActionIncrease
     */
    readonly warehousePositionId: number

    /**
     * 
     * @type {AddWarehousePositionActionIncreaseBody}
     * @memberof WarehouseApiWarehousePositionControllerAddWarehousePositionActionIncrease
     */
    readonly addWarehousePositionActionIncreaseBody: AddWarehousePositionActionIncreaseBody
}

/**
 * Request parameters for warehousePositionControllerAddWarehousePositionActionSellOutside operation in WarehouseApi.
 * @export
 * @interface WarehouseApiWarehousePositionControllerAddWarehousePositionActionSellOutsideRequest
 */
export interface WarehouseApiWarehousePositionControllerAddWarehousePositionActionSellOutsideRequest {
    /**
     * 
     * @type {number}
     * @memberof WarehouseApiWarehousePositionControllerAddWarehousePositionActionSellOutside
     */
    readonly warehousePositionId: number

    /**
     * 
     * @type {AddWarehousePositionActionSellOutsideBody}
     * @memberof WarehouseApiWarehousePositionControllerAddWarehousePositionActionSellOutside
     */
    readonly addWarehousePositionActionSellOutsideBody: AddWarehousePositionActionSellOutsideBody
}

/**
 * Request parameters for warehousePositionControllerAddWarehousePositionActionTransferFrom operation in WarehouseApi.
 * @export
 * @interface WarehouseApiWarehousePositionControllerAddWarehousePositionActionTransferFromRequest
 */
export interface WarehouseApiWarehousePositionControllerAddWarehousePositionActionTransferFromRequest {
    /**
     * 
     * @type {number}
     * @memberof WarehouseApiWarehousePositionControllerAddWarehousePositionActionTransferFrom
     */
    readonly warehousePositionId: number

    /**
     * 
     * @type {AddWarehousePositionActionTransferFromBody}
     * @memberof WarehouseApiWarehousePositionControllerAddWarehousePositionActionTransferFrom
     */
    readonly addWarehousePositionActionTransferFromBody: AddWarehousePositionActionTransferFromBody
}

/**
 * Request parameters for warehousePositionControllerDeleteWarehousePosition operation in WarehouseApi.
 * @export
 * @interface WarehouseApiWarehousePositionControllerDeleteWarehousePositionRequest
 */
export interface WarehouseApiWarehousePositionControllerDeleteWarehousePositionRequest {
    /**
     * 
     * @type {number}
     * @memberof WarehouseApiWarehousePositionControllerDeleteWarehousePosition
     */
    readonly warehousePositionId: number
}

/**
 * Request parameters for warehousePositionControllerGetWarehousePositionActions operation in WarehouseApi.
 * @export
 * @interface WarehouseApiWarehousePositionControllerGetWarehousePositionActionsRequest
 */
export interface WarehouseApiWarehousePositionControllerGetWarehousePositionActionsRequest {
    /**
     * 
     * @type {number}
     * @memberof WarehouseApiWarehousePositionControllerGetWarehousePositionActions
     */
    readonly warehousePositionId: number
}

/**
 * Request parameters for warehousePositionControllerGetWarehousePositions operation in WarehouseApi.
 * @export
 * @interface WarehouseApiWarehousePositionControllerGetWarehousePositionsRequest
 */
export interface WarehouseApiWarehousePositionControllerGetWarehousePositionsRequest {
    /**
     * 
     * @type {number}
     * @memberof WarehouseApiWarehousePositionControllerGetWarehousePositions
     */
    readonly warehouseId: number
}

/**
 * WarehouseApi - object-oriented interface
 * @export
 * @class WarehouseApi
 * @extends {BaseAPI}
 */
export class WarehouseApi extends BaseAPI {
    /**
     * 
     * @summary Создать новый товар на складе
     * @param {WarehouseApiCreateWarehousePositionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WarehouseApi
     */
    public createWarehousePosition(requestParameters: WarehouseApiCreateWarehousePositionRequest, options?: AxiosRequestConfig) {
        return WarehouseApiFp(this.configuration).createWarehousePosition(requestParameters.warehouseId, requestParameters.createWarehousePositionBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {WarehouseApiWarehouseControllerCreateWarehouseRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WarehouseApi
     */
    public warehouseControllerCreateWarehouse(requestParameters: WarehouseApiWarehouseControllerCreateWarehouseRequest, options?: AxiosRequestConfig) {
        return WarehouseApiFp(this.configuration).warehouseControllerCreateWarehouse(requestParameters.companyId, requestParameters.createWarehouseBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {WarehouseApiWarehouseControllerDeleteWarehouseRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WarehouseApi
     */
    public warehouseControllerDeleteWarehouse(requestParameters: WarehouseApiWarehouseControllerDeleteWarehouseRequest, options?: AxiosRequestConfig) {
        return WarehouseApiFp(this.configuration).warehouseControllerDeleteWarehouse(requestParameters.warehouseId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {WarehouseApiWarehouseControllerGetWarehouseRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WarehouseApi
     */
    public warehouseControllerGetWarehouse(requestParameters: WarehouseApiWarehouseControllerGetWarehouseRequest, options?: AxiosRequestConfig) {
        return WarehouseApiFp(this.configuration).warehouseControllerGetWarehouse(requestParameters.warehouseId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {WarehouseApiWarehouseControllerGetWarehousesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WarehouseApi
     */
    public warehouseControllerGetWarehouses(requestParameters: WarehouseApiWarehouseControllerGetWarehousesRequest, options?: AxiosRequestConfig) {
        return WarehouseApiFp(this.configuration).warehouseControllerGetWarehouses(requestParameters.companyId, requestParameters.warehouseIds, requestParameters.orderBy, requestParameters.order, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {WarehouseApiWarehouseControllerUpdateWarehouseRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WarehouseApi
     */
    public warehouseControllerUpdateWarehouse(requestParameters: WarehouseApiWarehouseControllerUpdateWarehouseRequest, options?: AxiosRequestConfig) {
        return WarehouseApiFp(this.configuration).warehouseControllerUpdateWarehouse(requestParameters.warehouseId, requestParameters.updateWarehouseBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {WarehouseApiWarehousePositionControllerAddWarehousePositionActionBuyOutsideRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WarehouseApi
     */
    public warehousePositionControllerAddWarehousePositionActionBuyOutside(requestParameters: WarehouseApiWarehousePositionControllerAddWarehousePositionActionBuyOutsideRequest, options?: AxiosRequestConfig) {
        return WarehouseApiFp(this.configuration).warehousePositionControllerAddWarehousePositionActionBuyOutside(requestParameters.warehousePositionId, requestParameters.addWarehousePositionActionBuyOutsideBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {WarehouseApiWarehousePositionControllerAddWarehousePositionActionDecreaseRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WarehouseApi
     */
    public warehousePositionControllerAddWarehousePositionActionDecrease(requestParameters: WarehouseApiWarehousePositionControllerAddWarehousePositionActionDecreaseRequest, options?: AxiosRequestConfig) {
        return WarehouseApiFp(this.configuration).warehousePositionControllerAddWarehousePositionActionDecrease(requestParameters.warehousePositionId, requestParameters.addWarehousePositionActionDecreaseBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {WarehouseApiWarehousePositionControllerAddWarehousePositionActionIncreaseRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WarehouseApi
     */
    public warehousePositionControllerAddWarehousePositionActionIncrease(requestParameters: WarehouseApiWarehousePositionControllerAddWarehousePositionActionIncreaseRequest, options?: AxiosRequestConfig) {
        return WarehouseApiFp(this.configuration).warehousePositionControllerAddWarehousePositionActionIncrease(requestParameters.warehousePositionId, requestParameters.addWarehousePositionActionIncreaseBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {WarehouseApiWarehousePositionControllerAddWarehousePositionActionSellOutsideRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WarehouseApi
     */
    public warehousePositionControllerAddWarehousePositionActionSellOutside(requestParameters: WarehouseApiWarehousePositionControllerAddWarehousePositionActionSellOutsideRequest, options?: AxiosRequestConfig) {
        return WarehouseApiFp(this.configuration).warehousePositionControllerAddWarehousePositionActionSellOutside(requestParameters.warehousePositionId, requestParameters.addWarehousePositionActionSellOutsideBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {WarehouseApiWarehousePositionControllerAddWarehousePositionActionTransferFromRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WarehouseApi
     */
    public warehousePositionControllerAddWarehousePositionActionTransferFrom(requestParameters: WarehouseApiWarehousePositionControllerAddWarehousePositionActionTransferFromRequest, options?: AxiosRequestConfig) {
        return WarehouseApiFp(this.configuration).warehousePositionControllerAddWarehousePositionActionTransferFrom(requestParameters.warehousePositionId, requestParameters.addWarehousePositionActionTransferFromBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {WarehouseApiWarehousePositionControllerDeleteWarehousePositionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WarehouseApi
     */
    public warehousePositionControllerDeleteWarehousePosition(requestParameters: WarehouseApiWarehousePositionControllerDeleteWarehousePositionRequest, options?: AxiosRequestConfig) {
        return WarehouseApiFp(this.configuration).warehousePositionControllerDeleteWarehousePosition(requestParameters.warehousePositionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {WarehouseApiWarehousePositionControllerGetWarehousePositionActionsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WarehouseApi
     */
    public warehousePositionControllerGetWarehousePositionActions(requestParameters: WarehouseApiWarehousePositionControllerGetWarehousePositionActionsRequest, options?: AxiosRequestConfig) {
        return WarehouseApiFp(this.configuration).warehousePositionControllerGetWarehousePositionActions(requestParameters.warehousePositionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {WarehouseApiWarehousePositionControllerGetWarehousePositionsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WarehouseApi
     */
    public warehousePositionControllerGetWarehousePositions(requestParameters: WarehouseApiWarehousePositionControllerGetWarehousePositionsRequest, options?: AxiosRequestConfig) {
        return WarehouseApiFp(this.configuration).warehousePositionControllerGetWarehousePositions(requestParameters.warehouseId, options).then((request) => request(this.axios, this.basePath));
    }
}
