import { breakpoints, mediaQueries } from './breakpoints'
import { colors } from './colors'
import { radii } from './radii'
import { shadows } from './shadows'
import { space } from './space'
import type { CustomTheme } from './types'
import { typography } from './typography'
import { zIndices } from './zIndices'

const commonTheme = {
  typography,
  space,
  breakpoints,
  mediaQueries,
  radii,
  zIndices,
}

export const light: CustomTheme = {
  mode: 'light',
  colors: colors.light,
  shadows: shadows.light,
  ...commonTheme,
}

// export const dark: CustomTheme = {
//   mode: 'dark',
//   // @TODO Исправить после добавления темной темы
//   colors: colors.dark as CustomTheme['colors'],
//   shadows: shadows.dark,
//   ...commonTheme,
// };
