import * as yup from 'yup'

import { useGetCompanyZernoAgregator } from '~hooks/company/useGetCompanyZernoAgregator'
import { useCheckCreateUserForCompany } from '~hooks/user'
import { addressSchema, VALIDATORS_MSG } from '~shared/validations'
import { fullPhoneNumberValidatorNotRequired } from '~shared/validations/schemas'

const requiredTrimmedString = yup.string().trim()
const notRequiredTrimmedString = yup.string().trim().notRequired()

export const useGetCreateLotAdminModalSchema = () => {
  const checkCreateUserForCompany = useCheckCreateUserForCompany()
  const companyZernoAgregator = useGetCompanyZernoAgregator()

  return yup.object({
    offerType: yup.mixed().oneOf(['BUY', 'SELL']),
    address: addressSchema,
    volume: yup
      .number()
      .typeError(VALIDATORS_MSG.required)
      .min(200, VALIDATORS_MSG.minValue(200))
      .required(VALIDATORS_MSG.required),
    price: yup
      .number()
      .typeError(VALIDATORS_MSG.required)
      .min(1000, VALIDATORS_MSG.minValue(1000))
      .required(VALIDATORS_MSG.required),
    culture: yup
      .object({
        // hasError: yup.bool().oneOf([false], 'Параметры культуры заполнены с ошибками'),
      })
      .required(VALIDATORS_MSG.required),
    companyInfo: yup
      .object({
        name: notRequiredTrimmedString,
        inn: notRequiredTrimmedString,
        kpp: notRequiredTrimmedString,
        codeOkved: notRequiredTrimmedString,
        director: notRequiredTrimmedString,
        actualAddress: notRequiredTrimmedString,
        legalAddress: notRequiredTrimmedString,
      })
      .when('company', (company, schema) => {
        return company === 'other'
          ? yup.object({
              name: requiredTrimmedString,
              inn: requiredTrimmedString,
              kpp: notRequiredTrimmedString,
              codeOkved: requiredTrimmedString,
              director: notRequiredTrimmedString,
              actualAddress: notRequiredTrimmedString,
              legalAddress: notRequiredTrimmedString,
            })
          : schema
      }),
    aicCharterMember: yup.boolean().required(VALIDATORS_MSG.required),
    contactUser: requiredTrimmedString,
    contactPhone: yup
      .string()
      .trim()
      .test('mobilePhone', VALIDATORS_MSG.notFullPhoneNumber, fullPhoneNumberValidatorNotRequired)
      .test({
        name: 'contactPhone',
        exclusive: true,
        params: {},
        message: 'Пользователь привязан к другой компании',
        test: function (phoneNumber) {
          const inn = this.parent.companyInfo?.inn || companyZernoAgregator.data?.inn
          if (!phoneNumber || !inn) return true
          return new Promise((resolve) => {
            checkCreateUserForCompany
              .mutateAsync({
                phoneNumber,
                inn,
              })
              .then((data) => resolve(data))
          })
        },
      }),
    labAnalysis: yup.mixed().when('offerType', {
      is: (val: 'buy' | 'sell') => val === 'sell',
      then: yup.mixed().required(VALIDATORS_MSG.required),
      otherwise: yup.mixed().notRequired(),
    }),
  })
}
