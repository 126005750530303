import styled from 'styled-components'

import css from '@styled-system/css'

export const Description = styled.div(
  css({
    color: 'Gray500',
    fontSize: '12px',
    lineHeight: '16px',
    marginTop: '-20px',
    marginBottom: '36px',
  })
)
