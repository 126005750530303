import styled from 'styled-components'

import css from '@styled-system/css'

export const Form = styled('form')(
  css({
    display: 'flex',
    flexDirection: 'column',
    width: ['100%', null, '364px'],
  })
)
