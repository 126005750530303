import { useMutation } from '@tanstack/react-query'

import { DealDocumentApiDealDocumentControllerGetDocumentsZipRequest } from '~/api/generated'
import { useApi } from '~providers/api'

interface Props {
  request: DealDocumentApiDealDocumentControllerGetDocumentsZipRequest
  name?: string
}

export const useGetDealDocumentsZip = () => {
  const api = useApi()

  const fetchFn = async ({ request, name }: Props) => {
    try {
      const { data } = await api.dealDocument.dealDocumentControllerGetDocumentsZip(request, {
        responseType: 'blob',
      })
      const fileURL = window.URL.createObjectURL(new Blob([data as unknown as BlobPart]))
      const fileLink = window.document.createElement('a')
      fileLink.href = fileURL
      fileLink.download = `${name ?? 'documents'}.zip`
      window.document.body.appendChild(fileLink)
      fileLink.click()
      fileLink.remove()
      URL.revokeObjectURL(fileLink.href)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  return useMutation(fetchFn)
}
