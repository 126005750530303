import React, { FC } from 'react'

import { Steps } from 'antd'

import { CompanyDealDataStatusEnum } from '~api/generated'
import { useGetDevice } from '~hooks/common'

import { DealStepsProps } from './DealSteps.types'

const statuses = [
  [CompanyDealDataStatusEnum.Signing, CompanyDealDataStatusEnum.Agreement],
  [CompanyDealDataStatusEnum.Payment],
  [CompanyDealDataStatusEnum.Shipment],
  [CompanyDealDataStatusEnum.Realised],
]
export const DealSteps: FC<DealStepsProps> = ({ status }) => {
  const device = useGetDevice()
  const isMobile = device === 'mobile'
  const currentStep = statuses.findIndex((it) => it.includes(status as never))

  return (
    <Steps
      current={currentStep}
      size={isMobile ? 'small' : 'default'}
      items={[
        {
          title: 'Подписание',
        },
        {
          title: 'Оплата',
        },
        {
          title: 'Перевозка',
        },
        {
          title: 'Сделка завершена',
          status: currentStep === 3 ? 'finish' : undefined,
        },
      ]}
    />
  )
}
