import styled from 'styled-components'

import { Text } from '../Text'

export const Wrapper = styled.div<{ isVisible: boolean; grow: boolean }>`
  display: ${({ isVisible }) => (isVisible ? 'unset' : 'none')};
  flex-direction: column;
  flex-grow: ${({ grow }) => (grow ? 1 : 'unset')};
`

export const Label = styled(Text)`
  display: block;
  margin-bottom: 8px;
`

export const Required = styled('span')`
  color: var(--danger-color);
`

export const Message = styled(Text)`
  align-items: center;
  padding: 4px 0;
  min-height: 24px;
  color: var(--danger-color);
  box-sizing: border-box;
`
