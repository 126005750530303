import { useQuery, UseQueryOptions, UseQueryResult } from '@tanstack/react-query'

import {
  ElevatorAdvertOfferApiElevatorAdvertOfferControllerGetElevatorAdvertOffersRequest,
  GetElevatorAdvertOffersResponse,
} from '~api/generated'
import { useApi } from '~providers/api'

type Options = Omit<
  UseQueryOptions<GetElevatorAdvertOffersResponse, Error, GetElevatorAdvertOffersResponse, [string, any]>,
  'queryKey' | 'queryFn' | 'initialData'
> & { initialData?: () => undefined }
export const useGetElevatorAdvertOffers = (
  request: ElevatorAdvertOfferApiElevatorAdvertOfferControllerGetElevatorAdvertOffersRequest,
  options?: Options
): UseQueryResult<GetElevatorAdvertOffersResponse> => {
  const api = useApi()

  const fetchFn = async (): Promise<GetElevatorAdvertOffersResponse> => {
    const res = await api.elevatorAdvertOffer.elevatorAdvertOfferControllerGetElevatorAdvertOffers(request)

    return res.data
  }

  return useQuery(['elevatorAdvertOffers', request], fetchFn, { ...options })
}
