export interface IAppRoute {
  url: string
  title: string
}

export const appRoutes = {
  root: { url: '/' },

  auth: { url: '/auth', title: 'Вход' },

  welcomePage: { url: '/welcome', title: 'Добро пожаловать!' },

  /* Предложения */
  offers: { url: '/offers', title: 'Предложения' },
  offersList: { url: '/offers/list', title: 'Предложения' },
  offersListSell: { url: '/offers/list-sell', title: 'Для продавцов' },
  offersListBuy: { url: '/offers/list-buy', title: 'Для покупателей' },
  offersListDetails: { url: '/offers/list/:id', title: '' },
  offersListSellDetails: { url: '/offers/list-sell/:id', title: '' },
  offersListBuyDetails: { url: '/offers/list-buy/:id', title: '' },
  offersMy: { url: '/offers/own', title: 'Мои предложения' },
  offersMyDetails: { url: '/offers/own/:id', title: '' },
  offersResponses: { url: '/offers/responses', title: 'Мои отклики' },
  offersResponsesDetails: { url: '/offers/responses/:id', title: '' },

  /* Кабинет модератора */
  moderator: { url: '/moderator', title: 'Администрирование' },
  moderatorUsers: { url: '/moderator/users', title: 'Пользователи' },
  moderatorCompanies: { url: '/moderator/companies', title: 'Компании' },
  moderatorOffers: { url: '/moderator/offers', title: 'Предложения' },
  moderatorOfferInformation: { url: '/moderator/offers/:id', title: '' },

  moderatorDeals: { url: '/moderator/deals', title: 'Сделки' },
  moderatorDealsList: { url: 'list', title: 'Сделки' },
  moderatorDealsResponsesList: { url: 'responses-list', title: 'Проверка отклика' },
  moderatorDealDetails: { url: '/moderator/deals/list/:id', title: 'Сделка' },
  moderatorDealResponseDetails: { url: '/moderator/deals/responses-list/:id', title: 'Отклик' },

  moderatorAuctions: { url: '/moderator/auctions', title: 'Торги' },
  moderatorAuctionDetails: { url: '/moderator/auctions/:id', title: 'Проверка лота №' },
  moderatorUserDetails: { url: '/moderator/users/:id', title: '' },
  moderatorCompanyDetails: { url: '/moderator/companies/:id', title: '' },
  moderatorCompanyInformation: { url: 'company', title: 'Данные о компании' },
  moderatorCompanyDocumentsInformation: { url: 'company-doc', title: 'Общие документы' },
  moderatorCompanyEmployees: { url: 'employees', title: 'Сотрудники' },

  moderatorBasis: { url: '/moderator/basis', title: 'Базисы' },
  moderatorBasisAdverts: { url: 'adverts', title: 'Объявления' },
  moderatorBasisAdvertDetails: { url: '/moderator/basis/adverts/:id', title: 'Проверка объявления №' },
  moderatorBasisAdvertOfferDetails: { url: '/moderator/basis/offers/:id', title: 'Проверка отклика №' },
  moderatorBasisBids: { url: 'bids', title: 'Отклики' },

  /* Сделки */
  deals: { url: '/deals', title: 'Сделки' },
  dealDetails: { url: '/deals/:id', title: 'Сделка' },
  dealDetailsInformation: { url: 'information', title: 'Информация о сделке' },
  dealDetailsPayment: { url: 'payment', title: 'Оплата' },
  dealDetailsLogistics: { url: 'logistics', title: 'Логистика' },
  dealDetailsDocuments: { url: 'documents', title: 'Документы' },

  /* Заявки */
  requests: { url: '/requests', title: 'Заявки' },
  requestDetails: { url: '/requests/:requestId', title: 'Заявка №' },
  requestOfferDetails: { url: '/requests/:requestId/offer/:offerId', title: 'Предложение №' },

  /* Торги */
  auctions: { url: '/auctions', title: 'Торги' },
  auctionLotsList: { url: '/auctions/lots-list', title: 'Список лотов' },
  auctionsMyLots: { url: '/auctions/my-lots', title: 'Мои лоты' },
  auctionsMyResponses: { url: '/auctions/my-responses', title: 'Мои ставки' },
  auctionLotsListDetails: { url: '/auctions/lots-list/:id', title: 'Торги за лот №' },
  auctionsMyLotsDetails: { url: '/auctions/my-lots/:id', title: 'Торги за лот №' },
  auctionsMyResponsesDetails: { url: '/auctions/my-responses/:id', title: 'Торги за лот №' },

  /* Базисы */
  basis: { url: '/basis', title: 'Базисы' },
  basisVip: { url: '/basis/vip/:id', title: 'К списку базисов' },
  basisList: { url: '/basis/basis-list', title: 'Все базисы' },
  basisMyBids: { url: '/basis/my-bids', title: 'Мои отклики' },
  basisMyAdvert: { url: '/basis/my-advert', title: 'Мои объявления' },
  basisDetails: { url: '/basis/basis-list/:id', title: 'Компании на базисе' },
  basisInfo: { url: '/basis-info/:id', title: 'Подробнее о базисе' },
  basisAdverts: { url: '/basis/basis-list/:id/adverts', title: 'Объявления всех компаний' },
  basisCompanyAdverts: { url: '/basis/basis-list/:basisId/company/:companyId/adverts', title: 'Объявления компании' },
  basisAdvert: { url: '/basis/advert/:id', title: 'Объявление №' },
  basisMyAdvertResponse: { url: '/basis/advert/:advertId/response/:id', title: 'Отклик №' },
  basisAdvertOffer: { url: '/basis/offer/:id', title: 'Отклик №' },

  /* Профиль */
  profile: { url: '/profile', title: 'Профиль' },
  profileEmployees: { url: 'employees', title: 'Личные Данные' },
  profileCompany: { url: 'company', title: 'Данные о компании' },
  profileDocuments: {
    url: 'documents',
    title: 'Документы компании',
  },

  /* Аккаунт */
  account: { url: '/account', title: 'Личный кабинет' },
  accountProfile: { url: '/account/user/profile', title: 'Личные данные' },
  accountCompanyMobileMenu: { url: '/account/company/menu/', title: 'Моя компания' },
  accountSelectCompanyMobileMenu: { url: '/account/company/menu/select-company', title: 'Все компании' },
  accountCompanyProfile: { url: '/account/company/profile', title: 'Общая информация' },
  accountCompanyRequisites: { url: '/account/company/requisites', title: 'Реквизиты компании' },
  accountCompanyPayRequisites: { url: '/account/company/pay-requisites', title: 'Платежные реквизиты' },
  accountCompanyDocs: { url: '/account/company/docs', title: 'Документы' },
  accountCompanyEmployees: { url: '/account/company/employees', title: 'Сотрудники' },

  /* Сотрудники */
  employees: { url: '/employees', title: 'Сотрудники' },

  /* Настройки */
  settings: { url: '/settings', title: 'Настройки' },
  settingsCultureParams: { url: 'culture-params', title: 'Параметры культуры' },
  settingsTemplateDocuments: { url: 'template-documents', title: 'Шаблоны документов' },
  settingsElevators: { url: '/settings/elevators-admin', title: 'Базисы' },
  settingsElevatorsDetails: { url: '/settings/elevators-admin/:id', title: 'Базис' },

  /* Автопарк */
  carPark: { url: '/car-park', title: 'Автопарк' },
  carParkTransportationData: { url: '/car-park/transportation-data', title: 'Транспортные данные' },
  carParkTransport: { url: '/car-park/transport', title: 'Транспорт' },
  carParkCreateTransportationData: { url: '/car-park/transportation-data/create', title: 'К автопарку' },
  carParkEditTransportationData: { url: '/car-park/transportation-data/edit', title: 'К автопарку' },
  carParkCreateTransport: { url: '/car-park/transport/create', title: 'К списку транспорта' },
  carParkEditTransport: { url: '/car-park/transport/edit/:id', title: 'К транспорту' },
  carParkTransportCard: { url: '/car-park/transport/card/:id', title: 'К списку транспорта' },

  /* Автопарк. Водители */
  carParkCreateDriver: { url: '/car-park/drivers/create', title: 'Добавить водителя' },
  carParkEditDriver: { url: '/car-park/drivers/edit/:id', title: 'Редактировать водителя' },
  carParkDrivers: { url: '/car-park/drivers', title: 'Водители' },
  carParkDriver: { url: '/car-park/driver/:id', title: 'К списку водителей' },

  /* Логистика */
  logistics: { url: '/logistics', title: 'Логистика' },
  logisticsCreateLoad: { url: '/logistics/my-cargos/create', title: 'Добавить груз' },
  logisticsEditLoad: { url: '/logistics/my-cargos/edit/:id', title: 'К грузу' },
  logisticsLoadCard: { url: '/logistics/my-cargos/card/:id', title: 'К списку грузов' },
  logisticFindCargo: { url: '/logistics/find-cargo', title: 'Найти груз' },
  logisticMyCargos: { url: '/logistics/my-cargos', title: 'Мои грузы' },
  logisticResponses: { url: '/logistics/responses', title: 'Отклики' },
  logisticDeals: { url: '/logistics/deals', title: 'Заказы' },
  logisticDealCard: { url: '/logistics/deals/card/:id', title: 'Заказы' },

  documents: { url: '/documents', title: 'Документы' },
  warehouses: { url: '/warehouses', title: 'Склады' },
  notifications: { url: '/notifications', title: 'Уведомления' },
  statistics: { url: '/statistics', title: 'Статистика' },
}

export type IAppRouteName = keyof typeof appRoutes
