import React, { useEffect, useMemo } from 'react'

import { Empty, Pagination, Table } from 'antd'
import { observer } from 'mobx-react-lite'
import { useLocation, useNavigate } from 'react-router-dom'

import { useGetProductTypeData, useGetTableHeight, usePagination } from '~/hooks'
import { NewBasisNotification } from '~/shared/components'
import { offersFilterStore } from '~/store/filters/offersFilterStore'
import { getOfferType } from '~/utils/offers/get-offer-type'
import { OfferData, OfferDataStatusEnum } from '~api/generated'
import { useGetCurrentRole } from '~hooks/auth'
import { useGetDevice } from '~hooks/common'
import { useGetCompanies } from '~hooks/company/useGetCompanies'
import { useGetModeratorOfferList } from '~hooks/moderator'
import { useGetOffers } from '~hooks/offer'
import { useGetProductTypes } from '~hooks/products/useGetProductTypes'
import { OfferCardPreview } from '~pages/OffersPage/components/OfferCardPreview'
import { OffersCardPreviewWrapper } from '~pages/OffersPage/components/OffersCardPreviewWrapper'
import { OffersFilter } from '~pages/OffersPage/components/OffersFilter'
import { View } from '~pages/OffersPage/components/ViewToggle'
import { useGetView } from '~pages/OffersPage/hooks'
import { ActiveFiltersInline } from '~shared/components/ActiveFiltersInline'

import { OffersHeader } from '../../components/OffersHeader'
import { useGetColumnsOffersList } from '../../hooks'

import * as S from '../../OffersPage.styled'

export const OffersList: React.FC = observer(() => {
  const navigate = useNavigate()
  const { view, setView } = useGetView()
  const device = useGetDevice()
  const location = useLocation()
  const { currentPage, setCurrentPage, pageSize, setPageSize, pageSizeOptions } = usePagination()
  const filters = offersFilterStore.filters
  const tableHeight = useGetTableHeight()

  const { getProductType, getProductTypeParametersForUI_V2 } = useGetProductTypeData()

  const {
    user: { isAdmin },
  } = useGetCurrentRole()

  const {
    data: offersData,
    refetch: refetchOfferList,
    isLoading: isLoadingOfferList,
  } = isAdmin
    ? useGetModeratorOfferList({
        currentPage,
        pageSize,
        statuses: [OfferDataStatusEnum.Published],
        orderBy: offersFilterStore.orderBy,
        order: offersFilterStore.order,
        ...offersFilterStore.mappedCurrentFiltersToResponse,
      })
    : useGetOffers({
        currentPage,
        pageSize,
        statuses: [OfferDataStatusEnum.Published],
        orderBy: offersFilterStore.orderBy,
        order: offersFilterStore.order,
        ...offersFilterStore.mappedCurrentFiltersToResponse,
      })

  useEffect(() => {
    setCurrentPage(1)
    // void refetchOfferList()
  }, [
    filters.volume.from,
    filters.volume.to,
    filters.price.from,
    filters.price.to,
    filters.offerType,
    filters.regionIds,
    filters.productTypes,
  ])

  const offers = offersData?.offers
  const pagination = offersData?.pagination

  const companiesIds = useMemo(
    () => Array.from(new Set(offers?.map((offer) => offer.companyId).filter(Boolean))),
    [offersData]
  )

  const { data: companiesData, isLoading: isLoadingCompanies } = useGetCompanies(
    {
      ids: companiesIds as number[],
    },
    isAdmin
  )
  const { data: products } = useGetProductTypes()

  const companiesMap = useMemo(() => new Map(companiesData?.map((company) => [company.id, company])), [companiesData])

  const handleOfferClick = (offer: OfferData) => {
    navigate({
      pathname: `${offer.id}`,
      search: location.search,
    })
  }

  const columns = useGetColumnsOffersList(products, companiesMap)

  const isMobile = device === 'mobile'

  return (
    <S.ContentWrapper>
      <S.Content>
        <NewBasisNotification />
        <OffersHeader
          filters={<OffersFilter store="offers" />}
          view={view}
          onChangeView={(value) => setView(value)}
          onAfterCreateOffer={refetchOfferList}
        />
        <ActiveFiltersInline store="offers" isShowDistanceFromWarehouseControl />
        {/*<ActiveFiltersTags store={offersFilterStore} />*/}

        {view === View.CARDS || isMobile ? (
          <OffersCardPreviewWrapper isLoading={isLoadingOfferList}>
            {!offers?.length && <Empty />}
            {offers?.map((offer, idx) => {
              return (
                <OfferCardPreview
                  key={idx}
                  onClick={() => handleOfferClick(offer)}
                  name={offer.product.name}
                  img={getProductType(offer.product.type)?.picture?.source}
                  parameters={getProductTypeParametersForUI_V2(offer.product)}
                  volume={offer.volume}
                  price={offer.price}
                  isNds={offer.includingNds}
                  address={offer.address}
                  distance={offer.distance}
                  statuses={[getOfferType(offer.type)]}
                  companyName={offer?.companyId ? companiesMap.get(offer?.companyId)?.name : ''}
                  isOwn={offer.isOwn}
                  publishedAt={offer.publishedAt}
                />
              )
            })}
          </OffersCardPreviewWrapper>
        ) : (
          <Table<OfferData>
            rowKey="id"
            className="table-interactive"
            loading={isLoadingOfferList || isLoadingCompanies}
            columns={columns}
            dataSource={offers}
            scroll={{ x: 'max-content', y: tableHeight }}
            pagination={false}
            rowClassName={(row) => (row.isOwn ? 'row-own' : '')}
            onRow={(record) => ({
              onClick: handleOfferClick.bind(null, record),
            })}
          />
        )}

        {pagination && !!offers?.length && (
          <Pagination
            current={pagination?.currentPage}
            total={pagination?.total}
            pageSize={pagination?.pageSize}
            pageSizeOptions={pageSizeOptions}
            onShowSizeChange={(x, size) => setPageSize(size)}
            showSizeChanger
            onChange={setCurrentPage}
          />
        )}
      </S.Content>
    </S.ContentWrapper>
  )
})
