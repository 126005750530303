import React from 'react'

import { Select } from 'antd'
import { BaseSelectRef } from 'rc-select'

import { getElevatorAdvertStatus } from '~/utils/elevator-adverts/get-elevator-advert-status'
import { ElevatorOfferStatus } from '~api/generated'
import { CustomControlInterface } from '~shared/controls/CustomControl.interface'

type Value = ElevatorOfferStatus[]

interface ElevatorAdvertStatusSelectControlProps extends CustomControlInterface {
  onChange: (value: Value) => void
  value: Value
  hasError?: boolean
  id?: string
  placeholder?: string
}

interface IOption {
  value: ElevatorOfferStatus
  label: string
}
const selectOptions: IOption[] = [
  {
    value: ElevatorOfferStatus.Deactivated,
    label: getElevatorAdvertStatus(ElevatorOfferStatus.Deactivated).name,
  },
  {
    value: ElevatorOfferStatus.OnModeration,
    label: getElevatorAdvertStatus(ElevatorOfferStatus.OnModeration).name,
  },
  {
    value: ElevatorOfferStatus.Published,
    label: getElevatorAdvertStatus(ElevatorOfferStatus.Published).name,
  },
  {
    value: ElevatorOfferStatus.Rejected,
    label: getElevatorAdvertStatus(ElevatorOfferStatus.Rejected).name,
  },
]

export const ElevatorAdvertStatusSelectControl: React.FC<ElevatorAdvertStatusSelectControlProps> = React.forwardRef<
  BaseSelectRef,
  ElevatorAdvertStatusSelectControlProps
>(({ id, value, onChange, hasError, placeholder = 'Статус' }, ref) => {
  const onSelectAddressHandler = (v: Value) => {
    onChange(v)
  }

  return (
    <Select
      allowClear
      mode="multiple"
      ref={ref}
      id={id}
      style={{ width: '100%' }}
      placeholder={placeholder}
      value={value}
      onChange={onSelectAddressHandler}
      status={hasError ? 'error' : undefined}
      optionFilterProp="children"
    >
      {selectOptions.map((it) => (
        <Select.Option key={it.value} value={it.value}>
          {it.label}
        </Select.Option>
      ))}
    </Select>
  )
})

ElevatorAdvertStatusSelectControl.displayName = 'ElevatorAdvertStatusSelectControl'
