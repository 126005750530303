import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { css } from '@styled-system/css'

import { Text } from '~/ui-components'

export const Header = styled.header<{
  isSimple: boolean
}>`
  background-color: white;
  padding: 16px 24px ${(props) => (props.isSimple ? '16px' : 0)};
  border-bottom: 1px solid var(--gray-2-color);
`

export const MobileHeader = styled('header')(
  css({
    display: ['flex', 'flex', 'none'],
    width: '100%',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: 'white',
    padding: '8px',

    borderBottom: '1px solid var(--gray-2-color)',
    // minHeight: '56px',
  })
)

export const MobileHeaderRow = styled('div')(
  css({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  })
)
export const MobileTitleWrapper = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
`

export const MobileHeaderRowChildren = styled(MobileHeaderRow)(
  css({
    paddingTop: '8px',
  })
)

export const DesktopHeader = styled('div')(
  css({
    display: ['none', 'none', 'block'],
  })
)

export const HeaderAuth = styled.header(
  css({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: ['56px', null, '64px'],
    padding: ['0 16px', null, '0 24px'],
    backgroundColor: 'white',
  })
)

export const HeaderAuthImg = styled.img(
  css({
    height: ['32px'],
  })
)

export const Inner = styled.div({
  display: 'flex',
  alignItems: 'center',
  gap: '16px',
})

export const Title = styled(Text).attrs({ variant: 'h1' })(
  css({
    color: 'TxtHeading',
    margin: 0,
    marginRight: 'auto',
  })
)

export const BackButton = styled('button')(
  css({
    padding: 0,
    border: 'none',
    backgroundColor: 'transparent',
    cursor: 'pointer',
    outline: 'none',

    svg: {
      display: 'block',
    },
  })
)

export const MobileMenuWrapper = styled.div(
  css({
    padding: '16px 0',
  })
)

export const LogoMobileLink = styled(Link)(
  css({
    display: 'block',
  })
)
