/* tslint:disable */
/* eslint-disable */
/**
 * API Зерно-Агрегатора
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { CompanyVerificationStatus } from './company-verification-status';

/**
 * 
 * @export
 * @interface BaseCompanyData
 */
export interface BaseCompanyData {
    /**
     * Id компании
     * @type {number}
     * @memberof BaseCompanyData
     */
    'id': number;
    /**
     * Название компании
     * @type {string}
     * @memberof BaseCompanyData
     */
    'name': string;
    /**
     * Тип компании
     * @type {string}
     * @memberof BaseCompanyData
     */
    'type': BaseCompanyDataTypeEnum;
    /**
     * ИНН компании
     * @type {string}
     * @memberof BaseCompanyData
     */
    'inn': string;
    /**
     * КПП компании
     * @type {string}
     * @memberof BaseCompanyData
     */
    'kpp': string | null;
    /**
     * ОГРН компании
     * @type {string}
     * @memberof BaseCompanyData
     */
    'ogrn': string | null;
    /**
     * Код ОКВЭД
     * @type {string}
     * @memberof BaseCompanyData
     */
    'codeOkved': string | null;
    /**
     * Применяется ли НДС
     * @type {boolean}
     * @memberof BaseCompanyData
     */
    'usingNds': boolean | null;
    /**
     * Директор компании
     * @type {string}
     * @memberof BaseCompanyData
     */
    'director': string | null;
    /**
     * Подписант
     * @type {string}
     * @memberof BaseCompanyData
     */
    'signatory': string | null;
    /**
     * Должность подписанта
     * @type {string}
     * @memberof BaseCompanyData
     */
    'signatoryPosition': string | null;
    /**
     * Основание права подписи
     * @type {string}
     * @memberof BaseCompanyData
     */
    'signatoryLegalBasis': BaseCompanyDataSignatoryLegalBasisEnum;
    /**
     * Юридический адрес
     * @type {string}
     * @memberof BaseCompanyData
     */
    'legalAddress': string | null;
    /**
     * Физический адрес
     * @type {string}
     * @memberof BaseCompanyData
     */
    'actualAddress': string | null;
    /**
     * Участник хартии АПК
     * @type {boolean}
     * @memberof BaseCompanyData
     */
    'aicCharterMember': boolean | null;
    /**
     * 
     * @type {CompanyVerificationStatus}
     * @memberof BaseCompanyData
     */
    'status': CompanyVerificationStatus;
}

export const BaseCompanyDataTypeEnum = {
    Seller: 'SELLER',
    Buyer: 'BUYER',
    Trader: 'TRADER',
    Carrier: 'CARRIER',
    DemoCompany: 'DEMO_COMPANY'
} as const;

export type BaseCompanyDataTypeEnum = typeof BaseCompanyDataTypeEnum[keyof typeof BaseCompanyDataTypeEnum];
export const BaseCompanyDataSignatoryLegalBasisEnum = {
    CompanyRegulation: 'COMPANY_REGULATION',
    PowerOfAttorney: 'POWER_OF_ATTORNEY'
} as const;

export type BaseCompanyDataSignatoryLegalBasisEnum = typeof BaseCompanyDataSignatoryLegalBasisEnum[keyof typeof BaseCompanyDataSignatoryLegalBasisEnum];


