import { NavLink } from 'react-router-dom'
import styled from 'styled-components'

import { css } from '@styled-system/css'

import { breakpoints } from '~/tokens'

export const StyledNavLink = styled(NavLink)`
  display: inline-flex;
  flex-wrap: wrap;
  align-items: center;
  height: 52px;
  width: 100%;
  padding: 8px 24px;
  justify-content: flex-start;
  box-sizing: border-box;
  cursor: pointer;
  text-decoration: none;
  color: inherit !important;

  &:hover {
    color: var(--primary-hover-color) !important;
  }

  &.active {
    position: relative;
    cursor: pointer;
    color: var(--primary-main-color) !important;

    &::after {
      content: '';
      position: absolute;
      top: 50%;
      left: 0;
      width: 2px;
      height: 24px;
      background-color: var(--primary-main-color);
      transform: translate(-50%, -50%);
    }
  }

  svg {
    display: block;
  }

  @media (max-width: ${breakpoints.DESKTOP_L}px) {
    padding: 0;
    justify-content: center;
  }
`

export const MenuText = styled.div`
  display: flex;
  font-weight: 600;
  margin-left: 20px;
  flex: 1;
  align-items: center;

  @media (max-width: ${breakpoints.DESKTOP_L}px) {
    display: none;
  }
`

export const Divider = styled.div(
  css({
    height: '1px',
    backgroundColor: 'Gray100',
  })
)

export const StyledNavLinkWithChild = styled(NavLink)`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 6px;
`

export const ChildrenWrapper = styled.ul`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 6px;
  margin-left: 15px;
`

export const ChildrenWrapperLi = styled.li`
  &.child-link-active {
    color: blue;
  }
`
export const NewLabel = styled.div`
  margin-left: auto;
  display: inline-flex;
  align-items: center;
  background: #eef7ed;
  border-radius: 8px;
  padding: 6px;
  color: #45a67d;
`
