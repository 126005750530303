import React from 'react'

import { Avatar, Space, Tooltip } from 'antd'
import { FiCheck, FiPlus } from 'react-icons/fi'

import { getAbbrCompany } from '~/utils/company/get-abbr-company'
import { CompanyEntity, CompanyVerificationStatus } from '~api/gql-generated/graphql'
import { ReactComponent as VerifiedIcon } from '~assets/icons/verified.svg'

import * as S from './CompaniesListItem.styled'

interface CompaniesListItemInfoProps {
  variant: 'company-info'
  data: Partial<CompanyEntity>
  isSelected: boolean
  onSelect: (companyId: number) => void
}

interface CompaniesListItemAddProps {
  variant: 'company-add'
  canUserCreateNewCompany: boolean
  onAdd: () => void
}

type CompaniesListItemProps = CompaniesListItemInfoProps | CompaniesListItemAddProps

export const CompaniesListItem: React.FC<CompaniesListItemProps> = (props) => {
  if (props.variant === 'company-info') {
    const isVerified = props.data.verificationStatus === CompanyVerificationStatus.Verified

    const onSelectHandler = () => {
      if (props.data.id) {
        props.onSelect(props.data.id)
      }
    }

    return (
      <S.ContentWrapper isSelected={props.isSelected} onClick={onSelectHandler}>
        <div>
          <Avatar size={28} shape="circle" src={props.data?.logoUrl}>
            {getAbbrCompany(props.data.legalName)}
          </Avatar>
        </div>

        <S.CompanyInfoWrapper>
          <S.CompanyNameWrapper>
            <S.CompanyName>{props.data.legalName}</S.CompanyName>
            {isVerified && <VerifiedIcon />}
          </S.CompanyNameWrapper>

          {!isVerified && <S.CompanyNotVerified>Не подтверждена</S.CompanyNotVerified>}
        </S.CompanyInfoWrapper>

        {props.isSelected && <FiCheck size={20} color={'var(--success-color)'} />}
      </S.ContentWrapper>
    )
  }
  if (props.variant === 'company-add') {
    return (
      <Tooltip title={!props.canUserCreateNewCompany ? 'Не хватает прав' : undefined}>
        <S.ContentWrapper
          onClick={!props.canUserCreateNewCompany ? undefined : props.onAdd}
          isDisabled={!props.canUserCreateNewCompany}
        >
          <Space>
            <FiPlus size={16} color={'var(--success-color)'} />
            <S.AddCompanyText>Добавить компанию</S.AddCompanyText>
          </Space>
        </S.ContentWrapper>
      </Tooltip>
    )
  }
  return null
}
