import { DocumentTypeDataTypeEnum } from '~/api/generated'

const documentDescriptionsMap = {
  [DocumentTypeDataTypeEnum.FullCompanyInfoCard]: {
    name: 'Полная информационная карточка компании',
    explanation: 'Полная информационная карточка или анкета с реквизитами и печатью вашей компании',
  },
  [DocumentTypeDataTypeEnum.Inn]: {
    name: 'ИНН',
    explanation: 'Свидетельство о постановке на учет в налоговом органе (ИНН)',
  },
  [DocumentTypeDataTypeEnum.Ogrn]: {
    name: 'Свидетельство о государственной регистрации компании (ОГРН или ЕГРЮЛ)',
    explanation: `
      Свидетельство о государственной регистрации (ОГРН, если зарегистрирован до 01.07.2002 - свидетельство о внесении записи в ЕГРЮЛ)
    `,
  },
  [DocumentTypeDataTypeEnum.CompanyRegulation]: {
    name: 'Устав',
    explanation: 'Устав (в действующей редакции)',
  },
  [DocumentTypeDataTypeEnum.CompanyFoundationResolution]: {
    name: 'Решение об учреждении общества',
    explanation:
      'Протокол собрания участников или решение единственного участника об учреждении общества (создании юридического лица)',
  },
  [DocumentTypeDataTypeEnum.DirectorAppointmentResolution]: {
    name: 'Решение о назначении генерального директора',
    explanation:
      'Протокол собрания участников или решение единственного участника о назначении на должность руководителя',
  },
  [DocumentTypeDataTypeEnum.DirectorAppointmentOrder]: {
    name: 'Приказ о назначении генерального директор',
    explanation: 'Приказ о назначении генерального директора',
  },
  [DocumentTypeDataTypeEnum.ExecutiveAgencyPassport]: {
    name: 'Паспорт сотрудника с правом подписи (генерального директора)',
    explanation: 'Паспорт сотрудника, указанного в документе подтверждающем право подписи (генерального директора)',
  },
  [DocumentTypeDataTypeEnum.AccountantAppointmentOrder]: {
    name: 'Приказ о назначении главного бухгалтера',
    explanation: 'Приказ о назначении лица, ответственного за ведение бухгалтерского учёта',
  },
  [DocumentTypeDataTypeEnum.EgrulStatement]: {
    name: 'Выписка из ЕГРЮЛ',
    explanation: 'Выписка из ЕГРЮЛ (выданная не ранее месяца до даты предполагаемого заключения Договора)',
  },
  [DocumentTypeDataTypeEnum.Ogrnip]: {
    name: 'Свидетельство о государственной регистрации ИП (лист записи ЕГРИП)',
    explanation:
      'Свидетельство о государственной регистрации индивидуального предпринимателя (ОГРНИП) лист записи ЕГРИП',
  },
  [DocumentTypeDataTypeEnum.IndividualEntrepreneurPassport]: {
    name: 'Паспорт индивидуального предпринимателя',
    explanation: 'Документ, удостоверяющий личность (паспорт) физического лица – индивидуального предпринимателя',
  },
  [DocumentTypeDataTypeEnum.PaymentCardConfirmation]: {
    name: 'Данные банковской карты',
    explanation:
      'Данные банковской карты с образцом подписи, заверенная банком, осуществляющим расчетно-кассовое обслуживание',
  },
  [DocumentTypeDataTypeEnum.CounterpartyAuthorityConfirmation]: {
    name: 'Документы для действий по поручению организации',
    explanation:
      'Документы, подтверждающие полномочия лиц, выступающих по поручению или в интересах вашей организации (при наличии)',
  },
  [DocumentTypeDataTypeEnum.LegalAddressConfirmation]: {
    name: 'Подтверждение юридического адреса',
    explanation: `
      Документ, подтверждающий местонахождение юридического лица по месту регистрации (договор аренды на недвижимое имущество или иные
      документы, подтверждающие адрес)
    `,
  },
  [DocumentTypeDataTypeEnum.LastYearFinancialStatement]: {
    name: 'Бухгалтерская отчетность за последний период',
    explanation:
      'Бухгалтерская отчетность (отчет о финансовых результатах) за последний отчетный период (с квитанцией ИФНС о приеме)',
  },
  [DocumentTypeDataTypeEnum.LastYearIncomeStatement]: {
    name: 'Справка об удельном весе дохода от сельхозпродукции',
    explanation: `
      Справка об удельном весе дохода от реализации сельхозпродукции за прошлый год (предыдущий отчетному), если предусмотрена
      по ОКВЭД другая деятельность т.е. не сельхозпроизводитель (образец прилагается)'
    `,
  },
  [DocumentTypeDataTypeEnum.LastYearStatisticReportForm4]: {
    name: 'Статистическая отчетность об итогах сева под урожай (форма 4)',
    explanation: `
      Статистическая отчетность, за последний отчетный период, подтверждающая принадлежность организации к статусу сельскохозяйственного
      товаропроизводителя. Форма 4 «Сведения об итогах сева урожая»
    `,
  },
  [DocumentTypeDataTypeEnum.LastYearStatisticReportForm29]: {
    name: 'Статистическая отчетность о сборе урожая (форма 29)',
    explanation: `
      Статистическая отчетность, за последний отчетный период, подтверждающая принадлежность организации к статусу сельскохозяйственного
      товаропроизводителя. Форма 29 «Сведения о сборе урожая сельскохозяйственных культур»
    `,
  },
  [DocumentTypeDataTypeEnum.FarmlandPropertyConfirmation]: {
    name: 'Документы на земельные участки',
    explanation: `
      Правоустанавливающие документы на земельные участки, участвующие в сельхозобороте (свидетельства о праве собственности,
      выписки из ЕГРН, договора аренды земли и прочие)
    `,
  },
  [DocumentTypeDataTypeEnum.SpecialTaxModeNotification]: {
    name: 'Уведомление о переходе на специальный налоговый режим',
    explanation: `
      Уведомление о переходе на специальный налоговый режим (с квитанцией ИФНС о приёме). Для ЕСХН форма 26-1, для УСН форма 26.2-1,
      для ЕНВД форма 1-3 Учёт
    `,
  },
  [DocumentTypeDataTypeEnum.NdsDeclaration]: {
    name: 'Декларация об уплате НДС',
    explanation: 'Декларация об уплате НДС за последний отчетный период (с квитанцией ИФНС о приёме)',
  },
  [DocumentTypeDataTypeEnum.AgricultureTaxDeclaration]: {
    name: 'Налоговая декларация по ЕСХН',
    explanation: 'Налоговая декларация по ЕСХН (УСН, ЕНВД) (с квитанцией ИФНС о приеме)',
  },
  [DocumentTypeDataTypeEnum.IncomeTaxDeclaration]: {
    name: 'Декларация по налогу на прибыль',
    explanation: 'Декларация по налогу на прибыль за последний отчетный период (с квитанцией ИФНС о приёме)',
  },
  [DocumentTypeDataTypeEnum.Ndfl3Declaration]: {
    name: 'Налоговая декларация (форма 3-НДФЛ)',
    explanation: `
      Налоговая декларация по налогу на доходы физических лиц (форма 3-НДФЛ) за предшествующий календарный год (с документальным
      подтверждением принятия налоговым органом)
    `,
  },
}

export const getDocumentInfo = (type: DocumentTypeDataTypeEnum) => {
  return documentDescriptionsMap[type]
}
