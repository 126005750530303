import React from 'react'

import { Button } from 'antd'
import { Controller, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

import { useMutation, useQuery } from '@apollo/client'
import { ApolloError } from '@apollo/client/errors'
import { yupResolver } from '@hookform/resolvers/yup'

import { AppHeader } from '~/layout'
import { appRoutes } from '~/router'
import { FormField } from '~/ui-components'
import { appToast } from '~/utils'
import { graphqlErrorHandler } from '~/utils/graphqlErrorHandler'
import { updateTransportationDataMutation } from '~api/gql-mutations/car-park.mutation.graphql'
import { getTransportationDataQuery } from '~api/gql-query/car-park.query.graphql'
import { useAuth } from '~providers/auth'
import {
  AddressControlDadata,
  RegionsSelectControl,
  TransportationAreaSelectControl,
  TransportModesSelectControl,
} from '~shared/controls'

import { EditTransportationDataSchema, EditTransportationDataValues } from './EditTransportationData.types'

import * as S from './EditTransportationData.styled'

export const EditTransportationData: React.FC = () => {
  const navigate = useNavigate()
  const { companyId } = useAuth()

  const getTransportationData = useQuery(getTransportationDataQuery, {
    variables: {
      companyId,
    },
    fetchPolicy: 'cache-and-network',
  })

  const [updateTransportationData] = useMutation(updateTransportationDataMutation, {
    refetchQueries: [getTransportationDataQuery],
  })

  const transportModes = getTransportationData.data?.transportData?.transportModes
  const transportationArea = getTransportationData.data?.transportData?.transportationArea
  const regions = getTransportationData.data?.transportData?.regions
  const address = getTransportationData.data?.transportData?.address

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      transportModes,
      transportationArea,
      regions: regions?.map((region) => region.id),
      address: address ?? null,
    },
    resolver: yupResolver(EditTransportationDataSchema),
  })

  const onUpdateTransportationData = async (formValues: EditTransportationDataValues) => {
    try {
      await updateTransportationData({
        variables: {
          companyId: companyId,
          input: {
            transportModes: formValues.transportModes,
            transportationArea: formValues.transportationArea ?? null,
            regionIds: formValues.regions,
            address: formValues.address,
          },
        },
      })
      appToast.success({ message: 'Транспортные данные изменены' })
      navigate(appRoutes.carParkTransportationData.url)
    } catch (e) {
      if (e instanceof ApolloError) {
        graphqlErrorHandler(e, 'Ошибка при изменении транспортных данных')
      }
    }
  }

  return (
    <>
      <AppHeader title={appRoutes.carParkEditTransportationData.title} isBack />

      <S.Wrapper>
        <S.StyledForm>
          <FormField
            label="Виды грузоперевозок"
            htmlFor="transportModes"
            validateMessage={errors.transportModes?.message}
          >
            <Controller
              name="transportModes"
              control={control}
              render={({ field }) => <TransportModesSelectControl {...field} hasError={!!errors.transportModes} />}
            />
          </FormField>

          <FormField
            label="Территория грузоперевозок"
            htmlFor="transportationArea"
            validateMessage={errors.transportationArea?.message}
          >
            <Controller
              name="transportationArea"
              control={control}
              render={({ field }) => (
                <TransportationAreaSelectControl
                  {...field}
                  value={field.value ?? undefined}
                  hasError={!!errors.transportationArea}
                />
              )}
            />
          </FormField>

          <FormField label="Регионы грузоперевозок" htmlFor="regions" validateMessage={errors.regions?.message}>
            <Controller
              name="regions"
              control={control}
              render={({ field }) => <RegionsSelectControl {...field} mode="multiple" hasError={!!errors.regions} />}
            />
          </FormField>

          <FormField label="Адрес парка" htmlFor="address" validateMessage={errors.address?.message}>
            <Controller
              name="address"
              control={control}
              render={({ field: { value, onChange } }) => (
                <AddressControlDadata
                  id="address"
                  placeholder="Введите адрес"
                  value={value ?? undefined}
                  onChange={(val) => onChange(val?.value)}
                  hasError={!!errors.address}
                />
              )}
            />
          </FormField>

          <S.ActionWrapper>
            <S.StyledButton type="default" htmlType="button" onClick={() => navigate(-1)}>
              Отмена
            </S.StyledButton>
            <Button type="primary" htmlType="button" onClick={handleSubmit(onUpdateTransportationData)}>
              Сохранить
            </Button>
          </S.ActionWrapper>
        </S.StyledForm>
      </S.Wrapper>
    </>
  )
}
