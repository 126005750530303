import React, { useEffect, useState } from 'react'

import { Button, Modal, Pagination } from 'antd'
import { observer } from 'mobx-react-lite'
import { useNavigate } from 'react-router-dom'

import { usePagination } from '~/hooks'
import { appRoutes } from '~/router'
import { basisAdvertOffersAdminFilterStore } from '~/store/filters'
import { appToast, httpErrorHandler, modalSharedSettings } from '~/utils'
import { useUploadFileAndGetId } from '~hooks/_utils'
import {
  useCreateElevatorAdvertOfferFromBuyerByAdmin,
  useCreateElevatorAdvertOfferFromSellerByAdmin,
  useGetElevatorAdvertOffersByAdmin,
  useUpdateElevatorAdvertOfferModerator,
} from '~hooks/elevator-advert-offers-admin'
import { CreateAdvertOfferFormByAdmin } from '~pages/ModeratorPage/components/ModeratorBasisAdvertOffers/CreateAdvertOfferFormByAdmin'
import { ModeratorBasisAdvertOffersFilters } from '~pages/ModeratorPage/components/ModeratorBasisAdvertOffers/ModeratorBasisAdvertOffersFilters'
import { ModeratorBasisAdvertOffersListTable } from '~pages/ModeratorPage/components/ModeratorBasisAdvertOffers/ModeratorBasisAdvertOffersList/components/ModeratorBasisAdvertOffersListTable'
import { useApi } from '~providers/api'
import { useAuth } from '~providers/auth'

import { CreateAdvertOfferFormBuyByAdmin } from '../CreateAdvertOfferFormBuyByAdmin'

import { CreateBuyAdvertOfferFormValues } from '~pages/BasisPage/components/CreateBuyAdvertOfferForm/CreateBuyAdvertOfferForm.types'
import { CreateAdvertOfferFormBuyByAdminValues } from '~pages/ModeratorPage/components/ModeratorBasisAdvertOffers/CreateAdvertOfferFormBuyByAdmin/CreateAdvertOfferFormBuyByAdmin.types'
import { CreateAdvertOfferFormByAdminValues } from '~pages/ModeratorPage/components/ModeratorBasisAdvertOffers/CreateAdvertOfferFormByAdmin/CreateAdvertOfferFormByAdmin.types'
import { ModeratorBasisAdvertOffersListTableDataType } from '~pages/ModeratorPage/components/ModeratorBasisAdvertOffers/ModeratorBasisAdvertOffersList/components/ModeratorBasisAdvertOffersListTable/ModeratorBasisAdvertOffersListTable.types'

import * as S from './ModeratorBasisAdvertOffersList.styled'

const FORM_ID_SELL = 'create-advert-offer-sell-form-by-admin-id'
const FORM_ID_BUY = 'create-advert-offer-buy-form-by-admin-id'

export const ModeratorBasisAdvertOffersList: React.FC = observer(() => {
  const api = useApi()
  const navigate = useNavigate()
  const { userId } = useAuth()
  const uploadLabAnalysisAndGetIdFn = useUploadFileAndGetId()
  const { currentPage, setCurrentPage, pageSize, setPageSize, pageSizeOptions } = usePagination()
  const [isCreateOfferSellModalOpen, setIsCreateOfferSellModalOpen] = useState(false)
  const [isCreateOfferBuyModalOpen, setIsCreateOfferBuyModalOpen] = useState(false)
  const createElevatorAdvertOfferSellFn = useCreateElevatorAdvertOfferFromSellerByAdmin()
  const createElevatorAdvertOfferBuyFn = useCreateElevatorAdvertOfferFromBuyerByAdmin()
  const updateOfferModeratorFn = useUpdateElevatorAdvertOfferModerator()

  const filters = basisAdvertOffersAdminFilterStore.filters
  useEffect(() => {
    setCurrentPage(1)
  }, [filters.elevatorAdvertId, filters.elevatorId, filters.statuses])

  const getElevatorAdvertsOffersAdminFn = useGetElevatorAdvertOffersByAdmin({
    currentPage,
    pageSize,
    ...basisAdvertOffersAdminFilterStore.mappedCurrentFiltersToResponse,
  })

  const onRowClickHandler = (record: ModeratorBasisAdvertOffersListTableDataType) => {
    navigate(appRoutes.moderatorBasisAdvertOfferDetails.url.replace(':id', record.id.toString()))
  }

  const onCancelCreateOfferSellModalHandler = () => {
    setIsCreateOfferSellModalOpen(false)
  }

  const onCancelCreateOfferBuyModalHandler = () => {
    setIsCreateOfferBuyModalOpen(false)
  }

  const takeOfferToWorkHandler = async (offerId: number) => {
    if (!offerId || !userId) return

    try {
      await updateOfferModeratorFn.mutateAsync({
        id: +offerId,
      })

      appToast.success({ description: `Вы взяли в работу отклик №${offerId}` })
    } catch (e) {
      httpErrorHandler(e, 'Ошибка при обновлении модератора отклика')
    }
    await getElevatorAdvertsOffersAdminFn.refetch()
  }

  const onSubmitCreateOfferSellModalHandler = async (formValues: CreateAdvertOfferFormByAdminValues) => {
    try {
      const laboratoryAnalysisFileKey = formValues?.labAnalysis
        ? await uploadLabAnalysisAndGetIdFn.mutateAsync(formValues.labAnalysis)
        : null

      const foundUserIdByPhoneNumber: null | number =
        (
          await api.users.userControllerGetUserByPhone({
            phoneNumber: formValues.contactPhone,
          })
        ).data?.user?.id ?? null

      await createElevatorAdvertOfferSellFn.mutateAsync({
        adminCreateOfferResponseFromSellerBody: {
          elevatorAdvertId: Number(formValues.advertId),
          includingNds: formValues.usingNds,
          laboratoryAnalysisFileKey,
          volume: formValues.volume,
          priceType: formValues.priceType,
          pricePerTon: formValues.pricePerTon,
          parameters: !formValues.culture?.parameters?.length ? null : formValues.culture.parameters,
          address: formValues.address?.value,
          user: {
            userId: foundUserIdByPhoneNumber,
            userData: !foundUserIdByPhoneNumber
              ? {
                  phoneNumber: formValues.contactPhone,
                  name: formValues.contactUser,
                }
              : null,
          },
          company: {
            inn: formValues.companyInfo.inn,
          },
        },
      })

      appToast.success({ description: `Ставка создана` })
      setIsCreateOfferSellModalOpen(false)
      await getElevatorAdvertsOffersAdminFn.refetch()
    } catch (e) {
      httpErrorHandler(e, 'Ошибка при создании ставки')
    }
  }

  const onSubmitCreateOfferBuyModalHandler = async (formValues: CreateAdvertOfferFormBuyByAdminValues) => {
    try {
      const foundUserIdByPhoneNumber: null | number =
        (
          await api.users.userControllerGetUserByPhone({
            phoneNumber: formValues.contactPhone,
          })
        ).data?.user?.id ?? null

      await createElevatorAdvertOfferBuyFn.mutateAsync({
        adminCreateOfferResponseFromBuyerBody: {
          elevatorAdvertId: Number(formValues.advertId),
          price: formValues.pricePerTon,
          volume: formValues.volume,
          user: {
            userId: foundUserIdByPhoneNumber,
            userData: !foundUserIdByPhoneNumber
              ? {
                  phoneNumber: formValues.contactPhone,
                  name: formValues.contactUser,
                }
              : null,
          },
          company: {
            inn: formValues.companyInfo.inn,
          },
        },
      })

      appToast.success({ description: `Ставка создана` })
      setIsCreateOfferSellModalOpen(false)
      await getElevatorAdvertsOffersAdminFn.refetch()
    } catch (e) {
      httpErrorHandler(e, 'Ошибка при создании ставки')
    }
  }
  const pagination = getElevatorAdvertsOffersAdminFn.data?.pagination
  const offers = getElevatorAdvertsOffersAdminFn.data?.elevatorAdvertOffers

  const tableData: ModeratorBasisAdvertOffersListTableDataType[] =
    offers?.map((offer) => ({
      id: offer.id,
      status: offer.status,
      cultureName: offer.product.name,
      companyName: offer.company.name,
      basisName: offer.elevator.name,
      offerId: offer.elevatorAdvert.id,
      publishedAt: offer.publishedAt,
      moderator: offer.moderator,
      type: offer.type,
    })) ?? []

  return (
    <S.Desktop>
      <S.FiltersWrapper>
        <ModeratorBasisAdvertOffersFilters />
        <Button type="primary" htmlType="button" onClick={() => setIsCreateOfferSellModalOpen(true)}>
          Предложить объем
        </Button>
        <Button type="primary" htmlType="button" onClick={() => setIsCreateOfferBuyModalOpen(true)}>
          Купить объем
        </Button>
      </S.FiltersWrapper>

      <ModeratorBasisAdvertOffersListTable
        tableData={tableData}
        loading={getElevatorAdvertsOffersAdminFn.isFetching}
        onRowClick={onRowClickHandler}
        takeAdvertToWork={takeOfferToWorkHandler}
      />
      <br />
      {pagination && (
        <Pagination
          current={pagination?.currentPage}
          total={pagination?.total}
          pageSize={pagination?.pageSize}
          pageSizeOptions={pageSizeOptions}
          onShowSizeChange={(x, size) => setPageSize(size)}
          showSizeChanger
          onChange={setCurrentPage}
        />
      )}

      <Modal
        {...modalSharedSettings}
        width={500}
        open={isCreateOfferSellModalOpen}
        onCancel={onCancelCreateOfferSellModalHandler}
        title="Предложить объем"
        footer={[
          <Button key="cancel" onClick={onCancelCreateOfferSellModalHandler} htmlType="button">
            Отмена
          </Button>,
          <Button
            key="submit"
            form={FORM_ID_SELL}
            type="primary"
            htmlType="submit"
            loading={createElevatorAdvertOfferSellFn.isLoading}
          >
            Предложить объем
          </Button>,
        ]}
      >
        <CreateAdvertOfferFormByAdmin formId={FORM_ID_SELL} onSubmit={onSubmitCreateOfferSellModalHandler} />
      </Modal>

      <Modal
        {...modalSharedSettings}
        width={500}
        open={isCreateOfferBuyModalOpen}
        onCancel={onCancelCreateOfferBuyModalHandler}
        title="Купить объем"
        footer={[
          <Button key="cancel" onClick={onCancelCreateOfferBuyModalHandler} htmlType="button">
            Отмена
          </Button>,
          <Button
            key="submit"
            form={FORM_ID_BUY}
            type="primary"
            htmlType="submit"
            loading={createElevatorAdvertOfferSellFn.isLoading}
          >
            Купить объем
          </Button>,
        ]}
      >
        <CreateAdvertOfferFormBuyByAdmin formId={FORM_ID_BUY} onSubmit={onSubmitCreateOfferBuyModalHandler} />
      </Modal>
    </S.Desktop>
  )
})
