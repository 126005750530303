import React from 'react'

import { Avatar, Spin } from 'antd'
import { observer } from 'mobx-react-lite'

import { useQuery } from '@apollo/client'

import { userUiSettingsStore } from '~/store/userUiSettingsStore'
import { Text } from '~/ui-components'
import { getAbbrCompany } from '~/utils/company/get-abbr-company'
import { getMeCompaniesQuery, getMeQuery } from '~api/gql-query/me.query.graphql'

import * as S from './AccountInfoDesktop.styled'

export const AccountInfoDesktop: React.FC = observer(() => {
  const getMe = useQuery(getMeQuery)
  const getCompanies = useQuery(getMeCompaniesQuery)
  const meData = getMe.data?.me
  const companyData = getCompanies?.data?.me?.companies?.find((x) => x.id === getMe.data?.me.activeCompanyId)

  return (
    <Spin spinning={getMe.loading || getCompanies.loading}>
      <S.Wrapper>
        <Avatar size={140} shape="circle" src={meData?.avatarUrl}>
          {getAbbrCompany(meData?.name)}
        </Avatar>

        <S.Info>
          <S.StyledName>{meData?.name}</S.StyledName>
          <Text variant="description">{companyData?.legalName}</Text>
        </S.Info>
      </S.Wrapper>
    </Spin>
  )
})
