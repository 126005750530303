import React, { useEffect } from 'react'

import { Pagination } from 'antd'
import { observer } from 'mobx-react-lite'
import { useNavigate } from 'react-router-dom'

import { usePagination } from '~/hooks'
import { appRoutes } from '~/router'
import { elevatorsAdminFilterStore } from '~/store/filters/basisFilterStore'
import { useGetElevatorsAdmin } from '~hooks/elevators-admin'
import { BasisListFiltersInline } from '~pages/BasisPage/components/BasisListFiltersInline'
import { ElevatorsListTable } from '~pages/SettingsPage/modules/ElevatorsAdmin/components/ElevatorsList/components/ElevatorsListTable'

import { ElevatorListTableDataType } from '~pages/SettingsPage/modules/ElevatorsAdmin/components/ElevatorsList/components/ElevatorsListTable/ElevatorsListTable.types'

import * as S from './ElevatorList.styled'

interface ElevatorsListProps {
  isShowDeletedOnly: boolean
  isShowCreationRequestOnly: boolean
}

export const ElevatorsList: React.FC<ElevatorsListProps> = observer((props) => {
  const navigate = useNavigate()
  const filters = elevatorsAdminFilterStore.basisFilters
  const { currentPage, setCurrentPage, pageSize, setPageSize, pageSizeOptions } = usePagination()

  const getElevatorsFn = useGetElevatorsAdmin({
    currentPage,
    pageSize,
    isDeletedOnly: props.isShowDeletedOnly,
    isCreationRequestOnly: props.isShowCreationRequestOnly,
    ...elevatorsAdminFilterStore.mappedCurrentFiltersToResponse,
  })

  useEffect(() => {
    void getElevatorsFn.refetch()
  }, [props.isShowDeletedOnly, props.isShowCreationRequestOnly])

  useEffect(() => {
    setCurrentPage(1)
    void getElevatorsFn.refetch()
  }, [filters.regionIds.length, filters.name, filters.productTypes])

  const onRowClickHandler = (record: ElevatorListTableDataType) => {
    navigate(appRoutes.settingsElevators.url + '/' + record.id)
  }

  const pagination = getElevatorsFn.data?.pagination
  const elevators = getElevatorsFn.data?.elevators

  const tableData: ElevatorListTableDataType[] =
    elevators?.map((elevator) => ({
      id: elevator.id,
      address: elevator.address,
      name: elevator.name,
      type: elevator.type,
      includeDeleted: elevator.isDeleted,
      hasScales: elevator?.hasScales,
      scalesLength: elevator?.scalesLength,
      scalesWeightLimit: elevator?.scalesWeightLimit,
      gateHeight: elevator?.gateHeight,
      isCreationRequest: elevator?.isCreationRequest ?? false,
    })) ?? []

  return (
    <div>
      <S.FiltersWrapper>
        <BasisListFiltersInline store={elevatorsAdminFilterStore} />
      </S.FiltersWrapper>

      <ElevatorsListTable tableData={tableData} loading={getElevatorsFn.isFetching} onRowClick={onRowClickHandler} />
      <br />
      {pagination && (
        <Pagination
          current={pagination?.currentPage}
          total={pagination?.total}
          pageSize={pagination?.pageSize}
          pageSizeOptions={pageSizeOptions}
          onShowSizeChange={(x, size) => setPageSize(size)}
          showSizeChanger
          onChange={setCurrentPage}
        />
      )}
    </div>
  )
})
