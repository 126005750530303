import styled from 'styled-components'

import { Text } from '~/ui-components'

export const ValueGreen = styled(Text).attrs({ variant: 'normal' })`
  color: var(--primary-main-color);
`
export const PublishedAt = styled(Text).attrs({ variant: 'description' })`
  color: var(--gray-4-color);
`
export const OffersCounterWrapper = styled.div<{ isActive: boolean }>`
  display: flex;
  max-width: max-content;
  align-items: center;
  background: ${(p) => (p.isActive ? '#eef7ed' : '#F5F5F5')};
  border: 1px solid #daede5;
  border-radius: 20px;
  padding: 4px 8px;
  gap: 8px;
  min-width: max-content;
`
export const OffersCounter = styled(Text).attrs({ variant: 'normal' })<{ isActive: boolean }>`
  color: ${(p) => (p.isActive ? '#45a67d' : '#9E9E9E')};
`
export const OffersCounterNew = styled(Text).attrs({ variant: 'normal' })`
  background: #45a67d;
  color: white;
  padding: 4px 8px;
  border-radius: 20px;
  ::before {
    content: '+';
  }
`
