import { useQuery, UseQueryOptions, UseQueryResult } from '@tanstack/react-query'

import { isApiResponseEmpty } from '~/utils/isApiResponseEmpty'
import { useApi } from '~providers/api'

type Result = string | null
type Options = Omit<UseQueryOptions<Result, Error, Result>, any>

export const useGetUserAvatarUrl = (userId: number | null, options?: Options): UseQueryResult<Result> => {
  const api = useApi()

  const fetchFn = async (): Promise<Result> => {
    if (!userId) {
      return null
    }

    try {
      const res = await api.users.userControllerGetProfilePictureUrl({ userId })

      return res.data.profilePictureUrl
    } catch (error) {
      if (isApiResponseEmpty(error)) {
        return null
      }

      return Promise.reject(error)
    }
  }

  return useQuery(['fetchUserAvatar', userId], fetchFn, {
    staleTime: Infinity,
    ...options,
  })
}
