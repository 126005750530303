import { isEqual } from 'lodash'
import { makeAutoObservable } from 'mobx'

import { AppConfig } from '~/appConfig'
import { getActiveFilters } from '~/store/filters/_utils'
import { OrderApiOrderControllerGetOrdersRequest } from '~api/generated'
import { IRequestsFilterForm } from '~pages/RequestsPage/components/RequestsFilter/components/RequestsFilterForm'

// Filter panel shows this values after first open
const defaultFiltersConfig: IRequestsFilterForm = {
  requestType: 'ALL',
  volume: {
    from: AppConfig.FILTERS.volume.from,
    to: AppConfig.FILTERS.volume.to,
  },
  price: {
    from: AppConfig.FILTERS.price.from,
    to: AppConfig.FILTERS.price.to,
  },
  status: undefined,
  regionIds: [],
}

class RequestsFilterStore {
  private filters: IRequestsFilterForm

  constructor() {
    this.filters = defaultFiltersConfig
    makeAutoObservable(this)
  }

  setFilters(newFilters: IRequestsFilterForm): void {
    this.filters = newFilters
  }

  get requestsFilters(): IRequestsFilterForm {
    return this.filters
  }

  get defaultFiltersConfig(): IRequestsFilterForm {
    return defaultFiltersConfig
  }

  get isCurrentFilterDefault(): boolean {
    return isEqual(this.filters, defaultFiltersConfig)
  }

  get mappedCurrentFiltersToResponse(): Partial<OrderApiOrderControllerGetOrdersRequest> {
    return this.isCurrentFilterDefault
      ? {}
      : {
          minVolume: this.filters.volume.from,
          maxVolume: this.filters.volume.to,
          minPrice: this.filters.price.from,
          maxPrice: this.filters.price.to,
          type: this.filters.requestType === 'ALL' ? undefined : this.filters.requestType,
          status: this.filters.status,
          regionIds: this.filters.regionIds,
        }
  }

  get activeFilters(): string[] {
    return getActiveFilters(this.filters, defaultFiltersConfig)
  }

  get hasActiveFilters(): boolean {
    return this.activeFilters.length > 0
  }

  get activeFiltersCount(): number {
    return this.activeFilters.length
  }
}

export const requestsFilterStore = new RequestsFilterStore()
