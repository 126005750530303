import React, { useState } from 'react'

import { Button, Empty, Flex, Pagination, Space } from 'antd'
import { useNavigate } from 'react-router-dom'

import { useQuery } from '@apollo/client'

import { usePagination } from '~/hooks'
import { appRoutes } from '~/router'
import { Text } from '~/ui-components'
import { graphqlErrorHandler } from '~/utils/graphqlErrorHandler'
import { TransportType } from '~api/gql-generated/graphql'
import { getTransportsQuery } from '~api/gql-query/car-park.query.graphql'
import { TransportListItem } from '~pages/CarParkPage/modules/Transport/components/TransportListItem'
import { useAuth } from '~providers/auth'
import { SearchInput } from '~shared/components/SearchInput'
import { TransportTypeSelectControl } from '~shared/controls'
import { TransportStationSelectControl } from '~shared/controls/TransportStationSelectControl'

export const Transport: React.FC = () => {
  const { companyId } = useAuth()
  const navigate = useNavigate()
  const { currentPage, setCurrentPage, pageSize, setPageSize, pageSizeOptions } = usePagination()
  const [searchTransportQuery, setSearchTransportQuery] = useState<string>('')
  const [transportTypeQuery, setTransportTypeQuery] = useState<TransportType[]>([])
  const [transportStationId, setTransportStationId] = useState<number>(0)

  const getTransports = useQuery(getTransportsQuery, {
    variables: {
      filter: {
        companyIds: [companyId],
        search: searchTransportQuery,
        types: transportTypeQuery,
        transportStationIds: transportStationId ? [transportStationId] : undefined,
      },
      pagination: { currentPage, pageSize },
    },
    onError: graphqlErrorHandler,
    fetchPolicy: 'cache-and-network',
  })

  const transports = getTransports.data?.transports.transports
  const pagination = getTransports.data?.transports.pagination

  const onClickCard = (id: number) => {
    navigate(appRoutes.carParkTransportCard.url.replace(':id', id.toString()))
  }

  return (
    <>
      <Flex vertical gap={24}>
        <Flex>
          <TransportStationSelectControl value={transportStationId} onChange={setTransportStationId} />
        </Flex>
        <Flex>
          <Text variant="normal">Всего машин: {pagination?.total ?? 0}</Text>
        </Flex>
        <Flex justify="space-between">
          <Flex justify="flex-start" gap={24}>
            <TransportTypeSelectControl value={undefined} onChange={setTransportTypeQuery} />
            <SearchInput defaultValue={searchTransportQuery} onSearch={setSearchTransportQuery} placeholder="Поиск" />
          </Flex>
          {!!transports?.length && (
            <Button type="primary" onClick={() => navigate(appRoutes.carParkCreateTransport.url)}>
              Добавить транспорт
            </Button>
          )}
        </Flex>
      </Flex>
      {transports?.length ? (
        <Flex vertical flex={1} gap={8}>
          {transports?.map((transport) => (
            <TransportListItem key={transport.id} transport={transport} onClickCard={onClickCard} />
          ))}

          {pagination && (
            <Pagination
              style={{ marginTop: 'auto' }}
              current={pagination?.currentPage}
              total={pagination?.total}
              pageSize={pagination?.pageSize}
              pageSizeOptions={pageSizeOptions}
              onShowSizeChange={(x, size) => setPageSize(size)}
              showSizeChanger
              onChange={setCurrentPage}
            />
          )}
        </Flex>
      ) : (
        <Flex justify="center">
          <Empty
            description={
              <>
                <Space direction="vertical">
                  <Text variant="normal-bold">Здесь будет ваш транспорт</Text>
                  <Text variant="normal">
                    Чтобы совершать перевозить грузы заказчиков, вам <br /> необходимо добавить транспорт
                  </Text>
                </Space>
              </>
            }
          >
            <Button type="primary" onClick={() => navigate(appRoutes.carParkCreateTransport.url)}>
              Добавить транспорт
            </Button>
          </Empty>
        </Flex>
      )}
    </>
  )
}
