import React, { useState } from 'react'

import { Flex, Table, Tooltip } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import { FiPlus } from 'react-icons/fi'

import { Text } from '~/ui-components'
import { showColumn } from '~/utils'
import { formatNumber } from '~/utils/formatNumber'
import { getDateFormat } from '~/utils/getDateFormat'
import { ElevatorAdvertListData, ElevatorProductTypeData, ProductParameterData } from '~api/generated'
import { useGetCurrentRole } from '~hooks/auth'
import { useGetElevatorAdverts } from '~hooks/elevator-advert'
import { CreateAdvertBuyOfferModal } from '~pages/BasisPage/components/CreateAdvertBuyOfferModal'
import { actionArrowColumn, idColumn, ndsColumn, priceColumn, volumeColumn } from '~shared/columns/shared'

import * as S from './BasisAdvertsForBuyerToSell.styled'

interface BasisAdvertsForBuyerToSellProps {
  elevatorId: number
  companyId: number
  productTypeData: ElevatorProductTypeData
  onClick: (advertId: number) => void
}

export const BasisAdvertsForBuyerToSellTable: React.FC<BasisAdvertsForBuyerToSellProps> = ({
  elevatorId,
  companyId,
  productTypeData,
  onClick,
}) => {
  const {
    company: { isSeller, isBuyer, isTrader },
  } = useGetCurrentRole()

  const [isCreateAdvertModalOpenForAdvertId, setIsCreateAdvertModalOpenForAdvertId] = useState<number | null>(null)
  const [isExpanded, setExpanded] = useState(true)
  const getElevatorAdvertsFn = useGetElevatorAdverts({
    elevatorId,
    productTypes: [productTypeData.type],
    userCompanyId: companyId,
    pageSize: 99,
    type: 'SELL',
  })

  const adverts = getElevatorAdvertsFn.data?.elevatorAdverts
  const pagination = getElevatorAdvertsFn.data?.pagination
  const maxAdvertsShown = 2
  const advertsListShort = !adverts
    ? []
    : adverts.length > maxAdvertsShown
    ? adverts.slice(0, maxAdvertsShown)
    : adverts
  const advertsList = isExpanded ? advertsListShort : adverts

  const makeOfferHandler = (record: ElevatorAdvertListData) => {
    setIsCreateAdvertModalOpenForAdvertId(record.id)
  }

  const handleRowClick = (record: ElevatorAdvertListData) => {
    onClick(record.id)
  }

  const uniqParams =
    adverts
      ?.flatMap((x) => x.product.parameters)
      .filter((el, idx, list) => list.findIndex((e) => e.type === el.type) === idx) ?? []

  const col = uniqParams?.map((k) => ({
    title: k.name,
    dataIndex: ['product', 'parameters'],
    width: '160px !important',
    render: (z: ProductParameterData[]) => {
      const parameter = z.find((x) => x.name === k.name)
      if (!parameter) {
        return
      }

      const isYear = parameter.type === 'crop_year'
      const value = Number(parameter.value).toFixed(isYear ? 0 : 1)
      const measurementUnit =
        parameter.measurementUnit === '%'
          ? `${parameter.measurementUnit}`
          : parameter.measurementUnit === null
          ? ''
          : ` ${parameter.measurementUnit}`

      return `${value}${measurementUnit}`
    },
  }))

  const columns: ColumnsType<ElevatorAdvertListData> = [
    {
      ...idColumn,
      fixed: 'left',
    },
    {
      ...priceColumn,
      dataIndex: 'pricePerTon',
      width: 140,
      fixed: 'left',
      render: (price: number, advert) => {
        return (
          <Flex vertical>
            <span>{formatNumber(price)}</span>
            {advert.priceUpdatedAt && <S.PublishedAt>обн. {getDateFormat(advert.priceUpdatedAt)}</S.PublishedAt>}
          </Flex>
        )
      },
    },
    { ...ndsColumn, dataIndex: 'usingNds', fixed: 'left' },
    { ...volumeColumn, fixed: 'left' },
    ...(col as any),
    ...showColumn<ElevatorAdvertListData>(isBuyer || isTrader, {
      title: '',
      dataIndex: 'addNew',
      key: 'action',
      fixed: 'right',
      width: 56,
      render: () => {
        return (
          <Tooltip title="Купить объем">
            <FiPlus size={24} display={'block'} color={'var(--primary-main-color)'} />
          </Tooltip>
        )
      },
      onCell: (record) => ({
        onClick: (e) => {
          e.stopPropagation()
          makeOfferHandler(record)
        },
      }),
    }),
    { ...actionArrowColumn, fixed: 'right', onCell: (record) => ({ onClick: () => handleRowClick(record) }) },
  ]

  return (
    <div>
      <S.TitleWrapper>
        <S.TitleWrapperInner>
          <Text variant="h3">{productTypeData.name}</Text>
          <S.CountWrapper>{adverts?.length}</S.CountWrapper>
        </S.TitleWrapperInner>
      </S.TitleWrapper>

      <Table
        rowKey="type"
        className="table-interactive"
        loading={getElevatorAdvertsFn.isLoading}
        columns={columns}
        dataSource={adverts}
        scroll={{ x: 'max-content' }}
        pagination={false}
        onRow={(record) => ({
          onClick: handleRowClick.bind(null, record),
        })}
      />

      <CreateAdvertBuyOfferModal
        userCompanyId={companyId}
        advertId={isCreateAdvertModalOpenForAdvertId!}
        isOpen={!!isCreateAdvertModalOpenForAdvertId}
        onCancel={() => setIsCreateAdvertModalOpenForAdvertId(null)}
      />
    </div>
  )
}
