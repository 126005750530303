import * as yup from 'yup'

import { VALIDATORS_MSG } from '~shared/validations'

import { WarehouseCultureFormValues } from '~pages/Warehouses/components/WarehouseCultureForm/WarehouseCultureForm.types'

export const warehouseCultureFormEditSchema = yup.object({
  culture: yup
    .object({
      // hasError: yup.bool().oneOf([false], 'Параметры культуры заполнены с ошибками'),
    })
    .required(VALIDATORS_MSG.required),
  labAnalysis: yup.mixed(),
  volumeTons: yup
    .number()
    .typeError(VALIDATORS_MSG.required)
    .required(VALIDATORS_MSG.required)
    .test('max', 'должно быть больше или равно 0', (val: number | undefined): boolean => Number(val) >= 0),
})
