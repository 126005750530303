export const getValueIfExistOrDefault = (
  value: string | number | boolean | null | undefined,
  measure?: string,
  defaultValue = '-'
) => {
  if (value === null || value === undefined) {
    return defaultValue
  }

  if (typeof value === 'string' && !value.trim()) {
    return defaultValue
  }

  if (typeof value === 'boolean') {
    return value ? 'Есть' : 'Нет'
  }

  if (measure) {
    return `${value} ${measure}`
  }

  return value
}
