/* tslint:disable */
/* eslint-disable */
/**
 * API Зерно-Агрегатора
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { FileData } from './file-data';

/**
 * 
 * @export
 * @interface DocumentTypeData
 */
export interface DocumentTypeData {
    /**
     * Тип документа
     * @type {string}
     * @memberof DocumentTypeData
     */
    'type': DocumentTypeDataTypeEnum;
    /**
     * Файл документа
     * @type {FileData}
     * @memberof DocumentTypeData
     */
    'file': FileData | null;
}

export const DocumentTypeDataTypeEnum = {
    FullCompanyInfoCard: 'full-company-info-card',
    Inn: 'inn',
    Ogrn: 'ogrn',
    CompanyRegulation: 'company-regulation',
    CompanyFoundationResolution: 'company-foundation-resolution',
    DirectorAppointmentResolution: 'director-appointment-resolution',
    DirectorAppointmentOrder: 'director-appointment-order',
    ExecutiveAgencyPassport: 'executive-agency-passport',
    AccountantAppointmentOrder: 'accountant-appointment-order',
    EgrulStatement: 'egrul-statement',
    Ogrnip: 'ogrnip',
    IndividualEntrepreneurPassport: 'individual-entrepreneur-passport',
    PaymentCardConfirmation: 'payment-card-confirmation',
    CounterpartyAuthorityConfirmation: 'counterparty-authority-confirmation',
    LegalAddressConfirmation: 'legal-address-confirmation',
    LastYearFinancialStatement: 'last-year-financial-statement',
    LastYearIncomeStatement: 'last-year-income-statement',
    LastYearStatisticReportForm4: 'last-year-statistic-report-form-4',
    LastYearStatisticReportForm29: 'last-year-statistic-report-form-29',
    FarmlandPropertyConfirmation: 'farmland-property-confirmation',
    SpecialTaxModeNotification: 'special-tax-mode-notification',
    NdsDeclaration: 'nds-declaration',
    AgricultureTaxDeclaration: 'agriculture-tax-declaration',
    IncomeTaxDeclaration: 'income-tax-declaration',
    Ndfl3Declaration: 'ndfl3-declaration'
} as const;

export type DocumentTypeDataTypeEnum = typeof DocumentTypeDataTypeEnum[keyof typeof DocumentTypeDataTypeEnum];


