import styled from 'styled-components'

import { breakpoints } from '~/tokens'

export const Wrapper = styled.div<{ background: string; color: string }>`
  display: flex;
  height: max-content;
  padding: 9px 8px;
  font-size: 14px;
  line-height: 14px;
  border-radius: 6px;
  background: ${(props) => props.background};
  color: ${(props) => props.color};

  @media (max-width: ${breakpoints.MOBILE_XL}px) {
    padding: 6px 8px;
    font-size: 12px;
    line-height: 14px;
  }
`
