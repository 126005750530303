import { isEqual } from 'lodash'
import { makeAutoObservable } from 'mobx'

import { AppConfig } from '~/appConfig'
import {
  foundProductNamesByIds,
  foundRegionNamesByIds,
  getActiveFilters,
  getOperationTypeLabel,
  getPriceLabel,
  getVolumeLabel,
} from '~/store/filters/_utils'
import {
  OfferApiGetOffersRequest,
  OfferCreatorType,
  OfferDataStatusEnum,
  OfferSortField,
  OrderDirection,
} from '~api/generated'
import { IOffersFilterForm } from '~pages/OffersPage/components/OffersFilter/components/OffersFilterForm'

import { IActiveFilterTag, IActiveTags, IStoreFilter } from '~/store/filters/_storeFilters.types'

export class OffersFilterStore implements IActiveTags<IOffersFilterForm>, IStoreFilter<IOffersFilterForm> {
  _filters: IOffersFilterForm
  idSearchQuery = ''
  companyId: number | null = null
  statusSearchQuery: OfferDataStatusEnum | 'all' = OfferDataStatusEnum.OnModeration
  creatorType: OfferCreatorType | undefined = undefined
  orderBy: OfferSortField | undefined = OfferSortField.PublishedAt
  order: OrderDirection | undefined = OrderDirection.Desc
  readonly _defaultFiltersConfig: IOffersFilterForm = {
    offerType: 'ALL',
    volume: {
      from: AppConfig.FILTERS.volume.from,
      to: AppConfig.FILTERS.volume.to,
    },
    price: {
      from: AppConfig.FILTERS.price.from,
      to: AppConfig.FILTERS.price.to,
    },
    regionIds: [],
    productTypes: [],
    distanceFromWarehouse: {
      warehouseId: undefined,
      distance: undefined,
    },
    usingNds: undefined,
  }

  constructor() {
    this._filters = this._defaultFiltersConfig
    makeAutoObservable(this)
  }

  setIdSearchQuery(searchQuery: string) {
    this.idSearchQuery = searchQuery
  }

  setStatusSearchQuery(status: OfferDataStatusEnum | 'all') {
    this.statusSearchQuery = status
  }

  setCreatorType(value: OfferCreatorType | undefined) {
    this.creatorType = value
  }

  setCompanyId(companyId: number | null) {
    this.companyId = companyId
  }

  setOrderBy(orderBy: OfferSortField | undefined) {
    this.orderBy = orderBy
  }

  setOrder(order: OrderDirection | undefined) {
    this.order = order
  }

  setFilters(newFilters: IOffersFilterForm): void {
    this._filters = newFilters
  }

  setFilterByDefault(filterKey: keyof IOffersFilterForm): void {
    const defaultValue = this.defaultFiltersConfig[filterKey]
    this.setFilters({ ...this.filters, [filterKey]: defaultValue })
  }

  get filters(): IOffersFilterForm {
    return this._filters
  }

  get defaultFiltersConfig(): IOffersFilterForm {
    return this._defaultFiltersConfig
  }

  get areCurrentFiltersDefault(): boolean {
    return isEqual(this._filters, this.defaultFiltersConfig)
  }

  get mappedCurrentFiltersToResponse(): Partial<OfferApiGetOffersRequest> {
    return this.areCurrentFiltersDefault
      ? {}
      : {
          minVolume: this._filters.volume.from,
          maxVolume: this._filters.volume.to,
          minPrice: this._filters.price.from,
          maxPrice: this._filters.price.to,
          type: this._filters.offerType === 'ALL' ? undefined : this._filters.offerType,
          regionIds: this._filters.regionIds,
          productTypes: this._filters.productTypes,
          warehouseIdForDistance: this._filters.distanceFromWarehouse?.warehouseId,
          maxDistance: this._filters.distanceFromWarehouse?.distance,
          includingNds: this._filters.usingNds,
        }
  }

  get hasActiveFilters(): boolean {
    return this._activeFiltersKeys.length > 0
  }

  get activeFiltersCount(): number {
    return this._activeFiltersKeys.length
  }

  getActiveFiltersTags(): IActiveFilterTag<IOffersFilterForm>[] {
    return this._activeFiltersKeys.map((key) => {
      const label = this._getFilterLabel(key)
      return {
        key,
        label,
      }
    })
  }

  get _activeFiltersKeys(): (keyof IOffersFilterForm)[] {
    return getActiveFilters(this._filters, this.defaultFiltersConfig)
  }

  _getFilterLabel(filterKey: keyof IOffersFilterForm): string {
    switch (filterKey) {
      case 'offerType': {
        return getOperationTypeLabel(this.filters.offerType)
      }
      case 'volume': {
        return getVolumeLabel(this.filters.volume)
      }
      case 'price': {
        return getPriceLabel(this.filters.price)
      }
      case 'productTypes': {
        return foundProductNamesByIds(this.filters.productTypes)
      }
      case 'regionIds': {
        return foundRegionNamesByIds(this.filters.regionIds)
      }
      default: {
        return ''
      }
    }
  }
}

export const offersFilterStore = new OffersFilterStore()
export const myOffersFilterStore = new OffersFilterStore()
export const adminOffersFilterStore = new OffersFilterStore()
