import React from 'react'

import { Button, Spin } from 'antd'
import { useNavigate, useParams } from 'react-router-dom'

import { useGetProductTypeData } from '~/hooks'
import { AppHeader } from '~/layout'
import { appRoutes } from '~/router'
import { appToast, httpErrorHandler } from '~/utils'
import { getOfferType } from '~/utils/offers/get-offer-type'
import { ModeratedAuctionDataStatusEnum, UpdateAuctionStatusBodyStatusEnum } from '~api/generated'
import { useUpdateAuctionModerator, useUpdateAuctionStatus } from '~hooks/auctions'
import { useGetModeratedAuctionById } from '~hooks/auctions'
import { useGetCompanyById } from '~hooks/company'
import { useGetUserById } from '~hooks/user/useGetUserById'
import { OfferCardDetails } from '~pages/OffersPage/components/OfferCardDetails'
import { useAuth } from '~providers/auth'

import * as S from './ModeratorAuctionDetails.styled'

export const ModeratorAuctionDetails: React.FC = () => {
  const navigate = useNavigate()
  const { userId } = useAuth()

  const { id: auctionId } = useParams<{ id: string | undefined }>()

  const {
    data: moderatedAuction,
    isLoading: isLoadingModeratedAuction,
    refetch: refetchModeratedAuction,
  } = useGetModeratedAuctionById(auctionId ? +auctionId : undefined)

  const { data: companyData } = useGetCompanyById(moderatedAuction?.offer.companyId)
  const { data: currentModerator } = useGetUserById(moderatedAuction?.moderatedBy)
  const updateAuctionModerator = useUpdateAuctionModerator()
  const updateAuctionStatus = useUpdateAuctionStatus()
  const { getProductTypeParametersForUI_V2 } = useGetProductTypeData()

  const changeAuctionStatus = async (status: UpdateAuctionStatusBodyStatusEnum, description: string) => {
    if (!auctionId) return
    try {
      await updateAuctionStatus.mutateAsync({
        auctionId: +auctionId,
        updateAuctionStatusBody: {
          status,
        },
      })
      handleBackButtonClick()
      appToast.success({ description })
    } catch (e) {
      httpErrorHandler(e, 'Ошибка при обновлении статуса лота')
    }
    await refetchModeratedAuction()
  }

  const handleAuctionToPublishClick = () => {
    void changeAuctionStatus(UpdateAuctionStatusBodyStatusEnum.InProgress, 'Лот опубликован')
  }

  const handleAuctionToRejectedClick = () => {
    void changeAuctionStatus(UpdateAuctionStatusBodyStatusEnum.Rejected, 'Лот отклонен')
  }

  const handleAuctionToWorkButtonClick = async () => {
    if (!auctionId || !userId) return
    try {
      await updateAuctionModerator.mutateAsync({
        auctionId: +auctionId,
        updateAuctionModeratorBody: {
          moderatorId: userId,
        },
      })

      appToast.success({ description: `Вы взяли в работу лот ${auctionId}` })
    } catch (e) {
      httpErrorHandler(e, 'Ошибка при обновлении модератора лота')
    }
    await refetchModeratedAuction()
  }

  const handleBackButtonClick = () => {
    navigate(appRoutes.moderatorAuctions.url)
  }

  if (!moderatedAuction || isLoadingModeratedAuction) {
    return (
      <S.SpinWrapper>
        <Spin spinning size="large" />
      </S.SpinWrapper>
    )
  }

  const offer = moderatedAuction.offer

  const isShowedActionButtons =
    moderatedAuction.moderatedBy && moderatedAuction.status === ModeratedAuctionDataStatusEnum.OnModeration

  return (
    <>
      <AppHeader
        title={`${appRoutes.moderatorAuctionDetails.title} ${auctionId}`}
        isBack
        onBack={handleBackButtonClick}
      />
      <S.ContentWrapper>
        <S.Header>
          {moderatedAuction.moderatedBy && (
            <>
              <S.TextModerator variant="normal">В работе у модератора</S.TextModerator>
              {currentModerator && <S.TextModeratorName variant="normal">{currentModerator.name}</S.TextModeratorName>}
            </>
          )}

          {moderatedAuction?.moderatedBy !== userId && (
            <Button htmlType="button" type="primary" onClick={handleAuctionToWorkButtonClick}>
              Взять в работу
            </Button>
          )}
        </S.Header>
        <OfferCardDetails
          statuses={[getOfferType(offer.type)]}
          title={offer.product.name}
          parameters={getProductTypeParametersForUI_V2(offer.product)}
          shippingRequired={offer.shippingRequired}
          type={offer.type}
          address={offer.address}
          isShowQualityIndicators={false}
          laboratoryAnalysisSource={offer.laboratoryAnalysis?.source}
          company={companyData ?? undefined}
          minPrice={offer.price}
          initialVolume={offer.volume}
          availableVolume={moderatedAuction?.notSelectedVolume}
          includingNds={offer.includingNds}
          startAuction={moderatedAuction.startedAt}
          distance={offer.distance}
          contactPersonName={offer.contactPerson?.name}
          contactPersonPhone={offer.contactPerson?.phoneNumber}
          isParametersCorrespondsToGost={offer.product.parametersIsCorrespondsToGost}
          gost={offer.product.gost}
          note={offer.note}
          priceDetail={offer.priceDetail}
        />

        {isShowedActionButtons && (
          <S.ActionButtons>
            <Button onClick={handleAuctionToRejectedClick}>Отклонить</Button>
            <Button type="primary" onClick={handleAuctionToPublishClick}>
              Опубликовать
            </Button>
          </S.ActionButtons>
        )}
      </S.ContentWrapper>
    </>
  )
}
