import React, { useState } from 'react'

import { Select } from 'antd'
import { DaDataAddress, DaDataSuggestion } from 'react-dadata/dist/types'
import { Controller, useForm } from 'react-hook-form'
import { FiPlus } from 'react-icons/fi'

import { FormField } from '~/ui-components'
import { useGetWarehouses } from '~hooks/warehouses'
import { AddressControlDadata } from '~shared/controls'

export interface ISelectedWarehouse {
  isNew: boolean
  warehouseId?: number
  warehouseAddress?: string
}

interface IForm {
  warehouseAddress: string
}

interface IWarehouseSelectProps {
  companyId: number
  onSelect: (value: ISelectedWarehouse | null) => void
}
export const WarehouseSelect: React.FC<IWarehouseSelectProps> = ({ companyId, onSelect }) => {
  const warehouses = useGetWarehouses(companyId)
  const [isNewAddress, setIsNewAddress] = useState(false)

  const {
    control,
    setValue,
    formState: { errors },
  } = useForm<IForm>({
    defaultValues: {
      warehouseAddress: '',
    },
  })

  const dropdownOptions =
    warehouses.data?.map((x) => ({
      value: x.id,
      label: `${x.name ? x.name.concat(' / ') : ''}${x.address}`,
    })) ?? []

  const onSelectWarehouseHandler = (warehouseId: number | null) => {
    if (!warehouseId) {
      setIsNewAddress(true)
      onSelect(null)
      setValue('warehouseAddress', '')
      return
    }
    onSelect({
      isNew: false,
      warehouseId,
    })
    setIsNewAddress(false)
  }
  const onCreateNewWarehouseHandler = (value: DaDataSuggestion<DaDataAddress> | undefined) => {
    const warehouseAddress = value?.unrestricted_value
    onSelect({
      isNew: true,
      warehouseAddress,
    })
  }

  return (
    <>
      <Select
        style={{ width: '100%' }}
        loading={warehouses.isLoading || warehouses.isFetching}
        onSelect={onSelectWarehouseHandler}
        placeholder="Выберите адрес"
      >
        {dropdownOptions.map((option) => (
          <Select.Option key={option.value} value={option.value}>
            {option.label}
          </Select.Option>
        ))}
        <Select.Option value={null}>
          <FiPlus />
          Добавить новый склад
        </Select.Option>
      </Select>

      <form style={{ marginTop: 16 }}>
        <FormField
          isRequired
          isVisible={isNewAddress}
          label="Введите адрес"
          htmlFor="warehouseAddress"
          validateMessage={errors.warehouseAddress?.message}
        >
          <Controller
            name="warehouseAddress"
            control={control}
            render={({ field }) => (
              <AddressControlDadata
                id="address"
                value={field.value}
                onChange={(value) => {
                  field.onChange(value)
                  onCreateNewWarehouseHandler(value)
                }}
                hasError={!!errors.warehouseAddress}
              />
            )}
          />
        </FormField>
      </form>
    </>
  )
}
