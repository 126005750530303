import { useMutation } from '@tanstack/react-query'

import { DealDocumentApiDealDocumentControllerUpdateDocumentFileRequest } from '~api/generated'
import { useApi } from '~providers/api'

export const useUpdateDealDocumentStatus = () => {
  const api = useApi()

  const updateDealDocumentStatusFn = async (
    request: DealDocumentApiDealDocumentControllerUpdateDocumentFileRequest
  ): Promise<void> => {
    const res = await api.dealDocument.dealDocumentControllerUpdateDocumentFile(request)
    return res.data
  }

  return useMutation(updateDealDocumentStatusFn)
}
