import React from 'react'

import { Select } from 'antd'
import { BaseSelectRef } from 'rc-select'

import { CustomControlInterface } from '~shared/controls/CustomControl.interface'

import { FreightPackingType } from '~api/gql-generated/graphql'
import { getFreightPackingType } from '~/utils/logistics/get-freight-packing-type'

interface FreightPackingTypeSelectControlProps extends CustomControlInterface {
  id?: string
  value: FreightPackingType | undefined
  onChange: (value: FreightPackingType) => void
  hasError?: boolean
}

export const FreightPackingTypeSelectControl: React.FC<FreightPackingTypeSelectControlProps> = React.forwardRef<
  BaseSelectRef,
  FreightPackingTypeSelectControlProps
>(({ id, value, onChange, hasError }, ref) => {
  return (
    <Select
      ref={ref}
      style={{ width: '100%' }}
      placeholder="Выберите"
      value={value}
      onChange={onChange}
      status={!!hasError ? 'error' : undefined}
    >
      {Object.values(FreightPackingType).map((freightPackingType) => (
        <Select.Option key={freightPackingType} value={freightPackingType}>
          {getFreightPackingType(freightPackingType)}
        </Select.Option>
      ))}
    </Select>
  )
})

FreightPackingTypeSelectControl.displayName = 'FreightPackingTypeSelectControl'
