import React from 'react'

import { Pagination } from 'antd'
import { useNavigate } from 'react-router-dom'

import { usePagination } from '~/hooks'
import { appRoutes } from '~/router'
import { useGetModeratorDealsList } from '~hooks/moderator'

import { ModeratorDealListMobile, ModeratorDealListTable } from './components'

import { ModeratorDealListTableDataType } from './components/ModeratorDealListTable/ModeratorDealListTable.types'

import * as S from './ModeratorDealList.styled'

export const ModeratorDealList: React.FC = () => {
  const navigate = useNavigate()
  const { currentPage, pageSize, setCurrentPage, setPageSize, pageSizeOptions } = usePagination()
  const dealListFn = useGetModeratorDealsList({
    currentPage,
    pageSize,
  })

  const dealList = dealListFn.data?.complexDeals ?? []
  const pagination = dealListFn.data?.pagination

  const onDealClickHandler = (record: ModeratorDealListTableDataType) => {
    navigate(record.id + '/' + appRoutes.dealDetailsInformation.url)
  }

  const dealListTableData: ModeratorDealListTableDataType[] =
    dealList.map((deal) => ({
      id: deal.id,
      culture: deal.offer.product.name ?? '-',
      quantity: deal.offer.volume,
      price: deal.offer.price,
      statusSeller: deal.dealWithSeller?.status ?? '-',
      statusBuyer: deal.dealWithBuyer?.status ?? '-',
      sellerCompanyName: deal.seller?.name ?? '-',
      buyerCompanyName: deal.buyer?.name ?? '-',
      startedAt: deal.createdAt ?? '',
    })) ?? []

  return (
    <>
      <S.Desktop>
        <ModeratorDealListTable
          tableData={dealListTableData}
          loading={dealListFn.isLoading || dealListFn.isFetching}
          onRowClick={onDealClickHandler}
        />
      </S.Desktop>
      <S.Mobile>
        <ModeratorDealListMobile
          data={dealListTableData}
          loading={dealListFn.isLoading || dealListFn.isFetching}
          onItemClick={onDealClickHandler}
        />
      </S.Mobile>

      {!!pagination && (
        <Pagination
          current={pagination?.currentPage}
          total={pagination?.total}
          pageSize={pagination?.pageSize}
          pageSizeOptions={pageSizeOptions}
          onShowSizeChange={(x, size) => setPageSize(size)}
          showSizeChanger
          onChange={setCurrentPage}
        />
      )}
    </>
  )
}
