import React, { useState } from 'react'

import { Button, Flex, Modal, Spin } from 'antd'
import { UploadFile } from 'antd/es/upload/interface'
import { pick } from 'lodash'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

import { useMutation } from '@apollo/client'
import { ApolloError } from '@apollo/client/errors'
import { yupResolver } from '@hookform/resolvers/yup'

import { AppHeader } from '~/layout'
import { appRoutes } from '~/router'
import { Text } from '~/ui-components'
import { appToast } from '~/utils'
import { graphqlErrorHandler } from '~/utils/graphqlErrorHandler'
import { TransportDocumentType } from '~api/gql-generated/graphql'
import {
  addTransportDocumentFilesMutation,
  createTransportDocumentMutation,
  createTransportMutation,
  deleteTransportDocumentFileMutation,
} from '~api/gql-mutations/car-park.mutation.graphql'
import { getTransportsQuery } from '~api/gql-query/car-park.query.graphql'
import { useUploadFilesAndGetIds } from '~hooks/_utils'
import { DocumentsFields } from '~pages/CarParkPage/components/DocumentsFields'
import { useAuth } from '~providers/auth'

import {
  CreateAdditionalFields,
  CreateMainFields,
  CreateOwnershipFields,
  CreateTransportTypeFields,
} from './components'

import { CreateTransportSchema, CreateTransportValues } from './CreateTransport.types'
import { TransportDocumentsFieldsProps } from '~pages/CarParkPage/modules/Transport/Transport.types'

import * as S from './CreateTransport.styled'

export const CreateTransport: React.FC = () => {
  const navigate = useNavigate()
  const { companyId } = useAuth()
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false)
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false)
  const uploadFilesAndGetIdsFn = useUploadFilesAndGetIds()

  const {
    handleSubmit,
    control,
    formState: { errors },
    trigger,
    watch,
  } = useForm<CreateTransportValues>({
    resolver: yupResolver(CreateTransportSchema),
  })

  const [transportDocumentsProps, setTransportDocumentsProps] = useState<TransportDocumentsFieldsProps>(
    [
      TransportDocumentType.RegistrationCertificate,
      TransportDocumentType.TransportPassport,
      TransportDocumentType.LeasingContract,
      TransportDocumentType.Photo,
    ].reduce((acc, cur) => {
      acc[cur] = {
        type: cur,
        documentId: undefined,
        uploadProps: {
          defaultFileList: [],
          onChange: ({ fileList }) => {
            setTransportDocumentsProps((prev) => ({
              ...prev,
              [cur]: { ...prev[cur], uploadProps: { ...prev[cur].uploadProps, fileList } },
            }))
          },
        },
      }
      return acc
    }, {} as TransportDocumentsFieldsProps)
  )

  const [createTransport, createTransportState] = useMutation(createTransportMutation, {
    refetchQueries: [getTransportsQuery],
  })
  const [createTransportDocument] = useMutation(createTransportDocumentMutation)
  const [addTransportDocumentFiles] = useMutation(addTransportDocumentFilesMutation)
  const [deleteTransportDocumentFile] = useMutation(deleteTransportDocumentFileMutation)

  const onCreateTransport = async (formValues: CreateTransportValues) => {
    setIsCreateModalOpen(false)

    try {
      const { data } = await createTransport({
        variables: {
          input: {
            companyId,
            type: formValues.type,
            brand: formValues.brand,
            bodyTypeId: formValues.bodyTypeId,
            manufacturingDate: formValues.manufacturingDate,
            vin: formValues.vin ?? '',
            registrationCertificate: formValues.registrationCertificate,
            transportPassport: formValues.transportPassport,
            licensePlate: formValues.licensePlate,
            axlesCount: formValues.axlesCount,
            loadCapacityKg: formValues.loadCapacityKg ? formValues.loadCapacityKg * 1000 : formValues.loadCapacityKg,
            loadVolumeL: formValues.loadVolumeL ? formValues.loadVolumeL * 1000 : formValues.loadVolumeL,
            lengthCm: formValues.lengthCm ? formValues.lengthCm * 100 : formValues.lengthCm,
            widthCm: formValues.widthCm ? formValues.widthCm * 100 : formValues.widthCm,
            heightCm: formValues.heightCm ? formValues.heightCm * 100 : formValues.heightCm,
            loadingMethodId: formValues.loadingMethodId,
            adrCertificate: formValues.adrCertificate,
            shortRouteKm: formValues.shortRouteKm,
            longRouteKm: formValues.longRouteKm,
            preferredRouteType: formValues.preferredRouteType,
            canWorkInField: formValues.canWorkInField,
            ownershipType: formValues.ownershipType,
            ownerName: formValues.ownerName,
            additionalDetails: formValues.additionalDetails,
          },
        },
      })
      if (data?.transportCreate.id) {
        await attachTransportDocuments(data.transportCreate.id)
      }
      appToast.success({ message: 'Транспорт добавлен' })
      navigate(appRoutes.carParkTransport.url)
    } catch (e) {
      if (e instanceof ApolloError) {
        graphqlErrorHandler(e, 'Ошибка при добавлении транспорта')
      }
    }
  }

  const getUploadedFileIds = async (fileList: UploadFile[]) => {
    const uploadFilesList = fileList.map((file) => file.originFileObj).filter((file) => file !== undefined)
    return uploadFilesList.length ? await uploadFilesAndGetIdsFn.mutateAsync(uploadFilesList as File[]) : []
  }

  const deleteTransportDocumentFiles = (documentId: number, files: UploadFile[]) => {
    return files.map((file) =>
      deleteTransportDocumentFile({
        variables: {
          documentFileId: +file.uid,
        },
      })
    )
  }

  const handleCreateTransportDocument = async (
    transportId: number,
    fileKeys: string[],
    type: TransportDocumentType
  ) => {
    try {
      await createTransportDocument({
        variables: {
          input: {
            transportId,
            fileKeys,
            type,
          },
        },
      })
    } catch (e) {
      if (e instanceof ApolloError) {
        graphqlErrorHandler(e, 'Ошибка при создании документа транспорта')
      }
    }
  }

  const attachTransportDocuments = async (transportId: number) => {
    return Promise.all(
      Object.values(transportDocumentsProps)
        .map(async (transportDocumentsPropsItem) => {
          const uploadedFileIds = await getUploadedFileIds(transportDocumentsPropsItem.uploadProps.fileList ?? [])
          const operations = []

          if (transportDocumentsPropsItem.documentId) {
            operations.push(
              deleteTransportDocumentFiles(
                transportDocumentsPropsItem.documentId,
                (transportDocumentsPropsItem.uploadProps.fileList &&
                  transportDocumentsPropsItem.uploadProps.defaultFileList?.filter(
                    (defaultFile) =>
                      !transportDocumentsPropsItem.uploadProps.fileList?.some((file) => defaultFile.uid === file.uid)
                  )) ??
                  []
              )
            )

            uploadedFileIds.length &&
              operations.push(
                addTransportDocumentFiles({
                  variables: {
                    documentId: transportDocumentsPropsItem.documentId,
                    input: {
                      fileKeys: uploadedFileIds,
                    },
                  },
                })
              )
          } else {
            uploadedFileIds.length &&
              operations.push(
                handleCreateTransportDocument(transportId, uploadedFileIds, transportDocumentsPropsItem.type)
              )
          }

          return operations
        })
        .flat()
    )
  }

  const checkValidForm = async () => {
    const isValid = await trigger()
    if (isValid) {
      setIsCreateModalOpen(true)
    }
  }

  const onCancel = async () => {
    setIsCancelModalOpen(true)
  }

  const watchType = watch('type')
  const watchOwnershipType = watch('ownershipType')

  const documentsProps = pick(transportDocumentsProps, [
    TransportDocumentType.RegistrationCertificate,
    TransportDocumentType.TransportPassport,
    TransportDocumentType.LeasingContract,
  ]) as TransportDocumentsFieldsProps
  const photosProps = pick(transportDocumentsProps, [TransportDocumentType.Photo]) as TransportDocumentsFieldsProps

  return (
    <Spin spinning={createTransportState.loading}>
      <AppHeader title={appRoutes.carParkCreateTransport.title} isBack onBack={onCancel} />

      <S.Wrapper>
        <form>
          <Flex vertical gap={32}>
            <S.FlexItem vertical gap={32}>
              <CreateTransportTypeFields control={control} errors={errors} />
            </S.FlexItem>
            <S.FlexItem vertical gap={32}>
              <CreateMainFields control={control} errors={errors} type={watchType} transportPhotosProps={photosProps} />
            </S.FlexItem>
            <S.FlexItem vertical gap={8}>
              <CreateOwnershipFields control={control} errors={errors} ownershipType={watchOwnershipType} />
            </S.FlexItem>
            <S.FlexItem vertical gap={32}>
              <DocumentsFields {...documentsProps} />
            </S.FlexItem>
            <Flex vertical gap={32}>
              <CreateAdditionalFields control={control} errors={errors} />
            </Flex>
            <Flex justify="flex-end" gap={8}>
              <S.StyledButton type="default" htmlType="button" onClick={onCancel}>
                Отмена
              </S.StyledButton>
              <Button type="primary" htmlType="button" onClick={checkValidForm}>
                Сохранить
              </Button>
            </Flex>
          </Flex>
        </form>
      </S.Wrapper>

      <Modal
        open={isCancelModalOpen}
        onCancel={() => setIsCancelModalOpen(false)}
        width={400}
        footer={null}
        title="😞 Данные не сохранятся"
      >
        <Flex vertical gap={32}>
          <Text variant="label">
            Нажимая “Продолжить”, мы не сможем сохранить введенные вами данные. Уверены, что хотите продолжить?
          </Text>

          <Flex justify="flex-end" gap={8}>
            <S.StyledButton type="default" onClick={() => setIsCancelModalOpen(false)}>
              Отмена
            </S.StyledButton>
            <Button type="primary" htmlType="button" onClick={() => navigate(-1)}>
              Продолжить
            </Button>
          </Flex>
        </Flex>
      </Modal>

      <Modal
        open={isCreateModalOpen}
        onCancel={() => setIsCreateModalOpen(false)}
        width={500}
        footer={null}
        title="🚛 Проверьте, поля заполнены верно?"
      >
        <Flex vertical gap={32}>
          <Text variant="label">
            С данным авто можно будет составлять реестр авто, поэтому важно, чтобы все поля были заполнены правильно.
          </Text>

          <Text variant="label">Вы точно хотите сохранить данные и закончить создание транспорта?</Text>

          <Flex justify="flex-end" gap={8}>
            <S.StyledButton type="default" onClick={() => setIsCreateModalOpen(false)}>
              Пойду перепроверю
            </S.StyledButton>
            <Button type="primary" htmlType="button" onClick={handleSubmit(onCreateTransport)}>
              Да, все верно
            </Button>
          </Flex>
        </Flex>
      </Modal>
    </Spin>
  )
}
