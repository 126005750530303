import React from 'react'

import { Button, Modal } from 'antd'

import { modalSharedSettings } from '~/utils'

interface IConfirmBankDetailsRemoveModalProps {
  text: string
  isOpen: boolean
  onCancel: () => void
  onConfirm: () => void
}

export const ConfirmBankDetailsRemoveModal: React.FC<IConfirmBankDetailsRemoveModalProps> = ({
  isOpen = false,
  text,
  onCancel,
  onConfirm,
}) => {
  return (
    <Modal
      {...modalSharedSettings}
      open={isOpen}
      onCancel={onCancel}
      title="Подтверждение"
      footer={[
        <Button key="cancel" onClick={onCancel}>
          Отмена
        </Button>,
        <Button key="submit" type="primary" onClick={onConfirm}>
          Удалить
        </Button>,
      ]}
    >
      {text}
    </Modal>
  )
}
