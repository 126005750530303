import React, { useEffect, useState } from 'react'

import { Button, Modal, Select } from 'antd'

import { FormField } from '~/ui-components'
import { appToast, modalSharedSettings } from '~/utils'
import { getDealDocumentStatus } from '~/utils/deals'
import { DealDocumentData, DealDocumentDataTypeEnum, DocumentPartStatus, FileData } from '~api/generated'
import { useGetDealTemplateDocuments } from '~hooks/deal-documents'
import { DealPaymentRemoveModal } from '~pages/Deals/modules/DealPayment/components/DealPaymentRemoveModal'
import {
  findUserDocumentByType,
  getDealDocumentStatusDropdownOptions,
  getDocumentsTypesDropdownOptions,
} from '~shared/components/DocumentChecker/utils'
import { DocumentViewer } from '~shared/components/DocumentViewer/DocumentViewer'

import * as S from './DocumentChecker.styled'

interface IDocumentCheckerProps {
  isCheckerVisible: boolean
  // список отображаемых Шаблонов документов
  docTemplateList: DealDocumentDataTypeEnum[]
  // список загруженных документов, которые нужно проверить
  documentsForCheck: DealDocumentData[]
  // какой загруженный документ будет отображен первым по дефолту при открытии вьювера
  startCheckFromDocument: DealDocumentDataTypeEnum | null
  // если среди загруженных документов несколько экземпляров одного типа, то будет отображен конкретный если передано значение
  startCheckFromDocumentInstanceId?: number | null
  // событие при изменении статуса экземпляра документа
  onDocumentFileStatusChange: (file: FileData, status: DocumentPartStatus, reason?: string) => void
  // событие при нажатии кнопки Заверщить проверку
  onFinishCheck: () => void
}

export const DocumentChecker: React.FC<IDocumentCheckerProps> = (props) => {
  const [selectedDocTemplate, setSelectedDocTemplate] = useState<DealDocumentDataTypeEnum | null>(null)
  const [selectedUserDocument, setSelectedUserDocument] = useState<DealDocumentDataTypeEnum | null>(null)
  const [selectedFile, setSelectedFile] = useState<FileData | null>(null)
  const [isRejectDocumentModalVisible, setIsRejectDocumentModalVisible] = useState(false)

  const getDealTemplateDocumentsFn = useGetDealTemplateDocuments({
    types: selectedDocTemplate ? [selectedDocTemplate] : undefined,
  })

  useEffect(() => setSelectedDocTemplate(selectedUserDocument), [selectedUserDocument])
  useEffect(() => setSelectedUserDocument(selectedDocTemplate), [selectedDocTemplate])

  useEffect(
    () => setSelectedUserDocument(findUserDocumentByType(props.documentsForCheck, props.startCheckFromDocument)),
    [props.startCheckFromDocument]
  )

  const handleModalCancelClick = () => {
    props.onFinishCheck()
  }

  const handleDocumentFileStatusChange = (status: DocumentPartStatus) => {
    if (!selectedFile) {
      return console.log('Файл не выбран')
    }

    if (status === DocumentPartStatus.Rejected) {
      return setIsRejectDocumentModalVisible(true)
    }
    Modal.confirm({
      title: 'Вы действительно хотите изменить статус файла документа?',
      content: (
        <>
          Статус файла будет изменен на <b>{getDealDocumentStatus(status).name}</b>
        </>
      ),
      okText: 'Изменить',
      cancelText: 'Отмена',
      onOk() {
        props.onDocumentFileStatusChange(selectedFile, status as DocumentPartStatus)
      },
    })
  }

  const handleRejectDocument = (reason: string) => {
    if (!selectedFile) {
      return console.log('Файл не выбран')
    }
    props.onDocumentFileStatusChange(selectedFile, DocumentPartStatus.Rejected, reason)
  }

  const handleChangeSelectedUserDocument = (action: 'increaseOne' | 'decreaseOne') => () => {
    const curIdx = props.documentsForCheck.findIndex((x) => x.type === selectedUserDocument)
    const len = props.documentsForCheck.length

    if (action === 'increaseOne') {
      const next = (curIdx + 1) % len
      setSelectedUserDocument(props.documentsForCheck[next].type)
    }
    if (action === 'decreaseOne') {
      const previous = (curIdx + len - 1) % len
      setSelectedUserDocument(props.documentsForCheck[previous].type)
    }
  }

  const docTemplateData = getDealTemplateDocumentsFn?.data?.length ? getDealTemplateDocumentsFn.data[0] : null
  const userDocumentData = props.documentsForCheck.find((doc) => doc.type === selectedUserDocument)

  if (getDealTemplateDocumentsFn.isError) {
    appToast.error({ description: 'Ошибка при загрузке щаблона документа' })
  }

  return (
    <Modal
      {...modalSharedSettings}
      title="Проверка документов"
      open={props.isCheckerVisible}
      style={{ top: 20 }}
      width={1400}
      onCancel={handleModalCancelClick}
      footer={
        <S.FooterContent>
          <S.FooterContentCenter>
            <Button key="prev" onClick={handleChangeSelectedUserDocument('decreaseOne')} htmlType="button">
              Предыдущий
            </Button>
            <Button key="next" onClick={handleChangeSelectedUserDocument('increaseOne')} htmlType="button">
              Следующий
            </Button>
          </S.FooterContentCenter>
          <Button key="submit" type="primary" htmlType="button" onClick={handleModalCancelClick}>
            Завершить проверку
          </Button>
        </S.FooterContent>
      }
    >
      <S.Content>
        <S.LeftSide>
          <FormField label="Шаблон документа" grow={false}>
            <Select
              value={selectedDocTemplate}
              style={{ width: '100%' }}
              options={getDocumentsTypesDropdownOptions(props.docTemplateList)}
              onSelect={(v: DealDocumentDataTypeEnum) => setSelectedDocTemplate(v)}
            />
          </FormField>

          {docTemplateData?.instances?.length ? (
            <DocumentViewer key={docTemplateData.type} docInstances={docTemplateData.instances} />
          ) : (
            <div>Шаблон отсутствует</div>
          )}
        </S.LeftSide>
        <S.RightSide>
          <FormField label="Загруженный документ" grow={false}>
            <Select
              value={selectedFile?.status}
              style={{ width: 200 }}
              options={getDealDocumentStatusDropdownOptions()}
              onSelect={handleDocumentFileStatusChange}
            />
          </FormField>

          {userDocumentData ? (
            <DocumentViewer
              key={userDocumentData.type}
              docInstances={userDocumentData.instances}
              startCheckFromDocInstanceId={props.startCheckFromDocumentInstanceId}
              onFileView={(fileData) => setSelectedFile(fileData)}
            />
          ) : (
            'Документ отсутствует'
          )}
        </S.RightSide>
      </S.Content>
      <DealPaymentRemoveModal
        type="rejectDocument"
        isOpen={isRejectDocumentModalVisible}
        onClose={() => setIsRejectDocumentModalVisible(false)}
        onRemove={({ message }) => handleRejectDocument(message)}
      />
    </Modal>
  )
}
