/* tslint:disable */
/* eslint-disable */
/**
 * API Зерно-Агрегатора
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AdminCreateOfferResponseFromBuyerBody } from '../models';
// @ts-ignore
import { AdminCreateOfferResponseFromBuyerResponse } from '../models';
// @ts-ignore
import { AdminCreateOfferResponseFromSellerBody } from '../models';
// @ts-ignore
import { AdminCreateOfferResponseFromSellerResponse } from '../models';
// @ts-ignore
import { AdminGetElevatorAdvertOfferResponse } from '../models';
// @ts-ignore
import { AdminGetElevatorAdvertOffersResponse } from '../models';
// @ts-ignore
import { AdminUpdateElevatorAdvertOfferRejectBody } from '../models';
// @ts-ignore
import { AdminUpdateElevatorAdvertOfferStatusBody } from '../models';
// @ts-ignore
import { AdminUpdateElevatorOfferResponseBody } from '../models';
// @ts-ignore
import { AdminUpdateElevatorOfferResponseResponse } from '../models';
// @ts-ignore
import { CreateOfferResponseFromBuyerBody } from '../models';
// @ts-ignore
import { CreateOfferResponseFromBuyerResponse } from '../models';
// @ts-ignore
import { CreateOfferResponseFromSellerBody } from '../models';
// @ts-ignore
import { CreateOfferResponseFromSellerResponse } from '../models';
// @ts-ignore
import { CreateOfferResponsePurchaseStatusBody } from '../models';
// @ts-ignore
import { ElevatorOfferResponseStatus } from '../models';
// @ts-ignore
import { GetElevatorAdvertOfferResponse } from '../models';
// @ts-ignore
import { GetElevatorAdvertOffersResponse } from '../models';
// @ts-ignore
import { GetMyElevatorAdvertOffersResponse } from '../models';
// @ts-ignore
import { OrderDirection } from '../models';
// @ts-ignore
import { UpdateElevatorAdvertOfferRejectBody } from '../models';
// @ts-ignore
import { UpdateElevatorOfferResponseAcceptBody } from '../models';
// @ts-ignore
import { UpdateElevatorOfferResponseCancelBody } from '../models';
/**
 * ElevatorAdvertOfferApi - axios parameter creator
 * @export
 */
export const ElevatorAdvertOfferApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Создать отклик на объявление продавца
         * @param {AdminCreateOfferResponseFromBuyerBody} adminCreateOfferResponseFromBuyerBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCreateOfferResponseFromBuyer: async (adminCreateOfferResponseFromBuyerBody: AdminCreateOfferResponseFromBuyerBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'adminCreateOfferResponseFromBuyerBody' is not null or undefined
            assertParamExists('adminCreateOfferResponseFromBuyer', 'adminCreateOfferResponseFromBuyerBody', adminCreateOfferResponseFromBuyerBody)
            const localVarPath = `/admin/offer-response/buy`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminCreateOfferResponseFromBuyerBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Создать отклик на объявление покупателя
         * @param {AdminCreateOfferResponseFromSellerBody} adminCreateOfferResponseFromSellerBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCreateOfferResponseFromSeller: async (adminCreateOfferResponseFromSellerBody: AdminCreateOfferResponseFromSellerBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'adminCreateOfferResponseFromSellerBody' is not null or undefined
            assertParamExists('adminCreateOfferResponseFromSeller', 'adminCreateOfferResponseFromSellerBody', adminCreateOfferResponseFromSellerBody)
            const localVarPath = `/admin/offer-response/sell`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminCreateOfferResponseFromSellerBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminElevatorAdvertOfferControllerAdminUpdateAdvertOfferModerator: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminElevatorAdvertOfferControllerAdminUpdateAdvertOfferModerator', 'id', id)
            const localVarPath = `/admin/elevator-offer-response/{id}/assign-moderator`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminElevatorAdvertOfferControllerGetAdvertOffer: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminElevatorAdvertOfferControllerGetAdvertOffer', 'id', id)
            const localVarPath = `/admin/elevator-offer-response/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {AdminUpdateElevatorAdvertOfferRejectBody} adminUpdateElevatorAdvertOfferRejectBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminElevatorAdvertOfferControllerRejectElevatorAdvertOffer: async (id: number, adminUpdateElevatorAdvertOfferRejectBody: AdminUpdateElevatorAdvertOfferRejectBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminElevatorAdvertOfferControllerRejectElevatorAdvertOffer', 'id', id)
            // verify required parameter 'adminUpdateElevatorAdvertOfferRejectBody' is not null or undefined
            assertParamExists('adminElevatorAdvertOfferControllerRejectElevatorAdvertOffer', 'adminUpdateElevatorAdvertOfferRejectBody', adminUpdateElevatorAdvertOfferRejectBody)
            const localVarPath = `/admin/elevator-offer-response/{id}/reject-by-buyer`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminUpdateElevatorAdvertOfferRejectBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {AdminUpdateElevatorAdvertOfferStatusBody} adminUpdateElevatorAdvertOfferStatusBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminElevatorAdvertOfferControllerUpdateElevatorAdvertOfferStatus: async (id: number, adminUpdateElevatorAdvertOfferStatusBody: AdminUpdateElevatorAdvertOfferStatusBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminElevatorAdvertOfferControllerUpdateElevatorAdvertOfferStatus', 'id', id)
            // verify required parameter 'adminUpdateElevatorAdvertOfferStatusBody' is not null or undefined
            assertParamExists('adminElevatorAdvertOfferControllerUpdateElevatorAdvertOfferStatus', 'adminUpdateElevatorAdvertOfferStatusBody', adminUpdateElevatorAdvertOfferStatusBody)
            const localVarPath = `/admin/elevator-offer-response/{id}/status`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminUpdateElevatorAdvertOfferStatusBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получить список откликов для модератора
         * @param {number} [currentPage] Номер текущей страницы
         * @param {number} [pageSize] Количество элементов на странице
         * @param {Array<ElevatorOfferResponseStatus>} [statuses] Статусы откликов
         * @param {number} [id] Идентификатор отклика
         * @param {number} [companyId] Идентификатор компании
         * @param {number} [elevatorId] Идентификатор элеватора
         * @param {number} [elevatorAdvertId] Идентификатор объявления
         * @param {'BUY' | 'SELL'} [type] Тип отклика
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetElevatorOfferResponses: async (currentPage?: number, pageSize?: number, statuses?: Array<ElevatorOfferResponseStatus>, id?: number, companyId?: number, elevatorId?: number, elevatorAdvertId?: number, type?: 'BUY' | 'SELL', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/elevator-offer-responses`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (currentPage !== undefined) {
                localVarQueryParameter['current-page'] = currentPage;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page-size'] = pageSize;
            }

            if (statuses) {
                localVarQueryParameter['statuses'] = statuses;
            }

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }

            if (elevatorId !== undefined) {
                localVarQueryParameter['elevatorId'] = elevatorId;
            }

            if (elevatorAdvertId !== undefined) {
                localVarQueryParameter['elevatorAdvertId'] = elevatorAdvertId;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Обновить отклик на объявление
         * @param {number} elevatorOfferResponseId 
         * @param {AdminUpdateElevatorOfferResponseBody} adminUpdateElevatorOfferResponseBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUpdateElevatorOfferResponse: async (elevatorOfferResponseId: number, adminUpdateElevatorOfferResponseBody: AdminUpdateElevatorOfferResponseBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'elevatorOfferResponseId' is not null or undefined
            assertParamExists('adminUpdateElevatorOfferResponse', 'elevatorOfferResponseId', elevatorOfferResponseId)
            // verify required parameter 'adminUpdateElevatorOfferResponseBody' is not null or undefined
            assertParamExists('adminUpdateElevatorOfferResponse', 'adminUpdateElevatorOfferResponseBody', adminUpdateElevatorOfferResponseBody)
            const localVarPath = `/admin/elevator-offer-response/{elevatorOfferResponseId}`
                .replace(`{${"elevatorOfferResponseId"}}`, encodeURIComponent(String(elevatorOfferResponseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminUpdateElevatorOfferResponseBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Создать отклик на объявление продавца
         * @param {CreateOfferResponseFromBuyerBody} createOfferResponseFromBuyerBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOfferResponseFromBuyer: async (createOfferResponseFromBuyerBody: CreateOfferResponseFromBuyerBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createOfferResponseFromBuyerBody' is not null or undefined
            assertParamExists('createOfferResponseFromBuyer', 'createOfferResponseFromBuyerBody', createOfferResponseFromBuyerBody)
            const localVarPath = `/offer-response/buy`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createOfferResponseFromBuyerBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Создать отклик на объявление продавца
         * @param {CreateOfferResponseFromSellerBody} createOfferResponseFromSellerBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOfferResponseFromSeller: async (createOfferResponseFromSellerBody: CreateOfferResponseFromSellerBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createOfferResponseFromSellerBody' is not null or undefined
            assertParamExists('createOfferResponseFromSeller', 'createOfferResponseFromSellerBody', createOfferResponseFromSellerBody)
            const localVarPath = `/offer-response/sell`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createOfferResponseFromSellerBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Добавить статус откуплено к отклику на объявление
         * @param {number} id 
         * @param {CreateOfferResponsePurchaseStatusBody} createOfferResponsePurchaseStatusBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOfferResponsePurchaseStatus: async (id: number, createOfferResponsePurchaseStatusBody: CreateOfferResponsePurchaseStatusBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('createOfferResponsePurchaseStatus', 'id', id)
            // verify required parameter 'createOfferResponsePurchaseStatusBody' is not null or undefined
            assertParamExists('createOfferResponsePurchaseStatus', 'createOfferResponsePurchaseStatusBody', createOfferResponsePurchaseStatusBody)
            const localVarPath = `/offer-response/{id}/purchase-status`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createOfferResponsePurchaseStatusBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdateElevatorOfferResponseAcceptBody} updateElevatorOfferResponseAcceptBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        elevatorAdvertOfferControllerAcceptElevatorAdvertOffer: async (id: number, updateElevatorOfferResponseAcceptBody: UpdateElevatorOfferResponseAcceptBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('elevatorAdvertOfferControllerAcceptElevatorAdvertOffer', 'id', id)
            // verify required parameter 'updateElevatorOfferResponseAcceptBody' is not null or undefined
            assertParamExists('elevatorAdvertOfferControllerAcceptElevatorAdvertOffer', 'updateElevatorOfferResponseAcceptBody', updateElevatorOfferResponseAcceptBody)
            const localVarPath = `/elevator-offer-response/{id}/accept`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateElevatorOfferResponseAcceptBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdateElevatorOfferResponseCancelBody} updateElevatorOfferResponseCancelBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        elevatorAdvertOfferControllerCancelElevatorAdvertOffer: async (id: number, updateElevatorOfferResponseCancelBody: UpdateElevatorOfferResponseCancelBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('elevatorAdvertOfferControllerCancelElevatorAdvertOffer', 'id', id)
            // verify required parameter 'updateElevatorOfferResponseCancelBody' is not null or undefined
            assertParamExists('elevatorAdvertOfferControllerCancelElevatorAdvertOffer', 'updateElevatorOfferResponseCancelBody', updateElevatorOfferResponseCancelBody)
            const localVarPath = `/elevator-offer-response/{id}/cancel`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateElevatorOfferResponseCancelBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {number} userCompanyId Id компании, пользователь которой делает запрос
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        elevatorAdvertOfferControllerGetAdvertOffer: async (id: number, userCompanyId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('elevatorAdvertOfferControllerGetAdvertOffer', 'id', id)
            // verify required parameter 'userCompanyId' is not null or undefined
            assertParamExists('elevatorAdvertOfferControllerGetAdvertOffer', 'userCompanyId', userCompanyId)
            const localVarPath = `/elevator-offer-response/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (userCompanyId !== undefined) {
                localVarQueryParameter['userCompanyId'] = userCompanyId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {number} userCompanyId Id компании, пользователь которой делает запрос
         * @param {number} [currentPage] Номер текущей страницы
         * @param {number} [pageSize] Количество элементов на странице
         * @param {Array<ElevatorOfferResponseStatus>} [statuses] Статусы откликов
         * @param {number} [advertOfferId] Идентификатор отклика
         * @param {'id' | 'price' | 'publishedAt'} [orderBy] Поле для сортировки
         * @param {OrderDirection} [order] Направление сортировки
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        elevatorAdvertOfferControllerGetElevatorAdvertOffers: async (id: number, userCompanyId: number, currentPage?: number, pageSize?: number, statuses?: Array<ElevatorOfferResponseStatus>, advertOfferId?: number, orderBy?: 'id' | 'price' | 'publishedAt', order?: OrderDirection, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('elevatorAdvertOfferControllerGetElevatorAdvertOffers', 'id', id)
            // verify required parameter 'userCompanyId' is not null or undefined
            assertParamExists('elevatorAdvertOfferControllerGetElevatorAdvertOffers', 'userCompanyId', userCompanyId)
            const localVarPath = `/elevator-offer/{id}/responses`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (currentPage !== undefined) {
                localVarQueryParameter['current-page'] = currentPage;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page-size'] = pageSize;
            }

            if (userCompanyId !== undefined) {
                localVarQueryParameter['userCompanyId'] = userCompanyId;
            }

            if (statuses) {
                localVarQueryParameter['statuses'] = statuses;
            }

            if (advertOfferId !== undefined) {
                localVarQueryParameter['advertOfferId'] = advertOfferId;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['order-by'] = orderBy;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} userCompanyId Id компании, пользователь которой делает запрос
         * @param {number} [currentPage] Номер текущей страницы
         * @param {number} [pageSize] Количество элементов на странице
         * @param {'BUY' | 'SELL'} [elevatorOfferType] Тип объявления
         * @param {Array<number>} [elevatorIds] Массив с id элеваторов
         * @param {Array<string>} [productTypes] Список типов товаров
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        elevatorAdvertOfferControllerGetMyElevatorAdvertOffers: async (userCompanyId: number, currentPage?: number, pageSize?: number, elevatorOfferType?: 'BUY' | 'SELL', elevatorIds?: Array<number>, productTypes?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userCompanyId' is not null or undefined
            assertParamExists('elevatorAdvertOfferControllerGetMyElevatorAdvertOffers', 'userCompanyId', userCompanyId)
            const localVarPath = `/elevator-offer-responses/my`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (currentPage !== undefined) {
                localVarQueryParameter['current-page'] = currentPage;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page-size'] = pageSize;
            }

            if (userCompanyId !== undefined) {
                localVarQueryParameter['userCompanyId'] = userCompanyId;
            }

            if (elevatorOfferType !== undefined) {
                localVarQueryParameter['elevatorOfferType'] = elevatorOfferType;
            }

            if (elevatorIds) {
                localVarQueryParameter['elevator-ids'] = elevatorIds;
            }

            if (productTypes) {
                localVarQueryParameter['product-types'] = productTypes;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdateElevatorAdvertOfferRejectBody} updateElevatorAdvertOfferRejectBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        elevatorAdvertOfferControllerRejectElevatorAdvertOffer: async (id: number, updateElevatorAdvertOfferRejectBody: UpdateElevatorAdvertOfferRejectBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('elevatorAdvertOfferControllerRejectElevatorAdvertOffer', 'id', id)
            // verify required parameter 'updateElevatorAdvertOfferRejectBody' is not null or undefined
            assertParamExists('elevatorAdvertOfferControllerRejectElevatorAdvertOffer', 'updateElevatorAdvertOfferRejectBody', updateElevatorAdvertOfferRejectBody)
            const localVarPath = `/elevator-offer-response/{id}/reject`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateElevatorAdvertOfferRejectBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ElevatorAdvertOfferApi - functional programming interface
 * @export
 */
export const ElevatorAdvertOfferApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ElevatorAdvertOfferApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Создать отклик на объявление продавца
         * @param {AdminCreateOfferResponseFromBuyerBody} adminCreateOfferResponseFromBuyerBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminCreateOfferResponseFromBuyer(adminCreateOfferResponseFromBuyerBody: AdminCreateOfferResponseFromBuyerBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminCreateOfferResponseFromBuyerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminCreateOfferResponseFromBuyer(adminCreateOfferResponseFromBuyerBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Создать отклик на объявление покупателя
         * @param {AdminCreateOfferResponseFromSellerBody} adminCreateOfferResponseFromSellerBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminCreateOfferResponseFromSeller(adminCreateOfferResponseFromSellerBody: AdminCreateOfferResponseFromSellerBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminCreateOfferResponseFromSellerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminCreateOfferResponseFromSeller(adminCreateOfferResponseFromSellerBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminElevatorAdvertOfferControllerAdminUpdateAdvertOfferModerator(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminElevatorAdvertOfferControllerAdminUpdateAdvertOfferModerator(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminElevatorAdvertOfferControllerGetAdvertOffer(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminGetElevatorAdvertOfferResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminElevatorAdvertOfferControllerGetAdvertOffer(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {AdminUpdateElevatorAdvertOfferRejectBody} adminUpdateElevatorAdvertOfferRejectBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminElevatorAdvertOfferControllerRejectElevatorAdvertOffer(id: number, adminUpdateElevatorAdvertOfferRejectBody: AdminUpdateElevatorAdvertOfferRejectBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminElevatorAdvertOfferControllerRejectElevatorAdvertOffer(id, adminUpdateElevatorAdvertOfferRejectBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {AdminUpdateElevatorAdvertOfferStatusBody} adminUpdateElevatorAdvertOfferStatusBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminElevatorAdvertOfferControllerUpdateElevatorAdvertOfferStatus(id: number, adminUpdateElevatorAdvertOfferStatusBody: AdminUpdateElevatorAdvertOfferStatusBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminElevatorAdvertOfferControllerUpdateElevatorAdvertOfferStatus(id, adminUpdateElevatorAdvertOfferStatusBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Получить список откликов для модератора
         * @param {number} [currentPage] Номер текущей страницы
         * @param {number} [pageSize] Количество элементов на странице
         * @param {Array<ElevatorOfferResponseStatus>} [statuses] Статусы откликов
         * @param {number} [id] Идентификатор отклика
         * @param {number} [companyId] Идентификатор компании
         * @param {number} [elevatorId] Идентификатор элеватора
         * @param {number} [elevatorAdvertId] Идентификатор объявления
         * @param {'BUY' | 'SELL'} [type] Тип отклика
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetElevatorOfferResponses(currentPage?: number, pageSize?: number, statuses?: Array<ElevatorOfferResponseStatus>, id?: number, companyId?: number, elevatorId?: number, elevatorAdvertId?: number, type?: 'BUY' | 'SELL', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminGetElevatorAdvertOffersResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetElevatorOfferResponses(currentPage, pageSize, statuses, id, companyId, elevatorId, elevatorAdvertId, type, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Обновить отклик на объявление
         * @param {number} elevatorOfferResponseId 
         * @param {AdminUpdateElevatorOfferResponseBody} adminUpdateElevatorOfferResponseBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminUpdateElevatorOfferResponse(elevatorOfferResponseId: number, adminUpdateElevatorOfferResponseBody: AdminUpdateElevatorOfferResponseBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminUpdateElevatorOfferResponseResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminUpdateElevatorOfferResponse(elevatorOfferResponseId, adminUpdateElevatorOfferResponseBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Создать отклик на объявление продавца
         * @param {CreateOfferResponseFromBuyerBody} createOfferResponseFromBuyerBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createOfferResponseFromBuyer(createOfferResponseFromBuyerBody: CreateOfferResponseFromBuyerBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateOfferResponseFromBuyerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createOfferResponseFromBuyer(createOfferResponseFromBuyerBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Создать отклик на объявление продавца
         * @param {CreateOfferResponseFromSellerBody} createOfferResponseFromSellerBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createOfferResponseFromSeller(createOfferResponseFromSellerBody: CreateOfferResponseFromSellerBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateOfferResponseFromSellerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createOfferResponseFromSeller(createOfferResponseFromSellerBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Добавить статус откуплено к отклику на объявление
         * @param {number} id 
         * @param {CreateOfferResponsePurchaseStatusBody} createOfferResponsePurchaseStatusBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createOfferResponsePurchaseStatus(id: number, createOfferResponsePurchaseStatusBody: CreateOfferResponsePurchaseStatusBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createOfferResponsePurchaseStatus(id, createOfferResponsePurchaseStatusBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdateElevatorOfferResponseAcceptBody} updateElevatorOfferResponseAcceptBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async elevatorAdvertOfferControllerAcceptElevatorAdvertOffer(id: number, updateElevatorOfferResponseAcceptBody: UpdateElevatorOfferResponseAcceptBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.elevatorAdvertOfferControllerAcceptElevatorAdvertOffer(id, updateElevatorOfferResponseAcceptBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdateElevatorOfferResponseCancelBody} updateElevatorOfferResponseCancelBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async elevatorAdvertOfferControllerCancelElevatorAdvertOffer(id: number, updateElevatorOfferResponseCancelBody: UpdateElevatorOfferResponseCancelBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.elevatorAdvertOfferControllerCancelElevatorAdvertOffer(id, updateElevatorOfferResponseCancelBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {number} userCompanyId Id компании, пользователь которой делает запрос
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async elevatorAdvertOfferControllerGetAdvertOffer(id: number, userCompanyId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetElevatorAdvertOfferResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.elevatorAdvertOfferControllerGetAdvertOffer(id, userCompanyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {number} userCompanyId Id компании, пользователь которой делает запрос
         * @param {number} [currentPage] Номер текущей страницы
         * @param {number} [pageSize] Количество элементов на странице
         * @param {Array<ElevatorOfferResponseStatus>} [statuses] Статусы откликов
         * @param {number} [advertOfferId] Идентификатор отклика
         * @param {'id' | 'price' | 'publishedAt'} [orderBy] Поле для сортировки
         * @param {OrderDirection} [order] Направление сортировки
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async elevatorAdvertOfferControllerGetElevatorAdvertOffers(id: number, userCompanyId: number, currentPage?: number, pageSize?: number, statuses?: Array<ElevatorOfferResponseStatus>, advertOfferId?: number, orderBy?: 'id' | 'price' | 'publishedAt', order?: OrderDirection, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetElevatorAdvertOffersResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.elevatorAdvertOfferControllerGetElevatorAdvertOffers(id, userCompanyId, currentPage, pageSize, statuses, advertOfferId, orderBy, order, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} userCompanyId Id компании, пользователь которой делает запрос
         * @param {number} [currentPage] Номер текущей страницы
         * @param {number} [pageSize] Количество элементов на странице
         * @param {'BUY' | 'SELL'} [elevatorOfferType] Тип объявления
         * @param {Array<number>} [elevatorIds] Массив с id элеваторов
         * @param {Array<string>} [productTypes] Список типов товаров
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async elevatorAdvertOfferControllerGetMyElevatorAdvertOffers(userCompanyId: number, currentPage?: number, pageSize?: number, elevatorOfferType?: 'BUY' | 'SELL', elevatorIds?: Array<number>, productTypes?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetMyElevatorAdvertOffersResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.elevatorAdvertOfferControllerGetMyElevatorAdvertOffers(userCompanyId, currentPage, pageSize, elevatorOfferType, elevatorIds, productTypes, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdateElevatorAdvertOfferRejectBody} updateElevatorAdvertOfferRejectBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async elevatorAdvertOfferControllerRejectElevatorAdvertOffer(id: number, updateElevatorAdvertOfferRejectBody: UpdateElevatorAdvertOfferRejectBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.elevatorAdvertOfferControllerRejectElevatorAdvertOffer(id, updateElevatorAdvertOfferRejectBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ElevatorAdvertOfferApi - factory interface
 * @export
 */
export const ElevatorAdvertOfferApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ElevatorAdvertOfferApiFp(configuration)
    return {
        /**
         * 
         * @summary Создать отклик на объявление продавца
         * @param {AdminCreateOfferResponseFromBuyerBody} adminCreateOfferResponseFromBuyerBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCreateOfferResponseFromBuyer(adminCreateOfferResponseFromBuyerBody: AdminCreateOfferResponseFromBuyerBody, options?: any): AxiosPromise<AdminCreateOfferResponseFromBuyerResponse> {
            return localVarFp.adminCreateOfferResponseFromBuyer(adminCreateOfferResponseFromBuyerBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Создать отклик на объявление покупателя
         * @param {AdminCreateOfferResponseFromSellerBody} adminCreateOfferResponseFromSellerBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCreateOfferResponseFromSeller(adminCreateOfferResponseFromSellerBody: AdminCreateOfferResponseFromSellerBody, options?: any): AxiosPromise<AdminCreateOfferResponseFromSellerResponse> {
            return localVarFp.adminCreateOfferResponseFromSeller(adminCreateOfferResponseFromSellerBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminElevatorAdvertOfferControllerAdminUpdateAdvertOfferModerator(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.adminElevatorAdvertOfferControllerAdminUpdateAdvertOfferModerator(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminElevatorAdvertOfferControllerGetAdvertOffer(id: number, options?: any): AxiosPromise<AdminGetElevatorAdvertOfferResponse> {
            return localVarFp.adminElevatorAdvertOfferControllerGetAdvertOffer(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {AdminUpdateElevatorAdvertOfferRejectBody} adminUpdateElevatorAdvertOfferRejectBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminElevatorAdvertOfferControllerRejectElevatorAdvertOffer(id: number, adminUpdateElevatorAdvertOfferRejectBody: AdminUpdateElevatorAdvertOfferRejectBody, options?: any): AxiosPromise<void> {
            return localVarFp.adminElevatorAdvertOfferControllerRejectElevatorAdvertOffer(id, adminUpdateElevatorAdvertOfferRejectBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {AdminUpdateElevatorAdvertOfferStatusBody} adminUpdateElevatorAdvertOfferStatusBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminElevatorAdvertOfferControllerUpdateElevatorAdvertOfferStatus(id: number, adminUpdateElevatorAdvertOfferStatusBody: AdminUpdateElevatorAdvertOfferStatusBody, options?: any): AxiosPromise<void> {
            return localVarFp.adminElevatorAdvertOfferControllerUpdateElevatorAdvertOfferStatus(id, adminUpdateElevatorAdvertOfferStatusBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получить список откликов для модератора
         * @param {number} [currentPage] Номер текущей страницы
         * @param {number} [pageSize] Количество элементов на странице
         * @param {Array<ElevatorOfferResponseStatus>} [statuses] Статусы откликов
         * @param {number} [id] Идентификатор отклика
         * @param {number} [companyId] Идентификатор компании
         * @param {number} [elevatorId] Идентификатор элеватора
         * @param {number} [elevatorAdvertId] Идентификатор объявления
         * @param {'BUY' | 'SELL'} [type] Тип отклика
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetElevatorOfferResponses(currentPage?: number, pageSize?: number, statuses?: Array<ElevatorOfferResponseStatus>, id?: number, companyId?: number, elevatorId?: number, elevatorAdvertId?: number, type?: 'BUY' | 'SELL', options?: any): AxiosPromise<AdminGetElevatorAdvertOffersResponse> {
            return localVarFp.adminGetElevatorOfferResponses(currentPage, pageSize, statuses, id, companyId, elevatorId, elevatorAdvertId, type, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Обновить отклик на объявление
         * @param {number} elevatorOfferResponseId 
         * @param {AdminUpdateElevatorOfferResponseBody} adminUpdateElevatorOfferResponseBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUpdateElevatorOfferResponse(elevatorOfferResponseId: number, adminUpdateElevatorOfferResponseBody: AdminUpdateElevatorOfferResponseBody, options?: any): AxiosPromise<AdminUpdateElevatorOfferResponseResponse> {
            return localVarFp.adminUpdateElevatorOfferResponse(elevatorOfferResponseId, adminUpdateElevatorOfferResponseBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Создать отклик на объявление продавца
         * @param {CreateOfferResponseFromBuyerBody} createOfferResponseFromBuyerBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOfferResponseFromBuyer(createOfferResponseFromBuyerBody: CreateOfferResponseFromBuyerBody, options?: any): AxiosPromise<CreateOfferResponseFromBuyerResponse> {
            return localVarFp.createOfferResponseFromBuyer(createOfferResponseFromBuyerBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Создать отклик на объявление продавца
         * @param {CreateOfferResponseFromSellerBody} createOfferResponseFromSellerBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOfferResponseFromSeller(createOfferResponseFromSellerBody: CreateOfferResponseFromSellerBody, options?: any): AxiosPromise<CreateOfferResponseFromSellerResponse> {
            return localVarFp.createOfferResponseFromSeller(createOfferResponseFromSellerBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Добавить статус откуплено к отклику на объявление
         * @param {number} id 
         * @param {CreateOfferResponsePurchaseStatusBody} createOfferResponsePurchaseStatusBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOfferResponsePurchaseStatus(id: number, createOfferResponsePurchaseStatusBody: CreateOfferResponsePurchaseStatusBody, options?: any): AxiosPromise<void> {
            return localVarFp.createOfferResponsePurchaseStatus(id, createOfferResponsePurchaseStatusBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdateElevatorOfferResponseAcceptBody} updateElevatorOfferResponseAcceptBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        elevatorAdvertOfferControllerAcceptElevatorAdvertOffer(id: number, updateElevatorOfferResponseAcceptBody: UpdateElevatorOfferResponseAcceptBody, options?: any): AxiosPromise<void> {
            return localVarFp.elevatorAdvertOfferControllerAcceptElevatorAdvertOffer(id, updateElevatorOfferResponseAcceptBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdateElevatorOfferResponseCancelBody} updateElevatorOfferResponseCancelBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        elevatorAdvertOfferControllerCancelElevatorAdvertOffer(id: number, updateElevatorOfferResponseCancelBody: UpdateElevatorOfferResponseCancelBody, options?: any): AxiosPromise<void> {
            return localVarFp.elevatorAdvertOfferControllerCancelElevatorAdvertOffer(id, updateElevatorOfferResponseCancelBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {number} userCompanyId Id компании, пользователь которой делает запрос
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        elevatorAdvertOfferControllerGetAdvertOffer(id: number, userCompanyId: number, options?: any): AxiosPromise<GetElevatorAdvertOfferResponse> {
            return localVarFp.elevatorAdvertOfferControllerGetAdvertOffer(id, userCompanyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {number} userCompanyId Id компании, пользователь которой делает запрос
         * @param {number} [currentPage] Номер текущей страницы
         * @param {number} [pageSize] Количество элементов на странице
         * @param {Array<ElevatorOfferResponseStatus>} [statuses] Статусы откликов
         * @param {number} [advertOfferId] Идентификатор отклика
         * @param {'id' | 'price' | 'publishedAt'} [orderBy] Поле для сортировки
         * @param {OrderDirection} [order] Направление сортировки
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        elevatorAdvertOfferControllerGetElevatorAdvertOffers(id: number, userCompanyId: number, currentPage?: number, pageSize?: number, statuses?: Array<ElevatorOfferResponseStatus>, advertOfferId?: number, orderBy?: 'id' | 'price' | 'publishedAt', order?: OrderDirection, options?: any): AxiosPromise<GetElevatorAdvertOffersResponse> {
            return localVarFp.elevatorAdvertOfferControllerGetElevatorAdvertOffers(id, userCompanyId, currentPage, pageSize, statuses, advertOfferId, orderBy, order, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} userCompanyId Id компании, пользователь которой делает запрос
         * @param {number} [currentPage] Номер текущей страницы
         * @param {number} [pageSize] Количество элементов на странице
         * @param {'BUY' | 'SELL'} [elevatorOfferType] Тип объявления
         * @param {Array<number>} [elevatorIds] Массив с id элеваторов
         * @param {Array<string>} [productTypes] Список типов товаров
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        elevatorAdvertOfferControllerGetMyElevatorAdvertOffers(userCompanyId: number, currentPage?: number, pageSize?: number, elevatorOfferType?: 'BUY' | 'SELL', elevatorIds?: Array<number>, productTypes?: Array<string>, options?: any): AxiosPromise<GetMyElevatorAdvertOffersResponse> {
            return localVarFp.elevatorAdvertOfferControllerGetMyElevatorAdvertOffers(userCompanyId, currentPage, pageSize, elevatorOfferType, elevatorIds, productTypes, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdateElevatorAdvertOfferRejectBody} updateElevatorAdvertOfferRejectBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        elevatorAdvertOfferControllerRejectElevatorAdvertOffer(id: number, updateElevatorAdvertOfferRejectBody: UpdateElevatorAdvertOfferRejectBody, options?: any): AxiosPromise<void> {
            return localVarFp.elevatorAdvertOfferControllerRejectElevatorAdvertOffer(id, updateElevatorAdvertOfferRejectBody, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for adminCreateOfferResponseFromBuyer operation in ElevatorAdvertOfferApi.
 * @export
 * @interface ElevatorAdvertOfferApiAdminCreateOfferResponseFromBuyerRequest
 */
export interface ElevatorAdvertOfferApiAdminCreateOfferResponseFromBuyerRequest {
    /**
     * 
     * @type {AdminCreateOfferResponseFromBuyerBody}
     * @memberof ElevatorAdvertOfferApiAdminCreateOfferResponseFromBuyer
     */
    readonly adminCreateOfferResponseFromBuyerBody: AdminCreateOfferResponseFromBuyerBody
}

/**
 * Request parameters for adminCreateOfferResponseFromSeller operation in ElevatorAdvertOfferApi.
 * @export
 * @interface ElevatorAdvertOfferApiAdminCreateOfferResponseFromSellerRequest
 */
export interface ElevatorAdvertOfferApiAdminCreateOfferResponseFromSellerRequest {
    /**
     * 
     * @type {AdminCreateOfferResponseFromSellerBody}
     * @memberof ElevatorAdvertOfferApiAdminCreateOfferResponseFromSeller
     */
    readonly adminCreateOfferResponseFromSellerBody: AdminCreateOfferResponseFromSellerBody
}

/**
 * Request parameters for adminElevatorAdvertOfferControllerAdminUpdateAdvertOfferModerator operation in ElevatorAdvertOfferApi.
 * @export
 * @interface ElevatorAdvertOfferApiAdminElevatorAdvertOfferControllerAdminUpdateAdvertOfferModeratorRequest
 */
export interface ElevatorAdvertOfferApiAdminElevatorAdvertOfferControllerAdminUpdateAdvertOfferModeratorRequest {
    /**
     * 
     * @type {number}
     * @memberof ElevatorAdvertOfferApiAdminElevatorAdvertOfferControllerAdminUpdateAdvertOfferModerator
     */
    readonly id: number
}

/**
 * Request parameters for adminElevatorAdvertOfferControllerGetAdvertOffer operation in ElevatorAdvertOfferApi.
 * @export
 * @interface ElevatorAdvertOfferApiAdminElevatorAdvertOfferControllerGetAdvertOfferRequest
 */
export interface ElevatorAdvertOfferApiAdminElevatorAdvertOfferControllerGetAdvertOfferRequest {
    /**
     * 
     * @type {number}
     * @memberof ElevatorAdvertOfferApiAdminElevatorAdvertOfferControllerGetAdvertOffer
     */
    readonly id: number
}

/**
 * Request parameters for adminElevatorAdvertOfferControllerRejectElevatorAdvertOffer operation in ElevatorAdvertOfferApi.
 * @export
 * @interface ElevatorAdvertOfferApiAdminElevatorAdvertOfferControllerRejectElevatorAdvertOfferRequest
 */
export interface ElevatorAdvertOfferApiAdminElevatorAdvertOfferControllerRejectElevatorAdvertOfferRequest {
    /**
     * 
     * @type {number}
     * @memberof ElevatorAdvertOfferApiAdminElevatorAdvertOfferControllerRejectElevatorAdvertOffer
     */
    readonly id: number

    /**
     * 
     * @type {AdminUpdateElevatorAdvertOfferRejectBody}
     * @memberof ElevatorAdvertOfferApiAdminElevatorAdvertOfferControllerRejectElevatorAdvertOffer
     */
    readonly adminUpdateElevatorAdvertOfferRejectBody: AdminUpdateElevatorAdvertOfferRejectBody
}

/**
 * Request parameters for adminElevatorAdvertOfferControllerUpdateElevatorAdvertOfferStatus operation in ElevatorAdvertOfferApi.
 * @export
 * @interface ElevatorAdvertOfferApiAdminElevatorAdvertOfferControllerUpdateElevatorAdvertOfferStatusRequest
 */
export interface ElevatorAdvertOfferApiAdminElevatorAdvertOfferControllerUpdateElevatorAdvertOfferStatusRequest {
    /**
     * 
     * @type {number}
     * @memberof ElevatorAdvertOfferApiAdminElevatorAdvertOfferControllerUpdateElevatorAdvertOfferStatus
     */
    readonly id: number

    /**
     * 
     * @type {AdminUpdateElevatorAdvertOfferStatusBody}
     * @memberof ElevatorAdvertOfferApiAdminElevatorAdvertOfferControllerUpdateElevatorAdvertOfferStatus
     */
    readonly adminUpdateElevatorAdvertOfferStatusBody: AdminUpdateElevatorAdvertOfferStatusBody
}

/**
 * Request parameters for adminGetElevatorOfferResponses operation in ElevatorAdvertOfferApi.
 * @export
 * @interface ElevatorAdvertOfferApiAdminGetElevatorOfferResponsesRequest
 */
export interface ElevatorAdvertOfferApiAdminGetElevatorOfferResponsesRequest {
    /**
     * Номер текущей страницы
     * @type {number}
     * @memberof ElevatorAdvertOfferApiAdminGetElevatorOfferResponses
     */
    readonly currentPage?: number

    /**
     * Количество элементов на странице
     * @type {number}
     * @memberof ElevatorAdvertOfferApiAdminGetElevatorOfferResponses
     */
    readonly pageSize?: number

    /**
     * Статусы откликов
     * @type {Array<ElevatorOfferResponseStatus>}
     * @memberof ElevatorAdvertOfferApiAdminGetElevatorOfferResponses
     */
    readonly statuses?: Array<ElevatorOfferResponseStatus>

    /**
     * Идентификатор отклика
     * @type {number}
     * @memberof ElevatorAdvertOfferApiAdminGetElevatorOfferResponses
     */
    readonly id?: number

    /**
     * Идентификатор компании
     * @type {number}
     * @memberof ElevatorAdvertOfferApiAdminGetElevatorOfferResponses
     */
    readonly companyId?: number

    /**
     * Идентификатор элеватора
     * @type {number}
     * @memberof ElevatorAdvertOfferApiAdminGetElevatorOfferResponses
     */
    readonly elevatorId?: number

    /**
     * Идентификатор объявления
     * @type {number}
     * @memberof ElevatorAdvertOfferApiAdminGetElevatorOfferResponses
     */
    readonly elevatorAdvertId?: number

    /**
     * Тип отклика
     * @type {'BUY' | 'SELL'}
     * @memberof ElevatorAdvertOfferApiAdminGetElevatorOfferResponses
     */
    readonly type?: 'BUY' | 'SELL'
}

/**
 * Request parameters for adminUpdateElevatorOfferResponse operation in ElevatorAdvertOfferApi.
 * @export
 * @interface ElevatorAdvertOfferApiAdminUpdateElevatorOfferResponseRequest
 */
export interface ElevatorAdvertOfferApiAdminUpdateElevatorOfferResponseRequest {
    /**
     * 
     * @type {number}
     * @memberof ElevatorAdvertOfferApiAdminUpdateElevatorOfferResponse
     */
    readonly elevatorOfferResponseId: number

    /**
     * 
     * @type {AdminUpdateElevatorOfferResponseBody}
     * @memberof ElevatorAdvertOfferApiAdminUpdateElevatorOfferResponse
     */
    readonly adminUpdateElevatorOfferResponseBody: AdminUpdateElevatorOfferResponseBody
}

/**
 * Request parameters for createOfferResponseFromBuyer operation in ElevatorAdvertOfferApi.
 * @export
 * @interface ElevatorAdvertOfferApiCreateOfferResponseFromBuyerRequest
 */
export interface ElevatorAdvertOfferApiCreateOfferResponseFromBuyerRequest {
    /**
     * 
     * @type {CreateOfferResponseFromBuyerBody}
     * @memberof ElevatorAdvertOfferApiCreateOfferResponseFromBuyer
     */
    readonly createOfferResponseFromBuyerBody: CreateOfferResponseFromBuyerBody
}

/**
 * Request parameters for createOfferResponseFromSeller operation in ElevatorAdvertOfferApi.
 * @export
 * @interface ElevatorAdvertOfferApiCreateOfferResponseFromSellerRequest
 */
export interface ElevatorAdvertOfferApiCreateOfferResponseFromSellerRequest {
    /**
     * 
     * @type {CreateOfferResponseFromSellerBody}
     * @memberof ElevatorAdvertOfferApiCreateOfferResponseFromSeller
     */
    readonly createOfferResponseFromSellerBody: CreateOfferResponseFromSellerBody
}

/**
 * Request parameters for createOfferResponsePurchaseStatus operation in ElevatorAdvertOfferApi.
 * @export
 * @interface ElevatorAdvertOfferApiCreateOfferResponsePurchaseStatusRequest
 */
export interface ElevatorAdvertOfferApiCreateOfferResponsePurchaseStatusRequest {
    /**
     * 
     * @type {number}
     * @memberof ElevatorAdvertOfferApiCreateOfferResponsePurchaseStatus
     */
    readonly id: number

    /**
     * 
     * @type {CreateOfferResponsePurchaseStatusBody}
     * @memberof ElevatorAdvertOfferApiCreateOfferResponsePurchaseStatus
     */
    readonly createOfferResponsePurchaseStatusBody: CreateOfferResponsePurchaseStatusBody
}

/**
 * Request parameters for elevatorAdvertOfferControllerAcceptElevatorAdvertOffer operation in ElevatorAdvertOfferApi.
 * @export
 * @interface ElevatorAdvertOfferApiElevatorAdvertOfferControllerAcceptElevatorAdvertOfferRequest
 */
export interface ElevatorAdvertOfferApiElevatorAdvertOfferControllerAcceptElevatorAdvertOfferRequest {
    /**
     * 
     * @type {number}
     * @memberof ElevatorAdvertOfferApiElevatorAdvertOfferControllerAcceptElevatorAdvertOffer
     */
    readonly id: number

    /**
     * 
     * @type {UpdateElevatorOfferResponseAcceptBody}
     * @memberof ElevatorAdvertOfferApiElevatorAdvertOfferControllerAcceptElevatorAdvertOffer
     */
    readonly updateElevatorOfferResponseAcceptBody: UpdateElevatorOfferResponseAcceptBody
}

/**
 * Request parameters for elevatorAdvertOfferControllerCancelElevatorAdvertOffer operation in ElevatorAdvertOfferApi.
 * @export
 * @interface ElevatorAdvertOfferApiElevatorAdvertOfferControllerCancelElevatorAdvertOfferRequest
 */
export interface ElevatorAdvertOfferApiElevatorAdvertOfferControllerCancelElevatorAdvertOfferRequest {
    /**
     * 
     * @type {number}
     * @memberof ElevatorAdvertOfferApiElevatorAdvertOfferControllerCancelElevatorAdvertOffer
     */
    readonly id: number

    /**
     * 
     * @type {UpdateElevatorOfferResponseCancelBody}
     * @memberof ElevatorAdvertOfferApiElevatorAdvertOfferControllerCancelElevatorAdvertOffer
     */
    readonly updateElevatorOfferResponseCancelBody: UpdateElevatorOfferResponseCancelBody
}

/**
 * Request parameters for elevatorAdvertOfferControllerGetAdvertOffer operation in ElevatorAdvertOfferApi.
 * @export
 * @interface ElevatorAdvertOfferApiElevatorAdvertOfferControllerGetAdvertOfferRequest
 */
export interface ElevatorAdvertOfferApiElevatorAdvertOfferControllerGetAdvertOfferRequest {
    /**
     * 
     * @type {number}
     * @memberof ElevatorAdvertOfferApiElevatorAdvertOfferControllerGetAdvertOffer
     */
    readonly id: number

    /**
     * Id компании, пользователь которой делает запрос
     * @type {number}
     * @memberof ElevatorAdvertOfferApiElevatorAdvertOfferControllerGetAdvertOffer
     */
    readonly userCompanyId: number
}

/**
 * Request parameters for elevatorAdvertOfferControllerGetElevatorAdvertOffers operation in ElevatorAdvertOfferApi.
 * @export
 * @interface ElevatorAdvertOfferApiElevatorAdvertOfferControllerGetElevatorAdvertOffersRequest
 */
export interface ElevatorAdvertOfferApiElevatorAdvertOfferControllerGetElevatorAdvertOffersRequest {
    /**
     * 
     * @type {number}
     * @memberof ElevatorAdvertOfferApiElevatorAdvertOfferControllerGetElevatorAdvertOffers
     */
    readonly id: number

    /**
     * Id компании, пользователь которой делает запрос
     * @type {number}
     * @memberof ElevatorAdvertOfferApiElevatorAdvertOfferControllerGetElevatorAdvertOffers
     */
    readonly userCompanyId: number

    /**
     * Номер текущей страницы
     * @type {number}
     * @memberof ElevatorAdvertOfferApiElevatorAdvertOfferControllerGetElevatorAdvertOffers
     */
    readonly currentPage?: number

    /**
     * Количество элементов на странице
     * @type {number}
     * @memberof ElevatorAdvertOfferApiElevatorAdvertOfferControllerGetElevatorAdvertOffers
     */
    readonly pageSize?: number

    /**
     * Статусы откликов
     * @type {Array<ElevatorOfferResponseStatus>}
     * @memberof ElevatorAdvertOfferApiElevatorAdvertOfferControllerGetElevatorAdvertOffers
     */
    readonly statuses?: Array<ElevatorOfferResponseStatus>

    /**
     * Идентификатор отклика
     * @type {number}
     * @memberof ElevatorAdvertOfferApiElevatorAdvertOfferControllerGetElevatorAdvertOffers
     */
    readonly advertOfferId?: number

    /**
     * Поле для сортировки
     * @type {'id' | 'price' | 'publishedAt'}
     * @memberof ElevatorAdvertOfferApiElevatorAdvertOfferControllerGetElevatorAdvertOffers
     */
    readonly orderBy?: 'id' | 'price' | 'publishedAt'

    /**
     * Направление сортировки
     * @type {OrderDirection}
     * @memberof ElevatorAdvertOfferApiElevatorAdvertOfferControllerGetElevatorAdvertOffers
     */
    readonly order?: OrderDirection
}

/**
 * Request parameters for elevatorAdvertOfferControllerGetMyElevatorAdvertOffers operation in ElevatorAdvertOfferApi.
 * @export
 * @interface ElevatorAdvertOfferApiElevatorAdvertOfferControllerGetMyElevatorAdvertOffersRequest
 */
export interface ElevatorAdvertOfferApiElevatorAdvertOfferControllerGetMyElevatorAdvertOffersRequest {
    /**
     * Id компании, пользователь которой делает запрос
     * @type {number}
     * @memberof ElevatorAdvertOfferApiElevatorAdvertOfferControllerGetMyElevatorAdvertOffers
     */
    readonly userCompanyId: number

    /**
     * Номер текущей страницы
     * @type {number}
     * @memberof ElevatorAdvertOfferApiElevatorAdvertOfferControllerGetMyElevatorAdvertOffers
     */
    readonly currentPage?: number

    /**
     * Количество элементов на странице
     * @type {number}
     * @memberof ElevatorAdvertOfferApiElevatorAdvertOfferControllerGetMyElevatorAdvertOffers
     */
    readonly pageSize?: number

    /**
     * Тип объявления
     * @type {'BUY' | 'SELL'}
     * @memberof ElevatorAdvertOfferApiElevatorAdvertOfferControllerGetMyElevatorAdvertOffers
     */
    readonly elevatorOfferType?: 'BUY' | 'SELL'

    /**
     * Массив с id элеваторов
     * @type {Array<number>}
     * @memberof ElevatorAdvertOfferApiElevatorAdvertOfferControllerGetMyElevatorAdvertOffers
     */
    readonly elevatorIds?: Array<number>

    /**
     * Список типов товаров
     * @type {Array<string>}
     * @memberof ElevatorAdvertOfferApiElevatorAdvertOfferControllerGetMyElevatorAdvertOffers
     */
    readonly productTypes?: Array<string>
}

/**
 * Request parameters for elevatorAdvertOfferControllerRejectElevatorAdvertOffer operation in ElevatorAdvertOfferApi.
 * @export
 * @interface ElevatorAdvertOfferApiElevatorAdvertOfferControllerRejectElevatorAdvertOfferRequest
 */
export interface ElevatorAdvertOfferApiElevatorAdvertOfferControllerRejectElevatorAdvertOfferRequest {
    /**
     * 
     * @type {number}
     * @memberof ElevatorAdvertOfferApiElevatorAdvertOfferControllerRejectElevatorAdvertOffer
     */
    readonly id: number

    /**
     * 
     * @type {UpdateElevatorAdvertOfferRejectBody}
     * @memberof ElevatorAdvertOfferApiElevatorAdvertOfferControllerRejectElevatorAdvertOffer
     */
    readonly updateElevatorAdvertOfferRejectBody: UpdateElevatorAdvertOfferRejectBody
}

/**
 * ElevatorAdvertOfferApi - object-oriented interface
 * @export
 * @class ElevatorAdvertOfferApi
 * @extends {BaseAPI}
 */
export class ElevatorAdvertOfferApi extends BaseAPI {
    /**
     * 
     * @summary Создать отклик на объявление продавца
     * @param {ElevatorAdvertOfferApiAdminCreateOfferResponseFromBuyerRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ElevatorAdvertOfferApi
     */
    public adminCreateOfferResponseFromBuyer(requestParameters: ElevatorAdvertOfferApiAdminCreateOfferResponseFromBuyerRequest, options?: AxiosRequestConfig) {
        return ElevatorAdvertOfferApiFp(this.configuration).adminCreateOfferResponseFromBuyer(requestParameters.adminCreateOfferResponseFromBuyerBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Создать отклик на объявление покупателя
     * @param {ElevatorAdvertOfferApiAdminCreateOfferResponseFromSellerRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ElevatorAdvertOfferApi
     */
    public adminCreateOfferResponseFromSeller(requestParameters: ElevatorAdvertOfferApiAdminCreateOfferResponseFromSellerRequest, options?: AxiosRequestConfig) {
        return ElevatorAdvertOfferApiFp(this.configuration).adminCreateOfferResponseFromSeller(requestParameters.adminCreateOfferResponseFromSellerBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ElevatorAdvertOfferApiAdminElevatorAdvertOfferControllerAdminUpdateAdvertOfferModeratorRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ElevatorAdvertOfferApi
     */
    public adminElevatorAdvertOfferControllerAdminUpdateAdvertOfferModerator(requestParameters: ElevatorAdvertOfferApiAdminElevatorAdvertOfferControllerAdminUpdateAdvertOfferModeratorRequest, options?: AxiosRequestConfig) {
        return ElevatorAdvertOfferApiFp(this.configuration).adminElevatorAdvertOfferControllerAdminUpdateAdvertOfferModerator(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ElevatorAdvertOfferApiAdminElevatorAdvertOfferControllerGetAdvertOfferRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ElevatorAdvertOfferApi
     */
    public adminElevatorAdvertOfferControllerGetAdvertOffer(requestParameters: ElevatorAdvertOfferApiAdminElevatorAdvertOfferControllerGetAdvertOfferRequest, options?: AxiosRequestConfig) {
        return ElevatorAdvertOfferApiFp(this.configuration).adminElevatorAdvertOfferControllerGetAdvertOffer(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ElevatorAdvertOfferApiAdminElevatorAdvertOfferControllerRejectElevatorAdvertOfferRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ElevatorAdvertOfferApi
     */
    public adminElevatorAdvertOfferControllerRejectElevatorAdvertOffer(requestParameters: ElevatorAdvertOfferApiAdminElevatorAdvertOfferControllerRejectElevatorAdvertOfferRequest, options?: AxiosRequestConfig) {
        return ElevatorAdvertOfferApiFp(this.configuration).adminElevatorAdvertOfferControllerRejectElevatorAdvertOffer(requestParameters.id, requestParameters.adminUpdateElevatorAdvertOfferRejectBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ElevatorAdvertOfferApiAdminElevatorAdvertOfferControllerUpdateElevatorAdvertOfferStatusRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ElevatorAdvertOfferApi
     */
    public adminElevatorAdvertOfferControllerUpdateElevatorAdvertOfferStatus(requestParameters: ElevatorAdvertOfferApiAdminElevatorAdvertOfferControllerUpdateElevatorAdvertOfferStatusRequest, options?: AxiosRequestConfig) {
        return ElevatorAdvertOfferApiFp(this.configuration).adminElevatorAdvertOfferControllerUpdateElevatorAdvertOfferStatus(requestParameters.id, requestParameters.adminUpdateElevatorAdvertOfferStatusBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получить список откликов для модератора
     * @param {ElevatorAdvertOfferApiAdminGetElevatorOfferResponsesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ElevatorAdvertOfferApi
     */
    public adminGetElevatorOfferResponses(requestParameters: ElevatorAdvertOfferApiAdminGetElevatorOfferResponsesRequest = {}, options?: AxiosRequestConfig) {
        return ElevatorAdvertOfferApiFp(this.configuration).adminGetElevatorOfferResponses(requestParameters.currentPage, requestParameters.pageSize, requestParameters.statuses, requestParameters.id, requestParameters.companyId, requestParameters.elevatorId, requestParameters.elevatorAdvertId, requestParameters.type, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Обновить отклик на объявление
     * @param {ElevatorAdvertOfferApiAdminUpdateElevatorOfferResponseRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ElevatorAdvertOfferApi
     */
    public adminUpdateElevatorOfferResponse(requestParameters: ElevatorAdvertOfferApiAdminUpdateElevatorOfferResponseRequest, options?: AxiosRequestConfig) {
        return ElevatorAdvertOfferApiFp(this.configuration).adminUpdateElevatorOfferResponse(requestParameters.elevatorOfferResponseId, requestParameters.adminUpdateElevatorOfferResponseBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Создать отклик на объявление продавца
     * @param {ElevatorAdvertOfferApiCreateOfferResponseFromBuyerRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ElevatorAdvertOfferApi
     */
    public createOfferResponseFromBuyer(requestParameters: ElevatorAdvertOfferApiCreateOfferResponseFromBuyerRequest, options?: AxiosRequestConfig) {
        return ElevatorAdvertOfferApiFp(this.configuration).createOfferResponseFromBuyer(requestParameters.createOfferResponseFromBuyerBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Создать отклик на объявление продавца
     * @param {ElevatorAdvertOfferApiCreateOfferResponseFromSellerRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ElevatorAdvertOfferApi
     */
    public createOfferResponseFromSeller(requestParameters: ElevatorAdvertOfferApiCreateOfferResponseFromSellerRequest, options?: AxiosRequestConfig) {
        return ElevatorAdvertOfferApiFp(this.configuration).createOfferResponseFromSeller(requestParameters.createOfferResponseFromSellerBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Добавить статус откуплено к отклику на объявление
     * @param {ElevatorAdvertOfferApiCreateOfferResponsePurchaseStatusRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ElevatorAdvertOfferApi
     */
    public createOfferResponsePurchaseStatus(requestParameters: ElevatorAdvertOfferApiCreateOfferResponsePurchaseStatusRequest, options?: AxiosRequestConfig) {
        return ElevatorAdvertOfferApiFp(this.configuration).createOfferResponsePurchaseStatus(requestParameters.id, requestParameters.createOfferResponsePurchaseStatusBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ElevatorAdvertOfferApiElevatorAdvertOfferControllerAcceptElevatorAdvertOfferRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ElevatorAdvertOfferApi
     */
    public elevatorAdvertOfferControllerAcceptElevatorAdvertOffer(requestParameters: ElevatorAdvertOfferApiElevatorAdvertOfferControllerAcceptElevatorAdvertOfferRequest, options?: AxiosRequestConfig) {
        return ElevatorAdvertOfferApiFp(this.configuration).elevatorAdvertOfferControllerAcceptElevatorAdvertOffer(requestParameters.id, requestParameters.updateElevatorOfferResponseAcceptBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ElevatorAdvertOfferApiElevatorAdvertOfferControllerCancelElevatorAdvertOfferRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ElevatorAdvertOfferApi
     */
    public elevatorAdvertOfferControllerCancelElevatorAdvertOffer(requestParameters: ElevatorAdvertOfferApiElevatorAdvertOfferControllerCancelElevatorAdvertOfferRequest, options?: AxiosRequestConfig) {
        return ElevatorAdvertOfferApiFp(this.configuration).elevatorAdvertOfferControllerCancelElevatorAdvertOffer(requestParameters.id, requestParameters.updateElevatorOfferResponseCancelBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ElevatorAdvertOfferApiElevatorAdvertOfferControllerGetAdvertOfferRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ElevatorAdvertOfferApi
     */
    public elevatorAdvertOfferControllerGetAdvertOffer(requestParameters: ElevatorAdvertOfferApiElevatorAdvertOfferControllerGetAdvertOfferRequest, options?: AxiosRequestConfig) {
        return ElevatorAdvertOfferApiFp(this.configuration).elevatorAdvertOfferControllerGetAdvertOffer(requestParameters.id, requestParameters.userCompanyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ElevatorAdvertOfferApiElevatorAdvertOfferControllerGetElevatorAdvertOffersRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ElevatorAdvertOfferApi
     */
    public elevatorAdvertOfferControllerGetElevatorAdvertOffers(requestParameters: ElevatorAdvertOfferApiElevatorAdvertOfferControllerGetElevatorAdvertOffersRequest, options?: AxiosRequestConfig) {
        return ElevatorAdvertOfferApiFp(this.configuration).elevatorAdvertOfferControllerGetElevatorAdvertOffers(requestParameters.id, requestParameters.userCompanyId, requestParameters.currentPage, requestParameters.pageSize, requestParameters.statuses, requestParameters.advertOfferId, requestParameters.orderBy, requestParameters.order, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ElevatorAdvertOfferApiElevatorAdvertOfferControllerGetMyElevatorAdvertOffersRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ElevatorAdvertOfferApi
     */
    public elevatorAdvertOfferControllerGetMyElevatorAdvertOffers(requestParameters: ElevatorAdvertOfferApiElevatorAdvertOfferControllerGetMyElevatorAdvertOffersRequest, options?: AxiosRequestConfig) {
        return ElevatorAdvertOfferApiFp(this.configuration).elevatorAdvertOfferControllerGetMyElevatorAdvertOffers(requestParameters.userCompanyId, requestParameters.currentPage, requestParameters.pageSize, requestParameters.elevatorOfferType, requestParameters.elevatorIds, requestParameters.productTypes, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ElevatorAdvertOfferApiElevatorAdvertOfferControllerRejectElevatorAdvertOfferRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ElevatorAdvertOfferApi
     */
    public elevatorAdvertOfferControllerRejectElevatorAdvertOffer(requestParameters: ElevatorAdvertOfferApiElevatorAdvertOfferControllerRejectElevatorAdvertOfferRequest, options?: AxiosRequestConfig) {
        return ElevatorAdvertOfferApiFp(this.configuration).elevatorAdvertOfferControllerRejectElevatorAdvertOffer(requestParameters.id, requestParameters.updateElevatorAdvertOfferRejectBody, options).then((request) => request(this.axios, this.basePath));
    }
}
