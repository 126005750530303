import React, { useState } from 'react'

import { Avatar, Button, Flex, Skeleton, Tabs, TabsProps } from 'antd'
import { TbVectorTriangle } from 'react-icons/tb'
import { useParams } from 'react-router'
import { useNavigate } from 'react-router-dom'

import { UserOutlined } from '@ant-design/icons'
import { useQuery } from '@apollo/client'

import { AppHeader } from '~/layout'
import { appRoutes } from '~/router'
import { Text } from '~/ui-components'
import { getDriverQuery } from '~api/gql-query/drivers.query.graphql'
import { AssignTransportToDriver } from '~pages/CarParkPage/modules/AssignTransportToDriver'
import { AssignedDriverTransport } from '~pages/CarParkPage/modules/DriverInfo/components/AssignedDriverTransport'
import { DriverInfoCard } from '~pages/CarParkPage/modules/DriverInfo/components/DriverInfoCard'

import * as S from './DriverInfo.styled'

export function DriverInfo() {
  const { id } = useParams<{ id: string | undefined }>()
  const driverId = Number(id)
  const navigate = useNavigate()
  const [assignTransportOpen, setAssignTransportOpen] = useState<boolean>(false)

  const getDriverFn = useQuery(getDriverQuery, {
    variables: {
      driverId,
    },
  })

  const driver = getDriverFn.data?.driver

  if (!driver || getDriverFn.loading) {
    return (
      <S.ContentWrapper>
        <Skeleton />
      </S.ContentWrapper>
    )
  }

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: 'Заказы',
      children: 'Заказы',
    },
    {
      key: '2',
      label: 'Назначенный транспорт',
      children: <AssignedDriverTransport driver={driver} />,
    },
    {
      key: '3',
      label: 'Информация о водителе',
      children: <DriverInfoCard driver={driver} />,
    },
  ]

  return (
    <Flex vertical>
      <AppHeader title={appRoutes.carParkDriver.title} isBack />

      <S.ContentWrapper>
        <Flex gap={20}>
          <Avatar size={50} shape="circle" icon={<UserOutlined />} />
          <Flex vertical gap={16} flex={1}>
            <Text variant="h2">{driver.name}</Text>
            <Text variant="h2" style={{ color: '#9E9E9E', fontWeight: 'normal' }}>
              ID: {driver.id}
            </Text>
          </Flex>

          <Flex gap={16}>
            <Button icon={<TbVectorTriangle />}>Ссылка на приложение</Button>
            <Button onClick={() => setAssignTransportOpen(true)}>Назначить ТС</Button>
            <Button disabled>Удалить</Button>
            <Button type="primary">Редактировать</Button>
          </Flex>
        </Flex>

        <br />
        <Tabs defaultActiveKey="3" items={items} />

        <AssignTransportToDriver
          driverId={driverId}
          open={assignTransportOpen}
          onClose={() => setAssignTransportOpen(false)}
        />
      </S.ContentWrapper>
    </Flex>
  )
}
