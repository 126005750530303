import React from 'react'

import { Button, Table } from 'antd'
import { ColumnsType } from 'antd/lib/table'

import { useGetTableHeight } from '~/hooks'
import { Badge } from '~/ui-components'
import { getElevatorAdvertOfferStatus } from '~/utils/elevator-advert-offers/get-elevator-advert-offer-status'
import { getElevatorAdvertOfferType } from '~/utils/elevator-advert-offers/get-elevator-advert-offer-type'
import { getDateFormat } from '~/utils/getDateFormat'
import { OfferDataStatusEnum } from '~api/generated'
import { actionArrowColumn } from '~shared/columns/shared'

import {
  IModeratorBasisAdvertOffersListTableProps,
  ModeratorBasisAdvertOffersListTableDataType,
} from './ModeratorBasisAdvertOffersListTable.types'

import * as S from './ModeratorBasisAdvertOffersListTable.styled'

export const ModeratorBasisAdvertOffersListTable: React.FC<IModeratorBasisAdvertOffersListTableProps> = ({
  tableData,
  loading,
  onRowClick,
  takeAdvertToWork,
}) => {
  const tableHeight = useGetTableHeight(345)
  const onRowClickHandler = (record: ModeratorBasisAdvertOffersListTableDataType) => {
    onRowClick(record)
  }

  const takeOfferToWorkHandler = (e: React.MouseEvent, offerId: number) => {
    e.stopPropagation()
    takeAdvertToWork(offerId)
  }

  const columns: ColumnsType<ModeratorBasisAdvertOffersListTableDataType> = [
    {
      title: '№',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Статус',
      dataIndex: 'status',
      key: 'status',
      render: (v: ModeratorBasisAdvertOffersListTableDataType['status']) => {
        const offerStatus = getElevatorAdvertOfferStatus(v)
        return (
          <Badge color={offerStatus?.color} size="s">
            {offerStatus?.name}
          </Badge>
        )
      },
    },
    {
      title: 'Культура',
      dataIndex: 'cultureName',
      key: 'cultureName',
      render: (v: ModeratorBasisAdvertOffersListTableDataType['cultureName']) => <b>{v}</b>,
    },
    {
      title: 'Тип отклика',
      dataIndex: 'type',
      key: 'type',
      render: (v: ModeratorBasisAdvertOffersListTableDataType['type']) => {
        const type = getElevatorAdvertOfferType(v)
        return (
          <Badge color={type.color} size="s">
            {type.name}
          </Badge>
        )
      },
    },
    {
      title: 'Компания',
      dataIndex: 'companyName',
      key: 'companyName',
      width: 300,
    },
    {
      title: 'Базис',
      dataIndex: 'basisName',
      key: 'basisName',
      width: 300,
    },
    {
      title: '№ объявления',
      dataIndex: 'offerId',
      key: 'offerId',
      width: 120,
    },
    {
      title: 'Дата публикации',
      dataIndex: 'publishedAt',
      key: 'publishedAt',
      width: 150,
      render: (d: ModeratorBasisAdvertOffersListTableDataType['publishedAt']) => (d ? getDateFormat(d) : '-'),
    },
    {
      title: 'Модератор',
      dataIndex: 'moderator',
      key: 'moderator',
      ellipsis: true,
      width: 150,
      render: (
        moderator: ModeratorBasisAdvertOffersListTableDataType['moderator'],
        record: ModeratorBasisAdvertOffersListTableDataType
      ) => {
        if (moderator) {
          return moderator?.name ?? '-'
        }

        if (record.status === OfferDataStatusEnum.OnModeration) {
          return (
            <Button type="primary" onClick={(event) => takeOfferToWorkHandler(event, record.id)}>
              Взять в работу
            </Button>
          )
        }
      },
    },
    actionArrowColumn,
  ]

  return (
    <S.StyledTable>
      <Table
        rowKey="id"
        className="table-interactive"
        loading={loading}
        columns={columns}
        dataSource={tableData}
        scroll={{ y: tableHeight, x: 'max-content' }}
        pagination={false}
        onRow={(record, index) => ({
          onClick: onRowClickHandler.bind(null, record, index),
        })}
      />
    </S.StyledTable>
  )
}
