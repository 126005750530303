import React, { useMemo, useState } from 'react'

import { Input, Modal, Select } from 'antd'

import { getCompanyStatusName } from '~/utils/company/get-company-status-name'
import { CompanyVerificationStatus } from '~api/generated'

import * as S from './CompanyStatusSelect.styled'

interface ICompanyStatusSelectProps {
  defaultValue?: CompanyVerificationStatus
  nextStatuses?: CompanyVerificationStatus[]
  onChange: (status: { status: CompanyVerificationStatus; rejectReason?: string }) => void
}

export const CompanyStatusSelect: React.FC<ICompanyStatusSelectProps> = (props) => {
  const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false)
  const [selectedStatus, setSelectedStatus] = useState<CompanyVerificationStatus | null>(null)
  const [rejectReason, setRejectReason] = useState<string | undefined>(undefined)

  const offerStatusOptions = useMemo(
    () => [
      {
        disabled: !props.nextStatuses?.includes(CompanyVerificationStatus.OnModeration),
        label: getCompanyStatusName(CompanyVerificationStatus.OnModeration),
        value: CompanyVerificationStatus.OnModeration,
      },
      {
        disabled: !props.nextStatuses?.includes(CompanyVerificationStatus.Verified),
        label: getCompanyStatusName(CompanyVerificationStatus.Verified),
        value: CompanyVerificationStatus.Verified,
      },
      {
        disabled: !props.nextStatuses?.includes(CompanyVerificationStatus.NotVerified),
        label: getCompanyStatusName(CompanyVerificationStatus.NotVerified),
        value: CompanyVerificationStatus.NotVerified,
      },
      {
        disabled: !props.nextStatuses?.includes(CompanyVerificationStatus.Rejected),
        label: getCompanyStatusName(CompanyVerificationStatus.Rejected),
        value: CompanyVerificationStatus.Rejected,
      },
    ],
    []
  )

  const handleChangeStatus = async (_e: React.MouseEvent) => {
    if (!selectedStatus) {
      return
    }
    props.onChange({ status: selectedStatus, rejectReason })
    setSelectedStatus(null)
    setIsConfirmModalVisible(false)
  }

  return (
    <S.Wrapper>
      <Select
        defaultValue={props.defaultValue}
        id="status"
        style={{ width: '150px' }}
        onChange={(status) => {
          setSelectedStatus(status)
          setIsConfirmModalVisible(true)
        }}
      >
        {offerStatusOptions.map((option) => (
          <Select.Option key={option.value} value={option.value} disabled={option.disabled}>
            {option.label}
          </Select.Option>
        ))}
      </Select>

      <Modal
        title="Изменить статус?"
        open={isConfirmModalVisible}
        okText="Изменить"
        cancelText="Отмена"
        onOk={handleChangeStatus}
        okButtonProps={{ disabled: selectedStatus === 'REJECTED' && !rejectReason }}
        onCancel={() => {
          setSelectedStatus(null)
          setIsConfirmModalVisible(false)
        }}
      >
        {selectedStatus && (
          <p>
            Статус компании будет изменен на <b>{getCompanyStatusName(selectedStatus)}</b>
          </p>
        )}

        {selectedStatus === 'REJECTED' && (
          <>
            <p>Укажите причину отклонения:</p>
            <Input.TextArea
              id="message"
              rows={4}
              placeholder="Укажите причину"
              maxLength={900}
              onChange={({ target: { value } }) => setRejectReason(value)}
            ></Input.TextArea>
          </>
        )}
      </Modal>
    </S.Wrapper>
  )
}
