import React from 'react'
import { Text } from '~/ui-components'
import { Flex } from 'antd'
import { TransportDocumentsFieldsProps } from '~pages/CarParkPage/modules/Transport/Transport.types'
import { Upload } from '~ui-components/components/Upload'
import { FiPaperclip } from 'react-icons/fi'

export const PhotosFields: React.FC<TransportDocumentsFieldsProps> = (transportDocumentsProps) => (
  <Flex vertical gap={32}>
    <Flex gap={16}>
      <Flex>
        <FiPaperclip size={28} />
      </Flex>

      <Flex vertical gap={8}>
        <Text variant="label-bold">Загрузите фотографии транспорта</Text>
        <Text variant="label">Максимум 10 файлов (PNG или JPG не больше 1000px в ширину и высоту)</Text>
      </Flex>
    </Flex>

    <Flex vertical gap={32}>
      {Object.values(transportDocumentsProps).map((transportDocument) => (
        <Flex key={transportDocument.type} vertical gap={24}>
          <Upload uploadProps={transportDocument.uploadProps} buttonName="Выбрать фотографию" />
        </Flex>
      ))}
    </Flex>
  </Flex>
)
