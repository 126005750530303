import styled from 'styled-components'

import { breakpoints } from '~/tokens'

export const ContentWrapper = styled.div`
  display: grid;
  grid-template-columns: 700px 410px;
  grid-gap: 16px;
  align-items: start;
  justify-content: center;

  @media (max-width: ${breakpoints.DESKTOP_L}px) {
    grid-template-columns: 1fr 410px;
  }

  @media (max-width: ${breakpoints.DESKTOP_S}px) {
    grid-template-columns: 1fr;
  }
`

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`

export const TopActionsWrapper = styled.div`
  margin-bottom: 24px;
`
