import React, { useEffect, useState } from 'react'

import { Button, Input } from 'antd'
import { Controller, useForm } from 'react-hook-form'

import { yupResolver } from '@hookform/resolvers/yup'

import { DaDataBankSuggestion, FormField, FormFooter, InputDadata, InputMask } from '~/ui-components'

import { bankInfoFormSchema } from './BankInfoForm.validation'

import { IBankInfoForm, IBankInfoFormProps } from './BankInfoForm.types'

import * as S from './BankInfoForm.styled'

export const BankInfoForm: React.FC<IBankInfoFormProps> = ({ onSubmit, defaultValues, isLoading }) => {
  const {
    register,
    control,
    setValue,
    handleSubmit,
    formState: { errors, isDirty },
    watch,
  } = useForm<IBankInfoForm>({
    defaultValues: {
      bankName: defaultValues?.name ?? '',
      accountNumber: defaultValues?.paymentAccount ?? '',
      correspondentAccountNumber: defaultValues?.correspondentAccount ?? '',
      bik: defaultValues?.bic ?? '',
    },
    resolver: yupResolver(bankInfoFormSchema),
  })

  const [dadataSelected, setDadataSelected] = useState<DaDataBankSuggestion | null>(null)
  const [oneOfDadataInputWasChanged, setOneOfDadataInputWasChanged] = useState<boolean>(false)

  // set dadata's inputs after selecting dadata suggestion
  const setFormAccordingByDadata = (value: DaDataBankSuggestion | undefined) => {
    const data = value?.data
    if (!data) {
      console.log('There is no data for set')

      return
    }
    setValue('bankName', data.name?.payment ?? value?.value, { shouldValidate: true })
    setValue('correspondentAccountNumber', data?.correspondent_account, { shouldValidate: true })
    setValue('bik', data.bic, { shouldValidate: true })

    setOneOfDadataInputWasChanged(false)
  }

  useEffect(() => {
    if (!oneOfDadataInputWasChanged) {
      return
    }

    // clear form fields when one of dadata's inputs was changed
    if (oneOfDadataInputWasChanged) {
      setValue('bankName', '', { shouldTouch: true })
      setValue('bik', '', { shouldTouch: true })
      setDadataSelected(null)
      setOneOfDadataInputWasChanged(false)

      return
    }
  }, [oneOfDadataInputWasChanged])

  const onSubmitHandler = (data: IBankInfoForm) => onSubmit(data)

  const handleDadataSelect = (val?: DaDataBankSuggestion | undefined): void => {
    setDadataSelected(val ?? null)
    setFormAccordingByDadata(val)
  }

  return (
    <form onSubmit={handleSubmit(onSubmitHandler)}>
      <S.ContentWrapper>
        <FormField label="Название банка" isRequired htmlFor="bankName" validateMessage={errors.bankName?.message}>
          <Controller
            name="bankName"
            control={control}
            render={({ field }) => (
              <InputDadata
                id="bankName"
                placeholder="Введите наименование банка"
                inputValue={dadataSelected?.data?.name?.short ?? field.value}
                onSelect={handleDadataSelect}
                onChangeInput={(v) => {
                  setOneOfDadataInputWasChanged(true)
                }}
                variant="banks"
                inputStatus={errors.bankName && 'error'}
              />
            )}
          />
        </FormField>

        <FormField
          label="Расчетный счет"
          isRequired
          htmlFor="accountNumber"
          validateMessage={errors.accountNumber?.message}
        >
          <Controller
            name="accountNumber"
            control={control}
            render={({ field }) => (
              <InputMask
                id="accountNumber"
                status={errors.accountNumber && 'error'}
                placeholder="Введите расчетный счет банка"
                format="####################"
                value={field.value}
                onValueChange={(evt) => field.onChange(evt.value)}
                mask="_"
              />
            )}
          />
        </FormField>

        <FormField
          label="Корреспондентский счет"
          isRequired
          htmlFor="correspondentAccountNumber"
          validateMessage={errors.correspondentAccountNumber?.message}
        >
          <Controller
            name="correspondentAccountNumber"
            control={control}
            render={({ field }) => (
              <InputMask
                id="correspondentAccountNumber"
                status={errors.correspondentAccountNumber && 'error'}
                placeholder="Введите корреспондентский счет банка"
                format="####################"
                value={field.value}
                onValueChange={(evt) => field.onChange(evt.value)}
                mask="_"
              />
            )}
          />
        </FormField>

        <FormField label="БИК" isRequired htmlFor="bik" validateMessage={errors.bik?.message}>
          <Controller
            name="bik"
            control={control}
            render={({ field }) => (
              <InputDadata
                id="bik"
                placeholder="Введите БИК"
                inputValue={dadataSelected?.data?.bic ?? field.value}
                onSelect={handleDadataSelect}
                onChangeInput={(v) => {
                  setOneOfDadataInputWasChanged(true)
                }}
                variant="banks"
                inputStatus={errors.bik && 'error'}
              />
            )}
          />
        </FormField>
      </S.ContentWrapper>
      <FormFooter>
        <Button htmlType="submit" type="primary" disabled={!isDirty || isLoading}>
          Добавить
        </Button>
      </FormFooter>
    </form>
  )
}
