/* tslint:disable */
/* eslint-disable */
/**
 * API Зерно-Агрегатора
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { DocumentInstanceData } from './document-instance-data';

/**
 * 
 * @export
 * @interface DealDocumentData
 */
export interface DealDocumentData {
    /**
     * Тип документа
     * @type {string}
     * @memberof DealDocumentData
     */
    'type': DealDocumentDataTypeEnum;
    /**
     * Документы
     * @type {Array<DocumentInstanceData>}
     * @memberof DealDocumentData
     */
    'instances': Array<DocumentInstanceData>;
    /**
     * Можно ли загружать и удалять документ и его части
     * @type {boolean}
     * @memberof DealDocumentData
     */
    'canChange': boolean;
}

export const DealDocumentDataTypeEnum = {
    Specification: 'specification',
    LaboratoryAnalysis: 'laboratory-analysis',
    SupplyContract: 'supply-contract',
    AgencyContract: 'agency-contract',
    AgencyOrder: 'agency-order',
    SignedAgencyContract: 'signed-agency-contract',
    SignedAgencyOrder: 'signed-agency-order',
    SignedSpecification: 'signed-specification',
    SignedSupplyContract: 'signed-supply-contract',
    InvoiceForSupplyPayment: 'invoice-for-supply-payment',
    PaymentOrderUnderTheSupplyContract: 'payment-order-under-the-supply-contract',
    InvoiceForGoods: 'invoice-for-goods',
    ActOfCompletedWork: 'act-of-completed-work',
    ReconciliationActFromSeller: 'reconciliation-act-from-seller',
    ReconciliationActFromBuyer: 'reconciliation-act-from-buyer',
    DeclarationOfConformityWithCuRegulations: 'declaration-of-conformity-with-cu-regulations',
    CertificateForUseOfPesticides: 'certificate-for-use-of-pesticides',
    CertificateOfAbsenceOfGmo: 'certificate-of-absence-of-gmo',
    CertificateOfOriginOfGoods: 'certificate-of-origin-of-goods',
    QuarantineCertificate: 'quarantine-certificate',
    PhytosanitaryCertificate: 'phytosanitary-certificate',
    VeterinaryCertificateForGoods: 'veterinary-certificate-for-goods',
    ShippingDocument: 'shipping-document',
    BillOfLading: 'bill-of-lading',
    ConsignmentNote: 'consignment-note',
    ShippingInvoice: 'shipping-invoice',
    AgentReport: 'agent-report',
    InvoiceForAgencyPayment: 'invoice-for-agency-payment',
    ActOfCompletedAgencyWork: 'act-of-completed-agency-work',
    PaymentOrderUnderAgencyContract: 'payment-order-under-agency-contract'
} as const;

export type DealDocumentDataTypeEnum = typeof DealDocumentDataTypeEnum[keyof typeof DealDocumentDataTypeEnum];


