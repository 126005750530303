import React, { useState } from 'react'

import { Flex, Select, Table } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import { FiChevronDown, FiChevronUp } from 'react-icons/fi'
import { useNavigate } from 'react-router-dom'

import { Badge, Card, Text } from '~/ui-components'
import { getElevatorAdvertOfferStatus } from '~/utils/elevator-advert-offers/get-elevator-advert-offer-status'
import { enumToArray } from '~/utils/enum-to-array'
import { getDateFormat } from '~/utils/getDateFormat'
import { ElevatorAdvertOfferData, ElevatorOfferResponseStatus, GetElevatorAdvertResponse } from '~api/generated'
import { useGetElevatorAdvertOffers } from '~hooks/elevator-advert-offers'
import { MyAdvertOffersListItemMobile } from '~pages/BasisPage/modules/MyAdvertOffers/components/MyAdvertOffersList/components/MyAdvertOffersListItemMobile'
import { idColumn, priceColumn, volumeColumn } from '~shared/columns/shared'
import { PhoneNumber } from '~shared/components'
import { SearchInput } from '~shared/components/SearchInput'

import * as S from '~pages/BasisPage/modules/BasisAdvertOffer/BasisAdvertOffer.styled'

const statusOptions = enumToArray(ElevatorOfferResponseStatus).map((option) => ({
  label: getElevatorAdvertOfferStatus(option as ElevatorOfferResponseStatus).name,
  value: option,
}))

interface ResponsesDesktopSellerProps {
  advert: GetElevatorAdvertResponse
  companyId: number
}
export function ResponsesDesktopSeller({ advert, companyId }: ResponsesDesktopSellerProps) {
  const navigate = useNavigate()
  const advertId = advert.id

  const [filterByStatus, setFilterByStatus] = useState<ElevatorOfferResponseStatus[]>([])
  const [filterById, setFilterById] = useState<string>('')

  const getElevatorAdvertOffers = useGetElevatorAdvertOffers({
    id: advertId,
    userCompanyId: companyId,
    pageSize: 100,
    statuses: filterByStatus,
    advertOfferId: +filterById ? Number(filterById) : undefined,
    orderBy: 'publishedAt',
  })
  const offers = getElevatorAdvertOffers.data?.elevatorAdvertOffers

  const columns: ColumnsType<ElevatorAdvertOfferData> = [
    {
      ...idColumn,
    },
    {
      title: 'Статус',
      dataIndex: 'status',
      fixed: 'left',
      width: 200,
      render: (status: ElevatorAdvertOfferData['status']) => {
        return (
          <Badge color={getElevatorAdvertOfferStatus(status).color} size="s">
            {getElevatorAdvertOfferStatus(status).name}
          </Badge>
        )
      },
    },
    { ...priceColumn, fixed: 'left' },
    { ...volumeColumn, fixed: 'left' },
    {
      title: 'Дата отклика',
      dataIndex: 'publishedAt',
      width: 150,
      render: (publishedAt) => publishedAt && getDateFormat(publishedAt),
      fixed: 'left',
    },
    {
      title: 'Компания',
      dataIndex: 'company',
      width: 150,
      render: (company: ElevatorAdvertOfferData['company']) => company?.name,
    },
    {
      title: '',
      dataIndex: 'action',
      key: 'action',
      fixed: 'right',
      width: 56,
      render: (_, offer) => {
        return (
          <MyAdvertOffersListItemMobile
            showOnlyActions
            offer={offer}
            advert={advert}
            onNeedToRefetch={() => getElevatorAdvertOffers.refetch()}
          />
        )
      },
    },
    Table.EXPAND_COLUMN,
  ]

  return (
    <Flex vertical gap={24}>
      <Text variant="h3">Отклики: {offers?.length}</Text>

      <Flex gap={8}>
        <Select
          id="status"
          style={{ width: 210 }}
          allowClear
          placeholder="Статус"
          mode="multiple"
          onChange={(v) => setFilterByStatus(v)}
          options={statusOptions}
        />

        <SearchInput
          style={{ width: 210 }}
          defaultValue=""
          onSearch={(value) => setFilterById(value)}
          placeholder="Поиск по №"
          type="number"
          pattern="\d*"
        />
      </Flex>

      <Table<ElevatorAdvertOfferData>
        rowKey="type"
        className="table-interactive"
        loading={getElevatorAdvertOffers.isLoading}
        columns={columns}
        dataSource={offers}
        scroll={{ x: 'max-content' }}
        pagination={false}
        expandable={{
          rowExpandable: (record) => record.company != null,
          expandedRowRender: (record) => (
            <S.CompanyInfo>
              <Card.Label>Компания</Card.Label>
              <Card.Value>
                <b>{record?.contactCompany?.name ?? '-'}</b>
              </Card.Value>
              <Card.Label>ИНН</Card.Label>
              <Card.Value>
                <b>{record?.contactCompany?.inn ?? '-'}</b>
              </Card.Value>

              <Card.Label>Контактное лицо</Card.Label>
              <Card.Value>
                <b>{record?.contactPerson?.name}</b>
              </Card.Value>
              <Card.Label>Телефон</Card.Label>
              <Card.Value>
                <PhoneNumber phoneNumber={record?.contactPerson?.phoneNumber} prefix="" />
              </Card.Value>
            </S.CompanyInfo>
          ),
          showExpandColumn: true,
          expandIcon: ({ expanded, onExpand, expandable, record }) => {
            if (!expandable) {
              return null
            }
            return expanded ? (
              <Flex
                align="center"
                gap={10}
                style={{ color: 'var(--primary-main-color)' }}
                onClick={(e) => onExpand(record, e)}
              >
                <FiChevronUp size={20} /> Свернуть
              </Flex>
            ) : (
              <Flex
                align="center"
                gap={10}
                style={{ color: 'var(--primary-main-color)' }}
                onClick={(e) => onExpand(record, e)}
              >
                <FiChevronDown size={20} /> Развернуть
              </Flex>
            )
          },
        }}
      />
    </Flex>
  )
}
