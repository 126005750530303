import React, { useState } from 'react'

import { Spin } from 'antd'
import { useParams } from 'react-router'

import { DealDocumentsGroup } from '~/pages/Deals/components'
import { dealDocumentsGroupsTypes } from '~/utils/constants'
import { useGetDealDocumentsGroups } from '~/utils/deals'
import { useGetDealDocuments } from '~hooks/deal-documents'
import { useGetModeratorDeal } from '~hooks/moderator'
import { SellerOrBuyerRadioControl } from '~pages/ModeratorPage/components/ModeratorDeals/components'

import * as S from './ModeratorDealDocuments.styled'

export const ModeratorDealDocuments = () => {
  const params = useParams<{ id: string | undefined }>()
  const complexDealId = Number(params.id)
  const { data: dealData } = useGetModeratorDeal(complexDealId)
  const [dealSide, setDealSide] = useState<'seller' | 'buyer'>('seller')

  const dealId = dealSide === 'seller' ? dealData?.dealWithSeller?.id : dealData?.dealWithBuyer?.id

  const {
    data: documents,
    refetch: refetchDocuments,
    isLoading: isLoadingDocuments,
  } = useGetDealDocuments({
    dealId: dealId as number,
  })

  const documentsGroups = useGetDealDocumentsGroups(documents)

  return (
    <S.Wrapper>
      {/*<div>*/}
      {/*  <Button icon={<FiDownload />} type="link">*/}
      {/*    Скачать все документы архивом*/}
      {/*  </Button>*/}
      {/*</div>*/}
      <SellerOrBuyerRadioControl value={dealSide} onChange={(value) => setDealSide(value)} />
      <Spin spinning={isLoadingDocuments}>
        <S.Content>
          <DealDocumentsGroup
            dealId={dealId}
            title="Документы по сделке"
            documents={documentsGroups.main}
            documentsTypes={dealDocumentsGroupsTypes.main}
            isAddDocuments
            isDeleteDocuments
            onRefetch={refetchDocuments}
          />

          {dealSide === 'buyer' && (
            <DealDocumentsGroup
              dealId={dealId}
              title="Документы от агента"
              documents={documentsGroups.agent}
              documentsTypes={dealDocumentsGroupsTypes.agent}
              isAddDocuments
              isDeleteDocuments
              onRefetch={refetchDocuments}
            />
          )}

          <DealDocumentsGroup
            dealId={dealId}
            title="Документы на товар"
            documents={documentsGroups.product}
            documentsTypes={dealDocumentsGroupsTypes.product}
            isAddDocuments
            isDeleteDocuments
            onRefetch={refetchDocuments}
          />

          <DealDocumentsGroup
            dealId={dealId}
            title="Документы по перевозке"
            documents={documentsGroups.transportation}
            documentsTypes={dealDocumentsGroupsTypes.transportation}
            isAddDocuments
            isDeleteDocuments
            onRefetch={refetchDocuments}
          />
        </S.Content>
      </Spin>
    </S.Wrapper>
  )
}
