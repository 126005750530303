import React from 'react'

import { Flex } from 'antd'

import { Text } from '~/ui-components'
import { getTransportTypeName } from '~/utils/car-park/get-transport-type'
import { getTransportTypeIcon } from '~/utils/car-park/get-transport-type-icon'
import { TransportType } from '~api/gql-generated/graphql'

import * as S from './EditTransportTypeFields.styled'

export const EditTransportTypeFields: React.FC<{ value: TransportType }> = ({ value }) => {
  return (
    <>
      <Text variant="h1">Тип транспорта</Text>

      <Flex>
        <S.RadioItemDisabled checked disabled>
          <Flex vertical align="center">
            {getTransportTypeIcon(value)}
            {getTransportTypeName(value)}
          </Flex>
        </S.RadioItemDisabled>
      </Flex>
    </>
  )
}
