import { GetUserResponseStatusEnum } from '~api/generated'

export function getUserActivityStatus(userRole: GetUserResponseStatusEnum): string {
  switch (userRole) {
    case GetUserResponseStatusEnum.Activated:
      return 'Активен'
    case GetUserResponseStatusEnum.Blocked:
      return 'Заблокирован'
    default: {
      console.error('[getUserRole]: User activity status is unreachable')
      return ''
    }
  }
}
