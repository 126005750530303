import styled from 'styled-components'

import css from '@styled-system/css'

export const ContentWrapper = styled.div(
  css({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  })
)
export const AccountType = styled.div(
  css({
    color: 'TxtHeading',
    variant: 'typography.headingsH5',
    fontWeight: '700',
  })
)
