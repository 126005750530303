import styled from 'styled-components'

import css from '@styled-system/css'

export const Content = styled.div(
  css({
    display: 'flex',
    flexDirection: 'column',
    padding: ['16px', null, '32px 24px'],
  })
)
