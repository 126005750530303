import { useMutation } from '@tanstack/react-query'

import { appToast } from '~/utils'
import { useApi } from '~providers/api'

export const useUploadFileAndGetId = () => {
  const api = useApi()

  async function uploadFileAndGetId(file?: File): Promise<string> {
    try {
      if (!file) {
        appToast.error({ description: 'Ошибка при загрузке файла на сервер' })
        return Promise.reject('LabAnalysis is required')
      }
      const res = await api.file.fileControllerUploadFile({ file })
      return res.data.fileKey
    } catch (e) {
      appToast.error({ description: 'Ошибка при загрузке файла на сервер' })
      return Promise.reject('[useUploadFileAndGetId]: Upload file was failed')
    }
  }

  return useMutation(uploadFileAndGetId, { retry: 3 })
}
