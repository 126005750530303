import React, { useEffect, useState } from 'react'

import { Box } from '~/ui-components'

import { useInterval } from '../../../../utils'

import * as S from './Countdown.styled'

interface ICountdownProps {
  seconds: number
  onRetry: () => void
}

export const Countdown: React.FC<ICountdownProps> = ({ seconds, onRetry }) => {
  const [timeLeft, setTimeLeft] = useState(seconds)
  const [isRunning, setIsRunning] = useState(true)
  useInterval(() => setTimeLeft(timeLeft - 1), isRunning ? 1000 : null)

  useEffect(() => {
    if (timeLeft <= 0) {
      setIsRunning(false)
      setTimeLeft(seconds)
    }
  }, [seconds, timeLeft])

  const onRetryHandler = () => {
    setIsRunning(true)
    onRetry()
  }

  return (
    <Box>
      {isRunning ? (
        <S.Description>Отправить код повторно через {timeLeft} сек.</S.Description>
      ) : (
        <S.SendButton htmlType="submit" type="link" onClick={onRetryHandler} block data-testid="retry-send-code-btn">
          Отправить повторно
        </S.SendButton>
      )}
    </Box>
  )
}
