import { useQuery, UseQueryOptions } from '@tanstack/react-query'

import { GetMeResponse, UserApi } from '~/api/generated'
import { useApi } from '~providers/api'

interface UseUpdateTokensQueryParams {
  api?: UserApi
  options?: Omit<UseQueryOptions<GetMeResponse, Error>, 'queryKey' | 'queryFn' | 'initialData'> & {
    initialData?: () => undefined
  }
}

export const useGetUserQuery = ({ api, options }: UseUpdateTokensQueryParams) => {
  let userApiInstance: UserApi
  if (!api) {
    const apiContext = useApi()
    userApiInstance = apiContext.users
  } else {
    userApiInstance = api
  }

  const fn = async () => {
    const res = await userApiInstance.userControllerMe()

    return res.data
  }

  return useQuery<GetMeResponse, Error>(['currentUser'], fn, { ...options, staleTime: 5000 })
}
