import React, { useEffect } from 'react'

import { Outlet, useLocation, useNavigate } from 'react-router-dom'

import { AppConfig } from '~/appConfig'
import { AppMenu } from '~/layout'
import { appRoutes } from '~/router'
import { MobileMenuFooter } from '~layout/MobileMenuFooter'

import * as S from './GlobalLayout.styled'

export const GlobalLayout: React.FC = () => {
  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    if (location.pathname === AppConfig.BASE_PATH) {
      navigate(appRoutes.offersList.url)
    }
  }, [location.pathname])

  return (
    <S.AppWrapper>
      <AppMenu />
      <S.ContentWrapper>
        <Outlet />
      </S.ContentWrapper>
      <MobileMenuFooter />
    </S.AppWrapper>
  )
}
