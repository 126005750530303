/* tslint:disable */
/* eslint-disable */
/**
 * API Зерно-Агрегатора
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { BaseCompanyData } from './base-company-data';
import { DayOfWeek } from './day-of-week';
import { LoadingTransportType } from './loading-transport-type';
import { ShortUserData } from './short-user-data';
import { TransportMode } from './transport-mode';
import { UnloadingTransportType } from './unloading-transport-type';

/**
 * 
 * @export
 * @interface AdminElevatorData
 */
export interface AdminElevatorData {
    /**
     * Идентификатор элеватора
     * @type {number}
     * @memberof AdminElevatorData
     */
    'id': number;
    /**
     * Название элеватора
     * @type {string}
     * @memberof AdminElevatorData
     */
    'name': string;
    /**
     * Адрес элеватора
     * @type {string}
     * @memberof AdminElevatorData
     */
    'address': string;
    /**
     * Типы элеватора
     * @type {Array<string>}
     * @memberof AdminElevatorData
     */
    'type': Array<AdminElevatorDataTypeEnum>;
    /**
     * Рабочие дни недели
     * @type {Array<DayOfWeek>}
     * @memberof AdminElevatorData
     */
    'workingDaysOfWeek': Array<DayOfWeek>;
    /**
     * Время начала работы
     * @type {string}
     * @memberof AdminElevatorData
     */
    'openingTime': string | null;
    /**
     * Время окончания работы
     * @type {string}
     * @memberof AdminElevatorData
     */
    'closingTime': string | null;
    /**
     * Наличие весов
     * @type {boolean}
     * @memberof AdminElevatorData
     */
    'hasScales': boolean | null;
    /**
     * Есть лаборатория
     * @type {boolean}
     * @memberof AdminElevatorData
     */
    'hasLab': boolean;
    /**
     * Есть цифровая очередь
     * @type {boolean}
     * @memberof AdminElevatorData
     */
    'hasDigitalQueue': boolean;
    /**
     * Высота ворот
     * @type {number}
     * @memberof AdminElevatorData
     */
    'gateHeight': number | null;
    /**
     * Максимальный тоннаж весов
     * @type {number}
     * @memberof AdminElevatorData
     */
    'scalesWeightLimit': number | null;
    /**
     * Длина весов
     * @type {number}
     * @memberof AdminElevatorData
     */
    'scalesLength': number | null;
    /**
     * Виды принимаемого транспорта
     * @type {Array<TransportMode>}
     * @memberof AdminElevatorData
     */
    'transport': Array<TransportMode> | null;
    /**
     * Способ разгрузки автотранспорта
     * @type {Array<UnloadingTransportType>}
     * @memberof AdminElevatorData
     */
    'unloadingTransport': Array<UnloadingTransportType> | null;
    /**
     * Способ погрузки автотранспорта
     * @type {Array<LoadingTransportType>}
     * @memberof AdminElevatorData
     */
    'loadingTransport': Array<LoadingTransportType> | null;
    /**
     * Мощность единовременного хранения (в тоннах)
     * @type {number}
     * @memberof AdminElevatorData
     */
    'storageVolume': number | null;
    /**
     * Ограничение для Т/С по высоте клиренса
     * @type {number}
     * @memberof AdminElevatorData
     */
    'autoClearance': number | null;
    /**
     * Оганичение для Т/С по высоте
     * @type {number}
     * @memberof AdminElevatorData
     */
    'autoHeight': number | null;
    /**
     * Ограничения для Т/С по длине в метрах
     * @type {number}
     * @memberof AdminElevatorData
     */
    'autoLength': number | null;
    /**
     * Скорость авторазгрузки
     * @type {number}
     * @memberof AdminElevatorData
     */
    'autoUnloadSpeed': number | null;
    /**
     * Накопитель для А/М
     * @type {boolean}
     * @memberof AdminElevatorData
     */
    'hasAutoStorage': boolean | null;
    /**
     * Наличие собственных железнодорожных путей
     * @type {boolean}
     * @memberof AdminElevatorData
     */
    'hasRailWay': boolean | null;
    /**
     * Наличие собственного тягового подвижного железнодорожного состава
     * @type {boolean}
     * @memberof AdminElevatorData
     */
    'hasRailStock': boolean | null;
    /**
     * Наличие железнодорожных весов
     * @type {boolean}
     * @memberof AdminElevatorData
     */
    'hasRailScales': boolean | null;
    /**
     * Скорость погрузки вагон/час
     * @type {number}
     * @memberof AdminElevatorData
     */
    'railLoadSpeed': number | null;
    /**
     * Скорость приёмки автотранспорта
     * @type {number}
     * @memberof AdminElevatorData
     */
    'autoReceptionSpeed': number | null;
    /**
     * доп. информация по автотранспорту
     * @type {string}
     * @memberof AdminElevatorData
     */
    'autoAdditionalInfo': string | null;
    /**
     * доп. информация по жд
     * @type {string}
     * @memberof AdminElevatorData
     */
    'railAdditionalInfo': string | null;
    /**
     * Возможность технико-экономического обоснования
     * @type {boolean}
     * @memberof AdminElevatorData
     */
    'hasTecEco': boolean | null;
    /**
     * Название железнодорожной станции 
     * @type {string}
     * @memberof AdminElevatorData
     */
    'stationName': string | null;
    /**
     * Фронт подачи вагонов
     * @type {number}
     * @memberof AdminElevatorData
     */
    'waitingArea': number | null;
    /**
     * НДС включена в стоимость доп. услуг
     * @type {boolean}
     * @memberof AdminElevatorData
     */
    'additionalServiceWithNds': boolean | null;
    /**
     * Цена приемки
     * @type {number}
     * @memberof AdminElevatorData
     */
    'receptionPrice': number | null;
    /**
     * Цена сушки
     * @type {number}
     * @memberof AdminElevatorData
     */
    'dryingPrice': number | null;
    /**
     * Цена хранения
     * @type {number}
     * @memberof AdminElevatorData
     */
    'storingPrice': number | null;
    /**
     * Цена смешивания
     * @type {number}
     * @memberof AdminElevatorData
     */
    'mixingPrice': number | null;
    /**
     * Цена переписи
     * @type {number}
     * @memberof AdminElevatorData
     */
    'censusPrice': number | null;
    /**
     * Цена отгрузки автотранспорта
     * @type {number}
     * @memberof AdminElevatorData
     */
    'autoShipmentPrice': number | null;
    /**
     * Цена отгрузки жд
     * @type {number}
     * @memberof AdminElevatorData
     */
    'trainShipmentPrice': number | null;
    /**
     * Цена оформления документов
     * @type {number}
     * @memberof AdminElevatorData
     */
    'paperworkPrice': number | null;
    /**
     * Цена очистки от сора
     * @type {number}
     * @memberof AdminElevatorData
     */
    'cleaningPrice': number | null;
    /**
     * Описание и цена других доп услуг
     * @type {string}
     * @memberof AdminElevatorData
     */
    'anotherServices': string | null;
    /**
     * Идентификатор пользователя создавшего элеватор
     * @type {number}
     * @memberof AdminElevatorData
     */
    'createdByUserId': number | null;
    /**
     * Является ли элеватор скрытым?
     * @type {boolean}
     * @memberof AdminElevatorData
     */
    'isDeleted': boolean;
    /**
     * Дата создания элеватора
     * @type {string}
     * @memberof AdminElevatorData
     */
    'createdAt': string;
    /**
     * Количество опубликованных объявлений
     * @type {number}
     * @memberof AdminElevatorData
     */
    'elevatorAdvertsCount': number;
    /**
     * Данные по создателю элеватора
     * @type {ShortUserData}
     * @memberof AdminElevatorData
     */
    'shortUserData': ShortUserData | null;
    /**
     * Компания создавшего элеватор
     * @type {BaseCompanyData}
     * @memberof AdminElevatorData
     */
    'createdByCompany': BaseCompanyData | null;
    /**
     * Создатель
     * @type {boolean}
     * @memberof AdminElevatorData
     */
    'isCreationRequest': boolean | null;
}

export const AdminElevatorDataTypeEnum = {
    Transshipment: 'TRANSSHIPMENT',
    Port: 'PORT',
    Recast: 'RECAST'
} as const;

export type AdminElevatorDataTypeEnum = typeof AdminElevatorDataTypeEnum[keyof typeof AdminElevatorDataTypeEnum];


