import React from 'react'

import { Button, Tooltip } from 'antd'
import { FiEdit2 } from 'react-icons/fi'

import { useGetDevice } from '~hooks/common'

interface RightPanelEditButtonProps {
  canEdit?: boolean
  onClick: () => void
}

export const RightPanelEditButton: React.FC<RightPanelEditButtonProps> = ({ canEdit = true, onClick }) => {
  const device = useGetDevice()
  const isMobile = device === 'mobile'

  if (isMobile) {
    return (
      <Tooltip title={!canEdit ? 'Не хватает прав' : undefined}>
        <Button type="text" icon={<FiEdit2 size={18} />} onClick={onClick} disabled={!canEdit} />
      </Tooltip>
    )
  }

  return (
    <Tooltip title={!canEdit ? 'Не хватает прав' : undefined}>
      <Button type="primary" onClick={onClick} disabled={!canEdit}>
        Редактировать
      </Button>
    </Tooltip>
  )
}
