import * as yup from 'yup'

import { onlyDigitValidator, VALIDATORS_MSG } from '~shared/validations'

const requiredTrimmedString = yup.string().trim().required(VALIDATORS_MSG.required)

export const bankInfoFormSchema = yup
  .object({
    bankName: requiredTrimmedString,
    accountNumber: yup
      .string()
      .test('onlyNumbers', 'расчетный счет должен состоять из цифр', onlyDigitValidator)
      .required(VALIDATORS_MSG.required)
      .length(20, 'должен состоять из 20 символов'),
    correspondentAccountNumber: yup
      .string()
      .test('onlyNumbers', 'корреспондентский счет должен состоять из цифр', onlyDigitValidator)
      .required(VALIDATORS_MSG.required)
      .length(20, 'должен состоять из 20 символов'),
    bik: requiredTrimmedString,
  })
  .required()
