import { useQuery, UseQueryResult } from '@tanstack/react-query'

import { ElevatorApiElevatorControllerGetElevatorRequest, GetElevatorResponse } from '~api/generated'
import { useApi } from '~providers/api'

export const useGetElevator = (
  request: ElevatorApiElevatorControllerGetElevatorRequest
): UseQueryResult<GetElevatorResponse> => {
  const api = useApi()

  const fetchFn = async (): Promise<GetElevatorResponse> => {
    const res = await api.elevators.elevatorControllerGetElevator(request)

    return res.data
  }

  return useQuery(['elevator', request.id], fetchFn, { enabled: !!request.id })
}
