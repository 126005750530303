import styled from 'styled-components'

import { breakpoints } from '~/tokens'

export const ContentWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 345px);
  gap: 24px;
  justify-content: center;

  @media (max-width: ${breakpoints.MOBILE_XL}px) {
    grid-template-columns: repeat(1, 345px);
  }
`

export const Section = styled.div`
  display: flex;
  width: 345px;
  & > div {
    width: 100%;
  }
  @media (max-width: ${breakpoints.MOBILE_XL}px) {
    min-height: unset;
    min-width: unset;
  }
`

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  max-width: 420px;
`
