import React, { useEffect } from 'react'

import { Divider, Empty, Flex, Skeleton } from 'antd'
import { observer } from 'mobx-react-lite'
import { useParams } from 'react-router'
import { useNavigate } from 'react-router-dom'

import { useQuery } from '@apollo/client'

import { AppHeader } from '~/layout'
import { appRoutes } from '~/router'
import { userUiSettingsStore } from '~/store/userUiSettingsStore'
import { Text } from '~/ui-components'
import { getCompanyStateQuery } from '~api/gql-query/company.query.graphql'
import { useGetVipElevators } from '~hooks/elevators'
import { findVipClientById, VIP_CLIENTS, VipClientId } from '~layout/WelcomePageV2/VipCards'
import { BasisVipItem } from '~pages/BasisPage/modules/BasisVip/BasisVipItem'
import { useAuth } from '~providers/auth'

import * as S from './BasisVip.styled'

export const BasisVip: React.FC = observer(() => {
  const { companyId } = useAuth()
  const navigate = useNavigate()
  const { id } = useParams<{ id: VipClientId }>()
  const vipClientId = id as VipClientId

  useEffect(() => {
    userUiSettingsStore.setVipClientId(vipClientId)
  }, [vipClientId])

  const vipClient = findVipClientById(vipClientId)

  const companyStateFn = useQuery(getCompanyStateQuery, {
    variables: { companyId },
    skip: !companyId,
  })

  const hasPriorityPass = VIP_CLIENTS.gapResurs.isShow || VIP_CLIENTS.damate.isShow

  const getVipElevatorsFn = useGetVipElevators()

  const onBack = () => navigate(appRoutes.basisList.url)

  if (companyStateFn.loading || getVipElevatorsFn.isLoading) {
    return <Skeleton />
  }

  if (
    !hasPriorityPass ||
    (!VIP_CLIENTS.gapResurs.isShow && vipClientId === VipClientId.GAP) ||
    (!VIP_CLIENTS.damate.isShow && vipClientId === VipClientId.DAMATE)
  ) {
    return (
      <>
        <AppHeader title={appRoutes.basisVip.title} onBack={onBack} isBack />
        <Flex justify="center" style={{ marginTop: 50 }}>
          <Empty description="Доступ ограничен" />
        </Flex>
      </>
    )
  }

  function getElevatorsData() {
    switch (vipClientId) {
      case 'gap-resurs': {
        return getVipElevatorsFn.data?.gapResource
      }
      case 'damate': {
        return getVipElevatorsFn.data?.damate
      }
      default: {
        return null
      }
    }
  }

  const elevators = getElevatorsData()

  if (!elevators) {
    return null
  }

  return (
    <Flex vertical>
      <AppHeader title={appRoutes.basisVip.title} onBack={onBack} isBack />

      <S.Wrapper>
        <S.Header>
          <Text variant="h4" style={{ maxWidth: 460 }}>
            СПИСОК ЗАКУПОК ГРУППЫ КОМПАНИЙ {vipClient.legalName} НА ЭЛЕВАТОРАХ И ПРЕДПРИЯТИЯХ В РЕГИОНАХ РФ
          </Text>
          {vipClient.logoLarge}
        </S.Header>

        <Flex vertical>
          {elevators.map((vipElevator) => (
            <>
              <BasisVipItem basisId={vipElevator.id} basisAddress={vipElevator.address} basisName={vipElevator.name} />
              <Divider />
            </>
          ))}
        </Flex>
      </S.Wrapper>
    </Flex>
  )
})
