import React from 'react'

import { FiPlus } from 'react-icons/fi'

import type { EmptyButtonProps } from './EmptyButton.types'

import * as S from './EmptyButton.styled'

export const EmptyButton: React.FC<EmptyButtonProps> = ({ children, isReadOnly, onClick, ...props }) => {
  return (
    <S.EmptyButton isReadOnly={isReadOnly} onClick={onClick} {...props}>
      <S.Content>
        {!isReadOnly && <FiPlus size={16} />}
        {isReadOnly ? <S.InfoText>{children}</S.InfoText> : <S.ContentText>{children}</S.ContentText>}
      </S.Content>
    </S.EmptyButton>
  )
}
