import React, { PropsWithChildren } from 'react'

import { Select } from 'antd'
import { BaseSelectRef } from 'rc-select'

import { RoadSurfaceType } from '~api/generated'
import { CustomControlInterface } from '~shared/controls/CustomControl.interface'
import { getRoadSurfaceTypeName } from '~shared/controls/RoadSurfaceTypeSelectControl/get-road-surface-type-name'

type Value = RoadSurfaceType | undefined

interface RoadSurfaceTypeSelectControlProps extends CustomControlInterface {
  onChange: (value: Value) => void
  value: Value
  hasError?: boolean
  disabled?: boolean
  id?: string
}

interface IOption {
  value: RoadSurfaceType
  label: string
}

const loadTypesOptions: IOption[] = [
  {
    label: getRoadSurfaceTypeName(RoadSurfaceType.Paved),
    value: RoadSurfaceType.Paved,
  },
  {
    label: getRoadSurfaceTypeName(RoadSurfaceType.Unpaved),
    value: RoadSurfaceType.Unpaved,
  },
]

export const RoadSurfaceTypeSelectControl: React.FC<PropsWithChildren<RoadSurfaceTypeSelectControlProps>> =
  React.forwardRef<BaseSelectRef, PropsWithChildren<RoadSurfaceTypeSelectControlProps>>(
    ({ id, value, onChange, hasError, disabled }, ref) => {
      const onSelectAddressHandler = (v: Value): void => {
        onChange(v)
      }

      return (
        <Select
          ref={ref}
          id={id}
          style={{ width: '100%' }}
          placeholder="Выберите из списка"
          value={value}
          disabled={disabled}
          onChange={onSelectAddressHandler}
          status={hasError ? 'error' : undefined}
          optionFilterProp="children"
          allowClear
        >
          {loadTypesOptions.map((it) => (
            <Select.Option key={it.value} value={it.value}>
              {it.label}
            </Select.Option>
          ))}
        </Select>
      )
    }
  )

RoadSurfaceTypeSelectControl.displayName = 'RoadSurfaceTypeSelectControl'
