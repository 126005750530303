import styled from 'styled-components'
import { Select } from 'antd'

export const StyledSelect = styled(Select)`
  & .ant-select-selection-item {
    font-size: 20px;
    font-weight: 700;
    color: #494949 !important;
    padding-inline-end: 26px !important;
  }

  & .ant-select-arrow {
    color: #494949;
  }
`
