import { graphql } from '~api/gql-generated'

export const freightOrderFieldInitialValuesQuery = graphql(/* GraphQL */ `
  query FreightOrderFieldInitialValues($freightDealId: Int!) {
    freightOrderFieldInitialValues(freightDealId: $freightDealId) {
      value
      fieldName
    }
  }
`)
