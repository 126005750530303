import React from 'react'

import { Segmented, Select } from 'antd'
import { BaseSelectRef } from 'rc-select'

import { getElevatorAdvertType } from '~/utils/elevator-adverts/get-elevator-advert-type'
import { AdminElevatorAdvertDataTypeEnum } from '~api/generated'
import { CustomControlInterface } from '~shared/controls/CustomControl.interface'

type Value = AdminElevatorAdvertDataTypeEnum | undefined

interface ElevatorAdvertStatusSelectControlProps extends CustomControlInterface {
  onChange: (value: Value) => void
  value: Value
  variant?: 'dropdown' | 'switch'
  hasError?: boolean
  id?: string
  placeholder?: string
}

interface IOption {
  value: AdminElevatorAdvertDataTypeEnum
  label: string
}
const selectOptions: IOption[] = [
  {
    value: AdminElevatorAdvertDataTypeEnum.Buy,
    label: getElevatorAdvertType(AdminElevatorAdvertDataTypeEnum.Buy).name,
  },
  {
    value: AdminElevatorAdvertDataTypeEnum.Sell,
    label: getElevatorAdvertType(AdminElevatorAdvertDataTypeEnum.Sell).name,
  },
]

export const ElevatorAdvertTypeSelectControl: React.FC<ElevatorAdvertStatusSelectControlProps> = React.forwardRef<
  BaseSelectRef,
  ElevatorAdvertStatusSelectControlProps
>(({ id, value, onChange, hasError, placeholder = 'Тип объявления', variant = 'dropdown' }, ref) => {
  const onSelectHandler = (v: Value) => {
    onChange(v)
  }

  if (variant === 'dropdown') {
    return (
      <Select
        allowClear
        ref={ref}
        id={id}
        style={{ width: '100%' }}
        placeholder={placeholder}
        value={value}
        onChange={onSelectHandler}
        status={hasError ? 'error' : undefined}
        optionFilterProp="children"
      >
        {selectOptions.map((it) => (
          <Select.Option key={it.value} value={it.value}>
            {it.label}
          </Select.Option>
        ))}
      </Select>
    )
  }
  if (variant === 'switch') {
    return (
      <Segmented
        style={{ width: '100%' }}
        value={value}
        block
        options={[
          { label: 'На покупку', value: AdminElevatorAdvertDataTypeEnum.Buy },
          { label: 'На продажу', value: AdminElevatorAdvertDataTypeEnum.Sell },
        ]}
        onChange={(advertType) => onSelectHandler(advertType as Value)}
      />
    )
  }
  return null
})

ElevatorAdvertTypeSelectControl.displayName = 'ElevatorAdvertTypeSelectControl'
