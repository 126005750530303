import { useMutation } from '@tanstack/react-query'

import { ElevatorAdvertOfferApiAdminElevatorAdvertOfferControllerRejectElevatorAdvertOfferRequest } from '~api/generated'
import { useApi } from '~providers/api'

export const useRejectAdvertOfferByAdmin = () => {
  const api = useApi()

  const fn = async (
    request: ElevatorAdvertOfferApiAdminElevatorAdvertOfferControllerRejectElevatorAdvertOfferRequest
  ) => {
    const res = await api.elevatorAdvertOffer.adminElevatorAdvertOfferControllerRejectElevatorAdvertOffer(request)

    return res.data
  }

  return useMutation(fn)
}
