import React from 'react'
import { Empty, Flex, Skeleton } from 'antd'
import {
  FreightOrderChangeRequestBlockEntity,
  FreightOrderChangeRequestBlockType,
  FreightOrderChangeRequestType,
  FreightOrderFieldChangeEntity,
  FreightOrderFieldName,
  FreightPackingType,
  FreightPaymentTerm,
} from '~api/gql-generated/graphql'
import { Text } from '~/ui-components'
import { FiBox } from 'react-icons/fi'
import { LogisticDealHistoryProps } from './LogisticDealHistory.types'
import * as S from './LogisticDealHistory.styled'
import { AiOutlineNodeIndex, AiOutlineWallet } from 'react-icons/ai'
import { useQuery } from '@apollo/client'
import { useAuth } from '~providers/auth'
import { getDateTimeFormat } from '~/utils/getDateFormat'
import { LinkValue } from '~shared/components/LinkValue'
import { getFreightOrderChangeRequestName } from '~/utils/logistics/get-freight-order-change-request-name'
import { getFreightPackingType } from '~/utils/logistics/get-freight-packing-type'
import { getFreightPaymentTerm } from '~/utils/logistics/get-freight-payment-term'
import { getNoun } from '~/utils/getNoun'
import { formatNumber } from '~/utils/formatNumber'
import { FreightDealInitialValues } from '~pages/LogisticsPage/modules/LogisticDealCard/components/FreightDealInitialValues/FreightDealInitialValues'
import { freightOrderChangeRequestBlocksQuery } from '~api/gql-query/freight-order-change-request-blocks.query.graphql'

const HeaderHistory = ({ type }: { type: FreightOrderChangeRequestBlockType }) => {
  switch (type) {
    case FreightOrderChangeRequestBlockType.Freight:
      return (
        <Flex align="center" gap={16}>
          <FiBox size={20} />
          <Text variant="h2">Груз</Text>
        </Flex>
      )
    case FreightOrderChangeRequestBlockType.Price:
      return (
        <Flex align="center" gap={16}>
          <AiOutlineWallet size={20} />
          <Text variant="h2">Оплата</Text>
        </Flex>
      )
    case FreightOrderChangeRequestBlockType.TransportRegistry:
      return (
        <Flex align="center" gap={16}>
          <Text variant="h2">Реестр авто</Text>
        </Flex>
      )
    case FreightOrderChangeRequestBlockType.Route:
      return (
        <Flex align="center" gap={16}>
          <AiOutlineNodeIndex size={20} />
          <Text variant="h2">Маршрут</Text>
        </Flex>
      )
    default:
      return null
  }
}

const ContentHistory = ({
  freightDealChangeRequestBlocks,
}: {
  freightDealChangeRequestBlocks: FreightOrderChangeRequestBlockEntity[]
}) => {
  const { companyId } = useAuth()
  return (
    <Flex vertical gap={24}>
      {freightDealChangeRequestBlocks.map((freightDealChangeRequestBlock) => (
        <React.Fragment key={freightDealChangeRequestBlock.id}>
          {freightDealChangeRequestBlock.changeRequests.length > 0 && (
            <S.FlexWrapper vertical gap={8}>
              <Flex justify="space-between">
                {freightDealChangeRequestBlock.changeRequests[0].createdByCompanyId === companyId ? (
                  <Text variant="normal">Вы</Text>
                ) : (
                  <Flex vertical>
                    <Text variant="normal">Контрагент</Text>
                    <Text variant="normal">
                      {freightDealChangeRequestBlock.changeRequests[0].createdByCompany.legalName}
                    </Text>
                  </Flex>
                )}
                <Text variant="form-read-label">
                  {getDateTimeFormat(freightDealChangeRequestBlock.changeRequests[0].updatedAt)}
                </Text>
              </Flex>

              <Flex gap={24}>
                {freightDealChangeRequestBlock.changeRequests.map((changeRequest) => (
                  <LinkValue
                    key={changeRequest.id}
                    name={getFreightOrderChangeRequestName(changeRequest.type)}
                    value={changeRequest.changedFields.map(getChangedFieldValue).join(', ')}
                    status={changeRequest.status}
                    isCreator={changeRequest.createdByCompanyId === companyId}
                    isFinalizing={changeRequest.endedByCompanyId === companyId}
                  />
                ))}
              </Flex>

              {freightDealChangeRequestBlock.changeRequests[0].type === FreightOrderChangeRequestType.Distance && (
                <Flex gap={8}>
                  <Text variant="form-read-label">Комментарий:</Text>
                  <Text variant="form-read-value">
                    {freightDealChangeRequestBlock.changeRequests[0].additionalDetails}
                  </Text>
                </Flex>
              )}
            </S.FlexWrapper>
          )}
        </React.Fragment>
      ))}
    </Flex>
  )
}

const getChangedFieldValue = (changedField: FreightOrderFieldChangeEntity) => {
  switch (changedField.fieldName) {
    case FreightOrderFieldName.Weight:
      return formatNumber(+changedField.value / 1000) + ' т'
    case FreightOrderFieldName.PackingType:
      return getFreightPackingType(changedField.value as FreightPackingType)
    case FreightOrderFieldName.PackingTypeCustom:
      return changedField.value
    case FreightOrderFieldName.BigBagsCount:
      return changedField.value + ' шт'
    case FreightOrderFieldName.PricePerTonWithoutNds:
      return changedField.value + ' ₽/т'
    case FreightOrderFieldName.PaymentTerm:
      return getFreightPaymentTerm(changedField.value as FreightPaymentTerm)
    case FreightOrderFieldName.AdvancePercent:
      return changedField.value + ' %'
    case FreightOrderFieldName.DeferredDaysCount:
      return changedField.value + ' ' + getNoun(+changedField.value, 'день', 'дня', 'дней')
    case FreightOrderFieldName.CanUpdateTransportRegistryInPast:
      return changedField.value === 'true' ? 'С возможностью редактирования' : 'Без возможности редактирования'
    case FreightOrderFieldName.Distance:
      return changedField.value + ' км'
    default:
      return changedField.value
  }
}

export const LogisticDealHistory: React.FC<LogisticDealHistoryProps> = ({ type, freightDealId }) => {
  const getFreightDealChangeRequestBlocks = useQuery(freightOrderChangeRequestBlocksQuery, {
    variables: { freightDealId, filter: { types: [type] } },
    fetchPolicy: 'cache-and-network',
  })
  const freightDealChangeRequestBlocks: FreightOrderChangeRequestBlockEntity[] =
    (getFreightDealChangeRequestBlocks.data
      ?.freightOrderChangeRequestBlocks as FreightOrderChangeRequestBlockEntity[]) ?? []

  return (
    <Flex vertical gap={24}>
      <S.FlexWrapper>
        <HeaderHistory type={type} />
      </S.FlexWrapper>

      {getFreightDealChangeRequestBlocks.loading ? (
        <Skeleton loading={getFreightDealChangeRequestBlocks.loading} />
      ) : freightDealChangeRequestBlocks.length > 0 ? (
        <>
          <S.FlexWrapper>
            <S.InitialHistoryWrapper vertical gap={8}>
              <Text variant="form-read-label">Исходное значение</Text>
              <FreightDealInitialValues type={type} freightDealId={freightDealId} />
            </S.InitialHistoryWrapper>
          </S.FlexWrapper>
          <ContentHistory freightDealChangeRequestBlocks={freightDealChangeRequestBlocks} />
        </>
      ) : (
        <Empty />
      )}
    </Flex>
  )
}
