import React from 'react'

import { notification } from 'antd'
import { ArgsProps } from 'antd/es/notification/interface'

type ToastOptions = Partial<Omit<ArgsProps, 'type'>>
type RequiredDescription = Partial<ToastOptions> & { description: React.ReactNode }

export default {
  // using only for notifications functionality from backend
  notification(options: Required<Pick<ToastOptions, 'description' | 'key' | 'onClick'>>) {
    notification.open({
      type: 'info',
      message: 'Уведомление',
      description: options.description,
      key: options.key,
      onClick: () => {
        options.onClick()
        notification.destroy(options.key)
      },
      duration: 6,
    })
  },
  success(options?: ToastOptions) {
    notification.open({
      type: 'success',
      message: options?.message ?? 'Успех',
      description: options?.description,
    })
  },
  error(options?: ToastOptions) {
    notification.open({
      type: 'error',
      message: options?.message ?? 'Ошибка',
      description: options?.description ?? 'Во время выполнения операции произошла ошибка. Обратитесь к администратору',
      duration: null, // it will never be closed automatically
    })
  },
  info(options: RequiredDescription) {
    notification.open({
      type: 'info',
      message: options?.message ?? 'Уведомление',
      description: options.description,
    })
  },
}
