import React from 'react'

import { Divider } from 'antd'
import styled from 'styled-components'

import { Badge, Card, Text } from '~/ui-components'
import { getCompanyRole } from '~/utils/company/get-company-role-old'
import { getCompanyStatusName } from '~/utils/company/get-company-status-name'
import { formatPhoneNumber } from '~/utils/formatPhoneNumber'
import { getValueIfExistOrDefault } from '~/utils/getValueIfExistOrDefault'
import { getUserRole } from '~/utils/user/getUserRoleOld'
import {
  BaseCompanyData,
  BaseCompanyDataTypeEnum,
  BaseOfferDataTypeEnum,
  BaseUserData,
  GetComplexDealResponse,
} from '~api/generated'
import { CompanyType, UserRole } from '~api/gql-generated/graphql'
import { AdminLinkToCompanyById } from '~shared/components'

import * as S from '../../DealInformation.styled'

export const GreenH4 = styled(Text).attrs({ variant: 'h4' })`
  color: var(--primary-main-color);
`
interface IDealCounterpartProps {
  deal: GetComplexDealResponse
  dealSide: 'seller' | 'buyer'
}
export const DealResponseInfo: React.FC<IDealCounterpartProps> = ({ deal, dealSide }) => {
  const offerType = deal.offer.type
  const company: BaseCompanyData = dealSide === 'seller' ? deal.seller : deal.buyer
  const user: BaseUserData | null = dealSide === 'seller' ? deal.sellerUser : deal.buyerUser

  const renderTitle = () => {
    if (offerType === BaseOfferDataTypeEnum.Sell) {
      if (dealSide === 'seller') {
        return 'Предложение создал'
      }
      if (dealSide === 'buyer') {
        return 'Откликнулся'
      }
    }
    if (offerType === BaseOfferDataTypeEnum.Buy) {
      if (dealSide === 'seller') {
        return 'Откликнулся'
      }
      if (dealSide === 'buyer') {
        return 'Предложение создал'
      }
    }
  }

  return (
    <Card>
      <Card.Header>
        <Card.Title>{renderTitle()}:</Card.Title>
      </Card.Header>
      <S.CardContent>
        <div>
          <Badge color={company.type === BaseCompanyDataTypeEnum.Seller ? 'yellow' : 'base'} size="s">
            {company?.type ? getCompanyRole(company.type as CompanyType) : '-'}
          </Badge>
        </div>

        <GreenH4>{company?.name}</GreenH4>

        <S.Row>
          <Card.Label>Статус компании</Card.Label>
          <Card.Value>
            <Badge color="base">{company?.status ? getCompanyStatusName(company.status) : '-'}</Badge>
          </Card.Value>
        </S.Row>
        <S.Row>
          <Card.Label>ID компании</Card.Label>
          <Card.Value>
            <AdminLinkToCompanyById companyId={company?.id ?? -1} />
          </Card.Value>
        </S.Row>
        <S.Row>
          <Card.Label>ИНН</Card.Label>
          <Card.Value>{getValueIfExistOrDefault(company?.inn)}</Card.Value>
        </S.Row>

        <Divider />

        <GreenH4>{getValueIfExistOrDefault(user?.name)}</GreenH4>

        <S.Row>
          <Card.Label>ID</Card.Label>
          <Card.Value>{getValueIfExistOrDefault(user?.id)}</Card.Value>
        </S.Row>
        <S.Row>
          <Card.Label>Номер телефона</Card.Label>
          <Card.Value>{formatPhoneNumber(user?.phoneNumber)}</Card.Value>
        </S.Row>
        <S.Row>
          <Card.Label>Электронная почта</Card.Label>
          <Card.Value>{getValueIfExistOrDefault(user?.email)}</Card.Value>
        </S.Row>
        <S.Row>
          <Card.Label>Должность</Card.Label>
          <Card.Value>{getValueIfExistOrDefault(user?.position)}</Card.Value>
        </S.Row>
        <S.Row>
          <Card.Label>Роль</Card.Label>
          <Card.Value>{user?.role ? getUserRole(user.role as UserRole) : '-'}</Card.Value>
        </S.Row>
      </S.CardContent>
    </Card>
  )
}
