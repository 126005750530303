/* tslint:disable */
/* eslint-disable */
/**
 * API Зерно-Агрегатора
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */

export const CompanyVerificationStatus = {
    NotVerified: 'NOT_VERIFIED',
    OnModeration: 'ON_MODERATION',
    Verified: 'VERIFIED',
    Rejected: 'REJECTED'
} as const;

export type CompanyVerificationStatus = typeof CompanyVerificationStatus[keyof typeof CompanyVerificationStatus];



