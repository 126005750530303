import React from 'react'

import { Button, Modal, Tooltip } from 'antd'
import { FiWifi, FiWifiOff } from 'react-icons/fi'

import { UserDataStatusEnum } from '~api/generated'

interface IDeactivateUserToggleProps {
  status: UserDataStatusEnum
  userName: string
  onChangeUserStatus: (status: UserDataStatusEnum) => void
}

const isActive = (status: UserDataStatusEnum): boolean => status === UserDataStatusEnum.Activated

export const DeactivateUserToggle: React.FC<IDeactivateUserToggleProps> = ({
  status,
  onChangeUserStatus,
  userName,
}) => {
  const showChangeStatusConfirm = (newStatus: UserDataStatusEnum) => {
    Modal.confirm({
      title: `Вы действительно хотите ${isActive(newStatus) ? 'активировать' : 'деактивировать'} пользователя?`,
      content: isActive(status) ? (
        <>
          Пользователь <b>{userName}</b> не сможет пользоваться системой.
        </>
      ) : (
        <>
          Пользователю <b>{userName}</b> будет разрешено пользоваться системой.
        </>
      ),
      okText: 'Да',
      cancelText: 'Отмена',
      onOk() {
        onChangeUserStatus(newStatus)
      },
    })
  }
  return (
    <Tooltip title={isActive(status) ? 'деактивировать' : 'активировать'}>
      <Button
        type="text"
        icon={
          isActive(status) ? (
            <FiWifi size={24} color={'var(--success-color)'} />
          ) : (
            <FiWifiOff size={24} color={'var(--danger-color)'} />
          )
        }
        onClick={(event) => {
          const newStatus = isActive(status) ? UserDataStatusEnum.Blocked : UserDataStatusEnum.Activated
          event.stopPropagation()
          showChangeStatusConfirm(newStatus)
        }}
      />
    </Tooltip>
  )
}
