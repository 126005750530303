import styled, { css } from 'styled-components'

import { Text } from '../Text'

export const EmptyButton = styled.div<{ isReadOnly?: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 16px;
  border-radius: 16px;
  border: 1px dashed var(--primary-main-color);
  cursor: pointer;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: 0.25s;
    opacity: 0.05;
  }

  &:hover {
    border-style: solid;

    &::before {
      background: var(--primary-main-color);
    }
  }

  ${({ isReadOnly }) =>
    isReadOnly &&
    css`
      border-style: solid;

      &::before {
        background: var(--primary-main-color);
      }
    `}
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  justify-content: center;
  text-align: center;
  color: var(--primary-main-color);
`

export const ContentText = styled(Text).attrs({ variant: 'normal-bold' })`
  color: var(--primary-main-color);
`

export const InfoText = styled(Text).attrs({ variant: 'normal' })`
  color: var(--gray-6-color);
`
