import * as yup from 'yup'

import { addressSchema, VALIDATORS_MSG } from '~shared/validations'
import { fullPhoneNumberValidatorNotRequired } from '~shared/validations/schemas'

export const CreateLotModalSchema = yup.object({
  offerType: yup.mixed().oneOf(['BUY', 'SELL']),
  address: addressSchema,
  volume: yup
    .number()
    .typeError(VALIDATORS_MSG.required)
    .min(200, VALIDATORS_MSG.minValue(200))
    .required(VALIDATORS_MSG.required),
  price: yup
    .number()
    .typeError(VALIDATORS_MSG.required)
    .min(1000, VALIDATORS_MSG.minValue(1000))
    .required(VALIDATORS_MSG.required),
  culture: yup
    .object({
      // hasError: yup.bool().oneOf([false], 'Параметры культуры заполнены с ошибками'),
    })
    .required(VALIDATORS_MSG.required),
  contactPhone: yup
    .string()
    .trim()
    .test('mobilePhone', VALIDATORS_MSG.notFullPhoneNumber, fullPhoneNumberValidatorNotRequired),
  labAnalysis: yup.mixed().when('offerType', {
    is: (val: 'BUY' | 'SELL') => val === 'SELL',
    then: yup.mixed().required(VALIDATORS_MSG.required),
    otherwise: yup.mixed().notRequired(),
  }),
})
