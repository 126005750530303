import React from 'react'

import { Flex, Spin } from 'antd'

import { useQuery } from '@apollo/client'

import { Text } from '~/ui-components'
import { getOwnershipTypeLabel } from '~/utils/car-park/get-ownership-type-label'
import { getTransportDocumentsQuery } from '~api/gql-query/car-park.query.graphql'
import { ReadValue } from '~shared/components'

import { OwnershipType, TransportDocumentType, TransportType } from '~api/gql-generated/graphql'
import { TransportPhotos } from './components/TransportPhotos'

import { TransportGeneralProps } from './TransportGeneral.types'

import * as S from './TransportGeneral.styled'
import { ReadValueWrapper } from './TransportGeneral.styled'

export const TransportGeneral: React.FC<TransportGeneralProps> = ({ transportId, transportGeneral }) => {
  const { type, brand, vin, bodyType, axlesCount, ownershipType, ownerName } = transportGeneral

  const getTransportPhotos = useQuery(getTransportDocumentsQuery, {
    variables: {
      transportId,
      filter: {
        types: [TransportDocumentType.Photo],
      },
    },
    fetchPolicy: 'cache-and-network',
  })

  const transportPhotos = getTransportPhotos.data?.transportDocuments ?? []

  return (
    <S.Wrapper>
      <Flex vertical gap={60}>
        <Flex vertical gap={32}>
          <Flex gap={16}>
            <ReadValueWrapper>
              <ReadValue name="Марка" value={brand} />
            </ReadValueWrapper>
            <ReadValueWrapper>
              <ReadValue name="VIN номер" value={vin} />
            </ReadValueWrapper>
            {type !== TransportType.Tractor && (
              <ReadValueWrapper>
                <ReadValue name="Тип кузова" value={bodyType?.name ?? ''} />
              </ReadValueWrapper>
            )}
            <ReadValueWrapper>
              <ReadValue name="Количество осей" value={axlesCount.toString()} />
            </ReadValueWrapper>
          </Flex>

          <Flex gap={16}>
            <S.OwnerWrapper vertical gap={16}>
              <Text variant="form-read-value">🔑 {getOwnershipTypeLabel(ownershipType)}</Text>
              {ownershipType !== OwnershipType.Own && (
                <Flex gap={10}>
                  <Text variant="description">Владелец</Text>
                  <Text variant="label-bold">{ownerName ? ownerName : 'Не заполнено'}</Text>
                </Flex>
              )}
            </S.OwnerWrapper>
          </Flex>
        </Flex>

        {getTransportPhotos.loading ? (
          <Flex justify="center" align="center" flex="1 0 auto">
            <Spin spinning={true} size="large" />
          </Flex>
        ) : (
          <Flex vertical gap={24}>
            <TransportPhotos transportPhotos={transportPhotos} />
          </Flex>
        )}
      </Flex>
    </S.Wrapper>
  )
}
