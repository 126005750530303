import styled from 'styled-components'

import { css } from '@styled-system/css'

import { Card } from '~/ui-components'

export const Wrapper = styled.div(
  css({
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
  })
)

export const StatusWrapper = styled.div(
  css({
    display: 'flex',
    gap: '16px',
    marginTop: '16px',
  })
)

export const CardValue = styled(Card.Value)(
  css({
    fontWeight: '600',
  })
)
