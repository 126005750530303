import React from 'react'

import { observer } from 'mobx-react-lite'

import { IAppRouteName } from '~/router/routes'
import { CompanyDataTypeEnum } from '~api/generated'
import { useGetCompanyAccountType } from '~hooks/company'
import { LogisticMenu } from '~layout/AppMenuItems/LogisticMenu'
import { useAuth } from '~providers/auth'

import { menu } from '../AppMenu/AppMenu.data'

import { AppMenuItem } from './AppMenuItem'

export const AppMenuItems: React.FC = observer(() => {
  const { user, companyId } = useAuth()
  const { data: accountTypeData } = useGetCompanyAccountType(companyId)

  const userRole = user?.role
  const companyRole = accountTypeData?.companyRoleType

  return (
    <>
      {Object.keys(menu).map((menuItemName) => {
        const item = menu[menuItemName as IAppRouteName]

        if (!item) {
          return null
        }

        const { url, title, icon, roles } = item

        const hasRoleGuard =
          roles?.length && ((userRole && roles.includes(userRole)) || (companyRole && roles.includes(companyRole)))

        if (!roles?.length || hasRoleGuard) {
          if (companyRole && companyRole !== CompanyDataTypeEnum.Carrier) {
            if (
              ['logisticFindCargo', 'logisticMyCargos', 'logisticResponses', 'logisticDeals'].includes(menuItemName)
            ) {
              return null
            }
            if (menuItemName === 'logistics') {
              return <LogisticMenu key="logistics" />
            }
          } else {
            if (['logistics'].includes(menuItemName)) {
              return null
            }
          }

          return <AppMenuItem url={url} title={title} icon={icon} key={url} />
        }

        return null
      })}
    </>
  )
})
