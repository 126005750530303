import { codeUnreachable } from '~/utils/code-unreachable'
import { FreightOrderChangeRequestType } from '~api/gql-generated/graphql'

export function getFreightOrderChangeRequestName(freightOrderChangeRequestType: FreightOrderChangeRequestType): string {
  switch (freightOrderChangeRequestType) {
    case FreightOrderChangeRequestType.Weight:
      return 'Вес'
    case FreightOrderChangeRequestType.PackingType:
      return 'Вид упаковки'
    case FreightOrderChangeRequestType.PricePerTon:
      return 'Ставка без НДС'
    case FreightOrderChangeRequestType.PaymentTerm:
      return 'Условия оплаты'
    case FreightOrderChangeRequestType.TransportRegistry:
      return 'Редактирование'
    case FreightOrderChangeRequestType.Distance:
      return 'Длина маршрута'
    default:
      return codeUnreachable(freightOrderChangeRequestType)
  }
}
