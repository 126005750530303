import * as yup from 'yup'

import { VALIDATORS_MSG } from '~shared/validations'
import { fullPhoneNumberValidator } from '~shared/validations/schemas'

const transformEmptyStringFn = (value: string) => (value === '' ? null : value)

const requiredTrimmedString = yup.string().trim().required(VALIDATORS_MSG.required)
export const addDriverFormSchema = yup
  .object({
    name: requiredTrimmedString,
    phoneNumber: yup
      .string()
      .trim()
      .test('mobilePhone', VALIDATORS_MSG.notFullPhoneNumber, fullPhoneNumberValidator)
      .required(VALIDATORS_MSG.required),
    birthDate: yup.date().nullable(),
    driverLicense: yup.mixed().required(VALIDATORS_MSG.requiredUpload),
    licenseNumber: yup.string().trim(),
    licenseIssuedAt: yup.date().nullable(),
    licenseExpiredAt: yup.date().nullable(),
    additionalDetails: yup.string().trim(),
  })
  .required()
