import React, { useEffect, useState } from 'react'

import { FormField, Text } from '~/ui-components'
import { formatPhoneNumber } from '~/utils/formatPhoneNumber'
import { getNumberFromString } from '~/utils/getNumberFromString'
import { Countdown } from '~pages/LoginPage/components/Countdown'
import { InputCode } from '~pages/LoginPage/components/InputCode'

import * as S from './ChangePhoneNumberConfirmationCode.styled'

interface ChangePhoneNumberModalProps {
  mobileNumber: string
  onRetry: () => void
  onSubmit: (smsCode: string) => void
  errorMessage?: string
}

const COUNTDOWN_TIME_SEC = 61
const CODE_LENGTH = 6
const DEFAULT_CODE = Array(CODE_LENGTH).fill('')

export const ChangePhoneNumberConfirmationCode: React.FC<ChangePhoneNumberModalProps> = ({
  onSubmit,
  onRetry,
  mobileNumber,
  errorMessage,
}) => {
  const [code, setCode] = useState<string[]>(DEFAULT_CODE)
  const [error, setError] = useState<string>(errorMessage ?? '')

  useEffect(() => {
    if (errorMessage !== error) {
      setError(errorMessage ?? '')
    }
  }, [errorMessage])

  const submitSmsCode = (smsCode: string) => {
    onSubmit(smsCode)
  }

  const inputCodeChangeHandler = (code: string[]) => {
    setCode(code)
    const codeValue = getNumberFromString(code.join('').trim())
    if (codeValue.length === CODE_LENGTH) {
      submitSmsCode(codeValue)
    }
  }

  const handleRetry = () => {
    setCode(DEFAULT_CODE)
    setError('')
    onRetry()
  }

  return (
    <S.Wrapper>
      <Text variant="normal">
        Код отправлен на номер <b>{formatPhoneNumber(mobileNumber)}</b>
      </Text>
      <FormField validateMessage={error}>
        <InputCode onChange={inputCodeChangeHandler} code={code} />
      </FormField>
      <Countdown seconds={COUNTDOWN_TIME_SEC} onRetry={handleRetry} />
    </S.Wrapper>
  )
}
