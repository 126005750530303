import { CompanyDealDataStatusEnum } from '~api/generated'

export function getDealStatusName(status: string): string {
  switch (status) {
    case CompanyDealDataStatusEnum.OnModeration:
      return 'Проверка предложения'
    case CompanyDealDataStatusEnum.RejectedAfterModeration:
      return 'Отменено модератором'
    case CompanyDealDataStatusEnum.Agreement:
      return 'Согласование договора'
    case CompanyDealDataStatusEnum.Signing:
      return 'Подписание договора'
    case CompanyDealDataStatusEnum.Payment:
      return 'Оплата'
    case CompanyDealDataStatusEnum.Shipment:
      return 'Перевозка'
    case CompanyDealDataStatusEnum.Rejected:
      return 'Отменено'
    case CompanyDealDataStatusEnum.Realised:
      return 'Реализовано'
    default:
      return status ?? ''
  }
}
