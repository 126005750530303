import styled from 'styled-components'

import css from '@styled-system/css'

export const DividerSize = '1px'

export const Divider = styled.div(
  css({
    m: 0,
    border: 0,
    bg: 'Gray300',
    height: DividerSize,
  })
)
