import React from 'react'

import { useGetProductTypeData } from '~/hooks'
import { Badge, Card, UploadButton } from '~/ui-components'
import { formatNumber } from '~/utils/formatNumber'
import { getValueIfExistOrDefault } from '~/utils/getValueIfExistOrDefault'
import { getOfferType } from '~/utils/offers/get-offer-type'
import { BaseOfferData } from '~api/generated'
import { useGetCurrentRole } from '~hooks/auth'
import { QualityIndicators } from '~shared/components'

import * as S from '../../DealInformation.styled'

interface IDealInfoProps {
  offer: BaseOfferData
}

export const DealOfferInfo: React.FC<IDealInfoProps> = ({ offer }) => {
  const {
    user: { isAdmin },
  } = useGetCurrentRole()
  const { getProductTypeParametersForUI_V2 } = useGetProductTypeData()

  const parameters: string[] = getProductTypeParametersForUI_V2(offer.product)

  const cultureName = offer.product.name

  return (
    <Card>
      <S.CardContent>
        <Card.Title>Карточка предложения №{offer.id}</Card.Title>

        {!!parameters.length && (
          <QualityIndicators
            gost={offer.product.gost}
            isLabAnalysis={!!offer.laboratoryAnalysis?.source}
            isParametersCorrespondsToGost={offer.product.parametersIsCorrespondsToGost}
          />
        )}

        <S.Row>
          <Card.Label>Культура</Card.Label>
          <Card.Value>{cultureName}</Card.Value>
        </S.Row>

        {!!parameters.length && (
          <S.Parameters>
            {parameters.map((param) => (
              <Badge key={param} color="gray" isRound>
                {param}
              </Badge>
            ))}
          </S.Parameters>
        )}

        {offer.laboratoryAnalysis && (
          <UploadButton
            fileName="Лаб. анализ"
            isReadOnly
            onFileClick={() => window.open(offer.laboratoryAnalysis?.source, '_blank')}
          />
        )}

        <S.DividerStyled />

        <S.Row>
          <Card.Label>Тип сделки</Card.Label>
          <S.CardValueGreen>{getOfferType(offer.type).name}</S.CardValueGreen>
        </S.Row>

        <S.Row>
          <Card.Label>Объем</Card.Label>
          <Card.Value>{offer.volume} тонн</Card.Value>
        </S.Row>

        <S.Row>
          <Card.Label>{isAdmin ? 'Цена без комиссии' : 'Цена'}</Card.Label>
          <Card.Value>
            {formatNumber(offer.price)} ₽/т {isAdmin && <span>{offer.includingNds ? '(с НДС)' : '(без НДС)'}</span>}
          </Card.Value>
        </S.Row>

        {isAdmin && !!offer?.priceDetail && (
          <>
            <S.Row>
              <Card.Label>Комиссия</Card.Label>
              <Card.Value>{getValueIfExistOrDefault(formatNumber(offer.priceDetail?.commission), '₽/т')}</Card.Value>
            </S.Row>
            <S.Row>
              <Card.Label>Адрес</Card.Label>
              <Card.Value>{getValueIfExistOrDefault(offer.address)}</Card.Value>
            </S.Row>
            <S.Row>
              <Card.Label>Примечание</Card.Label>
              <Card.Value>{getValueIfExistOrDefault(offer.note)}</Card.Value>
            </S.Row>
          </>
        )}
      </S.CardContent>
    </Card>
  )
}
