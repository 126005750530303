import React, { PropsWithChildren } from 'react'

import { Checkbox, CheckboxRef } from 'antd'
import { CheckboxChangeEvent } from 'antd/es/checkbox'

import { CustomControlInterface } from '~shared/controls/CustomControl.interface'

type Value = boolean | undefined

interface IPriceControlProps extends CustomControlInterface {
  onChange: (value: Value) => void
  value: Value
  disabled?: boolean
  hasError?: boolean
  id?: string
}

export const CheckboxControl: React.FC<PropsWithChildren<IPriceControlProps>> = React.forwardRef<
  CheckboxRef,
  PropsWithChildren<IPriceControlProps>
>(({ children, id, value, onChange, hasError, disabled }, ref) => {
  const onChangeHandler = (e: CheckboxChangeEvent) => {
    onChange(e.target.checked)
  }

  return (
    <Checkbox id={id} checked={value} onChange={onChangeHandler} disabled={disabled} ref={ref}>
      {children}
    </Checkbox>
  )
})

CheckboxControl.displayName = 'CheckboxControl'
