import React from 'react'
import { FormField, Text } from '~/ui-components'
import { Controller } from 'react-hook-form'
import { CreateTransportFormFieldProps } from '~pages/CarParkPage/modules/CreateTransport/CreateTransport.types'
import { Flex, Input } from 'antd'

export const CreateAdditionalFields: React.FC<CreateTransportFormFieldProps> = ({ control, errors }) => {
  return (
    <>
      <Text variant="h1">Дополнительно</Text>

      <Flex gap={24}>
        <FormField label="Примечание" htmlFor="additionalDetails" validateMessage={errors.additionalDetails?.message}>
          <Controller
            name="additionalDetails"
            control={control}
            render={({ field }) => (
              <Input.TextArea
                {...field}
                id="additionalDetails"
                placeholder="Введите примечание"
                status={errors.additionalDetails && 'error'}
                autoSize={{ minRows: 3, maxRows: 6 }}
              />
            )}
          />
        </FormField>
      </Flex>
    </>
  )
}
