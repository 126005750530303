import React from 'react'

import { Pagination } from 'antd'
import { format } from 'date-fns'
import { observer } from 'mobx-react-lite'
import { useNavigate } from 'react-router-dom'

import { usePagination, useSorting } from '~/hooks'
import { appRoutes } from '~/router'
import { moderatorUsersFilterStore } from '~/store/filters/moderatorUsersFilterStore'
import { getDateFormat } from '~/utils/getDateFormat'
import { UserApiUserControllerGetUsersRequest, UserDataStatusEnum } from '~api/generated'
import { useGetModeratorUserList } from '~hooks/moderator'
import { useUpdateUserStatus } from '~hooks/moderator/useUpdateUserStatus'
import {
  ModeratorUserListMobile,
  ModeratorUserListTable,
} from '~pages/ModeratorPage/components/ModeratorUserList/components'
import { SearchInput } from '~shared/components/SearchInput'

import { IUsersSortType, UserListTableDataType } from './components/ModeratorUserListTable/ModeratorUserListTable.types'

import * as S from './ModeratorUserList.styled'

export const ModeratorUserList: React.FC = observer(() => {
  const navigate = useNavigate()
  const { orderBy, order, setOrderBy, setOrder } = useSorting()
  const { pageSize, pageSizeOptions, currentPage, setCurrentPage, setPageSize } = usePagination()
  const {
    data,
    refetch: userListRefetch,
    isLoading: isLoadingUser,
    isFetching: isFetchingUser,
  } = useGetModeratorUserList({
    userIds: moderatorUsersFilterStore.idSearchQuery ? [Number(moderatorUsersFilterStore.idSearchQuery)] : undefined,
    phoneNumber: moderatorUsersFilterStore.phoneSearchQuery || undefined,
    companyIds: undefined,
    currentPage,
    pageSize,
    order,
    orderBy: orderBy as UserApiUserControllerGetUsersRequest['orderBy'],
  })
  const updateUserStatus = useUpdateUserStatus()

  const userList = data?.users ?? []
  const pagination = data?.pagination

  const userListTableData: UserListTableDataType[] =
    userList?.map((user) => ({
      id: user.id,
      authenticatedAt: format(new Date(user.authenticatedAt), 'dd.MM.yyyy, HH:mm'),
      phoneNumber: user.phoneNumber,
      name: user?.name ?? '-',
      companyId: user.company?.id,
      companyName: user.company?.name ?? '-',
      role: user.position ?? '-',
      createdBy: user.createdBy ? (user.createdBy.id === user.id ? 'Пользователь' : 'Администратор') : '-',
      createdAt: user.createdAt ? getDateFormat(user.createdAt) : '-',
      userStatus: user.status,
    })) ?? []

  const onUserClickHandler = (record: UserListTableDataType) => {
    navigate(appRoutes.moderatorUsers.url + '/' + record.id)
  }

  const onDeactivateUserHandler = async (userId: number, status: UserDataStatusEnum) => {
    await updateUserStatus.mutateAsync({ userId, updateUserStatusBody: { status } })
    await userListRefetch()
  }

  const onSortingChangeHandler = (value: IUsersSortType) => {
    setOrderBy(value.orderBy)
    setOrder(value.order)
    setCurrentPage(1)
  }

  return (
    <S.Content>
      <S.Filters>
        <SearchInput
          defaultValue={moderatorUsersFilterStore.phoneSearchQuery}
          onSearch={(value) => moderatorUsersFilterStore.setPhoneSearchQuery(value)}
          placeholder="Поиск по телефону"
          type="number"
          pattern="\d*"
        />
        <SearchInput
          defaultValue={moderatorUsersFilterStore.idSearchQuery}
          onSearch={(value) => moderatorUsersFilterStore.setIdSearchQuery(value)}
          placeholder="Поиск по ID"
          type="number"
          pattern="\d*"
        />
      </S.Filters>

      <S.Desktop>
        <ModeratorUserListTable
          tableData={userListTableData}
          loading={isLoadingUser || isFetchingUser}
          onRowClick={onUserClickHandler}
          onDeactivateUser={onDeactivateUserHandler}
          onSortingChange={onSortingChangeHandler}
        />
      </S.Desktop>
      <S.Mobile>
        <ModeratorUserListMobile
          data={userListTableData}
          loading={isLoadingUser || isFetchingUser}
          onItemClick={onUserClickHandler}
          onDeactivateUser={onDeactivateUserHandler}
        />
      </S.Mobile>

      {pagination && (
        <Pagination
          current={pagination?.currentPage}
          total={pagination?.total}
          pageSize={pagination?.pageSize}
          pageSizeOptions={pageSizeOptions}
          onShowSizeChange={(x, size) => setPageSize(size)}
          showSizeChanger
          onChange={setCurrentPage}
        />
      )}
    </S.Content>
  )
})
