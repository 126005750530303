import { OfferDataTypeEnum } from '~api/generated'
import { OfferType } from '~pages/OffersPage/models/Offer'

type BadgeVariant = 'base' | 'yellow' | 'red'

export function getOfferType(type: OfferType): {
  color: BadgeVariant
  name: string
} {
  switch (type) {
    case OfferDataTypeEnum.Buy:
      return {
        color: 'base',
        name: 'Покупка',
      }
    case OfferDataTypeEnum.Sell:
      return {
        color: 'yellow',
        name: 'Продажа',
      }
    default:
      return {
        color: 'red',
        name: 'Ошибка',
      }
  }
}
