import React, { useState } from 'react'

import { Col, Flex, InputNumber, Radio, Row, Space } from 'antd'
import { Controller } from 'react-hook-form'

import { FormField, Text } from '~/ui-components'
import { inputSharedSettings } from '~/utils/inputSharedSettings'
import { getFreightReadinessType } from '~/utils/logistics/get-freight-readiness-type'
import { FreightReadinessType } from '~api/gql-generated/graphql'
import { CheckboxControl, DatePickerControl } from '~shared/controls'

import { CreateLoadFormFieldProps } from '~pages/LogisticsPage/modules/MyCargos/components/CreateLoad/CreateLoad.types'

import * as S from './LoadingDate.styled'

const options = [FreightReadinessType.FromDate, FreightReadinessType.Always].map((option) => ({
  label: getFreightReadinessType(option),
  value: option,
}))

export const LoadingDate: React.FC<CreateLoadFormFieldProps> = ({ control, errors }) => {
  const [isUnloadingDate, setIsUnloadingDate] = useState(false)

  return (
    <Row gutter={24}>
      <Col sm={{ span: 12 }} lg={{ span: 12 }}>
        <Flex vertical gap={24}>
          <Text variant="h1">
            Когда груз готов к погрузке? <S.Required>*</S.Required>
          </Text>

          <Flex vertical gap={8}>
            <FormField validateMessage={errors.readinessType?.message} isRequired>
              <Controller
                name="readinessType"
                control={control}
                render={({ field }) => (
                  <Radio.Group {...field}>
                    <Space size="small">
                      {options.map(({ label, value }) => (
                        <S.RadioItem key={value} value={value}>
                          <Flex align="center" gap={16}>
                            {label}
                          </Flex>
                        </S.RadioItem>
                      ))}
                    </Space>
                  </Radio.Group>
                )}
              />
            </FormField>

            <Flex gap={16}>
              <FormField label="Дата погрузки" htmlFor="startedAt" validateMessage={errors.startedAt?.message}>
                <Controller
                  name="startedAt"
                  control={control}
                  render={({ field }) => (
                    <DatePickerControl worksWith="isoString" id="startedAt" {...field} hasError={!!errors.startedAt} />
                  )}
                />
              </FormField>

              <FormField label="+дней" htmlFor="startDaysCount" validateMessage={errors.startDaysCount?.message}>
                <Controller
                  name="startDaysCount"
                  control={control}
                  render={({ field }) => (
                    <InputNumber
                      {...field}
                      {...inputSharedSettings}
                      style={{ maxWidth: '342px' }}
                      id="startDaysCount"
                      placeholder="0"
                      status={errors.startDaysCount && 'error'}
                      step={1}
                    />
                  )}
                />
              </FormField>
            </Flex>

            <Flex vertical gap={32}>
              <CheckboxControl value={isUnloadingDate} onChange={() => setIsUnloadingDate((prev) => !prev)}>
                Добавить дату приемки
              </CheckboxControl>

              {isUnloadingDate && (
                <Flex gap={16} style={{ width: '70%' }}>
                  <FormField label="Дата приемки" htmlFor="endedAt" validateMessage={errors.endedAt?.message}>
                    <Controller
                      name="endedAt"
                      control={control}
                      render={({ field }) => (
                        <DatePickerControl worksWith="isoString" id="endedAt" {...field} hasError={!!errors.endedAt} />
                      )}
                    />
                  </FormField>
                </Flex>
              )}
            </Flex>
          </Flex>
        </Flex>
      </Col>
    </Row>
  )
}
