import styled from 'styled-components'

import { breakpoints } from '~/tokens'
import { Button } from 'antd'

export const Wrapper = styled.div`
  width: 100%;
  padding: 8px 16px;
  border-radius: 8px;
  border: 2px dashed var(--gray-2-color);
  gap: 16px;
  display: flex;
  align-items: center;

  @media (max-width: ${breakpoints.DESKTOP_S}px) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 4px;
  }
`

export const EmployeeInfoWrapper = styled.div`
  display: flex;
  flex-grow: 1;

  @media (max-width: ${breakpoints.MOBILE_XL}px) {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }
`

export const ActionsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 16px;

  @media (max-width: ${breakpoints.DESKTOP_S}px) {
    width: 100%;
    justify-content: space-between;
    gap: 8px;
  }
`

export const ActionWrapper = styled.div`
  display: flex;
  gap: 16px;

  @media (max-width: ${breakpoints.MOBILE_XL}px) {
    gap: 8px;
  }
`

export const StyledButton = styled(Button)`
  display: flex;
  align-items: center;
  background: var(--gray-2-color);
  color: var(--gray-5-color);
`
