import React, { useEffect, useState } from 'react'

import { Button, Divider, Input, InputNumber, Result, Skeleton, Spin } from 'antd'
import { debounce } from 'lodash'
import { Controller, useForm } from 'react-hook-form'
import { FiAlertCircle } from 'react-icons/fi'

import { yupResolver } from '@hookform/resolvers/yup/dist/yup'

import { AppConfig } from '~/appConfig'
import { useGetProductTypeData } from '~/hooks'
import { DaDataPartySuggestion, FormField, InputDadata, Text, UploadButton } from '~/ui-components'
import { getElevatorAdvertStatus } from '~/utils/elevator-adverts/get-elevator-advert-status'
import { OfferPriceType } from '~api/generated'
import { useGetElevatorAdvertAdmin } from '~hooks/elevator-advert-admin'
import {
  AddressControlDadata,
  ParametersCultureControl,
  PhoneNumberControl,
  PriceControl,
  PriceTypeControl,
  UsingNdsControl,
  VolumeControl,
} from '~shared/controls'
import { ParametersCultureControlValue } from '~shared/controls/ParametersCultureControl'
import { fullPhoneNumberValidator } from '~shared/validations/schemas'

import { createAdvertOfferFormByAdminSchema } from './CreateAdvertOfferFormByAdmin.validation'

import {
  CreateAdvertOfferFormByAdminProps,
  CreateAdvertOfferFormByAdminValues,
} from './CreateAdvertOfferFormByAdmin.types'

import * as S from './CreateAdvertOfferFormByAdmin.styled'
export const CreateAdvertOfferFormByAdmin: React.FC<CreateAdvertOfferFormByAdminProps> = ({
  defaultValues,
  onSubmit,
  formId,
}) => {
  const schema = createAdvertOfferFormByAdminSchema()
  const [isEditParams, setEditParams] = useState(false)
  const { getProductTypeParametersForUI_V2 } = useGetProductTypeData()

  const {
    trigger,
    watch,
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm<CreateAdvertOfferFormByAdminValues>({
    defaultValues: {
      advertId: null,
      pricePerTon: defaultValues?.pricePerTon,
      volume: defaultValues?.volume,
      usingNds: defaultValues?.usingNds ?? false,
      priceType: defaultValues?.priceType,
      culture: defaultValues?.culture,
      companyInfo: {
        name: '',
        inn: '',
        kpp: '',
        ogrn: '',
        codeOkved: '',
        director: '',
        actualAddress: '',
        legalAddress: '',
      },
      contactUser: '',
      contactPhone: '',
    },
    resolver: yupResolver(schema),
  })

  const priceTypeWatch = watch('priceType')
  const advertIdWatch = watch('advertId')
  const contactPhoneWatch = watch('contactPhone')
  const companyInfoWatch = watch('companyInfo')

  useEffect(() => {
    setValue('address', undefined)
  }, [priceTypeWatch])

  useEffect(() => {
    if (fullPhoneNumberValidator(contactPhoneWatch) && companyInfoWatch.inn) {
      void trigger('contactPhone')
    }
  }, [companyInfoWatch.inn, contactPhoneWatch])

  const onSubmitHandler = (formValues: CreateAdvertOfferFormByAdminValues) => {
    onSubmit(formValues)
  }

  const handleDadataSelect = (value?: DaDataPartySuggestion | undefined): void => {
    const data = value?.data

    if (!data) {
      console.log('There is no data for set')
      return
    }

    setValue(
      'companyInfo',
      {
        name: data.name?.short_with_opf ?? value?.value,
        inn: data?.inn,
        kpp: data?.kpp ?? '',
        ogrn: data?.ogrn ?? '',
        codeOkved: data?.okved,
        director: data.type === 'INDIVIDUAL' ? data.name?.full : data.management?.name ?? '',
        legalAddress: data.address?.value,
        actualAddress: data.address?.value,
      },
      { shouldValidate: true }
    )
  }

  const resetCompanyInfo = (name?: string) => {
    setValue('companyInfo', {
      name: name ?? '',
      inn: '',
      kpp: '',
      ogrn: '',
      codeOkved: '',
      director: '',
      legalAddress: '',
      actualAddress: '',
    })
  }
  const isShowAddressControl = priceTypeWatch === OfferPriceType.AtSellersGate
  const getElevatorAdvertAdminFn = useGetElevatorAdvertAdmin({ elevatorAdvertId: advertIdWatch ?? NaN })

  const foundAdvert = getElevatorAdvertAdminFn.data

  useEffect(() => {
    const cultureDefaultControl: ParametersCultureControlValue | undefined =
      foundAdvert && foundAdvert.allowOffersWithOtherParameters
        ? {
            cultureTypeId: foundAdvert.product.type,
            parameters: foundAdvert.product.parameters,
            hasError: false,
          }
        : undefined

    setEditParams(false)
    setValue('culture', cultureDefaultControl)
  }, [foundAdvert?.id])

  const isAdvertFitsForOffer = foundAdvert?.status === 'PUBLISHED' ?? false

  return (
    <form id={formId} onSubmit={handleSubmit(onSubmitHandler)}>
      <FormField
        label="Введите номер объявления"
        validateMessage={getElevatorAdvertAdminFn.isError ? 'Объявление не найдено' : errors.advertId?.message}
        isRequired
      >
        <Controller
          name="advertId"
          control={control}
          render={({ field }) => (
            <Spin spinning={getElevatorAdvertAdminFn.isFetching} size="small">
              <InputNumber
                onChange={debounce((x) => field.onChange(x), AppConfig.DEFAULT_DEBOUNCE_TIME)}
                value={field.value}
                placeholder="100"
                style={{ width: '100%' }}
                status={errors.advertId || getElevatorAdvertAdminFn.isError ? 'error' : undefined}
              />
            </Spin>
          )}
        />
      </FormField>

      <Skeleton loading={getElevatorAdvertAdminFn.isFetching} />

      {foundAdvert && !getElevatorAdvertAdminFn.isFetching ? (
        <S.FoundAdvertInfo>
          <Text variant="h4">{foundAdvert?.company?.name}</Text>
          <Text variant="h4">{foundAdvert?.product.name}</Text>

          {!foundAdvert.allowOffersWithOtherParameters ? (
            <S.ParametersInfo isAllow={false}>
              <FiAlertCircle size="24" />
              <Text variant="normal">Показатели вашей культуры должны совпадать с указанными в объявлении</Text>
            </S.ParametersInfo>
          ) : (
            <FormField label="Показатели культуры" isVisible={!isEditParams}>
              <S.EditCultureParamsWrapper>
                <S.EditCultureParamsText>У вас другие показатели?</S.EditCultureParamsText>
                <div>
                  <Button htmlType="button" onClick={() => setEditParams(true)}>
                    Редактировать
                  </Button>
                </div>
              </S.EditCultureParamsWrapper>
            </FormField>
          )}

          <FormField
            isVisible={!!foundAdvert.allowOffersWithOtherParameters && isEditParams}
            validateMessage={errors.culture ? 'Параметры культуры заполнены с ошибками' : ''}
          >
            <Controller
              name="culture"
              control={control}
              render={({ field }) => <ParametersCultureControl isRequired {...field} isShowOnlyParams />}
            />
          </FormField>

          {(!foundAdvert.allowOffersWithOtherParameters || !isEditParams) &&
            !!foundAdvert.product.parameters.length && (
              <S.Parameters>
                {getProductTypeParametersForUI_V2(foundAdvert.product).map((param) => (
                  <div key={param}>{param}</div>
                ))}
              </S.Parameters>
            )}
        </S.FoundAdvertInfo>
      ) : (
        <S.NotFoundAdvertText>Данные появятся после выбора объявления</S.NotFoundAdvertText>
      )}

      <Divider />

      {isAdvertFitsForOffer ? (
        <>
          <FormField label="Анализная карта" validateMessage={errors.labAnalysis?.message} isRequired>
            <Controller
              control={control}
              name="labAnalysis"
              render={({ field }) => (
                <UploadButton
                  onChange={(evt) => {
                    if (evt.target.files?.length) {
                      field.onChange(evt.target.files[0])
                    }
                  }}
                >
                  Загрузить
                </UploadButton>
              )}
            />
          </FormField>

          <FormField label="Объем" htmlFor="volume" validateMessage={errors.volume?.message} isRequired>
            <Controller
              name="volume"
              control={control}
              render={({ field }) => <VolumeControl {...field} id="volume" hasError={!!errors.volume} />}
            />
          </FormField>

          <S.PriceControlWrapper>
            <FormField
              htmlFor="pricePerTon"
              label="Предлагаемая цена"
              validateMessage={errors.pricePerTon?.message}
              isRequired
            >
              <Controller
                name="pricePerTon"
                control={control}
                render={({ field }) => (
                  <PriceControl id="pricePerTon" {...field} hasError={!!errors.pricePerTon} addAfter="₽/т" />
                )}
              />
            </FormField>

            <FormField htmlFor="usingNds" validateMessage={errors.usingNds?.message} label="&nbsp;">
              <Controller name="usingNds" control={control} render={({ field }) => <UsingNdsControl {...field} />} />
            </FormField>
          </S.PriceControlWrapper>

          <FormField htmlFor="priceType" validateMessage={errors.priceType?.message} label="Тип стоимости" isRequired>
            <Controller name="priceType" control={control} render={({ field }) => <PriceTypeControl {...field} />} />
          </FormField>

          <FormField
            label="Адрес"
            htmlFor="address"
            isRequired
            validateMessage={errors.address?.message}
            isVisible={isShowAddressControl}
          >
            <Controller
              name="address"
              control={control}
              render={({ field }) => (
                <AddressControlDadata
                  id="address"
                  value={undefined}
                  onChange={(value) => field.onChange(value)}
                  hasError={!!errors.address}
                />
              )}
            />
          </FormField>

          <Divider />

          <FormField
            label="Наименование компании или ИНН"
            htmlFor="companyInfo"
            validateMessage={errors.companyInfo?.inn?.message}
            isRequired
          >
            <Controller
              name="companyInfo"
              control={control}
              render={({ field }) => (
                <InputDadata
                  id="name"
                  placeholder="Введите наименование компании"
                  inputValue={field.value.name}
                  onSelect={handleDadataSelect}
                  variant="organizations"
                  inputStatus={errors.companyInfo && 'error'}
                  onChangeInput={resetCompanyInfo}
                />
              )}
            />
          </FormField>

          <FormField
            label="Контактное лицо"
            htmlFor="contactUser"
            validateMessage={errors.contactUser?.message}
            isRequired
          >
            <Controller
              name="contactUser"
              control={control}
              render={({ field }) => (
                <Input id="contactUser" placeholder="Укажите ФИО" {...field} status={errors.contactUser && 'error'} />
              )}
            />
          </FormField>

          <FormField validateMessage={errors.contactPhone?.message} label="Телефон" htmlFor="contactPhone" isRequired>
            <Controller
              name="contactPhone"
              control={control}
              render={({ field }) => (
                <PhoneNumberControl {...field} id="contactPhone" hasError={!!errors.contactPhone} />
              )}
              rules={{
                validate: {
                  contactPhone: () => false,
                },
              }}
            />
          </FormField>
        </>
      ) : (
        foundAdvert && (
          <Result
            status="warning"
            title="Невозможно предложить объем"
            extra={`Предложение в статусе "${getElevatorAdvertStatus(foundAdvert.status).name}"`}
          />
        )
      )}
    </form>
  )
}
