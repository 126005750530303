import React from 'react'

import { Flex, Typography } from 'antd'

import { AppConfig } from '~/appConfig'
import { PhoneNumber } from '~shared/components'

export const NoAccessToUserCompany: React.FC = () => {
  return (
    <Flex vertical gap="middle">
      <Typography.Text>Ваш доступ к компании обновлён</Typography.Text>

      <Typography.Text>По дополнительным вопросам обратитесь к администраторам платформы:</Typography.Text>
      <PhoneNumber prefix="" phoneNumber={AppConfig.ADMIN_CONTACT_PHONE} />
    </Flex>
  )
}
