import React, { FC, useState } from 'react'

import { Button } from 'antd'

import { Badge } from '~/ui-components'
import { getOfferStatusForAuction } from '~/utils/auctions/get-offer-status-for-auction'
import { formatNumber } from '~/utils/formatNumber'
import { getNdsString } from '~/utils/getNdsString'
import { AuctionOfferStatus, BaseOfferDataTypeEnum, UpdateAuctionOfferStatusBodyStatusEnum } from '~api/generated'
import { useGetCurrentRole } from '~hooks/auth'
import { ActionOfferMenu } from '~pages/Auctions/components'

import { AuctionMobileCardProps } from './types'

import * as S from './AuctionOfferMobileCard.styled'

export const AuctionOfferMobileCard: FC<AuctionMobileCardProps> = ({
  offerId,
  offerNumber,
  offerType,
  isShowStatus,
  isOwn,
  offerStatus,
  price,
  includingNds,
  volume,
  companyName,
  location,
  datetime,
  onClick,
  contactUserName,
  contactUserPhone,
  offerParameters,
  productTypeId,
  onClickDealButton,
  onUpdate,
  onChangeStatus,
  nextStatuses,
}) => {
  const {
    user: { isAdmin },
  } = useGetCurrentRole()
  const [isShownParams, setShownParams] = useState(false)
  const offerStatusStatusConfig = isShowStatus ? getOfferStatusForAuction(offerStatus) : null

  const isRealisedStatus = nextStatuses?.includes(UpdateAuctionOfferStatusBodyStatusEnum.Rejected)
  const isRejectedStatus = nextStatuses?.includes(UpdateAuctionOfferStatusBodyStatusEnum.Rejected)

  return (
    <S.Card onClick={onClick}>
      <S.RowVertical>
        <S.Label>Участник</S.Label>
        <S.Value>{isOwn ? <S.Own>Вы</S.Own> : offerNumber}</S.Value>
      </S.RowVertical>

      {isShowStatus && offerStatusStatusConfig && (
        <S.RowVertical>
          <S.Label>Статус ставки</S.Label>
          <Badge color={offerStatusStatusConfig.color} size="s">
            {offerStatusStatusConfig.name}
          </Badge>
        </S.RowVertical>
      )}

      <S.Row>
        <S.Label>Объем, т</S.Label>
        <S.Value>{formatNumber(volume)}</S.Value>
      </S.Row>

      <S.Row>
        <S.Label>Ставка, ₽/т</S.Label>
        <S.ValueBold>
          {formatNumber(price)} ({getNdsString(includingNds)})
        </S.ValueBold>
      </S.Row>

      {companyName && (
        <S.Row>
          <S.Label>Компания</S.Label>
          <S.Value>{companyName}</S.Value>
        </S.Row>
      )}

      {contactUserName && contactUserPhone && (
        <S.Row>
          <S.Label>Контактное лицо</S.Label>
          <S.Value>{contactUserName}</S.Value>
          <S.Value>{contactUserPhone}</S.Value>
        </S.Row>
      )}

      {location && (
        <S.Row>
          <S.Label>Локация</S.Label>
          <S.Value>{location}</S.Value>
        </S.Row>
      )}

      {datetime && (
        <S.Row>
          <S.Label>Время ставки</S.Label>
          <S.Value>{datetime}</S.Value>
        </S.Row>
      )}

      {isShownParams && offerParameters?.length && (
        <S.Parameters>
          {offerParameters.map((param) => (
            <Badge color="gray" isRound key={param.type}>
              {param.name}
            </Badge>
          ))}
        </S.Parameters>
      )}

      {offerType === BaseOfferDataTypeEnum.Sell && (
        <Button type="link" onClick={() => setShownParams((prev) => !prev)}>
          {isShownParams ? 'Скрыть' : 'Параметры культуры'}
        </Button>
      )}

      {onClickDealButton && <Button onClick={onClickDealButton}>Перейти к сделке</Button>}

      {offerStatus === AuctionOfferStatus.Published && (isOwn || isAdmin) && (
        <S.Action>
          <ActionOfferMenu auctionOfferId={offerId} onCancelOffer={onUpdate} />
        </S.Action>
      )}

      {isRealisedStatus && isRejectedStatus && (
        <S.ButtonsWrapper>
          {isRejectedStatus && (
            <Button onClick={(evt) => onChangeStatus?.(evt, UpdateAuctionOfferStatusBodyStatusEnum.Rejected)}>
              Отклонить
            </Button>
          )}
          {isRealisedStatus && (
            <Button
              onClick={(evt) => onChangeStatus?.(evt, UpdateAuctionOfferStatusBodyStatusEnum.Realised)}
              type="primary"
            >
              Принять
            </Button>
          )}
        </S.ButtonsWrapper>
      )}
    </S.Card>
  )
}
