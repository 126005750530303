import React, { FC } from 'react'

import { Button } from 'antd'
import { FiRotateCw } from 'react-icons/fi'

import { AuctionsHeaderDetailsProps } from './AuctionsHeaderDetails.types'

import * as S from './AuctionsHeaderDetails.styled'

export const AuctionsHeaderDetails: FC<AuctionsHeaderDetailsProps> = ({ onUpdateData }) => {
  return (
    <S.Header>
      <Button type="link" onClick={onUpdateData} icon={<FiRotateCw />}>
        Обновить данные
      </Button>
    </S.Header>
  )
}
