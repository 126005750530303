import { OwnershipType } from '~api/gql-generated/graphql'
import { codeUnreachable } from '~/utils/code-unreachable'

export const getOwnershipTypeTooltip = (ownershipType: OwnershipType) => {
  switch (ownershipType) {
    case OwnershipType.Own:
      return 'ТС в собственности у компании'
    case OwnershipType.Rent:
      return 'Данное ТС находится у компании по договору аренды'
    case OwnershipType.Leasing:
      return 'Данное ТС находится у компании по договору лизинга'
    default:
      return codeUnreachable(ownershipType)
  }
}
