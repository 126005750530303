import React, { useState } from 'react'

import { Button, Empty, Spin } from 'antd'
import { useNavigate, useParams } from 'react-router-dom'

import { AppHeader } from '~/layout'
import { appRoutes } from '~/router'
import { Text } from '~/ui-components'
import { useGetElevator, useGetElevatorProductTypes } from '~hooks/elevators'
import { BasisAdvertsByProductType } from '~pages/BasisPage/modules/BasisAdvert/BasisAdvertsByProductType/BasisAdvertsByProductType'
import { CultureSelectControl } from '~shared/controls'

import * as S from './BasisCompaniesAdverts.styled'

export const BasisCompaniesAdverts: React.FC = () => {
  const { id } = useParams<{ id: string | undefined }>()
  const basisId = Number(id)
  const navigate = useNavigate()

  const [selectedProductTypes, setSelectedProductTypes] = useState<string[]>([])
  const [companyNameQuery, setCompanyNameQuery] = useState<string>('')

  const getElevatorFn = useGetElevator({ id: basisId })
  const getElevatorProductTypesFn = useGetElevatorProductTypes({ id: basisId })

  const basis = getElevatorFn?.data
  const productTypes = getElevatorProductTypesFn.data?.elevatorProductTypes.filter((x) =>
    selectedProductTypes.length ? selectedProductTypes.includes(x.type) : true
  )

  const handleNavigateToBasisInfo = () => {
    navigate(appRoutes.basisInfo.url.replace(':id', basis?.id.toString() ?? ''))
  }

  const onItemClickHandler = (advertId: number) => {
    navigate(appRoutes.basisAdvert.url.replace(':id', advertId.toString()))
  }

  return (
    <>
      <AppHeader title={appRoutes.basisAdverts.title} isBack />

      <Spin spinning={getElevatorProductTypesFn.isFetching}>
        <S.ContentWrapper>
          <div>
            <Text variant="h3">{basis?.name ?? ''}</Text>
            <Button type="link" onClick={handleNavigateToBasisInfo}>
              Подробнее о базисе
            </Button>
          </div>
          <S.CompanyLIstWrapper>
            <S.FiltersWrapper>
              {/*<SearchInput*/}
              {/*  defaultValue={companyNameQuery}*/}
              {/*  onSearch={(v) => setCompanyNameQuery(v)}*/}
              {/*  autoFocus={false}*/}
              {/*  placeholder="Поиск компании"*/}
              {/*/>*/}
              <CultureSelectControl value={selectedProductTypes} onChange={(x) => setSelectedProductTypes(x)} />
            </S.FiltersWrapper>

            {!getElevatorProductTypesFn.isLoading && !productTypes?.length && (
              <Empty description="Культуры на базисе отсутстуют" />
            )}

            {productTypes?.map((productType) => (
              <BasisAdvertsByProductType
                key={productType.id}
                productTypeData={productType}
                elevatorId={basisId}
                onClick={onItemClickHandler}
              />
            ))}
          </S.CompanyLIstWrapper>
        </S.ContentWrapper>
      </Spin>
    </>
  )
}
