import styled from 'styled-components'

import { breakpoints } from '~/tokens'
import { Text } from '~/ui-components'

export const LIstWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`

export const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 8px;
  align-items: center;
  margin-bottom: 16px;
`
export const TitleWrapperInner = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`

export const CountWrapper = styled.div`
  background: #e3e3e3;
  border-radius: 8px;
  padding: 2px 6px;
`

export const Parameters = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 8px;
  max-width: 500px;
  color: var(--badge-gray-bg-color);
  @media (max-width: ${breakpoints.MOBILE_XL}px) {
    grid-template-columns: 1fr;
    grid-row-gap: 4px;
  }
`
export const CardFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
`
export const CardHeaderTop = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
`
export const PublishedAt = styled(Text).attrs({ variant: 'description' })`
  color: var(--gray-4-color);
  display: inline;
`
