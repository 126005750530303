import React from 'react'

import { Button, Dropdown, Modal } from 'antd'
import { ItemType } from 'antd/es/menu/interface'
import { FiMoreVertical, FiTrash2 } from 'react-icons/fi'

import { appToast } from '~/utils'
import { UpdateAuctionStatusBodyStatusEnum } from '~api/generated'
import { useUpdateAuctionStatus } from '~hooks/auctions'

interface IOfferActionMenuProps {
  auctionId: number
  onCancelAuction?: () => void
}

export const ActionContextMenu: React.FC<IOfferActionMenuProps> = ({ auctionId, onCancelAuction }) => {
  const updateAuctionStatus = useUpdateAuctionStatus()
  const handleUnpublishConfirm = () => {
    Modal.confirm({
      title: 'Вы действительно хотите отменить лот?',
      content: (
        <>
          Лот <b>№{auctionId}</b> будет отменен
        </>
      ),
      okText: 'Да',
      cancelText: 'Отмена',
      async onOk() {
        await handleUnpublish()
      },
    })
  }
  const handleUnpublish = async () => {
    try {
      await updateAuctionStatus.mutateAsync({
        auctionId,
        updateAuctionStatusBody: {
          status: UpdateAuctionStatusBodyStatusEnum.Rejected,
        },
      })
      await onCancelAuction?.()
      appToast.success({ description: 'Лот отменен' })
    } catch (e) {
      const description = 'Ошибка отмены лота'
      console.error(description, e)
      appToast.error({ description })
    }
  }

  const menuItems: ItemType[] = [
    {
      label: 'Отменить лот',
      key: 'unpublish',
      icon: <FiTrash2 />,
      onClick: handleUnpublishConfirm,
    },
  ]

  return (
    <>
      <Dropdown menu={{ items: menuItems }} trigger={['click']}>
        <Button size="small" type="text" icon={<FiMoreVertical size={24} />} onClick={(evt) => evt.stopPropagation()} />
      </Dropdown>
    </>
  )
}
