import React from 'react'

import { Button, Divider, Modal } from 'antd'
import { FiEdit2 } from 'react-icons/fi'
import { Link } from 'react-router-dom'

import { appRoutes } from '~/router'
import { Card } from '~/ui-components'
import { formatPhoneNumber } from '~/utils/formatPhoneNumber'
import { getDateFormat } from '~/utils/getDateFormat'
import { getUserActivityStatus } from '~/utils/user/getUserActivityStatus'
import { getUserRoleOld } from '~/utils/user/getUserRoleOld'
import { useGetCurrentRole } from '~hooks/auth'
import { useAuth } from '~providers/auth'

import { IPersonalProfileViewProps } from './PersonalProfileView.types'

import * as S from './PersonalProfileView.styled'

export const PersonalProfileView: React.FC<IPersonalProfileViewProps> = ({ onChange, user, onAdminLoginAsUser }) => {
  const { user: currentLoggedUser } = useAuth()

  const currentUserId = currentLoggedUser?.id

  const {
    user: { isAdmin },
  } = useGetCurrentRole()

  const fullName = user?.name ?? ''

  const onAdminLoginAsUserHandler = () => {
    if (isAdmin) {
      onAdminLoginAsUser?.(user.id)
    }
  }

  const onAdminLoginAsUserHandlerConfirm = () => {
    Modal.confirm({
      title: 'Вы действительно войти в приложение от лица другого пользователя?',
      content:
        'Текущая сессия работы с приложением будет завершена и вы сможете начать работу от лица выбранного пользователя',
      okText: 'Да',
      cancelText: 'Отмена',
      onOk() {
        onAdminLoginAsUserHandler()
      },
    })
  }

  return (
    <Card>
      <Card.Header>
        <Card.Title>Личные данные</Card.Title>
        <Card.ActionsContent>
          <Button type="text" icon={<FiEdit2 size={24} />} onClick={onChange} />
        </Card.ActionsContent>
      </Card.Header>

      <S.FullName>{fullName}</S.FullName>

      <Card.Content>
        <S.ContentWrapper>
          <S.Label>Номер телефона</S.Label>
          <Card.Value>{formatPhoneNumber(user.phoneNumber)}</Card.Value>
        </S.ContentWrapper>

        <S.ContentWrapper>
          <S.Label>Должность</S.Label>
          <Card.Value>{user.position ?? '-'}</Card.Value>
        </S.ContentWrapper>

        <S.ContentWrapper>
          <S.Label>Электронная почта</S.Label>
          <Card.Value>{user.email ?? '-'}</Card.Value>
        </S.ContentWrapper>

        {isAdmin && (
          <>
            <Divider />

            <Card.Header>
              <Card.Title>Служебные данные</Card.Title>
            </Card.Header>

            <S.ContentWrapper>
              <S.Label>Идентификатор</S.Label>
              <Card.Value>{user.id}</Card.Value>
            </S.ContentWrapper>

            <S.ContentWrapper>
              <S.Label>Роль</S.Label>
              <Card.Value>{getUserRoleOld(user.role)}</Card.Value>
            </S.ContentWrapper>

            <S.ContentWrapper>
              <S.Label>Статус</S.Label>
              <Card.Value>{getUserActivityStatus(user.status)}</Card.Value>
            </S.ContentWrapper>

            {isAdmin && user.createdBy && (
              <>
                <S.ContentWrapper>
                  <S.Label>Создал пользователя</S.Label>

                  {user.createdBy.id === user.id ? (
                    <>
                      <Card.Value>Пользователь ({user.createdBy.id})</Card.Value>
                    </>
                  ) : (
                    <>
                      <Card.Value>
                        <>
                          {getUserRoleOld(user.createdBy.role)}:
                          <br />
                          <span>{user.createdBy?.name}</span>
                          <br />
                          ID:
                          <Button type="link">
                            <Link to={appRoutes.moderatorUserDetails.url.replace(':id', user.createdBy.id.toString())}>
                              {user.createdBy.id}
                            </Link>
                          </Button>
                        </>
                      </Card.Value>
                    </>
                  )}
                </S.ContentWrapper>

                <S.ContentWrapper>
                  <S.Label>Дата регистрации</S.Label>
                  <Card.Value>{user?.createdAt ? getDateFormat(user.createdAt) : '-'}</Card.Value>
                </S.ContentWrapper>
              </>
            )}

            {currentUserId !== user.id && (
              <div>
                <Button type="primary" onClick={onAdminLoginAsUserHandlerConfirm}>
                  Войти от лица пользователя
                </Button>
              </div>
            )}
          </>
        )}
      </Card.Content>
    </Card>
  )
}
