import React from 'react'

import { RouteObject } from 'react-router-dom'

import { appRoutes } from '~/router'
import { ProfilePage } from '~pages/ProfilePage'
import { DocumentsProfileTab } from '~pages/ProfilePage/components'

import { RequireAuth } from '../../providers/auth'

export const profileRouter: RouteObject = {
  path: appRoutes.profile.url,
  element: (
    <RequireAuth>
      <ProfilePage />
    </RequireAuth>
  ),
  children: [
    {
      path: appRoutes.profileDocuments.url,
      element: <DocumentsProfileTab />,
    },
  ],
}
