import { useMutation } from '@tanstack/react-query'

import { UpdateCompanyBody } from '~/api/generated'
import { useApi } from '~providers/api'

export const useUpdateCompany = (companyId: number) => {
  const api = useApi()

  return useMutation((updateCompanyBody: UpdateCompanyBody) => {
    return api.companies.companyControllerUpdateCompany({
      companyId,
      updateCompanyBody,
    })
  })
}
