import { OfferPriceType } from '~api/generated'

export function getElevatorPriceTypeName(v: OfferPriceType): string {
  switch (v) {
    case OfferPriceType.Census:
      return 'Перепись'
    case OfferPriceType.AtBuyersGate:
      return 'На воротах покупателя'
    case OfferPriceType.AtSellersGate:
      return 'На воротах продавца'
  }
}
