import styled from 'styled-components'

import { css } from '@styled-system/css'

export const Wrapper = styled('div')(
  css({
    display: 'grid',
    gridTemplateColumns: ['1fr', '1fr', 'max-content 1fr'],
    gap: ['4px', '4px', '16px'],
    alignItems: 'center',
    width: '100%',
  })
)
