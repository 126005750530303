/* tslint:disable */
/* eslint-disable */
/**
 * API Зерно-Агрегатора
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CompanyVerificationStatus } from '../models';
// @ts-ignore
import { CreateBankAccountBody } from '../models';
// @ts-ignore
import { CreateBankAccountResponse } from '../models';
// @ts-ignore
import { CreateCompanyBody } from '../models';
// @ts-ignore
import { CreateCompanyResponse } from '../models';
// @ts-ignore
import { CreateEdmOperatorBody } from '../models';
// @ts-ignore
import { GetBankAccountsResponse } from '../models';
// @ts-ignore
import { GetCompaniesResponse } from '../models';
// @ts-ignore
import { GetCompanyZernoAgregatorResponse } from '../models';
// @ts-ignore
import { GetDocumentsArchiveUrlResponse } from '../models';
// @ts-ignore
import { GetDocumentsResponse } from '../models';
// @ts-ignore
import { GetEdmOperatorResponse } from '../models';
// @ts-ignore
import { OrderDirection } from '../models';
// @ts-ignore
import { UpdateBankAccountBody } from '../models';
// @ts-ignore
import { UpdateCompanyBody } from '../models';
// @ts-ignore
import { UpdateCompanyResponse } from '../models';
// @ts-ignore
import { UpdateCompanyStatusBody } from '../models';
// @ts-ignore
import { UpdateCompanyTypeBody } from '../models';
// @ts-ignore
import { UpdateDocumentBody } from '../models';
// @ts-ignore
import { UpdateDocumentStatusBody } from '../models';
// @ts-ignore
import { UpdateDocumentsArchiveBody } from '../models';
// @ts-ignore
import { UpdateEdmOperatorBody } from '../models';
/**
 * CompanyApi - axios parameter creator
 * @export
 */
export const CompanyApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} companyId 
         * @param {CreateBankAccountBody} createBankAccountBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bankAccountControllerCreate: async (companyId: number, createBankAccountBody: CreateBankAccountBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('bankAccountControllerCreate', 'companyId', companyId)
            // verify required parameter 'createBankAccountBody' is not null or undefined
            assertParamExists('bankAccountControllerCreate', 'createBankAccountBody', createBankAccountBody)
            const localVarPath = `/company/{companyId}/bank-account`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createBankAccountBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} bankAccountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bankAccountControllerDeleteBankAccount: async (bankAccountId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'bankAccountId' is not null or undefined
            assertParamExists('bankAccountControllerDeleteBankAccount', 'bankAccountId', bankAccountId)
            const localVarPath = `/bank-account/{bankAccountId}`
                .replace(`{${"bankAccountId"}}`, encodeURIComponent(String(bankAccountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bankAccountControllerFindAllByCompanyId: async (companyId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('bankAccountControllerFindAllByCompanyId', 'companyId', companyId)
            const localVarPath = `/company/{companyId}/bank-account`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} bankAccountId 
         * @param {UpdateBankAccountBody} updateBankAccountBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bankAccountControllerUpdateBankAccounts: async (bankAccountId: number, updateBankAccountBody: UpdateBankAccountBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'bankAccountId' is not null or undefined
            assertParamExists('bankAccountControllerUpdateBankAccounts', 'bankAccountId', bankAccountId)
            // verify required parameter 'updateBankAccountBody' is not null or undefined
            assertParamExists('bankAccountControllerUpdateBankAccounts', 'updateBankAccountBody', updateBankAccountBody)
            const localVarPath = `/bank-account/{bankAccountId}`
                .replace(`{${"bankAccountId"}}`, encodeURIComponent(String(bankAccountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateBankAccountBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Создать компанию
         * @param {CreateCompanyBody} createCompanyBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyControllerCreateCompany: async (createCompanyBody: CreateCompanyBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createCompanyBody' is not null or undefined
            assertParamExists('companyControllerCreateCompany', 'createCompanyBody', createCompanyBody)
            const localVarPath = `/company`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createCompanyBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получить список компаний
         * @param {number} [currentPage] Номер текущей страницы
         * @param {number} [pageSize] Количество элементов на странице
         * @param {Array<number>} [ids] Список id компаний
         * @param {Array<string>} [inns] Список ИНН компаний
         * @param {string} [companyName] Название компании
         * @param {'SELLER' | 'BUYER' | 'TRADER' | 'CARRIER' | 'DEMO_COMPANY'} [type] Тип компании
         * @param {CompanyVerificationStatus} [status] Статус заявки
         * @param {'id' | 'name' | 'type' | 'inn' | 'kpp' | 'codeOkved' | 'legalAddress' | 'director' | 'verificationStatus' | 'createdAt' | 'updatedAt'} [orderBy] Поле для сортировки
         * @param {OrderDirection} [order] Направление сортировки
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyControllerGetCompanies: async (currentPage?: number, pageSize?: number, ids?: Array<number>, inns?: Array<string>, companyName?: string, type?: 'SELLER' | 'BUYER' | 'TRADER' | 'CARRIER' | 'DEMO_COMPANY', status?: CompanyVerificationStatus, orderBy?: 'id' | 'name' | 'type' | 'inn' | 'kpp' | 'codeOkved' | 'legalAddress' | 'director' | 'verificationStatus' | 'createdAt' | 'updatedAt', order?: OrderDirection, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/companies`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (currentPage !== undefined) {
                localVarQueryParameter['current-page'] = currentPage;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page-size'] = pageSize;
            }

            if (ids) {
                localVarQueryParameter['ids'] = ids;
            }

            if (inns) {
                localVarQueryParameter['inns'] = inns;
            }

            if (companyName !== undefined) {
                localVarQueryParameter['companyName'] = companyName;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['order-by'] = orderBy;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получить данные компании-владельца площадки (Зерно-Агрегатор)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyControllerGetCompanyZernoAgregator: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/company/zerno-agregator`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Создать компанию
         * @param {number} companyId 
         * @param {UpdateCompanyBody} updateCompanyBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyControllerUpdateCompany: async (companyId: number, updateCompanyBody: UpdateCompanyBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('companyControllerUpdateCompany', 'companyId', companyId)
            // verify required parameter 'updateCompanyBody' is not null or undefined
            assertParamExists('companyControllerUpdateCompany', 'updateCompanyBody', updateCompanyBody)
            const localVarPath = `/company/{companyId}`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateCompanyBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Обновить статус компании
         * @param {number} companyId 
         * @param {UpdateCompanyStatusBody} updateCompanyStatusBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyControllerUpdateCompanyStatus: async (companyId: number, updateCompanyStatusBody: UpdateCompanyStatusBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('companyControllerUpdateCompanyStatus', 'companyId', companyId)
            // verify required parameter 'updateCompanyStatusBody' is not null or undefined
            assertParamExists('companyControllerUpdateCompanyStatus', 'updateCompanyStatusBody', updateCompanyStatusBody)
            const localVarPath = `/company/{companyId}/status`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateCompanyStatusBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Изменить тип компании
         * @param {number} companyId 
         * @param {UpdateCompanyTypeBody} updateCompanyTypeBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyControllerUpdateCompanyType: async (companyId: number, updateCompanyTypeBody: UpdateCompanyTypeBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('companyControllerUpdateCompanyType', 'companyId', companyId)
            // verify required parameter 'updateCompanyTypeBody' is not null or undefined
            assertParamExists('companyControllerUpdateCompanyType', 'updateCompanyTypeBody', updateCompanyTypeBody)
            const localVarPath = `/company/{companyId}/type`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateCompanyTypeBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyDocumentControllerGetDocuments: async (companyId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('companyDocumentControllerGetDocuments', 'companyId', companyId)
            const localVarPath = `/company/{companyId}/documents`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyDocumentControllerGetDocumentsArchiveUrl: async (companyId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('companyDocumentControllerGetDocumentsArchiveUrl', 'companyId', companyId)
            const localVarPath = `/company/{companyId}/documents-archive`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} companyId 
         * @param {'full-company-info-card' | 'inn' | 'ogrn' | 'company-regulation' | 'company-foundation-resolution' | 'director-appointment-resolution' | 'director-appointment-order' | 'executive-agency-passport' | 'accountant-appointment-order' | 'egrul-statement' | 'ogrnip' | 'individual-entrepreneur-passport' | 'payment-card-confirmation' | 'counterparty-authority-confirmation' | 'legal-address-confirmation' | 'last-year-financial-statement' | 'last-year-income-statement' | 'last-year-statistic-report-form-4' | 'last-year-statistic-report-form-29' | 'farmland-property-confirmation' | 'special-tax-mode-notification' | 'nds-declaration' | 'agriculture-tax-declaration' | 'income-tax-declaration' | 'ndfl3-declaration'} documentType Тип документа
         * @param {UpdateDocumentStatusBody} updateDocumentStatusBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyDocumentControllerUpdateDocumentStatus: async (companyId: number, documentType: 'full-company-info-card' | 'inn' | 'ogrn' | 'company-regulation' | 'company-foundation-resolution' | 'director-appointment-resolution' | 'director-appointment-order' | 'executive-agency-passport' | 'accountant-appointment-order' | 'egrul-statement' | 'ogrnip' | 'individual-entrepreneur-passport' | 'payment-card-confirmation' | 'counterparty-authority-confirmation' | 'legal-address-confirmation' | 'last-year-financial-statement' | 'last-year-income-statement' | 'last-year-statistic-report-form-4' | 'last-year-statistic-report-form-29' | 'farmland-property-confirmation' | 'special-tax-mode-notification' | 'nds-declaration' | 'agriculture-tax-declaration' | 'income-tax-declaration' | 'ndfl3-declaration', updateDocumentStatusBody: UpdateDocumentStatusBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('companyDocumentControllerUpdateDocumentStatus', 'companyId', companyId)
            // verify required parameter 'documentType' is not null or undefined
            assertParamExists('companyDocumentControllerUpdateDocumentStatus', 'documentType', documentType)
            // verify required parameter 'updateDocumentStatusBody' is not null or undefined
            assertParamExists('companyDocumentControllerUpdateDocumentStatus', 'updateDocumentStatusBody', updateDocumentStatusBody)
            const localVarPath = `/company/{companyId}/document/{documentType}/status`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"documentType"}}`, encodeURIComponent(String(documentType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateDocumentStatusBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} companyId 
         * @param {CreateEdmOperatorBody} createEdmOperatorBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        edmOperatorControllerCreateEdmOperator: async (companyId: number, createEdmOperatorBody: CreateEdmOperatorBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('edmOperatorControllerCreateEdmOperator', 'companyId', companyId)
            // verify required parameter 'createEdmOperatorBody' is not null or undefined
            assertParamExists('edmOperatorControllerCreateEdmOperator', 'createEdmOperatorBody', createEdmOperatorBody)
            const localVarPath = `/company/{companyId}/edm-operator`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createEdmOperatorBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        edmOperatorControllerDeleteEdmOperator: async (companyId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('edmOperatorControllerDeleteEdmOperator', 'companyId', companyId)
            const localVarPath = `/company/{companyId}/edm-operator`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        edmOperatorControllerGetEdmOperator: async (companyId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('edmOperatorControllerGetEdmOperator', 'companyId', companyId)
            const localVarPath = `/company/{companyId}/edm-operator`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} companyId 
         * @param {UpdateEdmOperatorBody} updateEdmOperatorBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        edmOperatorControllerUpdateEdmOperator: async (companyId: number, updateEdmOperatorBody: UpdateEdmOperatorBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('edmOperatorControllerUpdateEdmOperator', 'companyId', companyId)
            // verify required parameter 'updateEdmOperatorBody' is not null or undefined
            assertParamExists('edmOperatorControllerUpdateEdmOperator', 'updateEdmOperatorBody', updateEdmOperatorBody)
            const localVarPath = `/company/{companyId}/edm-operator`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateEdmOperatorBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * На текущий момент шаблон только один \"Справка по проценту сельхоз продукции\" (`last_year_income_statement`), поэтому метод не содержит параметров
         * @summary Скачать шаблон документа
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStaticFile: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/company/document-template`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Обновить документ компании
         * @param {number} companyId 
         * @param {'full-company-info-card' | 'inn' | 'ogrn' | 'company-regulation' | 'company-foundation-resolution' | 'director-appointment-resolution' | 'director-appointment-order' | 'executive-agency-passport' | 'accountant-appointment-order' | 'egrul-statement' | 'ogrnip' | 'individual-entrepreneur-passport' | 'payment-card-confirmation' | 'counterparty-authority-confirmation' | 'legal-address-confirmation' | 'last-year-financial-statement' | 'last-year-income-statement' | 'last-year-statistic-report-form-4' | 'last-year-statistic-report-form-29' | 'farmland-property-confirmation' | 'special-tax-mode-notification' | 'nds-declaration' | 'agriculture-tax-declaration' | 'income-tax-declaration' | 'ndfl3-declaration'} documentType Тип документа
         * @param {UpdateDocumentBody} updateDocumentBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDocument: async (companyId: number, documentType: 'full-company-info-card' | 'inn' | 'ogrn' | 'company-regulation' | 'company-foundation-resolution' | 'director-appointment-resolution' | 'director-appointment-order' | 'executive-agency-passport' | 'accountant-appointment-order' | 'egrul-statement' | 'ogrnip' | 'individual-entrepreneur-passport' | 'payment-card-confirmation' | 'counterparty-authority-confirmation' | 'legal-address-confirmation' | 'last-year-financial-statement' | 'last-year-income-statement' | 'last-year-statistic-report-form-4' | 'last-year-statistic-report-form-29' | 'farmland-property-confirmation' | 'special-tax-mode-notification' | 'nds-declaration' | 'agriculture-tax-declaration' | 'income-tax-declaration' | 'ndfl3-declaration', updateDocumentBody: UpdateDocumentBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('updateDocument', 'companyId', companyId)
            // verify required parameter 'documentType' is not null or undefined
            assertParamExists('updateDocument', 'documentType', documentType)
            // verify required parameter 'updateDocumentBody' is not null or undefined
            assertParamExists('updateDocument', 'updateDocumentBody', updateDocumentBody)
            const localVarPath = `/company/{companyId}/document/{documentType}`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"documentType"}}`, encodeURIComponent(String(documentType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateDocumentBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Обновить архив с документами
         * @param {number} companyId 
         * @param {UpdateDocumentsArchiveBody} updateDocumentsArchiveBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDocumentArchive: async (companyId: number, updateDocumentsArchiveBody: UpdateDocumentsArchiveBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('updateDocumentArchive', 'companyId', companyId)
            // verify required parameter 'updateDocumentsArchiveBody' is not null or undefined
            assertParamExists('updateDocumentArchive', 'updateDocumentsArchiveBody', updateDocumentsArchiveBody)
            const localVarPath = `/company/{companyId}/documents-archive`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateDocumentsArchiveBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CompanyApi - functional programming interface
 * @export
 */
export const CompanyApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CompanyApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} companyId 
         * @param {CreateBankAccountBody} createBankAccountBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bankAccountControllerCreate(companyId: number, createBankAccountBody: CreateBankAccountBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateBankAccountResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bankAccountControllerCreate(companyId, createBankAccountBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} bankAccountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bankAccountControllerDeleteBankAccount(bankAccountId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bankAccountControllerDeleteBankAccount(bankAccountId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bankAccountControllerFindAllByCompanyId(companyId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetBankAccountsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bankAccountControllerFindAllByCompanyId(companyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} bankAccountId 
         * @param {UpdateBankAccountBody} updateBankAccountBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bankAccountControllerUpdateBankAccounts(bankAccountId: number, updateBankAccountBody: UpdateBankAccountBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bankAccountControllerUpdateBankAccounts(bankAccountId, updateBankAccountBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Создать компанию
         * @param {CreateCompanyBody} createCompanyBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async companyControllerCreateCompany(createCompanyBody: CreateCompanyBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateCompanyResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.companyControllerCreateCompany(createCompanyBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Получить список компаний
         * @param {number} [currentPage] Номер текущей страницы
         * @param {number} [pageSize] Количество элементов на странице
         * @param {Array<number>} [ids] Список id компаний
         * @param {Array<string>} [inns] Список ИНН компаний
         * @param {string} [companyName] Название компании
         * @param {'SELLER' | 'BUYER' | 'TRADER' | 'CARRIER' | 'DEMO_COMPANY'} [type] Тип компании
         * @param {CompanyVerificationStatus} [status] Статус заявки
         * @param {'id' | 'name' | 'type' | 'inn' | 'kpp' | 'codeOkved' | 'legalAddress' | 'director' | 'verificationStatus' | 'createdAt' | 'updatedAt'} [orderBy] Поле для сортировки
         * @param {OrderDirection} [order] Направление сортировки
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async companyControllerGetCompanies(currentPage?: number, pageSize?: number, ids?: Array<number>, inns?: Array<string>, companyName?: string, type?: 'SELLER' | 'BUYER' | 'TRADER' | 'CARRIER' | 'DEMO_COMPANY', status?: CompanyVerificationStatus, orderBy?: 'id' | 'name' | 'type' | 'inn' | 'kpp' | 'codeOkved' | 'legalAddress' | 'director' | 'verificationStatus' | 'createdAt' | 'updatedAt', order?: OrderDirection, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCompaniesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.companyControllerGetCompanies(currentPage, pageSize, ids, inns, companyName, type, status, orderBy, order, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Получить данные компании-владельца площадки (Зерно-Агрегатор)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async companyControllerGetCompanyZernoAgregator(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCompanyZernoAgregatorResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.companyControllerGetCompanyZernoAgregator(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Создать компанию
         * @param {number} companyId 
         * @param {UpdateCompanyBody} updateCompanyBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async companyControllerUpdateCompany(companyId: number, updateCompanyBody: UpdateCompanyBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateCompanyResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.companyControllerUpdateCompany(companyId, updateCompanyBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Обновить статус компании
         * @param {number} companyId 
         * @param {UpdateCompanyStatusBody} updateCompanyStatusBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async companyControllerUpdateCompanyStatus(companyId: number, updateCompanyStatusBody: UpdateCompanyStatusBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.companyControllerUpdateCompanyStatus(companyId, updateCompanyStatusBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Изменить тип компании
         * @param {number} companyId 
         * @param {UpdateCompanyTypeBody} updateCompanyTypeBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async companyControllerUpdateCompanyType(companyId: number, updateCompanyTypeBody: UpdateCompanyTypeBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.companyControllerUpdateCompanyType(companyId, updateCompanyTypeBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async companyDocumentControllerGetDocuments(companyId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetDocumentsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.companyDocumentControllerGetDocuments(companyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async companyDocumentControllerGetDocumentsArchiveUrl(companyId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetDocumentsArchiveUrlResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.companyDocumentControllerGetDocumentsArchiveUrl(companyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} companyId 
         * @param {'full-company-info-card' | 'inn' | 'ogrn' | 'company-regulation' | 'company-foundation-resolution' | 'director-appointment-resolution' | 'director-appointment-order' | 'executive-agency-passport' | 'accountant-appointment-order' | 'egrul-statement' | 'ogrnip' | 'individual-entrepreneur-passport' | 'payment-card-confirmation' | 'counterparty-authority-confirmation' | 'legal-address-confirmation' | 'last-year-financial-statement' | 'last-year-income-statement' | 'last-year-statistic-report-form-4' | 'last-year-statistic-report-form-29' | 'farmland-property-confirmation' | 'special-tax-mode-notification' | 'nds-declaration' | 'agriculture-tax-declaration' | 'income-tax-declaration' | 'ndfl3-declaration'} documentType Тип документа
         * @param {UpdateDocumentStatusBody} updateDocumentStatusBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async companyDocumentControllerUpdateDocumentStatus(companyId: number, documentType: 'full-company-info-card' | 'inn' | 'ogrn' | 'company-regulation' | 'company-foundation-resolution' | 'director-appointment-resolution' | 'director-appointment-order' | 'executive-agency-passport' | 'accountant-appointment-order' | 'egrul-statement' | 'ogrnip' | 'individual-entrepreneur-passport' | 'payment-card-confirmation' | 'counterparty-authority-confirmation' | 'legal-address-confirmation' | 'last-year-financial-statement' | 'last-year-income-statement' | 'last-year-statistic-report-form-4' | 'last-year-statistic-report-form-29' | 'farmland-property-confirmation' | 'special-tax-mode-notification' | 'nds-declaration' | 'agriculture-tax-declaration' | 'income-tax-declaration' | 'ndfl3-declaration', updateDocumentStatusBody: UpdateDocumentStatusBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.companyDocumentControllerUpdateDocumentStatus(companyId, documentType, updateDocumentStatusBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} companyId 
         * @param {CreateEdmOperatorBody} createEdmOperatorBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async edmOperatorControllerCreateEdmOperator(companyId: number, createEdmOperatorBody: CreateEdmOperatorBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.edmOperatorControllerCreateEdmOperator(companyId, createEdmOperatorBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async edmOperatorControllerDeleteEdmOperator(companyId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.edmOperatorControllerDeleteEdmOperator(companyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async edmOperatorControllerGetEdmOperator(companyId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetEdmOperatorResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.edmOperatorControllerGetEdmOperator(companyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} companyId 
         * @param {UpdateEdmOperatorBody} updateEdmOperatorBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async edmOperatorControllerUpdateEdmOperator(companyId: number, updateEdmOperatorBody: UpdateEdmOperatorBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.edmOperatorControllerUpdateEdmOperator(companyId, updateEdmOperatorBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * На текущий момент шаблон только один \"Справка по проценту сельхоз продукции\" (`last_year_income_statement`), поэтому метод не содержит параметров
         * @summary Скачать шаблон документа
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStaticFile(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getStaticFile(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Обновить документ компании
         * @param {number} companyId 
         * @param {'full-company-info-card' | 'inn' | 'ogrn' | 'company-regulation' | 'company-foundation-resolution' | 'director-appointment-resolution' | 'director-appointment-order' | 'executive-agency-passport' | 'accountant-appointment-order' | 'egrul-statement' | 'ogrnip' | 'individual-entrepreneur-passport' | 'payment-card-confirmation' | 'counterparty-authority-confirmation' | 'legal-address-confirmation' | 'last-year-financial-statement' | 'last-year-income-statement' | 'last-year-statistic-report-form-4' | 'last-year-statistic-report-form-29' | 'farmland-property-confirmation' | 'special-tax-mode-notification' | 'nds-declaration' | 'agriculture-tax-declaration' | 'income-tax-declaration' | 'ndfl3-declaration'} documentType Тип документа
         * @param {UpdateDocumentBody} updateDocumentBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateDocument(companyId: number, documentType: 'full-company-info-card' | 'inn' | 'ogrn' | 'company-regulation' | 'company-foundation-resolution' | 'director-appointment-resolution' | 'director-appointment-order' | 'executive-agency-passport' | 'accountant-appointment-order' | 'egrul-statement' | 'ogrnip' | 'individual-entrepreneur-passport' | 'payment-card-confirmation' | 'counterparty-authority-confirmation' | 'legal-address-confirmation' | 'last-year-financial-statement' | 'last-year-income-statement' | 'last-year-statistic-report-form-4' | 'last-year-statistic-report-form-29' | 'farmland-property-confirmation' | 'special-tax-mode-notification' | 'nds-declaration' | 'agriculture-tax-declaration' | 'income-tax-declaration' | 'ndfl3-declaration', updateDocumentBody: UpdateDocumentBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateDocument(companyId, documentType, updateDocumentBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Обновить архив с документами
         * @param {number} companyId 
         * @param {UpdateDocumentsArchiveBody} updateDocumentsArchiveBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateDocumentArchive(companyId: number, updateDocumentsArchiveBody: UpdateDocumentsArchiveBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateDocumentArchive(companyId, updateDocumentsArchiveBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CompanyApi - factory interface
 * @export
 */
export const CompanyApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CompanyApiFp(configuration)
    return {
        /**
         * 
         * @param {number} companyId 
         * @param {CreateBankAccountBody} createBankAccountBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bankAccountControllerCreate(companyId: number, createBankAccountBody: CreateBankAccountBody, options?: any): AxiosPromise<CreateBankAccountResponse> {
            return localVarFp.bankAccountControllerCreate(companyId, createBankAccountBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} bankAccountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bankAccountControllerDeleteBankAccount(bankAccountId: number, options?: any): AxiosPromise<void> {
            return localVarFp.bankAccountControllerDeleteBankAccount(bankAccountId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bankAccountControllerFindAllByCompanyId(companyId: number, options?: any): AxiosPromise<GetBankAccountsResponse> {
            return localVarFp.bankAccountControllerFindAllByCompanyId(companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} bankAccountId 
         * @param {UpdateBankAccountBody} updateBankAccountBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bankAccountControllerUpdateBankAccounts(bankAccountId: number, updateBankAccountBody: UpdateBankAccountBody, options?: any): AxiosPromise<void> {
            return localVarFp.bankAccountControllerUpdateBankAccounts(bankAccountId, updateBankAccountBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Создать компанию
         * @param {CreateCompanyBody} createCompanyBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyControllerCreateCompany(createCompanyBody: CreateCompanyBody, options?: any): AxiosPromise<CreateCompanyResponse> {
            return localVarFp.companyControllerCreateCompany(createCompanyBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получить список компаний
         * @param {number} [currentPage] Номер текущей страницы
         * @param {number} [pageSize] Количество элементов на странице
         * @param {Array<number>} [ids] Список id компаний
         * @param {Array<string>} [inns] Список ИНН компаний
         * @param {string} [companyName] Название компании
         * @param {'SELLER' | 'BUYER' | 'TRADER' | 'CARRIER' | 'DEMO_COMPANY'} [type] Тип компании
         * @param {CompanyVerificationStatus} [status] Статус заявки
         * @param {'id' | 'name' | 'type' | 'inn' | 'kpp' | 'codeOkved' | 'legalAddress' | 'director' | 'verificationStatus' | 'createdAt' | 'updatedAt'} [orderBy] Поле для сортировки
         * @param {OrderDirection} [order] Направление сортировки
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyControllerGetCompanies(currentPage?: number, pageSize?: number, ids?: Array<number>, inns?: Array<string>, companyName?: string, type?: 'SELLER' | 'BUYER' | 'TRADER' | 'CARRIER' | 'DEMO_COMPANY', status?: CompanyVerificationStatus, orderBy?: 'id' | 'name' | 'type' | 'inn' | 'kpp' | 'codeOkved' | 'legalAddress' | 'director' | 'verificationStatus' | 'createdAt' | 'updatedAt', order?: OrderDirection, options?: any): AxiosPromise<GetCompaniesResponse> {
            return localVarFp.companyControllerGetCompanies(currentPage, pageSize, ids, inns, companyName, type, status, orderBy, order, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получить данные компании-владельца площадки (Зерно-Агрегатор)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyControllerGetCompanyZernoAgregator(options?: any): AxiosPromise<GetCompanyZernoAgregatorResponse> {
            return localVarFp.companyControllerGetCompanyZernoAgregator(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Создать компанию
         * @param {number} companyId 
         * @param {UpdateCompanyBody} updateCompanyBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyControllerUpdateCompany(companyId: number, updateCompanyBody: UpdateCompanyBody, options?: any): AxiosPromise<UpdateCompanyResponse> {
            return localVarFp.companyControllerUpdateCompany(companyId, updateCompanyBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Обновить статус компании
         * @param {number} companyId 
         * @param {UpdateCompanyStatusBody} updateCompanyStatusBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyControllerUpdateCompanyStatus(companyId: number, updateCompanyStatusBody: UpdateCompanyStatusBody, options?: any): AxiosPromise<void> {
            return localVarFp.companyControllerUpdateCompanyStatus(companyId, updateCompanyStatusBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Изменить тип компании
         * @param {number} companyId 
         * @param {UpdateCompanyTypeBody} updateCompanyTypeBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyControllerUpdateCompanyType(companyId: number, updateCompanyTypeBody: UpdateCompanyTypeBody, options?: any): AxiosPromise<void> {
            return localVarFp.companyControllerUpdateCompanyType(companyId, updateCompanyTypeBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyDocumentControllerGetDocuments(companyId: number, options?: any): AxiosPromise<GetDocumentsResponse> {
            return localVarFp.companyDocumentControllerGetDocuments(companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyDocumentControllerGetDocumentsArchiveUrl(companyId: number, options?: any): AxiosPromise<GetDocumentsArchiveUrlResponse> {
            return localVarFp.companyDocumentControllerGetDocumentsArchiveUrl(companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} companyId 
         * @param {'full-company-info-card' | 'inn' | 'ogrn' | 'company-regulation' | 'company-foundation-resolution' | 'director-appointment-resolution' | 'director-appointment-order' | 'executive-agency-passport' | 'accountant-appointment-order' | 'egrul-statement' | 'ogrnip' | 'individual-entrepreneur-passport' | 'payment-card-confirmation' | 'counterparty-authority-confirmation' | 'legal-address-confirmation' | 'last-year-financial-statement' | 'last-year-income-statement' | 'last-year-statistic-report-form-4' | 'last-year-statistic-report-form-29' | 'farmland-property-confirmation' | 'special-tax-mode-notification' | 'nds-declaration' | 'agriculture-tax-declaration' | 'income-tax-declaration' | 'ndfl3-declaration'} documentType Тип документа
         * @param {UpdateDocumentStatusBody} updateDocumentStatusBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyDocumentControllerUpdateDocumentStatus(companyId: number, documentType: 'full-company-info-card' | 'inn' | 'ogrn' | 'company-regulation' | 'company-foundation-resolution' | 'director-appointment-resolution' | 'director-appointment-order' | 'executive-agency-passport' | 'accountant-appointment-order' | 'egrul-statement' | 'ogrnip' | 'individual-entrepreneur-passport' | 'payment-card-confirmation' | 'counterparty-authority-confirmation' | 'legal-address-confirmation' | 'last-year-financial-statement' | 'last-year-income-statement' | 'last-year-statistic-report-form-4' | 'last-year-statistic-report-form-29' | 'farmland-property-confirmation' | 'special-tax-mode-notification' | 'nds-declaration' | 'agriculture-tax-declaration' | 'income-tax-declaration' | 'ndfl3-declaration', updateDocumentStatusBody: UpdateDocumentStatusBody, options?: any): AxiosPromise<void> {
            return localVarFp.companyDocumentControllerUpdateDocumentStatus(companyId, documentType, updateDocumentStatusBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} companyId 
         * @param {CreateEdmOperatorBody} createEdmOperatorBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        edmOperatorControllerCreateEdmOperator(companyId: number, createEdmOperatorBody: CreateEdmOperatorBody, options?: any): AxiosPromise<void> {
            return localVarFp.edmOperatorControllerCreateEdmOperator(companyId, createEdmOperatorBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        edmOperatorControllerDeleteEdmOperator(companyId: number, options?: any): AxiosPromise<void> {
            return localVarFp.edmOperatorControllerDeleteEdmOperator(companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        edmOperatorControllerGetEdmOperator(companyId: number, options?: any): AxiosPromise<GetEdmOperatorResponse> {
            return localVarFp.edmOperatorControllerGetEdmOperator(companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} companyId 
         * @param {UpdateEdmOperatorBody} updateEdmOperatorBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        edmOperatorControllerUpdateEdmOperator(companyId: number, updateEdmOperatorBody: UpdateEdmOperatorBody, options?: any): AxiosPromise<void> {
            return localVarFp.edmOperatorControllerUpdateEdmOperator(companyId, updateEdmOperatorBody, options).then((request) => request(axios, basePath));
        },
        /**
         * На текущий момент шаблон только один \"Справка по проценту сельхоз продукции\" (`last_year_income_statement`), поэтому метод не содержит параметров
         * @summary Скачать шаблон документа
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStaticFile(options?: any): AxiosPromise<void> {
            return localVarFp.getStaticFile(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Обновить документ компании
         * @param {number} companyId 
         * @param {'full-company-info-card' | 'inn' | 'ogrn' | 'company-regulation' | 'company-foundation-resolution' | 'director-appointment-resolution' | 'director-appointment-order' | 'executive-agency-passport' | 'accountant-appointment-order' | 'egrul-statement' | 'ogrnip' | 'individual-entrepreneur-passport' | 'payment-card-confirmation' | 'counterparty-authority-confirmation' | 'legal-address-confirmation' | 'last-year-financial-statement' | 'last-year-income-statement' | 'last-year-statistic-report-form-4' | 'last-year-statistic-report-form-29' | 'farmland-property-confirmation' | 'special-tax-mode-notification' | 'nds-declaration' | 'agriculture-tax-declaration' | 'income-tax-declaration' | 'ndfl3-declaration'} documentType Тип документа
         * @param {UpdateDocumentBody} updateDocumentBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDocument(companyId: number, documentType: 'full-company-info-card' | 'inn' | 'ogrn' | 'company-regulation' | 'company-foundation-resolution' | 'director-appointment-resolution' | 'director-appointment-order' | 'executive-agency-passport' | 'accountant-appointment-order' | 'egrul-statement' | 'ogrnip' | 'individual-entrepreneur-passport' | 'payment-card-confirmation' | 'counterparty-authority-confirmation' | 'legal-address-confirmation' | 'last-year-financial-statement' | 'last-year-income-statement' | 'last-year-statistic-report-form-4' | 'last-year-statistic-report-form-29' | 'farmland-property-confirmation' | 'special-tax-mode-notification' | 'nds-declaration' | 'agriculture-tax-declaration' | 'income-tax-declaration' | 'ndfl3-declaration', updateDocumentBody: UpdateDocumentBody, options?: any): AxiosPromise<void> {
            return localVarFp.updateDocument(companyId, documentType, updateDocumentBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Обновить архив с документами
         * @param {number} companyId 
         * @param {UpdateDocumentsArchiveBody} updateDocumentsArchiveBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDocumentArchive(companyId: number, updateDocumentsArchiveBody: UpdateDocumentsArchiveBody, options?: any): AxiosPromise<void> {
            return localVarFp.updateDocumentArchive(companyId, updateDocumentsArchiveBody, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for bankAccountControllerCreate operation in CompanyApi.
 * @export
 * @interface CompanyApiBankAccountControllerCreateRequest
 */
export interface CompanyApiBankAccountControllerCreateRequest {
    /**
     * 
     * @type {number}
     * @memberof CompanyApiBankAccountControllerCreate
     */
    readonly companyId: number

    /**
     * 
     * @type {CreateBankAccountBody}
     * @memberof CompanyApiBankAccountControllerCreate
     */
    readonly createBankAccountBody: CreateBankAccountBody
}

/**
 * Request parameters for bankAccountControllerDeleteBankAccount operation in CompanyApi.
 * @export
 * @interface CompanyApiBankAccountControllerDeleteBankAccountRequest
 */
export interface CompanyApiBankAccountControllerDeleteBankAccountRequest {
    /**
     * 
     * @type {number}
     * @memberof CompanyApiBankAccountControllerDeleteBankAccount
     */
    readonly bankAccountId: number
}

/**
 * Request parameters for bankAccountControllerFindAllByCompanyId operation in CompanyApi.
 * @export
 * @interface CompanyApiBankAccountControllerFindAllByCompanyIdRequest
 */
export interface CompanyApiBankAccountControllerFindAllByCompanyIdRequest {
    /**
     * 
     * @type {number}
     * @memberof CompanyApiBankAccountControllerFindAllByCompanyId
     */
    readonly companyId: number
}

/**
 * Request parameters for bankAccountControllerUpdateBankAccounts operation in CompanyApi.
 * @export
 * @interface CompanyApiBankAccountControllerUpdateBankAccountsRequest
 */
export interface CompanyApiBankAccountControllerUpdateBankAccountsRequest {
    /**
     * 
     * @type {number}
     * @memberof CompanyApiBankAccountControllerUpdateBankAccounts
     */
    readonly bankAccountId: number

    /**
     * 
     * @type {UpdateBankAccountBody}
     * @memberof CompanyApiBankAccountControllerUpdateBankAccounts
     */
    readonly updateBankAccountBody: UpdateBankAccountBody
}

/**
 * Request parameters for companyControllerCreateCompany operation in CompanyApi.
 * @export
 * @interface CompanyApiCompanyControllerCreateCompanyRequest
 */
export interface CompanyApiCompanyControllerCreateCompanyRequest {
    /**
     * 
     * @type {CreateCompanyBody}
     * @memberof CompanyApiCompanyControllerCreateCompany
     */
    readonly createCompanyBody: CreateCompanyBody
}

/**
 * Request parameters for companyControllerGetCompanies operation in CompanyApi.
 * @export
 * @interface CompanyApiCompanyControllerGetCompaniesRequest
 */
export interface CompanyApiCompanyControllerGetCompaniesRequest {
    /**
     * Номер текущей страницы
     * @type {number}
     * @memberof CompanyApiCompanyControllerGetCompanies
     */
    readonly currentPage?: number

    /**
     * Количество элементов на странице
     * @type {number}
     * @memberof CompanyApiCompanyControllerGetCompanies
     */
    readonly pageSize?: number

    /**
     * Список id компаний
     * @type {Array<number>}
     * @memberof CompanyApiCompanyControllerGetCompanies
     */
    readonly ids?: Array<number>

    /**
     * Список ИНН компаний
     * @type {Array<string>}
     * @memberof CompanyApiCompanyControllerGetCompanies
     */
    readonly inns?: Array<string>

    /**
     * Название компании
     * @type {string}
     * @memberof CompanyApiCompanyControllerGetCompanies
     */
    readonly companyName?: string

    /**
     * Тип компании
     * @type {'SELLER' | 'BUYER' | 'TRADER' | 'CARRIER' | 'DEMO_COMPANY'}
     * @memberof CompanyApiCompanyControllerGetCompanies
     */
    readonly type?: 'SELLER' | 'BUYER' | 'TRADER' | 'CARRIER' | 'DEMO_COMPANY'

    /**
     * Статус заявки
     * @type {CompanyVerificationStatus}
     * @memberof CompanyApiCompanyControllerGetCompanies
     */
    readonly status?: CompanyVerificationStatus

    /**
     * Поле для сортировки
     * @type {'id' | 'name' | 'type' | 'inn' | 'kpp' | 'codeOkved' | 'legalAddress' | 'director' | 'verificationStatus' | 'createdAt' | 'updatedAt'}
     * @memberof CompanyApiCompanyControllerGetCompanies
     */
    readonly orderBy?: 'id' | 'name' | 'type' | 'inn' | 'kpp' | 'codeOkved' | 'legalAddress' | 'director' | 'verificationStatus' | 'createdAt' | 'updatedAt'

    /**
     * Направление сортировки
     * @type {OrderDirection}
     * @memberof CompanyApiCompanyControllerGetCompanies
     */
    readonly order?: OrderDirection
}

/**
 * Request parameters for companyControllerUpdateCompany operation in CompanyApi.
 * @export
 * @interface CompanyApiCompanyControllerUpdateCompanyRequest
 */
export interface CompanyApiCompanyControllerUpdateCompanyRequest {
    /**
     * 
     * @type {number}
     * @memberof CompanyApiCompanyControllerUpdateCompany
     */
    readonly companyId: number

    /**
     * 
     * @type {UpdateCompanyBody}
     * @memberof CompanyApiCompanyControllerUpdateCompany
     */
    readonly updateCompanyBody: UpdateCompanyBody
}

/**
 * Request parameters for companyControllerUpdateCompanyStatus operation in CompanyApi.
 * @export
 * @interface CompanyApiCompanyControllerUpdateCompanyStatusRequest
 */
export interface CompanyApiCompanyControllerUpdateCompanyStatusRequest {
    /**
     * 
     * @type {number}
     * @memberof CompanyApiCompanyControllerUpdateCompanyStatus
     */
    readonly companyId: number

    /**
     * 
     * @type {UpdateCompanyStatusBody}
     * @memberof CompanyApiCompanyControllerUpdateCompanyStatus
     */
    readonly updateCompanyStatusBody: UpdateCompanyStatusBody
}

/**
 * Request parameters for companyControllerUpdateCompanyType operation in CompanyApi.
 * @export
 * @interface CompanyApiCompanyControllerUpdateCompanyTypeRequest
 */
export interface CompanyApiCompanyControllerUpdateCompanyTypeRequest {
    /**
     * 
     * @type {number}
     * @memberof CompanyApiCompanyControllerUpdateCompanyType
     */
    readonly companyId: number

    /**
     * 
     * @type {UpdateCompanyTypeBody}
     * @memberof CompanyApiCompanyControllerUpdateCompanyType
     */
    readonly updateCompanyTypeBody: UpdateCompanyTypeBody
}

/**
 * Request parameters for companyDocumentControllerGetDocuments operation in CompanyApi.
 * @export
 * @interface CompanyApiCompanyDocumentControllerGetDocumentsRequest
 */
export interface CompanyApiCompanyDocumentControllerGetDocumentsRequest {
    /**
     * 
     * @type {number}
     * @memberof CompanyApiCompanyDocumentControllerGetDocuments
     */
    readonly companyId: number
}

/**
 * Request parameters for companyDocumentControllerGetDocumentsArchiveUrl operation in CompanyApi.
 * @export
 * @interface CompanyApiCompanyDocumentControllerGetDocumentsArchiveUrlRequest
 */
export interface CompanyApiCompanyDocumentControllerGetDocumentsArchiveUrlRequest {
    /**
     * 
     * @type {number}
     * @memberof CompanyApiCompanyDocumentControllerGetDocumentsArchiveUrl
     */
    readonly companyId: number
}

/**
 * Request parameters for companyDocumentControllerUpdateDocumentStatus operation in CompanyApi.
 * @export
 * @interface CompanyApiCompanyDocumentControllerUpdateDocumentStatusRequest
 */
export interface CompanyApiCompanyDocumentControllerUpdateDocumentStatusRequest {
    /**
     * 
     * @type {number}
     * @memberof CompanyApiCompanyDocumentControllerUpdateDocumentStatus
     */
    readonly companyId: number

    /**
     * Тип документа
     * @type {'full-company-info-card' | 'inn' | 'ogrn' | 'company-regulation' | 'company-foundation-resolution' | 'director-appointment-resolution' | 'director-appointment-order' | 'executive-agency-passport' | 'accountant-appointment-order' | 'egrul-statement' | 'ogrnip' | 'individual-entrepreneur-passport' | 'payment-card-confirmation' | 'counterparty-authority-confirmation' | 'legal-address-confirmation' | 'last-year-financial-statement' | 'last-year-income-statement' | 'last-year-statistic-report-form-4' | 'last-year-statistic-report-form-29' | 'farmland-property-confirmation' | 'special-tax-mode-notification' | 'nds-declaration' | 'agriculture-tax-declaration' | 'income-tax-declaration' | 'ndfl3-declaration'}
     * @memberof CompanyApiCompanyDocumentControllerUpdateDocumentStatus
     */
    readonly documentType: 'full-company-info-card' | 'inn' | 'ogrn' | 'company-regulation' | 'company-foundation-resolution' | 'director-appointment-resolution' | 'director-appointment-order' | 'executive-agency-passport' | 'accountant-appointment-order' | 'egrul-statement' | 'ogrnip' | 'individual-entrepreneur-passport' | 'payment-card-confirmation' | 'counterparty-authority-confirmation' | 'legal-address-confirmation' | 'last-year-financial-statement' | 'last-year-income-statement' | 'last-year-statistic-report-form-4' | 'last-year-statistic-report-form-29' | 'farmland-property-confirmation' | 'special-tax-mode-notification' | 'nds-declaration' | 'agriculture-tax-declaration' | 'income-tax-declaration' | 'ndfl3-declaration'

    /**
     * 
     * @type {UpdateDocumentStatusBody}
     * @memberof CompanyApiCompanyDocumentControllerUpdateDocumentStatus
     */
    readonly updateDocumentStatusBody: UpdateDocumentStatusBody
}

/**
 * Request parameters for edmOperatorControllerCreateEdmOperator operation in CompanyApi.
 * @export
 * @interface CompanyApiEdmOperatorControllerCreateEdmOperatorRequest
 */
export interface CompanyApiEdmOperatorControllerCreateEdmOperatorRequest {
    /**
     * 
     * @type {number}
     * @memberof CompanyApiEdmOperatorControllerCreateEdmOperator
     */
    readonly companyId: number

    /**
     * 
     * @type {CreateEdmOperatorBody}
     * @memberof CompanyApiEdmOperatorControllerCreateEdmOperator
     */
    readonly createEdmOperatorBody: CreateEdmOperatorBody
}

/**
 * Request parameters for edmOperatorControllerDeleteEdmOperator operation in CompanyApi.
 * @export
 * @interface CompanyApiEdmOperatorControllerDeleteEdmOperatorRequest
 */
export interface CompanyApiEdmOperatorControllerDeleteEdmOperatorRequest {
    /**
     * 
     * @type {number}
     * @memberof CompanyApiEdmOperatorControllerDeleteEdmOperator
     */
    readonly companyId: number
}

/**
 * Request parameters for edmOperatorControllerGetEdmOperator operation in CompanyApi.
 * @export
 * @interface CompanyApiEdmOperatorControllerGetEdmOperatorRequest
 */
export interface CompanyApiEdmOperatorControllerGetEdmOperatorRequest {
    /**
     * 
     * @type {number}
     * @memberof CompanyApiEdmOperatorControllerGetEdmOperator
     */
    readonly companyId: number
}

/**
 * Request parameters for edmOperatorControllerUpdateEdmOperator operation in CompanyApi.
 * @export
 * @interface CompanyApiEdmOperatorControllerUpdateEdmOperatorRequest
 */
export interface CompanyApiEdmOperatorControllerUpdateEdmOperatorRequest {
    /**
     * 
     * @type {number}
     * @memberof CompanyApiEdmOperatorControllerUpdateEdmOperator
     */
    readonly companyId: number

    /**
     * 
     * @type {UpdateEdmOperatorBody}
     * @memberof CompanyApiEdmOperatorControllerUpdateEdmOperator
     */
    readonly updateEdmOperatorBody: UpdateEdmOperatorBody
}

/**
 * Request parameters for updateDocument operation in CompanyApi.
 * @export
 * @interface CompanyApiUpdateDocumentRequest
 */
export interface CompanyApiUpdateDocumentRequest {
    /**
     * 
     * @type {number}
     * @memberof CompanyApiUpdateDocument
     */
    readonly companyId: number

    /**
     * Тип документа
     * @type {'full-company-info-card' | 'inn' | 'ogrn' | 'company-regulation' | 'company-foundation-resolution' | 'director-appointment-resolution' | 'director-appointment-order' | 'executive-agency-passport' | 'accountant-appointment-order' | 'egrul-statement' | 'ogrnip' | 'individual-entrepreneur-passport' | 'payment-card-confirmation' | 'counterparty-authority-confirmation' | 'legal-address-confirmation' | 'last-year-financial-statement' | 'last-year-income-statement' | 'last-year-statistic-report-form-4' | 'last-year-statistic-report-form-29' | 'farmland-property-confirmation' | 'special-tax-mode-notification' | 'nds-declaration' | 'agriculture-tax-declaration' | 'income-tax-declaration' | 'ndfl3-declaration'}
     * @memberof CompanyApiUpdateDocument
     */
    readonly documentType: 'full-company-info-card' | 'inn' | 'ogrn' | 'company-regulation' | 'company-foundation-resolution' | 'director-appointment-resolution' | 'director-appointment-order' | 'executive-agency-passport' | 'accountant-appointment-order' | 'egrul-statement' | 'ogrnip' | 'individual-entrepreneur-passport' | 'payment-card-confirmation' | 'counterparty-authority-confirmation' | 'legal-address-confirmation' | 'last-year-financial-statement' | 'last-year-income-statement' | 'last-year-statistic-report-form-4' | 'last-year-statistic-report-form-29' | 'farmland-property-confirmation' | 'special-tax-mode-notification' | 'nds-declaration' | 'agriculture-tax-declaration' | 'income-tax-declaration' | 'ndfl3-declaration'

    /**
     * 
     * @type {UpdateDocumentBody}
     * @memberof CompanyApiUpdateDocument
     */
    readonly updateDocumentBody: UpdateDocumentBody
}

/**
 * Request parameters for updateDocumentArchive operation in CompanyApi.
 * @export
 * @interface CompanyApiUpdateDocumentArchiveRequest
 */
export interface CompanyApiUpdateDocumentArchiveRequest {
    /**
     * 
     * @type {number}
     * @memberof CompanyApiUpdateDocumentArchive
     */
    readonly companyId: number

    /**
     * 
     * @type {UpdateDocumentsArchiveBody}
     * @memberof CompanyApiUpdateDocumentArchive
     */
    readonly updateDocumentsArchiveBody: UpdateDocumentsArchiveBody
}

/**
 * CompanyApi - object-oriented interface
 * @export
 * @class CompanyApi
 * @extends {BaseAPI}
 */
export class CompanyApi extends BaseAPI {
    /**
     * 
     * @param {CompanyApiBankAccountControllerCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public bankAccountControllerCreate(requestParameters: CompanyApiBankAccountControllerCreateRequest, options?: AxiosRequestConfig) {
        return CompanyApiFp(this.configuration).bankAccountControllerCreate(requestParameters.companyId, requestParameters.createBankAccountBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CompanyApiBankAccountControllerDeleteBankAccountRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public bankAccountControllerDeleteBankAccount(requestParameters: CompanyApiBankAccountControllerDeleteBankAccountRequest, options?: AxiosRequestConfig) {
        return CompanyApiFp(this.configuration).bankAccountControllerDeleteBankAccount(requestParameters.bankAccountId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CompanyApiBankAccountControllerFindAllByCompanyIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public bankAccountControllerFindAllByCompanyId(requestParameters: CompanyApiBankAccountControllerFindAllByCompanyIdRequest, options?: AxiosRequestConfig) {
        return CompanyApiFp(this.configuration).bankAccountControllerFindAllByCompanyId(requestParameters.companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CompanyApiBankAccountControllerUpdateBankAccountsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public bankAccountControllerUpdateBankAccounts(requestParameters: CompanyApiBankAccountControllerUpdateBankAccountsRequest, options?: AxiosRequestConfig) {
        return CompanyApiFp(this.configuration).bankAccountControllerUpdateBankAccounts(requestParameters.bankAccountId, requestParameters.updateBankAccountBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Создать компанию
     * @param {CompanyApiCompanyControllerCreateCompanyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public companyControllerCreateCompany(requestParameters: CompanyApiCompanyControllerCreateCompanyRequest, options?: AxiosRequestConfig) {
        return CompanyApiFp(this.configuration).companyControllerCreateCompany(requestParameters.createCompanyBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получить список компаний
     * @param {CompanyApiCompanyControllerGetCompaniesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public companyControllerGetCompanies(requestParameters: CompanyApiCompanyControllerGetCompaniesRequest = {}, options?: AxiosRequestConfig) {
        return CompanyApiFp(this.configuration).companyControllerGetCompanies(requestParameters.currentPage, requestParameters.pageSize, requestParameters.ids, requestParameters.inns, requestParameters.companyName, requestParameters.type, requestParameters.status, requestParameters.orderBy, requestParameters.order, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получить данные компании-владельца площадки (Зерно-Агрегатор)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public companyControllerGetCompanyZernoAgregator(options?: AxiosRequestConfig) {
        return CompanyApiFp(this.configuration).companyControllerGetCompanyZernoAgregator(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Создать компанию
     * @param {CompanyApiCompanyControllerUpdateCompanyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public companyControllerUpdateCompany(requestParameters: CompanyApiCompanyControllerUpdateCompanyRequest, options?: AxiosRequestConfig) {
        return CompanyApiFp(this.configuration).companyControllerUpdateCompany(requestParameters.companyId, requestParameters.updateCompanyBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Обновить статус компании
     * @param {CompanyApiCompanyControllerUpdateCompanyStatusRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public companyControllerUpdateCompanyStatus(requestParameters: CompanyApiCompanyControllerUpdateCompanyStatusRequest, options?: AxiosRequestConfig) {
        return CompanyApiFp(this.configuration).companyControllerUpdateCompanyStatus(requestParameters.companyId, requestParameters.updateCompanyStatusBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Изменить тип компании
     * @param {CompanyApiCompanyControllerUpdateCompanyTypeRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public companyControllerUpdateCompanyType(requestParameters: CompanyApiCompanyControllerUpdateCompanyTypeRequest, options?: AxiosRequestConfig) {
        return CompanyApiFp(this.configuration).companyControllerUpdateCompanyType(requestParameters.companyId, requestParameters.updateCompanyTypeBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CompanyApiCompanyDocumentControllerGetDocumentsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public companyDocumentControllerGetDocuments(requestParameters: CompanyApiCompanyDocumentControllerGetDocumentsRequest, options?: AxiosRequestConfig) {
        return CompanyApiFp(this.configuration).companyDocumentControllerGetDocuments(requestParameters.companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CompanyApiCompanyDocumentControllerGetDocumentsArchiveUrlRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public companyDocumentControllerGetDocumentsArchiveUrl(requestParameters: CompanyApiCompanyDocumentControllerGetDocumentsArchiveUrlRequest, options?: AxiosRequestConfig) {
        return CompanyApiFp(this.configuration).companyDocumentControllerGetDocumentsArchiveUrl(requestParameters.companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CompanyApiCompanyDocumentControllerUpdateDocumentStatusRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public companyDocumentControllerUpdateDocumentStatus(requestParameters: CompanyApiCompanyDocumentControllerUpdateDocumentStatusRequest, options?: AxiosRequestConfig) {
        return CompanyApiFp(this.configuration).companyDocumentControllerUpdateDocumentStatus(requestParameters.companyId, requestParameters.documentType, requestParameters.updateDocumentStatusBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CompanyApiEdmOperatorControllerCreateEdmOperatorRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public edmOperatorControllerCreateEdmOperator(requestParameters: CompanyApiEdmOperatorControllerCreateEdmOperatorRequest, options?: AxiosRequestConfig) {
        return CompanyApiFp(this.configuration).edmOperatorControllerCreateEdmOperator(requestParameters.companyId, requestParameters.createEdmOperatorBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CompanyApiEdmOperatorControllerDeleteEdmOperatorRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public edmOperatorControllerDeleteEdmOperator(requestParameters: CompanyApiEdmOperatorControllerDeleteEdmOperatorRequest, options?: AxiosRequestConfig) {
        return CompanyApiFp(this.configuration).edmOperatorControllerDeleteEdmOperator(requestParameters.companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CompanyApiEdmOperatorControllerGetEdmOperatorRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public edmOperatorControllerGetEdmOperator(requestParameters: CompanyApiEdmOperatorControllerGetEdmOperatorRequest, options?: AxiosRequestConfig) {
        return CompanyApiFp(this.configuration).edmOperatorControllerGetEdmOperator(requestParameters.companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CompanyApiEdmOperatorControllerUpdateEdmOperatorRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public edmOperatorControllerUpdateEdmOperator(requestParameters: CompanyApiEdmOperatorControllerUpdateEdmOperatorRequest, options?: AxiosRequestConfig) {
        return CompanyApiFp(this.configuration).edmOperatorControllerUpdateEdmOperator(requestParameters.companyId, requestParameters.updateEdmOperatorBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * На текущий момент шаблон только один \"Справка по проценту сельхоз продукции\" (`last_year_income_statement`), поэтому метод не содержит параметров
     * @summary Скачать шаблон документа
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public getStaticFile(options?: AxiosRequestConfig) {
        return CompanyApiFp(this.configuration).getStaticFile(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Обновить документ компании
     * @param {CompanyApiUpdateDocumentRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public updateDocument(requestParameters: CompanyApiUpdateDocumentRequest, options?: AxiosRequestConfig) {
        return CompanyApiFp(this.configuration).updateDocument(requestParameters.companyId, requestParameters.documentType, requestParameters.updateDocumentBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Обновить архив с документами
     * @param {CompanyApiUpdateDocumentArchiveRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public updateDocumentArchive(requestParameters: CompanyApiUpdateDocumentArchiveRequest, options?: AxiosRequestConfig) {
        return CompanyApiFp(this.configuration).updateDocumentArchive(requestParameters.companyId, requestParameters.updateDocumentsArchiveBody, options).then((request) => request(this.axios, this.basePath));
    }
}
