import React, { useEffect } from 'react'

import { Button } from 'antd'
import { Controller, useForm } from 'react-hook-form'

import { yupResolver } from '@hookform/resolvers/yup'

import { FormField, Text } from '~/ui-components'
import { PhoneNumberControl } from '~shared/controls'

import { newPhoneNumberFormSchema } from './NewPhoneNumberForm.validation'

import { NewPhoneNumberFormProps, NewPhoneNumberFormValues } from './NewPhoneNumberForm.types'

import * as S from './NewPhoneNumberForm.styled'

export const NewPhoneNumberForm: React.FC<NewPhoneNumberFormProps> = ({
  onSubmit,
  onCancel,
  isSubmitting,
  errorMessage,
}) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm<NewPhoneNumberFormValues>({
    defaultValues: {
      mobileNumber: '',
    },
    resolver: yupResolver(newPhoneNumberFormSchema),
  })

  useEffect(() => {
    if (errorMessage) {
      setError('mobileNumber', { message: errorMessage })
    }
  }, [errorMessage])

  const onSubmitHandler = (data: NewPhoneNumberFormValues) => onSubmit(data.mobileNumber)

  return (
    <S.Form onSubmit={handleSubmit(onSubmitHandler)}>
      <Text variant="normal-bold" style={{ marginBottom: 32 }}>
        Введите новый номер телефона. Мы пришлем код подтверждения снова, после чего обновим данные.
      </Text>

      <FormField htmlFor="mobileNumber" validateMessage={errors.mobileNumber?.message} label="Номер телефона">
        <Controller
          name="mobileNumber"
          control={control}
          render={({ field }) => (
            <PhoneNumberControl {...field} autoFocus id="mobileNumber" hasError={!!errors.mobileNumber} />
          )}
        />
      </FormField>

      <S.ActionWrapper>
        <Button htmlType="button" onClick={onCancel}>
          Отмена
        </Button>
        <Button type="primary" htmlType="submit" data-testid="login-next-btn" loading={isSubmitting}>
          Готово
        </Button>
      </S.ActionWrapper>
    </S.Form>
  )
}
