import { graphql } from '~api/gql-generated'

export const createTransportationDataMutation = graphql(/* GraphQL */ `
  mutation TransportationDataCreate($input: TransportDataCreateInput!) {
    transportDataCreate(input: $input) {
      id
    }
  }
`)

export const updateTransportationDataMutation = graphql(/* GraphQL */ `
  mutation Mutation($companyId: Int!, $input: TransportDataUpdateInput!) {
    transportData(companyId: $companyId) {
      update(input: $input) {
        id
      }
    }
  }
`)

export const createTransportMutation = graphql(/* GraphQL */ `
  mutation TransportCreate($input: TransportCreateInput!) {
    transportCreate(input: $input) {
      id
    }
  }
`)

export const updateTransportMutation = graphql(/* GraphQL */ `
  mutation TransportUpdate($input: TransportUpdateInput!, $transportId: Int!) {
    transport(transportId: $transportId) {
      update(input: $input) {
        id
      }
    }
  }
`)

export const createTransportDocumentMutation = graphql(/* GraphQL */ `
  mutation TransportDocumentCreate($input: TransportDocumentCreateInput!) {
    transportDocumentCreate(input: $input) {
      type
    }
  }
`)

export const deleteTransportDocumentMutation = graphql(/* GraphQL */ `
  mutation DeleteTransportDocument($documentId: Int!) {
    transportDocument(documentId: $documentId) {
      delete
    }
  }
`)

export const addTransportDocumentFilesMutation = graphql(/* GraphQL */ `
  mutation AddTransportDocumentFiles($documentId: Int!, $input: DocumentFilesCreateInput!) {
    transportDocument(documentId: $documentId) {
      addFiles(input: $input) {
        type
      }
    }
  }
`)

export const deleteTransportDocumentFileMutation = graphql(/* GraphQL */ `
  mutation DeleteTransportDocumentFile($documentFileId: Int!) {
    transportDocumentFile(documentFileId: $documentFileId) {
      delete
    }
  }
`)
