import React, { useEffect } from 'react'

import { Button, Input, Modal, Radio, Space } from 'antd'
import { Controller, useForm } from 'react-hook-form'

import { yupResolver } from '@hookform/resolvers/yup/dist/yup'

import { FormField } from '~/ui-components'
import { modalSharedSettings } from '~/utils'

import { basisAdvertOfferChangeStatusModalValidation } from './BasisAdvertOfferChangeStatusModal.validation'

import {
  BasisAdvertOfferChangeStatusModalProps,
  ChangeStatusBasisOfferForm,
} from './BasisAdvertOfferChangeStatusModal.types'

const FORM_ID = 'change-status-advert-offer-modal'

export const BasisAdvertOfferChangeStatusModal: React.FC<BasisAdvertOfferChangeStatusModalProps> = (props) => {
  const {
    watch,
    setValue,
    control,
    handleSubmit,
    formState: { errors, isDirty },
  } = useForm<ChangeStatusBasisOfferForm>({
    defaultValues: {
      isDealComplete: undefined,
      rejectDealComment: undefined,
      successDealComment: undefined,
    },
    resolver: yupResolver(basisAdvertOfferChangeStatusModalValidation),
  })

  const watchIsDealComplete = watch('isDealComplete')

  useEffect(() => {
    if (watchIsDealComplete) {
      setValue('rejectDealComment', undefined)
    } else {
      setValue('successDealComment', undefined)
    }
  }, [watchIsDealComplete])

  const handleFormSubmit = (values: ChangeStatusBasisOfferForm) => {
    props.onSubmit(values)
    props.onClose()
  }

  return (
    <Modal
      {...modalSharedSettings}
      destroyOnClose={true}
      width={undefined}
      open={props.isOpen}
      onCancel={props.onClose}
      title="Изменить статус"
      footer={[
        <Button key="cancel" htmlType="button" onClick={props.onClose}>
          Отмена
        </Button>,
        <Button type="primary" key="submit" htmlType="submit" form={FORM_ID} disabled={!isDirty}>
          Отправить
        </Button>,
      ]}
    >
      <form id={FORM_ID} onSubmit={handleSubmit(handleFormSubmit)}>
        <FormField htmlFor="isDealComplete" validateMessage={errors.isDealComplete?.message}>
          <Controller
            name="isDealComplete"
            control={control}
            render={({ field }) => (
              <Radio.Group onChange={(e) => field.onChange(e.target.value)} value={field.value}>
                <Space direction="vertical">
                  <Radio value={true}>Сделка состоялась</Radio>
                  <Radio value={false}>Сделка не состоялась</Radio>
                </Space>
              </Radio.Group>
            )}
          />
        </FormField>

        <FormField
          isVisible={watchIsDealComplete === true}
          label="Комментарий"
          htmlFor="successDealComment"
          validateMessage={errors.successDealComment?.message}
        >
          <Controller
            name="successDealComment"
            control={control}
            render={({ field }) => (
              <Input.TextArea
                {...field}
                id="successDealComment"
                rows={4}
                placeholder="Поделитесь впечатлениями о сделке"
                maxLength={500}
                status={errors.successDealComment && 'error'}
              ></Input.TextArea>
            )}
          />
        </FormField>

        <FormField
          isVisible={watchIsDealComplete === false}
          label="Почему не состоялась сделка?"
          isRequired
          htmlFor="rejectDealComment"
          validateMessage={errors.rejectDealComment?.message}
        >
          <Controller
            name="rejectDealComment"
            control={control}
            render={({ field }) => (
              <Input.TextArea
                {...field}
                id="rejectDealComment"
                rows={4}
                placeholder="Напишите причину"
                maxLength={500}
                status={errors.rejectDealComment && 'error'}
              ></Input.TextArea>
            )}
          />
        </FormField>
      </form>
    </Modal>
  )
}
