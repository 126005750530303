/* tslint:disable */
/* eslint-disable */
/**
 * API Зерно-Агрегатора
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AdminCreateElevatorAdvertBuyBody } from '../models';
// @ts-ignore
import { AdminCreateElevatorAdvertResponse } from '../models';
// @ts-ignore
import { AdminCreateElevatorAdvertSellBody } from '../models';
// @ts-ignore
import { AdminGetElevatorAdvertResponse } from '../models';
// @ts-ignore
import { AdminGetElevatorAdvertsResponse } from '../models';
// @ts-ignore
import { AdminUpdateElevatorAdvertBody } from '../models';
// @ts-ignore
import { AdminUpdateElevatorAdvertStatusBody } from '../models';
// @ts-ignore
import { CreateElevatorAdvertBuyBody } from '../models';
// @ts-ignore
import { CreateElevatorAdvertResponse } from '../models';
// @ts-ignore
import { CreateElevatorAdvertSellBody } from '../models';
// @ts-ignore
import { ElevatorOfferStatus } from '../models';
// @ts-ignore
import { GetElevatorAdvertResponse } from '../models';
// @ts-ignore
import { GetElevatorAdvertsResponse } from '../models';
// @ts-ignore
import { GetMyElevatorAdvertsResponse } from '../models';
// @ts-ignore
import { GetSimilarElevatorOffersResponse } from '../models';
// @ts-ignore
import { GetSimilarElevatorsResponse } from '../models';
// @ts-ignore
import { UpdateElevatorAdvertPriceBody } from '../models';
// @ts-ignore
import { UpdateElevatorAdvertStatusBody } from '../models';
/**
 * ElevatorAdvertApi - axios parameter creator
 * @export
 */
export const ElevatorAdvertApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Создать объявление на элеваторе для админа покупателя
         * @param {AdminCreateElevatorAdvertBuyBody} adminCreateElevatorAdvertBuyBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCreateElevatorAdvertBuy: async (adminCreateElevatorAdvertBuyBody: AdminCreateElevatorAdvertBuyBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'adminCreateElevatorAdvertBuyBody' is not null or undefined
            assertParamExists('adminCreateElevatorAdvertBuy', 'adminCreateElevatorAdvertBuyBody', adminCreateElevatorAdvertBuyBody)
            const localVarPath = `/admin/elevator-offer/buy`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminCreateElevatorAdvertBuyBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Создать объявление на элеваторе для админа продавца
         * @param {AdminCreateElevatorAdvertSellBody} adminCreateElevatorAdvertSellBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCreateElevatorAdvertSell: async (adminCreateElevatorAdvertSellBody: AdminCreateElevatorAdvertSellBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'adminCreateElevatorAdvertSellBody' is not null or undefined
            assertParamExists('adminCreateElevatorAdvertSell', 'adminCreateElevatorAdvertSellBody', adminCreateElevatorAdvertSellBody)
            const localVarPath = `/admin/elevator-offer/sell`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminCreateElevatorAdvertSellBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} elevatorAdvertId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminElevatorAdvertControllerAdminGetElevatorAdvert: async (elevatorAdvertId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'elevatorAdvertId' is not null or undefined
            assertParamExists('adminElevatorAdvertControllerAdminGetElevatorAdvert', 'elevatorAdvertId', elevatorAdvertId)
            const localVarPath = `/admin/elevator-offer/{elevatorAdvertId}`
                .replace(`{${"elevatorAdvertId"}}`, encodeURIComponent(String(elevatorAdvertId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [currentPage] Номер текущей страницы
         * @param {number} [pageSize] Количество элементов на странице
         * @param {Array<ElevatorOfferStatus>} [statuses] Статусы объявлений
         * @param {Array<number>} [elevatorIds] Массив с id элеваторов
         * @param {Array<number>} [companyIds] Массив с id компаний
         * @param {Array<number>} [elevatorAdvertIds] Массив с id объявлений
         * @param {'BUY' | 'SELL'} [type] Тип объявления
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminElevatorAdvertControllerAdminGetElevatorAdverts: async (currentPage?: number, pageSize?: number, statuses?: Array<ElevatorOfferStatus>, elevatorIds?: Array<number>, companyIds?: Array<number>, elevatorAdvertIds?: Array<number>, type?: 'BUY' | 'SELL', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/elevator-offers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (currentPage !== undefined) {
                localVarQueryParameter['current-page'] = currentPage;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page-size'] = pageSize;
            }

            if (statuses) {
                localVarQueryParameter['statuses'] = statuses;
            }

            if (elevatorIds) {
                localVarQueryParameter['elevator-ids'] = elevatorIds;
            }

            if (companyIds) {
                localVarQueryParameter['company-ids'] = companyIds;
            }

            if (elevatorAdvertIds) {
                localVarQueryParameter['elevator-advert-ids'] = elevatorAdvertIds;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {AdminUpdateElevatorAdvertBody} adminUpdateElevatorAdvertBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminElevatorAdvertControllerUpdateElevatorAdvert: async (id: number, adminUpdateElevatorAdvertBody: AdminUpdateElevatorAdvertBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminElevatorAdvertControllerUpdateElevatorAdvert', 'id', id)
            // verify required parameter 'adminUpdateElevatorAdvertBody' is not null or undefined
            assertParamExists('adminElevatorAdvertControllerUpdateElevatorAdvert', 'adminUpdateElevatorAdvertBody', adminUpdateElevatorAdvertBody)
            const localVarPath = `/admin/elevator-offer/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminUpdateElevatorAdvertBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} elevatorAdvertId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminElevatorAdvertControllerUpdateElevatorAdvertModerator: async (elevatorAdvertId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'elevatorAdvertId' is not null or undefined
            assertParamExists('adminElevatorAdvertControllerUpdateElevatorAdvertModerator', 'elevatorAdvertId', elevatorAdvertId)
            const localVarPath = `/admin/elevator-offer/{elevatorAdvertId}/assign-moderator`
                .replace(`{${"elevatorAdvertId"}}`, encodeURIComponent(String(elevatorAdvertId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {AdminUpdateElevatorAdvertStatusBody} adminUpdateElevatorAdvertStatusBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminElevatorAdvertControllerUpdateElevatorAdvertStatus: async (id: number, adminUpdateElevatorAdvertStatusBody: AdminUpdateElevatorAdvertStatusBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminElevatorAdvertControllerUpdateElevatorAdvertStatus', 'id', id)
            // verify required parameter 'adminUpdateElevatorAdvertStatusBody' is not null or undefined
            assertParamExists('adminElevatorAdvertControllerUpdateElevatorAdvertStatus', 'adminUpdateElevatorAdvertStatusBody', adminUpdateElevatorAdvertStatusBody)
            const localVarPath = `/admin/elevator-offer/{id}/status`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminUpdateElevatorAdvertStatusBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Создать объявление на элеваторе для покупателя
         * @param {CreateElevatorAdvertBuyBody} createElevatorAdvertBuyBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createElevatorAdvertBuy: async (createElevatorAdvertBuyBody: CreateElevatorAdvertBuyBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createElevatorAdvertBuyBody' is not null or undefined
            assertParamExists('createElevatorAdvertBuy', 'createElevatorAdvertBuyBody', createElevatorAdvertBuyBody)
            const localVarPath = `/elevator-offer/buy`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createElevatorAdvertBuyBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Создать объявление на элеваторе для продавца
         * @param {CreateElevatorAdvertSellBody} createElevatorAdvertSellBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createElevatorAdvertSell: async (createElevatorAdvertSellBody: CreateElevatorAdvertSellBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createElevatorAdvertSellBody' is not null or undefined
            assertParamExists('createElevatorAdvertSell', 'createElevatorAdvertSellBody', createElevatorAdvertSellBody)
            const localVarPath = `/elevator-offer/sell`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createElevatorAdvertSellBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {number} userCompanyId Id компании, пользователь которой делает запрос
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        elevatorAdvertControllerGetElevatorAdvert: async (id: number, userCompanyId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('elevatorAdvertControllerGetElevatorAdvert', 'id', id)
            // verify required parameter 'userCompanyId' is not null or undefined
            assertParamExists('elevatorAdvertControllerGetElevatorAdvert', 'userCompanyId', userCompanyId)
            const localVarPath = `/elevator-offer/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (userCompanyId !== undefined) {
                localVarQueryParameter['userCompanyId'] = userCompanyId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} userCompanyId Id компании, пользователь которой делает запрос
         * @param {number} elevatorId Id элеватора
         * @param {number} [currentPage] Номер текущей страницы
         * @param {number} [pageSize] Количество элементов на странице
         * @param {Array<string>} [productTypes] Список типов товаров
         * @param {number} [companyId] Id компании
         * @param {'BUY' | 'SELL'} [type] Тип объявления
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        elevatorAdvertControllerGetElevatorAdverts: async (userCompanyId: number, elevatorId: number, currentPage?: number, pageSize?: number, productTypes?: Array<string>, companyId?: number, type?: 'BUY' | 'SELL', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userCompanyId' is not null or undefined
            assertParamExists('elevatorAdvertControllerGetElevatorAdverts', 'userCompanyId', userCompanyId)
            // verify required parameter 'elevatorId' is not null or undefined
            assertParamExists('elevatorAdvertControllerGetElevatorAdverts', 'elevatorId', elevatorId)
            const localVarPath = `/elevator-offers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (currentPage !== undefined) {
                localVarQueryParameter['current-page'] = currentPage;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page-size'] = pageSize;
            }

            if (userCompanyId !== undefined) {
                localVarQueryParameter['userCompanyId'] = userCompanyId;
            }

            if (elevatorId !== undefined) {
                localVarQueryParameter['elevatorId'] = elevatorId;
            }

            if (productTypes) {
                localVarQueryParameter['product-types'] = productTypes;
            }

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} userCompanyId Id компании, пользователь которой делает запрос
         * @param {number} [currentPage] Номер текущей страницы
         * @param {number} [pageSize] Количество элементов на странице
         * @param {'BUY' | 'SELL'} [type] Тип объявления
         * @param {Array<ElevatorOfferStatus>} [statuses] Статусы объявлений
         * @param {Array<number>} [elevatorIds] Массив с id элеваторов
         * @param {Array<string>} [productTypes] Список типов товаров
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        elevatorAdvertControllerGetMyElevatorAdverts: async (userCompanyId: number, currentPage?: number, pageSize?: number, type?: 'BUY' | 'SELL', statuses?: Array<ElevatorOfferStatus>, elevatorIds?: Array<number>, productTypes?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userCompanyId' is not null or undefined
            assertParamExists('elevatorAdvertControllerGetMyElevatorAdverts', 'userCompanyId', userCompanyId)
            const localVarPath = `/elevator-offers/my`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (currentPage !== undefined) {
                localVarQueryParameter['current-page'] = currentPage;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page-size'] = pageSize;
            }

            if (userCompanyId !== undefined) {
                localVarQueryParameter['userCompanyId'] = userCompanyId;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            if (statuses) {
                localVarQueryParameter['statuses'] = statuses;
            }

            if (elevatorIds) {
                localVarQueryParameter['elevator-ids'] = elevatorIds;
            }

            if (productTypes) {
                localVarQueryParameter['product-types'] = productTypes;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} elevatorAdvertId 
         * @param {number} [currentPage] Номер текущей страницы
         * @param {number} [pageSize] Количество элементов на странице
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        elevatorAdvertControllerGetSimilarElevators: async (elevatorAdvertId: number, currentPage?: number, pageSize?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'elevatorAdvertId' is not null or undefined
            assertParamExists('elevatorAdvertControllerGetSimilarElevators', 'elevatorAdvertId', elevatorAdvertId)
            const localVarPath = `/elevator-offer/{elevatorAdvertId}/similar-elevators`
                .replace(`{${"elevatorAdvertId"}}`, encodeURIComponent(String(elevatorAdvertId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (currentPage !== undefined) {
                localVarQueryParameter['current-page'] = currentPage;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page-size'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdateElevatorAdvertPriceBody} updateElevatorAdvertPriceBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        elevatorAdvertControllerUpdateElevatorAdvertPrice: async (id: number, updateElevatorAdvertPriceBody: UpdateElevatorAdvertPriceBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('elevatorAdvertControllerUpdateElevatorAdvertPrice', 'id', id)
            // verify required parameter 'updateElevatorAdvertPriceBody' is not null or undefined
            assertParamExists('elevatorAdvertControllerUpdateElevatorAdvertPrice', 'updateElevatorAdvertPriceBody', updateElevatorAdvertPriceBody)
            const localVarPath = `/elevator-offer/{id}/price-per-ton`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateElevatorAdvertPriceBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdateElevatorAdvertStatusBody} updateElevatorAdvertStatusBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        elevatorAdvertControllerUpdateElevatorAdvertStatus: async (id: number, updateElevatorAdvertStatusBody: UpdateElevatorAdvertStatusBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('elevatorAdvertControllerUpdateElevatorAdvertStatus', 'id', id)
            // verify required parameter 'updateElevatorAdvertStatusBody' is not null or undefined
            assertParamExists('elevatorAdvertControllerUpdateElevatorAdvertStatus', 'updateElevatorAdvertStatusBody', updateElevatorAdvertStatusBody)
            const localVarPath = `/elevator-offer/{id}/status`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateElevatorAdvertStatusBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получить отчёт по объявлению в xlsx
         * @param {number} elevatorOfferId 
         * @param {number} userCompanyId Id компании, пользователь которой делает запрос
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getElevatorOfferReport: async (elevatorOfferId: number, userCompanyId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'elevatorOfferId' is not null or undefined
            assertParamExists('getElevatorOfferReport', 'elevatorOfferId', elevatorOfferId)
            // verify required parameter 'userCompanyId' is not null or undefined
            assertParamExists('getElevatorOfferReport', 'userCompanyId', userCompanyId)
            const localVarPath = `/elevator-offer/{elevatorOfferId}/report`
                .replace(`{${"elevatorOfferId"}}`, encodeURIComponent(String(elevatorOfferId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (userCompanyId !== undefined) {
                localVarQueryParameter['userCompanyId'] = userCompanyId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получить похожие объявления
         * @param {number} id 
         * @param {number} [currentPage] Номер текущей страницы
         * @param {number} [pageSize] Количество элементов на странице
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSimilarElevatorOffers: async (id: number, currentPage?: number, pageSize?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getSimilarElevatorOffers', 'id', id)
            const localVarPath = `/elevator-offer/{id}/similar-offers`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (currentPage !== undefined) {
                localVarQueryParameter['current-page'] = currentPage;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page-size'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ElevatorAdvertApi - functional programming interface
 * @export
 */
export const ElevatorAdvertApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ElevatorAdvertApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Создать объявление на элеваторе для админа покупателя
         * @param {AdminCreateElevatorAdvertBuyBody} adminCreateElevatorAdvertBuyBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminCreateElevatorAdvertBuy(adminCreateElevatorAdvertBuyBody: AdminCreateElevatorAdvertBuyBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminCreateElevatorAdvertResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminCreateElevatorAdvertBuy(adminCreateElevatorAdvertBuyBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Создать объявление на элеваторе для админа продавца
         * @param {AdminCreateElevatorAdvertSellBody} adminCreateElevatorAdvertSellBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminCreateElevatorAdvertSell(adminCreateElevatorAdvertSellBody: AdminCreateElevatorAdvertSellBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminCreateElevatorAdvertResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminCreateElevatorAdvertSell(adminCreateElevatorAdvertSellBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} elevatorAdvertId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminElevatorAdvertControllerAdminGetElevatorAdvert(elevatorAdvertId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminGetElevatorAdvertResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminElevatorAdvertControllerAdminGetElevatorAdvert(elevatorAdvertId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [currentPage] Номер текущей страницы
         * @param {number} [pageSize] Количество элементов на странице
         * @param {Array<ElevatorOfferStatus>} [statuses] Статусы объявлений
         * @param {Array<number>} [elevatorIds] Массив с id элеваторов
         * @param {Array<number>} [companyIds] Массив с id компаний
         * @param {Array<number>} [elevatorAdvertIds] Массив с id объявлений
         * @param {'BUY' | 'SELL'} [type] Тип объявления
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminElevatorAdvertControllerAdminGetElevatorAdverts(currentPage?: number, pageSize?: number, statuses?: Array<ElevatorOfferStatus>, elevatorIds?: Array<number>, companyIds?: Array<number>, elevatorAdvertIds?: Array<number>, type?: 'BUY' | 'SELL', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminGetElevatorAdvertsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminElevatorAdvertControllerAdminGetElevatorAdverts(currentPage, pageSize, statuses, elevatorIds, companyIds, elevatorAdvertIds, type, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {AdminUpdateElevatorAdvertBody} adminUpdateElevatorAdvertBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminElevatorAdvertControllerUpdateElevatorAdvert(id: number, adminUpdateElevatorAdvertBody: AdminUpdateElevatorAdvertBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminElevatorAdvertControllerUpdateElevatorAdvert(id, adminUpdateElevatorAdvertBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} elevatorAdvertId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminElevatorAdvertControllerUpdateElevatorAdvertModerator(elevatorAdvertId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminElevatorAdvertControllerUpdateElevatorAdvertModerator(elevatorAdvertId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {AdminUpdateElevatorAdvertStatusBody} adminUpdateElevatorAdvertStatusBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminElevatorAdvertControllerUpdateElevatorAdvertStatus(id: number, adminUpdateElevatorAdvertStatusBody: AdminUpdateElevatorAdvertStatusBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminElevatorAdvertControllerUpdateElevatorAdvertStatus(id, adminUpdateElevatorAdvertStatusBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Создать объявление на элеваторе для покупателя
         * @param {CreateElevatorAdvertBuyBody} createElevatorAdvertBuyBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createElevatorAdvertBuy(createElevatorAdvertBuyBody: CreateElevatorAdvertBuyBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateElevatorAdvertResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createElevatorAdvertBuy(createElevatorAdvertBuyBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Создать объявление на элеваторе для продавца
         * @param {CreateElevatorAdvertSellBody} createElevatorAdvertSellBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createElevatorAdvertSell(createElevatorAdvertSellBody: CreateElevatorAdvertSellBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateElevatorAdvertResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createElevatorAdvertSell(createElevatorAdvertSellBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {number} userCompanyId Id компании, пользователь которой делает запрос
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async elevatorAdvertControllerGetElevatorAdvert(id: number, userCompanyId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetElevatorAdvertResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.elevatorAdvertControllerGetElevatorAdvert(id, userCompanyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} userCompanyId Id компании, пользователь которой делает запрос
         * @param {number} elevatorId Id элеватора
         * @param {number} [currentPage] Номер текущей страницы
         * @param {number} [pageSize] Количество элементов на странице
         * @param {Array<string>} [productTypes] Список типов товаров
         * @param {number} [companyId] Id компании
         * @param {'BUY' | 'SELL'} [type] Тип объявления
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async elevatorAdvertControllerGetElevatorAdverts(userCompanyId: number, elevatorId: number, currentPage?: number, pageSize?: number, productTypes?: Array<string>, companyId?: number, type?: 'BUY' | 'SELL', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetElevatorAdvertsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.elevatorAdvertControllerGetElevatorAdverts(userCompanyId, elevatorId, currentPage, pageSize, productTypes, companyId, type, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} userCompanyId Id компании, пользователь которой делает запрос
         * @param {number} [currentPage] Номер текущей страницы
         * @param {number} [pageSize] Количество элементов на странице
         * @param {'BUY' | 'SELL'} [type] Тип объявления
         * @param {Array<ElevatorOfferStatus>} [statuses] Статусы объявлений
         * @param {Array<number>} [elevatorIds] Массив с id элеваторов
         * @param {Array<string>} [productTypes] Список типов товаров
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async elevatorAdvertControllerGetMyElevatorAdverts(userCompanyId: number, currentPage?: number, pageSize?: number, type?: 'BUY' | 'SELL', statuses?: Array<ElevatorOfferStatus>, elevatorIds?: Array<number>, productTypes?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetMyElevatorAdvertsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.elevatorAdvertControllerGetMyElevatorAdverts(userCompanyId, currentPage, pageSize, type, statuses, elevatorIds, productTypes, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} elevatorAdvertId 
         * @param {number} [currentPage] Номер текущей страницы
         * @param {number} [pageSize] Количество элементов на странице
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async elevatorAdvertControllerGetSimilarElevators(elevatorAdvertId: number, currentPage?: number, pageSize?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetSimilarElevatorsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.elevatorAdvertControllerGetSimilarElevators(elevatorAdvertId, currentPage, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdateElevatorAdvertPriceBody} updateElevatorAdvertPriceBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async elevatorAdvertControllerUpdateElevatorAdvertPrice(id: number, updateElevatorAdvertPriceBody: UpdateElevatorAdvertPriceBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.elevatorAdvertControllerUpdateElevatorAdvertPrice(id, updateElevatorAdvertPriceBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdateElevatorAdvertStatusBody} updateElevatorAdvertStatusBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async elevatorAdvertControllerUpdateElevatorAdvertStatus(id: number, updateElevatorAdvertStatusBody: UpdateElevatorAdvertStatusBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.elevatorAdvertControllerUpdateElevatorAdvertStatus(id, updateElevatorAdvertStatusBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Получить отчёт по объявлению в xlsx
         * @param {number} elevatorOfferId 
         * @param {number} userCompanyId Id компании, пользователь которой делает запрос
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getElevatorOfferReport(elevatorOfferId: number, userCompanyId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getElevatorOfferReport(elevatorOfferId, userCompanyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Получить похожие объявления
         * @param {number} id 
         * @param {number} [currentPage] Номер текущей страницы
         * @param {number} [pageSize] Количество элементов на странице
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSimilarElevatorOffers(id: number, currentPage?: number, pageSize?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetSimilarElevatorOffersResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSimilarElevatorOffers(id, currentPage, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ElevatorAdvertApi - factory interface
 * @export
 */
export const ElevatorAdvertApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ElevatorAdvertApiFp(configuration)
    return {
        /**
         * 
         * @summary Создать объявление на элеваторе для админа покупателя
         * @param {AdminCreateElevatorAdvertBuyBody} adminCreateElevatorAdvertBuyBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCreateElevatorAdvertBuy(adminCreateElevatorAdvertBuyBody: AdminCreateElevatorAdvertBuyBody, options?: any): AxiosPromise<AdminCreateElevatorAdvertResponse> {
            return localVarFp.adminCreateElevatorAdvertBuy(adminCreateElevatorAdvertBuyBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Создать объявление на элеваторе для админа продавца
         * @param {AdminCreateElevatorAdvertSellBody} adminCreateElevatorAdvertSellBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCreateElevatorAdvertSell(adminCreateElevatorAdvertSellBody: AdminCreateElevatorAdvertSellBody, options?: any): AxiosPromise<AdminCreateElevatorAdvertResponse> {
            return localVarFp.adminCreateElevatorAdvertSell(adminCreateElevatorAdvertSellBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} elevatorAdvertId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminElevatorAdvertControllerAdminGetElevatorAdvert(elevatorAdvertId: number, options?: any): AxiosPromise<AdminGetElevatorAdvertResponse> {
            return localVarFp.adminElevatorAdvertControllerAdminGetElevatorAdvert(elevatorAdvertId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [currentPage] Номер текущей страницы
         * @param {number} [pageSize] Количество элементов на странице
         * @param {Array<ElevatorOfferStatus>} [statuses] Статусы объявлений
         * @param {Array<number>} [elevatorIds] Массив с id элеваторов
         * @param {Array<number>} [companyIds] Массив с id компаний
         * @param {Array<number>} [elevatorAdvertIds] Массив с id объявлений
         * @param {'BUY' | 'SELL'} [type] Тип объявления
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminElevatorAdvertControllerAdminGetElevatorAdverts(currentPage?: number, pageSize?: number, statuses?: Array<ElevatorOfferStatus>, elevatorIds?: Array<number>, companyIds?: Array<number>, elevatorAdvertIds?: Array<number>, type?: 'BUY' | 'SELL', options?: any): AxiosPromise<AdminGetElevatorAdvertsResponse> {
            return localVarFp.adminElevatorAdvertControllerAdminGetElevatorAdverts(currentPage, pageSize, statuses, elevatorIds, companyIds, elevatorAdvertIds, type, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {AdminUpdateElevatorAdvertBody} adminUpdateElevatorAdvertBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminElevatorAdvertControllerUpdateElevatorAdvert(id: number, adminUpdateElevatorAdvertBody: AdminUpdateElevatorAdvertBody, options?: any): AxiosPromise<void> {
            return localVarFp.adminElevatorAdvertControllerUpdateElevatorAdvert(id, adminUpdateElevatorAdvertBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} elevatorAdvertId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminElevatorAdvertControllerUpdateElevatorAdvertModerator(elevatorAdvertId: number, options?: any): AxiosPromise<void> {
            return localVarFp.adminElevatorAdvertControllerUpdateElevatorAdvertModerator(elevatorAdvertId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {AdminUpdateElevatorAdvertStatusBody} adminUpdateElevatorAdvertStatusBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminElevatorAdvertControllerUpdateElevatorAdvertStatus(id: number, adminUpdateElevatorAdvertStatusBody: AdminUpdateElevatorAdvertStatusBody, options?: any): AxiosPromise<void> {
            return localVarFp.adminElevatorAdvertControllerUpdateElevatorAdvertStatus(id, adminUpdateElevatorAdvertStatusBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Создать объявление на элеваторе для покупателя
         * @param {CreateElevatorAdvertBuyBody} createElevatorAdvertBuyBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createElevatorAdvertBuy(createElevatorAdvertBuyBody: CreateElevatorAdvertBuyBody, options?: any): AxiosPromise<CreateElevatorAdvertResponse> {
            return localVarFp.createElevatorAdvertBuy(createElevatorAdvertBuyBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Создать объявление на элеваторе для продавца
         * @param {CreateElevatorAdvertSellBody} createElevatorAdvertSellBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createElevatorAdvertSell(createElevatorAdvertSellBody: CreateElevatorAdvertSellBody, options?: any): AxiosPromise<CreateElevatorAdvertResponse> {
            return localVarFp.createElevatorAdvertSell(createElevatorAdvertSellBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {number} userCompanyId Id компании, пользователь которой делает запрос
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        elevatorAdvertControllerGetElevatorAdvert(id: number, userCompanyId: number, options?: any): AxiosPromise<GetElevatorAdvertResponse> {
            return localVarFp.elevatorAdvertControllerGetElevatorAdvert(id, userCompanyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} userCompanyId Id компании, пользователь которой делает запрос
         * @param {number} elevatorId Id элеватора
         * @param {number} [currentPage] Номер текущей страницы
         * @param {number} [pageSize] Количество элементов на странице
         * @param {Array<string>} [productTypes] Список типов товаров
         * @param {number} [companyId] Id компании
         * @param {'BUY' | 'SELL'} [type] Тип объявления
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        elevatorAdvertControllerGetElevatorAdverts(userCompanyId: number, elevatorId: number, currentPage?: number, pageSize?: number, productTypes?: Array<string>, companyId?: number, type?: 'BUY' | 'SELL', options?: any): AxiosPromise<GetElevatorAdvertsResponse> {
            return localVarFp.elevatorAdvertControllerGetElevatorAdverts(userCompanyId, elevatorId, currentPage, pageSize, productTypes, companyId, type, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} userCompanyId Id компании, пользователь которой делает запрос
         * @param {number} [currentPage] Номер текущей страницы
         * @param {number} [pageSize] Количество элементов на странице
         * @param {'BUY' | 'SELL'} [type] Тип объявления
         * @param {Array<ElevatorOfferStatus>} [statuses] Статусы объявлений
         * @param {Array<number>} [elevatorIds] Массив с id элеваторов
         * @param {Array<string>} [productTypes] Список типов товаров
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        elevatorAdvertControllerGetMyElevatorAdverts(userCompanyId: number, currentPage?: number, pageSize?: number, type?: 'BUY' | 'SELL', statuses?: Array<ElevatorOfferStatus>, elevatorIds?: Array<number>, productTypes?: Array<string>, options?: any): AxiosPromise<GetMyElevatorAdvertsResponse> {
            return localVarFp.elevatorAdvertControllerGetMyElevatorAdverts(userCompanyId, currentPage, pageSize, type, statuses, elevatorIds, productTypes, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} elevatorAdvertId 
         * @param {number} [currentPage] Номер текущей страницы
         * @param {number} [pageSize] Количество элементов на странице
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        elevatorAdvertControllerGetSimilarElevators(elevatorAdvertId: number, currentPage?: number, pageSize?: number, options?: any): AxiosPromise<GetSimilarElevatorsResponse> {
            return localVarFp.elevatorAdvertControllerGetSimilarElevators(elevatorAdvertId, currentPage, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdateElevatorAdvertPriceBody} updateElevatorAdvertPriceBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        elevatorAdvertControllerUpdateElevatorAdvertPrice(id: number, updateElevatorAdvertPriceBody: UpdateElevatorAdvertPriceBody, options?: any): AxiosPromise<void> {
            return localVarFp.elevatorAdvertControllerUpdateElevatorAdvertPrice(id, updateElevatorAdvertPriceBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdateElevatorAdvertStatusBody} updateElevatorAdvertStatusBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        elevatorAdvertControllerUpdateElevatorAdvertStatus(id: number, updateElevatorAdvertStatusBody: UpdateElevatorAdvertStatusBody, options?: any): AxiosPromise<void> {
            return localVarFp.elevatorAdvertControllerUpdateElevatorAdvertStatus(id, updateElevatorAdvertStatusBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получить отчёт по объявлению в xlsx
         * @param {number} elevatorOfferId 
         * @param {number} userCompanyId Id компании, пользователь которой делает запрос
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getElevatorOfferReport(elevatorOfferId: number, userCompanyId: number, options?: any): AxiosPromise<void> {
            return localVarFp.getElevatorOfferReport(elevatorOfferId, userCompanyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получить похожие объявления
         * @param {number} id 
         * @param {number} [currentPage] Номер текущей страницы
         * @param {number} [pageSize] Количество элементов на странице
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSimilarElevatorOffers(id: number, currentPage?: number, pageSize?: number, options?: any): AxiosPromise<GetSimilarElevatorOffersResponse> {
            return localVarFp.getSimilarElevatorOffers(id, currentPage, pageSize, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for adminCreateElevatorAdvertBuy operation in ElevatorAdvertApi.
 * @export
 * @interface ElevatorAdvertApiAdminCreateElevatorAdvertBuyRequest
 */
export interface ElevatorAdvertApiAdminCreateElevatorAdvertBuyRequest {
    /**
     * 
     * @type {AdminCreateElevatorAdvertBuyBody}
     * @memberof ElevatorAdvertApiAdminCreateElevatorAdvertBuy
     */
    readonly adminCreateElevatorAdvertBuyBody: AdminCreateElevatorAdvertBuyBody
}

/**
 * Request parameters for adminCreateElevatorAdvertSell operation in ElevatorAdvertApi.
 * @export
 * @interface ElevatorAdvertApiAdminCreateElevatorAdvertSellRequest
 */
export interface ElevatorAdvertApiAdminCreateElevatorAdvertSellRequest {
    /**
     * 
     * @type {AdminCreateElevatorAdvertSellBody}
     * @memberof ElevatorAdvertApiAdminCreateElevatorAdvertSell
     */
    readonly adminCreateElevatorAdvertSellBody: AdminCreateElevatorAdvertSellBody
}

/**
 * Request parameters for adminElevatorAdvertControllerAdminGetElevatorAdvert operation in ElevatorAdvertApi.
 * @export
 * @interface ElevatorAdvertApiAdminElevatorAdvertControllerAdminGetElevatorAdvertRequest
 */
export interface ElevatorAdvertApiAdminElevatorAdvertControllerAdminGetElevatorAdvertRequest {
    /**
     * 
     * @type {number}
     * @memberof ElevatorAdvertApiAdminElevatorAdvertControllerAdminGetElevatorAdvert
     */
    readonly elevatorAdvertId: number
}

/**
 * Request parameters for adminElevatorAdvertControllerAdminGetElevatorAdverts operation in ElevatorAdvertApi.
 * @export
 * @interface ElevatorAdvertApiAdminElevatorAdvertControllerAdminGetElevatorAdvertsRequest
 */
export interface ElevatorAdvertApiAdminElevatorAdvertControllerAdminGetElevatorAdvertsRequest {
    /**
     * Номер текущей страницы
     * @type {number}
     * @memberof ElevatorAdvertApiAdminElevatorAdvertControllerAdminGetElevatorAdverts
     */
    readonly currentPage?: number

    /**
     * Количество элементов на странице
     * @type {number}
     * @memberof ElevatorAdvertApiAdminElevatorAdvertControllerAdminGetElevatorAdverts
     */
    readonly pageSize?: number

    /**
     * Статусы объявлений
     * @type {Array<ElevatorOfferStatus>}
     * @memberof ElevatorAdvertApiAdminElevatorAdvertControllerAdminGetElevatorAdverts
     */
    readonly statuses?: Array<ElevatorOfferStatus>

    /**
     * Массив с id элеваторов
     * @type {Array<number>}
     * @memberof ElevatorAdvertApiAdminElevatorAdvertControllerAdminGetElevatorAdverts
     */
    readonly elevatorIds?: Array<number>

    /**
     * Массив с id компаний
     * @type {Array<number>}
     * @memberof ElevatorAdvertApiAdminElevatorAdvertControllerAdminGetElevatorAdverts
     */
    readonly companyIds?: Array<number>

    /**
     * Массив с id объявлений
     * @type {Array<number>}
     * @memberof ElevatorAdvertApiAdminElevatorAdvertControllerAdminGetElevatorAdverts
     */
    readonly elevatorAdvertIds?: Array<number>

    /**
     * Тип объявления
     * @type {'BUY' | 'SELL'}
     * @memberof ElevatorAdvertApiAdminElevatorAdvertControllerAdminGetElevatorAdverts
     */
    readonly type?: 'BUY' | 'SELL'
}

/**
 * Request parameters for adminElevatorAdvertControllerUpdateElevatorAdvert operation in ElevatorAdvertApi.
 * @export
 * @interface ElevatorAdvertApiAdminElevatorAdvertControllerUpdateElevatorAdvertRequest
 */
export interface ElevatorAdvertApiAdminElevatorAdvertControllerUpdateElevatorAdvertRequest {
    /**
     * 
     * @type {number}
     * @memberof ElevatorAdvertApiAdminElevatorAdvertControllerUpdateElevatorAdvert
     */
    readonly id: number

    /**
     * 
     * @type {AdminUpdateElevatorAdvertBody}
     * @memberof ElevatorAdvertApiAdminElevatorAdvertControllerUpdateElevatorAdvert
     */
    readonly adminUpdateElevatorAdvertBody: AdminUpdateElevatorAdvertBody
}

/**
 * Request parameters for adminElevatorAdvertControllerUpdateElevatorAdvertModerator operation in ElevatorAdvertApi.
 * @export
 * @interface ElevatorAdvertApiAdminElevatorAdvertControllerUpdateElevatorAdvertModeratorRequest
 */
export interface ElevatorAdvertApiAdminElevatorAdvertControllerUpdateElevatorAdvertModeratorRequest {
    /**
     * 
     * @type {number}
     * @memberof ElevatorAdvertApiAdminElevatorAdvertControllerUpdateElevatorAdvertModerator
     */
    readonly elevatorAdvertId: number
}

/**
 * Request parameters for adminElevatorAdvertControllerUpdateElevatorAdvertStatus operation in ElevatorAdvertApi.
 * @export
 * @interface ElevatorAdvertApiAdminElevatorAdvertControllerUpdateElevatorAdvertStatusRequest
 */
export interface ElevatorAdvertApiAdminElevatorAdvertControllerUpdateElevatorAdvertStatusRequest {
    /**
     * 
     * @type {number}
     * @memberof ElevatorAdvertApiAdminElevatorAdvertControllerUpdateElevatorAdvertStatus
     */
    readonly id: number

    /**
     * 
     * @type {AdminUpdateElevatorAdvertStatusBody}
     * @memberof ElevatorAdvertApiAdminElevatorAdvertControllerUpdateElevatorAdvertStatus
     */
    readonly adminUpdateElevatorAdvertStatusBody: AdminUpdateElevatorAdvertStatusBody
}

/**
 * Request parameters for createElevatorAdvertBuy operation in ElevatorAdvertApi.
 * @export
 * @interface ElevatorAdvertApiCreateElevatorAdvertBuyRequest
 */
export interface ElevatorAdvertApiCreateElevatorAdvertBuyRequest {
    /**
     * 
     * @type {CreateElevatorAdvertBuyBody}
     * @memberof ElevatorAdvertApiCreateElevatorAdvertBuy
     */
    readonly createElevatorAdvertBuyBody: CreateElevatorAdvertBuyBody
}

/**
 * Request parameters for createElevatorAdvertSell operation in ElevatorAdvertApi.
 * @export
 * @interface ElevatorAdvertApiCreateElevatorAdvertSellRequest
 */
export interface ElevatorAdvertApiCreateElevatorAdvertSellRequest {
    /**
     * 
     * @type {CreateElevatorAdvertSellBody}
     * @memberof ElevatorAdvertApiCreateElevatorAdvertSell
     */
    readonly createElevatorAdvertSellBody: CreateElevatorAdvertSellBody
}

/**
 * Request parameters for elevatorAdvertControllerGetElevatorAdvert operation in ElevatorAdvertApi.
 * @export
 * @interface ElevatorAdvertApiElevatorAdvertControllerGetElevatorAdvertRequest
 */
export interface ElevatorAdvertApiElevatorAdvertControllerGetElevatorAdvertRequest {
    /**
     * 
     * @type {number}
     * @memberof ElevatorAdvertApiElevatorAdvertControllerGetElevatorAdvert
     */
    readonly id: number

    /**
     * Id компании, пользователь которой делает запрос
     * @type {number}
     * @memberof ElevatorAdvertApiElevatorAdvertControllerGetElevatorAdvert
     */
    readonly userCompanyId: number
}

/**
 * Request parameters for elevatorAdvertControllerGetElevatorAdverts operation in ElevatorAdvertApi.
 * @export
 * @interface ElevatorAdvertApiElevatorAdvertControllerGetElevatorAdvertsRequest
 */
export interface ElevatorAdvertApiElevatorAdvertControllerGetElevatorAdvertsRequest {
    /**
     * Id компании, пользователь которой делает запрос
     * @type {number}
     * @memberof ElevatorAdvertApiElevatorAdvertControllerGetElevatorAdverts
     */
    readonly userCompanyId: number

    /**
     * Id элеватора
     * @type {number}
     * @memberof ElevatorAdvertApiElevatorAdvertControllerGetElevatorAdverts
     */
    readonly elevatorId: number

    /**
     * Номер текущей страницы
     * @type {number}
     * @memberof ElevatorAdvertApiElevatorAdvertControllerGetElevatorAdverts
     */
    readonly currentPage?: number

    /**
     * Количество элементов на странице
     * @type {number}
     * @memberof ElevatorAdvertApiElevatorAdvertControllerGetElevatorAdverts
     */
    readonly pageSize?: number

    /**
     * Список типов товаров
     * @type {Array<string>}
     * @memberof ElevatorAdvertApiElevatorAdvertControllerGetElevatorAdverts
     */
    readonly productTypes?: Array<string>

    /**
     * Id компании
     * @type {number}
     * @memberof ElevatorAdvertApiElevatorAdvertControllerGetElevatorAdverts
     */
    readonly companyId?: number

    /**
     * Тип объявления
     * @type {'BUY' | 'SELL'}
     * @memberof ElevatorAdvertApiElevatorAdvertControllerGetElevatorAdverts
     */
    readonly type?: 'BUY' | 'SELL'
}

/**
 * Request parameters for elevatorAdvertControllerGetMyElevatorAdverts operation in ElevatorAdvertApi.
 * @export
 * @interface ElevatorAdvertApiElevatorAdvertControllerGetMyElevatorAdvertsRequest
 */
export interface ElevatorAdvertApiElevatorAdvertControllerGetMyElevatorAdvertsRequest {
    /**
     * Id компании, пользователь которой делает запрос
     * @type {number}
     * @memberof ElevatorAdvertApiElevatorAdvertControllerGetMyElevatorAdverts
     */
    readonly userCompanyId: number

    /**
     * Номер текущей страницы
     * @type {number}
     * @memberof ElevatorAdvertApiElevatorAdvertControllerGetMyElevatorAdverts
     */
    readonly currentPage?: number

    /**
     * Количество элементов на странице
     * @type {number}
     * @memberof ElevatorAdvertApiElevatorAdvertControllerGetMyElevatorAdverts
     */
    readonly pageSize?: number

    /**
     * Тип объявления
     * @type {'BUY' | 'SELL'}
     * @memberof ElevatorAdvertApiElevatorAdvertControllerGetMyElevatorAdverts
     */
    readonly type?: 'BUY' | 'SELL'

    /**
     * Статусы объявлений
     * @type {Array<ElevatorOfferStatus>}
     * @memberof ElevatorAdvertApiElevatorAdvertControllerGetMyElevatorAdverts
     */
    readonly statuses?: Array<ElevatorOfferStatus>

    /**
     * Массив с id элеваторов
     * @type {Array<number>}
     * @memberof ElevatorAdvertApiElevatorAdvertControllerGetMyElevatorAdverts
     */
    readonly elevatorIds?: Array<number>

    /**
     * Список типов товаров
     * @type {Array<string>}
     * @memberof ElevatorAdvertApiElevatorAdvertControllerGetMyElevatorAdverts
     */
    readonly productTypes?: Array<string>
}

/**
 * Request parameters for elevatorAdvertControllerGetSimilarElevators operation in ElevatorAdvertApi.
 * @export
 * @interface ElevatorAdvertApiElevatorAdvertControllerGetSimilarElevatorsRequest
 */
export interface ElevatorAdvertApiElevatorAdvertControllerGetSimilarElevatorsRequest {
    /**
     * 
     * @type {number}
     * @memberof ElevatorAdvertApiElevatorAdvertControllerGetSimilarElevators
     */
    readonly elevatorAdvertId: number

    /**
     * Номер текущей страницы
     * @type {number}
     * @memberof ElevatorAdvertApiElevatorAdvertControllerGetSimilarElevators
     */
    readonly currentPage?: number

    /**
     * Количество элементов на странице
     * @type {number}
     * @memberof ElevatorAdvertApiElevatorAdvertControllerGetSimilarElevators
     */
    readonly pageSize?: number
}

/**
 * Request parameters for elevatorAdvertControllerUpdateElevatorAdvertPrice operation in ElevatorAdvertApi.
 * @export
 * @interface ElevatorAdvertApiElevatorAdvertControllerUpdateElevatorAdvertPriceRequest
 */
export interface ElevatorAdvertApiElevatorAdvertControllerUpdateElevatorAdvertPriceRequest {
    /**
     * 
     * @type {number}
     * @memberof ElevatorAdvertApiElevatorAdvertControllerUpdateElevatorAdvertPrice
     */
    readonly id: number

    /**
     * 
     * @type {UpdateElevatorAdvertPriceBody}
     * @memberof ElevatorAdvertApiElevatorAdvertControllerUpdateElevatorAdvertPrice
     */
    readonly updateElevatorAdvertPriceBody: UpdateElevatorAdvertPriceBody
}

/**
 * Request parameters for elevatorAdvertControllerUpdateElevatorAdvertStatus operation in ElevatorAdvertApi.
 * @export
 * @interface ElevatorAdvertApiElevatorAdvertControllerUpdateElevatorAdvertStatusRequest
 */
export interface ElevatorAdvertApiElevatorAdvertControllerUpdateElevatorAdvertStatusRequest {
    /**
     * 
     * @type {number}
     * @memberof ElevatorAdvertApiElevatorAdvertControllerUpdateElevatorAdvertStatus
     */
    readonly id: number

    /**
     * 
     * @type {UpdateElevatorAdvertStatusBody}
     * @memberof ElevatorAdvertApiElevatorAdvertControllerUpdateElevatorAdvertStatus
     */
    readonly updateElevatorAdvertStatusBody: UpdateElevatorAdvertStatusBody
}

/**
 * Request parameters for getElevatorOfferReport operation in ElevatorAdvertApi.
 * @export
 * @interface ElevatorAdvertApiGetElevatorOfferReportRequest
 */
export interface ElevatorAdvertApiGetElevatorOfferReportRequest {
    /**
     * 
     * @type {number}
     * @memberof ElevatorAdvertApiGetElevatorOfferReport
     */
    readonly elevatorOfferId: number

    /**
     * Id компании, пользователь которой делает запрос
     * @type {number}
     * @memberof ElevatorAdvertApiGetElevatorOfferReport
     */
    readonly userCompanyId: number
}

/**
 * Request parameters for getSimilarElevatorOffers operation in ElevatorAdvertApi.
 * @export
 * @interface ElevatorAdvertApiGetSimilarElevatorOffersRequest
 */
export interface ElevatorAdvertApiGetSimilarElevatorOffersRequest {
    /**
     * 
     * @type {number}
     * @memberof ElevatorAdvertApiGetSimilarElevatorOffers
     */
    readonly id: number

    /**
     * Номер текущей страницы
     * @type {number}
     * @memberof ElevatorAdvertApiGetSimilarElevatorOffers
     */
    readonly currentPage?: number

    /**
     * Количество элементов на странице
     * @type {number}
     * @memberof ElevatorAdvertApiGetSimilarElevatorOffers
     */
    readonly pageSize?: number
}

/**
 * ElevatorAdvertApi - object-oriented interface
 * @export
 * @class ElevatorAdvertApi
 * @extends {BaseAPI}
 */
export class ElevatorAdvertApi extends BaseAPI {
    /**
     * 
     * @summary Создать объявление на элеваторе для админа покупателя
     * @param {ElevatorAdvertApiAdminCreateElevatorAdvertBuyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ElevatorAdvertApi
     */
    public adminCreateElevatorAdvertBuy(requestParameters: ElevatorAdvertApiAdminCreateElevatorAdvertBuyRequest, options?: AxiosRequestConfig) {
        return ElevatorAdvertApiFp(this.configuration).adminCreateElevatorAdvertBuy(requestParameters.adminCreateElevatorAdvertBuyBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Создать объявление на элеваторе для админа продавца
     * @param {ElevatorAdvertApiAdminCreateElevatorAdvertSellRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ElevatorAdvertApi
     */
    public adminCreateElevatorAdvertSell(requestParameters: ElevatorAdvertApiAdminCreateElevatorAdvertSellRequest, options?: AxiosRequestConfig) {
        return ElevatorAdvertApiFp(this.configuration).adminCreateElevatorAdvertSell(requestParameters.adminCreateElevatorAdvertSellBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ElevatorAdvertApiAdminElevatorAdvertControllerAdminGetElevatorAdvertRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ElevatorAdvertApi
     */
    public adminElevatorAdvertControllerAdminGetElevatorAdvert(requestParameters: ElevatorAdvertApiAdminElevatorAdvertControllerAdminGetElevatorAdvertRequest, options?: AxiosRequestConfig) {
        return ElevatorAdvertApiFp(this.configuration).adminElevatorAdvertControllerAdminGetElevatorAdvert(requestParameters.elevatorAdvertId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ElevatorAdvertApiAdminElevatorAdvertControllerAdminGetElevatorAdvertsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ElevatorAdvertApi
     */
    public adminElevatorAdvertControllerAdminGetElevatorAdverts(requestParameters: ElevatorAdvertApiAdminElevatorAdvertControllerAdminGetElevatorAdvertsRequest = {}, options?: AxiosRequestConfig) {
        return ElevatorAdvertApiFp(this.configuration).adminElevatorAdvertControllerAdminGetElevatorAdverts(requestParameters.currentPage, requestParameters.pageSize, requestParameters.statuses, requestParameters.elevatorIds, requestParameters.companyIds, requestParameters.elevatorAdvertIds, requestParameters.type, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ElevatorAdvertApiAdminElevatorAdvertControllerUpdateElevatorAdvertRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ElevatorAdvertApi
     */
    public adminElevatorAdvertControllerUpdateElevatorAdvert(requestParameters: ElevatorAdvertApiAdminElevatorAdvertControllerUpdateElevatorAdvertRequest, options?: AxiosRequestConfig) {
        return ElevatorAdvertApiFp(this.configuration).adminElevatorAdvertControllerUpdateElevatorAdvert(requestParameters.id, requestParameters.adminUpdateElevatorAdvertBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ElevatorAdvertApiAdminElevatorAdvertControllerUpdateElevatorAdvertModeratorRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ElevatorAdvertApi
     */
    public adminElevatorAdvertControllerUpdateElevatorAdvertModerator(requestParameters: ElevatorAdvertApiAdminElevatorAdvertControllerUpdateElevatorAdvertModeratorRequest, options?: AxiosRequestConfig) {
        return ElevatorAdvertApiFp(this.configuration).adminElevatorAdvertControllerUpdateElevatorAdvertModerator(requestParameters.elevatorAdvertId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ElevatorAdvertApiAdminElevatorAdvertControllerUpdateElevatorAdvertStatusRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ElevatorAdvertApi
     */
    public adminElevatorAdvertControllerUpdateElevatorAdvertStatus(requestParameters: ElevatorAdvertApiAdminElevatorAdvertControllerUpdateElevatorAdvertStatusRequest, options?: AxiosRequestConfig) {
        return ElevatorAdvertApiFp(this.configuration).adminElevatorAdvertControllerUpdateElevatorAdvertStatus(requestParameters.id, requestParameters.adminUpdateElevatorAdvertStatusBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Создать объявление на элеваторе для покупателя
     * @param {ElevatorAdvertApiCreateElevatorAdvertBuyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ElevatorAdvertApi
     */
    public createElevatorAdvertBuy(requestParameters: ElevatorAdvertApiCreateElevatorAdvertBuyRequest, options?: AxiosRequestConfig) {
        return ElevatorAdvertApiFp(this.configuration).createElevatorAdvertBuy(requestParameters.createElevatorAdvertBuyBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Создать объявление на элеваторе для продавца
     * @param {ElevatorAdvertApiCreateElevatorAdvertSellRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ElevatorAdvertApi
     */
    public createElevatorAdvertSell(requestParameters: ElevatorAdvertApiCreateElevatorAdvertSellRequest, options?: AxiosRequestConfig) {
        return ElevatorAdvertApiFp(this.configuration).createElevatorAdvertSell(requestParameters.createElevatorAdvertSellBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ElevatorAdvertApiElevatorAdvertControllerGetElevatorAdvertRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ElevatorAdvertApi
     */
    public elevatorAdvertControllerGetElevatorAdvert(requestParameters: ElevatorAdvertApiElevatorAdvertControllerGetElevatorAdvertRequest, options?: AxiosRequestConfig) {
        return ElevatorAdvertApiFp(this.configuration).elevatorAdvertControllerGetElevatorAdvert(requestParameters.id, requestParameters.userCompanyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ElevatorAdvertApiElevatorAdvertControllerGetElevatorAdvertsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ElevatorAdvertApi
     */
    public elevatorAdvertControllerGetElevatorAdverts(requestParameters: ElevatorAdvertApiElevatorAdvertControllerGetElevatorAdvertsRequest, options?: AxiosRequestConfig) {
        return ElevatorAdvertApiFp(this.configuration).elevatorAdvertControllerGetElevatorAdverts(requestParameters.userCompanyId, requestParameters.elevatorId, requestParameters.currentPage, requestParameters.pageSize, requestParameters.productTypes, requestParameters.companyId, requestParameters.type, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ElevatorAdvertApiElevatorAdvertControllerGetMyElevatorAdvertsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ElevatorAdvertApi
     */
    public elevatorAdvertControllerGetMyElevatorAdverts(requestParameters: ElevatorAdvertApiElevatorAdvertControllerGetMyElevatorAdvertsRequest, options?: AxiosRequestConfig) {
        return ElevatorAdvertApiFp(this.configuration).elevatorAdvertControllerGetMyElevatorAdverts(requestParameters.userCompanyId, requestParameters.currentPage, requestParameters.pageSize, requestParameters.type, requestParameters.statuses, requestParameters.elevatorIds, requestParameters.productTypes, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ElevatorAdvertApiElevatorAdvertControllerGetSimilarElevatorsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ElevatorAdvertApi
     */
    public elevatorAdvertControllerGetSimilarElevators(requestParameters: ElevatorAdvertApiElevatorAdvertControllerGetSimilarElevatorsRequest, options?: AxiosRequestConfig) {
        return ElevatorAdvertApiFp(this.configuration).elevatorAdvertControllerGetSimilarElevators(requestParameters.elevatorAdvertId, requestParameters.currentPage, requestParameters.pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ElevatorAdvertApiElevatorAdvertControllerUpdateElevatorAdvertPriceRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ElevatorAdvertApi
     */
    public elevatorAdvertControllerUpdateElevatorAdvertPrice(requestParameters: ElevatorAdvertApiElevatorAdvertControllerUpdateElevatorAdvertPriceRequest, options?: AxiosRequestConfig) {
        return ElevatorAdvertApiFp(this.configuration).elevatorAdvertControllerUpdateElevatorAdvertPrice(requestParameters.id, requestParameters.updateElevatorAdvertPriceBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ElevatorAdvertApiElevatorAdvertControllerUpdateElevatorAdvertStatusRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ElevatorAdvertApi
     */
    public elevatorAdvertControllerUpdateElevatorAdvertStatus(requestParameters: ElevatorAdvertApiElevatorAdvertControllerUpdateElevatorAdvertStatusRequest, options?: AxiosRequestConfig) {
        return ElevatorAdvertApiFp(this.configuration).elevatorAdvertControllerUpdateElevatorAdvertStatus(requestParameters.id, requestParameters.updateElevatorAdvertStatusBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получить отчёт по объявлению в xlsx
     * @param {ElevatorAdvertApiGetElevatorOfferReportRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ElevatorAdvertApi
     */
    public getElevatorOfferReport(requestParameters: ElevatorAdvertApiGetElevatorOfferReportRequest, options?: AxiosRequestConfig) {
        return ElevatorAdvertApiFp(this.configuration).getElevatorOfferReport(requestParameters.elevatorOfferId, requestParameters.userCompanyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получить похожие объявления
     * @param {ElevatorAdvertApiGetSimilarElevatorOffersRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ElevatorAdvertApi
     */
    public getSimilarElevatorOffers(requestParameters: ElevatorAdvertApiGetSimilarElevatorOffersRequest, options?: AxiosRequestConfig) {
        return ElevatorAdvertApiFp(this.configuration).getSimilarElevatorOffers(requestParameters.id, requestParameters.currentPage, requestParameters.pageSize, options).then((request) => request(this.axios, this.basePath));
    }
}
