import * as yup from 'yup'

import { LoadingTransportType } from '~api/generated'
import { VALIDATORS_MSG } from '~shared/validations'
import { fullPhoneNumberValidator } from '~shared/validations/schemas'

export const loadingInfoCardFormSchema = yup.object({
  contactUser: yup.string().trim().required(VALIDATORS_MSG.required),
  scalesWeightLimit: yup.number().typeError(VALIDATORS_MSG.required).nullable(),
  loadingType: yup
    .mixed()
    .nullable()
    .oneOf([...Object.values(LoadingTransportType), null]),
  hasScales: yup.boolean(),
  openingTime: yup.date().nullable(),
  closingTime: yup.date().nullable(),
  contactPhone: yup
    .string()
    .trim()
    .test('mobilePhone', VALIDATORS_MSG.notFullPhoneNumber, fullPhoneNumberValidator)
    .required(VALIDATORS_MSG.required),
})
