import React, { useMemo } from 'react'

import { Button, Pagination, Table } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import { useNavigate } from 'react-router-dom'

import { CompanyAuctionOfferData, OfferDataTypeEnum } from '~/api/generated'
import { useGetTableHeight, usePagination } from '~/hooks'
import { actionArrowColumn, idColumn } from '~/shared/columns/shared'
import { Badge } from '~/ui-components'
import { showColumn } from '~/utils'
import { getMyResponsesOfferStatus } from '~/utils/auctions/get-my-responses-offer-status'
import { formatNumber } from '~/utils/formatNumber'
import { getNdsString } from '~/utils/getNdsString'
import { getOfferType } from '~/utils/offers/get-offer-type'
import { useGetCompanyAuctionOffers } from '~hooks/auctions'
import { useGetCurrentRole } from '~hooks/auth'
import { useGetDevice } from '~hooks/common'
import { useGetProductTypes } from '~hooks/products/useGetProductTypes'
import { AuctionMobileCard, AuctionsHeader } from '~pages/Auctions/components'
import { REFETCH_INTERVAL } from '~pages/Auctions/utils'
import { useAuth } from '~providers/auth'

import * as S from '~pages/Auctions/AuctionsPage.styled'

export const AuctionMyResponses = () => {
  const navigate = useNavigate()
  const { companyId } = useAuth()
  const {
    company: { isTrader },
  } = useGetCurrentRole()
  const device = useGetDevice()
  const isMobile = device === 'mobile'
  const tableHeight = useGetTableHeight(250)

  const { currentPage, setCurrentPage, pageSize, setPageSize, pageSizeOptions } = usePagination()
  const { data: myAuctionsData, isLoading: isLoadingAuctions } = useGetCompanyAuctionOffers(
    {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      companyId: companyId!,
      currentPage,
      pageSize,
    },
    {
      keepPreviousData: true,
      refetchInterval: REFETCH_INTERVAL,
    }
  )

  const companyAuctionOffers = myAuctionsData?.companyAuctionOffers
  const pagination = myAuctionsData?.pagination

  const { data: products } = useGetProductTypes()

  const productsMap = useMemo(() => new Map(products.map((product) => [product.type, product])), [products])

  const handleRowClick = (record: CompanyAuctionOfferData) => {
    navigate(`${record.auctionId}`)
  }

  const handleDealButtonClick = (evt: React.MouseEvent<HTMLElement, MouseEvent>, id: number | null) => {
    evt.stopPropagation()
    navigate(`/deals/${id}/information`)
  }

  const isShowDealsColumn = useMemo(
    () => !!companyAuctionOffers?.map((offer) => offer.dealId)?.length,
    [companyAuctionOffers]
  )

  const getDealButton = (dealId: number | null) => {
    return <Button onClick={(evt) => handleDealButtonClick(evt, dealId)}>Перейти к сделке</Button>
  }

  const columns: ColumnsType<CompanyAuctionOfferData> = [
    idColumn,
    {
      title: 'Статус ставки',
      dataIndex: 'status',
      width: 160,
      render: (value) => {
        const offerType = getMyResponsesOfferStatus(value)
        return (
          <Badge color={offerType.color} size="s">
            {offerType.name}
          </Badge>
        )
      },
    },
    ...showColumn<CompanyAuctionOfferData>(isTrader, {
      title: 'Тип лота',
      dataIndex: 'type',
      width: 140,
      render: (type: OfferDataTypeEnum) => {
        const offerType = getOfferType(type)
        return (
          <Badge color={offerType.color} size="s">
            {offerType.name}
          </Badge>
        )
      },
    }),
    {
      title: 'Наименование культуры',
      dataIndex: ['product', 'name'],
      width: 200,
      className: 'bold-cell',
    },
    {
      title: 'Объем, т',
      dataIndex: 'volume',
      align: 'right',
      width: 100,
      className: 'bold-cell',
      render: (volume) => {
        return formatNumber(volume)
      },
    },
    {
      title: 'Ставка лота, ₽/т',
      dataIndex: 'auctionOfferPrice',
      align: 'right',
      width: 140,
      className: 'bold-cell',
      render: (auctionOfferPrice) => {
        return formatNumber(auctionOfferPrice)
      },
    },
    {
      title: 'Моя ставка, ₽/т',
      dataIndex: 'price',
      align: 'right',
      width: 140,
      className: 'bold-cell',
      render: (price) => {
        return formatNumber(price)
      },
    },
    {
      title: 'НДС',
      dataIndex: 'includingNds',
      width: '100px',
      render: (includingNds) => {
        return getNdsString(includingNds)
      },
    },
    {
      title: 'Локация',
      dataIndex: 'address',
    },
    ...showColumn<CompanyAuctionOfferData>(isShowDealsColumn, {
      title: '',
      dataIndex: 'dealId',
      width: 100,
      render: (dealId: number) => {
        if (dealId) {
          return getDealButton(dealId)
        }
      },
    }),
    actionArrowColumn,
  ]

  return (
    <>
      <AuctionsHeader isCreateLot={false} />
      {!isMobile ? (
        <S.AuctionTableWrapper>
          <Table<CompanyAuctionOfferData>
            rowKey="auctionId"
            className="table-interactive"
            loading={isLoadingAuctions}
            columns={columns}
            dataSource={companyAuctionOffers}
            scroll={{ x: 'max-content', y: tableHeight }}
            pagination={false}
            onRow={(record) => ({
              onClick: handleRowClick.bind(null, record),
            })}
          />
        </S.AuctionTableWrapper>
      ) : (
        <S.AuctionMobileCardList>
          {companyAuctionOffers?.map((offer) => (
            <AuctionMobileCard
              key={offer.id}
              auctionId={offer.auctionId}
              productName={offer.product.name}
              offerType={isTrader ? offer.type : undefined}
              offerStatus={offer.status}
              volume={offer.volume}
              price={offer.auctionOfferPrice}
              myPrice={offer.price}
              includingNds={offer.includingNds}
              location={offer.address}
              onClick={() => handleRowClick(offer)}
              dealButton={offer.dealId ? getDealButton(offer.dealId) : undefined}
            />
          ))}
        </S.AuctionMobileCardList>
      )}
      {pagination && (
        <Pagination
          current={pagination?.currentPage}
          total={pagination?.total}
          pageSize={pagination?.pageSize}
          pageSizeOptions={pageSizeOptions}
          onShowSizeChange={(x, size) => setPageSize(size)}
          showSizeChanger
          onChange={setCurrentPage}
        />
      )}
    </>
  )
}
