import { useQuery, UseQueryResult } from '@tanstack/react-query'

import {
  ElevatorAdvertOfferApiElevatorAdvertOfferControllerGetAdvertOfferRequest,
  GetElevatorAdvertOfferResponse,
} from '~api/generated'
import { useApi } from '~providers/api'

export const useGetElevatorAdvertOffer = (
  request: ElevatorAdvertOfferApiElevatorAdvertOfferControllerGetAdvertOfferRequest
): UseQueryResult<GetElevatorAdvertOfferResponse> => {
  const api = useApi()

  const fetchFn = async (): Promise<GetElevatorAdvertOfferResponse> => {
    const res = await api.elevatorAdvertOffer.elevatorAdvertOfferControllerGetAdvertOffer(request)

    return res.data
  }

  return useQuery(['elevatorAdvertOffer', request.id], fetchFn, { keepPreviousData: true })
}
