import { useMutation } from '@tanstack/react-query'

import { useUploadFileAndGetId } from '~hooks/_utils'
import { useCreateOfferAdmin } from '~hooks/offer'
import { useApi } from '~providers/api'

import { SubmitAdminModalData } from '~pages/Auctions/components/CreateLotAdminModal/CreateLotAdminModal.types'

export const useCreateAuctionForAdmin = () => {
  const api = useApi()
  const createOfferAndCheckCompany = useCreateOfferAdmin()
  const uploadLabAnalysisAndGetIdFn = useUploadFileAndGetId()

  const createAuctionFn = async (data: SubmitAdminModalData) => {
    const offerData = await createOfferAndCheckCompany.mutateAsync(data)

    const offerId = offerData?.id
    const companyId = offerData?.company?.id

    if (!offerId || !companyId) return

    let auctionId = null
    let laboratoryAnalysisFileKey: string | null = null

    if (data.createOfferBody.type === 'SELL' && data.labAnalysis) {
      laboratoryAnalysisFileKey = await uploadLabAnalysisAndGetIdFn.mutateAsync(data.labAnalysis)
    }

    try {
      auctionId = (
        await api.auction.auctionControllerCreateAuction({
          companyId,
          createAuctionBody: {
            offerId,
            startedAt: (data.startedAt ?? new Date()).toISOString(),
            laboratoryAnalysisFileKey,
          },
        })
      ).data.auctionId
    } catch (err) {
      return Promise.reject(err)
    }

    return auctionId
  }

  return useMutation(createAuctionFn)
}
