import { graphql } from '~api/gql-generated'

export const freightOrderDocumentCreateMutation = graphql(/* GraphQL */ `
  mutation FreightOrderDocumentCreate($input: FreightOrderDocumentCreateInput!) {
    freightOrderDocumentCreate(input: $input) {
      type
      instances {
        id
        status
        createdAt
        number
        formedAt
        rejectionNote
        files {
          name
          size
          mimetype
          source
          id
          status
        }
      }
    }
  }
`)

export const freightOrderDocumentAddFilesMutation = graphql(/* GraphQL */ `
  mutation FreightOrderDocumentAddFiles($input: DocumentFilesCreateInput!, $documentId: Int!) {
    freightOrderDocument(documentId: $documentId) {
      addFiles(input: $input) {
        type
        instances {
          id
          status
          createdAt
          number
          formedAt
          rejectionNote
          files {
            name
            size
            mimetype
            source
            id
            status
          }
        }
      }
    }
  }
`)

export const freightOrderDocumentDeleteMutation = graphql(/* GraphQL */ `
  mutation FreightOrderDocument($documentId: Int!) {
    freightOrderDocument(documentId: $documentId) {
      delete
    }
  }
`)
