import { useMutation } from '@tanstack/react-query'

import { ElevatorAdvertOfferApiElevatorAdvertOfferControllerCancelElevatorAdvertOfferRequest } from '~/api/generated'
import { useApi } from '~providers/api'

export const useCancelElevatorAdvertOffer = () => {
  const api = useApi()

  return useMutation((request: ElevatorAdvertOfferApiElevatorAdvertOfferControllerCancelElevatorAdvertOfferRequest) => {
    return api.elevatorAdvertOffer.elevatorAdvertOfferControllerCancelElevatorAdvertOffer(request)
  })
}
