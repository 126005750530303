import { useState } from 'react'

const PAGINATION_DEFAULT_OPTIONS = {
  currentPage: 1,
  pageSizeOptions: [15, 25, 50, 100],
  pageSize: 100,
}

interface Params {
  currentPage?: number
  pageSizeOptions?: number[]
  pageSize?: number
}

export const usePagination = (params?: Params) => {
  const [currentPage, setCurrentPage] = useState(params?.currentPage ?? PAGINATION_DEFAULT_OPTIONS.currentPage)
  const [pageSize, setPageSize] = useState(params?.pageSize ?? PAGINATION_DEFAULT_OPTIONS.pageSize)

  return {
    currentPage,
    setCurrentPage,
    pageSize,
    setPageSize,
    pageSizeOptions: params?.pageSizeOptions ?? PAGINATION_DEFAULT_OPTIONS.pageSizeOptions,
  }
}
