// export function enumToArray<T extends { [key: string]: number | string }>(t: T): { id: number; name: string }[] {
//   return Object.keys(t)
//     .map((key: string) => {
//       return ({ id: Number(t[key]), name: key.replace('_', ' ') })
//     })
//     .filter((item: { id: number; name: string }) => item.id)
// }

//
// export function enumToHashMap<T extends { [key: string]: number | string }>(t: T): Record<number, string> {
//   return enumToArray(t).reduce((map: Record<number, string>, obj: { id: number; name: string }) => {
//     // eslint-disable-next-line no-param-reassign
//     map[obj.id] = obj.name
//     return map
//   }, {})
// }

export function enumToArray<T extends { [key: string]: string }>(t: T): string[] {
  return Object.keys(t).map((key: string) => {
    return t[key]
  })
}
