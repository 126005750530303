import React, { FC } from 'react'

import { AiOutlineCheckCircle } from 'react-icons/ai'
import { FiAlertCircle, FiInfo } from 'react-icons/fi'

import { Text } from '~/ui-components'

import { QualityIndicatorsProps } from './types'

import * as S from './QualityIndicators.styled'

export const QualityIndicators: FC<QualityIndicatorsProps> = ({
  isLabAnalysis,
  isParametersCorrespondsToGost,
  gost,
}) => {
  if (isLabAnalysis && isParametersCorrespondsToGost) {
    return (
      <S.Wrapper>
        <AiOutlineCheckCircle color="var(--primary-main-color)" size="24" />
        <Text variant="normal">Качественные показатели соответствуют ГОСТ {gost ?? ''}</Text>
      </S.Wrapper>
    )
  }

  if (isParametersCorrespondsToGost) {
    return (
      <S.Wrapper>
        <FiInfo color="var(--info-color)" size="24" />
        <Text variant="normal">Качественные показатели заявлены поставщиком, соответствуют ГОСТ {gost ?? ''}</Text>
      </S.Wrapper>
    )
  }
  return (
    <S.Wrapper>
      <FiAlertCircle color="var(--warning-color)" size="24" />
      <Text variant="normal">Качественные показатели не соответствуют ГОСТ {gost ?? ''}</Text>
    </S.Wrapper>
  )
}
