import * as yup from 'yup'

import { VALIDATORS_MSG } from '~shared/validations'

export const ResponseFormSchema = yup.object({
  rejectionReason: yup
    .string()
    .trim()
    .when('$isAccept', {
      is: (isAccept: boolean) => !isAccept,
      then: (schema) => schema.required(VALIDATORS_MSG.required),
    }),
})
