import React, { useState } from 'react'
import * as S from './Upload.styled'
import { FaPlus } from 'react-icons/fa6'
import { Flex } from 'antd'
import { IoMdClose } from 'react-icons/io'
import { UploadChangeParam } from 'antd/es/upload/interface'
import { Text } from '~/ui-components'
import { IUploadProps } from '~ui-components/components/Upload/Upload.types'

export const Upload: React.FC<IUploadProps> = ({
  uploadProps: {
    listType = 'picture',
    multiple = true,
    beforeUpload = () => false,
    showUploadList = {
      showRemoveIcon: true,
      removeIcon: <IoMdClose />,
    },
    defaultFileList = [],
    onChange,
    ...otherProps
  },
  buttonName = 'Выбрать файл',
}) => {
  const [isEmpty, setIsEmpty] = useState(!defaultFileList.length)

  const onChangeHandle = (props: UploadChangeParam) => {
    setIsEmpty(!props.fileList.length)
    onChange?.(props)
  }

  return (
    <S.StyledUpload
      listType={listType}
      multiple={multiple}
      beforeUpload={beforeUpload}
      showUploadList={showUploadList}
      defaultFileList={defaultFileList}
      onChange={onChangeHandle}
      {...otherProps}
    >
      <Flex vertical gap={16}>
        <S.StyledUploadButton icon={<FaPlus />}>{buttonName}</S.StyledUploadButton>
        {isEmpty && <Text variant="form-read-label">Файл не выбран</Text>}
      </Flex>
    </S.StyledUpload>
  )
}
