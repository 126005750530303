import { useQuery } from '@tanstack/react-query'

interface AppVersionJson {
  release: string
}

interface UseCheckNewAppVersionResponse {
  hasNewVersion: boolean
  lastCheckAt: number
}

export const useCheckNewAppVersion = () => {
  const fetchFn = async (): Promise<UseCheckNewAppVersionResponse> => {
    const versionJson = await fetch(`${process.env.PUBLIC_URL}/version.json?t=${Date.now()}`).then(
      (r) => r.json() as unknown as AppVersionJson
    )
    const versionEnv = process.env.REACT_APP_RELEASE as string

    console.log({ versionJson, versionEnv })
    return { hasNewVersion: versionJson.release !== versionEnv, lastCheckAt: Date.now() }
  }

  return useQuery(['appVersion'], fetchFn, { refetchInterval: 1000 * 60 * 10 })
}
