import React from 'react'

import type { MenuProps } from 'antd'
import { Button, Dropdown } from 'antd'
import { useNavigate } from 'react-router-dom'

import { DownOutlined } from '@ant-design/icons'

import { appRoutes } from '~/router'
import { DriverEntity } from '~api/gql-generated/graphql'

interface DriverActionMenuProps {
  driver: DriverEntity
  onAssignTransport: (driverId: number) => void
}
export function DriverActionMenu({ driver, onAssignTransport }: DriverActionMenuProps) {
  const navigate = useNavigate()

  const items: MenuProps['items'] = [
    {
      label: 'Редактировать',
      key: '1',
      onClick: () => navigate(appRoutes.carParkEditDriver.url.replace(':id', driver.id.toString())),
    },
    {
      label: 'Назначить ТС',
      key: '2',
      onClick: () => onAssignTransport(driver.id),
    },
    {
      label: 'Отправить ссылку на приложение',
      key: '3',
    },
    {
      label: 'Удалить',
      key: '4',
      danger: true,
    },
  ]

  const handleMenuClick: MenuProps['onClick'] = (e) => {
    console.info('Click on menu item.')
    console.log('click', e)
  }

  const menuProps = {
    items,
    onClick: handleMenuClick,
  }

  return (
    <Dropdown menu={menuProps}>
      <Button>
        <DownOutlined />
        Действия
      </Button>
    </Dropdown>
  )
}
