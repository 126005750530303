import React from 'react'

import { RouteObject } from 'react-router-dom'

import { appRoutes } from '~/router'
import { DealDetails, DealDocuments, DealInformation, DealLogistics, DealPayment, DealsList } from '~pages/Deals'

import { RequireAuth } from '../../providers/auth'

export const dealsRouter: RouteObject[] = [
  {
    path: appRoutes.deals.url,
    element: (
      <RequireAuth>
        <DealsList />
      </RequireAuth>
    ),
  },
  {
    path: appRoutes.dealDetails.url,
    element: (
      <RequireAuth>
        <DealDetails />
      </RequireAuth>
    ),
    children: [
      {
        path: appRoutes.dealDetailsInformation.url,
        element: <DealInformation />,
      },
      {
        path: appRoutes.dealDetailsPayment.url,
        element: <DealPayment />,
      },
      {
        path: appRoutes.dealDetailsLogistics.url,
        element: <DealLogistics />,
      },
      {
        path: appRoutes.dealDetailsDocuments.url,
        element: <DealDocuments />,
      },
    ],
  },
]
