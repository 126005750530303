import React from 'react'

import styled from 'styled-components'

import { Badge } from '~/ui-components'

type CompanyRatingVariant = 'high' | 'low'

interface ICompanyRatingProps {
  rating: CompanyRatingVariant
}

function mapCompanyRating(rating: CompanyRatingVariant): string {
  switch (rating) {
    case 'high':
      return 'Высокий'
    case 'low':
      return 'Низкий'
    default:
      return 'Не установлен'
  }
}
const Content = styled('div')({
  display: 'flex',
  alignItems: 'center',
})

export const CompanyRating: React.FC<ICompanyRatingProps> = ({ rating }) => {
  return (
    <Badge color="base">
      <Content>{mapCompanyRating(rating)}</Content>
    </Badge>
  )
}
