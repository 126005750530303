/* tslint:disable */
/* eslint-disable */
/**
 * API Зерно-Агрегатора
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */

export const ElevatorOfferResponseStatus = {
    OnModeration: 'ON_MODERATION',
    OnModerationWithAnalysis: 'ON_MODERATION_WITH_ANALYSIS',
    RejectedOnModeration: 'REJECTED_ON_MODERATION',
    UnderConsideration: 'UNDER_CONSIDERATION',
    RejectedByBuyer: 'REJECTED_BY_BUYER',
    Accepted: 'ACCEPTED',
    Cancelled: 'CANCELLED',
    DealRealised: 'DEAL_REALISED',
    Purchased: 'PURCHASED',
    Controversial: 'CONTROVERSIAL'
} as const;

export type ElevatorOfferResponseStatus = typeof ElevatorOfferResponseStatus[keyof typeof ElevatorOfferResponseStatus];



