import { useEffect, useState } from 'react'

import { View } from '~pages/OffersPage/components/ViewToggle'

const LOCALSTORAGE_KEY = 'offersView'

export const useGetView = () => {
  const [view, setView] = useState<View>((localStorage.getItem(LOCALSTORAGE_KEY) as View) ?? View.TABLE)

  useEffect(() => {
    if (!view) return

    localStorage.setItem(LOCALSTORAGE_KEY, view)
  }, [view])

  return {
    view,
    setView,
  }
}
