import { AppConfig } from '~/appConfig'

const LENGTH_PHONE_NUMBER = 10

export const transformNanToUndefined = (value: number) => (Number.isNaN(value) ? undefined : value)

export const phoneNumberValidator = (val: string | undefined): boolean => !val || /^\+[1-9]{1}[0-9]{3,14}$/.test(val)

export const onlyDigitValidator = (val: string | undefined): boolean => !val || /^\d+$/.test(val)

export const fullPhoneNumberValidator = (val: string | undefined): boolean => {
  if (!val) {
    return true
  }
  const res = getPhoneNumberWithoutCode(val)

  return res?.length === getPhoneNumberLength(val)
}

export const fullPhoneNumberValidatorNotRequired = (val: string | undefined): boolean => {
  if (!val) {
    return true
  }
  const res = getPhoneNumberWithoutCode(val)

  return res?.length === getPhoneNumberLength(val)
}

function getPhoneNumberWithoutCode(val: string | undefined): string | undefined {
  const countriesCodes = AppConfig.COUNTRIES_CODE_LIST.map((x) => x.value)

  const codeToRemove = countriesCodes.find((code) => val?.includes(code))

  if (!codeToRemove) {
    console.error('Value does not contain any of the specified codes.')
    return val
  }

  return val?.replace(codeToRemove, '')
}

function getPhoneNumberLength(val: string | undefined): number {
  const country = AppConfig.COUNTRIES_CODE_LIST.find((country) => val?.includes(country.value))

  return country?.numberLength ?? LENGTH_PHONE_NUMBER
}
