import React from 'react'

import { Button, Flex, Space } from 'antd'
import { Link, useNavigate } from 'react-router-dom'

import { useGetProductTypes } from '~/hooks'
import { appRoutes } from '~/router'
import { Badge, Card, Text } from '~/ui-components'
import { getElevatorLoadType } from '~/utils/elevators/get-elevator-load-type'
import { getElevatorTransportTypeName } from '~/utils/elevators/get-elevator-transport-type-name'
import { getElevatorTypeName } from '~/utils/elevators/get-elevator-type-name'
import { getElevatorUnloadType } from '~/utils/elevators/get-elevator-unload-type'
import { getDateTimeFormat, getWorkTime } from '~/utils/getDateFormat'
import { getDayOfWeek } from '~/utils/getDayOfWeek'
import { getNoun } from '~/utils/getNoun'
import { getValueIfExistOrDefault } from '~/utils/getValueIfExistOrDefault'
import { AdminGetElevatorResponse, GetElevatorResponse } from '~api/generated'
import { ReactComponent as WheatIcon } from '~assets/icons/wheat.svg'
import { useGetCurrentRole } from '~hooks/auth'
import { useGetDevice } from '~hooks/common'
import { PhoneNumber } from '~shared/components'

import * as S from './ElevatorDetailsInfo.styled'

interface ElevatorDetailsInfoProps {
  elevator: AdminGetElevatorResponse | GetElevatorResponse | undefined
}

export const ElevatorDetailsInfo: React.FC<ElevatorDetailsInfoProps> = ({ elevator }) => {
  const {
    user: { isAdmin },
  } = useGetCurrentRole()
  const device = useGetDevice()
  const isMobile = device === 'mobile'

  const navigate = useNavigate()

  const { data: products = [] } = useGetProductTypes()

  const createdByUserId = elevator?.createdByUserId ?? null

  const totalOffers: number = (elevator?.buyOfferCount ?? 0) + (elevator?.sellOfferCount ?? 0)

  const handleNavigateToAdvertInfo = () => {
    if (!elevator?.id) {
      return
    }

    navigate(appRoutes.basisAdverts.url.replace(':id', elevator.id.toString()))
  }

  return (
    <S.ContentWrapper>
      <S.AdvertsContentWrapper>
        <S.AdvertsTitle>
          <WheatIcon style={{ height: 18, marginBottom: -2, marginRight: 4 }} />
          {totalOffers} {getNoun(totalOffers ?? 0, 'предложение', 'предложения', 'предложений')} на базисе
        </S.AdvertsTitle>

        <Button block={isMobile} htmlType="button" type="primary" onClick={handleNavigateToAdvertInfo}>
          Перейти к объявлениям
        </Button>
      </S.AdvertsContentWrapper>

      <Card>
        <Card.Header>
          <Card.Title>Информация о базисе</Card.Title>
          {isAdmin && (elevator as AdminGetElevatorResponse)?.isCreationRequest && (
            <Badge color="yellow">Заявка на создание</Badge>
          )}
        </Card.Header>

        <Card.Content>
          <S.Content>
            <div>
              <S.Subtitle>ОСНОВНОЕ</S.Subtitle>
              <S.FormView>
                <div>
                  <Card.Label>Название</Card.Label>
                  <Card.ValueBold>{getValueIfExistOrDefault(elevator?.name)}</Card.ValueBold>
                </div>
                <div>
                  <Card.Label>Тип</Card.Label>
                  <Card.ValueBold>{elevator?.type.map(getElevatorTypeName).join(', ')}</Card.ValueBold>
                </div>
                <div>
                  <Card.Label>Адрес</Card.Label>
                  <Card.ValueBold>{getValueIfExistOrDefault(elevator?.address)}</Card.ValueBold>
                </div>

                {isAdmin && createdByUserId && (
                  <>
                    <div>
                      <Card.Label>Создал</Card.Label>

                      <Flex vertical>
                        <Text variant="normal">
                          {(elevator as AdminGetElevatorResponse).shortUserData?.role === 'ADMIN'
                            ? 'Администратор'
                            : 'Пользователь'}
                        </Text>

                        <Text variant="normal-bold">
                          {(elevator as AdminGetElevatorResponse).shortUserData?.name ?? '-'}{' '}
                        </Text>

                        <Flex align="baseline">
                          <Text variant="normal-bold">ID:</Text>
                          <Button type="link" style={{ height: 20 }}>
                            <Link to={appRoutes.moderatorUserDetails.url.replace(':id', createdByUserId.toString())}>
                              <Space size={4}>{createdByUserId}</Space>
                            </Link>
                          </Button>
                        </Flex>
                        <PhoneNumber phoneNumber={(elevator as AdminGetElevatorResponse).shortUserData?.phoneNumber} />
                      </Flex>
                    </div>

                    <div>
                      <Card.Label>{elevator?.createdByUserId ? 'Дата отправки запроса на добавление' : '-'}</Card.Label>
                      <Card.ValueBold>
                        {elevator && 'createdAt' in elevator ? getDateTimeFormat(elevator.createdAt) : '-'}
                      </Card.ValueBold>
                    </div>
                  </>
                )}
              </S.FormView>
            </div>
            <div>
              <S.Subtitle>ПАРАМЕТРЫ БАЗИСА</S.Subtitle>
              <S.FormView2Column>
                <div>
                  <Card.Label>Время работы</Card.Label>
                  <Card.ValueBold>{getWorkTime(elevator?.openingTime, elevator?.closingTime)}</Card.ValueBold>
                </div>
                <div>
                  <Card.Label>Виды перевозки</Card.Label>
                  <Card.ValueBold>
                    {elevator?.transport?.length
                      ? elevator?.transport.map((t) => getElevatorTransportTypeName(t)).join(', ')
                      : '-'}
                  </Card.ValueBold>
                </div>
                <div>
                  <Card.Label>Дни работы</Card.Label>
                  <Card.ValueBold>{elevator?.workingDaysOfWeek.map(getDayOfWeek).join(', ') ?? '-'}</Card.ValueBold>
                </div>
                <div>
                  <Card.Label>Наличие лаборатории</Card.Label>
                  <Card.ValueBold>{getValueIfExistOrDefault(elevator?.hasLab)}</Card.ValueBold>
                </div>
                <div>
                  <Card.Label>Мощность единовременного хранения</Card.Label>
                  <Card.ValueBold>{getValueIfExistOrDefault(elevator?.storageVolume, 'т')}</Card.ValueBold>
                </div>
                <div>
                  <Card.Label>Наличие электронной очереди</Card.Label>
                  <Card.ValueBold>{getValueIfExistOrDefault(elevator?.hasDigitalQueue)}</Card.ValueBold>
                </div>
                <div>
                  <Card.Label>Хранимые культуры</Card.Label>
                  <Card.ValueBold>
                    {products
                      ?.filter((x) => elevator?.productOnElevator?.includes(x.type))
                      ?.map((p) => p.name)
                      .join(', ')}
                  </Card.ValueBold>
                </div>
              </S.FormView2Column>
            </div>
          </S.Content>
        </Card.Content>
      </Card>

      <S.FormView2Column>
        <Card>
          <Card.Header>
            <Card.Title>Автотранспорт</Card.Title>
          </Card.Header>

          <Card.Content>
            <div>
              <S.Subtitle>ОСНОВНОЕ</S.Subtitle>
              <S.FormView3Column>
                <div>
                  <Card.Label>Скорость погрузки</Card.Label>
                  <Card.ValueBold>{getValueIfExistOrDefault(elevator?.autoUnloadSpeed, 'Авто/сутки')}</Card.ValueBold>
                </div>
                <div>
                  <Card.Label>Весы</Card.Label>
                  <Card.ValueBold>Есть</Card.ValueBold>
                </div>
                <div>
                  <Card.Label>Тоннаж весов</Card.Label>
                  <Card.ValueBold>{getValueIfExistOrDefault(elevator?.autoUnloadSpeed, 'т')}</Card.ValueBold>
                </div>
              </S.FormView3Column>
            </div>
            <div>
              <S.Subtitle>ДОПОЛНИТЕЛЬНО</S.Subtitle>
              <S.FormView>
                <div>
                  <Card.Label>Ограничения по длине</Card.Label>
                  <Card.ValueBold>{getValueIfExistOrDefault(elevator?.autoLength, 'м')}</Card.ValueBold>
                </div>
                <div>
                  <Card.Label>Ограничения по высоте</Card.Label>
                  <Card.ValueBold>
                    {getValueIfExistOrDefault(elevator?.autoHeight ? elevator.autoHeight / 100 : undefined, 'м')}
                  </Card.ValueBold>
                </div>
                <div>
                  <Card.Label>Ограничения по высоте клиренса</Card.Label>
                  <Card.ValueBold>{getValueIfExistOrDefault(elevator?.autoClearance, 'см')}</Card.ValueBold>
                </div>
                <div>
                  <Card.Label>Способ приемки</Card.Label>
                  <Card.Value>
                    {elevator?.unloadingTransport?.length
                      ? elevator?.unloadingTransport.map((u) => getElevatorUnloadType(u)).join(', ')
                      : '-'}
                  </Card.Value>
                </div>
                <div>
                  <Card.Label>Способ погрузки</Card.Label>
                  <Card.Value>
                    {elevator?.loadingTransport?.length
                      ? elevator?.loadingTransport.map((l) => getElevatorLoadType(l)).join(', ')
                      : '-'}
                  </Card.Value>
                </div>
                <div>
                  <Card.Label>Площадка накопитель</Card.Label>
                  <Card.ValueBold>{getValueIfExistOrDefault(elevator?.hasAutoStorage)}</Card.ValueBold>
                </div>
                <div>
                  <Card.Label>Дополнительные сведения</Card.Label>
                  <Card.ValueBold>{getValueIfExistOrDefault(elevator?.autoAdditionalInfo)}</Card.ValueBold>
                </div>
              </S.FormView>
            </div>
          </Card.Content>
        </Card>
        <Card>
          <Card.Header>
            <Card.Title>Ж/Д транспорт</Card.Title>
          </Card.Header>

          <Card.Content>
            <div>
              <S.Subtitle>ОСНОВНОЕ</S.Subtitle>
              <S.FormView>
                <S.FormView2Column>
                  <div>
                    <Card.Label>Название станции</Card.Label>
                    <Card.ValueBold>{getValueIfExistOrDefault(elevator?.stationName)}</Card.ValueBold>
                  </div>
                  <div>
                    <Card.Label>Наличие собственных путей</Card.Label>
                    <Card.ValueBold>{getValueIfExistOrDefault(elevator?.hasRailWay)}</Card.ValueBold>
                  </div>
                </S.FormView2Column>
                <div>
                  <Card.Label>Наличие собственных Ж/Д весов</Card.Label>
                  <Card.ValueBold>{getValueIfExistOrDefault(elevator?.hasRailScales)}</Card.ValueBold>
                </div>
                <div>
                  <Card.Label>Наличие собственного тягового подвижного состава</Card.Label>
                  <Card.ValueBold>{getValueIfExistOrDefault(elevator?.hasRailStock)}</Card.ValueBold>
                </div>
              </S.FormView>
            </div>
            <div>
              <S.Subtitle>ДОПОЛНИТЕЛЬНО</S.Subtitle>
              <S.FormView>
                <div>
                  <Card.Label>Скорость погрузки</Card.Label>
                  <Card.ValueBold>{getValueIfExistOrDefault(elevator?.railLoadSpeed, 'вагонов/день')}</Card.ValueBold>
                </div>

                <div>
                  <Card.Label>Фронт подачи вагонов</Card.Label>
                  <Card.ValueBold>{getValueIfExistOrDefault(elevator?.waitingArea, 'шт')}</Card.ValueBold>
                </div>
                <div>
                  <Card.Label>Возможность транспортно-экспедиционного обслуживания</Card.Label>
                  <Card.ValueBold>{getValueIfExistOrDefault(elevator?.hasTecEco)}</Card.ValueBold>
                </div>
                <div>
                  <Card.Label>Дополнительные сведения</Card.Label>
                  <Card.ValueBold>{getValueIfExistOrDefault(elevator?.railAdditionalInfo)}</Card.ValueBold>
                </div>
              </S.FormView>
            </div>
          </Card.Content>
        </Card>
      </S.FormView2Column>

      <Card>
        <Card.Header>
          <Card.Title>
            <Space>
              Услуги базиса{' '}
              <span>
                <S.NdsTitle>{elevator?.additionalServiceWithNds === true ? 'Все цены с НДС' : 'Без НДС'}</S.NdsTitle>
              </span>
            </Space>
          </Card.Title>
        </Card.Header>

        <Card.Content>
          <S.BasisServicePriceGridWrapper>
            <S.BasisServicePriceGrid>
              <Card.Label>Приемка</Card.Label>
              <Card.Value>{getValueIfExistOrDefault(elevator?.receptionPrice, '₽/т')}</Card.Value>

              <Card.Label>Сушка</Card.Label>
              <Card.Value>{getValueIfExistOrDefault(elevator?.dryingPrice, '₽/т')}</Card.Value>

              <Card.Label>Очистка от сорной примеси</Card.Label>
              <Card.Value>{getValueIfExistOrDefault(elevator?.cleaningPrice, '₽/т')}</Card.Value>

              <Card.Label>Хранение</Card.Label>
              <Card.Value>{getValueIfExistOrDefault(elevator?.storingPrice, '₽/т/день')}</Card.Value>

              <Card.Label>Смешение партий/улучшение</Card.Label>
              <Card.Value>{getValueIfExistOrDefault(elevator?.mixingPrice, '₽/т')}</Card.Value>
            </S.BasisServicePriceGrid>

            <S.BasisServicePriceGrid>
              <Card.Label>Перепись</Card.Label>
              <Card.Value>{getValueIfExistOrDefault(elevator?.censusPrice, '₽')}</Card.Value>

              <Card.Label>Отгрузка авто</Card.Label>
              <Card.Value>{getValueIfExistOrDefault(elevator?.autoShipmentPrice, '₽/т')}</Card.Value>

              <Card.Label>Отгрузка Ж/Д</Card.Label>
              <Card.Value>{getValueIfExistOrDefault(elevator?.trainShipmentPrice, '₽/т')}</Card.Value>

              <Card.Label>Оформление документов</Card.Label>
              <Card.Value>{getValueIfExistOrDefault(elevator?.paperworkPrice, '₽/т')}</Card.Value>

              <Card.Label>Остальные услуги</Card.Label>
              <Card.Value>{getValueIfExistOrDefault(elevator?.anotherServices)}</Card.Value>
            </S.BasisServicePriceGrid>
          </S.BasisServicePriceGridWrapper>
        </Card.Content>
      </Card>
    </S.ContentWrapper>
  )
}
