import React, { useEffect } from 'react'

import { Flex, Pagination, Spin, Table } from 'antd'
import { observer } from 'mobx-react-lite'
import { FiChevronDown, FiChevronUp } from 'react-icons/fi'
import { useNavigate } from 'react-router-dom'

import { useQuery } from '@apollo/client'

import { usePagination } from '~/hooks'
import { AppHeader } from '~/layout'
import { appRoutes } from '~/router'
import { findCargoFilterStore } from '~/store/filters'
import { Text } from '~/ui-components'
import { getNoun } from '~/utils/getNoun'
import { graphqlErrorHandler } from '~/utils/graphqlErrorHandler'
import { isIntegerString } from '~/utils/isIntegerString'
import { FreightOrderEntity, FreightOrderFilterInput } from '~api/gql-generated/graphql'
import { freightOrdersQuery } from '~api/gql-query/freight-orders.query.graphql'
import { useGetCurrentRole } from '~hooks/auth'
import { CargoTableRowDetails } from '~pages/LogisticsPage/modules/MyCargos/components/CargoTableRowDetails/CargoTableRowDetails'
import { CargoFilters } from '~pages/LogisticsPage/shared/CargoFilters/CargoFilters'
import { getFreightOrdersColumns } from '~pages/LogisticsPage/shared/getFreightOrdersColumns'
import { SearchInput } from '~shared/components/SearchInput'

export const FindCargo: React.FC = observer(() => {
  const {
    company: { isCarrier },
  } = useGetCurrentRole()
  const navigate = useNavigate()
  const { currentPage, setCurrentPage, pageSize, setPageSize, pageSizeOptions } = usePagination()

  const searchFilters: Partial<FreightOrderFilterInput> = {
    search: isIntegerString(findCargoFilterStore.search) ? undefined : findCargoFilterStore.search,
    freightOrderIds: isIntegerString(findCargoFilterStore.search) ? [Number(findCargoFilterStore.search)] : undefined,
  }

  const freightOrdersFn = useQuery(freightOrdersQuery, {
    variables: {
      filter: { ...findCargoFilterStore.mappedCurrentFiltersToResponse, ...searchFilters },
      pagination: { currentPage, pageSize },
    },
    onError: graphqlErrorHandler,
    fetchPolicy: 'cache-and-network',
  })

  useEffect(() => {
    void freightOrdersFn.refetch()
    setCurrentPage(1)
  }, [
    findCargoFilterStore.filters.weightKgFrom,
    findCargoFilterStore.filters.weightKgTo,
    findCargoFilterStore.filters.pricePerTonTo,
    findCargoFilterStore.filters.pricePerTonFrom,
    findCargoFilterStore.filters.priceWithNds,
    findCargoFilterStore.filters.fromRegionId,
    findCargoFilterStore.filters.toRegionId,
    findCargoFilterStore.filters.productTypeId,
    findCargoFilterStore.filters.paymentTerm,
    findCargoFilterStore.filters.period,
    findCargoFilterStore.search,
  ])

  const freightOrders = freightOrdersFn.data?.freightOrders.freightOrders ?? []
  const pagination = freightOrdersFn.data?.freightOrders.pagination

  const onActionArrowClickHandler = (record: FreightOrderEntity) => {
    navigate(appRoutes.logisticsLoadCard.url.replace(':id', record.id.toString()))
  }

  const columns = getFreightOrdersColumns(onActionArrowClickHandler, { hasDistanceToStartKm: isCarrier })

  return (
    <Flex vertical>
      <AppHeader title={appRoutes.logisticFindCargo.title} />

      <Flex vertical gap={24} style={{ padding: 24 }}>
        <Spin spinning={freightOrdersFn.loading}>
          <Text variant="h1">
            Найдено: {pagination?.total ?? 0} {getNoun(pagination?.total ?? 0, 'груз', 'груза', 'грузов')}
          </Text>
        </Spin>

        <Flex justify="space-between">
          <CargoFilters store="findCargoFilterStore" />
          <SearchInput
            style={{ width: 280 }}
            defaultValue={findCargoFilterStore.search ?? ''}
            onSearch={(x) => findCargoFilterStore.setSearch(x.trim() ? x : undefined)}
            autoFocus={true}
            placeholder="Поиск по ID, грузу, маршруту"
          />
        </Flex>

        <Table
          rowKey="id"
          className="table-interactive"
          loading={freightOrdersFn.loading}
          columns={columns}
          dataSource={freightOrders}
          scroll={{ x: 'max-content' }}
          expandable={{
            expandedRowRender: (record) => <CargoTableRowDetails freight={record} />,
            fixed: 'right',
            showExpandColumn: true,
            expandIcon: ({ expanded, onExpand, record }) =>
              expanded ? (
                <Flex
                  align="center"
                  gap={10}
                  style={{ color: 'var(--primary-main-color)' }}
                  onClick={(e) => onExpand(record, e)}
                >
                  <FiChevronUp size={20} /> Свернуть
                </Flex>
              ) : (
                <Flex
                  align="center"
                  gap={10}
                  style={{ color: 'var(--primary-main-color)' }}
                  onClick={(e) => onExpand(record, e)}
                >
                  <FiChevronDown size={20} /> Развернуть
                </Flex>
              ),
          }}
          pagination={false}
        />

        {pagination && (
          <Pagination
            style={{ marginTop: 'auto' }}
            current={pagination?.currentPage}
            total={pagination?.total}
            pageSize={pagination?.pageSize}
            pageSizeOptions={pageSizeOptions}
            onShowSizeChange={(x, size) => setPageSize(size)}
            showSizeChanger
            onChange={setCurrentPage}
          />
        )}
      </Flex>
    </Flex>
  )
})
