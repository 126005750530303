import styled from 'styled-components'

import css from '@styled-system/css'

export const Row = styled.div(
  css({
    display: ['none', null, 'flex'],
    flexDirection: 'column',
    gap: 8,
    padding: '32px 24px',
  })
)

export const Content = styled.div(
  css({
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    gap: 4,
  })
)

export const Status = styled.div(
  css({
    display: 'flex',
    gap: 8,
  })
)

// export const IconArrow = styled(Icon)(
//   css({
//     position: 'absolute',
//     right: '0',
//     bottom: '0',
//   })
// )
