import * as yup from 'yup'

import { LoadingTransportType, UnloadingTransportType } from '~api/generated'
import { transformNanToUndefined } from '~shared/validations/schemas'

export const createElevatorFormStep4CarSchema = yup.object({
  autoReceptionSpeed: yup.number().transform(transformNanToUndefined),
  autoUnloadSpeed: yup.number().transform(transformNanToUndefined),
  scalesWeightLimit: yup.number().transform(transformNanToUndefined),
  scalesLength: yup.number().transform(transformNanToUndefined),
  autoLength: yup.number().transform(transformNanToUndefined),
  autoHeight: yup.number().transform(transformNanToUndefined),
  autoClearance: yup.number().transform(transformNanToUndefined),
  loadingTransport: yup.array().of(yup.mixed().oneOf(Object.values(LoadingTransportType))),
  // .min(1, VALIDATORS_MSG.required),
  unloadingTransport: yup.array().of(yup.mixed().oneOf(Object.values(UnloadingTransportType))),
  // .min(1, VALIDATORS_MSG.required),
  hasAutoStorage: yup.boolean(),
  autoAdditionalInfo: yup.string(),
})
