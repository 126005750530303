import React, { useState } from 'react'

import { Avatar, Modal, Popover, Skeleton, Spin } from 'antd'
import { FiChevronRight } from 'react-icons/fi'

import { useMutation, useQuery } from '@apollo/client'
import { ApolloError } from '@apollo/client/errors'

import { appToast, modalSharedSettings } from '~/utils'
import { getAbbrCompany } from '~/utils/company/get-abbr-company'
import { getCompanyRole } from '~/utils/company/get-company-role-old'
import { graphqlErrorHandler } from '~/utils/graphqlErrorHandler'
import { CompanyEntity } from '~api/gql-generated/graphql'
import { setActiveCompanyMutation } from '~api/gql-mutations/auth.mutation.graphql'
import { createCompanyMutation } from '~api/gql-mutations/company.mutation.graphql'
import { getMeCompaniesQuery, getMeQuery } from '~api/gql-query/me.query.graphql'
import { AddNewCompanyForm } from '~shared/components'
import { AddNewCompanyFormValues } from '~shared/components/AddNewCompanyForm'
import { CompaniesList } from '~shared/components/UserCompanySelect/components/CompaniesList'

import * as S from './UserCompanySelect.styled'

interface UserCompanySelectProps {
  isMobile?: boolean
  isShowOnlyCompanyList?: boolean
}

export const UserCompanySelect: React.FC<UserCompanySelectProps> = ({
  isMobile = false,
  isShowOnlyCompanyList = false,
}) => {
  const getCompanies = useQuery(getMeCompaniesQuery)
  const getMe = useQuery(getMeQuery)
  const [createCompany, createCompanyState] = useMutation(createCompanyMutation)
  const [setActiveCompany, setActiveCompanyState] = useMutation(setActiveCompanyMutation)
  const [isAddNewCompanyModalOpen, setIsAddNewCompanyModalOpen] = useState(false)

  const canUserCreateNewCompany = getMe.data?.me.actions.createCompany ?? false
  const companiesList: Partial<CompanyEntity>[] = getCompanies.data?.me.companies ?? []
  const selectedCompany = companiesList.find((x) => x.id === getMe.data?.me.activeCompanyId)

  const loading = getMe.loading || getCompanies.loading || setActiveCompanyState.loading || createCompanyState.loading

  const onSelectHandler = async (companyId: number) => {
    const foundCompany = companiesList.find((x) => x.id === companyId)
    try {
      await setActiveCompany({
        variables: {
          input: { companyId },
        },
        refetchQueries: [getMeQuery],
      })

      appToast.success({
        message: 'Смена компании',
        description: `Вы сменили компанию на ${foundCompany?.legalName ?? companyId}`,
      })
    } catch (e) {
      if (e instanceof ApolloError) {
        graphqlErrorHandler(e, 'Ошибка при изменении активной компании компании')
      }
    }
  }

  const onAddCompanyHandler = async (formValues: AddNewCompanyFormValues) => {
    try {
      await createCompany({
        variables: {
          input: {
            type: formValues.companyRoleType,
            inn: formValues.companyInn,
          },
        },
      })

      appToast.success({ message: 'Компания создана' })
      setIsAddNewCompanyModalOpen(false)
      await getCompanies.refetch()
    } catch (e) {
      if (e instanceof ApolloError) {
        if (e.message === 'The company already exists') {
          Modal.info({
            title: 'Компания с таким ИНН уже зарегестрирована на платформе',
            content: 'Убедитесь что ИНН верный',
          })
          return
        }
        graphqlErrorHandler(e, 'Ошибка при создании компании')
      }
    }
  }

  const isAddCompanyOpenHandler = () => {
    setIsAddNewCompanyModalOpen(true)
  }

  const isAddCompanyCloseHandler = () => {
    setIsAddNewCompanyModalOpen(false)
  }

  if (!selectedCompany) {
    return null
  }

  if (getCompanies.loading || getMe.loading) {
    return (
      <S.ContentWrapper>
        <Skeleton />
      </S.ContentWrapper>
    )
  }

  const renderContent = () => (
    <S.ContentWrapper>
      <div>
        <Avatar size={isMobile ? 48 : 36} shape="circle" src={selectedCompany?.logoUrl}>
          {getAbbrCompany(selectedCompany?.legalName)}
        </Avatar>
      </div>

      <S.CompanyInfoWrapper>
        <S.CompanyTitle>Компания</S.CompanyTitle>
        <S.CompanyName>{selectedCompany.legalName}</S.CompanyName>
        <S.CompanyRole>{selectedCompany.type ? getCompanyRole(selectedCompany.type) : 'тип не задан'}</S.CompanyRole>
      </S.CompanyInfoWrapper>

      <S.RightIcon>
        <FiChevronRight size={24} display={'block'} />
      </S.RightIcon>
    </S.ContentWrapper>
  )

  const renderAddCompanyModal = () => (
    <Modal
      {...modalSharedSettings}
      open={isAddNewCompanyModalOpen}
      onCancel={isAddCompanyCloseHandler}
      title="Добавить компанию"
      width={400}
    >
      <AddNewCompanyForm
        isAdmin={false}
        isLoading={createCompanyState.loading}
        onSubmit={onAddCompanyHandler}
        onCancel={isAddCompanyCloseHandler}
      />
    </Modal>
  )

  const renderCompanyList = () => (
    <Spin spinning={loading}>
      <CompaniesList
        companies={companiesList}
        selectedCompanyId={selectedCompany.id ?? -1}
        onAdd={isAddCompanyOpenHandler}
        onSelect={onSelectHandler}
        canUserCreateNewCompany={canUserCreateNewCompany}
      />
    </Spin>
  )

  if (isMobile) {
    return renderContent()
  }

  if (isShowOnlyCompanyList) {
    return (
      <>
        {renderCompanyList()}
        {renderAddCompanyModal()}
      </>
    )
  }

  return (
    <Spin spinning={loading}>
      <Popover
        content={renderCompanyList()}
        zIndex={990}
        placement="rightBottom"
        trigger="click"
        arrow={false}
        overlayClassName="custom-popover"
      >
        {renderContent()}

        {renderAddCompanyModal()}
      </Popover>
    </Spin>
  )
}
