/* tslint:disable */
/* eslint-disable */
/**
 * API Зерно-Агрегатора
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */

export const UnloadingTransportType = {
    TailgateAutoUnloader: 'TAILGATE_AUTO_UNLOADER',
    SideBoardAutoUnloader: 'SIDE_BOARD_AUTO_UNLOADER',
    Manipulator: 'MANIPULATOR',
    DumpTruck: 'DUMP_TRUCK',
    MechanicalShovel: 'MECHANICAL_SHOVEL',
    PneumaticUnloader: 'PNEUMATIC_UNLOADER',
    Custom: 'CUSTOM',
    ByContractorChoice: 'BY_CONTRACTOR_CHOICE'
} as const;

export type UnloadingTransportType = typeof UnloadingTransportType[keyof typeof UnloadingTransportType];



