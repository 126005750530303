import { useQuery, UseQueryResult } from '@tanstack/react-query'

import { CompanyApiCompanyControllerGetCompaniesRequest, CompanyData } from '~api/generated'
import { sharedCompanyQueryOptions } from '~hooks/company/_sharedCompanyQueryOptions'
import { useApi } from '~providers/api'

export const useGetCompanies = (
  request: CompanyApiCompanyControllerGetCompaniesRequest,
  isFetch = true
): UseQueryResult<CompanyData[] | null> => {
  const api = useApi()

  const fetchFn = async (): Promise<CompanyData[] | null> => {
    if (!isFetch || request.ids?.length === 0) return null

    const res = await api.companies.companyControllerGetCompanies({
      pageSize: 100,
      ...request,
    })

    return res.data.companies
  }

  return useQuery(['fetchCompanies', request], fetchFn, sharedCompanyQueryOptions)
}
