import React from 'react'

import { RouteObject } from 'react-router-dom'

import { AccountPage } from '~/pages'
import { appRoutes } from '~/router'
import {
  CompanyAccountInfoPage,
  CompanyAccountPayRequisitesPage,
  CompanyAccountRequisitesPage,
  CompanyEmployeePage,
  PersonalAccountInfoPage,
} from '~pages/AccountPage'
import { AccountCompanyMenuMobile } from '~pages/AccountPage/components/AccountMenuMobile/AccountCompanyMenuMobile'
import { AccountSelectCompanyMenuMobile } from '~pages/AccountPage/components/AccountMenuMobile/AccountSelectCompanyMenuMobile'
import { DocumentsProfileTab } from '~pages/ProfilePage/components'

import { RequireAuth } from '../../providers/auth'

export const accountRouter: RouteObject = {
  path: appRoutes.account.url,
  element: (
    <RequireAuth>
      <AccountPage />
    </RequireAuth>
  ),
  children: [
    {
      path: appRoutes.accountCompanyMobileMenu.url,
      element: <AccountCompanyMenuMobile />,
    },
    {
      path: appRoutes.accountSelectCompanyMobileMenu.url,
      element: <AccountSelectCompanyMenuMobile />,
    },
    {
      path: appRoutes.accountProfile.url,
      element: <PersonalAccountInfoPage />,
    },
    {
      path: appRoutes.accountCompanyProfile.url,
      element: <CompanyAccountInfoPage />,
    },
    {
      path: appRoutes.accountCompanyRequisites.url,
      element: <CompanyAccountRequisitesPage />,
    },
    {
      path: appRoutes.accountCompanyPayRequisites.url,
      element: <CompanyAccountPayRequisitesPage />,
    },
    {
      path: appRoutes.accountCompanyDocs.url,
      element: <DocumentsProfileTab />,
    },
    {
      path: appRoutes.accountCompanyEmployees.url,
      element: <CompanyEmployeePage />,
    },
  ],
}
