import { useMutation } from '@tanstack/react-query'

import { CompanyApiCompanyControllerUpdateCompanyStatusRequest } from '~/api/generated'
import { useApi } from '~providers/api'

export const useUpdateCompanyStatus = () => {
  const api = useApi()

  return useMutation((request: CompanyApiCompanyControllerUpdateCompanyStatusRequest) => {
    return api.companies.companyControllerUpdateCompanyStatus(request)
  })
}
