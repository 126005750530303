import React, { useEffect, useState } from 'react'

import { Button, Modal, Skeleton, Spin } from 'antd'
import { useParams } from 'react-router'

import { Counterpart, DealOfferInfo, SpecificationCard } from '~/pages/Deals/modules/DealInformation/components'
import { appToast, httpErrorHandler, modalSharedSettings } from '~/utils'
import {
  BaseCompanyData,
  BaseUserData,
  GetComplexDealResponseNextStatusesEnum,
  UpdateDealStatusBodyStatusEnum,
} from '~api/generated'
import { useCreateDealSpecification, useUpdateDealStatus } from '~hooks/deals'
import { useGetModeratorDeal, useUpdateComplexDealStatus } from '~hooks/moderator'
import { DealSteps, DocumentsProduct } from '~pages/Deals/components'
import { DealResponseInfo } from '~pages/Deals/modules/DealInformation/components/DealResponseInfo'
import {
  DealInformationDocuments,
  DealSpecificationForm,
  DealStatusSelect,
  IDealSpecificationForm,
  ModeratorDealBankInfo,
  SellerOrBuyerRadioControl,
} from '~pages/ModeratorPage/components/ModeratorDeals/components'
import { DealAdditionalServicesAdminSection } from '~pages/ModeratorPage/components/ModeratorDeals/components/DealAdditionalServicesAdmin'
import { mapFormValueToDealSpecificationBody } from '~pages/ModeratorPage/components/ModeratorDeals/utils/mapFormValueToDealSpecificationBody'

import * as S from './ModeratorDealInformation.styled'

export const ModeratorDealInformation: React.FC = () => {
  const [isSpecificationFormModalVisible, setIsSpecificationFormModalVisible] = useState(false)
  const params = useParams<{ id: string | undefined }>()
  const complexDealId = Number(params.id)
  const dealFn = useGetModeratorDeal(complexDealId)
  const [dealSide, setDealSide] = useState<'seller' | 'buyer'>('seller')
  const [dealId, setDealId] = useState<number | null>(null)
  const updateDealStatusFn = useUpdateDealStatus()
  const updateComplexDealStatusFn = useUpdateComplexDealStatus()
  const createDealSpecification = useCreateDealSpecification()

  useEffect(() => {
    if (dealFn.data?.dealWithSeller || dealFn.data?.dealWithBuyer) {
      if (dealSide === 'seller') {
        setDealId(dealFn.data.dealWithSeller?.id ?? null)
        return
      }
      if (dealSide === 'buyer') {
        setDealId(dealFn.data.dealWithBuyer?.id ?? null)
        return
      }
      setDealId(null)
    }
  }, [dealFn.data?.id, dealSide])

  const onRejectComplexDealConfirm = () => {
    const nextStatuses = dealFn.data?.nextStatuses
    if (!nextStatuses) {
      return
    }
    let rejectStatus: GetComplexDealResponseNextStatusesEnum
    if (nextStatuses.includes(GetComplexDealResponseNextStatusesEnum.RejectedAfterModeration)) {
      rejectStatus = GetComplexDealResponseNextStatusesEnum.RejectedAfterModeration
    } else if (nextStatuses.includes(GetComplexDealResponseNextStatusesEnum.Rejected)) {
      rejectStatus = GetComplexDealResponseNextStatusesEnum.Rejected
    }

    Modal.confirm({
      title: 'Вы уверены что хотите отменить сделку?',
      okText: 'Да',
      cancelText: 'Отмена',
      async onOk() {
        try {
          await updateComplexDealStatusFn.mutateAsync({
            complexDealId,
            updateComplexDealStatusBody: {
              status: rejectStatus,
            },
          })
          await dealFn.refetch()
          appToast.success({ description: 'Сделка отменена' })
        } catch (e) {
          httpErrorHandler(e, 'Ошибка при отмене сделки')
        }
      },
    })
  }

  const onAfterAdditionalServiceChangesHandler = async () => {
    await dealFn.refetch()
  }

  const specificationFormModalClose = () => setIsSpecificationFormModalVisible(false)
  const specificationFormModalOpen = () => setIsSpecificationFormModalVisible(true)
  const specificationFormSubmitHandler = async (formValues: IDealSpecificationForm) => {
    if (!dealId) {
      appToast.error({ description: '[ModeratorDealInformation]: Deal id was not found' })
      return
    }
    try {
      await createDealSpecification.mutateAsync(mapFormValueToDealSpecificationBody(formValues, dealId))
      appToast.success({ description: 'Cпецификация сохранена' })
      specificationFormModalClose()
      await dealFn.refetch()
    } catch (e) {
      appToast.error({ description: 'Ошибка при сохранении спецификации' })
    }
  }

  const updateDealStatusHandler = async (status: UpdateDealStatusBodyStatusEnum) => {
    if (!dealId) {
      appToast.error({ description: '[ModeratorDealInformation]: Deal id was not found' })
      return
    }
    try {
      await updateDealStatusFn.mutateAsync({
        dealId,
        updateDealStatusBody: {
          status,
        },
      })
      appToast.success({ description: 'Статус сделки изменен' })
      await dealFn.refetch()
    } catch (e) {
      appToast.error({ description: 'Ошибка при изменении статуса сделки' })
    }
  }

  if (!dealFn.data || dealFn.isLoading) {
    return <Skeleton />
  }

  const deal = dealFn.data
  const specification = dealSide === 'seller' ? deal.dealWithSeller?.specification : deal.dealWithBuyer?.specification
  const additionalServices =
    dealSide === 'seller' ? deal.dealWithSeller?.additionalServices ?? [] : deal.dealWithBuyer?.additionalServices ?? []
  const hasSpecification = !!specification

  const companyData: BaseCompanyData = dealSide === 'seller' ? deal.seller : deal.buyer
  const userData: BaseUserData | null = dealSide === 'seller' ? deal.sellerUser : deal.buyerUser

  const canDeclineComplexDeal = [
    GetComplexDealResponseNextStatusesEnum.Rejected,
    GetComplexDealResponseNextStatusesEnum.RejectedAfterModeration,
  ].some((r) => deal.nextStatuses.includes(r))

  const nextStatuses = dealSide === 'seller' ? deal.dealWithSeller?.nextStatuses : deal.dealWithBuyer?.nextStatuses

  const isShowDealInformationDocumentCard = Boolean(
    (dealSide === 'seller' && deal.dealWithSeller?.bankAccount) ||
      (dealSide === 'buyer' && deal.dealWithBuyer?.bankAccount)
  )

  const dealStatus = deal.dealWithSeller?.status || deal.dealWithBuyer?.status

  return (
    <Spin spinning={dealFn.isLoading || dealFn.isFetching}>
      <S.Content>
        {dealStatus && <DealSteps status={dealStatus} />}
        <S.TopActionsWrapper>
          <SellerOrBuyerRadioControl value={dealSide} onChange={(value) => setDealSide(value)} />
          <DealStatusSelect
            label={dealSide === 'seller' ? 'Сделка на продажу' : 'Сделка на покупку'}
            defaultValue={dealSide === 'seller' ? deal.dealWithSeller?.status : deal.dealWithBuyer?.status}
            onChange={updateDealStatusHandler}
            nextStatuses={nextStatuses}
          />

          {canDeclineComplexDeal && (
            <Button type="primary" danger block onClick={onRejectComplexDealConfirm}>
              Отменить сделку
            </Button>
          )}
        </S.TopActionsWrapper>
        <S.ColumnWrapper>
          <S.Column>
            {hasSpecification && dealId ? (
              <SpecificationCard
                dealSide={dealSide}
                dealId={dealId}
                specification={specification}
                offerLaboratoryAnalysis={deal.offer.laboratoryAnalysis?.source}
              />
            ) : (
              <DealOfferInfo offer={deal.offer} />
            )}

            <Counterpart
              company={companyData}
              user={userData}
              title={dealSide === 'buyer' ? 'Данные покупателя' : 'Данные продавца'}
            />
            <DealResponseInfo deal={deal} dealSide={dealSide} />
          </S.Column>
          <S.Column>
            {dealSide === 'buyer' && (
              <DealAdditionalServicesAdminSection
                additionalServices={additionalServices}
                afterChanges={onAfterAdditionalServiceChangesHandler}
                dealId={dealId}
                fromAddress={deal.loadingAddress ?? '-'}
                toAddress={deal?.unloadingAddress ?? '-'}
                volume={deal.offer?.volume ?? 0}
              />
            )}

            {dealId && (
              <DocumentsProduct dealSide={dealSide} dealId={dealId} documentType="product" title="Документы на товар" />
            )}
            <ModeratorDealBankInfo
              bankAccount={
                dealSide === 'seller'
                  ? deal.dealWithSeller?.bankAccount ?? null
                  : deal.dealWithBuyer?.bankAccount ?? null
              }
            />

            {dealId && isShowDealInformationDocumentCard && (
              <DealInformationDocuments
                isHasSpecification={!!deal.dealWithSeller?.specification}
                dealId={dealId}
                dealSide={dealSide}
                openSpecificationFormButtonDisabled={hasSpecification}
                onSpecificationFormOpen={specificationFormModalOpen}
              />
            )}
          </S.Column>
        </S.ColumnWrapper>
      </S.Content>

      <Modal
        {...modalSharedSettings}
        open={isSpecificationFormModalVisible}
        onCancel={specificationFormModalClose}
        title="Спецификация"
      >
        <DealSpecificationForm
          onSubmit={specificationFormSubmitHandler}
          isLoading={createDealSpecification.isLoading}
          offer={deal.offer}
          onClose={specificationFormModalClose}
        />
      </Modal>
    </Spin>
  )
}
