import { useMutation } from '@tanstack/react-query'

import { DealDocumentApiDealDocumentControllerAddDocumentFilesRequest } from '~api/generated'
import { useUploadFilesAndGetIds } from '~hooks/_utils'
import { useApi } from '~providers/api'

interface DealDocumentUploadFilesRequest {
  files: File[]
  addDocumentFilesRequest: Omit<
    DealDocumentApiDealDocumentControllerAddDocumentFilesRequest,
    'addDealDocumentFilesBody'
  >
}
export const useUploadDealDocumentFiles = () => {
  const api = useApi()
  const uploadFilesAndGetIdsFn = useUploadFilesAndGetIds()

  const uploadDealDocumentFn = async ({ files, addDocumentFilesRequest }: DealDocumentUploadFilesRequest) => {
    const uploadedFileIds = await uploadFilesAndGetIdsFn.mutateAsync(files)

    const res = await api.dealDocument.dealDocumentControllerAddDocumentFiles({
      ...addDocumentFilesRequest,
      addDealDocumentFilesBody: {
        fileKeys: uploadedFileIds,
      },
    })

    return res.data
  }

  return useMutation(uploadDealDocumentFn)
}
