import styled from 'styled-components'

import { css } from '@styled-system/css'

export const Value = styled.div(
  css({
    fontSize: '14px',
    lineHeight: '16px',
    color: 'TxtDefault',
  })
)

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 24px;
`

export const Culture = styled(Value)(
  css({
    fontWeight: '700',
  })
)

export const Price = styled(Value)(
  css({
    fontWeight: '700',
    color: 'GreenPrimary',
  })
)
