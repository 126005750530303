/* tslint:disable */
/* eslint-disable */
/**
 * API Зерно-Агрегатора
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { AdminElevatorAdvertContactUserData } from './admin-elevator-advert-contact-user-data';
import { AdminElevatorAdvertModeratorData } from './admin-elevator-advert-moderator-data';
import { BaseElevatorEntity } from './base-elevator-entity';
import { ElevatorAdvertCompanyData } from './elevator-advert-company-data';
import { ElevatorOfferStatus } from './elevator-offer-status';
import { OfferPriceType } from './offer-price-type';
import { ProductData } from './product-data';

/**
 * 
 * @export
 * @interface AdminElevatorAdvertData
 */
export interface AdminElevatorAdvertData {
    /**
     * Идентификатор объявления
     * @type {number}
     * @memberof AdminElevatorAdvertData
     */
    'id': number;
    /**
     * Компания, разместившая объявление
     * @type {ElevatorAdvertCompanyData}
     * @memberof AdminElevatorAdvertData
     */
    'company': ElevatorAdvertCompanyData | null;
    /**
     * Элеватор
     * @type {BaseElevatorEntity}
     * @memberof AdminElevatorAdvertData
     */
    'elevator': BaseElevatorEntity;
    /**
     * Товар
     * @type {ProductData}
     * @memberof AdminElevatorAdvertData
     */
    'product': ProductData;
    /**
     * Цена за тонну
     * @type {number}
     * @memberof AdminElevatorAdvertData
     */
    'pricePerTon': number;
    /**
     * Время обновления цены
     * @type {string}
     * @memberof AdminElevatorAdvertData
     */
    'priceUpdatedAt': string | null;
    /**
     * Разрешить предложения с другими параметрами
     * @type {boolean}
     * @memberof AdminElevatorAdvertData
     */
    'allowOffersWithOtherParameters': boolean | null;
    /**
     * Тип объявления
     * @type {string}
     * @memberof AdminElevatorAdvertData
     */
    'type': AdminElevatorAdvertDataTypeEnum;
    /**
     * 
     * @type {ElevatorOfferStatus}
     * @memberof AdminElevatorAdvertData
     */
    'status': ElevatorOfferStatus;
    /**
     * Объём товара
     * @type {number}
     * @memberof AdminElevatorAdvertData
     */
    'volume': number | null;
    /**
     * Оставшийся объём
     * @type {number}
     * @memberof AdminElevatorAdvertData
     */
    'notSelectedVolume': number | null;
    /**
     * Включен ли ндс?
     * @type {boolean}
     * @memberof AdminElevatorAdvertData
     */
    'usingNds': boolean | null;
    /**
     * 
     * @type {OfferPriceType}
     * @memberof AdminElevatorAdvertData
     */
    'priceType': OfferPriceType;
    /**
     * Время публикации
     * @type {string}
     * @memberof AdminElevatorAdvertData
     */
    'publishedAt': string | null;
    /**
     * Время окончания публикации
     * @type {string}
     * @memberof AdminElevatorAdvertData
     */
    'expiredAt': string | null;
    /**
     * Время отклонения
     * @type {string}
     * @memberof AdminElevatorAdvertData
     */
    'rejectedAt': string | null;
    /**
     * Причина отклонения
     * @type {string}
     * @memberof AdminElevatorAdvertData
     */
    'rejectionReason': string | null;
    /**
     * Дата создания объявления
     * @type {string}
     * @memberof AdminElevatorAdvertData
     */
    'createdAt': string;
    /**
     * Id активного отклика
     * @type {number}
     * @memberof AdminElevatorAdvertData
     */
    'elevatorOfferResponseId': number | null;
    /**
     * Контактное лицо
     * @type {AdminElevatorAdvertContactUserData}
     * @memberof AdminElevatorAdvertData
     */
    'contactUser': AdminElevatorAdvertContactUserData;
    /**
     * Модератор
     * @type {AdminElevatorAdvertModeratorData}
     * @memberof AdminElevatorAdvertData
     */
    'moderator': AdminElevatorAdvertModeratorData | null;
}

export const AdminElevatorAdvertDataTypeEnum = {
    Buy: 'BUY',
    Sell: 'SELL'
} as const;

export type AdminElevatorAdvertDataTypeEnum = typeof AdminElevatorAdvertDataTypeEnum[keyof typeof AdminElevatorAdvertDataTypeEnum];


