import React, { useEffect } from 'react'

import { InputNumber } from 'antd'
import { Controller, useForm } from 'react-hook-form'

import { yupResolver } from '@hookform/resolvers/yup/dist/yup'

import { AppConfig } from '~/appConfig'
import { FormField, UploadButton } from '~/ui-components'
import { PhoneNumber } from '~shared/components'
import {
  CheckboxControl,
  ElevatorSelectControl,
  ParametersCultureControl,
  PriceControl,
  PriceTypeControl,
  UsingNdsControl,
  VolumeControl,
} from '~shared/controls'

import { createAdvertFormSchema } from './CreateAdvertFormBySeller.validation'

import { CreateAdvertFormBySellerValues, CreateAdvertFormProps } from './CreateAdvertFormBySeller.types'

import * as S from './CreateAdvertFormBySeller.styled'

export const CreateAdvertFormBySeller: React.FC<CreateAdvertFormProps> = ({ onSubmit, formId, defaultValues }) => {
  const {
    watch,
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm<CreateAdvertFormBySellerValues>({
    defaultValues: {
      elevatorId: defaultValues?.elevatorId,
      pricePerTon: defaultValues?.pricePerTon,
      volume: defaultValues?.volume,
      usingNds: defaultValues?.usingNds ?? false,
      priceType: defaultValues?.priceType,
      culture: defaultValues?.culture,
      labAnalysis: defaultValues?.labAnalysis,
      requiredLaboratoryAnalysis: defaultValues?.requiredLaboratoryAnalysis ?? false,
      publicationDateCount: undefined,
      isUnlimitedDate: true,
    },
    resolver: yupResolver(createAdvertFormSchema),
  })

  const requiredLaboratoryAnalysisWatch = watch('requiredLaboratoryAnalysis')
  const labAnalysisWatch = watch('labAnalysis')
  const isUnlimitedDateWatch = watch('isUnlimitedDate')

  useEffect(() => {
    setValue('publicationDateCount', undefined)
  }, [isUnlimitedDateWatch])

  const onSubmitHandler = (formValues: CreateAdvertFormBySellerValues) => {
    onSubmit(formValues)
  }

  return (
    <form id={formId} onSubmit={handleSubmit(onSubmitHandler)}>
      <FormField
        label="Базис"
        htmlFor="elevatorId"
        validateMessage={errors.elevatorId?.message}
        isRequired
        isVisible={!defaultValues?.elevatorId}
      >
        <Controller
          name="elevatorId"
          control={control}
          render={({ field }) => (
            <>
              <ElevatorSelectControl id="elevatorId" {...field} hasError={!!errors.elevatorId} />{' '}
              <S.InfoText>
                Не нашли нужный базис? Свяжитесь с нами:{' '}
                <PhoneNumber prefix="" phoneNumber={AppConfig.ADMIN_CONTACT_PHONE} />
              </S.InfoText>
            </>
          )}
        />
      </FormField>

      <FormField validateMessage={errors.culture ? 'Параметры культуры заполнены с ошибками' : ''}>
        <Controller
          name="culture"
          control={control}
          render={({ field }) => <ParametersCultureControl isRequired {...field} />}
        />
      </FormField>

      <FormField label="Анализная карта" validateMessage={errors.labAnalysis?.message} isRequired>
        <Controller
          control={control}
          name="labAnalysis"
          render={({ field }) => (
            <UploadButton
              onFileClick={() => window.open(defaultValues?.labAnalysis as string, '_blank')}
              onChange={(evt) => {
                if (evt.target.files?.length) {
                  field.onChange(evt.target.files[0])
                }
              }}
              disabled={!!requiredLaboratoryAnalysisWatch}
              onDelete={() => field.onChange(undefined)}
            >
              Загрузить
            </UploadButton>
          )}
        />
      </FormField>

      {/*<S.OrText>или</S.OrText>*/}

      <FormField htmlFor="requiredLaboratoryAnalysis" validateMessage={errors.requiredLaboratoryAnalysis?.message}>
        <Controller
          name="requiredLaboratoryAnalysis"
          control={control}
          render={({ field }) => (
            <CheckboxControl {...field} disabled={!!labAnalysisWatch}>
              Заказать отбор качества
            </CheckboxControl>
          )}
        />
      </FormField>

      <FormField label="Объем" htmlFor="volume" validateMessage={errors.volume?.message} isRequired>
        <Controller
          name="volume"
          control={control}
          render={({ field }) => (
            <S.HalfRow>
              <VolumeControl {...field} id="volume" hasError={!!errors.volume} />
            </S.HalfRow>
          )}
        />
      </FormField>

      <S.PriceControlWrapper>
        <FormField
          htmlFor="pricePerTon"
          label="Предлагаемая цена"
          validateMessage={errors.pricePerTon?.message}
          isRequired
        >
          <Controller
            name="pricePerTon"
            control={control}
            render={({ field }) => (
              <PriceControl id="pricePerTon" {...field} hasError={!!errors.pricePerTon} addAfter="₽/т" />
            )}
          />
        </FormField>

        <FormField htmlFor="usingNds" validateMessage={errors.usingNds?.message} label="&nbsp;">
          <Controller name="usingNds" control={control} render={({ field }) => <UsingNdsControl {...field} />} />
        </FormField>
      </S.PriceControlWrapper>

      <FormField htmlFor="priceType" validateMessage={errors.priceType?.message} label="Тип стоимости" isRequired>
        <Controller
          name="priceType"
          control={control}
          render={({ field }) => (
            <S.HalfRow>
              <PriceTypeControl {...field} />
            </S.HalfRow>
          )}
        />
      </FormField>

      <FormField
        htmlFor="publicationDateCount"
        label="Срок публикации"
        validateMessage={errors.publicationDateCount?.message}
        isRequired
      >
        <Controller
          name="publicationDateCount"
          control={control}
          render={({ field }) => (
            <S.HalfRow>
              <InputNumber
                onChange={field.onChange}
                addonAfter="дней"
                value={field.value}
                // placeholder="введите"
                disabled={isUnlimitedDateWatch}
                status={errors.publicationDateCount ? 'error' : undefined}
                precision={0}
              />
            </S.HalfRow>
          )}
        />
      </FormField>

      <FormField validateMessage={errors.publicationDateCount?.message}>
        <Controller
          control={control}
          name="isUnlimitedDate"
          render={({ field }) => <CheckboxControl {...field}>Опубликовать бессрочно</CheckboxControl>}
        />
      </FormField>
    </form>
  )
}
