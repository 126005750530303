import * as yup from 'yup'

import { addressSchema, VALIDATORS_MSG } from '~shared/validations'

export const moderatorOfferFormSchema = yup
  .object({
    address: addressSchema,
    volume: yup.number().typeError(VALIDATORS_MSG.required).required(),
    price: yup.number().typeError(VALIDATORS_MSG.required).notRequired(),
    commission: yup.number().typeError(VALIDATORS_MSG.required).min(0, VALIDATORS_MSG.minValue(0)).notRequired(),
    usingNds: yup.boolean().notRequired(),
    shippingRequired: yup.boolean().notRequired(),
    culture: yup.object({
      // hasError: yup.bool().oneOf([false], 'Параметры культуры заполнены с ошибками'),
    }),
    note: yup.string().notRequired(),
  })
  .required()
