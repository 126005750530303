import { useQuery, UseQueryResult } from '@tanstack/react-query'

import { ModeratedAuctionData } from '~/api/generated'
import { useApi } from '~/providers/api'
import { isApiResponseEmpty } from '~/utils/isApiResponseEmpty'

export const useGetModeratedAuctionById = (id: number | undefined): UseQueryResult<ModeratedAuctionData> => {
  const api = useApi()

  const fetchFn = async (): Promise<ModeratedAuctionData | null> => {
    if (!id) return null

    try {
      return (
        (await api.auction.auctionControllerGetModeratedAuctions({ auctionIds: [id] })).data.moderatedAuctions?.[0] ??
        null
      )
    } catch (error) {
      if (isApiResponseEmpty(error)) {
        return null
      }

      return Promise.reject(error)
    }
  }

  return useQuery(['moderatedAuction', id], fetchFn)
}
